import { MerchantType, Paging } from './types'

export interface PaymentsCreateForm {
  merchantList: [MerchantType]
  fiatCurrencies: [
    {
      code: string
      name: string
    },
  ]
  onSuccess: Function
}

export interface PaymentsCreateRequest {
  merchantId: string
  currency: string
  amount: string
  returnUrl: string
  reference: string
  flow: string
  type: string
  expiryMinutes: string
}

interface PaymentSummaryCurrency {
  currency: string
  amount: string | number
  actual: number
}

interface PaymentSummaryAddress {
  address: string
  tag: string
  protocol: string
  uri: string
  alternatives: string[]
}

interface PaymentSummaryRate {
  base: string
  counter: string
  rate: number
}

interface PaymentSummaryTransaction {
  dateCreated: number | string
  dateConfirmed: number | string
  hash: string
  amount: number
  risk: Risk
  networkFeeCurrency: string
  networkFeeAmount: number | string
  sources: string[]
  displayRate: PaymentSummaryRate
  exchangeRate: PaymentSummaryRate
}

export interface PaymentsResponse {
  acceptanceExpiryDate: number
  address: Address
  dateCreated: number
  displayCurrency: DisplayCurrency
  displayRate: DisplayRate
  exchangeRate: ExchangeRate
  expiryDate: number
  feeCurrency: FeeCurrency
  hasEnhancedChecks?: boolean
  merchantDisplayName: string
  merchantId: string
  paidCurrency: PaidCurrency
  quoteExpiryDate: number
  quoteStatus: string
  redirectUrl: string
  reference: string
  refund: any
  refunds: any[]
  returnUrl: string
  riskLevel?: string
  status: string
  subType: string
  transactions: Transaction[]
  type: string
  uuid: string
  walletCurrency: WalletCurrency
  latePayment?: boolean
  overPayment?: boolean
}

export interface Address {
  protocol: string
  address: string
  tag: string[]
  uri: string
  alternatives: string[]
}

export interface DisplayCurrency {
  currency: string
  amount: number
  actual: number
}

export interface DisplayRate {
  base: string
  counter: string
  rate: number
}

export interface ExchangeRate {
  base: string
  counter: string
  rate: number
}

export interface FeeCurrency {
  currency: string
  amount: number
  actual: number
}

export interface PaidCurrency {
  currency: string
  amount: number
  actual: number
}

export interface Transaction {
  dateCreated: number
  dateConfirmed: number
  hash: string
  amount: number
  risk: RiskProfile
  networkFeeCurrency: string
  networkFeeAmount: number
  sources: string[]
  exchangeRate: TransactionExchangeRate
  displayRate: TransactionDisplayRate
  hasEnhancedChecks?: boolean
}

export interface Risk {
  level?: string
  resourceName?: string
  resourceCategory?: string
  alerts?: any[]
}

export interface TransactionExchangeRate {
  base: string
  counter: string
  rate: number
}

export interface TransactionDisplayRate {
  base: string
  counter: string
  rate: number
}

export interface WalletCurrency {
  currency: string
  amount: number
  actual: number
}

export interface PaymentList {
  enteredTo: Date
  visible: boolean
  isTransactionsLoaded: boolean
  transactions: PaymentsResponse[]
  paging: Paging
  pagingMax: number
  selectedPayloadOpen: boolean
}

export const PaymentType = {
  IN: 'IN',
  OUT: 'OUT',
} as const

export const InflowPaymentTypes = [
  PaymentType.IN,
  'paymentIn',
  'overPayment',
  'underPayment',
  'reversal',
  'payIn',
  'underPaid',
  'overPaid',
  'userDeposit',
  'paymentUnder',
  'paymentOver',
  'channelDeposit',
  'latePayment',
]

export const SpecialPayments = {
  JOURNAL_ENTRY: 'journalEntry',
  CRYPTO_PAYOUT: 'cryptoPayout',
}

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType]

export const PaymentStatus = {
  ALL: 'ALL',
  CANCELLED: 'CANCELLED',
  COMPLETE: 'COMPLETE',
  COMPLETED: 'COMPLETED',
  DETECTED: 'DETECTED',
  EXPIRED: 'EXPIRED',
  PAID: 'PAID',
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
  PROCESSING: 'PROCESSING',
  UNDERPAID: 'UNDERPAID',
  OVERPAID: 'OVERPAID',
  SUCCESS: 'SUCCESS',
  CREATED: 'CREATED',
  VALID: 'VALID',
  INVALID: 'INVALDID',
  FAILED: 'FAILED',
  REVERTED: 'REVERTED',
  VALIDATED: 'VALIDATED',
  DRAFT: 'DRAFT',
  REQUESTED: 'REQUESTED',
  SUBMITTED: 'SUBMITTED',
  DECLINED: 'DECLINED',
  ERROR: 'ERROR',
  REJECETED: 'REJECTED',
  PENDING_APPROVAL: 'PENDING APPROVAL',
} as const

export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus]

export const PaymentMerchantDefault = {
  ALL: 'ALL',
} as const

export type PaymentMerchantDefault =
  (typeof PaymentMerchantDefault)[keyof typeof PaymentMerchantDefault]

export interface PayInstruction {
  action: string
  form: null
  redirectUrl: string
  displayParameters: null
}
interface PayInLeg {
  id: number
  amount: number
  dateCreated: number
  reference: string
  currency: string
}

export interface AcceptQuote {
  payInInstruction: PayInInstruction
  quote: Quote
}

export interface Quote {
  id: number
  from: string
  to: string
  amountIn: number
  amountDue: number
  amountOut: number
  price: number
  quoteStatus: string
  paymentStatus: string
  acceptanceExpiryDate: number
  paymentExpiryDate: number
  paymentReceiptDate: number
  payInLegs: PayInLeg[]
  payInMethod: PayInMethod
  payOutMethod: PayOutMethod
  uuid: string
  payOutInstruction?: any
  payInInstruction: PayInInstruction
  usePayInMethod: UsePayInMethod
  usePayOutMethod: UsePayInMethod
  fee: number
  processingFee: number
  type: string
  netPrice: number
  grossPrice: number
  amountInGross: number
  amountInNet: number
  fees: Fees
  dateCreated: number
  lastUpdated: number
}

interface PayInMethod {
  id: number
  code: string
  settlementCurrency: string
  requestedCurrency?: any
  estimatedExchangeRate?: any
  accountMethods: any[]
}

interface PayInInstruction {
  action: string
  form?: any
  redirectUrl?: any
  displayParameters?: any
}

interface UsePayInMethod {
  id: number
  display?: any
}

export interface RiskAlerts {
  level?: string
  category?: string
  service?: string
  amount?: number
  exposure?: string
}

export interface RiskProfile {
  level?: string
  resourceName?: string
  resourceCategory?: string
  alerts?: RiskAlerts[]
}

export interface Fees {
  percentage: Percentage
  value: Percentage
}

export interface Percentage {
  service: number
  processing: number
}

export interface PayOutMethod {
  id: number
  code: string
  currency: string
  accountMethods: any[]
}

export interface PaymentsCreateResponseError {
  errorList: ErrorList[]
}

export interface ErrorList {
  parameter: string
  code: string
  message: string
}

export interface PaymentNetworkFee {
  displayAmount: number
  displayCurrency: string
  paidAmount: number
  paidCurrency: string
}

export interface PaymentSummaryResponse {
  uuid: string
  merchantDisplayName: string
  merchantId: string
  dateCreated: number | string
  expiryDate: number | string
  quoteExpiryDate: number | string
  acceptanceExpiryDate: number | string
  quoteStatus: string
  reference: string
  type: PaymentType
  subType: string
  status: PaymentStatus | string
  displayCurrency: PaymentSummaryCurrency
  walletCurrency: PaymentSummaryCurrency
  paidCurrency: PaymentSummaryCurrency
  feeCurrency: PaymentSummaryCurrency
  displayRate: PaymentSummaryCurrency
  exchangeRate: PaymentSummaryCurrency
  address: PaymentSummaryAddress
  returnUrl: string
  redirectUrl: string
  transactions: PaymentSummaryTransaction[]
  refund?: string | object
  refunds?: string[] | object[]
}

export interface ChannelCreateRequest {
  merchantId: string
  displayCurrency: string
  payCurrency: string
  reference: string
}

export const PaymentCaseType = {
  NEW: 'New',
  UNRESOLVED: 'Unresolved',
  IN_PROGRESS: 'In Progress',
  RESOLVED: 'Resolved',
  ON_HOLD: 'On Hold',
  ESCALATED: 'Escalated',
  PENDING: 'Pending',
} as const

export type PaymentCaseType = (typeof PaymentCaseType)[keyof typeof PaymentCaseType]

export const PaymentTag = {
  ALL: 'ALL',
  ONHOLD: 'ONHOLD',
} as const

export type PaymentTag = (typeof PaymentTag)[keyof typeof PaymentTag]

export interface PaymentCase extends PaymentCaseCustomer {
  id: string
  caseNumber: string
  status: PaymentCaseType
  subject: string
  description: string
  createdDate: string
  closedDate?: string
  accountId: string
  paymentReference?: string
}

export interface PaymentCasesResponse {
  cases: PaymentCase[]
  count: number
  isFiltered?: boolean
}

export interface PaymentCaseDocument {
  id: string
  title: string
  pathOnClient: string
  fileExtension: string
  createdDate: string
}

export interface PaymentCaseEmail {
  id: string
  textBody: string
  htmlBody: string
  incoming: boolean
  fromAddress: string
  createdDate: string
}

export interface PaymentCaseDocumentsResponse {
  files: PaymentCaseDocument[]
  count: number
}

export interface PaymentCaseEmailsResponse {
  emails: PaymentCaseEmail[]
  count: number
}

export const CaseDetailsTabs = {
  EMAILS: 'emails',
  DOCUMENTS: 'documents',
} as const

export type CaseDetailsTabs = (typeof CaseDetailsTabs)[keyof typeof CaseDetailsTabs]

export interface PaymentCaseSendEmail {
  caseId: string
  fromAddress: string
  htmlBody: string
}

export interface PaymentCaseUploadDocument {
  caseId: string
  filename: string
  description: string
  content: ArrayBuffer | string
}

export interface CasesPagination {
  paging: Paging
  pagingMax: number
}

export interface PaymentCaseCustomer {
  customerId?: string
  customerCaseCompleted?: boolean
}

export interface PaymentCaseUpdate {
  caseId: string
  customer: PaymentCaseCustomer
}
