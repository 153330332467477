import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import EmptyContainer from 'components/EmptyContainer'

interface BeneficiaryErrorStateProps {
  onRefresh: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const BeneficiaryErrorState = ({ onRefresh }: BeneficiaryErrorStateProps) => {
  const { t } = useTranslation()

  return (
    <EmptyContainer className="grid p-16 mt-2 justify-items-center">
      <Box paddingB={16}>
        <Text size="lg" weight="medium" color="black" tag="h3">
          {t('oopsSomethingWentWrongContracted')}
        </Text>
      </Box>
      <Box flex paddingB={24}>
        <Text size="sm" color="grey">
          {t('reloadPage')}
        </Text>
      </Box>
      <Button iconElement={<Icon name="RefreshIcon" color="white" />} onClick={onRefresh}>
        {t('refresh')}
      </Button>
    </EmptyContainer>
  )
}
