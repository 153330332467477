import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { SmallTabs, Text } from '@node-space/storybook-components'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import CopyRow from 'components/CopyRow'
import { Options } from 'types/BankAccountsDetails'
import { Currencies, CurrencyLimits } from 'types/types'
import { roundNumberWithCommas } from 'utils/utils'
import { getSwiftBankDetails } from 'utils/wallet'
import { minAmount } from '../constants/constants'
import useDepositPaymentReference from '../hooks/useDepositPaymentReference'
import CopyAllBankDetailsButton from './CopyAllDetails'

interface SwiftDetailsProps {
  currency: Currencies
  options: Options
  copiedValue: string
  setCopiedValue: React.Dispatch<React.SetStateAction<string>>
  setCopiedTitle?: React.Dispatch<React.SetStateAction<string>>
  depositLimits?: CurrencyLimits
}

const SwiftDetails = ({
  currency,
  options,
  copiedValue,
  setCopiedValue,
  setCopiedTitle,
  depositLimits,
}: SwiftDetailsProps) => {
  const { t } = useTranslation()
  const { enableMultiplePaymentMethods } = useFeatureFlags()
  const accountDetails = getSwiftBankDetails(options, t, enableMultiplePaymentMethods)
  const paymentReference = useDepositPaymentReference(options)

  const { beneficiaryBankDetails, beneficiaryDetails } = accountDetails
  const accountDetailsText = useMemo(() => {
    const allBeneficiaryBankDetailsText = Object.keys(beneficiaryBankDetails || {})
      .map(label => `${label}: ${beneficiaryBankDetails[label]}`)
      .join('\n')

    const allBeneficiaryDetailsText = Object.keys(beneficiaryDetails || {})
      .map(label => `${label}: ${beneficiaryDetails[label]}`)
      .join('\n')

    return `${t('beneficiaryDetails')}:\n${allBeneficiaryDetailsText}\n\n${t(
      'beneficiaryBankDetails'
    )}:\n${allBeneficiaryBankDetailsText}`
  }, [beneficiaryBankDetails, beneficiaryDetails])

  const accordionItems = [
    {
      id: 'beneficiaryDetails',
      tabHeader: t('beneficiaryDetails'),
      tabContent: (
        <Box>
          <Box testid="beneficiaryDepositDetails" divideChildren="y">
            {Object.keys(beneficiaryDetails)
              .filter(label => !!label)
              .map(label => {
                return (
                  <Box key={label}>
                    <CopyRow
                      key={label}
                      title={label}
                      description={beneficiaryDetails[label]}
                      copiedValue={copiedValue}
                      setCopiedValue={setCopiedValue}
                      setCopiedTitle={setCopiedTitle}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      ),
    },
    {
      id: 'bankDetails',
      tabHeader: t('beneficiaryBankDetails'),
      tabContent: (
        <Box>
          <Box testid="beneficiaryBankDepositDetails" divideChildren="y">
            {Object.keys(beneficiaryBankDetails)
              .filter(label => !!label)
              .map(label => {
                return (
                  <Box key={label}>
                    <CopyRow
                      key={label}
                      title={label}
                      description={beneficiaryBankDetails[label]}
                      copiedValue={copiedValue}
                      setCopiedValue={setCopiedValue}
                      setCopiedTitle={setCopiedTitle}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      ),
    },
  ]

  if (!Object.keys(accountDetails).length) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }

  return (
    <>
      <Text size="sm">
        {`${t('depositUseDetails')} `}
        <span className="font-semibold">{`${
          depositLimits?.minimum
            ? roundNumberWithCommas(depositLimits?.minimum)
            : minAmount[currency]
        } ${currency}`}</span>
        {t('depositMatchDetails', {
          0: 'BVNK',
        })}
      </Text>
      <SmallTabs tabs={accordionItems} />
      <CopyAllBankDetailsButton
        accountDetailsText={accountDetailsText}
        copiedValue={copiedValue}
        setCopiedValue={setCopiedValue}
        setCopiedTitle={setCopiedTitle}
      />
      {!!paymentReference && (
        <Box paddingT={16}>
          <Callout state="warning" message={t('depositInformation')} />
        </Box>
      )}
    </>
  )
}

export default memo(SwiftDetails)
