import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Button } from '@node-space/storybook-components/dist/Button'

export const PaymentCaseError = () => {
  const { t } = useTranslation()

  return (
    <Box gapX={4}>
      <Text size="sm" align="center" color="grey">
        {t('paymentSupport.errorDescription')}{' '}
        <Button noStyling onClick={() => window.location.reload()}>
          <Text
            className="hover:underline inline-block"
            size="sm"
            align="center"
            color="primary-500"
          >
            {t('paymentSupport.reloadPage')}
          </Text>
        </Button>
      </Text>
    </Box>
  )
}
