import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { NavLink, To, useLocation } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import Box from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import ToggleIcon from './ToggleIcon'

export interface SidebarItem {
  title: string
  to: To
  testId?: string
}
export interface SidebarProps {
  to?: To
  title: string
  items?: SidebarItem[]
  icon: React.ReactNode
  requiresVerification?: boolean
  badgeLabel?: string
  sideBarToggleCallback?: Dispatch<SetStateAction<boolean>>
  isVerifiedAccount?: boolean
  isHidden: boolean
  testId?: string
  highlight?: React.ReactNode
}

const WALLETS_LINK = `${PathNames.WALLETS}`
const WALLETS_DASHBOARD_LINK = `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`
const APPROVALS_LINK = `${PathNames.APPROVALS}`
const MANAGE_ACCOUNT_LINK = `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`
const SETTINGS_LINK = `${PathNames.SETTINGS}`

const SideBarLink = ({
  to,
  title,
  items = null,
  icon,
  requiresVerification = true,
  badgeLabel,
  isVerifiedAccount,
  sideBarToggleCallback,
  isHidden,
  testId,
  highlight,
}: SidebarProps) => {
  if (isHidden) return null

  const location = useLocation()
  const { enableApprovalFlowAccountLevel } = useFeatureFlags()
  const { isDeveloperAccount } = useAccountsContext()
  const {
    pendingApprovalDetails: { isPendingApprovalsSuccess, numberOfPendingApprovals },
  } = useApprovalsContext()

  const { pathname } = location
  const pathStart = pathname?.substring(1, pathname?.length)?.split('/')?.[0]

  const shouldHighlightWallet =
    !isDeveloperAccount &&
    (pathname === '/' || pathname === WALLETS_LINK) &&
    to === WALLETS_DASHBOARD_LINK
  const isActive = '/' + pathStart === to || pathname === to || shouldHighlightWallet

  const isActiveManageAccount = to === MANAGE_ACCOUNT_LINK && '/' + pathStart === SETTINGS_LINK
  const disableClass = requiresVerification && !isVerifiedAccount && 'disable-element opacity-50'
  const urlTo = items?.length ? pathname : to
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(isActive)
  const additionalClass = isSubMenuOpen ? 'transform rotate-180' : ''

  useEffect(() => {
    setIsSubMenuOpen(isActive)
  }, [pathStart])

  const handleSideBarClose = () => {
    sideBarToggleCallback?.(false)
  }

  const showPendingApprovalsBadge = useMemo(
    () =>
      !!(
        enableApprovalFlowAccountLevel &&
        to === `${APPROVALS_LINK}` &&
        isPendingApprovalsSuccess &&
        numberOfPendingApprovals
      ),
    [enableApprovalFlowAccountLevel, numberOfPendingApprovals, isPendingApprovalsSuccess]
  )

  return (
    <li
      data-testid={testId}
      className={clsx(
        'relative pb-1',
        {
          'pt-7': to === `${WALLETS_LINK}` || to === `${WALLETS_DASHBOARD_LINK}`,
        },
        {
          'pt-8': to === `${MANAGE_ACCOUNT_LINK}`,
        },
        `${disableClass}`
      )}
    >
      <NavLink
        to={urlTo}
        className={clsx('py-2 px-3 rounded block transition duration-150 hover:bg-primary-700', {
          'text-primary-300': isActive || isActiveManageAccount,
          'text-neutral-cotton': !isActive && !isActiveManageAccount,
        })}
        onClick={!items && handleSideBarClose}
      >
        <div
          className="flex items-center justify-between"
          onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
        >
          <div className="flex flex-grow items-center">
            {icon}
            <span className="text-sm font-medium ml-5">{title}</span>
            {/* Pending Approvals Badge  */}
            {showPendingApprovalsBadge && (
              <Text
                size="xs"
                color="grey-01"
                className="ml-2 bg-purple-500 font-medium px-2 py-0.5 rounded-full"
              >
                {numberOfPendingApprovals}
              </Text>
            )}

            {!!highlight && (
              <Box borderRadius="full" className="px-2 py-0.5 ml-2 bg-purple-500">
                <Text size="xs" weight="medium" color="grey-01">
                  {highlight}
                </Text>
              </Box>
            )}
          </div>
          {badgeLabel && (
            <Text size="xs" color="status-success-500">
              {badgeLabel}
            </Text>
          )}
          {/* Icon */}
          {items && <ToggleIcon additionalClass={additionalClass} />}
        </div>
      </NavLink>
      {items && (
        <Box flex alignItems="center" justifyContent="between">
          <ul className={`pl-16 mt-1 ${!isSubMenuOpen && 'hidden'}`}>
            {items?.map(item => (
              <li data-testid={item?.testId} key={item?.title} className="mt-2 mb-1 py-1">
                <NavLink
                  to={item?.to}
                  className={clsx('block transition duration-150 hover:text-primary-500', {
                    'text-primary-300': pathname === item?.to,
                    'text-neutral-cotton': pathname !== item?.to,
                  })}
                  onClick={handleSideBarClose}
                >
                  <span className="text-sm">{item?.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </li>
  )
}

export default SideBarLink
