import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader, Text } from '@node-space/storybook-components'
import { useMappedRoles } from 'hooks/useMappedRoles'
import ApiKeyHeaderCreateButton from './ApiKeyHeaderCreateButton'

const ApiKeyHeader = () => {
  const { t } = useTranslation()
  const permissions = useMappedRoles().manageApiIntegration
  const canEdit = permissions?.canEdit

  return (
    <PageHeader
      description={
        <Text size="sm" color="grey" className="flex-auto whitespace-pre-line">
          {t('manageApiKeys.apiKeysDescription')}{' '}
        </Text>
      }
      renderRightSection={canEdit && <ApiKeyHeaderCreateButton />}
    />
  )
}

export default React.memo(ApiKeyHeader)
