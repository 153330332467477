import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'

export const FileUpload = ({ upload = false }) => {
  const { t } = useTranslation()

  const uploadFileTxt = upload ? t('massPayouts.uploadFile') : t('massPayouts.upload')
  const dragAndDropTxt = upload
    ? t('massPayouts.orDragAndDrop')
    : t('massPayouts.orDragAndDropToReplace')

  return (
    <Box flex gap={4}>
      <Text size="sm" color="primary-500">
        {uploadFileTxt}
      </Text>
      <Text size="sm" color="grey">
        {dragAndDropTxt}
      </Text>
    </Box>
  )
}
