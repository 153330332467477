import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { IconSelect } from '@node-space/storybook-components/dist/IconSelect'
import { Select } from '@node-space/storybook-components/dist/Select'
import { AddBeneficiaryFormErrors, AddBeneficiaryFormPayload } from 'types/beneficiaries'

interface IndividualBeneficiaryFieldsProps {
  availableCurrencies: { label: string; value: string }[]
  alias: string
  countries: { label: string; value: string }[]
  isCountriesLoading: boolean
  countryCode: string
  currencyCode: string
  errors: AddBeneficiaryFormErrors
  firstName: string
  lastName: string
  dateOfBirth: string
  isLocalBeneficiary?: boolean
  register: UseFormRegister<AddBeneficiaryFormPayload>
  setValue: (name: string, value: string) => void
  renderCurrencySpecificFields: () => React.ReactNode
}

export const IndividualBeneficiaryFields = ({
  availableCurrencies,
  alias,
  countries,
  isCountriesLoading,
  countryCode,
  currencyCode,
  errors,
  firstName,
  lastName,
  dateOfBirth,
  register,
  setValue,
  renderCurrencySpecificFields,
}: IndividualBeneficiaryFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Box flex direction="col">
      <Box paddingB={16}>
        <Select
          {...register('countryCode')}
          label={t('manageBeneficiaries.countryOfResidence')}
          value={countryCode}
          isSearchable
          options={countries}
          error={!!errors?.countryCode}
          errorText={errors?.countryCode?.message}
          placeholder={t('searchOrSelectACountry')}
          loading={isCountriesLoading}
          onChange={value => setValue('countryCode', value)}
        />
      </Box>
      <Box paddingB={16}>
        <IconSelect
          {...register('currencyCode')}
          iconsUrl={`${process.env.ICONS_URL}`}
          label={t('manageBeneficiaries.accountCurrency')}
          placeholder={t('searchOrSelectACurrency')}
          name="currencySelect"
          menuMaxHeight={400}
          options={availableCurrencies}
          isSearchable={true}
          iconLeft={!currencyCode && <Icon name="SearchIcon" />}
          error={!!errors?.currencyCode}
          errorText={errors?.currencyCode?.message}
          value={currencyCode}
          onChange={value => setValue('currencyCode', value)}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('firstName')}
          label={t('formFields.firstName')}
          placeholder={t('enterName')}
          inputMode="text"
          name="firstName"
          testid="first-name"
          errorText={errors?.firstName?.message}
          error={!!errors?.firstName}
          value={firstName}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('lastName')}
          label={t('formFields.lastName')}
          placeholder={t('enterLastName')}
          inputMode="text"
          name="lastName"
          testid="last-name"
          errorText={errors?.lastName?.message}
          error={!!errors?.lastName}
          value={lastName}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('alias')}
          label={t('beneficiaryName')}
          placeholder={t('inputPlaceholders.enterBeneficiaryNamePlaceholder')}
          inputMode="text"
          name="alias"
          testid="alias"
          errorText={errors?.alias?.message}
          error={!!errors?.alias}
          value={alias}
        />
      </Box>
      {!!renderCurrencySpecificFields && <Box>{renderCurrencySpecificFields()}</Box>}
      <Box paddingB={16}>
        <Input
          {...register('dateOfBirth')}
          label={`${t('formFields.dateOfBirth')} ${t('formFields.optional')}`}
          placeholder={t('formFields.dateOfBirthFormat')}
          inputMode="text"
          name="dateOfBirth"
          testid="date-of-birth"
          errorText={errors?.dateOfBirth?.message}
          error={!!errors?.dateOfBirth}
          value={dateOfBirth}
        />
      </Box>
    </Box>
  )
}
