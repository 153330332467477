import { requestApi } from 'api/api'
import { AccountAgreementAcceptancePayload } from 'components/PendingAgreements/types'
import {
  LegalAgreementStatus,
  LegalAgreementStatusType,
  LegalAgreementType,
} from 'pages/LegalAgreements/types'

export const getPendingAgreements = async (): Promise<LegalAgreementType[]> => {
  return requestApi({
    url: '/accounts-management/v1/accounts/agreements',
    method: 'GET',
    params: {
      status: LegalAgreementStatus.PENDING,
    },
  })
}

export const updatePendingAgreements = (data: AccountAgreementAcceptancePayload[]) => {
  return requestApi({
    url: '/business/account/applicable-agreement',
    method: 'PUT',
    data,
  })
}

export const getLegalAgreements = async (
  statusParams?: LegalAgreementStatusType[]
): Promise<LegalAgreementType[]> => {
  return requestApi({
    url: '/accounts-management/v1/accounts/agreements',
    method: 'GET',
    params: {
      ...(!!statusParams?.length && { status: statusParams?.join() }),
    },
  })
}
