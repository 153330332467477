import React, { createContext, useMemo, useState } from 'react'

interface DevXProps {
  openStep: string
  isDevXGoLiveReady: boolean
  setOpenStep: React.Dispatch<React.SetStateAction<string>>
  setIsDevXGoLiveReady: React.Dispatch<React.SetStateAction<boolean>>
}

export const DevXContext = createContext<DevXProps>(null)

export const DevXProvider = ({ children }) => {
  const [openStep, setOpenStep] = useState('')

  const [isDevXGoLiveReady, setIsDevXGoLiveReady] = useState(false)

  const value = useMemo(
    () => ({
      openStep,
      isDevXGoLiveReady,
      setOpenStep: setOpenStep,
      setIsDevXGoLiveReady,
    }),
    [openStep, isDevXGoLiveReady, setOpenStep, setIsDevXGoLiveReady]
  )

  return <DevXContext.Provider value={value}>{children}</DevXContext.Provider>
}
