import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { logSentryError } from '@node-space/utils'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { postDepositDetails } from 'services/WalletsService'
import { WalletType } from 'types/types'
import { WalletTabProps } from '../Modal/WalletModal'
import ReceiveForm from './ReceiveForm'

export type ReceiveSteps = 'form'

export type ReceiveModalProps = {}

export type ReceiveForm = {
  walletId?: string
  protocol?: string
  message?: string
}

export type ReceiveActionProps = WalletTabProps &
  IWalletActionApiType<ReceiveForm, ReceiveSteps> & {
    wallet?: WalletType | undefined
    walletWithProtocol?: WalletType | undefined
  }

/**
 * Receive Tab containing state, form and API data for each step
 */
export const Receive = ({
  wallets,
  form,
  setLoading,
  refresh,
  step,
  loading,
  ...otherProps
}: ReceiveActionProps) => {
  const { t } = useTranslation()
  const formValues = form.watch()

  const [wallet, setWallet] = useState<WalletType | undefined>()

  const supportedWallets = wallets.filter(x => !x.currency.fiat && x.currency.supportsDeposits)

  /**
   * Set default protocol if wallet contains more than 1
   */
  useEffect(() => {
    async function checkWallet() {
      const selectedWallet =
        formValues.walletId && supportedWallets.find(x => x.id.toString() === formValues.walletId)

      if (!selectedWallet) {
        return
      }

      // always set local wallet state with selected form wallet
      wallet?.id !== selectedWallet.id && setWallet(selectedWallet)

      const protocolAddress =
        selectedWallet && (!formValues.protocol || selectedWallet.protocol === formValues.protocol)
          ? selectedWallet.address
          : (selectedWallet?.alternatives as { protocol: string; address: string }[])?.find(
              x => x.protocol === formValues.protocol
            )?.address

      const protocolChanged =
        formValues.protocol && selectedWallet.protocol !== formValues.protocol && !protocolAddress

      // fetch wallet if no address or different protocol
      if (!selectedWallet.address || protocolChanged) {
        setLoading(true)
        try {
          const walletDetails = await postDepositDetails({
            walletId: selectedWallet.id.toString(),
            protocol: formValues.protocol,
          })
          if (walletDetails) {
            // set local wallet state with fetched wallet details
            setWallet(walletDetails)

            refresh()
          }
        } catch (err) {
          logSentryError('Error from Receive - Could not fetch deposit details for wallet', err, {
            walletId: selectedWallet.id.toString(),
            protocol: formValues.protocol,
          })
          form.setError('message', { message: t('depositDetailsError') })
        }
        setLoading(false)
      }
    }
    checkWallet()
  }, [formValues.walletId, formValues.protocol])

  const props = {
    wallet,
    wallets,
    form,
    setLoading,
    refresh,
    step,
    loading,
    ...otherProps,
  }

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case 'form':
        return <ReceiveForm {...props} />
    }
  }

  return renderStep()
}
