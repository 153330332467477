import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import { DateFormat } from 'constants/General'
import { SearchTransactionContent } from 'types/transaction'
import { TransactionType } from 'types/transaction/TransactionType'
import { transformSearchResultsByPaymentType } from '../searchWalletTransformer'

export const useTransactionDetailsTransformer = (
  transactionDetails: SearchTransactionContent,
  t?: TFunction<string, unknown>
) => {
  const transformedData = transformSearchResultsByPaymentType(transactionDetails, t)
  const isBvnkNetworkTransfer = transactionDetails?.type === TransactionType.BVNK_NETWORK_TRANSFER
  const amountWithCurrency = !!transformedData?.amountWithoutCurrency
    ? `${transformedData?.amount}`
    : ''
  const fromCurrency = transformedData?.fromCurrency
  const toCurrency = transformedData?.toCurrency

  const iconBaseUrl = process.env.ICONS_URL
  const iconUrls = []
  !!fromCurrency && iconUrls.push(`${iconBaseUrl}${fromCurrency?.toLowerCase()}.svg`)
  !!toCurrency && iconUrls.push(`${iconBaseUrl}${toCurrency?.toLowerCase()}.svg`)

  const transactionType = transactionDetails?.type || transactionDetails?.description
  const transactionSubType = transactionDetails?.subtype
  const hasDirectionOrIsChannelsDeposit =
    transactionDetails?.direction || transformedData?.isChannelsDeposit
  const walletAddresses = transactionDetails?.walletAddresses
  const walletName = transactionDetails?.walletName
  const customerReference =
    !isBvnkNetworkTransfer &&
    (transactionDetails?.customerReference ||
      (transformedData?.isChannelsDeposit && transactionDetails?.transactionReference))
  const transactionUUID = transactionDetails?.transactionUUID
  const transactionHash = transactionDetails?.hash
  const transactionDate =
    !!transactionDetails?.dateCreated &&
    dayjs(transactionDetails.dateCreated).format(DateFormat.D_MMM_YYYY_AT_HH_MM)
  const withdrawalDetails =
    transactionDetails?.accountNumber &&
    (transactionDetails?.bankName || transactionDetails?.bankCode)
  const showSentFromWalletAddress =
    !!transactionDetails?.walletAddresses?.length &&
    transactionDetails?.description === TransactionType.USER_DEPOSIT
  const showSentToWalletAddress =
    !!transactionDetails?.walletAddresses?.length &&
    transactionDetails?.description === TransactionType.USER_WITHDRAWAL

  const exchangeRate = transactionDetails?.exchangeRate

  const customTextCopies = transformedData?.copiesPerKeyTransactionTypes

  return {
    amountWithCurrency,
    fromCurrency,
    toCurrency,
    iconUrls,
    transactionType,
    transactionSubType,
    customerReference,
    transactionHash,
    transactionDate,
    customTextCopies,
    hasDirectionOrIsChannelsDeposit,
    walletAddresses,
    walletName,
    transactionUUID,
    withdrawalDetails,
    showSentFromWalletAddress,
    showSentToWalletAddress,
    transformedData,
    exchangeRate,
    name: transactionDetails?.name,
  }
}
