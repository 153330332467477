import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface ConvertOutProps {
  amountWithCurrency: string
  currencyFrom: string
  currencyTo: string
  convertedFromWallet: string
  convertedToWallet: string
  exchangeRate: number
  convertedAmountTo?: string
}

const ConvertOut = ({
  amountWithCurrency,
  currencyFrom,
  currencyTo,
  convertedFromWallet,
  convertedToWallet,
  exchangeRate,
  convertedAmountTo,
}: ConvertOutProps) => {
  const { t } = useTranslation()
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.amountConverted')}
          detail={amountWithCurrency}
        />
      )}
      {convertedFromWallet && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.convertedFrom')}
          detail={convertedFromWallet}
        />
      )}
      {convertedToWallet && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.convertedTo')}
          detail={convertedToWallet}
        />
      )}
      {convertedAmountTo && (
        <TransactionDetailRow
          label={t('wallets.searchTransaction.payOutAmount')}
          detail={convertedAmountTo}
        />
      )}
      {exchangeRate && (
        <TransactionDetailRow
          label={t('wallets.searchTransaction.exchangeRate')}
          detail={`1 ${currencyFrom} = ${exchangeRate} ${currencyTo}`}
        />
      )}
    </>
  )
}

export default ConvertOut
