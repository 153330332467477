import React from 'react'
import i18next from 'i18next'
import { startCase } from 'lodash-es'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { SpecialPayments } from 'types/payments'
import { Transaction } from 'types/transaction'
import { TTransactionSubType } from 'types/transaction/TransactionType'
import { getSupportedPaymentTypes } from 'utils/utils'
import { useTransactionDetails } from '../hooks/useTransactionDetails'
import {
  ChannelDeposit,
  CommonFields,
  ConvertIn,
  ConvertOut,
  CryptoDeposit,
  DefaultPayment,
  FailedWithdrawal,
  FiatDeposit,
  FirstAndThirdPartyWithdrawal,
  LatePayment,
  PaymentIn,
  PaymentOut,
  ProcessingFee,
  UserCryptoWithdrawal,
  WithdrawalDetails,
} from '../SidePanel/PaymentTemplates'
import BvnkNetworkTransfer from './PaymentTemplates/BvnkNetworkTransfer'
import BvnkNetworkTransferSenderReceiver from './PaymentTemplates/BvnkNetworkTransferSenderReceiver'
import { TransactionDetailRow } from './TransactionDetailRow'

interface SidePanelBodyProps {
  transactionDetails: Transaction
  supportedPaymentTypes: ReturnType<typeof getSupportedPaymentTypes>
}

export const SidePanelBody = ({
  transactionDetails,
  supportedPaymentTypes,
}: SidePanelBodyProps) => {
  const {
    amountWithCurrency,
    transactionHash,
    currencyFrom = '',
    currencyTo = '',
    currencyCode,
    transactionDescription,
    transactionType,
    transactionSubType,
    transactionDate,
    transactionReference,
    depositDetails,
    withdrawalDetails,
    paidActualAmountWithCurrencyFrom,
    paidActualAmountWithCurrencyTo,
    merchantId,
    transactionUUID,
    channelId,
    channelReference,
    amountWithdrawn,
    transactionDepositCryptoUUID,
    recipientDetails,
    sourceAddress,
    exchangeRate,
    convertedFromWallet,
    convertedToWallet,
    convertedAmountTo,
    convertedAmountFrom,
    paymentMetadata,
    bvnkNetworkBeneficiary,
    bvnkNetworkSender,
  } = useTransactionDetails(transactionDetails)

  const getPaymentDetails = (type: string, subType: TTransactionSubType | string = '') => {
    const key = `${type}_${subType}`

    switch (key) {
      case supportedPaymentTypes.CHANNEL_DEPOSIT:
        return (
          <>
            <DefaultPayment amountWithCurrency={amountWithCurrency} merchantId={merchantId} />
            <ChannelDeposit channelId={channelId} channelReference={channelReference} />
          </>
        )
      case supportedPaymentTypes.CHANNEL_DEPOSIT_FEE:
        return (
          <>
            <ProcessingFee amountWithCurrency={amountWithCurrency} />
            <ChannelDeposit channelId={channelId} channelReference={channelReference} />
          </>
        )
      case supportedPaymentTypes.CONVERT_IN:
        return (
          <ConvertIn
            amountWithCurrency={amountWithCurrency}
            convertedFromWallet={convertedFromWallet}
            exchangeRate={exchangeRate}
            currencyFrom={currencyFrom}
            currencyTo={currencyTo}
            convertedAmountFrom={convertedAmountFrom}
            convertedToWallet={convertedToWallet}
          />
        )
      case supportedPaymentTypes.CONVERT_OUT:
        return (
          <ConvertOut
            amountWithCurrency={amountWithCurrency}
            convertedToWallet={convertedToWallet}
            convertedFromWallet={convertedFromWallet}
            convertedAmountTo={convertedAmountTo}
            exchangeRate={exchangeRate}
            currencyFrom={currencyFrom}
            currencyTo={currencyTo}
          />
        )
      case supportedPaymentTypes.PAYMENT_OUT:
        return (
          <PaymentOut
            merchantId={merchantId}
            amountWithCurrency={amountWithCurrency}
            paidActualAmountWithCurrency={paidActualAmountWithCurrencyTo}
            transactionReference={transactionReference}
            sourceAddress={sourceAddress}
            date={transactionDate}
            uuid={transactionUUID}
            hash={transactionHash}
            paymentMetadata={paymentMetadata}
            currencyFrom={currencyFrom}
            currencyTo={currencyTo}
          />
        )
      case supportedPaymentTypes.PAYMENT_IN:
      case supportedPaymentTypes.PAYMENT_IN_UNDER:
      case supportedPaymentTypes.PAYMENT_IN_OVER:
      case supportedPaymentTypes.OVERPAYMENT_IN_OVER:
      case supportedPaymentTypes.UNDER_PAYMENT:
        return (
          <PaymentIn
            merchantId={merchantId}
            sourceAddress={sourceAddress}
            amountWithCurrency={amountWithCurrency}
            paidActualAmountWithCurrency={paidActualAmountWithCurrencyFrom}
            transactionReference={transactionReference}
            paymentMetadata={paymentMetadata}
            currencyFrom={currencyFrom}
            currencyTo={currencyTo}
          />
        )
      case supportedPaymentTypes.PAYMENT_IN_LATE:
        return (
          <LatePayment
            transactionHash={transactionDetails?.hash} // late payments have different format
            amountWithCurrency={amountWithCurrency}
          />
        )
      case supportedPaymentTypes.PAYMENT_PROCESSING_FEE:
      case supportedPaymentTypes.PAYMENT_PROCESSING_FEE_UNDERPAYMENT:
      case supportedPaymentTypes.PAYMENT_PROCESSING_FEE_PAYMENT_OUT:
      case supportedPaymentTypes.PAYMENT_PROCESSING_FEE_OVERPAYMENT:
        return <ProcessingFee amountWithCurrency={amountWithCurrency} />

      case supportedPaymentTypes.WITHDRAWAL_FAILED:
        return <FailedWithdrawal amountWithCurrency={amountWithCurrency} merchantId={merchantId} />

      case supportedPaymentTypes.FIRST_AND_THIRD_PARTY_WITHDRAWAL:
        return (
          <FirstAndThirdPartyWithdrawal
            amountWithdrawn={amountWithdrawn}
            transactionReference={transactionReference}
            currencyCode={currencyCode}
          />
        )
      case supportedPaymentTypes.USER_DEPOSIT_FIAT:
        return (
          <FiatDeposit
            depositDetails={depositDetails}
            transactionReference={transactionReference}
            amountWithCurrency={amountWithCurrency}
            transactionDate={transactionDate}
          />
        )

      case supportedPaymentTypes.USER_DEPOSIT_CRYPTO:
        return (
          <CryptoDeposit
            amountWithCurrency={amountWithCurrency}
            transactionDate={transactionDate}
            transactionHash={transactionHash}
            transactionDepositCryptoUUID={transactionDepositCryptoUUID}
            sourceAddress={sourceAddress}
          />
        )
      case supportedPaymentTypes.USER_CRYPTO_WITHDRAWAL:
        return (
          <UserCryptoWithdrawal
            amountWithCurrency={amountWithCurrency}
            recipientDetails={recipientDetails}
            transactionHash={transactionHash}
            transactionDate={transactionDate}
          />
        )
      case supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_OUT:
      case supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_IN:
        return (
          <BvnkNetworkTransfer
            amountWithCurrency={amountWithCurrency}
            transactionReference={transactionReference}
            currencyCode={currencyCode}
            transactionType={startCase(transactionType)}
            transactionSubType={transactionSubType}
          />
        )
      default:
        return <DefaultPayment amountWithCurrency={amountWithCurrency} merchantId={merchantId} />
    }
  }

  const displayTemplate = getPaymentDetails(transactionType, transactionSubType)
  const transactionTypeSubType = `${transactionType}_${transactionSubType}`

  const shouldRenderCommonFields = !(
    supportedPaymentTypes.USER_DEPOSIT_FIAT === transactionTypeSubType ||
    supportedPaymentTypes.USER_DEPOSIT_CRYPTO === transactionTypeSubType ||
    supportedPaymentTypes.USER_CRYPTO_WITHDRAWAL === transactionTypeSubType ||
    supportedPaymentTypes.PAYMENT_OUT === transactionTypeSubType
  )
  const isBvnkNetworkTransaction =
    transactionTypeSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_IN ||
    supportedPaymentTypes?.BVNK_NETWORK_TRANSFER_PAYMENT_OUT

  return (
    <Box padding={16}>
      <Text size="base" weight="medium" color="dark-grey">
        {i18next.t('transactionDetails')}
      </Text>
      <Box paddingT={8}>
        {displayTemplate}

        {shouldRenderCommonFields && (
          <CommonFields
            transactionUUID={transactionUUID}
            transactionHash={transactionHash}
            transactionDate={transactionDate}
          />
        )}

        {transactionType === SpecialPayments.JOURNAL_ENTRY && transactionDescription && (
          <TransactionDetailRow label={i18next.t('description')} detail={transactionDescription} />
        )}
      </Box>
      {!!withdrawalDetails && <WithdrawalDetails withdrawalDetails={withdrawalDetails} />}

      {isBvnkNetworkTransaction && (
        <BvnkNetworkTransferSenderReceiver
          transactionTypeSubType={transactionTypeSubType}
          sender={bvnkNetworkSender}
          beneficiary={bvnkNetworkBeneficiary}
        />
      )}
    </Box>
  )
}
