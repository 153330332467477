import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AddBeneficiaryFormErrors, BaseFormProps, ValidateScanResponse } from 'types/beneficiaries'

interface GbpFormFieldsProps extends BaseFormProps {
  accountNumber: string
  errors: AddBeneficiaryFormErrors
  sortCode: string
  isValidatingScan: boolean
  scanValidationData: ValidateScanResponse
}

export const GbpFormFields = ({
  accountNumber,
  errors,
  sortCode,
  handleChange,
  register,
}: GbpFormFieldsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Box paddingB={16}>
        <Input
          {...register('accountNumber')}
          label={t('accountNumber')}
          placeholder={t('enterAccountNumber')}
          inputMode="numeric"
          name="accountNumber"
          testid="input-accountNumber"
          errorText={errors?.accountNumber?.message}
          error={!!errors?.accountNumber}
          value={accountNumber}
          onChange={event => handleChange('accountNumber', event?.target?.value)}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('sortCode')}
          label={t('sortCode')}
          placeholder={t('enterCode')}
          inputMode="numeric"
          name="sortCode"
          testid="input-sortCode"
          errorText={errors?.sortCode?.message}
          error={!!errors?.sortCode}
          value={sortCode}
          onChange={event => handleChange('sortCode', event?.target?.value)}
        />
      </Box>
    </>
  )
}
