import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createChannel } from 'services/ChannelService'

export const useCreateChannel = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createChannel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['channels'] })
    },
  })
}
