import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import SearchingToolV2 from 'components/SearchingToolV2'
import { useApiKeysQuery } from 'hooks/queries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import ApiKeyHeader from '../components/Header/ApiKeyHeader'
import ApiKeysNoData from '../components/Table/ApiKeysNoData'
import { ApiKeyModalProvider } from '../context/ApiKeyModalProvider'
import ApiKeysTableV2 from './ApiKeysTable'
import ApiKeyModal from './Modal/ApiKeyModal'

const ApiKeys = () => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const { data: apiKeyData, isFetching, isError } = useApiKeysQuery(query)

  const showEmptyState = useMemo(() => {
    return !isError && !isFetching && !apiKeyData?.length && !query
  }, [isError, isFetching, apiKeyData, query])

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_API_KEYS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <Wrapper>
      <ApiKeyModalProvider>
        <ApiKeyModal />
        {showEmptyState ? (
          <ApiKeysNoData />
        ) : (
          <>
            <ApiKeyHeader />
            <SearchingToolV2
              placeholder={t('browseApiKeys')}
              onSearchChanged={setQuery}
              query={query}
            />
          </>
        )}

        {isFetching && (
          <Wrapper flex justifyContent="center" data-testid="loader">
            <Loader theme="dark" size="large" data-testid="loader" />
          </Wrapper>
        )}

        {isError && <StatusCard headline={t('defaultError')} status="error" />}

        {!isFetching && !!apiKeyData?.length && <ApiKeysTableV2 data={apiKeyData} />}
      </ApiKeyModalProvider>
    </Wrapper>
  )
}

export default React.memo(ApiKeys)
