import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postPayoutsPostAuthV2, postPayoutsV2PreAuth } from 'services/PayoutsService'

export const usePayoutsV2PreAuthMutation = () => {
  return useMutation({
    mutationFn: postPayoutsV2PreAuth,
    onError: (error, data) => {
      logSentryError('Error: usePayoutsV2PreAuthMutation', error, data)
    },
  })
}

export const usePayoutsV2PostAuthMutation = () => {
  return useMutation({
    mutationFn: postPayoutsPostAuthV2,
    onError: (error, data) => {
      logSentryError('Error: usePayoutsV2PostAuthMutation', error, data)
    },
  })
}
