import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPayoutsFeeEstimate } from 'services/PayoutsService'
import { getPayoutFees } from 'services/WalletsService'
import { BeneficiaryTransferDestination } from 'types/beneficiaries'
import { PayoutsFeeEstimateResponse, PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import { QueryMetaType } from 'types/reactQuery'
import { PayFee } from 'types/wallets'

export interface PayoutsFeeEstimateParams {
  request: PayoutsV2PreAuthRequest
  isThirdPartyPayment?: boolean
  isPayoutFeesV2: boolean
  isEnabled?: boolean
  transferDestination?: BeneficiaryTransferDestination
}

export const usePayoutsFeeEstimateQuery = ({
  request,
  isThirdPartyPayment = false,
  isPayoutFeesV2,
  isEnabled = false,
  transferDestination = BeneficiaryTransferDestination.LOCAL,
}: PayoutsFeeEstimateParams) => {
  const fetchWithdrawFee = () => {
    if (isPayoutFeesV2) {
      return getPayoutsFeeEstimate(request)
    } else {
      return getPayoutFees(
        Number(request?.walletId),
        Number(request?.amount?.value),
        isThirdPartyPayment,
        transferDestination
      )
    }
  }

  const getPayFees = (response: PayoutsFeeEstimateResponse | PayFee): PayFee => {
    const isPayoutFeesV2 = response['availablePayoutAmount'] && response['fee']
    return isPayoutFeesV2
      ? ({
          currencyCode: (response as PayoutsFeeEstimateResponse)?.fee?.currency,
          fee: (response as PayoutsFeeEstimateResponse)?.fee?.value,
          maximumWithdrawalAmount: (response as PayoutsFeeEstimateResponse)?.availablePayoutAmount
            ?.value,
        } as PayFee)
      : (response as PayFee)
  }

  const meta: QueryMetaType = {
    errorAdditionalData: { request },
  }

  return useQuery({
    queryKey: reactQueryKeys.payoutsFeeEstimate(request),
    queryFn: fetchWithdrawFee,
    enabled: isEnabled,
    select: (response: PayoutsFeeEstimateResponse | PayFee) => {
      return getPayFees(response)
    },
    meta,
  })
}
