import React, { ChangeEvent } from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Search } from '@node-space/storybook-components/dist/Search'

type SearchingToolProps = {
  query?: string
  placeholder: string
  onSearchChanged: (query: string) => void
}

const SearchingTool = ({ onSearchChanged, query }: SearchingToolProps) => {
  const onQueryChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChanged(e.currentTarget.value)
  }

  return (
    <Box paddingB={20}>
      <Box width="full" className="relative">
        <Box>
          <Search onChange={onQueryChanged} searchTerm={query} />
          {!!query.length && (
            <Box paddingR={12} className="absolute z-10 right-0 top-2">
              <Button
                type="button"
                iconElement={<Icon name="CloseIcon" />}
                noStyling
                onClick={() => onSearchChanged('')}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(SearchingTool)
