import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Container } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import MerchantHeader from './components/MerchantHeader'
import { MerchantTable } from './components/MerchantTable'

export const MerchantsDetails = () => {
  const { t } = useTranslation()

  const { data: { merchantList = [] } = {}, isError, isPending } = useMerchantsQuery()

  const handleRefresh = () => window.location.reload()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_MERCHANTS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <>
      {/* Show loading state */}
      {isPending && (
        <Container flex height="full" justifyContent="center">
          <Loader theme="dark" size="medium" />
        </Container>
      )}

      {/* Show error state */}
      {isError && !isPending && (
        <StatusCard description={t('reloadPage')} headline={t('oopsSomethingWentWrongContracted')}>
          <Button iconElement={<Icon name="RefreshIcon" color="white" />} onClick={handleRefresh}>
            {t('refresh')}
          </Button>
        </StatusCard>
      )}

      {!isPending && !isError && <MerchantHeader />}

      {!isPending && !isError && !!merchantList?.length && <MerchantTable />}
    </>
  )
}
