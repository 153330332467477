import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getExchangeRate } from 'services/CurrencyService'

interface ExchangeRateQueryProps {
  fromCurrency: string
  toCurrency: string
  amount: string | number
  enabled: boolean
}

export const useExchangeRateQuery = ({
  fromCurrency,
  toCurrency,
  amount,
  enabled,
}: ExchangeRateQueryProps) =>
  useQuery({
    queryKey: reactQueryKeys.exchangeRate(fromCurrency, toCurrency, amount),
    queryFn: () => getExchangeRate(fromCurrency, toCurrency, amount),
    enabled,
    refetchOnReconnect: false,
  })
