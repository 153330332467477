import { customHeaders, requestApi } from 'api/api'
import { v4 as uuidv4 } from 'uuid'
import {
  PayoutsFeeEstimateResponse,
  PayoutsV2PostAuthRequest,
  PayoutsV2PostAuthResponse,
  PayoutsV2PreAuthRequest,
  PayoutsV2PreAuthResponse,
} from 'types/payoutsV2'

export const postPayoutsV2PreAuth = (
  data: PayoutsV2PreAuthRequest
): Promise<PayoutsV2PreAuthResponse> => {
  return requestApi({
    url: '/v2/fiat/pay/wallet',
    method: 'POST',
    data,
    headers: {
      ...customHeaders,
      'x-idempotency-key': uuidv4(),
    },
  })
}

export const postPayoutsPostAuthV2 = (
  data: PayoutsV2PostAuthRequest
): Promise<PayoutsV2PostAuthResponse> => {
  return requestApi({
    url: '/v2/fiat/pay/operation-authorizations',
    method: 'POST',
    data,
    headers: {
      'x-idempotency-key': uuidv4(),
    },
  })
}

export const getPayoutsFeeEstimate = (
  data: PayoutsV2PreAuthRequest
): Promise<PayoutsFeeEstimateResponse> => {
  return requestApi({
    url: '/v2/fiat/pay/estimate',
    method: 'POST',
    data,
  })
}
