import React from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { useFeatureFlags } from '@node-space/hooks'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import Icon from '@node-space/storybook-components/dist/Icon'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import Text from '@node-space/storybook-components/dist/Text'
import { Options } from 'types/BankAccountsDetails'
import { Currencies, WalletType } from 'types/types'
import { getLocalBankDetails } from 'utils/wallet'

interface LocalBankDetailsProps {
  options: Options
  wallet: WalletType
  currency: Currencies
  isSegregated: boolean
}

const LocalBankDetails = ({ options, wallet, currency, isSegregated }: LocalBankDetailsProps) => {
  const { t } = useTranslation()
  const { enableMultiplePaymentMethods } = useFeatureFlags()
  const accountDetails = getLocalBankDetails(
    options,
    wallet,
    currency,
    isSegregated,
    t,
    enableMultiplePaymentMethods
  )

  if (!Object.keys(accountDetails)?.length) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }

  return (
    <Box testid="local-bank-details">
      <SummaryDetail hasMargin={false} testid="local-bank-details-summary">
        {Object.keys(accountDetails)
          ?.filter(label => !!label)
          ?.map(label => {
            return (
              <SummaryRow
                key={label}
                label={label}
                renderDescription={
                  <Box flex justifyContent="between">
                    <Text size="sm" color="grey">
                      {accountDetails?.[label]}
                    </Text>
                    <CopyButton
                      copyText={String(accountDetails?.[label])}
                      confirmationMessage={t('copiedToClipboard')}
                      confirmationMessageAlignment="left"
                      confirmationMessageTheme="dark"
                    />
                  </Box>
                }
              ></SummaryRow>
            )
          })}
      </SummaryDetail>
      <Box paddingT={20} flex justifyContent="center">
        <CopyButton confirmationMessageTheme="dark" copyObject={accountDetails}>
          <Box flex direction="row" gapX={4}>
            <Text weight="medium" color={copyButtonIconColour}>
              {t('copyAllDetails')}
            </Text>
            <Icon name="CopyIcon" color={copyButtonIconColour} />
          </Box>
        </CopyButton>
      </Box>
    </Box>
  )
}

export default LocalBankDetails
