import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import {
  Box as BvnkNetworkContainer,
  Box as CompanyInfoContainer,
  Box as Container,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import Text from '@node-space/storybook-components/dist/Text'
import { NetworkConnectionViewTypes } from 'constants/bvnkNetwork'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useAccountPreferencesQuery } from 'hooks/queries'
import { useToastContext } from 'hooks/useToastContext'
import EditCompanyProfile from 'pages/CompanyProfile/components/EditCompanyProfile'
import { NetworkConnectionViewType } from 'types/bvnkNetwork'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { bvnkNetworkConnectionsAtom } from './atoms/bvnkNetworkAtoms'
import BvnkNetworkTabs from './components/BvnkNetworkTabs'
import CompanyInfo from './components/CompanyInfo'
import { DiscoverNetworkMembers } from './components/DiscoverNetworkMembers'

interface CompanyDetails {
  id: number
  name: string
  description: string
  logo: string
}

export const BvnkNetwork = () => {
  const bvnkNetworkConnections = useAtomValue(bvnkNetworkConnectionsAtom)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentAccount } = useAccountsContext()
  const addToast = useToastContext()

  const { data: accountPreferences = {}, isPending: isLoadingAccountPreferences } =
    useAccountPreferencesQuery()

  const { tab } = queryString.parse(window.location.search) as { tab?: NetworkConnectionViewType }

  const [activeTab, setActiveTab] = useState(tab || NetworkConnectionViewTypes.CONNECTIONS_VIEW)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDiscoverMembersModalOpen, setIsDiscoverMembersModalOpen] = useState(false)
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>({
    id: currentAccount?.id,
    name: currentAccount?.name,
    description: '',
    logo: '',
  })

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.BVNK_NETWORK, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  useEffect(() => {
    const details = {
      id: currentAccount?.id,
      name: currentAccount?.name,
      description: accountPreferences?.companyDescription,
      logo: '',
    }
    setCompanyDetails(details)
  }, [accountPreferences?.companyDescription, currentAccount?.name])

  const onEditClickHandler = () => {
    setIsEditModalOpen(true)
  }

  const onEditClose = () => {
    setIsEditModalOpen(false)
  }

  const onEditSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['accountPreferences'] })
    setIsEditModalOpen(false)
    addToast({ title: t('bvnkNetwork.detailsUpdatedSuccessfully'), state: 'success' })
  }

  const onEditError = () => {
    addToast({ title: t('bvnkNetwork.detailsUpdatedError'), state: 'error' })
  }

  const handleDiscoverMembersModalClose = () => {
    setIsDiscoverMembersModalOpen(false)
  }

  const onDiscoverMemberClickHandler = () => {
    setIsDiscoverMembersModalOpen(true)
  }

  return (
    <>
      <PageHeader
        description={
          <Text size="sm" color="grey">
            {t('bvnkNetwork.pageDescription')}
            {/* TODO: Add once FAQ has been added */}
            {/* <TextAction onClick={() => null} text={t('bvnkNetwork.readFaq')} /> */}
          </Text>
        }
        renderRightSection={
          bvnkNetworkConnections?.connected?.length && (
            <Button
              secondary
              onClick={onDiscoverMemberClickHandler}
              name={t('bvnkNetwork.discoverMembers')}
              iconElement={<Icon name="SearchIcon" size="default" color="primary-500" />}
            >
              {t('bvnkNetwork.discoverMembers')}
            </Button>
          )
        }
      />
      <Container flex className="md:flex-row" direction="col" gapX={24} gapY={24} width="full">
        <BvnkNetworkContainer flex direction="col" width="full" className="md:w-2/3 break-words">
          <BvnkNetworkTabs queryTab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />
        </BvnkNetworkContainer>

        {companyDetails && (
          <CompanyInfoContainer flex className="md:w-1/3 xxs:pt-4 xxs:flex-row " direction="col">
            <CompanyInfo
              companyDescription={companyDetails?.description}
              companyName={companyDetails?.name}
              isLoading={isLoadingAccountPreferences}
              onEditClickHandler={onEditClickHandler}
            />
          </CompanyInfoContainer>
        )}

        <EditCompanyProfile
          description={companyDetails?.description}
          isOpen={isEditModalOpen}
          key={companyDetails?.description}
          logo={companyDetails?.logo}
          onModalClose={onEditClose}
          onEditError={onEditError}
          onEditSuccess={onEditSuccess}
        />

        <DiscoverNetworkMembers
          isModalOpen={isDiscoverMembersModalOpen}
          handleModalClose={handleDiscoverMembersModalClose}
        />
      </Container>
    </>
  )
}
