import React from 'react'
import { Radio } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import { useMediaQuery } from '@mantine/hooks'
import { useProtocolContext } from 'hooks/context'

export const ProtocolsRadioButtons = () => {
  const { protocols, currentProtocol, setProtocol } = useProtocolContext()
  const isMobileScreen = useMediaQuery('(min-width: 640px)')

  const getRadioOptions = () => {
    return protocols.map(protocol => {
      return {
        label: protocol?.code,
        value: protocol?.address,
      }
    })
  }
  const handleChange = (value: string, label: string) => {
    setProtocol({
      code: label,
      address: value,
    })
  }
  if (!protocols.length) {
    return <></>
  }
  return (
    <Box width="full" paddingB={28} data-testid="protocol-radio-button-group" className="sm:w-8">
      <Radio
        testid="protocol"
        horizontal={isMobileScreen}
        onChange={handleChange}
        borderless={false}
        options={getRadioOptions()}
        value={currentProtocol.address}
      />
    </Box>
  )
}
