import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postWithdraw } from 'services/WalletsService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useWithdrawMutation = () => {
  return useMutation({
    mutationFn: postWithdraw,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useWithdrawMutation', error, data)
      }
    },
  })
}
