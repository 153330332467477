import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Select } from '@node-space/storybook-components/dist/Select'
import { SelectGroup } from '@node-space/storybook-components/dist/SelectGroup'
import Text from '@node-space/storybook-components/dist/Text'
import { AddCustomerFormErrors, AddCustomerPayload, TransformedOption } from 'types/customers'
import { findLabelByValue } from 'utils/utils'

interface BusinessCustomerFieldsProps {
  businessName?: string
  businessDescription?: string
  businessRegistrationNumber?: string
  businessTaxResidency?: string
  countries?: { label: string; value: string }[]
  isCountriesLoading?: boolean
  businessMonthlyVolume?: string
  monthlyVolumesOptions?: { label: string; value: string }[]
  isFetchingMonthlyVolumes?: boolean
  industryOptions?: TransformedOption[]
  isFetchingIndustries?: boolean
  addressLine1?: string
  addressLine2?: string
  city?: string
  postCode?: string
  countryCode?: string
  industry?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  nationality?: string
  errors?: AddCustomerFormErrors
  register?: UseFormRegister<AddCustomerPayload>
  setValue?: (name: string, value: string) => void
}

export const BusinessCustomerFields = ({
  businessName,
  businessDescription,
  businessRegistrationNumber,
  businessTaxResidency,
  countries,
  isCountriesLoading,
  businessMonthlyVolume,
  monthlyVolumesOptions,
  isFetchingMonthlyVolumes,
  industryOptions,
  isFetchingIndustries,
  addressLine1,
  addressLine2,
  city,
  postCode,
  countryCode,
  industry,
  firstName,
  lastName,
  dateOfBirth,
  nationality,
  errors,
  register,
  setValue,
}: BusinessCustomerFieldsProps) => {
  const { t } = useTranslation()

  const handleCountryTextResidencySelect = (countryCode: string) => {
    const taxResidencyName = countries.find(country => country?.value === countryCode)?.label
    setValue('businessTaxResidency', countryCode)
    setValue('businessTaxResidencyName', taxResidencyName)
  }

  const handleCountrySelect = (countryCode: string) => {
    const countryName = countries.find(country => country?.value === countryCode)?.label
    setValue('countryCode', countryCode)
    setValue('countryName', countryName)
  }

  const handleIndustryChange = value => {
    const industryName = findLabelByValue(value, industryOptions)
    setValue('industry', value)
    setValue('industryName', industryName)
  }

  const handleNationalitySelect = (countryCode: string) => {
    const nationalityName = countries.find(country => country?.value === countryCode)?.label
    setValue('nationality', countryCode)
    setValue('nationalityName', nationalityName)
  }

  const handleMonthlyEstimateVolumesSelect = (value: string) => {
    const businessMonthlyVolumeName = monthlyVolumesOptions.find(
      monthlyVolume => monthlyVolume?.value === value
    )?.label
    setValue('businessMonthlyVolume', value)
    setValue('businessMonthlyVolumeName', businessMonthlyVolumeName)
  }

  const filterOption = ({ label, value }, input) => {
    const search = input?.toLowerCase()
    const OptionLabel = label?.toLowerCase()

    if (OptionLabel?.includes(search)) return true

    const groupOptions = industryOptions?.filter(group =>
      group?.label?.toLowerCase()?.includes(search)
    )

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        const option = groupOption?.options?.find(opt => opt?.value === value)
        if (option) {
          return true
        }
      }
    }
    return false
  }

  return (
    <Box flex flexCol gapY={16}>
      <Box>
        <Input
          {...register('businessName')}
          label={t('customers.businessName')}
          inputMode="text"
          name="businessName"
          testid="business-name"
          errorText={errors?.businessName?.message}
          error={!!errors?.businessName}
          value={businessName}
        />
      </Box>
      <Box>
        <SelectGroup
          label={t('customers.industry')}
          {...register('industry')}
          placeholder={t('customers.industryPlaceholder')}
          isSearchable
          iconLeft={<Icon name="SearchIcon" />}
          customFilter={filterOption}
          options={industryOptions}
          disabled={isFetchingIndustries}
          onChange={handleIndustryChange}
          errorText={errors?.industry?.message}
          error={!!errors?.industry}
          value={industry}
        />
      </Box>
      <Box>
        <Input
          {...register('businessDescription')}
          label={t('customers.businessDescription')}
          inputMode="text"
          name="businessDescription"
          testid="business-description"
          errorText={errors?.businessDescription?.message}
          error={!!errors?.businessDescription}
          value={businessDescription}
        />
      </Box>
      <Box flex direction="row" gapX={16}>
        <Box flexSize="auto" width="full">
          <Input
            {...register('businessRegistrationNumber')}
            label={t('customers.businessRegistrationNumber')}
            inputMode="text"
            name="businessRegistrationNumber"
            testid="business-registration-number"
            errorText={errors?.businessRegistrationNumber?.message}
            error={!!errors?.businessRegistrationNumber}
            value={businessRegistrationNumber}
          />
        </Box>
        <Box flexSize="auto" width="full">
          <Select
            {...register('businessTaxResidency')}
            label={t('customers.businessTaxResidency')}
            isSearchable
            options={countries}
            errorText={errors?.businessTaxResidency?.message}
            error={!!errors?.businessTaxResidency}
            loading={isCountriesLoading}
            onChange={handleCountryTextResidencySelect}
            value={businessTaxResidency}
          />
        </Box>
      </Box>
      <Box>
        <Select
          {...register('businessMonthlyVolume')}
          label={t('customers.businessMonthlyVolume')}
          isSearchable
          options={monthlyVolumesOptions}
          error={!!errors?.businessMonthlyVolume}
          errorText={errors?.businessMonthlyVolume?.message}
          loading={isFetchingMonthlyVolumes}
          onChange={handleMonthlyEstimateVolumesSelect}
          value={businessMonthlyVolume}
        />
      </Box>
      <Text size="lg" weight="medium">
        {t('customers.registeredAddress')}
      </Text>
      <Box>
        <Input
          {...register('addressLine1')}
          label={t('addressLine1')}
          inputMode="text"
          name="addressLine1"
          testid="business-address-line-1"
          errorText={errors?.addressLine1?.message}
          error={!!errors?.addressLine1}
          value={addressLine1}
        />
      </Box>
      <Box>
        <Input
          {...register('addressLine2')}
          label={t('addressLine2')}
          inputMode="text"
          name="addressLine2"
          testid="business-address-line-2"
          errorText={errors?.addressLine2?.message}
          error={!!errors?.addressLine2}
          value={addressLine2}
        />
      </Box>
      <Box flex direction="row" gapX={16}>
        <Box flexSize="auto" width="full">
          <Input
            {...register('city')}
            label={t('customers.address.city')}
            inputMode="text"
            name="city"
            testid="customer-city"
            errorText={errors?.city?.message}
            error={!!errors?.city}
            value={city}
          />
        </Box>
        <Box flexSize="auto" width="full">
          <Input
            {...register('postCode')}
            label={t('customers.address.postCode')}
            inputMode="text"
            name="postCode"
            testid="customer-postCode"
            errorText={errors?.postCode?.message}
            error={!!errors?.postCode}
            value={postCode}
          />
        </Box>
      </Box>
      <Box flexSize="fill">
        <Select
          {...register('countryCode')}
          label={t('customers.address.country')}
          isSearchable
          options={countries}
          errorText={errors?.countryCode?.message}
          error={!!errors?.countryCode}
          placeholder={t('searchOrSelectACountry')}
          loading={isCountriesLoading}
          onChange={handleCountrySelect}
          value={countryCode}
        />
      </Box>
      <Text size="xl" weight="medium">
        {t('customers.representative.title')}
      </Text>
      <Box flex direction="row" gapX={16}>
        <Box flexSize="auto" width="full">
          <Input
            {...register('firstName')}
            label={t('customers.representative.firstName')}
            inputMode="text"
            name="firstName"
            testid="customer-firstName"
            errorText={errors?.firstName?.message}
            error={!!errors?.firstName}
            value={firstName}
          />
        </Box>
        <Box flexSize="auto" width="full">
          <Input
            {...register('lastName')}
            label={t('customers.representative.lastName')}
            inputMode="text"
            name="lastName"
            testid="customer-lastName"
            errorText={errors?.lastName?.message}
            error={!!errors?.lastName}
            value={lastName}
          />
        </Box>
      </Box>
      <Box flex direction="row" gapX={16}>
        <Box flexSize="auto" width="full">
          <Input
            {...register('dateOfBirth')}
            label={t('customers.representative.dateOfBirth')}
            inputMode="text"
            name="dateOfBirth"
            testid="customer-dateOfBirth"
            errorText={errors?.dateOfBirth?.message}
            error={!!errors?.dateOfBirth}
            value={dateOfBirth}
          />
        </Box>
        <Box flexSize="auto" width="full">
          <Select
            {...register('nationality')}
            label={t('customers.representative.nationality')}
            isSearchable
            options={countries}
            errorText={errors?.nationality?.message}
            error={!!errors?.nationality}
            loading={isCountriesLoading}
            onChange={handleNationalitySelect}
            value={nationality}
          />
        </Box>
      </Box>
    </Box>
  )
}
