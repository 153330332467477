import React, { useState, useEffect } from 'react'
import { Box as Wrapper, Paginator } from '@node-space/storybook-components'
import { PaymentList } from 'types/payments'
import { Paging } from 'types/types'

export type UnknownIfEmpty<T extends object> = keyof T extends never ? unknown : T

export type RootStateOrUnknown = UnknownIfEmpty<{}>

interface TablePaginationProps {
  total: number
  pagingMax?: number
  paging?: Paging
  setPaymentsList?: React.Dispatch<React.SetStateAction<PaymentList>>
  executor?: (val: RootStateOrUnknown) => void
  disableLeftDropdown?: boolean
}

const TablePagination = ({
  total,
  pagingMax,
  paging,
  setPaymentsList,
  executor,
  disableLeftDropdown = false,
}: TablePaginationProps) => {
  const [page, setPage] = useState(0)
  const [isClicked, setIsClicked] = useState(false)
  const [pageListOptionsValue, setPageListOtionValue] = useState('')

  const maxPage = (!!total && Math.ceil(total / pagingMax) - 1) || 0

  const pageListOptions = disableLeftDropdown ? null : [{ label: '20', value: '20' }]

  useEffect(() => {
    if (isClicked) {
      onPageChange()
    }
  }, [page, isClicked])

  const onPageChange = () => {
    const offset = page * pagingMax
    executor({
      paging: {
        ...paging,
        offset,
        max: pagingMax,
      },
    })
  }

  const onClickPrev = () => {
    if (page !== 0) {
      setIsClicked(true)
      setPage(page - 1)
    }
  }

  const onClickNext = () => {
    if (page < maxPage) {
      setIsClicked(true)
      setPage(page + 1)
    }
  }

  const onPageListDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    if (paging.max !== Number(value)) {
      setPageListOtionValue(value)

      const updatedPaging = { ...paging, offset: 0, max: Number(value) }

      setPaymentsList(prev => ({
        ...prev,
        paging: updatedPaging,
        pagingMax: Number(value),
      }))
      executor({ paging: updatedPaging })
      setPage(0)
    }
  }

  return (
    <>
      {!!total && (
        <Wrapper testid="pagination-wrapper" className="mt-6 mb-10">
          <Paginator
            data-testid="pagination-group-container"
            totalPages={total}
            range={{
              end: Math.min(page * pagingMax + pagingMax, total),
              start: page * pagingMax + 1 || 1,
            }}
            onClickBack={onClickPrev}
            onClickForward={onClickNext}
            results={pageListOptions}
            onChangeDropDown={onPageListDropdownChange}
            resultValue={pageListOptionsValue}
          />
        </Wrapper>
      )}
    </>
  )
}

export default TablePagination
