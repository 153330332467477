import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@node-space/hooks'
import Box, { Box as Container } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import Modal from '@node-space/storybook-components/dist/Modal'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import Search from '@node-space/storybook-components/dist/Search'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import NoSearchResults from 'components/NoSearchResults/NoSearchResults'
import TablePagination from 'components/PageableTablePagination'
import { Description } from 'components/text/Description'
import { useCustomersQuery } from 'hooks/queries/Customers/useCustomersQuery'
import { usePaginationPagingPersist } from 'hooks/usePaginationPagingPersist'
import { PaginationPaging } from 'types/pagination'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { AddCustomer } from './components/AddCustomer'
import CustomersEmptyState from './components/CustomerEmptyState'
import CustomersList from './components/CustomersList'
import CustomersListSkeletonLoader from './components/CustomersListSkeletonLoader'

export const Customers = () => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchQuery, 1000)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)

  const {
    refetch,
    data: { content: customerList, pagination } = {},
    isPending,
    isFetching,
    error,
  } = useCustomersQuery(debouncedSearchTerm, 10, page)

  const { paging, showPagination } = usePaginationPagingPersist(pagination)

  useEffect(() => {
    refetch()
  }, [debouncedSearchTerm])

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CUSTOMERS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const onAddNewCustomer = () => {
    setIsAddModalOpen(true)
  }

  const handleCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false)
  }, [setIsAddModalOpen])

  const handleOnSuccessCloseModal = useCallback(() => {
    setIsAddModalOpen(false)
    track.Amp.track(AmplitudeEvent.ADD_NEW_CUSTOMER, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
    })
  }, [setIsAddModalOpen])

  if (error) {
    return (
      <StatusCard status="error" headline={t('defaultError')}>
        <Description>
          {`${t('requestErrors.comeBackLater')} `}
          <TextAction isWindowReload text={t('requestErrors.refreshPage')} />.
        </Description>
      </StatusCard>
    )
  }

  if (!isFetching && !customerList?.length && !debouncedSearchTerm) {
    return (
      <CustomersEmptyState
        onAddNewCustomer={onAddNewCustomer}
        isAddModalOpen={isAddModalOpen}
        handleCloseAddModal={handleCloseAddModal}
        handleOnSuccessCloseModal={handleOnSuccessCloseModal}
      />
    )
  }

  return (
    <>
      <PageHeader
        description={
          <Text size="sm" color="grey">
            {t('customers.description')}
          </Text>
        }
        renderRightSection={
          <Button
            onClick={onAddNewCustomer}
            name={t('customers.addNewCustomer')}
            iconElement={<Icon name="PlusIcon" size="default" color="white" />}
          >
            {t('customers.addNewCustomer')}
          </Button>
        }
      />
      <Box paddingB={16} width="full">
        <Search
          searchTerm={searchQuery}
          size="sm"
          testid="customer-search-field"
          placeholderText={t('customers.searchPlaceholder')}
          onChange={event => setSearchQuery(event.target.value)}
        />
      </Box>
      {debouncedSearchTerm && !isFetching && !customerList?.length && (
        <NoSearchResults
          clearSearchCriteria={() => {
            setSearchQuery('')
          }}
        />
      )}
      {!isFetching ? (
        <Container flex className="md:flex-row" direction="col" gapX={24} width="full">
          <Box className="w-full">
            <CustomersList customers={customerList} />
          </Box>
        </Container>
      ) : (
        <CustomersListSkeletonLoader />
      )}
      {!!paging && !showPagination && (
        <Container className={`${isPending ? 'opacity-25' : ''}`}>
          <TablePagination
            disableLeftDropdown
            total={paging?.total}
            pagingMax={paging?.max}
            onPagination={({ page }: PaginationPaging) => setPage(page)}
          />
        </Container>
      )}
      <Modal allowOverFlow visible={isAddModalOpen} closeModal={() => false}>
        <AddCustomer
          handleCloseModal={handleCloseAddModal}
          handleOnSuccessCloseModal={handleOnSuccessCloseModal}
        />
      </Modal>
    </>
  )
}
