import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { InputLabel } from '@node-space/storybook-components'
import Box, { Box as Container } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Input from '@node-space/storybook-components/dist/Input'
import Loader from '@node-space/storybook-components/dist/Loader'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { logSentryError } from '@node-space/utils'
import { CriteriaKeys } from 'components/CSAT/types'
import { usePutPaymentCaseMutation } from 'hooks/mutations/usePutPaymentCaseMutation'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PaymentCaseUpdate } from 'types/payments'
import useCSATSurvey from '../hooks/useCSATSurvey'

interface CustomerIdInputProps {
  customerId?: string
  caseId: string
}

export const CustomerIdInput = ({ customerId, caseId }: CustomerIdInputProps) => {
  const { enableCsatSurveyCasePortal, getIsCriteriaCompleted, completeCSATCriteria } =
    useCSATSurvey()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const permissions = useMappedRoles().paymentSupport
  const canEdit = permissions?.canEdit
  const [customerIdInput, setCustomerIdInput] = useState('')
  const { mutate, isPending } = usePutPaymentCaseMutation()

  const hasEnteredCustomeridCriteriaCompleted = getIsCriteriaCompleted(
    CriteriaKeys.HAS_ENTERED_CUSTOMER_ID
  )

  const isEditingCustomerId = customerId === customerIdInput

  useEffect(() => {
    setCustomerIdInput(customerId ?? '')
  }, [customerId])

  const handleOnClick = async () => {
    const customerIdPayload: PaymentCaseUpdate = {
      customer: { customerId: customerIdInput },
      caseId,
    }

    mutate(customerIdPayload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: reactQueryKeys.paymentSupportCases() })
        addToast({ title: t('paymentSupport.successCustomerIdMessage'), state: 'success' })

        !hasEnteredCustomeridCriteriaCompleted &&
          enableCsatSurveyCasePortal &&
          handleCSATSurveyUpdate()
      },
      onError: error => {
        setCustomerIdInput(customerId ?? '')
        addToast({ title: t('paymentSupport.errorCustomerIdMessage'), state: 'error' })
        logSentryError('Error: could not update payment case customer ID', error)
      },
    })
  }

  const handleCSATSurveyUpdate = () => {
    completeCSATCriteria(CriteriaKeys.HAS_ENTERED_CUSTOMER_ID)
  }

  return (
    <Container width="full" flex paddingY={12} direction="col">
      <Box flex direction="row" gapX={4} paddingB={4}>
        <InputLabel forId="customer-id">{t('paymentSupport.customerId')}</InputLabel>
        <Tooltip position="right" hasMaxWidth bodyContent={t('paymentSupport.customerIdToolTip')}>
          <Icon name="InfoCircleIcon" />
        </Tooltip>
      </Box>
      <Box flex>
        <Input
          testid="customer-id"
          inputMode="text"
          placeholder={t('paymentSupport.customerIdPlaceholder')}
          name="customerId"
          value={customerIdInput}
          onChange={e => setCustomerIdInput(e.target.value)}
          disabled={!canEdit || isPending}
        />
        <Box flex className="-ml-8 z-10">
          <Button
            iconElement={
              isPending ? (
                <Loader size="small" />
              ) : (
                <Tooltip
                  position="top"
                  bodyContent={t(
                    isEditingCustomerId
                      ? 'paymentSupport.customerIdEdit'
                      : 'paymentSupport.customerIdSave'
                  )}
                >
                  <Icon color="primary-500" name={isEditingCustomerId ? 'EditIcon' : 'SaveIcon'} />
                </Tooltip>
              )
            }
            noStyling
            onClick={handleOnClick}
            disabled={!canEdit || isPending || !customerIdInput?.trim() || isEditingCustomerId}
          />
        </Box>
      </Box>
    </Container>
  )
}
