import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Accordion } from '@node-space/storybook-components'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import {
  AlignActions,
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import Text from '@node-space/storybook-components/dist/Text'
import Loader from 'components/spinners/LoadingAnimationDark'
import { BankAccountsDetails, DepositBankAccount } from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { formatString, roundNumberWithCommas } from 'utils/utils'
import { getPaymentTitle } from 'utils/wallet'
import SingleDepositDetails from './components/SingleDepositDetails'
import { DepositActionProps } from './Deposit'
import DepositAccountDetailsBoard from './DepositAccountDetailsBoard'

const DepositForm = ({
  dismissAction,
  setStep,
  form,
  wallets,
  loading,
  accounts,
  depositLimits,
  inactiveSegregatedAccount,
  isError,
}: DepositActionProps) => {
  const { t } = useTranslation()
  const { enableFiatCurrencyFilter, enableMultiplePaymentMethods } = useFeatureFlags()
  const formValues = form.watch()
  const supportedDepositCurrencies = enableFiatCurrencyFilter?.supportedDepositCurrencies

  const accountsV1 = accounts as BankAccountsDetails
  const accountsV2 = accounts as DepositBankAccount[]

  const [copiedValue, setCopiedValue] = useState('')
  const [copiedTitle, setCopiedTitle] = useState('')
  const [openAccordion, setOpenAccordion] = useState('')

  const handleSetCopiedTitle = (value: string) => {
    track.Amp.track(AmplitudeEvent.DEPOSIT_COPY, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      copied: value.toLowerCase() || '',
    })
    setCopiedTitle(value)
  }

  const supportedWallets = wallets.filter(
    wallet =>
      wallet.currency?.code &&
      wallet.currency?.fiat &&
      wallet.supportsDeposits &&
      ((!supportedDepositCurrencies && wallet?.supportsDeposits) ||
        supportedDepositCurrencies?.includes(wallet?.currency?.code?.toUpperCase()))
  )

  const selectedWallet =
    formValues?.walletId && supportedWallets.find(x => x?.id.toString() === formValues?.walletId)

  const actionsAggregated: ButtonProps[] = [
    { children: t('cancel'), secondary: true, onClick: dismissAction, loading },
    enableMultiplePaymentMethods
      ? { children: t('done'), onClick: () => dismissAction() }
      : {
          children: t('continue'),
          testid: t('continue'),
          disabled: !selectedWallet,
          onClick: () => setStep('instructions'),
          loading,
        },
  ]
  const actionsSegregated: ButtonProps[] = [
    { children: t('done'), onClick: dismissAction, loading },
  ]

  track.Amp.track(AmplitudeEvent.DEPOSIT_INITIATE, {
    category: AmplitudeEventCategory.MERCHANT_PORTAL,
    action: AmplitudeEventAction.VIEW,
    currency: selectedWallet?.currency?.code?.toLowerCase(),
    walletId: selectedWallet?.id,
  })

  const getAccordianItems = (bankAccounts: DepositBankAccount[]) => {
    const localPaymentMethods = []
    const internationalPaymentMethods = []

    bankAccounts?.forEach((account, index) => {
      const accordionItem = {
        name: `${index}-${account?.accountNumber}`,
        title: getPaymentTitle(account, t),
        children: (
          <SingleDepositDetails
            account={account}
            wallet={selectedWallet}
            walletLimits={depositLimits}
            copiedValue={copiedValue}
            setCopiedValue={setCopiedValue}
            handleSetCopiedTitle={handleSetCopiedTitle}
          />
        ),
      }
      account?.isSwift || account?.accountNumberFormat === BankTransferType.SWIFT
        ? internationalPaymentMethods.push(accordionItem)
        : localPaymentMethods.push(accordionItem)
    })
    return [...localPaymentMethods, ...internationalPaymentMethods]
  }
  const isWalletSelectedAndNotLoading = !loading && !!selectedWallet

  const isV1GenericError =
    (isWalletSelectedAndNotLoading &&
      !enableMultiplePaymentMethods &&
      accountsV1?.segregatedPaymentMethod?.currency === null &&
      !inactiveSegregatedAccount &&
      !loading) ||
    isError

  const isV2GenericError =
    (isWalletSelectedAndNotLoading &&
      enableMultiplePaymentMethods &&
      !accountsV2?.length &&
      !inactiveSegregatedAccount &&
      !loading) ||
    isError

  const isInactiveSegragatedWalletError =
    isWalletSelectedAndNotLoading &&
    inactiveSegregatedAccount &&
    !enableMultiplePaymentMethods &&
    inactiveSegregatedAccount &&
    !loading

  return (
    <Box data-testid="depositForm">
      <ModalNavBar title={t('deposit')} onClose={dismissAction} />
      <ModalScrollable>
        <Box padding={24}>
          {/* wallet selector */}
          <DoubleLabelSelect
            loading={!selectedWallet}
            iconsUrl={process.env.ICONS_URL}
            name={t('selectWallet')}
            label={t('selectWallet')}
            showOverflow
            menuMaxHeight={250}
            placeholder={
              !wallets
                ? t('loadingEllipses')
                : wallets && wallets?.length === 0
                  ? t('noWallets')
                  : t('searchOrSelectAWallet')
            }
            options={supportedWallets?.map(wallet => ({
              icon: wallet?.currency?.code?.toLowerCase(),
              value: wallet?.id?.toString(),
              label: wallet?.description,
              secondLabel: formatString(
                t('balanceWithCurrency'),
                roundNumberWithCommas(wallet?.balance),
                wallet?.currency?.code
              ),
            }))}
            value={selectedWallet?.id?.toString()}
            isSearchable={true}
            iconLeft={!selectedWallet && <Icon name="SearchIcon" />}
            onChange={(value: string) =>
              form.reset({ walletId: value }, { keepDefaultValues: true })
            }
          />

          {loading && !isWalletSelectedAndNotLoading && (
            <Box flex justifyContent="center" paddingT={32}>
              {Loader}
            </Box>
          )}

          {(isV1GenericError || isV2GenericError || isInactiveSegragatedWalletError) && !loading ? (
            <Box flex width={'full'} paddingT={24}>
              {isInactiveSegragatedWalletError ? (
                <Callout
                  state="warning"
                  message={t('inactiveSegragatedWalletMessage', {
                    currencyName: selectedWallet.currency?.code,
                  })}
                />
              ) : (
                <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
              )}
            </Box>
          ) : (
            <>
              {isWalletSelectedAndNotLoading && (
                <>
                  {enableMultiplePaymentMethods && !!accountsV2?.length && (
                    <Box paddingT={20}>
                      {accountsV2?.length > 1 ? (
                        <Accordion
                          isSlim={true}
                          items={getAccordianItems(accountsV2)}
                          bodyClassOverrides="w-full pr-[15px]"
                          borderless={true}
                          getOpenName={name => {
                            setOpenAccordion(name)
                          }}
                          defaultOpen={openAccordion}
                        />
                      ) : (
                        <Box paddingX={20}>
                          <SingleDepositDetails
                            account={accountsV2?.[0]}
                            wallet={selectedWallet}
                            walletLimits={depositLimits}
                            copiedValue={copiedValue}
                            setCopiedValue={setCopiedValue}
                            handleSetCopiedTitle={handleSetCopiedTitle}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {!enableMultiplePaymentMethods &&
                    accountsV1?.isAggregatedWallet &&
                    !!accountsV1?.aggregatedPaymentMethods.length && (
                      <Box paddingT={16}>
                        <Radio
                          label={t('selectPartnerBank')}
                          options={(!!accountsV1?.aggregatedPaymentMethods.length
                            ? accountsV1.aggregatedPaymentMethods
                            : []
                          ).map(paymentMethod => ({
                            value: paymentMethod.id?.toString(),
                            label: paymentMethod.description,
                          }))}
                          value={formValues.accountId}
                          onChange={value => form.setValue('accountId', value)}
                        />
                      </Box>
                    )}

                  {!enableMultiplePaymentMethods &&
                    accountsV1?.segregatedPaymentMethod?.currency && (
                      <Box
                        flex
                        className="flex-1"
                        direction="col"
                        paddingB={24}
                        testid="deposit-form-details"
                        gapY={8}
                      >
                        <Text size="base" weight="medium" className="text-gray-10 pb-1">
                          {t('depositDetails')}
                        </Text>
                        <DepositAccountDetailsBoard
                          wallet={selectedWallet}
                          currency={accountsV1?.segregatedPaymentMethod?.currency}
                          options={accountsV1?.segregatedPaymentMethod?.options}
                          isSegregated
                        />
                        {/* reference number warning */}
                        <Box className="mt-2">
                          <Callout
                            state="warning"
                            message={t('depositReferenceMessageSegregated')}
                          />
                        </Box>
                      </Box>
                    )}
                </>
              )}
            </>
          )}
        </Box>
      </ModalScrollable>

      {/* actions */}
      {!loading && accountsV1?.isAggregatedWallet && selectedWallet ? (
        <ModalActions actions={actionsAggregated} totalSteps={2} />
      ) : (
        <ModalActions actions={actionsSegregated} alignActions={AlignActions.CENTER} />
      )}
    </Box>
  )
}

export default DepositForm
