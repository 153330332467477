import React from 'react'

interface TransactionAmountProps {
  amount: string
}

export const TransactionAmount = ({ amount }: TransactionAmountProps) => {
  return (
    <div>
      <p className="text-right pr-40">{amount}</p>
    </div>
  )
}
