import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as SummaryContainer } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { NotificationCard } from '@node-space/storybook-components/dist/NotificationCard'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { logSentryError, numberWithCommas } from '@node-space/utils'
import { usePostConvertQuoteMutation } from 'hooks/mutations/usePostConvertQuoteMutation'
import { usePutAcceptQuoteMutation } from 'hooks/mutations/usePutAcceptQuoteMutation'
import { BaseErrorResponse } from 'types/beneficiaries'
import { postConvert } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { Amp } from 'utils/tracker'
import { floorWithPrecision, formatString, prefixString } from 'utils/utils'
import { ConvertActionProps, ConvertFormType } from './Convert'

let interval
let expiry

const ConvertConfirm = ({
  dismissAction,
  setStep,
  form,
  wallets,
  setQuote,
  quote,
  convertCurrency,
  shouldUseMax,
  shouldUseMin,
}: ConvertActionProps) => {
  const { t } = useTranslation()
  const [expiryInSeconds, setExpiryInSeconds] = useState<number>()
  const formValues = form.watch()

  const {
    mutate: acceptQuote,
    isPending: isAcceptingQuote,
    isError: isAcceptingQuoteError,
  } = usePutAcceptQuoteMutation()
  const { mutate: convertQuote, isPending: isRefreshingQuote } = usePostConvertQuoteMutation()

  useEffect(() => {
    startExpiryTimer()
    return () => {
      stopExpiryTimer()
    }
  }, [])

  const actions: ButtonProps[] = [
    {
      children: t('convertNow'),
      ...((isAcceptingQuote || isRefreshingQuote) && { iconElement: <Loader /> }),
      disabled: isAcceptingQuote || isRefreshingQuote,
      onClick: () => convert(),
      testid: 'Convert now',
    },
  ]

  // only show cancel button if not loading
  !isAcceptingQuote &&
    actions.unshift({
      children: t('cancel'),
      secondary: true,
      onClick: () => dismissAction(),
    })

  const fromWallet =
    formValues?.walletId && wallets?.find(x => x.id?.toString() === formValues.walletId)
  const toWallet =
    formValues?.toWalletId && wallets?.find(x => x.id?.toString() === formValues.toWalletId)
  const convertSameCurrency = fromWallet && convertCurrency === fromWallet?.currency?.code
  const currencyCode = toWallet ? toWallet?.currency?.code : formValues?.toWalletId
  const minutes = expiryInSeconds !== undefined && Math.floor(expiryInSeconds / 60)
  const seconds = expiryInSeconds !== undefined && expiryInSeconds - minutes * 60

  const secondsFormatted =
    expiryInSeconds !== undefined &&
    prefixString(minutes, '0', 2) + ':' + prefixString(seconds, '0', 2)

  useEffect(() => {
    Amp.track(AmplitudeEvent.CONVERT_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currencyFrom: quote?.from,
      currencyTo: quote?.to,
      amountFrom: quote?.amountIn,
      amountOut: quote?.amountOut,
      walletFrom: fromWallet?.id,
      walletTo: toWallet?.id,
      quoteId: quote?.uuid,
    })
  }, [])

  /**
   * Handle quote expiry
   */
  const startExpiryTimer = (time?: number) => {
    const totalExpiry =
      time ??
      Math.round((new Date(quote?.acceptanceExpiryDate).getTime() - new Date().getTime()) / 1000)
    expiry = totalExpiry

    setExpiryInSeconds(expiry)

    interval = setInterval(() => {
      expiry = expiry - 1
      if (expiry <= -1) {
        if (!isAcceptingQuote) {
          refreshQuote()
        }
      } else {
        setExpiryInSeconds(expiry)
      }
    }, 1000)
  }

  const stopExpiryTimer = () => {
    interval && clearInterval(interval)
    expiry = null
  }

  /**
   * Fetch new quote
   */
  const refreshQuote = () => {
    stopExpiryTimer()

    const data: postConvert = {
      from: fromWallet?.currency?.code,
      to: currencyCode,
      fromWallet: fromWallet?.id,
      useMaximum: shouldUseMax,
      useMinimum: shouldUseMin,
      ...(formValues?.reference && { reference: formValues?.reference }),
    }
    if (toWallet) {
      data.toWallet = toWallet.id
    }

    if (convertSameCurrency) {
      data.amountIn = formValues?.amount?.toString()
    } else {
      data.amountOut = formValues?.amount?.toString()
    }

    convertQuote(data, {
      onSuccess: response => {
        setQuote(response)

        Amp.track(AmplitudeEvent.CONVERT_REFRESH, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.REFRESH,
          currencyFrom: quote?.from,
          currencyTo: quote?.to,
          amountFrom: quote?.amountIn,
          amountOut: quote?.amountOut,
          walletFrom: fromWallet?.id,
          walletTo: toWallet?.id,
          quoteId: quote?.uuid,
        })

        const totalExpiry = Math.round(
          (new Date(response?.acceptanceExpiryDate).getTime() - new Date().getTime()) / 1000
        )

        startExpiryTimer(totalExpiry)
      },
      onError: (err: BaseErrorResponse) => {
        if (err?.data?.errorList) {
          err.data.errorList.forEach(error => {
            form.setError(error?.parameter as keyof ConvertFormType, { message: error?.message })
          })
          setStep('form')
        }
        logSentryError(
          'Error from ConvertConfirm - postConvertQuote - Error fetching quote',
          err,
          data
        )
      },
    })
  }

  /**
   * Convert funds via API
   */
  const convert = () => {
    acceptQuote(quote?.uuid, {
      onSuccess: () => {
        setStep('complete')
      },
      onError: (err: BaseErrorResponse) => {
        if (err?.data?.errorList) {
          err.data.errorList.forEach(error => {
            form.setError(error?.parameter as keyof ConvertFormType, { message: error?.message })
          })
          setStep('form')
        }
        logSentryError('Error from ConvertConfirm - acceptQuote', err, quote)
      },
    })
  }

  const renderSeconds = () => (
    <div className="w-20">
      <NotificationCard label={secondsFormatted} state="orange" />
    </div>
  )

  const renderRefreshing = () => (
    <div className="flex flex-row items-center py-1">
      <Loader />
      <div className="text-primary ml-2">{t('requestingNewQuote')}</div>
    </div>
  )

  return (
    <>
      <ModalScrollable>
        {/* nav bar */}
        <ModalNavBar
          title={t('convertConfirm')}
          onBack={!isAcceptingQuote && (() => setStep('form'))}
          onClose={dismissAction}
        />
        <SummaryContainer flex direction="col" gapY={20} padding={24}>
          <SummaryDetail hasMargin={false}>
            <SummaryRow
              label={t('youWillget')}
              description={`${numberWithCommas(
                floorWithPrecision(quote?.amountOut, toWallet?.currency?.pricePrecision)
              )} ${currencyCode}`}
              testid="you-will-get"
            />
            {currencyCode !== fromWallet?.currency?.code && (
              <SummaryRow
                label={t('exchangeRate')}
                description={`1 ${currencyCode} = ${floorWithPrecision(
                  quote?.price,
                  fromWallet?.currency?.pricePrecision
                )} ${fromWallet?.currency?.code}`}
                testid="price"
              />
            )}
            <SummaryRow
              label={t('total')}
              description={`${numberWithCommas(
                floorWithPrecision(quote?.amountDue, fromWallet?.currency?.pricePrecision)
              )} ${fromWallet?.currency?.code}`}
              testid="total"
            />
            {formValues?.reference && (
              <SummaryRow
                label={t('paymentReference')}
                description={formValues?.reference}
                testid="payment-reference"
              />
            )}
            <SummaryRow
              label={t('quoteExpiresIn')}
              renderDescription={isRefreshingQuote ? renderRefreshing() : renderSeconds()}
              testid="quote-expires-in"
            />
          </SummaryDetail>

          {/* quote disclaimer */}
          <Callout message={formatString(t('convertDisclaimer'), currencyCode)} state="info" />

          {isAcceptingQuoteError && <Callout message={t('quoteAcceptError')} state="error" />}
        </SummaryContainer>
      </ModalScrollable>

      <ModalActions actions={actions} />
    </>
  )
}

export default ConvertConfirm
