import { requestApi } from 'api/api'
import { CsvExportTransaction, PdfExportTransaction, Transaction } from 'types/transaction'
import { CustomDateRangeReq } from 'types/transaction/CustomDateRangeReq'
import {
  GetWalletTransactionsReq,
  WalletTransactionsExport,
} from 'types/transaction/GetTransactionsReq'
import { TransactionsPagination } from 'types/transaction/TransactionsPagination'

export type transactionExportFileFormatType = 'csv' | 'pdf'

export const getWalletTransactions = async ({
  walletId,
  offset,
  max,
}: GetWalletTransactionsReq): Promise<TransactionsPagination> => {
  const { data, headers }: { data: Transaction[]; headers: { [key: string]: string } } =
    await requestApi({
      url: `/transaction/${walletId}`,
      method: 'GET',
      params: {
        ...(!!offset && { offset }),
        ...(!!max && { max }),
      },
      fullResponse: true,
    })
  return {
    transactions: data || [],
    paging: {
      total: Number(headers['x-api-list-total']),
      offset: Number(headers['x-api-list-start']),
      max,
    },
  }
}

export const sendTransactionExportToEmail = ({
  selectedExportFormat,
  walletId,
  selectedDateRange,
}: WalletTransactionsExport): Promise<CsvExportTransaction[] | PdfExportTransaction[]> => {
  const requestUrl: Record<transactionExportFileFormatType, string> = {
    csv: '/transaction/report',
    pdf: '/transaction/report/external',
  }
  return requestApi({
    url: requestUrl?.[selectedExportFormat],
    params: {
      walletId,
      format: selectedExportFormat,
      fromDate: selectedDateRange?.from,
      toDate: selectedDateRange?.to,
    },
    method: 'GET',
  })
}

export const sendTransactionExportToEmailAllWallets = (
  selectedDateRange?: CustomDateRangeReq
): Promise<Transaction[]> => {
  return requestApi({
    url: '/transaction/report',
    params: {
      format: 'csv',
      fromDate: selectedDateRange?.from,
      toDate: selectedDateRange?.to,
    },
    method: 'GET',
  })
}
