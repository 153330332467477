import { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getSouthAfricanBanks } from 'services/AccountService'
import { BaseErrorResponse } from 'types/beneficiaries'
import { MappedErrorMessageState } from 'types/errors'
import { QueryMetaType } from 'types/reactQuery'
import { ONE_DAY } from 'utils/utils'

export const useSouthAfricanBanks = (
  setRequestError: Dispatch<SetStateAction<MappedErrorMessageState>>,
  isEnabled = false
) => {
  const meta: QueryMetaType = {
    onErrorAdditional: (error: BaseErrorResponse) => {
      setRequestError({
        errorCode: error?.status,
        show: true,
      })
    },
  }

  return useQuery({
    queryKey: reactQueryKeys.southAfricanBanks(),
    queryFn: () => getSouthAfricanBanks(),
    staleTime: ONE_DAY,
    enabled: isEnabled,
    meta,
  })
}
