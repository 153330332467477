export const TransactionType = {
  WITHDRAWAL: 'WITHDRAWAL',
  POST_DEPOSIT: 'POST_DEPOSIT',
} as const

export type TransactionType = (typeof TransactionType)[keyof typeof TransactionType]

export interface CustomerToken {
  customerToken: string
  vaspDID: string
}

export interface Asset {
  code: string
  decimals: number
  supported: boolean
}

export interface NotabeneTransaction {
  originatorAccountNumber?: string
  transactionAsset: string
  transactionAmount: string
  beneficiaryAccountNumber?: string
  transactionBlockchainInfo?: {
    destination: string
  }
  originatorVASPdid?: string
  isValid?: boolean
  ivms?: {
    beneficiary: {
      beneficiaryPersons: unknown[] // Specify the type of beneficiaryPersons if known
    }
  }
  error?: NotabeneErrorData
}

export interface Notabene {
  setTransaction: (transaction: NotabeneTransaction) => void
  tx: NotabeneTransaction
  destroyWidget: () => void
}

export interface NotabeneAssetInfoResponse {
  code: string
  decimals: number
  supported: boolean
}

export interface NotabeneErrorData {
  title: string
  detail: string
  code: number
  type: string
}

export interface NotabeneError {
  data: NotabeneErrorData
}
