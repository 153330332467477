import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getFiatCurrencies } from 'services/CurrencyService'

export const useFiatCurrenciesQuery = () =>
  useQuery({
    queryKey: reactQueryKeys.fiatCurrencies(),
    queryFn: getFiatCurrencies,
    refetchOnReconnect: false,
  })
