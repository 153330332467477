import React from 'react'
import i18next from 'i18next'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface ProcessingFeeProps {
  amountWithCurrency: string
}

const ProcessingFee = ({ amountWithCurrency }: ProcessingFeeProps) => {
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.processingFee')}
          detail={amountWithCurrency}
        />
      )}
    </>
  )
}

export default ProcessingFee
