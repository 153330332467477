import { useMutation } from '@tanstack/react-query'
import { updateAccountUser } from 'services/AccountService'
import { FormFieldsAccountUser } from 'types/types'

export const useUpdateAccountUserMutation = () => {
  return useMutation({
    mutationFn: ({ userID, formData }: { userID: number; formData: FormFieldsAccountUser }) =>
      updateAccountUser(userID, formData),
  })
}
