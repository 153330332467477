import dayjs from 'dayjs'
import { isArray } from 'lodash-es'
import { DateFormat } from 'constants/General'
import { DepositDetails, Transaction } from 'types/transaction'
import { getTransactionReference } from 'utils/utils'

export const useTransactionDetails = (transactionDetails: Transaction) => {
  const currencyCode = transactionDetails?.wallet?.currency?.code
  const amountWithCurrency = `${transactionDetails?.amount} ${currencyCode?.toUpperCase()}`
  const currencyFrom = transactionDetails?.context?.fromCurrencyCode
  const currencyTo = transactionDetails?.context?.toCurrencyCode
  const amountWithdrawn = transactionDetails?.context?.amount
  const status = transactionDetails?.context?.status

  const iconUrls =
    currencyFrom && currencyTo
      ? [
          `${process.env.ICONS_URL}${currencyFrom?.toLowerCase()}.svg`,
          `${process.env.ICONS_URL}${currencyTo?.toLowerCase()}.svg`,
        ]
      : [`${process.env.ICONS_URL}${currencyCode?.toLowerCase()}.svg`]
  const transactionId = transactionDetails?.id
  const transactionDepositCryptoUUID = transactionDetails?.hash
  const transactionDescription = transactionDetails?.description
  const transactionHash = transactionDetails?.context?.hash
  const transactionType = transactionDetails?.type
  const transactionSubType = transactionDetails?.context?.subType
  const transactionDate =
    !!transactionDetails?.dateCreated &&
    dayjs(transactionDetails.dateCreated).format(DateFormat.D_MMM_YYYY_AT_HH_MM)
  const transactionReference = getTransactionReference(transactionDetails)
  const depositDetails: DepositDetails = transactionDetails?.context?.depositDetails
  const withdrawalDetails = transactionDetails?.context?.withdrawalDetails
  const paidActualAmountWithCurrencyFrom = `${
    transactionDetails?.context?.paidActualAmount
  } ${transactionDetails?.context?.fromCurrencyCode?.toUpperCase()}`
  const paidActualAmountWithCurrencyTo = `${
    transactionDetails?.context?.paidActualAmount
  } ${transactionDetails?.context?.toCurrencyCode?.toUpperCase()}`
  const merchantId = transactionDetails?.context?.merchantId
  const transactionUUID = transactionDetails?.context?.uuid
  const channelId = transactionDetails?.context?.channel
  const channelReference = transactionDetails?.context?.reference
  const externalReference = transactionDetails?.context?.externalReference
  const hasProofOfPayment = transactionDetails?.context?.hasProofOfPayment
  const recipientDetails = transactionDetails?.context?.recipientDetails
  const sourceAddress = isArray(transactionDetails?.context?.sourceAddress)
    ? transactionDetails?.context?.sourceAddress?.[0]
    : transactionDetails?.context?.sourceAddress

  const smallTabsId = `${transactionId}-${transactionHash}-${transactionDate}`
  const exchangeRate = transactionDetails?.context?.exchangeRate
  const convertedToWallet = transactionDetails?.context?.convertedTo
  const convertedFromWallet = transactionDetails?.context?.convertedFrom
  const convertedAmountTo = `${transactionDetails?.context?.convertedAmountTo} ${currencyTo?.toUpperCase()}`
  const convertedAmountFrom = `${transactionDetails?.context?.convertedAmountFrom} ${currencyFrom?.toUpperCase()}`
  const paymentMetadata = transactionDetails?.context?.paymentMetadata
  const bvnkNetworkBeneficiary = transactionDetails?.context?.beneficiary
  const bvnkNetworkSender = transactionDetails?.context?.sender

  return {
    currencyCode,
    amountWithCurrency,
    amountWithdrawn,
    iconUrls,
    currencyFrom,
    currencyTo,
    transactionDescription,
    transactionId,
    transactionHash,
    transactionType,
    transactionSubType,
    transactionDate,
    transactionReference,
    transactionDepositCryptoUUID,
    depositDetails,
    withdrawalDetails,
    paidActualAmountWithCurrencyFrom,
    paidActualAmountWithCurrencyTo,
    merchantId,
    transactionUUID,
    channelId,
    channelReference,
    smallTabsId,
    status,
    externalReference,
    hasProofOfPayment,
    recipientDetails,
    sourceAddress,
    exchangeRate,
    convertedFromWallet,
    convertedToWallet,
    convertedAmountTo,
    convertedAmountFrom,
    paymentMetadata,
    bvnkNetworkBeneficiary,
    bvnkNetworkSender,
  }
}
