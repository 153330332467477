import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { TableRow } from '@node-space/storybook-components/dist/components/SimpleTable'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { DateFormat } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsTransactionsQuery } from 'hooks/queries/useApprovalsTransactionsQuery'
import { ApprovalStatuses } from 'types/approvals'
import { SidePanelSummary } from '../SidePanel/SidePanelSummary'

const rowHeaderWidth = 'px-8 w-1/4'

export const ExpiredTransactions = () => {
  const { t } = useTranslation()
  const { currentAccount } = useAccountsContext()

  const currentAccountReference = currentAccount?.reference || ''
  const {
    data: expiredTransactions,
    isError,
    isFetching,
  } = useApprovalsTransactionsQuery(
    currentAccountReference,
    ApprovalStatuses.EXPIRED,
    !!currentAccountReference
  )

  const {
    isSidePanelOpen: isExpiredTransactionDetailsOpen,
    sidePanelData: expiredTransactionDetails,
    paging,
    setIsSidePanelOpen,
    updateSidePanel,
  } = useSidePanel(expiredTransactions)

  const openSidePanelSummary = (index: number) => {
    updateSidePanel(index)
    setIsSidePanelOpen(true)
  }

  const transactionsData = (): TableRow[] => {
    return expiredTransactions?.map((approvalTransaction, index) => ({
      rowData: [
        {
          cellData: (
            <Box flex alignItems="center" gap={12} className="relative">
              <ImageIcon
                width={24}
                height={24}
                hasBackgroundColor={false}
                src={`${
                  process.env.ICONS_URL
                }${approvalTransaction?.currency?.code?.toLowerCase()}.svg`}
              />
              <Box className="relative">
                {!!(approvalTransaction?.amount && approvalTransaction?.currency?.code) && (
                  <Text size="sm" className="text-500">
                    {`${approvalTransaction?.amount} ${approvalTransaction?.currency?.code}`}
                  </Text>
                )}
                <Text size="sm" color="disabled">
                  {approvalTransaction?.walletName}
                </Text>
              </Box>
            </Box>
          ),
          className: `py-4 px-8`,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.beneficiaryDetails?.accountNumber ||
                  approvalTransaction?.beneficiaryDetails?.destinationWallet}
              </Text>
              <Text size="sm" color="disabled">
                {approvalTransaction?.beneficiaryDetails?.beneficiaryName}
              </Text>
            </Box>
          ),
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.initiatedBy || approvalTransaction?.initiatedByEmail}
              </Text>
              {!!approvalTransaction?.initiatedAt && (
                <Text size="sm" color="disabled">
                  {dayjs(approvalTransaction?.initiatedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
                </Text>
              )}
            </Box>
          ),
        },
        {
          cellData: dayjs(approvalTransaction?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM),
        },
      ],
      onClick: () => openSidePanelSummary(index),
    }))
  }

  const expiredTransactionTableInfo = transactionsData()

  return (
    <Box paddingY={16}>
      {!!expiredTransactionDetails && (
        <SidePanelSummary
          isOpen={isExpiredTransactionDetailsOpen}
          paging={paging}
          onClose={() => setIsSidePanelOpen(false)}
          transactionDetails={expiredTransactionDetails}
          tab={ApprovalStatuses.EXPIRED}
        />
      )}
      {isError ? (
        <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />
      ) : !isFetching && !expiredTransactionTableInfo?.length ? (
        <StatusCard
          headline={t('approvals.emptyStateHeadline', {
            approvalStatus: ApprovalStatuses.EXPIRED.toLowerCase(),
          })}
          description={t('approvals.emptyStateDescription', {
            approvalStatus: ApprovalStatuses.EXPIRED.toLowerCase(),
          })}
        />
      ) : (
        <SimpleTableV2
          columns={[
            {
              headerTitle: t('payment'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('to'),
            },
            {
              headerTitle: t('approvals.initiatedBy'),
              className: `w-1/4`,
            },
            {
              headerTitle: t('approvals.dateOfExpiry'),
            },
          ]}
          isLoading={isFetching}
          tableData={expiredTransactionTableInfo}
        />
      )}
    </Box>
  )
}
