import React from 'react'

interface BvnkLogoProps {
  color?: string
}

export const BvnkLogo = ({ color }: BvnkLogoProps) => (
  <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M12.9695 11.6686C14.7007 11.0823 15.9913 9.35154 15.9913 7.21006C15.9913 3.98222 13.7026 1.60668 9.65445 1.60668H0V22.4054H10.212C14.1129 22.4054 16.636 19.971 16.636 16.5974C16.636 14.0157 14.9936 12.1676 12.9695 11.6686ZM3.98964 5.01007H9.00822C10.9752 5.01007 12.0016 6.0063 12.0016 7.59169C12.0016 9.17562 10.8866 10.202 9.03839 10.202H3.98964V5.01007ZM9.44873 19.0318H3.98964V13.5469H9.50725C11.5313 13.5469 12.5879 14.6907 12.5879 16.3331C12.5879 18.0054 11.4143 19.0318 9.44873 19.0318Z"
          fill={color}
        />
        <path
          d="M53.7129 15.3647L45.0591 1.60612H40.0143V22.4063H44.0629V7.65L53.5087 22.4063H57.7615V1.60612H53.7129V15.3647Z"
          fill={color}
        />
        <path
          d="M27.3856 17.0671L22.0003 1.61957L19.1679 6.04281L25.2144 22.4063H29.3814L37.0961 1.60612H32.8135L27.3856 17.0671Z"
          fill={color}
        />
        <path
          d="M78.8699 1.60612H73.9775L65.4116 11.8893V1.60612H61.358V22.4063H65.4116V12.123L73.9775 22.4063H78.8699L70.1128 12.006L78.8699 1.60612Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="24" fill={color} />
      </clipPath>
      <clipPath id="clip1">
        <rect width="78.8699" height="20.8001" fill={color} transform="translate(0 1.60612)" />
      </clipPath>
    </defs>
  </svg>
)
