import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { wait } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { deleteChatbotCookie, deleteProfileCookie, HALF_SECOND } from 'utils/utils'

const Logout = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { state } = useLocation() || {}

  const resetCookiesSession = async () => {
    deleteProfileCookie()
    deleteChatbotCookie()
    return Promise.resolve()
  }

  const resetReactQuery = async () => {
    await queryClient.clear()
  }

  const startCleanup = async () => {
    await wait(HALF_SECOND)
    await resetCookiesSession()
    await resetReactQuery()
    navigate(PathNames.LOGIN, { replace: true, state })
  }

  useEffect(() => {
    startCleanup()
  }, [])

  return <></>
}

export default Logout
