import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { useDepositAccountsV2Context, useWalletContext } from 'hooks/context'
import { BankAccountDetailsRow } from './BankAccountDetailsRow'

export const BankAccountDetails = () => {
  const { details, isAggregatedWallet, swiftDetails } = useDepositAccountsV2Context()
  const wallet = useWalletContext()
  const { t } = useTranslation()

  const referenceNumberDetailRow = useMemo(() => {
    if (swiftDetails?.isSwift) {
      return (
        <BankAccountDetailsRow label={t('referenceNumber')} value={swiftDetails?.swiftReference} />
      )
    } else if (wallet?.address && isAggregatedWallet && !swiftDetails?.isSwift) {
      return <BankAccountDetailsRow label={t('referenceNumber')} value={wallet?.address} />
    }
  }, [swiftDetails?.isSwift, wallet?.address, isAggregatedWallet])

  return (
    <Box className="max-w-xs">
      {referenceNumberDetailRow}
      {Object.keys(details).map(label => (
        <BankAccountDetailsRow key={label} label={label} value={details?.[label]} />
      ))}
    </Box>
  )
}
