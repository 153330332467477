import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getDepositAccounts } from 'services/WalletsService'
import { BankAccountsDetails, DepositBankAccount } from 'types/BankAccountsDetails'
import { QueryMetaType } from 'types/reactQuery'
import { Currencies, ErrorAPI } from 'types/types'

export const useDepositAccountsQuery = (walletId: string, enabled = false, enableV2Api = false) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { walletId },
  }

  return useQuery<BankAccountsDetails | DepositBankAccount[], { data: ErrorAPI }>({
    queryKey: reactQueryKeys.depositAccounts(walletId),
    queryFn: () => getDepositAccounts(walletId, enableV2Api),
    enabled,
    meta,
    select: (data: BankAccountsDetails | DepositBankAccount[]) => {
      if (!enableV2Api) return data

      return (data as DepositBankAccount[])?.filter(bankAccount => {
        if (bankAccount?.currency !== Currencies.GBP) return true

        if (!bankAccount?.accountNumberFormat) return true

        return !bankAccount?.isSwift && bankAccount?.accountNumberFormat === 'SCAN'
      })
    },
  })
}
