import { useQuery } from '@tanstack/react-query'
import { isString } from 'lodash-es'
import { useLocation } from 'react-router-dom'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getReissueTokenParams } from 'services/AuthService'
import { QueryMetaType } from 'types/reactQuery'
import { AuthToken } from 'types/types'
import { setAuthCookies } from 'utils/utils'

export const useRefreshToken = (accessToken: string, secret: string) => {
  const location = useLocation()

  const meta: QueryMetaType = {
    errorAdditionalData: { location },
    onSuccess: (freshToken: AuthToken) => {
      setAuthCookies({ ...freshToken })
    },
  }

  return useQuery({
    queryKey: reactQueryKeys.reissueToken(),
    queryFn: () => getReissueTokenParams({ access_token: accessToken, secret }),
    enabled: isString(accessToken) && isString(secret),
    meta,
  })
}
