import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { BeneficiaryDetailsCurrency } from 'types/beneficiaries'

export const getNgnSpecificSchemaFields = (t: TFunction<'translation', undefined>) => {
  const accountNumberLabel = t('accountNumber')
  const accountNumberLength = 10

  const ngnAccountNumber = yup
    .string()
    .label(accountNumberLabel)
    .min(
      accountNumberLength,
      t('fieldExactLength', {
        field: accountNumberLabel,
        amount: accountNumberLength,
      })
    )
    .max(
      accountNumberLength,
      t('fieldExactLength', {
        field: accountNumberLabel,
        amount: accountNumberLength,
      })
    )
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const bankCodeLabel = t('bank')
  const bankCode = yup
    .string()
    .label(bankCodeLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is NGN
        if (ctx?.parent?.currencyCode === BeneficiaryDetailsCurrency.NGN) {
          if (!value) {
            ctx.schema.required()
            return ctx.createError({ message: t('bankNameRequired') })
          }
          return true
        }

        // Should not be required for other currencies
        ctx.schema.notRequired()
        return true
      },
    })
  return {
    ngnAccountNumber,
    bankCode,
  }
}
