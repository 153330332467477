import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { Approval } from 'constants/General'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import { ApprovalStatuses, UnapprovableTransactions } from 'types/approvals'

interface ApprovalHeaderButtonsProps {
  activeTab: string
  hasCheckedPendingTransactions?: boolean
  cannotApprove: UnapprovableTransactions
}

const ApprovalHeaderButtons = ({
  activeTab,
  hasCheckedPendingTransactions,
  cannotApprove,
}: ApprovalHeaderButtonsProps) => {
  const { t } = useTranslation()

  const {
    permissions,
    approvalsConfig: { handleManageApproversModal, handleApprovalDecisionModal },
  } = useApprovalsContext()

  const hasSelfInitiatedApprovals = !!cannotApprove?.selfInitiatedTransactions?.length
  const hasUnapprovableTransactions = !!cannotApprove?.cannotApproveTransactions?.length
  const cannotApproveTransaction = hasSelfInitiatedApprovals || hasUnapprovableTransactions

  return (
    <Box flex className="sm:p-0">
      <Box width="full" className="sm:w-auto">
        <Button secondary onClick={() => handleManageApproversModal({ isVisible: true })}>
          {permissions?.canEdit ? t('approvals.manageApprovers') : t('approvals.viewApprovers')}
        </Button>
      </Box>

      {permissions?.canManageApproval && activeTab == ApprovalStatuses.PENDING && (
        <>
          <Box width="full" className="ml-3 sm:w-auto">
            {cannotApproveTransaction ? (
              <Tooltip
                clickable
                hasMaxWidth
                bodyContent={
                  hasSelfInitiatedApprovals
                    ? t('approvals.hasSelfInitiatedApprovalsDisclaimer')
                    : t('approvals.unapprovableDisclaimer')
                }
                className="whitespace-normal"
                position="bottom"
              >
                <Button
                  disabled={!hasCheckedPendingTransactions || cannotApproveTransaction}
                  onClick={() =>
                    handleApprovalDecisionModal({
                      isVisible: true,
                      decision: Approval.DECISION.APPROVE as 'APPROVE',
                    })
                  }
                >
                  {t('approvals.approve')}
                </Button>
              </Tooltip>
            ) : (
              <Button
                disabled={!hasCheckedPendingTransactions}
                onClick={() =>
                  handleApprovalDecisionModal({
                    isVisible: true,
                    decision: Approval.DECISION.APPROVE as 'APPROVE',
                  })
                }
              >
                {t('approvals.approve')}
              </Button>
            )}
          </Box>
          <Box width="full" className="ml-3 sm:w-auto">
            <Button
              disabled={!hasCheckedPendingTransactions}
              onClick={() =>
                handleApprovalDecisionModal({
                  isVisible: true,
                  decision: Approval.DECISION.REJECT as 'REJECT',
                })
              }
            >
              {t('approvals.reject')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default React.memo(ApprovalHeaderButtons)
