import { requestApi } from 'api/api'
import { ForgotPasswordResponse, ResetPasswordResponse } from 'types/passwords'

export const postForgotPassword = async ({ emailAddress }): Promise<ForgotPasswordResponse> => {
  return requestApi({
    url: `/user/forgot/request`,
    method: 'POST',
    data: { emailAddress },
    authenticated: false,
  })
}

export const postResetPassword = async ({ newPassword, token }): Promise<ResetPasswordResponse> => {
  return requestApi({
    url: '/user/forgot/confirm',
    method: 'POST',
    data: { newPassword, token },
    authenticated: false,
  })
}
