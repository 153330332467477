export const StepKeys = {
  CREATE_API_KEY: 'createApiKey',
  USE_API_KEY: 'useApiKey',
  VIEW_WALLETS: 'viewWallets',
  CREATE_MERCHANT: 'createMerchant',
  CREATE_PAYMENT: 'createPayment',
  GO_LIVE: 'goLive',
} as const

export const StepStates = {
  INFO: 'info',
  SUCCESS: 'success',
} as const
