import { requestApi } from 'api/api'
import { Country } from 'types/authentication/Profile'

export const getCountries = async (canEnable = true, pagination = false): Promise<Country[]> => {
  return requestApi({
    url: '/country',
    method: 'GET',
    params: {
      enabled: canEnable ? 'true' : 'false',
      pagination,
    },
    authenticated: false,
  })
}
