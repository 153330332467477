import React, { useMemo } from 'react'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box, Box as Row } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { BvnkNetworkTransferTransactionSubType } from 'pages/WalletDetails/components/RecentActivity/SidePanel/PaymentTemplates/BvnkNetworkTransfer'
import BvnkNetworkTransferSenderReceiver from 'pages/WalletDetails/components/RecentActivity/SidePanel/PaymentTemplates/BvnkNetworkTransferSenderReceiver'
import { TransactionDetailRow } from 'pages/WalletDetails/components/RecentActivity/SidePanel/TransactionDetailRow'
import { SearchTransactionContent } from 'types/transaction'
import { TransactionType } from 'types/transaction/TransactionType'
import { paymentDirections } from 'types/types'
import { useTransactionDetailsTransformer } from '../hooks/useTransactionDetailsTransformer'

const shortenLongText = {
  START: 15,
  END: 10,
}

interface SidePanelBodyProps {
  transactionDetails: SearchTransactionContent
}

export const SidePanelBody = ({ transactionDetails }: SidePanelBodyProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isBvnkNetworkTransfer = transactionDetails?.type === TransactionType.BVNK_NETWORK_TRANSFER

  const {
    amountWithCurrency,
    transactionType,
    customerReference,
    transactionHash,
    transactionDate,
    customTextCopies,
    hasDirectionOrIsChannelsDeposit,
    walletAddresses,
    walletName,
    transactionUUID,
    withdrawalDetails,
    showSentFromWalletAddress,
    showSentToWalletAddress,
    transformedData,
    exchangeRate,
    fromCurrency,
    toCurrency,
    transactionSubType,
  } = useTransactionDetailsTransformer(transactionDetails, t)
  const transactionTypeSubType = `${transactionType}_${transactionSubType}`

  const summaryTextCopies = {
    IN: {
      walletAddress: t('wallets.searchTransaction.payInWalletAddress'),
      amount: t('wallets.searchTransaction.payInAmount'),
      paidWallet: t('wallets.searchTransaction.paidInWallet'),
    },
    OUT: {
      walletAddress: t('wallets.searchTransaction.payOutWalletAddress'),
      amount: t('wallets.searchTransaction.payOutAmount'),
      paidWallet: t('wallets.searchTransaction.paidOutWallet'),
    },
  }

  const deepLinkToOriginalPayments = () => {
    if (transformedData?.isChannelsDeposit) {
      navigate(
        `${PathNames.PAYMENTS}${PathNames.CHANNELS_LINKS}?dlt-reference=${customerReference}`
      )
    } else {
      navigate(
        `${PathNames.PAYMENTS}${PathNames.PAYMENTS_LINKS}?dlt-reference=${customerReference}`
      )
    }
  }

  const primaryAmountLabel = useMemo(() => {
    if (isBvnkNetworkTransfer) {
      switch (transactionSubType) {
        case BvnkNetworkTransferTransactionSubType.PAYMENT_IN:
          return t('amountRecieved')
        case BvnkNetworkTransferTransactionSubType.PAYMENT_OUT:
          return t('transactions.sideBar.amountWithdrawn')
        default:
          return t('amount')
      }
    } else {
      return transformedData?.resolvedPaymentDirection == paymentDirections.IN
        ? customTextCopies?.[transactionType]?.amountReceived
        : customTextCopies?.[transactionType]?.amountPaid
    }
  }, [
    customTextCopies,
    isBvnkNetworkTransfer,
    transformedData?.resolvedPaymentDirection,
    transactionSubType,
  ])

  const secondaryAmountLabel =
    transformedData?.resolvedPaymentDirection == paymentDirections.IN
      ? customTextCopies?.[transactionType]?.amountPaid
      : customTextCopies?.[transactionType]?.amountReceived

  const getWalletAddressDisplay = (walletAddress: string) => {
    return walletAddresses?.length > 1
      ? ellipseMiddleText(walletAddress, 6, 6)
      : ellipseMiddleText(walletAddress, shortenLongText.START, shortenLongText.END)
  }

  return (
    <Box padding={16}>
      <Text size="base" weight="medium" color="dark-grey">
        {t('transactionDetails')}
      </Text>
      <Box paddingT={8} paddingB={8}>
        {amountWithCurrency && transactionType !== TransactionType.LATE_PAYMENT && (
          <TransactionDetailRow
            label={primaryAmountLabel || t('amountRecieved')}
            detail={amountWithCurrency}
          />
        )}
        {transformedData?.fromAmount && hasDirectionOrIsChannelsDeposit && (
          <TransactionDetailRow
            label={secondaryAmountLabel || t('wallets.searchTransaction.paymentInAmountPaid')}
            detail={transformedData?.fromAmount}
          />
        )}
        {!!transactionType && (
          <TransactionDetailRow label={t('transactionType')} detail={startCase(transactionType)} />
        )}
        {transactionType == 'paymentOut' && (
          <TransactionDetailRow
            label={t('wallets.searchTransaction.exchangeRate')}
            detail={`1 ${fromCurrency} = ${exchangeRate} ${toCurrency}`}
          />
        )}
        {transactionType == 'paymentIn' && (
          <TransactionDetailRow
            label={t('wallets.searchTransaction.exchangeRate')}
            detail={`1 ${fromCurrency} = ${exchangeRate} ${toCurrency}`}
          />
        )}
        {walletName && hasDirectionOrIsChannelsDeposit && (
          <TransactionDetailRow
            label={
              summaryTextCopies?.[
                transactionDetails?.direction ?? transformedData?.resolvedPaymentDirection
              ]?.paidWallet
            }
            detail={startCase(walletName)}
          />
        )}
        {walletName && transactionType === TransactionType.USER_WITHDRAWAL && (
          <TransactionDetailRow
            label={t('wallets.searchTransaction.sentFromWallet')}
            detail={startCase(walletName)}
          />
        )}
        {showSentFromWalletAddress && (
          <TransactionDetailRow
            label={t('wallets.searchTransaction.payInWalletAddress')}
            detail={
              <Row flex justifyContent="start" gap={4} className="flex-wrap md:justify-end">
                {walletAddresses?.map(walletAddress => (
                  <Box flex key={walletAddress}>
                    <Text tag="span" size="sm">
                      {getWalletAddressDisplay(walletAddress)}
                    </Text>
                    <Box>
                      <CopyButton
                        copyText={walletAddress}
                        confirmationMessage={t('copiedToClipboard')}
                        iconColour={copyButtonIconColour}
                        confirmationMessageAlignment="left"
                        confirmationMessageTheme="dark"
                      />
                    </Box>
                  </Box>
                ))}
              </Row>
            }
          />
        )}
        {showSentToWalletAddress &&
          transactionDetails?.type !== TransactionType.USER_WITHDRAWAL && (
            <TransactionDetailRow
              label={t('wallets.searchTransaction.payOutWalletAddress')}
              detail={
                <Row flex justifyContent="start" gap={4} className="flex-wrap md:justify-end">
                  {walletAddresses?.map(walletAddress => (
                    <Box flex key={walletAddress}>
                      <Text tag="span" size="sm">
                        {getWalletAddressDisplay(walletAddress)}
                      </Text>
                      <Box>
                        <CopyButton
                          copyText={walletAddress}
                          confirmationMessage={t('copiedToClipboard')}
                          iconColour={copyButtonIconColour}
                          confirmationMessageAlignment="left"
                          confirmationMessageTheme="dark"
                        />
                      </Box>
                    </Box>
                  ))}
                </Row>
              }
            />
          )}
        {!!walletAddresses?.length && hasDirectionOrIsChannelsDeposit && (
          <TransactionDetailRow
            label={
              summaryTextCopies?.[
                transactionDetails?.direction ?? transformedData?.resolvedPaymentDirection
              ]?.walletAddress
            }
            detail={
              <Row flex justifyContent="start" gap={4} className="flex-wrap md:justify-end">
                {walletAddresses?.map(walletAddress => (
                  <Box flex key={walletAddress}>
                    <Text tag="span" size="sm">
                      {getWalletAddressDisplay(walletAddress)}
                    </Text>
                    <Box>
                      <CopyButton
                        copyText={walletAddress}
                        confirmationMessage={t('copiedToClipboard')}
                        iconColour={copyButtonIconColour}
                        confirmationMessageAlignment="left"
                        confirmationMessageTheme="dark"
                      />
                    </Box>
                  </Box>
                ))}
              </Row>
            }
          />
        )}
        {!!customerReference && (
          <TransactionDetailRow
            label={t('customerReference')}
            detail={
              <Row flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {ellipseMiddleText(customerReference, shortenLongText.START, shortenLongText.END)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={customerReference}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                    confirmationMessageAlignment="left"
                    confirmationMessageTheme="dark"
                  />
                </Box>
              </Row>
            }
          />
        )}
        {!!transactionUUID && (
          <TransactionDetailRow
            label={t('transactionUuid')}
            detail={
              <Row flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {ellipseMiddleText(transactionUUID, shortenLongText.START, shortenLongText.END)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transactionUUID}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                    confirmationMessageAlignment="left"
                    confirmationMessageTheme="dark"
                  />
                </Box>
              </Row>
            }
          />
        )}
        {!!transactionHash && (
          <TransactionDetailRow
            label={t('hash')}
            detail={
              <Row flex flexItemsCenter>
                <Text tag="span" size="sm">
                  {ellipseMiddleText(transactionHash, shortenLongText.START, shortenLongText.END)}
                </Text>
                <CopyButton
                  copyText={transactionHash}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                  confirmationMessageTheme="dark"
                />
              </Row>
            }
          />
        )}
        {!!transactionDate && <TransactionDetailRow label={t('date')} detail={transactionDate} />}
      </Box>
      {!!withdrawalDetails && (
        <>
          <Text size="base" weight="medium" color="dark-grey" className="mt-3">
            {transactionType === TransactionType.USER_DEPOSIT && t('senderDetails')}
            {transactionType === TransactionType.USER_WITHDRAWAL && t('withdrawalDetails')}
          </Text>
          <Box gapY={8} paddingB={8}>
            {!!transactionDetails?.accountNumber && (
              <TransactionDetailRow
                label={t('accountNumber')}
                detail={transactionDetails?.accountNumber}
              />
            )}
            {!!transactionDetails?.bankName && (
              <TransactionDetailRow
                label={t('bankName')}
                detail={
                  <Text color="grey" size="sm">
                    {transactionDetails?.name}
                  </Text>
                }
              />
            )}
            {!!transactionDetails?.bankCode && (
              <TransactionDetailRow
                label={t('bankCode')}
                detail={
                  <Text color="grey" size="sm">
                    {transactionDetails?.bankCode}
                  </Text>
                }
              />
            )}
            {!!transactionDetails?.name && transactionType !== TransactionType.USER_DEPOSIT && (
              <TransactionDetailRow
                label={t('beneficiaryName')}
                detail={
                  <Text color="grey" size="sm">
                    {transactionDetails?.name}
                  </Text>
                }
              />
            )}
          </Box>
        </>
      )}
      {!!customerReference && (
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          paddingY={12}
          divider="top"
          className="last:border-b-0"
        >
          <Text size="sm" color="grey">
            <Button onClick={deepLinkToOriginalPayments} noStyling>
              {t('wallets.searchTransaction.dltDeepLinkText')}
            </Button>
          </Text>
        </Box>
      )}

      {isBvnkNetworkTransfer && (
        <BvnkNetworkTransferSenderReceiver
          transactionTypeSubType={transactionTypeSubType}
          sender={transactionDetails?.name}
          beneficiary={transactionDetails?.name}
        />
      )}
    </Box>
  )
}
