import { requestApi } from 'api/api'
import { Approval } from 'constants/General'
import {
  ApprovalOperationChallengeRequest,
  ApprovalWorkflowActionPreRequest,
  ApprovalWorkflowActionResponse,
  GetApprovalPreferences,
  PendingTransactionsResponse,
  UpdateAccountApprovalsPreferenceV1,
  UpdateAccountApprovalsPreferenceV2,
  UpdateAccountAprrovers,
} from 'types/approvals'

export const getAccountApprovalsPreference = (
  accountReference: string,
  preference = Approval.ACCOUNT
): Promise<GetApprovalPreferences> => {
  return requestApi({
    url: `/approval/v2/preferences/all-preference-status`,
    method: 'GET',
    params: {
      parentId: accountReference,
      ...(!!preference && { preference }),
    },
  })
}

export const getApprovalsTransactions = (
  currentAccountReference: string,
  status: string
): Promise<PendingTransactionsResponse[]> => {
  return requestApi({
    url: `/approval-workflow/v1/payout-logs`,
    method: 'GET',
    params: {
      sort: 'createDate,desc',
      status,
      reference: currentAccountReference,
    },
  })
}

export const approvalWorkflowActionPreRequest = (
  data: ApprovalWorkflowActionPreRequest
): Promise<ApprovalWorkflowActionResponse> => {
  return requestApi({
    url: '/approval-workflow/v1/action',
    method: 'POST',
    data,
  })
}

export const approvalWorkflowChallengeRequest = (data: ApprovalOperationChallengeRequest) => {
  return requestApi({
    url: '/approval-workflow/operation-authorizations',
    method: 'POST',
    data,
  })
}

export const updateAccountApprovalsPreference = (
  data: { preferences: UpdateAccountApprovalsPreferenceV1[] } | UpdateAccountApprovalsPreferenceV2,
  isV2Api?: boolean
): Promise<unknown> => {
  const apiUrl = isV2Api ? '/approval/v2/preferences' : '/approval/v1/preferences'
  return requestApi({
    url: apiUrl,
    method: 'PUT',
    data,
  })
}

export const updateApprovers = (data: UpdateAccountAprrovers): Promise<unknown> => {
  return requestApi({
    url: '/account/user',
    method: 'PUT',
    data,
  })
}
