import GA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { isProduction, isSandbox } from './environment'

const isEnabled = isSandbox || isProduction

const actions = {
  event: (event: string | UaEventOptions) => isEnabled && GA.event(event),
  set: (fields: unknown) => isEnabled && GA.set(fields),
  initialize: () => isEnabled && GA.initialize(process.env.ANALYTICS_ID),
}

export const GoogleAnalytics = actions
