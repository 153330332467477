import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import {
  Box as Body,
  Box as DatePickerWrapper,
  Box as Footer,
  Box as Header,
  Box as OuterWrapper,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { DatePicker } from '@node-space/storybook-components/dist/DatePicker'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Modal, ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import useFilterModal from 'components/FilterBar/hooks/useFilterModal'
import useFilterParams from 'components/FilterBar/hooks/useFilterParams'
import type { FilterModalType } from '../../types'
import FilterMerchant from '../Filters/FilterMerchant'
import FilterStatus from '../Filters/FilterStatus'
import FilterTag from '../Filters/FilterTag'

const FilterModal = ({
  eventHandlers,
  isChannel,
  filterDateRange,
  isChannelManagement,
}: FilterModalType) => {
  const { t } = useTranslation()

  const { isFilterModalOpen, toggleIsFilterModalOpen } = useFilterModal()
  const { filterCount } = useFilterParams()

  const onResetChanged = () => {
    eventHandlers?.onResetAll()
    toggleIsFilterModalOpen()
  }
  return (
    <OuterWrapper>
      <Button
        testid="filter-modal-trigger-btn"
        secondary
        iconElement={<Icon name="FilterIcon" color={secondaryButtonIconColour} />}
        className={`mr-3`}
        onClick={toggleIsFilterModalOpen}
      >
        <span className={clsx({ 'w-16': filterCount?.length })}>
          {t('filters')} {!!filterCount?.length && `(${filterCount?.length})`}
        </span>
      </Button>

      <Modal allowOverFlow visible={isFilterModalOpen} closeModal={toggleIsFilterModalOpen}>
        <Wrapper tag="section" testid="filter-modal-wrapper" className="p-0 m-0 w-512 mx-auto">
          <Header testid="filter-modal-header">
            <ModalNavBar title={t('filters')} onClose={toggleIsFilterModalOpen} />
          </Header>

          <Body testid="filter-modal-body" className="space-y-8 pt-4 pb-8 p-5">
            {!isChannelManagement && (
              <DatePickerWrapper>
                <Text size="sm" weight="medium" className="mb-1">
                  {t('period')}
                </Text>
                <DatePicker
                  data-testid="filter-date-field"
                  dateRange
                  dateFieldPlaceholder={t('wallets.selectDate')}
                  dateValue={filterDateRange}
                  onDateValueChange={dateVal => {
                    eventHandlers?.onDateFilterChange(dateVal)
                  }}
                  dateRangeSeparator="-"
                  maxDate={new Date()}
                  enableDateFieldEdit={false}
                />
              </DatePickerWrapper>
            )}

            <FilterMerchant onMerchantSelected={eventHandlers?.onMerchantSelected} />
            {!isChannelManagement && (
              <FilterStatus
                onPaymentStatusChanged={eventHandlers?.onPaymentStatusChanged}
                isChannel={isChannel}
              />
            )}
            {!isChannelManagement && (
              <FilterTag onPaymentTagChange={eventHandlers?.onPaymentTagChange} />
            )}
          </Body>

          <Footer testid="filter-modal-footer">
            <ModalActions
              actions={[
                {
                  children: t('resetAll'),
                  secondary: true,
                  onClick: onResetChanged,
                  testid: 'filter-modal-reset-btn',
                },
                {
                  children: t('show'),
                  onClick: () => {
                    eventHandlers.showSearchResult()
                    toggleIsFilterModalOpen()
                  },
                  testid: 'filter-modal-show-btn',
                },
              ]}
            />
          </Footer>
        </Wrapper>
      </Modal>
    </OuterWrapper>
  )
}

export default FilterModal
