import React from 'react'
import { Box } from '@node-space/storybook-components'

interface OnClickProviderProps {
  children: React.ReactNode
  onClick: () => void
  id: string
}

const OnClickProvider = ({ children, onClick, id }: OnClickProviderProps) =>
  onClick && (
    <Box id={id} flex className="cursor-pointer" onClick={onClick}>
      {children}
    </Box>
  )

export default OnClickProvider
