import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'

export type LoginFormData = {
  emailAddress: string
  password: string
}

export type LoginFormKeys = keyof LoginFormData

export const LoginSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getEmailYupField } = useYupFields()

  return yup.object<Partial<Record<LoginFormKeys, yup.StringSchema>>>({
    emailAddress: getEmailYupField(),
    password: getRequiredYupField({ label: t('password') }),
  })
}
