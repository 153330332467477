import React, { useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useFeatureFlags } from '@node-space/hooks'
import { Approval } from 'constants/General'
import {
  PayoutsFeeEstimateParams,
  usePayoutsFeeEstimateQuery,
} from 'hooks/queries/usePayoutsFeeEstimateQuery'
import { useWalletLimits } from 'hooks/queries/useWalletLimits'
import { useApprovalsConfigForFlow } from 'hooks/useApprovalsConfigForFlow'
import RequiresApprovalSuccessModal from 'pages/Approvals/components/RequiresApprovalSuccessModal'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { BaseErrorResponse } from 'types/beneficiaries'
import { Currencies, Limits, WalletType } from 'types/types'
import { PayFee } from 'types/wallets'
import { WalletAction, WalletTabProps } from '../Modal/WalletModal'
import { selectedFiatBeneficiaryAtom } from './atoms/atoms'
import PayAmount from './PayAmount'
import PayComplete from './PayComplete'
import PayConfirm from './PayConfirm'
import PayFormBenficiary from './PayFormBenficiary'
import PayFormBvnkNetworkBeneficiary from './PayFormBvnkNetworkBeneficiary'
import { getPayFeeRequest } from './utils/payFormUtils'

export type PaySteps = 'form' | 'amount' | 'confirm' | 'complete'

export type PayForm = {
  walletId?: string
  password?: string
  beneficiarySelection?: string
  amount?: string | number
  errorMessage?: string
  reference?: string
  challenge?: string
}

export type PayActionProps = WalletTabProps &
  IWalletActionApiType<PayForm, PaySteps> & {
    withdrawalLimits?: Limits
    payFees?: PayFee
    confirmAmount?: () => void
    isLimitsError: boolean
    action?: WalletAction
    feeError: BaseErrorResponse
  }

/**
 * Pay Tab containing state, form and API data for each step
 */
export const Pay = ({
  loading,
  step,
  form,
  wallets,
  setLoading,
  setStep,
  ...otherProps
}: PayActionProps) => {
  const selectedFiatBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const { enablePayoutsV2 } = useFeatureFlags()

  const [selectedWallet, setSelectedWallet] = useState<WalletType>(null)
  const [withdrawalLimits, setWithdrawalLimits] = useState<Limits>()
  const formValues = form.watch()

  const { approvalsIsRequiredForFlow, isFetchingApprovalPreference } = useApprovalsConfigForFlow(
    Approval.FLOWS.FIAT_3RD_PARTY_PAYOUT,
    formValues?.amount
  )

  const isPayoutsV2Enabled =
    enablePayoutsV2 && !!selectedWallet?.supportedTransferDestinations?.length

  const feeRequest = getPayFeeRequest(formValues, selectedWallet, isPayoutsV2Enabled)

  const payoutFeeEstimateParams: PayoutsFeeEstimateParams = {
    request: feeRequest,
    isThirdPartyPayment: true,
    isPayoutFeesV2: isPayoutsV2Enabled,
    isEnabled: false,
    transferDestination: selectedFiatBeneficiary?.transferDestination,
  }

  const {
    data: payFees,
    refetch: refetchPayFees,
    isFetching: isFetchingPayFees,
    error: payFeeError,
  } = usePayoutsFeeEstimateQuery(payoutFeeEstimateParams)

  const {
    data: withdrawLimits,
    isFetching: isFetchingWithdrawLimits,
    isError: isFetchingWithdrawLimitsError,
    refetch: fetchWithdrawLimits,
  } = useWalletLimits(selectedWallet?.currency?.code as Currencies, selectedWallet?.id, false)

  const confirmAmount = async () => {
    setLoading(false)
    setStep('confirm')
  }

  useEffect(() => {
    if (
      formValues?.amount &&
      !!selectedWallet &&
      !selectedWallet?.supportsInternalBvnkNetworkTransfers
    ) {
      setLoading(true)
      refetchPayFees()

      setLoading(false)
    }
  }, [formValues?.amount, selectedWallet])

  const props = {
    withdrawalLimits,
    payFees,
    loading: loading || isFetchingPayFees,
    step,
    form,
    setLoading,
    wallets,
    setStep,
    confirmAmount,
    ...otherProps,
  }

  useEffect(() => {
    if (formValues?.walletId && wallets?.length) {
      const wallet = wallets?.find(wallet => wallet?.id?.toString() === formValues?.walletId)
      setSelectedWallet(wallet)
    }
  }, [formValues?.walletId, wallets])

  useEffect(() => {
    if (selectedWallet) {
      setWithdrawalLimits(null)
      fetchWithdrawLimits()
    }
  }, [selectedWallet])

  useEffect(() => {
    const limits = withdrawLimits?.withdrawalLimits?.current
    limits && setWithdrawalLimits(limits)
  }, [withdrawLimits])

  const isBusy = useMemo(
    () => !wallets?.length || loading || isFetchingWithdrawLimits || isFetchingPayFees,
    [withdrawalLimits, wallets, loading, isFetchingWithdrawLimits, isFetchingPayFees]
  )

  const isNetworkBeneficiaryPaymentType =
    otherProps?.action?.metadata?.pay?.beneficiaryPaymentType === 'network'
  const isBvnkNetworkPayment =
    selectedWallet?.supportsInternalBvnkNetworkTransfers || isNetworkBeneficiaryPaymentType

  const isRequiresApprovalModalVisible =
    approvalsIsRequiredForFlow && !isFetchingApprovalPreference && !isBvnkNetworkPayment

  const renderStep = () => {
    switch (step) {
      case 'form':
        return isBvnkNetworkPayment ? (
          <PayFormBvnkNetworkBeneficiary
            {...props}
            loading={isBusy}
            isLimitsError={isFetchingWithdrawLimitsError}
          />
        ) : (
          <PayFormBenficiary
            {...props}
            loading={isBusy}
            isLimitsError={isFetchingWithdrawLimitsError}
          />
        )
      case 'amount':
        return <PayAmount {...props} feeError={payFeeError as BaseErrorResponse} />
      case 'confirm':
        return <PayConfirm {...props} />
      case 'complete':
        return isRequiresApprovalModalVisible ? (
          <RequiresApprovalSuccessModal onClose={props?.dismissAction} actionType={props?.action} />
        ) : (
          !isFetchingApprovalPreference && <PayComplete {...props} />
        )
    }
  }

  return renderStep()
}
