import React from 'react'
import clsx from 'clsx'
import { Box } from '@node-space/storybook-components/dist/Box'
import { SizeType } from '@node-space/storybook-components/dist/components/Box/BoxTypes'
import { Text } from '@node-space/storybook-components/dist/Text'
import { AssignedColors, AssignedColorType } from '../types'

interface IconCircleProps {
  name?: string
  assignedColor?: AssignedColorType
  size?: SizeType
}

const IconCircle = ({ name, assignedColor, size = 40 }: IconCircleProps) => {
  const iconCircleClass = clsx({
    'bg-[#3850DD]': assignedColor === AssignedColors.BLUE,
    'bg-[#FF697D]': assignedColor === AssignedColors.PINK,
    'bg-[#3BDABA]': assignedColor === AssignedColors.GREEN,
    'bg-[#FFA300]': assignedColor === AssignedColors.ORANGE,
    'bg-[#8E41E8]': assignedColor === AssignedColors.PURPLE,
  })

  return (
    <Box
      flex
      centerChildren
      size={size}
      flexSize="keep"
      borderRadius="full"
      className={iconCircleClass}
    >
      <Text align="center" size="sm" weight="normal" className="text-white">
        {name?.toUpperCase()?.[0]}
      </Text>
    </Box>
  )
}

export default IconCircle
