import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getConnections } from 'services/BvnkNetworkService'
import { ConnectionsRequest } from 'types/bvnkNetwork'

export const useBvnkNetworkConnectionsQuery = (
  { accountReference, connectionStatus }: ConnectionsRequest,
  enabled?: boolean
) => {
  return useQuery({
    queryKey: reactQueryKeys.bvnkNetworkConnections({ accountReference, connectionStatus }),
    queryFn: () => getConnections({ accountReference, connectionStatus }),
    enabled,
    // Phase 2 pagination
    // select: (res: WithPaginationApiResponse<BvnkNetworkConnection[]>) => {
    //   return withPaginationPaging<BvnkNetworkConnection[]>(res)
    // },
  })
}
