import React from 'react'
import { Input2FA } from '@node-space/storybook-components'

type TwoFactorProps = {
  error?: string
  isLoading?: boolean
  onChange: (value: string) => void
}

const TwoFaField = ({ error, isLoading, onChange }: TwoFactorProps) => {
  return (
    <Input2FA
      testid="otc-2FA-input"
      disabled={isLoading}
      error={!!error}
      errorText={error}
      onChange={onChange}
    />
  )
}

export const TWO_FA_REQUIRED_LENGTH = 6

export default React.memo(TwoFaField)
