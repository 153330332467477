import React, { ReactElement } from 'react'
import { Box as ButtonContent } from '@node-space/storybook-components/dist/Box'
import { Text as Label, Text as Description } from '@node-space/storybook-components/dist/Text'

export type ButtonCardProps = {
  label: string
  description: string
  icon: ReactElement
  onCardClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const CardButton = ({ label, description, icon, onCardClick }: ButtonCardProps) => {
  return (
    <ButtonContent
      flex
      flexCol
      alignItems="start"
      paddingX={16}
      paddingY={8}
      gap={8}
      border="primary-500"
      borderRadius={4}
      className="cursor-pointer ring-primary-500 hover:ring-primary-700 hover:shadow-btn-secondary"
      onClick={onCardClick}
    >
      {icon}
      <Label size="sm" weight="normal" color="primary-500">
        {label}
      </Label>
      <Description color="text-500" size="sm">
        {description}
      </Description>
    </ButtonContent>
  )
}
