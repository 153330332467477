import React, { useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecaptchaTokenError } from 'recaptcha/RecaptchaTokenError'
import { RecaptchaProps } from 'recaptcha/types'
import { useFeatureFlags } from '@node-space/hooks'
import { Box as FormBody } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input, InputProps } from '@node-space/storybook-components/dist/Input'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { RequestError } from 'components/Errors/RequestError'
import { HeaderCTA } from 'components/header/HeaderCTA'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { TextLink } from 'components/TextLink'
import { PathNames } from 'constants/General'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useLoginMutation } from 'hooks/mutations'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ErrorData, WorkspaceListItemId } from 'types/types'
import { isSandbox } from 'utils/environment'
import { LoginFormKeys, LoginSchema } from '../schemas/loginSchema'
import { getLoginErrorType, LoginError } from '../utils'
import { LoginErrorMessage } from './LoginErrorMessage'

interface LoginFormProps {
  loginData: PostLoginReq
  recaptcha: RecaptchaProps
  setLoginData: React.Dispatch<React.SetStateAction<PostLoginReq>>
  setShowTwoFactor: (value: boolean) => void
  setRequiresWorkspaceSelection: (workspaces: [WorkspaceListItemId]) => Promise<void>
  setSuccessfulLogin: () => Promise<void>
}

const LoginForm = ({
  loginData,
  recaptcha,
  setLoginData,
  setShowTwoFactor,
  setRequiresWorkspaceSelection,
  setSuccessfulLogin,
}: LoginFormProps) => {
  const { t } = useTranslation()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { enableHeaderSignup } = useFeatureFlags()

  const { isLoading: isProfileLoading } = useProfileContext()
  const { mutate: postLogin, isPending: isLoadingSubmitRequest } = useLoginMutation()

  const formSchema = LoginSchema(t)
  const form = useForm<PostLoginReq>({
    defaultValues: loginData,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    handleSubmit,
    formState: { errors: fieldErrors, isSubmitting },
  } = form
  const formValues = useWatch({ control })

  const { setEmailField, setInputField } = useFormFields<LoginFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<LoginFormKeys, InputProps> = {
    emailAddress: setEmailField({ key: 'emailAddress' }),
    password: setInputField({ key: 'password', type: 'password', placeholder: t('enterPassword') }),
  }

  const isLoading = useMemo(
    () => isProfileLoading || recaptcha?.isLoading || isSubmitting || isLoadingSubmitRequest,
    [isProfileLoading, recaptcha?.isLoading, isSubmitting, isLoadingSubmitRequest]
  )

  const onSubmit: SubmitHandler<typeof formValues> = async (formValues: PostLoginReq) => {
    resetRequestError()
    const recaptchaToken = await recaptcha?.getToken()

    if (!recaptchaToken) {
      setRequestError({
        handledMessage: <RecaptchaTokenError />,
        show: true,
      })
      return
    }

    const payload: PostLoginReq = {
      ...formValues,
      recaptcha: recaptchaToken,
      recaptchaVersion: recaptcha?.version,
    }

    postLogin(payload, {
      onSuccess: async () => {
        await setSuccessfulLogin()
      },
      onError: async (error: BaseErrorResponse) => {
        const errorCode = error?.status
        const errorResponseMessage = error?.data?.message
        const errorType = getLoginErrorType(errorCode, errorResponseMessage)
        let sentryTraceId = ''

        if (errorType === LoginError.TWO_FACTOR || errorType === LoginError.WORKSPACE) {
          setLoginData(payload)
          if (errorType === LoginError.TWO_FACTOR) {
            setShowTwoFactor(true)
          }
          if (errorType === LoginError.WORKSPACE) {
            await setRequiresWorkspaceSelection(error.data as [WorkspaceListItemId] & ErrorData)
          }
          return
        }

        if (!errorType || errorType === LoginError.FORBIDDEN) {
          sentryTraceId = logSentryErrorAndGetTraceId(
            `${errorCode ? `${errorCode} ` : ''}Error from LoginForm - postLogin${
              errorResponseMessage ? ` - ${errorResponseMessage}` : ''
            }`,
            error,
            payload,
            'fatal'
          )
        }

        setRequestError({
          errorCode,
          handledMessage: !!errorType ? <LoginErrorMessage errorType={errorType} /> : '',
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  return (
    <LoginPageLayout
      title={t('logInYourAccount')}
      headerRightContent={
        enableHeaderSignup && (
          <HeaderCTA
            helpText={`${t('havingAccountQuestion')}`}
            buttonText={`${t('signUp')}`}
            buttonUrl={isSandbox ? PathNames.DEVX_SIGNUP_URL : PathNames.KYB_SIGNUP_URL}
          />
        )
      }
      hasFooter
    >
      {requestError?.show && (
        <RequestError
          errorCode={requestError?.errorCode}
          fallbackMessage={t('errorMessages.login.default')}
          sentryTraceId={requestError?.sentryTraceId}
        >
          {requestError?.handledMessage}
        </RequestError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody flex direction="col" gapY={20}>
          <Input {...formFields.emailAddress} testid="emailAddress" />
          <Input
            {...formFields.password}
            rightLabel={<TextLink path={PathNames.FORGOT_PASSWORD} text={t('forgotPassword')} />}
            testid="password"
          />

          <Button
            type="submit"
            loading={isLoading}
            disabled={!!Object.keys(fieldErrors)?.length}
            full
            iconElement={<Icon name="LockIcon" color="inherit" />}
            testid="loginButton"
            className="mt-1"
          >
            {`${t('logIn')}`}
          </Button>
        </FormBody>
      </form>
    </LoginPageLayout>
  )
}

export default LoginForm
