import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { BeneficiaryType } from 'types/beneficiaries'

type BeneficaryTypeCheckboxProps = {
  isFirstPartyBeneficiary: boolean
  disabled: boolean
  setValue: (name: string, beneficaryType: string) => void
}

export const BeneficaryTypeCheckbox = ({
  isFirstPartyBeneficiary,
  disabled,
  setValue,
}: BeneficaryTypeCheckboxProps) => {
  const { t } = useTranslation()
  return (
    <Box paddingT={8}>
      <CheckBox
        value={isFirstPartyBeneficiary}
        onChange={value =>
          setValue(
            'beneficiaryType',
            value ? BeneficiaryType.FIRST_PARTY : BeneficiaryType.THIRD_PARTY
          )
        }
        label={t('manageBeneficiaries.beneficiaryTypeSelection')}
        disabled={disabled}
        labelColour={disabled ? 'light' : 'default'}
      />
    </Box>
  )
}
