import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getWalletLimits } from 'services/WalletsService'
import { QueryMetaType } from 'types/reactQuery'

export const useWalletLimits = (currencyCode: string, walletId: number, enabled = false) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { walletId, currencyCode },
  }

  return useQuery({
    queryKey: reactQueryKeys.getWalletLimits(currencyCode, walletId),
    queryFn: () => getWalletLimits(currencyCode, walletId),
    enabled,
    meta,
  })
}
