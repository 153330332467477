import React, { createContext, useState } from 'react'
import { PaymentSummaryResponse } from 'types/payments'

interface PaymentContext {
  createdPayment: PaymentSummaryResponse
  setCreatedPayment: React.Dispatch<React.SetStateAction<PaymentSummaryResponse>>
}

export const PaymentCreatedContext = createContext<PaymentContext>({
  createdPayment: {} as PaymentSummaryResponse,
  setCreatedPayment: () => null,
})

interface PaymentCreatedContextProviderProps {
  children: React.ReactNode
}

export const PaymentCreatedContextProvider = ({ children }: PaymentCreatedContextProviderProps) => {
  const [createdPayment, setCreatedPayment] = useState<PaymentSummaryResponse>()
  return (
    <PaymentCreatedContext.Provider
      value={{
        createdPayment,
        setCreatedPayment,
      }}
    >
      {children}
    </PaymentCreatedContext.Provider>
  )
}
