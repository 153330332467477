import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { AddBeneficiaryBasicPayload } from 'types/beneficiaries'
import { getCommonSchemaFields } from './fields/commonFields'
import { getEurSpecificSchemaFields } from './fields/eurSpecificFields'
import { getGbpSpecificSchemaFields } from './fields/gbpSpecificFields'
import { getNgnSpecificSchemaFields } from './fields/ngnSpecificFields'
import { getUsdSpecificSchemaFields } from './fields/usdSpecificFields'
import { getZarSpecificSchemaFields } from './fields/zarSpecificFields'

export const addBeneficiaryDetailsSchema = (t: TFunction<'translation', undefined>) => {
  const commonSchemaFields = getCommonSchemaFields(t)
  const currencySpecificSchemaFields = {
    ...getGbpSpecificSchemaFields(t),
    ...getNgnSpecificSchemaFields(t),
    ...getUsdSpecificSchemaFields(t),
    ...getZarSpecificSchemaFields(t),
    ...getEurSpecificSchemaFields(t),
  }
  const formFields = {
    ...commonSchemaFields,
    ...currencySpecificSchemaFields,
  }

  return yup.object<Partial<Record<keyof AddBeneficiaryBasicPayload, yup.AnySchema>>>(formFields)
}
