import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { WalletEditFormFields } from '../components/Modal/WalletEditModal'

const walletEditSchema = (t: TFunction<'translation', undefined>) => {
  return yup.object<Record<keyof WalletEditFormFields, yup.StringSchema>>({
    walletName: yup.string().required(t('walletNameRequired')).max(40, t('walletNameLengthError')),
  })
}
export default walletEditSchema
