import { requestApi } from 'api/api'
import dayjs from 'dayjs'
import { ChannelsPaymentsResponse } from 'types/channels'
import {
  PaymentCaseDocumentsResponse,
  PaymentCaseEmailsResponse,
  PaymentCaseSendEmail,
  PaymentCasesResponse,
  PaymentCaseType,
  PaymentCaseUpdate,
  PaymentCaseUploadDocument,
  PaymentsCreateRequest,
  PaymentsResponse,
  PaymentStatus,
  PaymentTag,
} from 'types/payments'
import { Paging } from 'types/types'

/**
 * POST Create a Payment
 */
export const postCreatePayment = async (payload: PaymentsCreateRequest) => {
  return requestApi({
    url: '/v1/pay/summary',
    method: 'POST',
    data: payload,
  })
}

/**
 * PUT Mark a still PROCESSING but incomplete Payment with aleast one Payment leg to be COMPLETE
 */
export const putMarkUnderpaidPaymentComplete = (uuid: string) => {
  return requestApi({
    url: `v1/pay/${uuid}/underpay`,
    method: 'PUT',
    data: {},
  })
}

/**
 * GET All the payments and optional export CSV
 */
export const getAllPayments = async ({
  paging,
  from,
  to,
  merchant,
  status,
  searchByRef,
  searchByTransactionHash,
  tag,
}: {
  paging: Paging
  from: string | Date
  to: string | Date
  merchant: string
  status: string
  searchByRef?: string
  searchByTransactionHash?: string
  tag: string
}): Promise<{ results: PaymentsResponse[]; paging: Paging }> => {
  const { offset, max } = paging

  const { data, headers } = await requestApi({
    url: '/v1/pay/ui',
    fullResponse: true,
    method: 'GET',
    params: {
      order: 'desc',
      sort: 'id',
      fromDate: from,
      toDate: to,
      ...(merchant !== 'ALL' && { merchantId: merchant }),
      ...(searchByRef && { q: encodeURI(searchByRef) }),
      ...(searchByTransactionHash && { transactionHash: encodeURI(searchByTransactionHash) }),
      ...(status !== PaymentStatus.ALL && { status }),
      ...(tag === PaymentTag.ONHOLD && { hasEnhancedChecks: true }),
      ...(max && { max }),
      offset,
    },
  })

  return {
    results: data,
    paging: {
      total: Number(headers['x-api-list-total']) || 0,
      offset: Number(headers['x-api-list-start']) || offset,
      max,
    },
  }
}

/**
 * GET All the Channel payments
 */
export const getAllChannelsPayments = async (
  paging: Paging,
  from: string,
  to: string,
  merchant: string,
  status: string,
  search: string
): Promise<{ data: ChannelsPaymentsResponse; paging: Paging }> => {
  const fromDateString = dayjs(from).format('YYYY-MM-DD')
  const toDateString = dayjs(to).format('YYYY-MM-DD')
  const { offset, max } = paging

  const { data, headers } = await requestApi({
    url: '/v2/channel/payment',
    method: 'GET',
    fullResponse: true,
    params: {
      order: 'desc',
      sort: 'id',
      fromDate: fromDateString,
      toDate: toDateString,
      ...(search && { q: encodeURI(search) }),
      ...(status !== 'ALL' && { status }),
      ...(merchant !== 'ALL' && { merchantId: merchant }),
      ...(max && { max }),
      offset,
    },
  })
  return {
    data: data,
    paging: {
      total: Number(headers['x-api-list-total']) || 0,
      offset: Number(headers['x-api-list-start']) || offset,
      max,
    },
  }
}

export const getAllPaymentsCSV = async (
  from: string,
  to: string,
  status: string,
  search: string,
  merchant: string
): Promise<unknown> => {
  const data = await requestApi({
    url: '/v1/pay/summary',
    method: 'GET',
    params: {
      export: true,
      format: 'CSV',
      fromDate: from,
      toDate: to,
      ...(status !== 'ALL' && { status }),
      ...(merchant !== 'ALL' && { merchantId: merchant }),
      ...(search && { q: encodeURI(search) }),
    },
  })
  return {
    data: data,
  }
}

export const getAllChannelsPaymentsCSV = async (
  from: string,
  to: string,
  status: string,
  search: string,
  merchant: string
): Promise<unknown> => {
  const data = await requestApi({
    url: '/v2/channel/payment',
    method: 'GET',
    params: {
      export: true,
      format: 'CSV',
      fromDate: from,
      toDate: to,
      ...(status !== 'ALL' && { status }),
      ...(merchant !== 'ALL' && { merchantId: merchant }),
      ...(search && { q: encodeURI(search) }),
    },
  })
  return {
    data: data,
  }
}

export const getPaymentSupportCases = (
  paging: Paging,
  status?: PaymentCaseType[]
): Promise<PaymentCasesResponse> => {
  const { offset, max } = paging

  return requestApi({
    url: '/salesforce-facade/v1/case-management/cases',
    method: 'GET',
    params: {
      ...(max && { max }),
      offset,
      order: 'desc',
      ...(status?.length && { status: status?.join(';') }),
    },
  })
}

export const getPaymentCaseDocuments = (
  paymentCaseId: string
): Promise<PaymentCaseDocumentsResponse> => {
  return requestApi({
    url: '/salesforce-facade/v1/case-management/documents',
    method: 'GET',
    params: {
      ...(paymentCaseId && { caseId: paymentCaseId }),
      objectName: 'file',
      order: 'asc',
    },
  })
}

export const getPaymentCaseEmails = (paymentCaseId: string): Promise<PaymentCaseEmailsResponse> => {
  return requestApi({
    url: '/salesforce-facade/v1/case-management/emails',
    method: 'GET',
    params: {
      ...(paymentCaseId && { caseId: paymentCaseId }),
      objectName: 'email',
      order: 'desc',
    },
  })
}

export const getPaymentCaseDocument = (paymentDocumentId: string) => {
  return requestApi({
    url: `/salesforce-facade/v1/case-management/documents/${paymentDocumentId}`,
    method: 'GET',
    config: {
      responseType: 'arraybuffer',
    },
  })
}

export const postPaymentCaseEmail = (email: PaymentCaseSendEmail) => {
  return requestApi({
    url: '/salesforce-facade/v1/case-management/emails',
    method: 'POST',
    data: email,
  })
}

export const postPaymentCaseDocument = (document: PaymentCaseUploadDocument) => {
  return requestApi({
    url: '/salesforce-facade/v1/case-management/documents',
    method: 'POST',
    data: document,
    timeout: 120000, // 2 minutes
  })
}

export const putPaymentSupportCase = (paymentCaseUpdate: PaymentCaseUpdate) => {
  return requestApi({
    url: `/salesforce-facade/v1/case-management/cases/${paymentCaseUpdate?.caseId}`,
    method: 'PUT',
    data: paymentCaseUpdate?.customer,
  })
}
