import React, { Dispatch, RefObject, SetStateAction } from 'react'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box as MenuListBox, Box as PanelBox } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { appBannersId } from 'components/app/AppBanners'
import { PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { useMappedRoles } from 'hooks/useMappedRoles'
import {
  bvnkNetworkConnectionsAtom,
  isBvnkNetworkEnabledAtom,
} from 'pages/BvnkNetwork/atoms/bvnkNetworkAtoms'
import { isProduction } from 'utils/environment'
import SidebarHeader from './Header'
import SideBarLink from './SidebarLink'

interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  sidebarRef: RefObject<HTMLDivElement>
}

const SideBar = ({ sidebarOpen, setSidebarOpen, sidebarRef }: SidebarProps) => {
  const bvnkNetworkConnections = useAtomValue(bvnkNetworkConnectionsAtom)
  const isBvnkNetworkEnabled = useAtomValue(isBvnkNetworkEnabledAtom)

  const { t } = useTranslation()
  const {
    enableApprovalFlowAccountLevel,
    enableMassPayouts,
    enableBvnkNetwork,
    enableUnderlyingCustomer,
  } = useFeatureFlags()

  const bannerSpacing = useComponentHeight(appBannersId)

  const permissions = useMappedRoles()

  const { isVerifiedBusinessAccount, isDeveloperAccount } = useAccountsContext()

  const canDisable = !isProduction && isDeveloperAccount ? false : !isVerifiedBusinessAccount

  const links = [
    ...(!isDeveloperAccount
      ? [
          {
            to: `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`,
            title: t('wallets.title'),
            icon: <Icon name="WalletIcon" size="lg" color="inherit" />,
            requiresVerification: canDisable,
            sideBarToggleCallback: setSidebarOpen,
            isHidden: !permissions.wallets.canView,
            testId: 'sidebar-wallets',
          },
        ]
      : [
          {
            to: '/',
            title: t('home'),
            icon: <Icon name="HomeIcon" size="lg" color="inherit" />,
            requiresVerification: false,
            sideBarToggleCallback: setSidebarOpen,
            testId: 'sidebar-home',
          },
        ]),
    ...(isDeveloperAccount
      ? [
          {
            to: `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`,
            title: t('wallets.title'),
            icon: <Icon name="WalletIcon" size="lg" color="inherit" />,
            requiresVerification: canDisable,
            sideBarToggleCallback: setSidebarOpen,
            isHidden: !permissions.wallets.canView,
            testId: 'sidebar-wallets',
          },
        ]
      : [
          {
            to: PathNames.TRANSACTION_HISTORY,
            title: t('wallets.transactionHistory'),
            icon: <Icon name="TransactionsIcon" size="lg" color="inherit" />,
            requiresVerification: canDisable,
            sideBarToggleCallback: setSidebarOpen,
            isHidden: !permissions.wallets.canView,
            testId: 'sidebar-transaction-history',
          },
        ]),
    ...(isDeveloperAccount
      ? [
          {
            to: PathNames.TRANSACTION_HISTORY,
            title: t('wallets.transactionHistory'),
            icon: <Icon name="TransactionsIcon" size="lg" color="inherit" />,
            requiresVerification: canDisable,
            sideBarToggleCallback: setSidebarOpen,
            isHidden: !permissions.wallets.canView,
            testId: 'sidebar-transaction-history',
          },
        ]
      : []),

    {
      to: '/payments',
      title: t('payments.title'),
      icon: <Icon name="PaymentIcon" size="lg" color="inherit" />,
      requiresVerification: canDisable,
      sideBarToggleCallback: setSidebarOpen,
      featureName: 'payments',
      isHidden: !permissions.payments.canView,
      testId: 'sidebar-payments',
      items: [
        {
          title: t('sidebar.paymentLinks'),
          to: `${PathNames.PAYMENTS}${PathNames.PAYMENTS_LINKS}`,
          testId: 'sidebar-payments-links',
        },
        {
          title: t('channels'),
          to: `${PathNames.PAYMENTS}${PathNames.CHANNELS_LINKS}`,
          testId: 'sidebar-payments-channels',
        },
      ],
    },
    ...(enableMassPayouts
      ? [
          {
            to: PathNames.MASS_PAYOUTS,
            icon: <Icon name="BoxArrowUpIcon" size="lg" color="inherit" />,
            requiresVerification: false,
            sideBarToggleCallback: setSidebarOpen,
            title: t('massPayouts.massPayouts'),
            testId: 'sidebar-mass-payouts',
          },
        ]
      : []),
    ...(enableBvnkNetwork && isBvnkNetworkEnabled
      ? [
          {
            to: PathNames.BVNK_NETWORK,
            icon: <Icon name="NetworkLightingIcon" size="lg" color="inherit" />,
            requiresVerification: false,
            sideBarToggleCallback: setSidebarOpen,
            title: t('bvnkNetwork.title'),
            testId: 'sidebar-bvnk-network',
            highlight: bvnkNetworkConnections?.received?.length,
          },
        ]
      : []),
    ...(enableUnderlyingCustomer
      ? [
          {
            to: PathNames.CUSTOMERS,
            icon: <Icon name="MerchantIcon" size="lg" color="inherit" />,
            requiresVerification: false,
            sideBarToggleCallback: setSidebarOpen,
            title: t('customers.sidebarTitle'),
            testId: 'sidebar-customers',
          },
        ]
      : []),
    ...(enableApprovalFlowAccountLevel
      ? [
          {
            to: `${PathNames.APPROVALS}`,
            title: t('approvals.title'),
            icon: <Icon name="TimerIcon" size="lg" color="inherit" />,
            requiresVerification: false,
            sideBarToggleCallback: setSidebarOpen,
            testId: 'sidebar-approvals',
          },
        ]
      : []),
    {
      to: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      title: t('manageAccount'),
      icon: <Icon name="SettingIcon" size="lg" color="inherit" />,
      requiresVerification: false,
      sideBarToggleCallback: setSidebarOpen,
      testId: 'sidebar-manage-account',
    },
  ]

  return (
    <PanelBox height="full" minHeight="screen" className="lg:w-64">
      <div
        className={clsx(
          'fixed inset-0 z-40 lg:-twhidden lg:z-auto transition-opacity duration-200',
          {
            'bg-text-900 opacity-40': sidebarOpen,
            'opacity-0 pointer-events-none': !sidebarOpen,
          }
        )}
        style={{ top: `${bannerSpacing}px` }}
        aria-hidden="true"
      ></div>
      <div
        ref={sidebarRef}
        id="sidebar"
        className={`absolute z-40 left-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-full overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 transition-transform duration-200 ease-in-out bg-primary-900
        ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
        style={{ top: `${bannerSpacing}px` }}
      >
        <SidebarHeader sidebarOpen={sidebarOpen} toggleCallback={setSidebarOpen} />

        {/* Links */}
        <MenuListBox padding={16}>
          <ul className="mt-3">
            {links?.map(
              ({
                to,
                title,
                icon,
                requiresVerification,
                sideBarToggleCallback,
                isHidden,
                items,
                testId,
                highlight,
              }) => (
                <SideBarLink
                  key={to}
                  to={to}
                  title={title}
                  icon={icon}
                  requiresVerification={requiresVerification}
                  sideBarToggleCallback={sideBarToggleCallback}
                  isHidden={isHidden ?? false}
                  items={items}
                  testId={testId}
                  highlight={highlight}
                />
              )
            )}
          </ul>
        </MenuListBox>
      </div>
    </PanelBox>
  )
}

export default SideBar
