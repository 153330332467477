import { useEffect, useRef } from 'react'

/* this check if a component unmounts. Generally we need to use it when we set the state in a useEffect 
and to an additinal check that tha component is mounted. It is needed in React 17, 
but we can safely remove this when will upgrade to React 18 */
export const useMounted = () => {
  const mountedRef = useRef(true)
  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])
  return mountedRef.current
}
