import React, { createContext, useMemo, useState } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { ApiKey } from 'types/apiKeys'

interface ApiKeyCreateModalProps {
  showCreateModal: boolean
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  show2FAModal: boolean
  setShow2FAModal: React.Dispatch<React.SetStateAction<boolean>>
  showDeleteModal: boolean
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  from2FA: boolean
  setFrom2FA: React.Dispatch<React.SetStateAction<boolean>>
  showManageModal: boolean
  setShowManageModal: React.Dispatch<React.SetStateAction<boolean>>
  apiKeyData: ApiKey | null
  setApiKeyData: React.Dispatch<React.SetStateAction<ApiKey | null>>
  enablePayrollRole: boolean
}

export const ApiKeyModalContext = createContext<ApiKeyCreateModalProps>(null)

export const ApiKeyModalProvider = ({ children, createModal = false }) => {
  const [showCreateModal, setShowCreateModal] = useState(createModal)
  const [show2FAModal, setShow2FAModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [from2FA, setFrom2FA] = useState(false)
  const [showManageModal, setShowManageModal] = useState(null)
  const [apiKeyData, setApiKeyData] = useState<ApiKey>(null)
  const { enablePayrollRole } = useFeatureFlags()

  const value = useMemo(
    () => ({
      showCreateModal,
      setShowCreateModal,
      show2FAModal,
      setShow2FAModal,
      showDeleteModal,
      setShowDeleteModal,
      from2FA,
      setFrom2FA,
      showManageModal,
      setShowManageModal,
      apiKeyData,
      setApiKeyData,
      enablePayrollRole,
    }),
    [
      showCreateModal,
      show2FAModal,
      showDeleteModal,
      from2FA,
      showManageModal,
      apiKeyData,
      enablePayrollRole,
    ]
  )

  return <ApiKeyModalContext.Provider value={value}>{children}</ApiKeyModalContext.Provider>
}
