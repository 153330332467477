import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@node-space/storybook-components/dist/Icon'
import Modal from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { AddCustomer } from './AddCustomer'

interface CustomersEmptyStateEmptyStateProps {
  isAddModalOpen: boolean
  handleCloseAddModal: () => void
  handleOnSuccessCloseModal: () => void
  onAddNewCustomer: () => void
}
const CustomersEmptyState = ({
  isAddModalOpen,
  handleCloseAddModal,
  handleOnSuccessCloseModal,
  onAddNewCustomer,
}: CustomersEmptyStateEmptyStateProps) => {
  const { t } = useTranslation()

  return (
    <>
      <StatusCard
        headline={t('customers.noCustomersHeadline')}
        description={t('customers.addNewCustomerDescription')}
        cardButton={{
          onClick: onAddNewCustomer,
          name: t('customers.newCustomer'),
          iconElement: <Icon name="PlusIcon" size="default" color="white" />,
        }}
      />
      <Modal allowOverFlow visible={isAddModalOpen} closeModal={() => false}>
        <AddCustomer
          handleCloseModal={handleCloseAddModal}
          handleOnSuccessCloseModal={handleOnSuccessCloseModal}
        />
      </Modal>
    </>
  )
}

export default CustomersEmptyState
