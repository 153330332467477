import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Section } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { SidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { SmallTabs } from '@node-space/storybook-components/dist/SmallTabs'
import { appBannersId } from 'components/app/AppBanners'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { SearchTransactionContent } from 'types/transaction'
import { highlightRowBasedOnId } from 'utils/table/table'
import { useTransactionDetailsTransformer } from '../hooks/useTransactionDetailsTransformer'
import { SidePanelBody } from './SidePanelBody'
import { SidePanelHeaderTransactionHistory } from './SidePanelHeaderTransactionHistory'

interface SidePanelSummaryProps {
  isOpen: boolean
  paging: PagingSidePanelType
  onClose: () => void
  transactionDetails: SearchTransactionContent
}

export const SidePanelSummary = ({
  isOpen,
  paging,
  onClose,
  transactionDetails,
}: SidePanelSummaryProps) => {
  const { t } = useTranslation()
  const bannerSpacing = useComponentHeight(appBannersId)

  const {
    amountWithCurrency,
    iconUrls,
    transactionType,
    transactionSubType,
    customerReference,
    transactionHash,
    transactionDate,
    transformedData,
    name,
  } = useTransactionDetailsTransformer(transactionDetails, t)

  const smallTabsId = `${customerReference}-${transactionHash}-${transactionDate}`
  const [activeId, setActiveId] = useState(smallTabsId)

  useEffect(() => {
    setActiveId(smallTabsId)
  }, [activeId])

  return (
    <SidePanel
      isOpen={isOpen}
      paging={paging}
      onClose={onClose}
      activeRowId={transactionDetails?.hash}
      onActiveRowChange={highlightRowBasedOnId}
      externalStyle={{ top: `${bannerSpacing}px` }}
      testid="transaction-details-sidepanel"
      sidePanelHeader={{
        sidePanelHeaderTemplate: (
          <SidePanelHeaderTransactionHistory
            data={{
              iconUrls,
              amountWithCurrency,
              transactionType,
              transactionSubType,
              customerReference,
              transformedData,
              name,
            }}
          />
        ),
      }}
    >
      <Section tag="section" full background="white">
        <SmallTabs
          currentValueId={activeId}
          tabs={[
            {
              id: activeId,
              tabHeader: t('overview'),
              tabContent: <SidePanelBody transactionDetails={transactionDetails} />,
            },
          ]}
        />
      </Section>
    </SidePanel>
  )
}
