import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import Radio from '@node-space/storybook-components/dist/Radio'
import { AddBeneficiaryFormErrors, BaseFormProps, USDBeneficiaryOptions } from 'types/beneficiaries'
import { usdBeneficiaryBankTypeAtom } from '../../atoms/beneficiaryAtoms'
import { DEFAULT_CBIT_BANK_CODE } from '../schemas/fields/usdSpecificFields'

interface UsdFormFieldsProps extends BaseFormProps {
  abaRoutingNumber: string
  cbitAddress: string
  cbitBankCode: string
  errors: AddBeneficiaryFormErrors
  usdAccountNumber: string
}

export const UsdFormFields = ({
  abaRoutingNumber,
  cbitAddress,
  cbitBankCode,
  errors,
  usdAccountNumber,
  handleChange,
  register,
}: UsdFormFieldsProps) => {
  const { t } = useTranslation()
  const [beneficiaryBankType, setBeneficiaryBankType] = useAtom(usdBeneficiaryBankTypeAtom)

  useEffect(() => {
    if (beneficiaryBankType === USDBeneficiaryOptions.CBIT) {
      handleChange('cbitBankCode', DEFAULT_CBIT_BANK_CODE)
    } else {
      handleChange('cbitBankCode', '')
    }
  }, [beneficiaryBankType])

  const onBeneficiaryTypeSelection = (type: USDBeneficiaryOptions) => {
    if (type === USDBeneficiaryOptions.CBIT) {
      handleChange('usdAccountNumber', '')
    } else {
      handleChange('cbitAddress', '')
    }
    setBeneficiaryBankType(type)
  }

  return (
    <>
      <Box paddingB={16}>
        <Radio
          height={36}
          name="currencyType"
          label={t('manageBeneficiaries.selectPaymentMethod')}
          onChange={onBeneficiaryTypeSelection}
          options={[
            {
              label: t('manageBeneficiaries.cbit'),
              value: USDBeneficiaryOptions.CBIT,
            },
          ]}
          value={beneficiaryBankType}
        />
      </Box>
      <Box flex flexRow gapX={12} paddingB={16}>
        {beneficiaryBankType === USDBeneficiaryOptions.CBIT ? (
          <Box flex width="full" direction="col" gapY={16}>
            <Input
              {...register('cbitAddress')}
              label={t('cbitAddress')}
              placeholder={t('manageBeneficiaries.enterWalletAddress')}
              inputMode="text"
              name="cbitAddress"
              testid="input-cbit-wallet-address"
              errorText={errors?.cbitAddress?.message || errors?.accountNumber?.message}
              error={!!errors?.cbitAddress || !!errors?.accountNumber}
              value={cbitAddress}
              onChange={event => handleChange('cbitAddress', event?.target?.value)}
            />
            <Input
              {...register('cbitBankCode')}
              label={t('bankCode')}
              inputMode="text"
              name="cbitBankCode"
              testid="input-cbit-bank-code"
              errorText={errors?.cbitBankCode?.message}
              error={!!errors?.cbitBankCode}
              value={cbitBankCode}
              disabled
            />
          </Box>
        ) : (
          <>
            <Input
              {...register('usdAccountNumber')}
              label={t('accountNumber')}
              placeholder={t('enterAccountNumber')}
              inputMode="text"
              name="usdAccountNumber"
              testid="input-usd-account-number"
              errorText={errors?.usdAccountNumber?.message || errors?.accountNumber?.message}
              error={!!errors?.usdAccountNumber || !!errors?.accountNumber}
              value={usdAccountNumber}
              onChange={event => handleChange('usdAccountNumber', event?.target?.value)}
            />
            <Input
              {...register('abaRoutingNumber')}
              label={t('abaRoutingNumber')}
              placeholder={t('enterRoutingNumber')}
              inputMode="text"
              name="abaRoutingNumber"
              testid="input-aba-routing-number"
              errorText={errors?.abaRoutingNumber?.message}
              error={!!errors?.abaRoutingNumber}
              value={abaRoutingNumber}
              onChange={event => handleChange('abaRoutingNumber', event?.target?.value)}
            />
          </>
        )}
      </Box>
    </>
  )
}
