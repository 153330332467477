import React from 'react'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { EditChannelReq } from 'types/transaction/GetChannelsReq'

interface ReferenceInputFieldProps {
  reference: string
  errors?: Partial<
    FieldErrorsImpl<{
      uuid: string
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >
  register: UseFormRegister<EditChannelReq>
}
const ReferenceInputField = ({ errors, register, reference }: ReferenceInputFieldProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper className="w-full mb-2">
      <Input
        label={t('payments.channels.channelReference')}
        inputMode="text"
        placeholder={t('uniqueReference')}
        name="reference"
        testid="unique-reference"
        errorText={errors?.reference?.message || errors?.uuid?.message}
        error={!!errors?.reference || !!errors?.uuid}
        {...register('reference', { value: reference })}
      />
    </Wrapper>
  )
}

export default ReferenceInputField
