import React, { useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useFeatureFlags } from '@node-space/hooks'
import { logError } from '@node-space/utils'
import { useBeneficiariesQuery } from 'hooks/queries/useBeneficiariesQuery'
import {
  PayoutsFeeEstimateParams,
  usePayoutsFeeEstimateQuery,
} from 'hooks/queries/usePayoutsFeeEstimateQuery'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { getWalletLimits } from 'services/WalletsService'
import { BeneficiaryDetails, BeneficiaryType } from 'types/beneficiaries'
import { Currencies, Limits, WalletType } from 'types/types'
import { PayFee, WithdrawSteps } from 'types/wallets'
import { getWithdrawV2Request } from 'utils/withdraw'
import { WalletTabProps } from '../Modal/WalletModal'
import { selectedFiatBeneficiaryAtom } from '../Pay/atoms/atoms'
import Withdraw2fa from './Withdraw2fa'
import WithdrawComplete from './WithdrawComplete'
import WithdrawConfirmV2 from './WithdrawConfirmV2'
import WithdrawFormV2 from './WithdrawFormV2'

export type WithdrawV2Form = {
  walletId?: string
  password?: string
  bankAccountId?: string
  amount?: string | number
  beneficiaryReference?: string
  reference?: string
  currencyCode?: string
  challenge?: string
}

export type WithdrawActionV2Props = WalletTabProps &
  IWalletActionApiType<WithdrawV2Form, WithdrawSteps> & {
    withdrawalLimits?: Limits
    beneficiaries?: BeneficiaryDetails[]
    isBeneficiaryListError?: boolean
    withdrawalFee?: PayFee
    hasWithdrawalFeeError?: boolean
    isFetchingFee: boolean
  }

/**
 * Withdraw Tab containing state, form and API data for each step
 */
export const WithdrawV2 = ({
  loading,
  step,
  form,
  wallets,
  setLoading,
  ...otherProps
}: WithdrawActionV2Props) => {
  const { enablePayoutsV2 } = useFeatureFlags()
  const selectedFiatBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const [isLimitsLoading, setIsLimitsLoading] = useState(false)
  const [withdrawalLimits, setWithdrawalLimits] = useState<Limits>()
  const [selectedWallet, setSelectedWallet] = useState<WalletType>()
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryDetails[]>()
  const formValues = form.watch()

  const isPayoutsV2Enabled =
    enablePayoutsV2 && !!selectedWallet?.supportedTransferDestinations?.length

  const isEnabled = isPayoutsV2Enabled
    ? !!formValues?.walletId && !!formValues.amount && !!formValues?.reference
    : !!formValues?.walletId && !!formValues.amount

  const request = getWithdrawV2Request(formValues, selectedWallet, isPayoutsV2Enabled)
  const payoutFeeEstimateParams: PayoutsFeeEstimateParams = {
    request,
    isEnabled,
    isThirdPartyPayment: false,
    isPayoutFeesV2: isPayoutsV2Enabled,
    transferDestination: selectedFiatBeneficiary?.transferDestination,
  }

  const {
    data: withdrawalFee,
    isError: isWithdrawalFeeError,
    isFetching: isFetchingFee,
  } = usePayoutsFeeEstimateQuery(payoutFeeEstimateParams)

  useEffect(() => {
    const wallet = wallets?.find?.(wallet => wallet?.id?.toString() === formValues?.walletId)
    setSelectedWallet(wallet)
  }, [formValues?.walletId])

  const {
    isError: isBeneficiaryListError,
    isFetching: isBeneficiariesLoading,
    refetch: refetchBeneficiaryList,
    data: beneficiaryResponse,
  } = useBeneficiariesQuery(
    {
      beneficiaryType: BeneficiaryType.FIRST_PARTY,
      currency: selectedWallet?.currency?.code as Currencies,
    },
    false
  )

  const props = {
    withdrawalLimits,
    beneficiaries,
    isBeneficiaryListError,
    loading: loading || isFetchingFee,
    step,
    form,
    setLoading,
    wallets,
    withdrawalFee: withdrawalFee,
    hasWithdrawalFeeError: isWithdrawalFeeError,
    ...otherProps,
  }

  useEffect(() => {
    if (selectedWallet && beneficiaries && !isBeneficiaryListError) {
      const beneficiaryReference = beneficiaries?.find(
        wallet => wallet?.currency === selectedWallet?.currency?.code
      )?.reference
      if (beneficiaryReference) {
        form.setValue('beneficiaryReference', beneficiaryReference)
      }
    }
  }, [selectedWallet, beneficiaries, isBeneficiaryListError])

  useEffect(() => {
    form.setValue('currencyCode', selectedWallet?.currency?.code)
  }, [selectedWallet?.currency?.code])

  /**
   * Get withdrawal limits for selected curreny code
   */
  useEffect(() => {
    if (selectedWallet && beneficiaries) {
      if (selectedWallet && !isLimitsLoading) {
        setIsLimitsLoading(true)
        setWithdrawalLimits(null)

        const beneficiaryReference = beneficiaries?.find?.(
          wallet => wallet?.currency === selectedWallet?.currency?.code
        )?.reference

        form.setValue('beneficiaryReference', beneficiaryReference)

        // get withdrawal limits for selected currency
        getWalletLimits(selectedWallet?.currency?.code, selectedWallet?.id)
          .then(currencyLimits => {
            const limits = currencyLimits?.withdrawalLimits?.current
            limits && setWithdrawalLimits(limits)
          })
          .catch(err => {
            logError('Error from Withdraw - getWalletLimits', err)
          })
          .finally(() => {
            setLoading(false)
            setIsLimitsLoading(false)
          })
      }
    }
  }, [selectedWallet, beneficiaries, isLimitsLoading])

  const isBusy = useMemo(
    () => loading || isLimitsLoading || isBeneficiariesLoading,
    [loading, isLimitsLoading, isBeneficiariesLoading]
  )

  useEffect(() => {
    selectedWallet?.currency?.code &&
      refetchBeneficiaryList().then(response => {
        setBeneficiaries(response?.data?.content)
      })
  }, [selectedWallet?.currency?.code])

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case '2fa':
        return <Withdraw2fa {...props} />
      case 'form':
        return (
          <WithdrawFormV2
            {...props}
            loading={isBusy}
            isFetchingFee={isFetchingFee}
            beneficiaries={beneficiaryResponse?.content}
          />
        )
      case 'confirm':
        return <WithdrawConfirmV2 {...props} />
      case 'complete':
        return <WithdrawComplete {...props} />
    }
  }

  return renderStep()
}
