import React, { useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Loader from '@node-space/storybook-components/dist/Loader'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import Text from '@node-space/storybook-components/dist/Text'
import TwoFAField, { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { Protocol } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { AddCryptoAddressFormData } from './AddCryptoAddressModal'

interface ConfirmStepData {
  selectedCryptocurrencyProtocols?: Protocol[]
  cryptocurrency: string
  addressName: string
  address: string
  destinationTag?: string
  reason?: string
  protocol?: string
}

interface ConfirmStepUtils {
  twoFactorCode: string
  twoFactorCodeError?: string
  handleTwoFactorCodeChange: (value: string) => void
  addCryptoAddressAction: SubmitHandler<AddCryptoAddressFormData>
  addCryptoAddressIsProcessing: boolean
  whitelistAddressError?: string
}

interface StepConfirmCryptoAddressFormProps {
  confirmStepData: ConfirmStepData
  confirmStepUtils: ConfirmStepUtils
}

const StepConfirmCryptoAddressForm = ({
  confirmStepData,
  confirmStepUtils,
}: StepConfirmCryptoAddressFormProps) => {
  const { t } = useTranslation()

  const {
    cryptocurrency = '',
    protocol = '',
    selectedCryptocurrencyProtocols = [],
    addressName = '',
    address = '',
    destinationTag = '',
    reason = '',
  } = confirmStepData

  const {
    twoFactorCode = '',
    twoFactorCodeError = '',
    handleTwoFactorCodeChange,
    addCryptoAddressAction,
    addCryptoAddressIsProcessing,
    whitelistAddressError = '',
  } = confirmStepUtils

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.ADD_CRYPTO_ADDRESS_WHITELIST_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  useEffect(() => {
    if (twoFactorCode?.length === TWO_FA_REQUIRED_LENGTH) {
      addCryptoAddressAction.call(addCryptoAddressAction)
    }
  }, [twoFactorCode])

  return (
    <>
      {whitelistAddressError && (
        <Box paddingX={24} paddingT={12}>
          <Callout state="error" message={whitelistAddressError} showCloseButton={false} />
        </Box>
      )}
      <SummaryDetail>
        <SummaryRow
          label={t('manageCryptoAddresses.modal.cryptocurrencyLabel')}
          testid="cryptocurrency"
          description={cryptocurrency}
        />
        {(protocol || selectedCryptocurrencyProtocols?.length > 1) && (
          <SummaryRow
            label={t('manageCryptoAddresses.modal.protocolLabel')}
            testid="protocol"
            description={protocol || selectedCryptocurrencyProtocols?.[0]?.code}
          />
        )}
        <SummaryRow
          label={t('manageCryptoAddresses.modal.addressNameLabel')}
          testid="address-name"
          description={addressName}
        />
        <SummaryRow
          label={t('manageCryptoAddresses.modal.addressLabel')}
          testid="address"
          description={address}
        />
        {destinationTag && (
          <SummaryRow
            label={t('destinationTag')}
            testid="destinationTag"
            description={destinationTag}
          />
        )}
        {reason && (
          <SummaryRow
            label={t('manageCryptoAddresses.modal.reasonLabel')}
            testid="reason"
            description={reason}
          />
        )}
      </SummaryDetail>

      <Box className="relative bottom-5" padding={24}>
        <Text size="sm" weight="medium">
          {t('authCodeConfirm')}
        </Text>
        {addCryptoAddressIsProcessing ? (
          <Box paddingT={24} flex flexJustifyCenter>
            <Loader size="medium" />
          </Box>
        ) : (
          <TwoFAField error={twoFactorCodeError} onChange={handleTwoFactorCodeChange} />
        )}
      </Box>
    </>
  )
}

export default StepConfirmCryptoAddressForm
