import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AddBeneficiaryFormErrors, BaseFormProps } from 'types/beneficiaries'

interface BeneficiaryAddressFieldsProps extends BaseFormProps {
  addressLine1: string
  addressLine2: string
  city: string
  errors: AddBeneficiaryFormErrors
  postCode: string
  region: string
}

export const BeneficiaryAddressFields = ({
  addressLine1,
  addressLine2,
  city,
  errors,
  postCode,
  region,
  handleChange,
  register,
}: BeneficiaryAddressFieldsProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box flex flexRow gapX={12} paddingB={16}>
        <Input
          {...register('addressLine1')}
          label={t('addressLine1')}
          inputMode="text"
          name="addressLine1"
          testid="input-address-line-1"
          errorText={errors?.addressLine1?.message}
          error={!!errors?.addressLine1}
          value={addressLine1}
          onChange={event => handleChange('addressLine1', event?.target?.value)}
          placeholder={t('inputPlaceholders.enterAddressLine1Placeholder')}
        />
      </Box>
      <Box flex flexRow gapX={12} paddingB={16}>
        <Input
          {...register('addressLine2')}
          label={t('addressLine2')}
          inputMode="text"
          name="addressLine2"
          testid="input-address-line-2"
          errorText={errors?.addressLine2?.message}
          error={!!errors?.addressLine2}
          value={addressLine2}
          onChange={event => handleChange('addressLine2', event?.target?.value)}
          placeholder={t('inputPlaceholders.enterAddressLine2Placeholder')}
        />
      </Box>
      <Box flex flexRow gapX={12} paddingB={16}>
        <Input
          {...register('city')}
          label={t('city')}
          inputMode="text"
          name="city"
          testid="input-city"
          errorText={errors?.city?.message}
          error={!!errors?.city}
          value={city}
          onChange={event => handleChange('city', event?.target?.value)}
          placeholder={t('inputPlaceholders.enterCityPlaceholder')}
        />
        <Input
          {...register('postCode')}
          label={t('postCode')}
          inputMode="text"
          name="postCode"
          testid="input-post-code"
          errorText={decodeURI(encodeURI(errors?.postCode?.message))}
          error={!!errors?.postCode}
          value={postCode}
          onChange={event => handleChange('postCode', event?.target?.value)}
          placeholder={t('inputPlaceholders.enterPostalCodePlaceholder')}
        />
      </Box>
      <Box flex flexRow paddingB={16}>
        <Input
          {...register('region')}
          label={t('region')}
          inputMode="text"
          name="region"
          testid="input-region"
          errorText={decodeURI(encodeURI(errors?.region?.message))}
          error={!!errors?.region}
          value={region}
          onChange={event => handleChange('region', event?.target?.value)}
          placeholder={t('inputPlaceholders.enterRegionPlaceholder')}
        />
      </Box>
    </Box>
  )
}
