import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAllCryptoMassPayouts } from 'services/MasspayoutService'

export const useGetCryptoMassPayoutList = (enabled: boolean = true) => {
  return useQuery({
    queryKey: reactQueryKeys.massCryptoPayoutsList(),
    queryFn: () => getAllCryptoMassPayouts(),
    enabled,
    placeholderData: keepPreviousData,
  })
}
