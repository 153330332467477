import React, { useEffect } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { UpdateBeneficiaryFormPayload } from 'types/beneficiaries'
import { EditBeneficiarySummaryDetails } from './EditBeneficiarySummaryDetails'

type EditBeneficiaryConfirmProps = {
  beneficiaryUpdatedDetails: UpdateBeneficiaryFormPayload
  isOtpRequestError: boolean
  isLoading: boolean
  password: string
  onEditSubmit: () => void
  register: UseFormRegister<UpdateBeneficiaryFormPayload>
  setValue: (name: string, value: string) => void
}

export const EditBeneficiaryConfirm = ({
  beneficiaryUpdatedDetails,
  isOtpRequestError,
  isLoading,
  password,
  onEditSubmit,
  register,
  setValue,
}: EditBeneficiaryConfirmProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (password?.length === TWO_FA_REQUIRED_LENGTH) {
      onEditSubmit?.()
    }
  }, [password])

  return (
    <Box flex direction="col" paddingX={24} paddingY={20}>
      <EditBeneficiarySummaryDetails beneficiaryUpdatedDetails={beneficiaryUpdatedDetails} />
      <Box paddingT={24} paddingB={12}>
        {isLoading ? (
          <Box flex justifyContent="center">
            <Loader size="medium" />
          </Box>
        ) : (
          <Input2FA
            {...register('password')}
            label={t('authCodeConfirm')}
            onChange={value => setValue('password', value)}
            testid="input-2fa"
            error={isOtpRequestError}
            errorText={t('incorrectOtpCode')}
          />
        )}
      </Box>
    </Box>
  )
}
