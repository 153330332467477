import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from '@node-space/storybook-components/dist/Icon'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Approval, PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { ApprovalDecision, ApprovalStatuses } from 'types/approvals'
import { useApprovalDecisionCopyTranslation } from './hooks/useApprovalDecisionCopyTranslation'

interface ApprovalDecisionSuccessProps {
  decision: ApprovalDecision
  staleSelectedItems?: unknown[]
}

const ApprovalDecisionSuccess = ({
  decision,
  staleSelectedItems,
}: ApprovalDecisionSuccessProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { currentAccount } = useAccountsContext()

  const currentAccountReference = currentAccount?.reference || ''
  const {
    approvalsConfig: { handleApprovalDecisionModal },
  } = useApprovalsContext()

  const {
    decisionHeadline = '',
    decisionDescription = '',
    decisionAction = '',
  } = useApprovalDecisionCopyTranslation(decision, staleSelectedItems, t)

  return (
    <>
      <StatusCard
        customIcon={
          decision === Approval.DECISION.APPROVE ? (
            <Icon color="inherit" name="CheckBadgeIcon" />
          ) : (
            <Icon color="inherit" name="ThumbsDownIcon" />
          )
        }
        headline={decisionHeadline}
        description={decisionDescription}
        borderless
      />
      <ModalActions
        actions={[
          {
            children: t('done'),
            secondary: true,
            onClick: () => {
              handleApprovalDecisionModal({ isVisible: false, isCompleted: true })
              queryClient.invalidateQueries({
                queryKey: reactQueryKeys.approvalsTransactions(
                  currentAccountReference,
                  ApprovalStatuses.PENDING
                ),
              })
              navigate(`${PathNames.APPROVALS}?tab=${ApprovalStatuses.PENDING}`, {
                replace: true,
              })
            },
          },
          {
            children: t('approvals.goToTab', { tabName: decisionAction }),
            onClick: () => {
              handleApprovalDecisionModal({ isVisible: false, isCompleted: true })
              queryClient.invalidateQueries({
                queryKey: reactQueryKeys.approvalsTransactions(
                  currentAccountReference,
                  ApprovalStatuses.PENDING
                ),
              })
              navigate(`${PathNames.APPROVALS}?tab=${decisionAction.toUpperCase()}`, {
                replace: true,
              })
            },
          },
        ]}
      />
    </>
  )
}

export default ApprovalDecisionSuccess
