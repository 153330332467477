import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text, TextColor } from '@node-space/storybook-components/dist/Text'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useBvnkNetworkTransferMutation } from 'hooks/mutations/BvnkNetwork/useBvnkNetworkTransferMutation'
import { usePayoutMutation } from 'hooks/mutations/usePayoutMutation'
import {
  usePayoutsV2PostAuthMutation,
  usePayoutsV2PreAuthMutation,
} from 'hooks/mutations/usePayoutsV2Mutation'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import { NetworkTransferRequest } from 'types/bvnkNetwork'
import { PayoutsV2PostAuthRequest, PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { roundNumberWithCommas } from 'utils/utils'
import { selectedBvnkNetworkBeneficiaryAtom, selectedFiatBeneficiaryAtom } from './atoms/atoms'
import { PayActionProps, PayForm } from './Pay'
import {
  getNetworkTransferRequest,
  getPayRequest,
  getPayV1Request,
  getRecipientName,
} from './utils/payFormUtils'

const errorCodes = {
  INSUFFICIENT_FUNDS: 'insufficientFunds',
  INVALID_RECEIVER: 'invalidReceiver',
}

const PayConfirm = ({
  dismissAction,
  setStep,
  form,
  wallets,
  payFees,
  registerFormField,
}: PayActionProps) => {
  const selectedFiatBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const selectedBvnkNetworkBeneficiary = useAtomValue(selectedBvnkNetworkBeneficiaryAtom)

  const { t } = useTranslation()
  const { twoFactorEnabled } = useTwoFAContext()
  const { currentAccount } = useAccountsContext()
  const { mutate: payBeneficiary, isPending: isSubmittingPayRequest } = usePayoutMutation()

  // Fiat payment
  const { mutate: submitPayPreAuthRequest, isPending: isPayPreAuthRequestLoading } =
    usePayoutsV2PreAuthMutation()
  const { mutate: submitPayPostAuthRequest, isPending: isPayPostAuthRequestLoading } =
    usePayoutsV2PostAuthMutation()

  // Bvnk NetworkTransfer
  const { mutate: submitBvnkNetworkTransfer, isPending: isSubmittingBvnkNetworkTransfer } =
    useBvnkNetworkTransferMutation()

  const { enablePayoutsV2 } = useFeatureFlags()
  const queryClient = useQueryClient()
  const formValues = form.watch()
  const [submitBeneficiary, setSubmitBeneficiary] = useState(false)
  const [authRequest, setAuthRequest] = useState<PayoutsV2PostAuthRequest>(null)

  useEffect(() => {
    submitBeneficiary && onSubmitPostRequest()
  }, [submitBeneficiary])

  const onSubmitPostRequest = () => {
    const payoutsPostrequest: PayoutsV2PostAuthRequest = {
      challenge: authRequest?.challenge,
      tokenResponse: authRequest?.tokenResponse,
    }

    submitPayPostAuthRequest(payoutsPostrequest, {
      onSuccess: () => {
        setStep('complete')
        queryClient.invalidateQueries({ queryKey: ['walletTransactions'] })
      },
      onError: onErrorHandler,
      onSettled: () => {
        setSubmitBeneficiary(false)
      },
    })
  }

  const selectedWallet =
    formValues?.walletId && wallets?.find(x => x?.id?.toString() === formValues?.walletId)

  const isPayoutsV2Enabled =
    enablePayoutsV2 && !!selectedWallet?.supportedTransferDestinations?.length

  const isLoading = isPayoutsV2Enabled
    ? isPayPreAuthRequestLoading || isPayPostAuthRequestLoading || isSubmittingBvnkNetworkTransfer
    : isSubmittingPayRequest || isSubmittingBvnkNetworkTransfer

  const actions: ButtonProps[] = [
    {
      children: t('confirmPayment'),
      type: 'submit',
      loading: isLoading,
      disabled: !formValues?.password,
      testid: t('confirmPayment'),
    },
  ]

  const isBvnkNetworkPayment = !!selectedBvnkNetworkBeneficiary && !selectedFiatBeneficiary

  // only show cancel button if not loading
  !isLoading &&
    !twoFactorEnabled &&
    actions.unshift({ children: t('cancel'), secondary: true, onClick: dismissAction })

  /**
   * Pay funds via API
   */

  const onErrorHandler = (error: BaseErrorResponse) => {
    if (error?.status === 403) {
      form.setError('password', {
        message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
      })
    } else if (!!error?.data?.errorList?.length) {
      const isInsufficientFunds = error?.errorList?.find(
        errorParam => errorParam?.code === errorCodes.INSUFFICIENT_FUNDS
      )

      const isInvalidReceiver = error?.errorList?.find(
        errorParam => errorParam?.code === errorCodes.INVALID_RECEIVER
      )

      if (isInsufficientFunds) {
        form.setError('errorMessage', {
          message: t('payInsufficient'),
        })
      } else if (isInvalidReceiver) {
        form.setError('errorMessage', {
          message: t('paymentError'),
        })
      } else {
        error.data.errorList.forEach(err => {
          form.setError(err?.parameter as keyof PayForm, { message: error?.message })
        })
      }
    } else {
      form.setError('errorMessage', {
        message: t('paymentError'),
      })
    }
  }

  const submitNetworkTransfer = () => {
    const networkTransferRequest: NetworkTransferRequest = getNetworkTransferRequest(
      Number(formValues?.amount),
      selectedWallet?.currency?.code,
      formValues?.reference,
      {
        walletId: selectedWallet?.lsid,
        accountReference: currentAccount?.reference,
      },
      {
        walletId: selectedBvnkNetworkBeneficiary?.details?.wallets?.[0]?.walletId,
        accountReference: selectedBvnkNetworkBeneficiary?.details?.accountReference,
      }
    )

    submitBvnkNetworkTransfer(networkTransferRequest, {
      onSuccess: () => {
        setStep('complete')
        queryClient.invalidateQueries({ queryKey: ['walletTransactions'] })
      },
      onError: (error: BaseErrorResponse) => {
        if (error?.status === 428) {
          form?.setValue('challenge', error?.data?.challenge?.value)
          setSubmitBeneficiary(true)
        } else if (error?.data?.errorList) {
          error.data.errorList.forEach(err => {
            form.setError(err?.parameter as keyof PayForm, { message: error?.message })
          })
        } else {
          form.setError('errorMessage', {
            message: t('paymentError'),
          })
        }
      },
    })
  }

  const submitV2FiatPayment = () => {
    const payoutsPrerequest: PayoutsV2PreAuthRequest = getPayRequest(formValues, selectedWallet)
    submitPayPreAuthRequest(payoutsPrerequest, {
      onError(error: BaseErrorResponse) {
        if (error?.status === 428) {
          setAuthRequest({
            challenge: error?.data?.challenge?.value,
            tokenResponse: formValues?.password,
          })
          form?.setValue('challenge', error?.data?.challenge?.value)
          setSubmitBeneficiary(true)
        } else if (error?.data?.errorList) {
          error.data.errorList.forEach(err => {
            form.setError(err?.parameter as keyof PayForm, { message: error?.message })
          })
        } else if (error?.status === 403) {
          form.setError('password', {
            message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
          })
        } else {
          form.setError('errorMessage', {
            message: t('paymentError'),
          })
        }
      },
    })
  }

  const onSubmitV1: SubmitHandler<typeof formValues> = async () => {
    if (isBvnkNetworkPayment) {
      submitNetworkTransfer()
    } else {
      const payRequest = getPayV1Request(formValues, selectedFiatBeneficiary, twoFactorEnabled)

      payBeneficiary(
        { walletId: selectedWallet?.id?.toString(), payRequest },
        {
          onSuccess: () => {
            setStep('complete')
            queryClient.invalidateQueries({ queryKey: ['walletTransactions'] })
          },
          onError: onErrorHandler,
        }
      )
    }
  }

  const onSubmitV2: SubmitHandler<typeof formValues> = async () => {
    if (isBvnkNetworkPayment) {
      submitNetworkTransfer()
    } else {
      submitV2FiatPayment()
    }
  }

  useEffect(() => {
    const password = formValues?.password
    if (password?.length === TWO_FA_REQUIRED_LENGTH && twoFactorEnabled) {
      if (isPayoutsV2Enabled) {
        onSubmitV2(formValues)
      } else {
        onSubmitV1(formValues)
      }
    }
  }, [formValues?.password])

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.PAY_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
      reference: formValues?.reference?.toLowerCase(),
      beneficiary: recipientName?.toLowerCase(),
      amount: formValues?.amount,
      alias: selectedFiatBeneficiary?.alias,
    })
  }, [])

  const recipientName = getRecipientName(selectedFiatBeneficiary)

  const toBeneficiary = isBvnkNetworkPayment
    ? selectedBvnkNetworkBeneficiary?.details?.name
    : `${recipientName} | ${selectedFiatBeneficiary?.fiat?.accountNumber}`

  return (
    <Box>
      {/* nav bar */}
      <ModalNavBar
        title={t('reviewPayment')}
        {...(!isLoading && { onBack: () => setStep('amount') })}
        onClose={dismissAction}
      />

      {/* error */}
      {form?.formState?.errors?.errorMessage && (
        <Box className="m-6 my-5">
          <Callout message={form.formState.errors.errorMessage.message} state="error" />
        </Box>
      )}

      {/* pay summary */}
      <form onSubmit={form.handleSubmit(isPayoutsV2Enabled ? onSubmitV2 : onSubmitV1)}>
        <ModalScrollable>
          <SummaryDetail>
            <SummaryRow
              label={t('amountToPay')}
              description={`${roundNumberWithCommas(Number(formValues?.amount) + (payFees?.fee ?? 0))} ${
                selectedWallet?.currency?.code
              }`}
              testid="amount-to-pay"
            />
            <SummaryRow
              label={t('fee')}
              description={`${roundNumberWithCommas(payFees?.fee ?? 0)} ${
                selectedWallet?.currency?.code
              }`}
              testid="transaction-fee"
            />
            <SummaryRow
              label={t('amountToBeReceived')}
              description={`${roundNumberWithCommas(formValues?.amount)} ${
                selectedWallet?.currency?.code
              }`}
              testid="total"
            />
            <SummaryRow
              label={t('fromWallet')}
              description={selectedWallet?.description}
              testid="from-account"
            />
            <SummaryRow
              label={t('toBeneficiary')}
              description={toBeneficiary}
              testid="to-recipient"
            />
            <SummaryRow
              label={t('beneficiaryPaymentReference')}
              renderDescription={
                <Text className="break-all" size="sm" color={TextColor.TEXT_500}>
                  {formValues?.reference}
                </Text>
              }
              testid="reference"
            />
          </SummaryDetail>

          <Box className="mx-6 mb-6 space-y-4">
            <Text size="sm" weight="medium" className="text-gray-120">
              {twoFactorEnabled ? t('authCodeConfirm') : t('authPasswordConfirm')}
            </Text>
            {/* security input */}
            {twoFactorEnabled ? (
              <>
                {isLoading ? (
                  <Box flex justifyContent="center">
                    <Loader size="medium" />
                  </Box>
                ) : (
                  <Input2FA
                    {...registerFormField('password')}
                    onChange={value => form.setValue('password', value)}
                    error={!!form.formState?.errors?.password}
                    errorText={t('incorrectOtpCode')}
                  />
                )}
              </>
            ) : (
              <Input
                placeholder={t('password')}
                type="password"
                testid="password"
                disabled={isLoading}
                {...registerFormField('password')}
                onChange={e => {
                  form.setValue('password', e.target.value)
                  form.clearErrors('errorMessage')
                }}
              />
            )}
          </Box>
        </ModalScrollable>

        {/* actions */}
        {!twoFactorEnabled && <ModalActions actions={actions} />}
      </form>
    </Box>
  )
}

export default PayConfirm
