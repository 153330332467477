import { CSATSurveyData } from 'components/CSAT/types'
import { AccountUserGroup } from './accounts'
import { Address, BeneficiaryTransferDestination } from './beneficiaries'
import { WalletStatus } from './wallets'

export const Partner = { JNFX: 'JnFx', BBVA: 'BBVA' } as const
export type Partner = (typeof Partner)[keyof typeof Partner]

export const WalletCapabilities = {
  B2C_PAYOUTS: 'B2C_PAYOUTS',
} as const

export type WalletCapabilities = (typeof WalletCapabilities)[keyof typeof WalletCapabilities]

export interface WalletType {
  approxConvertedAvailable: string
  approxBalance: string
  address: string | null
  alternatives: Alternative[]
  available: number
  balance: number
  capabilities?: WalletCapabilities[]
  currency: Currency
  depositFee: number
  description: string
  id: number
  lookup: null
  protocol: string
  supportsDeposits: boolean
  supportsWithdrawals: boolean
  supportsThirdParty: boolean
  withdrawalFee: number
  isEmoney?: boolean
  partner?: Partner
  lsid?: string
  status?: WalletStatus
  supportedTransferDestinations: BeneficiaryTransferDestination[]
  supportsInternalBvnkNetworkTransfers?: boolean
}

interface Alternative {
  protocol: string
  address: string
  tag?: string
  uri: string
  amount?: number
}

export interface Currency {
  code: string
  depositFee: number
  fiat: boolean
  icon: string
  id: number
  name: string
  options: Options
  pricePrecision: number
  protocols: Protocol[]
  quantityPrecision: number
  supportsDeposits: boolean
  supportsWithdrawals: boolean
  withdrawalFee: number
  withdrawalParameters: unknown[]
}

export interface Options {
  transaction: string
  address: string
  explorer: string
  confirmations: number
}

export interface Paging {
  offset: number
  total: number
  max: number
}

export interface Protocol {
  code: string
  network: string
}

export interface FormFieldsMerchant {
  merchantName: string
  walletId: string
  webhookUrl: string
}

export interface MerchantType {
  id: number
  merchantId: string
  displayName: string
  secret: string
  webhookUrl: string | null
  autoConvertInvalidPayments: boolean
  defaultExpiryMinutes: number
  webhookVersion: number
  wallet: WalletType
  emailRecipients: null
}

export interface AddMerchantPayload {
  displayName: string
  webhookUrl?: string
  wallet: {
    id: number
  }
}

export interface EditMerchantPayload {
  displayName: string
  webhookUrl?: string
  wallet: number
  merchant: MerchantType
}

export type MerchantInfo = {
  merchantId: string
  displayName: string
  wallet: {
    currency: {
      code: string
    }
    id: number
  }
}
export interface ErrorAPI {
  errorList: Error[]
}

export interface ErrorData {
  error: string
  message: string
  status: number
}

export interface Error {
  parameter: string
  code: string
  message: string
}

export interface ErrorResponseV2 {
  code: string
  documentLink: string
  message: string
  status: string
}

export interface FormFieldsAccountUser {
  emailAddress: string
  groups: AccountUserGroup[]
}

export interface SelectOption {
  value: string
  label: string
}

export interface WorkspaceListItem {
  value: string
  label: string
}

export interface WorkspaceListItemId {
  id: string
  name: string
}

export interface FilterListItems {
  order: number
  value: string
  name: string
}

export interface IDateValue {
  startDate: string | null | Date
  endDate: string | null | Date
}

export type LegalAgreementsCallout = { text: string }

export interface GenericFeatureFlags {
  [key: string]: unknown
}

export interface WalletPartnerConfig {
  [property: string]: {
    beneficiaryFilters: {
      pay: BeneficiaryTransferDestination[]
      withdraw: BeneficiaryTransferDestination[]
    }
    walletActions: {
      deposit: boolean
      pay: boolean
      withdraw: boolean
    }
  }
}

export type NamedFeatureFlags = {
  enableFiatCurrencyFilter: EnableFiatCurrencyFilter
  'show-wallet-total': boolean
  enableBanner: EnableBanner
  enableLegalAgreements: boolean
  enableLegalAgreementsCallout: LegalAgreementsCallout
  enableTwoFaAuth: boolean
  enableRestrictDepositCurrencyToCrypto: boolean
  enableSidePanelForWalletTransactions: boolean
  enableSwiftBeneficiaryCreation: boolean
  enableBeneficiaryConsolidation: boolean
  enableUseMigratedPaymentMethods: boolean
  enablePaymentsCsvExport: boolean
  walletPartnerConfig: WalletPartnerConfig
  enableMassPayouts: boolean
  enableCasePortalCustomerId: boolean
  enableCasePortalCompletedCheck: boolean
  enableCasePortalSupportTableUpdate: boolean
  enableCsatSurveyCasePortal: boolean
  enablePayrollRole: boolean
  enableMultiplePaymentMethods: boolean
  enableApprovalFlowAccountLevel: boolean
  enablePayoutsV2: boolean
  enableBvnkNetwork: boolean
  enableApprovalGlobalAccountRule: boolean
  enableCbitBeneficiaryCreation: boolean
  enableMassPayoutsV2: boolean
  enableUnderlyingCustomer?: boolean
  enableMassPayoutsFiat: boolean
}

export type FeatureFlags = GenericFeatureFlags & NamedFeatureFlags

export interface Preferences {
  currency?: string
  showVerifiedBanner?: boolean
  showCSATSurvey?: CSATSurveyData
  companyDescription?: string
}

export interface ProfileCountry {
  name: string
}
export interface Profile {
  id?: string | number
  key?: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  roles?: string[]
  twoFactorEnabled?: boolean
  country?: ProfileCountry
  language?: string
  currencyCode?: string
  loggedIn?: boolean
  viewOnly?: boolean
  roleGroups?: string[]
}

export const EnvironmentTypes = {
  PRODUCTION: 'PRODUCTION',
  SANDBOX: 'SANDBOX',
  STAGING: 'STAGING',
} as const

export type EnvironmentTypes = (typeof EnvironmentTypes)[keyof typeof EnvironmentTypes]

export type URLType = string
export const Currencies = {
  EUR: 'EUR',
  GBP: 'GBP',
  ZAR: 'ZAR',
  NGN: 'NGN',
  USD: 'USD',
} as const
export type Currencies = (typeof Currencies)[keyof typeof Currencies]

export const CountryCodes = {
  NG: 'NG',
  ZA: 'ZA',
  US: 'US',
  UK: 'GB',
}

export type BankAccount = {
  accountDocument?: unknown
  currency: string
  description: string
  id: number
  instantFundingOptions: unknown[]
  options: BankAccountOptions
  otp?: unknown
  type: unknown
  reference?: string
  status?: string
  alias?: string
  beneficiaryPaymentReference?: string
}

export type BankAccountOptions = {
  routingNumber: string
  bankId: string
  countryCode: string
  accountType: string
  bankName: string
  countryName: string
  accountNumber: string
  accountHolderName: string
  iban: string
  bic: string
  description: string
  ukSortCode: string
  ukAccountNumber: string
  beneficiaryBank?: {
    address?: Address
    bic?: string
    name?: string
  }
}

export type Limits = {
  currency: string
  maximum: number
  minimum: number
  reason?: string
}

export type WithdrawalLimits = {
  current: Limits
  daily: Limits
  weekly: Limits
}

export type CurrencyLimits = {
  accountLimits: Limits
  currency: string
  depositLimits: Limits
  minimum: number
  maximum: number
  remaining: number
  withdrawalLimits?: WithdrawalLimits
}

export type EnableFiatCurrencyFilter = {
  supportedDepositCurrencies: string[]
  supportedWithdrawCurrencies: string[]
  supportedPayCurrencies: string[]
}

type EnableBanner = {
  enabled: boolean
  state: 'critical'
  message: string
  buttonText: string
  buttonLink: string
}

export type ExchangeRate = {
  pair: string
  baseCode: string
  counterCode: string
  rate: number
}

export interface AuthToken {
  access_token?: string
  username?: string
  secret?: string
  impersonate?: boolean
  roles?: string[]
  length?: number
  token_type?: 'Hawk'
}

export const AccountTypes = {
  BUSINESS: 'business',
  DEVELOPER: 'developer',
} as const

export type AccountTypes = (typeof AccountTypes)[keyof typeof AccountTypes]

export const WalletTypes = {
  FIAT: 'FIAT',
  CRYPTO: 'CRYPTO',
}

export type WalletTypes = (typeof WalletTypes)[keyof typeof WalletTypes]

export interface RequestedDocument {
  id: number
  name: string
}

export const paymentDirections = {
  IN: 'IN',
  OUT: 'OUT',
}

export type ErrorResponse = {
  status: number
  data: {
    code: string
    message: string
  }
}

export const LogoColor = {
  DARK: 'black',
  LIGHT: 'white',
} as const

export type LogoColorType = (typeof LogoColor)[keyof typeof LogoColor]

export type ExchangeRateResponse = {
  value: number
}

export interface CurrencyPreference {
  id?: number
  name: string
  value: string
}

export type AccountPreferenceKey = keyof Preferences
