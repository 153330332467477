import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box as Container, Button } from '@node-space/storybook-components'
import { isProduction, isSandbox } from 'utils/environment'
import { Amp } from 'utils/tracker'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { PathNames } from 'constants/General'

export interface HeaderCTAProps {
  helpText?: string
  buttonText: string
  buttonUrl: string
  externalLink?: boolean
}

export const HeaderCTA = ({ helpText, buttonText, buttonUrl, externalLink }: HeaderCTAProps) => {
  const navigate = useNavigate()

  const goInternal = () => navigate(buttonUrl)
  const goExternal = () => window.open(buttonUrl)

  const onClickHandler = () => {
    if (buttonUrl === PathNames.KYB_SIGNUP_URL) {
      if (isSandbox) Amp.track(AmplitudeEvent.DEVX_SANDBOX_CLICKED_SIGN_UP)
      if (isProduction) Amp.track(AmplitudeEvent.DEVX_PROD_CLICKED_SIGN_UP)
      Amp.track(AmplitudeEvent.ONBOARDING_CLICK_SIGNUP_TOP_LINK, {
        category: AmplitudeEventCategory.SIGNUP,
        action: AmplitudeEventAction.CLICK,
      })

      window.location.href = `${process.env.ONBOARDING_URL}${buttonUrl}`
      return
    }
    externalLink ? goExternal() : goInternal()
  }

  return (
    <Container flex flexItemsCenter flexJustifyEnd className="flex-wrap pl-4">
      {helpText && <span className="text-gray-08 text-sm py-2">{helpText}</span>}
      <Button onClick={onClickHandler} secondary className={helpText ? ' ml-3' : ''}>
        {buttonText}
      </Button>
    </Container>
  )
}
