import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Loader from '@node-space/storybook-components/dist/Loader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { usePaymentCaseContext } from 'hooks/context/usePaymentCaseContext'
import { usePaymentCaseEmailsQuery } from 'hooks/queries/usePaymentCaseEmailsQuery'
import { PaymentCaseEmail } from './PaymentCaseEmail'
import { PaymentCaseError } from './PaymentCaseError'

export const PaymentCaseEmails = () => {
  const { t } = useTranslation()

  const { paymentCaseId } = usePaymentCaseContext()

  const {
    data: { emails: paymentCaseEmails } = {},
    isPending,
    isError,
  } = usePaymentCaseEmailsQuery(paymentCaseId)

  return (
    <Box>
      {isPending && (
        <Box flex middle>
          <Loader theme="dark" size="small" />
        </Box>
      )}

      {isError && !isPending && (
        <StatusCard borderless headline={t('paymentSupport.errorHeadline')} status="error">
          <PaymentCaseError />
        </StatusCard>
      )}

      {!isPending && !isError && !!paymentCaseEmails?.length && (
        <Box flex gapY={24} direction="col-reverse">
          {paymentCaseEmails?.map((paymentCaseEmail, paymentCaseEmailIndex) => (
            <PaymentCaseEmail key={paymentCaseEmailIndex} paymentCaseEmail={paymentCaseEmail} />
          ))}
        </Box>
      )}

      {!isPending && !isError && !paymentCaseEmails?.length && (
        <StatusCard borderless headline="" description={t('paymentSupport.noCaseEmails')} />
      )}
    </Box>
  )
}
