import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { TransactionExportModal } from './TransactionExportModal'

interface TransactionExportProps {
  downloadAll?: boolean
}

export const TransactionExport = ({ downloadAll }: TransactionExportProps) => {
  const { t } = useTranslation()

  const [showCustomDateExportModal, setShowCustomDateExportModal] = useState(false)

  const btnTxt = downloadAll ? t('walletsPage.exportTransactionsBtnText') : t('walletsPage.export')

  const handleClose = () => {
    setShowCustomDateExportModal(false)
  }

  return (
    <>
      {showCustomDateExportModal && (
        <TransactionExportModal
          handleClose={handleClose}
          showCustomDateExportModal={showCustomDateExportModal}
          downloadAll={downloadAll}
        />
      )}
      <Button
        secondary
        iconElement={<Icon name="DownloadIcon" color={secondaryButtonIconColour} />}
        onClick={() => setShowCustomDateExportModal(true)}
        testid="export-wallet-transactions-btn"
      >
        {btnTxt}
      </Button>
    </>
  )
}
