import { useEffect, useState } from 'react'
import { generateReference } from 'utils/utils'
import { getNotabeneCustomerToken } from 'services/notabene/NotabeneService'
import { useFeatureFlags } from '@node-space/hooks'
import { logError } from '@node-space/utils'

const useNotabeneCredentials = () => {
  const { enableTravelRule } = useFeatureFlags()
  const [notabeneCredentials, setNotabeneCredentials] = useState({
    vaspDID: null,
    customerToken: null,
  })

  useEffect(() => {
    const postToObtainCustomerTokenAction = async () => {
      const customerRef = generateReference()
      try {
        const response = await getNotabeneCustomerToken(customerRef)
        if (!response?.data?.errorList?.length) {
          setNotabeneCredentials(response)
        }
      } catch (err) {
        logError('Error from Notabene - postToObtainCustomerTokenAction', err)
      }
    }
    enableTravelRule && postToObtainCustomerTokenAction()
  }, [])

  return notabeneCredentials
}

export default useNotabeneCredentials
