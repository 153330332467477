import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@node-space/hooks'
import Box, { Box as CalloutContainer } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import SmallTabs from '@node-space/storybook-components/dist/SmallTabs'
import { DEFAULT_NUMBER_OF_ROWS, PathNames } from 'constants/General'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useShowVerifiedBannerMutation } from 'hooks/mutations/useShowVerifiedBannerMutation'
import { useAccountPreferencesQuery } from 'hooks/queries'
import { useWalletsQueryV2 } from 'hooks/queries/useWalletsQueryV2'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { TransactionsPagination } from 'pages/WalletDetails/components/RecentActivity/TransactionsPagination'
import { WalletEditModal } from 'pages/Wallets/components/Modal/WalletEditModal'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { TWalletType, WalletTypes } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useGetTotalBalance } from '../../hooks/queries/useGetTotalBalance'
import UnsupportedWalletAction from './components/Modal/UnsupportedWalletAction'
import WalletCreateModal from './components/Modal/WalletCreateModal'
import WalletModal, { WalletAction } from './components/Modal/WalletModal'
import WalletTable from './components/Table/WalletTable'
import WalletTotalBalance from './components/Table/WalletTotalBalance'
import WalletHeaderButtons from './components/WalletHeaderButtons'
import { WalletCreateModalProvider } from './context/WalletCreateModalProvider'
import { WalletControllerProvider } from './context/WalletProvider'

const Wallets = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const permissions = useMappedRoles()?.wallets
  const isEditDisabled = !permissions?.canEdit
  const canTrade = permissions?.canTrade

  const [page, setPage] = useState(0)
  const [searchPage, setSearchPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [numOfRows, setNumOfRows] = useState(DEFAULT_NUMBER_OF_ROWS)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [closedVerifiedBanner, setClosedVerifiedBanner] = useState(false)
  const [unsupportedWalletAction, setUnsupportedWalletAction] = useState<string>()
  const [showWalletModal, setShowWalletModal] = useState<boolean>(false)
  const [showEditWalletModal, setShowEditWalletModal] = useState<boolean>(false)
  const [selectedWalletName, setSelectedWalletName] = useState<string>()
  const [selectedItem, setSelectedItem] = useState<string>()
  const [walletModalAction, setWalletModalAction] = useState<WalletAction>(undefined)

  const { tab } = queryString.parse(window.location.search) as { tab?: TWalletType }
  const [activeTab, setActiveTab] = useState(tab || WalletTypes.FIAT)

  const { mutate: putShowVerifiedBanner } = useShowVerifiedBannerMutation()

  const {
    data: accountPreferences,
    isPending: isLoadingAccountPreferences,
    isFetching: isFetchingAccountPreferences,
    isError: isErrorAccountPreferences,
  } = useAccountPreferencesQuery()

  const { profile, viewOnly } = useProfileContext() || {}
  const { currencyCode } = profile || {}

  const baseCurrency = accountPreferences?.currency || currencyCode

  const showVerifiedBanner = useMemo(
    () => !isLoadingAccountPreferences && accountPreferences?.showVerifiedBanner,
    [isLoadingAccountPreferences, accountPreferences]
  )

  const debouncedSearch = useDebounce(searchQuery, 1000)

  const {
    data: wallets,
    isSuccess: isWalletSuccess,
    isPending: isLoadingWallets,
    isFetching: isFetchingWallets,
    isError: isErrorWallets,
  } = useWalletsQueryV2({
    walletType: activeTab,
    searchTerm: debouncedSearch,
    offset,
    max: Number(numOfRows),
  })

  const {
    data: totalBalance,
    isPending: isLoadingTotalBalance,
    isFetching: isFetchingTotalBalance,
  } = useGetTotalBalance(baseCurrency)

  const hasErrors = isErrorWallets || isErrorAccountPreferences
  const isLoading = isLoadingWallets || isLoadingTotalBalance || isLoadingAccountPreferences
  const isFetching = isFetchingWallets || isFetchingTotalBalance || isFetchingAccountPreferences
  const isWalletBeingSearched = !!searchQuery?.length

  const handleEditWalletName = useCallback(
    (walletId: string, walletName: string) => {
      setSelectedItem(walletId)
      setSelectedWalletName(walletName)
      setShowEditWalletModal(true)
    },
    [setSelectedItem, setSelectedWalletName, setShowEditWalletModal]
  )

  const switchToTab = (tab: TWalletType) => {
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.walletsV2(tab) })
    // add query param to current URL without causing page reload
    window.history.pushState({}, '', `?tab=${tab}`)
    setActiveTab(tab)
  }

  const tabs = [
    {
      id: WalletTypes.FIAT,
      tabHeader: `${t('wallets.fiat')} (${totalBalance?.fiatWalletsNumber || 0})`,
      tabContent: null,
      onClick: () => switchToTab(WalletTypes.FIAT),
      testid: 'fiat-wallets-tab',
    },
    {
      id: WalletTypes.CRYPTO,
      tabHeader: `${t('wallets.crypto')} (${totalBalance?.cryptoWalletsNumber || 0})`,
      tabContent: null,
      onClick: () => switchToTab(WalletTypes.CRYPTO),
      testid: 'crypto-wallets-tab',
    },
  ]

  const refreshWallets = async () => {
    await queryClient.invalidateQueries({ queryKey: reactQueryKeys.walletsV2(WalletTypes.FIAT) })
    await queryClient.invalidateQueries({ queryKey: reactQueryKeys.totalBalance(baseCurrency) })
  }

  const clearFilters = () => {
    setSearchQuery('')
    setPage(0)
    setSearchPage(0)
    setOffset(0)
  }

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WALLET_HOME, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  useEffect(() => {
    if (isWalletBeingSearched) {
      setSearchPage(0)
      setOffset(0)
    }
  }, [isWalletBeingSearched])

  const verifyWidgetCloseButton = () => {
    setClosedVerifiedBanner(true)
    putShowVerifiedBanner(false)
  }

  return (
    <WalletControllerProvider
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isWalletSuccess}
      hasErrors={hasErrors}
      baseCurrency={baseCurrency}
      totalBalance={totalBalance?.total}
      totalFiat={totalBalance?.totalFiat}
      totalCrypto={totalBalance?.totalCrypto}
    >
      <WalletCreateModalProvider
        condition={location.pathname === `${PathNames.WALLETS}${PathNames.WALLET_CREATE}`}
      >
        {showVerifiedBanner && !closedVerifiedBanner && (
          <CalloutContainer paddingB={16}>
            <Callout
              onClose={verifyWidgetCloseButton}
              showCloseButton
              state="success"
              message={t('verification.successMessage')}
            />
          </CalloutContainer>
        )}

        <PageHeader
          description={<WalletTotalBalance isFetchingTotalBalance={isFetchingTotalBalance} />}
          renderRightSection={
            <WalletHeaderButtons
              setWalletModalAction={setWalletModalAction}
              canEdit={!isEditDisabled}
              canTrade={canTrade}
              viewOnly={viewOnly}
              setShowWalletModal={setShowWalletModal}
            />
          }
        />

        <SmallTabs tabs={tabs} currentValueId={activeTab} />

        <WalletTable
          searchQuery={searchQuery}
          onSearchTermChange={setSearchQuery}
          wallets={wallets?.data}
          walletType={activeTab}
          setWalletModalAction={setWalletModalAction}
          handleEditWalletName={handleEditWalletName}
          testid={`${activeTab}-wallet-table`}
          setShowWalletModal={setShowWalletModal}
          clearFilters={clearFilters}
          isWalletBeingSearched={isWalletBeingSearched}
          debouncedSearch={debouncedSearch}
        />

        {!!wallets?.data?.length && !isFetching && (
          <Box paddingT={24}>
            <TransactionsPagination
              page={isWalletBeingSearched ? searchPage : page}
              setPage={isWalletBeingSearched ? setSearchPage : setPage}
              setOffset={setOffset}
              pagination={wallets?.paging}
              nrOfRows={numOfRows}
              setNrOfRows={setNumOfRows}
            />
          </Box>
        )}

        {showWalletModal && (
          <WalletModal
            action={walletModalAction}
            setWalletModalAction={setWalletModalAction}
            refresh={refreshWallets}
            setShowWalletModal={setShowWalletModal}
          />
        )}
        {(!viewOnly || !isEditDisabled) && (
          <WalletCreateModal refresh={refreshWallets}></WalletCreateModal>
        )}
        {unsupportedWalletAction && (
          <UnsupportedWalletAction
            setUnsupportedWalletAction={setUnsupportedWalletAction}
            unsupportedWalletAction={unsupportedWalletAction}
          />
        )}
        {showEditWalletModal && (
          <WalletEditModal
            setShowEditWalletModal={setShowEditWalletModal}
            selectedWalletName={selectedWalletName}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </WalletCreateModalProvider>
    </WalletControllerProvider>
  )
}

export default memo(Wallets)
