import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import CheckboxSelect, {
  checkboxSelectClearAction,
} from '@node-space/storybook-components/dist/CheckboxSelect'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { CSATLaunch } from 'components/CSAT/CSATLaunch'
import { CriteriaKeys } from 'components/CSAT/types'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { PaymentCaseType } from 'types/payments'
import { convertLabelToKey } from 'utils/utils'
import { PaymentSupportTable } from './components/PaymentSupportTable'
import { paymentCaseTypeFilterOptions } from './constants'
import useCSATSurvey from './hooks/useCSATSurvey'

type FilterOptionsType = {
  label: string
  value: PaymentCaseType
}

export const PaymentSupport = () => {
  const {
    CSATPreferenceData,
    enableCsatSurveyCasePortal,
    getIsCriteriaCompleted,
    isFetchingAccountPreferences,
    completeCSATCriteria,
  } = useCSATSurvey({
    shouldInitialiseCSATSurvey: true,
  })

  const [showCSATSurvey, setShowCSATSurvey] = useState(false)

  const isFilteredCaseStatusCriteriaCompleted = getIsCriteriaCompleted(
    CriteriaKeys.HAS_FILTERED_CASE_STATUS
  )

  const permissions = useMappedRoles()
  const canViewPaymentSupport = permissions?.paymentSupport?.canView
  const { t } = useTranslation()
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<PaymentCaseType[]>([])

  useEffect(() => {
    const hasCompletedCriteria =
      !!CSATPreferenceData?.criteria &&
      Object.values(CSATPreferenceData?.criteria)?.indexOf(false) === -1

    const showCSATSurvey =
      enableCsatSurveyCasePortal && hasCompletedCriteria && !CSATPreferenceData?.isSurveyCompleted

    setShowCSATSurvey(showCSATSurvey)
  }, [isFetchingAccountPreferences, enableCsatSurveyCasePortal, CSATPreferenceData?.criteria])

  const checkboxSelectOptions = paymentCaseTypeFilterOptions?.map(paymentCaseType => {
    let label = convertLabelToKey(paymentCaseType)

    if (paymentCaseType == PaymentCaseType.UNRESOLVED) {
      label = convertLabelToKey(PaymentCaseType.PENDING)
    }

    const translatedLabel = t(`paymentSupport.filterStatus.${label}`)
    return { value: paymentCaseType, label: translatedLabel }
  })

  const handleClearFilters = () => {
    checkboxSelectClearAction()
    setSelectedFilterOptions([])
    !isFilteredCaseStatusCriteriaCompleted && enableCsatSurveyCasePortal && handleCSATSurveyUpdate()
  }

  const handleCSATSurveyUpdate = () => {
    completeCSATCriteria(CriteriaKeys.HAS_FILTERED_CASE_STATUS)
  }

  const selectedOptions: FilterOptionsType[] = selectedFilterOptions?.map(option => ({
    value: option,
    label: t(`paymentSupport.filterStatus.${option?.toLowerCase()}`),
  }))

  const mappedStatuses = useMemo(() => {
    if (selectedFilterOptions?.includes(PaymentCaseType.IN_PROGRESS)) {
      return [...selectedFilterOptions, PaymentCaseType.ESCALATED, PaymentCaseType.ON_HOLD]
    }
    return selectedFilterOptions
  }, [selectedFilterOptions])

  return (
    <>
      <PageHeader description={t('paymentSupport.subTitle')} />
      {canViewPaymentSupport ? (
        <>
          <Box flex gapX={8} paddingB={24}>
            <Box style={{ width: '180px' }}>
              <CheckboxSelect
                testid="case-portal-checkbox-select"
                placeholder={t('paymentSupport.status')}
                allOptions={checkboxSelectOptions}
                selectedOptions={selectedOptions}
                isClearable={false}
                closeMenuOnSelect={false}
                handleChange={(options: FilterOptionsType[]) => {
                  setSelectedFilterOptions(options?.map(opt => opt?.value))
                }}
                showOverflow
              />
            </Box>
            {!!selectedFilterOptions?.length && (
              <Button noStyling onClick={handleClearFilters}>
                <Text size="sm" color="primary-500">
                  {t('clearFilters')}
                </Text>
              </Button>
            )}
          </Box>
          <PaymentSupportTable status={mappedStatuses} />
          {showCSATSurvey && <CSATLaunch />}
        </>
      ) : (
        <StatusCard
          headline={t('paymentSupport.permissionsErrorHeadline')}
          description={t('paymentSupport.permissionsErrorDescription')}
        />
      )}
    </>
  )
}
