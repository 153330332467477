import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { IconSelect } from '@node-space/storybook-components/dist/IconSelect'
import { Select } from '@node-space/storybook-components/dist/Select'
import { AddBeneficiaryFormErrors, AddBeneficiaryFormPayload } from 'types/beneficiaries'

interface BusinessBeneficiaryFieldsProps {
  availableCurrencies: { label: string; value: string }[]
  alias: string
  businessName: string
  countries: { label: string; value: string }[]
  isCountriesLoading: boolean
  countryCode: string
  currencyCode: string
  errors: AddBeneficiaryFormErrors
  register: UseFormRegister<AddBeneficiaryFormPayload>
  setValue: (name: string, value: string) => void
  renderCurrencySpecificFields: () => React.ReactNode
}

export const BusinessBeneficiaryFields = ({
  availableCurrencies,
  alias,
  businessName,
  countries,
  isCountriesLoading,
  countryCode,
  currencyCode,
  errors,
  register,
  setValue,

  renderCurrencySpecificFields,
}: BusinessBeneficiaryFieldsProps) => {
  const { t } = useTranslation()

  const handleCountrySelect = (countryCode: string) => {
    const countryName = countries.find(country => country?.value === countryCode)?.label
    setValue('countryName', countryName)
    setValue('countryCode', countryCode)
  }

  return (
    <>
      <Box>
        <Box flex flexRow paddingB={16}>
          <Box flexSize="fill">
            <Select
              {...register('countryCode')}
              label={t('manageBeneficiaries.countryOfRegistration')}
              value={countryCode}
              isSearchable
              options={countries}
              error={!!errors?.countryCode}
              errorText={errors?.countryCode?.message}
              placeholder={t('searchOrSelectACountry')}
              loading={isCountriesLoading}
              onChange={handleCountrySelect}
            />
          </Box>
        </Box>
        <Box flex flexRow paddingB={16}>
          <Box flexSize="fill">
            <IconSelect
              {...register('currencyCode')}
              iconsUrl={`${process.env.ICONS_URL}`}
              label={t('manageBeneficiaries.accountCurrency')}
              placeholder={t('searchOrSelectACurrency')}
              name="currencySelect"
              menuMaxHeight={400}
              options={availableCurrencies}
              isSearchable={true}
              iconLeft={!currencyCode && <Icon name="SearchIcon" />}
              error={!!errors?.currencyCode}
              errorText={errors?.currencyCode?.message}
              value={currencyCode}
              onChange={value => setValue('currencyCode', value)}
            />
          </Box>
        </Box>
        <Box paddingB={16}>
          <Input
            {...register('businessName')}
            label={t('businessName')}
            placeholder={t('enterBusinessName')}
            inputMode="text"
            name="businessName"
            testid="business-name"
            errorText={errors?.businessName?.message}
            error={!!errors?.businessName}
            value={businessName}
          />
        </Box>
        <Box paddingB={16}>
          <Input
            {...register('alias')}
            label={t('beneficiaryName')}
            inputMode="text"
            name="alias"
            testid="alias"
            errorText={errors?.alias?.message}
            error={!!errors?.alias}
            value={alias}
            placeholder={t('inputPlaceholders.enterBeneficiaryNamePlaceholder')}
          />
        </Box>
        {!!renderCurrencySpecificFields && <Box>{renderCurrencySpecificFields()}</Box>}
      </Box>
    </>
  )
}
