import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box as AgreementLinks } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions, ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logError } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { usePendingAgreementsMutation } from 'hooks/mutations/usePendingAgreementsMutation'
import { useToastContext } from 'hooks/useToastContext'
import { LegalAgreementStatus } from 'pages/LegalAgreements/types'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  AccountAgreementAcceptancePayload,
  AccountAgreementStatus,
  AgreementModalData,
} from './types'

export interface AgreementsModalProps {
  agreements: AgreementModalData[]
  onClose: () => void
}

export const AgreementModalBody = ({ agreements, onClose }: AgreementsModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()

  const {
    mutate: confirmAgreements,
    isPending: isLoadingConfirm,
    isError: isErrorConfirm,
  } = usePendingAgreementsMutation()

  const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  const currentStep = activeStepIndex + 1
  const totalSteps = agreements?.length
  const isFirstStep = activeStepIndex === 0
  const isLastStep = activeStepIndex + 1 === agreements?.length

  const activeAgreement: AgreementModalData = agreements?.[activeStepIndex]

  const submitAgreementsAcceptance = () => {
    setSubmitErrorMessage('')
    const confirmedAgreements: AccountAgreementAcceptancePayload[] = agreements?.map(confirmed => ({
      id: confirmed?.agreementId,
      status: confirmed?.status,
    }))

    confirmAgreements(confirmedAgreements, {
      onSuccess: () => {
        onClose()
        addToast({ title: t('pendingAgreements.submitSuccess'), state: 'success' })
        if (window.location.pathname.includes(PathNames.LEGAL_AGREEMENTS)) {
          queryClient.invalidateQueries({
            queryKey: reactQueryKeys.legalAgreements([
              LegalAgreementStatus.PENDING,
              LegalAgreementStatus.SIGNED,
            ]),
          })
        }
      },
      onError: (error: BaseErrorResponse) => {
        let errorMessage = t('pendingAgreements.submitError')
        if (error?.status === 403) {
          errorMessage = t('pendingAgreements.permissionsError')
        } else {
          logError('Error from AgreementModal - confirmAgreements', error)
        }
        setSubmitErrorMessage(errorMessage)
      },
    })
  }

  const handleContinue = useCallback(() => {
    isLastStep ? submitAgreementsAcceptance() : setActiveStepIndex(current => current + 1)
  }, [isLastStep, submitAgreementsAcceptance])

  const handleBack = useCallback(() => {
    if (showDeclineConfirmation) {
      setShowDeclineConfirmation(false)
    } else if (!isFirstStep) {
      activeAgreement.status = isChecked
        ? AccountAgreementStatus.SIGNED
        : AccountAgreementStatus.PENDING
      setActiveStepIndex(current => current - 1)
    }
  }, [showDeclineConfirmation, isFirstStep, activeStepIndex, isChecked])

  const handleAccept = useCallback(() => {
    activeAgreement.status = AccountAgreementStatus.SIGNED
    handleContinue()
  }, [activeStepIndex, handleContinue])

  const handleDecline = useCallback(() => {
    activeAgreement.status = AccountAgreementStatus.DECLINED
    setShowDeclineConfirmation(false)
    handleContinue()
  }, [activeStepIndex, handleContinue])

  useEffect(() => {
    const isActiveChecked = activeAgreement?.status === AccountAgreementStatus.SIGNED
    setIsChecked(isActiveChecked)
  }, [activeStepIndex])

  const showBackButton = !isFirstStep || showDeclineConfirmation

  const acceptAgreementButton: ButtonProps = {
    children: isLastStep ? t('accept') : t('acceptAndContinue'),
    loading: isLoadingConfirm,
    disabled: !isChecked,
    onClick: handleAccept,
  }
  const declineAgreementButton: ButtonProps = {
    children: t('decline'),
    destructive: true,
    onClick: () => setShowDeclineConfirmation(true),
  }
  const confirmDeclineButton: ButtonProps = {
    children: t('declineConfirm'),
    destructive: true,
    onClick: handleDecline,
  }

  return (
    <>
      <ModalNavBar
        title={activeAgreement?.title}
        onClose={onClose}
        {...(showBackButton && { onBack: handleBack })}
      />

      {showDeclineConfirmation ? (
        <>
          <ModalBody centerChildren>
            <Text size="sm" weight="semibold">
              {t('declinePrompt')}
            </Text>
          </ModalBody>
          <ModalActions actions={[confirmDeclineButton]} alignActions="center" />
        </>
      ) : (
        <>
          <ModalBody spaceChildren={24}>
            {isErrorConfirm && (
              <Callout state="error" message={submitErrorMessage} showCloseButton={false} />
            )}

            {activeAgreement?.bodyContent}

            <AgreementLinks flex direction="col" gapY={8}>
              <Text size="sm" weight="semibold">
                {t('readAndApprove')}
              </Text>
              {activeAgreement?.links.map(link => (
                <Text key={link.label} size="sm">
                  <Button noStyling onClick={() => window.open(link?.url)}>
                    {link?.label}
                  </Button>
                </Text>
              ))}
            </AgreementLinks>

            <CheckBox
              testid="agreement-checkbox"
              value={isChecked}
              onChange={() => setIsChecked(current => !current)}
              label={activeAgreement?.checkboxLabel}
            />
          </ModalBody>

          <ModalActions
            currentStep={currentStep}
            totalSteps={totalSteps}
            actions={
              activeAgreement?.isDeclinable
                ? [declineAgreementButton, acceptAgreementButton]
                : [acceptAgreementButton]
            }
          />
        </>
      )}
    </>
  )
}
