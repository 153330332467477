import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getApprovalsTransactions } from 'services/ApprovalsService'

export const useApprovalsTransactionsQuery = (
  currentAccountReference: string,
  status: string,
  isEnabled = false
) => {
  return useQuery({
    queryKey: reactQueryKeys.approvalsTransactions(currentAccountReference, status),
    queryFn: () => getApprovalsTransactions(currentAccountReference, status),
    enabled: isEnabled,
  })
}
