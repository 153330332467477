import React from 'react'
import clsx from 'clsx'

const Layout = ({ children, ...props }: { children: React.ReactNode }) => {
  // need absolute class around all modern pages, as the page-layout style is applied over the whole routes in app.jsx
  const className = clsx(`absolute top-0 left-0 flex w-full`, props)

  return <div className={className}>{children}</div>
}

export default Layout
