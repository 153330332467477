import * as yup from 'yup'
import { AddLocalBeneficiaryFormConfirmPayload } from 'types/beneficiaries'

export const addBeneficiaryConfirmSchema = () => {
  const password = yup.string().trim().required()

  const formFields = {
    password,
  }

  return yup.object<Partial<Record<keyof AddLocalBeneficiaryFormConfirmPayload, yup.AnySchema>>>(
    formFields
  )
}
