import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'

export interface NetworkMembersEmptyStateProps {
  onModalClose?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const NetworkMembersEmptyState = ({ onModalClose }: NetworkMembersEmptyStateProps) => {
  const { t } = useTranslation()

  const cardButton: ButtonProps = {
    name: t('bvnkNetwork.close'),
    secondary: true,
    onClick: onModalClose,
  }

  return (
    <StatusCard
      headline={t('bvnkNetwork.noNetworkMembersHeadline')}
      description={t('bvnkNetwork.noNetworkMembersDescription')}
      cardButton={cardButton}
      borderless
      className="p-6"
    />
  )
}
