import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import { TableRow } from '@node-space/storybook-components/dist/components/SimpleTable'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import {
  SimpleTableV2,
  useTableCheckbox,
} from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { DateFormat } from 'constants/General'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import { ApprovalStatuses } from 'types/approvals'
import { SidePanelSummary } from '../SidePanel/SidePanelSummary'

const rowHeaderWidth = 'px-8 w-1/4'
const cellDataWidth = 'py-4 px-8'

export const PendingTransactions = () => {
  const { t } = useTranslation()

  const {
    pendingApprovalDetails: { pendingApprovals, hasPendingApprovals },
    permissions,
    isFetching,
    isError,
    approvalsConfig: {
      allApprovalFlowsAreDisabled,
      approvalDecisionIsCompleted,
      handleTableRowCheck,
      handleManageApproversModal,
      handleApprovalPreferenceSettingsModal,
    },
  } = useApprovalsContext()

  const {
    isCheckAll = false,
    checkedItems,
    handleCheckAll,
    handleCheckRow,
    rowsClicked = [],
    clearSelection,
  } = useTableCheckbox(pendingApprovals)

  useEffect(() => {
    handleTableRowCheck(checkedItems)
  }, [rowsClicked])

  useEffect(() => {
    if (approvalDecisionIsCompleted) {
      handleTableRowCheck([])
      clearSelection()
    }
  }, [approvalDecisionIsCompleted])

  const {
    isSidePanelOpen: isPendingApprovalDetailsOpen,
    sidePanelData: pendingApprovalDetails,
    paging,
    setIsSidePanelOpen,
    updateSidePanel,
  } = useSidePanel(pendingApprovals)

  const openSidePanelSummary = (index: number) => {
    updateSidePanel(index)
    setIsSidePanelOpen(true)
  }

  const transactionsData = (): TableRow[] => {
    return pendingApprovals?.map((approvalTransaction, index) => ({
      rowData: [
        {
          cellData: (
            <Box flex alignItems="center" gap={12} className="relative">
              <ImageIcon
                width={24}
                height={24}
                hasBackgroundColor={false}
                src={`${
                  process.env.ICONS_URL
                }${approvalTransaction?.currency?.code?.toLowerCase()}.svg`}
              />
              <Box className="relative">
                {!!(approvalTransaction?.amount && approvalTransaction?.currency?.code) && (
                  <Text size="sm" className="text-500">
                    {`${approvalTransaction?.amount} ${approvalTransaction?.currency?.code}`}
                  </Text>
                )}
                <Text size="sm" color="disabled">
                  {approvalTransaction?.walletName}
                </Text>
              </Box>
            </Box>
          ),
          className: cellDataWidth,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.beneficiaryDetails?.accountNumber ||
                  approvalTransaction?.beneficiaryDetails?.destinationWallet}
              </Text>
              <Text size="sm" color="disabled">
                {approvalTransaction?.beneficiaryDetails?.beneficiaryName}
              </Text>
            </Box>
          ),
          className: cellDataWidth,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.initiatedBy || approvalTransaction?.initiatedByEmail}
              </Text>
              <Text size="sm" color="disabled">
                {dayjs(approvalTransaction?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
              </Text>
            </Box>
          ),
          className: cellDataWidth,
        },
        {
          cellData: (
            <Text size="sm" className="text-500">
              {!!approvalTransaction?.approvalsCounter
                ? `${approvalTransaction?.approvalsCounter?.approvalsRequired - approvalTransaction?.approvalsCounter?.approvalsAcquired}`
                : '-'}
            </Text>
          ),
          className: cellDataWidth,
        },
      ],
      onClick: () => openSidePanelSummary(index),
    }))
  }

  const pendingTransactionTableInfo = transactionsData()

  return (
    <Box paddingY={16}>
      {allApprovalFlowsAreDisabled && hasPendingApprovals && (
        <Box paddingB={16}>
          <Callout state="warning" message={t('approvals.disableApprovalCalloutDisclaimer')} />
        </Box>
      )}
      {!!pendingApprovalDetails && (
        <SidePanelSummary
          isOpen={isPendingApprovalDetailsOpen}
          paging={paging}
          onClose={() => setIsSidePanelOpen(false)}
          transactionDetails={pendingApprovalDetails}
          tab={ApprovalStatuses.PENDING}
        />
      )}
      {isError ? (
        <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />
      ) : !isFetching && !hasPendingApprovals ? (
        <StatusCard
          headline={
            allApprovalFlowsAreDisabled
              ? t('approvals.disabledApprovalsHeadline')
              : t('approvals.enabledAndNoPendingApprovalsHeadline')
          }
        >
          <Box paddingB={16} className="sm:flex">
            <Text size="sm">
              {allApprovalFlowsAreDisabled
                ? t('approvals.disabledApprovalsDescription')
                : t('approvals.enabledAndNoPendingApprovalsDescription')}
              <Link
                to={`${process.env.ABOUT_APPROVAL_LINK}`}
                className="ml-1 text-primary-500"
                target="_blank"
              >
                {t('learnMore')}
              </Link>
            </Text>
          </Box>
          <Box flex>
            <Button
              disabled={!permissions?.canEdit}
              onClick={() => handleApprovalPreferenceSettingsModal({ isVisible: true })}
            >
              {t('approvals.enableAndEditPreferences')}
            </Button>
            {!allApprovalFlowsAreDisabled && !hasPendingApprovals && (
              <Button
                disabled={!permissions?.canEdit}
                onClick={() => handleManageApproversModal({ isVisible: true })}
                className="ml-2"
              >
                {t('approvals.manageApprovers')}
              </Button>
            )}
          </Box>
        </StatusCard>
      ) : (
        <SimpleTableV2
          columns={[
            {
              headerTitle: t('payment'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('to'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('approvals.initiatedBy'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('approvals.required'),
              className: rowHeaderWidth,
            },
          ]}
          isLoading={isFetching}
          tableData={pendingTransactionTableInfo}
          enableCheckbox={!isFetching && permissions?.canManageApproval}
          checkboxConfig={{ isCheckAll, handleCheckAll, rowsClicked, handleCheckRow }}
        />
      )}
    </Box>
  )
}
