import { transformOptions } from 'utils/utils'
import { useIndustriesQuery } from './useIndustriesQuery'

export const useIndustryOptions = () => {
  const { data: industries, isPending } = useIndustriesQuery()
  const industryOptions = transformOptions(industries)
  return { industryOptions: industryOptions ?? [], isPending }
}

export const useIndustryValues = () => {
  const { data: industries } = useIndustriesQuery()
  const industryOptionValues = transformOptions(industries)?.flatMap(option =>
    option?.options?.map(({ value }) => `${value}`)
  )

  return industryOptionValues ?? []
}
