import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { useWalletContext } from 'hooks/context'

export const NoTransactions = () => {
  const { t } = useTranslation()
  const wallet = useWalletContext()
  return (
    <Box padding={16}>
      <StatusCard
        headline={t('wallets.emptyStateHeadline')}
        description={t('wallets.emptyStateDescription', { walletName: wallet.description })}
      />
    </Box>
  )
}
