import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { PaymentStatus } from 'types/payments'
import { FiatPayout, MassPayoutCryptoBatch, PreProcessedPayouts } from '../types'

interface MassPayoutReviewCalloutsProps {
  loadingMassFiatPayoutDetails?: boolean
  cryptoBatchPayout?: MassPayoutCryptoBatch
  massFiatPayoutInformation?: FiatPayout
  massPayoutPreProcessedPayouts?: PreProcessedPayouts[]
  isPendingCryptoMassPayout?: boolean
  isPendingDeleteCryptoRecord?: boolean
  isCrypto?: boolean
  paymentsReadyToBeProcessed?: string
  isPayoutSubmitted?: boolean
  numberOfErrors?: number
}

export const MassPayoutReviewCallouts = ({
  loadingMassFiatPayoutDetails,
  cryptoBatchPayout,
  massFiatPayoutInformation,
  massPayoutPreProcessedPayouts,
  isPendingCryptoMassPayout,
  isPendingDeleteCryptoRecord,
  isCrypto,
  paymentsReadyToBeProcessed,
  isPayoutSubmitted,
  numberOfErrors,
}: MassPayoutReviewCalloutsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box className="mb-4">
        {(loadingMassFiatPayoutDetails && !massPayoutPreProcessedPayouts) ||
        isPendingCryptoMassPayout ||
        isPendingDeleteCryptoRecord ? (
          <Skeleton className="w-full" variant="input" />
        ) : (
          !isPayoutSubmitted && <Callout state="success" message={paymentsReadyToBeProcessed} />
        )}
      </Box>

      <Box className="mb-4">
        {loadingMassFiatPayoutDetails || isPendingCryptoMassPayout ? (
          <Skeleton className="w-full" variant="input" />
        ) : (
          numberOfErrors > 0 && (
            <Callout
              state="error"
              message={t('massPayouts.errorWarnings', {
                numberOfErrors: numberOfErrors,
                batchName: isCrypto
                  ? cryptoBatchPayout?.fileName
                  : massFiatPayoutInformation?.alias,
              })}
            />
          )
        )}
      </Box>
      {massFiatPayoutInformation?.status === PaymentStatus.CREATED && (
        <Box className="mb-4">
          {loadingMassFiatPayoutDetails || isPendingCryptoMassPayout ? (
            <Skeleton className="w-full" variant="input" />
          ) : (
            <Callout state="warning" message={t('massPayouts.paymentsValidation')} />
          )}
        </Box>
      )}
    </>
  )
}
