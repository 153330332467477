import React, { createContext, useMemo, useReducer } from 'react'
import { rootInitialState, rootReducer, FilterState, UnknownAction } from './reducer'

type FilterContextType = [state: FilterState, dispatch: React.Dispatch<UnknownAction>]

export const FilterProviderContext = createContext<FilterContextType | null>(null)

interface FilterContextProviderProps {
  children: React.ReactNode
}

export const FilterContextProvider = ({ children }: FilterContextProviderProps) => {
  const [state, dispatch] = useReducer(rootReducer, rootInitialState)
  const value: FilterContextType = useMemo(() => [state, dispatch], [state])

  return <FilterProviderContext.Provider value={value}>{children}</FilterProviderContext.Provider>
}
