import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { BeneficiaryListRequestParams, getBeneficiaryList } from 'services/BeneficiaryService'
import { BeneficiaryDetails } from 'types/beneficiaries'
import { WithPaginationApiResponse } from 'types/pagination'
import { API_PAGINATION_LIMIT, API_PAGINATION_OFFSET, withPaginationPaging } from 'utils/pagination'

export const useBeneficiariesQuery = (
  {
    size = API_PAGINATION_LIMIT,
    page = API_PAGINATION_OFFSET,
    sort = '',
    beneficiaryType,
    currency,
  }: BeneficiaryListRequestParams = {},
  enabled?: boolean
) => {
  return useQuery({
    queryKey: reactQueryKeys.beneficiaries(size, page, sort),
    queryFn: () => getBeneficiaryList({ size, page, sort, beneficiaryType, currency }),
    enabled,
    select: (res: WithPaginationApiResponse<BeneficiaryDetails[]>) => {
      //  Transform the result to removing default Pageable pagination and adding Paging object
      const beneficiaryListWithPagination = withPaginationPaging<BeneficiaryDetails[]>(res)
      return beneficiaryListWithPagination
    },
  })
}
