import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CTACard, Icon, Box } from '@node-space/storybook-components'
import { isProduction, isSandbox } from 'utils/environment'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { HeaderCTA } from 'components/header/HeaderCTA'
import { Amp } from 'utils/tracker'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { PathNames } from 'constants/General'

const CONTACT_SALES_URL = process.env.CONTACT_SALES_URL

export const CTAGetStarted = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    Amp.track(AmplitudeEvent.ONBOARDING_LOAD_CTA_GET_STARTED, {
      category: AmplitudeEventCategory.ONBOARDING,
      action: AmplitudeEventAction.LOAD,
    })
  }, [])

  const getInTouchClick = () => {
    Amp.track(AmplitudeEvent.ONBOARDING_CLICK_CONTINUE_CTA_GET_STARTED, {
      category: AmplitudeEventCategory.ONBOARDING,
      action: AmplitudeEventAction.CLICK,
      experimentID: 'EXP-2-ONB-EXPERT',
    })

    location.href = CONTACT_SALES_URL
  }

  const onSignUpClick = () => {
    Amp.track(AmplitudeEvent.ONBOARDING_CLICK_CONTINUE_CTA_GET_STARTED, {
      category: AmplitudeEventCategory.ONBOARDING,
      action: AmplitudeEventAction.CLICK,
      experimentID: 'EXP-2-ONB-PROD-SIGNUP',
    })

    if (isProduction) {
      navigate(PathNames.KYB_SIGNUP_URL)
    } else {
      location.href = 'https://app.bvnk.com/create-account'
    }
  }

  const onSignUpSandboxClick = () => {
    Amp.track(AmplitudeEvent.ONBOARDING_CLICK_CONTINUE_CTA_GET_STARTED, {
      category: AmplitudeEventCategory.ONBOARDING,
      action: AmplitudeEventAction.CLICK,
      experimentID: 'EXP-2-ONB-SANDBOX-SIGNUP',
    })

    if (isSandbox) {
      navigate(PathNames.DEVX_SIGNUP_URL)
    } else {
      location.href = `https://app.sandbox.bvnk.com${PathNames.DEVX_SIGNUP_URL}`
    }
  }

  return (
    <>
      <LoginPageLayout
        title={t('ctaGetStarted.banner')}
        titleBig
        description={t('ctaGetStarted.description')}
        headerRightContent={
          <HeaderCTA
            helpText={`${t('alreadySignedUp')}`}
            buttonText={`${t('logIn')}`}
            buttonUrl={PathNames.LOGIN}
          />
        }
        narrowView={false}
        hasFooter
      >
        <Box
          flex
          direction="col"
          gap={32}
          paddingT={16}
          paddingB={32}
          justifyContent="center"
          width="full"
          alignItems="center"
          className="md:flex-row md:items-stretch"
        >
          <CTACard
            icon={<Icon name="SpeakToExpertIcon" />}
            label={t('ctaGetStarted.speakToExpert')}
            description={t('ctaGetStarted.speakToExpertDescription')}
            buttonText={t('getInTouch')}
            buttonOnClick={getInTouchClick}
          />
          <CTACard
            icon={<Icon name="CreateAccountIcon" />}
            label={t('ctaGetStarted.createAccount')}
            description={t('ctaGetStarted.createAccountDescription')}
            buttonText={t('signUp')}
            buttonOnClick={onSignUpClick}
          />
          <CTACard
            icon={<Icon name="DeveloperSandboxIcon" />}
            label={t('ctaGetStarted.developerSandbox')}
            description={t('ctaGetStarted.developerSandboxDescription')}
            buttonText={t('signUpSandbox')}
            buttonOnClick={onSignUpSandboxClick}
          />
        </Box>
      </LoginPageLayout>
    </>
  )
}
