import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getReissueToken } from 'services/AuthService'
import { QueryMetaType } from 'types/reactQuery'
import { AuthToken } from 'types/types'
import { setAuthCookies, TEN_MINUTES } from 'utils/utils'

export const useRefreshSession = (isEnabled: boolean) => {
  const { isAuthed } = useAuth()

  const shouldRun = useMemo(() => !!isAuthed && !!isEnabled, [isAuthed, isEnabled])

  const meta: QueryMetaType = {
    onSuccess: (freshToken: AuthToken) => {
      setAuthCookies({ ...freshToken })
    },
    isErrorFatal: true,
  }

  useQuery({
    queryKey: reactQueryKeys.reissueToken(),
    queryFn: getReissueToken,
    enabled: shouldRun,
    refetchIntervalInBackground: true,
    refetchInterval: TEN_MINUTES, // Use RQ built-in timer, no useEffect needed
    meta,
  })
}
