import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPaymentCaseDocument } from 'services/PaymentsService'

export const usePaymentCaseDocumentQuery = (paymentDocumentId: string) => {
  return useQuery({
    queryKey: reactQueryKeys.paymentCaseDocument(paymentDocumentId),
    queryFn: () => getPaymentCaseDocument(paymentDocumentId),
    enabled: false,
    gcTime: 0,
  })
}
