import React from 'react'
import { useTranslation } from 'react-i18next'
import { Callout } from '@node-space/storybook-components'

interface CurrencySelectionWarningProps {
  handleResetCurrency: () => void
}
const CurrencySelectionWarning = ({ handleResetCurrency }: CurrencySelectionWarningProps) => {
  const { t } = useTranslation()

  return (
    <div className="mb-6" role="alert" data-testid="display-currency-warning">
      <Callout state="warning" message={t('displayCurrencyNotice')}>
        <p>
          {t('displayCurrencyNotice')}{' '}
          <button className="underline" onClick={handleResetCurrency}>
            {t('ResetTheDisplayCurrency')}
          </button>
        </p>
      </Callout>
    </div>
  )
}

export default CurrencySelectionWarning
