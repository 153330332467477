import React, { createContext, Dispatch, SetStateAction } from 'react'
import {
  AddBeneficiaryBankDetailsPayload,
  AddBeneficiaryFormPayload,
  AddBeneficiaryFormPayloadErrors,
} from 'types/beneficiaries'

interface AddBeneficiaryContextValue {
  beneficiaryFormState: AddBeneficiaryFormPayload
  setBeneficiaryFormState: Dispatch<SetStateAction<AddBeneficiaryFormPayload>>
  beneficiaryFormErrors: AddBeneficiaryFormPayloadErrors
  setBeneficiaryFormErrors: Dispatch<SetStateAction<AddBeneficiaryFormPayloadErrors>>
  beneficiaryIbanValidationState: AddBeneficiaryBankDetailsPayload
  setBeneficiaryIbanValidationState: Dispatch<SetStateAction<AddBeneficiaryBankDetailsPayload>>
  beneficiaryBicValidationState: string
  setBeneficiaryBicValidationState: Dispatch<SetStateAction<string>>
}
export interface AddBeneficiaryContextProviderProps {
  children: React.ReactNode
  value: AddBeneficiaryContextValue
}

export const AddBeneficiaryContext = createContext<AddBeneficiaryContextValue>({
  beneficiaryFormState: {} as AddBeneficiaryFormPayload,
  setBeneficiaryFormState: () => null,
  beneficiaryFormErrors: {} as AddBeneficiaryFormPayloadErrors,
  setBeneficiaryFormErrors: () => null,
  beneficiaryIbanValidationState: {} as AddBeneficiaryBankDetailsPayload,
  setBeneficiaryIbanValidationState: () => null,
  beneficiaryBicValidationState: {} as string,
  setBeneficiaryBicValidationState: () => null,
})

export const AddBeneficiaryContextProvider = ({
  children,
  value,
}: AddBeneficiaryContextProviderProps) => {
  return <AddBeneficiaryContext.Provider value={value}>{children}</AddBeneficiaryContext.Provider>
}
