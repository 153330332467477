import React from 'react'
import i18next from 'i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { WithdrawalDetailsType } from 'types/transaction'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface WithdrawalDetailsProps {
  withdrawalDetails: WithdrawalDetailsType
}

const WithdrawalDetails = ({ withdrawalDetails }: WithdrawalDetailsProps) => {
  const THIRD_PARTY = 'THIRD_PARTY'

  return (
    <>
      <Text size="base" weight="medium" color="dark-grey" className="mt-3">
        {withdrawalDetails?.beneficiary.type === THIRD_PARTY
          ? i18next.t('transactions.sideBar.beneficiaryDetails')
          : i18next.t('withdrawalDetails')}
      </Text>
      <Box gapY={8}>
        {!!withdrawalDetails?.beneficiary?.accountNumber && (
          <TransactionDetailRow
            label={i18next.t('accountNumber')}
            detail={withdrawalDetails?.beneficiary?.accountNumber}
          />
        )}
        {!!withdrawalDetails?.beneficiary?.bankName && (
          <TransactionDetailRow
            label={i18next.t('bankName')}
            detail={withdrawalDetails?.beneficiary?.bankName}
          />
        )}
        {!!withdrawalDetails?.beneficiary?.bankCode && (
          <TransactionDetailRow
            label={i18next.t('bankCode')}
            detail={withdrawalDetails?.beneficiary?.bankCode}
          />
        )}
        {!!withdrawalDetails?.beneficiary?.name && (
          <TransactionDetailRow
            label={i18next.t('beneficiaryName')}
            detail={withdrawalDetails?.beneficiary?.name}
          />
        )}
      </Box>
    </>
  )
}

export default WithdrawalDetails
