import React from 'react'
import { Box as List } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import TextList from '@node-space/storybook-components/dist/TextList'

interface PayCalloutsContainers {
  calloutItems?: string[]
}

const PayCalloutsContainers = ({ calloutItems }: PayCalloutsContainers) => {
  const textListItems = calloutItems?.map(message => {
    return {
      children: message,
      key: message,
    }
  })

  return (
    <>
      {!!textListItems?.length && (
        <Callout
          testid="pay-callouts"
          state="info"
          message={calloutItems?.length <= 1 && calloutItems?.[0]}
        >
          <List tag="ul" paddingL={textListItems?.length === 1 ? 4 : 12}>
            <TextList
              className="text-secondary-900"
              listItems={textListItems}
              listStyle={textListItems?.length === 1 ? 'none' : 'bullet'}
            />
          </List>
        </Callout>
      )}
    </>
  )
}

export default PayCalloutsContainers
