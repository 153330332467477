import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { updateNetworkConnection } from 'services/BvnkNetworkService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useUpdateNetworkConnectionMutation = () => {
  return useMutation({
    mutationFn: updateNetworkConnection,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useUpdateNetworkConnectionMutation', error, data)
      }
    },
  })
}
