import { requestApi } from 'api/api'
import { CapabilitiesRequest, CapabilitiesResponse } from 'types/capabilities/capabilities'

export const getCapabilityValue = ({
  group,
  name,
  scope,
  subjectId,
}: CapabilitiesRequest): Promise<CapabilitiesResponse> => {
  return requestApi({
    url: '/v1/capabilities',
    method: 'GET',
    params: {
      group,
      name,
      scope,
      subjectId,
    },
  })
}
