import { requestApi } from 'api/api'
import {
  AddBeneficiaryRequest,
  AddBeneficiaryRequestPreRequest,
  BanksResponse,
  BeneficiaryDetails,
  BeneficiaryType,
  NgBankAccountValidationRequest,
  NgBankAccountValidationResponse,
  UUID,
  ValidateIbanRequest,
  ValidateIbanResponse,
  ValidateScanRequest,
  ValidateScanResponse,
} from 'types/beneficiaries'
import { WithPaginationApiResponse } from 'types/pagination'
import { Currencies } from 'types/types'
import { API_PAGINATION_LIMIT, API_PAGINATION_OFFSET } from 'utils/pagination'

export interface BeneficiaryListRequestParams {
  size?: number
  page?: number
  sort?: string
  beneficiaryType?: BeneficiaryType
  currency?: Currencies
}

export const getBeneficiaryList = ({
  size = API_PAGINATION_LIMIT,
  page = API_PAGINATION_OFFSET,
  sort = '',
  beneficiaryType,
  currency,
}: BeneficiaryListRequestParams): Promise<WithPaginationApiResponse<BeneficiaryDetails[]>> => {
  return requestApi({
    url: '/beneficiaries',
    method: 'GET',
    params: {
      size,
      page,
      ...(sort && { sort }),
      ...(beneficiaryType && { 'beneficiary-type': beneficiaryType }),
      ...(currency && { currency }),
    },
  })
}

export const addBeneficiary = ({
  authorization: { totp },
  data,
}: AddBeneficiaryRequest): Promise<WithPaginationApiResponse<BeneficiaryDetails[]>> => {
  return requestApi({
    url: '/beneficiaries',
    method: 'POST',
    headers: {
      totp,
    },
    data,
  })
}

export const addBeneficiaryPreRequest = (data: AddBeneficiaryRequestPreRequest): Promise<void> => {
  const beneficiaryType =
    data?.beneficiaryType === BeneficiaryType.FIRST_PARTY ? 'first-party' : 'third-party'
  return requestApi({
    url: `/beneficiaries/${beneficiaryType}`,
    method: 'POST',
    data,
  })
}

export const addBeneficiaryPostChallengeRequest = ({
  challenge,
  tokenResponse,
}: {
  challenge: UUID
  tokenResponse: string
}): Promise<BeneficiaryDetails> => {
  return requestApi({
    url: '/beneficiaries/operation-authorizations',
    method: 'POST',
    data: {
      challenge,
      tokenResponse,
    },
  })
}

export const updateBeneficiaryPreRequest = (beneficiary: BeneficiaryDetails): Promise<void> => {
  const { reference, ...payload } = beneficiary
  return requestApi({
    url: `/beneficiaries/${reference}`,
    method: 'PUT',
    data: payload,
  })
}

export const updateBeneficiaryPostChallengeRequest = ({
  challenge,
  tokenResponse,
}: {
  challenge: UUID
  tokenResponse: string
}): Promise<BeneficiaryDetails> => {
  return requestApi({
    url: `/beneficiaries/operation-authorizations`,
    method: 'POST',
    data: {
      challenge,
      tokenResponse,
    },
  })
}

export const deleteBeneficiary = (uuid: UUID): Promise<void> => {
  return requestApi({
    url: `/beneficiaries/${uuid}`,
    method: 'DELETE',
  })
}

export const getListOfBanksForCountryAndCurrency = async (
  countryCode: string,
  currencyCode: string
): Promise<BanksResponse> => {
  return requestApi({
    url: `/v1/banks?countryCode=${countryCode}&currencyCode=${currencyCode}`,
    method: 'GET',
  })
}

export const validateNgBankAccount = async ({
  accountNumber,
  bankCode,
}: NgBankAccountValidationRequest): Promise<NgBankAccountValidationResponse> => {
  return requestApi({
    url: '/v1/bank-accounts/verify',
    method: 'POST',
    data: {
      accountNumber,
      bankCode,
    },
  })
}

export const validateIban = async ({
  accountNumberFormat,
  accountNumber,
}: ValidateIbanRequest): Promise<ValidateIbanResponse> => {
  return requestApi({
    url: `/banking/bank-accounts/iban?accountNumberFormat=${accountNumberFormat}&accountNumber=${accountNumber}`,
    method: 'GET',
  })
}

export const validateScan = async ({
  sortCode,
  accountNumber,
}: ValidateScanRequest): Promise<ValidateScanResponse> => {
  return requestApi({
    url: `/banking/bank-accounts/scan?sortCode=${sortCode}&accountNumber=${accountNumber}`,
    method: 'GET',
  })
}

export const validateBic = async (bic: string): Promise<string> => {
  return requestApi({
    url: `/bank/information/validation?bic=${bic}`,
    method: 'GET',
  })
}
