import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box as Form,
  Box as FormRow,
  Box as FormWrapper,
} from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { IconSelect } from '@node-space/storybook-components/dist/IconSelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { useCreateWalletMutation } from 'hooks/mutations'
import { useSupportedCurrenciesQuery } from 'hooks/queries'
import { useToastContext } from 'hooks/useToastContext'
import { createWalletSchema } from 'pages/Wallets/schemas/createWalletSchema'
import { WalletsCreateRequest } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

export interface WalletsCreateFormProps {
  onSuccess: () => void
  setCreateWalletModal: Dispatch<SetStateAction<boolean>>
}

const WalletsCreateForm = ({ onSuccess, setCreateWalletModal }: WalletsCreateFormProps) => {
  const { t } = useTranslation()
  const { enableRestrictDepositCurrencyToCrypto } = useFeatureFlags()
  const { isPending, mutateAsync: createWallet } = useCreateWalletMutation()

  const [createWalletError, setCreateWalletError] = useState('')

  const { data: currencies, isPending: isSupportedCurrenciesLoading } = useSupportedCurrenciesQuery(
    enableRestrictDepositCurrencyToCrypto
  )
  const addToast = useToastContext()
  const { register, setValue, handleSubmit, formState, getValues } = useForm<WalletsCreateRequest>({
    defaultValues: {
      currency: '',
      description: '',
    },
    resolver: yupResolver(createWalletSchema(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const getCurrenciesOptions = () => {
    const hasCurrencies = currencies && currencies.length > 0

    if (hasCurrencies) {
      return currencies.map(currency => {
        return {
          value: currency.code,
          label: `${currency.name} (${currency.code})`,
        }
      })
    }

    return []
  }

  const currenciesOptions = getCurrenciesOptions()

  const { onChange, ...restCurrencyProps } = register('currency')

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WALLET_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const onSubmit: SubmitHandler<WalletsCreateRequest> = async payload => {
    try {
      await createWallet(payload)

      track.Amp.track(AmplitudeEvent.EOF_INITIATE_WALLET_SUCCESS, {
        category: AmplitudeEventCategory.WALLET,
        action: AmplitudeEventAction.CLICK,
        currency: getValues('currency'),
      })

      addToast({
        title: t('walletCreated', {
          walletName: payload.description,
        }),
        state: 'success',
      })
      setCreateWalletError('')
      onSuccess()
    } catch (err) {
      const createWalletError = err?.data?.errorList?.[0]?.message || t('oopsSomethingWentWrong')
      setCreateWalletError(createWalletError)
      logSentryError('Error from WalletsCreateForm - WalletsCreateRequest - onSubmit', err)
    }
  }
  return (
    <FormWrapper tag="section">
      <Form tag="form" testid="wallet-create-form" onSubmit={handleSubmit(onSubmit)}>
        {createWalletError && (
          <FormRow paddingX={24} paddingT={16}>
            <Callout state="error" message={createWalletError} showCloseButton={false} />
          </FormRow>
        )}
        <FormRow className="flex flex-wrap p-6">
          <div className="w-full">
            <IconSelect
              iconsUrl={`${process.env.ICONS_URL}`}
              label={t('selectCurrency')}
              name="currency"
              options={currenciesOptions}
              onChange={value => {
                setValue('currency', value)
              }}
              loading={isSupportedCurrenciesLoading}
              isSearchable={true}
              {...restCurrencyProps}
            />
          </div>
        </FormRow>
        <FormRow className="px-6 pb-6">
          <Input
            label={t('walletName')}
            inputMode="text"
            placeholder="Ie. ETH Wallet"
            name="description"
            testid="wallet-name"
            error={Boolean(formState.errors?.description)}
            errorText={formState.errors?.description?.message}
            {...register('description')}
          />
        </FormRow>
        {enableRestrictDepositCurrencyToCrypto && (
          <FormRow className="px-6 pb-6">
            <Callout state="info">
              <Text size="sm" color="dark-grey">
                {t('createFiatWalletDisclaimer')}
                <Link
                  to="https://help.bvnk.com/hc/en-us/articles/13995679766930"
                  className="text-primary-500 hover:text-primary-700 active:text-primary-900"
                  target="_blank"
                >
                  {` ${t('learnMore')}`}
                </Link>
              </Text>
            </Callout>
          </FormRow>
        )}
        <ModalActions
          actions={[
            {
              children: t('cancel'),
              disabled: isPending,
              secondary: true,
              type: 'button',
              testid: 'cancel-create-wallet',
              onClick: () => setCreateWalletModal(false),
            },
            {
              children: t('createWallet'),
              disabled: !formState.isValid,
              loading: isPending,
              type: 'submit',
              testid: 'create-wallet',
            },
          ]}
        />
      </Form>
    </FormWrapper>
  )
}

export default React.memo(WalletsCreateForm)
