import React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  AlignActions,
  ModalActions,
  SuccessContainer,
  TextSize,
} from '@node-space/storybook-components'
import CopyUrl from 'components/CopyUrl'
import { Description } from 'components/text/Description'
import { TextAlignType } from 'types/text'
import { PathNames } from 'constants/General'

interface SuccessResponseFormProps {
  redirectUrl: string
  closeCallback: () => void
  showManageChannelsButton: boolean
}
const SuccessResponseForm = ({
  redirectUrl,
  closeCallback,
  showManageChannelsButton,
}: SuccessResponseFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <SuccessContainer
        test-id="channel-success-modal"
        headline={t('payments.channels.successMessageTitle')}
        hasDescription={false}
      >
        <CopyUrl redirectUrl={redirectUrl} />
        <Description color="disabled" size={TextSize.SM} align={TextAlignType.CENTER}>
          {t('payments.channels.successMessageDescription')}
        </Description>
      </SuccessContainer>
      <ModalActions
        alignActions={showManageChannelsButton ? AlignActions.RIGHT : AlignActions.CENTER}
        actions={[
          {
            children: t('payments.channels.ctaDone'),
            onClick: closeCallback,
            type: 'button',
            testid: 'close-channel-success-modal',
            secondary: showManageChannelsButton,
          },
          {
            children: t('payments.channels.ctaManageChannels'),
            onClick: () => navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`),
            type: 'button',
            testid: 'navigate-manage-channel-btn',
            className: `${showManageChannelsButton ? '' : 'hidden'}`,
          },
        ]}
      />
    </>
  )
}

export default SuccessResponseForm
