import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import { IconSelect } from '@node-space/storybook-components/dist/IconSelect'
import { Search } from '@node-space/storybook-components/dist/Search'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useCryptoCurrenciesQuery } from 'hooks/queries'
import type { SelectOption } from 'types/types'

type CryptoFilterBarProps = {
  searchTerm: string
  onSearchTermChange: React.Dispatch<SetStateAction<string>>
  searchCurrency: string
  onSearchCurrencyChange: React.Dispatch<SetStateAction<string>>
  isBeingSearched: boolean
  isFetchingCryptoAddresses: boolean
}

const CryptoFilterBar = ({
  searchTerm,
  onSearchTermChange,
  onSearchCurrencyChange,
  searchCurrency,
  isBeingSearched,
  isFetchingCryptoAddresses,
}: CryptoFilterBarProps) => {
  const { t } = useTranslation()
  const { data: cryptoCurrencies, isPending: isLoadingCrypto } = useCryptoCurrenciesQuery()

  const options: SelectOption[] =
    cryptoCurrencies?.map(({ code, name }) => {
      return {
        label: name,
        value: code,
      }
    }) ?? []

  return (
    <Box flex flexRow>
      <Box className="w-1/2 mr-3 mb-8">
        <Search
          searchTerm={searchTerm}
          size="sm"
          testid="filter-search-field"
          placeholderText={t('manageCryptoAddresses.searchPlaceholder')}
          onChange={event => onSearchTermChange(event.target.value)}
        />
      </Box>
      <Box className="block mr-3 mb-8 w-1/5">
        <IconSelect
          iconsUrl={process.env.ICONS_URL}
          menuMaxHeight={300}
          value={searchCurrency}
          onChange={value => {
            onSearchCurrencyChange(value)
          }}
          options={options}
          placeholder={t('currency')}
          disabled={isLoadingCrypto}
        />
      </Box>
      {isBeingSearched && !isFetchingCryptoAddresses && (
        <Box flex centerChildren flexJustifyCenter>
          <Button
            noStyling
            onClick={() => {
              onSearchTermChange('')
              onSearchCurrencyChange('')
            }}
          >
            <Text size="sm" color="primary-500">
              {t('clearFilters')}
            </Text>
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CryptoFilterBar
