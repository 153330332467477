import { keepPreviousData, Query, useQuery } from '@tanstack/react-query'
import { DurationInMilliseconds } from 'constants/General'
import { BatchStatus, FiatPayout } from 'pages/MassPayouts/types'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getMassFiatPayoutsDetails } from 'services/MasspayoutService'
import { QueryMetaType } from 'types/reactQuery'

export const useGetMassFiatPayoutsDetailsQuery = (batchReference: string, enabled = true) => {
  const meta: QueryMetaType = {
    errorAdditionalData: {
      batchReference,
    },
  }

  const getRefetchInterval = (query: Query): number | false | undefined => {
    const batchStatus = (query?.state?.data as FiatPayout)?.status
    return !batchStatus ||
      batchStatus === BatchStatus.CREATED ||
      batchStatus === BatchStatus.PROCESSING
      ? DurationInMilliseconds.THIRTY_SECONDS
      : false
  }

  return useQuery({
    queryKey: reactQueryKeys.massFiatPayoutsDetails(batchReference),
    queryFn: () => getMassFiatPayoutsDetails({ batchReference }),
    enabled: enabled && !!batchReference,
    placeholderData: keepPreviousData,
    gcTime: DurationInMilliseconds.THIRTY_SECONDS,
    meta,
    refetchInterval: getRefetchInterval,
    refetchIntervalInBackground: true,
  })
}
