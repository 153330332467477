import { UseFormReturn } from 'react-hook-form'
import {
  BeneficiaryDetails,
  BeneficiaryEntityType,
  BeneficiaryTransferDestination,
} from 'types/beneficiaries'
import { BvnkNetworkConnection, NetworkTransferRequest } from 'types/bvnkNetwork'
import { PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import { WalletType } from 'types/types'
import { WalletPayReceiverDetails, WalletsPayRequest } from 'types/wallets'
import { generateReference } from 'utils/utils'
import { PayForm } from '../Pay'

export const setFormDetails = (
  form: UseFormReturn<PayForm>,
  selectedWallet: WalletType,
  selectedBeneficiary: BeneficiaryDetails
) => {
  const paymentReference = !!selectedBeneficiary?.beneficiaryPaymentReference
    ? selectedBeneficiary?.beneficiaryPaymentReference
    : generateReference()

  form?.setValue('reference', paymentReference)
}

export const resetFields = (form: UseFormReturn<PayForm>) => {
  const fields: (keyof PayForm)[] = ['reference']

  fields.forEach(field => {
    form?.resetField(field, { defaultValue: null })
  })
}

export const getBvnkNetworkBeneficiaryOptions = (bvnkConnections: BvnkNetworkConnection[]) => {
  return bvnkConnections?.map(connection => {
    return {
      icon: connection?.details?.name || '',
      label: connection?.details?.name,
      value: connection?.id,
      secondLabel: null,
      secondLabelText: connection?.details?.description,
      rowLayout: false,
    }
  })
}

export const getBeneficiaryOptions = (
  beneficiaries: BeneficiaryDetails[],
  beneficiaryTransferDestinationFilters: BeneficiaryTransferDestination[],
  isB2cPayoutEnabled = false
) => {
  return beneficiaries
    ?.filter(beneficiary =>
      beneficiaryTransferDestinationFilters.includes(beneficiary?.transferDestination) &&
      isB2cPayoutEnabled
        ? true
        : beneficiary?.entityType !== BeneficiaryEntityType.INDIVIDUAL
    )
    ?.map(beneficiary => {
      const label =
        beneficiary?.businessName || `${beneficiary?.firstName} ${beneficiary?.lastName}`
      const aliasLabel = beneficiary?.alias ? `(${beneficiary.alias})` : ''
      return {
        icon: beneficiary?.currency?.toLowerCase() || '',
        label: `${label} ${aliasLabel} (${beneficiary?.entityType})`,
        value: beneficiary?.reference,
        secondLabel: ` ${beneficiary?.fiat?.code}`,
        secondLabelText: `${beneficiary?.fiat?.accountNumber}`,
      }
    })
}

export const getPayV1Request = (
  formValues: PayForm,
  selectedFiatBeneficiary: BeneficiaryDetails,
  twoFactorEnabled: boolean
): WalletsPayRequest => {
  const {
    fiat,
    address,
    addressCountry,
    entityType,
    transferDestination,
    firstName,
    lastName,
    dateOfBirth,
    businessName,
  } = selectedFiatBeneficiary || {}
  const { bankAddress, bankName, intermediaryBank, accountNumber, code } = fiat || {}

  const payRequest: WalletsPayRequest = {
    amount: Number(formValues?.amount),
    authorization: {},
    receiver: {
      ...(entityType && { type: entityType }),
      ...(addressCountry && { countryCode: addressCountry }),
      ...(formValues?.reference && { reference: formValues?.reference }),
      ...(transferDestination === BeneficiaryTransferDestination.INTERNATIONAL && {
        ...(address && { address }),
        ...(bankAddress && { bankAddress }),
        ...(intermediaryBank && { intermediaryBank }),
        ...(bankName && { bankName }),
      }),
      ...(accountNumber && { bankAccountNumber: accountNumber }),
      ...(code && { code }),
    } as WalletPayReceiverDetails,
  }

  if (entityType === BeneficiaryEntityType.INDIVIDUAL) {
    payRequest.receiver = {
      ...payRequest?.receiver,
      firstName,
      lastName,
      dateOfBirth,
    }
  } else {
    payRequest.receiver = {
      ...payRequest?.receiver,
      companyName: businessName,
    }
  }

  if (twoFactorEnabled) {
    payRequest.authorization = {
      totp: formValues?.password,
    }
  } else {
    payRequest.authorization = {
      password: formValues?.password,
    }
  }

  return payRequest
}

export const getPayRequest = (formValues: PayForm, selectedWallet: WalletType) => {
  return {
    walletId: selectedWallet?.lsid,
    amount: {
      value: Number(formValues?.amount),
      currency: selectedWallet?.currency?.code,
    },
    paymentReference: formValues?.reference,
    beneficiary: {
      reference: formValues?.beneficiarySelection,
    },
  } as PayoutsV2PreAuthRequest
}

export const getNetworkTransferRequest = (
  amount: number,
  currency: string,
  paymentReference: string,
  networkSender: { accountReference: string; walletId: string },
  networkBeneficiary: { accountReference: string; walletId: string }
) => {
  return {
    sender: {
      walletId: networkSender?.walletId,
      accountReference: networkSender?.accountReference,
    },
    beneficiary: {
      walletId: networkBeneficiary?.walletId,
      accountReference: networkBeneficiary?.accountReference,
    },
    amount: {
      value: amount,
      currency: currency,
    },
    customerReference: paymentReference,
  } as NetworkTransferRequest
}

export const getPayFeeRequest = (
  formValues: PayForm,
  selectedWallet: WalletType,
  isPayoutsV2Enabled: boolean
) => {
  return {
    walletId: isPayoutsV2Enabled ? selectedWallet?.lsid : selectedWallet?.id?.toString(),
    amount: {
      value: Number(formValues?.amount),
      currency: selectedWallet?.currency?.code,
    },
    paymentReference: formValues?.reference,
    beneficiary: {
      reference: formValues?.beneficiarySelection,
    },
  } as PayoutsV2PreAuthRequest
}

export const getRecipientName = (beneficiary: BeneficiaryDetails): string => {
  return beneficiary?.entityType === BeneficiaryEntityType.INDIVIDUAL
    ? `${beneficiary?.firstName} ${beneficiary?.lastName}`
    : beneficiary?.businessName
}

export const getInitialMaxPayoutAmountRequest = (
  formValues: PayForm,
  selectedWallet: WalletType,
  isPayoutsV2Enabled: boolean
) => {
  return {
    walletId: isPayoutsV2Enabled ? selectedWallet?.lsid : selectedWallet?.id?.toString(),
    amount: {
      value: 1,
      currency: selectedWallet?.currency?.code,
    },
    paymentReference: formValues?.reference,
    beneficiary: {
      reference: formValues?.beneficiarySelection,
    },
  } as PayoutsV2PreAuthRequest
}
