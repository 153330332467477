import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  Box as Wrapper,
  Text,
  TableRow,
  StatusPill,
  NotificationCard,
  NotificationState,
  SimpleTableV2,
} from '@node-space/storybook-components'
import { getStatusState, titleCase } from 'utils/utils'
import { PaymentType, PaymentsResponse } from 'types/payments'
import PaymentRowExpanded from './PaymentRowExpanded'

interface PaymentTableProps {
  transactions: PaymentsResponse[]
  withContentExpanded?: boolean
  isLoading: boolean
}

const PaymentTable = ({ transactions, withContentExpanded = false, isLoading }: PaymentTableProps) => {
  const { t } = useTranslation()

  const [rowExpanded, setRowExpanded] = useState(withContentExpanded)
  const singleRowTransactionResult = transactions?.length === 1

  useEffect(() => {
    if (!singleRowTransactionResult) {
      setRowExpanded(false)
    }
  }, [transactions])

  const rowHeaderWidth = 'px-8'
  const rowWidth = 'px-6 py-4 whitespace-nowrap'
  const amountWidth = 'pr-14 whitespace-nowrap'
  const expandWidth = 'px-4 mr--2'
  const dateWidth = 'py-0 '

  const convertPaymentInfo = (): TableRow[] => {
    if (!transactions) return []

    return transactions?.map(transaction => {
      const isPaymentIn = transaction?.type === PaymentType.IN

      return {
        rowData: [
          {
            cellData: <></>,
          },
          {
            cellData: <Text size="sm">{transaction?.reference}</Text>,
            className: rowWidth,
          },
          {
            cellData: isPaymentIn ? (
              <NotificationCard
                state={NotificationState.GREEN}
                label={`+ ${transaction?.displayCurrency?.amount} ${transaction?.displayCurrency?.currency}`}
                showBorder={false}
              />
            ) : (
              `- ${transaction?.displayCurrency?.amount} ${transaction?.displayCurrency?.currency}`
            ),
            align: 'end',
            className: amountWidth,
          },
          {
            cellData: `${dayjs(transaction?.dateCreated).format('DD MMM YYYY')}`,
            className: dateWidth,
          },
          {
            cellData: (
              <>
                <StatusPill
                  state={getStatusState(transaction?.status?.toUpperCase())}
                  label={titleCase(transaction?.status)}
                  fitContent
                />
                {transaction?.latePayment && (
                  <Text tag="span" className="ml-2">
                    <StatusPill state={'info'} label={`${t('latePayment')}`} fitContent />
                  </Text>
                )}
                {transaction?.overPayment && (
                  <Text tag="span" className="ml-2">
                    <StatusPill state={'info'} label={`${t('overPayment')}`} fitContent />
                  </Text>
                )}
                {!!transaction?.hasEnhancedChecks && (
                  <Text tag="span" className="ml-2">
                    <StatusPill
                      state={'warning'}
                      label={`${t('payments.statusOnHold')}`}
                      fitContent
                    />
                  </Text>
                )}
              </>
            ),
            className: rowWidth,
          },
        ],
        ...(rowExpanded && singleRowTransactionResult && { rowExpanded }),
        expandedContent: <PaymentRowExpanded transaction={transaction} />,
      }
    })
  }

  return (
    <Wrapper className="mt-6 overflow-x-auto bg-white">
      <SimpleTableV2
        isLoading={isLoading}
        expand={true}
        columns={[
          { headerTitle: '' },
          { headerTitle: t('payments.reference'), className: rowWidth },
          { headerTitle: t('payments.amount'), align: 'end', className: amountWidth },
          { headerTitle: t('payments.date'), className: dateWidth },
          { headerTitle: t('payments.status'), className: rowHeaderWidth },
          { headerTitle: '', className: expandWidth },
        ]}
        tableData={convertPaymentInfo()}
      />
    </Wrapper>
  )
}

export default React.memo(PaymentTable)
