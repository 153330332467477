export const ConnectionStatusTypes = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  INACTIVE: 'INACTIVE',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED',
  REQUESTED: 'REQUESTED',
} as const

export const NetworkConnectionViewTypes = {
  CONNECTIONS_VIEW: 'CONNECTIONS_VIEW',
  RECEIVED_INVITATIONS_VIEW: 'RECEIVED_INVITATIONS_VIEW',
  SENT_INVITATIONS_VIEW: 'SENT_INVITATIONS_VIEW',
  DISCOVER_MEMBERS_VIEW: 'DISCOVER_MEMBERS_VIEW',
} as const
