import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PathNames } from 'constants/General'
import { WalletCreateSuccessfulForm } from './components/Form/WalletCreateSuccessfulForm'

const WalletCreateSuccessful = () => {
  const location = useLocation()
  const wallet = location.state?.wallet
  const navigate = useNavigate()

  const onClickCreateWalletDone = () => {
    navigate(`${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`)
  }

  const onClickCreateWalletAltAction = (isFiatCurrency: boolean) => {
    isFiatCurrency
      ? navigate(`/wallets/${wallet.id}/fund`)
      : navigate(`/wallets/${wallet.id}/receive`)
  }

  return (
    <div className="page-content wallet-container">
      <WalletCreateSuccessfulForm
        wallet={wallet}
        onClickCreateWalletAltAction={onClickCreateWalletAltAction}
        onClickCreateWalletDone={onClickCreateWalletDone}
      />
    </div>
  )
}

export default WalletCreateSuccessful
