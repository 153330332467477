import { PaymentStatus } from 'types/payments'
import { Currency } from 'types/types'
import {
  FiatPayout,
  GetEditBeneficiaryPayloadProps,
  MassPayoutCryptoBatch,
  PayoutStatuses,
  PreProcessedPayouts,
} from './types'

export const getSumOfRecords = (records: PayoutStatuses | undefined | null): number => {
  if (!records) return 0
  return Object.values(records).reduce((sum, value) => sum + value, 0)
}

export const getValidRecords = (records: PayoutStatuses | undefined | null): number | undefined => {
  if (records && PaymentStatus.VALID in records) {
    return records[PaymentStatus.VALID]
  }
  return 0
}

export const getCurrencySrc = (
  currency: string,
  protocol: string,
  currencies: Currency[]
): string => {
  if (!currencies?.length) return process.env.ICONS_URL

  if (!currencies?.find(c => c?.code === currency)) {
    return `${process.env.ICONS_URL}`
  }

  if (currency !== protocol) {
    return `${process.env.ICONS_URL}${currency.toLowerCase()}-${protocol.toLowerCase()}.svg`
  }
  return `${process.env.ICONS_URL}${currency.toLowerCase()}.svg`
}

export const getSumOfRecordsWithErrors = (batch: MassPayoutCryptoBatch): number => {
  if (!batch?.items) return 0

  return Object.values(batch.items).filter(
    item => (item?.status === 'DRAFT' || item?.status === 'ERROR') && item?.errorMessage !== null
  ).length
}

export const getSumOfFiatRecordsWithErrors = (batch: FiatPayout): number => {
  if (!batch?.preProcessedPayouts?.length) return 0

  return batch?.preProcessedPayouts.filter(
    item => !!Object.keys(item?.validationErrors || {})?.length
  ).length
}

export const getEditBeneficiaryPayload = ({
  amount,
  type,
  transferDestination,
  beneficiaryDetails,
  businessName,
  accountNumber,
  bankCode,
  bankCountry,
  paymentReference,
  recordReference,
  firstName,
  lastName,
  dateOfBirth,
}: GetEditBeneficiaryPayloadProps) => {
  return {
    data: {
      amount: amount,
      enrichedBeneficiary: {
        type: type,
        transferDestination: transferDestination,
        firstName: firstName || null,
        lastName: lastName || null,
        dateOfBirth: dateOfBirth || null,
        businessName: businessName || null,
        country: beneficiaryDetails?.country || null,
        accountNumber: accountNumber,
        bankCode: bankCode,
        bankCountry: bankCountry,
      },
      paymentReference: paymentReference,
    },
    reference: recordReference,
  }
}

interface IsFiatB2CPaymentProps {
  firstName: string
  lastName: string
  dateOfBirth: string
  businessName: string
}

export const isFiatB2CPayment = ({
  firstName,
  lastName,
  dateOfBirth,
  businessName,
}: IsFiatB2CPaymentProps): boolean => {
  return (!!firstName || !!lastName || !!dateOfBirth) && !businessName
}

export const isFiatB2CBatch = (batchRecords: PreProcessedPayouts[]): boolean => {
  return batchRecords?.some(record => {
    const recordDetails = record?.beneficiaryDetails?.enrichedBeneficiary
    const { firstName, lastName, dateOfBirth, businessName } = recordDetails
    return isFiatB2CPayment({ firstName, lastName, dateOfBirth, businessName })
  })
}
