export const AccountAgreementStatus = {
  PENDING: 'PENDING',
  SIGNED: 'SIGNED',
  DECLINED: 'DECLINED',
} as const
export type AccountAgreementStatusType =
  (typeof AccountAgreementStatus)[keyof typeof AccountAgreementStatus]

export const AgreementOptions = {
  EMI: 'EMI',
  MODULR: 'MODULR',
} as const
export type AgreementTypes = (typeof AgreementOptions)[keyof typeof AgreementOptions]

export const SharedAgreementCountries = {
  UK: 'UK',
  EU: 'EU',
} as const
export type SharedAgreementCountriesType =
  (typeof SharedAgreementCountries)[keyof typeof SharedAgreementCountries]

export const ModulrCountries = {
  ...SharedAgreementCountries,
} as const
export type ModulrCountriesType = SharedAgreementCountriesType &
  (typeof ModulrCountries)[keyof typeof ModulrCountries]

export const EMICountries = {
  ...SharedAgreementCountries,
  EEA: 'EEA',
} as const
export type EMICountriesType = SharedAgreementCountriesType &
  (typeof EMICountries)[keyof typeof EMICountries]

export type AgreementLinkType = {
  url: string
  label: string
}

export type AgreementModalData = {
  agreementId: number
  title: string
  bodyContent: React.ReactNode
  links: AgreementLinkType[]
  appendixLinks?: AgreementLinkType[]
  checkboxLabel: string
  status: AccountAgreementStatusType
  isDeclinable: boolean
}

export interface AccountAgreementAcceptancePayload {
  id: number
  status: AccountAgreementStatusType
}
