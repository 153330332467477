import { useQuery } from '@tanstack/react-query'
import { LegalAgreementStatusType } from 'pages/LegalAgreements/types'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getLegalAgreements } from 'services/AgreementsService'

export const useLegalAgreementsQuery = (
  statusParams?: LegalAgreementStatusType[],
  isEnabled = true
) => {
  return useQuery({
    queryKey: reactQueryKeys.legalAgreements(statusParams),
    queryFn: () => getLegalAgreements(statusParams),
    enabled: isEnabled,
  })
}
