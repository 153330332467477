import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getDocument } from 'services/DocumentService'
import { QueryMetaType } from 'types/reactQuery'

export const usePopDownloadQuery = (url: string, enabled = false) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { url },
  }

  return useQuery({
    queryKey: reactQueryKeys.documentDownload(url),
    queryFn: () => getDocument(url),
    enabled: enabled,
    gcTime: 1800000,
    meta,
  })
}
