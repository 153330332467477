import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Box as OuterWrapper, Box as Item, Select } from '@node-space/storybook-components'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import { PaymentMerchantDefault } from 'types/payments'
import { titleCase } from 'utils/utils'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'

interface FilterMerchantProps {
  onMerchantSelected: (value: string) => void
}

const FilterMerchant = ({ onMerchantSelected }: FilterMerchantProps) => {
  const { t } = useTranslation()

  const { data: { merchantListOptions: options = [] } = {} } = useMerchantsQuery()

  const { merchant } = useFilterMerchant()

  const merchantListOptions = () => {
    return [
      { value: PaymentMerchantDefault.ALL, label: titleCase(PaymentMerchantDefault.ALL) },
      ...options,
    ]
  }

  const onMerchantChange = merchant => {
    onMerchantSelected(merchant)
  }

  return (
    <OuterWrapper testid="filter-merchant-field-wrapper" className="col-span-6">
      <Text size="sm" weight="medium" className="mb-1">
        {t('merchantId')}
      </Text>
      <Item style={{ height: '40px' }}>
        <Select
          name="merchantId"
          data-testid="filter-merchant-field"
          value={merchant}
          onChange={onMerchantChange}
          options={merchantListOptions()}
          isSearchable={true}
        />
      </Item>
    </OuterWrapper>
  )
}

export default FilterMerchant
