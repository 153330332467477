import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import Icon from '@node-space/storybook-components/dist/Icon'
import {
  AlignActions,
  Modal,
  ModalActions,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import Text from '@node-space/storybook-components/dist/Text'
import { TextMask } from '@node-space/storybook-components/dist/TextMask'
import { ellipseMiddleText } from '@node-space/utils'
import { ApiKeyCreateResponse } from 'types/apiKeys'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { Amp } from 'utils/tracker'

type ApiKeySuccessModalProps = {
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>
  response: ApiKeyCreateResponse
}

const ApiKeySuccessModal = ({ setShowSuccessModal, response }: ApiKeySuccessModalProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    Amp.track(AmplitudeEvent.EOF_INITIATE_API_KEY_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
    })
  }, [])

  const actions: ButtonProps[] = [
    {
      children: t('done'),
      testid: t('done'),
      disabled: false,
      onClick: () => setShowSuccessModal(false),
    },
  ]

  return (
    <Modal visible closeModal={() => setShowSuccessModal(false)}>
      <ModalNavBar onClose={() => setShowSuccessModal(false)} hasDivider={false} />
      <Box flex direction="col" alignItems="center" padding={16}>
        <Icon name="ClapIcon" color="status-success-500" size="lg" />
        <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
          {t('manageApiKeys.apiKeyCreated')}
        </Text>
      </Box>
      <Box paddingX={24} paddingT={4} paddingB={8}>
        <Callout state="warning" message={t('keepSecretKeySafe')} />
      </Box>
      {!!response && (
        <SummaryDetail testid="delete-crypto-address">
          {response?.access_token && (
            <SummaryRow
              label={t('manageApiKeys.hawkAuthId')}
              renderDescription={
                <Box flex direction="row" justifyContent="between">
                  <TextMask text={ellipseMiddleText(response?.access_token, 25, 10)} />
                  <CopyButton
                    copyText={response?.access_token?.toString()}
                    confirmationMessage={t('copiedToClipboard')}
                  />
                </Box>
              }
            />
          )}
          {response?.secret && (
            <SummaryRow
              label={t('manageApiKeys.secretKey')}
              renderDescription={
                <Box flex direction="row" justifyContent="between">
                  <TextMask text={ellipseMiddleText(response?.secret, 25, 10)} />
                  <CopyButton
                    copyText={response?.secret?.toString()}
                    confirmationMessage={t('copiedToClipboard')}
                  />
                </Box>
              }
            />
          )}
        </SummaryDetail>
      )}
      <ModalActions actions={actions} alignActions={AlignActions.CENTER} />
    </Modal>
  )
}

export default ApiKeySuccessModal
