import React, { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Select } from '@node-space/storybook-components/dist/Select'
import { useUpdatePreProcessedRecordMutation } from 'hooks/mutations/useUpdatePreProcessedRecordMutation'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useToastContext } from 'hooks/useToastContext'
import { useSetFiatRecordToEditErrors } from 'pages/MassPayouts/hooks/useSetFiatRecordToEditErrors'
import { updateFiatPayoutSchema } from 'pages/MassPayouts/schemas/updateFiatPayoutSchema'
import {
  PreProcessedPayouts,
  UpdateFiatPayoutFormPayload,
  UpdateFiatPayoutFormPayloadKeys,
} from 'pages/MassPayouts/types'
import { getEditBeneficiaryPayload, isFiatB2CPayment } from 'pages/MassPayouts/utils'
import {
  BaseErrorResponse,
  BeneficiaryTransferDestination,
  BeneficiaryType,
} from 'types/beneficiaries'
import { mapToOptions } from 'utils/utils'

interface FiatPayoutEditRecordFormProps {
  record: PreProcessedPayouts
  setIsEditPayoutModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setPreProcessedRecordToUpdate: React.Dispatch<React.SetStateAction<PreProcessedPayouts>>
  walletCurrency: string
  isDeletingPreProcessedRecord: boolean
  onDeletePreProcessedRecordRequest: (reference: string) => void
}

export const FiatPayoutEditRecordForm = ({
  record,
  setIsEditPayoutModalVisible,
  setPreProcessedRecordToUpdate,
  walletCurrency,
  onDeletePreProcessedRecordRequest,
  isDeletingPreProcessedRecord,
}: FiatPayoutEditRecordFormProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const formSchema = updateFiatPayoutSchema(t)
  const { control, formState, register, setValue, trigger, clearErrors, setError } =
    useForm<UpdateFiatPayoutFormPayload>({
      resolver: yupResolver(formSchema),
    })

  const {
    mutate: mutateUpdatePreProcessedRecord,
    isPending: isLoadingUpdatePreProcessedRecord,
    isError: isError,
  } = useUpdatePreProcessedRecordMutation()
  const { enrichedBeneficiary: beneficiaryDetails } = record?.beneficiaryDetails ?? {}

  const [
    accountNumber,
    amount,
    bankCode,
    bankCountry,
    type,
    businessName,
    dateOfBirth,
    firstName,
    lastName,
    paymentReference,
    transferDestination,
  ] = useWatch({
    control,
    name: [
      'accountNumber',
      'amount',
      'bankCode',
      'bankCountry',
      'type',
      'businessName',
      'dateOfBirth',
      'firstName',
      'lastName',
      'paymentReference',
      'transferDestination',
    ],
  })

  useEffect(() => {
    if (record) {
      const { enrichedBeneficiary: beneficiaryDetails } = record?.beneficiaryDetails ?? {}

      setValue('accountNumber', beneficiaryDetails?.accountNumber)
      setValue('amount', record?.amount)
      setValue('bankCode', beneficiaryDetails?.bankCode)
      setValue('bankCountry', beneficiaryDetails?.bankCountry)
      setValue('businessName', beneficiaryDetails?.businessName)
      setValue('dateOfBirth', beneficiaryDetails?.dateOfBirth)
      setValue('firstName', beneficiaryDetails?.firstName)
      setValue('lastName', beneficiaryDetails?.lastName)
      setValue('paymentReference', record?.paymentReference)
      setValue('transferDestination', beneficiaryDetails?.transferDestination)
      setValue('type', beneficiaryDetails?.type)
    }
  }, [record])

  const isB2CPayout = useMemo(() => {
    return isFiatB2CPayment({ firstName, lastName, dateOfBirth, businessName })
  }, [firstName, lastName, dateOfBirth, businessName])

  const { data: countries, isPending: isCountriesLoading } = useCountriesQuery(true)
  const [generalError, setGeneralError] = useState('')
  const [paymentDisabled, setPaymentDisabled] = useState(false)

  useSetFiatRecordToEditErrors({
    record,
    walletCurrency,
    setError,
    setGeneralError,
    setPaymentDisabled,
  })

  const countryOptions = useMemo(() => {
    if (countries?.length) {
      return mapToOptions(countries)
    }

    return []
  }, [countries])

  useEffect(() => {
    if (countryOptions?.length && bankCountry) {
      const bankCountryFound = countryOptions?.find(country => country?.value === bankCountry)

      if (!bankCountryFound) {
        setValue('bankCountry', '')
        setError?.('bankCountry', { message: t('massPayouts.fiatErrors.bankCountry') })
      }
    }
  }, [countryOptions, bankCountry])

  useEffect(() => {
    if (transferDestination && transferDestination !== BeneficiaryTransferDestination.LOCAL) {
      setValue('transferDestination', '')
      setError?.('transferDestination', {
        message: t('massPayouts.fiatErrors.transferDestination'),
      })
    }
  }, [transferDestination])

  const isPayoutUpdateFormDisabled = useMemo(() => {
    if (isB2CPayout) {
      return (
        !bankCode ||
        !accountNumber ||
        !amount ||
        !paymentReference ||
        !bankCountry ||
        !firstName ||
        !lastName ||
        !dateOfBirth
      )
    } else {
      return (
        !businessName || !bankCode || !accountNumber || !amount || !paymentReference || !bankCountry
      )
    }
  }, [
    isB2CPayout,
    bankCode,
    accountNumber,
    amount,
    paymentReference,
    bankCountry,
    firstName,
    lastName,
    dateOfBirth,
  ])

  const onBeneficiaryTypeSelection = (beneficiaryType: BeneficiaryType) => {
    setValue('type', beneficiaryType)
  }

  const onTransferDestinationSelection = (type: BeneficiaryTransferDestination) => {
    setValue('transferDestination', type)
  }

  const getBeneficiaryTypeDetails = (isB2C: boolean) => {
    if (isB2C) {
      return (
        <>
          <Box flex flexRow gapX={12}>
            <Input
              {...register('firstName')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('firstName', e.target.value)
              }
              label={t('formFields.firstName')}
              placeholder={t('enterName')}
              inputMode="text"
              name="firstName"
              testid="first-name"
              errorText={formState?.errors?.firstName?.message}
              error={!!formState?.errors?.firstName}
              value={firstName}
            />
            <Input
              {...register('lastName')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('lastName', e.target.value)
              }
              label={t('formFields.lastName')}
              placeholder={t('enterName')}
              inputMode="text"
              name="lastName"
              testid="last-name"
              errorText={formState?.errors?.lastName?.message}
              error={!!formState?.errors?.lastName}
              value={lastName}
            />
          </Box>
          <Box>
            <Input
              {...register('dateOfBirth')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('dateOfBirth', e.target.value)
              }
              label={t('formFields.dateOfBirth')}
              placeholder={t('formFields.dateOfBirthFormat')}
              inputMode="text"
              name="dateOfBirth"
              testid="date-of-birth"
              errorText={formState?.errors?.dateOfBirth?.message}
              error={!!formState?.errors?.dateOfBirth}
              value={dateOfBirth}
            />
          </Box>
        </>
      )
    } else {
      return (
        <Box>
          <Input
            testid="update-business-name-input"
            {...register('businessName')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue('businessName', e.target.value)
            }
            label={t('massPayouts.beneficiaryName')}
            inputMode="text"
            name="businessName"
            value={businessName}
            error={!!formState?.errors?.businessName}
            errorText={formState?.errors?.businessName?.message}
          />
        </Box>
      )
    }
  }

  const onUpdatePreProcessedRecordRequest = async () => {
    const commonFields: UpdateFiatPayoutFormPayloadKeys[] = [
      'bankCode',
      'accountNumber',
      'amount',
      'paymentReference',
      'bankCountry',
      'transferDestination',
      'type',
    ]

    const validationFields: UpdateFiatPayoutFormPayloadKeys[] = isB2CPayout
      ? [...commonFields, 'firstName', 'lastName', 'dateOfBirth']
      : [...commonFields, 'businessName']

    const isValidDetails = await trigger(validationFields)

    if (isValidDetails) {
      clearErrors()
      const payload = getEditBeneficiaryPayload({
        businessName,
        bankCode,
        accountNumber,
        amount,
        paymentReference,
        bankCountry,
        firstName,
        lastName,
        dateOfBirth,
        type,
        transferDestination,
        beneficiaryDetails,
        recordReference: record?.reference,
      })
      mutateUpdatePreProcessedRecord(payload, {
        onSuccess: () => {
          setIsEditPayoutModalVisible(false)
          queryClient.invalidateQueries({ queryKey: ['massFiatPayoutsDetails'] })
          setPreProcessedRecordToUpdate(null)
          addToast({
            title: t('massPayouts.paymentUpdated'),
            state: 'success',
            delay: 0,
          })
        },
        onError: (error: BaseErrorResponse) => {
          addToast({
            title: t('oopsSomethingWentWrong'),
            state: 'error',
            delay: 0,
          })
          const bvknErrorStatus = error?.status
          if (bvknErrorStatus === 400) {
            // Set appropriate form error message if validation fails.
          } else {
            // Display error message sent from the BE.
          }
        },
      })
    }
  }

  const actions: ButtonProps[] = [
    {
      children: t('massPayouts.removeFromBatch'),
      testid: 'remove-from-batch',
      secondary: true,
      loading: isDeletingPreProcessedRecord,
      onClick: () => onDeletePreProcessedRecordRequest(record?.reference),
    },
    ...(!paymentDisabled
      ? [
          {
            children: t('massPayouts.update'),
            testid: 'update-payout',
            onClick: () => onUpdatePreProcessedRecordRequest(),
            loading: isLoadingUpdatePreProcessedRecord,
            disabled: isPayoutUpdateFormDisabled,
          },
        ]
      : []),
  ]

  return (
    <>
      <Box paddingX={24} className="my-6 space-y-6 text-gray-100">
        {!!generalError && <Callout state="error" message={generalError} />}
        <Box>
          <Radio
            {...register('type')}
            height={36}
            name="type"
            horizontal={true}
            borderless={false}
            label={t('manageBeneficiaries.beneficiaryType')}
            onChange={onBeneficiaryTypeSelection}
            options={[
              {
                label: t('internal'),
                value: BeneficiaryType.FIRST_PARTY,
              },
              {
                label: t('external'),
                value: BeneficiaryType.THIRD_PARTY,
              },
            ]}
            value={type}
            error={!!formState?.errors?.type}
            errorText={formState?.errors?.type?.message}
          />
        </Box>
        {getBeneficiaryTypeDetails(isB2CPayout)}
        <Input
          testid="update-bank-code-input"
          {...register('bankCode')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue('bankCode', e.target.value)
          }
          label={t('massPayouts.bankCode')}
          inputMode="text"
          name="bankCode"
          value={bankCode}
          error={!!formState?.errors?.bankCode}
          errorText={formState?.errors?.bankCode?.message}
        />
        <Input
          testid="update-account-number-input"
          {...register('accountNumber')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue('accountNumber', e.target.value)
          }
          label={t('massPayouts.accountNumber')}
          inputMode="text"
          name="accountNumber"
          value={accountNumber}
          error={!!formState?.errors?.accountNumber}
          errorText={formState?.errors?.accountNumber?.message}
        />
        <Box>
          <Radio
            {...register('transferDestination')}
            height={36}
            name="transferDestination"
            horizontal={true}
            borderless={false}
            label={t('massPayouts.beneficiaryLocation')}
            onChange={onTransferDestinationSelection}
            options={[
              {
                label: t('manageBeneficiaries.local'),
                value: BeneficiaryTransferDestination.LOCAL,
              },
              {
                label: t('manageBeneficiaries.international'),
                value: BeneficiaryTransferDestination.INTERNATIONAL,
                disabled: true,
              },
            ]}
            value={transferDestination}
            error={!!formState?.errors?.transferDestination}
            errorText={formState?.errors?.transferDestination?.message}
          />
        </Box>
        <Select
          {...register('bankCountry')}
          label={t('bankCountry')}
          value={bankCountry}
          isSearchable
          options={countryOptions}
          error={!!formState?.errors?.bankCountry}
          errorText={formState?.errors?.bankCountry?.message}
          placeholder={t('searchOrSelectACountry')}
          loading={isCountriesLoading}
          onChange={(countryCode: string) => {
            setValue('bankCountry', countryCode)
          }}
        />
        <Input
          testid="update-amount-input"
          {...register('amount')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('amount', e.target.value)}
          label={t('massPayouts.amount')}
          inputMode="text"
          name="amount"
          value={amount}
          error={!!formState?.errors?.amount}
          errorText={formState?.errors?.amount?.message}
        />
        <Input
          testid="update-reference-input"
          {...register('paymentReference')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue('paymentReference', e.target.value)
          }
          label={t('massPayouts.paymentReference')}
          inputMode="text"
          name="paymentReference"
          value={paymentReference}
          error={!!formState?.errors?.paymentReference}
          errorText={formState?.errors?.paymentReference?.message}
        />
      </Box>
      <ModalActions actions={actions} />
    </>
  )
}
