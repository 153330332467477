import React, { useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useFeatureFlags } from '@node-space/hooks'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useBeneficiariesQuery } from 'hooks/queries/useBeneficiariesQuery'
import {
  PayoutsFeeEstimateParams,
  usePayoutsFeeEstimateQuery,
} from 'hooks/queries/usePayoutsFeeEstimateQuery'
import { useApprovalsConfigForFlow } from 'hooks/useApprovalsConfigForFlow'
import RequiresApprovalSuccessModal from 'pages/Approvals/components/RequiresApprovalSuccessModal'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { getPaymentMethods, getWalletLimits } from 'services/WalletsService'
import { BeneficiaryDetails, BeneficiaryType } from 'types/beneficiaries'
import { BankAccount, Currencies, Limits } from 'types/types'
import { PayFee, WithdrawSteps } from 'types/wallets'
import { getWithdrawRequest } from 'utils/withdraw'
import { WalletTabProps } from '../Modal/WalletModal'
import { selectedFiatBeneficiaryAtom } from '../Pay/atoms/atoms'
import Withdraw2fa from './Withdraw2fa'
import WithdrawComplete from './WithdrawComplete'
import WithdrawConfirm from './WithdrawConfirm'
import WithdrawForm from './WithdrawForm'

export type WithdrawForm = {
  walletId?: string
  password?: string
  bankAccountId?: string
  amount?: string | number
  reference?: string
  currencyCode?: string
  challenge?: string
}

export type WithdrawActionProps = WalletTabProps &
  IWalletActionApiType<WithdrawForm, WithdrawSteps> & {
    withdrawalLimits?: Limits
    bankAccounts?: BankAccount[]
    withdrawalFee?: PayFee
    hasWithdrawalFeeError?: boolean
    isBeneficiaryListError?: boolean
    beneficiaries?: BeneficiaryDetails[]
    isFetchingFee: boolean
  }

/**
 * Withdraw Tab containing state, form and API data for each step
 */
export const Withdraw = ({
  loading,
  step,
  form,
  wallets,
  setLoading,
  ...otherProps
}: WithdrawActionProps) => {
  const selectedFiatBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const { enablePayoutsV2 } = useFeatureFlags()

  const [isLimitsLoading, setIsLimitsLoading] = useState(false)
  const [isBankAccountsLoading, setIsBankAccountsLoading] = useState(false)
  const [withdrawalLimits, setWithdrawalLimits] = useState<Limits>()
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>()
  const formValues = form.watch()

  const { approvalsIsRequiredForFlow, isFetchingApprovalPreference } = useApprovalsConfigForFlow(
    Approval.FLOWS.FIAT_1ST_PARTY_PAYOUT,
    formValues?.amount
  )

  const wallet = wallets?.find(wallet => wallet?.id?.toString() === formValues?.walletId)

  const selectedBankAccount =
    formValues.bankAccountId &&
    bankAccounts?.find(
      bankAccount =>
        (bankAccount?.id?.toString() || bankAccount?.reference) === formValues.bankAccountId
    )
  const isLegacyPaymentMethod = !!selectedBankAccount?.id && !selectedBankAccount?.reference
  const isPayoutsV2Enabled =
    enablePayoutsV2 && !isLegacyPaymentMethod && !!wallet?.supportedTransferDestinations?.length

  const isEnabled = isPayoutsV2Enabled
    ? !!formValues?.walletId && !!formValues.amount && !!formValues?.reference
    : !!formValues?.walletId && !!formValues.amount

  const request = getWithdrawRequest(formValues, wallet, isPayoutsV2Enabled)

  const payoutFeeEstimateParams: PayoutsFeeEstimateParams = {
    request,
    isEnabled,
    isThirdPartyPayment: false,
    isPayoutFeesV2: isPayoutsV2Enabled,
    transferDestination: selectedFiatBeneficiary?.transferDestination,
  }

  const {
    data: withdrawalFee,
    isError: isWithdrawalFeeError,
    isFetching: isFetchingFee,
  } = usePayoutsFeeEstimateQuery(payoutFeeEstimateParams)

  const {
    isError: isBeneficiaryListError,
    isFetching: isBeneficiariesLoading,
    data: beneficiaryResponse,
  } = useBeneficiariesQuery(
    {
      beneficiaryType: BeneficiaryType.FIRST_PARTY,
      currency: wallet?.currency?.code as Currencies,
    },
    true
  )

  const props = {
    withdrawalLimits,
    bankAccounts,
    loading: loading || isFetchingFee || isBeneficiariesLoading,
    step,
    form,
    setLoading,
    wallets,
    withdrawalFee: withdrawalFee,
    hasWithdrawalFeeError: isWithdrawalFeeError,
    isBeneficiaryListError,
    ...otherProps,
  }

  /**
   * Get user's bank accounts
   */
  useEffect(() => {
    if (beneficiaryResponse?.content.length) {
      // get bank accounts
      setIsBankAccountsLoading(true)
      getPaymentMethods()
        .then(bankAccounts => {
          const updatedBankAccounts = bankAccounts?.map(bankAccount => {
            const matchingBeneficiary = beneficiaryResponse?.content?.find(
              beneficiary => beneficiary?.reference === bankAccount?.reference
            )

            return {
              ...bankAccount,
              alias: matchingBeneficiary?.alias,
              beneficiaryPaymentReference: matchingBeneficiary?.beneficiaryPaymentReference,
            }
          })
          if (formValues.walletId && updatedBankAccounts?.length) {
            const bankAccountId = updatedBankAccounts
              ?.find(bankAccount => bankAccount?.currency === wallet?.currency?.code)
              ?.id?.toString()

            if (bankAccountId) {
              form.setValue('bankAccountId', bankAccountId)
            }
          }
          setBankAccounts(updatedBankAccounts)
        })
        .catch(err => {
          logSentryError('Error from Withdraw - getPaymentMethods', err)
        })
        .finally(() => {
          setIsBankAccountsLoading(false)
        })
    }
  }, [beneficiaryResponse?.content])

  useEffect(() => {
    form.setValue('currencyCode', wallet?.currency?.code)
  }, [wallet])

  /**
   * Get withdrawal limits for selected curreny code
   */
  useEffect(() => {
    setIsLimitsLoading(true)
    if (wallet && !isLimitsLoading && bankAccounts) {
      setWithdrawalLimits(null)

      const bankAccountId = bankAccounts
        ?.find(bankAccount => bankAccount?.currency === wallet?.currency?.code)
        ?.id?.toString()

      form.setValue('bankAccountId', bankAccountId)

      // get withdrawal limits for selected currency
      getWalletLimits(wallet?.currency?.code, wallet?.id)
        .then(currencyLimits => {
          const limits = currencyLimits?.withdrawalLimits?.current
          limits && setWithdrawalLimits(limits)
        })
        .catch(err => {
          logSentryError('Error from Withdraw - getWalletLimits', err)
        })
        .finally(() => {
          setLoading(false)
          setIsLimitsLoading(false)
        })
    }
    setIsLimitsLoading(false)
  }, [wallet, isLimitsLoading, bankAccounts])

  const isBusy = useMemo(
    () => loading || isLimitsLoading || isBankAccountsLoading || isFetchingFee,
    [loading, isLimitsLoading, isBankAccountsLoading]
  )

  const isRequiresApprovalModalVisible = approvalsIsRequiredForFlow && !isFetchingApprovalPreference

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case '2fa':
        return <Withdraw2fa {...props} />
      case 'form':
        return (
          <WithdrawForm
            {...props}
            loading={isBusy}
            isFetchingFee={isFetchingFee}
            beneficiaries={beneficiaryResponse?.content}
          />
        )
      case 'confirm':
        return <WithdrawConfirm {...props} />
      case 'complete':
        return isRequiresApprovalModalVisible ? (
          <RequiresApprovalSuccessModal onClose={props?.dismissAction} actionType={props?.action} />
        ) : (
          !isFetchingApprovalPreference && <WithdrawComplete {...props} />
        )
    }
  }

  return renderStep()
}
