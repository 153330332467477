import React, { createContext } from 'react'
import { MerchantType } from 'types/types'

export const MerchantsContext = createContext<MerchantType[]>([])

interface MerchantsProviderProps {
  merchants: MerchantType[]
  children: React.ReactNode
}

export const MerchantsProvider = ({ merchants, children }: MerchantsProviderProps) => {
  return <MerchantsContext.Provider value={merchants}>{children}</MerchantsContext.Provider>
}
