import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getMerchants } from 'services/MerchantService'
import { isTestEnvironment, TEN_MINUTES } from 'utils/utils'

export const useMerchantsQuery = () => {
  const { isAuthed } = useAuth()

  return useQuery({
    queryKey: reactQueryKeys.merchants(),
    queryFn: getMerchants,
    refetchOnReconnect: false,
    staleTime: TEN_MINUTES,
    select: merchantList => ({
      merchantList: merchantList,
      merchantListOptions: merchantList?.map(merchant => {
        return {
          value: merchant?.merchantId,
          label: `${merchant?.displayName} (${merchant?.merchantId?.substring(0, 6)} ...)`,
        }
      }),
    }),
    enabled: isTestEnvironment || !!isAuthed,
  })
}
