import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import Box, { Box as Item } from '@node-space/storybook-components/dist/Box'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import {
  SimpleTableV2 as Table,
  TableColumnV2 as TableColumn,
  TableRowV2 as TableRow,
} from '@node-space/storybook-components/dist/SimpleTableV2'
import SmallTabs from '@node-space/storybook-components/dist/SmallTabs'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import TablePagination from 'components/PageableTablePagination'
import {
  TablePopoverMenu,
  TablePopoverMenuItemType,
} from 'components/TablePopoverMenu/TablePopoverMenu'
import { DateFormat, PathNames } from 'constants/General'
import { useGetCryptoMassPayoutsListByPolling } from 'hooks/queries/MassPayouts/useGetCryptoMassPayoutsListByPolling'
import { useGetMassFiatPayoutsListQuery } from 'hooks/queries/MassPayouts/useGetMassFiatPayoutsList'
import { useWalletsQueryV2 } from 'hooks/queries/useWalletsQueryV2'
import { usePaginationPagingPersist } from 'hooks/usePaginationPagingPersist'
import { PaginationPaging } from 'types/pagination'
import { PaymentStatus } from 'types/payments'
import { WalletTypes } from 'types/types'
import { getMassPayoutCryptoBatchStatus, getMassPayoutStatusState, titleCase } from 'utils/utils'
import { walletTypeAtom } from '../atoms/massPayoutAtoms'
import MassPayoutHeader from '../components/MassPayoutHeader'
import { CryptoBatchSidePanel } from '../components/SidePanel/CryptoBatchSidePanel'
import { FiatBatchSidePanel } from '../components/SidePanel/FiatBatchSidePanel'
import { BatchStatus, FiatPayout, MassPayoutButtonGroup } from '../types'

export const MassPayoutBatchView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enableMassPayoutsFiat } = useFeatureFlags()

  const [page, setPage] = useState<number>(0)
  const [walletType, setWalletType] = useAtom(walletTypeAtom)
  const { tab } = queryString.parse(window.location.search) as { tab?: WalletTypes }
  const [activeTab, setActiveTab] = useState(tab || WalletTypes.FIAT)

  const { data: cryptoBatchListPolling, isPending: isLoadingMassCryptoPayoutList } =
    useGetCryptoMassPayoutsListByPolling(activeTab === WalletTypes.CRYPTO)

  const { data: wallets, isLoading: isLoadingWallets } = useWalletsQueryV2({
    walletType: WalletTypes.FIAT,
    max: 1000,
  })

  const {
    data: { content: massFiatPayoutList, pagination: fiatListPagination } = {},
    isError: massFiatPayoutListError,
    refetch: fetchMassFiatPayoutList,
    isPending: isLoadingMassFiatPayoutList,
  } = useGetMassFiatPayoutsListQuery({ page, enabled: false })

  const { paging, showPagination } = usePaginationPagingPersist(fiatListPagination)

  useEffect(() => {
    enableMassPayoutsFiat && fetchMassFiatPayoutList()
  }, [page])

  const {
    isSidePanelOpen: isFiatSidePanelOpen,
    sidePanelData: fiatSidePanelData,
    paging: fiatPaging,
    setIsSidePanelOpen: fiatSetIsSidePanelOpen,
    updateSidePanel: fiatUpdateSidePanel,
  } = useSidePanel<FiatPayout>(massFiatPayoutList)

  const {
    isSidePanelOpen: isCryptoSidePanelOpen,
    sidePanelData: cryptoSidePanelData,
    paging: cryptoPaging,
    setIsSidePanelOpen: cryptoSetIsSidePanelOpen,
    updateSidePanel: cryptoUpdateSidePanel,
  } = useSidePanel(cryptoBatchListPolling)

  const onOpenFiatSidePanel = (payoutIndex: number) => {
    fiatUpdateSidePanel(payoutIndex)
    fiatSetIsSidePanelOpen(true)
  }

  const onOpenCryptoSidePanel = (payoutIndex: number) => {
    cryptoUpdateSidePanel(payoutIndex)
    cryptoSetIsSidePanelOpen(true)
  }

  const goToFiatMassPayout = (batch: FiatPayout) => {
    navigate(
      `${PathNames.MASS_PAYOUTS}/payout-details/${batch.reference}?batchType=${activeTab.toLowerCase()}`
    )
  }

  const fiatColumns: TableColumn[] = [
    {
      headerTitle: t('massPayouts.batch'),
      className: 'pl-4',
      width: '25%',
      testid: `mass-payouts-column-batch`,
    },
    {
      headerTitle: t('massPayouts.date'),
      width: '25%',
      testid: `mass-payouts-column-date`,
    },
    {
      width: '25%',
      headerTitle: t('massPayouts.uploadedBy'),
      className: 'pr-4',
      testid: `mass-payouts-column-errors`,
    },
    {
      width: '25%',
      headerTitle: t('massPayouts.status'),
      className: 'pr-4',
      testid: `mass-payouts-column-errors`,
    },
    {
      width: '10%',
      headerTitle: null,
      align: 'end',
      testid: `mass-payouts-column-errors`,
    },
  ]

  const fiatTableData: TableRow[] = massFiatPayoutList?.map((batch, payoutIndex?: number) => ({
    rowData: [
      {
        cellData: (
          <Item flex paddingY={24} paddingL={16} gapX={4}>
            <Text size="sm">{batch?.alias}</Text>
          </Item>
        ),
        testid: `batch-alias`,
      },
      {
        cellData: (
          <Item flex paddingY={24}>
            <Text size="sm">{dayjs(batch?.createdAt).format('DD MMMM YYYY')}</Text>
          </Item>
        ),
        testid: `batch-upload-date`,
      },
      {
        cellData: (
          <Item flex paddingY={24}>
            <Text size="sm">{batch?.createdBy?.fullName}</Text>
          </Item>
        ),
        testid: `individual-payout-operations`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            <StatusPill
              state={getMassPayoutStatusState(batch?.status?.toUpperCase())}
              label={titleCase(batch?.status)}
              fitContent
            />
          </Item>
        ),
        testid: `individual-record-errors`,
      },
      {
        cellData: (
          <TablePopoverMenu
            key={`${batch?.reference}(${batch.alias})`}
            hasPadding
            testid="mass-payout-list-popver-menu"
            stopPropagation
            menuItems={[
              {
                iconName: 'InfoCircleIcon',
                label: t('details'),
                onClick: () => onOpenFiatSidePanel(payoutIndex),
              },
              ...(batch?.status !== PaymentStatus.PROCESSING &&
              batch?.status !== PaymentStatus.COMPLETED
                ? [
                    {
                      iconName: 'EditIcon',
                      label: t('massPayouts.editDraft'),
                      onClick: () => goToFiatMassPayout(batch),
                    } as TablePopoverMenuItemType,
                  ]
                : []),
            ]}
          />
        ),
      },
    ],
    onClick: () => goToFiatMassPayout(batch),
  }))

  const cryptoColumns: TableColumn[] = [
    {
      headerTitle: t('massPayouts.batch'),
      className: 'pl-4',
      width: '25%',
      testid: `mass-payouts-column-batch`,
    },
    {
      headerTitle: t('massPayouts.date'),
      width: '15%',
      testid: `mass-payouts-column-date`,
    },
    {
      width: '15%',
      headerTitle: t('massPayouts.payoutsProcessed'),
      className: 'pr-4',
      testid: `mass-payouts-column`,
    },
    {
      width: '25%',
      headerTitle: t('massPayouts.uploadedBy'),
      className: 'pr-4',
      testid: `mass-payouts-column-errors`,
    },
    {
      width: '20%',
      headerTitle: t('massPayouts.status'),
      className: 'pr-4',
      testid: `mass-payouts-column-errors`,
    },
    {
      width: '10%',
      headerTitle: null,
      align: 'end',
      testid: `mass-payouts-column-errors`,
    },
  ]

  const cryptoTableData: TableRow[] = cryptoBatchListPolling?.map(
    (cryptoBatch, payoutIndex?: number) => ({
      key: `${cryptoBatch?.externalId}-${cryptoBatch?.totalProcessedItems}-${cryptoBatch?.status}`,
      rowData: [
        {
          cellData: (
            <Item flex paddingY={24} paddingL={16}>
              <Text size="sm">{cryptoBatch?.fileName}</Text>
            </Item>
          ),
          testid: `individual-payout-batch`,
        },
        {
          cellData: (
            <Item flex paddingY={24}>
              <Text size="sm">{dayjs(cryptoBatch?.createdAt).format(DateFormat.DD_MMMM_YYYY)}</Text>
            </Item>
          ),
          testid: `individual-payout-date`,
        },
        {
          cellData: (
            <Item flex paddingY={24}>
              <Text size="sm">
                {cryptoBatch?.totalProcessedItems}/{cryptoBatch?.totalItems}
              </Text>
            </Item>
          ),
          testid: `individual-payout-processed`,
        },
        {
          cellData: (
            <Item flex paddingY={24}>
              <Text size="sm">{cryptoBatch?.userName}</Text>
            </Item>
          ),
          testid: `individual-payout-username`,
        },
        {
          cellData: (
            <Item flex paddingR={12}>
              <StatusPill
                state={getMassPayoutStatusState(cryptoBatch?.status?.toUpperCase())}
                label={titleCase(getMassPayoutCryptoBatchStatus(cryptoBatch?.status))}
                fitContent
              />
            </Item>
          ),
        },
        {
          cellData: (
            <TablePopoverMenu
              key={`${cryptoBatch?.externalId}-${cryptoBatch?.status}`}
              hasPadding
              testid="mass-payout-list-popver-menu"
              menuItems={
                cryptoBatch?.status?.toUpperCase() === BatchStatus.DRAFT
                  ? [
                      {
                        iconName: 'InfoCircleIcon',
                        label: t('details'),
                        onClick: () => onOpenCryptoSidePanel(payoutIndex),
                      },
                      {
                        iconName: 'EditIcon',
                        label: t('massPayouts.editDraft'),
                        onClick: () => {
                          navigate(
                            `${PathNames.MASS_PAYOUTS}/payout-details/${cryptoBatch?.externalId}?batchType=${activeTab.toLowerCase()}`
                          )
                        },
                      },
                    ]
                  : [
                      {
                        iconName: 'InfoCircleIcon',
                        label: t('details'),
                        onClick: () => onOpenCryptoSidePanel(payoutIndex),
                      },
                    ]
              }
            />
          ),
          align: 'end',
        },
      ],
    })
  )

  useEffect(() => {
    setWalletType(walletType)
    setActiveTab(walletType)
    window.history.pushState({}, '', `?tab=${walletType}`)
  }, [])

  const switchToTab = (tab: WalletTypes) => {
    // add query param to current URL without causing page reload
    window.history.pushState({}, '', `?tab=${tab}`)
    setWalletType(tab)
    setActiveTab(tab)
  }

  const tabs = [
    {
      id: WalletTypes.CRYPTO,
      tabHeader: t('wallets.crypto'),
      tabContent: null,
      onClick: () => switchToTab(WalletTypes.CRYPTO),
      testid: 'crypto-wallets-tab',
    },
    ...(enableMassPayoutsFiat
      ? [
          {
            id: WalletTypes.FIAT,
            tabHeader: t('wallets.fiat'),
            tabContent: null,
            onClick: () => switchToTab(WalletTypes.FIAT),
            testid: 'fiat-wallets-tab',
          },
        ]
      : []),
  ]

  return (
    <Box className="w-full">
      <MassPayoutHeader buttonGroup={MassPayoutButtonGroup?.BATCH_LIST} />
      <SmallTabs tabs={tabs} currentValueId={activeTab} />
      <Table
        columns={activeTab === WalletTypes.FIAT ? fiatColumns : cryptoColumns}
        tableData={activeTab === WalletTypes.FIAT ? fiatTableData : cryptoTableData}
        isLoading={isLoadingMassFiatPayoutList || isLoadingMassCryptoPayoutList}
      />
      {activeTab === WalletTypes.FIAT ? (
        <>
          <FiatBatchSidePanel
            isOpen={isFiatSidePanelOpen}
            massFiatPayoutDetails={fiatSidePanelData}
            pageMassPayout={fiatPaging}
            onClose={() => fiatSetIsSidePanelOpen(false)}
            wallets={wallets?.data}
            isLoadingWallets={isLoadingWallets}
          />
          {!!paging && !showPagination && (
            <Box className={`${isLoadingMassFiatPayoutList || showPagination ? 'opacity-25' : ''}`}>
              <TablePagination
                disableLeftDropdown
                total={paging?.total}
                pagingMax={paging?.max}
                onPagination={({ page }: PaginationPaging) => setPage(page)}
              />
            </Box>
          )}
        </>
      ) : (
        <CryptoBatchSidePanel
          isOpen={isCryptoSidePanelOpen}
          massPayoutDetails={cryptoSidePanelData}
          pageMassPayout={cryptoPaging}
          onClose={() => cryptoSetIsSidePanelOpen(false)}
        />
      )}
    </Box>
  )
}
