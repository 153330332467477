import { isArray } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Approval } from 'constants/General'
import { ApprovalPreferences } from 'types/approvals'
import { removeLastComma } from 'utils/utils'

type ApprovalFlowTranslationsType = { [x: string]: string }

const approvalFlowTranslations = () => {
  const { t } = useTranslation()

  return {
    [Approval.FLOWS.FIAT_1ST_PARTY_PAYOUT]: t('approvals.firstPartyFlow'),
    [Approval.FLOWS.FIAT_3RD_PARTY_PAYOUT]: t('approvals.thirdPartyFlow'),
    // disabled temporarily
    // [Approval.FLOWS.CRYPTO_SEND]: t('approvals.cryptoSendFlow'),
    [Approval.FLOWS.CRYPTO_PAYOUT]: t('approvals.cryptoPayoutFlow'),
  }
}

const isflowSupported = (flow: string, translations: ApprovalFlowTranslationsType) => {
  return Object.prototype.hasOwnProperty.call(translations, flow)
}

export const useEnabledApprovalFlows = (approvalFlowsPreference: ApprovalPreferences[]) => {
  let enabledApprovalFlowsTranslation = ''

  const translations = approvalFlowTranslations()
  if (isArray(approvalFlowsPreference)) {
    approvalFlowsPreference?.forEach(approvalPreference => {
      const flowHasATranslation = isflowSupported(approvalPreference?.flow, translations)
      if (flowHasATranslation && approvalPreference?.approvalsRequired) {
        enabledApprovalFlowsTranslation += `${translations?.[approvalPreference?.flow]}, `
      }
    })
  }

  return removeLastComma(enabledApprovalFlowsTranslation)
}

export const useApprovalFlowsCheckboxOptions = (approvalPreferences: ApprovalPreferences[]) => {
  const { t } = useTranslation()

  const translations = approvalFlowTranslations()
  const approvalFlowOptions = approvalPreferences?.map(approvalPreference => {
    const flowIsSupported = isflowSupported(approvalPreference?.flow, translations)
    if (flowIsSupported) {
      return {
        label: `${t('approvals.for')} ${translations?.[approvalPreference?.flow]}`,
        value: approvalPreference?.flow,
        checked: approvalPreference?.approvalsRequired,
      }
    }
  })

  return {
    approvalFlowCheckboxOptions: approvalFlowOptions?.filter(Boolean),
    approvalFlowTranslations: translations,
  }
}
