import React from 'react'
import { CopyButton } from '@node-space/storybook-components'
import { useWalletContext } from 'hooks/context'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'

export const CopyAllDetailsCrypto = () => {
  const wallet = useWalletContext()
  const { t } = useTranslation()
  const currencyCodeLabel = t('yourAddress', {
    currencyCode: wallet.currency?.code?.toUpperCase(),
  })
  return (
    <div className="border-t border-gray-03 py-6">
      <div className="flex justify-center items-center">
        <p className={`text-${copyButtonIconColour} text-sm font-medium`}>{t('copyAllDetails')}</p>
        <CopyButton
          copyObject={{
            [currencyCodeLabel]: wallet.address,
            [t('destinationTag')]: wallet.lookup,
          }}
          confirmationMessage={t('copiedToClipboard')}
          iconColour={copyButtonIconColour}
        />
      </div>
    </div>
  )
}
