import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { MerchantType } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { MerchantDetails } from './MerchantDetails'
import { MerchantEditor } from './MerchantEditor'

const MerchantHeader = () => {
  const { t } = useTranslation()

  const { data: { merchantList = [] } = {} } = useMerchantsQuery()

  const [isModalOpen, setModalOpen] = useState(false)
  const [createdMerchant, setCreatedMerchant] = useState<MerchantType>()
  const permissions = useMappedRoles()?.manageMerchants

  const canEdit = permissions?.canEdit

  const handleCloseModal = () => {
    setModalOpen(false)
    setCreatedMerchant(undefined)
  }

  useEffect(() => {
    if (!!createdMerchant) {
      track.Amp.track(AmplitudeEvent.EOF_INITIATE_MERCHANT_SUCCESS, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
  }, [createdMerchant])

  return (
    <>
      {!merchantList?.length ? (
        <StatusCard
          button={
            canEdit && {
              onClick: () => setModalOpen(true),
              text: t('manageMerchants.emptyStateButtonText'),
            }
          }
          description={t('manageMerchants.emptyStateDescription')}
          headline={t('manageMerchants.emptyStateHeadline')}
        />
      ) : (
        <PageHeader
          description={t('tokenInfo')}
          {...(canEdit && {
            renderRightSection: (
              <Button
                onClick={() => setModalOpen(true)}
                iconElement={<Icon name="PlusIcon" color="white" />}
              >
                {t('createNewMerchant')}
              </Button>
            ),
          })}
        />
      )}

      <Modal allowOverFlow visible={isModalOpen} closeModal={handleCloseModal}>
        {createdMerchant ? (
          <MerchantDetails
            handleCloseModal={handleCloseModal}
            merchant={createdMerchant}
            successMessage={t('merchantCreatedMsg')}
          />
        ) : (
          <MerchantEditor
            handleCloseModal={handleCloseModal}
            merchant={createdMerchant}
            setMerchant={setCreatedMerchant}
            actionName={t('createMerchant')}
            submitBtnText={t('createMerchant')}
            action="CREATE"
          />
        )}
      </Modal>
    </>
  )
}

export default MerchantHeader
