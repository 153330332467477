import React, { useState } from 'react'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { CSATModal } from './CSATModal'

export const CSATLaunch = () => {
  const [showCSATSurveyModal, setShowCSATSurveyModal] = useState(true)

  const { profile } = useProfileContext()
  const { currentAccount } = useAccountsContext()

  const onClickComplete = () => {
    setShowCSATSurveyModal(false)
  }

  return (
    <>
      {showCSATSurveyModal && profile?.emailAddress && (
        <CSATModal
          customer={{
            emailAddress: profile?.emailAddress,
            firstname: profile?.firstName,
            lastname: profile?.lastName,
            companyName: currentAccount?.name,
            customerID: `${profile?.id}`,
          }}
          onClose={onClickComplete}
        />
      )}
    </>
  )
}
