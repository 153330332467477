import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '@node-space/storybook-components'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { RequestError } from 'components/Errors/RequestError'
import { HeaderCTA } from 'components/header/HeaderCTA'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { PathNames } from 'constants/General'
import { useForgotPasswordMutation } from 'hooks/mutations/usePasswordMutation'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/beneficiaries'

export default function ForgotPassword({ ...rest }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [emailAddress, setEmailAddress] = useState('')
  const validEmail = new RegExp(/^[^@]+@[^@]+\.[^@]+$/)
  const [emailError, setEmailError] = useState(false)
  const [displayErrors, setDisplayErrors] = useState(false)
  const [initialView, setInitialView] = useState(true)
  const supportUrl = process.env.SUPPORT_URL

  const { mutate: forgotPassword, isPending } = useForgotPasswordMutation()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const submitRequest = () => {
    resetRequestError()
    forgotPassword(
      { emailAddress },
      {
        onSuccess: () => {
          setInitialView(false)
        },
        onError: (error: BaseErrorResponse) => {
          const sentryTraceId = logSentryErrorAndGetTraceId('Error: forgotPassword', error)
          setRequestError({ show: true, sentryTraceId })
        },
      }
    )
  }
  const validateEmail = () => {
    if (validEmail.test(emailAddress)) {
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  useEffect(() => {
    validateEmail()
  }, [emailAddress])
  return (
    <LoginPageLayout
      title={initialView ? `${t('forgotPassword')}` : `${t('checkEmail')}`}
      description={
        initialView ? (
          'Just enter the email address you signed up with and we’ll send you a link to reset your password.'
        ) : (
          <>
            We sent an email to <span className="font-semibold">{emailAddress}</span>
          </>
        )
      }
      headerRightContent={
        <HeaderCTA
          helpText={`${t('stillNeedHelp')}`}
          buttonText={`${t('contactSupport')}`}
          buttonUrl={supportUrl}
          externalLink
        />
      }
    >
      {requestError?.show && <RequestError sentryTraceId={requestError?.sentryTraceId} />}
      {initialView ? (
        <>
          <Input
            type="email"
            errorText="Invalid email address"
            error={emailError && displayErrors}
            inputMode="email"
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
            placeholder={`${t('yourEmailAddress')}`}
          />
          <Button
            disabled={emailError && displayErrors}
            loading={isPending}
            full
            onClick={() => {
              emailError ? setDisplayErrors(true) : submitRequest()
            }}
          >
            Send password reset link
          </Button>
          <div className="text-center ">
            <Button
              className="text-primary-500 hover:text-primary-700 active:text-primary-900"
              noStyling
              onClick={() => navigate(PathNames.LOGIN)}
            >{`${t('backToLogin')}`}</Button>
          </div>
        </>
      ) : (
        <>
          <p className="text-center text-sm text-gray-08">
            Sometimes these emails go missing, please check your spam folder if you don’t receive it
            soon.
          </p>
          <Button
            disabled={emailError && displayErrors}
            loading={isPending}
            full
            onClick={() => {
              navigate(PathNames.LOGIN)
            }}
          >
            {`${t('backToLogin')}`}
          </Button>
        </>
      )}
    </LoginPageLayout>
  )
}
