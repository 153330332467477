import React, { useEffect } from 'react'
import { useSetAtom } from 'jotai'
import { useFeatureFlags } from '@node-space/hooks'
import Loader from '@node-space/storybook-components/dist/Loader'
import { useGetCryptoMassPayoutList } from 'hooks/queries/MassPayouts/useGetCryptoMassPayoutsList'
import { useGetMassFiatPayoutsListQuery } from 'hooks/queries/MassPayouts/useGetMassFiatPayoutsList'
import { cryptoBatchListAtom, fiatBatchListAtom, modalAtom } from '../atoms/massPayoutAtoms'
import { MassPayoutBatchView } from './MassPayoutBatchView'
import { MassPayoutsUpload } from './MassPayoutsUpload'

export const MassPayoutLanding = () => {
  const setModal = useSetAtom(modalAtom)
  const setCryptoBatchList = useSetAtom(cryptoBatchListAtom)
  const setFiatBatchList = useSetAtom(fiatBatchListAtom)
  const { enableMassPayoutsFiat } = useFeatureFlags()

  const { data: cryptoBatchList, isPending: isFetchingCrypto } = useGetCryptoMassPayoutList()
  const { data: { content: fiatBatchList } = {}, isLoading: isFetchingFiat } =
    useGetMassFiatPayoutsListQuery({
      page: 0,
      enabled: enableMassPayoutsFiat,
    })

  setCryptoBatchList(cryptoBatchList ?? [])
  setFiatBatchList(fiatBatchList)

  useEffect(() => {
    setModal({ isOpen: false })
  }, [])

  if (isFetchingCrypto || isFetchingFiat) {
    return <Loader />
  }

  if (cryptoBatchList?.length > 0 || fiatBatchList?.length > 0) {
    return <MassPayoutBatchView />
  }

  if (cryptoBatchList?.length === 0 || fiatBatchList?.length === 0) {
    return <MassPayoutsUpload />
  }
}
