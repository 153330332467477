import React from 'react'
import { Box as DefaultLogo } from '@node-space/storybook-components/dist/Box'
import { SizeType } from '@node-space/storybook-components/dist/components/Box/BoxTypes'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'

interface CompanyLogoProps {
  companyName: string
  logoUrl?: string
  size?: SizeType
}

const CompanyLogo = ({ size = 48, logoUrl, companyName }: CompanyLogoProps) => {
  return logoUrl ? (
    <ImageIcon height={size} src={logoUrl} width={size} />
  ) : (
    // TODO: Replace this lekkertjies with a proper default icon
    <DefaultLogo
      flex
      centerChildren
      size={size}
      borderRadius="full"
      background="info"
      className="mr-4 text-gray-00 font-normal text-xs bg-secondary-500"
    >
      {companyName?.[0]?.toString()}
    </DefaultLogo>
  )
}

export default CompanyLogo
