import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'

type SafeguardIconProps = {
  isDetails?: boolean
}

const SafeGuardIcon = ({ isDetails }: SafeguardIconProps) => {
  const { t } = useTranslation()

  const position = isDetails ? 'top-0 left-4' : 'top-[-5px] left-[13px]'

  return (
    <Wrapper className={`absolute ${position}`} data-testid="safeguard-icon">
      <Tooltip
        id="safeguard"
        clickable
        delayHide={500}
        position="right"
        hasMaxWidth
        bodyContent={
          <>
            {t('wallets.safeguardTooltip')}{' '}
            <TextAction
              text={t('wallets.safeguardTooltipSeeHere')}
              linkTo={process.env.SAFEGUARD_LEARN_MORE_LINK}
              inheritColor
            />
          </>
        }
      >
        <Icon
          name="ShieldCheckIcon"
          size={isDetails ? 'default' : 'sm'}
          color="status-success-500"
          fill="#ffffff"
        />
      </Tooltip>
    </Wrapper>
  )
}

export default SafeGuardIcon
