import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Banner } from '@node-space/storybook-components/dist/Banner'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from './text/Description'
import { useDevXContext } from 'hooks/useDevXContext'
import { isSandbox } from 'utils/environment'
import { Amp } from 'utils/tracker'
import { AmplitudeEvent } from 'utils/amplitude/amplitudeEvents'
import { PathNames } from 'constants/General'

const GoLiveUrl = `${process.env.PROD_URL}${PathNames.KYB_SIGNUP_URL}`

export const SandboxGoLiveCTA = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const { setIsDevXGoLiveReady } = useDevXContext()

  const handleGoLiveUrlOnClick = () => {
    if (isSandbox) Amp.track(AmplitudeEvent.DEVX_SANDBOX_CLICKED_GO_LIVE)
    setIsDevXGoLiveReady(true)
    window.open(GoLiveUrl)
  }

  const handleContactSalesOnClick = () => {
    setIsDevXGoLiveReady(true)
    window.open(`${process.env.CONTACT_SALES_URL}`)
  }

  return (
    <>
      <Banner
        state="warning"
        message={
          <Box tag="span" flex gapX={8}>
            <Icon name="FlaskIcon" color="inherit" />
            <span>
              {t('sandboxTestData')}
              {' • '}
              <TextAction text="Go live" onClick={() => setShowModal(true)} inheritColor />
            </span>
          </Box>
        }
      />

      <Modal visible={showModal} closeModal={() => setShowModal(false)} closeOnBackgroundClick>
        <ModalNavBar title={t('getStarted.howToGoLive')} onClose={() => setShowModal(false)} />

        <ModalBody>
          <Description>
            {t('getStarted.goLiveDescription.verifyWithKYB')}{' '}
            <TextAction text={GoLiveUrl} onClick={handleGoLiveUrlOnClick} />
          </Description>
          <Description>{t('getStarted.goLiveDescription.contactSales')}</Description>
        </ModalBody>

        <ModalActions
          actions={[{ children: t('contactSales'), onClick: handleContactSalesOnClick }]}
        />
      </Modal>
    </>
  )
}
