import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Text } from '@node-space/storybook-components/dist/Text'
import { MappedErrorMessage, MappedErrorMessageProps } from './MappedErrorMessage'

export interface RequestErrorProps extends MappedErrorMessageProps {
  children?: ReactNode
  sentryTraceId?: string
}

export const RequestError = ({
  children,
  errorCode,
  fallbackMessage,
  sentryTraceId,
}: RequestErrorProps) => {
  const { t } = useTranslation()

  const isHandledErrorMessage = !!children
  const errorMessage = isHandledErrorMessage ? (
    children
  ) : (
    <MappedErrorMessage errorCode={errorCode} fallbackMessage={fallbackMessage} />
  )

  return (
    <Callout state="error" testid="request-error-callout">
      <Box aria-label="Request error message">{errorMessage}</Box>
      {!!sentryTraceId && (
        <Text size="xs" className="pt-2" color="inherit">
          {t('errorMessages.traceId')}: {sentryTraceId}
        </Text>
      )}
    </Callout>
  )
}
