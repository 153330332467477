import React from 'react'
import { ImageIcon } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'

interface MerchantWalletInfoProps {
  description: string
  currencyCode: string
}

const MerchantWalletInfo = ({ description, currencyCode }: MerchantWalletInfoProps) => {
  const iconUrl = `${process.env.ICONS_URL}${currencyCode.toLowerCase()}.svg`
  return (
    <Box className="flex items-center gap-2">
      <Box className="w-6 h-6 ">
        <ImageIcon src={iconUrl} width={24} height={24} alt={currencyCode} />
      </Box>
      <Box className="flex flex-col">
        <Text className="text-sm">{description}</Text>
        <Text className="text-sm text-gray-400"> {currencyCode}</Text>
      </Box>
    </Box>
  )
}

export default MerchantWalletInfo
