import React from 'react'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import DevXGetStarted from 'pages/Dashboard/components/DevXGetStarted'
import Wallets from 'pages/Wallets/Wallets'
import { isProduction } from 'utils/environment'

export interface GetStartedProps {
  isComplianceView: boolean
  complianceViewComponent: JSX.Element
}

export const GetStarted = ({ isComplianceView, complianceViewComponent }: GetStartedProps) => {
  const { isDeveloperAccount } = useAccountsContext()

  if (isComplianceView && !!complianceViewComponent) {
    return complianceViewComponent
  }

  if (isDeveloperAccount && !isProduction) {
    return <DevXGetStarted />
  }

  return <Wallets />
}
