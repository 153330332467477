import React, { createContext } from 'react'
import { WalletType } from 'types/types'

interface WalletContextProviderProps {
  children: React.ReactNode
  wallet: WalletType
}

export const WalletContext = createContext<WalletType>(null)

export const WalletContextProvider = ({ children, wallet }: WalletContextProviderProps) => {
  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
}
