import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Box as TextContainer } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { LogoColor } from 'types/types'
import { Logo } from './logos/Logo'

interface ErrorContentProps {
  errorInfo: string
  sentryTraceId?: string
}

const CONTACT = process.env.CONTACT_SALES_URL

const ErrorContent: React.FC<ErrorContentProps> = ({ errorInfo, sentryTraceId }) => {
  const { t } = useTranslation()
  return (
    <Box flex centerChildren height="screen" width="full" padding={24}>
      <TextContainer flex direction="col" maxWidth={512} gapY={12}>
        <Logo logoColor={LogoColor.DARK} />
        <Text size="lg">{t('errorMessages.errorOccured')}</Text>
        <Box className="text-text-500">
          <details>
            <summary>{t('errorMessages.clickDetails')}</summary>
            {errorInfo}
          </details>
        </Box>
        <Text color="text-500">
          {t('errorMessages.please')}{' '}
          <TextAction text={t('errorMessages.contactSupport')} linkTo={CONTACT} />{' '}
          {t('errorMessages.or')} <TextAction text={t('errorMessages.reload')} isWindowReload />.
        </Text>
        {sentryTraceId && (
          <Text color="text-500">
            {t('errorMessages.traceId')}: {sentryTraceId}
          </Text>
        )}
      </TextContainer>
    </Box>
  )
}

export default ErrorContent
