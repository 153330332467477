import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { ALPHANUMERIC_VALIDATION, createNameSchema } from 'utils/yupSchemas/createNameSchema'
import { UpdateCryptoPayoutFormPayload } from '../types'

export const updateCryptoPayoutSchema = (t: TFunction<'translation', undefined>) => {
  const currency = createNameSchema(
    t('massPayouts.currencyCryptoLabel'),
    t,
    null,
    null,
    ALPHANUMERIC_VALIDATION
  )
  const protocol = createNameSchema(
    t('massPayouts.blockchainProtocol'),
    t,
    null,
    null,
    ALPHANUMERIC_VALIDATION
  )
  const amountLabel = t('massPayouts.amountCryptoLabel')
  const amount = yup
    .string()
    .label(amountLabel)
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const fundingAmountLabel = t('massPayouts.fundingAmountLabel')
  const fundingAmount = yup
    .string()
    .label(fundingAmountLabel)
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const recipientAddress = createNameSchema(
    t('recipientAddress'),
    t,
    null,
    null,
    ALPHANUMERIC_VALIDATION
  )

  const formFields = {
    amount,
    currency,
    protocol,
    recipientAddress,
    fundingAmount,
  }

  return yup.object<Partial<Record<keyof UpdateCryptoPayoutFormPayload, yup.AnySchema>>>(formFields)
}
