import { TFunction } from 'react-i18next'
import { Approval } from 'constants/General'
import { ApprovalDecision, ApprovalsCounter } from 'types/approvals'

export const useApprovalDecisionCopyTranslation = (
  decision: ApprovalDecision,
  staleSelectedItems: unknown[],
  t?: TFunction<string, unknown>
) => {
  const isFurtherApprovalsRequired = !!staleSelectedItems?.find(
    (selectedItem: { approvalsCounter: ApprovalsCounter }) =>
      selectedItem?.approvalsCounter?.approvalsAcquired <
      selectedItem?.approvalsCounter?.approvalsRequired - 1
  )
  const isDecisionApproved = decision === Approval.DECISION.APPROVE
  const selectedItemsCount = staleSelectedItems?.length
  const isMultipleSelectedItems = selectedItemsCount > 1

  const decisionAction = isDecisionApproved ? t('approvals.approved') : t('approvals.rejected')
  const paymentNoun = isMultipleSelectedItems ? t('approvals.payments') : t('approvals.payment')
  const decisionSuccessHeadline = t('approvals.decisionSuccessHeadline', {
    paymentCount: selectedItemsCount,
    payment: paymentNoun,
    action: decisionAction,
  })

  const approvedActionDescription = isMultipleSelectedItems
    ? t('approvals.decisionApproveDescriptionMultiple')
    : t('approvals.decisionApproveDescriptionSingle')
  const rejectActionDescription = isMultipleSelectedItems
    ? t('approvals.decisionRejectDescriptionMultiple')
    : t('approvals.decisionRejectDescriptionSingle')
  const furtherActionDisclaimer = isMultipleSelectedItems
    ? t('approvals.approvedDescriptionDisclaimerMultiple')
    : t('approvals.approvedDescriptionDisclaimerSingle')

  let decisionHeadline = decisionSuccessHeadline
  let decisionDescription = rejectActionDescription
  if (isFurtherApprovalsRequired) {
    if (isDecisionApproved) {
      decisionHeadline = t('approvals.decisionSuccessDisclaimerHeadline', {
        paymentCount: selectedItemsCount,
        payment: paymentNoun,
        action: decisionAction,
      })
      decisionDescription = furtherActionDisclaimer
    }
  } else {
    decisionDescription = isDecisionApproved ? approvedActionDescription : decisionDescription
  }

  return { decisionHeadline, decisionDescription, decisionAction }
}
