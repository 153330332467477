import React from 'react'
import clsx from 'clsx'

interface TransactionDetailRowProps {
  label: string
  children: React.ReactNode
}

export const TransactionDetailRow = ({ label, children }: TransactionDetailRowProps) => {
  return (
    <div
      className={clsx(
        'flex justify-between items-center py-3 border-b border-gray-03 last:border-b-0'
      )}
    >
      <p className="text-sm font-medium leading-5">{label}</p>
      <div className="text-sm leading-5">{children}</div>
    </div>
  )
}
