import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { deleteAccountUser } from 'services/AccountService'

export const useDeleteTeamMemberMutation = () => {
  return useMutation({
    mutationFn: deleteAccountUser,
    onError(error: { status: number; message?: string; errorList: unknown }) {
      logSentryError('Error from TeamMemberDelete - deleteUser', error)
    },
  })
}
