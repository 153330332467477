import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { TableRow } from '@node-space/storybook-components/dist/SimpleTable'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { DateFormat } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsTransactionsQuery } from 'hooks/queries/useApprovalsTransactionsQuery'
import { ApprovalActionStatuses, ApprovalStatuses } from 'types/approvals'
import { SidePanelSummary } from '../SidePanel/SidePanelSummary'

const rowHeaderWidth = 'px-8'
const cellDataWidth = `py-4 ${rowHeaderWidth}`

export const ApprovedTransactions = () => {
  const { t } = useTranslation()
  const { currentAccount } = useAccountsContext()

  const currentAccountReference = currentAccount?.reference || ''
  const {
    data: approvedTransactions,
    isError,
    isFetching,
  } = useApprovalsTransactionsQuery(
    currentAccountReference,
    ApprovalStatuses.APPROVED,
    !!currentAccountReference
  )

  const {
    isSidePanelOpen: isApprovedTransactionDetailsOpen,
    sidePanelData: approvedTransactionDetails,
    paging,
    setIsSidePanelOpen,
    updateSidePanel,
  } = useSidePanel(approvedTransactions)

  const openSidePanelSummary = (index: number) => {
    updateSidePanel(index)
    setIsSidePanelOpen(true)
  }

  const transactionsData = (): TableRow[] => {
    return approvedTransactions?.map((approvalTransaction, index) => ({
      rowData: [
        {
          cellData: (
            <Box flex alignItems="center" gap={12} className="relative">
              <ImageIcon
                width={24}
                height={24}
                hasBackgroundColor={false}
                src={`${
                  process.env.ICONS_URL
                }${approvalTransaction?.currency?.code?.toLowerCase()}.svg`}
              />
              <Box className="relative">
                {!!(approvalTransaction?.amount && approvalTransaction?.currency?.code) && (
                  <Text size="sm" className="text-500">
                    {`${approvalTransaction?.amount} ${approvalTransaction?.currency?.code}`}
                  </Text>
                )}
                <Text size="sm" color="disabled">
                  {approvalTransaction?.walletName}
                </Text>
              </Box>
            </Box>
          ),
          className: cellDataWidth,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.beneficiaryDetails?.accountNumber ||
                  approvalTransaction?.beneficiaryDetails?.destinationWallet}
              </Text>
              <Text size="sm" color="disabled">
                {approvalTransaction?.beneficiaryDetails?.beneficiaryName}
              </Text>
            </Box>
          ),
        },
        {
          cellData: (
            <Box>
              {!!approvalTransaction?.processedBy ? (
                approvalTransaction?.processedBy?.map((processedByDetail, index) => (
                  <Text size="sm" className="text-500" key={index}>
                    {processedByDetail?.status ===
                      ApprovalActionStatuses.PAYOUT_MERCHANT_APPROVAL_APPROVED &&
                      `${processedByDetail?.userFullName?.trim() || processedByDetail?.email}, ${dayjs(processedByDetail?.eventTime).format(DateFormat.D_MMM_YYYY_AT_HH_MM)} `}
                  </Text>
                ))
              ) : (
                <>
                  <Text size="sm" className="text-500">
                    {approvalTransaction?.actionedBy}
                  </Text>
                  <Text size="sm" color="disabled">
                    {dayjs(approvalTransaction?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
                  </Text>
                </>
              )}
            </Box>
          ),
          className: cellDataWidth,
        },
      ],
      onClick: () => openSidePanelSummary(index),
    }))
  }

  const approvedTransactionTableInfo = transactionsData()

  return (
    <Box paddingY={16}>
      {!!approvedTransactionDetails && (
        <SidePanelSummary
          isOpen={isApprovedTransactionDetailsOpen}
          paging={paging}
          onClose={() => setIsSidePanelOpen(false)}
          transactionDetails={approvedTransactionDetails}
          tab={ApprovalStatuses.APPROVED}
        />
      )}
      {isError ? (
        <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />
      ) : !isFetching && !approvedTransactionTableInfo?.length ? (
        <StatusCard
          headline={t('approvals.emptyStateHeadline', {
            approvalStatus: ApprovalStatuses.APPROVED.toLowerCase(),
          })}
          description={t('approvals.emptyStateDescription', {
            approvalStatus: ApprovalStatuses.APPROVED.toLowerCase(),
          })}
        />
      ) : (
        <SimpleTableV2
          columns={[
            {
              headerTitle: t('payment'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('to'),
            },
            {
              headerTitle: t('approvals.approvedBy'),
              className: rowHeaderWidth,
            },
          ]}
          isLoading={isFetching}
          tableData={approvedTransactionTableInfo}
        />
      )}
    </Box>
  )
}
