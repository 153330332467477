export const OnceOffExportSteps = {
  FORM: 'form',
  COMPLETE: 'complete',
} as const

export type OnceOffExportStepsType = (typeof OnceOffExportSteps)[keyof typeof OnceOffExportSteps]

export const RecurringExportSteps = {
  LIST: 'list',
  FORM: 'form',
  COMPLETE: 'complete',
} as const

export type RecurringExportStepsType =
  (typeof RecurringExportSteps)[keyof typeof RecurringExportSteps]

export const ExportFileFormat = {
  CSV: 'csv',
  PDF: 'pdf',
} as const

export type ExportFileFormatType = (typeof ExportFileFormat)[keyof typeof ExportFileFormat]

export const ExportAccordionItems = {
  ONCE_OFF: 'once-off',
  RECURRING: 'recurring',
} as const

export type ExportAccordionItemsType =
  (typeof ExportAccordionItems)[keyof typeof ExportAccordionItems]

export interface OnceOffExportForm {
  selectedDateOptionName: string
  selectedExportFormat: ExportFileFormatType
}
export interface RecurringExportForm {
  format: ExportFileFormatType
  frequencyType: ReportScheduleFrequencyType
  frequencyDay?: string
  time: string
  timezone: string
  channel?: string
  email?: string
}

export const ReportScheduleFrequency = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
} as const

export type ReportScheduleFrequencyType =
  (typeof ReportScheduleFrequency)[keyof typeof ReportScheduleFrequency]

export interface ReportSchedule {
  id?: number
  createdBy?: string
  accountReference?: string
  time: string
  timezone: string
  frequencyType: ReportScheduleFrequencyType
  frequencyDay?: number
  format: ExportFileFormatType
  channel: string
  email: string
}

export interface ReportSchedulePageableSort {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

export interface ReportSchedulePageable {
  pageNumber: number
  pageSize: number
  sort: ReportSchedulePageableSort
  offset: number
  paged: boolean
  unpaged: boolean
}

export interface ReportSchedulesResponse {
  content: ReportSchedule[]
  pageable: ReportSchedulePageable
  last: boolean
  totalPages: number
  totalElements: number
  first: boolean
  size: number
  number: number
  sort: ReportSchedulePageableSort
  numberOfElements: number
  empty: boolean
}

export interface ReportScheduleTimeZone {
  zoneId: string
  label: string
}

export interface ReportScheduleErrorList {
  createOrUpdateReportScheduleRequest: string[]
}
