import React, { createContext, SetStateAction } from 'react'
import { Approval } from 'constants/General'
import {
  ApprovalPreferences,
  ApprovalRulesData,
  PendingTransactionsResponse,
} from 'types/approvals'

const defaultContextValues = {
  permissions: { canEdit: false, canManageApproval: false },
  pendingApprovalDetails: {
    pendingApprovals: [],
    numberOfPendingApprovals: null,
    hasPendingApprovals: false,
    isPendingApprovalsSuccess: false,
  },
  approvalsConfig: {
    approvalPreferences: [],
    approvalPreferencesLimits: [],
    allApprovalFlowsAreDisabled: true,
    approvalDecisionIsCompleted: false,
    handleTableRowCheck: null,
    handleManageApproversModal: null,
    handleApprovalDecisionModal: null,
    handleApprovalPreferenceSettingsModal: null,
  },
  isFetching: false,
  isSuccess: false,
  isError: false,
}

type ApprovalDecision = keyof typeof Approval.DECISION

export const ApprovalsContext = createContext<ApprovalProviderProps>(defaultContextValues)

interface ApprovalProviderProps {
  permissions?: { canEdit: boolean; canManageApproval: boolean }
  pendingApprovalDetails?: {
    pendingApprovals?: PendingTransactionsResponse[]
    numberOfPendingApprovals?: number
    hasPendingApprovals?: boolean
    isPendingApprovalsError?: boolean
    isPendingApprovalsSuccess?: boolean
    isFetchingPendingApprovals?: boolean
  }
  approvalsConfig?: {
    approvalPreferences: ApprovalPreferences[]
    approvalPreferencesLimits: ApprovalRulesData[]
    allApprovalFlowsAreDisabled?: boolean
    approvalDecisionIsCompleted?: boolean
    handleTableRowCheck?: (value: SetStateAction<unknown[]>) => void
    handleManageApproversModal?: ({ isVisible }: { isVisible?: boolean }) => void
    handleApprovalDecisionModal?: ({
      isVisible,
      decision,
      isCompleted,
    }: {
      isVisible?: boolean
      decision?: ApprovalDecision
      isCompleted?: boolean
    }) => void
    handleApprovalPreferenceSettingsModal?: ({ isVisible }: { isVisible?: boolean }) => void
    handleApprovalPreferenceSuccessModal?: ({ isVisible }: { isVisible?: boolean }) => void
  }
  isFetching?: boolean
  isSuccess?: boolean
  isError?: boolean
  children?: React.ReactNode
}

export const ApprovalContextProvider = ({
  permissions,
  pendingApprovalDetails,
  approvalsConfig,
  isFetching,
  isSuccess,
  isError,
  children,
}: ApprovalProviderProps) => {
  return (
    <ApprovalsContext.Provider
      value={{
        permissions,
        pendingApprovalDetails,
        approvalsConfig,
        isFetching,
        isSuccess,
        isError,
      }}
    >
      {children}
    </ApprovalsContext.Provider>
  )
}
