import React from 'react'
import { useTranslation } from 'react-i18next'
import { Banner } from '@node-space/storybook-components/dist/Banner'

export interface UKRiskBannerProps {
  companyName?: string
}

export const UKRiskBanner = ({ companyName }: UKRiskBannerProps) => {
  const { t } = useTranslation()

  const intro = companyName
    ? `${companyName}, ${t('ukBanner.introLowercase')}`
    : t('ukBanner.intro')
  const message = t('ukBanner.message')
  const bannerText = `${intro} ${message}`

  return (
    <Banner
      state="info"
      message={bannerText}
      link={process.env.UK_INFO_BANNER_LINK}
      linkLabel={t('ukBanner.linkText')}
      showInfoIcon={false}
      showCloseBtn={false}
    />
  )
}
