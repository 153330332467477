import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components'
import { logSentryError } from '@node-space/utils'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { changePassword } from 'services/UserDetailsService'
import { getPasswordSecret } from 'services/ApiKeysService'
import { useToastContext } from 'hooks/useToastContext'

interface ChangePasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [hasCurrentPasswordError, setHasCurrentPasswordError] = useState(false)
  const [hasNewPasswordError, setHasNewPasswordError] = useState(false)
  const [hasConfirmPasswordError, setHasConfirmPasswordError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fieldsFilled = currentPassword !== '' && newPassword !== '' && confirmPassword !== ''

  const submitPasswordChange = async () => {
    try {
      await changePassword(currentPassword, newPassword, confirmPassword)
      addToast({ title: t('passwordSuccess'), state: 'success' })
      onClose()
    } catch (error) {
      const {
        data: { errorList },
      } = error
      const errorMessage = `${errorList?.[0]?.message}`
      errorMessage &&
        addToast({
          title: errorMessage,
          state: 'error',
          delay: 20,
        })
      logSentryError(
        `Error from ChangePasswordModal - submitPasswordChange - ${errorMessage}`,
        error
      )
    }
    setIsLoading(false)
  }

  const validatePasswords = async () => {
    setIsLoading(true)
    const newPasswordValid = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(newPassword)
    const confirmPasswordValid = newPassword === confirmPassword
    const canSubmitNewPassword = newPasswordValid && confirmPasswordValid
    let isSubmitting = false
    try {
      const res = await getPasswordSecret(currentPassword)
      if (res?.body) {
        setHasCurrentPasswordError(false)
        if (canSubmitNewPassword) {
          submitPasswordChange()
          isSubmitting = true
        }
      } else {
        setHasCurrentPasswordError(true)
      }
    } catch (err) {
      logSentryError('Error from ChangePasswordModal - validatePasswords', err)
    }
    setHasNewPasswordError(!newPasswordValid)
    setHasConfirmPasswordError(!confirmPasswordValid)
    !isSubmitting && setIsLoading(false)
  }

  const actions: ButtonProps[] = [
    {
      children: t('save'),
      testid: 'save-new-password',
      disabled: !fieldsFilled,
      loading: isLoading,
      onClick: () => validatePasswords(),
    },
  ]

  return (
    <Modal visible={isOpen} closeModal={onClose} closeOnBackgroundClick>
      <ModalNavBar title={t('changePassword')} onClose={onClose} />
      <ModalBody>
        <Input
          type="password"
          testid="current-password-input"
          label={t('currentPassword')}
          placeholder={t('currentPasswordPlaceholder')}
          error={hasCurrentPasswordError}
          errorText={t('incorrectPassword')}
          value={currentPassword}
          onChange={e => {
            setCurrentPassword(e.target.value)
            setHasCurrentPasswordError(false)
          }}
        />
        <Input
          type="password"
          testid="new-password-input"
          label={t('newPasswordSimple')}
          placeholder={t('newPasswordPlaceholder')}
          error={hasNewPasswordError}
          errorText={t('passwordComplexityError')}
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value)
            setHasNewPasswordError(false)
          }}
        />
        <Input
          type="password"
          testid="confirm-password-input"
          label={t('confirmPassword')}
          placeholder={t('confirmPasswordPlaceholder')}
          error={hasConfirmPasswordError}
          errorText={t('matchingPasswordsError')}
          value={confirmPassword}
          onChange={e => {
            setConfirmPassword(e.target.value)
            setHasConfirmPasswordError(false)
          }}
        />
      </ModalBody>
      <ModalActions actions={actions} />
    </Modal>
  )
}

export default ChangePasswordModal
