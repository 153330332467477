import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { ApiKeyModalContext } from '../../context/ApiKeyModalProvider'

const ApiKeyHeaderCreateButton = () => {
  const { t } = useTranslation()
  const { viewOnly } = useProfileContext()
  const { setShowCreateModal, setShowManageModal, setApiKeyData } = useContext(ApiKeyModalContext)
  const { twoFactorEnabled, setShowTwoFAModal } = useTwoFAContext()

  const generateButtonAction = () => {
    twoFactorEnabled ? setShowCreateModal(true) : setShowTwoFAModal(true)
    // setShowManageModal(false)
    setApiKeyData(null)
  }
  return (
    <Button
      disabled={viewOnly}
      onClick={() => generateButtonAction()}
      testid={'generate-api-key'}
      iconElement={<Icon name="PlusIcon" color="white" />}
    >
      {t('manageApiKeys.generateAPIKeyBtn')}
    </Button>
  )
}

export default React.memo(ApiKeyHeaderCreateButton)
