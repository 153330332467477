import React from 'react'
import { Box, Box as Container } from '@node-space/storybook-components/dist/Box'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'

interface CustomersListSkeletonLoaderProps {
  rows?: number
}

const CustomersListSkeletonLoader = ({ rows = 8 }: CustomersListSkeletonLoaderProps) => {
  return (
    <>
      {new Array(rows).fill(null).map((item, index) => (
        <Container
          key={index}
          paddingX={16}
          paddingY={20}
          flex
          alignItems="center"
          direction="row"
          gapX={16}
          border="gray"
        >
          <Box width="full" flex direction="row">
            <Container background="gray-03" borderRadius={'full'} width={32} height={32} />
            <Container flexSize="fill" paddingL={12}>
              <Skeleton className="w-32 h-4" variant="text" />
              <Skeleton className="w-24 h-4 mt-2" variant="text" />
            </Container>
            <Container justifyContent="center" alignItems="center" flex paddingR={32}>
              <Skeleton className="w-16 h-4 border-8" variant="text" />
            </Container>
          </Box>
          <Skeleton className="w-2 h-8 border-8" variant="text" />
        </Container>
      ))}
    </>
  )
}

export default CustomersListSkeletonLoader
