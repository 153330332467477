type Currency = {
  code: string
  name: string
}

export function groupPaymentsTotalByCurrency(payments: unknown[]) {
  const groupedPayments = []

  payments?.forEach((payment: { currency: Currency; amount: string }) => {
    const currency = payment?.currency?.code
    const amount = Number(payment?.amount)

    // Check if currency already exists in groupedPayments, add the amount to it
    const existingCurrencyIndex = groupedPayments?.findIndex(item => item?.currency === currency)

    if (existingCurrencyIndex !== -1) {
      // If currency already exists in groupedPayments, add the amount to it
      groupedPayments[existingCurrencyIndex].amount += amount
    } else {
      // If currency does not exist, create a new entry of currency with the amount
      groupedPayments.push({ currency, amount })
    }
  })

  return groupedPayments
}
