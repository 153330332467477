import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LoadPardotScript, PardotRoutes } from '.'

export const usePardotTracking = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    let scriptElem
    const pardot: PardotRoutes = PardotRoutes[pathname]

    if (!pardot?.onlyRun() || !pardot?.piAId) return

    try {
      const { piAId, piCId } = pardot
      scriptElem = LoadPardotScript({
        piAId,
        piCId,
      })
    } catch (error) {
      console.error('BVNK/Pardot Script Error', error)
    }

    return () => {
      if (scriptElem) {
        document.head.removeChild(scriptElem)
        return undefined
      }
    }
  }, [pathname])
}
