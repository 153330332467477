import React, { useEffect } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box as Card,
  Box as Container,
  Box as Group,
} from '@node-space/storybook-components/dist/Box'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { MassPayoutCallout } from './components/MassPayoutCallout'
import { MassPayoutLanding } from './pages/MassPayoutLanding'
import { MassPayoutsUpload } from './pages/MassPayoutsUpload'

export const MassPayouts = () => {
  const { enableMassPayoutsV2 } = useFeatureFlags()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MASS_PAYOUTS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <>
      <MassPayoutCallout />

      <Card>
        <Container flex direction="col" centerChildren gapY={24}>
          <Group
            flex
            direction="col"
            centerChildren
            gapY={24}
            className="w-full"
            id="mass-payouts-header-card-description"
            testid="mass-payouts-header-card-description"
          >
            {enableMassPayoutsV2 ? <MassPayoutLanding /> : <MassPayoutsUpload />}
          </Group>
        </Container>
      </Card>
    </>
  )
}
