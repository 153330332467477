export const AmplitudeEvent = {
  // APP
  APP_LOGGED_IN: 'App start: Logged in',
  // DEVX
  DEVX_SANDBOX_DEVELOPER_LOGGED_IN: 'DevX : SB: Action - Developer Logged in',
  DEVX_SANDBOX_CLICKED_GO_LIVE: 'DevX: SB: Click - Clicked Go Live',
  DEVX_SANDBOX_CLICKED_SIGN_UP: 'DevX: SB: Click - Sign up button',
  DEVX_SANDBOX_CLICKED_GENERATE_API_KEY: 'DevX: SB: Click - Generate API Keys button',
  DEVX_SANDBOX_CLICKED_READ_DOCS: 'DevX: SB: Click - Read docs',
  DEVX_SANDBOX_CLICKED_READ_API_REF: 'DevX: SB: Click - Read API reference',
  DEVX_SANDBOX_CLICKED_VIEW_WALLETS: 'DevX: SB: Click - View wallets',
  DEVX_SANDBOX_CLICKED_LEARN_ABOUT_WALLETS: 'DevX: SB: Click - Learn more about wallets',
  DEVX_SANDBOX_CLICKED_CREATE_MERCHANT: 'DevX: SB: Click - Create a Merchant',
  DEVX_SANDBOX_CLICKED_MERCHANT_LEARN_MORE: 'DevX: SB: Click - Learn more about on Merchant page',
  DEVX_SANDBOX_CLICKED_CREATE_PAYMENT: 'DevX: SB: Click - Create a Payment',
  DEVX_SANDBOX_CLICKED_CONTACT_SALES: 'DevX: SB: Click - Contact Sales team',
  DEVX_PROD_CLICKED_SIGN_UP: 'DevX: PD: Click - Sign up button',
  // ONBOARDING
  ONBOARDING_CLICK_SIGNUP_TOP_LINK: 'ONB - Sign up top link',
  ONBOARDING_LOAD_CTA_GET_STARTED: 'ONB - Page Load - CTA Get Started',
  ONBOARDING_CLICK_CONTINUE_CTA_GET_STARTED: 'ONB - Continue - CTA Get Started',
  ONBOARDING_CONFIRM_EMAIL: 'ONB - Confirmed email',
  ONBOARDING_CLICK_RESEND_EMAIL_VERIFICATION: 'ONB - Resend email verification code',
  // HOMEPAGE
  HOMEPAGE_TOTAL_USERS: 'HOME - Total users',
  HOMEPAGE_CLICK_MERCHANT_DETAILS: 'HOME - Add merchant details',
  HOMEPAGE_CLICK_CREATE_PAYMENT: 'HOME - Create Payment',
  HOMEPAGE_CLICK_VIEW_ALL_WALLETS: 'HOME - View all wallets',
  HOMEPAGE_CLICK_GENERATE_API_KEYS: 'HOME - Generate API keys',
  // MANAGE ACOUNT (SETTINGS)
  MANAGE_ACCOUNT_BANK_ACCOUNT_ADDED: 'Bank Account account added',

  // VERSION 2 EVENTS (REWORK)

  // DASHBOARD (HOMEPAGE)
  DASHBOARD_HOME: 'Dashboard: Home',

  // DEVX (HOMEPAGE)
  DEVX_HOME: 'DevX: Home',

  // WALLETS
  WALLET_HOME: 'Wallet: Home',
  WALLET_CONVERT: 'Wallet: Convert',
  WALLET_POPOVER_MENU: 'Wallet: Popover Menu',
  WALLET_INITIATE: 'Create Wallet: Initiate',
  EOF_INITIATE_WALLET_SUCCESS: 'EOF Create Wallet: Success',

  // WALLET DETAILS
  WALLET_DETAILS: 'Wallet: Wallet Details',
  WALLET_DETAILS_PAY: 'Wallet Details: Pay',
  WALLET_DETAILS_WITHDRAW: 'Wallet Details: Withdraw',
  WALLET_DETAILS_DEPOSIT: 'Wallet Details: Deposit',
  WALLET_DETAILS_CONVERT: 'Wallet Details: Convert',
  WALLET_DETAILS_SEND: 'Wallet Details: Send',
  WALLET_DETAILS_RECEIVE: 'Wallet Details: Receive',
  WALLET_DETAILS_TRANSACTION: 'Wallet Details: Transaction History',

  // TRANSACTION HISTORY
  TRANSACTION_HISTORY_HOME: 'Transaction History: Home',

  // PAY
  PAY_INITIATE: 'Pay: Initiate',
  PAY_INPUT_AMOUNT: 'Pay: Input Amount',
  PAY_CONFIRM: 'Pay: Confirm',
  PAY_SUCCESS: 'EOF Pay: Success',

  // WITHDRAW
  WITHDRAW_INITIATE: 'Withdraw: Initiate',
  WITHDRAW_CONFIRM: 'Withdraw: Confirm',
  WITHDRAW_SUCCESS: 'EOF Withdraw: Success',

  // DEPOSIT
  DEPOSIT_INITIATE: 'Deposit: Initiate',
  DEPOSIT_COPY: 'Deposit: Copy',
  EOF_DEPOSIT_SUCCESS: 'EOF Deposit: Success',

  // CONVERT
  CONVERT_INITIATE: 'Convert: Initiate',
  CONVERT_CONFIRM: 'Convert: Confirm',
  CONVERT_REFRESH: 'Convert: Refresh Quote',
  CONVERT_SUCCESS: 'EOF Convert: Success',

  // SEND
  SEND_INITIATE: 'Send: Initiate',
  SEND_CONFIRM: 'Send: Confirm',
  EOF_SEND_SUCCESS: 'EOF Send: Success',

  // NOTABENE
  SEND_NOTABENE_INITIATE: 'Send: Notabene Widget Initiate',
  SEND_NOTABENE_ERROR: 'Send: Notabene Widget Error',

  // RECEIVE
  RECEIVE_INITIATE: 'Receive: Initiate',
  RECEIVE_COPY: 'Receive: Copy',

  // MANAGE ACCOUNT
  MANAGE_ACCOUNT: 'Manage Account: Home',
  MANAGE_ACCOUNT_MANAGE_CRYPTO_ADDRESSES: 'Manage Account: Manage Crypto Addresses',

  // APPROVALS
  APPROVALS_HOME: 'Approvals: Home',
  UPDATE_APPROVALS_PREFERENCE: 'Approvals: Update Preference',
  MANAGE_APPROVERS_INITIATE: 'Manage Approvers: Initiate',
  EOF_MANAGE_APPROVERS_SUCCESS: 'EOF Manage Approvers: Success',
  EOF_MANAGE_APPROVERS_ERROR: 'EOF Manage Approvers: Error',
  APPROVE_TRANSACTION_INITIATE: 'Approve Transaction: Initiate',
  EOF_APPROVE_TRANSACTION_SUCCESS: 'EOF Approve Transaction: Success',
  EOF_APPROVE_TRANSACTION_ERROR: 'EOF Approve Transaction: Error',
  REJECT_TRANSACTION_INITIATE: 'Reject Transaction: Initiate',
  EOF_REJECT_TRANSACTION_SUCCESS: 'EOF Reject Transaction: Success',
  EOF_REJECT_TRANSACTION_ERROR: 'EOF Reject Transaction: Error',

  // ADD CRYPTO ADDRESS WHITELIST
  ADD_CRYPTO_ADDRESS_WHITELIST_INITIATE: 'Add Crypto Address Whitelist: Initiate',
  ADD_CRYPTO_ADDRESS_WHITELIST_CONFIRM: 'Add Crypto Address Whitelist: Confirm',
  EOF_ADD_CRYPTO_ADDRESS_WHITELIST_SUCCESS: 'EOF Add Crypto Address Whitelist: Success',

  // DELETE CRYPTO ADDRESS WHITELIST
  DELETE_CRYPTO_ADDRESS_WHITELIST_INITIATE: 'Delete Crypto Address Whitelist: Initiate',
  EOF_DELETE_CRYPTO_ADDRESS_WHITELIST_SUCCESS: 'EOF Delete Crypto Address Whitelist: Success',

  // MANAGE TEAM MEMBERS
  MANAGE_TEAM_MEMBERS: 'Manage Account: Manage Team members',
  ADD_TEAM_MEMBER_INITIATE: 'Add Team member: Initiate',
  EDIT_TEAM_MEMBER_INITIATE: 'Edit Team member: Initiate',
  EOF_EDIT_TEAM_MEMBER_SUCCESS: 'Edit Team member: Success',
  EOF_ADD_TEAM_MEMBER_SUCCESS: 'EOF Add Team member: Success',
  EOF_ADD_TEAM_MEMBER_ERROR: 'EOF Add Team member: Error',
  EOF_TOGGLE_TEAM_MEMBER_APPROVER_SUCCESS: 'EOF Toggle Team member Approver Role: Success',
  EOF_TOGGLE_TEAM_MEMBER_APPROVER_ERROR: 'EOF Toggle Team member Approver Role: Success',

  // MANAGE BENEFICIARIES
  MANAGE_BENEFICIARIES: 'Manage Account: Manage Beneficiaries',
  MANAGE_BENEFICIARIES_COPY: 'Manage Beneficiaries: Copy',

  // ADD BENEFICIARY
  ADD_BENEFICARY_SELECT_DESTINATION: 'Add Beneficiary: Select Destination',
  ADD_BENEFICIARY_INITIATE: 'Add Beneficiary: Initiate',
  ADD_BENEFICIARY_CONFIRM: 'Add Beneficary: Confirm',
  EOF_ADD_BENEFICIARY_SUCCESS: 'EOF Add Beneficiary: Success',

  // DELETE BENEFICIARY
  DELETE_BENEFICIARY_INITIATE: 'Delete Beneficiary: Initiate',
  EOF_DELETE_BENEFICIARY_SUCCESS: 'EOF Delete Beneficiary: Success',

  // MASS PAYOUTS
  MASS_PAYOUTS: 'Mass Payouts: Home',

  // BVNK NETWORK
  BVNK_NETWORK: 'BVNK Network: Home',

  // CUSTOMERS
  CUSTOMERS: 'Customers: Home',
  ADD_NEW_CUSTOMER: 'Customers: Add New Customer',
  CUSTOMER: 'Customer: Home',

  // PAYMENT LINKS
  PAYMENTS: 'Payments Home',
  PAYMENT_INITIATE: 'Create Payment: Initiate',
  EOF_PAYMENT: 'EOF Create Payment: Success',

  //CHANNELS
  CHANNELS: 'Channels: Home',
  CHANNEL_VIEW_DETAILS: 'Channels: View Details',
  CHANNEL_INITIATE: 'Create Channel: Initiate',
  EOF_CHANNEL: 'EOF Create Channel: Success',
  CHANNEL_EDIT_INITIATE: 'Edit Channel: Initiate',
  EOF_EDIT_CHANNEL: 'EOF Edit Channel: Success',
  MANAGE_CHANNEL: 'Manage Acccout: Manage Channels',

  //MANAGE API KEYS
  MANAGE_API_KEYS: 'Manage Account: Manage API Keys',
  API_KEY_INITIATE: 'Create API Key: Initiate',
  EOF_INITIATE_API_KEY_SUCCESS: 'EOF Create API Key: Success',
  API_KEY_EDIT: 'Edit API Key: Initiate',
  EOF_EDIT_API_KEY_SUCCESS: 'EOF Edit API Key: Success',
  DELETE_API_KEY_INITIATE: 'Delete API Key: Initiate',
  EOF_DELETE_API_KEY_SUCCESS: 'EOF Delete API Key: Success',

  //MANAGE MERCHANTS
  MANAGE_MERCHANTS: 'Manage Account: Manage Merchants',
  MERCHANT_INITIATE: 'Create Merchant: Initiate',
  EOF_INITIATE_MERCHANT_SUCCESS: 'EOF Create Merchant: Success',
  MERCHANT_EDIT: 'Edit Merchant: Initiate',
  EOF_EDIT_MERCHANT_SUCCESS: 'EOF Edit Merchant: Success',

  //EXPORT TRANSACTIONS
  EXPORT_TRANSACTION_INITIATE: 'Export Transaction: Initiate',
  EOF_CREATE_EXPORT_INITIATE_SUCCESS: 'EOF: Create Export Initiate: Success',
  EOF_CREATE_EXPORT_INITIATE_ERROR: 'EOF: Create Export Initiate: Error',
  EDIT_EXPORT_INITIATE: 'Edit Export: Initiate',
  EOF_EDIT_EXPORT_INITIATE_SUCCESS: 'EOF: Edit Export Initiate: Success',
  EOF_EDIT_EXPORT_INITIATE_ERROR: 'EOF: Edit Export Initiate: Error',
  EOF_DELETE_EXPORT_SUCCESS: 'EOF: Delete Export: Success',
  EOF_DELETE_EXPORT_ERROR: 'EOF: Delete Export: Error',
} as const

export type AmplitudeEventType = (typeof AmplitudeEvent)[keyof typeof AmplitudeEvent]

export const AmplitudeEventCategory = {
  APPLICATION: 'Application',
  MERCHANT_PORTAL: 'Merchant Portal',
  MANAGE_CRYPTO_ADDRESS: 'Manage Crypto Address',
  ONBOARDING: 'Onboarding',
  SIGNUP: 'Signup',
  WALLET: 'Wallet',
  WALLET_TRANSACTION_EXPORT: 'WalletTransactionExport',
  WALLET_TRANSACTION_SEARCH: 'Wallet Transaction Search',
  WALLET_SEARCH_TRANSACTION_MODAL: 'Wallet Transaction Search Modal',
  WALLET_SEARCH_TRANSACTION_SIDE_PANEL: 'Wallet Transaction Search Side Panel',
  MANAGE_BENEFICIARIES_SIDE_PANEL: 'Manage Beneficiaries Side Panel',
} as const

export const AmplitudeEventAction = {
  LOAD: 'load',
  NAVIGATE: 'navigate',
  COPY: 'copy',
  CLICK: 'click',
  SEARCH: 'search',
  VERIFIED: 'verified',
  WHITELIST: 'whitelist',
  ERROR: 'error',
  VIEW: 'view',
  REFRESH: 'refresh',
} as const
