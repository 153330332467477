import { isRecapthcaLowScoreError } from 'recaptcha/utils'

export const LoginError = {
  CREDENTIALS: 'credentials',
  FORBIDDEN: 'forbidden',
  RECAPTCHA_LOW_SCORE: 'recaptcha low score',
  TWO_FACTOR: 'two factor',
  WORKSPACE: 'workspace',
} as const
export type LoginErrorType = (typeof LoginError)[keyof typeof LoginError]

export const getLoginErrorType = (status: number, message: string): LoginErrorType => {
  if (status === 401) return LoginError.CREDENTIALS
  if (status === 403) return LoginError.FORBIDDEN
  if (status === 406) return LoginError.TWO_FACTOR
  if (status === 409) return LoginError.WORKSPACE
  if (isRecapthcaLowScoreError(message)) return LoginError.RECAPTCHA_LOW_SCORE
}
