import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useApprovalsConfigForFlow } from 'hooks/useApprovalsConfigForFlow'
import RequiresApprovalSuccessModal from 'pages/Approvals/components/RequiresApprovalSuccessModal'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { postDepositDetails } from 'services/WalletsService'
import { Limits } from 'types/types'
import { WalletTabProps } from '../Modal/WalletModal'
import ConfirmProtocol from './ConfirmProtocol'
import Send2fa from './Send2fa'
import SendComplete from './SendComplete'
import SendConfirm from './SendConfirm'
import SendForm from './SendForm'

export type SendSteps = '2fa' | 'form' | 'confirm' | 'complete' | 'addProtocol'

export type SendForm = {
  walletId?: string
  address?: string
  password?: string
  amount?: string | number
  destinationTag?: string
  saveBeneficiary?: boolean
  beneficiaryName?: string
  protocol?: string
  message?: string
  uuid?: string
  twoFaField?: string
  reference?: string
  steps?: {
    currentStep: number
    totalSteps: number
  }
}

export type SendActionProps = WalletTabProps &
  IWalletActionApiType<SendForm, SendSteps> & {
    withdrawalLimits?: Limits
    addressValidated?: string
    setAddressValidated?: Dispatch<SetStateAction<string>>
  }

/**
 * Send Tab containing state, form and API data for each step
 */
export const Send = ({
  step,
  form,
  loading,
  setLoading,
  wallets,
  ...otherProps
}: SendActionProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { enableCryptoAddressCreateOnSend } = useFeatureFlags()

  const [addressValidated, setAddressValidated] = useState<string>()
  const formValues = form.watch()

  const { approvalsIsRequiredForFlow, isFetchingApprovalPreference } = useApprovalsConfigForFlow(
    Approval.FLOWS.CRYPTO_PAYOUT,
    formValues?.amount
  )
  const props = {
    form,
    loading,
    setLoading,
    addressValidated,
    setAddressValidated,
    wallets,
    step,
    ...otherProps,
  }

  const supportedWallets = wallets?.filter(
    wallet => !wallet?.currency?.fiat && wallet?.currency?.supportsWithdrawals
  )

  const isRequiresApprovalModalVisible = approvalsIsRequiredForFlow && !isFetchingApprovalPreference

  /**
   * Get withdrawal limits for selected curreny code
   */
  useEffect(() => {
    async function checkWallet() {
      const selectedWallet =
        formValues.walletId && supportedWallets.find(x => x.id.toString() === formValues.walletId)

      if (!selectedWallet) {
        return
      }

      if (!selectedWallet.address) {
        setLoading(true)
        try {
          await postDepositDetails({
            walletId: selectedWallet.id.toString(),
          })

          // refresh wallets
          await queryClient.invalidateQueries({ queryKey: ['wallets'] })
        } catch (err) {
          logSentryError('Error from Send - postDepositDetails', err)
          form.setError('message', { message: t('depositDetailsError') })
        }
        setLoading(false)
      }
    }

    // fetch wallet if no address and feature flag is enabled
    if (enableCryptoAddressCreateOnSend) {
      checkWallet()
    }
  }, [formValues?.walletId])

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case '2fa':
        return <Send2fa {...props} />
      case 'form':
        return <SendForm {...props} />
      case 'addProtocol':
        return <ConfirmProtocol {...props} />
      case 'confirm':
        return <SendConfirm {...props} />
      case 'complete':
        return isRequiresApprovalModalVisible ? (
          <RequiresApprovalSuccessModal onClose={props?.dismissAction} actionType={props?.action} />
        ) : (
          !isFetchingApprovalPreference && <SendComplete {...props} />
        )
    }
  }

  return renderStep()
}
