import { useAtomValue } from 'jotai'
import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { usdBeneficiaryBankTypeAtom } from 'pages/ManageBeneficiaries/AddBeneficiary/atoms/beneficiaryAtoms'
import { USDBeneficiaryOptions } from 'types/beneficiaries'
import { CountryCodes } from 'types/types'
import { NUMERIC_VALIDATION_PATTERN } from 'utils/yupSchemas/createNameSchema'

const CBIT_ADDRESS_EXACT_LENGTH = 42
const CBIT_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
export const DEFAULT_CBIT_BANK_CODE = 'CUESUS33'

export const getUsdSpecificSchemaFields = (t: TFunction<'translation', undefined>) => {
  const beneficiaryBankType = useAtomValue(usdBeneficiaryBankTypeAtom)
  const accountNumberLabel = t('accountNumber')
  const usdAccountNumber = yup
    .string()
    .label(accountNumberLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is USD and not a CBIT beneficiary
        if (
          ctx?.parent?.countryCode === CountryCodes.US &&
          !value &&
          beneficiaryBankType !== USDBeneficiaryOptions.CBIT
        ) {
          ctx.schema.required()
          return ctx.createError({ message: t('accountNumberRequired') })
        }

        // Validation for fields if United states
        if (ctx?.parent?.countryCode === CountryCodes.US) {
          const usAccNumberMinLength = 5
          const usAccNumberMaxLength = 17

          if (!NUMERIC_VALIDATION_PATTERN.test(value)) {
            return ctx.createError({
              message: t('onlyNumericValues'),
            })
          }

          if (value?.length < usAccNumberMinLength) {
            return ctx.createError({
              message: t('fieldMinLength', {
                field: accountNumberLabel,
                min: usAccNumberMinLength,
              }),
            })
          }

          if (value?.length > usAccNumberMaxLength) {
            return ctx.createError({
              message: t('fieldMaxLength', {
                field: accountNumberLabel,
                max: usAccNumberMaxLength,
              }),
            })
          }
        }

        // Validation for fields if not United States
        const nonUsAccNumberMinLength = 5
        if (value?.length < nonUsAccNumberMinLength) {
          return ctx.createError({
            message: t('fieldMinLength', {
              field: accountNumberLabel,
              min: nonUsAccNumberMinLength,
            }),
          })
        }

        if (!/^[a-zA-Z0-9]*$/.test(value)) {
          return ctx.createError({
            message: t('fieldAlphanumeric', { field: accountNumberLabel }),
          })
        }

        return true
      },
    })

  const abaRoutingNumberLabel = t('abaRoutingNumber')
  const abaRoutingNumber = yup
    .string()
    .label(abaRoutingNumberLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is USD and not a CBIT beneficiary
        if (
          ctx.parent?.countryCode === CountryCodes.US &&
          !value &&
          beneficiaryBankType !== USDBeneficiaryOptions.CBIT
        ) {
          ctx.schema?.required()
          return ctx.createError({ message: t('abaRoutingNumberRequired') })
        }

        // Validation for fields if United States
        if (ctx?.parent?.countryCode === CountryCodes.US) {
          const usRoutingNumberExactLength = 9

          if (!NUMERIC_VALIDATION_PATTERN.test(value)) {
            return ctx.createError({
              message: t('onlyNumericValues'),
            })
          }

          if (
            value?.length < usRoutingNumberExactLength ||
            value?.length > usRoutingNumberExactLength
          ) {
            return ctx.createError({
              message: t('fieldExactLength', {
                field: abaRoutingNumberLabel,
                amount: usRoutingNumberExactLength,
              }),
            })
          }
        }

        // Validation for fields if not United States
        const nonUsRoutingNumberMinLength = 3
        if (value?.length < nonUsRoutingNumberMinLength) {
          return ctx.createError({
            message: t('fieldMinLength', {
              field: abaRoutingNumberLabel,
              min: nonUsRoutingNumberMinLength,
            }),
          })
        }

        if (!/^[a-zA-Z0-9]*$/.test(value)) {
          return ctx.createError({
            message: t('fieldAlphanumeric', { field: abaRoutingNumberLabel }),
          })
        }

        return true
      },
    })

  const cbitLabel = t('cbitAddress')
  const cbitAddress = yup
    .string()
    .label(cbitLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is USD and is CBIT beneficiary

        const isCbitAddressLengthValid = value?.length === CBIT_ADDRESS_EXACT_LENGTH
        const isCbitAddressFormatValid = CBIT_ADDRESS_REGEX.test(value)

        if (!value && beneficiaryBankType === USDBeneficiaryOptions.CBIT) {
          ctx.schema?.required()
          return ctx.createError({ message: t('walletAddressRequired') })
        }
        if (!isCbitAddressLengthValid) {
          return ctx.createError({
            message: t('fieldExactLength', {
              field: cbitLabel,
              amount: CBIT_ADDRESS_EXACT_LENGTH,
            }),
          })
        }

        if (!isCbitAddressFormatValid) {
          return ctx.createError({
            message: t('cbitAddressFormatError'),
          })
        }
        return true
      },
    })

  const cbitBankCode = yup.string().required()

  return {
    usdAccountNumber,
    abaRoutingNumber,
    cbitAddress,
    cbitBankCode,
  }
}
