import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@mantine/hooks'
import {
  Box as OuterWrapper,
  Box as Header,
  Box as Footer,
  Box as Body,
  Box as Wrapper,
  NotificationCard as Card,
  Text,
  Modal,
  ModalNavBar,
  ModalActions,
} from '@node-space/storybook-components'
import { SummaryRow, SummaryDetail } from '@node-space/storybook-components/dist/SummaryDetail'
import { putMarkUnderpaidPaymentComplete } from 'services/PaymentsService'
import { useToastContext } from 'hooks/useToastContext'
import { calculatePercentageDiff, getStatusState, titleCase } from 'utils/utils'
import { PaymentsResponse } from 'types/payments'
import { logError } from '@node-space/utils'

interface PaymentUnderpaidModalProps {
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
  setIsPaymentSuccess: (value: boolean) => void
  transaction: PaymentsResponse
}

const PaymentUnderpaidModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsPaymentSuccess,
  transaction,
}: PaymentUnderpaidModalProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()
  const componentMounted = useRef(true)

  const [isClicked, setIsClicked] = useToggle(false, [false, true])

  const amountPaid = transaction?.walletCurrency?.actual
  const amountDue = transaction?.walletCurrency?.amount - transaction?.walletCurrency?.actual

  useEffect(() => {
    return () => {
      componentMounted.current = false
    }
  }, [])

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const onMarkPaymentComplete = async () => {
    try {
      setIsClicked(true)
      await putMarkUnderpaidPaymentComplete(transaction?.uuid)

      if (componentMounted.current) {
        addToast({ title: `${t('markedPaymentSuccess')}`, state: 'success' })
        setIsClicked(false)
        setIsModalOpen(false)
        setIsPaymentSuccess(true)
      }
    } catch (error) {
      const {
        data: { errorList },
      } = error
      setIsClicked(false)
      logError('Error from PaymentUnderpaidModal - onMarkPaymentComplete', error)
      addToast({
        title: `${errorList?.[0]?.message || t('defaultError')}`,
        state: 'error',
      })
    }
  }

  return (
    <OuterWrapper testid="payment-mark-underpaid-modal-wrapper">
      <Modal allowOverFlow visible={isModalOpen} closeModal={onCloseModal}>
        <Header testid="payment-mark-underpaid-modal-header">
          <ModalNavBar title={t('acceptUnderpayment')} onClose={onCloseModal} />
        </Header>
        <Wrapper
          tag="section"
          testid="payment-mark-underpaid-modal-inner"
          className="p-0 m-0 w-512 mx-auto"
        >
          <Body testid="payment-mark-underpaid-modal-body" className="space-y-2 pt-0 pb-8 p-5">
            <SummaryDetail>
              <SummaryRow
                label={t('status')}
                renderDescription={
                  <Text size="sm">
                    <Card
                      size="xs"
                      state={getStatusState(transaction?.status?.toUpperCase())}
                      label={titleCase(transaction?.status)}
                    />
                  </Text>
                }
              />

              <SummaryRow
                label={t('paymentRequested')}
                renderDescription={
                  <Text size="sm">
                    {transaction?.walletCurrency?.amount} {transaction?.walletCurrency?.currency}
                  </Text>
                }
              />
              <SummaryRow
                label={t('quoteAccepted')}
                renderDescription={
                  <Text size="sm">
                    {transaction?.exchangeRate?.base} {transaction?.walletCurrency?.actual}
                  </Text>
                }
              />

              <SummaryRow
                label={t('receivedPayment')}
                renderDescription={
                  <Text size="sm">
                    {transaction?.exchangeRate?.base} {amountPaid}
                    {` (${calculatePercentageDiff(
                      transaction?.walletCurrency?.actual,
                      amountDue,
                      'paid'
                    )})`}
                  </Text>
                }
              />

              <SummaryRow
                label={t('receivedDue')}
                renderDescription={
                  <Text size="sm">
                    {transaction?.exchangeRate?.base} {amountDue}
                    {` (${calculatePercentageDiff(
                      transaction?.walletCurrency?.actual,
                      amountDue,
                      'remaining'
                    )})`}
                  </Text>
                }
              />
            </SummaryDetail>

            <Text color="grey" size="xs">
              {t('termsUnderpayment1')}
            </Text>
            <Text color="grey" size="xs">
              {t('termsUnderpayment2')}
            </Text>
          </Body>

          <Footer testid="payment-mark-underpaid-modal-footer">
            <ModalActions
              actions={[
                {
                  children: t('cancel'),
                  secondary: true,
                  onClick: onCloseModal,
                },
                {
                  children: t('acceptUnderpayment'),
                  onClick: onMarkPaymentComplete,
                  loading: isClicked,
                },
              ]}
            />
          </Footer>
        </Wrapper>
      </Modal>
    </OuterWrapper>
  )
}

export default PaymentUnderpaidModal
