import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { useWalletContext } from 'hooks/context'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import WalletModal, { WalletAction } from 'pages/Wallets/components/Modal/WalletModal'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { WalletStatus } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface WalletButtonsCryptoProps {
  disabled?: boolean
}

export const WalletButtonsCrypto = ({ disabled }: WalletButtonsCryptoProps) => {
  const { t } = useTranslation()
  const [walletModalAction, setWalletModalAction] = useState<WalletAction>(undefined)
  const queryClient = useQueryClient()
  const wallet = useWalletContext()
  const isSelectedWalletActive = useIsActiveWallet(wallet)

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.wallet(wallet.id) })
  }
  const handleOpenReceiveModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_RECEIVE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
    setWalletModalAction({
      key: 'receive',
      data: {
        id: String(wallet.id),
      },
    })
  }
  const handleOpenSendModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_SEND, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
    setWalletModalAction({
      key: 'send',
      data: {
        id: String(wallet.id),
      },
    })
  }
  const handleOpenConvertModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_CONVERT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
      isFiat: false,
    })
    setWalletModalAction({
      key: 'convert',
      data: {
        id: String(wallet.id),
      },
    })
  }

  return (
    isSelectedWalletActive && (
      <Box flex padding={8} className="gap-4 sm:pt-0">
        <Button
          testid="buttonConvert"
          disabled={disabled}
          onClick={handleOpenConvertModal}
          iconElement={<Icon color="white" name="ConvertIcon" />}
        >
          {t('convert')}
        </Button>
        <Button
          testid="buttonSend"
          disabled={disabled}
          onClick={handleOpenSendModal}
          iconElement={<Icon color="white" name="ArrowUpRightIcon" />}
        >
          {t('send')}
        </Button>
        <Button
          testid="buttonReceive"
          disabled={disabled}
          onClick={handleOpenReceiveModal}
          iconElement={<Icon color="white" name="ArrowDownLeftIcon" />}
        >
          {t('receive')}
        </Button>
        <WalletModal
          action={walletModalAction}
          setWalletModalAction={setWalletModalAction}
          refresh={handleRefresh}
        />
      </Box>
    )
  )
}
