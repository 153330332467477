import React from 'react'
import { Box, ImageIcon, Text } from '@node-space/storybook-components'
import { CurrencyInterface } from 'types/wallets'

interface CurrencyProps {
  walletCurrency: string
  currencies: CurrencyInterface[]
}

const Currency = ({ walletCurrency, currencies }: CurrencyProps) => {
  const cryptoDescription = (crypto: string) => {
    return currencies ? currencies.find(currency => currency.code === crypto)?.name : ''
  }

  return (
    <Box flex gapX={12} alignItems="center" className="whitespace-nowrap">
      <Box size={24}>
        <ImageIcon
          width={24}
          height={24}
          src={`${process.env.ICONS_URL}${walletCurrency.toLowerCase()}.svg`}
          alt=""
        />
      </Box>
      <Box flex direction="col" className="text-gray-800 text-tiny">
        {cryptoDescription(walletCurrency)}
        <Text className="text-gray-400 text-tiny">{walletCurrency}</Text>
      </Box>
    </Box>
  )
}

export default Currency
