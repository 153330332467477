import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Modal, { AlignActions, ModalActions } from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import Text from '@node-space/storybook-components/dist/Text'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { useApprovalsContext } from 'hooks/useApprovalsContext'
import { useApprovalFlowsCheckboxOptions } from './hooks/useApprovalsFlowSettings'

const ApprovalPreferenceSuccessModal = () => {
  const { t } = useTranslation()

  const {
    permissions,
    approvalsConfig: {
      handleApprovalPreferenceSuccessModal,
      handleApprovalPreferenceSettingsModal,
      approvalPreferences,
      approvalPreferencesLimits,
    },
  } = useApprovalsContext()

  const { approvalFlowCheckboxOptions } = useApprovalFlowsCheckboxOptions(approvalPreferences)

  const {
    profile: { currencyCode },
  } = useProfileContext()
  const { data: accountPreferences, isLoading: isLoadingAccountPreferences } =
    useAccountPreferencesQuery()
  const baseCurrency =
    (!isLoadingAccountPreferences && (accountPreferences?.currency || currencyCode)) || ''

  const handleResetForm = () => handleApprovalPreferenceSuccessModal({ isVisible: false })
  const handleClose = () => {
    handleResetForm()
    handleApprovalPreferenceSettingsModal({ isVisible: false })
  }

  const modalActions = permissions?.canEdit
    ? [
        {
          children: t('approvals.addAnotherRule'),
          secondary: true,
          onClick: handleResetForm,
        },
        {
          children: t('done'),
          onClick: handleClose,
        },
      ]
    : [
        {
          children: t('done'),
          onClick: handleClose,
        },
      ]

  return (
    <Modal closeOnBackgroundClick={false} closeModal={handleClose} visible>
      <StatusCard
        className="h-40"
        status="success"
        headline={t('approvals.rulesSaved')}
        borderless
      />
      <SummaryDetail>
        <SummaryRow
          label={t('approvals.typeOfOutgoingPayment')}
          renderDescription={
            <Box flex direction="col">
              {approvalFlowCheckboxOptions?.map((item, index) => (
                <Box key={index}>
                  {item?.checked && (
                    <Text color="text-500" size="sm">
                      {item?.label}
                    </Text>
                  )}
                </Box>
              ))}
            </Box>
          }
        />
        {approvalPreferencesLimits?.map((limit, index) => (
          <Box key={index}>
            <SummaryRow
              label={`${t('approvals.condition')} ${index + 1}`}
              description={`${t('from')} ${limit?.minAmount} - ${limit?.maxAmount} ${baseCurrency}; ${limit?.approvalsCount} ${limit?.approvalsCount === 1 ? t('approvals.approver')?.toLowerCase() : t('approvals.approvers')?.toLowerCase()}`}
            />
          </Box>
        ))}
      </SummaryDetail>
      <ModalActions
        actions={modalActions}
        {...(!permissions?.canEdit && { alignActions: AlignActions.CENTER })}
      />
    </Modal>
  )
}

export default React.memo(ApprovalPreferenceSuccessModal)
