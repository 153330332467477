import React, { createContext, useEffect, useState } from 'react'

export interface OfflineContext {
  isOffline: boolean
}

export const OfflineContext = createContext<OfflineContext>({ isOffline: false })

interface OfflineContextProviderProps {
  children: React.ReactNode
}

export const OfflineContextProvider = ({ children }: OfflineContextProviderProps) => {
  const [isOffline, setIsOffline] = useState(false)

  const onOnline = () => setIsOffline(false)
  const onOffline = () => setIsOffline(true)

  useEffect(() => {
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)
    return () => {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [])

  const value = {
    isOffline,
  }

  return <OfflineContext.Provider value={value}>{children}</OfflineContext.Provider>
}
