import { FieldErrorsImpl } from 'react-hook-form'

export const CustomerEntityType = {
  COMPANY: 'COMPANY',
  INDIVIDUAL: 'INDIVIDUAL',
} as const

export type CustomerEntityType = (typeof CustomerEntityType)[keyof typeof CustomerEntityType]

export const CustomerStatus = {
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
} as const

export type CustomerStatusType = (typeof CustomerStatus)[keyof typeof CustomerStatus]

export interface Customer {
  reference: string
  status: CustomerStatusType
  type: CustomerEntityType
  name: string
  description: string
}

export interface Address {
  address1: string
  address2?: string
  city: string
  postCode: string
  countryCode: string
  country: string
}

export interface MonthlyExpectedVolumes {
  reference: string
  name: string
  min: number
  max: number
}

export interface Industry {
  reference: string
  name: string
}

export interface Representative {
  firstName: string
  lastName: string
  dateOfBirth: string
  countryCode: string
  address: Address
}

export interface Business {
  name: string
  description: string
  taxResidenceCountryCode: string
  businessRegistrationNumber: string
  address: Address
  representative: Representative
}

export interface Individual {
  firstName: string
  lastName: string
  dateOfBirth: string
  birthCountryCode: string
  residenceCountryCode: string
  documentNumber: string
  address: Address
}

export interface CustomerType {
  type: CustomerEntityType
}

export interface BusinessCustomerType extends CustomerType {
  company: Business
  industry: Industry
  monthlyExpectedVolumes: MonthlyExpectedVolumes
}

export interface IndividualCustomerType extends CustomerType {
  person: Individual
}

export interface AddCustomerPayload {
  customerEntityType?: CustomerEntityType
  customerType?: string
  businessName?: string
  industry?: string
  industryName?: string
  businessDescription?: string
  businessRegistrationNumber?: string
  businessMonthlyVolume?: string
  businessMonthlyVolumeName?: string
  businessTaxResidency?: string
  businessTaxResidencyName?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  nationality?: string
  nationalityName?: string
  countryCode?: string
  countryName?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  postCode?: string
}

export type AddCustomerPayloadKeys = keyof AddCustomerPayload

export type AddCustomerFormErrors = Partial<FieldErrorsImpl<AddCustomerPayload>>

export interface MonthlyVolume {
  id: number
  max: number
  min: number
  name: string
  reference: string
}

export interface Industries {
  id: number
  name: string
}

export interface TransformedOption {
  label: string
  options: {
    label: string
    value: string
  }[]
}
