import React from 'react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import Radio from '@node-space/storybook-components/dist/Radio'
import Text from '@node-space/storybook-components/dist/Text'
import { WalletTypes } from 'types/types'
import { walletTypeAtom } from '../atoms/massPayoutAtoms'

export const MassPayoutTypeSelection = () => {
  const { t } = useTranslation()
  const [walletType, setWalletType] = useAtom(walletTypeAtom)
  const { enableMassPayoutsFiat } = useFeatureFlags()
  const onCurrencyTypeSelection = (type: string) => {
    setWalletType(type)
  }

  const MasspayoutsFiatCurrencies = 'EUR, GBP'

  return (
    <Box>
      <Radio
        height={36}
        name="currencyType"
        label={t('massPayouts.whatCurrenciesAreYouPayingOutIn')}
        onChange={onCurrencyTypeSelection}
        options={[
          {
            label: t('massPayouts.cryptocurrencies'),
            value: WalletTypes.CRYPTO,
          },
          {
            label: MasspayoutsFiatCurrencies,
            value: WalletTypes.FIAT,
            ...(!enableMassPayoutsFiat && {
              disabled: true,
              extendedLabel: (
                <Text
                  size="xs"
                  color="primary-500"
                  className="ml-2 bg-purple-100 font-medium px-2.5 py-0.5 rounded"
                >
                  {t('massPayouts.comingSoon').toUpperCase()}
                </Text>
              ),
            }),
          },
        ]}
        value={walletType}
      />
    </Box>
  )
}
