import React from 'react'
import i18next from 'i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

interface FirstAndThirdPartyWithdrawalProps {
  amountWithdrawn: string
  transactionReference: string
  currencyCode: string
}

const FirstAndThirdPartyWithdrawal = ({
  amountWithdrawn,
  currencyCode,
  transactionReference,
}: FirstAndThirdPartyWithdrawalProps) => {
  const amountWithCurrency = `${amountWithdrawn} ${currencyCode}`
  return (
    <Box gapY={8}>
      <TransactionDetailRow
        label={i18next.t('transactions.sideBar.amountWithdrawn')}
        detail={amountWithCurrency}
      />
      {!!transactionReference && (
        <CopyTransactionRow
          content={transactionReference}
          label={i18next.t('transactions.sideBar.reference')}
        />
      )}
    </Box>
  )
}

export default FirstAndThirdPartyWithdrawal
