import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Box as Body } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Text, TextColor, TextSize } from '@node-space/storybook-components/dist/Text'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { wait } from '@node-space/utils'

interface CopyUrlProps {
  redirectUrl: string
  testid?: string
}
const CopyUrl = ({ redirectUrl, testid }: CopyUrlProps) => {
  const { t } = useTranslation()

  const [_, setCopied] = useState<string | null>()
  const [copyText, setCopyText] = useState<string>(t('copy'))
  const onCopy = async value => {
    setCopied(value)
    setCopyText(t('copied'))
    await wait(5000)
    setCopied(null)
    setCopyText(t('copy'))
  }

  return (
    <Body
      testid={testid ?? 'copy-url'}
      flex
      alignItems="center"
      height={48}
      padding={4}
      border="gray"
      borderRadius={4}
      className="bg-gray-01"
    >
      {redirectUrl && (
        <>
          <div className="truncate">
            <Tooltip id="tooltip-url" position="top" bodyContent={redirectUrl}>
              <Text
                className="px-2 text-ellipsis overflow-hidden"
                size={TextSize.SM}
                color={TextColor.DARKGREY}
              >
                {redirectUrl}
              </Text>
            </Tooltip>
          </div>
          <CopyToClipboard text={redirectUrl} onCopy={() => onCopy(redirectUrl)}>
            <span className="cursor-pointer">
              <Tooltip id="tooltip-copy" position="top" bodyContent={copyText}>
                <Icon name="CopyIcon" color={secondaryButtonIconColour} />
              </Tooltip>
            </span>
          </CopyToClipboard>
          <a href={redirectUrl} rel="noreferrer noopener" target="_blank" className="mx-4">
            <Tooltip id="tooltip-external-link" position="top" bodyContent={t('openNewTab')}>
              <Icon name="ExternalLinkIcon" color={secondaryButtonIconColour} />
            </Tooltip>
          </a>
        </>
      )}
    </Body>
  )
}

export default React.memo(CopyUrl)
