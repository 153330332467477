import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Chips } from '@node-space/storybook-components/dist/Chips'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import Toggle from '@node-space/storybook-components/dist/Toggle'
import { ApiKeyRequestForm, IPOption } from 'types/apiKeys'
import { apiKeySchema } from './schema'

export type ApiKeyCreateForm = {
  payload?: ApiKeyRequestForm
  onSubmit: SubmitHandler<{
    description: string
    withdrawals: boolean
    payroll: boolean
    allowedIps: IPOption[]
  }>
  isLoading?: boolean
  requestError?: string
  isEditMode?: boolean
  dismissAction?: () => void
  enablePayrollRole: boolean
}

const ApiKeyCreateForm = ({
  payload,
  onSubmit,
  isLoading = false,
  isEditMode = false,
  requestError,
  dismissAction,
  enablePayrollRole,
}: ApiKeyCreateForm) => {
  const { t } = useTranslation()

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<{
    description: string
    withdrawals: boolean
    payroll: boolean
    allowedIps: IPOption[]
  }>({
    defaultValues: {
      allowedIps: payload?.allowedIps ?? [],
      description: payload?.description ?? '',
      withdrawals: payload?.withdrawals ?? true,
      payroll: enablePayrollRole,
    },
    resolver: yupResolver(apiKeySchema()),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [allowedIps, withdrawals] = useWatch({
    control,
    name: ['allowedIps', 'withdrawals'],
  })

  const isIP = (ip: string): boolean => {
    return /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ip)
  }
  const isFormValid = (isValid && !!allowedIps.length) || (isValid && !withdrawals)

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      secondary: true,
      onClick: dismissAction,
    },
    {
      children: isEditMode ? t('save') : t('continue'),
      disabled: !isFormValid,
      loading: isLoading,
      testid: t('continue'),
      type: 'submit',
    },
  ]

  return (
    <Box
      tag="form"
      flex
      direction="col"
      gapY={12}
      data-testid="apikey-create"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box padding={24} gapY={24} flex direction="col">
        <Input
          label={t('name')}
          placeholder={t('manageApiKeys.keyDescription')}
          name="description"
          testid="api-key-name"
          error={Boolean(errors?.description)}
          errorText={errors?.description?.message}
          disabled={isLoading}
          {...register('description')}
        />

        <Chips
          label={t('manageApiKeys.allowedIpAddressInputLabel')}
          placeholder={'manageApiKeys.allowedIpAddressInputPlaceholder'}
          rightLabel={withdrawals ? '' : t('optionalLabel')}
          name="allowedIps"
          testid="api-key-allowed-ips"
          onChipsChange={values => setValue('allowedIps', values)}
          useValidation={true}
          isValidFormat={isIP}
          value={allowedIps}
          errorText={t('manageApiKeys.invalidIPEntry')}
          uniqueErrorText={t('manageApiKeys.duplicateIPEntry')}
          disabled={isLoading}
        />

        <Box width="full" gapX={20}>
          <Text size="sm" weight="medium">
            {t('manageApiKeys.enableAdditionally')}
          </Text>
          <Box flex width="full" paddingT={8}>
            <Toggle
              value={Boolean(withdrawals)}
              onChange={value => {
                setValue('withdrawals', value)
              }}
            />
            <Box flex direction="col" paddingL={8}>
              <Text size="sm" color="black" className="mr-2">
                {t('manageApiKeys.enableWithdrawalsLabel')}
              </Text>
              <Text size="xs" color="disabled" className="mr-2">
                {t('manageApiKeys.enabledWithdrawalsDescription')}
              </Text>
            </Box>
          </Box>
        </Box>

        {requestError && <Text color="error">{requestError}</Text>}
      </Box>

      <ModalActions actions={actions} />
    </Box>
  )
}

export default React.memo(ApiKeyCreateForm)
