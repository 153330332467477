import React from 'react'
import { Box, ImageIcon, Text } from '@node-space/storybook-components'

interface CurrencyProps {
  cryptoDescription: string
  currencyName: string
  currencyCode: string
}

const CurrencyCell = ({ cryptoDescription, currencyName, currencyCode }: CurrencyProps) => {
  return (
    <Box flex gapX={12} alignItems="center" className="whitespace-nowrap">
      <Box size={24}>
        <ImageIcon
          width={24}
          height={24}
          src={`${process.env.ICONS_URL}${currencyCode.toLowerCase()}.svg`}
          alt=""
        />
      </Box>
      <Box flex direction="col" className="text-gray-800 text-tiny">
        {cryptoDescription}
        <Text className="text-gray-500 text-tiny">{currencyName}</Text>
      </Box>
    </Box>
  )
}

export default CurrencyCell
