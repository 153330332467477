import React from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { BeneficiaryDetailRow } from 'pages/ManageBeneficiaries/components/BeneficiaryDetailRow'
import { BeneficiaryType } from 'types/beneficiaries'
import { SidePanelBodyDataProps } from './MassPayoutSidePanel'

export type MassPayoutSidePanelBodyProps = {
  sidePanelBodyData: SidePanelBodyDataProps
  isB2CPayout: boolean
}

export const MassPayoutSidePanelBody = ({
  sidePanelBodyData,
  isB2CPayout,
}: MassPayoutSidePanelBodyProps) => {
  const { t } = useTranslation()
  const sidePanelData =
    sidePanelBodyData?.preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary

  // Beneficiary Details
  const accountNumber = sidePanelData?.accountNumber
  const bankCode = sidePanelData?.bankCode
  const bankCountry = sidePanelData?.bankCountry
  const dateOfBirth = sidePanelData?.dateOfBirth
  const beneficiaryType = sidePanelData?.type
  const isFirstParty = beneficiaryType === BeneficiaryType.FIRST_PARTY
  const beneficiaryTypeLabel = isFirstParty ? t('internal') : t('external')
  const countryOfRegistration = sidePanelData?.country
  const entityType = isB2CPayout
    ? t('manageBeneficiaries.individual')
    : t('manageBeneficiaries.business')

  return (
    <Box>
      <Text size="base" color="dark-grey">
        {t('bankDetails')}
      </Text>
      <Box paddingT={8} paddingB={12}>
        {accountNumber && (
          <BeneficiaryDetailRow
            label={t('accountNumber')}
            detail={
              <Box flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {ellipseMiddleText(accountNumber, 8, 8)}
                </Text>
                <CopyButton
                  copyText={accountNumber}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                  confirmationMessageTheme="dark"
                />
              </Box>
            }
          />
        )}
        {bankCode && (
          <BeneficiaryDetailRow
            label={t('bankCode')}
            detail={
              <Box flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {bankCode}
                </Text>
                <CopyButton
                  copyText={bankCode}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                  confirmationMessageTheme="dark"
                />
              </Box>
            }
          />
        )}
        {bankCountry && <BeneficiaryDetailRow label={t('bankCountry')} detail={bankCountry} />}
      </Box>

      <Box>
        <Text size="base" color="dark-grey">
          {t('massPayouts.beneficiaryDetails')}
        </Text>
        <Box paddingT={8} paddingB={8}>
          {dateOfBirth && (
            <BeneficiaryDetailRow label={t('formFields.dateOfBirth')} detail={dateOfBirth} />
          )}
          {beneficiaryType && (
            <BeneficiaryDetailRow
              label={t('manageBeneficiaries.beneficiaryType')}
              detail={beneficiaryTypeLabel}
            />
          )}
          {entityType && (
            <BeneficiaryDetailRow label={t('manageBeneficiaries.entityType')} detail={entityType} />
          )}
          {countryOfRegistration && (
            <BeneficiaryDetailRow
              label={t('massPayouts.countryOfRegistration')}
              detail={countryOfRegistration}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
