import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { BeneficiaryDetailRow } from 'pages/ManageBeneficiaries/components/BeneficiaryDetailRow'

export type MassPayoutListSidePanelBodyProps = {
  dateCreated: string
  uploadedBy: string
  paidFromWallet: string
}

export const MassPayoutListSidePanelBody = ({
  dateCreated,
  uploadedBy,
  paidFromWallet,
}: MassPayoutListSidePanelBodyProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box paddingT={8} paddingB={4}>
        {dateCreated && (
          <BeneficiaryDetailRow label={t('massPayouts.dateCreated')} detail={dateCreated} />
        )}
      </Box>
      <Box paddingT={4} paddingB={4}>
        {uploadedBy && (
          <BeneficiaryDetailRow label={t('massPayouts.uploadedBy')} detail={uploadedBy} />
        )}
      </Box>
      <Box paddingT={4} paddingB={12}>
        {paidFromWallet && (
          <BeneficiaryDetailRow label={t('massPayouts.paidFromWallet')} detail={paidFromWallet} />
        )}
      </Box>
    </Box>
  )
}
