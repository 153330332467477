import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useWalletLimits } from 'hooks/queries/useWalletLimits'
import { Quote } from 'types/payments'
import { WalletType } from 'types/types'
import { floorWithPrecision, formatString } from 'utils/utils'
import { ConvertFormType } from '../Convert'

export const useConvertWallets = (
  formValues: ConvertFormType,
  convertCurrency: string,
  quoteEstimate: Quote,
  wallets: WalletType[]
) => {
  const { t } = useTranslation()

  // From wallets
  const fromWallets = useMemo(
    () =>
      wallets?.filter(
        wallet => wallet?.id?.toString() !== formValues?.toWalletId && wallet?.balance > 0
      ),
    [wallets]
  )

  const fromWallet = useMemo(
    () => fromWallets?.find(wallet => wallet?.id?.toString() === formValues?.walletId),
    [fromWallets]
  )

  const fromWalletOptions = useMemo(
    () =>
      fromWallets?.map(wallet => ({
        icon: wallet?.currency?.code?.toLowerCase(),
        value: wallet?.id?.toString(),
        label: wallet?.description,
        secondLabel: formatString(
          t('balanceWithCurrency'),
          floorWithPrecision(wallet?.balance || 0, wallet?.currency?.quantityPrecision),
          wallet?.currency?.code
        ),
      })),
    [fromWallets]
  )

  //To wallets
  const toWallets = useMemo(
    () => wallets?.filter(wallet => wallet?.id?.toString() !== formValues?.walletId),
    [wallets]
  )
  const toWallet = useMemo(
    () => toWallets?.find(wallet => wallet?.id?.toString() === formValues?.toWalletId),
    [toWallets]
  )

  // Wallet currency codes
  const fromWalletCurrencyCode = fromWallet ? fromWallet?.currency?.code : formValues?.walletId
  const toWalletCurrencyCode = toWallet ? toWallet?.currency?.code : formValues?.toWalletId

  const {
    data: withdrawalLimits,
    isFetching: isFetchingLimits,
    isError: isErrorLimits,
  } = useWalletLimits(
    fromWalletCurrencyCode,
    fromWallet?.id,
    !!fromWalletCurrencyCode && !!fromWallet?.id
  )

  const toWalletOptions = useMemo(
    () =>
      toWallets?.map(wallet => ({
        icon: wallet?.currency?.code?.toLowerCase(),
        value: wallet?.id?.toString(),
        label: wallet?.description,
        secondLabel:
          Number.isInteger(wallet?.id) &&
          formatString(
            t('balanceWithCurrency'),
            floorWithPrecision(wallet?.balance || 0, wallet?.currency?.pricePrecision),
            wallet?.currency?.code
          ),
      })),
    [toWallets]
  )

  // Other
  const exchangeRate = quoteEstimate?.amountOut / (quoteEstimate?.amountIn || 1)

  const toCurrencyPrecision =
    (toWalletCurrencyCode === convertCurrency && toWallet?.currency?.pricePrecision) ||
    fromWallet?.currency.pricePrecision
  const isSameCurrencyConvertion = convertCurrency === fromWalletCurrencyCode
  const otherConvertCurrency = isSameCurrencyConvertion
    ? toWalletCurrencyCode
    : fromWalletCurrencyCode

  const limitMaximum = withdrawalLimits?.maximum
  const limitMinimum = withdrawalLimits?.minimum

  const maxAmount = fromWallet?.available >= limitMaximum ? limitMaximum : fromWallet?.available

  const adjustedMin = isSameCurrencyConvertion
    ? limitMinimum
    : floorWithPrecision(limitMinimum * exchangeRate, toCurrencyPrecision)
  const adjustedMax = isSameCurrencyConvertion
    ? limitMaximum
    : floorWithPrecision(limitMaximum * exchangeRate, toCurrencyPrecision)
  const adjustedMaxAmount = isSameCurrencyConvertion
    ? maxAmount
    : floorWithPrecision(maxAmount * exchangeRate, toCurrencyPrecision)

  return {
    withdrawalLimits,
    fromWallet,
    fromWalletCurrencyCode,
    toWalletCurrencyCode,
    toWallet,
    isFetchingLimits,
    isErrorLimits,
    fromWalletOptions,
    toWalletOptions,
    adjustedMin,
    adjustedMax,
    adjustedMaxAmount,
    isSameCurrencyConvertion,
    otherConvertCurrency,
    limitMinimum,
    toCurrencyPrecision,
    exchangeRate,
  }
}
