import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@node-space/hooks'
import { Box, Box as FormRow } from '@node-space/storybook-components/dist/Box'
import Button, { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import Icon from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import Modal, {
  ModalNavBar,
  ModalScrollable,
  ModalSize,
} from '@node-space/storybook-components/dist/Modal'
import Search from '@node-space/storybook-components/dist/Search'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useAddNetworkConnectionMutation } from 'hooks/mutations/BvnkNetwork/useAddNetworkConnectionMutation'
import { useNetworkMembersQuery } from 'hooks/queries/BvnkNetwork/useNetworkMembersQuery'
import { useToastContext } from 'hooks/useToastContext'
import { NetworkMember } from 'types/bvnkNetwork'
import { useBvnkMemberSearch } from '../hooks/useBvnkMemberSearch'
import { BvnkNetworkRequestSentTag } from './BvnkNetworkRequestSentTag'
import ConnectionsList from './ConnectionsList'
import { ConnectionItem } from './ConnectionsListItem'
import { NetworkMembersEmptyState } from './NetworkMembersEmptyState'

interface SentRequestsHistory {
  [property: string]: boolean
}

export interface DiscoverNetworkMembersProps {
  isModalOpen: boolean
  handleModalClose: () => void
}

export const DiscoverNetworkMembers = ({
  isModalOpen,
  handleModalClose,
}: DiscoverNetworkMembersProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { currentAccount } = useAccountsContext()
  const addToast = useToastContext()
  const { mutate: addNetworkConnection } = useAddNetworkConnectionMutation()
  const {
    data: networkMembers,
    isFetching: isFetchingNetworkMembers,
    isError: isFetchingNetworkMembersError,
  } = useNetworkMembersQuery(currentAccount?.reference, isModalOpen)
  const [connectionList, setConnectionList] = useState<ConnectionItem[]>([])
  const [sentRequests, setSentRequests] = useState<SentRequestsHistory>({})

  // Phase 1: FE filter
  // Phase 2: Add BE search and pagination
  const [query, setQuery] = useState('')
  const debouncedSearchTerm: string = useDebounce(query, 500)

  const { filteredConnectionList, isFiltering } = useBvnkMemberSearch(
    debouncedSearchTerm,
    connectionList
  )

  const onNetworkMemberConnectHandler = (networkMember: NetworkMember) => {
    const { accountReference, name } = networkMember?.account || {}

    addNetworkConnection(
      {
        requesterAccount: currentAccount?.reference,
        receiverAccount: accountReference,
      },
      {
        onSuccess: () => {
          setSentRequests({ ...sentRequests, [accountReference]: true })
          queryClient.invalidateQueries({ queryKey: ['bvnkNetworkConnections'] })
        },
        onError: () => {
          addToast({
            title: t('bvnkNetwork.addNetworkMemberError', { networkMember: name }),
            state: 'error',
          })
        },
      }
    )
  }

  useEffect(() => {
    if (networkMembers?.length) {
      const list = networkMembers?.map(networkMember => {
        const { accountReference } = networkMember?.account || {}
        const buttonProps = {
          children: (
            <Text size="sm" color="primary-500">
              {t('bvnkNetwork.connect')}
            </Text>
          ),
          iconElement: <Icon name="UserPlusIcon" size="sm" color="primary-500" />,
          noStyling: true,
          onClick: () => onNetworkMemberConnectHandler(networkMember),
        } as ButtonProps

        return {
          connectionId: '',
          key: `${accountReference}${sentRequests[accountReference] ? '' : '-request-sent'}`,
          name: networkMember?.account?.name,
          description: networkMember?.account?.description,
          icon: <Icon size="default" name="ProfileIcon" />,
          actions: sentRequests[accountReference] ? (
            <Box testid="network-request-sent-tag">
              <BvnkNetworkRequestSentTag />
            </Box>
          ) : (
            <Button {...buttonProps} />
          ),
        }
      })

      setConnectionList(list)
    } else {
      setConnectionList([])
    }
  }, [networkMembers, sentRequests])

  const onSearchTermChange = (searchTermValue: string) => {
    setQuery(searchTermValue)
  }

  const onModalClose = () => {
    handleModalClose?.()
    setSentRequests({})
    setQuery(null)
  }

  return (
    // TODO: Look at the height transition
    <Modal
      testid="discover-members-modal"
      size={ModalSize.xl}
      allowOverFlow
      visible={isModalOpen}
      closeModal={onModalClose}
    >
      <ModalNavBar title={t('bvnkNetwork.discoverMembers')} onClose={onModalClose} />
      <Box testid="discover-members-container" padding={20}>
        {isFetchingNetworkMembers || isFiltering ? (
          <Box flex centerChildren width="full" paddingT={28} alignItems="center">
            <Loader size="medium" />
          </Box>
        ) : !isFetchingNetworkMembersError && !connectionList?.length ? (
          <NetworkMembersEmptyState onModalClose={onModalClose} />
        ) : (
          <>
            {isFetchingNetworkMembersError && (
              <Box>
                <Callout message={t('bvnkNetwork.discoverMembersError')} state="error" />
              </Box>
            )}

            {!isFetchingNetworkMembersError && (
              <>
                <FormRow width="full">
                  <Search
                    searchTerm={query}
                    size="sm"
                    testid="network-member-search-field"
                    placeholderText={t('bvnkNetwork.networkMembersSearchPlaceholder')}
                    onChange={event => onSearchTermChange(event.target.value)}
                  />
                </FormRow>

                {!!query && !filteredConnectionList?.length ? (
                  <StatusCard
                    className="mt-4"
                    headline={t('bvnkNetwork.noSearchResultHeadline')}
                    description={t('bvnkNetwork.noSearchResultDescription')}
                    borderless
                  />
                ) : (
                  <ModalScrollable>
                    <Box className="-mt-4">
                      <ConnectionsList connections={filteredConnectionList} />
                    </Box>
                  </ModalScrollable>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}
