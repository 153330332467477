import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { Paging } from 'types/types'
import { getAllPayments } from '../../services/PaymentsService'

interface PaymentsApiOptions {
  paging?: Paging
  from?: string
  to?: string
  merchant?: string
  status?: string
  searchByRef?: string
  searchByTransactionHash?: string
  tag: string
}

export const usePaymentsQuery = (
  paymentOptions: PaymentsApiOptions,
  queryOptions?: Omit<UseQueryOptions<unknown>, 'queryKey' | 'queryFn'>
) => {
  const { paging, from, to, merchant, status, searchByRef, searchByTransactionHash, tag } =
    paymentOptions

  return useQuery({
    queryKey: reactQueryKeys.payments(
      paging,
      from,
      to,
      merchant,
      status,
      searchByRef,
      searchByTransactionHash,
      tag
    ),
    queryFn: () =>
      getAllPayments({
        paging,
        from,
        to,
        merchant,
        status,
        searchByRef,
        searchByTransactionHash,
        tag,
      }),
    enabled: false,
    gcTime: 0,
    staleTime: Infinity,
    ...queryOptions,
  })
}
