import React, { useEffect, useRef, useState } from 'react'
import { useDocumentTitle } from '@mantine/hooks'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom'
import { useAuth, useFeatureFlags } from '@node-space/hooks'
import { Box, Box as Main, Box as Page } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { AppBanners, AppBannerType } from 'components/app/AppBanners'
import { FlagEnabledBanner } from 'components/banners/FlagEnabledBanner'
import { UKRiskBanner } from 'components/banners/UKRiskBanner'
import type { CustomBreadCrumbConfig } from 'components/Breadcrumbs'
import { SandboxGoLiveCTA } from 'components/SandboxGoLiveCTA'
import { PathNames, RedirectPathStateKey } from 'constants/General'
import { ApprovalContextProvider } from 'contexts/approvalsContext'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useComplianceViewHelper } from 'hooks/useComplianceViewHelper'
import { usePendingApprovalDetails } from 'hooks/usePendingApprovalDetails'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import TwoFactorAuthMandatory from 'pages/TwoFactorAuth/TwoFactorAuthMandatory'
import { isProduction } from 'utils/environment'
import AppLoader from '../AppLoader'
import Header from '../Header'
import Sidebar from '../Sidebar'

export type PageLayoutProps = {
  title?: string
  isTitleEnabled?: boolean
  loading?: boolean
  canRoute?: boolean
  noStyling?: boolean
  breadcrumbConfig?: CustomBreadCrumbConfig[]
} & RouteProps

const PageLayout = ({
  title,
  isTitleEnabled = true,
  loading,
  canRoute,
  noStyling,
  breadcrumbConfig,
}: PageLayoutProps) => {
  const { t } = useTranslation()
  const { state } = useLocation() || {}
  const { isAuthed } = useAuth()
  const { showTwoFAMandatoryScreen } = useTwoFAContext()
  const { enableBanner, enableApprovalFlowAccountLevel, enableChatbot } = useFeatureFlags()

  const { profile } = useProfileContext()
  const isComplianceView = useComplianceViewHelper(profile)
  const {
    currentAccount,
    isDeveloperAccount,
    shouldShowUKInfoBanner,
    isLoading: isLoadingAccounts,
  } = useAccountsContext()

  const currentAccountReference = currentAccount?.reference || ''
  const pendingApprovalDetails = usePendingApprovalDetails(
    currentAccountReference,
    enableApprovalFlowAccountLevel && isAuthed && profile?.loggedIn && !!currentAccountReference
  )

  const siteTitle = title ? `${title} | ${process.env.SITE_TITLE}` : `${process.env.SITE_TITLE}`
  useDocumentTitle(siteTitle)

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const sidebarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (!sidebarRef?.current?.contains(event.target as Node)) {
        setSidebarOpen(false)
      }
    }

    document.addEventListener('mousedown', onClick)
    return () => {
      document.removeEventListener('mousedown', onClick)
    }
  }, [sidebarOpen])

  if (loading || isLoadingAccounts) {
    return <AppLoader />
  }

  // TODO: Can be move to protected component
  if (!isAuthed) {
    const currentPath = window?.location?.pathname
    return <Navigate to={PathNames.LOGOUT} state={{ [RedirectPathStateKey]: currentPath }} />
  }

  if (canRoute === false) {
    return <Navigate to={PathNames.NOT_FOUND} />
  }

  if (noStyling) {
    return <Outlet />
  }

  const appBanners: AppBannerType[] = []

  if (!isProduction && isDeveloperAccount) {
    appBanners.push(<SandboxGoLiveCTA />)
  }

  if (enableBanner?.enabled) {
    appBanners.push(<FlagEnabledBanner {...enableBanner} />)
  }

  if (shouldShowUKInfoBanner) {
    appBanners.push(<UKRiskBanner companyName={currentAccount?.name} />)
  }

  if (showTwoFAMandatoryScreen && !state?.isImpersonate) {
    return <TwoFactorAuthMandatory />
  }

  return (
    <>
      <AppBanners banners={appBanners} />

      <Box flex minHeight="full" width="full" overflow="hidden">
        {!isComplianceView &&
          (enableApprovalFlowAccountLevel ? (
            <ApprovalContextProvider pendingApprovalDetails={pendingApprovalDetails}>
              <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                sidebarRef={sidebarRef}
              />
            </ApprovalContextProvider>
          ) : (
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              sidebarRef={sidebarRef}
            />
          ))}

        <Page
          flex
          direction="col"
          background="off-white"
          className={clsx('flex-1 relative overflow-y-auto overflow-x-hidden', {
            'mb-24': enableChatbot,
          })}
        >
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            title={title}
            isTitleEnabled={isTitleEnabled}
            isHamburgerEnabled={!isComplianceView}
            breadcrumbConfig={breadcrumbConfig}
          />

          <Main
            tag="main"
            width="full"
            padding={16}
            className={clsx('sm:p-6 lg:px-8 lg:pb-8 lg:pt-6 max-w-9xl mx-auto', {
              'mb-8': shouldShowUKInfoBanner,
            })}
          >
            <Outlet />
          </Main>

          {shouldShowUKInfoBanner && (
            <Text size="sm" align="center" className="absolute bottom-2 left-0 right-0">
              {t('ukApprovalStamp')}
            </Text>
          )}
        </Page>
      </Box>
    </>
  )
}

export default PageLayout
