import { useMemo } from 'react'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { BvnkNetworkTransferTransactionSubType } from 'pages/WalletDetails/components/RecentActivity/SidePanel/PaymentTemplates/BvnkNetworkTransfer'
import { TransactionType } from 'types/transaction/TransactionType'

interface UseTransactionHistoryLabelProps {
  transactionType: string
  transactionSubType: string
  name: string
  fallback: string
}
export const useTransactionHistoryLabel = ({
  transactionType,
  transactionSubType,
  name,
  fallback,
}: UseTransactionHistoryLabelProps) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const isNetworkTransfer = transactionType === TransactionType.BVNK_NETWORK_TRANSFER

    if (isNetworkTransfer) {
      const isPayout = transactionSubType === BvnkNetworkTransferTransactionSubType.PAYMENT_OUT
      const isPayin = transactionSubType === BvnkNetworkTransferTransactionSubType.PAYMENT_IN

      if (isPayout) {
        return t('transactions.sideBar.networkTransferTo', {
          networkMember: name,
        })
      } else if (isPayin) {
        return t('transactions.sideBar.networkTransferFrom', {
          networkMember: name,
        })
      }

      return startCase(transactionType)
    } else {
      return fallback
    }
  }, [transactionType, transactionSubType, name, startCase])
}
