import React from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box as Wrapper, Input, Text } from '@node-space/storybook-components'
import { PathNames } from 'constants/General'

type PasswordFieldTypes = {
  error?: FieldError
  register: UseFormRegister<{ password: string }>
  isLoading: boolean
}

const PasswordField = ({ error, register, isLoading }: PasswordFieldTypes) => {
  const { t } = useTranslation()

  return (
    <Wrapper flex width="full">
      <Input
        label={t('twoFactorVerification.enterPassword')}
        rightLabel={
          <Text
            color="primary-500"
            size="sm"
            className="hover:underline hover:text-primary-700 active:text-primary-900"
          >
            <Link to={PathNames.FORGOT_PASSWORD}>{t('forgotPassword')}</Link>
          </Text>
        }
        placeholder={t('enterPassword')}
        type="password"
        name="password"
        testid="password-2FA-input"
        disabled={isLoading}
        error={Boolean(error)}
        errorText={error?.message}
        {...register('password')}
      />
    </Wrapper>
  )
}

export default React.memo(PasswordField)
