import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components'
import Callout from '@node-space/storybook-components/dist/Callout'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { useRecurringExportContext } from 'hooks/context/useRecurringExportContext'
import { useReportScheduleQuery } from 'hooks/queries/useReportScheduleQuery'
import { RecurringExportSteps } from 'types/walletTransactionsExport'
import { ReportScheduleItem } from './ReportSchedule'

export const RecurringExportList = () => {
  const { t } = useTranslation()
  const [isDeleteError, setIsDeleteError] = useState(false)
  const { setStep } = useRecurringExportContext()
  const { data: reportSchedules = [], isFetching, isError } = useReportScheduleQuery(true)

  useEffect(() => {
    if (!isFetching && !isError && !reportSchedules?.length) {
      setStep(RecurringExportSteps.FORM)
    }
  }, [isFetching, isError, reportSchedules])

  return (
    <Box flex direction="col" gapY={16} paddingX={24} paddingY={16}>
      {isFetching && <Skeleton className="animate-pulse" variant="button" />}
      {isError && !isFetching && (
        <Callout state="error" message={t('exports.scheduledReportsError')} />
      )}
      {!isFetching && !isError && !!reportSchedules?.length && (
        <>
          {reportSchedules?.map(reportSchedule => {
            return (
              <ReportScheduleItem
                key={reportSchedule?.id}
                reportSchedule={reportSchedule}
                setIsDeleteError={setIsDeleteError}
              />
            )
          })}
        </>
      )}
      {isDeleteError && <Callout state="error" message={t('exports.deleteReportScheduleError')} />}
    </Box>
  )
}
