import React, { Dispatch, SetStateAction, useState } from 'react'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import { Quote } from 'types/payments'
import { WalletTabProps } from '../Modal/WalletModal'
import ConvertComplete from './ConvertComplete'
import ConvertConfirm from './ConvertConfirm'
import ConvertForm from './ConvertForm'

export type ConvertSteps = 'form' | 'confirm' | 'complete'

export type ConvertFormType = {
  walletId?: string
  toWalletId?: string
  amount?: string | number
  reference?: string
}

export type ConvertActionProps = WalletTabProps &
  IWalletActionApiType<ConvertFormType, ConvertSteps> & {
    quote: Quote
    setQuote: Dispatch<SetStateAction<Quote>>
    convertCurrency: string
    setConvertCurrency: Dispatch<SetStateAction<string>>
    shouldUseMax: boolean
    shouldUseMin: boolean
    setShouldUseMax: Dispatch<SetStateAction<boolean>>
    setShouldUseMin: Dispatch<SetStateAction<boolean>>
  }

type ConvertProps = WalletTabProps & IWalletActionApiType<ConvertFormType, ConvertSteps>

export const Convert = ({ loading, step, form, ...otherProps }: ConvertProps) => {
  const [shouldUseMax, setShouldUseMax] = useState(false)
  const [shouldUseMin, setShouldUseMin] = useState(false)
  const [convertCurrency, setConvertCurrency] = useState<string>()
  const [quote, setQuote] = useState<Quote>()

  const props = {
    form,
    quote,
    setQuote,
    shouldUseMax,
    shouldUseMin,
    convertCurrency,
    setShouldUseMax,
    setShouldUseMin,
    setConvertCurrency,
    loading,
    step,
    ...otherProps,
  }

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case 'form':
        return <ConvertForm {...props} />
      case 'confirm':
        return <ConvertConfirm {...props} />
      case 'complete':
        return <ConvertComplete {...props} />
    }
  }

  return renderStep()
}
