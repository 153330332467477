import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import { WalletType } from 'types/types'

interface ProtocolFormated {
  code: string
  address: string
}

interface ProtocolContextProviderProps {
  children: React.ReactNode
  wallet: WalletType
}

interface ProtocolContext {
  currentProtocol: ProtocolFormated
  setProtocol: React.Dispatch<React.SetStateAction<ProtocolFormated>>
  protocols: ProtocolFormated[]
}

export const ProtocolContext = createContext<ProtocolContext>({
  currentProtocol: {} as ProtocolFormated,
  setProtocol: () => null,
  protocols: [],
})

export const ProtocolContextProvider = ({ children, wallet }: ProtocolContextProviderProps) => {
  const [currentProtocol, setProtocol] = useState<ProtocolFormated>()
  const [protocols, setProtocols] = useState<ProtocolFormated[]>([])
  useEffect(() => {
    const walletCurrencyProtocols = wallet?.currency?.protocols
    if (walletCurrencyProtocols?.length > 1) {
      const defaultProtocol = {
        code: wallet?.protocol,
        address: wallet?.address,
      }
      setProtocol(defaultProtocol)
      setProtocols([defaultProtocol])
      if (wallet?.alternatives?.length) {
        const alternatives = wallet?.alternatives
        const protocols = alternatives?.map(alternative => ({
          code: alternative?.protocol,
          address: alternative?.address,
        }))
        setProtocols([defaultProtocol, ...protocols])
      }
    }
  }, [wallet])
  return (
    <ProtocolContext.Provider value={{ currentProtocol, setProtocol, protocols }}>
      {children}
    </ProtocolContext.Provider>
  )
}
