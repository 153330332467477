import { useCallback, useEffect, useState } from 'react'
import { usePaymentSupportCasesQuery } from 'hooks/queries/usePaymentSupportCases'
import { CasesPagination, PaymentCaseType } from 'types/payments'

export const CASELIST_PAGINATION_MAX = 20

const defaultPagination: CasesPagination = {
  paging: { offset: 0, total: 0, max: CASELIST_PAGINATION_MAX },
  pagingMax: CASELIST_PAGINATION_MAX,
}

const useCaseListApi = (status?: PaymentCaseType[]) => {
  const [caseListPagination, setCaseListPagination] = useState<CasesPagination>(defaultPagination)

  const { paging, pagingMax } = caseListPagination

  const {
    data: { cases: casesList, count } = {},
    isFetching,
    isPending,
    isError,
    refetch,
  } = usePaymentSupportCasesQuery(paging, status)

  useEffect(() => {
    setCaseListPagination(prev => ({
      ...prev,
      paging: {
        ...paging,
        total: count,
      },
    }))
  }, [count])

  useEffect(() => {
    refetch()
  }, [paging.offset])

  useEffect(() => {
    setCaseListPagination(defaultPagination)

    // if status change and offset stays 0
    if (!paging.offset) {
      refetch()
    }
  }, [status])

  const onPageChange = useCallback(
    ({ paging }) => {
      setCaseListPagination(prev => ({
        ...prev,
        paging: {
          ...paging,
          total: count,
          max: pagingMax,
        },
      }))
    },
    [paging, pagingMax, count]
  )

  return {
    executor: refetch,
    isFetching,
    isLoading: isPending,
    isError,
    data: { caseListPagination, casesList },
    actions: {
      onPageChange,
      setCaseListPagination,
      showSearchResult: refetch,
    },
  }
}

export default useCaseListApi
