import { TFunction } from 'react-i18next'
import useDepositPaymentReference from 'pages/Wallets/components/Deposit/hooks/useDepositPaymentReference'
import { DepositBankAccount, Options } from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import { Currencies, WalletType } from 'types/types'

export const getLocalBankDetails = (
  options: Options,
  wallet: WalletType,
  currency: string,
  isSegregated: boolean,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean
) => {
  let details = {}

  switch (currency) {
    case 'EUR':
      details = getEurDetails(options, wallet, t, isDepositsV2)
      break
    case 'GBP':
      details = getGbpDetails(options, wallet, t, isDepositsV2)
      break
    case 'ZAR':
      details = getZarDetails(options, wallet, t, isDepositsV2)
      break
    case 'NGN':
      details = getNgnDetails(options, wallet, t)
      break
    case 'USD':
      details = getUsdDetails(options, wallet, t, isDepositsV2)
      break
  }

  if (isSegregated) {
    if (options?.reference) {
      details[t('referenceNumber')] = options.reference
    } else {
      delete details[t('referenceNumber')]
    }
  }

  return details
}

const getEurDetails = (
  options: Options,
  wallet,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean
) => {
  if (isDepositsV2) {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
      ...(options?.bankCode && { [t('bankCode')]: options.bankCode }),
    }
  } else {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.iban && { IBAN: options.iban }),
      ...(options?.bic && { BIC: options.bic }),
    }
  }
}

const getGbpDetails = (
  options: Options,
  wallet,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean
) => {
  if (isDepositsV2) {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
      ...(options?.bankCode && { [t('bankCode')]: options.bankCode }),
    }
  } else {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.ukSortCode && { [t('sortCode')]: options.ukSortCode }),
      ...(options?.ukAccountNumber && { [t('accountNumber')]: options.ukAccountNumber }),
    }
  }
}

const getZarDetails = (
  options: Options,
  wallet,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean
) => {
  if (isDepositsV2) {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
      ...(options?.bankCode && { [t('bankCode')]: options.bankCode }),
      ...(options?.bankName && { [t('bankName')]: options.bankName }),
      ...(options?.accountType && { [t('accountType')]: options.accountType }),
    }
  } else {
    return {
      [t('referenceNumber')]: wallet.address || '-',
      ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
      ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
      ...(options?.routingNumber && { [t('branchCode')]: options.routingNumber }),
      ...(options?.bankName && { [t('bankName')]: options.bankName }),
      ...(options?.accountType && { [t('accountType')]: options.accountType }),
    }
  }
}

const getUsdDetails = (
  options: Options,
  wallet,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean
) => {
  return {
    [t('referenceNumber')]: wallet?.address || '-',
    ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
    ...(options?.usdBankRoutingNumber && {
      [t('bankRoutingNumber')]: options.usdBankRoutingNumber,
    }),
    ...(!isDepositsV2 &&
      options?.usdAccountNumber && { [t('accountNumber')]: options.usdAccountNumber }),
    ...(isDepositsV2 && options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
    // TODO: Add CBIT details if necessary
    ...(options?.bankName && { [t('bankName')]: options.bankName }),
    ...(options?.bankCity && { [t('bankCity')]: options.bankCity }),
    ...(options?.bankState && { [t('bankState')]: options.bankState }),
    ...(options?.bankCountry && { [t('bankCountry')]: options.bankCountry }),
    ...(options?.bankPostalCode && { [t('bankPostalCode')]: options.bankPostalCode }),
  }
}

const getNgnDetails = (options: Options, wallet, t: TFunction<'translation', undefined>) => {
  return {
    [t('referenceNumber')]: wallet?.address || '-',
    ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
    ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
    ...(options?.bankName && { [t('bankName')]: options.bankName }),
  }
}

export const getV1SwiftBeneficiaryDetails = (
  options: Options,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(options?.beneficiaryName && { [t('beneficiaryName')]: options?.beneficiaryName }),
    ...(options?.beneficiaryAddress?.addressLine && {
      [t('beneficiaryAddress')]: options?.beneficiaryAddress?.addressLine,
    }),
    ...(options?.beneficiaryAddress?.city && {
      [t('beneficiaryCity')]: options?.beneficiaryAddress?.city,
    }),
    ...(options?.beneficiaryAddress?.countryCode && {
      [t('beneficiaryCountry')]: options?.beneficiaryAddress?.countryCode,
    }),
    ...(options?.beneficiaryAddress?.postalCode && {
      [t('beneficiaryZipCode')]: options?.beneficiaryAddress?.postalCode,
    }),
  }
}

export const getV2SwiftBeneficiaryDetails = (
  options: Options,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(options?.accountHolderName && { [t('beneficiaryName')]: options?.accountHolderName }),
    ...(options?.beneficiaryAddress?.addressLine1 && {
      [t('beneficiaryAddress')]: options?.beneficiaryAddress?.addressLine1,
    }),
    ...(options?.beneficiaryAddress?.city && {
      [t('beneficiaryCity')]: options?.beneficiaryAddress?.city,
    }),
    ...(options?.beneficiaryAddress?.countryCode && {
      [t('beneficiaryCountry')]: options?.beneficiaryAddress?.countryCode,
    }),
    ...(options?.beneficiaryAddress?.postCode && {
      [t('beneficiaryZipCode')]: options?.beneficiaryAddress?.postCode,
    }),
  }
}

export const getV1SwiftBankDetails = (
  options: Options,
  t: TFunction<'translation', undefined>,
  shouldFetchReference = true
) => {
  const paymentReference = useDepositPaymentReference(options)
  return {
    // EUR
    ...(options?.iban && { [t('accountNumber')]: options.iban }),
    ...(options?.bic && { [t('bic')]: options.bic }),
    // USD
    // TODO: Add CBIT details if necessary
    ...(options?.usdAccountNumber && { [t('accountNumber')]: options.usdAccountNumber }),
    ...(options?.usdBankRoutingNumber && {
      [t('abaRoutingNumber')]: options.usdBankRoutingNumber,
    }),
    ...(options?.bankName && { [t('bankName')]: options.bankName }),
    ...(options?.bankAddress?.addressLine && {
      [t('bankAddress')]: options.bankAddress.addressLine,
    }),
    ...(options?.bankAddress?.countryCode && {
      [t('bankCountry')]: options.bankAddress.countryCode,
    }),
    ...(shouldFetchReference && paymentReference && { [t('paymentReference')]: paymentReference }),
  }
}

export const getV2SwiftBankDetails = (
  options: Options,
  t: TFunction<'translation', undefined>,
  shouldFetchReference = true
) => {
  const paymentReference = useDepositPaymentReference(options)
  return {
    ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
    ...(options?.bankCode && { [t('bankCode')]: options.bankCode }),
    ...(options?.usdBankRoutingNumber && {
      [t('abaRoutingNumber')]: options.usdBankRoutingNumber,
    }),
    ...(options?.bankName && { [t('bankName')]: options.bankName }),
    ...(options?.bankAddress?.addressLine1 && {
      [t('bankAddress')]: options.bankAddress.addressLine1,
    }),
    ...(options?.bankAddress?.countryCode && { [t('country')]: options.bankAddress.countryCode }),
    ...(options?.bankAddress?.postCode && { [t('bankZipCode')]: options?.bankAddress?.postCode }),
    ...(shouldFetchReference && paymentReference && { [t('paymentReference')]: paymentReference }),
  }
}

export const getSwiftBankDetails = (
  options: Options,
  t: TFunction<'translation', undefined>,
  isDepositsV2: boolean,
  shouldFetchReference = true
): {
  beneficiaryDetails: { [property: string]: string }
  beneficiaryBankDetails: { [property: string]: string }
} => {
  return {
    beneficiaryDetails: isDepositsV2
      ? getV2SwiftBeneficiaryDetails(options, t)
      : getV1SwiftBeneficiaryDetails(options, t),
    beneficiaryBankDetails: isDepositsV2
      ? getV2SwiftBankDetails(options, t, shouldFetchReference)
      : getV1SwiftBankDetails(options, t, shouldFetchReference),
  }
}

export const getPaymentTitle = (
  account: DepositBankAccount,
  t: TFunction<'translation', undefined>
): string => {
  const { currency, bankName } = account || {}
  if (account?.isSwift || account?.accountNumberFormat === BankTransferType.SWIFT) {
    return t('internationalTransfer', { currency })
  } else {
    return account?.currency === Currencies.ZAR
      ? t('localTransferZAR', { bankName })
      : t('localTransfer', { currency })
  }
}
