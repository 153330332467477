import React, { useCallback, useContext, useState } from 'react'
import { PaymentCreatedContextProvider } from 'pages/Payments/context/PaymentCreatedProvider'
import { PaymentCreateModalContext } from '../../context/PaymentCreateModalProvider'
import PaymentCreateModal from './PaymentCreateModal'
import PaymentSuccessModal from './PaymentSuccessModal'

interface PaymentModalProps {
  onSuccessPayment?: () => void
}

const PaymentModal = ({ onSuccessPayment }: PaymentModalProps) => {
  const [redirectUrl, setRedirectUrl] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useContext(PaymentCreateModalContext)

  const onSuccessCreatePayment = useCallback(redirectUrl => {
    setShowCreateModal(false)
    setShowSuccessModal(true)
    setRedirectUrl(redirectUrl)
    onSuccessPayment()
  }, [])

  return (
    <PaymentCreatedContextProvider>
      {showCreateModal && <PaymentCreateModal onSuccessCreatePayment={onSuccessCreatePayment} />}

      {showSuccessModal && (
        <PaymentSuccessModal redirectUrl={redirectUrl} setShowSuccessModal={setShowSuccessModal} />
      )}
    </PaymentCreatedContextProvider>
  )
}

export default React.memo(PaymentModal)
