import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input } from '@node-space/storybook-components/dist/Input'
import {
  UpdateBeneficiaryFormErrors,
  UpdateBeneficiaryFormPayload,
  UpdateBeneficiaryFormPayloadKeys,
} from 'types/beneficiaries'

interface EditBeneficiaryFormProps {
  alias: string
  errorMessage: string | undefined
  errors: UpdateBeneficiaryFormErrors
  isGenericError: boolean
  handleOnchange: (field: UpdateBeneficiaryFormPayloadKeys, value: string) => void
  register: UseFormRegister<UpdateBeneficiaryFormPayload>
}

export const EditBeneficiaryForm = ({
  alias,
  errors,
  handleOnchange,
  register,
}: EditBeneficiaryFormProps) => {
  const { t } = useTranslation()

  return (
    <Box paddingX={24} className="my-6 space-y-6 text-gray-100">
      <Input
        testid="update-beneficiary-name-input"
        {...register('alias')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnchange('alias', e.target.value)
        }
        label={t('manageBeneficiaries.beneficiaryName')}
        inputMode="text"
        name="beneficiaryName"
        value={alias}
        error={!!errors?.alias}
        errorText={errors?.alias?.message}
      />
    </Box>
  )
}
