import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getTotalBalance } from 'services/WalletsService'
import { QueryMetaType } from 'types/reactQuery'

export const useGetTotalBalance = (baseCurrency: string) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { baseCurrency },
  }

  return useQuery({
    queryKey: reactQueryKeys.totalBalance(baseCurrency),
    queryFn: () => getTotalBalance(baseCurrency),
    enabled: !!baseCurrency,
    meta,
  })
}
