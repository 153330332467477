import React from 'react'

interface ToggleIconProps {
  additionalClass?: string
}
const ToggleIcon = ({ additionalClass = '' }: ToggleIconProps) => {
  return (
    <div className="flex flex-shrink-0 ml-2">
      <svg
        className={`w-3 h-3 flex-shrink-0 ml-1 fill-current ${additionalClass}`}
        viewBox="0 0 12 12"
      >
        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
      </svg>
    </div>
  )
}

export default ToggleIcon
