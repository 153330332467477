import React from 'react'
import clsx from 'clsx'
import { Box as Wrapper } from '@node-space/storybook-components'

interface EmptyContainerProps {
  children: React.ReactNode
  hasGap?: boolean
  className?: string
}

const EmptyContainer = ({ children, className, hasGap = true }: EmptyContainerProps) => {
  return (
    <Wrapper
      flex
      centerChildren
      borderRadius={8}
      background="white"
      border="gray"
      className={clsx('p-10 ', { 'mt-10': hasGap }, className)}
    >
      {children}
    </Wrapper>
  )
}

export default EmptyContainer
