import React from 'react'
import i18next from 'i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TransactionDetailRow } from '../TransactionDetailRow'
import { DepositDetails } from 'types/transaction'
import CopyTransactionRow from './CopyTransactionRow'

interface FiatDepositProps {
  depositDetails: DepositDetails
  amountWithCurrency: string
  transactionReference: string
  transactionDate: string
}

const FiatDeposit = ({
  depositDetails,
  amountWithCurrency,
  transactionReference,
  transactionDate,
}: FiatDepositProps) => {
  return (
    <>
      <TransactionDetailRow
        label={i18next.t('transactions.sideBar.paymentInAmountReceived')}
        detail={amountWithCurrency}
      />
      {!!transactionReference && (
        <CopyTransactionRow
          content={transactionReference}
          label={i18next.t('transactions.sideBar.reference')}
        />
      )}
      <TransactionDetailRow label={i18next.t('date')} detail={transactionDate} />
      <Text size="base" weight="medium" color="dark-grey" className="mt-3">
        {i18next.t('senderDetails')}
      </Text>
      <Box gapY={8}>
        {!!depositDetails?.senderAccountNumber && (
          <TransactionDetailRow
            label={i18next.t('walletsPage.fromBankAccountNo')}
            detail={depositDetails?.senderAccountNumber}
          />
        )}
        {depositDetails?.senderBankCode && (
          <TransactionDetailRow
            label={i18next.t('walletsPage.fromBankCode')}
            detail={depositDetails?.senderBankCode}
          />
        )}
        {depositDetails?.senderName && (
          <TransactionDetailRow
            label={i18next.t('walletsPage.receivedFrom')}
            detail={depositDetails?.senderName}
          />
        )}
      </Box>
    </>
  )
}

export default FiatDeposit
