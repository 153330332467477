import { FieldErrorsImpl } from 'react-hook-form'
import { BeneficiaryTransferDestination, BeneficiaryType } from 'types/beneficiaries'

export const MassPayoutSteps = {
  UPLOAD: 'UPLOAD',
  REVIEW: 'REVIEW',
  LIST: 'LIST',
  INITIAL: 'INITIAL',
} as const
export type MassPayoutStepsType = (typeof MassPayoutSteps)[keyof typeof MassPayoutSteps]

export const ModalVariant = {
  PROCESSED: 'PROCESSED',
  APPROVALS: 'APPROVALS',
  SUCCESSFUL: 'SUCCESSFUL',
  DRAFT: 'DRAFT',
} as const
export type ModalVariantType = (typeof ModalVariant)[keyof typeof ModalVariant]

export const BatchStatus = {
  CREATED: 'CREATED',
  VALIDATED: 'VALIDATED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  DRAFT: 'DRAFT',
} as const

export type BatchStatus = (typeof BatchStatus)[keyof typeof BatchStatus]

export const RecordStatus = {
  CREATED: 'CREATED',
  VALID: 'VALID',
  INVALID: 'INVALID',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  REVERTED: 'REVERTED',
  ERROR: 'ERROR',
  DRAFT: 'DRAFT',
} as const

export type RecordStatus = (typeof RecordStatus)[keyof typeof RecordStatus]

export const PayoutType = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const

export type PayoutType = (typeof PayoutType)[keyof typeof PayoutType]

export interface EnrichedBeneficiary {
  type: BeneficiaryType
  transferDestination: BeneficiaryTransferDestination
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  businessName?: string | null
  country?: string | null
  accountNumber: string
  bankCode: string
  bankCountry: string
}

interface BeneficiaryDetails {
  reference?: string
  enrichedBeneficiary?: EnrichedBeneficiary
}

export interface PreProcessedPayouts {
  reference: string
  amount: string
  beneficiaryDetails: BeneficiaryDetails
  feeAmount?: string
  paymentReference: string
  status: RecordStatus
  validationErrors?: ValidationErrors
  retryCount?: number
}

export interface PaymentSummary {
  totalAmount: string
  totalFee: string
  totalAmountWithFee: string
}

export interface PayoutStatuses {
  [key: string]: number
}

export interface CreatedBy {
  userId: string
  email: string
  fullName: string
}

export interface TriggeredBy {
  userId: string
  email: string
  fullName: string
}

export interface FiatPayout {
  reference: string
  alias: string
  walletId: string
  accountReference: string
  currency: string
  status: BatchStatus
  createdBy: CreatedBy
  createdAt: string
  triggeredBy: TriggeredBy
  paymentSummary: PaymentSummary
  payoutStatuses: PayoutStatuses
  preProcessedPayouts: PreProcessedPayouts[]
}
export interface MassFiatPayout {
  content: FiatPayout[]
  pageable: string
  last: boolean
  totalPages: number
  totalElements: number
  size: number
  number: number
  sort: any[]
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface MassPayoutConfirmationPayload {
  password: string
}

type ValidationErrors = Record<string, string>

export interface UpdateFiatPayoutFormPayload {
  businessName?: string
  bankCode?: string
  accountNumber?: string
  amount?: string
  paymentReference?: string
  bankCountry?: string
  type?: string
  transferDestination?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
}

export interface UpdatePayoutFormPayload2 {
  amount: string
  enrichedBeneficiary?: EnrichedBeneficiary
  paymentReference?: string
}

export interface MassPayoutPostAuthRequest {
  challenge: string
  tokenResponse: string
  callbackUrl?: string
}

export type UpdateFiatPayoutFormPayloadKeys = keyof UpdateFiatPayoutFormPayload

export type UpdateFiatPayoutFormErrors = Partial<FieldErrorsImpl<UpdateFiatPayoutFormPayload>>

export interface MassPayoutCryptoBatch {
  externalId: string
  merchantId: string
  userName: string
  accountId: string
  brandId: number
  batchId: string
  fileName: string
  totalItems: number
  totalPaidRequiredAmount: number
  items: CryptoPayout[]
  createdAt: string
  totalFeeAmount: number
  totalProcessedItems: number
  merchantName: string
  walletCurrency: string
  status: MassPayoutCryptoBatchStatusType
}

export const MassPayoutCryptoBatchStatus = {
  DRAFT: 'DRAFT',
  REQUESTED: 'REQUESTED',
  COMPLETED: 'COMPLETED',
  CREATED: 'CREATED',
  PROCESSING: 'PROCESSING',
}

export type MassPayoutCryptoBatchStatusType =
  (typeof MassPayoutCryptoBatchStatus)[keyof typeof MassPayoutCryptoBatchStatus]

export interface CryptoPayout {
  externalId: string
  massPayoutExternalId: string
  paymentExternalId: string
  fundingWalletAmount?: number
  paidCurrencyAmount?: number
  currency: string
  protocol: string
  tag: string
  address: string
  reference: string
  status: string
  errorMessage: ErrorMessage
}

export interface ErrorMessage {
  value: string
}

export const MassPayoutButtonGroup = {
  BATCH_INITIAL: 'BATCH_INITIAL',
  BATCH_UPLOAD: 'BATCH_UPLOAD',
  BATCH_LIST: 'BATCH_LIST',
  BATCH_DETAILS: 'BATCH_DETAILS',
} as const

export type MassPayoutButtonGroupType =
  (typeof MassPayoutButtonGroup)[keyof typeof MassPayoutButtonGroup]

export interface UpdateCryptoPayoutFormPayload {
  amount: string
  currency: string
  protocol: string
  recipientAddress: string
  tag: string
  reference: string
}

export type UpdateCryptoPayoutFormPayloadKeys = keyof UpdateCryptoPayoutFormPayload

export type UpdateCryptoPayoutFormErrors = Partial<FieldErrorsImpl<UpdateCryptoPayoutFormPayload>>

export interface BatchSummaryInformation {
  reference?: string
  status: string
  numberOfPayments?: string
  totalBatchAmount?: string
  totalBatchFees?: string
  totalBatchAmountWithFees?: string
  walletName?: string
  currency?: string
  numberOfErrors?: number
}

export interface GetEditBeneficiaryPayloadProps {
  amount: string
  type: string
  transferDestination: string
  beneficiaryDetails: EnrichedBeneficiary
  businessName?: string
  accountNumber: string
  bankCode: string
  bankCountry: string
  paymentReference: string
  dateOfBirth?: string
  firstName?: string
  lastName?: string
  recordReference: string
}
