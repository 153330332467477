import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Currencies } from 'types/types'

interface CurrencyReferenceLimits {
  [property: string]: { min: number; max: number }
}

export const REFERENCE_VALIDATION_PATTERN = /^[a-zA-Z0-9\s]*$/
export const currencyReferenceLimits: CurrencyReferenceLimits = {
  [Currencies.EUR]: { min: 5, max: 70 },
  [Currencies.GBP]: { min: 5, max: 18 },
  default: { min: 5, max: 20 },
}

export const createReferenceSchema = (
  label: string,
  t: TFunction<'translation', undefined>,
  isRequired = false
) => {
  const schema = yup
    .string()
    .trim()
    .label(label)
    .test({
      test(value, ctx) {
        const limits =
          currencyReferenceLimits[ctx?.parent?.currencyCode] || currencyReferenceLimits?.default

        if (value) {
          if (value.length < limits?.min || value.length > limits?.max) {
            return ctx.createError({
              message: t('referenceFieldInvalidLength', { min: limits?.min, max: limits?.max }),
            })
          }

          if (!REFERENCE_VALIDATION_PATTERN.test(value)) {
            return ctx.createError({
              message: t('referenceFieldInvalidCharacters', {
                field: label,
              }),
            })
          }

          return true
        } else if (isRequired) {
          return ctx.createError({
            message: t('fieldRequired', { field: t('beneficiaryPaymentReference') }),
          })
        } else {
          return ctx.schema.notRequired()
        }
      },
    })

  return schema
}
