import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import Icon from '@node-space/storybook-components/dist/Icon'
import { AlignActions, ModalActions } from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import Text from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { MerchantType } from 'types/types'

interface MerchantDetailsProps {
  merchant: MerchantType
  handleCloseModal: () => void
  successMessage: string
}

export const MerchantDetails = ({
  handleCloseModal,
  merchant,
  successMessage,
}: MerchantDetailsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Box className="flex flex-col items-center justify-center p-4 gap-2">
        <Icon name="ClapIcon" color="status-success-500" size="lg" />
        <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
          {successMessage}
        </Text>
      </Box>
      <Box flex flexCol>
        <SummaryDetail>
          <SummaryRow
            label={t('merchantName')}
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {merchant?.displayName}
                </Text>
              </Box>
            }
          />
          <SummaryRow
            label={t('currency')}
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {merchant?.wallet?.currency?.name}
                </Text>
              </Box>
            }
          />
          <SummaryRow
            label={t('merchantId')}
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {merchant?.merchantId}
                </Text>

                <CopyButton
                  copyText={String(merchant?.merchantId)}
                  confirmationMessage={t('copiedToClipboard')}
                />
              </Box>
            }
          />
          <SummaryRow
            label={t('secretKey')}
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {ellipseMiddleText(merchant?.secret, 25, 9)}
                </Text>
                {!!merchant?.secret && (
                  <CopyButton
                    copyText={String(merchant?.secret)}
                    confirmationMessage={t('copiedToClipboard')}
                  />
                )}
              </Box>
            }
          />
          {merchant?.webhookUrl && (
            <SummaryRow
              label={t('webhookUrl')}
              renderDescription={
                <Box className="flex flex-row justify-between">
                  <Text size="sm" color="grey">
                    {merchant.webhookUrl || '-'}
                  </Text>

                  {!!merchant?.webhookUrl && (
                    <CopyButton
                      copyText={String(merchant?.webhookUrl)}
                      confirmationMessage={t('copiedToClipboard')}
                    />
                  )}
                </Box>
              }
            />
          )}
        </SummaryDetail>
      </Box>
      <ModalActions
        actions={[
          {
            children: t('done'),
            onClick: handleCloseModal,
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </>
  )
}
