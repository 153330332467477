import React, { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { ConnectionStatusTypes } from 'constants/bvnkNetwork'
import { NetworkConnectionViewType } from 'types/bvnkNetwork'
import { getConnections } from 'utils/bvnkNetwork'
import {
  bvnkNetworkConnectionsAtom,
  isFetchingConnectionsAtom,
  isFetchingConnectionsErrorAtom,
} from '../atoms/bvnkNetworkAtoms'
import { ActionButtons } from './ActionButtons'
import { ConnectionConnectionMenu } from './ConnectionContextMenu'
import { ConnectionItem } from './ConnectionsListItem'
import { ConnectionsTabContent } from './ConnectionsTabContent'

export interface ConnectionsTabProps {
  networkConnectionViewType: NetworkConnectionViewType
}

const ConnectionsTab = ({ networkConnectionViewType }: ConnectionsTabProps) => {
  const { t } = useTranslation()
  const bvnkNetworkConnections = useAtomValue(bvnkNetworkConnectionsAtom)
  const isFetchingConnections = useAtomValue(isFetchingConnectionsAtom)
  const isFetchingConnectionsError = useAtomValue(isFetchingConnectionsErrorAtom)
  const [connectionList, setConnectionList] = useState<ConnectionItem[]>([])

  useEffect(() => {
    if (bvnkNetworkConnections) {
      const networkConnections = getConnections(bvnkNetworkConnections, networkConnectionViewType)

      const list = networkConnections?.map(connection => {
        const isDeletable = connection?.connectionStatus === ConnectionStatusTypes.CONNECTED

        return {
          connectionId: connection?.id,
          name: connection?.details?.name,
          description: connection?.details?.description,
          icon: <Icon size="default" name="ProfileIcon" />,
          actions: (
            <ActionButtons
              connection={connection}
              networkConnectionViewType={networkConnectionViewType}
            />
          ),
          contextMenu: isDeletable && <ConnectionConnectionMenu connection={connection} />,
        }
      })

      setConnectionList(list)
    } else {
      setConnectionList([])
    }
  }, [bvnkNetworkConnections])

  return isFetchingConnections ? (
    <Box flex centerChildren width="full" paddingT={28}>
      <Loader size="medium" />
    </Box>
  ) : (
    <>
      {isFetchingConnectionsError ? (
        <Box width="full" padding={20}>
          <Callout message={t('bvnkNetwork.connectionsListError')} state="error" />
        </Box>
      ) : (
        <Box width="full" paddingT={4}>
          <ConnectionsTabContent
            connectionList={connectionList}
            networkConnectionViewType={networkConnectionViewType}
          />
        </Box>
      )}
    </>
  )
}

export default ConnectionsTab
