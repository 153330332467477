import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@node-space/hooks'
import { PathNames } from 'constants/General'
import { isTestEnvironment } from 'utils/utils'

export const useAuthQuery = () => {
  const { isAuthed } = useAuth()
  const location = useLocation()

  const restricedPaths = [PathNames.LOGIN, PathNames.LOGOUT]

  const pathname = location?.pathname
  const isRestrictedPath = restricedPaths?.includes(pathname)

  const isQueryAllowed = useMemo(
    () => !isRestrictedPath && (isAuthed || isTestEnvironment),
    [isRestrictedPath, isAuthed, isTestEnvironment]
  )

  return { isQueryAllowed }
}
