import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { ApiKeyModalContext } from '../../context/ApiKeyModalProvider'

const ApiKeysNoData = () => {
  const { t } = useTranslation()
  const { setShowCreateModal, setApiKeyData } = useContext(ApiKeyModalContext)
  const { twoFactorEnabled, setShowTwoFAModal } = useTwoFAContext()
  const permissions = useMappedRoles().manageApiIntegration
  const canEdit = permissions?.canEdit

  const generateButtonAction = () => {
    twoFactorEnabled ? setShowCreateModal(true) : setShowTwoFAModal(true)
    setApiKeyData(null)
  }
  return (
    <StatusCard
      button={
        canEdit &&
        twoFactorEnabled && {
          onClick: () => generateButtonAction(),
          text: t('manageApiKeys.generateKey'),
        }
      }
      headline={t('manageApiKeys.emptyStateNoApiKey')}
    >
      <Text size="sm" color="grey" className="flex-auto whitespace-pre-line text-center">
        {twoFactorEnabled
          ? t('manageApiKeys.emptyStateNoAPIKeysNotice')
          : t('manageApiKeys.twoFADisabled')}

        <a
          href={process.env.API_KEYS_LEARN}
          className="ml-1 text-purple-500 text-sm"
          target="_blank"
          rel="noreferrer"
        >
          {t('learnMore')}
        </a>
      </Text>
    </StatusCard>
  )
}

export default ApiKeysNoData
