import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Input } from '@node-space/storybook-components/dist/Input'
import {
  AlignActions,
  ModalActions,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { SuccessContainer } from '@node-space/storybook-components/dist/SuccessContainer'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useCreateAccountUserMutation } from 'hooks/mutations/useCreateAccountUserMutation'
import { useUpdateAccountUserMutation } from 'hooks/mutations/useUpdateAccountUserMutation'
import { UserGroups } from 'pages/TeamMembers/const'
import { AccountUser, AccountUserGroup, UserGroupType } from 'types/accounts'
import { FormFieldsAccountUser } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track, { Amp } from 'utils/tracker'

type TeamMemberEditorProps = {
  handleCloseModal: () => void
  getValues: () => void
  actionName: string
  submitBtnText: string
  accountUser?: AccountUser
  userGroups?: AccountUserGroup[]
  action: 'EDIT' | 'CREATE'
}

export const TeamMemberEditor = ({
  handleCloseModal,
  getValues,
  actionName,
  submitBtnText,
  accountUser,
  userGroups,
}: TeamMemberEditorProps) => {
  const { t } = useTranslation()

  const existingUser = accountUser?.groups?.[0]?.id?.toString() || ''

  const [errors, setErrors] = useState(null)
  const [formData, setFormData] = useState({
    emailAddress: accountUser?.emailAddress || '',
    groups: accountUser?.groups || [],
  })
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(existingUser)

  const { mutate: mutateCreateUser, isPending: isLoadingCreateUser } =
    useCreateAccountUserMutation()
  const { mutate: mutateUpdateUser, isPending: isLoadingUpdateUser } =
    useUpdateAccountUserMutation()

  useEffect(() => {
    if (!existingUser) {
      Amp.track(AmplitudeEvent.ADD_TEAM_MEMBER_INITIATE, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
    if (!!existingUser) {
      Amp.track(AmplitudeEvent.EDIT_TEAM_MEMBER_INITIATE, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
  }, [])

  const mapTranslationsSubText = (groupName: UserGroupType) => {
    switch (UserGroups[groupName]) {
      case UserGroups['Workspace Owner']:
        return t('teamMember.workspaceOwnerSubText')
      case UserGroups.Admin:
        return t('teamMember.adminSubText')
      case UserGroups.Collaborator:
        return t('teamMember.collaboratorSubText')
      case UserGroups['Pay-In Only']:
      case UserGroups['Pay-In Only ']:
        return t('teamMember.payInSubText')
      case UserGroups.Viewer:
        return t('teamMember.viewerSubText')
      case UserGroups.Compliance:
        return t('teamMember.complianceSubText')
      case UserGroups['Compliance-Plus']:
        return t('teamMember.compliancePlusSubText')
      default:
        return ''
    }
  }

  const validate = () => {
    setErrors(null)
    const tempErrorList = {}

    if (formData.emailAddress?.length < 1) {
      tempErrorList['emailAddress'] = {
        status: true,
        message: t('emailRequired'),
      }
    } else {
      const validEmail = new RegExp(/^[^@]+@[^@]+\.[^@]+$/)
      if (!validEmail.test(formData.emailAddress)) {
        tempErrorList['emailAddress'] = {
          status: true,
          message: t('validEmailRequired'),
        }
      }
    }

    if (formData.groups?.length < 1) {
      tempErrorList['groups'] = {
        status: true,
      }
    }

    let hasErrors = false
    for (const property in tempErrorList) {
      if (tempErrorList[property].status === true) {
        hasErrors = true
      }
    }

    setErrors(tempErrorList)

    return hasErrors
  }

  const onSubmit = () => {
    const hasErrors = validate()
    if (!hasErrors) {
      saveUser(formData)
    }
  }

  const onCheckboxChange = (id: number) => {
    const foundItem = formData.groups.find(g => g.id === id)
    let newGroups: AccountUserGroup[]

    if (foundItem) {
      newGroups = formData.groups.filter(g => g.id !== id)
    } else {
      const newItem = userGroups.find(item => item.id === id)
      newGroups = [...formData.groups, newItem]
    }

    setFormData({ ...formData, groups: newGroups })
  }

  const onChange = e => {
    setFormData({ ...formData, emailAddress: e.target.value })
  }

  const onRadioChange = id => {
    const foundItem = Array(1).fill(userGroups.find(g => g.id == id))

    setFormData({
      ...formData,
      groups: foundItem,
    })
    setSelectedRadioOption(id)
  }

  const radioOptions = userGroups
    ?.filter(userGroup => userGroup?.name !== Approval.ACCOUNT_TRANSACTION_APPROVER)
    ?.map(group => {
      return {
        label: group?.name,
        value: `${group?.id}`,
        secondaryLabel: mapTranslationsSubText(group?.name),
      }
    })

  const createNewUser = (formData: FormFieldsAccountUser) => {
    mutateCreateUser(formData, {
      onSuccess: () => {
        setShowSuccess(true)
        track.Amp.track(AmplitudeEvent.EOF_ADD_TEAM_MEMBER_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
        })
      },
      onError: (error: Error) => {
        logSentryError(`Error from TeamMemberEditor - createNewUser`, error)
        setErrorMessage(error?.message || t('defaultAddTeamMemberError'))
      },
    })
  }

  const updateExistingUser = (formData: FormFieldsAccountUser) => {
    mutateUpdateUser(
      { userID: accountUser?.id, formData },
      {
        onSuccess: () => {
          setShowSuccess(true)
          track.Amp.track(AmplitudeEvent.EOF_EDIT_TEAM_MEMBER_SUCCESS, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.VIEW,
          })
        },
        onError: (error: Error) => {
          logSentryError(`Error from TeamMemberDelete - updateExistingUser`, error)
          setErrorMessage(error?.message || t('defaultEditTeamMemberError'))
        },
      }
    )
  }

  const saveUser = (formData: FormFieldsAccountUser) => {
    if (!!accountUser) {
      updateExistingUser(formData)
    } else {
      createNewUser(formData)
    }
  }

  const onContinueClick = () => {
    handleCloseModal()
    setErrorMessage(null)
    getValues()
  }

  return showSuccess ? (
    <>
      <SuccessContainer
        headline={
          accountUser ? t('teamMember.editSuccessHeading') : t('teamMember.addSuccessHeading')
        }
        description={
          accountUser ? t('teamMember.editSuccessSubheading') : t('teamMember.addSuccessSubheading')
        }
      />
      <ModalActions
        actions={[
          {
            children: t('done'),
            testid: 'submit-button',
            onClick: onContinueClick,
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </>
  ) : (
    <>
      <ModalNavBar title={actionName} onClose={handleCloseModal} />
      <>
        <div className="p-5">
          <div className="mb-2">
            <Input
              label={t('teamMemberEmailAddress')}
              placeholder={t('teamMemberEmailAddressPlaceholder')}
              name="emailAddress"
              testid="email-address"
              disabled={!!accountUser}
              onChange={onChange}
              value={formData.emailAddress}
              error={errors?.emailAddress?.status}
              errorText={errors?.emailAddress?.status && errors?.emailAddress.message}
              required
            />
          </div>
          <div className="mb-6">
            <div className="space-y-8">
              <Radio
                name="groupOne"
                onChange={onRadioChange}
                options={radioOptions}
                value={selectedRadioOption}
              />
              <Callout state="info">
                <p>
                  {t('teamMember.permissionsCalloutPartOne')}{' '}
                  <a className="underline" href={process.env.ROLES_FAQ}>
                    {t('teamMember.permissionsCalloutPartTwo')}
                  </a>{' '}
                  {t('teamMember.permissionsCalloutPartThree')}
                </p>
              </Callout>
            </div>
          </div>

          {errors?.groups?.status && <div className="mt-1 text-red-25">{t('selectOneOption')}</div>}
          {!!errorMessage && <div className="mt-1 text-red-25 error-text">{errorMessage}</div>}
        </div>

        <ModalActions
          actions={[
            {
              children: t('cancel'),
              onClick: handleCloseModal,
              secondary: true,
            },
            {
              children: submitBtnText,
              testid: 'submit-button',
              loading: isLoadingCreateUser || isLoadingUpdateUser,
              onClick: onSubmit,
            },
          ]}
        />
      </>
    </>
  )
}
