import { useQuery } from '@tanstack/react-query'
import { useAuthQuery } from 'hooks/useAuthQuery'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getVerificationRequestStatus } from 'services/AccountService'
import { ONE_DAY } from 'utils/utils'

export const useVerificationRequestStatusQuery = (isEnabled = true) => {
  const { isQueryAllowed } = useAuthQuery()

  return useQuery({
    queryKey: reactQueryKeys.verificationRequestStatus(),
    queryFn: getVerificationRequestStatus,
    enabled: isEnabled && isQueryAllowed,
    staleTime: ONE_DAY,
  })
}
