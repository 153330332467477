import { requestApi } from 'api/api'
import { v4 as uuidv4 } from 'uuid'
import { NetworkTransferRequest } from 'types/bvnkNetwork'
import { PayoutsV2PreAuthResponse } from 'types/payoutsV2'

export const postBvnkNetworkTransferRequest = (
  request: NetworkTransferRequest
): Promise<PayoutsV2PreAuthResponse> => {
  return requestApi({
    url: '/v1/network/transfer',
    method: 'POST',
    data: request,
    headers: {
      'x-idempotency-key': uuidv4(),
    },
  })
}
