import { useMemo } from 'react'
import { useLocalStorage } from '@node-space/hooks'
import { isLocalStorageAvailable } from 'utils/utils'
import { StepKeys, StepStates } from 'pages/Dashboard/constants'
import { StepKey, StepState } from 'pages/Dashboard/types'

const StepList: StepKey[] = Object.values(StepKeys) // StepKeys is a constant which means that StepList won't change

export const useDevXGetStartedStepTracking = (canViewApiKeys: boolean) => {
  const applyStepTracking = isLocalStorageAvailable()

  const filteredStepList: StepKey[] = canViewApiKeys
    ? StepList
    : StepList?.filter((stepKey: StepKey) => stepKey !== StepKeys.CREATE_API_KEY)

  const defaultCompletedSteps: StepKey[] = []

  const [devXCompletedSteps, setDevXCompletedSteps] = applyStepTracking
    ? useLocalStorage('devXCompletedSteps', defaultCompletedSteps)
    : []

  const completedSteps: StepKey[] = useMemo(
    () => (applyStepTracking ? (devXCompletedSteps as StepKey[]) : defaultCompletedSteps),
    [devXCompletedSteps]
  )

  const isStepCompleted = (stepKey: StepKey): boolean => completedSteps?.includes(stepKey)

  const pendingStepKey: StepKey = filteredStepList?.find(
    (stepKey: StepKey) => !isStepCompleted(stepKey)
  )

  const getStepState = (stepKey: StepKey): StepState => {
    if (isStepCompleted(stepKey)) return StepStates.SUCCESS
    if (stepKey === pendingStepKey) return StepStates.INFO
    return null
  }

  const onCompleteStep = (stepKey: StepKey) => {
    if (!applyStepTracking) return

    if (!isStepCompleted(stepKey)) {
      setDevXCompletedSteps(current => [...current, stepKey])
    }
  }

  const onCompleteSteps = (stepKeys: StepKey[]) => {
    if (!applyStepTracking) return

    const updateSteps: StepKey[] = []

    stepKeys.forEach((stepKey: StepKey) => {
      if (!isStepCompleted(stepKey)) {
        updateSteps.push(stepKey)
      }
    })

    if (updateSteps?.length) {
      setDevXCompletedSteps(current => [...current, ...updateSteps])
    }
  }

  return {
    applyStepTracking,
    completedSteps,
    isStepCompleted,
    getStepState,
    onCompleteStep,
    onCompleteSteps,
  }
}
