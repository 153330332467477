import { useAccountApprovalsPreferenceQuery } from './queries/useAccountApprovalsPreferenceQuery'

export const useApprovalsPreference = (currentAccountReference: string, isEnabled = false) => {
  const {
    data: approvalFlowPreferences,
    isError: isApprovalPreferenceError,
    isSuccess: isApprovalPreferenceSuccess,
    isFetching: isFetchingApprovalPreference,
  } = useAccountApprovalsPreferenceQuery(currentAccountReference, isEnabled)

  return {
    approvalFlowPreferences,
    isApprovalPreferenceError,
    isApprovalPreferenceSuccess,
    isFetchingApprovalPreference,
  }
}
