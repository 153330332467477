export interface CustomerDetails {
  type: string
  registrationNumber: string
  taxResidence: string
  address: string
  name: string
  representativeName: string
}

export const AssignedColors = {
  BLUE: '#3850DD',
  PINK: '#FF697D',
  GREEN: '#3BDABA',
  ORANGE: '#FFA300',
  PURPLE: '#8E41E8',
} as const

export type AssignedColorType = (typeof AssignedColors)[keyof typeof AssignedColors]

export const ModalStep = {
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  CONFIRMATION: 'CONFIRMATION',
} as const

export type ModalStep = (typeof ModalStep)[keyof typeof ModalStep]
