import React, { useEffect } from 'react'
import { isEmpty } from 'lodash-es'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Select } from '@node-space/storybook-components/dist/Select'
import Text from '@node-space/storybook-components/dist/Text'
import { useRecurringExportContext } from 'hooks/context/useRecurringExportContext'
import { useReportScheduleTimezonesQuery } from 'hooks/queries/useReportScheduleTimezonesQuery'
import { exportFormatRadioOptions } from 'pages/WalletDetails/constants/walletTransactionsExportOptions'
import {
  ExportFileFormatType,
  ReportScheduleErrorList,
  ReportScheduleFrequency,
  ReportScheduleFrequencyType,
} from 'types/walletTransactionsExport'
import { getUserDefaultTimezone } from 'utils/walletTransactionsExport'
import { daysOfWeek, timesOfDay } from '../constants/constants'

interface RecurringExportFormType {
  downloadAll: boolean
}

export const RecurringExportForm = ({ downloadAll }: RecurringExportFormType) => {
  const { t } = useTranslation()
  const {
    form,
    isErrorPostReportSchedule,
    postReportScheduleError,
    isUpdatingReportSchedule,
    currentReportSchedule,
    isErrorPutReportSchedule,
    putReportScheduleError,
  } = useRecurringExportContext()

  const exportFormatOptions = exportFormatRadioOptions()

  const postErrorList = postReportScheduleError?.data?.details?.errors as ReportScheduleErrorList
  const putErrorList = putReportScheduleError?.data?.details?.errors as ReportScheduleErrorList

  const getErrorMessage = () => {
    if (!isEmpty(postErrorList?.createOrUpdateReportScheduleRequest)) {
      return postErrorList?.createOrUpdateReportScheduleRequest?.[0]
    }
    if (!isEmpty(putErrorList?.createOrUpdateReportScheduleRequest)) {
      return putErrorList?.createOrUpdateReportScheduleRequest?.[0]
    }

    return isErrorPutReportSchedule
      ? t('exports.updateReportScheduleError')
      : t('exports.createReportScheduleError')
  }

  const {
    control,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = form
  const formValues = useWatch({ control })

  const { data: timezones } = useReportScheduleTimezonesQuery(true)

  const timezoneOptions = timezones?.map(timezone => ({
    label: timezone?.label,
    value: timezone?.zoneId,
  }))

  useEffect(() => {
    if (isUpdatingReportSchedule) {
      setValue('frequencyType', currentReportSchedule?.frequencyType)
      setValue('frequencyDay', String(currentReportSchedule?.frequencyDay))
      setValue('time', currentReportSchedule?.time)
      setValue('timezone', currentReportSchedule?.timezone)
      setValue('format', currentReportSchedule?.format)
    } else {
      const userDefaultTimezone = getUserDefaultTimezone(timezones)
      setValue('timezone', userDefaultTimezone?.zoneId)
    }
  }, [isUpdatingReportSchedule, timezones])

  return (
    <Box flex direction="col" gapY={16} tag="form" paddingX={28}>
      <Radio
        {...register('frequencyType')}
        onChange={value => {
          setValue('frequencyType', value as ReportScheduleFrequencyType)
        }}
        value={formValues?.frequencyType}
        horizontal
        borderless={false}
        options={[
          { value: ReportScheduleFrequency.DAILY, label: t('exports.daily') },
          { value: ReportScheduleFrequency.WEEKLY, label: t('exports.weekly') },
          { value: ReportScheduleFrequency.MONTHLY, label: t('exports.monthly') },
        ]}
      />

      {formValues?.frequencyType === ReportScheduleFrequency.WEEKLY && (
        <Select
          {...register('frequencyDay')}
          label={t('exports.dayOfTheWeek')}
          name="dayOfTheWeek"
          options={daysOfWeek}
          onChange={value => {
            setValue('frequencyDay', value as ReportScheduleFrequencyType)
          }}
          value={formValues?.frequencyDay}
          error={!!errors?.frequencyDay}
          errorText={errors?.frequencyDay?.message}
        />
      )}

      <Box flex width="full" gapX={8}>
        <Box className="flex-1">
          <Select
            {...register('time')}
            label={t('exports.sendAt')}
            name="sendAt"
            options={timesOfDay}
            onChange={value => {
              clearErrors('time')
              setValue('time', value)
            }}
            value={formValues?.time}
            error={!!errors?.time}
            errorText={errors?.time?.message}
          />
        </Box>

        <Box className="flex-[2_1_0%]">
          <Select
            {...register('timezone')}
            label={t('exports.timezone')}
            name="timezone"
            options={timezoneOptions}
            onChange={value => {
              clearErrors('timezone')
              setValue('timezone', value)
            }}
            value={formValues?.timezone}
            error={!!errors?.timezone}
            errorText={errors?.timezone?.message}
          />
        </Box>
      </Box>

      {!downloadAll && (
        <Box paddingT={4}>
          <Text className="ml-4">{t('walletsPage.exportFormat')}</Text>
          <Radio
            {...register('format')}
            horizontal
            onChange={value => {
              setValue('format', value as ExportFileFormatType)
            }}
            options={exportFormatOptions}
            value={formValues?.format}
          />
        </Box>
      )}

      {(isErrorPostReportSchedule || isErrorPutReportSchedule) && (
        <Callout state="error" message={getErrorMessage()} />
      )}
    </Box>
  )
}
