import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { SidePanel, SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { appBannersId } from 'components/app/AppBanners'
import { useGetCryptoMassPayoutById } from 'hooks/queries/MassPayouts/useGetCryptoMassPayoutById'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { highlightRowBasedOnId } from 'utils/table/table'
import { MassPayoutCryptoBatch } from '../../types'
import { MassPayoutListSidePanelBody } from '../MassPayoutListSidePanelBody'

export type MassPayoutListSummaryProps = {
  isOpen: boolean
  massPayoutDetails: MassPayoutCryptoBatch
  pageMassPayout: PagingSidePanelType
  onClose: () => void
}

export type SidePanelBodyDataProps = {
  massPayoutDetails: MassPayoutCryptoBatch
  country: string
}

export const CryptoBatchSidePanel = ({
  isOpen,
  massPayoutDetails,
  pageMassPayout,
  onClose,
}: MassPayoutListSummaryProps) => {
  const { t } = useTranslation()
  const bannerSpacing = useComponentHeight(appBannersId)

  const {
    data: cryptoBatch,
    isPending,
    isError,
  } = useGetCryptoMassPayoutById(massPayoutDetails?.externalId, !!massPayoutDetails?.externalId)

  const numberOfProcessedPayments = cryptoBatch?.totalProcessedItems
  const numberOfPayments = cryptoBatch?.totalItems
  const fileName = cryptoBatch?.fileName
  const dateCreated = dayjs(cryptoBatch?.createdAt).format('DD MMMM YYYY')
  const uploadedBy = cryptoBatch?.userName

  const paidFromWallet = `${cryptoBatch?.merchantName} (${cryptoBatch?.walletCurrency})`

  return (
    <SidePanel
      isOpen={isOpen}
      paging={pageMassPayout}
      onClose={onClose}
      activeRowId={massPayoutDetails?.externalId}
      onActiveRowChange={highlightRowBasedOnId}
      externalStyle={{ top: `${bannerSpacing}px` }}
      sidePanelHeader={{
        customSidePanelHeader: isPending ? (
          <Skeleton className="w-full" variant="input" />
        ) : (
          <SidePanelHeader
            heading={t('massPayouts.numberOfPayoutsProcessed', {
              numberOfProcessedPayments: numberOfProcessedPayments,
              numberOfPayments: numberOfPayments,
            })}
            description={fileName}
            template={3}
          />
        ),
      }}
    >
      <Box padding={24} tag="section" width="full" background="white">
        {isPending ? (
          <Skeleton className="w-full" variant="input" />
        ) : (
          <MassPayoutListSidePanelBody
            dateCreated={dateCreated}
            uploadedBy={uploadedBy}
            paidFromWallet={paidFromWallet}
          />
        )}
      </Box>
    </SidePanel>
  )
}
