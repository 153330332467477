import React, { createContext, useMemo, useState } from 'react'

export const PaymentCreateModalContext = createContext(null)

export const PaymentCreateModalProvider = ({ children, condition = false }) => {
  const [showCreateModal, setShowCreateModal] = useState(condition || false)
  const value = useMemo(() => [showCreateModal, setShowCreateModal], [showCreateModal])

  return (
    <PaymentCreateModalContext.Provider value={value}>
      {children}
    </PaymentCreateModalContext.Provider>
  )
}
