import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postMassCryptoPayouts } from 'services/MasspayoutService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useMassCryptoPayoutsMutation = () =>
  useMutation({
    mutationFn: postMassCryptoPayouts,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useMassCryptoPayoutsMution', error, data)
      }
    },
  })
