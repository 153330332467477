import { useQuery } from '@tanstack/react-query'
import { useAuthQuery } from 'hooks/useAuthQuery'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getProfile } from 'services/AuthService'

export const useProfileQuery = () => {
  const { isQueryAllowed } = useAuthQuery()

  return useQuery({
    queryKey: reactQueryKeys.profile(),
    queryFn: getProfile,
    enabled: isQueryAllowed,
  })
}
