import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAccountUsers } from 'services/AccountService'

export const useAccountUsersQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.accountUsers(),
    queryFn: getAccountUsers,
  })
}
