import React from 'react'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Box, Box as InfoTitle, Box as Section } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { Description } from 'components/text/Description'
import { ModulrCountriesType } from '../types'

export interface ModulrAgreementProps {
  country: ModulrCountriesType
}

export const ModulrAgreement = ({ country }: ModulrAgreementProps) => {
  const { t } = useTranslation()

  return (
    <Box flex direction="col" gapY={16}>
      <Section flex direction="col" gapY={4}>
        <InfoTitle flex gapX={4}>
          <Text tag="h4" size="sm" weight="semibold">
            {t(`modulrAgreement.title`, { country })}{' '}
          </Text>
          <Tooltip
            position="bottom"
            hasMaxWidth
            bodyContent={
              <Text size="xs" color="inherit">
                {t(`modulrAgreement.descriptons.${country}.tooltip`)}
              </Text>
            }
          >
            <Icon name="InfoCircleIcon" color={secondaryButtonIconColour} />
          </Tooltip>
        </InfoTitle>
        <Description>{t(`modulrAgreement.descriptons.${country}.parapraph1`)}</Description>
      </Section>
      <Section flex direction="col" gapY={4}>
        <Text tag="h4" size="sm" weight="semibold">
          {t(`modulrAgreement.areFundsSafeguarded`)}
        </Text>
        <Description>{t(`modulrAgreement.descriptons.${country}.parapraph2`)}</Description>
      </Section>
    </Box>
  )
}
