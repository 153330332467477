import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input } from '@node-space/storybook-components/dist'
import { AddBeneficiaryFormErrors, BaseFormProps, ValidateIbanResponse } from 'types/beneficiaries'

interface EurFormFieldsProps extends BaseFormProps {
  bic: string
  errors: AddBeneficiaryFormErrors
  iban: string
  isValidatingIban: boolean
  isSepaSupported: boolean
  ibanValidationData?: ValidateIbanResponse
  onIbanInputBlur: () => void
}

export const EurFormFields = ({
  bic,
  errors,
  iban,
  isValidatingIban,
  ibanValidationData,
  isSepaSupported,
  handleChange,
  register,
  onIbanInputBlur,
}: EurFormFieldsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box paddingB={16}>
        <Input
          {...register('iban')}
          label={t('iban')}
          placeholder={t('enterAccountNumber')}
          inputMode="text"
          name="iban"
          testid="input-iban"
          errorText={errors?.iban?.message}
          error={!!errors?.iban}
          value={iban}
          onChange={event => handleChange('iban', event?.target?.value)}
          onBlur={onIbanInputBlur}
          disabled={isValidatingIban}
          isValid={!!ibanValidationData?.bankAccount?.accountNumber && isSepaSupported && !ibanValidationData?.validationFailed.length}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('bic')}
          label={t('bic')}
          placeholder={t('enterCode')}
          inputMode="text"
          name="bic"
          testid="input-bic"
          errorText={errors?.bic?.message}
          error={!!errors?.bic}
          value={bic}
          onChange={event => handleChange('bic', event?.target?.value)}
          disabled={isValidatingIban || !!ibanValidationData?.bankAccount?.bankCode}
        />
      </Box>
    </>
  )
}
