import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCapabilityValue } from 'services/CapabilitiesService'
import { CapabilitiesRequest } from 'types/capabilities/capabilities'
import { QueryMetaType } from 'types/reactQuery'

export const useCapabiltiesQuery = (
  { group, name, scope, subjectId }: CapabilitiesRequest,
  enabled?: boolean
) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { group, name, scope, subjectId },
    excludedErrorCodes: [404],
  }

  return useQuery({
    queryKey: reactQueryKeys.capabilities(group, name, scope, subjectId),
    queryFn: () => getCapabilityValue({ group, name, scope, subjectId }),
    enabled,
    meta,
  })
}
