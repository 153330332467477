import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultLoginRedirect, RedirectPathStateKey } from 'constants/General'

export const useAuthenticatedPathRedirect = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const redirectToLanding = () => {
    const redirectPath = state?.[RedirectPathStateKey]
    navigate(redirectPath ?? DefaultLoginRedirect)
  }

  const redirectWithState = (path: string) => {
    navigate(path, { state })
  }

  return {
    redirectToLanding,
    redirectWithState,
  }
}
