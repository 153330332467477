import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postAddCustomer } from 'services/CustomerService'
import { ErrorResponse } from 'types/types'

export const useCustomerMutation = () => {
  return useMutation({
    mutationFn: postAddCustomer,
    onError: (error: ErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useCustomerMutation', error, data)
      }
    },
  })
}
