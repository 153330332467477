import React from 'react'

export default (
  <svg
    className="animate-spin"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3329 1.66651C11.3329 1.20627 10.9598 0.833172 10.4996 0.833172C10.0394 0.833172 9.66626 1.20627 9.66626 1.66651V4.99984C9.66626 5.46008 10.0394 5.83317 10.4996 5.83317C10.9598 5.83317 11.3329 5.46008 11.3329 4.99984V1.66651ZM11.3329 15C11.3329 14.5398 10.9598 14.1667 10.4996 14.1667C10.0394 14.1667 9.66626 14.5398 9.66626 15V18.3333C9.66626 18.7936 10.0394 19.1667 10.4996 19.1667C10.9598 19.1667 11.3329 18.7936 11.3329 18.3333V15ZM4.01874 3.51914C4.34417 3.19371 4.87181 3.19371 5.19725 3.51914L7.55558 5.87748C7.88102 6.20291 7.88102 6.73055 7.55558 7.05599C7.23014 7.38143 6.70251 7.38143 6.37707 7.05599L4.01874 4.69766C3.6933 4.37222 3.6933 3.84458 4.01874 3.51914ZM14.6223 12.9439C14.2969 12.6185 13.7692 12.6185 13.4438 12.9439C13.1183 13.2694 13.1183 13.797 13.4438 14.1225L15.8021 16.4808C16.1276 16.8062 16.6552 16.8062 16.9806 16.4808C17.3061 16.1554 17.3061 15.6277 16.9806 15.3023L14.6223 12.9439ZM1.33301 10C1.33301 9.53976 1.7061 9.16667 2.16634 9.16667H5.49967C5.95991 9.16667 6.33301 9.53976 6.33301 10C6.33301 10.4602 5.95991 10.8333 5.49967 10.8333H2.16634C1.7061 10.8333 1.33301 10.4602 1.33301 10ZM15.4996 9.16667C15.0394 9.16667 14.6663 9.53976 14.6663 10C14.6663 10.4602 15.0394 10.8333 15.4996 10.8333H18.8329C19.2932 10.8333 19.6663 10.4602 19.6663 10C19.6663 9.53976 19.2932 9.16667 18.8329 9.16667H15.4996ZM7.55558 12.9439C7.88102 13.2694 7.88102 13.797 7.55558 14.1225L5.19725 16.4808C4.87181 16.8062 4.34417 16.8062 4.01874 16.4808C3.6933 16.1554 3.6933 15.6277 4.01874 15.3023L6.37707 12.9439C6.70251 12.6185 7.23014 12.6185 7.55558 12.9439ZM16.9806 4.69766C17.3061 4.37222 17.3061 3.84458 16.9806 3.51914C16.6552 3.19371 16.1276 3.19371 15.8021 3.51914L13.4438 5.87748C13.1183 6.20291 13.1183 6.73055 13.4438 7.05599C13.7692 7.38143 14.2969 7.38143 14.6223 7.05599L16.9806 4.69766Z"
      fill="#7364ff"
    />
  </svg>
)
