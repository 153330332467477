import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAllMonthlyVolumes } from 'services/CustomerService'
import { ONE_DAY } from 'utils/utils'

export const useMonthlyVolumesQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.monthlyVolumes(),
    queryFn: getAllMonthlyVolumes,
    staleTime: ONE_DAY,
  })
}
