import React from 'react'
import { Link } from 'react-router-dom'

export type TextLinkProps = {
  path: string
  text: string
}

export const TextLink = ({ path, text }: TextLinkProps) => {
  return (
    <Link
      to={path}
      className="text-primary-500 hover:underline hover:text-primary-700 active:text-primary-900"
    >
      {text}
    </Link>
  )
}
