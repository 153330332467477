import { isEmpty, isNaN, isNumber, isObject, isString, isUndefined, round } from 'lodash-es'
import { RestApi } from '@node-space/rest-api'
import { NotificationType } from '@node-space/storybook-components/dist/NotificationCard'
import { StateType } from '@node-space/storybook-components/dist/types'
import { floorNumber } from '@node-space/utils'
import General, { CookieTime, PathNames, PathNamesType } from 'constants/General'
import {
  MassPayoutCryptoBatchStatus,
  MassPayoutCryptoBatchStatusType,
} from 'pages/MassPayouts/types'
import { TransformedOption } from 'types/customers'
import { PaymentCaseType, PaymentStatus } from 'types/payments'
import { Transaction, TransactionStatus } from 'types/transaction'
import { TransactionType } from 'types/transaction/TransactionType'
import { AuthToken, ErrorAPI, ExchangeRate } from 'types/types'

export const isTestEnvironment = process.env.NODE_ENV === 'jest'

export const HALF_SECOND = 500 // 500 ms = 0.5 seconds
export const ONE_DAY = 1000 * 60 * 60 * 24 // 86,400,000 ms = 24 hours
export const TEN_MINUTES = 1000 * 60 * 10 // 600,000 ms = 10 mins

const emailDelimiters = [
  '--------------- Original Message ---------------',
  '<https://protect-za.mimecast.com/',
]

export function formatDob(day, month, year) {
  // accepts year XXXX
  // month X or XX
  // day X or XX
  // and formats to YYYY-MM-DD
  // if is able to format, returns result, else returns 'invalid'
  let result = ''
  if (year.length !== 4) {
    return 'invalid'
  }
  const yearString = year + '-'
  let monthString = ''
  switch (month.length) {
    case 1:
      monthString = '0' + month + '-'
      break
    case 2:
      monthString = month + '-'
      break
    default:
      return 'invalid'
  }
  switch (day.length) {
    case 1:
      result = yearString + monthString + '0' + day
      break
    case 2:
      result = yearString + monthString + day
      break
    default:
      return 'invalid'
  }
  return result
}

export function formatCryptoValue(value, currency, precision = 0) {
  if (precision === 0) {
    switch (currency) {
      case 'USDT':
      case 'USDC':
        precision = 2
        break
      case 'BTC':
        precision = 8
        break
      case 'ETH':
        precision = 4
        break
    }
  }

  value = floorNumber(value, precision)

  return numberWithCommas(value)
}

/**
 * MATH: floorWithPrecision - Floors a number down, with a specific precision pointer.
 *  Defaults to 2 decimal points if no PRECISION is added.
 *  Example:
 *  1.2123 with 4 precision points becomes = 1.2123
 *  1.2123 with 2 precision points becomes = 1.21
 *  0.1234 with 8 precision points becomes (or stays) = 0.1234 (doesnt add the extra points)
 * @returns number
 */
export function floorWithPrecision(value: number | string, precision = 2): number | string {
  if (!precision || !isNumber(precision)) {
    precision = 2
  }

  if (isString(value) || isUndefined(value) || isNaN(value) || !isNumber(Number(value))) {
    return value
  }

  const result = floorNumber(Number(value), precision)
  return result
}

export const numberWithCommas = (x: number | string) => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const roundNumberWithCommas = (value: number | string, decimals = 2) => {
  const rounded = (+(Math.round(Number(value + `e+${decimals}`)) + `e-${decimals}`)).toString()
  let formatted = rounded.toString()
  const split = rounded.split('.')

  // pad with 0s if missing
  const padding = split.length === 1 ? decimals : decimals - split[1].length
  if (padding == decimals) {
    formatted += '.'
  }
  for (let i = 0; i < padding; i++) {
    formatted += '0'
  }

  return numberWithCommas(formatted)
}

export const stringToDecimal = (value: string) => {
  return value
    .replace(/[^0-9.]/g, '')
    .replace(/\.{2,}/g, '.')
    .replace(/^0*([^0]\d*\.\d{1,40}).*/g, '$1')
}

export const fancyTimeFormat = (duration: number) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600)
  const mins = ~~((duration % 3600) / 60)
  const secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export const formatString = (input: string, ...args: (string | number)[]) => {
  const replaceArgs = (match, number) => {
    return typeof args[number] != 'undefined' ? args[number] : match
  }
  return input.replace(/{(\d+)}/g, replaceArgs)
}

export const cleanBase64String = (string: string) => {
  const regex = new RegExp(/^data:.+;base64,/)
  const result = string.replace(regex, '')
  return result
}

/**
 * Takes a string and makes it Title case ... `hello` -> `Hello`
 * @returns string
 */
export const titleCase = (str: string): string => {
  if (typeof str !== 'string') {
    return str
  }
  return str
    .toLowerCase()
    .replace(/\b(\w)/g, s => s.toUpperCase())
    .replace(/_/g, ' ')
}

/**
 * Returns a plaintext Reference number like REF12343
 * @returns string
 */
export const generateReference = (): string => `REF${Math.floor(Math.random() * 1000000)}`

/**
 * Takes a string and makes it Sentence case ... `Hello, How Are You` -> `Hello, how are you`
 * @returns string
 */
export const sentenceCase = (str: string): string => {
  if (typeof str !== 'string') {
    return str
  }

  if (
    str.charAt(0) === str.charAt(0).toUpperCase() &&
    str.charAt(1) === str.charAt(1).toUpperCase()
  ) {
    return str
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/**
 * Takes a string and makes it shorter ... `Hello, how are you` -> `Hello...`
 * @returns string
 */
export const shortenString = (str: string, num = 10, ending = '...'): string => {
  if (typeof str !== 'string') {
    return str
  }

  return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + ending : str
}

/**
 * Takes an array of strings and joins it into a comma separated sentance.
 * @returns string
 */
export const arrayListToString = (array: string[], start?: string, end?: string): string => {
  let string = start || ''
  const stop = end || '.'
  array.forEach((item, index) => {
    const isFirst = index === 0
    const isLast = index === array.length - 1
    const prefix = isFirst ? ' ' : isLast ? ' and ' : ', '
    const suffix = isLast ? stop : ''
    const formatted = `${prefix}${item}${suffix}`
    string += formatted
  })
  return string
}

/**
 * Takes a obj and reorganises the KEYS to be alphabetized/ in order
 * (Used for Payload display)
 * @returns object
 */
export const sortObjectPropertiesByName = (obj: Object): Object => {
  if (typeof obj !== 'object') {
    return obj
  }

  return Object.keys(obj)
    .sort()
    .reduce((c, d) => ((c[d] = obj[d]), c), {})
}

/**
 * Copies a string to memory, has a fallback because of Chrome copying issue on local environments
 * that arent running SSL locally
 * @returns object
 */
export const copyToClipboard = (textToCopy: string): Promise<string | void> => {
  //Default: Most browsers
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy)
  } else {
    //Default: for Chrome issues locally
    const textArea = document.createElement('textarea')

    textArea.value = textToCopy
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    return new Promise((res, rej) => {
      document.execCommand('copy') ? res('copied') : rej('failed')
      textArea.remove()
    })
  }
}

/**
 * Sanitizes string from unsafe characters for API
 * @returns string
 */
export const sanitizeUserInput = (str: string) => {
  if (typeof str !== 'string') {
    return str
  }
  const res = str?.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '')
  return res?.trim()
}

/**
 * Returns exchange rate value between 2 currencies. BTC is the base currency for the exchange rates
 */
export const findExchangeRate = (
  exchangeRates: ExchangeRate[],
  fromCurrency: string,
  toCurrency: string
) => {
  let exchangeRate: number
  if (fromCurrency === 'BTC') {
    exchangeRate = 1 / exchangeRates.find(x => x.pair === `${fromCurrency}/${toCurrency}`)?.rate
  } else if (toCurrency === 'BTC') {
    exchangeRate = exchangeRates.find(x => x.pair === `${toCurrency}/${fromCurrency}`)?.rate
  } else {
    const firstPair = exchangeRates.find(x => x.pair === `BTC/${toCurrency}`)
    const secondPair = exchangeRates.find(x => x.pair === `BTC/${fromCurrency}`)
    exchangeRate = secondPair?.rate / firstPair?.rate
  }
  return exchangeRate
}

/**
 * Prefixs string with character to match totalLength
 * e.g. prefixString("eg", "0", 2) -> "0eg"
 * e.g. prefixString("eg", "0", 5) -> "0000eg"
 */
export const prefixString = (string: string | number, prefix: string, totalLength: number) => {
  return (new Array(totalLength + 1).join(prefix) + string).slice(-totalLength)
}

export const checkIfVibanIsInactive = (errorAPI: ErrorAPI) => {
  return errorAPI?.errorList?.some(x => x.code === 'viban_inactive')
}
/**
 * Takes in BIG number and SMALL number and result is percentage difference
 * with 2 decimal places
 * -> up = 100, 5 => '5.00%'
 * -> down = 100, 5 => '95.00%'
 * @returns string
 */
export const calculatePercentageDiff = (
  amountTotal: number,
  amountFraction: number,
  calculateUp: 'paid' | 'remaining' = 'paid',
  withSymbol = true
): string => {
  if (!amountTotal || !amountFraction) return

  let calcPercentage

  if (calculateUp || calculateUp == 'remaining') {
    calcPercentage = (amountFraction / amountTotal) * 100
  }

  if (calculateUp === 'paid') {
    calcPercentage = ((amountTotal - amountFraction) / amountTotal) * 100
  }

  return `${round(calcPercentage, 2)?.toFixed(2)}${withSymbol && '%'}`
}

/**
 * Take in current PaymentStatus and return correct colour state
 * @returns string
 */
export const getStatusState = (type: string): NotificationType => {
  const status = {
    [PaymentStatus.PAID]: NotificationType.SUCCESS,
    [PaymentStatus.COMPLETE]: NotificationType.SUCCESS,
    [PaymentStatus.COMPLETED]: NotificationType.SUCCESS,
    [PaymentStatus.DETECTED]: NotificationType.INFO,
    [PaymentStatus.PENDING]: NotificationType.WARNING,
    [PaymentStatus.PROCESSING]: NotificationType.INFO,
    [PaymentStatus.PROCESSED]: NotificationType.SUCCESS,
    [PaymentStatus.EXPIRED]: NotificationType.ERROR,
    [PaymentStatus.UNDERPAID]: NotificationType.SUCCESS,
    [PaymentStatus.CANCELLED]: NotificationType.ERROR,
    [PaymentStatus.SUCCESS]: NotificationType.SUCCESS,
    [PaymentStatus.SUBMITTED]: NotificationType.SUCCESS,
    [PaymentStatus.DECLINED]: NotificationType.ERROR,
    [PaymentStatus.ERROR]: NotificationType.ERROR,
    [PaymentStatus.FAILED]: NotificationType.ERROR,
    [PaymentStatus.REJECETED]: NotificationType.ERROR,
    [PaymentStatus.PENDING_APPROVAL]: NotificationType.INFO,
    default: NotificationType.WARNING,
  }
  return status[type] || status['default']
}
export const getMassPayoutStatusState = (type: string): NotificationType => {
  const status = {
    [PaymentStatus.COMPLETED]: NotificationType.SUCCESS,
    [PaymentStatus.REQUESTED]: NotificationType.INFO,
    [PaymentStatus.PROCESSING]: NotificationType.INFO,
    [PaymentStatus.CANCELLED]: NotificationType.ERROR,
    [PaymentStatus.CREATED]: NotificationType.INFO,
    [PaymentStatus.DRAFT]: NotificationType.INFO,
    [PaymentStatus.VALID]: NotificationType.SUCCESS,
    [PaymentStatus.VALIDATED]: NotificationType.SUCCESS,
    [PaymentStatus.REVERTED]: NotificationType.ERROR,
    [PaymentStatus.INVALID]: NotificationType.ERROR,
    [PaymentStatus.FAILED]: NotificationType.ERROR,
    default: NotificationType.INFO,
  }
  return status[type] || status['default']
}

/**
 * Deletes profile cookie
 * @returns void
 */
export const deleteProfileCookie = (): void => {
  RestApi.deleteCookie(General.COOKIE_NAME)
}

/**
 * Global Cookie function to maintain session consistency
 * @returns void
 */
export const setAuthCookies = (authToken: AuthToken): void => {
  RestApi.setCookie(General.COOKIE_NAME, { ...authToken }, CookieTime.ONE_HOUR)
  RestApi.setCookie(General.COOKIE_PERMANENT, '1', CookieTime.ONE_DAY)
}

export const setChatbotTokenCookie = (token: string): void => {
  RestApi.setCookie(General.ZENDESK_COOKIE_NAME, token, CookieTime.ONE_HOUR)
}

export const deleteChatbotCookie = (): void => {
  RestApi.deleteCookie(General.ZENDESK_COOKIE_NAME)
}

/**
 * isLocalStorageAvailable -Checks if the localStorage is available and usable in the browser.
 * @returns {boolean} - Returns true if localStorage is available and usable, false otherwise.
 */
export const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = 'test'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Returns url-encoded string from an object
 *
 * @param   {object} obj  Object
 * @return  {string}      String like 'RegisteredName=Adidas&FirstName=Kanye&LastName=West',
 */

export const objectToEncodedString = (obj: Record<string, string | number | boolean>): string => {
  if (!isObject(obj)) {
    return ''
  }

  if (isEmpty(obj)) {
    return ''
  }

  const queryParam = Object.keys(obj)
    .map(key => {
      return `${key}=${encodeURIComponent(obj[key])}`
    })
    .join('&')

  return queryParam
}

/**
 * Masks any given value e.g IBAN/Bank Account Number
 * \u2022 - unicode character for a bullet
 * @param {string} params - e.g 0198594044
 * @returns {string} maskedParams - e.g `••••{last 4 digits/characters}`
 */
export const getMaskedValue = (params: string): String => {
  const bulletSymbol = '\u2022'
  const paramsLength = params?.length

  let maskedParams = ''
  if (paramsLength && paramsLength >= 8) {
    const last8CharOfParams = params.substring(paramsLength - 8)
    maskedParams = last8CharOfParams.slice(-4).padStart(last8CharOfParams.length, bulletSymbol)
  } else if (paramsLength && paramsLength < 8) {
    const last4CharOfParams = params.substring(paramsLength - 4)
    maskedParams = `${bulletSymbol.repeat(4)}${last4CharOfParams}`
  } else {
    maskedParams = params
  }

  return maskedParams
}

/**
 * Clean window.location route logout
 * @returns {void}
 */
export const logout = (): void => {
  window.location.href = PathNames.LOGOUT
}

/**
 * Returns stripped email text
 *
 * @param {string} emailText
 * @returns {string}
 */
export const getExtractedEmailText = (emailText: string) => {
  let cleanedText = emailDelimiters.reduce((acc, substring) => {
    return getCharactersBeforeSubstring(acc, substring)
  }, emailText)

  // Remove trailing whitespace
  cleanedText = cleanedText.replace(/\s+$/, '')

  return cleanedText
}

/**
 * Returns characters placed before given substring
 *
 * @param {string} inputString
 * @param {string} substring
 * @returns {string}
 */
export const getCharactersBeforeSubstring = (inputString: string, substring: string) => {
  const index = inputString?.indexOf(substring)

  if (index !== -1) {
    return inputString?.substring(0, index)
  } else {
    return inputString
  }
}

/**
 * Takes in any object, and cleans the string values of PRE or POST white space (trim)
 * @param {GenericObject}
 * @returns {GenericObject | any}
 */

export const sanitizeValues = <T>(obj: T) => {
  if (!isObject(obj) || isEmpty(obj) || isUndefined(obj)) return obj

  return Object.keys(obj).reduce((acc, curr) => {
    const val = obj[curr]
    if (isString(val)) {
      acc[curr] = val?.trim()
    } else {
      acc[curr] = val
    }
    return acc
  }, {})
}

/**
 * Returns supported payment type
 */
// TODO: Replace this with just the "supportedPaymentTypes" and subType constants
export const getSupportedPaymentTypes = () => {
  const supportedPaymentTypes = {
    CHANNEL_DEPOSIT: 'channelDeposit_channelDeposit',
    CHANNEL_DEPOSIT_TRANSACTION_HISTORY: 'channelDeposit_undefined',
    CHANNEL_DEPOSIT_FEE: 'channelDepositFee_channelDeposit',
    CONVERT_IN: 'payIn_trade',
    CONVERT_OUT: 'payOut_trade',
    CRYPTO_WITHDRAWAL: 'cryptoWithdrawal',
    PAYMENT_OUT: 'paymentOut_paymentOut',
    PAYMENT_OUT_TRANSACTION_HISTORY: 'paymentOut_PAYMENT_OUT',
    PAYMENT_IN: 'paymentIn_paymentIn',
    PAYMENT_IN_TRANSACTION_HISTORY: 'paymentIn_PAYMENT_IN',
    PAYMENT_IN_LATE: 'latePayment_',
    UNDER_PAYMENT: 'underPayment_underPayment',
    UNDER_PAYMENT_TRANASACTION_HISTORY: 'underPayment_UNDER_PAYMENT',
    PAYMENT_IN_UNDER: 'paymentIn_underPayment',
    PAYMENT_IN_UNDER_TRANSACTION_HISTORY: 'paymentIn_UNDERPAYMENT',
    OVERPAYMENT_IN_OVER: 'overPayment_overPayment',
    OVERPAYMENT_IN_OVER_TRANSACTION_HISTORY: 'overPayment_OVER_PAYMENT',
    PAYMENT_IN_OVER: 'paymentIn_overPayment',
    PAYMENT_IN_OVER_TRANSACTION_HISTORY: 'paymentIn_OVER_PAYMENT',
    PAYMENT_PROCESSING_FEE: 'processingFee_paymentIn',
    PAYMENT_PROCESSING_FEE_TEMP: 'processingFee_IN',
    PAYMENT_PROCESSING_FEE_PAYMENT_OUT: 'processingFee_paymentOut',
    PAYMENT_PROCESSING_FEE_PAYMENT_OUT_TRANSACTION_HISTORY: 'processingFee_PAYMENT_OUT',
    PAYMENT_PROCESSING_FEE_UNDERPAYMENT: 'processingFee_underPayment',
    PAYMENT_PROCESSING_FEE_OVERPAYMENT: 'processingFee_overPayment',
    WITHDRAWAL_FAILED: 'withdrawalFailed_',
    FIRST_AND_THIRD_PARTY_WITHDRAWAL: 'userWithdrawal_fiatWithdrawal',
    USER_CRYPTO_WITHDRAWAL: 'userWithdrawal_cryptoWithdrawal',
    USER_DEPOSIT_FIAT: 'userDeposit_fiatDeposit',
    USER_DEPOSIT_CRYPTO: 'userDeposit_cryptoDeposit',
    BVNK_NETWORK_TRANSFER_PAYMENT_OUT: 'bvnkNetworkTransfer_PAYMENT_OUT',
    BVNK_NETWORK_TRANSFER_PAYMENT_IN: 'bvnkNetworkTransfer_PAYMENT_IN',
    PAYMENT_OUT_ELECTRONIC_MONEY_INTERMEDIARY: 'paymentOutElectronicMoneyIntermediary_',
    PAYMENT_IN_ELECTRONIC_MONEY_INTERMEDIARY: 'paymentInElectronicMoneyIntermediary_',
    PAY_OUT: 'payOut_',
    PAY_IN: 'payIn_',
    WALLET_PAYMENT_OUT: 'paymentOut_',
    JOURNAL_ENTRY: 'journalEntry_',
  } as const

  return supportedPaymentTypes
}

const supportedPaymentTypes = getSupportedPaymentTypes()
export const statusMappings: { [key: string]: string } = {
  [supportedPaymentTypes.CONVERT_IN]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.CONVERT_OUT]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.PAY_OUT]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.PAY_IN]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.CHANNEL_DEPOSIT]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.PAYMENT_OUT_ELECTRONIC_MONEY_INTERMEDIARY]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.PAYMENT_IN_ELECTRONIC_MONEY_INTERMEDIARY]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.WITHDRAWAL_FAILED]: PaymentStatus.FAILED,
  [supportedPaymentTypes.USER_DEPOSIT_CRYPTO]: PaymentStatus.PROCESSED,
  [supportedPaymentTypes.USER_DEPOSIT_FIAT]: PaymentStatus.PROCESSED,
  [supportedPaymentTypes.PAYMENT_IN_LATE]: PaymentStatus.COMPLETE,
  [supportedPaymentTypes.JOURNAL_ENTRY]: PaymentStatus.COMPLETE,
}
export const getTransactionStatus = () => {
  const failedStatuses: string[] = [
    TransactionStatus.FAILED,
    TransactionStatus.REJECTED,
    TransactionStatus.DECLINED,
  ]
  const pendingStatuses: string[] = [
    TransactionStatus.PROCESSING,
    TransactionStatus.PENDING_USER_APPROVAL,
    TransactionStatus.SUBMITTED,
  ]

  return {
    failedStatuses,
    pendingStatuses,
  }
}

/**
 * Capitalises the first letter of a word or converts it to lowercase.
 * Used for cleaning and formatting string values in an array.
 * @param {string} word - The word to be capitalized.
 * @param {number} index - The index of the word in the sequence.
 * @returns {string} - The cleaned and formatted word.
 */
export const capitalizeWord = (word: string, index: number) => {
  return index === 0 ? word?.toLowerCase() : word?.charAt(0)?.toUpperCase() + word?.slice(1)
}

/**
 * Remove last comma from a string
 * Used for cleaning and formatting string values.
 * @param {string} word - The word to be cleaned.
 * @returns {string} - The cleaned and formatted word.
 */
export const removeLastComma = (word: string): string => {
  return word.replace(/,\s*$/, '')
}

/**
 * Converts a label string into a key format by removing spaces and capitalizing words.
 * @param {string} label - The label to be converted.
 * @returns {string} - The converted key format.
 */
export const convertLabelToKey = (label: string): string => {
  return label?.split(' ').map(capitalizeWord)?.join('')
}

/**
 * Gets transaction reference based on priority by transaction type
 * @param {Transaction} transaction - FIAT or crypto transaction detais.
 * @returns {string} - Tranaction reference to display
 */
export const getTransactionReference = (transaction: Transaction): string => {
  const isFiatWithdrawal =
    transaction?.type === TransactionType.USER_WITHDRAWAL &&
    transaction?.context?.subType === 'fiatWithdrawal'
  const isNetworkTransfer = transaction?.type === TransactionType.BVNK_NETWORK_TRANSFER

  if (isFiatWithdrawal || isNetworkTransfer) {
    return (
      transaction?.context?.reference ||
      transaction?.reference ||
      transaction?.context?.paymentReference
    )
  } else {
    return (
      transaction?.reference ||
      transaction?.context?.reference ||
      transaction?.context?.paymentReference
    )
  }
}

/**
 * Gets the state type for the given payment case status
 * @param {PaymentCaseType} paymentCaseStatus - The payment case status.
 * @returns {StateType} - The corresponding state type.
 */
export const getPaymentCaseStatus = (paymentCaseStatus: PaymentCaseType): StateType => {
  switch (paymentCaseStatus) {
    case PaymentCaseType.RESOLVED:
      return 'success'
    case PaymentCaseType.NEW:
    case PaymentCaseType.UNRESOLVED:
      return 'warning'
    default:
      return 'info'
  }
}

/**
 * Gets the payment case status label
 * @param {PaymentCaseType} paymentCaseStatus - The payment case status.
 * @returns {PaymentCaseType} - The corresponding status label.
 */
export const getPaymentCaseStatusLabel = (paymentCaseStatus: PaymentCaseType): PaymentCaseType => {
  switch (paymentCaseStatus) {
    case PaymentCaseType.UNRESOLVED:
      return PaymentCaseType.PENDING
    case PaymentCaseType.ESCALATED:
    case PaymentCaseType.ON_HOLD:
      return PaymentCaseType.IN_PROGRESS
    default:
      return paymentCaseStatus
  }
}
/**
 * Decodes the payload of a JWT (JSON Web Token) and returns it as an object.
 * This function does not validate the token, it only decodes the payload to be readable.
 *
 * @param {string} jwt - The JWT string to be decoded.
 * @returns {Record<string, unknown> | null
 */
export const decodeJwtPayload = (jwt: string): Record<string, unknown> | null => {
  try {
    const payloadBase64 = jwt.split('.')[1]
    if (!payloadBase64) {
      console.error('Invalid JWT: Payload segment not found.')
      return null
    }
    const base64 = payloadBase64.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(char => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error('Failed to decode JWT:', error)
    return null
  }
}

/**
 * Checks if a timestamp is within a certain number of minutes from now.
 *
 * @param {number} timestamp - The timestamp to check, in milliseconds.
 * @param {number} minutes - The number of minutes to check against.
 * @returns {boolean} - True if the timestamp is within the specified number of minutes from now, false otherwise.
 */
export const isWithinMinutesFromNow = (timestamp: number, minutes: number): boolean => {
  const now = Date.now()
  const futureTime = new Date(timestamp * 1000)
  const minutesInMilliseconds = minutes * 60 * 1000
  const difference = futureTime.getTime() - now

  return difference <= minutesInMilliseconds
}

/**
 * Redirects the browser to the specified URL.
 * @param {string} url - The URL to redirect to.
 */
const redirectTo = (url: string) => {
  if (url) {
    window.location.href = url
  } else {
    console.error('Invalid URL for redirection.')
  }
}

/**
 * Redirects the browser to the specified path.
 * @param {PathNamesType} path - The path name to redirect to.
 */
export const redirectToPath = (path: PathNamesType) => {
  redirectTo(path)
}

/*
 * Gets the crypto mass payout batch status and maps it to the corresponding status label to align with fiat.
 * @param {MassPayoutCryptoBatchStatusType} massPayoutCryptoBatchStatus - The mass payout status.
 * @returns {MassPayoutCryptoBatchStatusType} - The corresponding status label.
 */
export const getMassPayoutCryptoBatchStatus = (
  massPayoutCryptoBatchStatus: MassPayoutCryptoBatchStatusType
): MassPayoutCryptoBatchStatusType => {
  switch (massPayoutCryptoBatchStatus) {
    case MassPayoutCryptoBatchStatus.DRAFT:
      return MassPayoutCryptoBatchStatus.CREATED
    case MassPayoutCryptoBatchStatus.REQUESTED:
      return MassPayoutCryptoBatchStatus.PROCESSING
    default:
      return massPayoutCryptoBatchStatus
  }
}

/**
 * Loop over the fiat currencies and prepare the data
 * for Select options or return an empty array.
 */
export const mapToOptions = (currencies: { code: string; name: string }[]) =>
  currencies?.length ? currencies.map(({ code, name }) => ({ value: code, label: name })) : []

/**
 *
 * @param {string} error
 * @returns  {ErrorAPI}
 */
export const formatErrorMsg = (error: string): ErrorAPI => {
  if (error) {
    return JSON.parse(error)
  }
  return {
    errorList: [],
  }
}

/**
 * Capitalizes the first letter of a given string while leaving the rest of the string unchanged.
 *
 * This function is useful for formatting strings where you want the first character to be uppercase,
 * such as capitalizing the first word of a sentence or a title. If the input string is empty, it will return
 * the empty string unchanged.
 *
 * @param {string} sentence - The input string that needs to be capitalized.
 * The function expects a non-null string input. If the string is empty, it will return the empty string.
 *
 * @returns {string} - A new string with the first letter capitalized. If the input string is empty, the
 * function returns an empty string.
 **/
export const capitalizeFirstLetter = (sentence: string): string => {
  if (sentence.length === 0) return sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

/**
 * Transforms an array of objects into a new structure with modified keys suitable for use in UI component.
 * @param {Object[]} options - The array of options objects to be transformed.
 * @returns {Object[]} An array of objects with updated keys.
 */
export const transformOptions = (
  options: { name: string; children?: { name: string; reference: string }[] }[]
): TransformedOption[] => {
  return options?.map(option => {
    const transformedOption = {
      label: `${option?.name}`,
      options: [],
    }

    if (option?.children) {
      transformedOption.options = option?.children?.map(children => ({
        label: children?.name,
        value: children?.reference,
      }))
    }

    return transformedOption
  })
}

/**
 * Finds the label associated with a specific value in a list of options.
 * @param {string} value - The value to search for in the options.
 * @param {TransformedOption[]} options - The list of options to search through.
 * @returns {string|null} The label associated with the given value, or null if not found.
 */
export const findLabelByValue = (
  value: string,
  options: { label: string; options: { label: string; value: string }[] }[]
): string | null => {
  for (const option of options) {
    const foundOption = option?.options?.find(opt => opt?.value === value)
    if (foundOption) {
      return foundOption?.label
    }
  }
  return null
}

/*
 * Convert number to ordinal string
 * For example 1 to "first", 2 to "second" etc
 *
 * @param {number} num - The number input
 *
 * @returns {string} - Ordinal string
 **/
export const number2Ordinal = (num: number): string => {
  if (typeof num !== 'number' || isNaN(num)) {
    return num?.toString()
  }

  const notableSuffixes = ['', 'First', 'Second', 'Third']
  if (num > 0 && num < 4) return notableSuffixes[num]

  const otherSuffixes = ['th', 'st', 'nd', 'rd']
  const remainder = num % 100
  const ordinal =
    num + (otherSuffixes[(remainder - 20) % 10] || otherSuffixes[remainder] || otherSuffixes[0])

  return ordinal
}
