import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { snakeCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { TableRow } from '@node-space/storybook-components/dist/components/SimpleTable'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ChannelsPaymentsResponse } from 'types/channels'
import { getStatusState, titleCase } from 'utils/utils'
import ChannelRowExpanded from './ChannelRowExpanded'

interface ChannelPaymentTableProps {
  channelPayments: ChannelsPaymentsResponse
  withContentExpanded?: boolean
}

const rowHeaderWidth = 'px-8'
const rowWidth = 'px-6 py-4 whitespace-nowrap'
const amountWidth = 'pr-14 whitespace-nowrap'
const expandWidth = 'px-4 mr--2'
const dateWidth = 'py-0 '

const Table = ({ channelPayments, withContentExpanded = false }: ChannelPaymentTableProps) => {
  const { t } = useTranslation()

  const [rowExpanded, setRowExpanded] = useState(withContentExpanded)
  const singleRowTransactionResult = channelPayments?.length === 1

  useEffect(() => {
    if (!singleRowTransactionResult) {
      setRowExpanded(false)
    }
  }, [channelPayments])

  const convertChannelPaymentsInfo = (): TableRow[] => {
    if (!channelPayments) return []

    return channelPayments?.map(transaction => {
      return {
        rowData: [
          {
            cellData: <></>,
          },
          {
            cellData: <Text size="sm">{transaction?.reference}</Text>,
            className: rowWidth,
            testid: `channels-${snakeCase(transaction?.reference)}-reference`,
          },
          {
            cellData: `${transaction?.paidAmount} ${transaction?.paidCurrency}`,
            className: amountWidth,
            align: 'end',
            testid: `channels-${snakeCase(transaction?.reference)}-amount`,
          },
          {
            cellData: transaction?.merchantId,
            className: rowWidth,
            testid: `channels-${snakeCase(transaction?.reference)}-merchant-id`,
          },
          {
            cellData: `${dayjs(transaction?.dateCreated).format('DD MMM YYYY')}`,
            className: dateWidth,
            testid: `channels-${snakeCase(transaction?.reference)}-date`,
          },
          {
            cellData: (
              <StatusPill
                state={getStatusState(transaction?.status?.toUpperCase())}
                label={titleCase(transaction?.status)}
                fitContent
              />
            ),
            className: rowWidth,
            testid: `channels-${snakeCase(transaction?.reference)}-status`,
          },
        ],
        ...(rowExpanded && singleRowTransactionResult && { rowExpanded }),
        expandedContent: <ChannelRowExpanded channelPayment={transaction} />,
      }
    })
  }

  return (
    <Wrapper background="white" overflowX="auto" className="mt-6">
      <SimpleTableV2
        expand={true}
        testid="payments-channels-table"
        columns={[
          { headerTitle: '', testid: 'payments-channels-table-column-channel-blank' },
          {
            headerTitle: t('payments.channels.channelReference'),
            className: rowWidth,
            testid: 'payments-channels-table-column-channel-ref',
          },
          {
            headerTitle: t('payments.amount'),
            align: 'end',
            className: amountWidth,
            testid: 'payments-channels-table-column-amount',
          },
          {
            headerTitle: t('payments.channels.merchantId'),
            className: rowWidth,
            testid: 'payments-channels-table-column-merchant-id',
          },
          {
            headerTitle: t('payments.date'),
            className: dateWidth,
            testid: 'payments-channels-table-column-date',
          },
          {
            headerTitle: t('payments.status'),
            className: rowHeaderWidth,
            testid: 'payments-channels-table-column-status',
          },
          { headerTitle: '', className: expandWidth },
        ]}
        tableData={convertChannelPaymentsInfo()}
      />
    </Wrapper>
  )
}

export default React.memo(Table)
