import React from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'
import ConnectionsListItem, { ConnectionItem } from './ConnectionsListItem'

export interface ConnectionsListProps {
  connections: ConnectionItem[]
}

const ConnectionsList = ({ connections }: ConnectionsListProps) => {
  return (
    <Box flex direction="col" className="mt-4" gapY={20} paddingX={16} paddingY={24}>
      {connections?.map(connection => {
        return <ConnectionsListItem key={connection?.name} {...connection} />
      })}
    </Box>
  )
}

export default ConnectionsList
