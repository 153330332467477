import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { useProfileContext } from 'hooks/context/useProfileContext'

interface TwoFAProps {
  twoFactorEnabled: boolean
  showTwoFAModal: boolean
  showTwoFAMandatoryScreen: boolean
  setShowTwoFAModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsTwoFactorEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const TwoFAContext = createContext<TwoFAProps>(null)

export const TwoFAProvider = ({ children, enable2FAModal = false }) => {
  const { enableTwoFaAuth } = useFeatureFlags()
  const { profile } = useProfileContext()
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState<boolean>(true)
  const [showTwoFAModal, setShowTwoFAModal] = useState(enable2FAModal || false)

  const showTwoFAMandatoryScreen = useMemo(
    () => !!enableTwoFaAuth && !isTwoFactorEnabled,
    [enableTwoFaAuth, isTwoFactorEnabled]
  )

  useEffect(() => {
    setIsTwoFactorEnabled(profile?.twoFactorEnabled)
  }, [profile?.twoFactorEnabled])

  const value = {
    twoFactorEnabled: isTwoFactorEnabled,
    showTwoFAModal,
    showTwoFAMandatoryScreen,
    setShowTwoFAModal,
    setIsTwoFactorEnabled,
  }

  return <TwoFAContext.Provider value={value}>{children}</TwoFAContext.Provider>
}
