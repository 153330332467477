import React from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useTitleTransaction } from 'pages/WalletDetails/hooks/useTitleTransaction'
import { Transaction } from 'types/transaction'
import { ProofOfPaymentContainer } from './components/ProofOfPaymentContainer'
import { TransactionDetails } from './TransactionDetails'

interface TransactionModalProps {
  transaction: Transaction | undefined
  onClose: () => void
}

export const TransactionModal = ({ transaction, onClose }: TransactionModalProps) => {
  const { title } = useTitleTransaction(transaction)
  const defaultContext = {
    status: '',
    subType: '',
    externalReference: '',
    hasProofOfPayment: false,
  }
  const { type: transactionType = '', context: transactionContext = defaultContext } = transaction

  return (
    <Modal visible closeOnBackgroundClick closeModal={onClose}>
      <ModalNavBar title={title} onClose={onClose} />
      <Box paddingY={32} flex justifyContent="center" alignItems="center">
        <Text className="font-semibold text-xl">{`${transaction?.amount} ${transaction?.wallet?.currency?.code}`}</Text>
      </Box>
      <Box paddingX={16} paddingB={16}>
        <TransactionDetails transaction={transaction} title={title} />
      </Box>
      <Box>
        <ProofOfPaymentContainer
          transactionType={transactionType}
          transactionSubType={transactionContext?.subType}
          status={transactionContext?.status}
          externalReference={transactionContext?.externalReference}
          hasProofOfPayment={transactionContext?.hasProofOfPayment}
          isLink={false}
        />
      </Box>
    </Modal>
  )
}
