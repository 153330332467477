import React, { useEffect } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import IconSelect from '@node-space/storybook-components/dist/IconSelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Select } from '@node-space/storybook-components/dist/Select'
import {
  CryptoPayout,
  UpdateCryptoPayoutFormErrors,
  UpdateCryptoPayoutFormPayload,
  UpdateCryptoPayoutFormPayloadKeys,
} from 'pages/MassPayouts/types'
import { Currencies } from 'types/types'
import { capitalizeFirstLetter, formatErrorMsg, mapToOptions } from 'utils/utils'

interface CryptoPayoutEditRecordFormProps {
  massPayoutEditRecordProps: UpdateCryptoPayoutFormPayload
  errors: UpdateCryptoPayoutFormPayloadKeys
  handleOnChange: (field: UpdateCryptoPayoutFormPayloadKeys, value: string) => void
  register: UseFormRegister<UpdateCryptoPayoutFormErrors>
  setError: (errors: UpdateCryptoPayoutFormErrors) => void
  payout: CryptoPayout
  walletName: string
  walletCurrency: string
  validateCryptoPayout: (data: UpdateCryptoPayoutFormPayload) => void
  clearErrors: () => void
  cryptoCurrencies: Currencies[]
}

export const CryptoPayoutEditRecordForm = ({
  massPayoutEditRecordProps,
  errors,
  handleOnChange,
  register,
  setError,
  payout,
  walletName,
  walletCurrency,
  validateCryptoPayout,
  clearErrors,
  cryptoCurrencies,
}: CryptoPayoutEditRecordFormProps) => {
  const { t } = useTranslation()

  const cryptoCurrenciesOptions = mapToOptions(cryptoCurrencies)

  const protocolOptions = cryptoCurrencies
    ?.filter(currency => currency?.code === massPayoutEditRecordProps?.currency)
    ?.map(currency => currency?.protocols)
    ?.flat()
    ?.map(protocol => ({
      value: protocol?.code,
      label: protocol?.network,
    }))

  const errorMessages = formatErrorMsg(payout?.errorMessage?.value)

  useEffect(() => {
    if (protocolOptions?.length === 1) {
      clearErrors('protocol')
    }
  }, [massPayoutEditRecordProps?.currency])

  useEffect(() => {
    errorMessages?.errorList.map(error => {
      const code = error?.code
      const message = capitalizeFirstLetter(error?.message)

      switch (code) {
        case 'MER-PAY-2019':
        case 'MER-PAY-2011':
          setError('currency', { message })
          break
        case 'MER-PAY-2024':
          setError('protocol', { message })
          break
        case 'MER-PAY-2001':
        case 'MER-PAY-2012':
          setError('cryptoAmount', { message })
          break
        case 'MER-PAY-2009':
          setError('reference', { message })
          break
        case 'MER-PAY-2027':
        case 'MER-PAY-2028':
          setError('recipientAddress', { message })
          break
      }
    })
  }, [setError, payout])

  const selectedFundingWalletLabel = `${walletName} (${walletCurrency})`

  return (
    <Box paddingX={24} className="my-6 space-y-6 text-gray-100">
      <IconSelect
        label={t('massPayouts.payingFromFundingWallet')}
        name="fundingWallet"
        value={walletCurrency}
        options={[
          {
            value: walletCurrency,
            label: selectedFundingWalletLabel,
          },
        ]}
        disabled
        hideArrow
        iconsUrl={process.env.ICONS_URL}
      />
      {payout?.fundingWalletAmount ? (
        <Input
          testid="update-amount-input"
          {...register('cryptoAmount')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('cryptoAmount', e.target.value)
          }
          label={t('massPayouts.fundingWalletAmountDebit')}
          inputMode="text"
          name="cryptoAmount"
          value={massPayoutEditRecordProps?.cryptoAmount}
          error={!!errors?.cryptoAmount}
          errorText={errors?.cryptoAmount?.message}
          disabled={!!!errors?.cryptoAmount}
          onBlur={() => validateCryptoPayout('cryptoAmount')}
        />
      ) : (
        <Input
          label={t('massPayouts.fundingWalletAmountDebit')}
          inputMode="text"
          name="cryptoAmountLabel"
          value={t('massPayouts.debitedFromFundingWalletWillBeCalculate', {
            currencyCode: walletCurrency,
          })}
          disabled
        />
      )}
      <Select
        {...register('currency')}
        label={t('massPayouts.currencyCryptoLabel')}
        value={massPayoutEditRecordProps?.currency}
        isSearchable
        options={cryptoCurrenciesOptions}
        error={!!errors?.currency}
        errorText={errors?.currency?.message}
        placeholder={t('searchOrSelectACurrency')}
        disabled={!!!errors?.currency}
        onChange={value => {
          handleOnChange('currency', value)
        }}
      />
      {payout?.fundingWalletAmount ? (
        <Input
          testid="update-amount-input"
          label={t('massPayouts.amountCryptoLabel')}
          inputMode="text"
          name="cryptoAmountLabel"
          value={t('massPayouts.amountWillCalculateAtPayment', {
            currencyCode: massPayoutEditRecordProps?.currency,
          })}
          disabled
        />
      ) : (
        <Input
          testid="update-amount-input"
          {...register('cryptoAmount')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('cryptoAmount', e.target.value)
          }
          label={t('massPayouts.amountCryptoLabel')}
          inputMode="text"
          name="cryptoAmount"
          value={massPayoutEditRecordProps?.cryptoAmount}
          error={!!errors?.cryptoAmount}
          errorText={errors?.cryptoAmount?.message}
          disabled={!!!errors?.cryptoAmount}
          onBlur={() => validateCryptoPayout('cryptoAmount')}
        />
      )}
      <Select
        {...register('protocol')}
        label={t('massPayouts.blockchainProtocol')}
        name="protocol"
        options={protocolOptions}
        value={massPayoutEditRecordProps?.protocol}
        error={!!errors?.protocol}
        errorText={errors?.protocol?.message}
        onChange={value => {
          handleOnChange('protocol', value)
        }}
        disabled={!!!errors?.protocol}
      />
      <Input
        testid="update-address-input"
        {...register('recipientAddress')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange('recipientAddress', e.target.value)
        }
        label={t('massPayouts.recipientAddress')}
        inputMode="text"
        name="recipientAddress"
        value={massPayoutEditRecordProps?.recipientAddress}
        error={!!errors?.recipientAddress}
        errorText={errors?.recipientAddress?.message}
        disabled={!!!errors?.recipientAddress}
        onBlur={() => validateCryptoPayout('recipientAddress')}
      />
      <Input
        testid="update-tag-input"
        {...register('tag')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange('tag', e.target.value)}
        label={t('massPayouts.tag')}
        inputMode="text"
        name="tag"
        value={massPayoutEditRecordProps?.tag}
        error={!!errors?.tag}
        errorText={errors?.tag?.message}
        disabled={!!!errors?.tag}
        onBlur={() => validateCryptoPayout('tag')}
      />
      <Input
        testid="update-reference-input"
        {...register('reference')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange('reference', e.target.value)
        }
        label={t('massPayouts.reference')}
        inputMode="text"
        name="reference"
        value={massPayoutEditRecordProps?.reference}
        error={!!errors?.reference}
        errorText={errors?.reference?.message}
        disabled={!!!errors?.reference}
        onBlur={() => validateCryptoPayout('reference')}
      />
    </Box>
  )
}
