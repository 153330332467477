import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Icon, IconProps } from '@node-space/storybook-components/dist/Icon'
import SettingsLink, { SettingsLinkProps } from 'components/settings/SettingsLink'
import { PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useProfileSetting } from 'hooks/useProfileSetting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { isProduction } from 'utils/environment'
import track from 'utils/tracker'

export const ManageAccount = () => {
  const { t } = useTranslation()
  const { enableLegalAgreements } = useFeatureFlags()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_ACCOUNT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const { profile } = useProfileContext()
  const { isVerifiedBusinessAccount, isDeveloperAccount } = useAccountsContext()

  const sharedIconProps: Omit<IconProps, 'name'> = {
    size: 'lg',
    color: 'grey-08',
  }

  const permissions = useMappedRoles()

  const isAccountViewOnly = !!profile?.viewOnly

  const canDisable =
    !isProduction && isDeveloperAccount ? false : isAccountViewOnly || !isVerifiedBusinessAccount

  const canViewLegalAgreements = permissions?.manageLegalAgreements?.canView ?? false
  const canViewManageChannels = permissions?.channels?.canView
  const canViewManageMerchants = permissions?.manageMerchants?.canView ?? false
  const canViewApiIntegration = permissions?.manageApiIntegration?.canView
  const canViewBeneficiaries = permissions?.manageBeneficiaries?.canView
  const canViewTeamMembers = permissions?.manageTeamMembers?.canView
  const canViewPaymentSupport = permissions?.paymentSupport?.canView

  const hasLegalAgreementProfileSetting = useProfileSetting('isLegalAgreementSectionEnabled')

  const settingsItems: SettingsLinkProps[] = [
    {
      icon: <Icon name="MerchantIcon" {...sharedIconProps} />,
      title: t('settingsManageMerchants'),
      description: t('settingsManageMerchantsDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_MERCHANT_DETAILS}`,
      disable: canDisable && !canViewManageMerchants,
    },
    {
      icon: <Icon name="ChannelIcon" {...sharedIconProps} />,
      title: t('settingsManageChannels'),
      description: t('settingsManageChannelsDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`,
      disable: !canViewManageChannels,
    },
  ]

  if (canViewPaymentSupport) {
    settingsItems.push({
      icon: <Icon name="SupportIcon" {...sharedIconProps} />,
      title: t('settingsPaymentSupport'),
      description: t('settingsPaymentSupportDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_PAYMENT_SUPPORT}`,
      disable: canDisable,
    })
  }

  settingsItems.push({
    icon: <Icon name="ChannelIcon" {...sharedIconProps} />,
    title: t('settingsManageCryptoAddress'),
    description: t('settingsManageCryptoAddressDescription'),
    path: `${PathNames.SETTINGS}${PathNames.MANAGE_CRYPTO_WHITELISTING}`,
    disable: false,
  })

  if (canViewBeneficiaries) {
    settingsItems.push({
      icon: <Icon name="TeamIcon" {...sharedIconProps} />,
      title: t('settingsManageBeneficiaries'),
      description: t('settingsManageBeneficiariesDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}`,
      disable: canDisable,
    })
  }

  if (!isAccountViewOnly && canViewApiIntegration) {
    settingsItems.push({
      icon: <Icon name="ApiIcon" {...sharedIconProps} />,
      title: t('settingsAPIIntegration'),
      description: t('settingsAPIIntegrationDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_API_KEYS}`,
      disable: canDisable,
    })
  }

  if (canViewTeamMembers) {
    settingsItems.push({
      icon: <Icon name="TeamIcon" {...sharedIconProps} />,
      title: t('settingsTeamMembers'),
      description: t('settingsTeamMembersDescription'),
      path: `${PathNames.SETTINGS}${PathNames.MANAGE_TEAM_MEMBERS}`,
      disable: false,
    })
  }

  if (enableLegalAgreements || hasLegalAgreementProfileSetting) {
    settingsItems.unshift({
      icon: <Icon name="BusinessIcon" {...sharedIconProps} />,
      title: t('legalAgreements.title'),
      description: t('legalAgreements.manageDescription'),
      path: `${PathNames.SETTINGS}${PathNames.LEGAL_AGREEMENTS}`,
      disable: !canViewLegalAgreements,
    })
  }

  return (
    <>
      {settingsItems.map(item => (
        <SettingsLink key={item.title} {...item} />
      ))}
    </>
  )
}
