import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import {
  ModalActions,
  ModalBody,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { sumNumbers } from '@node-space/utils'
import { useProfileContext } from 'hooks/context/useProfileContext'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { roundNumberWithCommas } from 'utils/utils'
import { WithdrawActionProps } from './Withdraw'

const WithdrawComplete = ({
  dismissAction,
  form,
  wallets,
  refresh,
  withdrawalFee,
}: WithdrawActionProps) => {
  const { t } = useTranslation()

  const { profile } = useProfileContext()
  const formValues = form.watch()

  // refresh wallets
  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WITHDRAW_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
      amount: formValues.amount,
      fee: withdrawalFee?.fee ?? 0,
    })
    refresh()
  }, [])
  const selectedWallet =
    formValues.walletId && wallets.find(x => x.id.toString() === formValues.walletId)

  const actions: ButtonProps[] = [{ children: t('done'), onClick: () => dismissAction() }]

  return (
    <>
      <ModalScrollable>
        <ModalNavBar onClose={dismissAction} hasDivider={false} />
        <ModalBody hasTopPadding={false}>
          <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
            <Icon name="ClapIcon" color="status-success-500" size="lg" />
            <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
              {t('transactionSubmitted')}
            </Text>
            <Text align="center" weight="medium" size="sm">
              {`${roundNumberWithCommas(
                sumNumbers(parseFloat(formValues?.amount?.toString()), withdrawalFee?.fee ?? 0)
              )} ${selectedWallet?.currency?.code} ${t('withdrawn')}`}
            </Text>
            <Box>
              <Text align="center" size="sm" color="grey">
                {`${t('withdrawProcess')} `}
                {!profile?.twoFactorEnabled && t('withdraw2FAAdditional')}
              </Text>
              <Text align="center" size="sm" color="grey">
                {t('withdrawDelay')}
              </Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalScrollable>
      <ModalActions actions={actions} alignActions="center" />
    </>
  )
}

export default WithdrawComplete
