import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AccountTypes } from 'types/types'
import { isSandbox } from 'utils/environment'
import { PathNames } from 'constants/General'

type CreateAccountProps = {
  accountType: AccountTypes
}

export const CreateAccount = ({ accountType }: CreateAccountProps) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (accountType === AccountTypes.DEVELOPER || isSandbox) {
      window.location.href = `${process.env.ONBOARDING_URL}${PathNames.DEVX_SIGNUP_URL}`
      return
    }

    if (accountType === AccountTypes.BUSINESS) {
      const s_id = searchParams.get('s_id')
      const s_idUrlQuery = s_id ? `?s_id=${s_id}` : ''
      window.location.href = `${process.env.ONBOARDING_URL}${PathNames.KYB_SIGNUP_URL}${s_idUrlQuery}`
    }
  }, [accountType])

  return <></>
}
