import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCryptoCurrencies } from 'services/CurrencyService'

export const useCryptoCurrenciesQuery = () =>
  useQuery({
    queryKey: reactQueryKeys.cryptoCurrencies(),
    queryFn: getCryptoCurrencies,
    refetchOnReconnect: false,
  })
