import React, { useMemo, useState } from 'react'
import { Options } from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import { Currencies, WalletType } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import LocalBankDetails from './components/LocalBankDetails'
import SwiftBankDetails from './components/SwiftBankDetails'

interface DepositAccountDetailsBoardProps {
  wallet: WalletType
  options: Options
  currency: Currencies
  isSegregated?: boolean
}

const DepositAccountDetailsBoard = ({
  wallet,
  options,
  currency,
  isSegregated,
}: DepositAccountDetailsBoardProps) => {
  const [copiedValue, setCopiedValue] = useState<string>()
  const [copiedTitle, setCopiedTitle] = useState<string>()

  const isSwiftBeneficiary = useMemo(() => {
    return options?.type === BankTransferType.SWIFT
  }, [options?.type])

  const handleSetCopiedTitle = (value: string) => {
    track.Amp.track(AmplitudeEvent.DEPOSIT_COPY, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      copied: value.toLowerCase() || '',
    })
    setCopiedTitle(value)
  }

  return (
    <>
      {isSwiftBeneficiary ? (
        <SwiftBankDetails
          options={options}
          copiedValue={copiedValue}
          setCopiedValue={setCopiedValue}
          setCopiedTitle={handleSetCopiedTitle}
        />
      ) : (
        <LocalBankDetails
          currency={currency}
          options={options}
          wallet={wallet}
          isSegregated={isSegregated}
        />
      )}
    </>
  )
}

export default DepositAccountDetailsBoard
