import { Callout } from '@node-space/storybook-components'
import { useDepositAccountsV2Context } from 'hooks/context'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const WarningMessage = () => {
  const { t } = useTranslation()
  const { selectedAccount } = useDepositAccountsV2Context()
  return (
    <Callout
      state="warning"
      message={t('fiatDepositWarning', {
        accountHolderName: selectedAccount?.options?.accountHolderName,
      })}
    />
  )
}
