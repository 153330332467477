import { CustomerStatus } from 'types/customers'
import { AssignedColors, AssignedColorType } from './types'

export const StatusMapping = {
  [CustomerStatus.VERIFIED]: 'success',
  [CustomerStatus.PENDING]: 'warning',
  [CustomerStatus.REJECTED]: 'error',
} as const

export const StatusLabelMapping = {
  [CustomerStatus.VERIFIED]: 'success',
  [CustomerStatus.PENDING]: 'Under review',
  [CustomerStatus.REJECTED]: 'Not supported',
} as const

export const assignedColorArray: AssignedColorType[] = Object?.values(AssignedColors)
