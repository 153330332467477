import React, { useState, useEffect, useMemo } from 'react'
import { ceil } from 'lodash-es'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Paginator } from '@node-space/storybook-components/dist/Paginator'
import { Paging } from 'types/types'

type UnknownIfEmpty<T extends object> = keyof T extends never ? unknown : T

type RootStateOrUnknown = UnknownIfEmpty<{}>

interface PageableTablePaginationProps {
  total: number
  pagingMax: number
  paging?: Paging
  onPagination?: (val: RootStateOrUnknown) => void
  disableLeftDropdown?: boolean
}

const PageableTablePagination = ({
  total,
  pagingMax,
  paging,
  onPagination,
  disableLeftDropdown,
}: PageableTablePaginationProps) => {
  const [page, setPage] = useState(0)
  const [isClicked, setIsClicked] = useState(false)
  const maxPage = ceil(total / pagingMax - 1)

  const pageListOptions = disableLeftDropdown
    ? null
    : [{ label: `${pagingMax}`, value: `${pagingMax}` }]

  useEffect(() => {
    if (isClicked) {
      onPageChange()
    }
  }, [page, isClicked])

  const onPageChange = () => {
    onPagination({
      ...paging,
      offset: page,
      max: pagingMax,
      page,
    })
  }

  const onClickPrev = () => {
    if (page !== 0) {
      setIsClicked(true)
      setPage(page - 1)
    }
  }

  const onClickNext = () => {
    if (page < maxPage) {
      setIsClicked(true)
      setPage(page + 1)
    }
  }

  const getRangeStart = useMemo(() => page * pagingMax + 1 || 1, [page, pagingMax])

  const getRangeEnd = useMemo(
    () => (total ? Math.min(page * pagingMax + pagingMax, total) : pagingMax),
    [total, page, pagingMax]
  )

  return (
    <>
      <Wrapper testid="pagination-wrapper" className="mt-6 mb-10">
        <Paginator
          data-testid="pagination-group-container"
          totalPages={total}
          range={{
            start: getRangeStart,
            end: getRangeEnd,
          }}
          onClickBack={onClickPrev}
          onClickForward={onClickNext}
          results={pageListOptions}
        />
      </Wrapper>
    </>
  )
}

export default PageableTablePagination
