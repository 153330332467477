import React from 'react'
import i18next from 'i18next'
import CopyTransactionRow from './CopyTransactionRow'

interface ChannelDepositProps {
  channelId: string
  channelReference: string
}

const ChannelDeposit = ({ channelId, channelReference }: ChannelDepositProps) => {
  return (
    <>
      {channelId && (
        <CopyTransactionRow content={channelId} label={i18next.t('channelDepositId')} />
      )}
      {channelReference && (
        <CopyTransactionRow content={channelReference} label={i18next.t('channelReference')} />
      )}
    </>
  )
}

export default ChannelDeposit
