import React, { useEffect } from 'react'
import { Box as CardGroup } from '@node-space/storybook-components/dist/Box'
import { ButtonCardProps, CardButton } from 'components/CardButtonGroup/CardButton/CardButton'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

type CardButtonGroupProps = {
  cards: ButtonCardProps[]
}

export const CardButtonGroup = ({ cards }: CardButtonGroupProps) => {
  useEffect(() => {
    track.Amp.track(AmplitudeEvent.ADD_BENEFICARY_SELECT_DESTINATION, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <>
      <CardGroup flex flexCol alignItems="start" padding={24} paddingT={20} gap={16}>
        {cards?.map(card => {
          return (
            <CardButton
              key={card?.label}
              label={card?.label}
              description={card?.description}
              icon={card?.icon}
              onCardClick={card?.onCardClick}
            />
          )
        })}
      </CardGroup>
    </>
  )
}
