import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Callout } from '@node-space/storybook-components/dist/Callout'

export type SubmitErrorProps = {
  children?: ReactNode
}

export const SubmitError = ({ children }: SubmitErrorProps) => {
  const { t } = useTranslation()

  return <Callout state="error">{children || t('oopsSomethingWentWrong')}</Callout>
}
