import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { updateAccountApprovalsPreference } from 'services/ApprovalsService'
import {
  UpdateAccountApprovalsPreferenceV1,
  UpdateAccountApprovalsPreferenceV2,
} from 'types/approvals'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useAccountApprovalsPreferenceMutation = () => {
  return useMutation({
    mutationFn: ({
      data,
      isV2Api,
    }: {
      data:
        | { preferences: UpdateAccountApprovalsPreferenceV1[] }
        | UpdateAccountApprovalsPreferenceV2
      isV2Api?: boolean
    }) => updateAccountApprovalsPreference(data, isV2Api),
    onError: (error: BaseErrorResponse) => {
      logSentryError(
        'Error from useAccountApprovalsPreferenceMutation - updateAccountApprovalsPreference',
        error
      )
    },
  })
}
