import React from 'react'
import { Box, Text } from '@node-space/storybook-components'
import { useWalletContext } from 'hooks/context'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { WalletBalance } from './WalletBalance'
import { WalletButtonsCrypto } from './WalletButtonsCrypto'
import { WalletButtonsFiat } from './WalletButtonsFiat'

export const WalletDetailsHeader = () => {
  const wallet = useWalletContext()
  const { viewOnly } = useProfileContext()

  return (
    <Box flex direction="col" justifyContent="between" className="pb-9 sm:flex-row">
      <Box>
        <Text color="grey">{` ${wallet.description} wallet balance`}</Text>
        <WalletBalance isEmoney={wallet.isEmoney} />
      </Box>
      {wallet.currency?.fiat ? (
        <WalletButtonsFiat disabled={viewOnly} />
      ) : (
        <WalletButtonsCrypto disabled={viewOnly} />
      )}
    </Box>
  )
}
