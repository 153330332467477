import React from 'react'
import clsx from 'clsx'
import { Box, Box as IconBox } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { CSATIcon } from './CSATIcon'
import {
  CSATFeedbackValue,
  CSATFeedbackValueType,
  CSATOptionStatus,
  CSATOptionStatusType,
} from './types'

type CSATOptionProps = {
  label: string
  value: CSATFeedbackValueType
  status: CSATOptionStatusType
  onClick: () => void
}

export const CSATOption = ({ label, value, status, onClick }: CSATOptionProps) => {
  const isStatusSelected = status === CSATOptionStatus.SELECTED
  const isStatusUnselected = status === CSATOptionStatus.UNSELECTED
  const isFeedbackValueBad = value === CSATFeedbackValue.BAD
  const isFeedbackValueOkay = value === CSATFeedbackValue.OKAY
  const isFeedbackValueGood = value === CSATFeedbackValue.GOOD

  return (
    <Box tag="button" flex direction="col" centerChildren onClick={onClick} key={value}>
      <IconBox
        className={clsx(
          isStatusUnselected
            ? 'text-text-300'
            : {
                'text-status-error-500': isFeedbackValueBad,
                'text-primary-500': isFeedbackValueOkay,
                'text-status-success-500': isFeedbackValueGood,
              }
        )}
      >
        <CSATIcon name={value} selected={isStatusSelected} />
      </IconBox>
      <Text
        size="sm"
        weight={isStatusSelected ? 'bold' : 'normal'}
        className={clsx(
          isStatusUnselected
            ? 'text-text-300'
            : {
                'text-status-error-900': isFeedbackValueBad,
                'text-primary-900': isFeedbackValueOkay,
                'text-status-success-900': isFeedbackValueGood,
              }
        )}
      >
        {label}
      </Text>
    </Box>
  )
}
