import React from 'react'

import AccountDetails from './AccountDetails'
import Security from './Security'

const UserDetails = () => {
  return (
    <>
      <AccountDetails />
      <Security />
    </>
  )
}

export default UserDetails
