import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon, WorkspaceMenu } from '@node-space/storybook-components'
import { PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { switchAccount } from 'services/AuthService'
import { isProduction } from 'utils/environment'
import { logout } from 'utils/utils'

export interface UserMenuProps {
  isVerification?: boolean
}

export const UserMenu = ({ isVerification = false }: UserMenuProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()
  const { profile } = useProfileContext() || {}
  const { firstName, lastName } = profile || {}

  const {
    accounts,
    currentAccount,
    isDeveloperAccount,
    isLoading: isLoadingAcounts,
  } = useAccountsContext()

  const [switchingAccount, setSwitchingAccount] = useState(false)

  const accountSwitcher = async (index: number) => {
    const accountIdToSwitchTo = accounts?.[index]?.id
    if (accounts?.length > 1 && currentAccount?.id !== accountIdToSwitchTo) {
      setSwitchingAccount(true)
      await switchAccount(accountIdToSwitchTo)
      window.location.reload()
    }
  }

  const profileName = !!firstName && !!lastName && `${firstName} ${lastName}`

  const menuListItems = [
    {
      icon: <Icon name="SupportIcon" />,
      text: t('helpMenuSupportAndHelp'),
      hyperlink: process.env.SUPPORT_URL,
    },
    {
      icon: <Icon name="LogOutIcon" />,
      text: t('logOut'),
      onClick: () => logout(),
    },
  ]

  location.pathname !== `${PathNames.SETTINGS}${PathNames.MANAGE_USER_PROFILE}` &&
    !isVerification &&
    menuListItems.unshift({
      icon: <Icon name="ProfileIcon" />,
      text: t('profile'),
      onClick: () => navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_USER_PROFILE}`),
    })

  !isProduction &&
    menuListItems.unshift({
      icon: <Icon name="ExternalLinkIcon" />,
      text: t('developerDocs'),
      hyperlink: process.env.DEVELOPER_DOCS,
    })

  return (
    <div className="relative inline-flex">
      <WorkspaceMenu
        workspaceAccounts={{
          accountName: profileName,
          accounts,
          selectedAccount: currentAccount,
          setSelectedAccount: accountSwitcher,
        }}
        menuListItems={menuListItems}
        loading={!isDeveloperAccount ? isLoadingAcounts || switchingAccount : false}
        testid="workspaceMenu"
      />
    </div>
  )
}
