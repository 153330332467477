import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AddCustomerFormErrors, AddCustomerPayload } from 'types/customers'

interface IndividualCustomerFieldsProps {
  errors?: AddCustomerFormErrors
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  isLocalBeneficiary?: boolean
  register?: UseFormRegister<AddCustomerPayload>
  setValue?: (name: string, value: string) => void
  renderCurrencySpecificFields?: () => React.ReactNode
}

export const IndividualCustomerFields = ({
  errors,
  firstName,
  lastName,
  dateOfBirth,
  register,
  setValue,
}: IndividualCustomerFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Box flex direction="col" gapY={16}>
      <Box>
        <Input
          {...register('firstName')}
          label={t('formFields.firstName')}
          placeholder={t('enterName')}
          inputMode="text"
          name="firstName"
          testid="first-name"
          errorText={errors?.firstName?.message}
          error={!!errors?.firstName}
          value={firstName}
        />
      </Box>
      <Box>
        <Input
          {...register('lastName')}
          label={t('formFields.lastName')}
          placeholder={t('enterLastName')}
          inputMode="text"
          name="lastName"
          testid="last-name"
          errorText={errors?.lastName?.message}
          error={!!errors?.lastName}
          value={lastName}
        />
      </Box>
      <Box>
        <Input
          {...register('dateOfBirth')}
          label={t('formFields.lastName')}
          placeholder={t('enterLastName')}
          inputMode="text"
          name="dateOfBirth"
          testid="dateOfBirth"
          errorText={errors?.dateOfBirth?.message}
          error={!!errors?.dateOfBirth}
          value={dateOfBirth}
        />
      </Box>
    </Box>
  )
}
