import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getNetworkMembers } from 'services/BvnkNetworkService'

export const useNetworkMembersQuery = (accountReference: string, enabled: boolean) => {
  return useQuery({
    queryKey: reactQueryKeys.bvnkNetworkMembers(accountReference),
    queryFn: () => getNetworkMembers(accountReference),
    enabled,
    // Phase 2 pagination
    // select: (res: WithPaginationApiResponse<BvnkNetworkConnection[]>) => {
    //   return withPaginationPaging<BvnkNetworkConnection[]>(res)
    // },
  })
}
