import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { SidePanel, SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { appBannersId } from 'components/app/AppBanners'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { Country } from 'types/authentication/Profile'
import { BeneficiaryDetails, BeneficiaryEntityType } from 'types/beneficiaries'
import { highlightRowBasedOnId } from 'utils/table/table'
import { titleCase } from 'utils/utils'
import { BeneficiarySidePanelBody } from './BeneficiarySidePanelBody'

export type BeneficiarySummaryProps = {
  isOpen: boolean
  beneficiaryDetails: BeneficiaryDetails
  pageBeneficiaries: PagingSidePanelType
  onClose: () => void
}

export const BeneficiarySummary = ({
  isOpen,
  beneficiaryDetails,
  pageBeneficiaries,
  onClose,
}: BeneficiarySummaryProps) => {
  const { t } = useTranslation()
  const bannerSpacing = useComponentHeight(appBannersId)
  const getCountryNameByCode = (code: string, countries: Country[]): string => {
    const foundCountry = countries?.find(country => country.code === code)
    return foundCountry ? foundCountry.name : code
  }

  const aliasLabel = beneficiaryDetails?.alias ? `(${beneficiaryDetails.alias})` : ''
  const primaryLabelIndividual = `${beneficiaryDetails?.firstName} ${beneficiaryDetails?.lastName} ${aliasLabel}`
  const primaryLabelBusiness = `${beneficiaryDetails?.businessName} ${aliasLabel}`
  const getPrimaryLabel = () => {
    if (beneficiaryDetails?.entityType === BeneficiaryEntityType.BUSINESS) {
      return primaryLabelBusiness
    }
    return primaryLabelIndividual
  }

  const secondaryLabel = `${titleCase(beneficiaryDetails?.entityType)} ${t('beneficiary')}`

  const iconUrl = beneficiaryDetails?.currency
    ? `${process.env.ICONS_URL}${beneficiaryDetails.currency?.toLowerCase()}.svg`
    : process.env.ICONS_URL

  const accountNumber = beneficiaryDetails?.fiat?.accountNumber

  return (
    <SidePanel
      isOpen={isOpen}
      paging={pageBeneficiaries}
      onClose={onClose}
      activeRowId={beneficiaryDetails?.reference}
      onActiveRowChange={highlightRowBasedOnId}
      externalStyle={{ top: `${bannerSpacing}px` }}
      sidePanelHeader={{
        customSidePanelHeader: (
          <SidePanelHeader
            heading={{
              iconUrls: [iconUrl],
              primaryLabel: (
                <Box className="md:flex w-full">
                  <Text size="xl" weight="semibold" className="text-900">
                    {getPrimaryLabel()}
                  </Text>
                </Box>
              ),
              secondaryLabel: (
                <Text size="sm" className="text-500">
                  {secondaryLabel}
                </Text>
              ),
            }}
            bottomContent={{
              leftLabel: t('accountNumber'),
              leftDescription: beneficiaryDetails?.fiat?.accountNumber && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap">
                  {accountNumber.length > 30
                    ? ellipseMiddleText(accountNumber, 10, 10)
                    : accountNumber}
                </Text>
              ),
              rightLabel: t('beneficiaryPaymentReference'),
              rightDescription: beneficiaryDetails?.beneficiaryPaymentReference && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap break-words">
                  {beneficiaryDetails?.beneficiaryPaymentReference}
                </Text>
              ),
            }}
            template={2}
          />
        ),
      }}
    >
      <Box padding={24} tag="section" full background="white">
        <BeneficiarySidePanelBody
          beneficiaryDetails={beneficiaryDetails}
          getCountryNameByCode={getCountryNameByCode}
        />
      </Box>
    </SidePanel>
  )
}
