import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CriteriaKeys } from 'components/CSAT/types'
import { usePaymentCaseContext } from 'hooks/context/usePaymentCaseContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useSendPaymentCaseEmailMutation } from 'hooks/mutations/useSendPaymentCaseEmailMutation'
import { usePaymentCaseEmailsQuery } from 'hooks/queries/usePaymentCaseEmailsQuery'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PaymentCaseSendEmail } from 'types/payments'
import useCSATSurvey from '../hooks/useCSATSurvey'
import { PaymentCaseEmails } from './PaymentCaseEmails'
import { SendEmailArea } from './SendEmailInput'

export const PaymentCaseEmailsBody = () => {
  const { t } = useTranslation()
  const { enableCsatSurveyCasePortal, getIsCriteriaCompleted, completeCSATCriteria } =
    useCSATSurvey()
  const [emailMessage, setEmailMessage] = useState('')

  const { profile } = useProfileContext()
  const { paymentCaseId } = usePaymentCaseContext()
  const addToast = useToastContext()
  const queryClient = useQueryClient()
  const hasSentEmailCriteriaCompleted = getIsCriteriaCompleted(CriteriaKeys.HAS_SENT_EMAIL)

  const {
    isPending,
    isFetching: isFetchingEmails,
    isError,
  } = usePaymentCaseEmailsQuery(paymentCaseId)

  const { mutate, isPending: isSendingEmail } = useSendPaymentCaseEmailMutation()

  const sendEmail = () => {
    const emailPayload: PaymentCaseSendEmail = {
      caseId: paymentCaseId,
      fromAddress: profile?.emailAddress,
      htmlBody: emailMessage?.trim()?.replaceAll('\n', '<br/>'),
    }

    mutate(emailPayload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: reactQueryKeys.paymentCaseEmails(paymentCaseId),
        })

        setEmailMessage('')

        // reset dom manipulated height
        const caseEmailInput = document.getElementById('caseEmailInputArea')

        if (caseEmailInput?.style) {
          caseEmailInput.style.height = '21px'
        }

        addToast({ title: t('paymentSupport.successEmailSent'), state: 'success' })

        !hasSentEmailCriteriaCompleted && enableCsatSurveyCasePortal && handleCSATSurveyUpdate()
      },
      onError: () => {
        addToast({ title: t('paymentSupport.errorEmailSend'), state: 'error' })
      },
    })
  }

  const handleCSATSurveyUpdate = () => {
    completeCSATCriteria(CriteriaKeys.HAS_SENT_EMAIL)
  }

  return (
    <>
      <Box id="caseEmailsList" paddingX={20} overflowY="auto" className="h-[calc(100%-151px)]">
        <PaymentCaseEmails />
      </Box>
      {!isPending && !isError && (
        <>
          {createPortal(
            <SendEmailArea
              isLoading={isSendingEmail || isFetchingEmails}
              value={emailMessage}
              onChange={e => setEmailMessage(e.target.value)}
              placeHolder={t('paymentSupport.typeMessage')}
              sendEmail={sendEmail}
            />,
            document.getElementById('caseDetailsBody')
          )}
        </>
      )}
    </>
  )
}
