import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextList } from '@node-space/storybook-components/dist/TextList'

export const RecaptchaScoreError = () => {
  const { t } = useTranslation()

  return (
    <Box flex direction="col" alignItems="start" gapY={16} aria-label="low recaptcha score error">
      <Text size="sm" color="inherit">
        {t('recaptcha.verificationFailed')}
      </Text>
      <Text size="sm" color="inherit">
        {t('recaptcha.scoreError.description')}
      </Text>

      <TextList
        textColor="inherit"
        listStyle="bullet"
        listItems={[
          {
            key: 'multipleAttempts',
            children: t('recaptcha.scoreError.multipleAttempts'),
          },
          {
            key: 'vpn',
            children: t('recaptcha.scoreError.vpn'),
          },
          {
            key: 'browser',
            children: t('recaptcha.scoreError.browser'),
          },
        ]}
      />
    </Box>
  )
}
