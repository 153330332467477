import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'

type TComplexTooltip = { isRoleTooltipDisplayed: boolean; isApproverTooltipDisplayed: boolean }

interface ComplexTooltipProps {
  complexTooltip: TComplexTooltip
  setComplexTooltip: ({
    isRoleTooltipDisplayed,
    isApproverTooltipDisplayed,
  }: TComplexTooltip) => void
}

export const ComplexTooltip = ({ complexTooltip, setComplexTooltip }: ComplexTooltipProps) => {
  const { t } = useTranslation()

  const CalloutFooter = (): JSX.Element => (
    <Callout state="info">
      <Text size="sm">
        {t('teamMember.roleComplexTooltipCalloutPrefix')}
        <Link
          to={process.env.ABOUT_APPROVAL_LINK}
          className="ml-1 text-primary-500"
          target="_blank"
        >
          {t('teamMember.thisArticle')}
        </Link>
        {` ${t('teamMember.roleComplexTooltipCalloutSuffix')}`}
      </Text>
    </Callout>
  )

  return (
    <Modal
      closeOnBackgroundClick={true}
      closeModal={() =>
        setComplexTooltip({ isRoleTooltipDisplayed: false, isApproverTooltipDisplayed: false })
      }
      visible
    >
      <Box flex justifyContent="end" padding={20}>
        <Button
          noStyling
          onClick={() =>
            setComplexTooltip({ isRoleTooltipDisplayed: false, isApproverTooltipDisplayed: false })
          }
        >
          <Icon color="inherit" name="CloseIcon" />
        </Button>
      </Box>
      {complexTooltip?.isApproverTooltipDisplayed && (
        <Box paddingY={16} paddingX={24} className="relative bottom-5">
          <Text size="sm" color="dark-grey">
            {t('teamMember.approver')}
          </Text>
          <Text size="sm" color="disabled" className="mb-3">
            {t('teamMember.approverComplexTooltipDescription')}
          </Text>
          <CalloutFooter />
        </Box>
      )}
      {complexTooltip?.isRoleTooltipDisplayed && (
        <Box paddingX={24} paddingY={16} className="relative bottom-5">
          <Text size="sm" color="dark-grey">
            {t('teamMember.workspaceOwner')}
          </Text>
          <Text size="sm" color="disabled" className="mb-3">
            {t('teamMember.workspaceOwnerComplexTooltipDescription')}
          </Text>
          <Text size="sm" color="dark-grey">
            {t('teamMember.administrator')}
          </Text>
          <Text size="sm" color="disabled" className="mb-3">
            {t('teamMember.administratorComplexTooltipDescription')}
          </Text>
          <Text size="sm" color="dark-grey">
            {t('teamMember.collaborator')}
          </Text>
          <Text size="sm" color="disabled" className="mb-3">
            {t('teamMember.collaboratorComplexTooltipDescription')}
          </Text>
          <Text size="sm" color="dark-grey">
            {t('teamMember.viewer')}
          </Text>
          <Text size="sm" color="disabled" className="mb-5">
            {t('teamMember.viewerComplexTooltipDescription')}
          </Text>
          <CalloutFooter />
        </Box>
      )}
    </Modal>
  )
}
