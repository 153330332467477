import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { IconSelect } from '@node-space/storybook-components/dist/IconSelect'
import { Modal, ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { logError } from '@node-space/utils'
import { useFiatCurrenciesQuery } from 'hooks/queries'
import { useToastContext } from 'hooks/useToastContext'
import { changeCurrencyPref } from 'services/UserDetailsService'

interface DisplaySettingsProps {
  userCurrency: string
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const DisplaySettings = ({ isOpen, userCurrency, onClose, onSuccess }: DisplaySettingsProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const [currency, setCurrency] = useState(userCurrency)
  const [isLoading, setIsLoading] = useState(false)
  const { data: currencyData, isPending: isFiatCurrenciesLoading } = useFiatCurrenciesQuery()

  const currencyOptions =
    currencyData?.map(currency => ({
      label: `${currency?.name} (${currency?.code})`,
      value: currency?.code,
    })) || []

  const onSave = async () => {
    setIsLoading(true)
    try {
      await changeCurrencyPref(currency)
      addToast({ title: t('account.displaySettingsSuccess'), state: 'success' })
      onSuccess()
      onClose()
    } catch (error) {
      const {
        data: { errorList },
      } = error
      const errorMessage = `${errorList?.[0]?.message}`
      errorMessage &&
        addToast({
          title: errorMessage,
          state: 'error',
          delay: 20,
        })
      logError(`Error from DisplaySettings - onSave`, error)
    }
    setIsLoading(false)
  }

  const actions: ButtonProps[] = [
    {
      children: t('save'),
      testid: 'save-display-settings',
      loading: isLoading,
      onClick: () => onSave(),
    },
  ]

  return (
    <Modal visible={isOpen} closeModal={onClose} closeOnBackgroundClick>
      <ModalNavBar title={t('account.editDisplaySettings')} onClose={onClose} />
      <div className="py-5 px-6">
        <IconSelect
          iconsUrl={`${process.env.ICONS_URL}`}
          label={t('currency')}
          name="currency-preference"
          options={currencyOptions}
          loading={isFiatCurrenciesLoading}
          onChange={value => setCurrency(value)}
          isSearchable={true}
          value={currency}
        />
      </div>
      <ModalActions actions={actions} />
    </Modal>
  )
}

export default DisplaySettings
