import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { useWalletContext } from 'hooks/context'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useFiatWalletActionSupport } from 'hooks/useFiatWalletActionSupport'
import { useMappedRoles } from 'hooks/useMappedRoles'
import WalletModal, { WalletAction } from 'pages/Wallets/components/Modal/WalletModal'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface WalletButtonsFiatProps {
  disabled?: boolean
}

export const WalletButtonsFiat = ({ disabled }: WalletButtonsFiatProps) => {
  const { t } = useTranslation()
  const permissions = useMappedRoles()
  const [walletModalAction, setWalletModalAction] = useState<WalletAction>(undefined)
  const queryClient = useQueryClient()
  const wallet = useWalletContext()
  const walletProperties = {
    currency: wallet?.currency?.code,
    supportsWithdrawals: wallet?.supportsWithdrawals,
    supportsThirdParty: wallet?.supportsThirdParty,
    supportsDeposits: wallet?.supportsDeposits,
    partner: wallet?.partner,
  }
  const { supportsPay, supportsDeposits, supportsWithdrawals } =
    useFiatWalletActionSupport(walletProperties)

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.wallet(wallet.id) })
  }

  const isSelectedWalletActive = useIsActiveWallet(wallet)

  const handleOpenPayModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_PAY, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
    setWalletModalAction({
      key: 'pay',
      data: {
        id: String(wallet.id),
      },
    })
  }

  const handleOpenConvertModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_CONVERT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
      isFiat: true,
    })
    setWalletModalAction({
      key: 'convert',
      data: {
        id: String(wallet.id),
      },
    })
  }

  const handleOpenWithdrawModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_WITHDRAW, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
    setWalletModalAction({
      key: 'withdraw',
      data: {
        id: String(wallet.id),
      },
    })
  }

  const handleOpenDepositModal = () => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_DEPOSIT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
    setWalletModalAction({
      key: 'deposit',
      data: {
        id: String(wallet.id),
      },
    })
  }

  const canMakeNetworkTranfer = permissions?.bvnkNetwork?.canTrade
  const isPayEnabled = wallet?.supportsInternalBvnkNetworkTransfers
    ? canMakeNetworkTranfer
    : !disabled

  return (
    isSelectedWalletActive && (
      <Box flex padding={8} className="gap-4 sm:pt-0">
        {supportsPay && (
          <Button
            testid="buttonPay"
            disabled={!isPayEnabled}
            onClick={handleOpenPayModal}
            iconElement={<Icon color="white" name="PaymentIcon" />}
          >
            {t('pay')}
          </Button>
        )}
        <Button
          testid="buttonConvert"
          disabled={disabled}
          onClick={handleOpenConvertModal}
          iconElement={<Icon color="white" name="ConvertIcon" />}
        >
          {t('convert')}
        </Button>
        {supportsWithdrawals && (
          <Button
            testid="buttonWithdraw"
            disabled={disabled}
            onClick={handleOpenWithdrawModal}
            iconElement={<Icon color="white" name="WithdrawIcon" />}
          >
            {t('withdraw')}
          </Button>
        )}
        {supportsDeposits && (
          <Button
            testid="buttonDeposit"
            disabled={disabled}
            onClick={handleOpenDepositModal}
            iconElement={<Icon color="white" name="DepositIcon" />}
          >
            {t('deposit')}
          </Button>
        )}
        <WalletModal
          action={walletModalAction}
          setWalletModalAction={setWalletModalAction}
          refresh={handleRefresh}
        />
      </Box>
    )
  )
}
