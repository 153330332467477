import dayjs from 'dayjs'
import { PaymentMerchantDefault, PaymentStatus, PaymentTag } from 'types/payments'

const INITIAL_FROM_DATE_LAST_6_MONTHS: Date = dayjs().subtract(6, 'month').toDate()
const INITIAL_TO_DATE: Date = dayjs().toDate()

export interface FilterState {
  from: Date | null
  to: Date | null
  search: string
  merchant: string
  status: string
  isSearchUsed: boolean
  isFilterModalOpen: boolean
  isTouched: boolean
  tag: string
}

export const rootInitialState: FilterState = {
  from: INITIAL_FROM_DATE_LAST_6_MONTHS,
  to: INITIAL_TO_DATE,
  search: '',
  merchant: PaymentMerchantDefault.ALL,
  status: PaymentStatus.ALL,
  isSearchUsed: false,
  isFilterModalOpen: false,
  isTouched: false,
  tag: PaymentTag.ALL,
}

interface Action<T = unknown> {
  type: T
}

export interface UnknownAction extends Action {
  [extraProps: string]: unknown
}

export const rootReducer = (state: FilterState, action: UnknownAction) => {
  switch (action.type) {
    case 'UPDATE_IS_FILTER_MODAL_OPEN':
      return {
        ...state,
        isFilterModalOpen: !!action.payload || false,
      }
    case 'TOGGLE_IS_FILTER_MODAL_OPEN':
      return {
        ...state,
        isFilterModalOpen: !state.isFilterModalOpen,
      }
    case 'UPDATE_MERCHANT':
      return {
        ...state,
        merchant: action.payload as string,
      }
    case 'RESET_MERCHANT':
      return {
        ...state,
        merchant: rootInitialState.merchant as string,
      }
    case 'UPDATE_FROM':
      return {
        ...state,
        from: action.payload as Date,
      }
    case 'UPDATE_TO':
      return {
        ...state,
        to: action.payload as Date,
      }
    case 'RESET_DATE':
      return {
        ...state,
        from: rootInitialState.from as Date,
        to: rootInitialState.to as Date,
      }
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload as string,
      }
    case 'SET_SEARCH_IS_USED':
      return {
        ...state,
        isSearchUsed: action.payload as boolean,
      }
    case 'RESET_SEARCH':
      return {
        ...state,
        search: '',
        isSearchUsed: false,
      }
    case 'UPDATE_STATUS':
      return {
        ...state,
        status: action.payload as string,
      }
    case 'RESET_STATUS':
      return {
        ...state,
        status: rootInitialState.status,
      }
    case 'UPDATE_TAG':
      return {
        ...state,
        tag: action.payload as string,
      }
    case 'RESET_TAG':
      return {
        ...state,
        status: rootInitialState.tag,
      }
    case 'UPDATE_TOUCHED':
      return {
        ...state,
        isTouched: action.payload as boolean,
      }
    case 'SET_DATES_TO_NULL':
      return {
        ...state,
        to: null,
        from: null,
      }
    default:
      return state
  }
}
