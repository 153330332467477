import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Select } from '@node-space/storybook-components/dist/Select'
import { Input } from '@node-space/storybook-components/dist'
import { AddBeneficiaryFormErrors, BaseFormProps } from 'types/beneficiaries'

interface ZarFormFieldsProps extends BaseFormProps {
  routingNumberBranchCode: string
  selectedBank: string
  bankOptions?: {
    value: string
    label: string
  }[]
  accountNumber: string
  accountType: string
  errors: AddBeneficiaryFormErrors
  isFetchingBanks: boolean
  setValue: (name: string, value: string) => void
}

export const ZarFormFields = ({
  routingNumberBranchCode,
  accountNumber,
  accountType,
  selectedBank,
  bankOptions,
  errors,
  isFetchingBanks,
  handleChange,
  register,
  setValue,
}: ZarFormFieldsProps) => {
  const { t } = useTranslation()

  const accountTypeOptions = [
    {
      label: t('cheque'),
      value: 'CHEQUE',
    },
    {
      label: t('savings'),
      value: 'SAVINGS',
    },
    {
      label: t('transmission'),
      value: 'TRANSMISSION',
    },
  ]

  const handleBankOnChange = (bankCode: string) => {
    handleChange('zarBank', bankCode)
    const bankName = bankOptions?.find(bank => bank?.value === bankCode)?.label
    if (bankName) {
      handleChange('bankName', bankName)
    }
  }

  return (
    <>
      <Box flexSize="fill" paddingB={16}>
        <Select
          {...register('zarBank')}
          label={t('bank')}
          value={selectedBank}
          isSearchable
          options={bankOptions}
          error={!!errors?.zarBank}
          errorText={errors?.zarBank?.message}
          placeholder={t('selectABank')}
          onChange={value => handleBankOnChange(value)}
          loading={isFetchingBanks}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('zarAccountNumber')}
          label={t('accountNumber')}
          placeholder={t('enterAccountNumber')}
          inputMode="numeric"
          name="zarAccountNumber"
          testid="input-zar-account-number"
          errorText={errors?.zarAccountNumber?.message}
          error={!!errors?.zarAccountNumber}
          value={accountNumber}
          onChange={event => handleChange('zarAccountNumber', event?.target?.value)}
        />
      </Box>
      <Box paddingB={16}>
        <Input
          {...register('zarRoutingNumberBranchCode')}
          label={t('manageBeneficiaries.abaRoutingCode')}
          placeholder={t('enterCode')}
          inputMode="numeric"
          name="zarRoutingNumberBranchCode"
          testid="input-zar-routing-number"
          errorText={errors?.zarRoutingNumberBranchCode?.message}
          error={!!errors?.zarRoutingNumberBranchCode}
          value={routingNumberBranchCode}
          onChange={event => handleChange('zarRoutingNumberBranchCode', event?.target?.value)}
        />
      </Box>
      <Box flexSize="fill" paddingB={16}>
        <Select
          {...register('zarAccountType')}
          label={t('accountType')}
          value={accountType}
          isSearchable
          options={accountTypeOptions}
          error={!!errors?.zarAccountType}
          errorText={errors?.zarAccountType?.message}
          placeholder={t('selectYourAccountType')}
          onChange={value => setValue('zarAccountType', value)}
        />
      </Box>
    </>
  )
}
