import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@node-space/hooks'
import AppLoader from 'components/AppLoader'
import { PathNames } from 'constants/General'
import { ExternalLinks } from 'constants/links'
import { useAuthenticatedPathRedirect } from 'hooks/useAuthenticatedPathRedirect'
import { performAuthRedirect } from 'utils/auth/utils'

export interface AuthenticateProps {
  isLoadingData: boolean
  isOnboardingAccount: boolean
}

export const Authenticate = ({ isLoadingData, isOnboardingAccount }) => {
  const navigate = useNavigate()
  const { isAuthed } = useAuth()
  const { redirectToLanding } = useAuthenticatedPathRedirect()

  useEffect(() => {
    if (isLoadingData) return

    if (!isAuthed) {
      navigate(PathNames.LOGOUT, { replace: true })
      return
    }

    if (isOnboardingAccount) {
      performAuthRedirect(ExternalLinks.ONBOARDING)
      return
    }

    redirectToLanding()
  }, [isAuthed, isLoadingData, isOnboardingAccount])

  return <AppLoader />
}
