import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import Loader from '@node-space/storybook-components/dist/Loader'
import { SidePanel, SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Text } from '@node-space/storybook-components/dist/Text'
import { appBannersId } from 'components/app/AppBanners'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { Country } from 'types/authentication/Profile'
import { highlightRowBasedOnId } from 'utils/table/table'
import { FiatPayout, PreProcessedPayouts } from '../types'
import { isFiatB2CBatch } from '../utils'
import { MassPayoutSidePanelBody } from './MassPayoutSidePanelBody'

export type MassPayoutSummaryProps = {
  isOpen: boolean
  massPayout: FiatPayout
  preProcessedPayouts: PreProcessedPayouts
  pageMassPayout: PagingSidePanelType
  onClose: () => void
  isLoadingMassFiatPayoutDetail: boolean
}

export type SidePanelBodyDataProps = {
  preProcessedPayouts: PreProcessedPayouts
  country: string
}

export const MassPayoutSidePanel = ({
  isOpen,
  preProcessedPayouts,
  massPayout,
  pageMassPayout,
  onClose,
  isLoadingMassFiatPayoutDetail,
}: MassPayoutSummaryProps) => {
  const { t } = useTranslation()
  const bannerSpacing = useComponentHeight(appBannersId)
  const getCountryNameByCode = (code: string, countries: Country[]): string => {
    const foundCountry = countries?.find(country => country.code === code)
    return foundCountry ? foundCountry.name : code
  }

  const isB2C = useMemo(() => {
    return isFiatB2CBatch(massPayout?.preProcessedPayouts)
  }, [massPayout?.preProcessedPayouts])

  const { data: countries } = useCountriesQuery(true)

  const primaryLabel = isB2C
    ? `${preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary?.firstName} ${preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary?.lastName}`
    : `${preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary?.businessName}`

  const secondaryLabel = isB2C
    ? t('massPayouts.individualBeneficiary')
    : t('massPayouts.businessBeneficiary')

  const iconUrl = `${process.env.ICONS_URL}${massPayout?.currency?.toLowerCase()}.svg`

  const sidePanelBodyData: SidePanelBodyDataProps = {
    preProcessedPayouts,
    country: getCountryNameByCode(
      preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary?.firstName,
      countries
    ),
  }

  return (
    <SidePanel
      isOpen={isOpen}
      paging={pageMassPayout}
      onClose={onClose}
      activeRowId={massPayout?.walletId}
      onActiveRowChange={highlightRowBasedOnId}
      externalStyle={{ top: `${bannerSpacing}px` }}
      sidePanelHeader={{
        customSidePanelHeader: (
          <SidePanelHeader
            heading={{
              iconUrls: [iconUrl],
              primaryLabel: (
                <Box className="md:flex w-full">
                  <Text size="xl" weight="semibold" className="text-900">
                    {primaryLabel}
                  </Text>
                </Box>
              ),
              secondaryLabel: (
                <Text size="sm" className="text-500">
                  {secondaryLabel}
                </Text>
              ),
            }}
            bottomContent={{
              leftLabel: t('accountNumber'),
              leftDescription: preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary
                ?.accountNumber && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap">
                  {preProcessedPayouts?.beneficiaryDetails?.enrichedBeneficiary?.accountNumber}
                </Text>
              ),
              rightLabel: t('beneficiaryPaymentReference'),
              rightDescription: preProcessedPayouts?.paymentReference && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap break-words">
                  {preProcessedPayouts?.paymentReference}
                </Text>
              ),
            }}
            template={2}
          />
        ),
      }}
    >
      <Box padding={24} tag="section" full background="white">
        {isLoadingMassFiatPayoutDetail ? (
          <Box flex justifyContent="center">
            <Loader theme="dark" size="large" />
          </Box>
        ) : (
          <MassPayoutSidePanelBody sidePanelBodyData={sidePanelBodyData} isB2CPayout={isB2C} />
        )}
      </Box>
    </SidePanel>
  )
}
