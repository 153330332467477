import React from 'react'
import { Navigate } from 'react-router-dom'
import { PathNames } from 'constants/General'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useProfileSetting } from 'hooks/useProfileSetting'
import { ProfileSettingType } from 'types/profileSettings'

export interface ProfileSettingProtectedPageProps {
  profileSettingName: ProfileSettingType
  rolePermissionName?: string
  /** @param hasOverride - set to true if you want to enable the page, for example to test staging via a feature flag */
  hasOverride?: boolean
  children: React.ReactNode
}

export const ProfileSettingProtectedPage = ({
  profileSettingName,
  rolePermissionName,
  hasOverride,
  children,
}: ProfileSettingProtectedPageProps) => {
  const profileHasAccess = useProfileSetting(profileSettingName)
  const permissions = useMappedRoles()

  const hasRolePermission = permissions?.[rolePermissionName]?.canView

  if ((hasOverride || profileHasAccess) && hasRolePermission) {
    return <>{children}</>
  }

  return <Navigate to={PathNames.NOT_FOUND} />
}
