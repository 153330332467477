import React, { useCallback, useEffect, useState } from 'react'
import { MerchantsProvider } from 'contexts'
import { useTranslation } from 'react-i18next'
import { Box, Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import EmptyContainer from 'components/EmptyContainer'
import ChannelsEmptyState from 'components/EmptyStates/ChannelsEmptyState'
import { RequestError } from 'components/Errors/RequestError'
import FilterBar from 'components/FilterBar'
import { rootInitialState } from 'components/FilterBar/context/reducer'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import useFilterSearch from 'components/FilterBar/hooks/useFilterSearch'
import { useChannelsQuery, useMerchantsQuery, useSupportedCurrenciesQuery } from 'hooks/queries'
import CreateChannelModal from 'pages/Channels/components/Modal/ChannelCreateModal'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useMappedRoles } from '../../hooks/useMappedRoles'
import ChannelsTable from './components/ChannelsTable'
import { Pagination } from './components/Pagination'
import { pageListOptions } from './constants/contants'

const ManageChannels = () => {
  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { data: currencies, isPending: isCurrenciesLoading } = useSupportedCurrenciesQuery()
  const [page, setPage] = useState(0)
  const [nrOfRows, setNrOfRows] = useState(pageListOptions[0].value)
  const [offset, setOffset] = useState(0)
  const { merchant, setMerchant, resetMerchant } = useFilterMerchant()
  const { search, setSearch, resetSearch, resetIsSearchUsed } = useFilterSearch()
  const {
    data: { channels = [], paging } = {},
    isFetching: isChannelsFetching,
    isError: isChannelsError,
    refetch: refetchChannels,
  } = useChannelsQuery({
    max: Number(nrOfRows),
    offset,
    merchant,
    search,
  })

  const { data: { merchantList = [] } = {} } = useMerchantsQuery()

  const onMerchantSelected = useCallback(
    merchant => {
      setMerchant(merchant)
    },
    [merchant, search, Number(nrOfRows)]
  )

  useEffect(() => {
    refetchChannels()
  }, [search, offset])

  useEffect(() => {
    if (search == rootInitialState?.search && merchant == rootInitialState?.merchant) {
      refetchChannels()
    }
    setOffset(0)
  }, [search, merchant])

  useEffect(() => {
    return () => {
      resetSearch()
      resetMerchant()
    }
  }, [])

  const onResetAll = () => {
    setMerchant(rootInitialState?.merchant)
    setSearch(rootInitialState?.search)
    resetIsSearchUsed()
    resetMerchant()
  }

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_CHANNEL, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const isLoading = isChannelsFetching || isCurrenciesLoading
  const permissions = useMappedRoles().channels
  const canEdit = permissions?.canEdit
  const hasActiveFilters = merchant !== 'ALL' || search.length

  return (
    <Wrapper className="channels-container">
      <PageHeader
        description={t('manageChannelsIntroText')}
        {...{
          renderRightSection: canEdit && (
            <Box direction="row" flex>
              <Button
                className="mr-3"
                onClick={() => {
                  setShowCreateModal(true)
                  track.Amp.track(AmplitudeEvent.CHANNEL_INITIATE, {
                    category: AmplitudeEventCategory.MERCHANT_PORTAL,
                    action: AmplitudeEventAction.CLICK,
                  })
                }}
                iconElement={<Icon name="PlusIcon" color="white" />}
              >
                {`${t('payments.channels.createChannel')}`}
              </Button>
            </Box>
          ),
        }}
      />

      {!search && !channels?.length && merchant == 'ALL' ? (
        isLoading ? (
          <Box flex middle className="mt-10">
            <Loader theme="dark" size="large" />
          </Box>
        ) : (
          <ChannelsEmptyState
            title={t('noChannelsFound')}
            description={t('noChannelsCreateChannel')}
            ctaBtnText={t('payments.channels.createChannel')}
            descriptionChildren={
              <a
                href={process.env.CHANNELS_LEARN_MORE}
                className="ml-1 text-blue-500 text-sm"
                target="_blank"
                rel="noreferrer"
              >
                {t('learnMore')}
              </a>
            }
            createChannelCallback={() => {
              setShowCreateModal(true)
              track.Amp.track(AmplitudeEvent.CHANNEL_INITIATE, {
                category: AmplitudeEventCategory.MERCHANT_PORTAL,
                action: AmplitudeEventAction.CLICK,
              })
            }}
            viewOnly={canEdit}
          />
        )
      ) : (
        <>
          <FilterBar
            eventHandlers={{
              executor: refetchChannels,
              onMerchantSelected,
              showSearchResult: refetchChannels,
              onResetAll,
            }}
            conditions={{ isExportingCSV: false }}
            isChannelManagement={true}
          />
          {isLoading && (
            <Box flex middle className="mt-10">
              <Loader theme="dark" size="large" />
            </Box>
          )}
          {isChannelsError && (
            <Box className="mt-10">
              <RequestError />
            </Box>
          )}

          {!isLoading && !channels?.length && hasActiveFilters && !isChannelsError && (
            <EmptyContainer>
              <EmptyContainer>
                <Text size="base" weight="medium" color="black">
                  {t('noChannelsFoundFiltered')}.
                </Text>

                <TextAction
                  onClick={onResetAll}
                  text={`${t('resetAllFilters')}?`}
                  className="ml-2"
                />
              </EmptyContainer>
            </EmptyContainer>
          )}
          {!isLoading && !!channels?.length && !isChannelsError && (
            <>
              <ChannelsTable channels={channels} currencies={currencies} />
              {paging?.total > Number(pageListOptions[0].value) && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  setOffset={setOffset}
                  pagination={paging}
                  nrOfRows={nrOfRows}
                  setNrOfRows={setNrOfRows}
                />
              )}
            </>
          )}
        </>
      )}

      {showCreateModal && (
        <MerchantsProvider merchants={merchantList}>
          <CreateChannelModal closeCallback={() => setShowCreateModal(false)} />
        </MerchantsProvider>
      )}
    </Wrapper>
  )
}

export default React.memo(ManageChannels)
