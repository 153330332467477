import { Approval } from 'constants/General'

export interface GetAccountApprovalsPreference {
  preference?: string
  flow?: string
  parentId: string
}

export interface AccountApprovalsPreference {
  flow?: string
  enabled: boolean
}

export interface UpdateAccountApprovalsPreferenceV1 {
  preference?: string
  flow?: string
  parentId: string
  enabled: boolean
}

export interface UpdateAccountApprovalsPreferenceV2 {
  preference: string
  parentId: string
  preferences: AccountApprovalsPreference[]
  limits?: ApprovalRulesData[]
}

export interface AccountApprovalsPreferenceError {
  code: string
  data: AccountApprovalsPreferenceErrorData
  errorList: unknown
  message: string
  status: number
}

export interface AccountApprovalsPreferenceErrorData {
  code: string
  error: string
  message: string
  status: number
}

export interface ApprovalWorkflowActionPreRequest {
  action: 'APPROVE' | 'REJECT'
  reference: string[]
  reason?: string
}

export interface ApprovalOperationChallengeRequest {
  challenge: string
  tokenResponse: string
}

export interface ApprovalWorkflowActionResponse {
  code?: string
  data?: {
    challenge: {
      value: string
      type: string
      ttl: string
    }
    callbackUrl: string
    context: {
      code: string
      message: string
    }
  }
  message?: string
  status?: number
}

export interface ApprovalPreferences {
  flow:
    | 'ALL_PAYOUT'
    | 'FIAT_1ST_PARTY_PAYOUT'
    | 'FIAT_3RD_PARTY_PAYOUT'
    | 'CRYPTO_PAYOUT'
    | 'CRYPTO_SEND'
  approvalsRequired: boolean
}

export interface GetApprovalPreferences {
  limits: ApprovalRulesData[]
  preferences: ApprovalPreferences[]
}

export interface UpdateAccountAprrovers {
  updateUserRoleGroups: {
    emailAddress: string
    approver: boolean
  }[]
}

export interface PendingTransactionsResponse {
  correlationKey: string
  status: keyof typeof ApprovalStatuses
  amount: string
  type: string
  currency: {
    code: string
    name: string
  }
  beneficiaryDetails: {
    bankCode?: string
    accountNumber?: string
    beneficiaryName?: string
    destinationWallet?: string
  }
  walletName: string
  initiatedBy: string
  initiatedByEmail?: string
  actionedBy: string
  userReference?: string
  initiatedAt: string
  actionedAt: string
  rejectionReason: string
  fromAmount?: string
  fromCurrency?: string
  canApprove?: boolean
  approvalsCounter?: ApprovalsCounter
  processedBy?: ApprovalsProcessedBy[]
}

export interface ApprovalsCounter {
  approvalsAcquired: number
  approvalsRequired: number
}

interface ApprovalsProcessedBy {
  status: string
  userFullName: string
  email: string
  eventTime: string
}

export const ApprovalStatuses = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
}

export const ApprovalActionStatuses = {
  PAYOUT_MERCHANT_APPROVAL_APPROVED: 'PAYOUT_MERCHANT_APPROVAL_APPROVED',
  PAYOUT_MERCHANT_APPROVAL_REJECTED: 'PAYOUT_MERCHANT_APPROVAL_REJECTED',
}

export interface ApprovalRulesData {
  minAmount: number
  maxAmount: number | string
  approvalsCount: number
}

export type ApprovalDecisionStateType = {
  decision: keyof typeof Approval.DECISION
  showApprovalDecisionModal: boolean
  isCompleted: boolean
}

export type BasisOfApprovalParam = {
  initiatedByEmail?: string
  canApprove?: boolean
}

export type UnapprovableTransactions = {
  selfInitiatedTransactions: unknown[]
  cannotApproveTransactions: unknown[]
}

export type ApprovalDecision = keyof typeof Approval.DECISION
