import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Box, { Box as Container } from '@node-space/storybook-components/dist/Box'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { LogoHeader } from 'components/header/LogoHeader'
import { PathNames } from 'constants/General'
import { ExternalLinks } from 'constants/links'
import { forceLogout } from 'utils/auth/utils'
import { redirectToPath } from 'utils/utils'

const AccountError = () => {
  const { t } = useTranslation()
  const { state: sentryTraceId } = useLocation()

  return (
    <Container height="screen" width="full" flex direction="col">
      <LogoHeader />
      <Box flex height="full" centerBox centerChildren maxWidth={896}>
        <StatusCard
          status="error"
          headline={t('errorMessages.errorHeading')}
          description={
            <>
              {` ${t('errorMessages.weApologiseForInconvenience')}`}
              {` ${t('errorMessages.pleaseTryTo')} `}
              <TextAction
                text={t('errorMessages.refreshPage')}
                onClick={() => {
                  redirectToPath(PathNames.DASHBOARD)
                }}
              />
              {` ${t('errorMessages.or')} `}
              <TextAction
                text={t('errorMessages.login.retry')}
                onClick={() => {
                  forceLogout()
                }}
              />
              {`. `}
              {` ${t('errorMessages.ifTheProblemPersists')} `}
              {` ${t('errorMessages.please')} `}
              <TextAction
                text={t('errorMessages.contactSupport')}
                linkTo={ExternalLinks.CONTACT_SUPPORT}
              />
              {` ${t('errorMessages.forAssistance')}`}
            </>
          }
          borderless
        >
          {sentryTraceId && (
            <Text size="xs" color="grey">
              {t('errorMessages.traceId')}: {sentryTraceId}
            </Text>
          )}
        </StatusCard>
      </Box>
    </Container>
  )
}

export default AccountError
