import { useContext, useEffect } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterSearch = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  useEffect(() => {
    if (state?.search?.length && !state?.isSearchUsed) {
      dispatch({ type: 'SET_SEARCH_IS_USED', payload: true })
    }
  }, [state?.search?.length])

  return {
    search: state?.search,
    setSearch: (value: string) => dispatch({ type: 'UPDATE_SEARCH', payload: value }),
    isSearchUsed: state?.isSearchUsed,
    resetIsSearchUsed: () => dispatch({ type: 'SET_SEARCH_IS_USED', payload: false }),
    resetSearch: () => dispatch({ type: 'RESET_SEARCH' }),
  }
}

export default useFilterSearch
