import React, { useEffect, useMemo, useState } from 'react'
import { useSetAtom } from 'jotai'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import Box, {
  Box as Container,
  Box as LoadingContainer,
  Box as Row,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import { Text } from '@node-space/storybook-components/dist/Text'
import Loader from 'components/spinners/LoadingAnimationDark'
import { PathNames } from 'constants/General'
import { useBeneficiariesQuery } from 'hooks/queries/useBeneficiariesQuery'
import useBeneficiaryPayoutDisclaimer from 'hooks/useBeneficiaryPayoutDisclaimer'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { BeneficiaryTransferDestination, BeneficiaryType } from 'types/beneficiaries'
import { Currencies } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { getTransferDestinations } from 'utils/beneficiaries'
import track from 'utils/tracker'
import { formatString, roundNumberWithCommas } from 'utils/utils'
import { selectedFiatBeneficiaryAtom, selectedWalletAtom } from './atoms/atoms'
import PayCalloutsContainers from './components/PayCalloutsContainers'
import useIsB2cPayoutsEnabled from './hooks/useIsB2cPayoutsEnabled'
import { PayActionProps } from './Pay'
import { getBeneficiaryOptions, resetFields, setFormDetails } from './utils/payFormUtils'

const PayFormBenficiary = ({
  wallets,
  form,
  loading: isLoading,
  isLimitsError,
  registerFormField,
  dismissAction,
  setStep,
}: PayActionProps) => {
  const { t } = useTranslation()
  const { enableBeneficiaryIndividual } = useFeatureFlags()
  const permissions = useMappedRoles()
  const [isHelperTextDisplayed, setIsHelperTextDisplayed] = useState(false)
  const canAddBeneficiary = permissions?.manageBeneficiaries?.canEdit
  const formValues = form.watch()
  const setSelectedWalletAtom = useSetAtom(selectedWalletAtom)
  const setSelectedFiatBeneficiaryAtom = useSetAtom(selectedFiatBeneficiaryAtom)

  const selectedWallet =
    formValues.walletId && wallets.find(wallet => wallet?.id.toString() === formValues?.walletId)

  const { data: { content: beneficiaryList } = {}, isError: isBeneficiaryListError } =
    useBeneficiariesQuery({
      sort: 'businessName',
      size: 1000,
      beneficiaryType: BeneficiaryType.THIRD_PARTY,
      currency: selectedWallet?.currency?.code as Currencies,
    })

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.PAY_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code,
      walletId: selectedWallet?.id,
    })
  }, [])

  const filteredBeneficiaryList = useMemo(() => {
    return beneficiaryList?.filter(
      beneficiary => beneficiary?.currency === selectedWallet?.currency?.code
    )
  }, [selectedWallet, beneficiaryList])

  const selectedBeneficiary = useMemo(() => {
    if (!formValues?.beneficiarySelection && !filteredBeneficiaryList?.length) return

    return filteredBeneficiaryList?.find(
      beneficiary => beneficiary?.reference === formValues?.beneficiarySelection
    )
  }, [formValues?.beneficiarySelection, filteredBeneficiaryList])

  useEffect(() => {
    if (!selectedBeneficiary) return
    resetFields(form)
    setFormDetails(form, selectedWallet, selectedBeneficiary)
    setSelectedFiatBeneficiaryAtom(selectedBeneficiary)
  }, [selectedBeneficiary, filteredBeneficiaryList])

  useEffect(() => {
    setSelectedWalletAtom(selectedWallet)
  }, [selectedWallet])

  const isInsufficientBalance = selectedWallet?.balance <= 0

  const calloutItems = useMemo(() => {
    const callouts = []
    const currency = selectedWallet?.currency?.code

    if (!enableBeneficiaryIndividual) {
      callouts.push(t('payBusinessInstruction'))
    }

    if (selectedBeneficiary?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL) {
      callouts.push(t('internationalPayInstruction', { currency }))
    } else if (currency === Currencies.EUR) {
      callouts.push(t('localEurPayInstruction'))
    } else if (currency === Currencies.GBP) {
      callouts.push(t('localGbpPayInstruction'))
    }

    return callouts
  }, [selectedWallet, selectedBeneficiary?.transferDestination])

  const { isB2cPayoutEnabled, isLoading: isFetchingB2CCapability } =
    useIsB2cPayoutsEnabled(selectedWallet)

  const beneficiaryOptions = useMemo(() => {
    setIsHelperTextDisplayed(selectedWallet?.supportedTransferDestinations?.length < 2)

    const transferDestinations = getTransferDestinations(
      selectedWallet?.supportedTransferDestinations
    )
    return getBeneficiaryOptions(filteredBeneficiaryList, transferDestinations, isB2cPayoutEnabled)
  }, [filteredBeneficiaryList, selectedWallet?.supportedTransferDestinations, isB2cPayoutEnabled])

  const selectBeneficiaryDropdownPlaceholder = useMemo(() => {
    if (filteredBeneficiaryList?.length && !beneficiaryOptions?.length) {
      return t('manageBeneficiaries.beneficiarySelect')
    } else if (!filteredBeneficiaryList?.length && !beneficiaryOptions?.length) {
      return t('manageBeneficiaries.noBeneficiaries')
    } else if (!!beneficiaryOptions?.length && !selectedBeneficiary) {
      return t('manageBeneficiaries.beneficiarySelect')
    }
  }, [beneficiaryOptions?.length, selectedBeneficiary, filteredBeneficiaryList])

  const beneficiaryListHelperText = useBeneficiaryPayoutDisclaimer(
    !!filteredBeneficiaryList?.length,
    !!beneficiaryOptions?.length
  )

  const isCalloutVisible = useMemo(() => {
    return selectedBeneficiary && !!filteredBeneficiaryList?.length && !isBeneficiaryListError
  }, [selectedBeneficiary, filteredBeneficiaryList?.length, isBeneficiaryListError])

  const errorCalloutMessage = useMemo(() => {
    if (isBeneficiaryListError) {
      return t('manageBeneficiaries.beneficiaryFetchError')
    } else if (isLimitsError) {
      return t('manageBeneficiaries.beneficiaryLimitshError')
    }
  }, [isLimitsError, isBeneficiaryListError])

  const actions: ButtonProps[] = [
    { children: t('cancel'), secondary: true, onClick: dismissAction },
    {
      children: t('payRecipient'),
      testid: t('payRecipient'),
      type: 'submit',
      loading: isLoading || isFetchingB2CCapability,
      disabled: !selectedBeneficiary || !filteredBeneficiaryList?.length || isInsufficientBalance,
    },
  ]

  const isShowingInsufficientError = isInsufficientBalance && !errorCalloutMessage
  const isShowingErrorCalloutMessage = !!errorCalloutMessage
  const isShowingBeneficiarySelect =
    !isInsufficientBalance &&
    !errorCalloutMessage &&
    beneficiaryOptions?.length &&
    beneficiaryList?.length &&
    !isLoading &&
    selectedWallet

  const onSubmit: SubmitHandler<typeof formValues> = async () => {
    setStep('amount')
  }

  const onWalletChange = walletId => {
    form.setValue('walletId', walletId)
  }

  const onBeneficiaryChange = beneficiaryReference => {
    form.setValue('beneficiarySelection', beneficiaryReference)
  }

  return (
    <Wrapper>
      <ModalNavBar title={t('whoToPay')} onClose={dismissAction} />
      <Row padding={24}>
        <Container flex direction="col" gapY={24}>
          <DoubleLabelSelect
            iconsUrl={process?.env?.ICONS_URL}
            label={t('wallet')}
            disabled
            value={formValues?.walletId}
            placeholder={!selectedWallet && t('noWallets')}
            name="currencyCode"
            options={[
              {
                label: selectedWallet?.description,
                value: selectedWallet?.id?.toString(),
                icon: selectedWallet?.currency?.code?.toLowerCase(),
                secondLabel: formatString(
                  t('balanceWithCurrency'),
                  roundNumberWithCommas(selectedWallet?.balance, 2),
                  selectedWallet?.currency?.code
                ),
              },
            ]}
            onChange={onWalletChange}
            loading={isLoading}
          />

          {isLoading && (
            <LoadingContainer flex justifyContent="center" paddingT={32}>
              {Loader}
            </LoadingContainer>
          )}

          {!isLoading && (
            <>
              {isShowingInsufficientError && (
                <Callout
                  state="error"
                  message={t('payInsufficient', { currencyCode: selectedWallet?.currency?.code })}
                />
              )}

              {isShowingErrorCalloutMessage && (
                <Callout state="error" message={errorCalloutMessage} />
              )}

              {isShowingBeneficiarySelect ? (
                <DoubleLabelSelect
                  disabled={!beneficiaryOptions?.length}
                  helperText={isHelperTextDisplayed ? beneficiaryListHelperText : ''}
                  iconsUrl={process.env.ICONS_URL}
                  label={t('manageBeneficiaries.selectBeneficiary')}
                  menuMaxHeight={300}
                  name="beneficiarySelection"
                  options={beneficiaryOptions}
                  placeholder={selectBeneficiaryDropdownPlaceholder}
                  rightLabel={
                    canAddBeneficiary && (
                      <Quicklink
                        className="text-primary-500"
                        to={`${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}?callbackUrl=${location.pathname}`}
                        text={t('addNewBeneficiary')}
                        target="_top"
                      />
                    )
                  }
                  value={formValues?.beneficiarySelection}
                  onChange={onBeneficiaryChange}
                />
              ) : (
                !isShowingErrorCalloutMessage &&
                !isShowingInsufficientError && (
                  <Callout state="warning">
                    <Box flex direction="col" gapY={12}>
                      <Text size="sm" color="inherit">
                        {t('payouts.noSupportedBeneficiaries')}
                      </Text>
                      <Quicklink
                        className="text-secondary-500 hover:text-secondary-700"
                        to={`${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}?callbackUrl=${location.pathname}`}
                        text={t('addNewBeneficiaryWithoutPlus')}
                        target="_top"
                      />
                    </Box>
                  </Callout>
                )
              )}
              {isCalloutVisible && (
                <>
                  <Input
                    {...registerFormField('reference')}
                    placeholder={t('inputPlaceholders.beneficiaryPaymentReferencePlaceHolder')}
                  />
                  <PayCalloutsContainers calloutItems={calloutItems} />
                </>
              )}
            </>
          )}
        </Container>
      </Row>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* actions */}
        <ModalActions actions={actions} />
      </form>
    </Wrapper>
  )
}

export default React.memo(PayFormBenficiary)
