import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, ModalActions, Text } from '@node-space/storybook-components'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { floorWithPrecision } from 'utils/utils'
import { SendActionProps } from './Send'

const SendComplete = ({ dismissAction, form, wallets, refresh }: SendActionProps) => {
  const { t } = useTranslation()
  const formValues = form.watch()

  // refresh wallets
  useEffect(() => {
    refresh()
    track.Amp.track(AmplitudeEvent.EOF_SEND_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const actions: ButtonProps[] = [
    { children: t('done'), testid: 'done', onClick: () => dismissAction() },
  ]

  const supportedWallets = wallets.filter(
    x => x.currency.code && x.currency.supportsWithdrawals && !x.currency.fiat
  )
  const selectedWallet =
    formValues.walletId &&
    supportedWallets &&
    supportedWallets.find(x => x.id.toString() === formValues.walletId)

  return (
    <Box data-testid="send-complete">
      <ModalNavBar onClose={dismissAction} hasDivider={false} />
      <ModalBody hasTopPadding={false}>
        <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
          <Icon name="ClapIcon" color="status-success-500" size="lg" />
          <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
            {`${floorWithPrecision(formValues?.amount, selectedWallet?.currency?.pricePrecision)} ${
              selectedWallet?.currency.code
            } ${t('payments.sent').toLowerCase()}!`}
          </Text>
          <Box>
            <Text align="center" color="text-500" size="sm">
              {t('payments.withdrawals.submittedTransaction')}
            </Text>
            <Text align="center" color="text-500" size="sm">
              {t('payments.withdrawals.checkBlockChainUpdates')}
            </Text>
          </Box>
        </Box>
      </ModalBody>
      <ModalActions actions={actions} alignActions="center" />
    </Box>
  )
}

export default SendComplete
