import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useDeleteTeamMemberMutation } from 'hooks/mutations/useDeleteTeamMemberMutation'
import { useToastContext } from 'hooks/useToastContext'
import { AccountUser } from 'types/accounts'

interface TeamMemberDeleteProps {
  onDeleteCallback: () => void
  userToDelete: AccountUser
  handleCloseModal: () => void
}

export const TeamMemberDelete = ({
  userToDelete,
  onDeleteCallback,
  handleCloseModal,
}: TeamMemberDeleteProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const { mutate: mutateDeleteTeamMember, isPending: isLoading } = useDeleteTeamMemberMutation()

  const handleOnDelete = async () => {
    mutateDeleteTeamMember(userToDelete?.id, {
      onSuccess: () => {
        onDeleteCallback()
        addToast({
          title: t('teamMember.deleteSuccess', {
            userToDelete: userToDelete?.name || userToDelete?.emailAddress,
          }),
          state: 'info',
          delay: 10,
        })
        handleCloseModal()
      },
      onError: () => {
        addToast({
          title: t('oopsSomethingWentWrong'),
          state: 'error',
          delay: 10,
        })
        handleCloseModal()
      },
    })
  }
  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      testid: 'close-button',
      onClick: handleCloseModal,
      secondary: true,
    },
    {
      children: t('confirm'),
      testid: 'delete-button',
      onClick: handleOnDelete,
      loading: isLoading,
    },
  ]

  return (
    <>
      <ModalNavBar title={t('deleteTeamMember')} onClose={handleCloseModal} />
      <Box flex flexCol paddingX={24} paddingT={20} paddingB={8} gap={16}>
        <Text size="sm" color="grey">
          {t('teamMember.deleteDescription')}
        </Text>
      </Box>
      <SummaryDetail testid="delete-crypto-address">
        {userToDelete?.name && (
          <SummaryRow
            label={t('name')}
            renderDescription={<Text size="sm">{userToDelete?.name}</Text>}
          />
        )}

        {userToDelete?.emailAddress && (
          <SummaryRow
            label={t('teamMemberEmailAddress')}
            renderDescription={<Text size="sm">{userToDelete?.emailAddress}</Text>}
          />
        )}
        {!!userToDelete.groups && (
          <SummaryRow
            label={t('teamMember.role')}
            renderDescription={
              <Text size="sm">
                {userToDelete?.groups?.length > 0 &&
                  userToDelete?.groups
                    .map(group => {
                      return group?.name
                    })
                    .join(', ')}
              </Text>
            }
          />
        )}
      </SummaryDetail>
      <ModalActions actions={actions} />
    </>
  )
}
