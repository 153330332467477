import { requestApi } from 'api/api'
import { TotalBalance } from 'pages/Wallets/types'
import { BankAccountsDetails, DepositBankAccount } from 'types/BankAccountsDetails'
import { BeneficiaryTransferDestination } from 'types/beneficiaries'
import { DepositDetailsReq } from 'types/deposit/DepositDetailsReq'
import { AcceptQuote, Quote } from 'types/payments'
import { BankAccount, CurrencyLimits, Paging, WalletType } from 'types/types'
import {
  CurrencyInterface,
  PayBeneficiaryRequest,
  PayFee,
  PayoutsRequest,
  postConvert,
  quoteEstimate,
  TWalletType,
  WalletDescription,
  WalletsCreateRequest,
  WalletsWithdrawResponse,
} from 'types/wallets'

export const getPaymentMethods = (): Promise<BankAccount[]> => {
  return requestApi({
    url: '/payment-method',
    method: 'GET',
    params: {
      max: 1000,
    },
  })
}

export const getDepositAccounts = async (
  walletId: string,
  isV2Api = false
): Promise<BankAccountsDetails | DepositBankAccount[]> => {
  const url = isV2Api ? `/v2/wallet/${walletId}/deposit-options/` : `/v2/wallet/${walletId}/funding`
  return requestApi({
    url,
    method: 'GET',
  })
}

export const getWalletLimits = (
  currencyCode: string,
  walletId?: number
): Promise<CurrencyLimits> => {
  return requestApi({
    url: '/v1/limit',
    method: 'GET',
    params: {
      currency: currencyCode,
      ...(!!walletId && { wallet: walletId }),
    },
  })
}

export const getPayFees = (walletId: number, amount?: number): Promise<PayFee> => {
  return requestApi({
    url: `/wallet/withdraw/${walletId}/fee`,
    method: 'GET',
    params: {
      transactionType: 'third-party',
      ...(!!amount && { amount }),
    },
  })
}

export const getPayoutFees = (
  walletId: number,
  amount?: number,
  isThirdPartyPayment = false,
  transferDestination?: BeneficiaryTransferDestination
): Promise<PayFee> => {
  return requestApi({
    url: `/wallet/withdraw/${walletId}/fee`,
    method: 'GET',
    params: {
      ...(isThirdPartyPayment && { transactionType: 'third-party' }),
      ...(!!amount && { amount }),
      ...(!!transferDestination && { transferDestination }),
    },
  })
}

export const getWithdrawalFee = (walletId: number, amount?: number): Promise<PayFee> => {
  return requestApi({
    url: `/wallet/withdraw/${walletId}/fee`,
    method: 'GET',
    params: {
      ...(!!amount && { amount }),
    },
  })
}

export const postWithdraw = ({
  walletId,
  requestData,
}: PayoutsRequest): Promise<WalletsWithdrawResponse> => {
  return requestApi({
    url: `/wallet/withdraw/${walletId}`,
    method: 'POST',
    data: requestData,
  })
}

export const postDeposit = (walletId: string): Promise<WalletType> => {
  return requestApi({
    url: `/wallet/deposit/${walletId}`,
    method: 'POST',
    data: {},
  })
}

export const postPay = ({ walletId, payRequest }: PayBeneficiaryRequest) => {
  return requestApi({
    url: `/wallet/send/${walletId}`,
    method: 'POST',
    data: payRequest,
  })
}

export const createWallet = (payload: WalletsCreateRequest): Promise<WalletType> => {
  return requestApi({
    url: '/wallet',
    method: 'POST',
    data: payload,
  })
}

export const getSupportedCurrencies = (): Promise<CurrencyInterface[]> => {
  return requestApi({
    url: '/currency/deposit',
    method: 'GET',
    params: {
      pagination: false,
    },
  })
}

export const postConvertQuote = (props: postConvert): Promise<Quote> => {
  return requestApi({
    url: '/v1/quote',
    method: 'POST',
    data: {
      ...props,
      payInMethod: 'wallet',
      payOutMethod: 'wallet',
      usePayInMethod: null,
      merchantId: null,
    },
  })
}

export const postQuoteEstimate = (props: quoteEstimate): Promise<Quote> => {
  return requestApi({
    url: '/v1/quote',
    method: 'POST',
    params: {
      estimate: true,
      direction: 'out',
    },
    data: {
      ...props,
      payInMethod: 'wallet',
      payOutMethod: 'wallet',
    },
  })
}

export const putAcceptQuote = (quoteId: string): Promise<AcceptQuote> => {
  return requestApi({
    url: `/v1/quote/accept/${quoteId}`,
    method: 'PUT',
    data: {
      successUrl: `${window.location.origin}/wallets/convert/complete/${quoteId}`,
    },
  })
}

export const getWalletById = (walletId: number): Promise<WalletType> => {
  return requestApi({
    url: `/wallet/${walletId}`,
    method: 'GET',
  })
}
export const putWalletDescription = ({
  walletId,
  description,
}: WalletDescription): Promise<WalletType> => {
  return requestApi({
    url: `/wallet/description/${walletId}`,
    method: 'PUT',
    data: {
      description,
    },
  })
}

export const postDepositDetails = ({
  walletId,
  protocol,
}: DepositDetailsReq): Promise<WalletType> => {
  return requestApi({
    url: `/wallet/deposit/${walletId}`,
    method: 'POST',
    params: {
      ...(!!protocol && { protocol }),
    },
    data: {},
  })
}

export const getWallets = (): Promise<WalletType[]> => {
  return requestApi({
    url: '/wallet',
    method: 'GET',
    params: {
      max: 1000,
      order: 'asc',
      sort: 'currency.rank',
    },
  })
}

export const getWalletsV2 = async (
  walletType: TWalletType,
  searchTerm?: string,
  offset?: number,
  max?: number
): Promise<{ data: WalletType[]; paging?: Paging }> => {
  const { data, headers } = await requestApi({
    url: '/wallet',
    method: 'GET',
    params: {
      ...(!!walletType && { type: walletType }),
      ...(!!searchTerm && { q: searchTerm }),
      ...(!!offset && { offset }),
      ...(!!max && { max }),
    },
    fullResponse: true,
  })

  return {
    data: data ?? [],
    paging: {
      total: Number(headers['x-api-list-total']),
      offset: Number(headers['x-api-list-start']),
      max,
    },
  }
}

export const postWalletSearch = (data: object) => {
  return requestApi({
    url: '/search/v1/transaction',
    method: 'POST',
    headers: {
      'X-Proxy-Forwarded-Port': '443',
    },
    data: data,
  })
}

export const getTotalBalance = (baseCurrency: string): Promise<TotalBalance> => {
  return requestApi({
    url: '/wallet/summary/total',
    method: 'GET',
    params: {
      ...(baseCurrency && { base: baseCurrency }),
    },
  })
}

export const validateReference = ({ reference }: { reference?: string }) => {
  return requestApi({
    url: `/wallet/withdrawal/request/validate`,
    method: 'POST',
    params: {
      paymentReference: reference,
    },
    headers: {
      'X-Proxy-Forwarded-Port': '443',
    },
  })
}
