import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Loader from '@node-space/storybook-components/dist/Loader'
import Text from '@node-space/storybook-components/dist/Text'
import Icon from '@node-space/storybook-components/dist/Icon'

interface ProofOfPaymentLinkProps {
  isFetchingDocument: boolean
  onClickHandler: () => void
}

export const ProofOfPaymentLink = ({
  isFetchingDocument,
  onClickHandler,
}: ProofOfPaymentLinkProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Button testid="pop-download-button" noStyling onClick={onClickHandler}>
        <Box flex direction="row" gap={4}>
          {isFetchingDocument && (
            <>
              <Loader theme="dark" size="small" />
              <Text size="sm" color="primary-500">
                {t('downloadProofOfPayment')}
              </Text>
            </>
          )}
          {!isFetchingDocument && (
            <>
              <Icon name="DownloadIcon" color="primary-500" />
              <Text size="sm" color="primary-500">
                {t('downloadProofOfPayment')}
              </Text>
            </>
          )}
        </Box>
      </Button>
    </Box>
  )
}
