import React from 'react'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@node-space/storybook-components'
import { ChannelCreateRequest } from 'types/payments'

interface ReferenceInputFieldProps {
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >
  register: UseFormRegister<ChannelCreateRequest>
}
const ReferenceInputField = ({ errors, register }: ReferenceInputFieldProps) => {
  const { t } = useTranslation()
  return (
    <div className="w-full mb-2">
      <Input
        label={t('payments.channels.channelReference')}
        inputMode="text"
        placeholder={t('uniqueReference')}
        name="reference"
        testid="unique-reference"
        errorText={errors?.reference?.message}
        error={!!errors?.reference}
        {...register('reference')}
      />
    </div>
  )
}

export default ReferenceInputField
