import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import {
  approvalWorkflowActionPreRequest,
  approvalWorkflowChallengeRequest,
} from 'services/ApprovalsService'
import { ApprovalWorkflowActionResponse } from 'types/approvals'

export const useApprovalWorkflowActionPreRequest = () => {
  return useMutation({
    mutationFn: approvalWorkflowActionPreRequest,
    onError: (error: ApprovalWorkflowActionResponse) => {
      if (error?.status !== 428) {
        logSentryError('Error: useApprovalWorkflowActionPreRequest', error)
      }
    },
  })
}

export const useApprovalWorkflowChallengeRequest = () =>
  useMutation({ mutationFn: approvalWorkflowChallengeRequest })
