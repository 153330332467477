import { TFunction } from 'react-i18next'
import { TableColumnV2 as TableColumn } from '@node-space/storybook-components/dist/SimpleTableV2'

export const getTableColumns = (
  isCrypto: boolean,
  t: TFunction<'translation', undefined>
): TableColumn[] => {
  return [
    {
      headerTitle: t('massPayouts.amount'),
      className: 'pl-4',
      width: '25%',
      testid: `mass-payouts-column-amount`,
    },
    {
      headerTitle: isCrypto ? t('massPayouts.recipientAddressAndTag') : t('massPayouts.recipient'),
      width: '25%',
      testid: `mass-payouts-column-recipient`,
    },
    {
      headerTitle: t('massPayouts.paymentReference'),
      width: '20%',
      testid: `mass-payouts-column-paymentReference`,
    },
    {
      headerTitle: isCrypto ? '' : t('massPayouts.status'),
      width: '20%',
      testid: `mass-payouts-column-errors`,
    },
    {
      headerTitle: '',
      align: 'end',
      testid: `mass-payouts-column-icons`,
    },
  ]
}
