import React, { useMemo } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { getSupportedPaymentTypes } from 'utils/utils'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface BvnkNetworkTransferSenderReceiverProps {
  beneficiary?: string
  sender?: string
  transactionTypeSubType: string
}

const supportedPaymentTypes = getSupportedPaymentTypes()

const BvnkNetworkTransferSenderReceiver = ({
  beneficiary,
  sender,
  transactionTypeSubType,
}: BvnkNetworkTransferSenderReceiverProps) => {
  const { t } = useTranslation()

  const details = useMemo(() => {
    if (transactionTypeSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_OUT) {
      return { title: t('withdrawalDetails'), networkMember: beneficiary }
    } else if (transactionTypeSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_IN) {
      return { title: t('senderDetails'), networkMember: sender }
    }
  }, [transactionTypeSubType])

  return (
    !!details && (
      <>
        <Text size="base" weight="medium" color="dark-grey" className="mt-3">
          {details?.title}
        </Text>
        <Box gapY={8}>
          <TransactionDetailRow
            label={i18next.t('transactions.sideBar.bvnkNetworkMember')}
            detail={details?.networkMember}
          />
        </Box>
      </>
    )
  )
}

export default BvnkNetworkTransferSenderReceiver
