import { useContext } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterStatus = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  return {
    status: state?.status,
    setStatus: (value: string) => dispatch({ type: 'UPDATE_STATUS', payload: value }),
    resetStatus: () => dispatch({ type: 'RESET_STATUS' }),
  }
}

export default useFilterStatus
