import { TFunction } from 'react-i18next'
import * as yup from 'yup'

export const getEurSpecificSchemaFields = (t: TFunction<'translation', undefined>) => {
  const ibanLabel = t('iban')
  const ibanMinLength = 11
  const ibanMaxLength = 40
  const bicMinLength = 8
  const bicMaxLength = 11

  const iban = yup
    .string()
    .trim()
    .label(ibanLabel)
    .min(
      ibanMinLength,
      t('fieldMinLength', {
        field: ibanLabel,
        min: ibanMinLength,
      })
    )
    .matches(
      /^[0-9a-zA-Z]+$/,
      t('fieldAlphanumeric', {
        field: ibanLabel,
      })
    )
    .max(
      ibanMaxLength,
      t('fieldMaxLength', {
        field: ibanLabel,
        max: ibanMaxLength,
      })
    )

  const bicLabel = t('bic')
  const bic = yup
    .string()
    .trim()
    .label(bicLabel)
    .min(
      bicMinLength,
      t('fieldMinLength', {
        field: bicLabel,
        min: bicMinLength,
      })
    )
    .max(
      bicMaxLength,
      t('fieldMaxLength', {
        field: bicLabel,
        max: bicMaxLength,
      })
    )
    .matches(
      /^[0-9a-zA-Z]+$/,
      t('fieldAlphanumeric', {
        field: bicLabel,
      })
    )

  return {
    iban,
    bic,
  }
}
