import React from 'react'
import { numberWithCommas } from '@node-space/utils'
import { Box, Text } from '@node-space/storybook-components'
import { formatTotal } from './utils'
import { TableSectionItem } from '../../types'

interface Props {
  wallet: TableSectionItem
  baseCurrency: string
}

const Balance = ({ wallet, baseCurrency }: Props) => {
  return (
    <Box paddingX={24} className="text-right">
      <p>
        {numberWithCommas(wallet.balance)} {wallet.currency.code}
      </p>
      {wallet?.currency?.code !== baseCurrency && (
        <Text size="xs" weight="normal" className="mb-1" color="grey">
          {formatTotal(wallet?.convertedAvailable, baseCurrency)}
        </Text>
      )}
    </Box>
  )
}

export default Balance
