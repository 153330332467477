import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import {
  AlignActions,
  ModalActions,
  ModalBody,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import Text from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useCreateAccountUserMutation } from 'hooks/mutations/useCreateAccountUserMutation'
import { useUpdateAccountUserMutation } from 'hooks/mutations/useUpdateAccountUserMutation'
import { UserGroups } from 'pages/TeamMembers/const'
import { AccountUser, AccountUserGroup, UserGroupType } from 'types/accounts'
import { FormFieldsAccountUser } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { validEmailRegex } from 'utils/email'
import track, { Amp } from 'utils/tracker'

type TeamMemberEditorProps = {
  handleCloseModal: () => void
  getValues: () => void
  actionName: string
  submitBtnText: string
  accountUser?: AccountUser
  userGroups?: AccountUserGroup[]
  action: 'EDIT' | 'CREATE'
  canEdit?: boolean
  canManageApprover?: boolean
}

export const TeamMemberEditor = ({
  handleCloseModal,
  getValues,
  actionName,
  submitBtnText,
  accountUser,
  userGroups,
  action,
  canEdit,
  canManageApprover,
}: TeamMemberEditorProps) => {
  const { t } = useTranslation()

  const existingUser = accountUser?.groups?.[0]?.id?.toString() || ''

  const [errors, setErrors] = useState(null)
  const [formData, setFormData] = useState({
    emailAddress: accountUser?.emailAddress || '',
    groups: accountUser?.groups || [],
    ...(action === 'CREATE' && canManageApprover && { approver: false }),
  })
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(existingUser)

  const { mutate: mutateCreateUser, isPending: isLoadingCreateUser } =
    useCreateAccountUserMutation()
  const { mutate: mutateUpdateUser, isPending: isLoadingUpdateUser } =
    useUpdateAccountUserMutation()

  useEffect(() => {
    if (!existingUser) {
      Amp.track(AmplitudeEvent.ADD_TEAM_MEMBER_INITIATE, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
    if (!!existingUser) {
      Amp.track(AmplitudeEvent.EDIT_TEAM_MEMBER_INITIATE, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
  }, [])

  const mapTranslationsSubText = (groupName: UserGroupType) => {
    switch (UserGroups[groupName]) {
      case UserGroups['Workspace Owner']:
        return t('teamMember.workspaceOwnerSubText')
      case UserGroups.Admin:
        return t('teamMember.adminSubText')
      case UserGroups.Collaborator:
        return t('teamMember.collaboratorSubText')
      case UserGroups['Pay-In Only']:
      case UserGroups['Pay-In Only ']:
        return t('teamMember.payInSubText')
      case UserGroups.Viewer:
        return t('teamMember.viewerSubText')
      case UserGroups.Compliance:
        return t('teamMember.complianceSubText')
      case UserGroups['Compliance-Plus']:
        return t('teamMember.compliancePlusSubText')
      default:
        return ''
    }
  }

  const validate = () => {
    setErrors(null)
    const tempErrorList = {}

    if (formData.emailAddress?.length < 1) {
      tempErrorList['emailAddress'] = {
        status: true,
        message: t('emailRequired'),
      }
    } else {
      const validEmail = new RegExp(validEmailRegex)
      if (!validEmail.test(formData.emailAddress)) {
        tempErrorList['emailAddress'] = {
          status: true,
          message: t('validEmailRequired'),
        }
      }
    }

    if (formData.groups?.length < 1) {
      tempErrorList['groups'] = {
        status: true,
      }
    }

    let hasErrors = false
    for (const property in tempErrorList) {
      if (tempErrorList[property].status === true) {
        hasErrors = true
      }
    }

    setErrors(tempErrorList)

    return hasErrors
  }

  const onSubmit = () => {
    const hasErrors = validate()
    if (!hasErrors) {
      saveUser(formData)
    }
  }

  const onCheckboxChange = (id: number) => {
    const selectedAccountGroupItem = formData.groups?.find(group => group.id === id)
    let newGroups: AccountUserGroup[]

    if (selectedAccountGroupItem) {
      newGroups = formData.groups?.filter(group => group.id !== id)
    } else {
      const newItem = userGroups?.find(item => item.id === id)
      newGroups = [...formData.groups, newItem]
    }

    setFormData({ ...formData, groups: newGroups })
  }

  const onEmailInputChange = (e: { target: { value: string } }) => {
    setFormData({ ...formData, emailAddress: e.target.value })
  }

  const onRadioChange = (id: number | React.SetStateAction<string>) => {
    const selectedUserGroup = Array(1).fill(userGroups?.find(group => group?.id == id))

    setFormData({
      ...formData,
      groups: selectedUserGroup,
    })
    setSelectedRadioOption(id?.toString())
  }

  const radioOptions = userGroups
    ?.filter(userGroup => userGroup?.name !== Approval.ACCOUNT_TRANSACTION_APPROVER)
    ?.map(group => {
      return {
        label: group?.name,
        value: `${group?.id}`,
        secondaryLabel: mapTranslationsSubText(group?.name),
      }
    })

  const onApproverCheckboxChange = () => {
    setFormData({
      ...formData,
      approver: !formData.approver,
    })
  }

  const createNewUser = (formData: FormFieldsAccountUser) => {
    mutateCreateUser(formData, {
      onSuccess: () => {
        setShowSuccess(true)
        track.Amp.track(AmplitudeEvent.EOF_ADD_TEAM_MEMBER_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
        })
      },
      onError: (error: Error) => {
        logSentryError(`Error from TeamMemberEditor - createNewUser`, error)
        setErrorMessage(error?.message || t('defaultAddTeamMemberError'))
      },
    })
  }

  const updateExistingUser = (formData: FormFieldsAccountUser) => {
    mutateUpdateUser(
      { userID: accountUser?.id, formData },
      {
        onSuccess: () => {
          setShowSuccess(true)
          track.Amp.track(AmplitudeEvent.EOF_EDIT_TEAM_MEMBER_SUCCESS, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.VIEW,
          })
        },
        onError: (error: Error) => {
          logSentryError(`Error from TeamMemberDelete - updateExistingUser`, error)
          setErrorMessage(error?.message || t('defaultEditTeamMemberError'))
        },
      }
    )
  }

  const saveUser = (formData: FormFieldsAccountUser) => {
    if (!!accountUser) {
      updateExistingUser(formData)
    } else {
      createNewUser(formData)
    }
  }

  const onContinueClick = () => {
    handleCloseModal()
    setErrorMessage(null)
    getValues()
  }

  return showSuccess ? (
    <>
      <ModalNavBar hasDivider={false} onClose={handleCloseModal} />
      <ModalBody hasTopPadding={false}>
        <Box flex direction="col" alignItems="center" padding={16} gap={16}>
          <Icon name="ClapIcon" color="status-success-500" size="lg" />
          <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
            {accountUser ? t('teamMember.editSuccessHeading') : t('teamMember.addSuccessHeading')}
          </Text>
          <Text size="sm" align="center" color="grey">
            {accountUser
              ? t('teamMember.editSuccessSubheading')
              : t('teamMember.addSuccessSubheading')}
          </Text>
        </Box>
      </ModalBody>
      <ModalActions
        actions={[
          {
            children: t('done'),
            testid: 'submit-button',
            onClick: onContinueClick,
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </>
  ) : (
    <>
      <ModalNavBar title={actionName} onClose={handleCloseModal} />
      <ModalScrollable>
        <>
          <Box padding={20}>
            <Box className="mb-2">
              <Input
                label={t('teamMemberEmailAddress')}
                placeholder={t('teamMemberEmailAddressPlaceholder')}
                name="emailAddress"
                testid="email-address"
                disabled={!!accountUser}
                onChange={onEmailInputChange}
                value={formData?.emailAddress}
                error={errors?.emailAddress?.status}
                errorText={errors?.emailAddress?.status && errors?.emailAddress?.message}
                required
              />
            </Box>
            <Box className="mb-6">
              <Box className="pt-6 space-y-2">
                <Radio
                  name="groupOne"
                  onChange={onRadioChange}
                  options={radioOptions}
                  value={selectedRadioOption}
                />
                {action === 'CREATE' && canManageApprover && (
                  <Box flex paddingX={12} paddingY={16}>
                    <CheckBox
                      value={formData.approver}
                      onChange={onApproverCheckboxChange}
                      labelSize="sm"
                    />
                    <Box paddingL={8}>
                      <Text size="sm" color="dark-grey" className="">
                        {t('teamMember.makeUserAnApprover')}
                      </Text>
                      <Text size="sm" color="disabled">
                        {t('teamMember.makeUserAnApproverDescription')}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box {...(!(action === 'CREATE' && canEdit) && { paddingT: 16 })}>
                  <Callout state="info">
                    <Text size="sm">
                      {`${t('teamMember.permissionsCalloutPartOne')} `}
                      <Link className="underline" to={process.env.ROLES_FAQ}>{`${t(
                        'teamMember.permissionsCalloutPartTwo'
                      )}`}</Link>
                      {` ${t('teamMember.permissionsCalloutPartThree')}`}
                    </Text>
                  </Callout>
                </Box>
              </Box>
            </Box>

            {errors?.groups?.status && (
              <Box className="mt-1 text-red-25">{t('selectOneOption')}</Box>
            )}
            {!!errorMessage && <Box className="mt-1 text-red-25 error-text">{errorMessage}</Box>}
          </Box>

          <ModalActions
            actions={[
              {
                children: t('cancel'),
                onClick: handleCloseModal,
                secondary: true,
              },
              {
                children: submitBtnText,
                testid: 'submit-button',
                loading: isLoadingCreateUser || isLoadingUpdateUser,
                onClick: onSubmit,
              },
            ]}
          />
        </>
      </ModalScrollable>
    </>
  )
}
