import { customHeaderWithoutXSource } from 'api/api'
import { request } from '@node-space/rest-api'
import { Currency, ExchangeRateResponse } from 'types/types'

export const getFiatCurrencies = (): Promise<Currency[]> => {
  return request({
    url: '/currency/fiat',
    method: 'GET',
    params: {
      pagination: false,
      sort: 'currency.rank',
      order: 'asc',
    },
    authenticated: false,
    headers: customHeaderWithoutXSource,
  })
}

export const getCryptoCurrencies = (): Promise<Currency[]> => {
  return request({
    url: '/currency/crypto',
    method: 'GET',
    params: {
      pagination: false,
      sort: 'rank',
      order: 'asc',
      allowDeposits: true,
      all: false,
    },
    authenticated: false,
    headers: customHeaderWithoutXSource,
  })
}

export const getExchangeRate = (
  fromCode: string,
  toCode: string,
  amount: number | string
): Promise<ExchangeRateResponse> => {
  const canGetExchangeRate = !!(fromCode && toCode && amount)
  if (canGetExchangeRate) {
    return request({
      url: `/currency/convert/${fromCode}/${toCode}`,
      method: 'GET',
      params: {
        amount,
      },
      headers: customHeaderWithoutXSource,
      authenticated: false,
    })
  }
}
