import { useContext } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterModal = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  return {
    isFilterModalOpen: state?.isFilterModalOpen,
    setIsFilterModalOpen: (value: boolean) =>
      dispatch({ type: 'UPDATE_IS_FILTER_MODAL_OPEN', payload: value }),
    toggleIsFilterModalOpen: () => dispatch({ type: 'TOGGLE_IS_FILTER_MODAL_OPEN' }),
  }
}

export default useFilterModal
