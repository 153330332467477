import React from 'react'
import { Box as Container, Box as Item } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { ImageIcon } from '@node-space/storybook-components/dist/ImageIcon'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import { FiatPayout, PreProcessedPayouts } from 'pages/MassPayouts/types'
import { PaymentStatus } from 'types/payments'
import { getMassPayoutStatusState, titleCase } from 'utils/utils'

interface FiatTableDataProps {
  massPayoutPreProcessedPayouts: PreProcessedPayouts[]
  massFiatPayoutDetails: FiatPayout
  isFiatPayoutSubmitted: boolean
  handleShowEditFiatPayoutModal: (
    event: React.MouseEvent<HTMLButtonElement>,
    payout: PreProcessedPayouts
  ) => void
  onOpenPayoutDetails: (payoutIndex?: number) => void
  handleDeleteFiatPayout: (
    event: React.MouseEvent<HTMLButtonElement>,
    payout: PreProcessedPayouts
  ) => void
}

export const getFiatTableRows = ({
  massPayoutPreProcessedPayouts,
  massFiatPayoutDetails,
  isFiatPayoutSubmitted,
  handleShowEditFiatPayoutModal,
  onOpenPayoutDetails,
  handleDeleteFiatPayout,
}: FiatTableDataProps) => {
  return massPayoutPreProcessedPayouts?.map((payout, payoutIndex) => ({
    rowData: [
      {
        cellData: (
          <Item flex paddingL={16} gapX={4}>
            <Text size="sm">{payout?.amount}</Text>
            <Text size="sm">{massFiatPayoutDetails?.currency}</Text>
          </Item>
        ),
        testid: `individual-record-amount`,
      },
      {
        cellData: (
          <Item flex alignItems="center" justifyContent="center">
            <Container>
              <ImageIcon
                width={32}
                height={32}
                src={`${process.env.ICONS_URL}${massFiatPayoutDetails?.currency?.toLowerCase()}.svg`}
              />
            </Container>
            <Item paddingY={20} paddingL={16} paddingR={12}>
              <Text size="sm">
                {payout?.beneficiaryDetails?.enrichedBeneficiary?.businessName ||
                  `${payout?.beneficiaryDetails?.enrichedBeneficiary?.firstName} ${payout?.beneficiaryDetails?.enrichedBeneficiary?.lastName}`}
              </Text>
              <Text size="sm" color="grey">
                {payout?.beneficiaryDetails?.enrichedBeneficiary?.accountNumber}
              </Text>
            </Item>
          </Item>
        ),
        testid: `individual-record-recipient`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            <Text size="sm">{payout?.paymentReference}</Text>
          </Item>
        ),
        testid: `individual-record-reference`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            <StatusPill
              state={getMassPayoutStatusState(payout?.status?.toUpperCase())}
              label={titleCase(payout?.status)}
              fitContent
            />
          </Item>
        ),
        testid: `individual-record-errors`,
      },
      {
        cellData: !isFiatPayoutSubmitted && (
          <Item flex paddingR={12}>
            {payout?.status === PaymentStatus.VALID && (
              <Container paddingR={8}>
                <Icon name="SuccessCircleIcon" color="validation-success" />
              </Container>
            )}
            {payout?.status !== PaymentStatus.VALID && payout?.status !== PaymentStatus.CREATED && (
              <Container
                paddingR={8}
                onClick={event =>
                  handleShowEditFiatPayoutModal(
                    event as React.MouseEvent<HTMLButtonElement>,
                    payout
                  )
                }
              >
                <Icon name="EditIcon" color="grey-10" />
              </Container>
            )}
            <Container
              paddingR={12}
              onClick={event =>
                handleDeleteFiatPayout(event as React.MouseEvent<HTMLButtonElement>, payout)
              }
            >
              <Icon name="TrashIcon" color="grey-10" />
            </Container>
          </Item>
        ),
        align: 'end',
        testid: `individual-record-operations`,
      },
    ],
    onClick: () => onOpenPayoutDetails(payoutIndex),
  }))
}
