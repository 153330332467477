import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalActions, ModalBody, ModalNavBar } from '@node-space/storybook-components'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'

import { getPasswordSecret, verify2FACode } from 'services/ApiKeysService'
import { useKeyPress } from '@node-space/hooks'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import Enable2FANoticeModal from './TwoFAStepNotice'
import Enable2FAPasswordModal from './TwoFAStepPassword'
import Enable2FAVerifyModal from './TwoFAStepVerify'
import { logSentryError } from '@node-space/utils'

interface TwoFAModalProps {
  notice?: {
    navTitle: string
    heading: string
    description: string
  }
  onComplete?: () => void
}

const TwoFAModal = ({ notice, onComplete }: TwoFAModalProps) => {
  const { t } = useTranslation()
  const { twoFactorEnabled, showTwoFAModal, setShowTwoFAModal } = useTwoFAContext()

  const [isLoading, setIsLoading] = useState(false)
  const [secret, setSecret] = useState('')
  const [password, setPassword] = useState('')
  const [hasPasswordError, setHasPasswordError] = useState(false)
  const [otc, setOtc] = useState('')
  const [hasOtcError, setHasOtcError] = useState(false)
  const [currentStep, setCurrentStep] = useState(notice ? 0 : 1)
  const startStep = notice ? 0 : 1

  const onBack = () => {
    if (!isLoading)
      currentStep === startStep ? setShowTwoFAModal(false) : setCurrentStep(step => step - 1)
  }

  const resetSteps = () => {
    setShowTwoFAModal(false)
    setCurrentStep(startStep)
    setPassword('')
    setHasPasswordError(false)
    setHasOtcError(false)
  }

  const handleKeyPress = useCallback(() => {
    if (!showTwoFAModal) {
      return
    }
    setShowTwoFAModal(false)
  }, [showTwoFAModal])

  useKeyPress('Escape', handleKeyPress)

  const onPasswordSubmitClicked = async () => {
    try {
      setHasPasswordError(false)
      setIsLoading(true)
      const res = await getPasswordSecret(password)
      setIsLoading(false)
      if (res?.body) {
        setSecret(res?.body)
        // TODO: implement logic for disabling 2FA
        twoFactorEnabled ? setShowTwoFAModal(false) : setCurrentStep(step => step + 1)
      } else {
        setHasPasswordError(true)
      }
    } catch (err) {
      setIsLoading(false)
      logSentryError('Error from TwoFAModal - onPasswordSubmitClicked', err)
    }
  }

  const onVerify = async () => {
    try {
      setHasOtcError(false)
      setIsLoading(true)
      await verify2FACode(otc)
      setIsLoading(false)
      onSuccessVerify()
    } catch (err) {
      if (err.status === 412) {
        setHasOtcError(true)
      } else {
        logSentryError('Error from TwoFAModal - onVerify', err)
      }
      setIsLoading(false)
    }
  }

  const onSuccessVerify = useCallback(() => {
    resetSteps()
    onComplete()
  }, [resetSteps, onComplete])

  const steps = [
    // notice step
    {
      actions: [
        {
          children: twoFactorEnabled ? t('2FADisable') : t('2FAEnable'),
          testid: twoFactorEnabled ? 'notify-disable-2FA' : 'notify-enable-2FA',
          disabled: false,
          onClick: () => setCurrentStep(1),
        },
      ],
    },
    // password step
    {
      actions: [
        {
          children: t('cancel'),
          testid: 'cancel-2FA',
          disabled: isLoading,
          secondary: true,
          onClick: () => resetSteps(),
        },
        {
          children: t('submit'),
          testid: 'submit-2FA-password',
          loading: isLoading,
          disabled: password.length === 0,
          onClick: () => onPasswordSubmitClicked(),
        },
      ],
    },
    // qr code step
    {
      actions: [
        {
          children: t('verify'),
          loading: isLoading,
          testid: 'verify-2FA-otc',
          disabled: otc.length !== 6,
          onClick: () => onVerify(),
        },
      ],
    },
  ]

  const actions: ButtonProps[] = steps[currentStep].actions

  const stepProps =
    currentStep === 0
      ? {}
      : {
          currentStep,
          totalSteps: steps.length - 1,
        }

  const isLastStep = useMemo(() => currentStep === steps.length - 1, [currentStep, steps])

  const getStep = () => {
    switch (currentStep) {
      case 0:
        return <Enable2FANoticeModal heading={notice?.heading} description={notice?.description} />
      case 1:
        return (
          <Enable2FAPasswordModal
            password={password}
            isLoading={isLoading}
            hasError={hasPasswordError}
            onChange={val => {
              setPassword(val)
              setHasPasswordError(false)
            }}
          />
        )
      default:
        return (
          <Enable2FAVerifyModal
            secret={secret}
            isLoading={isLoading}
            hasError={hasOtcError}
            onChange={val => {
              setOtc(val)
              setHasOtcError(false)
            }}
          />
        )
    }
  }

  return (
    <Modal
      visible={showTwoFAModal}
      closeModal={() => setShowTwoFAModal(false)}
      closeOnBackgroundClick
    >
      <ModalNavBar
        title={
          currentStep === 0 && notice
            ? notice.navTitle
            : twoFactorEnabled
            ? t('disable2FAAuthentication')
            : t('enable2FAAuthentication')
        }
        onBack={() => onBack()}
      />
      <ModalBody centerChildren={isLastStep} spaceChildren={isLastStep ? 24 : 16}>
        {getStep()}
      </ModalBody>
      <ModalActions actions={actions} {...stepProps} />
    </Modal>
  )
}

export default TwoFAModal
