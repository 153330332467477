import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { PathNames } from 'constants/General'
import { usePendingAgreementsContext } from 'hooks/context/usePendingAgreementsContext'
import { usePendingAgreementsQuery } from 'hooks/queries/usePendingAgreementsQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { LegalAgreementType } from 'pages/LegalAgreements/types'
import { AgreementModalBody } from './AgreementModalBody'
import { AgreementModalData } from './types'
import { useAgreementsModalData } from './useAgreementsModalData'
import { filterSingleAgreement, orderAgreements } from './utils'

export const PendingAgreements = () => {
  const location = useLocation()
  const currentpath = location.pathname
  const excludedPaths = [PathNames.NOT_FOUND]
  const isExcludedPath = excludedPaths.includes(currentpath)

  if (isExcludedPath) return <></>

  const { t } = useTranslation()

  const permissions = useMappedRoles()
  const hasLegalAgreementsRolePermissions = permissions?.manageLegalAgreements?.canEdit ?? false

  const [pendingAgreements, setPendingAgreements] = useState<AgreementModalData[]>([])
  const [hasMappedAgreements, setHasMappedAgreements] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isFetching: isFetchingAgreementsData,
    data: agreementsData,
    refetch: refetchPendingAgreements,
  } = usePendingAgreementsQuery(hasLegalAgreementsRolePermissions)

  const hasAgreements = useMemo(() => !!agreementsData?.length, [agreementsData])

  const { showSingleAgreementId, shouldRefetch, setShouldRefetch } = usePendingAgreementsContext()
  const { getTitle, getBodyContent, getLinks, getCheckboxLabel } = useAgreementsModalData()

  const handleCloseModal = () => setIsModalOpen(false)

  const mapAgreements = () => {
    const agreementsToShow: LegalAgreementType[] = !!showSingleAgreementId
      ? filterSingleAgreement(showSingleAgreementId, agreementsData)
      : orderAgreements('EMI', agreementsData)

    const mappedAgreements: AgreementModalData[] = agreementsToShow?.map(
      (agreementData: LegalAgreementType) => {
        const { name, description, url, declinable: isDeclinable } = agreementData?.agreement || {}
        const appendixes = agreementData?.appendixes
        return {
          agreementId: agreementData?.id,
          title: getTitle(name),
          bodyContent: getBodyContent(name, description),
          links: getLinks(name, url, appendixes),
          checkboxLabel: getCheckboxLabel(name),
          status: 'PENDING',
          isDeclinable,
        }
      }
    )

    setPendingAgreements(mappedAgreements)
    setHasMappedAgreements(true)
    setShouldRefetch(false)
    if (!isModalOpen) {
      setIsModalOpen(true)
    }
  }

  useEffect(() => {
    if (shouldRefetch) {
      const singleAgreementIndex = agreementsData?.findIndex(
        (agreementData: LegalAgreementType) => agreementData?.id === showSingleAgreementId
      )
      if (singleAgreementIndex !== -1) {
        mapAgreements()
      } else {
        refetchPendingAgreements()
        setIsModalOpen(true)
      }
      setHasMappedAgreements(false)
    }
  }, [shouldRefetch])

  useEffect(() => {
    if (
      hasLegalAgreementsRolePermissions &&
      !isFetchingAgreementsData &&
      hasAgreements &&
      !hasMappedAgreements
    ) {
      mapAgreements()
    }
  }, [
    hasLegalAgreementsRolePermissions,
    isFetchingAgreementsData,
    hasAgreements,
    hasMappedAgreements,
  ])

  return hasLegalAgreementsRolePermissions && !!pendingAgreements?.length ? (
    <Modal ariaLabel="agreementsModal" visible={isModalOpen} closeModal={handleCloseModal}>
      {isFetchingAgreementsData ? (
        <StatusCard
          customIcon={<Loader size="medium" />}
          headline={t('pendingAgreements.loading')}
          borderless
        />
      ) : (
        <AgreementModalBody agreements={pendingAgreements} onClose={handleCloseModal} />
      )}
    </Modal>
  ) : (
    <></>
  )
}
