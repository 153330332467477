import React, { useCallback, useState } from 'react'
import { useAtom, useSetAtom } from 'jotai'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'
import UploadFile from '@node-space/storybook-components/dist/UploadFile'
import { logSentryError } from '@node-space/utils'
import { FILE_UPLOAD_LIMIT, MAX_DOCUMENT_UPLOAD } from 'utils/documents'
import { fileAtom, fileNameAtom, uploadErrorAtom } from '../../atoms/massPayoutAtoms'
import { FileUpload } from './FileUpload'

export const MassPayoutFileUpload = () => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [file, setFile] = useAtom(fileAtom)
  const setUploadError = useSetAtom(uploadErrorAtom)
  const setFileName = useSetAtom(fileNameAtom)

  const onFileDrop = useCallback((acceptedFiles, fileRejections: FileRejection[]) => {
    setError('')
    setUploadError('')
    setFile(null)

    if (fileRejections?.length > MAX_DOCUMENT_UPLOAD) {
      setError(t('documents.multipleUploadsError'))
      return
    }

    if (!acceptedFiles?.length) {
      setError(t('massPayouts.uploadRequirementsOnly'))
      return
    }

    acceptedFiles.forEach(acceptedFile => {
      try {
        // -- error: file exceeds max size
        if (acceptedFile?.size > FILE_UPLOAD_LIMIT) {
          setError(t('documents.smallerFileRequired'))
          return
        }
        // -- read file data and set state
        const reader = new FileReader()

        // -- to handle other events on the reader, use 'reader.onabort' and 'reader.onerror'
        reader.onload = () => {
          const dataUrl = reader?.result as string

          const fileData = { fileData: acceptedFile, buffer: dataUrl }

          setFileName(acceptedFile?.name)
          setFile(fileData)
        }

        reader.readAsDataURL(acceptedFile)
      } catch (error) {
        console.error('error', error)
        logSentryError('Masspayouts crypto onFileDrop', error)
        setError(t('oopsSomethingWentWrong'))
      }
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  })

  return (
    <Box flex direction="col" justifyContent="start" gapY={4}>
      <UploadFile
        full
        isDragActive={isDragActive}
        defaultMessage={t('massPayouts.csvLessThan10MB')}
        file={file}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        name="mass-payouts-file-upload-input"
        textList={{
          replaceFile: <FileUpload />,
          dropFile: t('documents.dropFile'),
          uploadFile: <FileUpload upload />,
          uploadingFile: t('massPayouts.validatingFileUpload'),
          fileRequired: t('fieldRequired', { field: t('documents.file') }),
        }}
        errorMessage={error}
        title={t('massPayouts.uploadCSV')}
      />

      <Text align="left" size="sm" color="text-500">
        {t('massPayouts.weAdvisePayingInBatch100')}
      </Text>
    </Box>
  )
}
