import React from 'react'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box as ActionsContainer,
  Box,
  Box as ContextMenuContainer,
  Box as TextContainer,
} from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import { PopoverPlacement } from '@node-space/storybook-components/dist/components/PopoverMenu/types'
import Icon from '@node-space/storybook-components/dist/Icon'
import PopoverMenu from '@node-space/storybook-components/dist/PopoverMenu'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import { PathNames } from 'constants/General'
import { CustomerStatus, CustomerStatusType } from 'types/customers'
import { iconBgColour } from '../atoms/customersAtoms'
import { assignedColorArray, StatusLabelMapping, StatusMapping } from '../constants'
import IconCircle from './IconCircle'

interface CustomerListItemProps {
  index?: number
  activeRowId?: number
  customerId?: string
  name: string
  description: string
  status?: CustomerStatusType
  setIsCustomerOpen?: (isOpen: boolean) => void
  updateCustomer?: (index: number) => void
}

const CustomerListItem = ({
  index,
  activeRowId,
  customerId,
  description,
  name,
  status,
  setIsCustomerOpen,
  updateCustomer,
}: CustomerListItemProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setCurrentIconBgColour = useSetAtom(iconBgColour)
  const assignedColor = assignedColorArray[index % assignedColorArray?.length]
  const isCustomerVerified = status === CustomerStatus.VERIFIED

  const activeRow = clsx('cursor-pointer hover:bg-lighterBlueTint', {
    'bg-secondary-50': activeRowId && activeRowId?.toString() === customerId,
  })

  const onRowClick = (customerId: string) => {
    setCurrentIconBgColour(assignedColor)
    navigate(`${PathNames.CUSTOMERS}/${customerId}`)
  }

  const onDetailsClick = () => {
    updateCustomer(index)
    setIsCustomerOpen(true)
  }

  return (
    <Box
      testid="customer-list-item"
      paddingX={16}
      paddingY={12}
      key={customerId}
      flex
      alignItems="center"
      direction="row"
      gapX={16}
      className={activeRow}
    >
      <Box
        width="full"
        flex
        direction="row"
        onClick={() => {
          isCustomerVerified && onRowClick(customerId)
        }}
      >
        <IconCircle name={name} assignedColor={assignedColor} />
        <TextContainer flexSize="fill" paddingL={12} gapY={4}>
          <Text size="sm" weight="medium" className="leading-5">
            {name}
          </Text>
          <Text size="sm" color="text-500" className="leading-5">
            {description}
          </Text>
        </TextContainer>
        {!isCustomerVerified && (
          <ActionsContainer justifyContent="center" alignItems="center" flex paddingR={32}>
            <StatusPill
              state={StatusMapping[status]}
              label={StatusLabelMapping[status]}
              fitContent
            />
          </ActionsContainer>
        )}
      </Box>
      <ContextMenuContainer flex gapX={12}>
        <PopoverMenu
          menuItems={[
            {
              item: (
                <Button
                  testid="customers-details-btn"
                  noStyling
                  name={t('customers.details')}
                  iconElement={<Icon name="InfoCircleIcon" />}
                >
                  {t('customers.details')}
                </Button>
              ),
              onClick: () => onDetailsClick(),
            },
          ]}
          placement={'bottomRight' as PopoverPlacement}
          hoverClass="cursor-pointer"
        >
          <Icon color="grey-08" name="MeatballIcon" />
        </PopoverMenu>
      </ContextMenuContainer>
    </Box>
  )
}

export default CustomerListItem
