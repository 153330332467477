import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { applyPasswordTests } from 'utils/password'

export const twoFaSchema = (t: TFunction<'translation', undefined>) => {
  const getFieldBase = (label: string) => {
    return yup
      .string()
      .trim()
      .required(t('fieldRequired', { field: label }))
      .label(label)
  }

  return yup.object<Partial<Record<string, yup.AnySchema>>>({
    password: getFieldBase(t('password')).test({
      name: 'password',
      test: (value, ctx) => {
        const { isValid, message } = applyPasswordTests(value, t)
        return isValid
          ? true
          : ctx.createError({
              message: message,
              path: 'password',
            })
      },
    }),
  })
}
