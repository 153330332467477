import React, { useEffect, useMemo } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import {
  AlignActions,
  ModalActions,
  ModalBody,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { BeneficiaryTransferDestination } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { roundNumberWithCommas } from 'utils/utils'
import { selectedBvnkNetworkBeneficiaryAtom, selectedFiatBeneficiaryAtom } from './atoms/atoms'
import { PayActionProps } from './Pay'
import { getRecipientName } from './utils/payFormUtils'

const PayComplete = ({ dismissAction, form, wallets, refresh }: PayActionProps) => {
  const selectedFiatBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const selectedBvnkNetworkBeneficiary = useAtomValue(selectedBvnkNetworkBeneficiaryAtom)
  const { t } = useTranslation()
  const formValues = form?.watch()
  const isBvnkNetworkPayment = !!selectedBvnkNetworkBeneficiary && !selectedFiatBeneficiary
  const recipientName = isBvnkNetworkPayment
    ? selectedBvnkNetworkBeneficiary?.details?.name
    : getRecipientName(selectedFiatBeneficiary)

  // refresh wallets
  useEffect(() => {
    refresh()
    track.Amp.track(AmplitudeEvent.PAY_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
      amount: formValues?.amount,
      iban: selectedFiatBeneficiary?.fiat?.accountNumber?.toLowerCase(),
      beneficiary: recipientName?.toLowerCase(),
      beneficiaryType: selectedFiatBeneficiary?.transferDestination?.toLowerCase(),
      alias: selectedFiatBeneficiary?.alias,
    })
  }, [])

  const actions: ButtonProps[] = [{ children: t('done'), onClick: dismissAction }]
  const selectedWallet =
    formValues?.walletId && wallets?.find(wallet => wallet?.id?.toString() === formValues?.walletId)

  const disclaimer = useMemo(() => {
    if (isBvnkNetworkPayment) {
      return ''
    } else {
      return selectedFiatBeneficiary?.transferDestination ===
        BeneficiaryTransferDestination.INTERNATIONAL
        ? t('internationalPaymentConfirmationDisclaimer')
        : t('localPaymentConfirmationDisclaimer')
    }
  }, [isBvnkNetworkPayment, selectedFiatBeneficiary?.transferDestination])

  return (
    <>
      <ModalScrollable>
        <Box data-testid="send-complete">
          <ModalNavBar onClose={dismissAction} hasDivider={false} />
          <ModalBody hasTopPadding={false}>
            <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
              <Icon name="ClapIcon" color="status-success-500" size="lg" />
              <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
                {t('paymentSubmitted')}
              </Text>
              <Text size="sm" weight="medium" className="text-gray-120">
                {`${roundNumberWithCommas(formValues?.amount)} ${
                  selectedWallet?.currency?.code
                } ${t('onItsWayTo')} `}
                {recipientName}
              </Text>

              {disclaimer && (
                <Text size="sm" className="text-gray-120 text-center">
                  {disclaimer}
                </Text>
              )}
            </Box>
          </ModalBody>
        </Box>
      </ModalScrollable>
      <ModalActions actions={actions} alignActions={AlignActions.CENTER} />
    </>
  )
}

export default PayComplete
