import React from 'react'
import i18next from 'i18next'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface DefaultPaymentProps {
  amountWithCurrency: string
  merchantId: string
}

const DefaultPayment = ({ amountWithCurrency, merchantId }: DefaultPaymentProps) => {
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.paymentOutAmountPaid')}
          detail={amountWithCurrency}
        />
      )}

      {merchantId && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.merchantId')}
          detail={merchantId}
        />
      )}
    </>
  )
}

export default DefaultPayment
