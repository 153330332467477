import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Box as LeftHeaderWrapper,
  Box as RightHeaderWrapper,
} from '@node-space/storybook-components/dist/Box'
import { Button as HamburgerButton } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Breadcrumbs, { type CustomBreadCrumbConfig } from 'components/Breadcrumbs'
import { LogoColor } from 'types/types'
import { UserMenu } from './header/UserMenu'
import { Logo } from './logos/Logo'

interface HeaderProps {
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  title?: string
  isTitleEnabled?: boolean
  isHamburgerEnabled?: boolean
  breadcrumbConfig?: CustomBreadCrumbConfig[]
}

const Header = ({
  sidebarOpen,
  setSidebarOpen,
  isTitleEnabled,
  isHamburgerEnabled = true,
  breadcrumbConfig,
}: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <header className="sticky top-0 border-b border-borderColor z-30">
      <Box paddingX={16} paddingY={12} className="sm:px-6 lg:px-8">
        <Box flex alignItems="center" justifyContent="between" height={40}>
          <LeftHeaderWrapper flex alignItems="center">
            {isHamburgerEnabled && (
              <HamburgerButton
                className="text-gray-500 hover:text-gray-600 lg:hidden mr-3"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                iconElement={<Icon name="HamburgerIcon" size="lg" color="grey-10" />}
                noStyling
              >
                <span className="sr-only">{t('openSidebar')}</span>
              </HamburgerButton>
            )}

            {!isHamburgerEnabled && (
              <Box className="mr-3">
                <Logo logoColor={LogoColor.DARK} />
              </Box>
            )}

            {isTitleEnabled && <Breadcrumbs customConfig={breadcrumbConfig} />}
          </LeftHeaderWrapper>

          <RightHeaderWrapper flex alignItems="center">
            <UserMenu />
          </RightHeaderWrapper>
        </Box>
      </Box>
    </header>
  )
}

export default Header
