import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { logSentryError } from '@node-space/utils'
import { useWalletContext } from 'hooks/context/useWalletContext'
import { useSendAllTransactionsToEmailMutation } from 'hooks/mutations/useTransactionExportToEmailAllWalletsMutation'
import { useSendWalletTransactionsToEmailMutation } from 'hooks/mutations/useTransactionExportToEmailMutation'
import {
  customDateValueOptions,
  exportFormatRadioOptions,
} from 'pages/WalletDetails/constants/walletTransactionsExportOptions'
import { BaseErrorResponse } from 'types/beneficiaries'
import { WalletTransactionsExport } from 'types/transaction/GetTransactionsReq'
import { IDateValue } from 'types/types'
import {
  ExportAccordionItems,
  ExportFileFormat,
  ExportFileFormatType,
  OnceOffExportForm,
  OnceOffExportSteps,
  OnceOffExportStepsType,
} from 'types/walletTransactionsExport'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface UseOnceOffExportHelperProps {
  handleClose: () => void
  downloadAll: boolean
}

export const useOnceOffExportHelper = ({
  downloadAll,
  handleClose,
}: UseOnceOffExportHelperProps) => {
  const wallet = useWalletContext()

  const {
    mutate: sendAllTransactionsToEmail,
    isPending: isLoadingAllTransactionsExport,
    error: allTransactionsExportError,
    isError: isErrorAllTransactionsExport,
  } = useSendAllTransactionsToEmailMutation()
  const {
    mutate: sendWalletTransactionsToEmail,
    isPending: isLoadingWalletTransactionsExport,
    error: walletTransactionsExportError,
    isError: isErrorWalletTransactionsExport,
  } = useSendWalletTransactionsToEmailMutation()

  const [step, setStep] = useState<OnceOffExportStepsType>(OnceOffExportSteps.FORM)
  const [exportFormat, setExportFormat] = useState<ExportFileFormatType>(ExportFileFormat.CSV)
  const [dateValue, setDateValue] = useState<IDateValue>({
    startDate: null,
    endDate: null,
  })

  const exportFormatOptions = exportFormatRadioOptions()
  const exportCustomDateOptions = customDateValueOptions(dateValue?.startDate, dateValue?.endDate)

  const { watch, setValue } = useForm({
    defaultValues: {
      selectedDateOptionName: exportCustomDateOptions?.last30Days?.name,
      selectedExportFormat: exportFormatOptions[0].value as ExportFileFormatType,
    },
    mode: 'onChange',
  })
  const formValues: OnceOffExportForm = watch()

  const customDateValueName = formValues.selectedDateOptionName
  const selectedFromDateVal = exportCustomDateOptions?.[customDateValueName]?.fromDate
  const selectedToDateVal = exportCustomDateOptions?.[customDateValueName]?.toDate
  const selectedDateRange = { from: selectedFromDateVal, to: selectedToDateVal }

  const amplitudeProperties = {
    category: AmplitudeEventCategory.MERCHANT_PORTAL,
    action: AmplitudeEventAction.LOAD,
    type: ExportAccordionItems.ONCE_OFF,
    format: formValues?.selectedExportFormat,
    from: selectedFromDateVal,
    to: selectedToDateVal,
    ...(!downloadAll && {
      walletId: wallet?.id,
    }),
  }

  const handleTransactionExportToEmail = () => {
    if (downloadAll) {
      sendAllTransactionsToEmail(selectedDateRange, {
        onSuccess: () => {
          track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_SUCCESS, {
            ...amplitudeProperties,
          })

          setStep(OnceOffExportSteps.COMPLETE)
        },
        onError: (error: BaseErrorResponse) => {
          track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_ERROR, {
            ...amplitudeProperties,
            error,
          })
          logSentryError(
            'Error from TransactionExportModal - handleTransactionExportToEmail',
            error
          )
        },
      })
    } else {
      const walletId = wallet?.id

      const walletExport: WalletTransactionsExport = {
        selectedExportFormat: formValues.selectedExportFormat,
        walletId,
        selectedDateRange,
      }

      sendWalletTransactionsToEmail(walletExport, {
        onSuccess: () => {
          track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_SUCCESS, {
            ...amplitudeProperties,
          })

          setStep(OnceOffExportSteps.COMPLETE)
        },
        onError: (error: BaseErrorResponse) => {
          track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_ERROR, {
            ...amplitudeProperties,
            error,
          })
          logSentryError(
            'Error from TransactionExportModal - handleTransactionExportToEmail',
            error
          )
        },
      })
    }
  }

  return {
    step,
    setStep,
    formValues,
    setValue,
    exportCustomDateOptions,
    dateValue,
    setDateValue,
    exportFormatOptions,
    exportFormat,
    setExportFormat,
    handleTransactionExportToEmail,
    isExporting: isLoadingWalletTransactionsExport || isLoadingAllTransactionsExport,
    handleClose,
    allTransactionsExportError,
    isErrorAllTransactionsExport,
    walletTransactionsExportError,
    isErrorWalletTransactionsExport,
  }
}
