import React from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { Country } from 'types/authentication/Profile'
import {
  BeneficiaryDetails,
  BeneficiaryEntityType,
  BeneficiaryTransferDestination,
} from 'types/beneficiaries'
import { BeneficiaryDetailRow } from './BeneficiaryDetailRow'

export type BeneficiarySidePanelBodyProps = {
  beneficiaryDetails: BeneficiaryDetails
  getCountryNameByCode: (code: string, countries: Country[]) => string
}

export const BeneficiarySidePanelBody = ({
  beneficiaryDetails,
  getCountryNameByCode,
}: BeneficiarySidePanelBodyProps) => {
  const { t } = useTranslation()
  const { data: countries, isPending: isLoadingCountries } = useCountriesQuery(true)

  // Beneficiary Details
  const accountNumber = beneficiaryDetails?.fiat?.accountNumber
  const bankCode = beneficiaryDetails?.fiat?.code
  const bankName = beneficiaryDetails?.fiat?.bankName
  const dateOfBirth = beneficiaryDetails?.dateOfBirth
  const {
    addressLine1: beneficiaryAddressLine1,
    city: beneficiaryAddressCity,
    region: beneficiaryAddressRegion,
    postCode: beneficiaryAddressPostCode,
    country: beneficiaryAddressCountry,
  } = beneficiaryDetails?.address || {}
  const hasAddress = beneficiaryAddressLine1 || beneficiaryAddressCity || beneficiaryAddressCountry
  const isPersonalDataPresent = dateOfBirth || beneficiaryAddressCountry || hasAddress
  const isInternationalBeneficiary =
    beneficiaryDetails?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL

  // Bank Details
  const {
    addressLine1: bankAddressLine1,
    city: bankAddressCity,
    country: bankAddressCountry,
    region: bankAddressRegion,
    postCode: bankAddressPostCode,
  } = beneficiaryDetails?.fiat?.bankAddress || {}
  const hasBankAddress = bankAddressLine1 || bankAddressCity || bankAddressCountry

  // Intermediary Bank Details
  const {
    bankAddress: intermediaryBankAddress,
    code: intermediaryBankBankCode,
    bankName: intermediaryBankName,
  } = beneficiaryDetails?.fiat?.intermediaryBank || {}
  const {
    addressLine1: intermediaryBankAddressLine1,
    city: intermediaryBankCity,
    postCode: intermediaryBankPostCode,
    region: intermediaryBankRegion,
    country: intermediaryBankCountry,
  } = intermediaryBankAddress || {}
  const hasIntermediaryBankAddress =
    intermediaryBankAddressLine1 || intermediaryBankCity || intermediaryBankCountry
  const isIntermediaryBankDataPresent = intermediaryBankBankCode || intermediaryBankName

  return isLoadingCountries ? (
    <Box flex flexJustifyCenter>
      <Loader theme="dark" size="large" />
    </Box>
  ) : (
    <Box>
      <Text size="base" color="dark-grey">
        {t('bankDetails')}
      </Text>
      <Box paddingT={8} paddingB={12}>
        {accountNumber && (
          <BeneficiaryDetailRow
            label={t('accountNumber')}
            detail={
              <Box flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {ellipseMiddleText(accountNumber, 8, 8)}
                </Text>
                <CopyButton
                  copyText={accountNumber}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                  confirmationMessageTheme="dark"
                />
              </Box>
            }
          />
        )}
        {bankCode && (
          <BeneficiaryDetailRow
            label={t('bankCode')}
            detail={
              <Box flex flexItemsCenter gap={8}>
                <Text tag="span" size="sm">
                  {bankCode}
                </Text>
                <CopyButton
                  copyText={bankCode}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                  confirmationMessageTheme="dark"
                />
              </Box>
            }
          />
        )}
        {bankName && <BeneficiaryDetailRow label={t('bankName')} detail={bankName} />}
        {isInternationalBeneficiary && hasBankAddress && (
          <BeneficiaryDetailRow
            label={t('bankAddress')}
            detail={
              <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                <Text className="text-left lg:text-right" tag="span" size="sm">
                  {`${bankAddressLine1}, ${bankAddressCity}, ${bankAddressPostCode},`}
                </Text>
                <Text className="text-left lg:text-right" tag="span" size="sm">
                  {`${bankAddressRegion}, ${getCountryNameByCode(bankAddressCountry, countries)}`}
                </Text>
              </Box>
            }
          />
        )}
      </Box>
      {isIntermediaryBankDataPresent && isInternationalBeneficiary && (
        <Box>
          <Text size="base" color="dark-grey">
            {t('manageBeneficiaries.intermediaryBankDetails')}
          </Text>
          <Box paddingT={8} paddingB={12}>
            {intermediaryBankBankCode && (
              <BeneficiaryDetailRow
                label={t('bankCode')}
                detail={
                  <Box flex flexItemsCenter gap={8}>
                    <Text tag="span" size="sm">
                      {intermediaryBankBankCode}
                    </Text>
                    <CopyButton
                      copyText={intermediaryBankBankCode}
                      confirmationMessage={t('copiedToClipboard')}
                      iconColour={copyButtonIconColour}
                      confirmationMessageAlignment="left"
                      confirmationMessageTheme="dark"
                    />
                  </Box>
                }
              />
            )}
            {intermediaryBankName && (
              <BeneficiaryDetailRow
                label={t('bankName')}
                detail={
                  <Box flex flexJustifyEnd flexWrap>
                    <Text align="right" tag="span" size="sm">
                      {intermediaryBankName}
                    </Text>
                  </Box>
                }
              />
            )}
            {hasIntermediaryBankAddress && (
              <BeneficiaryDetailRow
                label={t('bankAddress')}
                detail={
                  <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                    <Text className="text-left lg:text-right" tag="span" size="sm">
                      {`${intermediaryBankAddressLine1}, ${intermediaryBankCity}, ${intermediaryBankPostCode},`}
                    </Text>
                    <Text className="text-left lg:text-right" tag="span" size="sm">
                      {`${intermediaryBankRegion}, ${getCountryNameByCode(
                        intermediaryBankCountry,
                        countries
                      )}`}
                    </Text>
                  </Box>
                }
              />
            )}
          </Box>
        </Box>
      )}
      {isPersonalDataPresent && (
        <Box>
          <Text size="base" color="dark-grey">
            {t('personalDetails')}
          </Text>
          <Box paddingT={8} paddingB={8}>
            {dateOfBirth && (
              <BeneficiaryDetailRow label={t('formFields.dateOfBirth')} detail={dateOfBirth} />
            )}
            {isInternationalBeneficiary && hasAddress && (
              <BeneficiaryDetailRow
                label={t('manageBeneficiaries.beneficiaryAddress')}
                detail={
                  <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                    <Text className="text-left lg:text-right" tag="span" size="sm">
                      {`${beneficiaryAddressLine1}, ${beneficiaryAddressCity}, ${beneficiaryAddressPostCode},`}
                    </Text>
                    <Text className="text-left lg:text-right" tag="span" size="sm">
                      {`${beneficiaryAddressRegion}, ${getCountryNameByCode(
                        beneficiaryAddressCountry,
                        countries
                      )}`}
                    </Text>
                  </Box>
                }
              />
            )}
            {!isInternationalBeneficiary && beneficiaryAddressCountry && (
              <BeneficiaryDetailRow
                label={
                  beneficiaryDetails?.entityType === BeneficiaryEntityType.BUSINESS
                    ? t('manageBeneficiaries.countryOfRegistration')
                    : t('manageBeneficiaries.countryOfResidence')
                }
                detail={getCountryNameByCode(beneficiaryAddressCountry, countries)}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
