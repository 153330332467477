import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import Text from '@node-space/storybook-components/dist/Text'
import { useWalletContext } from 'hooks/context'
import { useProtocolContext } from 'hooks/context/useProtocolContext'
import { copyButtonIconColour } from 'styles/theme/colours'
import { getWalletProtocolNetwork } from './getWalletProtocolNetwork'

export const WalletAddressDetails = () => {
  const { t } = useTranslation()
  const wallet = useWalletContext()
  const { currentProtocol } = useProtocolContext()

  const address = currentProtocol?.address || wallet?.address
  const { selectedProtocolNetwork: protocolNetwork } = getWalletProtocolNetwork(
    wallet,
    currentProtocol
  )

  return (
    <Box className="self-center">
      <Text testid="wallet-details-address-label" size="sm" weight="medium">
        {t('yourAddress', {
          currencyCode: wallet.currency?.code?.toUpperCase(),
          protocol: protocolNetwork ? `(${protocolNetwork} network)` : '',
        })}
      </Text>

      <Box data-testid="wallet-details-address" flex gap={64} paddingB={32}>
        <Text className="w-48 break-all md:w-full" size="sm" color="text-500">
          {address}
        </Text>
        <CopyButton
          copyText={address}
          confirmationMessage={t('copiedToClipboard')}
          iconColour={copyButtonIconColour}
        />
      </Box>
      {wallet.lookup && (
        <>
          <Text testid="wallet-details-destination-tag" size="sm" weight="medium">
            {t('destinationTag')}
          </Text>

          <Box data-testid="wallet-details-wallet-lookup" flex flexJustifyBetween>
            <Text
              className="break-all"
              testid="wallet-details-wallet-lookup"
              size="sm"
              align="center"
              color="grey"
            >
              {wallet.lookup}
            </Text>

            <CopyButton
              testid="wallet-details-copy-button"
              copyText={wallet.lookup}
              confirmationMessage={t('copiedToClipboard')}
              iconColour={copyButtonIconColour}
            />
          </Box>
        </>
      )}
    </Box>
  )
}
