import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import Modal, {
  AlignActions,
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useAccountApprovalsPreferenceMutation } from 'hooks/mutations/useAccountApprovalsPreferenceMutation'
import { useApprovalsContext } from 'hooks/useApprovalsContext'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useApprovalFlowsCheckboxOptions } from './hooks/useApprovalsFlowSettings'

const ApprovalPreferenceSettingsModal = () => {
  const { t } = useTranslation()

  const { currentAccount } = useAccountsContext()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const {
    permissions,
    approvalsConfig: { approvalPreferences, handleApprovalPreferenceSettingsModal },
  } = useApprovalsContext()
  const currentAccountReference = currentAccount?.reference || ''

  const { mutate: updateAccountApprovalsPreference, isPending: updatePreferenceIsProcessing } =
    useAccountApprovalsPreferenceMutation()

  const { approvalFlowCheckboxOptions, approvalFlowTranslations } =
    useApprovalFlowsCheckboxOptions(approvalPreferences)

  const [checkBoxItems, setCheckboxItems] = useState(approvalFlowCheckboxOptions)
  const [preferencesConfig, setPreferencesConfig] = useState([])

  const onCheckboxChange = (index: number) => {
    const copyOfCheckboxItems = cloneDeep(checkBoxItems)
    copyOfCheckboxItems[index].checked = !copyOfCheckboxItems?.[index]?.checked

    const existingValue = approvalFlowCheckboxOptions?.[index]?.checked
    if (existingValue !== copyOfCheckboxItems[index].checked) {
      preferencesConfig[index] = {
        flow: checkBoxItems?.[index]?.value,
        preference: Approval.ACCOUNT,
        enabled: copyOfCheckboxItems?.[index]?.checked,
        parentId: currentAccountReference,
      }
      setPreferencesConfig(preferencesConfig)
    } else {
      delete preferencesConfig[index]
      setPreferencesConfig(preferencesConfig)
    }
    setCheckboxItems(copyOfCheckboxItems)
  }

  const cleanPreferencesConfig = preferencesConfig?.filter(Boolean)

  const handleApprovalPreferenceUpdate = () => {
    updateAccountApprovalsPreference(
      {
        data: { preferences: cleanPreferencesConfig },
      },
      {
        onSuccess: () => {
          addToast({
            title: t('approvals.approvalPreferenceUpdated'),
            state: 'success',
            delay: 5,
          })
          handleClose()
          queryClient.invalidateQueries({
            queryKey: reactQueryKeys.accountApprovalsPreference(currentAccountReference),
          })
          track.Amp.track(AmplitudeEvent.UPDATE_APPROVALS_PREFERENCE, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.CLICK,
            updatedFlows: preferencesConfig,
          })
        },
        onError: (error: unknown) => {
          addToast({
            title: t('oopsSomethingWentWrongContracted'),
            state: 'error',
            delay: 5,
          })
          logSentryError(
            'Error from ApprovalPreferenceSettingsModal - handleApprovalPreferenceUpdate',
            error
          )
        },
      }
    )
  }

  const handleClose = () => handleApprovalPreferenceSettingsModal({ isVisible: false })

  const modalActions = permissions?.canEdit
    ? [
        {
          children: t('cancel'),
          secondary: true,
          onClick: handleClose,
        },
        {
          children: t('save'),
          loading: updatePreferenceIsProcessing,
          disabled: !cleanPreferencesConfig?.length || updatePreferenceIsProcessing,
          onClick: handleApprovalPreferenceUpdate,
        },
      ]
    : [
        {
          children: t('done'),
          onClick: handleClose,
        },
      ]

  return (
    <Modal closeOnBackgroundClick={false} closeModal={handleClose} visible>
      <ModalNavBar
        title={
          permissions?.canEdit
            ? t('approvals.enableApproval')
            : t('approvals.viewApprovalPreference')
        }
        onClose={handleClose}
      />
      <ModalBody spaceChildren={0}>
        {checkBoxItems?.length !== Object.keys(approvalFlowTranslations)?.length ? (
          <Callout state="error">{t('approvals.approvalFlowConfigurationIssue')}</Callout>
        ) : (
          checkBoxItems?.map((item, index) => (
            <Box paddingB={16} key={item?.value}>
              <CheckBox
                disabled={!permissions?.canEdit}
                label={item?.label}
                value={item?.checked}
                onChange={() => onCheckboxChange(index)}
              />
            </Box>
          ))
        )}
      </ModalBody>
      <ModalActions
        actions={modalActions}
        {...(!permissions?.canEdit && { alignActions: AlignActions.CENTER })}
      />
    </Modal>
  )
}

export default React.memo(ApprovalPreferenceSettingsModal)
