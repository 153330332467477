import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import Modal, {
  AlignActions,
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { logSentryError } from '@node-space/utils'
import { Approval, PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useAccountApprovalsPreferenceMutation } from 'hooks/mutations/useAccountApprovalsPreferenceMutation'
import { useApprovalsContext } from 'hooks/useApprovalsContext'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { ApprovalRulesData, UpdateAccountApprovalsPreferenceV2 } from 'types/approvals'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import ApprovalRangeForm from './ApprovalRangeForm'
import { useApprovalFlowsCheckboxOptions } from './hooks/useApprovalsFlowSettings'

const DEFAULT_APPROVAL_LIMIT: ApprovalRulesData[] = [
  {
    minAmount: 0.0,
    maxAmount: Approval.RULES.INFINITY,
    approvalsCount: 1,
  },
]

const ApprovalPreferenceSettingsModalV2 = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { currentAccount } = useAccountsContext()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const {
    permissions,
    approvalsConfig: {
      approvalPreferences,
      approvalPreferencesLimits,
      handleApprovalPreferenceSettingsModal,
      handleApprovalPreferenceSuccessModal,
    },
  } = useApprovalsContext()
  const currentAccountReference = currentAccount?.reference || ''

  const { mutate: updateAccountApprovalsPreference, isPending: updatePreferenceIsProcessing } =
    useAccountApprovalsPreferenceMutation()

  const { approvalFlowCheckboxOptions, approvalFlowTranslations } =
    useApprovalFlowsCheckboxOptions(approvalPreferences)
  const approvalRangeLimits = approvalPreferencesLimits?.length
    ? approvalPreferencesLimits
    : DEFAULT_APPROVAL_LIMIT

  const [checkBoxItems, setCheckboxItems] = useState(approvalFlowCheckboxOptions)
  const [isRefetching, setIsRefetching] = useState(false)
  const [preferencesConfig, setPreferencesConfig] = useState([])
  const [approvalRulesData, setApprovalRulesData] =
    useState<ApprovalRulesData[]>(approvalRangeLimits)
  const [isApprovalRuleInvalid, setIsApprovalRuleInvalid] = useState(false)

  const onCheckboxChange = (index: number) => {
    const copyOfCheckboxItems = cloneDeep(checkBoxItems)
    copyOfCheckboxItems[index].checked = !copyOfCheckboxItems?.[index]?.checked

    const existingValue = approvalFlowCheckboxOptions?.[index]?.checked
    if (existingValue !== copyOfCheckboxItems[index].checked) {
      preferencesConfig[index] = {
        flow: checkBoxItems?.[index]?.value,
        enabled: copyOfCheckboxItems?.[index]?.checked,
      }
      setPreferencesConfig(preferencesConfig)
    } else {
      delete preferencesConfig[index]
      setPreferencesConfig(preferencesConfig)
    }
    setCheckboxItems(copyOfCheckboxItems)
  }

  const updatedPreferencesConfig = preferencesConfig?.filter(Boolean)

  const getApprovalsPreferencePayload = (): UpdateAccountApprovalsPreferenceV2 => {
    const preferences = checkBoxItems?.map(checkBoxItem => {
      return {
        flow: checkBoxItem?.value,
        enabled: checkBoxItem?.checked,
      }
    })
    const limits = approvalRulesData
    const limitsLength = limits?.length
    if (limitsLength) {
      const lastLimit = limits?.[limitsLength - 1]
      if (lastLimit.maxAmount === Approval.RULES.INFINITY) {
        lastLimit.maxAmount = null
      }
    }
    if (limitsLength === 1) {
      limits[0].approvalsCount = limits?.[0]?.approvalsCount || 1
    }

    return {
      parentId: currentAccountReference,
      preference: Approval.ACCOUNT,
      preferences,
      limits,
    }
  }

  const handleApprovalPreferenceUpdate = () => {
    const accountApprovalsPreference = getApprovalsPreferencePayload()

    setIsRefetching(true)
    updateAccountApprovalsPreference(
      { data: accountApprovalsPreference, isV2Api: true },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: reactQueryKeys.accountApprovalsPreference(currentAccountReference),
          })
          setIsRefetching(false)
          handleApprovalPreferenceSuccessModal({ isVisible: true })
          track.Amp.track(`${AmplitudeEvent.UPDATE_APPROVALS_PREFERENCE} V2`, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.CLICK,
            updatedFlows: updatedPreferencesConfig,
          })
        },
        onError: (error: unknown) => {
          addToast({
            title: t('oopsSomethingWentWrongContracted'),
            state: 'error',
            delay: 5,
          })
          logSentryError(
            'Error from ApprovalPreferenceSettingsModalV2 - handleApprovalPreferenceUpdate',
            error
          )
          setIsRefetching(false)
        },
      }
    )
  }

  const handleClose = () => handleApprovalPreferenceSettingsModal({ isVisible: false })

  const modalActions = permissions?.canEdit
    ? [
        {
          children: t('cancel'),
          secondary: true,
          onClick: handleClose,
        },
        {
          children: t('save'),
          loading: updatePreferenceIsProcessing || isRefetching,
          disabled: isApprovalRuleInvalid || updatePreferenceIsProcessing || isRefetching,
          onClick: handleApprovalPreferenceUpdate,
        },
      ]
    : [
        {
          children: t('done'),
          onClick: handleClose,
        },
      ]

  return (
    <Modal closeOnBackgroundClick={false} closeModal={handleClose} visible>
      <ModalNavBar
        title={
          permissions?.canEdit
            ? t('approvals.manageApprovalRules')
            : t('approvals.viewApprovalRules')
        }
        onClose={handleClose}
      />
      <ModalBody spaceChildren={0}>
        <Callout state="info">
          <Text size="sm" className="mb-2">
            {t('approvals.manageApprovalRulesDescription')}
          </Text>
          <TextAction
            text={t('approvals.changeYourBaseCurrency')}
            onClick={() => navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_USER_PROFILE}`)}
          />
        </Callout>
        <Box paddingY={12}>
          <Text size="sm" className="my-3">
            {t('approvals.typeOfOutgoingPayment')}
          </Text>
          {checkBoxItems?.length !== Object.keys(approvalFlowTranslations)?.length ? (
            <Callout state="error">{t('approvals.approvalFlowConfigurationIssue')}</Callout>
          ) : (
            checkBoxItems?.map((item, index) => (
              <Box paddingB={8} key={item?.value}>
                <CheckBox
                  disabled={!permissions?.canEdit}
                  label={item?.label}
                  value={item?.checked}
                  onChange={() => onCheckboxChange(index)}
                />
              </Box>
            ))
          )}
        </Box>
        <ApprovalRangeForm
          approvalRulesData={approvalRulesData}
          setApprovalRulesData={setApprovalRulesData}
          isApprovalRuleInvalid={isApprovalRuleInvalid}
          setIsApprovalRuleInvalid={setIsApprovalRuleInvalid}
        />
      </ModalBody>
      <ModalActions
        actions={modalActions}
        {...(!permissions?.canEdit && { alignActions: AlignActions.CENTER })}
      />
    </Modal>
  )
}

export default React.memo(ApprovalPreferenceSettingsModalV2)
