import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { putReportSchedule } from 'services/TransactionExportService'

export const usePutReportScheduleMutation = () => {
  return useMutation({
    mutationFn: putReportSchedule,
    onError: error => logSentryError('Error: usePutReportScheduleMutation', error),
  })
}
