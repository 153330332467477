import { Address } from './beneficiaries'
import { ErrorList } from './payments'
import { Currency, Options } from './types'

export interface WalletDescription {
  walletId: number
  description: string
}

export const WalletTypes = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
}

export type TWalletType = (typeof WalletTypes)[keyof typeof WalletTypes]

export const WalletActions = {
  PAY: 'pay',
  WITHDRAW: 'withdraw',
  DEPOSIT: 'deposit',
  SEND: 'send',
  RECEIVE: 'receive',
  CONVERT: 'convert',
} as const

export type WalletActionType = (typeof WalletActions)[keyof typeof WalletActions]

export interface WalletPayReceiverDetails {
  address?: Address
  bankAccountNumber?: string
  bankAddress?: Address
  bankName?: string
  code?: string
  companyName?: string
  countryCode?: string
  dateOfBirth?: string
  firstName?: string
  lastName?: string
  reference: string
  type?: string
}
export interface WalletsPayRequest {
  amount: number
  authorization: Authorization
  receiver: WalletPayReceiverDetails
}
export interface WalletsPayoutsRequest {
  address: string
  paymentReference?: string
  amount: string | number
  authorization: Authorization
}
export interface PayBeneficiaryRequest {
  walletId: string
  payRequest: WalletsPayRequest
}
export interface PayoutsRequest {
  walletId: string
  requestData: WalletsPayoutsRequest
}

export interface WalletsWithdrawResponse {
  id: number
}

export interface WalletsWithdrawResponseError {
  errorList: ErrorList[]
}

export interface WalletsWithdrawValidateRequest {
  address: string
  amount: number
  authorization: Authorization
}

export interface WalletsWithdrawValidateResponse {
  errorList: ErrorList[]
}

export interface WalletsWithdrawValidateResponseError {
  errorList: ErrorList[]
}

export interface Authorization {
  password?: string
  totp?: string
}

export interface CurrencyInterface {
  id: number
  code: string
  fiat?: boolean
  depositFee?: number
  icon?: string
  name: string
  options?: Options
  pricePrecision?: number
  quantityPrecision?: number
  supportsDeposits?: boolean
  supportsWithdrawal?: boolean
  withdrawalFee?: number
  withdrawalParameters?: []
}

export interface WalletsCreateRequest {
  description: string
  currency: string
}

export interface postConvert {
  from: string
  to: string
  fromWallet: number
  toWallet?: number
  amountIn?: string
  amountOut?: string
  useMaximum?: boolean
  useMinimum?: boolean
  reference?: string
}

export interface quoteEstimate {
  from: string
  to: string
}

export interface Balance {
  currency: Currency
  walletId: number
  available: number
  reserved: number
  convertedAvailable: number
  convertedReserved: number
  total: number
}

export interface WalletSummary {
  currency: Currency
  totalCrypto: number
  totalFiat: number
  total: number
  balances: Balance[]
}

export interface PayFee {
  currencyCode: string
  feePercentage?: number
  fee?: number
  maximumWithdrawalAmount?: number
}

export const WithdrawSteps = {
  AUTH_2FA: '2fa',
  FORM: 'form',
  CONFIRM: 'confirm',
  COMPLETE: 'complete',
} as const

export type WithdrawSteps = (typeof WithdrawSteps)[keyof typeof WithdrawSteps]

export const WalletStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED_BY_ACCOUNT_SUSPENSION: 'SUSPENDED_BY_ACCOUNT_SUSPENSION',
} as const

export type WalletStatus = (typeof WalletStatus)[keyof typeof WalletStatus]
