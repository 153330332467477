import { getSupportedPaymentTypes } from 'utils/utils'

export const isTransactionANetworkTransfer = (
  transactionType: string,
  transactionSubType: string
) => {
  const supportedPaymentTypes = getSupportedPaymentTypes()
  const transactionTypeAndSubType = `${transactionType}_${transactionSubType}`

  return (
    transactionTypeAndSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_OUT ||
    transactionTypeAndSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_IN
  )
}
