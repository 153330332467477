import React, { memo, SetStateAction } from 'react'
import { snakeCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Box as FormRow } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Search } from '@node-space/storybook-components/dist/Search'
import SimpleTableV2, { TableRowV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import NoSearchResults from 'components/NoSearchResults/NoSearchResults'
import { Description } from 'components/text/Description'
import { PathNames } from 'constants/General'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useWalletControllerContext } from 'hooks/useWalletControllerContext'
import { TableSectionItem } from 'pages/Wallets/types'
import { WalletType } from 'types/types'
import { TWalletType, WalletTypes } from 'types/wallets'
import { WalletAction } from '../Modal/WalletModal'
import Submenu from '../Submenu'
import Balance from './Balance'
import Currency from './Currency'
import { formatTotal } from './utils'

interface WalletTableProps {
  testid: string
  wallets: WalletType[]
  walletType: TWalletType
  setWalletModalAction: React.Dispatch<SetStateAction<WalletAction>>
  handleEditWalletName: (walletId: string, walletName: string) => void
  searchQuery?: string
  setShowWalletModal?: React.Dispatch<React.SetStateAction<boolean>>
  clearFilters?: () => void
  onSearchTermChange?: React.Dispatch<React.SetStateAction<string>>
  isWalletBeingSearched?: boolean
  debouncedSearch?: string
}

const rowHeaderWidth = 'px-8 w-1/3.5'

const WalletTable = ({
  searchQuery,
  onSearchTermChange,
  testid,
  wallets,
  walletType,
  setShowWalletModal,
  clearFilters,
  setWalletModalAction,
  handleEditWalletName,
  isWalletBeingSearched,
  debouncedSearch,
}: WalletTableProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isFetching, isSuccess, totalFiat, totalCrypto, hasErrors, baseCurrency } =
    useWalletControllerContext()

  const inactiveWalletLabel = (
    <Box flex alignItems="center" gap={4}>
      <Icon size="sm" name="LockIcon" color={'status-error-900'} />
      <Text color="status-error-900" size="sm">
        {t('unavailable').toUpperCase()}
      </Text>
    </Box>
  )

  const buildWalletTableInfo = () => {
    return wallets?.map((wallet: TableSectionItem) => {
      const isSelectedWalletActive = useIsActiveWallet(wallet)

      return {
        testid: `wallet-row-${snakeCase(wallet?.description)}`,
        onClick: (e: { stopPropagation: () => void }) => {
          e?.stopPropagation()
          navigate(`${PathNames.WALLETS}/wallet-details/${wallet.id}?tab='recent-activity'`)
        },
        rowData: [
          {
            cellData: <Currency wallet={wallet} isEmoney={wallet?.isEmoney} />,
            testid: `wallet-${snakeCase(wallet?.description)}-name`,
          },
          !isSelectedWalletActive && {
            cellData: <StatusPill fitContent state={'error'} label={inactiveWalletLabel} />,
            align: 'middle',
            testid: `wallet-${snakeCase(wallet?.description)}-availbility`,
          },
          {
            cellData: <Balance wallet={wallet} baseCurrency={baseCurrency} />,
            align: 'end',
            testid: `wallet-${snakeCase(wallet?.description)}-balance`,
          },
          {
            cellData: (
              <Submenu
                wallet={wallet}
                handleEditWalletName={handleEditWalletName}
                setShowWalletModal={setShowWalletModal}
                setWalletModalAction={setWalletModalAction}
                type={walletType as 'FIAT' | 'CRYPTO'}
                key={wallet?.id}
              />
            ),
            align: 'end',
            testid: `wallet-${snakeCase(wallet?.description)}-popover-menu`,
          },
        ],
      }
    })
  }

  const walletInfo = buildWalletTableInfo()

  if (hasErrors) {
    return (
      <StatusCard status="error" headline={t('requestErrors.pageErrorIntro')} borderless>
        <Description>
          {`${t('requestErrors.comeBackLater')} `}
          <TextAction isWindowReload text={t('requestErrors.refreshPage')} />.
        </Description>
      </StatusCard>
    )
  }

  return (
    <>
      <Box paddingY={20} flex>
        <FormRow width="full">
          <Search
            searchTerm={searchQuery}
            size="sm"
            testid="filter-search-field"
            placeholderText={t('walletsPage.searchPlaceholder')}
            onChange={event => onSearchTermChange(event.target.value)}
            {...(!!!walletInfo?.length && !!!searchQuery?.length && { disabled: true })}
          />
        </FormRow>
      </Box>

      <>
        {!!!walletInfo?.length && isWalletBeingSearched && !isFetching ? (
          <NoSearchResults clearSearchCriteria={clearFilters} />
        ) : isSuccess && !!!walletInfo?.length && !isFetching && !debouncedSearch ? (
          <StatusCard headline={t('wallets.emptyStateForWalletsHeadline')}>
            {walletType === WalletTypes.FIAT ? (
              <Text size="sm" color="dark-grey">
                {t('createFiatWalletDisclaimer')}
                <Link
                  to="https://help.bvnk.com/hc/en-us/articles/13995679766930"
                  className="text-primary-500 hover:text-primary-700 active:text-primary-900"
                  target="_blank"
                >
                  {` ${t('learnMore')}`}
                </Link>
              </Text>
            ) : (
              <Text size="sm">{t('wallets.emptyStateForWalletsDescription')}</Text>
            )}
          </StatusCard>
        ) : (
          <SimpleTableV2
            testid={testid}
            columns={[
              {
                headerTitle: t('walletName'),
                className: rowHeaderWidth,
              },
              {
                headerTitle: null,
                className: rowHeaderWidth,
              },
              {
                headerTitle: t('balance'),
                className: rowHeaderWidth,
                align: 'end',
                testid: `${testid || 'simple-table-v2'}-column-balance`,
              },
              {
                headerTitle: !isFetching ? (
                  t('totalBalance') +
                  ` ${formatTotal(
                    walletType === WalletTypes.FIAT ? totalFiat : totalCrypto,
                    baseCurrency
                  )}`
                ) : (
                  <>
                    {!hasErrors && (
                      <Box flex justifyContent="end" alignItems="center" height="full">
                        <Skeleton className="w-2/5" variant="text-xs" />
                      </Box>
                    )}
                  </>
                ),
                align: 'end',
                className: rowHeaderWidth,
                testid: `${testid || 'simple-table-v2'}-column-total-balance`,
              },
            ]}
            isLoading={isFetching || (debouncedSearch && !isSuccess)}
            tableData={walletInfo as TableRowV2[]}
            numberOfRowsLoading={10}
            emptyState
          />
        )}
      </>
    </>
  )
}

export default memo(WalletTable)
