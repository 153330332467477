import React, { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box as CenteredWrapper,
  Box as Form,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { QRCode } from '@node-space/storybook-components/dist/QRCode'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logError } from '@node-space/utils'
import TwoFAField, { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { PathNames } from 'constants/General'
import { useTwoFactorCodeMutation } from 'hooks/mutations/useTwoFactorCodeMutation'
import { useGetOtpUrl } from 'hooks/queries/useGetOtpUrlQuery'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import Description from './components/Description'
import PasswordField from './components/PasswordField'
import { twoFaSchema } from './schema'

const TwoFactorAuthMandatory = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setIsTwoFactorEnabled } = useTwoFAContext()

  const [enter2FaStep, setEnter2FaStep] = useState(false)
  const [twoFactorCode, setTwoFactorCode] = useState('')
  const [twoFactorCodeError, setTwoFactorCodeError] = useState('')
  const { isPending: isFetchingCodeResponse, mutate } = useTwoFactorCodeMutation()
  const {
    register,
    formState: { errors, isValid },
    control,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<{
    password: string
  }>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(twoFaSchema(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const formValues = useWatch({ control })

  const INVALID_CODE_RESPONSE = 412

  const { secret, otpUrl, isFetchingSecrect, isErrorGetSecrectCall, refetchSecrect } = useGetOtpUrl(
    formValues?.password
  )

  useEffect(() => {
    if (!isFetchingSecrect) {
      if (secret === null) {
        setError('password', { type: 'invalidPassword', message: t('incorrectPassword') })
      } else if (secret) {
        setEnter2FaStep(true)
      }

      if (isErrorGetSecrectCall) {
        setError('password', { type: 'genericError', message: t('oopsSomethingWentWrong') })
      }
    }
  }, [isFetchingSecrect, secret, isErrorGetSecrectCall])

  const onSubmit: SubmitHandler<{
    password: string
  }> = async () => {
    clearErrors()
    setTwoFactorCodeError('')

    if (!enter2FaStep) {
      await refetchSecrect()
    } else {
      mutate(twoFactorCode, {
        onSuccess: () => {
          setIsTwoFactorEnabled(true)
        },
        onError: (error: BaseErrorResponse) => {
          let errMsg = ''

          if (error?.status === INVALID_CODE_RESPONSE) {
            errMsg = t('invalidCode')
          } else {
            errMsg = t('oopsSomethingWentWrong')
            logError('Error from TwoFactorAuthMandatory - onSubmit', error)
          }
          setTwoFactorCodeError(errMsg)
        },
      })
    }
  }

  const handleTwoFactorCodeChange = useCallback(
    (value: string) => {
      setTwoFactorCode(value)

      if (twoFactorCodeError) {
        setTwoFactorCodeError('')
      }
    },
    [twoFactorCodeError, setTwoFactorCodeError]
  )

  const isSubmitCodeValid = twoFactorCode.length < TWO_FA_REQUIRED_LENGTH

  return (
    <Wrapper
      flex
      justifyContent="center"
      height="screen"
      className="from-blue-light bg-gradient-to-b to-white"
    >
      <CenteredWrapper flex direction="col" className="max-w-lg" centerChildren>
        <Text tag="h4" size="lg" align="center" weight="medium" className="mb-4 w-2/3">
          {t('twoFactorVerification.title')}
        </Text>

        {enter2FaStep && (
          <Wrapper
            flex
            justifyContent="center"
            background="white"
            borderRadius={8}
            padding={12}
            className="mb-3"
          >
            <QRCode value={otpUrl} />
          </Wrapper>
        )}
        <small className="mb-4">{secret}</small>
        <Description />

        {enter2FaStep && (
          <Text color="black" size="sm" align="center" weight="medium">
            {t('twoFactorVerification.enter2FaCode')}
          </Text>
        )}

        <Wrapper
          flex
          alignItems="stretch"
          direction="row"
          width="full"
          flexJustifyBetween
          className="mb-1"
        ></Wrapper>
        <Form tag="form" width="full" testid="2fa-form" onSubmit={handleSubmit(onSubmit)}>
          {!enter2FaStep && (
            <>
              <PasswordField
                error={errors?.password}
                register={register}
                isLoading={isFetchingSecrect}
              />
            </>
          )}

          {enter2FaStep && (
            <TwoFAField
              error={twoFactorCodeError}
              isLoading={isFetchingCodeResponse}
              onChange={handleTwoFactorCodeChange}
            />
          )}
          <Wrapper flex justifyContent="center" paddingT={24} width="full" gap={16}>
            <Button
              secondary
              disabled={isFetchingSecrect || isFetchingCodeResponse}
              onClick={() => navigate(PathNames.LOGOUT)}
            >
              {t('twoFactorVerification.logoutButton')}
            </Button>
            {!enter2FaStep && (
              <Button loading={isFetchingSecrect} disabled={!isValid} type="submit">
                {t('continue')}
              </Button>
            )}
            {enter2FaStep && (
              <Button type="submit" loading={isFetchingCodeResponse} disabled={isSubmitCodeValid}>
                {t('confirm')}
              </Button>
            )}
          </Wrapper>
        </Form>
      </CenteredWrapper>
    </Wrapper>
  )
}

export default TwoFactorAuthMandatory
