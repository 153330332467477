import { ProfileSettingType } from 'types/profileSettings'
import { useProfileContext } from './context/useProfileContext'

/**
 * Returns a boolean value indicating if the profile setting enables a feature
 * by checking if the profile setting queried is true
 * @param {ProfileSettingType} profileSettingName
 * @returns {boolean}
 */
export const useProfileSetting = (profileSettingName: ProfileSettingType): boolean => {
  const { profile } = useProfileContext()

  if (!profileSettingName || !profile?.settings) return false

  const hasProfileSetting = profile?.settings?.[profileSettingName]

  return hasProfileSetting
}
