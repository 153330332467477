import React, { createContext, useMemo, useState } from 'react'

export const WalletCreateModalContext = createContext(null)

export const WalletCreateModalProvider = ({ children, condition = false }) => {
  const [createWalletModal, setCreateWalletModal] = useState(condition)
  const value = useMemo(() => [createWalletModal, setCreateWalletModal], [createWalletModal])

  return (
    <WalletCreateModalContext.Provider value={value}>{children}</WalletCreateModalContext.Provider>
  )
}
