import { UseFormSetError } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import { BankAccount, BankAccountErrorMap } from 'types/bankAccounts/types'
import { BankAccountError } from 'types/BankAccountsDetails'
import { AddBankAcountFormPayload } from 'types/paymentMethod'
import { BankAccount as BankAccountDetails, CountryCodes, Currencies } from 'types/types'
import { cleanBase64String } from 'utils/utils'

export const getBankAccountDataFactory = (
  selectedCurrency: string,
  code: string,
  paymentMethodTypes: { name: string; id: string }[],
  formValues: AddBankAcountFormPayload
) => {
  const data = {
    currency: selectedCurrency,
    options: {},
    type: null,
    otp: code,
    accountDocument: {
      code: 'bankAccountDocument',
      file: {
        name: 'Proof of Account Ownership',
        base64: cleanBase64String(formValues?.proofOfOwnershipDocument?.buffer as string),
      },
    },
    description: formValues?.description,
  }

  switch (selectedCurrency) {
    case Currencies.EUR:
      data.type = paymentMethodTypes?.find(item => item?.name === 'bankTransferSepa')?.id
      data.options = {
        bic: formValues?.bic,
        iban: formValues?.iban,
        accountHolderName: formValues?.accountHolderName,
      }
      break
    case Currencies.GBP:
      data.type = paymentMethodTypes?.find(item => item?.name === 'bankTransferUk')?.id
      data.options = {
        ukAccountNumber: formValues?.accountNumber,
        ukSortCode: formValues?.sortCode,
        accountHolderName: formValues?.accountHolderName,
      }
      break
    case Currencies.ZAR:
      data.type = paymentMethodTypes?.find(item => item?.name === 'bankTransferNational')?.id
      data.options = {
        accountNumber: formValues?.zarAccountNumber,
        bankId: formValues?.zarBank,
        countryCode: CountryCodes.ZA,
        accountType: formValues?.zarAccountType,
        routingNumber: formValues?.zarRoutingNumberBranchCode,
        accountHolderName: formValues?.accountHolderName,
      }
      break
    case Currencies.NGN:
      data.type = paymentMethodTypes?.find(item => item?.name === 'bankTransferNigeria')?.id
      data.description = formValues?.description
      data.options = {
        accountNumber: formValues?.ngnAccountNumber,
        routingNumber: formValues?.bankCode,
        countryCode: CountryCodes.NG,
        accountHolderName: formValues?.accountHolderName,
      }
  }

  return data
}

export const getBankAccountErrorFactory = (
  error: BankAccountError,
  selectedCurrency: string,
  t: TFunction<'translation', undefined>
) => {
  const data = {
    accountHolderName: {
      status: false,
      message: '',
    },
    accountNumber: {
      status: false,
      message: '',
    },
    bankName: {
      status: false,
      message: '',
    },
    otp: {
      status: false,
      message: '',
    },
    generic: {
      status: false,
      message: '',
    },
  }
  error?.data?.errorList?.forEach(item => {
    switch (item.parameter) {
      case 'accountHolderName':
        data.accountHolderName = {
          status: true,
          message: item.message,
        }
        break
      case 'accountNumber':
        if (selectedCurrency === Currencies.ZAR) {
          data.accountNumber = {
            status: true,
            message: item.message,
          }
        } else {
          data.bankName = {
            status: true,
            message: item.message,
          }
        }
        break
      case 'otp':
        data.otp = {
          status: true,
          message: t('invalidCode'),
        }
        break
      default:
        data.generic = {
          status: true,
          message: t('oopsSomethingWentWrong'),
        }
    }
  })
  return data
}

export const getBankAccountTempErrorFactory = (
  selectedCurrency: string,
  formData: BankAccount,
  proofOfOwnershipFile: unknown,
  t: TFunction<'translation', undefined>
) => {
  const tempErrorList: BankAccountErrorMap = {
    accountHolderName: {
      status: false,
      message: t('accountHolderNameRequired'),
    },
    bankName: {
      status: false,
      message: t('bankRequired'),
    },
    bankId: {
      status: false,
      message: t('bankRequired'),
    },
    iban: {
      status: false,
      message: t('ibanNumberRequired'),
    },
    bic: {
      status: false,
      message: t('bicSwiftCodeRequired'),
    },
    sortCode: {
      status: false,
      message: t('sortCodeRequired'),
    },
    accountNumber: {
      status: false,
      message: t('accountNumberRequired'),
    },
    ukAccountNumber: {
      status: false,
      message: t('accountNumberRequired'),
    },
    accountType: {
      status: false,
      message: t('accountTypeRequired'),
    },
    routingNumberBranchCode: {
      status: false,
      message: t('routingNumberBranchCodeRequired'),
    },
    proofOfOwnershipFile: {
      status: false,
    },
  }

  switch (selectedCurrency) {
    case Currencies.EUR:
      tempErrorList.accountHolderName.status = formData.accountHolderName.length < 1
      tempErrorList.iban.status = formData.iban.length < 1
      tempErrorList.bic.status = formData.bic.length < 1
      tempErrorList.bankName.status = formData.bankName.length < 1
      break
    case Currencies.GBP:
      tempErrorList.accountHolderName.status = formData.accountHolderName.length < 1
      tempErrorList.sortCode.status = formData.sortCode.length < 1
      tempErrorList.ukAccountNumber.status = formData.ukAccountNumber.length < 1
      tempErrorList.bankName.status = formData.bankName.length < 1
      break
    case Currencies.ZAR:
      tempErrorList.accountHolderName.status = formData.accountHolderName.length < 1
      tempErrorList.bankName.status = formData.bankName.length < 1
      tempErrorList.accountNumber.status = formData.accountNumber.length < 1
      tempErrorList.accountType.status = formData.accountType.length < 1
      tempErrorList.bankId.status = formData.bankId.length < 1
      tempErrorList.routingNumberBranchCode.status = formData.routingNumberBranchCode.length < 1
      break
    case Currencies.NGN:
      tempErrorList.accountHolderName.status = formData.accountHolderName.length < 1
      tempErrorList.bankName.status = formData.bankName.length < 1
      tempErrorList.accountNumber.status = formData.accountNumber.length < 1
      tempErrorList.bankId.status = formData.bankId.length < 1
      break
  }
  tempErrorList.proofOfOwnershipFile.status = proofOfOwnershipFile === null

  return tempErrorList
}

export const getBankAccountValidationErrorFactory = (
  error: BankAccountError,
  selectedCurrency: string,
  t: TFunction<'translation', undefined>
) => {
  const validationErrorList = {}
  error?.data?.errorList?.forEach(item => {
    switch (item.parameter) {
      case 'iban':
        validationErrorList['iban'] = {
          status: true,
          message: t('settingsBankAccountInvalidIBAN'),
        }
        break
      case 'bic':
        validationErrorList['bic'] = {
          status: true,
          message: t('settingsBankAccountInvalidBIC'),
        }
        break
      case 'accountNumber':
        validationErrorList['accountNumber'] = {
          status: true,
          message: t('settingsBankAccountIncorrectAccountNumber'),
        }
        break
      case 'description':
        if (selectedCurrency === Currencies.ZAR) {
          validationErrorList['bankName'] = {
            status: true,
            message: t('settingsBankAccountDuplicateAccountNumber'),
          }
        } else {
          validationErrorList['bankName'] = {
            status: true,
            message: t('settingsBankAccountDuplicateBankName'),
          }
        }
        break
      default:
        validationErrorList['generic'] = {
          status: true,
          message: t('oopsSomethingWentWrong'),
        }
    }
  })

  return validationErrorList
}

export const setBankAccountErrors = (
  error: BankAccountError,
  selectedCurrency: string,
  t: TFunction<'translation', undefined>,
  setError: UseFormSetError<AddBankAcountFormPayload>
) => {
  error?.data?.errorList?.forEach(item => {
    switch (item.parameter) {
      case 'iban':
        setError('iban', { message: t('settingsBankAccountInvalidIBAN') })
        break
      case 'bic':
        setError('bic', { message: t('settingsBankAccountInvalidBIC') })
        break
      case 'accountNumber':
        setError('accountNumber', { message: t('settingsBankAccountIncorrectAccountNumber') })
        break
      case 'description':
        if (selectedCurrency === Currencies.ZAR) {
          setError('zarBank', { message: t('settingsBankAccountDuplicateAccountNumber') })
        } else {
          setError('bankCode', { message: t('settingsBankAccountDuplicateBankName') })
        }
        break
      default:
    }
  })
}

export const getFieldsToValidate = (currencyCode: Currencies) => {
  const validateFields: (keyof AddBankAcountFormPayload)[] = [
    'accountHolderName',
    'description',
    'proofOfOwnershipDocument',
    'currencyCode',
  ]

  switch (currencyCode) {
    case Currencies.EUR:
      validateFields.push('iban', 'bic')
      break
    case Currencies.GBP:
      validateFields.push('accountNumber', 'sortCode')
      break
    case Currencies.NGN:
      validateFields.push('ngnAccountNumber', 'bankCode')
      break
    case Currencies.ZAR:
      validateFields.push(
        'zarAccountNumber',
        'zarAccountType',
        'zarBank',
        'zarRoutingNumberBranchCode'
      )
      break
    default:
      break
  }
  return validateFields
}

export const getAccountNumber = (bankAccount: BankAccountDetails) => {
  switch (bankAccount?.currency) {
    case Currencies.EUR:
      return bankAccount?.options?.iban
    case Currencies.GBP:
      return bankAccount?.options?.ukAccountNumber
    case Currencies.USD:
    case Currencies.ZAR:
    case Currencies.NGN:
      return bankAccount?.options?.accountNumber
    default:
      return ''
  }
}

export const getBankCode = (bankAccount: BankAccountDetails) => {
  switch (bankAccount?.currency) {
    case Currencies.EUR:
      return bankAccount?.options?.bic
    case Currencies.GBP:
      return bankAccount?.options?.ukSortCode
    case Currencies.USD:
      return bankAccount?.options?.beneficiaryBank?.bic
    case Currencies.ZAR:
    case Currencies.NGN:
      return bankAccount?.options?.routingNumber
    default:
      return ''
  }
}
