import { useEffect, useState } from 'react'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import {
  ArrowTransactionInIcon,
  ArrowTransactionOutIcon,
  AttentionIcon,
  ConvertIcon,
} from '@node-space/storybook-components'
import { Transaction } from 'types/transaction'

export const useTitleTransaction = (transaction: Transaction) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('-')
  const [icon, setIcon] = useState<React.ReactNode>()

  useEffect(() => {
    if (!transaction) return

    let type: string = transaction.type
    if (transaction.context?.subType === 'trade' && transaction.type !== 'processingFee') {
      type = transaction.context.subType
    }

    switch (type) {
      case 'trade':
        const convertType = transaction.type === 'payOut' ? 'Out' : 'In'
        setTitle(
          t('convertAction', {
            action: convertType,
            currencyNameFrom: transaction.context?.fromCurrencyCode,
            currencyNameTo: transaction.context?.toCurrencyCode,
          })
        )
        setIcon(ConvertIcon)
        break
      case 'paymentIn':
        setTitle(startCase('paymentIn'))
        setIcon(ArrowTransactionInIcon)
        break
      case 'paymentOut':
        setTitle(startCase('paymentOut'))
        setIcon(ArrowTransactionOutIcon)
        break
      case 'paymentRefund':
        setTitle(startCase('paymentRefund'))
        setIcon(ArrowTransactionOutIcon)
        break
      case 'overPayment':
        setTitle(startCase('overPayment'))
        setIcon(ArrowTransactionInIcon)
        break
      case 'underPayment':
        setTitle(startCase('underPayment'))
        setIcon(ArrowTransactionInIcon)
        break
      case 'reversal':
        setTitle(startCase('reversal'))
        setIcon(ArrowTransactionInIcon)
        break
      case 'processingFee':
        setTitle(startCase('processingFee'))
        setIcon(ArrowTransactionOutIcon)
        break
      case 'networkFee':
        setTitle(startCase('networkFee'))
        setIcon(ArrowTransactionOutIcon)
        break
      case 'bvnkNetworkTransfer':
        setTitle(startCase('bvnkNetworkTransfer').replace('Bvnk', 'BVNK'))
        setIcon(ArrowTransactionOutIcon)
        break
      default:
        const { paymentType, icon } = formatFallbackType(type)
        setTitle(paymentType)
        setIcon(icon)
    }
  }, [transaction])
  return { title, icon }
}

const formatFallbackType = (type: string) => {
  switch (type) {
    case 'payIn':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'payOut':
      return { paymentType: startCase(type), icon: ArrowTransactionOutIcon }
    case 'underPaid':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'overPaid':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'userDeposit':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'userWithdrawal':
      return { paymentType: startCase(type), icon: ArrowTransactionOutIcon }
    case 'paymentInFees':
      return { paymentType: startCase(type), icon: ArrowTransactionOutIcon }
    case 'paymentOutFees':
      return { paymentType: startCase(type), icon: ArrowTransactionOutIcon }
    case 'paymentUnder':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'paymentOver':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'channelDepositFee':
      return { paymentType: startCase(type), icon: ArrowTransactionOutIcon }
    case 'channelDeposit':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }
    case 'latePayment':
      return { paymentType: startCase(type), icon: ArrowTransactionInIcon }

    default:
      return { paymentType: startCase(type), icon: AttentionIcon }
  }
}
