import { TableSectionItem } from 'pages/Wallets/types'
import { WalletType } from 'types/types'
import { WalletStatus } from 'types/wallets'

const useActiveWallets = (wallets: WalletType[] | TableSectionItem[]) => {
  return wallets?.filter(wallet => {
    if (!wallet?.status) {
      return true
    }

    return useIsActiveWallet(wallet)
  })
}

const useIsActiveWallet = (wallet: WalletType | TableSectionItem) => {
  if (!wallet?.status) {
    return true
  }

  return (
    wallet?.status !== WalletStatus.INACTIVE &&
    wallet?.status !== WalletStatus.SUSPENDED_BY_ACCOUNT_SUSPENSION
  )
}

export { useActiveWallets, useIsActiveWallet }
