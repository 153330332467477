import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader, SmallTabs } from '@node-space/storybook-components'
import { PathNames } from 'constants/General'
import { WalletContextProvider } from 'contexts/walletContext'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useWalletQuery } from 'hooks/queries'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useFiatWalletActionSupport } from 'hooks/useFiatWalletActionSupport'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { ExtendedDetails } from './components/ExtendedDetails/ExtendedDetails'
import { RecentActivity } from './components/RecentActivity/RecentActivity'
import { WalletDetailsHeader } from './components/WalletDetailsHeader/WalletDetailsHeader'
import { WalletNotFound } from './components/WalletNotFound/WalletNotFound'

type TabQuery = 'recent-activity' | 'extended-details' | string

export const WalletDetails = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentAccount } = useAccountsContext()
  const { id }: { id?: string } = useParams()
  const navigate = useNavigate()
  const { tab } = queryString.parse(window.location.search) as { tab?: TabQuery }
  const { data: wallet, isPending, isError } = useWalletQuery(Number(id))
  const permissions = useMappedRoles()?.wallets
  const isSelectedWalletActive = useIsActiveWallet(wallet)

  const canEdit = permissions?.canEdit
  const canTrade = permissions?.canTrade
  const isFiatWallet = wallet?.currency?.fiat

  const walletProperties = {
    currency: wallet?.currency?.code,
    supportsWithdrawals: wallet?.supportsWithdrawals,
    supportsThirdParty: wallet?.supportsThirdParty,
    supportsDeposits: wallet?.supportsDeposits,
    partner: wallet?.partner,
  }
  const { supportsDeposits } = useFiatWalletActionSupport(walletProperties)

  useEffect(() => {
    if (wallet?.supportsInternalBvnkNetworkTransfers && !!currentAccount?.reference) {
      queryClient.invalidateQueries({
        queryKey: [
          'bvnkNetworkConnections',
          {
            accountReference: currentAccount?.reference,
          },
        ],
      })
    }
  }, [wallet?.supportsInternalBvnkNetworkTransfers, currentAccount?.reference])

  if (isError) {
    return <WalletNotFound />
  }
  if (isPending) {
    return (
      <div className="flex justify-center">
        <Loader theme="dark" size="large" />
      </div>
    )
  }

  const isExtendedWalletDetailsAvailable =
    (isFiatWallet && supportsDeposits) || (!isFiatWallet && !wallet?.supportsThirdParty)

  const tabs = [
    {
      id: 'recent-activity',
      tabHeader: t('wallets.transactionHistory'),
      tabContent: <RecentActivity />,
      onClick: () => {
        navigate(`${PathNames.WALLETS}/wallet-details/${id}?tab=recent-activity`, {
          replace: true,
        })
      },
      testid: 'wallet-details-transaction-history-tab',
    },
  ]

  if (isExtendedWalletDetailsAvailable && isSelectedWalletActive) {
    const walletDetailsTab = {
      id: 'extended-details',
      tabHeader: t('walletDetails'),
      tabContent: <ExtendedDetails />,
      onClick: () => {
        navigate(`${PathNames.WALLETS}/wallet-details/${id}?tab=extended-details`, {
          replace: true,
        })
      },
      testid: 'wallet-details-extended-details-tab',
    }
    tabs.push(walletDetailsTab)
  }

  return (
    <div>
      <WalletContextProvider wallet={wallet}>
        {(canEdit || canTrade) && <WalletDetailsHeader />}
        {isExtendedWalletDetailsAvailable ? (
          <SmallTabs
            tabs={tabs}
            currentValueId={tab === 'extended-details' ? tab : 'recent-activity'}
          />
        ) : (
          <RecentActivity />
        )}
      </WalletContextProvider>
    </div>
  )
}
