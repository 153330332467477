import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { patchPreprocessedRecords } from 'services/MasspayoutService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useUpdatePreProcessedRecordMutation = () => {
  return useMutation({
    mutationFn: patchPreprocessedRecords,
    onError: (error: BaseErrorResponse, data) => {
      logSentryError('Error: useUpdateNetworkConnectionMutation', error, data)
    },
  })
}
