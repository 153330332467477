import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { DepositForm, DepositSteps } from '../components/Deposit/Deposit'

const depositSchema = ({
  t,
  step,
}: {
  t: TFunction<'translation', undefined>
  step: DepositSteps
}) => {
  return yup.object<Partial<Record<keyof DepositForm, yup.AnySchema>>>({})
}

export default depositSchema
