import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Section } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { SidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { SmallTabs } from '@node-space/storybook-components/dist/SmallTabs'
import { appBannersId } from 'components/app/AppBanners'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { Transaction } from 'types/transaction'
import { TransactionType } from 'types/transaction/TransactionType'
import { highlightRowBasedOnId } from 'utils/table/table'
import { getSupportedPaymentTypes } from 'utils/utils'
import { useTransactionDetails } from '../hooks/useTransactionDetails'
import BvnkNetworkActivityTransactionActivity from './PaymentTemplates/BvnkNetworkActivityTransactionActivity'
import { SidePanelBody } from './SidePanelBody'
import { SidePanelHeaderWallet } from './SidePanelHeaderWallet'

interface SidePanelSummaryProps {
  isOpen: boolean
  paging: PagingSidePanelType
  transactionDetails: Transaction
  onClose: () => void
}
export const SidePanelSummary = ({
  isOpen,
  paging,
  transactionDetails,
  onClose,
}: SidePanelSummaryProps) => {
  const { t } = useTranslation()

  const {
    amountWithCurrency,
    iconUrls,
    transactionType,
    transactionSubType,
    transactionReference,
    currencyFrom,
    currencyTo,
    smallTabsId,
    status,
    hasProofOfPayment,
    externalReference,
    bvnkNetworkBeneficiary,
    bvnkNetworkSender,
  } = useTransactionDetails(transactionDetails)
  const tabs = {
    OVERVIEW: `${smallTabsId}-overview`,
    ACTIVITY: `${smallTabsId}-activity`,
  } as const

  const isBvnkNetworkTransfer = transactionType === TransactionType.BVNK_NETWORK_TRANSFER
  const bannerSpacing = useComponentHeight(appBannersId)

  const [activeTab, setActiveTab] = useState<string>(tabs.OVERVIEW)

  useEffect(() => {
    setActiveTab(tabs.OVERVIEW)
  }, [smallTabsId])

  const supportedPaymentTypes = getSupportedPaymentTypes()

  return (
    <SidePanel
      isOpen={isOpen}
      paging={paging}
      onClose={onClose}
      activeRowId={transactionDetails?.hash}
      onActiveRowChange={highlightRowBasedOnId}
      sidePanelHeader={{
        sidePanelHeaderTemplate: (
          <SidePanelHeaderWallet
            data={{
              iconUrls,
              amountWithCurrency,
              transactionReference,
              transactionType,
              transactionSubType,
              currencyFrom,
              currencyTo,
              status,
              hasProofOfPayment,
              externalReference,
              bvnkNetworkBeneficiary,
              bvnkNetworkSender,
            }}
            supportedPaymentTypes={supportedPaymentTypes}
          />
        ),
      }}
      externalStyle={{ top: `${bannerSpacing}px` }}
    >
      <Section tag="section" full background="white">
        <SmallTabs
          currentValueId={activeTab}
          tabs={[
            {
              id: tabs.OVERVIEW,
              tabHeader: t('overview'),
              tabContent: (
                <SidePanelBody
                  transactionDetails={transactionDetails}
                  supportedPaymentTypes={supportedPaymentTypes}
                />
              ),
              testid: 'sidepanel-overview-tab',
              onClick: () => {
                setActiveTab(tabs.OVERVIEW)
              },
            },
            ...[
              isBvnkNetworkTransfer &&
                !!transactionDetails?.context?.transactions?.length && {
                  id: tabs.ACTIVITY,
                  tabHeader: t('transactions.sideBar.activity'),
                  tabContent: (
                    <BvnkNetworkActivityTransactionActivity
                      transactionActivities={transactionDetails?.context?.transactions}
                    />
                  ),
                  testid: 'sidepanel-activity-tab',
                  onClick: () => {
                    setActiveTab(tabs.ACTIVITY)
                  },
                },
            ],
          ]}
        />
      </Section>
    </SidePanel>
  )
}
