import { WalletType } from 'types/types'

export const getWalletProtocolNetwork = (wallet: WalletType, currentProtocol: { code: string }) => {
  let selectedProtocolNetwork: string
  const walletCurrencyProtocols = wallet?.currency?.protocols
  if (walletCurrencyProtocols?.length > 1) {
    const currentCode = currentProtocol?.code
    const highlightedProtocolNetwork = walletCurrencyProtocols?.filter(
      x => x?.code === currentCode
    )?.[0]?.network
    selectedProtocolNetwork = highlightedProtocolNetwork ?? null
  }
  if (walletCurrencyProtocols?.length === 1) {
    selectedProtocolNetwork = wallet?.currency?.protocols?.[0]?.network ?? null
  }

  return { selectedProtocolNetwork }
}
