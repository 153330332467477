import { requestApi } from 'api/api'
import { RestApi } from '@node-space/rest-api'
import General from 'constants/General'
import { AccountType } from 'types/accounts'
import { MerchantRes } from 'types/authentication/MerchantRes'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { Profile, ProfileGroupRoles } from 'types/authentication/Profile'
import { AuthToken } from 'types/types'
import { LoginUserResponse } from 'types/users'
import { isTestEnvironment, setAuthCookies } from 'utils/utils'

export const COOKIE_DOMAIN = '.coindirect.com'

export const postLogin = async (postData: PostLoginReq): Promise<LoginUserResponse> => {
  const response = await requestApi({
    url: '/login',
    method: 'POST',
    authenticated: false,
    data: { ...postData },
  })

  if (!isTestEnvironment) {
    setAuthCookies(response as AuthToken)
  }
  return response
}

export const getAccounts = (): Promise<AccountType[]> => {
  return requestApi({
    url: '/account/user-accounts',
    method: 'GET',
  })
}

export const switchAccount = async (accountId: number): Promise<LoginUserResponse> => {
  const data: LoginUserResponse = await requestApi({
    url: `/account/switch/${accountId}`,
    method: 'GET',
  })
  setAuthCookies({ ...data } as AuthToken)

  return data
}

export const getProfile = async (): Promise<Profile> => {
  const data: Profile = await requestApi({
    url: '/user/profile',
    method: 'GET',
  })
  const merchant = RestApi.getCookie(General.COOKIE_NAME) as unknown as MerchantRes
  const roles = merchant?.roles || []
  return {
    loggedIn: true,
    viewOnly: data?.roleGroups?.includes(ProfileGroupRoles.VIEWER),
    ...data,
    roles,
  }
}

export const getReissueToken = (): Promise<AuthToken> => {
  return requestApi({
    url: '/login/reissue',
    method: 'POST',
  })
}

export const getReissueTokenParams = ({
  access_token,
  secret,
}: {
  access_token?: string
  secret?: string
} = {}): Promise<AuthToken> => {
  return requestApi({
    url: '/login/reissue',
    method: 'POST',
    access_token,
    secret,
  })
}
