import { getSupportedPaymentTypes } from 'utils/utils'

export const useTransactionIcons = (transactionsTypeSubType, iconUrls) => {
  const supportedPaymentTypes = getSupportedPaymentTypes()
  const isConvertIn = transactionsTypeSubType === supportedPaymentTypes.CONVERT_IN
  const isConvertOut = transactionsTypeSubType === supportedPaymentTypes.CONVERT_OUT
  const isChannelDeposit = transactionsTypeSubType === supportedPaymentTypes.CHANNEL_DEPOSIT
  const isChannelDepositTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.CHANNEL_DEPOSIT_TRANSACTION_HISTORY
  const isChannelDepositFee = transactionsTypeSubType === supportedPaymentTypes.CHANNEL_DEPOSIT_FEE
  const isProcessingFeeUnderPayment =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_PROCESSING_FEE_UNDERPAYMENT
  const isPaymentIn = transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN
  const isPaymentInTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN_TRANSACTION_HISTORY
  const isProcessingFeePayment =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_PROCESSING_FEE
  const isProcessingFeePaymentOverPayment =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_PROCESSING_FEE_OVERPAYMENT
  const isUnderPayment = transactionsTypeSubType === supportedPaymentTypes.UNDER_PAYMENT
  const isUnderPaymentTransaction =
    transactionsTypeSubType === supportedPaymentTypes.UNDER_PAYMENT_TRANASACTION_HISTORY
  const isOverPaymentInOver = transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN_OVER
  const isOverPaymentInOverTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN_OVER_TRANSACTION_HISTORY
  const isOverPaymentOverPayment =
    transactionsTypeSubType === supportedPaymentTypes.OVERPAYMENT_IN_OVER
  const isOverPaymentOverPaymentTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.OVERPAYMENT_IN_OVER_TRANSACTION_HISTORY
  const isPaymentOut = transactionsTypeSubType === supportedPaymentTypes.PAYMENT_OUT
  const isPaymentOutTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_OUT_TRANSACTION_HISTORY
  const isPaymentOutProcessingFee =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_PROCESSING_FEE_PAYMENT_OUT
  const isPaymentOutProcessingFeeTransationHistory =
    transactionsTypeSubType ===
    supportedPaymentTypes.PAYMENT_PROCESSING_FEE_PAYMENT_OUT_TRANSACTION_HISTORY
  const isPaymentInUnder = transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN_UNDER
  const isPaymentInUnderTransactionHistory =
    transactionsTypeSubType === supportedPaymentTypes.PAYMENT_IN_UNDER_TRANSACTION_HISTORY

  const singleIcon =
    isPaymentOut || isPaymentOutTransactionHistory
      ? [iconUrls?.[0]]
      : isChannelDeposit ||
        isChannelDepositTransactionHistory ||
        isChannelDepositFee ||
        isPaymentIn ||
        isPaymentInTransactionHistory ||
        isOverPaymentInOver ||
        isOverPaymentInOverTransactionHistory ||
        isOverPaymentOverPayment ||
        isOverPaymentOverPaymentTransactionHistory ||
        isProcessingFeeUnderPayment ||
        isUnderPayment ||
        isUnderPaymentTransaction ||
        isProcessingFeePayment ||
        isPaymentOutProcessingFeeTransationHistory ||
        isProcessingFeePaymentOverPayment ||
        isPaymentInUnder ||
        isPaymentInUnderTransactionHistory
      ? [iconUrls?.[1]]
      : [iconUrls?.[0]]

  const isMultipleIcons = isConvertIn || isConvertOut

  return { singleIcon, isMultipleIcons }
}
