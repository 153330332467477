import React from 'react'
import { CopyButton } from '@node-space/storybook-components'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'

interface BankAccountDetailsRowProps {
  label: string
  value: string
}

export const BankAccountDetailsRow = ({ label, value }: BankAccountDetailsRowProps) => {
  const { t } = useTranslation()
  return (
    <div className="pb-4">
      <p className="font-medium text-sm">{label}</p>
      <div className="flex justify-between items-center">
        <div className="text-sm">{value}</div>
        <CopyButton
          copyText={value}
          confirmationMessage={t('copiedToClipboard')}
          iconColour={copyButtonIconColour}
        />
      </div>
    </div>
  )
}
