import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { SwapIcon } from '@node-space/storybook-components/dist/Icons'
import { Input } from '@node-space/storybook-components/dist/Input'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { logSentryError } from '@node-space/utils'
import { usePostConvertQuoteMutation } from 'hooks/mutations/usePostConvertQuoteMutation'
import { usePostQuoteEstimateMutation } from 'hooks/mutations/usePostQuoteEstimateMutation'
import { useValidateReferenceWithdrawalMutation } from 'hooks/mutations/useValidateReferenceWithdrawalMutation'
import { BaseErrorResponse } from 'types/beneficiaries'
import { Quote } from 'types/payments'
import { postConvert } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { floorWithPrecision, formatString, isTestEnvironment, stringToDecimal } from 'utils/utils'
import { ConvertActionProps, ConvertFormType } from './Convert'
import { useConvertWallets } from './hooks/useConvertWallets'

const ConvertForm = ({
  dismissAction,
  setStep,
  form,
  setQuote,
  convertCurrency,
  shouldUseMax,
  shouldUseMin,
  setShouldUseMax,
  setShouldUseMin,
  setConvertCurrency,
  registerFormField,
  wallets,
  isFetchingWallets,
}: ConvertActionProps) => {
  const { t } = useTranslation()
  const formValues = form.watch()
  const [quoteEstimate, setQuoteEstimate] = useState<Quote>()

  const {
    withdrawalLimits,
    fromWallet,
    toWallet,
    fromWalletCurrencyCode,
    toWalletCurrencyCode,
    fromWalletOptions,
    toWalletOptions,
    adjustedMin,
    adjustedMax,
    adjustedMaxAmount,
    isFetchingLimits,
    isErrorLimits,
    isSameCurrencyConvertion,
    otherConvertCurrency,
    limitMinimum,
    toCurrencyPrecision,
    exchangeRate,
  } = useConvertWallets(formValues, convertCurrency, quoteEstimate, wallets)

  const {
    mutate: convertQuote,
    isPending: isConvertingQuote,
    isError: isErrorPostingConvertQuote,
    reset: resetPostConvertMutate,
  } = usePostConvertQuoteMutation()
  const {
    mutate: postQuoteEstimate,
    isPending: isPostingQuoteEstimate,
    isError: isErrorPostingQuoteEstimate,
    reset: resetQuoteEstimateMutate,
  } = usePostQuoteEstimateMutation()

  const isLoading = isFetchingWallets || isFetchingLimits
  const { mutate: mutateReference, isPending: referenceIsValidating } =
    useValidateReferenceWithdrawalMutation()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CONVERT_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  /**
   * Re-validate amount on wallet currency change
   */
  useEffect(() => {
    if (fromWalletCurrencyCode && toWalletCurrencyCode && formValues?.amount && quoteEstimate) {
      validateAmount(formValues?.amount, true)
    }
  }, [
    formValues?.walletId,
    formValues?.toWalletId,
    fromWalletCurrencyCode,
    toWalletCurrencyCode,
    formValues?.amount,
    quoteEstimate,
  ])

  /**
   * Re-validate amount on convert currency change
   */
  useEffect(() => {
    convertCurrency && formValues?.amount && validateAmount(formValues?.amount, true)
  }, [convertCurrency])

  useEffect(() => {
    setConvertCurrency(toWallet ? toWallet?.currency?.code : formValues?.toWalletId)
  }, [formValues?.toWalletId])

  useEffect(() => {
    if (isErrorPostingQuoteEstimate) {
      resetQuoteEstimateMutate()
    }
    if (isErrorPostingConvertQuote) {
      resetPostConvertMutate()
    }
    fetchQuoteExchangeRate()
  }, [fromWallet?.id, toWallet?.id, form.formState?.errors])

  const isNotEnoughFundsError =
    !isFetchingLimits && limitMinimum > fromWallet?.balance && !isErrorLimits

  const isExchangeRateError =
    !isPostingQuoteEstimate &&
    isErrorPostingQuoteEstimate &&
    !isErrorLimits &&
    !isNotEnoughFundsError

  const showInputField =
    !isLoading &&
    !isPostingQuoteEstimate &&
    fromWallet &&
    toWallet &&
    quoteEstimate &&
    !isErrorLimits &&
    !isNotEnoughFundsError

  const isDisabledRequestQuote =
    isConvertingQuote ||
    isPostingQuoteEstimate ||
    isFetchingLimits ||
    isErrorLimits ||
    referenceIsValidating ||
    !fromWallet?.id ||
    !toWallet?.id ||
    !formValues?.amount ||
    !!Object.keys(form.formState?.errors || {}).length

  // modal buttons
  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      secondary: true,
      onClick: () => dismissAction(),
    },
    {
      children: t('requestAQuote'),
      disabled: isDisabledRequestQuote,
      testid: t('requestAQuote'),
      onClick: () => getQuote(),
      ...((isConvertingQuote || isLoading || isPostingQuoteEstimate || referenceIsValidating) && {
        iconElement: <Loader />,
      }),
    },
  ]

  const amountErrorMinimum =
    withdrawalLimits &&
    fromWallet &&
    formatString(t('amountErrorMinimum'), adjustedMin, convertCurrency)

  const amountErrorMaximumFees =
    adjustedMaxAmount &&
    fromWallet &&
    formatString(t('amountErrorMaximumFeesConvert'), adjustedMaxAmount, convertCurrency)

  const amountErrorMaximum =
    withdrawalLimits &&
    fromWallet &&
    formatString(t('amountErrorMaximum'), adjustedMax, convertCurrency)

  const checkMinAmount = (amount: number | string, onBlur = false) => {
    if (adjustedMin && amount < adjustedMin && onBlur) {
      return amountErrorMinimum
    }
  }

  const checkMaxAmount = (amount: number | string) => {
    if (amount > adjustedMaxAmount) {
      return amountErrorMaximumFees
    }
  }

  const validateAmount = (amount: number | string, onBlur = isTestEnvironment) => {
    if (isNaN(Number(amount))) {
      form.setError('amount', { message: t('amountErrorIncorrectFormat') })
      return
    }

    let error = checkMinAmount(amount, onBlur)
    if (!error) {
      error = checkMaxAmount(amount)
    }

    if (error) {
      form.setError('amount', { message: error })
    } else {
      form.clearErrors('amount')
    }
  }
  /**
   * Fetch new quote on from/to wallet change
   */
  const fetchQuoteExchangeRate = () => {
    if (formValues.walletId && formValues.toWalletId) {
      const sameCurrency =
        quoteEstimate?.from === fromWalletCurrencyCode && quoteEstimate?.to === toWalletCurrencyCode
      if (fromWalletCurrencyCode && toWalletCurrencyCode && !sameCurrency) {
        postQuoteEstimate(
          { from: fromWalletCurrencyCode, to: toWalletCurrencyCode },
          {
            onSuccess: quoteEstimate => {
              const formState = form?.getValues()
              // only update state if latest selected wallet matches request
              if (formState?.walletId === fromWallet?.id?.toString()) {
                setQuoteEstimate(quoteEstimate)
              }
            },
            onError: (err: BaseErrorResponse) => {
              setQuoteEstimate(null)
              logSentryError('Error from Convert - fetchQuoteExchangeRate', err, {
                fromCurrency: fromWalletCurrencyCode,
                toCurrency: toWalletCurrencyCode,
              })
            },
          }
        )
      }
    }
  }

  // action to set amount to a valid value based on validation errors
  const errorAction =
    !!form.formState?.errors?.amount?.message &&
    (form.formState.errors.amount.message === amountErrorMinimum
      ? () => {
          setAmount(adjustedMin)
          setShouldUseMax(false)
          setShouldUseMin(true)
        }
      : form.formState.errors.amount.message === amountErrorMaximumFees
        ? () => {
            setAmount(adjustedMaxAmount)
            setShouldUseMax(true)
            setShouldUseMin(false)
          }
        : form.formState.errors.amount.message === amountErrorMaximum &&
          (() => {
            setAmount(adjustedMax)
            setShouldUseMax(true)
            setShouldUseMin(false)
          }))

  const setAmount = (amount: number | string) => {
    form.setValue('amount', amount)
    form.clearErrors('amount')
  }

  // fetch quote from api
  const getQuote = () => {
    const data: postConvert = {
      from: fromWallet?.currency?.code,
      to: toWalletCurrencyCode,
      fromWallet: fromWallet?.id,
      useMaximum: shouldUseMax,
      useMinimum: shouldUseMin,
      ...(formValues?.reference && { reference: formValues?.reference }),
    }
    if (toWallet) {
      data.toWallet = toWallet?.id
    }

    if (isSameCurrencyConvertion) {
      data.amountIn = formValues?.amount?.toString()
    } else {
      data.amountOut = formValues?.amount?.toString()
    }

    convertQuote(data, {
      onSuccess: response => {
        setQuote(response)
        setStep('confirm')
      },
      onError: (err: BaseErrorResponse) => {
        if (err.data.errorList) {
          err.data.errorList.forEach(error => {
            form.setError(error.parameter as keyof ConvertFormType, { message: error.message })
          })
        }
        logSentryError('Error from ConvertForm - postConvertQuote - Error fetching quote', err)
      },
    })
  }

  const handleReference = ({ target }) => {
    form.setValue('reference', target?.value)
    form.clearErrors('reference')

    mutateReference(
      {
        reference: target?.value,
      },
      {
        onSuccess: () => {
          form.clearErrors('reference')
        },
        onError: (error: BaseErrorResponse) => {
          if (error?.status !== 412) {
            logSentryError('Convert form reference validation error', error?.errorList)
            form.setError('reference', {
              message: t('referenceFailed'),
            })
          } else {
            form.setError('reference', {
              message: error?.errorList[0]?.message,
            })
          }
        },
      }
    )
  }

  return (
    <div data-testid="convertForm">
      <ModalNavBar title={t('convert')} onClose={dismissAction} />
      <ModalScrollable>
        <div className="p-6 pt-5 pb-7">
          {/* from wallet */}
          <div className="pb-6">
            <DoubleLabelSelect
              iconsUrl={process.env.ICONS_URL}
              label={t('from')}
              menuMaxHeight={400}
              showOverflow
              name={t('from')}
              disabled={!fromWalletOptions?.length}
              placeholder={
                !isFetchingWallets && wallets?.length === 0
                  ? t('noWallets')
                  : t('searchOrSelectAWallet')
              }
              options={fromWalletOptions}
              value={formValues?.walletId}
              skeletonLoading={isFetchingWallets}
              isSearchable={true}
              iconLeft={!formValues?.walletId && <Icon name="SearchIcon" />}
              onChange={(value: string) => {
                form.reset({ walletId: value }, { keepDefaultValues: true })
              }}
              displayTooltipForLongLabel
            />
          </div>

          {/* swap wallet toggle */}
          {toWallet && (
            <div
              className="flex flex-1 justify-center -mt-2 -mb-3 z-10 relative cursor-pointer"
              onClick={() => {
                const walletId = formValues?.walletId?.toString()
                const toWalletId = formValues?.toWalletId?.toString()
                form.reset(
                  { walletId: toWalletId, toWalletId: walletId, amount: null },
                  { keepDefaultValues: true }
                )
              }}
            >
              <SwapIcon />
            </div>
          )}

          {/* to wallet */}
          <div className="pb-6">
            <DoubleLabelSelect
              iconsUrl={process.env.ICONS_URL}
              label={t('to')}
              menuMaxHeight={300}
              showOverflow
              name={t('to')}
              placeholder={
                !isFetchingWallets && !wallets?.length ? t('noWallets') : t('searchOrSelectAWallet')
              }
              options={toWalletOptions}
              value={formValues.toWalletId}
              skeletonLoading={isFetchingWallets}
              isSearchable={true}
              iconLeft={!formValues?.toWalletId && <Icon name="SearchIcon" />}
              onChange={(value: string) => {
                form.setValue('toWalletId', value)
              }}
              displayTooltipForLongLabel
            />
          </div>

          {isNotEnoughFundsError && (
            <Callout
              state="error"
              message={t('convertNotEnoughFunds', {
                currencyCode: fromWalletCurrencyCode,
              })}
            />
          )}

          {isExchangeRateError && (
            <Callout
              message={formatString(
                t('quoteExchangeRateError'),
                fromWalletCurrencyCode,
                toWalletCurrencyCode
              )}
              state="error"
            />
          )}

          {!isFetchingLimits && isErrorLimits && (
            <Callout state="error" message={t('limitsError')} />
          )}

          {showInputField && (
            <Box flex direction="col" gapY={24}>
              <Input
                {...registerFormField('amount')}
                label={t('amount')}
                testid={t('amount')}
                postLabel={convertCurrency}
                rightLabel={t('useMax')}
                rightLabelClass="text-primary font-medium"
                rightLabelRender={
                  fromWalletCurrencyCode !== toWalletCurrencyCode && (
                    <div className="flex flex-row">
                      {
                        <div
                          className={clsx(
                            'cursor-pointer',
                            convertCurrency === fromWalletCurrencyCode && 'font-semibold'
                          )}
                          onClick={() => setConvertCurrency(fromWalletCurrencyCode)}
                        >
                          {fromWalletCurrencyCode}
                        </div>
                      }
                      <div className="mx-2">/</div>
                      {
                        <div
                          className={clsx(
                            'cursor-pointer',
                            convertCurrency === (toWalletCurrencyCode || formValues?.toWalletId) &&
                              'font-semibold'
                          )}
                          onClick={() =>
                            setConvertCurrency(toWalletCurrencyCode || formValues?.toWalletId)
                          }
                        >
                          {toWalletCurrencyCode || formValues?.toWalletId}
                        </div>
                      }
                    </div>
                  )
                }
                onRightLabelClick={() => {
                  form.setValue('amount', adjustedMaxAmount)
                  setShouldUseMin(false)
                  setShouldUseMax(true)
                }}
                bottomLeftLabel={
                  adjustedMin &&
                  formatString(
                    t('minimumAmount'),
                    floorWithPrecision(adjustedMin, toCurrencyPrecision),
                    convertCurrency
                  )
                }
                onBottomLeftLabelClick={() => {
                  form.setValue('amount', adjustedMin)
                  setShouldUseMax(false)
                  setShouldUseMin(true)
                }}
                bottomRightLabel={
                  formValues.amount &&
                  fromWalletCurrencyCode !== toWalletCurrencyCode &&
                  `~ ${
                    isSameCurrencyConvertion
                      ? floorWithPrecision(
                          Number(formValues.amount) * exchangeRate,
                          toWallet.currency.pricePrecision
                        )
                      : floorWithPrecision(
                          Number(formValues.amount) / exchangeRate,
                          fromWallet.currency.pricePrecision
                        )
                  } ${otherConvertCurrency}`
                }
                onChange={e => {
                  form.setValue('amount', stringToDecimal(e.target.value))
                  setShouldUseMin(false)
                  setShouldUseMax(false)
                }}
                inputMode="decimal"
                onErrorClick={errorAction ? () => errorAction() : null}
              />

              <Input
                {...registerFormField('reference')}
                testid={t('reference')}
                label={`${t('paymentReference')} (${t('optional')})`}
                error={form.getFieldState('reference', form.formState)?.invalid}
                errorText={form.getFieldState('reference', form.formState)?.error?.message}
                value={formValues?.reference}
                onBlur={handleReference}
                disabled={referenceIsValidating}
              />

              {!isConvertingQuote && isErrorPostingConvertQuote && (
                <Callout
                  state="error"
                  message={formatString(
                    t('quoteError'),
                    fromWalletCurrencyCode,
                    toWalletCurrencyCode
                  )}
                />
              )}
            </Box>
          )}
        </div>
      </ModalScrollable>

      {/* actions */}
      <ModalActions actions={actions} />
    </div>
  )
}

export default ConvertForm
