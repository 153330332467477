import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getReportScheduleTimezones } from 'services/TransactionExportService'

export const useReportScheduleTimezonesQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: reactQueryKeys.reportScheduleTimezones(),
    queryFn: getReportScheduleTimezones,
    enabled,
  })
}
