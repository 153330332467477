import { requestApi } from 'api/api'
import {
  AddNetworkConnection,
  BvnkNetworkConnectionsResponse,
  ConnectionsRequest,
  NetworkMember,
  UpdateNetworkConnection,
} from 'types/bvnkNetwork'

export const getConnections = async ({
  accountReference,
  connectionStatus,
}: ConnectionsRequest): Promise<BvnkNetworkConnectionsResponse> => {
  return requestApi({
    url: `/v1/connections/${accountReference}`,
    method: 'GET',
    params: {
      ...(connectionStatus && { 'connection-type': connectionStatus }),
    },
  })
}

export const getNetworkMembers = async (accountReference: string): Promise<NetworkMember[]> => {
  return requestApi({
    url: `/v1/connections/${accountReference}/discover`,
    method: 'GET',
  })
}

export const addNetworkConnection = async ({
  requesterAccount,
  receiverAccount,
}: AddNetworkConnection) => {
  return requestApi({
    url: `/v1/connections`,
    method: 'POST',
    data: { requesterAccount, receiverAccount },
  })
}

export const updateNetworkConnection = async ({
  connectionId,
  connectionStatus,
}: UpdateNetworkConnection) => {
  return requestApi({
    url: `/v1/connections/${connectionId}`,
    method: 'PATCH',
    data: { connectionStatus },
  })
}
