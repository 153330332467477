import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { CriteriaKeys } from 'components/CSAT/types'
import { usePaymentCaseContext } from 'hooks/context/usePaymentCaseContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useSendPaymentCaseEmailMutation } from 'hooks/mutations/useSendPaymentCaseEmailMutation'
import { useUploadPaymentCaseDocumentMutation } from 'hooks/mutations/useUploadPaymentCaseDocumentMutation'
import { usePaymentCaseDocumentsQuery } from 'hooks/queries/usePaymentCaseDocumentsQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PaymentCaseSendEmail, PaymentCaseType, PaymentCaseUploadDocument } from 'types/payments'
import { DocumentUpload } from 'types/verification'
import { cleanBase64String } from 'utils/utils'
import useCSATSurvey from '../hooks/useCSATSurvey'
import { PaymentCaseDocuments } from './PaymentCaseDocuments'
import { UploadDocumentModal } from './UploadDocumentModal'

export const PaymentCaseDocumentsBody = () => {
  const { t } = useTranslation()
  const { enableCsatSurveyCasePortal, getIsCriteriaCompleted, completeCSATCriteria } =
    useCSATSurvey()
  const { paymentCaseId, paymentCaseStatus } = usePaymentCaseContext() || {}
  const { profile } = useProfileContext() || {}
  const addToast = useToastContext()
  const queryClient = useQueryClient()

  const permissions = useMappedRoles().paymentSupport
  const canEdit = permissions?.canEdit

  const [showUploadModal, setShowUploadModal] = useState(false)
  const hasUploadedDocumentCriteriaCompleted = getIsCriteriaCompleted(
    CriteriaKeys.HAS_UPLOADED_DOCUMENT
  )

  const {
    isPending,
    isFetching: isFetchingDocuments,
    isError,
  } = usePaymentCaseDocumentsQuery(paymentCaseId)

  const { mutate, isPending: isUploadingDocument } = useUploadPaymentCaseDocumentMutation()
  const { mutate: onMutateSendEmail } = useSendPaymentCaseEmailMutation()

  const sendEmail = (document: DocumentUpload) => {
    const emailPayload: PaymentCaseSendEmail = {
      caseId: paymentCaseId,
      fromAddress: profile?.emailAddress,
      htmlBody: `Uploaded document: ${document?.fileData?.name} - ${document?.description}`,
    }
    onMutateSendEmail(emailPayload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: reactQueryKeys.paymentCaseEmails(paymentCaseId),
        })
        await queryClient.invalidateQueries({ queryKey: reactQueryKeys.paymentSupportCases() })
      },
      onError: () => {
        addToast({ title: t('paymentSupport.errorEmailSend'), state: 'error' })
      },
    })
  }

  const uploadDocument = (document: DocumentUpload) => {
    const caseDocumentPayload: PaymentCaseUploadDocument = {
      caseId: paymentCaseId,
      filename: document?.fileData?.name,
      description: document?.description,
      content: cleanBase64String(document?.buffer as string),
    }

    mutate(caseDocumentPayload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: reactQueryKeys.paymentCaseDocuments(paymentCaseId),
        })

        setShowUploadModal(false)

        if (paymentCaseStatus == PaymentCaseType.RESOLVED) {
          sendEmail(document)
        }

        addToast({ title: t('paymentSupport.successDocumentUploaded'), state: 'success' })
        !hasUploadedDocumentCriteriaCompleted &&
          enableCsatSurveyCasePortal &&
          handleCSATSurveyUpdate()
      },
      onError: () => {
        addToast({ title: t('paymentSupport.errorDocumentUploaded'), state: 'error' })
      },
    })
  }

  const handleCSATSurveyUpdate = () => {
    completeCSATCriteria(CriteriaKeys.HAS_UPLOADED_DOCUMENT)
  }

  return (
    <>
      <Box paddingX={20} overflowY="auto" className="h-[calc(100%-151px)]">
        <PaymentCaseDocuments />
      </Box>
      {!isPending && !isError && (
        <>
          <Box paddingX={20} paddingT={24} paddingB={20} className="w-full">
            <Button
              loading={isUploadingDocument || isFetchingDocuments}
              className="w-full"
              disabled={!canEdit}
              iconElement={<Icon name="UploadIcon" color="white" />}
              onClick={() => setShowUploadModal(true)}
            >
              {t('documents.uploadDocument')}
            </Button>
          </Box>

          {showUploadModal &&
            createPortal(
              <UploadDocumentModal
                onUpload={document => uploadDocument(document)}
                onClose={() => setShowUploadModal(false)}
              />,
              document.getElementById('root')
            )}
        </>
      )}
    </>
  )
}
