import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { PathNames } from 'constants/General'
import ChannelCreateForm from '../Form/CreateChannelForm'
import SuccessResponseForm from '../Form/SuccessResponseForm'

interface ChannelCreateModalProps {
  closeCallback: () => void
}

const ChannelCreateModal = ({ closeCallback }: ChannelCreateModalProps) => {
  const { t } = useTranslation()

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')
  const { pathname } = useLocation()

  return (
    <Modal visible closeModal={closeCallback}>
      {!showSuccessModal && (
        <ModalNavBar title={t('payments.channels.createChannel')} onClose={closeCallback} />
      )}
      <Wrapper tag="section" testid="payment-create-modal-wrapper" className=" w-512 mx-auto">
        {!showSuccessModal && (
          <ChannelCreateForm
            onSuccess={redirectUrl => {
              setRedirectUrl(redirectUrl)
              setShowSuccessModal(true)
            }}
            dismissAction={closeCallback}
          />
        )}
      </Wrapper>
      {showSuccessModal && (
        <SuccessResponseForm
          showManageChannelsButton={
            pathname !== `${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`
          }
          redirectUrl={redirectUrl}
          closeCallback={closeCallback}
        />
      )}
    </Modal>
  )
}

export default React.memo(ChannelCreateModal)
