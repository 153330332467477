import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { WithdrawSteps } from 'types/wallets'
import { createReferenceSchema } from 'utils/yupSchemas/createReferenceSchema'
import { WithdrawV2Form } from '../components/Withdraw/WithdrawV2'

const withdrawSchema = ({
  t,
  step,
}: {
  t: TFunction<'translation', undefined>
  step: WithdrawSteps
}) => {
  const amount = yup.number()
  const reference = createReferenceSchema(t('beneficiaryPaymentReference'), t, true)
  const password = yup.string().trim()
  const currencyCode = yup.string().trim()
  const challenge = yup.string()
  const formFields = {
    reference,
    amount,
    currencyCode,
  }
  const confirmFields = { password, challenge }
  return yup.object<Partial<Record<keyof WithdrawV2Form, yup.AnySchema>>>(
    step === 'form' ? formFields : confirmFields
  )
}

export default withdrawSchema
