import React from 'react'
import i18next from 'i18next'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface FailedWithdrawalProps {
  amountWithCurrency: string
  merchantId: string
}

const FailedWithdrawal = ({ amountWithCurrency, merchantId }: FailedWithdrawalProps) => {
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.amountReturned')}
          detail={amountWithCurrency}
        />
      )}

      {merchantId && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.merchantId')}
          detail={merchantId}
        />
      )}
    </>
  )
}

export default FailedWithdrawal
