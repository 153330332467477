import React from 'react'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Box, Box as InfoTitle, Box as Section } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { Description } from 'components/text/Description'
import { EMICountriesType } from '../types'

export interface EMIAgreementProps {
  country: EMICountriesType
}

export const EMIAgreement = ({ country }: EMIAgreementProps) => {
  const { t } = useTranslation()
  const FAQsUrl = process.env.EMI_FAQ_URL

  return (
    <Box flex direction="col" gapY={16}>
      <Section flex direction="col" gapY={4}>
        <InfoTitle flex gapX={4}>
          <Text size="sm" weight="semibold">
            {t(`emiAgreement.titleElectronicMoneyAccounts.${country}`)}
          </Text>
          <Tooltip
            position="bottom"
            bodyContent={t(`emiAgreement.tooltipElectronicMoneyAccounts.${country}`)}
            hasMaxWidth
          >
            <Icon name="InfoCircleIcon" color={secondaryButtonIconColour} />
          </Tooltip>
        </InfoTitle>
        <Description>{t(`emiAgreement.descriptionElectronicMoneyAccounts.${country}`)}</Description>
        <Text size="sm">
          <Button noStyling onClick={() => window.open(FAQsUrl)}>
            {t('FAQs')}
          </Button>
        </Text>
      </Section>

      <Section flex direction="col" gapY={4}>
        <InfoTitle flex gapX={4}>
          <Text size="sm" weight="semibold">
            {t('emiAgreement.titleWhatsNext')}
          </Text>
          <Tooltip
            position="bottom"
            bodyContent={`${t('emiAgreement.tooltipWhatsNext')}`}
            hasMaxWidth
          >
            <Icon name="InfoCircleIcon" color={secondaryButtonIconColour} />
          </Tooltip>
        </InfoTitle>
        <Description>{t(`emiAgreement.descriptionWhatsNext.${country}`)}</Description>
      </Section>
    </Box>
  )
}
