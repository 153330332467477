import React from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import DoubleLabelSelect from '@node-space/storybook-components/dist/DoubleLabelSelect'
import Text from '@node-space/storybook-components/dist/Text'
import { useMerchantsQuery } from 'hooks/queries'
import { useWalletsQueryV2 } from 'hooks/queries/useWalletsQueryV2'
import { Currencies, WalletTypes } from 'types/types'
import { roundNumberWithCommas } from 'utils/utils'
import { merchantAtom, walletAtom, walletTypeAtom } from '../atoms/massPayoutAtoms'

export const MassPayoutWalletSelection = () => {
  const { t } = useTranslation()
  const {
    data: merchants,
    isFetching: isFetchingMerchants,
    error: isErrorMerchants,
  } = useMerchantsQuery()
  const [merchant, setMerchant] = useAtom(merchantAtom)
  const [wallet, setWallet] = useAtom(walletAtom)
  const walletType = useAtomValue(walletTypeAtom)
  const isFiatMasspayouts = walletType === WalletTypes.FIAT

  const {
    data: wallets,
    isFetching: isFetchingWallets,
    isError: isErrorWallets,
  } = useWalletsQueryV2({
    walletType: WalletTypes.FIAT.toLowerCase(),
    max: 100,
  })

  const MASS_PAYOUTS_FIAT_CURRENCIES: string[] = [Currencies.GBP, Currencies.EUR]

  const fiatWallets = wallets?.data?.filter(
    wallet =>
      wallet?.currency?.fiat &&
      MASS_PAYOUTS_FIAT_CURRENCIES.includes(wallet?.currency?.code) &&
      !!wallet?.supportedTransferDestinations?.length
  )

  const walletListOptions = fiatWallets?.map(wallet => {
    const code = wallet?.currency?.code
    const approximateBalance = roundNumberWithCommas(wallet?.approxBalance, 2)
    return {
      icon: code?.toLowerCase(),
      label: wallet?.description,
      value: wallet?.lsid,
      secondLabel: ` ${approximateBalance} ${code}`,
      secondLabelText: t('fundingWallet'),
    }
  })

  const merchantListOptions = merchants?.merchantList?.map(merchant => {
    const code = merchant?.wallet?.currency?.code
    const approximateBalance = merchant.wallet?.currency?.fiat
      ? roundNumberWithCommas(merchant?.wallet?.approxBalance, 2)
      : merchant?.wallet?.approxBalance

    return {
      icon: code?.toLowerCase(),
      label: merchant?.displayName,
      value: merchant?.merchantId,
      secondLabel: ` ${approximateBalance} ${code}`,
      secondLabelText: t('fundingWallet'),
    }
  })

  const onWalletChangeHandler = (value: string) => {
    setWallet(value)
  }

  const onMerchantChangeHandler = (value: string) => {
    setMerchant(value)
  }

  return (
    <Box flex direction="col" justifyContent="start" gapY={4}>
      {isFiatMasspayouts ? (
        <DoubleLabelSelect
          label={t('massPayouts.selectWalletToPayFrom')}
          name="walletId"
          options={walletListOptions}
          isSearchable
          loading={isFetchingWallets}
          onChange={value => {
            onWalletChangeHandler(value)
          }}
          placeholder={t('massPayouts.searchOrSelect')}
          iconsUrl={process.env.ICONS_URL}
          error={!!isErrorWallets}
          value={wallet}
        />
      ) : (
        <DoubleLabelSelect
          label={t('massPayouts.selectWalletToPayFrom')}
          name="merchantId"
          options={merchantListOptions}
          isSearchable
          loading={isFetchingMerchants}
          onChange={value => {
            onMerchantChangeHandler(value)
          }}
          placeholder={t('massPayouts.searchOrSelect')}
          iconsUrl={process.env.ICONS_URL}
          error={!!isErrorMerchants}
          value={merchant}
        />
      )}

      <Text align="left" size="sm" color="text-500">
        {t('massPayouts.selectWalletDescription')}
      </Text>
    </Box>
  )
}
