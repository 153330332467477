import { requestApi } from 'api/api'
import {
  BusinessCustomerType,
  Customer,
  IndividualCustomerType,
  Industries,
  MonthlyVolume,
} from 'types/customers'
import { WithPaginationApiResponse } from 'types/pagination'
import { API_PAGINATION_LIMIT, API_PAGINATION_OFFSET } from 'utils/pagination'

export const getCustomers = (
  search: string,
  size = API_PAGINATION_LIMIT,
  page = API_PAGINATION_OFFSET,
  sort = ''
): Promise<WithPaginationApiResponse<Customer[]>> => {
  return requestApi({
    url: '/platform/v1/customers',
    method: 'GET',
    params: {
      size,
      page,
      ...(sort && { sort }),
      ...(search && { name: search }),
    },
  })
}

export const postAddCustomer = (
  customer: BusinessCustomerType | IndividualCustomerType
): Promise<void> => {
  return requestApi({
    url: '/platform/v1/customers',
    method: 'POST',
    data: customer,
  })
}

export const getCustomer = (
  customerReference: string
): Promise<BusinessCustomerType | IndividualCustomerType> => {
  return requestApi({
    url: `/platform/v1/customers/${customerReference}`,
    method: 'GET',
  })
}

export const getAllMonthlyVolumes = (): Promise<MonthlyVolume[]> => {
  return requestApi({
    url: '/platform/v1/accounts/monthly-expected-volumes',
    method: 'GET',
  })
}

export const getIndustries = (): Promise<Industries[]> => {
  return requestApi({
    url: '/platform/v1/accounts/industries',
    method: 'GET',
  })
}
