import * as React from 'react'
import { useTranslation } from 'react-i18next'
import SimpleTableV2 from '@node-space/storybook-components/dist/SimpleTableV2'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { Approval } from 'constants/General'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { AccountUser } from 'types/accounts'

interface TeamMemberTableProps {
  accountUsers: AccountUser[]
  currentEmailAddress: string
  onEditUserClick: (accountUser: AccountUser) => void
  onDeleteUserClick: (accountUser: AccountUser) => void
}

export const TeamMemberTable = ({
  accountUsers,
  currentEmailAddress,
  onEditUserClick,
  onDeleteUserClick,
}: TeamMemberTableProps) => {
  const { t } = useTranslation()
  const permissions = useMappedRoles()?.manageTeamMembers
  const canEdit = permissions?.canEdit

  const tableRowClasses = 'py-5 px-1 '

  const parseUsers = () => {
    if (!!accountUsers?.length) {
      const tableArray = []

      accountUsers?.forEach(item => {
        const rowObject = {
          rowData: [],
        }

        rowObject?.rowData?.push({
          cellData: item?.emailAddress,
          className: `${tableRowClasses} pl-4`,
        })

        let groupList = 'N/A'
        if (item?.groups?.length > 0) {
          groupList = item?.groups
            ?.filter(userGroup => userGroup?.name !== Approval.ACCOUNT_TRANSACTION_APPROVER)
            ?.map(group => {
              return group?.name
            })
            .join(', ')
        }

        rowObject?.rowData?.push({ cellData: groupList })
        if (item?.emailAddress !== currentEmailAddress && canEdit) {
          rowObject?.rowData?.push({
            cellData: (
              <TablePopoverMenu
                stopPropagation
                testid="team-member-popover-menu"
                menuItems={[
                  {
                    iconName: 'EditIcon',
                    label: t('teamMember.changeUserRole'),
                    onClick: () => onEditUserClick(item),
                  },
                  {
                    iconName: 'TrashIcon',
                    label: t('delete'),
                    onClick: () => onDeleteUserClick(item),
                  },
                ]}
              />
            ),
            className: `${tableRowClasses} pr-4`,
            align: 'end',
          })
        } else {
          rowObject?.rowData?.push({
            cellData: '',
          })
        }

        tableArray?.push(rowObject)
      })

      return tableArray
    }
  }
  const tableData = parseUsers()

  return (
    <>
      {!tableData ? (
        <p>{t('noAccountUsersExist')}</p>
      ) : (
        <SimpleTableV2
          testid="team-member-table"
          columns={[
            { headerTitle: t('email'), className: 'pl-4' },
            { headerTitle: t('teamMember.role') },
            { headerTitle: '' },
          ]}
          tableData={tableData}
        />
      )}
    </>
  )
}
