import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import SmallTabs from '@node-space/storybook-components/dist/SmallTabs'
import { usePaymentCaseContext } from 'hooks/context/usePaymentCaseContext'
import { CaseDetailsTabs } from 'types/payments'
import { PaymentCaseDocumentsBody } from './PaymentCaseDocumentsBody'
import { PaymentCaseEmailsBody } from './PaymentCaseEmailsBody'

export const PaymentCaseDetailsBody = () => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState<CaseDetailsTabs>(CaseDetailsTabs.EMAILS)

  const { paymentCaseId } = usePaymentCaseContext()

  const paymentCaseDetailsTabs = [
    {
      id: CaseDetailsTabs.EMAILS,
      onClick: () => setActiveTab(CaseDetailsTabs.EMAILS),
      tabContent: <PaymentCaseEmailsBody />,
      tabHeader: t('paymentSupport.emails'),
      testid: 'payment-case-emails-tab',
    },
    {
      id: CaseDetailsTabs.DOCUMENTS,
      onClick: () => setActiveTab(CaseDetailsTabs.DOCUMENTS),
      tabContent: <PaymentCaseDocumentsBody />,
      tabHeader: t('paymentSupport.documents'),
      testid: 'payment-case-docs-tab',
    },
  ]

  return (
    <Box id="caseDetailsBody" paddingT={8} className="h-full relative">
      {paymentCaseId && (
        <SmallTabs className="mb-6" currentValueId={activeTab} tabs={paymentCaseDetailsTabs} />
      )}
    </Box>
  )
}
