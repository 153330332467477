import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Box } from '@node-space/storybook-components/dist/Box'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'

interface TestIds {
  headline: string
  description: string
  clearButton: string
}

interface NoSearchResultsProps {
  testIds?: TestIds
  clearSearchCriteria?: () => void
}
const NoSearchResults = ({ testIds, clearSearchCriteria }: NoSearchResultsProps) => {
  const { t } = useTranslation()

  return (
    <Box paddingY={16}>
      <StatusCard
        {...(testIds?.headline && { testid: testIds?.headline })}
        headline={t('noSearchResultHeadline')}
      >
        <Text
          {...(testIds?.description && { testid: testIds?.description })}
          align="center"
          size="sm"
        >
          {t('noSearchResultDescription')}
          <Button
            {...(testIds?.clearButton && { testid: testIds?.clearButton })}
            noStyling
            {...(!!clearSearchCriteria && { onClick: clearSearchCriteria })}
            className="ml-1 text-primary-500"
          >
            {t('clearSearchCriteria')}
          </Button>
        </Text>
      </StatusCard>
    </Box>
  )
}

export default NoSearchResults
