import React, { useMemo, useState } from 'react'
import { snakeCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box as Container,
  Box as Item,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { ImageIcon } from '@node-space/storybook-components/dist/ImageIcon'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import {
  SimpleTableV2,
  TableColumnV2,
  TableRowV2,
} from '@node-space/storybook-components/dist/SimpleTableV2'
import { Text } from '@node-space/storybook-components/dist/Text'
import OnClickProvider from 'components/OnClickProvider/OnClickProvider'
import TablePagination from 'components/PageableTablePagination'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { useBeneficiariesQuery } from 'hooks/queries/useBeneficiariesQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { usePaginationPagingPersist } from 'hooks/usePaginationPagingPersist'
import { BeneficiaryDetails, BeneficiaryType } from 'types/beneficiaries'
import { PaginationPaging } from 'types/pagination'
import { getBeneficiaryName, getBeneficiaryType, getEntityType } from 'utils/beneficiaries'
import { BeneficiaryEmptyState } from './BeneficiaryEmptyState'
import { BeneficiaryErrorState } from './BeneficiaryErrorState'
import { BeneficiarySummary } from './BeneficiarySummary'

interface BeneficiaryTableProps {
  setBeneficiaryToDelete: React.Dispatch<React.SetStateAction<BeneficiaryDetails>>
  setIsAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleEditBeneficiaryName: (beneficiary: BeneficiaryDetails) => void
}

export const BeneficiaryTable = ({
  setBeneficiaryToDelete,
  setIsAddModalOpen,
  handleEditBeneficiaryName,
}: BeneficiaryTableProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { enableBeneficiaryConsolidation } = useFeatureFlags()
  const sizeMax = +searchParams.get('size')

  const [page, setPage] = useState<number>(0)

  const {
    data: { content: beneficiaryList, pagination } = {},
    isPending,
    isError,
  } = useBeneficiariesQuery({
    page,
    ...(sizeMax && { size: sizeMax }),
    ...(!enableBeneficiaryConsolidation && { beneficiaryType: BeneficiaryType.THIRD_PARTY }),
  })

  const {
    isSidePanelOpen: isBeneficiaryDetailsOpen,
    sidePanelData: beneficiaryDetails,
    paging: pageBeneficiaries,
    setIsSidePanelOpen: setIsBeneficiaryDetailsOpen,
    updateSidePanel: updateBeneficiaryDetails,
  } = useSidePanel<BeneficiaryDetails>(beneficiaryList)

  const { paging, showPagination } = usePaginationPagingPersist(pagination)
  const permissions = useMappedRoles().manageBeneficiaries

  const canEdit = permissions?.canEdit

  const columns: TableColumnV2[] = [
    {
      headerTitle: t('accountHolder'),
      className: 'pl-4',
      width: '40%',
      testid: `beneficiary-table-column-account-holder`,
    },
    {
      headerTitle: t('currency'),
      testid: `beneficiary-table-column-currency`,
    },
    {
      headerTitle: t('manageBeneficiaries.beneficiaryType'),
      width: '15%',
      testid: `beneficiary-table-column-beneficiary-type`,
    },
    {
      headerTitle: t('manageBeneficiaries.entityType'),
      width: '15%',
      testid: `beneficiary-table-column-entity-type`,
    },
    {
      headerTitle: '',
      align: 'end',
      testid: `beneficiary-table-column-popover-menu`,
    },
  ]

  const onOpenBeneficiaryDetails = (beneficiaryIndex: number) => {
    updateBeneficiaryDetails(beneficiaryIndex)
    setIsBeneficiaryDetailsOpen(true)
  }

  const tableData: TableRowV2[] = useMemo(() => {
    return beneficiaryList?.map((beneficiary: BeneficiaryDetails, beneficiaryIndex?: number) => {
      const currencyIcon = beneficiary.currency
        ? `${process.env.ICONS_URL}${beneficiary.currency?.toLowerCase()}.svg`
        : process.env.ICONS_URL

      return {
        rowData: [
          {
            cellData: (
              <OnClickProvider
                id={beneficiary?.reference}
                onClick={() => onOpenBeneficiaryDetails(beneficiaryIndex)}
              >
                <Item paddingY={20} paddingL={16} paddingR={12}>
                  <Text size="sm">
                    {getBeneficiaryName(beneficiary)}
                    {beneficiary?.alias ? ` (${beneficiary.alias})` : ''}
                  </Text>
                  <Text size="sm" color="grey">
                    {beneficiary.fiat?.accountNumber}
                  </Text>
                </Item>
              </OnClickProvider>
            ),
            testid: `beneficiary-${snakeCase(getBeneficiaryName(beneficiary))}-title`,
          },
          {
            cellData: (
              <Item flex paddingR={12}>
                <Container paddingR={12}>
                  <ImageIcon width={24} height={24} src={currencyIcon} />
                </Container>
                <Text size="sm">{beneficiary.currency}</Text>
              </Item>
            ),
            testid: `beneficiary-${snakeCase(getBeneficiaryName(beneficiary))}-currency`,
          },
          {
            cellData: <Text size="sm">{getBeneficiaryType(beneficiary?.beneficiaryType, t)}</Text>,
            testid: `beneficiary-${snakeCase(getBeneficiaryName(beneficiary))}-beneficiary-type`,
          },
          {
            cellData: <Text size="sm">{getEntityType(beneficiary?.entityType, t)}</Text>,
            testid: `beneficiary-${snakeCase(getBeneficiaryName(beneficiary))}-entity-type`,
          },
          {
            cellData: canEdit ? (
              <TablePopoverMenu
                key={`${beneficiary?.reference}(${beneficiary.alias})`}
                hasPadding
                testid="beneficiaryTablePopverMenu"
                stopPropagation
                menuItems={[
                  {
                    iconName: 'InfoCircleIcon',
                    label: t('details'),
                    onClick: () => onOpenBeneficiaryDetails(beneficiaryIndex),
                  },
                  {
                    iconName: 'TrashIcon',
                    label: t('delete'),
                    onClick: () => setBeneficiaryToDelete(beneficiary),
                  },
                  {
                    iconName: 'EditIcon',
                    label: t('manageBeneficiaries.editBeneficiary'),
                    onClick: () => handleEditBeneficiaryName(beneficiary),
                  },
                ]}
              />
            ) : null,
            align: 'end',
          },
        ],
        onClick: () => onOpenBeneficiaryDetails(beneficiaryIndex),
      }
    })
  }, [beneficiaryList])

  return (
    <Wrapper tag="span" testid="container-beneficiary">
      {isError ? (
        <BeneficiaryErrorState onRefresh={() => window.location.reload()} />
      ) : (
        <>
          <Container
            tag="span"
            className={`${isPending && 'border-primaryTint'} rounded middle grid min-h-[200px]`}
          >
            {isPending ? (
              <Container flex height="full" flexJustifyCenter flexItemsCenter>
                <Loader theme="dark" size="medium" />
              </Container>
            ) : (
              <>
                {beneficiaryList?.length ? (
                  <>
                    <SimpleTableV2 columns={columns} tableData={tableData} />
                    <BeneficiarySummary
                      isOpen={isBeneficiaryDetailsOpen}
                      beneficiaryDetails={beneficiaryDetails}
                      pageBeneficiaries={pageBeneficiaries}
                      onClose={() => setIsBeneficiaryDetailsOpen(false)}
                    />
                  </>
                ) : (
                  <BeneficiaryEmptyState onAddBeneficiary={() => setIsAddModalOpen(true)} />
                )}
              </>
            )}
          </Container>

          {!!paging && !showPagination && (
            <Container className={`${isPending || showPagination ? 'opacity-25' : ''}`}>
              <TablePagination
                disableLeftDropdown
                total={paging?.total}
                pagingMax={paging?.max}
                onPagination={({ page }: PaginationPaging) => setPage(page)}
              />
            </Container>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default BeneficiaryTable
