import { useEffect, useState } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import {
  PayoutsFeeEstimateParams,
  usePayoutsFeeEstimateQuery,
} from 'hooks/queries/usePayoutsFeeEstimateQuery'
import { BeneficiaryDetails } from 'types/beneficiaries'
import { WalletType } from 'types/types'
import { PayForm } from '../Pay'
import { getInitialMaxPayoutAmountRequest } from '../utils/payFormUtils'

const usePaymentFeeRequest = (
  wallets: WalletType[],
  formValues: PayForm,
  selectedBeneficiary: BeneficiaryDetails
) => {
  const [maxPayoutAmount, setPayoutMaxAmount] = useState(0)

  const { enablePayoutsV2 } = useFeatureFlags()
  const selectedWallet =
    formValues?.walletId && wallets.find(x => x.id.toString() === formValues?.walletId)
  const isPayoutsV2Enabled =
    enablePayoutsV2 && !!selectedWallet?.supportedTransferDestinations?.length

  const initialPayoutFeeRequest = getInitialMaxPayoutAmountRequest(
    formValues,
    selectedWallet,
    isPayoutsV2Enabled
  )
  const initialPayoutFeeRequestParams: PayoutsFeeEstimateParams = {
    request: initialPayoutFeeRequest,
    isThirdPartyPayment: true,
    isPayoutFeesV2: isPayoutsV2Enabled,
    isEnabled: false,
    transferDestination: selectedBeneficiary?.transferDestination,
  }

  const {
    data: initialFeeForMaxAmount,
    refetch: fetchInitialFeeForMaxAmount,
    isFetching: isFetchingMaxPayoutAmount,
    error: fetchingMaxPayoutAmountError,
  } = usePayoutsFeeEstimateQuery(initialPayoutFeeRequestParams)

  useEffect(() => {
    !!formValues?.beneficiarySelection &&
      formValues?.reference &&
      selectedWallet &&
      !selectedWallet?.supportsInternalBvnkNetworkTransfers &&
      fetchInitialFeeForMaxAmount()
  }, [formValues?.beneficiarySelection, formValues?.reference, selectedWallet])

  useEffect(() => {
    if (!!initialFeeForMaxAmount) {
      setPayoutMaxAmount(initialFeeForMaxAmount?.maximumWithdrawalAmount)
    }
  }, [initialFeeForMaxAmount])

  return {
    maxPayoutAmount,
    isFetchingMaxPayoutAmount,
    fetchingMaxPayoutAmountError,
  }
}

export default usePaymentFeeRequest
