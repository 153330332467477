import React, { useCallback, useContext, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CopyButton, TextMask } from '@node-space/storybook-components'
import { Box } from '@node-space/storybook-components/dist/Box'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { ApiKey } from 'types/apiKeys'
import { ApiKeyModalContext } from '../context/ApiKeyModalProvider'
import ApiKeyDeleteModal from './Modal/ApiKeyDeleteModal'
import ApiKeyManageModal from './Modal/ApiKeyManageModal'

export const formattedDate = (date: string) => {
  const formattedDate = dayjs(date).format('DD MMMM YYYY')
  return formattedDate
}

type ApiKeysTableProps = {
  data: ApiKey[]
}

const ApiKeysTable = ({ data }: ApiKeysTableProps) => {
  const { t } = useTranslation()
  const permissions = useMappedRoles().manageApiIntegration
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { setShowCreateModal, setShowManageModal } = useContext(ApiKeyModalContext)
  const onDeleteClicked = useCallback(
    (apiKey: ApiKey) => {
      setSelectedApiKey(apiKey)
      setShowDeleteModal(true)
    },
    [setSelectedApiKey, setShowDeleteModal]
  )

  const onEditClick = useCallback(
    (apiKey: ApiKey) => {
      setSelectedApiKey(apiKey)
      setShowManageModal(true)
    },
    [setShowCreateModal]
  )

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    if (!!selectedApiKey) {
      setSelectedApiKey(null)
    }
  }

  const convertKeyInfo = () => {
    if (!data) return []

    return data.map(apiKey => ({
      rowData: [
        { cellData: apiKey?.description, className: 'pl-4 text-base py-5' },
        {
          cellData: (
            <Box flex direction="row" justifyContent="between">
              <TextMask text={ellipseMiddleText(apiKey?.tokenValue, 25, 10)} />
              <CopyButton
                copyText={apiKey?.tokenValue?.toString()}
                confirmationMessage={t('copiedToClipboard')}
              />
            </Box>
          ),
        },
        {
          cellData: (
            <Box flex direction="row" justifyContent="between">
              <Text size="sm">{apiKey?.canWithdraw ? 'Withdrawals' : ''}</Text>
            </Box>
          ),
        },
        {
          cellData: (
            <Box className="w-3/4">
              <Text size="sm">{formattedDate(apiKey?.dateCreated)}</Text>
            </Box>
          ),
        },
        {
          cellData: permissions?.canEdit ? (
            <TablePopoverMenu
              stopPropagation
              hasPadding
              testid="apiKeysTablePopverMenu"
              menuItems={[
                {
                  iconName: 'EditIcon',
                  label: t('apiManage.actionButtons.editBtn'),
                  onClick: () => onEditClick(apiKey),
                },
                {
                  iconName: 'TrashIcon',
                  label: t('apiManage.actionButtons.deleteBtn'),
                  onClick: () => onDeleteClicked(apiKey),
                },
              ]}
            />
          ) : null,
        },
      ],
    }))
  }

  return (
    <>
      {selectedApiKey && (
        <>
          <ApiKeyDeleteModal
            selectedApiKey={selectedApiKey}
            handleCloseModal={handleCloseDeleteModal}
            showDeleteModal={showDeleteModal}
          />
          <ApiKeyManageModal
            selectedApiKey={selectedApiKey}
            onSuccessCreateApiKey={() => setSelectedApiKey(null)}
            onClose={() => setSelectedApiKey(null)}
          />
        </>
      )}

      {data && data.length > 0 && (
        <SimpleTableV2
          columns={[
            { headerTitle: t('name'), className: 'pl-4' },
            { headerTitle: t('hawkAuthID') },
            { headerTitle: t('manageApiKeys.apiKeysEnabledFor') },
            { headerTitle: t('manageApiKeys.apiKeysActiveSince') },
            { headerTitle: '' },
          ]}
          tableData={convertKeyInfo()}
        />
      )}
    </>
  )
}

export default React.memo(ApiKeysTable)
