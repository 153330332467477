import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { orderBy, isEmpty, map } from 'lodash-es'
import { Box as OuterWrapper, Chip } from '@node-space/storybook-components'
import useFilterParams from 'components/FilterBar/hooks/useFilterParams'
import useFilterFromTo from 'components/FilterBar/hooks/useFilterFromTo'
import useFilterStatus from 'components/FilterBar/hooks/useFilterStatus'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import useFilterSearch from 'components/FilterBar/hooks/useFilterSearch'
import { rootInitialState } from 'components/FilterBar/context/reducer'

const FilterResetList = ({ executor }) => {
  const { filterCount } = useFilterParams()
  const { resetDateRange } = useFilterFromTo()
  const { resetStatus } = useFilterStatus()
  const { resetMerchant } = useFilterMerchant()
  const { resetSearch } = useFilterSearch()

  const onResetListClose = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget
    const fromDefault = rootInitialState?.from
    const toDefault = rootInitialState?.to
    const statusDefault = rootInitialState?.status
    const merchantDefault = rootInitialState?.merchant
    const searchDefault = rootInitialState?.search

    if (id === 'date') {
      resetDateRange()
      executor({ from: fromDefault, to: toDefault })
    }

    if (id === 'status') {
      resetStatus()
      executor({ status: statusDefault })
    }

    if (id === 'merchant') {
      resetMerchant()
      executor({ merchant: merchantDefault })
    }

    if (id === 'search') {
      resetSearch()
      executor({ search: searchDefault })
    }
  }

  const orderedFilterList = orderBy(filterCount, ['order']) || []

  return (
    <>
      {!isEmpty(orderedFilterList) && (
        <OuterWrapper testid="filter-bar-reset" className="flex flex-row mt-3">
          {map(orderedFilterList, filter => {
            return (
              <Chip
                key={uuidv4()}
                value={filter?.value}
                id={filter?.name}
                onCloseHandler={onResetListClose}
              />
            )
          })}
        </OuterWrapper>
      )}
    </>
  )
}

export default FilterResetList
