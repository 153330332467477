import { TFunction } from 'react-i18next'
import * as yup from 'yup'

const MIN_LENGTH = 2
const MAX_LENGTH = 200
export const createAddressSchema = (label: string, t: TFunction<'translation', undefined>) =>
  yup
    .string()
    .trim()
    .label(label)
    .min(
      MIN_LENGTH,
      t('fieldMinLength', {
        field: label,
        min: MIN_LENGTH,
        interpolation: { escapeValue: false },
      })
    )
    .max(
      MAX_LENGTH,
      t('fieldMaxLength', {
        field: label,
        max: MAX_LENGTH,
      }).trim()
    )
