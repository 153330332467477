import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import { logSentryError } from '@node-space/utils'
import { CriteriaKeys } from 'components/CSAT/types'
import { usePutPaymentCaseMutation } from 'hooks/mutations/usePutPaymentCaseMutation'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PaymentCaseUpdate } from 'types/payments'
import useCSATSurvey from '../hooks/useCSATSurvey'

interface CaseCompleteCheckboxProps {
  isCaseCompleted?: boolean
  caseId: string
}

export const CaseCompleteCheckbox = ({ isCaseCompleted, caseId }: CaseCompleteCheckboxProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const [isCompleted, setIsCompleted] = useState(false)
  const permissions = useMappedRoles().paymentSupport
  const canEdit = permissions?.canEdit
  const { mutate, isPending } = usePutPaymentCaseMutation()
  const { enableCsatSurveyCasePortal, getIsCriteriaCompleted, completeCSATCriteria } =
    useCSATSurvey()
  const isCaseCriteriaCompleted = getIsCriteriaCompleted(CriteriaKeys.IS_CASE_COMPLETED)

  useEffect(() => {
    setIsCompleted(isCaseCompleted)
  }, [isCaseCompleted])

  const handleOnChange = async value => {
    setIsCompleted(currentValue => !currentValue)
    const caseCompletedPayload: PaymentCaseUpdate = {
      customer: { customerCaseCompleted: value },
      caseId,
    }

    mutate(caseCompletedPayload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: reactQueryKeys.paymentSupportCases() })

        if (value) {
          addToast({
            title: t('paymentSupport.successCompleteMessage'),
            state: 'success',
          })
          !isCaseCriteriaCompleted && enableCsatSurveyCasePortal && handleCSATSurveyUpdate()
        }
      },
      onError: error => {
        setIsCompleted(isCaseCompleted)
        addToast({ title: t('paymentSupport.errorCompleteMessage'), state: 'error' })
        logSentryError('Error: could not update payment case completed', error)
      },
    })
  }

  const handleCSATSurveyUpdate = () => {
    completeCSATCriteria(CriteriaKeys.IS_CASE_COMPLETED)
  }

  return (
    <CheckBox
      label={t('paymentSupport.markAsComplete')}
      disabled={!canEdit || isPending}
      value={isCompleted}
      onChange={handleOnChange}
      isLoading={isPending}
      labelColour="light"
    />
  )
}
