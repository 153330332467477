import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCountries } from 'services/ReferenceService'
import { Country } from 'types/authentication/Profile'
import { ONE_DAY } from 'utils/utils'

export const useCountriesQuery = (canEnable = true, pagination = false) => {
  return useQuery<Country[]>({
    queryKey: reactQueryKeys.countries(canEnable, pagination),
    queryFn: () => getCountries(canEnable, pagination),
    staleTime: ONE_DAY,
  })
}
