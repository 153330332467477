import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { TransactionsContextProvider } from 'contexts/transactionsContext'
import { useWalletContext } from 'hooks/context'
import { useTransactionsQuery } from 'hooks/queries'
import { pageListOptions } from 'pages/WalletDetails/constants/contants'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { NoTransactions } from './NoTransactions/NoTransactions'
import { SidePanelSummary } from './SidePanel/SidePanelSummary'
import { TransactionsPagination } from './TransactionsPagination'
import { TransactionTable } from './TransactionTable'
import { TransactionExport } from './WalletTransactionsExport/TransactionExport'

export const RecentActivity = () => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [numOfRows, setNumOfRows] = useState(pageListOptions[0].value)
  const wallet = useWalletContext()
  const { data, isPending, isFetching, isError } = useTransactionsQuery({
    max: Number(numOfRows),
    offset,
    walletId: wallet.id,
  })
  const { t } = useTranslation()
  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS_TRANSACTION, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const {
    isSidePanelOpen: isTransactionDetailsOpen,
    sidePanelData: transactionDetails,
    paging: pageTransactions,
    setIsSidePanelOpen: setIsTransactionDetailsOpen,
    updateSidePanel: updateTransactionDetails,
  } = useSidePanel(data?.transactions)

  const openSidePanelSummary = (index: number) => {
    updateTransactionDetails(index)
    setIsTransactionDetailsOpen(true)
  }

  if (isPending) {
    return (
      <Box flex justifyContent="center" paddingT={8}>
        <Loader theme="dark" size="large" />
      </Box>
    )
  }
  if (isError) {
    return (
      <div className="pt-2">
        <Callout state="error" message={t('oopsSomethingWentWrong')} />
      </div>
    )
  }

  if (isEmpty(data?.transactions)) {
    return <NoTransactions />
  }

  return (
    <div data-testid="recent-activity-screen">
      <TransactionsContextProvider transactions={data?.transactions}>
        <Box paddingT={20} paddingB={16}>
          <TransactionExport />
        </Box>
        {!!transactionDetails && (
          <SidePanelSummary
            isOpen={isTransactionDetailsOpen}
            paging={pageTransactions}
            onClose={() => setIsTransactionDetailsOpen(false)}
            transactionDetails={transactionDetails}
          />
        )}
        <TransactionTable
          isFetching={isFetching}
          onOpenSidePanelSummary={(index: number) => {
            openSidePanelSummary(index)
          }}
        />
      </TransactionsContextProvider>
      {data?.paging?.total > Number(pageListOptions[0].value) && (
        <TransactionsPagination
          page={page}
          setPage={setPage}
          setOffset={setOffset}
          pagination={data?.paging}
          nrOfRows={numOfRows}
          setNrOfRows={setNumOfRows}
        />
      )}
    </div>
  )
}
