import { useContext } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterTag = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  return {
    tag: state?.tag,
    setTag: (value: string) => dispatch({ type: 'UPDATE_TAG', payload: value }),
    resetTag: () => dispatch({ type: 'RESET_TAG' }),
  }
}

export default useFilterTag
