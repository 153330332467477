import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { TableRow } from '@node-space/storybook-components/dist/components/SimpleTable'
import { ImageIcon } from '@node-space/storybook-components/dist/ImageIcon'
import {
  NotificationCard,
  NotificationState,
} from '@node-space/storybook-components/dist/NotificationCard'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import OnClickProvider from 'components/OnClickProvider/OnClickProvider'
import { TableDataSkeleton } from 'components/Skeletons/TableDataSkeleton'
import { TransactionType } from 'types/transaction/TransactionType'
import { paymentDirections } from 'types/types'
import { getStatusState, titleCase } from 'utils/utils'
import { SearchTransactionModal } from './SearchTransactionModal'
import { transformSearchResultsByPaymentType } from './searchWalletTransformer'

export interface TransactionContent {
  id: string
  transactionId: string
  transactionUUID?: string
  dateCreated: string
  description: string
  amount: number
  status?: string
  fromAmount?: number | string
  toAmount?: number | string
  fromCurrency?: string
  toCurrency?: string
  paymentCode?: string
  type?: string
  subtype?: string
  direction?: string
  code?: string
  subcode?: string
  hash: string
  walletId: number
  walletName: string
  walletAddress?: string
  reference?: string
  customerReference?: string
  transactionReference?: string
  walletVersion?: string
  accountId: number
  hasProofOfPayment?: boolean
  accountNumber?: string
  bankName?: string
  bankCode?: string
  name?: string
}

interface TransactionTypeAggregation {
  value: string
  label: string
}

interface SearchTransactionTableProps {
  walletTransactions: {
    transactions: { content: TransactionContent[] }
    aggregations: TransactionTypeAggregation[]
  }
  isSearching: boolean
  onOpenSidePanelSummary?: (index: number) => void
}

export interface TransformedInfo {
  fromCurrency: string
  toCurrency: string
  amount: number | string
  fromAmount: number | string
  amountWithoutCurrency: number | string
  isChannelsDeposit?: boolean
  resolvedPaymentDirection?: (typeof paymentDirections)[keyof typeof paymentDirections]
  copiesPerKeyTransactionTypes?: unknown
}

const rowHeaderWidth = 'px-8 w-1/4'

export const SearchTransactionTable = ({
  walletTransactions,
  isSearching,
  onOpenSidePanelSummary,
}: SearchTransactionTableProps) => {
  const { t } = useTranslation()
  const { enableSidePanelForWalletTransactions } = useFeatureFlags()

  const transactions = walletTransactions?.transactions?.content ?? []

  const [selectedTransaction, setSelectedTransaction] = useState<TransactionContent | undefined>()
  const [transformedInfo, setTransformedInfo] = useState<TransformedInfo>()
  const handleModalClose = () => setSelectedTransaction(undefined)
  const openTransactionModal = useCallback(
    (transaction, transformedData) => {
      setSelectedTransaction(transaction)
      setTransformedInfo(transformedData)
    },
    [selectedTransaction]
  )

  const openTransactionDetails = (
    transaction: TransactionContent,
    transformedData: TransformedInfo,
    index?: number
  ) => {
    enableSidePanelForWalletTransactions
      ? onOpenSidePanelSummary(index)
      : openTransactionModal(transaction, transformedData)
  }

  const walletTransactionsData = (): TableRow[] => {
    return transactions?.map((transaction, transactionIndex) => {
      const isPaymentIn = transaction?.direction === paymentDirections.IN && transaction?.amount > 0
      const transformedData = transformSearchResultsByPaymentType(transaction)
      const latePayment = transaction?.type === TransactionType.LATE_PAYMENT

      return {
        rowData: [
          {
            cellData: isSearching ? (
              <TableDataSkeleton skeletalIcon={false} />
            ) : (
              <OnClickProvider
                id={transaction?.hash}
                onClick={() => onOpenSidePanelSummary(transactionIndex)}
              >
                <Box>
                  <Text size="sm">{dayjs(transaction?.dateCreated).format('DD MMM YYYY')}</Text>
                  <Text size="sm" color="grey">
                    {dayjs(transaction?.dateCreated).format('HH:mm:ss')}
                  </Text>
                </Box>
              </OnClickProvider>
            ),
            className: `p-4 px-4`,
          },
          {
            cellData: isSearching ? (
              <TableDataSkeleton skeletalIcon={false} />
            ) : (
              startCase(transaction?.description)
            ),
          },
          {
            cellData: isSearching ? (
              <TableDataSkeleton />
            ) : (
              <Box flex>
                {transformedData.fromCurrency ? (
                  <ImageIcon
                    width={24}
                    height={24}
                    src={`${
                      process.env.ICONS_URL
                    }${transformedData.fromCurrency?.toLowerCase()}.svg`}
                  />
                ) : (
                  '-'
                )}
                <Text tag="span" size="sm" className="ml-2">
                  {startCase(transformedData.fromCurrency)}
                </Text>
              </Box>
            ),
          },
          {
            cellData: isSearching ? (
              <TableDataSkeleton />
            ) : (
              <Box flex>
                {transformedData.toCurrency ? (
                  <ImageIcon
                    width={24}
                    height={24}
                    src={`${process.env.ICONS_URL}${transformedData.toCurrency?.toLowerCase()}.svg`}
                  />
                ) : (
                  '-'
                )}
                <Text tag="span" size="sm" className="ml-2">
                  {startCase(transformedData.toCurrency)}
                </Text>
              </Box>
            ),
            className: rowHeaderWidth,
          },
          {
            cellData: isSearching ? (
              <TableDataSkeleton skeletalIcon={false} />
            ) : isPaymentIn ? (
              <NotificationCard
                state={NotificationState.GREEN}
                label={`+ ${transformedData.amount}`}
                showBorder={false}
              />
            ) : (
              transformedData.amount
            ),
            align: 'end',
          },

          {
            cellData: isSearching ? (
              <TableDataSkeleton skeletalIcon={false} />
            ) : (
              <>
                <StatusPill
                  state={getStatusState(transaction?.status?.toUpperCase())}
                  label={titleCase(transaction?.status)}
                  fitContent
                />
                {latePayment && (
                  <Text tag="span" className="ml-2">
                    <StatusPill state="info" label={t('latePayment')} fitContent />
                  </Text>
                )}
              </>
            ),
            className: rowHeaderWidth,
          },
        ],
        onClick: () => openTransactionDetails(transaction, transformedData, transactionIndex),
      }
    })
  }

  return (
    <>
      <SimpleTableV2
        columns={[
          {
            headerTitle: t('date'),
            className: `p-4 px-4`,
          },
          {
            headerTitle: t('transactionType'),
          },
          {
            headerTitle: t('from'),
          },
          {
            headerTitle: t('to'),
            className: `px-8`,
          },
          {
            headerTitle: t('amount'),
            align: 'end',
          },
          {
            headerTitle: t('status'),
            className: rowHeaderWidth,
          },
        ]}
        tableData={walletTransactionsData()}
      />
      <div className="mt-6">
        {Boolean(selectedTransaction) && (
          <SearchTransactionModal
            transaction={selectedTransaction}
            transformedInfo={transformedInfo}
            onClose={handleModalClose}
          />
        )}
      </div>
    </>
  )
}
