import { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { useLocation, useNavigate } from 'react-router-dom'
import { rootInitialState } from 'components/FilterBar/context/reducer'
import useFilterFromTo from 'components/FilterBar/hooks/useFilterFromTo'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import useFilterSearch from 'components/FilterBar/hooks/useFilterSearch'
import useFilterStatus from 'components/FilterBar/hooks/useFilterStatus'
import {
  dateStringIsValid,
  merchantIdIsValid,
  setValidFilterList,
  statusIsValid,
} from 'components/FilterBar/validation'
import { sanitizeUserInput } from 'utils/utils'

const useFilterParams = () => {
  const { search: currentSearchParams, pathname } = useLocation()
  const navigate = useNavigate()

  const { from, to } = useFilterFromTo()
  const { status } = useFilterStatus()
  const { merchant } = useFilterMerchant()
  const { search } = useFilterSearch()

  const memoParams = useMemo(() => new URLSearchParams(currentSearchParams), [currentSearchParams])
  const params = Object.fromEntries(memoParams)

  useEffect(() => {
    const input = {
      search: sanitizeUserInput(search),
      from: dayjs(from).format('YYYY-MM-DD'),
      to:
        (dayjs(to).isValid() && dayjs(to).format('YYYY-MM-DD')) ||
        dayjs(rootInitialState?.to).format('YYYY-MM-DD'),
      status: sanitizeUserInput(status),
      merchant: sanitizeUserInput(merchant),
    }
    const newParams = new URLSearchParams({ ...input })

    navigate(pathname, { state: { search: newParams?.toString() }, replace: true })
  }, [search, from, to, merchant, status, isEmpty(params)])

  const sanitizedParams = {
    search: (params?.search && sanitizeUserInput(params?.search)) || search,
    from:
      (dateStringIsValid(params?.from) && dayjs(params?.from).format('YYYY-MM-DD')) ||
      dayjs(from).format('YYYY-MM-DD'),
    to:
      (dateStringIsValid(params?.to) && dayjs(params?.to).format('YYYY-MM-DD')) ||
      dayjs(to).format('YYYY-MM-DD'),
    status: (statusIsValid(params?.status) && sanitizeUserInput(params?.status)) || status,
    merchant:
      (merchantIdIsValid(params?.merchant) && sanitizeUserInput(params?.merchant)) || merchant,
  }

  const validFilterValues = {
    search: sanitizedParams?.search,
    from:
      (dateStringIsValid(params?.from) && params?.from) ||
      dayjs(rootInitialState?.from).format('YYYY-MM-DD'),
    to:
      (dateStringIsValid(params?.to) && params?.to) ||
      dayjs(rootInitialState?.to).format('YYYY-MM-DD'),
    status: sanitizedParams?.status,
    merchant: sanitizedParams?.merchant,
  }

  return {
    ...sanitizedParams,
    filterValues: validFilterValues,
    filterCount: setValidFilterList(validFilterValues),
  }
}

export default useFilterParams
