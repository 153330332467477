import React, { useEffect, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Box as Container,
  Box as CustomerContainter,
  Box as CustomerInfoContainer,
} from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { capitalize } from '@node-space/utils'
import { useCustomerQuery } from 'hooks/queries/Customers/useCustomerQuery'
import { BusinessCustomerType, IndividualCustomerType } from 'types/customers'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { currentCustomerAtom } from './atoms/customersAtoms'
import CustomerInfo from './components/CustomerInfo'
import { CustomerDetails } from './types'
import { isBusinessCustomer } from './utils'

export const Customer = () => {
  const { t } = useTranslation()
  const { id }: { id?: number } = useParams()
  const setCurrentCustomerAtom = useSetAtom(currentCustomerAtom)
  const currentCustomer = useAtomValue(currentCustomerAtom)
  const defaultCustomerDetails: CustomerDetails = {
    type: '',
    registrationNumber: '',
    taxResidence: '',
    address: '',
    name: '',
    representativeName: '',
  }

  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>(defaultCustomerDetails)
  const { data: customer, error, isPending } = useCustomerQuery(id?.toString())

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CUSTOMER, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  useEffect(() => {
    if (customer && isBusinessCustomer(customer)) {
      const details: CustomerDetails = {
        type: customer?.type ? capitalize(customer?.type?.toLowerCase()) : '',
        registrationNumber: customer?.company?.businessRegistrationNumber,
        taxResidence: customer?.company?.taxResidenceCountryCode,
        address: [
          customer?.company?.address?.address1,
          customer?.company?.address?.address2,
          customer?.company?.address?.city,
          customer?.company?.address?.country,
        ]
          ?.filter(Boolean)
          ?.join(', '),
        name: customer?.company?.name,
        representativeName: `${customer?.company?.representative?.firstName} ${customer?.company?.representative?.lastName}`,
      }
      setCustomerDetails(details)
    }

    return () => {
      setCurrentCustomerAtom({} as BusinessCustomerType | IndividualCustomerType)
    }
  }, [customer])

  useEffect(() => {
    setCurrentCustomerAtom(customer)
  }, [customer])

  return (
    <>
      <PageHeader
        description={
          <Text size="sm" color="grey">
            {t('customers.customerDescription', { customerName: customerDetails?.name })}
          </Text>
        }
      />
      <Container flex className="md:flex-row" direction="col" gapX={24} gapY={24} width="full">
        <CustomerContainter flex direction="col" width="full" className="md:w-2/3 break-words">
          <StatusCard
            cardButton={{
              onClick: () => {},
              name: t('customers.addWallet'),
              iconElement: <Icon name="PlusIcon" size="default" color="white" />,
            }}
            description={t('customers.customerWalletDescription', {
              customerName: customerDetails?.name,
            })}
            headline={t('customers.noCustomerWalletHeadline', {
              customerName: customerDetails?.name,
            })}
          />
        </CustomerContainter>
        {customerDetails && (
          <CustomerInfoContainer flex className="md:w-1/3 xxs:flex-row " direction="col">
            <CustomerInfo customerDetails={customerDetails} isLoading={isPending} />
          </CustomerInfoContainer>
        )}
      </Container>
    </>
  )
}
