import { URLType, EnvironmentTypes } from 'types/types'
import { isLocal, isStaging, isSandbox, isProduction } from 'utils/environment'

export const getPayLinkUrl = (): URLType => {
  const bankUrlList = {
    PRODUCTION: 'https://pay.bvnk.com',
    SANDBOX: 'https://pay.sandbox.bvnk.com',
    STAGING: 'https://pay.staging.bvnk.com',
    default: 'https://pay.bvnk.com',
  }

  const environment =
    (isLocal && EnvironmentTypes.STAGING) ||
    (isStaging && EnvironmentTypes.STAGING) ||
    (isSandbox && EnvironmentTypes.SANDBOX) ||
    (isProduction && EnvironmentTypes.PRODUCTION) ||
    EnvironmentTypes.STAGING

  return bankUrlList[environment] || bankUrlList['default']
}

export const paymentLinkBuilder = ({ redirectUrl, uuid = '', paymentType = '' }) => {
  if (redirectUrl) {
    return redirectUrl
  }

  const inOrOut = paymentType === 'in' ? 'in' : paymentType === 'out' && 'out'

  if (!uuid || !paymentType || !inOrOut) {
    return ''
  }

  return `${getPayLinkUrl()}/pay${inOrOut}?uuid=${uuid}`
}
