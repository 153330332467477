import React from 'react'
import { DepositBankAccount } from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import { Currencies, CurrencyLimits, WalletType } from 'types/types'
import LocalDetails from './LocalDetails'
import SwiftDetails from './SwiftDetails'

interface SingleDepositDetailsProps {
  account: DepositBankAccount
  wallet: WalletType
  walletLimits: CurrencyLimits
  copiedValue: string
  setCopiedValue: React.Dispatch<React.SetStateAction<string>>
  handleSetCopiedTitle?: React.Dispatch<React.SetStateAction<string>>
}

const SingleDepositDetails = ({
  account,
  wallet,
  walletLimits,
  copiedValue,
  setCopiedValue,
  handleSetCopiedTitle,
}: SingleDepositDetailsProps) => {
  return (
    <>
      {account?.isSwift || account?.accountNumberFormat === BankTransferType.SWIFT ? (
        <SwiftDetails
          currency={wallet?.currency?.code as Currencies}
          options={account}
          copiedValue={copiedValue}
          setCopiedValue={setCopiedValue}
          setCopiedTitle={handleSetCopiedTitle}
          depositLimits={walletLimits}
        />
      ) : (
        <LocalDetails
          currency={wallet?.currency?.code as Currencies}
          options={account}
          wallet={wallet}
          isSegregated
          copiedValue={copiedValue}
          setCopiedValue={setCopiedValue}
          setCopiedTitle={handleSetCopiedTitle}
          depositLimits={walletLimits}
        />
      )}
    </>
  )
}

export default SingleDepositDetails
