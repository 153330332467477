import React from 'react'
import i18next from 'i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import CopyTransactionRow from './CopyTransactionRow'
import { TransactionDetailRow } from '../TransactionDetailRow'
import { TransactionType } from 'types/transaction/TransactionType'

interface CommonFieldsProps {
  transactionHash: string
  transactionDate: string
  transactionUUID: string
}

const CommonFields = ({ transactionHash, transactionDate, transactionUUID }: CommonFieldsProps) => {
  return (
    <>
      {transactionUUID && (
        <CopyTransactionRow
          content={transactionUUID}
          label={i18next.t('transactions.sideBar.uuid')}
        />
      )}

      {transactionHash && (
        <Box>
          <CopyTransactionRow content={transactionHash} label={i18next.t('hash')}>
            {transactionHash.includes(TransactionType.OFF_CHAIN) && (
              <Quicklink
                to={process.env.OFF_CHAIN_INFO_LINK}
                iconElement={<Icon name="InfoCircleIcon" color="primary-500" />}
                text={''}
              />
            )}
          </CopyTransactionRow>
        </Box>
      )}
      {transactionDate && (
        <TransactionDetailRow label={i18next.t('date')} detail={transactionDate} />
      )}
    </>
  )
}

export default CommonFields
