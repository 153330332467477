import React from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'

interface MassPayoutBatchInformationProps {
  batchInformation: {
    batchName: string
    totalAmount?: string
    totalAmountCurrency?: string
    totalFee?: string
    totalFeeCurrency?: string
    walletName?: string
    walletNameCurrency?: string
  }
  isLoading: boolean
}

export const MassPayoutBatchInformation = ({
  batchInformation,
  isLoading,
}: MassPayoutBatchInformationProps) => {
  const { t } = useTranslation()
  const batchDetails = [
    {
      label: t('massPayouts.batchId'),
      value: batchInformation?.batchName,
    },
    {
      ...(batchInformation?.totalAmount && {
        label: t('massPayouts.totalProcessedAmount'),
        value: `${batchInformation?.totalAmount} ${batchInformation?.totalAmountCurrency}`,
      }),
    },
    {
      ...(batchInformation?.totalFee !== undefined && {
        label: t('massPayouts.totalBatchFees'),
        value: `${batchInformation?.totalFee} ${batchInformation?.totalFeeCurrency}`,
      }),
    },
    {
      ...(batchInformation?.walletName && {
        label: t('massPayouts.payingFromWallet'),
        value: `${batchInformation?.walletName} (${batchInformation?.walletNameCurrency})`,
      }),
    },
  ]

  return (
    <>
      {isLoading ? (
        <Skeleton className="w-full" variant="input" />
      ) : (
        <Box flex className="w-full">
          {batchDetails?.map((item, index) => (
            <Box key={index} padding={4} alignItems="start" className="w-full">
              <Text size="sm">{item?.label}</Text>
              <Text size="sm" color="grey">
                {item?.value}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
