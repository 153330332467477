import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCustomer } from 'services/CustomerService'

export const useCustomerQuery = (id: string) => {
  return useQuery({
    queryKey: reactQueryKeys.customer(id),
    queryFn: () => getCustomer(id),
    enabled: !!id,
  })
}
