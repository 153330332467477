import React, { createContext } from 'react'
import { Transaction } from 'types/transaction'

interface TransactionsContextProviderProps {
  children: React.ReactNode
  transactions: Transaction[]
}

export const TransactionsPaginatedContext = createContext<Transaction[]>([])

export const TransactionsContextProvider = ({
  children,
  transactions,
}: TransactionsContextProviderProps) => {
  return (
    <TransactionsPaginatedContext.Provider value={transactions}>
      {children}
    </TransactionsPaginatedContext.Provider>
  )
}
