import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  Box as Row,
  Box as Section,
  Button,
  SmallTabs,
} from '@node-space/storybook-components'
import { useFeatureFlags } from '@node-space/hooks'
import { useToastContext } from 'hooks/useToastContext'
import { copyToClipboard } from 'utils/utils'
import { PaymentsResponse } from 'types/payments'
import { paymentLinkBuilder } from 'utils/paymentLinkBuilder'
import { PaymentTimeline } from './Timeline/PaymentTimeline'
import PaymentDetails from './PaymentDetails'
import PaymentUnderpaid from './PaymentUnderpaid'
import { secondaryButtonIconColour } from 'styles/theme/colours'

interface PaymentRowExpandedProps {
  transaction: PaymentsResponse
}

const PaymentRowExpanded = ({ transaction }: PaymentRowExpandedProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()
  const { enableMarkPaymentButton } = useFeatureFlags()

  const PAYMENT_LINK =
    paymentLinkBuilder({
      redirectUrl: transaction.redirectUrl,
      uuid: transaction?.uuid,
      paymentType: transaction?.type?.toLowerCase(),
    }) || ''

  return (
    <Section tag="section" full className="border-1 p-6 bg-white">
      <SmallTabs
        tabs={[
          {
            id: '1',
            tabHeader: 'Updates',
            tabContent: (
              <div className="pt-4">
                <PaymentTimeline transaction={transaction} />
              </div>
            ),
            testid: 'payment-row-updates-tab',
          },
          {
            id: '2',
            tabHeader: 'Details',
            tabContent: (
              <div className="pt-4">
                <PaymentDetails transaction={transaction as unknown as PaymentsResponse} />
              </div>
            ),
            testid: 'payment-row-details-tab',
          },
        ]}
      />

      <Section className="mt-4 border-t">
        <Row flex flexRow className="mt-4">
          <Button
            noStyling
            className={`mr-8 text-primary-500 hover:text-primary-700 active:text-primary-900`}
            iconElement={<Icon name="ExternalLinkIcon" color={secondaryButtonIconColour} />}
            onClick={() => {
              if (PAYMENT_LINK) {
                window.open(PAYMENT_LINK)
              }
            }}
          >
            {t('openPaymentLink')}
          </Button>
          <Button
            noStyling
            className={`mr-8 text-primary-500 hover:text-primary-700 active:text-primary-900`}
            iconElement={<Icon name="CopyIcon" color={secondaryButtonIconColour} />}
            onClick={() => {
              if (PAYMENT_LINK) {
                copyToClipboard(PAYMENT_LINK)
                addToast({ title: `${t('copiedToClipboard')}`, state: 'success' })
              }
            }}
          >
            {t('copyPaymentLink')}
          </Button>
          {enableMarkPaymentButton && <PaymentUnderpaid transaction={transaction} />}
        </Row>
      </Section>
    </Section>
  )
}

export default React.memo(PaymentRowExpanded)
