import React, { createContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDepositPaymentReference from 'pages/Wallets/components/Deposit/hooks/useDepositPaymentReference'
import {
  AggregatedPaymentMethod,
  BankAccountsDetails,
  SegregatedPaymentMethod,
} from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import { getSwiftBankDetails } from 'utils/wallet'

interface DepositAccountsContextV2ProviderProps {
  children: React.ReactNode
  depositAccounts: BankAccountsDetails
}

export interface Details {
  [key: string]: string
}

interface DepositAccountsV2Context {
  depositAccounts: BankAccountsDetails
  selectedAccount: AggregatedPaymentMethod | SegregatedPaymentMethod
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<AggregatedPaymentMethod | SegregatedPaymentMethod>
  >
  isAggregatedWallet: boolean
  details: Details
  swiftDetails?: {
    isSwift?: boolean
    swiftReference?: string
  }
}

export const DepositAccountsV2Context = createContext<DepositAccountsV2Context>({
  depositAccounts: {
    isAggregatedWallet: false,
    aggregatedPaymentMethods: [],
    segregatedPaymentMethod: null,
  },
  selectedAccount: {} as AggregatedPaymentMethod | SegregatedPaymentMethod,
  setSelectedAccount: () => null,
  details: {} as Details,
  isAggregatedWallet: false,
})

export const DepositAccountsContextV2Provider = ({
  children,
  depositAccounts,
}: DepositAccountsContextV2ProviderProps) => {
  const [selectedAccount, setSelectedAccount] = useState<
    AggregatedPaymentMethod | SegregatedPaymentMethod
  >(
    depositAccounts.isAggregatedWallet
      ? depositAccounts.aggregatedPaymentMethods[0]
      : depositAccounts.segregatedPaymentMethod
  )
  const { t } = useTranslation()

  const details = useMemo<Details>(() => {
    const options = selectedAccount?.options

    if (options?.type === BankTransferType.SWIFT) {
      const { beneficiaryBankDetails, beneficiaryDetails } = getSwiftBankDetails(options, t, false)
      return { ...beneficiaryDetails, ...beneficiaryBankDetails }
    } else {
      const details = {
        EUR: {
          ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
          ...(options?.iban && { IBAN: options.iban }),
          ...(options?.bic && { BIC: options.bic }),
        },
        ZAR: {
          ...(options?.accountHolderName && { [t('accountName')]: options.accountHolderName }),
          ...(options?.accountNumber && { [t('accountNumber')]: options.accountNumber }),
          ...(options?.routingNumber && { [t('branchCode')]: options.routingNumber }),
          ...(options?.bankName && { [t('bankName')]: options.bankName }),
          ...(options?.accountType && { [t('accountType')]: options.accountType }),
        },
        GBP: {
          ...(options?.accountHolderName && {
            [t('accountHolderName')]: options.accountHolderName,
          }),
          ...(options?.ukSortCode && { [t('sortCode')]: options.ukSortCode }),
          ...(options?.ukAccountNumber && { [t('accountNumber')]: options.ukAccountNumber }),
        },
        NGN: {
          ...(options?.accountHolderName && {
            [t('accountHolderName')]: options.accountHolderName,
          }),
          ...(options?.accountHolderName && { [t('accountNumber')]: options.accountNumber }),
          ...(options?.bankName && { [t('bankName')]: options.bankName }),
        },
      }
      return details[selectedAccount?.currency] || ({} as Details)
    }
  }, [selectedAccount])

  const swiftDetails = useMemo(() => {
    const { options } = selectedAccount || {}
    const paymentReference = useDepositPaymentReference(options)
    return {
      isSwift: options?.type === BankTransferType.SWIFT,
      swiftReference: paymentReference,
    }
  }, [selectedAccount])

  const value = useMemo(() => {
    return {
      selectedAccount,
      setSelectedAccount,
      depositAccounts,
      details,
      swiftDetails,
      isAggregatedWallet: depositAccounts.isAggregatedWallet,
    }
  }, [
    selectedAccount,
    setSelectedAccount,
    depositAccounts,
    details,
    swiftDetails,
    depositAccounts.isAggregatedWallet,
  ])

  return (
    <DepositAccountsV2Context.Provider value={value}>{children}</DepositAccountsV2Context.Provider>
  )
}
