import React from 'react'
import { capitalize } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ReportSchedule, ReportScheduleFrequency } from 'types/walletTransactionsExport'
import { getDayOfWeek } from 'utils/walletTransactionsExport'

interface ReportScheduleDescriptionProps {
  reportSchedule: ReportSchedule
}

export const ReportScheduleDescription = ({ reportSchedule }: ReportScheduleDescriptionProps) => {
  const { t } = useTranslation()

  const dailyDescription = `${capitalize(reportSchedule?.frequencyType?.toLowerCase())} ${t('exports.at')} ${reportSchedule?.time} ${reportSchedule?.timezone}, ${reportSchedule?.format?.toUpperCase()}`
  const weeklyDescription = `${capitalize(reportSchedule?.frequencyType?.toLowerCase())} ${t('exports.on')} ${getDayOfWeek(reportSchedule?.frequencyDay)}  ${t('exports.at')} ${reportSchedule?.time} ${reportSchedule?.timezone}, ${reportSchedule?.format?.toUpperCase()}`
  const monthlyDescription = `${t('exports.firstOfMonth')} ${t('exports.at')} ${reportSchedule?.time} ${reportSchedule?.timezone}, ${reportSchedule?.format?.toUpperCase()}`

  return (
    <Text size="sm" weight="normal" color="grey">
      {reportSchedule?.frequencyType === ReportScheduleFrequency.DAILY && dailyDescription}
      {reportSchedule?.frequencyType === ReportScheduleFrequency.WEEKLY && weeklyDescription}
      {reportSchedule?.frequencyType === ReportScheduleFrequency.MONTHLY && monthlyDescription}
    </Text>
  )
}
