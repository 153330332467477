import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getWallets } from 'services/WalletsService'
import { TEN_MINUTES } from 'utils/utils'

export const useWalletsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.wallets(),
    queryFn: getWallets,
    staleTime: TEN_MINUTES,
  })
}
