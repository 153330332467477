import React from 'react'
import i18next from 'i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { PaymentMetadata } from 'types/transaction'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

interface PaymentOutProps {
  amountWithCurrency: string
  paidActualAmountWithCurrency: string
  transactionReference: string
  merchantId: string
  uuid: string
  hash: string
  date: string
  sourceAddress: string
  paymentMetadata?: PaymentMetadata
  currencyFrom?: string
  currencyTo?: string
}

const PaymentOut = ({
  amountWithCurrency,
  paidActualAmountWithCurrency,
  transactionReference,
  merchantId,
  uuid,
  hash,
  date,
  sourceAddress,
  paymentMetadata,
  currencyFrom,
  currencyTo,
}: PaymentOutProps) => {
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.paymentOutAmountPaid')}
          detail={amountWithCurrency}
        />
      )}
      {paidActualAmountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.paymentOutAmountReceived')}
          detail={paidActualAmountWithCurrency}
        />
      )}
      {merchantId && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.merchantId')}
          detail={merchantId}
        />
      )}
      {transactionReference && (
        <CopyTransactionRow
          content={transactionReference}
          label={i18next.t('transactions.sideBar.reference')}
        />
      )}
      {paymentMetadata?.exchangeRate && (
        <TransactionDetailRow
          label={i18next.t('wallets.searchTransaction.exchangeRate')}
          detail={`1 ${currencyFrom} = ${paymentMetadata?.exchangeRate} ${currencyTo}`}
        />
      )}
      {uuid && (
        <TransactionDetailRow label={i18next.t('transactions.sideBar.uuid')} detail={uuid} />
      )}
      {hash && <CopyTransactionRow content={hash} label={i18next.t('hash')} />}
      {date && <TransactionDetailRow label={i18next.t('date')} detail={date} />}
      {sourceAddress && (
        <>
          <Text size="base" weight="medium" color="dark-grey" className="mt-3">
            {i18next.t('transactions.sideBar.beneficiaryDetails')}
          </Text>
          <CopyTransactionRow
            content={sourceAddress}
            label={i18next.t('transactions.sideBar.beneficiaryAddress')}
          />
        </>
      )}
    </>
  )
}

export default PaymentOut
