import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@node-space/storybook-components/dist/Icon'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { NetworkConnectionViewTypes } from 'constants/bvnkNetwork'
import { NetworkConnectionViewType } from 'types/bvnkNetwork'
import { DiscoverNetworkMembers } from './DiscoverNetworkMembers'

interface ConnectionsEmptyStateProps {
  networkConnectionViewType: NetworkConnectionViewType
}
const ConnectionsEmptyState = ({ networkConnectionViewType }: ConnectionsEmptyStateProps) => {
  const { t } = useTranslation()
  const [isDiscoverMembersModalOpen, setIsDiscoverMembersModalOpen] = useState(false)

  const handleDiscoverMembersModalClose = () => {
    setIsDiscoverMembersModalOpen(false)
  }

  const onDiscoverMemberClickHandler = () => {
    setIsDiscoverMembersModalOpen(true)
  }

  switch (networkConnectionViewType) {
    case NetworkConnectionViewTypes.CONNECTIONS_VIEW:
      return (
        <>
          <StatusCard
            headline={t('bvnkNetwork.welcomeMessage')}
            description={t('bvnkNetwork.welcomeMessageDescription')}
            cardButton={{
              onClick: onDiscoverMemberClickHandler,
              name: t('bvnkNetwork.discoverMembers'),
              iconElement: <Icon name="SearchIcon" size="default" color="white" />,
            }}
          />
          <DiscoverNetworkMembers
            isModalOpen={isDiscoverMembersModalOpen}
            handleModalClose={handleDiscoverMembersModalClose}
          />
        </>
      )
    case NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW:
      return (
        <StatusCard
          headline={t('bvnkNetwork.receivedInvitationEmptyStateHeader')}
          description={t('bvnkNetwork.receivedInvitationEmptyStateDescription')}
        />
      )
    case NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW:
      return (
        <StatusCard
          headline={t('bvnkNetwork.sentInvitationEmptyStateHeader')}
          description={t('bvnkNetwork.sentInvitationEmptyStateDescription')}
        />
      )
    default:
      return null
  }
}

export default ConnectionsEmptyState
