import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Icon, IconLabel } from '@node-space/storybook-components'
import ChangePasswordModal from 'components/modals/ChangePasswordModal'
import TwoFAModal from 'components/modals/TwoFAModal/TwoFAModal'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useToastContext } from 'hooks/useToastContext'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import Section from './components/Section/Section'

const Security = () => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const { twoFactorEnabled, setShowTwoFAModal } = useTwoFAContext()
  const [showChangePassword, setShowChangePassword] = useState(false)
  const permissions = useMappedRoles()?.profile

  const canEdit = permissions?.canEdit

  const cards = [
    // Password
    {
      title: t('password'),
      children: t('passwordSuggest'),
      button: canEdit && {
        testId: 'change-password-modal-button',
        label: t('change'),
        icon: <Icon name="EditIcon" color={secondaryButtonIconColour} />,
        onClick: () => setShowChangePassword(true),
      },
    },
    // Two-factor authentication
    {
      title: t('twoFactorAuthentication'),
      children: (
        <>
          {twoFactorEnabled ? (
            <IconLabel
              label={t('enabled')}
              icon={<Icon name="CheckIcon" color={secondaryButtonIconColour} />}
              state="success"
            />
          ) : (
            <IconLabel
              label={t('disabled')}
              icon={<Icon name="CloseIcon" color={secondaryButtonIconColour} />}
              state="warning"
            />
          )}
          <div className="mt-4">{t('2FASuggest')}</div>
        </>
      ),
      /* Disable functionality not currently in scope */
      button: canEdit &&
        !twoFactorEnabled && {
          testId: 'enable-2FA-modal-button',
          label: t('enable'),
          icon: <Icon name="PlusIcon" color={secondaryButtonIconColour} />,
          onClick: () => setShowTwoFAModal(true),
        },
    },
  ]

  return (
    <>
      <Section title={t('security')} cards={cards} />
      <ChangePasswordModal
        isOpen={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      />
      <TwoFAModal onComplete={() => addToast({ title: t('2FASuccess'), state: 'success' })} />
    </>
  )
}

export default Security
