import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { logError } from '@node-space/utils'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import Button from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { PaymentCaseDocument as PaymentCaseDocumentType } from 'types/payments'
import { usePaymentCaseDocumentQuery } from 'hooks/queries/usePaymentCaseDocumentQuery'
import { DateFormat } from 'constants/General'
import Loader from '@node-space/storybook-components/dist/Loader'

interface PaymentCaseDocumentProps {
  paymentCaseDocument: PaymentCaseDocumentType
  testId?: string
}

const dateFormat = DateFormat.HH_MM_DD_MMM_YYYY

export const PaymentCaseDocument = ({ paymentCaseDocument, testId }: PaymentCaseDocumentProps) => {
  const {
    data: documentData,
    isFetching: isFetchingDocument,
    refetch,
  } = usePaymentCaseDocumentQuery(paymentCaseDocument?.id)

  useEffect(() => {
    if (!documentData) return

    try {
      const url = window.URL.createObjectURL(new Blob([documentData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${paymentCaseDocument?.id}_${paymentCaseDocument?.title}.${paymentCaseDocument?.fileExtension}`
      )
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      logError(`Error: case - downloadDocument`, error)
    }
  }, [documentData])

  return (
    <Box>
      <Box flex gapX={4} gapY={4} justifyContent="between" className="flex-col-reverse sm:flex-row">
        {isFetchingDocument ? (
          <Box flex flexItemsCenter>
            <Loader size="small" />
          </Box>
        ) : (
          <Box testid={testId} gapX={4}>
            <Button
              iconElement={<Icon name="DownloadIcon" color="primary-500" size="sm" />}
              noStyling
              onClick={() => refetch()}
            >
              <Text className="hover:underline" size="sm" color="primary-500">
                {paymentCaseDocument?.pathOnClient}
              </Text>
            </Button>
          </Box>
        )}
        <Box>
          <Text size="xs" color="text-500" className="h-full flex items-center sm:pb-0.5">
            {dayjs(paymentCaseDocument?.createdDate).format(dateFormat)}
          </Text>
        </Box>
      </Box>
      <Box width="full">
        <Text size="xs" color="text-500">
          {paymentCaseDocument?.title}
        </Text>
      </Box>
    </Box>
  )
}
