import { Paginator } from '@node-space/storybook-components'
import { pageListOptions } from '../constants/contants'
import React from 'react'
import { Paging } from 'types/types'

interface PaginationProps {
  pagination: Paging
  setOffset: React.Dispatch<React.SetStateAction<number>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  nrOfRows: string
  setNrOfRows: React.Dispatch<React.SetStateAction<string>>
}

export const Pagination = ({
  pagination,
  setOffset,
  page,
  setPage,
  nrOfRows,
  setNrOfRows,
}: PaginationProps) => {
  const pagingMax = pagination.max
  const total = pagination.total
  const maxPage = (!!total && Math.ceil(total / pagingMax) - 1) || 0

  const handleOnClickForward = async () => {
    if (page < maxPage) {
      const newPage = page + 1
      setPage(newPage)
      const offset = newPage * pagingMax
      setOffset(offset)
    }
  }
  const handleOnClickBack = () => {
    if (page > 0) {
      const newPage = page - 1
      setPage(newPage)
      const offset = newPage * pagingMax
      setOffset(offset)
    }
  }

  const handleDropDownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNrOfRows(e.target.value)
    setPage(0)
    setOffset(0)
  }
  return (
    <Paginator
      data-testid="pagination-group-container"
      totalPages={total}
      range={{
        end: Math.min(page * pagingMax + pagingMax, total),
        start: page * pagingMax + 1 || 1,
      }}
      onClickBack={handleOnClickBack}
      onClickForward={handleOnClickForward}
      results={pageListOptions}
      onChangeDropDown={handleDropDownChange}
      resultValue={nrOfRows}
    />
  )
}
