import React, { Dispatch, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'
import { Logo } from '../logos/Logo'

interface SidebarHeaderProps {
  toggleCallback: Dispatch<SetStateAction<boolean>>
  sidebarOpen: boolean
}

const SidebarHeader = ({ toggleCallback, sidebarOpen }: SidebarHeaderProps) => {
  return (
    <div className="flex">
      <button
        className="lg:hidden text-gray-500 hover:text-gray-400"
        onClick={() => toggleCallback(!sidebarOpen)}
        aria-controls="sidebar"
        aria-expanded={sidebarOpen}
      />
      <div className="items-center justify-center w-full py-3 pr-4 pl-8">
        <NavLink to="/" className="relative mb-0 h-10 inline-flex items-center justify-center">
          <Logo />
        </NavLink>
      </div>
    </div>
  )
}

export default SidebarHeader
