import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { ConvertFormType, ConvertSteps } from '../components/Convert/Convert'

const convertSchema = ({
  t,
  step,
}: {
  t: TFunction<'translation', undefined>
  step: ConvertSteps
}) => {
  return yup.object<Partial<Record<keyof ConvertFormType, yup.AnySchema>>>({})
}

export default convertSchema
