import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RestApi } from '@node-space/rest-api'
import General from 'constants/General'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { WithdrawSteps } from 'types/wallets'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import { WithdrawForm } from '../components/Withdraw/Withdraw'
import withdrawSchema from '../schemas/withdrawSchema'
import useWalletActionApi from './useWalletActionApi'

const useWithdrawAction = (sharedActionProps: WalletActionSharedProps) => {
  const { profile } = useProfileContext()
  const { t } = useTranslation()

  const ignoreWithdraw2faWarning = RestApi.getCookie(General.IGNORE_2FA_COOKIE_NAME)

  const intialWithdrawStep =
    profile?.twoFactorEnabled || ignoreWithdraw2faWarning
      ? WithdrawSteps.FORM
      : WithdrawSteps.AUTH_2FA
  const [withdrawStep, setWithdrawStep] = useState<WithdrawSteps>(intialWithdrawStep)

  const withdrawActionApi = useWalletActionApi<WithdrawForm, WithdrawSteps>({
    intialStep: intialWithdrawStep,
    step: withdrawStep,
    setStep: setWithdrawStep,
    formSchema: withdrawSchema({ t, step: withdrawStep }),
    ...sharedActionProps,
  })

  return withdrawActionApi
}

export default useWithdrawAction
