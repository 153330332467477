import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text, TextColor } from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import {
  usePayoutsV2PostAuthMutation,
  usePayoutsV2PreAuthMutation,
} from 'hooks/mutations/usePayoutsV2Mutation'
import { useWithdrawMutation } from 'hooks/mutations/useWithdrawMutation'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { BaseErrorResponse } from 'types/beneficiaries'
import { PayoutsV2PostAuthRequest, PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { roundNumberWithCommas } from 'utils/utils'
import { WithdrawForm } from './Withdraw'
import { WithdrawActionV2Props, WithdrawV2Form } from './WithdrawV2'

const WithdrawConfirmV2 = ({
  dismissAction,
  setStep,
  form,
  wallets,
  beneficiaries,
  withdrawalFee,
  registerFormField,
}: WithdrawActionV2Props) => {
  const { t } = useTranslation()
  const { twoFactorEnabled } = useTwoFAContext()
  const queryClient = useQueryClient()
  const { enablePayoutsV2 } = useFeatureFlags()
  const formValues = form.watch()
  const selectedWallet =
    formValues.walletId && wallets.find(wallet => wallet.id.toString() === formValues.walletId)

  const { mutate: withdrawMutation, isPending: isSubmittingWithdrawRequest } = useWithdrawMutation()
  const { mutate: postPayPrerequest, isPending: isPostPayPrerequestLoading } =
    usePayoutsV2PreAuthMutation()
  const { mutate: submitPayPostAuthRequest, isPending: isPayPostAuthRequestLoading } =
    usePayoutsV2PostAuthMutation()

  const [submitBeneficiary, setSubmitBeneficiary] = useState(false)
  const [calloutError, setCalloutError] = useState<string>(null)
  const [authRequest, setAuthRequest] = useState<PayoutsV2PostAuthRequest>(null)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WITHDRAW_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
      beneficary: selectedBeneficiary?.businessName,
      alias: selectedBeneficiary?.alias,
    })
  }, [])

  useEffect(() => {
    const password = formValues?.password

    if (password?.length === TWO_FA_REQUIRED_LENGTH && twoFactorEnabled) {
      onSubmitWithdrawHandler()
    }
  }, [formValues.password])

  useEffect(() => {
    submitBeneficiary && onSubmitPostRequest()
  }, [submitBeneficiary])

  const selectedBeneficiary =
    formValues?.beneficiaryReference &&
    beneficiaries?.find(beneficiary => beneficiary?.reference === formValues?.beneficiaryReference)

  /**
   * Payputs v1
   */
  const withdraw = async () => {
    setCalloutError(null)
    const beneficiaryName = selectedBeneficiary?.businessName
      ? selectedBeneficiary?.businessName
      : `${selectedBeneficiary?.firstName} ${selectedBeneficiary?.lastName}`
    const details = {
      amountRaw: formValues?.amount,
      accountNumber: selectedBeneficiary?.fiat?.accountNumber,
      accountDescription: beneficiaryName,
      withdrawConfirmed: true,
      withdrawCompleted: false,
      withdrawalFee: withdrawalFee?.fee,
      accountId: formValues?.beneficiaryReference,
      amount: formValues?.amount,
      code: selectedWallet?.currency?.code,
      walletId: formValues?.walletId,
      authAttempt: 'init',
      protocol: selectedWallet?.protocol || null,
      paymentReference: formValues?.reference,
    }

    const withdrawRequest = {
      address: JSON.stringify({ beneficiaryReference: formValues.beneficiaryReference }),
      paymentReference: formValues?.reference,
      amount: formValues?.amount,
      authorization: {},
    }

    if (twoFactorEnabled) {
      withdrawRequest.authorization = {
        totp: formValues.password,
      }
    } else {
      withdrawRequest.authorization = {
        password: formValues.password,
      }
    }

    withdrawMutation(
      { walletId: details?.walletId, requestData: withdrawRequest },
      {
        onSuccess: () => {
          setStep('complete')
          queryClient.invalidateQueries({
            queryKey: reactQueryKeys.walletTransactions({
              walletId: selectedWallet?.id,
              offset: 0,
              max: 10,
            }),
          })
        },
        onError: (error: BaseErrorResponse) => {
          if (error?.data?.errorList?.length) {
            error.data.errorList.forEach(err => {
              form.setError(err?.parameter as keyof WithdrawForm, { message: error?.message })
            })
          } else if (error?.status === 403) {
            form.setError('password', {
              message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
            })
          } else {
            setCalloutError(t('withdrawError'))
            logSentryError('Error from WithdrawConfirm - postWithdraw', error, withdrawRequest)
          }
        },
      }
    )
  }

  /**
   * Payouts V2
   */
  const onPreAuthErrorHandler = (error: BaseErrorResponse) => {
    if (error?.status === 428) {
      setAuthRequest({
        challenge: error?.data?.challenge?.value,
        tokenResponse: formValues?.password,
      })
      form?.setValue('challenge', error?.data?.challenge?.value)
      setSubmitBeneficiary(true)
    } else if (error?.data?.errorList?.length) {
      error.data.errorList.forEach(err => {
        form.setError(err?.parameter as keyof WithdrawV2Form, { message: error?.message })
      })
    } else if (error?.status === 403) {
      form.setError('password', {
        message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
      })
    } else {
      setCalloutError(t('withdrawError'))
      logSentryError('Error from WithdrawConfirm - postPayPrerequest', error, {
        amount: formValues?.amount,
        reference: formValues?.reference,
        walletId: formValues?.walletId,
        currencyCode: formValues?.currencyCode,
      })
    }
  }

  const onPostAuthErrorHandler = (error: BaseErrorResponse) => {
    if (error?.status === 403) {
      form.setError('password', {
        message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
      })
    } else if (error?.data?.errorList?.length) {
      error.data.errorList.forEach(err => {
        form.setError(err?.parameter as keyof WithdrawForm, { message: error?.message })
      })
    } else {
      setCalloutError(t('withdrawError'))
      logSentryError('Error from WithdrawConfirm - submitPayPostAuthRequest', error, {
        amount: formValues?.amount,
        reference: formValues?.reference,
        walletId: formValues?.walletId,
        currencyCode: formValues?.currencyCode,
      })
    }

    setSubmitBeneficiary(false)
  }

  const withdrawV2 = async () => {
    setCalloutError(null)
    const payoutsV2PreAuthRequest: PayoutsV2PreAuthRequest = {
      walletId: selectedWallet?.lsid,
      amount: {
        value: Number(formValues?.amount),
        currency: selectedWallet?.currency?.code,
      },
      paymentReference: formValues?.reference,
      beneficiary: {
        reference: formValues?.beneficiaryReference,
      },
    }

    postPayPrerequest(payoutsV2PreAuthRequest, {
      onError: onPreAuthErrorHandler,
    })
  }

  const onSubmitPostRequest = () => {
    const payoutsPostrequest: PayoutsV2PostAuthRequest = {
      challenge: authRequest?.challenge,
      tokenResponse: authRequest?.tokenResponse,
    }

    submitPayPostAuthRequest(payoutsPostrequest, {
      onSuccess: () => {
        setStep('complete')
        queryClient.invalidateQueries({
          queryKey: reactQueryKeys.walletTransactions({
            walletId: selectedWallet?.id,
            offset: 0,
            max: 10,
          }),
        })
      },
      onError: onPostAuthErrorHandler,
      onSettled: () => {
        setSubmitBeneficiary(false)
      },
    })
  }
  const isPayoutsV2Enabled =
    enablePayoutsV2 &&
    !!selectedBeneficiary?.reference &&
    !!selectedWallet?.supportedTransferDestinations?.length

  const onSubmitWithdrawHandler = () => {
    if (isPayoutsV2Enabled) {
      withdrawV2()
    } else {
      withdraw()
    }
  }

  const actions: ButtonProps[] = [
    {
      children: t('confirm'),
      loading: enablePayoutsV2
        ? isPostPayPrerequestLoading || isPayPostAuthRequestLoading
        : isSubmittingWithdrawRequest,
      disabled: !formValues.password,
      onClick: onSubmitWithdrawHandler,
      testid: t('confirm'),
    },
  ]

  // only show cancel button if not loading
  !isSubmittingWithdrawRequest &&
    !isPostPayPrerequestLoading &&
    !twoFactorEnabled &&
    !isPayPostAuthRequestLoading &&
    actions.unshift({
      children: t('cancel'),
      secondary: true,
      onClick: () => dismissAction(),
    })

  return (
    <Box>
      {/* nav bar */}
      <ModalNavBar
        title={t('withdrawConfirm')}
        onBack={
          (isPayoutsV2Enabled
            ? !isPostPayPrerequestLoading && !isPayPostAuthRequestLoading
            : !isSubmittingWithdrawRequest) && (() => setStep('form'))
        }
      />

      {/* withdraw summary */}
      <ModalScrollable>
        <Box paddingX={20} paddingT={20}>
          {!!calloutError && <Callout state="error" message={calloutError} />}
        </Box>
        <SummaryDetail>
          <SummaryRow
            label={t('amountToWithdraw')}
            description={`${roundNumberWithCommas(formValues?.amount)} ${
              selectedWallet?.currency?.code
            }`}
            testid="amount-to-withdraw"
          />
          <SummaryRow
            label={t('fee')}
            description={`${roundNumberWithCommas(withdrawalFee?.fee || 0)} ${
              selectedWallet?.currency?.code
            }`}
            testid="fee"
          />
          <SummaryRow
            label={t('to')}
            description={
              selectedBeneficiary?.businessName
                ? selectedBeneficiary?.businessName
                : `${selectedBeneficiary?.firstName} ${selectedBeneficiary?.lastName}`
            }
            testid="send-to"
          />
          <SummaryRow
            label={t('beneficiaryPaymentReference')}
            renderDescription={
              <Text className="break-all" size="sm" color={TextColor.TEXT_500}>
                {formValues?.reference}
              </Text>
            }
            testid="reference"
          />
        </SummaryDetail>
        <Box className="mx-6 mb-6" gapY={12}>
          <Text size="sm" color="black" weight="medium">
            {twoFactorEnabled ? t('authCodeConfirm') : t('authPasswordConfirm')}
          </Text>

          {/* security input */}
          {twoFactorEnabled ? (
            <>
              {isSubmittingWithdrawRequest ||
              isPostPayPrerequestLoading ||
              isPayPostAuthRequestLoading ? (
                <Box flex flexJustifyCenter>
                  <Loader size="medium" />
                </Box>
              ) : (
                <Input2FA
                  onChange={value => {
                    form.setValue('password', value)
                  }}
                  error={!!form.formState?.errors?.password}
                  errorText={form.formState?.errors?.password?.message}
                />
              )}
            </>
          ) : (
            <Input
              placeholder={t('password')}
              type="password"
              testid="password"
              value={formValues.password || ''}
              disabled={
                isSubmittingWithdrawRequest ||
                isPostPayPrerequestLoading ||
                isPayPostAuthRequestLoading
              }
              {...registerFormField('password')}
              onChange={e => form.setValue('password', e.target.value)}
            />
          )}
        </Box>
      </ModalScrollable>

      {/* actions */}
      {!twoFactorEnabled && <ModalActions currentStep={2} totalSteps={3} actions={actions} />}
    </Box>
  )
}

export default WithdrawConfirmV2
