import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getSupportedCurrencies } from 'services/WalletsService'

export const useSupportedCurrenciesQuery = (cryptoOnly = false) =>
  useQuery({
    queryKey: reactQueryKeys.supportedCurrencies(),
    queryFn: getSupportedCurrencies,
    select: response => {
      if (cryptoOnly) {
        return response.filter(currency => !currency.fiat)
      }

      return response
    },
  })
