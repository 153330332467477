import { StatusPillProps } from '@node-space/storybook-components/dist/StatusPill'

export const LegalAgreementStatus = {
  PENDING: 'PENDING',
  SIGNED: 'SIGNED',
} as const
export type LegalAgreementStatusType =
  (typeof LegalAgreementStatus)[keyof typeof LegalAgreementStatus]

export type LegalAgreementUserType = {
  firstName: string
  lastName: string
  emailAddress: string
}

export type LegalAgreementDetailsType = {
  name: string
  description: string
  fileName: string
  url: string
  declinable: boolean
  reference: string
}
export type LegalAgreementAppendixType = {
  reference: string
  name: string
  url: string
  dateCreated: string
  lastUpdated?: string
  status: LegalAgreementStatusType
}

export type LegalAgreementType = {
  id: number
  reference: string
  status: LegalAgreementStatusType
  versionId: string
  dateCreated: string
  lastUpdated?: string
  user?: LegalAgreementUserType
  agreement: LegalAgreementDetailsType
  appendixes?: LegalAgreementAppendixType[]
}

export type LegalAgreementTableType = LegalAgreementType & { statusPill: StatusPillProps }
