import {
  AddCustomerPayload,
  BusinessCustomerType,
  CustomerEntityType,
  IndividualCustomerType,
} from 'types/customers'

/**
 * Checks if the customer is a business customer.
 * @param {BusinessCustomerType | IndividualCustomerType} currentCustomer - The customer to check.
 * @returns {currentCustomer is BusinessCustomerType} - True if the customer is a business customer.
 */
export const isBusinessCustomer = (
  currentCustomer: BusinessCustomerType | IndividualCustomerType
): currentCustomer is BusinessCustomerType => !!currentCustomer && 'company' in currentCustomer

/**
 * Checks if the customer is an individual customer.
 * @param {BusinessCustomerType | IndividualCustomerType} currentCustomer - The customer to check.
 * @returns {currentCustomer is IndividualCustomerType} - True if the customer is an individual customer.
 */
export const isIndividualCustomer = (
  currentCustomer: BusinessCustomerType | IndividualCustomerType
): currentCustomer is IndividualCustomerType => !!currentCustomer && 'person' in currentCustomer

export const getCustomerFields = (customerFormState: AddCustomerPayload) => {
  if (customerFormState?.customerEntityType === CustomerEntityType.COMPANY) {
    return {
      type: customerFormState?.customerEntityType,
      industryReference: customerFormState?.industry ?? '',
      monthlyExpectedVolumesReference: customerFormState?.businessMonthlyVolume ?? '',
      company: {
        name: customerFormState?.businessName ?? '',
        description: customerFormState?.businessDescription ?? '',
        taxResidenceCountryCode: customerFormState?.businessTaxResidency ?? '',
        registrationNumber: customerFormState?.businessRegistrationNumber ?? '',
        address: {
          address1: customerFormState?.addressLine1 ?? '',
          address2: customerFormState?.addressLine2 ?? '',
          city: customerFormState?.city ?? '',
          postalCode: customerFormState?.postCode ?? '',
          countryCode: customerFormState?.countryCode ?? '',
        },
        representative: {
          firstName: customerFormState?.firstName ?? '',
          lastName: customerFormState?.lastName ?? '',
          dateOfBirth: customerFormState?.dateOfBirth ?? '',
          address: { countryCode: customerFormState?.countryCode ?? '' },
        },
      },
    }
  } else if (customerFormState?.customerEntityType === CustomerEntityType.INDIVIDUAL) {
    return {
      type: customerFormState?.customerEntityType,
      person: {
        firstName: customerFormState?.firstName ?? '',
        lastName: customerFormState?.lastName ?? '',
        dateOfBirth: customerFormState?.dateOfBirth ?? '',
        // birthCountryCode: customerFormState?.birthCountryCode ?? '',
        // residenceCountryCode: customerFormState?.residenceCountryCode ?? '',
        // documentNumber: customerFormState?.documentNumber ?? '',
        address: {
          address1: customerFormState.addressLine1 ?? '',
          city: customerFormState.city ?? '',
          postCode: customerFormState.postCode ?? '',
          countryCode: customerFormState.countryCode ?? '',
        },
      },
    }
  }
}
