import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { RestApi } from '@node-space/rest-api'
import loadingAnimation from 'components/spinners/LoadingAnimationDark'
import { PathNames } from 'constants/General'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { AuthToken } from 'types/types'
import { deleteProfileCookie, setAuthCookies } from 'utils/utils'

const Impersonate = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { profile } = useProfileContext()

  const impersonateState = { isImpersonate: true }

  const queryClient = useQueryClient()

  useEffect(() => {
    const params = queryString.parse(location.search)
    if (params.token) {
      const tokenData = JSON.parse(params.token as string)
      RestApi.setImpersonationToken(tokenData)
      setAuthCookies({ ...tokenData } as AuthToken)

      authenticateWithImpersonation()
    } else {
      navigate(PathNames.AUTHENTICATE, { state: impersonateState })
    }
  }, [])

  useEffect(() => {
    if (profile?.loggedIn) {
      navigate(PathNames.AUTHENTICATE, { state: impersonateState })
    }
  }, [profile])

  // Overwrite auth cookies and fetch new profile
  const authenticateWithImpersonation = async () => {
    try {
      const refreshTokenResponse = await RestApi.refreshToken()
      refreshTokenResponse.data.impersonate = true
      setAuthCookies({ ...refreshTokenResponse.data } as AuthToken)

      await queryClient.invalidateQueries({ queryKey: reactQueryKeys.profile() })
    } catch (err) {
      deleteProfileCookie()
      navigate(PathNames.LOGIN, { replace: true })
    }
  }

  return <div className="flex flex-1 h-screen items-center justify-center">{loadingAnimation}</div>
}

export default Impersonate
