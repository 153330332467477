import { validate } from 'uuid'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { PaymentStatus, PaymentMerchantDefault } from 'types/payments'
import { rootInitialState } from 'components/FilterBar/context/reducer'
import { titleCase, shortenString } from 'utils/utils'
import { FilterListItems } from 'types/types'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'

dayjs.extend(localizedFormat)

/**
 *
 * @param dateStr string
 * @returns boolean
 */

export function dateStringIsValid(dateStr: string): boolean {
  if (!dateStr) return false

  const regex = /^\d{4}-\d{2}-\d{2}$/

  if (dateStr?.match(regex) === null) {
    return false
  }

  const date = new Date(dateStr)
  const timestamp = date.getTime()

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false
  }

  return date.toISOString().startsWith(dateStr)
}

/**
 *
 * @param str string
 * @returns boolean
 */

export function merchantIdIsValid(str: string): boolean {
  if (!str) return false

  if (str === PaymentMerchantDefault.ALL || validate(str)) {
    return true
  }

  return false
}

/**
 *
 * @param str string
 * @returns boolean
 */

export function statusIsValid(str: string): boolean {
  if (!str) return false

  const arrayOfValidStatus = Object.values(PaymentStatus) || []
  const isValidStatus = arrayOfValidStatus?.find(validString => str?.includes(validString))

  if (str === PaymentMerchantDefault.ALL || isValidStatus) {
    return true
  }

  return false
}

/**
 *
 * @param currentFilters object - Record<string, string>
 * @returns array
 */
export function setValidFilterList(currentFilters: Record<string, string>): FilterListItems[] {
  const { data: { merchantList = [] } = {} } = useMerchantsQuery()

  const resultArray = []

  if (currentFilters?.search !== rootInitialState?.search) {
    resultArray.push({ order: 1, name: 'search', value: currentFilters?.search })
  }

  if (
    currentFilters?.from !== dayjs(rootInitialState?.from).format('YYYY-MM-DD') ||
    currentFilters?.to !== dayjs(rootInitialState?.to).format('YYYY-MM-DD')
  ) {
    const dateString = `${dayjs(currentFilters?.from).format('LL')} —> ${dayjs(
      currentFilters?.to
    ).format('LL')}`
    resultArray.push({ order: 2, name: 'date', value: dateString })
  }

  if (currentFilters?.merchant !== rootInitialState?.merchant) {
    const foundMerchant = merchantList?.filter(
      merchant => merchant?.merchantId === currentFilters?.merchant
    )?.[0]
    const merchantValue =
      `${foundMerchant?.displayName} (${shortenString(foundMerchant?.merchantId)})` ||
      currentFilters?.merchant

    resultArray.push({ order: 3, name: 'merchant', value: merchantValue })
  }

  if (currentFilters?.status !== rootInitialState?.status) {
    resultArray.push({ order: 4, name: 'status', value: titleCase(currentFilters?.status) })
  }

  return [...resultArray]
}
