import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Loader from '@node-space/storybook-components/dist/Loader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { usePaymentCaseContext } from 'hooks/context/usePaymentCaseContext'
import { usePaymentCaseDocumentsQuery } from 'hooks/queries/usePaymentCaseDocumentsQuery'
import { PaymentCaseDocument } from './PaymentCaseDocument'
import { PaymentCaseError } from './PaymentCaseError'

export const PaymentCaseDocuments = () => {
  const { t } = useTranslation()

  const { paymentCaseId } = usePaymentCaseContext()

  const {
    data: { files: paymentCaseDocuments } = {},
    isPending,
    isError,
  } = usePaymentCaseDocumentsQuery(paymentCaseId)

  return (
    <Box>
      {isPending && (
        <Box flex middle>
          <Loader theme="dark" size="small" />
        </Box>
      )}

      {isError && !isPending && (
        <StatusCard borderless headline={t('paymentSupport.errorHeadline')} status="error">
          <PaymentCaseError />
        </StatusCard>
      )}

      {!isPending && !isError && !!paymentCaseDocuments?.length && (
        <Box flex flexCol gapY={16}>
          <Text color="text-900" weight="medium">
            {t('paymentSupport.uploadedDocuments')}
          </Text>
          <Box flex flexCol gapY={24}>
            {paymentCaseDocuments?.map((paymentCaseDocument, paymentCaseDocumentIndex) => (
              <PaymentCaseDocument
                testId={`payment-case-document-${paymentCaseDocument?.id}`}
                key={paymentCaseDocumentIndex}
                paymentCaseDocument={paymentCaseDocument}
              />
            ))}
          </Box>
        </Box>
      )}

      {!isPending && !isError && !paymentCaseDocuments?.length && (
        <StatusCard borderless headline="" description={t('paymentSupport.noCaseDocuments')} />
      )}
    </Box>
  )
}
