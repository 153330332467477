import { CopyButton } from '@node-space/storybook-components'
import { useDepositAccountsV2Context, useWalletContext } from 'hooks/context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'

export const CopyAllDetailsFiat = () => {
  const wallet = useWalletContext()
  const { details, isAggregatedWallet } = useDepositAccountsV2Context()
  const { t } = useTranslation()
  return (
    <div className="border-t border-gray-03 py-6">
      <div className="flex justify-center items-center">
        <p className={`text-${copyButtonIconColour} text-sm font-medium`}>{t('copyAllDetails')}</p>
        <CopyButton
          copyObject={
            isAggregatedWallet
              ? details
              : {
                  [t('referenceNumber')]: wallet.address || '',
                  ...details,
                }
          }
          confirmationMessage={t('copiedToClipboard')}
          iconColour={copyButtonIconColour}
        />
      </div>
    </div>
  )
}
