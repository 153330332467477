import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Chip from '@node-space/storybook-components/dist/Chip'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import Modal, { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useDeleteApiKeyMutation } from 'hooks/mutations'
import { useToastContext } from 'hooks/useToastContext'
import { ApiKey } from 'types/apiKeys'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

type ApiKeySuccessModalProps = {
  selectedApiKey: ApiKey
  handleCloseModal: () => void
  showDeleteModal: boolean
}

const ApiKeyDeleteModal = ({
  selectedApiKey,
  handleCloseModal,
  showDeleteModal,
}: ApiKeySuccessModalProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { mutate, isPending } = useDeleteApiKeyMutation()
  const addToast = useToastContext()

  useEffect(() => {
    if (showDeleteModal) {
      track.Amp.track(AmplitudeEvent.DELETE_API_KEY_INITIATE, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
  }, [showDeleteModal])

  const onDeleteClicked = async () => {
    mutate(selectedApiKey?.tokenValue, {
      onSuccess: async () => {
        addToast({ title: t('manageApiKeys.apiKeyDeleted'), state: 'info' })
        track.Amp.track(AmplitudeEvent.EOF_DELETE_API_KEY_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.CLICK,
        })
        handleCloseModal()
        await queryClient.invalidateQueries({ queryKey: ['apiKeys'] })
      },
      onError: () => {
        addToast({ title: t('apiKeyDeleteError'), state: 'error' })
      },
    })
  }

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      testid: 'close-button',
      onClick: () => handleCloseModal(),
      secondary: true,
    },
    {
      children: t('confirm'),
      loading: isPending,
      testid: t('deleteKey'),
      onClick: () => onDeleteClicked(),
    },
  ]

  return (
    <Modal allowOverFlow visible={showDeleteModal} closeModal={handleCloseModal}>
      <ModalNavBar title={t('manageApiKeys.deleteAPIKey')} onClose={handleCloseModal} />
      <Box flex flexCol paddingX={24} paddingT={20} paddingB={8} gap={16}>
        <Text size="sm" color="grey">
          {t('manageApiKeys.deleteDescription')}
        </Text>
      </Box>
      <SummaryDetail testid="delete-crypto-address">
        {!!selectedApiKey?.description && (
          <SummaryRow
            label={t('manageApiKeys.name')}
            renderDescription={<Text size="sm">{selectedApiKey?.description}</Text>}
          />
        )}
        {!!selectedApiKey?.allowedIps && (
          <SummaryRow
            label={t('manageApiKeys.ipWhitelisting')}
            renderDescription={
              <Box flex flexWrap gapY={8}>
                {selectedApiKey?.allowedIps?.map((allowedIp, index) => (
                  <Chip key={index} value={allowedIp} />
                ))}
              </Box>
            }
          />
        )}
      </SummaryDetail>
      <ModalActions actions={actions} />
    </Modal>
  )
}

export default ApiKeyDeleteModal
