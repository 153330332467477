import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { AnyObjectSchema } from 'yup'
import { useWalletsQuery } from 'hooks/queries/useWalletsQuery'
import { useActiveWallets } from 'hooks/useActiveWallets'
import { WalletType } from 'types/types'

export interface IWalletActionApiType<FormType, StepType> {
  registerFormField: (key: keyof FormType) => { error: boolean; errorText: string }
  form: UseFormReturn<FormType>
  step: StepType
  setStep: Dispatch<SetStateAction<StepType>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  dismissAction: () => void
  wallets: WalletType[]
  isFetchingWallets: boolean
}

const useWalletActionApi = <FormType, StepType>({
  intialStep,
  walletId,
  actionWalletId,
  setWalletId,
  step,
  setStep,
  onModalClose,
  formSchema,
  defaultFormValues,
}: {
  intialStep: StepType
  walletId: string
  actionWalletId: string
  setWalletId: Dispatch<SetStateAction<string>>
  step: StepType
  setStep: Dispatch<SetStateAction<StepType>>
  onModalClose: () => void
  formSchema?: AnyObjectSchema
  defaultFormValues?: FormType
}): IWalletActionApiType<FormType, StepType> => {
  type FormExtendedType = FormType | { walletId?: string }

  const { data: wallets = [], isFetching: isFetchingWallets } = useWalletsQuery()

  const activeWallets = useActiveWallets(wallets)

  const [loading, setLoading] = useState(false)

  const form = useForm<FormExtendedType>({
    defaultValues: defaultFormValues || {},
    resolver: yupResolver(formSchema),
  })

  // set modal wallet id on form update
  useEffect(() => {
    form.watch(values => {
      const formWalletId = (values as { walletId?: string }).walletId
      if (walletId !== formWalletId) {
        setWalletId(formWalletId)
      }
    })
  }, [])

  // reset entire form on form wallet id change
  useEffect(() => {
    const formWalletId = form.getValues('walletId')
    if (walletId !== formWalletId) {
      form.reset({ walletId, ...defaultFormValues }, { keepDefaultValues: true })
    }
  }, [walletId])

  // set form wallet id on modal open
  useEffect(() => {
    form.reset({ walletId: actionWalletId, ...defaultFormValues }, { keepDefaultValues: true })
    setWalletId(actionWalletId)
  }, [actionWalletId])

  const registerFormField = (key: keyof FormExtendedType) => {
    const error = form.formState.errors[key as string]
    return {
      ...form.register(key),
      error: !!error,
      errorText: error ? error['message'] : null,
      label: formSchema?.fields[key]?.spec?.label,
      testid: key,
    }
  }

  const dismissAction = () => {
    setStep(intialStep)
    form.reset({ walletId, ...defaultFormValues }, { keepDefaultValues: true })
    onModalClose()
  }

  return {
    step: step as StepType,
    setStep,
    form: form as UseFormReturn<FormType>,
    registerFormField,
    loading,
    setLoading,
    dismissAction,
    wallets: activeWallets,
    isFetchingWallets,
  }
}

export default useWalletActionApi
