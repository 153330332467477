import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { logSentryError } from '@node-space/utils'
import { useRecurringExportContext } from 'hooks/context/useRecurringExportContext'
import { useDeleteReportScheduleMutation } from 'hooks/mutations/useDeleteReportScheduleMutation'
import { useToastContext } from 'hooks/useToastContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  ExportAccordionItems,
  RecurringExportSteps,
  ReportSchedule,
} from 'types/walletTransactionsExport'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { ReportScheduleDescription } from './ReportScheduleDescription'

export interface ReportScheduleProps {
  reportSchedule: ReportSchedule
  setIsDeleteError: Dispatch<SetStateAction<boolean>>
}

export const ReportScheduleItem = ({ reportSchedule, setIsDeleteError }: ReportScheduleProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()
  const { setCurrentReportSchedule, setIsUpdatingReportSchedule, setStep, setCloseAllItems } =
    useRecurringExportContext()

  const { mutate, isPending } = useDeleteReportScheduleMutation()

  const amplitudeProperties = {
    category: AmplitudeEventCategory.MERCHANT_PORTAL,
    action: AmplitudeEventAction.LOAD,
    type: ExportAccordionItems.RECURRING,
  }

  const deleteReportSchedule = () => {
    setIsDeleteError(false)
    mutate(reportSchedule?.id, {
      onSuccess: () => {
        track.Amp.track(AmplitudeEvent.EOF_DELETE_EXPORT_SUCCESS, amplitudeProperties)
        setCloseAllItems(true)
        addToast({ title: t('exports.reportScheduleDeleted'), state: 'info' })
      },
      onError: (error: BaseErrorResponse) => {
        track.Amp.track(AmplitudeEvent.EOF_DELETE_EXPORT_ERROR, {
          ...amplitudeProperties,
          error,
        })
        setIsDeleteError(true)
        logSentryError('Error from ReportScheduleItem - deleteReportSchedule', error)
      },
    })
  }

  const handleEditSchedule = () => {
    track.Amp.track(AmplitudeEvent.EDIT_EXPORT_INITIATE, {
      ...amplitudeProperties,
      action: AmplitudeEventAction.CLICK,
    })
    setIsUpdatingReportSchedule(true)
    setCurrentReportSchedule(reportSchedule)
    setStep(RecurringExportSteps.FORM)
  }

  return (
    <SummaryDetail hasMargin={false}>
      <SummaryRow
        label={t('exports.currentSchedule')}
        renderDescription={
          <>
            <ReportScheduleDescription reportSchedule={reportSchedule} />
            <Box flex gapX={12} paddingT={12} justifyContent="end" width="full">
              <Button
                className="text-primary-500 hover:text-primary-700 active:text-primary-900"
                noStyling
                onClick={() => handleEditSchedule()}
              >
                {t('exports.editSchedule')}
              </Button>
              <Button
                disabled={isPending}
                {...(isPending && { iconElement: <Loader /> })}
                className="text-primary-500 hover:text-primary-700 active:text-primary-900 disabled:text-gray-07"
                noStyling
                onClick={() => deleteReportSchedule()}
              >
                {t('exports.deleteSchedule')}
              </Button>
            </Box>
          </>
        }
      />
    </SummaryDetail>
  )
}
