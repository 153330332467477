import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@node-space/storybook-components'
import { Box } from '@node-space/storybook-components/dist/Box'
import { DetailsCard } from '@node-space/storybook-components/dist/DetailsCard'
import Loader from '@node-space/storybook-components/dist/Loader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useMappedRoles } from 'hooks/useMappedRoles'
import CompanyLogo from './CompanyLogo'

interface CompanyInfoProps {
  companyName: string
  companyDescription: string
  isLoading?: boolean
  logoUrl?: string
  onEditClickHandler: () => void
}

const CompanyInfo = ({
  companyName,
  companyDescription,
  isLoading,
  logoUrl,
  onEditClickHandler,
}: CompanyInfoProps) => {
  const { t } = useTranslation()
  const permissions = useMappedRoles()
  const hasCompanyInfoRolePermissions = permissions?.manageCompanyInfo?.canEdit ?? false

  return (
    <Box border="gray" background="white" width="full">
      <Box flex direction="row">
        <Box alignItems="start">
          <Text size="xl" weight="medium" className="p-4">
            {t('bvnkNetwork.companyInfo')}
          </Text>
        </Box>
        {hasCompanyInfoRolePermissions && (
          <Box flex direction="row-reverse" className="flex-1">
            <Button
              noStyling
              iconElement={<Icon name="EditIcon" color="primary-500" size="lg" />}
              testid="company-info-edit-button"
              onClick={onEditClickHandler}
            ></Button>
          </Box>
        )}
      </Box>

      <Box flex direction="col" padding={16} alignItems="center" gapY={12}>
        <CompanyLogo companyName={companyName} logoUrl={logoUrl} />
        <Text size="lg" weight="medium" align="center">
          {companyName}
        </Text>
      </Box>

      {isLoading ? (
        <Box flex centerChildren width="full" paddingT={28}>
          <Loader size="medium" />
        </Box>
      ) : (
        companyDescription && (
          <DetailsCard
            borderless
            background="white"
            details={[
              {
                label: t('bvnkNetwork.description'),
                value: companyDescription,
              },
            ]}
          />
        )
      )}
    </Box>
  )
}

export default CompanyInfo
