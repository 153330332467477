import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FieldErrorsImpl } from 'react-hook-form'
import { Box as FormRow, Box as TooltipLabel } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'

interface MerchantIdFieldProps {
  merchantListOptions: {
    icon: string
    value: string
    label: string
    secondLabel: string
    secondLabelText?: string
  }[]
  merchantId: string
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >
  isLoading: boolean
}

const MerchantIdField = ({
  merchantListOptions,
  merchantId,
  errors,
  isLoading,
}: MerchantIdFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormRow className="mb-12 h-16 mt-8 z-20">
      <DoubleLabelSelect
        disabled
        label={
          <>
            <TooltipLabel className="mr-2">{t('merchantId')}</TooltipLabel>
            <Tooltip
              id="merchant-tooltip"
              position="right"
              bodyContent={`${t('payments.channels.tooltips.merchantId')}`}
              className="w-72 whitespace-normal"
            >
              <Icon name="InfoCircleIcon" size="sm" />
            </Tooltip>
          </>
        }
        name="merchantId"
        options={merchantListOptions}
        value={merchantId}
        menuMaxHeight={225}
        placeholder={t('merchantId')}
        iconsUrl={process.env.ICONS_URL}
        error={Boolean(errors.merchantId)}
        errorText={errors.merchantId?.message}
        loading={isLoading}
      />
    </FormRow>
  )
}

export default React.memo(MerchantIdField)
