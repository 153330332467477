import React, { useEffect, useState } from 'react'
import { MerchantsProvider } from 'contexts'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Box, Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Chip } from '@node-space/storybook-components/dist/Chip'
import Loader from '@node-space/storybook-components/dist/Loader'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import FilterBar from 'components/FilterBar/FilterBar'
import NoSearchResults from 'components/NoSearchResults/NoSearchResults'
import TablePagination from 'components/TablePagination'
import { PathNames } from 'constants/General'
import { useMerchantsQuery } from 'hooks/queries'
import { useMappedRoles } from 'hooks/useMappedRoles'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { PaymentsResponse, PaymentStatus, PaymentTag } from '../../types/payments'
import SearchFields from './components/Form/SearchFields'
import PaymentHeaderCreateButton from './components/Header/PaymentHeaderCreateButton'
import PaymentModal from './components/Modal/PaymentModal'
import PaymentTable from './components/Table/PaymentTable'
import { PaymentCreateModalProvider } from './context/PaymentCreateModalProvider'
import usePaymentsApi from './hooks/usePaymentsApi'

const Payments = () => {
  const { t } = useTranslation()
  const permissions = useMappedRoles()?.payments

  const [withContentExpanded, setWithContentExpanded] = useState(false)

  const canEdit = permissions?.canEdit
  const canCreatePayIn = permissions?.canCreatePayIn
  const {
    executor,
    isLoading,
    isError,
    data: {
      paymentsList: { transactions, paging, pagingMax },
      searchByRef,
      searchByTransactionHash,
      isExportingCSV,
      dateValue,
      status,
      tag,
    },
    actions: {
      onDateSelected,
      handleExportClicked,
      onPaymentStatusChanged,
      onPaymentTagChange,
      onMerchantSelected,
      showSearchResult,
      onSuccessPayment,
      onResetAll,
      setPaymentsList,
      onPageChange,
      onSearchByRefChanged,
      onSearchByTransactionHash,
      resetSearch,
    },
  } = usePaymentsApi()

  const { data: { merchantList = [] } = {} } = useMerchantsQuery()

  const urlParams = new URLSearchParams(window.location.search)
  const queryPaymentsLinkBy = urlParams.get('dlt-reference') ?? null

  useEffect(() => {
    if (queryPaymentsLinkBy && !isLoading) {
      setWithContentExpanded(true)
      onSearchByRefChanged(queryPaymentsLinkBy)
    }
  }, [])

  const shouldShowNoSearchResults =
    (searchByRef ||
      searchByTransactionHash ||
      status !== PaymentStatus.ALL ||
      tag !== PaymentTag.ALL) &&
    !isLoading &&
    !transactions?.length

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.PAYMENTS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <Wrapper className="payments-container">
      {!searchByRef &&
      !searchByTransactionHash &&
      !transactions?.length &&
      status === PaymentStatus.ALL &&
      tag === PaymentTag.ALL ? (
        isLoading && !isError ? (
          <Box flex centerChildren className="mt-10">
            <Loader theme="dark" size="large" />
          </Box>
        ) : (
          <StatusCard
            headline={t('payments.emptyStateHeadline')}
            description={t('payments.emptyStateDescription')}
          >
            <Box padding={16}>
              <PaymentCreateModalProvider
                condition={
                  location.pathname === `${PathNames.PAYMENTS}${PathNames.PAYMENTS_CREATE}`
                }
              >
                {(canEdit || canCreatePayIn) && <PaymentHeaderCreateButton />}
                <MerchantsProvider merchants={merchantList}>
                  <PaymentModal onSuccessPayment={onSuccessPayment} />
                </MerchantsProvider>
              </PaymentCreateModalProvider>
            </Box>
          </StatusCard>
        )
      ) : (
        <>
          <PaymentCreateModalProvider
            condition={location.pathname === `${PathNames.PAYMENTS}${PathNames.PAYMENTS_CREATE}`}
          >
            {(canEdit || canCreatePayIn) && (
              <PageHeader
                description={t('payments.headerDescription')}
                renderRightSection={<PaymentHeaderCreateButton />}
              />
            )}
            <MerchantsProvider merchants={merchantList}>
              <PaymentModal onSuccessPayment={onSuccessPayment} />
            </MerchantsProvider>
          </PaymentCreateModalProvider>

          <Text size="lg" weight="medium" className="pb-4">
            {t('latestActivity')}
          </Text>

          <FilterBar
            eventHandlers={{
              executor,
              onMerchantSelected,
              onDateFilterChange: onDateSelected,
              onPaymentStatusChanged,
              handleExportClicked,
              onResetAll,
              showSearchResult,
              onPaymentTagChange,
            }}
            preLoadedSearchTerm={queryPaymentsLinkBy}
            conditions={{ isExportingCSV }}
            filterDateRange={dateValue}
          >
            <SearchFields
              t={t}
              searchByRef={searchByRef}
              searchByTransactionHash={searchByTransactionHash}
              onSearchByRefChanged={onSearchByRefChanged}
              onSearchByTransactionHash={onSearchByTransactionHash}
              queryPaymentsLinkBy={queryPaymentsLinkBy}
            />
          </FilterBar>

          {(!!searchByRef || !!searchByTransactionHash) && (
            <Box flex direction="row" testid="filter-bar-reset" className="mt-3">
              <Chip
                key={uuidv4()}
                value={searchByTransactionHash || searchByRef || queryPaymentsLinkBy}
                onCloseHandler={resetSearch}
              />
            </Box>
          )}

          {isError && <StatusCard headline={t('defaultError')} status="error" />}

          {shouldShowNoSearchResults && (
            <NoSearchResults
              testIds={{
                headline: 'payment-links-search-status-card',
                description: 'payment-links-no-result-label',
                clearButton: 'payment-links-clear-result-btn',
              }}
              clearSearchCriteria={onResetAll}
            />
          )}

          {!!transactions?.length && !isError && (
            <PaymentTable
              isLoading={isLoading}
              transactions={transactions as unknown as PaymentsResponse[]}
              withContentExpanded={withContentExpanded}
            />
          )}
          {!isError && (
            <TablePagination
              total={paging?.total}
              pagingMax={pagingMax}
              paging={paging}
              setPaymentsList={setPaymentsList}
              executor={onPageChange}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

export default React.memo(Payments)
