import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Modal, { ModalActions, ModalBody } from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { ConnectionStatusTypes } from 'constants/bvnkNetwork'
import { useUpdateNetworkConnectionMutation } from 'hooks/mutations/BvnkNetwork/useUpdateNetworkConnectionMutation'
import { useToastContext } from 'hooks/useToastContext'
import { BvnkNetworkConnection, ConnectionStatusType } from 'types/bvnkNetwork'
import { getConnectionStatusUpdateSuccessMessage } from 'utils/bvnkNetwork'

export interface ConnectionConfirmationModalProps {
  connection: BvnkNetworkConnection
  connectionStatus: ConnectionStatusType
  isModalOpen: boolean
  onModalClose: () => void
}

export const ConnectionConfirmationModal = ({
  connection,
  connectionStatus,
  isModalOpen,
  onModalClose,
}: ConnectionConfirmationModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { mutate: updateNetworkConnection, isPending: isUpdatingStatus } =
    useUpdateNetworkConnectionMutation()

  const updateConnection = (
    connection: BvnkNetworkConnection,
    connectionStatus: ConnectionStatusType
  ) => {
    updateNetworkConnection(
      { connectionId: connection?.id, connectionStatus },
      {
        onSuccess: () => {
          const successToastMessage = getConnectionStatusUpdateSuccessMessage(
            connection?.details?.name,
            connectionStatus,
            t
          )
          addToast({
            title: successToastMessage,
            state: 'success',
          })
          queryClient.invalidateQueries({ queryKey: ['bvnkNetworkConnections'] })
        },
        onError: () => {
          addToast({
            title: t('bvnkNetwork.updateNetworkConnectionError', { networkMember: name }),
            state: 'error',
          })
        },
      }
    )
  }

  const ConfirmationModalActions = () => {
    const actionButtons: ButtonProps[] = []

    switch (connectionStatus) {
      case ConnectionStatusTypes.DISCONNECTED:
        actionButtons.push(
          ...([
            {
              destructive: true,
              children: t('bvnkNetwork.disconnect'),
              testid: 'connection-disconnect-button',
              loading: isUpdatingStatus,
              iconElement: <Icon name="Prohibit" color="white" />,
              onClick: () => updateConnection(connection, connectionStatus),
            },
            {
              secondary: true,
              children: t('cancel'),
              testid: 'ancel-button',
              onClick: () => onModalClose?.(),
            },
          ] as ButtonProps[])
        )
        break
      case ConnectionStatusTypes.REJECTED:
        actionButtons.push(
          ...([
            {
              destructive: true,
              children: t('bvnkNetwork.denyRequest'),
              testid: 'connection-disconnect-button',
              loading: isUpdatingStatus,
              iconElement: <Icon name="Prohibit" color="white" />,
              onClick: () => updateConnection(connection, connectionStatus),
            },
            {
              secondary: true,
              children: t('cancel'),
              testid: 'connection-cancel-button',
              onClick: () => onModalClose?.(),
            },
          ] as ButtonProps[])
        )
        break
      default:
        break
    }

    return actionButtons?.length && <ModalActions actions={actionButtons} />
  }

  const getHeadline = () => {
    switch (connectionStatus) {
      case ConnectionStatusTypes.DISCONNECTED:
        return t('bvnkNetwork.disconnectModalHeadline', {
          networkMember: connection?.details?.name,
        })
      case ConnectionStatusTypes.REJECTED:
        return t('bvnkNetwork.denyRequestModalHeadline', {
          networkMember: connection?.details?.name,
        })
      default:
        return
    }
  }

  const getDescription = () => {
    switch (connectionStatus) {
      case ConnectionStatusTypes.DISCONNECTED:
        return t('bvnkNetwork.disconnectModalDescription', {
          networkMember: connection?.details?.name,
        })
      case ConnectionStatusTypes.REJECTED:
        return t('bvnkNetwork.denyRequestModalDescription', {
          networkMember: connection?.details?.name,
        })
      default:
        return
    }
  }

  const headline = getHeadline()
  const description = getDescription()

  return (
    <Modal
      testid="connection-confirmation-members-modal"
      allowOverFlow
      visible={isModalOpen}
      closeModal={onModalClose}
    >
      <ModalBody>
        <StatusCard
          className="p-2"
          testid="bvnkNetwork-confirmation-modal"
          borderless
          customIcon={<Icon name="Prohibit" size={'hero'} color="status-error-500" />}
          headline={headline}
          description={description}
        />
      </ModalBody>

      <ConfirmationModalActions />
    </Modal>
  )
}
