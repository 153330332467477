import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { trimStart } from 'lodash-es'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Option } from '@node-space/storybook-components/dist/Select'
import { useIndustryOptions } from 'hooks/queries/Customers/useIndustryOptions'
import { useMonthlyVolumesQuery } from 'hooks/queries/Customers/useMonthlyVolumesQuery'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { AddCustomerPayload, AddCustomerPayloadKeys, CustomerEntityType } from 'types/customers'
import { addCustomerDetailsSchema } from '../schema/addCustomersDetailsSchema'
import { CustomerEntityForm } from './CustomerEntityForm'

type AddCustomerFormProps = {
  isSubmitting: boolean
  onBack: () => void
  onContinue: (formData: AddCustomerPayload) => void
  customerFormState: AddCustomerPayload
}

export const AddCustomerForm = ({
  customerFormState,
  isSubmitting,
  onBack,
  onContinue,
}: AddCustomerFormProps) => {
  const { t } = useTranslation()

  const {
    data: countries,
    isPending: isCountriesLoading,
    isError: isCountriesLoadingError,
  } = useCountriesQuery(true)

  const countriesOptions = countries?.map(country => ({
    label: country?.name,
    value: country?.code,
  }))

  const [activeTab, setActiveTab] = useState<CustomerEntityType>(CustomerEntityType.COMPANY)

  const {
    data: monthlyVolumes,
    isPending: isFetchingMonthlyVolumes,
    isError: isMonthlyVolumesGetError,
  } = useMonthlyVolumesQuery()

  const monthlyVolumesOptions: Option[] = monthlyVolumes?.map(data => ({
    value: data.reference,
    label: data.name,
  }))

  const { industryOptions, isPending: isFetchingIndustries } = useIndustryOptions()

  const formSchema = addCustomerDetailsSchema(t)
  const {
    control,
    formState,
    register,
    setValue,
    resetField,
    trigger,
    clearErrors,
    watch,
    setError,
  } = useForm<AddCustomerPayload>({
    resolver: yupResolver(formSchema),
  })

  const formValues = watch()

  const [
    customerEntityType,
    customerType,
    businessName,
    industry,
    industryName,
    businessDescription,
    businessRegistrationNumber,
    businessMonthlyVolume,
    businessMonthlyVolumeName,
    businessTaxResidency,
    businessTaxResidencyName,
    firstName,
    lastName,
    dateOfBirth,
    nationality,
    nationalityName,
    countryCode,
    countryName,
    addressLine1,
    addressLine2,
    city,
    postCode,
  ] = useWatch({
    control,
    name: [
      'customerEntityType',
      'customerType',
      'businessName',
      'industry',
      'industryName',
      'businessDescription',
      'businessRegistrationNumber',
      'businessMonthlyVolume',
      'businessMonthlyVolumeName',
      'businessTaxResidency',
      'businessTaxResidencyName',
      'firstName',
      'lastName',
      'dateOfBirth',
      'nationality',
      'nationalityName',
      'countryCode',
      'countryName',
      'addressLine1',
      'addressLine2',
      'city',
      'postCode',
    ],
    defaultValue: {
      customerEntityType: CustomerEntityType.COMPANY,
      customerType: CustomerEntityType.COMPANY,
      businessName: '',
      industry: '',
      businessDescription: '',
      businessRegistrationNumber: '',
      businessMonthlyVolume: '',
      businessTaxResidency: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      nationality: '',
      countryCode: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postCode: '',
    },
  })

  useEffect(() => {
    setCachedValues()
  }, [onBack])

  const setCachedValues = () => {
    if (!customerFormState) {
      return
    }
    Object.keys(customerFormState).forEach((field: keyof AddCustomerPayload) => {
      if (customerFormState[field] && !!Object.keys(customerFormState)?.length) {
        setValue(field, customerFormState[field])
      }
    })
  }

  const handleContinue = async data => {
    const businessCustomerFields: AddCustomerPayloadKeys[] = [
      'customerEntityType',
      'businessName',
      'industry',
      'businessDescription',
      'businessRegistrationNumber',
      'businessMonthlyVolume',
      'businessTaxResidency',
      'countryCode',
      'addressLine1',
      'city',
      'postCode',
    ]

    const individualCustomerFields: AddCustomerPayloadKeys[] = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'nationality',
    ]

    const representativeFields = individualCustomerFields

    const isFieldsValid = await trigger(
      customerEntityType === CustomerEntityType.COMPANY
        ? [...businessCustomerFields, ...representativeFields]
        : individualCustomerFields
    )

    if (isFieldsValid) {
      onContinue(formValues)
    }
  }

  useEffect(() => {
    setValue('customerEntityType', CustomerEntityType.COMPANY)
  }, [])

  const actions: ButtonProps[] = [
    {
      children: t('back'),
      testid: 'back',
      secondary: true,
      onClick: () => {
        onBack()
      },
    },
    {
      children: t('continue'),
      onClick: handleContinue,
      loading: false,
      disabled: false,
    },
  ]

  const handleChange = (field: AddCustomerPayloadKeys, value: string) => {
    setValue(field, trimStart(value))
  }

  return (
    <ModalScrollable>
      <Box testid="add-customer-form" flex flexCol>
        <Box paddingX={12} paddingY={8}>
          <Radio
            {...register('customerEntityType')}
            onChange={value => {
              setValue('customerEntityType', value as CustomerEntityType)
              setActiveTab(value as CustomerEntityType)
            }}
            value={formValues?.customerEntityType}
            horizontal
            borderless={false}
            options={[
              { value: CustomerEntityType.COMPANY, label: t('customers.business') },
              {
                value: CustomerEntityType.INDIVIDUAL,
                label: t('customers.individual'),
                disabled: true,
              },
            ]}
          />
        </Box>
        <Box padding={12}>
          <CustomerEntityForm
            activeTab={activeTab}
            businessName={businessName}
            businessDescription={businessDescription}
            businessRegistrationNumber={businessRegistrationNumber}
            isCountriesLoading={isCountriesLoading}
            countries={countriesOptions}
            businessTaxResidency={businessTaxResidency}
            businessMonthlyVolume={businessMonthlyVolume}
            monthlyVolumesOptions={monthlyVolumesOptions}
            isFetchingMonthlyVolumes={isFetchingMonthlyVolumes}
            industryOptions={industryOptions}
            isFetchingIndustries={isFetchingIndustries}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            postCode={postCode}
            countryCode={countryCode}
            industry={industry}
            firstName={firstName}
            lastName={lastName}
            dateOfBirth={dateOfBirth}
            nationality={nationality}
            errors={formState?.errors}
            register={register}
            setValue={setValue}
          />
        </Box>
      </Box>
      <ModalActions actions={actions} />
    </ModalScrollable>
  )
}
