import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import Icon from '@node-space/storybook-components/dist/Icon'
import { IconLabel } from '@node-space/storybook-components/dist/IconLabel'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useMappedRoles } from '../../hooks/useMappedRoles'
import DisplaySettings from './components/Modals/DisplaySettings'
import Section from './components/Section/Section'

const AccountDetails = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const permissions = useMappedRoles()?.profile
  const canEdit = permissions?.canEdit

  const { profile } = useProfileContext() || {}
  const {
    firstName,
    lastName,
    country,
    emailAddress,
    language,
    currencyCode: profileCurrency,
  } = profile || {}

  const { data: accountPreferences } = useAccountPreferencesQuery()

  const preferredCurrency = accountPreferences?.currency

  const userCurrency = preferredCurrency || profileCurrency

  const [showDisplaySettings, setShowDisplaySettings] = useState(false)

  const personalInfoDetails = [{ label: t('name'), value: `${firstName} ${lastName}` }]

  if (country?.name) {
    personalInfoDetails.push({ label: t('country'), value: country?.name })
  }

  const cards = [
    // Personal info
    {
      title: t('account.personalInfo'),
      details: personalInfoDetails,
    },
    // Contact info
    {
      title: t('account.contactInfo'),
      details: [{ label: t('email'), value: emailAddress }],
    },
    // Display settings
    {
      title: t('account.displaySettings'),
      details: [
        { label: t('language'), value: language || 'English' },
        {
          label: t('currency'),
          value: userCurrency ? (
            <IconLabel
              label={userCurrency}
              icon={
                <ImageIcon
                  width={17}
                  height={17}
                  src={`${process.env.ICONS_URL}${userCurrency?.toLowerCase()}.svg`}
                />
              }
            />
          ) : (
            t('noneSelected')
          ),
        },
      ],
      button: canEdit
        ? {
            testId: 'edit-display-settings',
            label: t('edit'),
            icon: <Icon name="EditIcon" color={secondaryButtonIconColour} />,
            onClick: () => setShowDisplaySettings(true),
          }
        : null,
    },
  ]

  return (
    <>
      <Section title={t('account.title')} cards={cards} />
      <DisplaySettings
        userCurrency={userCurrency}
        isOpen={showDisplaySettings}
        onClose={() => setShowDisplaySettings(false)}
        onSuccess={() =>
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.accountPreferences() })
        }
      />
    </>
  )
}

export default AccountDetails
