import { useQuery } from '@tanstack/react-query'
import { Approval } from 'constants/General'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAccountApprovalsPreference } from 'services/ApprovalsService'

export const useAccountApprovalsPreferenceQuery = (
  accountReference: string,
  isEnabled = false,
  preference?: string
) => {
  return useQuery({
    queryKey: reactQueryKeys.accountApprovalsPreference(accountReference, preference),
    queryFn: () => getAccountApprovalsPreference(accountReference, preference),
    enabled: isEnabled,
    select: data => {
      const limits = data?.limits
      if (limits?.length) {
        const lastLimit = limits?.[limits?.length - 1]
        if (!lastLimit.maxAmount) {
          lastLimit.maxAmount = Approval.RULES.INFINITY
        }
      }
      return {
        preferences: data?.preferences,
        limits,
      }
    },
  })
}
