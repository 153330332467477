import React from 'react'
import i18next from 'i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TransactionType } from 'types/transaction/TransactionType'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

interface CryptoDepositProps {
  amountWithCurrency: string
  transactionDate: string
  transactionHash: string
  transactionDepositCryptoUUID: string
  sourceAddress: string
}

const CryptoDeposit = ({
  amountWithCurrency,
  transactionDate,
  transactionHash,
  transactionDepositCryptoUUID,
  sourceAddress,
}: CryptoDepositProps) => {
  return (
    <>
      <TransactionDetailRow
        label={i18next.t('transactions.sideBar.paymentInAmountReceived')}
        detail={amountWithCurrency}
      />
      <CopyTransactionRow content={transactionHash} label={i18next.t('hash')}>
        {transactionHash.includes(TransactionType.OFF_CHAIN) && (
          <Quicklink
            to={process.env.OFF_CHAIN_INFO_LINK}
            iconElement={<Icon name="InfoCircleIcon" color="primary-500" />}
            text={''}
          />
        )}
      </CopyTransactionRow>
      <CopyTransactionRow
        content={transactionDepositCryptoUUID}
        label={i18next.t('transactions.sideBar.uuid')}
      />
      <TransactionDetailRow label={i18next.t('date')} detail={transactionDate} />

      {sourceAddress && (
        <Box paddingT={20}>
          <Text size="base" weight="medium" color="dark-grey">
            {i18next.t('senderDetails')}
          </Text>

          <CopyTransactionRow
            content={sourceAddress}
            label={i18next.t('transactions.sideBar.sourceAddress')}
          />
        </Box>
      )}
    </>
  )
}

export default CryptoDeposit
