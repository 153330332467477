import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useForm as useCryptoForm, useWatch as useCryptoWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { SimpleTableV2 as SimpleTable } from '@node-space/storybook-components/dist/SimpleTableV2'
import { logSentryError } from '@node-space/utils'
import { useDeleteCryptoRecordMutation } from 'hooks/mutations/useDeleteCryptoRecordMutation'
import { useDeletePreProcessedRecordMutation } from 'hooks/mutations/useDeletePreProcssedRecordMutation'
import { usePutUpdateMassCryptoPayoutMutation } from 'hooks/mutations/usePutUpdateMassCryptoPayoutMutation'
import { useGetCryptoMassPayoutById } from 'hooks/queries/MassPayouts/useGetCryptoMassPayoutById'
import { useGetMassFiatPayoutsDetailsQuery } from 'hooks/queries/MassPayouts/useGetMassFiatPayoutsDetails'
import { useCryptoCurrenciesQuery } from 'hooks/queries/useCryptoCurrenciesQuery'
import { useWalletsQueryV2 } from 'hooks/queries/useWalletsQueryV2'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PaymentStatus } from 'types/payments'
import { WalletTypes } from 'types/types'
import { roundNumberWithCommas } from 'utils/utils'
import { BaseErrorResponse } from '../../../types/beneficiaries'
import {
  fiatBatchInformationAtom,
  modalAtom,
  preProcessedRecordToUpdateAtom,
  walletTypeAtom,
} from '../atoms/massPayoutAtoms'
import { CryptoPayoutEditRecordForm } from '../components/Forms/CryptoPayoutEditRecordForm'
import { FiatPayoutEditRecordForm } from '../components/Forms/FiatPayoutEditRecordForm'
import { MassPayoutEditRecordModal } from '../components/Forms/MassPayoutEditRecordModal'
import { MassPayoutBatchInformation } from '../components/MassPayoutBatchInformation'
import MassPayoutHeader from '../components/MassPayoutHeader'
import MassPayoutModal from '../components/MassPayoutModal'
import { MassPayoutReviewCallouts } from '../components/MassPayoutReviewCallouts'
import { MassPayoutSidePanel } from '../components/MassPayoutSidePanel'
import { getCryptoTableRows } from '../components/TableData/cryptoTableData'
import { getFiatTableRows } from '../components/TableData/fiatTableData'
import { getTableColumns } from '../components/TableData/tableColumns'
import { useIsCryptoBatch } from '../hooks/useIsCryptoBatch'
import { updateCryptoPayoutSchema } from '../schemas/updateCryptoPayoutSchema'
import {
  BatchStatus,
  CryptoPayout,
  FiatPayout,
  MassPayoutButtonGroup,
  PreProcessedPayouts,
  UpdateCryptoPayoutFormPayload,
  UpdateCryptoPayoutFormPayloadKeys,
} from '../types'
import {
  getSumOfFiatRecordsWithErrors,
  getSumOfRecords,
  getSumOfRecordsWithErrors,
  getValidRecords,
} from '../utils'

export const MassPayoutReview = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { reference: batchId } = useParams()

  const fiatBatchInformation = useAtomValue(fiatBatchInformationAtom)
  const walletType = useAtomValue(walletTypeAtom)
  const [preProcessedRecordToUpdate, setPreProcessedRecordToUpdate] = useAtom<PreProcessedPayouts>(
    preProcessedRecordToUpdateAtom
  )
  const setModal = useSetAtom(modalAtom)
  const [cryptoRecordToUpdate, setCryptoRecordToUpdate] = useState<CryptoPayout>()
  const [isEditPayoutModalVisible, setIsEditPayoutModalVisible] = useState(false)

  const isCrypto = useIsCryptoBatch(walletType)

  useEffect(() => {
    setIsEditPayoutModalVisible(false)
  }, [])

  const { data: cryptoCurrencies, isLoading: isCryptoCurrenciesLoading } =
    useCryptoCurrenciesQuery()

  /* #region Fiat ----------------------------------------- */
  const { data: wallets, isLoading: isLoadingWallets } = useWalletsQueryV2({
    walletType: WalletTypes.FIAT,
    max: 1000,
  })

  const { mutate: mutateDeletePreProcessedRecord, isPending: isLoadingDeletePreProcessedRecord } =
    useDeletePreProcessedRecordMutation()

  const { data: massFiatPayoutDetails, isLoading: loadingMassFiatPayoutDetails } =
    useGetMassFiatPayoutsDetailsQuery(batchId, !isCrypto)

  const selectedWallet = wallets?.data?.find(
    wallet => wallet?.lsid === massFiatPayoutDetails?.walletId
  )

  const massFiatPayoutInformation = massFiatPayoutDetails || fiatBatchInformation

  const isFiatPayoutSubmitted =
    massFiatPayoutInformation?.status === PaymentStatus.PROCESSING ||
    massFiatPayoutInformation?.status === PaymentStatus.COMPLETED

  const {
    isSidePanelOpen: isPayoutDetailsOpen,
    sidePanelData: payoutDetails,
    paging: pageMassPayout,
    setIsSidePanelOpen: setIsPayoutDetailsOpen,
    updateSidePanel: updatePayoutDetails,
  } = useSidePanel<PreProcessedPayouts>(
    (massFiatPayoutInformation as FiatPayout)?.preProcessedPayouts
  )
  const massPayoutPreProcessedPayouts: PreProcessedPayouts[] =
    massFiatPayoutInformation?.preProcessedPayouts

  const onDeletePreProcessedRecordRequest = (reference: string) => {
    const payload = {
      reference: reference,
    }
    mutateDeletePreProcessedRecord(payload, {
      onSuccess: () => {
        setIsEditPayoutModalVisible(false)
        queryClient.invalidateQueries({ queryKey: ['massFiatPayoutsDetails'] })
        addToast({
          title: t('massPayouts.paymentRemoved'),
          state: 'success',
          delay: 0,
        })
      },
      onError: error => {
        addToast({
          title: t('oopsSomethingWentWrong'),
          state: 'error',
          delay: 10,
        })
        const bvknErrorStatus = error?.status
        if (bvknErrorStatus === 400) {
          // Display callout with appropriate error response
        } else {
          // Display error message sent from the BE.
        }
      },
    })
  }

  const onBackHandler = () => {
    setIsEditPayoutModalVisible(false)
  }

  useEffect(() => {
    setModal({ isOpen: false })
  }, [])

  const onOpenPayoutDetails = (payoutIndex?: number) => {
    updatePayoutDetails(payoutIndex)
    setIsPayoutDetailsOpen(true)
  }

  const handleShowEditFiatPayoutModal = async (
    e: MouseEvent<HTMLButtonElement>,
    payout: PreProcessedPayouts
  ) => {
    e?.stopPropagation()
    setPreProcessedRecordToUpdate(payout)
    setIsEditPayoutModalVisible(true)
  }

  const handleDeleteFiatPayout = async (
    e: MouseEvent<HTMLButtonElement>,
    payout: PreProcessedPayouts
  ) => {
    e?.stopPropagation()
    onDeletePreProcessedRecordRequest(payout?.reference)
  }

  const fiatTable = useMemo(() => {
    return getFiatTableRows({
      massPayoutPreProcessedPayouts,
      massFiatPayoutDetails,
      isFiatPayoutSubmitted,
      handleShowEditFiatPayoutModal,
      onOpenPayoutDetails,
      handleDeleteFiatPayout,
    })
  }, [massFiatPayoutInformation, massPayoutPreProcessedPayouts])

  const fiatBatchPayoutInformation = {
    batchName: massFiatPayoutInformation?.alias,
    totalAmount: massFiatPayoutDetails?.paymentSummary?.totalAmount,
    totalAmountCurrency: massFiatPayoutDetails?.currency,
    totalFee: massFiatPayoutDetails?.paymentSummary?.totalFee,
    totalFeeCurrency: massFiatPayoutDetails?.currency,
    walletName: selectedWallet?.description ?? massFiatPayoutDetails?.walletId,
    walletNameCurrency: selectedWallet?.currency?.code,
  }

  const fiatBatchSummaryInfo = {
    reference: massFiatPayoutInformation?.reference,
    status: massFiatPayoutInformation?.status,
    numberOfPayments: getSumOfRecords(massFiatPayoutInformation?.payoutStatuses).toString(),
    totalBatchAmount: `${roundNumberWithCommas(massFiatPayoutInformation?.paymentSummary?.totalAmount ?? 0)} ${
      massFiatPayoutInformation?.currency
    }`,
    totalBatchFees: `${roundNumberWithCommas(massFiatPayoutInformation?.paymentSummary?.totalFee)} ${
      massFiatPayoutInformation?.currency
    }`,
    totalBatchAmountWithFees: `${roundNumberWithCommas(
      massFiatPayoutInformation?.paymentSummary?.totalAmountWithFee
    )} ${massFiatPayoutInformation?.currency}`,
    numberOfErrors: getSumOfFiatRecordsWithErrors(massFiatPayoutInformation),
  }
  /* #endregion --------------------------------------------  */

  /* #region Crypto ------------------------------------------------- */

  const {
    data: cryptoBatchPayout,
    isLoading: isPendingCryptoMassPayout,
    isError: isCryptoMassPayoutError,
    refetch: refetchCryptoBatchPayout,
  } = useGetCryptoMassPayoutById(batchId, isCrypto && !!batchId)

  const updateCryptoPayout = updateCryptoPayoutSchema(t)

  const {
    control: controlCrypto,
    formState: formCryptoState,
    register: registerCrypto,
    setValue: setCryptoValue,
    trigger: triggerCrypto,
    clearErrors: clearCryptoErrors,
    setError: setCryptoError,
    watch: watchCrypto,
  } = useCryptoForm<UpdateCryptoPayoutFormPayload>({
    resolver: yupResolver(updateCryptoPayout),
  })

  const formCryptoValues = watchCrypto()

  const [cryptoAmount, currency, protocol, recipientAddress, tag, reference] = useCryptoWatch({
    control: controlCrypto,
    name: ['cryptoAmount', 'currency', 'protocol', 'recipientAddress', 'tag', 'reference'],
  })

  useEffect(() => {
    if (cryptoRecordToUpdate) {
      setCryptoValue(
        'cryptoAmount',
        cryptoRecordToUpdate?.fundingWalletAmount ?? cryptoRecordToUpdate?.paidCurrencyAmount
      )
      setCryptoValue('currency', cryptoRecordToUpdate?.currency)
      setCryptoValue('protocol', cryptoRecordToUpdate?.protocol)
      setCryptoValue('recipientAddress', cryptoRecordToUpdate?.address)
      setCryptoValue('tag', cryptoRecordToUpdate?.tag)
      setCryptoValue('reference', cryptoRecordToUpdate?.reference)
    }
  }, [cryptoRecordToUpdate])

  const massCryptoPayoutEditRecordProps = {
    cryptoAmount,
    currency,
    protocol,
    recipientAddress,
    tag,
    reference,
  }

  const handleShowEditCryptoPayoutModal = async (
    e: MouseEvent<HTMLButtonElement>,
    payout: CryptoPayout
  ) => {
    e?.stopPropagation()
    setIsEditPayoutModalVisible(true)
    setCryptoRecordToUpdate(payout)
  }

  const { mutate: mutateUpdateCryptoRecord, isPending: isPendingUpdateCryptoRecord } =
    usePutUpdateMassCryptoPayoutMutation()

  const handleOnCryptoChange = (field: UpdateCryptoPayoutFormPayloadKeys, value: string) => {
    setCryptoValue(field, value)
  }

  const validateCryptoPayout = (field: string) => {
    clearCryptoErrors(field)

    const payload = {
      batchId: batchId,
      externalId: cryptoRecordToUpdate?.externalId,
      fundingWalletAmount: cryptoRecordToUpdate?.fundingWalletAmount ? cryptoAmount : null,
      paidCurrencyAmount: cryptoRecordToUpdate?.paidCurrencyAmount ? cryptoAmount : null,
      currency: currency,
      protocol: protocol,
      address: recipientAddress,
      reference: reference,
      tag: tag || '',
      onlyValidate: true,
    }

    if (!!cryptoRecordToUpdate?.externalId) {
      mutateUpdateCryptoRecord(payload, {
        onSuccess: response => {
          clearCryptoErrors()
        },
        onError: (error: BaseErrorResponse) => {
          const updatedProperties = Object.keys(payload).reduce((updates, key) => {
            if (error?.data && key in error.data) {
              updates[key] = error.data[key]
            }
            return updates
          }, {})

          if (error.data?.errorMessage) {
            updatedProperties.errorMessage = error.data.errorMessage
          }

          setCryptoRecordToUpdate({
            ...cryptoRecordToUpdate,
            ...updatedProperties,
          })
        },
      })
    }
  }

  useEffect(() => {
    validateCryptoPayout('currency')
  }, [currency])

  useEffect(() => {
    validateCryptoPayout('protocol')
  }, [protocol])

  const handleUpdateCryptoPayout = async () => {
    const isValidDetails = await triggerCrypto([
      'cryptoAmount',
      'currency',
      'protocol',
      'recipientAddress',
      'tag',
      'reference',
    ])

    if (isValidDetails) {
      clearCryptoErrors()
      const payload = {
        batchId: batchId,
        externalId: cryptoRecordToUpdate?.externalId,
        fundingWalletAmount: cryptoRecordToUpdate?.fundingWalletAmount ? cryptoAmount : null,
        paidCurrencyAmount: cryptoRecordToUpdate?.paidCurrencyAmount ? cryptoAmount : null,
        currency: currency,
        protocol: protocol,
        address: recipientAddress,
        reference: reference,
        tag: tag || '',
      }

      mutateUpdateCryptoRecord(payload, {
        onSuccess: () => {
          setIsEditPayoutModalVisible(false)
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.massCryptoPayoutById(batchId) })
          setCryptoRecordToUpdate(null)
          addToast({
            title: t('massPayouts.paymentUpdated'),
            state: 'success',
            delay: 0,
          })
        },
        onError: (error: BaseErrorResponse) => {
          setIsEditPayoutModalVisible(false)
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.massCryptoPayoutById(batchId) })
          setCryptoRecordToUpdate(null)
          if (error.status !== 400) {
            addToast({
              title: t('oopsSomethingWentWrong'),
              state: 'error',
              delay: 0,
            })
            logSentryError('Error: mutateUpdateCryptoRecord', error, {
              payload,
            })
          }
        },
      })
    }
  }

  const cryptoBatchPayoutInformation = {
    batchName: cryptoBatchPayout?.fileName,
    walletName: cryptoBatchPayout?.merchantName,
    walletNameCurrency: cryptoBatchPayout?.walletCurrency,
  }

  const isCryptoBatchSubmitted =
    cryptoBatchPayout?.status === BatchStatus.PROCESSING ||
    cryptoBatchPayout?.status === BatchStatus.COMPLETED ||
    cryptoBatchPayout?.status !== BatchStatus.DRAFT

  const { mutate: mutateDeleteCryptoRecord, isPending: isPendingDeleteCryptoRecord } =
    useDeleteCryptoRecordMutation()

  const handleDeleteCryptoPayout = (payout?: CryptoPayout) => {
    mutateDeleteCryptoRecord(
      { batchId: batchId, externalId: payout?.externalId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: reactQueryKeys.massCryptoPayoutById(batchId),
          })
          addToast({
            title: t('massPayouts.paymentRemoved'),
            state: 'success',
            delay: 0,
          })

          setIsEditPayoutModalVisible(false)
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.massCryptoPayoutById(batchId) })
          setCryptoRecordToUpdate(null)
        },
        onError: error => {
          addToast({
            title: t('oopsSomethingWentWrong'),
            state: 'error',
            delay: 10,
          })
          logSentryError('Error: mutateDeleteCryptoRecord', error, {
            batchId,
            externalId: payout?.externalId,
          })
        },
      }
    )
  }

  const cryptoTable = useMemo(() => {
    return getCryptoTableRows({
      cryptoBatchPayout,
      handleDeleteCryptoPayout,
      handleShowEditCryptoPayoutModal,
      onOpenPayoutDetails,
      t,
      cryptoCurrencies,
    })
  }, [cryptoBatchPayout])

  const cryptoBatchSummaryInfo = {
    status: cryptoBatchPayout?.status,
    numberOfPayments:
      Number(cryptoBatchPayout?.totalItems) - Number(cryptoBatchPayout?.totalProcessedItems),
    numberOfErrors: getSumOfRecordsWithErrors(cryptoBatchPayout),
    walletName: cryptoBatchPayout?.merchantName,
    currency: cryptoBatchPayout?.walletCurrency,
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      isCrypto && refetchCryptoBatchPayout()
    }, 5000)

    return () => clearTimeout(timer)
  }, [refetchCryptoBatchPayout])

  /* #endregion Crypto ---------------------------------------------- */

  const isPayoutSubmitted = isCrypto ? isCryptoBatchSubmitted : isFiatPayoutSubmitted

  const numberOfErrors = isCrypto
    ? getSumOfRecordsWithErrors(cryptoBatchPayout)
    : getSumOfFiatRecordsWithErrors(massFiatPayoutInformation)
  const numberOfProcessedPayments = isCrypto
    ? cryptoBatchPayout?.totalProcessedItems
    : getValidRecords(massFiatPayoutInformation?.payoutStatuses)
  const numberOfPayments = isCrypto
    ? cryptoBatchPayout?.totalItems
    : getSumOfRecords(massFiatPayoutInformation?.payoutStatuses)

  const paymentsReadyToBeProcessed = isCrypto
    ? t('massPayouts.paymentsHaveBeenProcessedAndCanBeTracked', {
        numberOfProcessedPayments: numberOfProcessedPayments,
        numberOfPayments: numberOfPayments,
      })
    : t('massPayouts.paymentsReadyToBeProcessed', {
        numberOfProcessedPayments: numberOfProcessedPayments,
        numberOfPayments: numberOfPayments,
      })

  const isPayoutUpdateFormDisabled = () => {
    if (isCrypto) {
      return (
        !cryptoAmount ||
        !currency ||
        !protocol ||
        !recipientAddress ||
        !reference ||
        Object.keys(formCryptoState?.errors)?.length > 0
      )
    }

    return false
  }

  const cryptoActions: ButtonProps[] = isCrypto
    ? [
        {
          children: t('massPayouts.removeFromBatch'),
          testid: 'remove-from-batch',
          secondary: true,
          loading: isPendingDeleteCryptoRecord,
          onClick: () => handleDeleteCryptoPayout(cryptoRecordToUpdate),
        },
        {
          children: t('massPayouts.update'),
          testid: 'update-payout',
          onClick: () => handleUpdateCryptoPayout(),
          loading: isPendingUpdateCryptoRecord,
          disabled: isPayoutUpdateFormDisabled(),
        },
      ]
    : []

  const onCloseEditModal = () => {
    setIsEditPayoutModalVisible(false)
    clearCryptoErrors()
  }

  return (
    <Box className="w-full">
      <MassPayoutHeader
        batchInformation={isCrypto ? cryptoBatchSummaryInfo : fiatBatchSummaryInfo}
        buttonGroup={MassPayoutButtonGroup.BATCH_DETAILS}
      />
      <Box
        flex
        padding={16}
        borderRadius={4}
        className="mb-4"
        border="neutral-thunder"
        justifyContent="between"
      >
        <MassPayoutBatchInformation
          batchInformation={isCrypto ? cryptoBatchPayoutInformation : fiatBatchPayoutInformation}
          isLoading={loadingMassFiatPayoutDetails || isPendingCryptoMassPayout || isLoadingWallets}
        />
      </Box>

      <MassPayoutReviewCallouts
        loadingMassFiatPayoutDetails={loadingMassFiatPayoutDetails}
        cryptoBatchPayout={cryptoBatchPayout}
        massFiatPayoutInformation={massFiatPayoutInformation}
        massPayoutPreProcessedPayouts={massPayoutPreProcessedPayouts}
        isPendingCryptoMassPayout={isPendingCryptoMassPayout}
        isPendingDeleteCryptoRecord={isPendingDeleteCryptoRecord}
        isCrypto={isCrypto}
        paymentsReadyToBeProcessed={paymentsReadyToBeProcessed}
        isPayoutSubmitted={isPayoutSubmitted}
        numberOfErrors={numberOfErrors}
      />

      {(!!massPayoutPreProcessedPayouts?.length ||
        !!cryptoBatchPayout?.items?.length ||
        !isCryptoCurrenciesLoading) && (
        <SimpleTable
          columns={getTableColumns(isCrypto, t)}
          tableData={isCrypto ? cryptoTable : fiatTable}
          isLoading={loadingMassFiatPayoutDetails || isPendingCryptoMassPayout}
        />
      )}
      {!isCrypto && (
        <MassPayoutSidePanel
          massPayout={massFiatPayoutDetails}
          isOpen={isPayoutDetailsOpen}
          preProcessedPayouts={payoutDetails}
          pageMassPayout={pageMassPayout}
          onClose={() => setIsPayoutDetailsOpen(false)}
          isLoadingMassFiatPayoutDetails={loadingMassFiatPayoutDetails}
        />
      )}
      <MassPayoutModal totalPayments={getSumOfRecords(massFiatPayoutInformation?.payoutStatuses)} />
      <MassPayoutEditRecordModal
        key={
          isCrypto
            ? massCryptoPayoutEditRecordProps?.reference
            : preProcessedRecordToUpdate?.reference
        }
        isEditPayoutModalVisible={isEditPayoutModalVisible}
        actions={cryptoActions}
        setIsEditPayoutModalVisible={setIsEditPayoutModalVisible}
        onBackHandler={onBackHandler}
        onCloseModal={onCloseEditModal}
      >
        <ModalScrollable>
          {!isCrypto ? (
            <FiatPayoutEditRecordForm
              record={preProcessedRecordToUpdate}
              setIsEditPayoutModalVisible={setIsEditPayoutModalVisible}
              setPreProcessedRecordToUpdate={setPreProcessedRecordToUpdate}
              walletCurrency={selectedWallet?.currency?.code}
              isDeletingPreProcessedRecord={isLoadingDeletePreProcessedRecord}
              onDeletePreProcessedRecordRequest={onDeletePreProcessedRecordRequest}
            />
          ) : (
            <CryptoPayoutEditRecordForm
              massPayoutEditRecordProps={massCryptoPayoutEditRecordProps}
              errors={formCryptoState.errors}
              handleOnChange={handleOnCryptoChange}
              register={registerCrypto}
              setError={setCryptoError}
              payout={cryptoRecordToUpdate}
              walletName={cryptoBatchPayout?.merchantName}
              walletCurrency={cryptoBatchPayout?.walletCurrency}
              validateCryptoPayout={validateCryptoPayout}
              clearErrors={clearCryptoErrors}
              cryptoCurrencies={cryptoCurrencies}
            />
          )}
        </ModalScrollable>
      </MassPayoutEditRecordModal>
    </Box>
  )
}
