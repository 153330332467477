import React from 'react'
import clsx from 'clsx'
import { Box } from '@node-space/storybook-components'
import { Text } from '@node-space/storybook-components/dist/Text'

interface CustomerDetailRowProps {
  label: string
  detail: React.ReactNode
  isColumn?: boolean
}

export const CustomerDetailRow = ({ label, detail, isColumn = false }: CustomerDetailRowProps) => {
  return (
    <Box
      className={clsx({
        'lg:flex-row': !isColumn,
      })}
      flex
      direction="col"
      paddingT={4}
      paddingB={4}
    >
      <Text tag="span" size="sm" color="disabled" className="md:w-2/5">
        {label}
      </Text>
      <Text
        tag="span"
        size="sm"
        weight="medium"
        color="dark-grey"
        className={clsx('md:w-4/5 flex justify-start', {
          'lg:justify-end': !isColumn,
        })}
      >
        {detail}
      </Text>
    </Box>
  )
}
