import React from 'react'
import { Navigate } from 'react-router-dom'
import { PathNames } from 'constants/General'
import { useMappedRoles } from 'hooks/useMappedRoles'

const ProtectedComponent = ({
  children,
  featureName,
}: {
  children: React.ReactElement
  featureName: string
}) => {
  const permissions = useMappedRoles()

  // If not, return element that will navigate to 404 page
  return permissions?.[featureName]?.canView ? children : <Navigate to={PathNames.NOT_FOUND} />
}

export default ProtectedComponent
