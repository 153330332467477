import React, { useEffect, useState } from 'react'
import { useDocumentTitle } from '@mantine/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { RecaptchaProps, RecaptchaVersion } from 'recaptcha/types'
import { useRecaptcha } from 'recaptcha/useRecaptcha'
import { logError } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { useAuthenticatedPathRedirect } from 'hooks/useAuthenticatedPathRedirect'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { WorkspaceListItemId } from 'types/types'
import useAuthRedirect from 'utils/auth/hooks/useAuthRedirect'
import { deleteProfileCookie } from 'utils/utils'
import LoginForm from './components/LoginForm'
import TwoFactorAuth from './TwoFactorAuth'
import WorkplacesAuth from './WorkplacesAuth'

const Login = () => {
  useDocumentTitle(`${process.env.SITE_TITLE}`)
  const token = useAuthRedirect()
  const { redirectWithState } = useAuthenticatedPathRedirect()

  const { isLoadingRecaptcha, loadRecaptcha, getRecaptchaToken, unloadRecaptcha } =
    useRecaptcha('login')

  const initialLoginDetails: PostLoginReq = {
    emailAddress: '',
    password: '',
    recaptcha: '',
  }

  const [showTwoFactor, setShowTwoFactor] = useState(false)
  const [requiresUserSelection, setRequiresUserSelection] = useState(false)
  const [workspaceList, setWorkspaceList] = useState([])
  const [loginData, setLoginData] = useState<PostLoginReq>(initialLoginDetails)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!token) {
      deleteProfileCookie()
    }
  }, [token])

  const invalidateQueries = async () => {
    try {
      await queryClient.invalidateQueries({ queryKey: reactQueryKeys.profile() })
      await queryClient.invalidateQueries({ queryKey: reactQueryKeys.verification })
    } catch (error) {
      console.error('Error: invalidateQueries', error)
    }
  }

  const setSuccessfulLogin = async () => {
    try {
      unloadRecaptcha()
      await invalidateQueries()
      redirectWithState(PathNames.AUTHENTICATE)
    } catch (error) {
      console.error('Error: setSuccessfulLogin', error)
    }
  }

  const setRequiresWorkspaceSelection = async (workspaces: [WorkspaceListItemId]) => {
    try {
      setWorkspaceList(
        workspaces?.map(workspace => {
          return {
            label: workspace.name,
            value: workspace.id,
          }
        })
      )
      setRequiresUserSelection(true)
      await invalidateQueries()
    } catch (error) {
      console.error('Error: setRequiresWorkspaceSelection', error)
    }
  }

  const getNewRecaptchaToken = async (): Promise<string> => {
    return getRecaptchaToken()
      .then(token => token)
      .catch(error => {
        logError('Error from Login - getRecaptchaTokenV3', error)
        return ''
      })
  }

  const recaptcha: RecaptchaProps = {
    version: RecaptchaVersion.V3,
    isLoading: isLoadingRecaptcha,
    getToken: getNewRecaptchaToken,
  }

  useEffect(() => {
    loadRecaptcha()
  }, [])

  return (
    <>
      {requiresUserSelection && (
        <WorkplacesAuth
          loginData={loginData}
          recaptcha={recaptcha}
          workspaceList={workspaceList}
          setShowTwoFactor={setShowTwoFactor}
          setRequiresUserSelection={setRequiresUserSelection}
          setSuccessfulLogin={setSuccessfulLogin}
        />
      )}
      {showTwoFactor && !requiresUserSelection && (
        <TwoFactorAuth
          loginData={loginData}
          recaptcha={recaptcha}
          setLoginData={setLoginData}
          setRequiresWorkspaceSelection={setRequiresWorkspaceSelection}
          setSuccessfulLogin={setSuccessfulLogin}
        />
      )}
      {!showTwoFactor && !requiresUserSelection && (
        <LoginForm
          loginData={loginData}
          recaptcha={recaptcha}
          setLoginData={setLoginData}
          setShowTwoFactor={setShowTwoFactor}
          setRequiresWorkspaceSelection={setRequiresWorkspaceSelection}
          setSuccessfulLogin={setSuccessfulLogin}
        />
      )}
    </>
  )
}

export default Login
