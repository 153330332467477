import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Modal, ModalNavBar, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { ApiKeyRequestForm } from 'types/apiKeys'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { ApiKeyModalContext } from '../../context/ApiKeyModalProvider'
import ApiKeyCreateForm from './../Form/ApiKeyCreateForm'

type PaymentCreateModalProps = {
  from2FA?: boolean
  onSuccessCreateApiKey: (value: ApiKeyRequestForm) => void
}

const ApiKeyCreateModal = ({ onSuccessCreateApiKey, from2FA = false }: PaymentCreateModalProps) => {
  const { t } = useTranslation()
  const { showCreateModal, setShowCreateModal, enablePayrollRole } = useContext(ApiKeyModalContext)

  useEffect(() => {
    if (showCreateModal) {
      track.Amp.track(AmplitudeEvent.API_KEY_INITIATE, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
  }, [showCreateModal])

  return (
    <Modal visible={showCreateModal} closeModal={() => setShowCreateModal(false)}>
      <ModalNavBar
        title={t('manageApiKeys.generateNewAPIKey')}
        onClose={() => setShowCreateModal(false)}
      />
      <ModalScrollable>
        {from2FA && <Callout state="success" message={t('2FAEnabled')}></Callout>}
        <ApiKeyCreateForm
          onSubmit={(formData: ApiKeyRequestForm) => onSuccessCreateApiKey(formData)}
          dismissAction={() => setShowCreateModal(false)}
          enablePayrollRole={enablePayrollRole}
        />
      </ModalScrollable>
    </Modal>
  )
}

export default ApiKeyCreateModal
