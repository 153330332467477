import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { createApiKey, deleteApiKey, editApiKey } from 'services/ApiKeysService'
import { ApiKeyCreateAPIRequest } from 'types/apiKeys'

export const useEditApiKeyMutation = () => {
  return useMutation({
    mutationFn: ({ payload, id }: { id: number; payload: ApiKeyCreateAPIRequest }) =>
      editApiKey(payload, id),
  })
}

export const useAddApiKeyMutation = () => {
  return useMutation({ mutationFn: (payload: ApiKeyCreateAPIRequest) => createApiKey(payload) })
}

export const useDeleteApiKeyMutation = () => {
  return useMutation({
    mutationFn: (apiKey: string) => deleteApiKey(apiKey),
    onError(error: { status: number; message?: string; errorList: unknown }) {
      console.error('Error: Deleting API keys request', error)
      logSentryError('Error: Deleting API keys request', error)
    },
  })
}
