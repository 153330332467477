import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postBvnkNetworkTransferRequest } from 'services/BvnkNetworkTransferService'

export const useBvnkNetworkTransferMutation = () => {
  return useMutation({
    mutationFn: postBvnkNetworkTransferRequest,
    onError: (error, data) => {
      logSentryError('Error: useBvnkNetworkTransferMutation', error, data)
    },
  })
}
