import { ethers } from 'ethers'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import {
  NotabeneAssetInfoResponse,
  NotabeneError,
  NotabeneErrorData,
  NotabeneTransaction,
} from 'types/notabene/notabene'
import { logError } from '@node-space/utils'

export const NotabeneSendScreen = {
  DETAILS: 'DETAILS',
  WIDGET: 'WIDGET',
  ASSET_NOT_SUPPORTED: 'ASSET_NOT_SUPPORTED',
  ERROR: 'ERROR',
} as const

export type NotabeneSendScreen = (typeof NotabeneSendScreen)[keyof typeof NotabeneSendScreen]

/**
 * The themeing object that allows us to override some basic colours and fonts
 * @returns theme for the Notabene widget
 */
export const notabeneWidgetTheme = {
  primaryColor: '#8e41e8', // var(--colour-primary-500)
  secondaryColor: '#3850dd', // var(--colour-secondary-500)
  primaryFontColor: '#1c2127', // var(--colour-text-900)
  secondaryFontColor: '#51627', // var(--colour-text-500)
  backgroundColor: '#ffffff', // var(--colour-neutral-cotton)
}

/**
 * Provides some basic styles for the outer widget containers
 * @returns css styles as a string
 */
export const notabeneWidgetStyles = `
  #notabeneWidgetContainer div {
      width: 100%;
  } 
  
  #notabeneWidgetContainer {
      position: relative;
      width: 100% !important;
      min-height: 500px;
  }
  
  .nb-overlay {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      background: #ffffff !important;
      justify-center: left !important;
      top: -50px !important;
  }
`

/**
 * Converts decimal numbers to the smallet unit of a given coin
 *
 * When creating or validating a travel rule transfer, the
 * Notabene APIs do not support commas or dots and therefore all
 * amounts have to be expressed as integers of the smallest
 * denominator of a coin. For example, if you want to create a
 * transaction of 1 ETH in the payload of txCreate, you will
 * have to express it in WEI by putting 1 followed by 18 zeros to get the correct amount.
 *
 * Example: 1 ETH = 1000000000000000000 WEI
 * @param amount Amount of crypto to send
 * @param decimals Amount of decimals specified for a given crypto asset
 * @returns the amount of crypto in a coins smallest unit (1000000000000000000 WEI)
 */
const convertToNotabeneAmount = (amount: number | string, decimals: number) => {
  return ethers.parseUnits(amount.toString(), decimals).toString()
}

/**
 * Orcherates the Notabene transaction to initialise the widget
 * Example: 1 ETH = 1000000000000000000 WEI
 * @param transactionDetails Amount and address to send to
 * @param selectedAsset The asset to send (Ex: ETH, BTC, XRP, etc)
 * @returns {NotabeneTransaction} The Notabene transaction to initialise the widget
 */
export const getNotabeneTransaction = (
  transactionDetails: { address: string; amount: number | string },
  selectedAsset: NotabeneAssetInfoResponse
): NotabeneTransaction => {
  return {
    transactionAsset: selectedAsset?.code,
    beneficiaryAccountNumber: transactionDetails?.address,
    transactionAmount: convertToNotabeneAmount(transactionDetails?.amount, selectedAsset?.decimals),
  } as NotabeneTransaction
}

/**
 * Handles changes in the transaction state
 * @param valid The validity of the transaction at the given time
 * @param NotabeneTransaction The transaction data
 * @param setTravelRuleCallback Callback function to update the current transaction
 * @param setNotabeneTxIsValidCallback Callback function to update the current transaction validity
 * @returns {NotabeneTransaction} The Notabene transaction to initialise the widget
 */
export const handleValidStateChange = (
  valid: boolean,
  NotabeneTransaction: NotabeneTransaction,
  setTravelRuleCallback: (transaction: NotabeneTransaction) => void,
  setNotabeneTxIsValidCallback: (valid: boolean) => void
) => {
  const { ivms, ...rest } = NotabeneTransaction
  if (ivms) {
    const { beneficiary } = ivms
    const transaction = { provider: 'Notabene', beneficiary, ...rest }

    setTravelRuleCallback(transaction)
    setNotabeneTxIsValidCallback(valid)
  }
}

/**
 * Handles error on the transaction
 * @param notabeneError Error returned from the widget
 * @param setNotabeneError Callback function to set the error
 * @returns {void}
 */
export const handleError = (
  notabeneError: NotabeneError,
  setNotabeneError: (notabeneError: NotabeneErrorData) => void
) => {
  track.Amp.track(AmplitudeEvent.WALLET_LOAD_NOTABENE_WIDGET_ERROR, {
    category: AmplitudeEventCategory.WALLET,
    action: AmplitudeEventAction.LOAD,
    error: notabeneError.data,
    integration: 'Notabene',
    section: 'Wallet - Send',
  })

  logError('Error from Notabene - handleError', notabeneError)
  setNotabeneError(notabeneError.data)
}
