import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { useCustomerMutation } from 'hooks/mutations/Customers/useCustomerMutation'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { useToastContext } from 'hooks/useToastContext'
import { AddCustomerPayload, BusinessCustomerType, IndividualCustomerType } from 'types/customers'
import { ErrorResponse } from 'types/types'
import { ModalStep } from '../types'
import { getCustomerFields } from '../utils'
import { AddCustomerStep } from './AddCustomerStep'

interface AddCustomerProps {
  handleCloseModal: () => void
  handleOnSuccessCloseModal: () => void
}

export const AddCustomer = ({ handleCloseModal, handleOnSuccessCloseModal }: AddCustomerProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.ADD_CUSTOMER)
  const [customerFormState, setCustomerFormState] = useState<AddCustomerPayload>(
    {} as AddCustomerPayload
  )
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { mutate: submitCustomer, isPending: isLoading } = useCustomerMutation()

  const modalHeader = useMemo(() => {
    if (modalStep === ModalStep.CONFIRMATION) {
      return t('customers.confirmDetails')
    }

    return t('customers.newCustomer')
  }, [modalStep])

  const submit = () => {
    const customer = getCustomerFields(customerFormState)

    submitCustomer(customer as BusinessCustomerType | IndividualCustomerType, {
      onSuccess: () => {
        handleOnSuccessCloseModal()
        addToast({ title: t('customers.addCustomer.success'), state: 'success' })
        queryClient.invalidateQueries({ queryKey: ['customers'] })
      },
      onError: (error: ErrorResponse) => {
        addToast({ title: t('customers.addCustomer.error'), state: 'error' })
        const sentryTraceId = logSentryErrorAndGetTraceId('Error: could not add customer', error)
        setRequestError({ show: true, errorCode: error.status, sentryTraceId })
      },
    })
  }

  const appendCustomerDetails = async (customerDetails: AddCustomerPayload) => {
    setCustomerFormState({ ...customerFormState, ...customerDetails })
    setModalStep(ModalStep.CONFIRMATION)
  }

  const onBackHandler = () => {
    switch (modalStep) {
      case ModalStep.ADD_CUSTOMER:
        handleCloseModal()
        break
      case ModalStep.CONFIRMATION:
        requestError && resetRequestError()
        setModalStep(ModalStep.ADD_CUSTOMER)
        break
      default:
        break
    }
  }

  return (
    <>
      <ModalNavBar title={modalHeader} onClose={handleCloseModal} onBack={onBackHandler} />
      <AddCustomerStep
        modalStep={modalStep}
        customerFormState={customerFormState}
        onBackHandler={onBackHandler}
        submit={submit}
        appendCustomerDetails={appendCustomerDetails}
        isLoading={isLoading}
        requestError={requestError}
        resetRequestError={resetRequestError}
      />
    </>
  )
}
