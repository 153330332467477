import { includes } from 'lodash-es'
import { daysOfWeek } from 'pages/WalletDetails/components/RecentActivity/WalletTransactionsExport/constants/constants'
import { ReportScheduleTimeZone } from 'types/walletTransactionsExport'

export const getDayOfWeek = (id: number) => {
  const day = daysOfWeek?.find(day => Number(day?.value) === id)

  return day?.label
}

export const getUserTimezone = () => {
  const localDate = new Date()

  const timezoneOffset = localDate?.getTimezoneOffset()

  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
  const offsetMinutes = Math.abs(timezoneOffset) % 60
  const sign = timezoneOffset > 0 ? '-' : '+'
  const formattedOffset = `UTC${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`

  return formattedOffset
}

export const getUserDefaultTimezone = (timezones: ReportScheduleTimeZone[]) => {
  const userTimezone = getUserTimezone()
  const timezone = timezones?.find(timezone => includes(timezone?.label, userTimezone))

  return timezone
}
