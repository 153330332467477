import { requestApi } from 'api/api'
import { NotabeneAssetInfoResponse } from 'types/notabene/notabene'

export const getNotabeneCustomerToken = (customerRef: string) => {
  return requestApi({
    url: '/travel-rule/v1/notabene/customer-token',
    method: 'POST',
    data: { customerRef },
  })
}

export const getNotebeneAssetInfo = (assetName: string): Promise<NotabeneAssetInfoResponse> => {
  return requestApi({
    url: `/travel-rule/v1/notabene/assets/${assetName}`,
    method: 'GET',
  })
}
