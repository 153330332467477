import { request } from '@node-space/rest-api'
import { RequestProps } from '@node-space/rest-api/lib/api'

export const customHeaders = {
  'x-requester': process.env.BASE_URL,
  'x-tag': 'merchant-portal',
}

export const customHeaderWithoutXSource = {
  'x-requester': process.env.BASE_URL,
  'x-tag': 'merchant-portal',
}

export const requestApi = ({
  url,
  method,
  data,
  params,
  admin,
  authenticated,
  impersonate,
  debug,
  access_token,
  secret,
  baseUrl,
  fullResponse,
  bff,
  timeout,
  config,
  headers = customHeaders,
}: RequestProps) => {
  return request({
    url,
    method,
    data,
    params,
    headers,
    admin,
    authenticated,
    impersonate,
    debug,
    access_token,
    secret,
    baseUrl,
    fullResponse,
    bff,
    timeout,
    config,
  })
}
