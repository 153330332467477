import { WithPaginationPaging } from 'types/pagination'

export const API_PAGINATION_LIMIT = 10
export const API_PAGINATION_OFFSET = 0

/**
 * Helper function for transforming API Reponses with Pagination (Pageable) payloads to Response with `pagination` key in Paging response
 * @returns WithPaginationPaging<WHATEVER_TYPE_SHOULD_BE_IN_CONTENT_KEY>
 */
export const withPaginationPaging = <T>({
  content,
  size,
  number,
  totalElements,
}: {
  content: T
  size: number
  number: number
  totalElements: number
}): WithPaginationPaging<T> => {
  return {
    content,
    pagination: {
      max: size,
      offset: number,
      total: totalElements,
      page: number,
    },
  }
}
