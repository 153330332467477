import { useEffect, useState } from 'react'

/**
 * Returns the dynamic height of the component with the queried id
 * if the component present and 0 if not
 * @param {string} componentId - the id of the component of which you want to use the height
 * @returns {number}
 */
export const useComponentHeight = (componentId: string): number => {
  const [storedHeight, setStoredHeight] = useState(0)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const updatedHeight = entries?.[0]?.contentRect?.height
      setStoredHeight(updatedHeight)
    })

    const component = document.getElementById(componentId)

    if (component) {
      resizeObserver.observe(component)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [componentId])

  return storedHeight
}
