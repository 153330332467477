import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import AddCryptoAddressModal from './AddModal/AddCryptoAddressModal'

interface CryptoAddressesHeaderProps {
  showAddCryptoAddressModal: boolean
  onAddCryptoSuccess: () => void
  handleClose: () => void
  setShowAddCryptoAddressModal: Dispatch<SetStateAction<boolean>>
  resetCryptoTable: () => void
  canEdit: boolean
}

const CryptoAddressesHeader = ({
  showAddCryptoAddressModal,
  onAddCryptoSuccess,
  handleClose,
  setShowAddCryptoAddressModal,
  canEdit,
  resetCryptoTable,
}: CryptoAddressesHeaderProps) => {
  const { t } = useTranslation()

  return (
    <>
      {showAddCryptoAddressModal && canEdit && (
        <AddCryptoAddressModal
          closeModal={handleClose}
          onAddCryptoSuccess={onAddCryptoSuccess}
          resetCryptoTable={resetCryptoTable}
        />
      )}
      <PageHeader
        description={
          <Text size="sm" color="grey" className="flex-auto whitespace-pre-line">
            {t('manageCryptoAddresses.subtitle')}
          </Text>
        }
        renderRightSection={
          canEdit && (
            <Button
              iconElement={<Icon name="PlusIcon" color="white" />}
              onClick={() => setShowAddCryptoAddressModal(true)}
              disabled={!canEdit}
            >
              {t('manageCryptoAddresses.addAddressBtn')}
            </Button>
          )
        }
      />
    </>
  )
}

export default CryptoAddressesHeader
