import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { Skeleton, SkeletonCard } from '@node-space/storybook-components/dist/SkeletonLoader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Approval } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useApprovalsPreference } from 'hooks/useApprovalsPreference'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { usePendingApprovalDetails } from 'hooks/usePendingApprovalDetails'
import { ApprovalDecisionStateType, ApprovalStatuses } from 'types/approvals'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { ApprovalContextProvider } from '../../contexts/approvalsContext'
import ApprovalDecisionModal from './components/ApprovalDecisionModal'
import ApprovalHeaderButtons from './components/ApprovalHeaderButtons'
import ApprovalPreferenceSettingsModal from './components/ApprovalPreferenceSettingsModal'
import ApprovalPreferenceSettingsModalV2 from './components/ApprovalPreferenceSettingsModalV2'
import ApprovalPreferenceSuccessModal from './components/ApprovalPreferenceSuccessModal'
import ApprovalTabs from './components/ApprovalTabs'
import { useEnabledApprovalFlows } from './components/hooks/useApprovalsFlowSettings'
import { useCheckUnapprovableTransactions } from './components/hooks/useCheckUnapprovableTransactions'
import ManageApproversModal from './components/ManageApproversModal'

const Approvals = () => {
  const { t } = useTranslation()
  const { tab } = queryString.parse(window.location.search) as { tab?: 'APPROVED' | 'REJECTED' }

  const { currentAccount } = useAccountsContext()
  const {
    profile: { emailAddress: currentEmailAddress },
  } = useProfileContext()
  const { enableApprovalGlobalAccountRule } = useFeatureFlags()
  const permissions = useMappedRoles()?.manageTeamMemberApprovers
  const approvalPermissions = useMappedRoles()?.manageApprovals
  const canEdit = permissions?.canEdit
  const canManageApproval = approvalPermissions?.canEdit

  const currentAccountReference = currentAccount?.reference
  const [showApprovalPreferenceModal, setShowApprovalPreferenceModal] = useState(false)
  const [showApprovalPreferenceSuccessModal, setShowApprovalPreferenceSuccessModal] =
    useState(false)
  const [showManageApproversModal, setShowManageApproversModal] = useState(false)
  const [approvalDecision, setApprovalDecision] = useState<ApprovalDecisionStateType>({
    decision: null,
    showApprovalDecisionModal: false,
    isCompleted: false,
  })
  const [checkedRowData, setCheckedRowData] = useState<unknown[]>([])
  const [activeTab, setActiveTab] = useState(tab || ApprovalStatuses.PENDING)

  const {
    approvalFlowPreferences,
    isApprovalPreferenceError,
    isApprovalPreferenceSuccess,
    isFetchingApprovalPreference,
  } = useApprovalsPreference(currentAccountReference, !!currentAccountReference)

  const enabledApprovalFlowsTranslation = useEnabledApprovalFlows(
    approvalFlowPreferences?.preferences
  )

  const {
    pendingApprovals,
    numberOfPendingApprovals,
    hasPendingApprovals,
    isPendingApprovalsError,
    isPendingApprovalsSuccess,
    isFetchingPendingApprovals,
  } = usePendingApprovalDetails(currentAccountReference, !!currentAccountReference)

  const isError = isApprovalPreferenceError || isPendingApprovalsError
  const isSuccess = isApprovalPreferenceSuccess && isPendingApprovalsSuccess
  const isFetching = isFetchingApprovalPreference || isFetchingPendingApprovals

  const unapprovableTransactions = useCheckUnapprovableTransactions(
    checkedRowData,
    currentEmailAddress
  )

  useEffect(() => {
    setApprovalDecision({ ...approvalDecision, isCompleted: false })
    track.Amp.track(AmplitudeEvent.APPROVALS_HOME, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      selectedItems: checkedRowData?.length,
    })
  }, [])

  const handleTableRowCheck = useCallback((value: SetStateAction<unknown[]>) => {
    setCheckedRowData(value)
  }, [])

  const handleApprovalPreferenceSettingsModal = useCallback(
    ({ isVisible }: { isVisible: boolean }) => {
      setShowApprovalPreferenceModal(isVisible)
    },
    [showApprovalPreferenceModal]
  )

  const handleApprovalPreferenceSuccessModal = useCallback(
    ({ isVisible }: { isVisible?: boolean }) => {
      setShowApprovalPreferenceSuccessModal(isVisible)
    },
    [showApprovalPreferenceSuccessModal]
  )

  const handleManageApproversModal = useCallback(
    ({ isVisible }: { isVisible: boolean }) => {
      setShowManageApproversModal(isVisible)
      isVisible &&
        track.Amp.track(AmplitudeEvent.MANAGE_APPROVERS_INITIATE, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
        })
    },
    [showManageApproversModal]
  )

  const handleApprovalDecisionModal = useCallback(
    ({ isVisible, decision, isCompleted = false }) => {
      setApprovalDecision({
        ...approvalDecision,
        showApprovalDecisionModal: isVisible,
        decision,
        isCompleted,
      })
      const titleOfEventInput =
        decision === Approval.DECISION.APPROVE
          ? AmplitudeEvent?.APPROVE_TRANSACTION_INITIATE
          : AmplitudeEvent?.REJECT_TRANSACTION_INITIATE
      decision &&
        track.Amp.track(titleOfEventInput, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
          selectedItems: checkedRowData?.length,
        })
    },
    [approvalDecision]
  )

  return (
    <ApprovalContextProvider
      permissions={{ canEdit, canManageApproval }}
      pendingApprovalDetails={{ pendingApprovals, numberOfPendingApprovals, hasPendingApprovals }}
      approvalsConfig={{
        approvalPreferences: approvalFlowPreferences?.preferences,
        approvalPreferencesLimits: approvalFlowPreferences?.limits,
        allApprovalFlowsAreDisabled: !!!enabledApprovalFlowsTranslation,
        approvalDecisionIsCompleted: approvalDecision?.isCompleted,
        handleTableRowCheck,
        handleManageApproversModal,
        handleApprovalDecisionModal,
        handleApprovalPreferenceSettingsModal,
        handleApprovalPreferenceSuccessModal,
      }}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
    >
      {showManageApproversModal && <ManageApproversModal />}
      {approvalDecision?.showApprovalDecisionModal && (
        <ApprovalDecisionModal
          decision={approvalDecision?.decision}
          selectedItems={checkedRowData}
        />
      )}

      {showApprovalPreferenceModal &&
        (enableApprovalGlobalAccountRule ? (
          <>
            {showApprovalPreferenceSuccessModal ? (
              <ApprovalPreferenceSuccessModal />
            ) : (
              <ApprovalPreferenceSettingsModalV2 />
            )}
          </>
        ) : (
          <ApprovalPreferenceSettingsModal />
        ))}

      {!isApprovalPreferenceError && isFetchingApprovalPreference && (
        <Box width="full" role="skeletonTab">
          <SkeletonCard name="approvalsSkeletonCard" />
        </Box>
      )}
      {isApprovalPreferenceError && !isFetchingApprovalPreference && (
        <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />
      )}
      {isApprovalPreferenceSuccess && !isFetchingApprovalPreference && (
        <>
          <PageHeader
            description={
              hasPendingApprovals || enabledApprovalFlowsTranslation ? (
                <Text size="sm" color="grey" className="mr-2">
                  {isFetchingApprovalPreference ? (
                    <Skeleton className="w-2/5" variant="text-xs" />
                  ) : enabledApprovalFlowsTranslation ? (
                    `${t('approvals.enabledOutgoingPayments')} ${enabledApprovalFlowsTranslation}.`
                  ) : (
                    t('approvals.disabledApprovalsHeadline')
                  )}
                  <Button
                    className="ml-1"
                    noStyling
                    onClick={() => setShowApprovalPreferenceModal(true)}
                  >
                    <Text size="sm" color="primary-500">
                      {canEdit
                        ? t('approvals.editApprovalPreference')
                        : t('approvals.viewApprovalPreference')}
                    </Text>
                  </Button>
                </Text>
              ) : (
                <Text size="sm" color="grey" className="mr-2">
                  {t('approvals.defaultPageHeaderDescription')}
                </Text>
              )
            }
            renderRightSection={
              hasPendingApprovals && (
                <ApprovalHeaderButtons
                  activeTab={activeTab}
                  hasCheckedPendingTransactions={!!checkedRowData?.length}
                  cannotApprove={unapprovableTransactions}
                />
              )
            }
          />
          <ApprovalTabs queryTab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />
        </>
      )}
    </ApprovalContextProvider>
  )
}

export default Approvals
