import React, { Dispatch, SetStateAction, useState } from 'react'
import { isEmpty } from 'lodash'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import { WalletActionType } from 'types/wallets'
import { TableSectionItem } from '../../types'
import { WalletModalAction } from './WalletModalAction'

export type WalletAction = {
  key: WalletActionType
  data?: TableSectionItem | { id?: string }
  metadata?: {
    pay?: {
      beneficiaryPaymentType: 'fiat' | 'network'
      beneficiaryOptionId?: string
    }
  }
}

export type WalletActionSharedProps = {
  walletId: string
  actionWalletId: string
  setWalletId: Dispatch<SetStateAction<string>>
  onModalClose: () => void
}

interface ModalProps {
  action: WalletAction
  setWalletModalAction: Dispatch<SetStateAction<WalletAction>>
  refresh: () => void
  setShowWalletModal?: Dispatch<SetStateAction<boolean>>
}

export interface WalletTabProps {
  action: WalletAction
  walletId?: string
  setWalletId?: Dispatch<SetStateAction<string>>
  refresh: () => void
  step?: string
}

const WalletModal = ({ action, setWalletModalAction, refresh, setShowWalletModal }: ModalProps) => {
  // shared wallet id to persist selected wallet state across tabs
  const [walletId, setWalletId] = useState<string>()

  // clear state on modal close
  const onModalClose = () => {
    setWalletModalAction(undefined)
    setWalletId(undefined)
    setShowWalletModal?.(false)
  }

  return (
    <Modal visible={!isEmpty(action)} closeModal={() => onModalClose()}>
      <WalletModalAction
        action={action}
        walletId={walletId}
        setWalletId={setWalletId}
        refresh={refresh}
        onModalClose={onModalClose}
      />
    </Modal>
  )
}

export default WalletModal
