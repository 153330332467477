import React, { ReactNode } from 'react'
import i18next from 'i18next'
import { Box as Row } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { copyButtonIconColour } from 'styles/theme/colours'
import { TransactionDetailRow } from '../TransactionDetailRow'

interface CopyTransactionRowProps {
  label: string
  content: string
  children?: ReactNode
}

const CopyTransactionRow = ({ label, content, children }: CopyTransactionRowProps) => {
  return (
    <TransactionDetailRow
      label={label}
      detail={
        <Row flex flexItemsCenter>
          <Text tag="span" size="sm" weight="medium" className="md:text-right">
            {ellipseMiddleText(content, 20, 10)}
          </Text>
          <CopyButton
            copyText={content}
            confirmationMessage={i18next.t('copiedToClipboard')}
            iconColour={copyButtonIconColour}
            confirmationMessageAlignment="left"
            confirmationMessageTheme="dark"
          />
          {children}
        </Row>
      }
    />
  )
}

export default CopyTransactionRow
