import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputLabel } from '@node-space/storybook-components'

interface Enable2FANoticeModalProps {
  password: string
  isLoading: boolean
  hasError: boolean
  onChange: (val: string) => void
}

const Enable2FAPasswordModal = ({
  password,
  isLoading,
  hasError,
  onChange,
}: Enable2FANoticeModalProps) => {
  const { t } = useTranslation()

  return (
    <>
      <InputLabel>{t('enable2FAAuthenticationNotice')}</InputLabel>
      <Input
        placeholder={t('password')}
        type="password"
        name="password"
        testid="password-2FA-input"
        value={password}
        disabled={isLoading}
        onChange={e => {
          onChange(e.target.value)
        }}
        error={hasError}
        errorText={t('incorrectPassword')}
      />
    </>
  )
}

export default Enable2FAPasswordModal
