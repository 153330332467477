import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Callout, Input } from '@node-space/storybook-components'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { PathNames } from 'constants/General'
import { useResetPasswordMutation } from 'hooks/mutations/usePasswordMutation'
import { BaseErrorResponse } from 'types/beneficiaries'
import { applyPasswordTests } from 'utils/password'

export default function PasswordReset() {
  const { t } = useTranslation()
  const location = useLocation()
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [displayErrors, setDisplayErrors] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const tokenObject = queryString.parse(location.search)
  const defaultErrorMessage = t('oopsSomethingWentWrong')
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)
  const validationResult = applyPasswordTests(newPassword, t)

  const { mutate: resetPassword, isPending } = useResetPasswordMutation()

  const validateMatch = () => {
    if (newPassword === confirmPassword) {
      setDisplayErrors(false)
      setConfirmPasswordError(false)
    } else {
      setConfirmPasswordError(true)
    }
  }

  const validatePassword = () => {
    setNewPasswordError(!validationResult?.isValid)
  }

  useEffect(() => {
    validateMatch()
    validatePassword()
  }, [confirmPassword, newPassword])

  const submitRequest = () => {
    resetPassword(
      { newPassword, token: tokenObject?.token },
      {
        onSuccess: () => {
          window.location.href = PathNames.DASHBOARD
        },
        onError: (error: BaseErrorResponse) => {
          if (error.status === 412) {
            error?.data?.errorList?.map(err => {
              if (err?.parameter === 'password' && err?.code === 'complexity') {
                setErrorMessage(err?.message)
              }
            })
          } else {
            setErrorMessage(defaultErrorMessage)
          }
          setShowErrorBanner(true)
        },
      }
    )
  }

  const onSubmit = () => {
    validateMatch()
    setDisplayErrors(true)
    if (!newPasswordError && !confirmPasswordError) {
      submitRequest()
    }
  }

  const handleConfirmPasswordChange = password => {
    setConfirmPassword(password.target.value)
  }

  const handleNewPasswordChange = password => {
    setNewPassword(password.target.value)
    validatePassword()
  }

  return (
    <LoginPageLayout title={t('newPasswordHeader')}>
      {showErrorBanner && <Callout state="error" message={errorMessage} />}
      <Input
        type="password"
        placeholder={t('newPassword')}
        error={newPasswordError && displayErrors}
        errorText={validationResult?.message}
        value={newPassword}
        onChange={handleNewPasswordChange}
      />
      <Input
        type="password"
        placeholder={t('confirmPassword')}
        error={confirmPasswordError && displayErrors}
        errorText={t('matchingPasswordsError')}
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />
      <Button
        disabled={(newPasswordError || confirmPassword) && displayErrors}
        loading={isPending}
        full
        onClick={onSubmit}
      >
        {t('saveNewPassword')}
      </Button>
    </LoginPageLayout>
  )
}
