import React from 'react'
import { Box, Box as SkeletonIcon } from '@node-space/storybook-components/dist/Box'

interface CellSkeletonProps {
  skeletalIcon?: boolean
}

export const TableDataSkeleton = ({ skeletalIcon = true }: CellSkeletonProps) => {
  return (
    <Box flex flexItemsCenter flexJustifyCenter>
      {skeletalIcon && (
        <SkeletonIcon size={32} borderRadius="full" background="gray" className="animate-pulse" />
      )}
      <div className="h-2 rounded bg-gray-02 animate-pulse w-20 ml-2" />
    </Box>
  )
}
