import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Box, Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import FilterBar from 'components/FilterBar'
import NoSearchResults from 'components/NoSearchResults/NoSearchResults'
import TablePagination from 'components/TablePagination'
import { PathNames } from 'constants/General'
import { useChannelsQuery, useMerchantsQuery } from 'hooks/queries'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useMounted } from 'hooks/useMounted'
import { ChannelPayment } from 'types/channels'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { MerchantsProvider } from '../../contexts'
import CreateChannelModal from './components/Modal/ChannelCreateModal'
import Table from './components/Table'
import useChannelsApi from './hooks/useChannelsApi'

const Channels = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [withContentExpanded, setWithContentExpanded] = useState(false)
  const isMounted = useMounted()

  const { data: { merchantList = [] } = {} } = useMerchantsQuery()
  const [showCreateModal, setShowCreateModal] = useState(false)

  const permissions = useMappedRoles()?.channels
  const isEditDisabled = !permissions?.canEdit

  const urlParams = new URLSearchParams(window.location.search)
  const queryPaymentsLinkBy = urlParams.get('dlt-reference') ?? null

  const {
    executor,
    isLoading,
    isError,
    data: {
      paymentsList: { transactions, paging, pagingMax },
      search,
      isExportingCSV,
      dateValue,
    },
    actions: {
      onDateSelected,
      handleExportClicked,
      onPaymentStatusChanged,
      onMerchantSelected,
      showSearchResult,
      onResetAll,
      setPaymentsList,
      onPageChange,
      onSearchChanged,
    },
  } = useChannelsApi()

  useEffect(() => {
    if (queryPaymentsLinkBy && isMounted && !isLoading) {
      setWithContentExpanded(true)
      onSearchChanged(queryPaymentsLinkBy)
    }
  }, [])

  const { data: channelsResp, isPending: isLoadingChannels } = useChannelsQuery({
    max: 1, // we only need to know if there are any channels
    offset: 0,
    merchant: 'ALL',
  })
  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CHANNELS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const isChannelsListEmpty = useMemo(() => {
    return (
      !isLoading && !isLoadingChannels && !transactions?.length && !channelsResp?.channels?.length
    )
  }, [isLoading, transactions, channelsResp])

  const isTransactionsListEmpty = useMemo(() => {
    return !isLoading && !transactions?.length && !search
  }, [isLoading, transactions])

  return (
    <Wrapper className="channels-container">
      {!isTransactionsListEmpty && (
        <>
          <PageHeader
            description={t('channelPaymentsIntroText')}
            renderRightSection={
              <Box direction="row" flex gap={12}>
                <>
                  {!isEditDisabled && (
                    <Button
                      className="mr-3"
                      onClick={() => {
                        setShowCreateModal(true)
                        track.Amp.track(AmplitudeEvent.CHANNEL_INITIATE, {
                          category: AmplitudeEventCategory.MERCHANT_PORTAL,
                          action: AmplitudeEventAction.CLICK,
                        })
                      }}
                      iconElement={<Icon name="PlusIcon" color="white" />}
                    >
                      {t('payments.channels.createChannel')}
                    </Button>
                  )}
                  <Button
                    secondary
                    onClick={() => navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`)}
                    iconElement={<Icon name="EditIcon" color={secondaryButtonIconColour} />}
                  >
                    {`${t('payments.channels.manageChannels')}`}
                  </Button>
                </>
              </Box>
            }
          />
          <Text size="lg" weight="medium" className="pb-4">
            {t('latestActivity')}
          </Text>
          <FilterBar
            isChannel={true}
            eventHandlers={{
              executor,
              onMerchantSelected,
              onDateFilterChange: onDateSelected,
              onPaymentStatusChanged,
              handleExportClicked,
              onResetAll,
              showSearchResult,
            }}
            conditions={{ isExportingCSV }}
            filterDateRange={dateValue}
            searchInputPlaceholder={t('channelsSearch')}
          />
        </>
      )}

      {isLoading && (
        <Box flex justifyContent="center" className="mt-10">
          <Loader theme="dark" size="large" />
        </Box>
      )}

      {isError && <StatusCard headline={t('defaultError')} status="error" />}

      {isChannelsListEmpty && (
        <StatusCard
          button={
            !isEditDisabled && {
              onClick: () => {
                setShowCreateModal(true)
                track.Amp.track(AmplitudeEvent.CHANNEL_INITIATE, {
                  category: AmplitudeEventCategory.MERCHANT_PORTAL,
                  action: AmplitudeEventAction.CLICK,
                })
              },
              text: t('payments.channels.createChannel'),
            }
          }
          headline={t('noChannelsFound')}
        >
          <Text size="sm" align="center" color="grey">
            {t('noChannelsCreateChannel')}{' '}
            <a
              href={process.env.CHANNELS_LEARN_MORE}
              className="text-purple-500 text-sm"
              target="_blank"
              rel="noreferrer"
            >
              {t('learnMore')}
            </a>
          </Text>
        </StatusCard>
      )}

      {!search && isTransactionsListEmpty && !isChannelsListEmpty && !isError && (
        <StatusCard
          button={
            !isEditDisabled && {
              onClick: () => navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`),
              text: t('payments.channels.manageChannels'),
            }
          }
          headline={t('payments.channels.noPaymentChannelsTitle')}
          description={t('payments.channels.noPaymentChannelsDescription')}
        />
      )}

      {search && !isLoading && !transactions?.length && (
        <NoSearchResults
          testIds={{
            headline: 'channels-search-status-card',
            description: 'channels-search-no-result-label',
            clearButton: 'channels-search-clear-result-btn',
          }}
          clearSearchCriteria={onResetAll}
        />
      )}

      {!isLoading && !!transactions?.length && !isError && (
        <Table
          channelPayments={transactions as unknown as ChannelPayment[]}
          withContentExpanded={withContentExpanded}
        />
      )}

      {showCreateModal && (
        <MerchantsProvider merchants={merchantList}>
          <CreateChannelModal closeCallback={() => setShowCreateModal(false)} />
        </MerchantsProvider>
      )}

      {!isError && (
        <TablePagination
          total={paging?.total}
          pagingMax={pagingMax}
          paging={paging}
          setPaymentsList={setPaymentsList}
          executor={onPageChange}
        />
      )}
    </Wrapper>
  )
}

export default React.memo(Channels)
