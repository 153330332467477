import React from 'react'
import { Box } from '@node-space/storybook-components'
import { Text } from '@node-space/storybook-components/dist/Text'

interface BeneficiaryDetailRowProps {
  label: string
  detail: React.ReactNode
}

export const BeneficiaryDetailRow = ({ label, detail }: BeneficiaryDetailRowProps) => {
  return (
    <Box className="lg:flex-row" flex direction="col" paddingT={4} paddingB={4}>
      <Text tag="span" size="sm" color="disabled" className="md:w-2/5">
        {label}
      </Text>
      <Text
        tag="span"
        size="sm"
        weight="medium"
        color="dark-grey"
        className="md:w-3/5 flex justify-start lg:justify-end"
      >
        {detail}
      </Text>
    </Box>
  )
}
