import { requestApi } from 'api/api'

export const getDocument = async (url: string) => {
  return requestApi({
    url,
    method: 'GET',
    config: {
      responseType: 'arraybuffer',
    },
  })
}
