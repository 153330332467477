import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { CustomerDetailRow } from './CustomerDetailRow'

interface CustomerDetailsSectionProps {
  title?: string
  customerDetails: { label: string; value: string }[]
  isColumn?: boolean
}
export const CustomerDetailsSection = ({
  title,
  customerDetails,
  isColumn = true,
}: CustomerDetailsSectionProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      {title && (
        <Text size="lg" weight="medium">
          {title}
        </Text>
      )}
      {customerDetails?.map(
        (customerDetail, index) =>
          customerDetail?.value && (
            <CustomerDetailRow
              key={index}
              label={t(customerDetail?.label)}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text className="text-left lg:text-right" tag="span" size="sm">
                    {customerDetail?.value}
                  </Text>
                </Box>
              }
              isColumn={isColumn}
            />
          )
      )}
    </Box>
  )
}
