import React from 'react'
import { FieldErrorsImpl } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Box, { Box as FormRow } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import Select from '@node-space/storybook-components/dist/Select'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'

interface PayCurrencyFieldProps {
  currencyList: { value: string; label: string }[]
  onChangeHandler: (name: string, value: string) => void
  selectedCurrency: string
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >

  isLoading: boolean
}

const DisplayCurrencyField = ({
  currencyList,
  onChangeHandler,
  selectedCurrency,
  errors,
  isLoading,
}: PayCurrencyFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormRow className="flex flex-wrap -mx-3 mb-6 px-3">
      <Box className="w-full">
        <Select
          label={
            <>
              <label className="mr-2">{t('payments.channels.cryptoToBePaidIn')}</label>
              <Tooltip
                id="pay-currency-tooltip"
                position="right"
                bodyContent={`${t('payments.channels.tooltips.payCurrency')}`}
                className="whitespace-normal"
                hasMaxWidth={true}
              >
                <Icon name="InfoCircleIcon" size="sm" />
              </Tooltip>
            </>
          }
          name="payCurrency"
          options={currencyList}
          onChange={value => {
            onChangeHandler('payCurrency', value)
          }}
          value={selectedCurrency}
          error={Boolean(errors?.payCurrency)}
          errorText={errors?.payCurrency?.message}
          loading={isLoading}
          isSearchable
        />
      </Box>
    </FormRow>
  )
}

export default React.memo(DisplayCurrencyField)
