import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RestApi } from '@node-space/rest-api'
import { useProfileContext } from 'hooks/context/useProfileContext'
import sendSchema from 'pages/Wallets/schemas/sendSchema'
import { generateReference } from 'utils/utils'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import { SendForm, SendSteps } from '../components/Send/Send'
import useWalletActionApi from './useWalletActionApi'

const useSendAction = (sharedActionProps: WalletActionSharedProps) => {
  const { profile } = useProfileContext()
  const { t } = useTranslation()

  const ignoreSend2faWarning = RestApi.getCookie('ignoreSend2faWarning')

  const [sendStep, setSendStep] = useState<SendSteps>(
    profile.twoFactorEnabled || ignoreSend2faWarning ? 'form' : '2fa'
  )
  const sendActionApi = useWalletActionApi<SendForm, SendSteps>({
    intialStep: profile.twoFactorEnabled || ignoreSend2faWarning ? 'form' : '2fa',
    step: sendStep,
    setStep: setSendStep,
    formSchema: sendSchema({ t, step: sendStep }),
    defaultFormValues: { amount: 0, reference: generateReference() },
    ...sharedActionProps,
  })

  return sendActionApi
}

export default useSendAction
