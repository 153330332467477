import { ProfileSettings } from 'types/profileSettings'
import { AccountTypes } from 'types/types'

export interface Profile {
  loggedIn: boolean
  viewOnly: boolean
  id: number
  city: string
  country: Country
  firstName: string
  headline: null
  language: null
  lastName: string
  timezone: null
  currencyCode: string
  emailAddress: string
  options: ProfileOptions
  roleGroups: string[]
  twoFactorEnabled: boolean
  roles: string[]
  settings?: ProfileSettings
  busy?: boolean
  error?: null
}

export type ProfileOptions = {
  registrationAccountType?: AccountTypes
} & { [key: string]: string }

export const ProfileGroupRoles = {
  ACCOUNT_OWNERS: 'Account Owners',
  ADMINISTRATORS: 'Administrators',
  FINANCE: 'Finance',
  LOGIN: 'Login',
  MERCHANTS: 'Merchants',
  PARTNERS: 'Partners',
  TRADERS: 'Traders',
  VIEWER: 'Viewer',
  WORKSPACE_OWNER: 'Workspace Owner',
} as const

export type ProfileGroupRoles = (typeof ProfileGroupRoles)[keyof typeof ProfileGroupRoles]

export interface Country {
  id: number
  allowRegistration: boolean
  code: string
  name: string
  documents: Document[]
  defaultCurrency: string
  options: Options
  region: null
}

export interface Document {
  id: number
  code: string
  description: string
  required: boolean
}

export interface Options {
  minimumOrderValue: number
  limitValue: number
  minimumTransferValue: number
  minimumPartnerValue: number
  requireProofOfPayment: string
  supportInstantTransfer: null
  orderMinimumValue: string
  unverifiedMaximum: string
  withdrawalMaximum: string
  withdrawalThreshold: string
  transferMinimumValue: string
}
