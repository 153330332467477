import { QueryClient, useQuery } from '@tanstack/react-query'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPasswordSecret } from 'services/ApiKeysService'
import { QueryMetaType } from 'types/reactQuery'

export const useGetOtpUrl = (password: string) => {
  const { profile } = useProfileContext()
  const queryClient = new QueryClient()

  const meta: QueryMetaType = {
    onSuccess: () => queryClient.removeQueries({ queryKey: reactQueryKeys.secrectCode() }),
  }

  const {
    data: responseGetSecrectCall,
    isFetching: isFetchingSecrect,
    refetch: refetchSecrect,
    isError: isErrorGetSecrectCall,
  } = useQuery({
    queryKey: reactQueryKeys.secrectCode(),
    refetchOnReconnect: false,
    enabled: false,
    queryFn: () => getPasswordSecret(password),
    meta,
  })
  const secret: string | null | undefined = responseGetSecrectCall?.body
  const otpUrl = `otpauth://totp/BVNK:${profile?.firstName}?secret=${secret}&issuer=BVNK&algorithm=SHA1&digits=6&period=30`

  return {
    secret,
    otpUrl,
    isFetchingSecrect,
    refetchSecrect,
    isErrorGetSecrectCall,
  }
}
