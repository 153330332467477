import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { useAddBeneficiaryContext } from 'hooks/useAddBeneficiaryContext'
import { AddLocalBeneficiaryFormConfirmPayload } from 'types/beneficiaries'
import { BeneficiarySummary } from './BeneficiarySummary'
import { addBeneficiaryConfirmSchema } from './schemas/addBeneficiaryConfirmSchema'

type AddBeneficiaryConfirmProps = {
  onContinue: (password: string) => void
  isLoading: boolean
  error: boolean
}

export const AddBeneficiaryConfirm = ({
  onContinue,
  isLoading,
  error,
}: AddBeneficiaryConfirmProps) => {
  const { t } = useTranslation()
  const formSchema = addBeneficiaryConfirmSchema()
  const { control, register, setValue, setError, clearErrors } =
    useForm<AddLocalBeneficiaryFormConfirmPayload>({
      resolver: yupResolver(formSchema),
    })
  const password = useWatch({
    control,
    name: 'password',
    defaultValue: '',
  })
  const { beneficiaryFormErrors } = useAddBeneficiaryContext()

  useEffect(() => {
    if (beneficiaryFormErrors?.password) {
      setError('password', beneficiaryFormErrors?.password)
    } else {
      clearErrors()
    }
  }, [setError, clearErrors, beneficiaryFormErrors?.password])

  useEffect(() => {
    if (password?.length === TWO_FA_REQUIRED_LENGTH) {
      onContinue(password)
    }
  }, [password])

  return (
    <Box flex flexCol padding={24} paddingT={20}>
      <BeneficiarySummary />
      <Box paddingT={24} paddingB={12}>
        {isLoading ? (
          <Box flex flexJustifyCenter>
            <Loader size="medium" />
          </Box>
        ) : (
          <Input2FA
            {...register('password')}
            label={t('authCodeConfirm')}
            onChange={value => setValue('password', value)}
            testid="input-2fa"
            error={error}
            errorText={t('incorrectOtpCode')}
          />
        )}
      </Box>
    </Box>
  )
}
