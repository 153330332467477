import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postReportSchedule } from 'services/TransactionExportService'

export const usePostReportScheduleMutation = () => {
  return useMutation({
    mutationFn: postReportSchedule,
    onError: error => logSentryError('Error: usePostReportScheduleMutation', error),
  })
}
