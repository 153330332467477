import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import {
  NotificationCard,
  NotificationState,
} from '@node-space/storybook-components/dist/NotificationCard'
import { SimpleTableV2, TableRowV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import OnClickProvider from 'components/OnClickProvider/OnClickProvider'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { DateFormat } from 'constants/General'
import { useTransactionsContext } from 'hooks/context/useTransactionsContext'
import { formatTotal } from 'pages/Wallets/components/Table/utils'
import { InflowPaymentTypes } from 'types/payments'
import { Transaction } from 'types/transaction'
import { TransactionType } from 'types/transaction/TransactionType'
import { getStatusState, statusMappings, titleCase } from 'utils/utils'
import { TransactionModal } from './Modal/TransactionModal'
import { AmountCellSkeleton } from './Skeletons/AmountCellSkeleton'
import { DateCellSkeleton } from './Skeletons/DateCellSkeleton'
import { TypeCellSkeleton } from './Skeletons/TypeCellSkeleton'
import { TransactionAmount } from './TableCells/TransactionAmount'
import { TransactionReference } from './TableCells/TransactionReference'
import { TransactionTitle } from './TableCells/TransactionTitle'

interface TransactionTableProps {
  isFetching: boolean
  onOpenSidePanelSummary?: (index: number) => void
}

const rowHeaderWidth = 'px-8 w-1/3'

const cellDatatStyle = 'p-4'

const noWrapAmount = 'min-w-max'

export const TransactionTable = ({ isFetching, onOpenSidePanelSummary }: TransactionTableProps) => {
  const { t } = useTranslation()
  const transactions = useTransactionsContext()

  const { enableSidePanelForWalletTransactions } = useFeatureFlags()

  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>()
  const handleModalClose = () => setSelectedTransaction(undefined)
  const openTransactionModal = useCallback(
    transaction => {
      setSelectedTransaction(transaction)
    },
    [selectedTransaction]
  )

  const openTransactionDetails = (transaction: unknown, index: number) => {
    enableSidePanelForWalletTransactions
      ? onOpenSidePanelSummary(index)
      : openTransactionModal(transaction)
  }

  const getTransactionDate = (transaction: Transaction) => {
    switch (transaction?.type) {
      case TransactionType.USER_DEPOSIT:
        return transaction?.context?.depositDetails?.valueDate || transaction?.dateCreated
      case TransactionType.USER_WITHDRAWAL:
        return transaction?.context?.withdrawalDetails?.valueDate || transaction?.dateCreated
      default:
        return transaction?.dateCreated
    }
  }

  const convertTransactionsInfo = (): TableRowV2[] => {
    return transactions?.map((transaction, transactionIndex) => {
      const isPaymentIn =
        (InflowPaymentTypes.includes(transaction?.type) ||
          InflowPaymentTypes.includes(transaction?.context.subType)) &&
        transaction?.amount?.toString()?.charAt(0) !== '-'

      const transactionType = transaction?.type
      const transactionSubtype = transaction?.context?.subType
      const transactionsTypeSubType = transactionSubtype
        ? `${transactionType}_${transactionSubtype}`
        : `${transactionType}_`

      const status = transaction?.context?.status || statusMappings[transactionsTypeSubType]
      const mappedStatus = getStatusState(status) ?? 'info'

      return {
        rowData: [
          {
            cellData: isFetching ? (
              <TypeCellSkeleton skeletalIcon={false} />
            ) : (
              <OnClickProvider
                id={transaction?.hash}
                onClick={() => onOpenSidePanelSummary(transactionIndex)}
              >
                <TransactionTitle transaction={transaction} />
              </OnClickProvider>
            ),
            className: `${cellDatatStyle} px-8`,
          },
          {
            cellData: isFetching ? (
              <AmountCellSkeleton />
            ) : isPaymentIn ? (
              <NotificationCard
                state={NotificationState.GREEN}
                label={`+ ${formatTotal(transaction?.amount, transaction.wallet?.currency?.code)}`}
                showBorder={false}
              />
            ) : (
              <TransactionAmount
                amount={formatTotal(transaction?.amount, transaction.wallet?.currency?.code)}
              />
            ),
            className: noWrapAmount,
          },
          {
            cellData: isFetching ? (
              <DateCellSkeleton />
            ) : (
              dayjs(getTransactionDate(transaction)).format(DateFormat.DD_MMM_YYYY)
            ),
            className: `${cellDatatStyle} ml-3`,
          },
          {
            cellData: isFetching ? (
              <TypeCellSkeleton skeletalIcon={false} />
            ) : (
              <TransactionReference transaction={transaction} />
            ),
            className: `${cellDatatStyle} px-8`,
          },
          {
            cellData: isFetching ? (
              <TypeCellSkeleton skeletalIcon={false} />
            ) : (
              status && <StatusPill state={mappedStatus} label={titleCase(status)} fitContent />
            ),
            className: `${cellDatatStyle} px-8 text-nowrap`,
          },
          {
            cellData: isFetching ? (
              <DateCellSkeleton />
            ) : (
              <TablePopoverMenu
                testid="transaction-table"
                stopPropagation
                menuItems={[
                  {
                    iconName: 'InfoCircleIcon',
                    label: t('wallets.details'),
                    onClick: () => openTransactionDetails(transaction, transactionIndex),
                  },
                ]}
              />
            ),
            align: 'end',
            className: cellDatatStyle,
          },
        ],
        onClick: () => openTransactionDetails(transaction, transactionIndex),
      }
    })
  }

  return (
    <>
      <SimpleTableV2
        columns={[
          {
            headerTitle: t('transactionType'),
            className: rowHeaderWidth,
          },
          {
            headerTitle: t('amount'),
          },
          {
            headerTitle: t('date'),
            className: rowHeaderWidth,
          },
          {
            headerTitle: t('payments.reference'),
            className: rowHeaderWidth,
          },
          {
            headerTitle: t('status'),
            className: rowHeaderWidth,
          },
          {
            headerTitle: '',
            align: 'end',
          },
        ]}
        tableData={convertTransactionsInfo()}
      />
      <div className="mt-6">
        {Boolean(selectedTransaction) && (
          <TransactionModal transaction={selectedTransaction} onClose={handleModalClose} />
        )}
      </div>
    </>
  )
}
