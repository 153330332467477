import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useFeatureFlags } from '@node-space/hooks'
import { logSentryError } from '@node-space/utils'
import { CriteriaKeyType, CSATSurveyData } from 'components/CSAT/types'
import { useAccountPreferenceMutation } from 'hooks/mutations/useAccountPreferenceMutation'
import { useAccountPreferencesQuery } from 'hooks/queries'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { AccountPreferenceKey } from 'types/types'

const CSATSurveyPreferenceKey: AccountPreferenceKey = 'showCSATSurvey'

interface CSATSurveyProps {
  shouldInitialiseCSATSurvey?: boolean
}

const useCSATSurvey = ({ shouldInitialiseCSATSurvey }: CSATSurveyProps = {}) => {
  const { enableCsatSurveyCasePortal } = useFeatureFlags()
  const queryClient = useQueryClient()
  const {
    data: accountPreferences,
    isFetched: isFetchedAccountPreferences,
    isFetching,
  } = useAccountPreferencesQuery(enableCsatSurveyCasePortal)

  const { mutate: putCSATSurveyPreferences } = useAccountPreferenceMutation<CSATSurveyData>()

  const initialiseDefaultCSATSurvey: CSATSurveyData = {
    criteria: {
      isCaseCompleted: false,
      hasSentEmail: false,
      hasFilteredCaseStatus: false,
      hasUploadedDocument: false,
      hasEnteredCustomerId: false,
    },
    isSurveyCompleted: false,
  }

  const hasNoCSATPreferenceData =
    accountPreferences?.[CSATSurveyPreferenceKey] == null ||
    accountPreferences?.[CSATSurveyPreferenceKey] == undefined

  useEffect(() => {
    if (
      isFetchedAccountPreferences &&
      accountPreferences &&
      shouldInitialiseCSATSurvey &&
      enableCsatSurveyCasePortal &&
      hasNoCSATPreferenceData
    ) {
      CSATPreferenceUpdate(initialiseDefaultCSATSurvey)
    }
  }, [isFetchedAccountPreferences])

  const CSATPreferenceUpdate = (
    value: CSATSurveyData,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => {
    putCSATSurveyPreferences(
      { preferenceKey: CSATSurveyPreferenceKey, value },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.accountPreferences() })
          onSuccessCallback && onSuccessCallback()
        },
        onError: error => {
          logSentryError(`Error: put ${JSON.stringify(value)} CSAT Preferences`, error)
          onFailureCallback && onFailureCallback()
        },
      }
    )
  }

  const completeCSATCriteria = (criteriaKey: CriteriaKeyType) => {
    if (!hasNoCSATPreferenceData) {
      const updatedPreferences = { ...accountPreferences?.showCSATSurvey }
      updatedPreferences.criteria[criteriaKey] = true
      CSATPreferenceUpdate(updatedPreferences)
    }
  }

  const getIsCriteriaCompleted = (criteriaKey: CriteriaKeyType): boolean =>
    accountPreferences?.showCSATSurvey?.criteria?.[criteriaKey]

  return {
    CSATPreferenceData: accountPreferences?.showCSATSurvey,
    CSATPreferenceUpdate,
    enableCsatSurveyCasePortal,
    getIsCriteriaCompleted,
    isFetchingAccountPreferences: isFetching,
    completeCSATCriteria,
    hasNoCSATPreferenceData,
  }
}

export default useCSATSurvey
