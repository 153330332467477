import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Select } from '@node-space/storybook-components/dist'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AddBeneficiaryFormErrors, BaseFormProps } from 'types/beneficiaries'

interface NgnFormFieldsProps extends BaseFormProps {
  banks: { label: string; value: string }[]
  bankCode: string
  errors: AddBeneficiaryFormErrors
  fetchingBankList: boolean
  ngnAccountNumber: string
}

export const NgnFormFields = ({
  banks,
  bankCode,
  errors,
  ngnAccountNumber,
  fetchingBankList,
  handleChange,
  register,
}: NgnFormFieldsProps) => {
  const { t } = useTranslation()

  const handleBankOnChange = (bankCode: string) => {
    handleChange('bankCode', bankCode)
    const bankName = banks?.find(bank => bank?.value === bankCode)?.label
    if (bankName) {
      handleChange('bankName', bankName)
    }
  }

  return (
    <Box paddingB={16}>
      <Box flex flexRow gapX={12} paddingB={16}>
        <Input
          {...register('ngnAccountNumber')}
          label={t('accountNumber')}
          placeholder={t('enterAccountNumber')}
          inputMode="numeric"
          name="ngnAccountNumber"
          testid="input-ngnAccountNumber"
          errorText={errors?.ngnAccountNumber?.message || errors?.accountNumber?.message}
          error={!!errors?.ngnAccountNumber || !!errors?.accountNumber}
          value={ngnAccountNumber}
          onChange={event => handleChange('ngnAccountNumber', event?.target?.value)}
        />
      </Box>

      <Select
        {...register('bankCode')}
        label={t('bank')}
        placeholder={t('enterCode')}
        value={bankCode}
        isSearchable={true}
        options={banks || []}
        onChange={value => handleBankOnChange(value)}
        error={!!errors?.bankCode}
        errorText={errors?.bankCode?.message}
        loading={fetchingBankList}
      />
    </Box>
  )
}
