import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { BeneficiaryDetails } from 'types/beneficiaries'
import { AccountTypes } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useMappedRoles } from '../../hooks/useMappedRoles'
import { AddBeneficiary } from './AddBeneficiary/AddBeneficiary'
import BeneficiaryTable from './components/BeneficiaryTable'
import { DeleteBeneficiary } from './DeleteBeneficiary/DeleteBeneficiary'
import { EditBeneficiary } from './EditBeneficiary/EditBeneficiary'

export const ManageBeneficiaries = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const permissions = useMappedRoles()

  const { profile } = useProfileContext() || {}
  const { options: profileOptions, twoFactorEnabled: isTwoFactorEnabled } = profile || {}

  const callbackUrl = searchParams.get('callbackUrl') ?? null
  const [isAddModalOpen, setIsAddModalOpen] = useState(!!callbackUrl)
  const [showEditBeneficiaryModal, setShowEditBeneficiaryModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [beneficiaryToUpdate, setBeneficiaryToUpdate] = useState<BeneficiaryDetails>()
  const [beneficiaryToDelete, setBeneficiaryToDelete] = useState<BeneficiaryDetails>(undefined)
  const isDeveloperAccount = profileOptions?.registrationAccountType === AccountTypes.DEVELOPER
  const canEdit = permissions?.manageBeneficiaries?.canEdit

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_BENEFICIARIES, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const handleOnSuccessCloseModal = useCallback(() => {
    setIsAddModalOpen(false)

    if (callbackUrl) {
      searchParams?.delete('callbackUrl')
      window.history.pushState({}, document.title, callbackUrl)
      window.location.pathname = callbackUrl
    }
  }, [setIsAddModalOpen, callbackUrl])

  const handleCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false)
    searchParams?.delete('callbackUrl')
    window.history.pushState({}, document.title, callbackUrl)
  }, [setIsAddModalOpen])

  const handleResetDeleteBeneficiary = useCallback(
    () => setBeneficiaryToDelete(undefined),
    [setBeneficiaryToDelete]
  )

  const handleEditBeneficiaryName = useCallback(
    (beneficiary: BeneficiaryDetails) => {
      setBeneficiaryToUpdate(beneficiary)
      setShowEditBeneficiaryModal(true)
      setErrorMessage(null)
    },
    [setBeneficiaryToUpdate, setShowEditBeneficiaryModal, setErrorMessage]
  )

  return (
    <Wrapper>
      <PageHeader
        description={t('manageBeneficiaries.description')}
        renderRightSection={
          canEdit && (
            <Button
              onClick={() => setIsAddModalOpen(true)}
              iconElement={<Icon name="PlusIcon" color="white" />}
              disabled={isDeveloperAccount || !isTwoFactorEnabled}
            >
              {t('manageBeneficiaries.addBeneficiary')}
            </Button>
          )
        }
      />

      <BeneficiaryTable
        setBeneficiaryToDelete={setBeneficiaryToDelete}
        setIsAddModalOpen={setIsAddModalOpen}
        handleEditBeneficiaryName={handleEditBeneficiaryName}
      />

      <Modal allowOverFlow visible={isAddModalOpen} closeModal={handleCloseAddModal}>
        <AddBeneficiary
          handleCloseModal={handleCloseAddModal}
          handleOnSuccessCloseModal={handleOnSuccessCloseModal}
        />
      </Modal>

      <Modal
        allowOverFlow
        visible={!!beneficiaryToDelete}
        closeModal={handleResetDeleteBeneficiary}
      >
        <DeleteBeneficiary
          beneficiaryDetails={beneficiaryToDelete}
          onClose={handleResetDeleteBeneficiary}
        />
      </Modal>
      <Modal
        visible={showEditBeneficiaryModal}
        closeModal={() => setShowEditBeneficiaryModal(false)}
      >
        <EditBeneficiary
          setShowEditBeneficiaryModal={setShowEditBeneficiaryModal}
          showEditBeneficiaryModal={showEditBeneficiaryModal}
          errorMessage={errorMessage}
          beneficiaryToUpdate={beneficiaryToUpdate}
          setBeneficiaryToUpdate={setBeneficiaryToUpdate}
        />
      </Modal>
    </Wrapper>
  )
}
