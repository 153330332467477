import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'

interface CopyAllBankDetailsButtonProps {
  accountDetailsText: string
  copiedValue: string
  setCopiedValue: React.Dispatch<React.SetStateAction<string>>
  setCopiedTitle?: React.Dispatch<React.SetStateAction<string>>
}

const CopyAllBankDetailsButton = ({
  accountDetailsText,
  copiedValue,
  setCopiedValue,
  setCopiedTitle,
}: CopyAllBankDetailsButtonProps) => {
  const { t } = useTranslation()

  const handleCopy = (value: string) => {
    setCopiedValue && setCopiedValue(value)
    setCopiedTitle && setCopiedTitle('All')
  }

  return (
    !!accountDetailsText && (
      <Box paddingT={16} alignItems="center">
        <CopyToClipboard text={accountDetailsText} onCopy={handleCopy}>
          <Box flex justifyContent="center">
            <Tooltip
              id="tooltip-deposit-all"
              position="top"
              bodyContent={copiedValue === accountDetailsText ? t('copied') : t('copy')}
            >
              <Box flex className="cursor-pointer">
                <Text color={copyButtonIconColour} weight="medium" className="pr-2">
                  {t('copyAllDetails')}
                </Text>
                <Icon name="CopyIcon" color={copyButtonIconColour} />
              </Box>
            </Tooltip>
          </Box>
        </CopyToClipboard>
      </Box>
    )
  )
}

export default CopyAllBankDetailsButton
