import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import Accordion from '@node-space/storybook-components/dist/Accordion'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { TextColor } from '@node-space/storybook-components/dist/Text'
import CopyRow from 'components/CopyRow'
import { Options } from 'types/BankAccountsDetails'
import { getSwiftBankDetails } from 'utils/wallet'
import CopyAllBankDetailsButton from './CopyAllDetails'

interface SwiftBankDetailsProps {
  options: Options
  copiedValue: string
  setCopiedValue: React.Dispatch<React.SetStateAction<string>>
  setCopiedTitle?: React.Dispatch<React.SetStateAction<string>>
}

const SwiftBankDetails = ({
  options,
  copiedValue,
  setCopiedValue,
  setCopiedTitle,
}: SwiftBankDetailsProps) => {
  const { t } = useTranslation()
  const { enableMultiplePaymentMethods } = useFeatureFlags()
  const [openAccordion, setOpenAccordion] = useState<string>('beneficiaryDetails')
  const accountDetails = getSwiftBankDetails(options, t, enableMultiplePaymentMethods)
  const { beneficiaryBankDetails, beneficiaryDetails } = accountDetails

  const accountDetailsText = useMemo(() => {
    const allBeneficiaryBankDetailsText = Object.keys(beneficiaryBankDetails || {})
      .map(label => `${label}: ${beneficiaryBankDetails[label]}`)
      .join('\n')

    const allBeneficiaryDetailsText = Object.keys(beneficiaryDetails || {})
      .map(label => `${label}: ${beneficiaryDetails[label]}`)
      .join('\n')

    return `${t('beneficiaryDetails')}:\n${allBeneficiaryDetailsText}\n\n${t(
      'beneficiaryBankDetails'
    )}:\n${allBeneficiaryBankDetailsText}`
  }, [beneficiaryBankDetails, beneficiaryDetails])
  const accordionItems = [
    {
      name: 'beneficiaryDetails',
      title: t('beneficiaryDetails'),
      children: (
        <Box divider="top" paddingX={24}>
          <Box testid="beneficiaryDepositDetails" divideChildren="y">
            {Object.keys(beneficiaryDetails)
              .filter(label => !!label)
              .map(label => {
                return (
                  <Box key={label}>
                    <CopyRow
                      key={label}
                      title={label}
                      description={beneficiaryDetails[label]}
                      copiedValue={copiedValue}
                      setCopiedValue={setCopiedValue}
                      setCopiedTitle={setCopiedTitle}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      ),
    },
    {
      name: 'bankDetails',
      title: t('beneficiaryBankDetails'),
      children: (
        <Box divider="top" paddingX={24}>
          <Box testid="beneficiaryBankDepositDetails" divideChildren="y">
            {Object.keys(beneficiaryBankDetails)
              .filter(label => !!label)
              .map(label => {
                return (
                  <Box key={label}>
                    <CopyRow
                      key={label}
                      title={label}
                      description={beneficiaryBankDetails[label]}
                      copiedValue={copiedValue}
                      setCopiedValue={setCopiedValue}
                      setCopiedTitle={setCopiedTitle}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      ),
    },
  ]

  if (!Object.keys(accountDetails).length) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }

  return (
    <>
      <Accordion
        isSlim
        items={accordionItems}
        getOpenName={name => setOpenAccordion(name)}
        defaultOpen={openAccordion}
        headerTextColor={TextColor.PRIMARY_500}
        bodyClassOverrides="!p-0"
      ></Accordion>
      <CopyAllBankDetailsButton
        accountDetailsText={accountDetailsText}
        copiedValue={copiedValue}
        setCopiedValue={setCopiedValue}
        setCopiedTitle={setCopiedTitle}
      />
    </>
  )
}

export default SwiftBankDetails
