import { useMemo } from 'react'
import { UserGroups } from 'pages/TeamMembers/const'
import { Profile } from 'types/types'

export const useComplianceViewHelper = (profile: Profile): boolean => {
  const { roleGroups } = profile || {}

  const isComplianceOnlyUser = useMemo(
    () => roleGroups?.includes(UserGroups.Compliance) && roleGroups?.length === 1,
    [roleGroups]
  )

  const isComplianceAndLoginUser = useMemo(
    () =>
      roleGroups?.includes(UserGroups.Compliance) &&
      roleGroups?.includes(UserGroups.Login) &&
      roleGroups?.length === 2,
    [roleGroups]
  )

  return isComplianceOnlyUser || isComplianceAndLoginUser
}
