import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as CSATOptions } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useToastContext } from 'hooks/useToastContext'
import useCSATSurvey from 'pages/PaymentSupport/hooks/useCSATSurvey'
import { CSATOption } from './CSATOption'
import {
  CSATCustomerType,
  CSATFeedbackRating,
  CSATFeedbackRatingType,
  CSATFeedbackValue,
  CSATOptionStatus,
  CSATOptionType,
} from './types'

const CSAT_API_URL = process.env.CSAT_API_URL
const CSAT_CP_API_KEY = process.env.CSAT_CP_API_KEY

export type CSATProps = {
  customer: CSATCustomerType
  onClose: () => void
}

export const CSATModal = ({ customer, onClose }: CSATProps) => {
  const { t } = useTranslation()
  const { CSATPreferenceData, CSATPreferenceUpdate, hasNoCSATPreferenceData } = useCSATSurvey()
  const addToast = useToastContext()

  const [rating, setRating] = useState<CSATFeedbackRatingType>()
  const [isLoading, setIsLoading] = useState(false)

  const feedbackOptions: CSATOptionType[] = [
    {
      label: t('csat.options.bad'),
      value: CSATFeedbackValue.BAD,
      rating: CSATFeedbackRating.BAD,
    },
    {
      label: t('csat.options.average'),
      value: CSATFeedbackValue.OKAY,
      rating: CSATFeedbackRating.OKAY,
    },
    {
      label: t('csat.options.good'),
      value: CSATFeedbackValue.GOOD,
      rating: CSATFeedbackRating.GOOD,
    },
  ]

  const params = {
    source: `generic_embed`,
    customer_email: `${customer?.emailAddress}`,
    t_firstname: `${customer?.firstname}`,
    t_lastname: `${customer?.lastname}`,
    t_customer_id: `${customer?.customerID}`,
    t_companyName: `${customer?.companyName}`,
  }
  const paramList = new URLSearchParams(params).toString()

  const CSATsurveyUrl = `${CSAT_API_URL}/api/embed/rating/${rating}/${CSAT_CP_API_KEY}/?${paramList}`

  const onSubmitFeedback = () => {
    handleCSATSurveyUpdate()
  }

  const handleCSATSurveyUpdate = () => {
    if (!hasNoCSATPreferenceData) {
      setIsLoading(true)
      const updatedPreferences = { ...CSATPreferenceData }
      updatedPreferences.isSurveyCompleted = true
      CSATPreferenceUpdate(updatedPreferences, onSuccess, onFailure)
    }
  }

  const onSuccess = () => {
    window.open(CSATsurveyUrl)
    handleCloseModal()
  }

  const onFailure = () => {
    handleCloseModal()
    addToast({ title: t('csat.errorFeedback'), state: 'error' })
  }

  const handleCloseModal = () => {
    setIsLoading(false)
    onClose()
  }

  const sendButton: ButtonProps = {
    children: t('csat.sendFeedback'),
    disabled: !rating || isLoading,
    onClick: onSubmitFeedback,
  }

  const getStatus = option => {
    return !rating
      ? CSATOptionStatus.DEFAULT
      : rating === option?.rating
        ? CSATOptionStatus.SELECTED
        : CSATOptionStatus.UNSELECTED
  }

  return (
    <Modal visible closeModal={onClose}>
      <ModalNavBar title={t('csat.title')} onClose={onClose} />

      <ModalBody spaceChildren={32}>
        <Text size="sm" color="grey" align="center">
          {t('csat.intro')}
        </Text>

        <CSATOptions flex justifyContent="between" paddingX={20}>
          {feedbackOptions?.map(option => (
            <CSATOption
              key={option?.value}
              label={option?.label}
              value={option?.value}
              status={getStatus(option)}
              onClick={() => !isLoading && setRating(option?.rating)}
            />
          ))}
        </CSATOptions>

        <Text size="sm" color="grey" align="center">
          {t('csat.additionalText')}
        </Text>
      </ModalBody>

      <ModalActions actions={[sendButton]} />
    </Modal>
  )
}
