import React, { Dispatch, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import SmallTabs from '@node-space/storybook-components/dist/SmallTabs'
import { NetworkConnectionViewTypes } from 'constants/bvnkNetwork'
import { NetworkConnectionViewType } from 'types/bvnkNetwork'
import { bvnkNetworkConnectionsAtom } from '../atoms/bvnkNetworkAtoms'
import ConnectionsTab from './ConnectionsTab'
import Highlight from './Highlight'

interface BvnkNetworkTabsProps {
  queryTab: NetworkConnectionViewType
  activeTab: string
  setActiveTab: Dispatch<React.SetStateAction<string>>
}

const BvnkNetworkTabs = ({ queryTab, activeTab, setActiveTab }: BvnkNetworkTabsProps) => {
  const bvnkNetworkConnections = useAtomValue(bvnkNetworkConnectionsAtom)
  const { t } = useTranslation()
  const { received, requested } = bvnkNetworkConnections || {}

  const tabs = [
    {
      id: NetworkConnectionViewTypes.CONNECTIONS_VIEW,
      testid: 'bvnk-network-connections-tab',
      tabHeader: t('bvnkNetwork.connections'),
      tabContent: (
        <ConnectionsTab
          key="bvnk-network-connections-tab-content"
          networkConnectionViewType={NetworkConnectionViewTypes.CONNECTIONS_VIEW}
        />
      ),
      onClick: () => {
        setActiveTab(NetworkConnectionViewTypes.CONNECTIONS_VIEW)
      },
    },
    {
      id: NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW,
      testid: 'bvnk-network-received-invitations-tab',
      tabHeader: (
        <Box flex>
          {t('bvnkNetwork.recievedInvitations')}
          {!!received?.length && <Highlight> {received?.length}</Highlight>}
        </Box>
      ),
      tabContent: (
        <ConnectionsTab
          key="bvnk-network-received-invitations-tab-content"
          networkConnectionViewType={NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW}
        />
      ),
      onClick: () => {
        setActiveTab(NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW)
      },
    },
    {
      id: NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW,
      testid: 'bvnk-network-sent-invitations-tab',
      tabHeader: (
        <Box flex>
          {t('bvnkNetwork.sentInvitations')}
          {!!requested?.length && <Highlight> {requested?.length}</Highlight>}
        </Box>
      ),
      tabContent: (
        <ConnectionsTab
          key="bvnk-network-sent-invitations-tab-content"
          networkConnectionViewType={NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW}
        />
      ),
      onClick: () => {
        setActiveTab(NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW)
      },
    },
  ]

  useEffect(() => {
    if (queryTab) {
      setActiveTab(queryTab)
    }
  }, [queryTab])

  return <SmallTabs tabs={tabs} currentValueId={activeTab} />
}

export default BvnkNetworkTabs
