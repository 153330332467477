import { useContext } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterMerchant = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  return {
    merchant: state?.merchant,
    setMerchant: (value: string) => dispatch({ type: 'UPDATE_MERCHANT', payload: value }),
    resetMerchant: () => dispatch({ type: 'RESET_MERCHANT' }),
  }
}

export default useFilterMerchant
