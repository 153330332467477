import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAllApiKeys } from 'services/ApiKeysService'

export const useApiKeysQuery = (query = '') => {
  return useQuery({
    queryKey: reactQueryKeys.apiKeys(query),
    queryFn: () => getAllApiKeys(query),
  })
}
