import { Paging } from './types'

export const ONPASTE_EVENT_FIRE_DELAY_TIME = 2000
export const INVALID_CODE_RESPONSE = 412
export const MINIMUM_ADDRESS_LENGTH_PRE_VALIDATION = 6
export const CODE_CRYPTO = 'crypto'

export const CryptoWithDestinationTag = {
  XRP: 'XRP',
  ALGO: 'ALGO',
}

export const DestinationTagValues = {
  IS_REQUIRED: '1',
  NOT_REQUIRED: '0',
}

export type DestinationTagObserverType =
  (typeof DestinationTagValues)[keyof typeof DestinationTagValues]

export interface DestinationTagObserver {
  mayRequireDestinationTag: boolean
  destinationTagIsRequired: DestinationTagObserverType
}

type Currency = {
  code: string
  name: string
  icon: string
}

type TCryptoAddressValidateStatus = 'ok' | 'invalid'

export const AddCryptoAddressScreens = {
  FORM: 'FORM',
  CONFIRM: 'CONFIRM',
  SUCCESS: 'SUCCESS',
}

export interface AddCryptoAddressRequest {
  name: string
  currencyCode: string
  address: string
  protocol?: string
  reason?: string
  authorization: {
    totp: string | number
  }
}

export interface AddCryptoAddressResponse {
  name: string
  address: string
  destinationTag?: string
  protocol?: string
  currency: Currency
  reason: string
  creator: string
  dateCreated: string | number
  uuid: string
  paging?: Paging
}

export interface ValidateCryptoAddressRequest {
  address: string
  amount?: number
  code: string
  currency: string
  protocol?: string
  source: string
  tag?: string
  accountReference: string
}

export interface ValidateCryptoAddressResponse {
  status: TCryptoAddressValidateStatus
  code?: string
  message?: string
}

export interface UpdateProtocolRequest {
  uuid: string
  selectedProtocol: string
  totp: string
}

export interface WhitelistingCryptoAddress {
  uuid: string
  address: string
  name: string
  protocol: string
}
