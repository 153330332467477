import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { UpdateBeneficiaryFormPayload } from 'types/beneficiaries'

type EditBeneficiarySummaryDetailsProps = {
  beneficiaryUpdatedDetails: UpdateBeneficiaryFormPayload
}

export const EditBeneficiarySummaryDetails = ({
  beneficiaryUpdatedDetails,
}: EditBeneficiarySummaryDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Box
      testid="editBeneficiarySummaryDetails"
      divideChildren="y"
      background="off-white"
      paddingX={20}
      borderRadius={4}
      border="gray"
      width="full"
    >
      <Box key={beneficiaryUpdatedDetails?.alias} width="full">
        <SummaryRow
          label={t('beneficiaryName')}
          renderDescription={
            <Text size="sm" className="break-all">
              {beneficiaryUpdatedDetails?.alias}
            </Text>
          }
        />
      </Box>
    </Box>
  )
}
