import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import Input2FA from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import Modal, { ModalNavBar, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { Approval } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import {
  useApprovalWorkflowActionPreRequest,
  useApprovalWorkflowChallengeRequest,
} from 'hooks/mutations/useApprovalDecisionMutation'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { ApprovalDecision, ApprovalStatuses, ApprovalWorkflowActionResponse } from 'types/approvals'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import ApprovalDecisionSuccess from './ApprovalDecisionSuccess'
import { groupPaymentsTotalByCurrency } from './hooks/useTotalValueOfPayments'

interface ApprovalDecisionModalProps {
  decision: ApprovalDecision
  selectedItems?: unknown[]
}

const ApprovalDecisionModal = ({ decision, selectedItems = [] }: ApprovalDecisionModalProps) => {
  const { t } = useTranslation()

  const { currentAccount } = useAccountsContext()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const {
    approvalsConfig: { handleApprovalDecisionModal },
  } = useApprovalsContext()

  const [approvalDecisionFormState, setApprovalDecisionFormState] = useState({
    hasReviewedPayment: false,
    rejectionReason: '',
    challengeCode: '',
    twoFactorCode: '',
    twoFactorCodeError: false,
    staleSelectedItems: selectedItems,
  })

  const { mutate: approvalWorkflowActionPreRequest, isPending: isLoadingPreRequest } =
    useApprovalWorkflowActionPreRequest()

  const {
    mutate: approvalWorkflowChallengeRequest,
    isPending: isLoadingPostRequest,
    isSuccess: isSuccessPostRequest,
  } = useApprovalWorkflowChallengeRequest()

  const approvalReferencesPayload = () => {
    const correlationKeys = []
    selectedItems?.forEach((selectedItem: { correlationKey?: string }) => {
      correlationKeys.push(selectedItem?.correlationKey)
    })
    return correlationKeys
  }

  const onCompleteHandleReviewPayment = (
    error: ApprovalWorkflowActionResponse,
    isRejectDecisionCheckboxClick?: boolean
  ) => {
    // pre condition to get challengeCode value
    if (error?.status === 428) {
      if (decision === Approval.DECISION.REJECT) {
        if (approvalDecisionFormState?.hasReviewedPayment) {
          // toggle hasReviewedPayment value of checkbox for reject if previously checked
          // albeit always store latest challengeCode for 2FA
          isRejectDecisionCheckboxClick
            ? setApprovalDecisionFormState({
                ...approvalDecisionFormState,
                hasReviewedPayment: !approvalDecisionFormState?.hasReviewedPayment,
                challengeCode: error?.data?.challenge?.value,
              })
            : setApprovalDecisionFormState({
                ...approvalDecisionFormState,
                challengeCode: error?.data?.challenge?.value,
              })
          return
        }

        // initial toggle for hasReviewedPayment value of checkbox for reject when checkbox is clicked
        // albeit always store latest challengeCode for 2FA
        isRejectDecisionCheckboxClick
          ? setApprovalDecisionFormState({
              ...approvalDecisionFormState,
              hasReviewedPayment: !approvalDecisionFormState?.hasReviewedPayment,
              challengeCode: error?.data?.challenge?.value,
            })
          : setApprovalDecisionFormState({
              ...approvalDecisionFormState,
              challengeCode: error?.data?.challenge?.value,
            })
      } else {
        // toggle for decision approve only
        setApprovalDecisionFormState({
          ...approvalDecisionFormState,
          hasReviewedPayment: !approvalDecisionFormState?.hasReviewedPayment,
          challengeCode: error?.data?.challenge?.value,
        })
      }
    } else {
      addToast({
        title: t('oopsSomethingWentWrongContracted'),
        state: 'error',
        delay: 5,
      })
      logSentryError('Error from ApprovalDecisionModal - handleReviewPayment', error)
    }
  }

  const handleReviewPayment = (isRejectDecisionCheckboxClick?: boolean) => {
    approvalWorkflowActionPreRequest(
      {
        action: decision,
        reference: approvalReferencesPayload(),
        ...(decision === Approval.DECISION.REJECT && {
          rejectionReason: approvalDecisionFormState?.rejectionReason,
        }),
      },
      {
        onError: (error: ApprovalWorkflowActionResponse) =>
          onCompleteHandleReviewPayment(error, isRejectDecisionCheckboxClick),
      }
    )
  }

  const onApprovalDecisionSuccess = () => {
    const currentAccountReference = currentAccount?.reference || ''
    const totalValueOfPayments = groupPaymentsTotalByCurrency(selectedItems)
    queryClient.invalidateQueries({
      queryKey: reactQueryKeys.approvalsTransactions(
        currentAccountReference,
        ApprovalStatuses.PENDING
      ),
    })
    handleApprovalDecisionModal({ decision, isVisible: true, isCompleted: true })
    const titleOfEventInput =
      decision === Approval.DECISION.APPROVE
        ? AmplitudeEvent?.EOF_APPROVE_TRANSACTION_SUCCESS
        : AmplitudeEvent?.EOF_REJECT_TRANSACTION_SUCCESS
    track.Amp.track(titleOfEventInput, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      numberOfSelectedItems: selectedItems?.length,
      totalValueOfPayments,
    })
  }

  const onApprovalDescisionError = (error: BaseErrorResponse) => {
    if (error?.status === 403 && !error?.data?.code) {
      setApprovalDecisionFormState({ ...approvalDecisionFormState, twoFactorCodeError: true })
    } else {
      addToast({
        title: t('oopsSomethingWentWrongContracted'),
        state: 'error',
        delay: 5,
      })
      logSentryError('Error from ApprovalDecisionModal - handleApprovalDecisionChallenge', error)
      const titleOfEventInput =
        decision === Approval.DECISION.APPROVE
          ? AmplitudeEvent?.EOF_APPROVE_TRANSACTION_ERROR
          : AmplitudeEvent?.EOF_REJECT_TRANSACTION_ERROR
      track.Amp.track(titleOfEventInput, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
  }

  const handleApprovalDecisionChallenge = () => {
    approvalWorkflowChallengeRequest(
      {
        challenge: approvalDecisionFormState?.challengeCode,
        tokenResponse: approvalDecisionFormState?.twoFactorCode,
      },
      {
        onSuccess: () => onApprovalDecisionSuccess(),
        onError: (error: BaseErrorResponse) => {
          onApprovalDescisionError(error)
        },
      }
    )
  }

  const selectedItemsCount = selectedItems?.length

  useEffect(() => {
    if (approvalDecisionFormState?.twoFactorCode?.length === TWO_FA_REQUIRED_LENGTH) {
      handleApprovalDecisionChallenge()
    }
  }, [approvalDecisionFormState.twoFactorCode])

  return (
    <Modal
      closeOnBackgroundClick={false}
      closeModal={() => handleApprovalDecisionModal({ isVisible: false })}
      visible
    >
      {isSuccessPostRequest ? (
        <ApprovalDecisionSuccess
          decision={decision}
          staleSelectedItems={approvalDecisionFormState?.staleSelectedItems}
        />
      ) : (
        <>
          <ModalNavBar
            hasDivider={false}
            onClose={() => handleApprovalDecisionModal({ isVisible: false })}
          />
          <ModalScrollable>
            <Box flex direction="col" alignItems="center" justifyContent="center">
              <Icon
                color="inherit"
                name={decision === Approval.DECISION.APPROVE ? 'StampIcon' : 'NoEntryIcon'}
              />
              <Text align="center" className="pt-3" weight="semibold">
                {t('approvals.decisionQuestionIntro')} {`${decision?.toLowerCase()} `}
                {selectedItemsCount > 1
                  ? t('approvals.thesePayments', { paymentCount: selectedItemsCount })
                  : t('approvals.thisPayment')}
              </Text>
              <Box paddingT={28} paddingB={12}>
                <CheckBox
                  isLoading={
                    isLoadingPreRequest &&
                    !approvalDecisionFormState?.rejectionReason &&
                    !approvalDecisionFormState?.hasReviewedPayment
                  }
                  label={t('approvals.reviewNote', {
                    paymentPronoun:
                      selectedItemsCount > 1 ? t('approvals.each') : t('approvals.this'),
                  })}
                  value={approvalDecisionFormState?.hasReviewedPayment}
                  onChange={() => handleReviewPayment(decision === Approval.DECISION.REJECT)}
                />
              </Box>
            </Box>
            {decision === Approval.DECISION.REJECT && (
              <Box paddingX={24} paddingY={4}>
                <Input
                  label={t('approvals.reasonForRejection')}
                  placeholder={t('approvals.reason')}
                  rightLabel={t('optionalLabel')}
                  value={approvalDecisionFormState?.rejectionReason}
                  onChange={e => {
                    setApprovalDecisionFormState({
                      ...approvalDecisionFormState,
                      rejectionReason: e.target.value,
                    })
                  }}
                  onBlur={() => handleReviewPayment()}
                />
              </Box>
            )}
            {isLoadingPostRequest ? (
              <Box paddingT={24} paddingB={32} flex justifyContent="center">
                <Loader size="medium" />
              </Box>
            ) : (
              <Box paddingX={24} paddingB={32} direction="col" alignItems="center">
                <Text size="sm" className="relative top-3">
                  {t('authCodeConfirm')}
                </Text>
                <Input2FA
                  testid="approval-decision-2FA-input"
                  disabled={!approvalDecisionFormState?.hasReviewedPayment}
                  onChange={value =>
                    setApprovalDecisionFormState({
                      ...approvalDecisionFormState,
                      twoFactorCode: value,
                    })
                  }
                  error={!!approvalDecisionFormState?.twoFactorCodeError}
                  errorText={t('incorrectOtpCode')}
                />
              </Box>
            )}
          </ModalScrollable>
        </>
      )}
    </Modal>
  )
}

export default ApprovalDecisionModal
