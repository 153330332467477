import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { Profile } from 'types/types'

const useSentryIdentification = (profile: Profile) => {
  const { id } = profile || {}

  useEffect(() => {
    if (!Sentry) return

    if (id) {
      Sentry.setUser({ id })
    }
  }, [id, Sentry])
}

export default useSentryIdentification
