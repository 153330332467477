import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useWalletContext } from 'hooks/context'
import { useDepositDetailsMutation } from 'hooks/mutations'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'

export const usePrepareNewlyCreatedFiatWallet = () => {
  const wallet = useWalletContext()
  const queryClient = useQueryClient()
  const {
    isPending: isDepositLoading,
    isError: isDepositError,
    mutate,
  } = useDepositDetailsMutation()

  useEffect(() => {
    ;(async () => {
      if (wallet?.address) {
        return
      }
      mutate(
        {
          walletId: String(wallet?.id),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: reactQueryKeys.depositAccounts(String(wallet?.id)),
            })
          },
        }
      )
    })()
  }, [wallet])

  return {
    isDepositLoading,
    isDepositError,
  }
}
