import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCryptoMassPayoutById } from 'services/MasspayoutService'
import { QueryMetaType } from 'types/reactQuery'

export const useGetCryptoMassPayoutById = (batchId: string, enabled = false) => {
  const meta: QueryMetaType = {
    errorAdditionalData: {
      batchId,
    },
  }

  return useQuery({
    queryKey: reactQueryKeys.massCryptoPayoutById(batchId),
    queryFn: () => getCryptoMassPayoutById({ batchId }),
    meta,
    enabled,
  })
}
