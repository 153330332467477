import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postPaymentCaseEmail } from 'services/PaymentsService'

export const useSendPaymentCaseEmailMutation = () => {
  return useMutation({
    mutationFn: postPaymentCaseEmail,
    onError: (error, data) => {
      logSentryError('Error: useSendPaymentCaseEmailMutation', error, data)
    },
  })
}
