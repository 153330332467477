import React, { createContext, Dispatch, SetStateAction } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  ExportAccordionItemsType,
  RecurringExportForm,
  RecurringExportStepsType,
  ReportSchedule,
} from 'types/walletTransactionsExport'

export interface RecurringExportContextValue {
  step: RecurringExportStepsType
  createReportSchedule: SubmitHandler<RecurringExportForm>
  updateReportSchedule: SubmitHandler<RecurringExportForm>
  setStep: Dispatch<SetStateAction<RecurringExportStepsType>>
  setCloseAllItems: Dispatch<SetStateAction<boolean>>
  form: UseFormReturn<RecurringExportForm, unknown, undefined>
  handleClose: () => void
  currentReportSchedule: ReportSchedule
  setCurrentReportSchedule: Dispatch<SetStateAction<ReportSchedule>>
  setOpenAccordion: React.Dispatch<React.SetStateAction<ExportAccordionItemsType>>
  isUpdatingReportSchedule: boolean
  setIsUpdatingReportSchedule: Dispatch<SetStateAction<boolean>>
  isLoadingPostReportSchedule: boolean
  isErrorPostReportSchedule: boolean
  postReportScheduleError: BaseErrorResponse
  isLoadingPutReportSchedule: boolean
  isErrorPutReportSchedule: boolean
  putReportScheduleError: BaseErrorResponse
}

export const RecurringExportContext = createContext<RecurringExportContextValue>({
  step: null,
  createReportSchedule: () => null,
  updateReportSchedule: () => null,
  setStep: () => null,
  handleClose: () => null,
  setCloseAllItems: null,
  currentReportSchedule: null,
  setCurrentReportSchedule: () => null,
  setOpenAccordion: () => null,
  isUpdatingReportSchedule: null,
  setIsUpdatingReportSchedule: () => null,
  form: null,
  isLoadingPostReportSchedule: null,
  isErrorPostReportSchedule: null,
  postReportScheduleError: null,
  isLoadingPutReportSchedule: null,
  isErrorPutReportSchedule: null,
  putReportScheduleError: null,
})

interface RecurringExportContextProviderProps {
  value: RecurringExportContextValue
  children: React.ReactNode
}

export const RecurringExportContextProvider = ({
  children,
  value,
}: RecurringExportContextProviderProps) => {
  return <RecurringExportContext.Provider value={value}>{children}</RecurringExportContext.Provider>
}
