import { PaymentStatus } from './payments'
import { WalletType } from './types'

export interface BvnkNetworkTransactionActivities {
  dateCreated: number
  description: string
  step: number
}

export interface Transaction {
  amount: number
  context: Context
  dateCreated: Date
  description: string
  hash: string
  id: number
  type: string
  wallet: WalletType
  runningBalance?: number | string
  reference?: string
  // BVNK Network Transfer
  account?: TransactionBvnkNetworkAccount
}

export interface TransactionBvnkNetworkAccount {
  id: string
  name: string
  reference: string
}

export interface Context {
  id: string
  fromCurrencyCode: string
  type: string
  uuid: string
  status: string
  merchantId: string
  subType: string
  toCurrencyCode: string
  reference?: string
  hash?: string
  withdrawalDetails?: WithdrawalDetailsType
  depositDetails?: DepositDetails
  hasProofOfPayment?: boolean
  externalReference?: string
  isBankingApiWallet?: boolean
  paidActualAmount?: string
  channel?: string
  amount?: string
  paymentReference?: string
  recipientDetails?: string
  sourceAddress: string[] | string
  exchangeRate: number
  fromCurrency?: string
  toCurrency?: string
  convertedFrom?: string
  convertedAmountFrom?: number
  convertedTo?: string
  convertedAmountTo?: number
  paymentMetadata?: PaymentMetadata
  // BVNK Network Transfer context
  beneficiary?: string
  sender?: string
  transactionReference?: string
  transactions?: BvnkNetworkTransactionActivities[]
}

export interface SearchTransactionContent {
  id: string
  transactionId: string
  transactionUUID?: string
  dateCreated: string
  description: string
  amount: number
  status?: string
  fromAmount?: number | string
  toAmount?: number | string
  fromCurrency?: string
  toCurrency?: string
  paymentCode?: string
  type?: string
  subtype?: string
  direction?: string
  code?: string
  subcode?: string
  hash: string
  walletId: number
  walletName: string
  walletAddress?: string
  walletAddresses?: string[]
  reference?: string
  customerReference?: string
  transactionReference?: string
  walletVersion?: string
  accountId: number
  hasProofOfPayment?: boolean
  accountNumber?: string
  bankName?: string
  bankCode?: string
  name?: string
  exchangeRate?: number
}

export interface WithdrawalDetailsType {
  beneficiary?: WithdrawalBeneficiaryDetails
  valueDate?: string
}

interface WithdrawalBeneficiaryDetails {
  accountNumber?: string
  bankCode?: string
  bankName?: string
  name?: string
  type?: string
}

export interface DepositDetails {
  senderAccountNumber?: string
  senderBankCode?: string
  senderName?: string
  senderReference?: string
  valueDate?: string
}

interface CsvRequestData {
  type: string
  walletId: string | null
  transactionType: string | null
  fromDate: string
  toDate: string
  format: string
  languageTag: string
  category: string | number
}

interface PdfRequestData {
  type: string
  walletId: string | null
  transactionType: string | null
  fromDate: string
  toDate: string
  format: string
  languageTag: string
  category: string | number
}

export interface CsvExportTransaction {
  id: string
  uuid: string
  dateCreated: number
  lastUpdated: number
  expiryDate: number
  status: PaymentStatus | string
  type: string
  requestData: CsvRequestData
}

export interface PdfExportTransaction {
  id: string
  uuid: string
  dateCreated: number
  lastUpdated: number
  expiryDate: number
  status: PaymentStatus | string
  type: string
  requestData: PdfRequestData
}

export const TransactionStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  DECLINED: 'DECLINED',
  PROCESSING: 'PROCESSING',
  PENDING_USER_APPROVAL: 'PENDING_USER_APPROVAL',
  SUBMITTED: 'SUBMITTED',
} as const

export type TransactionStatus = (typeof TransactionStatus)[keyof typeof TransactionStatus]
export interface PaymentMetadata {
  exchangeRate: number
  displayCurrency: string
  displayActualAmount: number
  displayRequiredAmount: number
}
