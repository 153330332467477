import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '@node-space/hooks'
import { Box, Box as FormRow } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import {
  CheckboxSelect,
  checkboxSelectClearAction,
} from '@node-space/storybook-components/dist/CheckboxSelect'
import { DatePicker, dateSelectClearAction } from '@node-space/storybook-components/dist/DatePicker'
import { Search } from '@node-space/storybook-components/dist/Search'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TableRowSkeleton } from 'components/Skeletons/TableRowSkeleton'
import { DateFormat, PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useWalletSearchQuery } from 'hooks/queries/useWalletSearchQuery'
import { TransactionsPagination } from 'pages/WalletDetails/components/RecentActivity/TransactionsPagination'
import { IDateValue } from 'types/types'
import { sanitizeUserInput } from 'utils/utils'
import { SearchTransactionTable, TransactionContent } from './SearchTransactionTable'
import { SidePanelSummary } from './SidePanel/SidePanelSummary'

const DEFAULT_NUMBER_OF_ROWS = '10'

interface SearchWalletTableProps {
  pageId: string
}

export const searchWalletTableTags = {
  DASHBOARD_RECENT_WALLETS: {
    id: 'DASHBOARD_RECENT_WALLETS',
    pageName: 'Home Page',
    skeletonTableRow: 15,
    limitReturnedRowsTo: 8,
  },
  WALLETS_TRANSACTION_HISTORY: {
    id: 'WALLETS_TRANSACTION_HISTORY',
    pageName: 'Wallets Page',
    skeletonTableRow: 4,
    limitReturnedRowsTo: 15,
  },
}

export const SearchWalletTable = ({ pageId }: SearchWalletTableProps) => {
  const { t } = useTranslation()
  const { currentAccount } = useAccountsContext()

  const navigate = useNavigate()

  const [searchTermQ, setSearchTermQ] = useState<string | undefined>('')

  const [dateValue, setDateValue] = useState<IDateValue>({
    startDate: null,
    endDate: null,
  })
  const [dateIntervalChanged, setDateIntervalChanged] = useState<Boolean>(false)

  const [selectedOptions, setSelectedOptions] = useState([])

  const [page, setPage] = useState(0)
  const [searchPage, setSearchPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [numberOfRows, setNumberOfRows] = useState(DEFAULT_NUMBER_OF_ROWS)

  const skeletonTableRow = searchWalletTableTags[pageId].skeletonTableRow
  const pageName = searchWalletTableTags[pageId].pageName

  const walletIsBeingSearched = !!(
    searchTermQ.length ||
    selectedOptions.length ||
    dateIntervalChanged
  )

  const {
    data: walletTransactions,
    isPending,
    isFetching,
    isError,
    isSuccess,
  } = useWalletSearchQuery({
    accountId: currentAccount?.id,
    searchTerm: useDebounce(searchTermQ, 1000),
    startDate: dateValue.startDate,
    endDate: dateValue.endDate,
    aggregation: useDebounce(selectedOptions, 1000),
    pageSize: numberOfRows,
    pageNumber: walletIsBeingSearched ? searchPage : page,
  })

  const paging = {
    total: walletTransactions?.transactions?.totalElements,
    offset,
    max: walletTransactions?.transactions?.pageable?.pageSize,
  }

  const {
    isSidePanelOpen: isTransactionDetailsOpen,
    sidePanelData: transactionDetails,
    paging: pageTransactions,
    setIsSidePanelOpen: setIsTransactionDetailsOpen,
    updateSidePanel: updateTransactionDetails,
  } = useSidePanel(walletTransactions?.transactions?.content)

  const openSidePanelSummary = (index: number) => {
    updateTransactionDetails(index)
    setIsTransactionDetailsOpen(true)
  }

  const onSearchQueryChanged = e => {
    const { value } = e?.target ?? ''
    setSearchTermQ(sanitizeUserInput(value))
  }

  const onDateSelected = (dateVal: IDateValue) => {
    setDateValue(dateVal)
    setDateIntervalChanged(true)
  }

  const clearFilters = () => {
    setPage(0)
    setSearchPage(0)
    setSearchTermQ('')
    setSelectedOptions([])
    checkboxSelectClearAction()
    setDateValue({
      startDate: null,
      endDate: null,
    })
    dateSelectClearAction()
  }

  const hasWalletTransactions = !!walletTransactions?.transactions?.content?.length

  useEffect(() => {
    // Set dateIntervalChanged value to false for null date values
    !dateValue.startDate && !dateValue.endDate && setDateIntervalChanged(false)
  }, [walletTransactions, walletIsBeingSearched, pageName])

  useEffect(() => {
    if (walletIsBeingSearched) {
      setSearchPage(0)
    }
  }, [walletIsBeingSearched])

  const shouldDisplayPaginator =
    pageId === searchWalletTableTags.WALLETS_TRANSACTION_HISTORY.id &&
    !!paging?.total &&
    numberOfRows &&
    !isError

  return (
    <div data-testid="recent-activity-screen">
      <Box paddingT={16} paddingB={8} gapX={16} flex flexWrap>
        <FormRow
          testid="transaction-text-search-input"
          width="full"
          className="pr-2 pb-4 lg:flex-auto lg:w-60"
          gapY={12}
        >
          <Search
            onChange={onSearchQueryChanged}
            placeholderText={t('wallets.searchPlaceholderText')}
            searchTerm={searchTermQ}
            size="sm"
            textSize="sm"
          />
        </FormRow>
        <FormRow
          testid="transaction-date-search-input"
          width="full"
          className="pr-2 pb-4 lg:flex-auto lg:w-24"
          gapY={12}
        >
          <DatePicker
            disabled={isFetching}
            dateRange
            dateFieldPlaceholder={t('wallets.selectDate')}
            dateValue={dateValue}
            onDateValueChange={dateVal => onDateSelected(dateVal)}
            dateRangeSeparator="-"
            maxDate={new Date()}
            enableDateFieldEdit={false}
            displayFormat={DateFormat.DD_MMM_YYYY}
          />
        </FormRow>
        <FormRow
          testid="transaction-type-search-input"
          flex
          width="full"
          className="pr-2 pb-4 lg:flex-auto lg:w-16"
        >
          <FormRow width="auto" style={{ width: '220px' }}>
            <CheckboxSelect
              disabled={isPending}
              allOptions={
                walletTransactions?.aggregations as readonly { value: string; label: string }[]
              }
              placeholder={t('wallets.transactionTypePlaceholderText')}
              selectedOptions={selectedOptions}
              isClearable={false}
              handleChange={options => setSelectedOptions(options.map(opt => opt.value))}
            />
          </FormRow>
          {walletIsBeingSearched && !isFetching && (
            <Button
              testid="clear-search-filters-btn"
              noStyling
              onClick={clearFilters}
              className="ml-2"
            >
              <Text size="sm" color="primary-500">
                {t('clearFilters')}
              </Text>
            </Button>
          )}
        </FormRow>
      </Box>

      {!isError && walletIsBeingSearched && !isFetching && !hasWalletTransactions ? (
        <StatusCard
          testid="transaction-search-status-card"
          headline={t('wallets.noSearchResultHeadline')}
        >
          <Text testid="transaction-search-no-result-label" align="center" size="sm">
            {t('wallets.noSearchResultDescription')}
            <Button
              testid="transaction-search-clear-result-btn"
              noStyling
              onClick={clearFilters}
              className="ml-1 text-primary-500"
            >
              {t('wallets.clearSearchCriteria')}
            </Button>
          </Text>
        </StatusCard>
      ) : !isError && !isFetching && !hasWalletTransactions ? (
        <StatusCard
          testid="transaction-search-no-transactions-label"
          headline={t('wallets.emptyStateHeadline')}
          description={t('wallets.noTransactionDescription')}
          button={{
            text: t('wallets.goToWallets'),
            onClick: () => {
              navigate(`${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`, { replace: true })
            },
          }}
        />
      ) : (
        <>
          {!isError && isFetching && !hasWalletTransactions && (
            <Box width="full">
              <TableRowSkeleton rows={skeletonTableRow} />
            </Box>
          )}
          {isSuccess && hasWalletTransactions && (
            <>
              {!!transactionDetails && (
                <SidePanelSummary
                  isOpen={isTransactionDetailsOpen}
                  paging={pageTransactions}
                  onClose={() => setIsTransactionDetailsOpen(false)}
                  transactionDetails={transactionDetails as TransactionContent}
                />
              )}
              <SearchTransactionTable
                walletTransactions={walletTransactions}
                isSearching={isFetching}
                onOpenSidePanelSummary={(index: number) => openSidePanelSummary(index)}
              />
            </>
          )}
          {shouldDisplayPaginator && (
            <Box paddingT={8}>
              <TransactionsPagination
                page={walletIsBeingSearched ? searchPage : page}
                setPage={walletIsBeingSearched ? setSearchPage : setPage}
                setOffset={setOffset}
                pagination={paging}
                nrOfRows={numberOfRows}
                setNrOfRows={setNumberOfRows}
              />
            </Box>
          )}
          {isError && <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />}
        </>
      )}
    </div>
  )
}
