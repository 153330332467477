import React, { Dispatch, SetStateAction } from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box, Box as Container } from '@node-space/storybook-components'
import Callout from '@node-space/storybook-components/dist/Callout'
import DatePicker from '@node-space/storybook-components/dist/DatePicker'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useOnceOffExportContext } from 'hooks/context/useOnceOffExportContext'
import { customDateRadioOptions } from 'pages/WalletDetails/constants/walletTransactionsExportOptions'
import { ExportFileFormatType } from 'types/walletTransactionsExport'

interface OnceOffExportFormType {
  downloadAll: boolean
  setExportFormat: Dispatch<SetStateAction<string>>
}

export const OnceOffExportForm = ({ downloadAll, setExportFormat }: OnceOffExportFormType) => {
  const { t } = useTranslation()

  const { enableRecurringExports } = useFeatureFlags()
  const {
    formValues,
    setValue,
    exportCustomDateOptions,
    dateValue,
    setDateValue,
    exportFormatOptions,
    allTransactionsExportError,
    isErrorAllTransactionsExport,
    walletTransactionsExportError,
    isErrorWalletTransactionsExport,
  } = useOnceOffExportContext()

  const addPadding = enableRecurringExports ? !downloadAll : !enableRecurringExports

  // from & to date values of other specified date range options asides "Custom date range" e.g last 30 days, previous N calendar months etc

  const exportCustomDateRadioOptions = customDateRadioOptions(exportCustomDateOptions)

  const customDateFieldIsSelected =
    formValues.selectedDateOptionName === exportCustomDateOptions?.customDateRange?.name

  const allTransactionsErrorList = allTransactionsExportError?.errorList
  const walletTransactionsErrorList = walletTransactionsExportError?.errorList

  const getErrorMessage = () => {
    if (!isEmpty(allTransactionsErrorList)) {
      return allTransactionsErrorList?.[0]?.code === 'reportRequestCurrentlyProcessing'
        ? t('exports.onceOffExportDuplicateError')
        : allTransactionsErrorList?.[0]?.message
    }
    if (!isEmpty(walletTransactionsErrorList)) {
      return walletTransactionsErrorList?.[0]?.code === 'reportRequestCurrentlyProcessing'
        ? t('exports.onceOffExportDuplicateError')
        : allTransactionsErrorList?.[0]?.message
    }

    return t('oopsSomethingWentWrong')
  }

  return (
    <Box tag="form">
      <Container paddingX={16} {...(addPadding && { paddingT: 20 })}>
        <Radio
          onChange={value => {
            setValue('selectedDateOptionName', value)
          }}
          options={exportCustomDateRadioOptions}
          value={formValues.selectedDateOptionName}
        />
        {customDateFieldIsSelected && (
          <Box paddingL={12} paddingR={32} paddingB={12}>
            <DatePicker
              dateRange
              dateFieldLabel={t('walletsPage.exportDateOptions.dateRangeInputLabel')}
              dateFieldPlaceholder={t('walletsPage.exportDateOptions.dateRangeInputLabel')}
              dateValue={dateValue}
              onDateValueChange={dateVal => setDateValue(dateVal)}
              dateRangeSeparator="-"
              maxDate={new Date()}
              enableDateFieldEdit={false}
            />
          </Box>
        )}
        {!downloadAll && (
          <Box paddingT={4}>
            <Text className="ml-4">{t('walletsPage.exportFormat')}</Text>
            <Radio
              horizontal
              onChange={value => {
                setValue('selectedExportFormat', value as ExportFileFormatType)
                setExportFormat(value)
              }}
              options={exportFormatOptions}
              value={formValues?.selectedExportFormat}
            />
          </Box>
        )}

        {(isErrorAllTransactionsExport || isErrorWalletTransactionsExport) && (
          <Box paddingL={12} paddingB={12}>
            <Callout state="error" message={getErrorMessage()} />
          </Box>
        )}
      </Container>
    </Box>
  )
}
