import { LegalAgreementStatusType } from 'pages/LegalAgreements/types'
import { ValidateIbanRequest, ValidateScanRequest } from 'types/beneficiaries'
import { ConnectionsRequest } from 'types/bvnkNetwork'
import { CapabilitiesScope } from 'types/capabilities/capabilities'
import { PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import { GetChannelsReq } from 'types/transaction/GetChannelsReq'
import { GetWalletTransactionsReq } from 'types/transaction/GetTransactionsReq'
import { Paging } from 'types/types'
import { TWalletType, WalletsPayRequest } from 'types/wallets'

export const reactQueryName = {
  BENEFICIARIES: 'beneficiaries',
} as const

export const reactQueryKeys = {
  accountPreferences: () => ['accountPreferences'] as const,
  accountUserGroups: () => ['accountUserGroups'] as const,
  accountUsers: () => ['accountUsers'] as const,
  accounts: () => ['accounts'],
  accountApprovalsPreference: (accountReference: string, preference?: string) => [
    'accountApprovalsPreference',
    accountReference,
    preference,
  ],
  activity: () => ['activity'],
  apiKeys: (query?: string) => ['apiKeys', query] as const,
  approvalsTransactions: (currentAccountReference: string, status: string) => [
    'approvalsTransactions',
    currentAccountReference,
    status,
  ],
  banks: (countryCode: string, currencyCode: string, canEnable: boolean) => [
    'banks',
    countryCode,
    currencyCode,
    canEnable,
  ],
  beneficiaries: (size: number, page: number, sort: string) =>
    [reactQueryName.BENEFICIARIES, size, page, ...(sort && [{ sort }])] as const,
  bvnkNetworkConnections: (request: ConnectionsRequest) => ['bvnkNetworkConnections', request],
  bvnkNetworkMembers: (accountReference: string) => ['bvnkNetworkMembers', accountReference],
  capabilities: (group: string, name: string, scope: CapabilitiesScope, subjectId: string) =>
    ['capabilities', group, name, scope, subjectId] as const,
  channels: (req: GetChannelsReq) => ['channels', req],
  channelPayments: (
    paging: Paging,
    from: string,
    to: string,
    merchant: string,
    status: string,
    search: string
  ) => ['channelPayments', from, to, merchant, status, search, paging],
  country: () => ['detectCountry'],
  countries: (canEnable: boolean, pagination: boolean) => ['countries', canEnable, pagination],
  customer: (customerId: string) => ['customer', customerId],
  customers: (size: number, page: number, sort: string) =>
    ['customers', size, page, ...(sort && [{ sort }])] as const,
  currencyPairs: () => ['currencyPairs'] as const,
  cryptoAddresses: () => ['cryptoAddresses'],
  cryptoCurrencies: () => ['cryptoCurrencies'],
  depositAccounts: (walletId: string) => ['depositAccounts', walletId],
  exchangeRate: (fromCurrency: string, toCurrency: string, amount: number | string) => [
    'exchangeRate',
    fromCurrency,
    toCurrency,
    amount,
  ],
  fiatCurrencies: () => ['fiatCurrencies'],
  fiatWithCurrencyCodes: () => ['fiatWithCurrencyCodes'],
  getCryptoAddresses: (req: {
    queryTerm?: string
    currency?: string
    offset?: number
    max?: number
    enabled?: boolean
  }) => ['getCryptoAddresses', req],
  getWalletLimits: (currencyCode: string, walletId: number) => [
    'getWalletLimits',
    currencyCode,
    walletId,
  ],
  industries: () => ['industries'],
  legalAgreements: (statusParams?: LegalAgreementStatusType[]) => ['legalAgreements', statusParams],
  massFiatPayoutsDetails: (batchReference: string) => ['massFiatPayoutsDetails', batchReference],
  massFiatPayoutsList: () => ['massFiatPayoutsList'],
  massCryptoPayoutsList: () => ['massCryptoPayoutsList'] as const,
  massCryptoPayoutById: (batchId: string) => ['massCryptoPayoutById', batchId],
  massCryptoPayoutsListByPolling: () => ['massCryptoPayoutsListByPolling'] as const,
  merchants: () => ['merchants'],
  monthlyVolumes: () => ['monthlyVolumes'],
  payments: (
    paging: Paging,
    from: string,
    to: string,
    merchant: string,
    status: string,
    searchByRef: string,
    searchByTransactionHash: string,
    tag: string
  ) => ['payments', from, to, merchant, status, searchByRef, searchByTransactionHash, paging, tag],
  paymentCaseDocument: (paymentCaseDocumentId: string) => [
    'paymentCaseDocument',
    paymentCaseDocumentId,
  ],
  paymentCaseDocuments: (paymentCaseId: string) => ['paymentCaseDocuments', paymentCaseId],
  paymentCaseEmails: (paymentCaseId: string) => ['paymentCaseEmails', paymentCaseId],
  payFees: (walletId: number, amount: number) => ['payFees', walletId, amount],
  payoutsFeeEstimate: (request: PayoutsV2PreAuthRequest) => ['payoutsFeeEstimate', request],
  paymentSupportCases: () => ['paymentSupportCases'],
  pendingAgreements: () => ['accountAgreements'],
  postPay: (walletId: string, request: WalletsPayRequest) => ['postPay', walletId, request],
  postWalletSearch: req => ['postWalletSearch', req],
  profile: () => ['profile'],
  documentDownload: (url: string) => ['documentDownload', url],
  quote: (uuid: string) => ['uuid', uuid],
  reissueToken: () => ['reissueToken'],
  reportSchedules: () => ['reportSchedules'],
  reportScheduleTimezones: () => ['reportScheduleTimezones'],
  secrectCode: () => ['secrectCode'],
  supportedCurrencies: () => ['supportedCurrencies'],
  tradingHours: () => ['tradingHours'],
  totalBalance: (baseCurrency: string) => ['totalBalance', baseCurrency] as const,
  validateIban: (req: ValidateIbanRequest) => ['validateIban', req],
  validateScan: (req: ValidateScanRequest) => ['validateScan', req],
  validateBic: (req: string) => ['validateBic', req],
  verification: ['verification'] as const,
  verificationRequestStatus: () =>
    [...reactQueryKeys.verification, 'verificationRequestStatus'] as const,
  wallet: (id: number) => ['wallet', id],
  wallets: () => ['wallets'] as const,
  walletsV2: (walletType: TWalletType, searchTerm?: string, offset?: number, max?: number) =>
    ['walletsV2', walletType, searchTerm, offset, max] as const,
  walletTransactions: (req: GetWalletTransactionsReq) => ['walletTransactions', req],
  withdrawalFee: (walletId: number, amount: number) => ['withdrawalFee', walletId, amount] as const,
  southAfricanBanks: () => ['southAfricanBanks'],
  chatbotToken: () => ['chatbotToken'],
}
