import React, { useEffect, useMemo } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Box, {
  Box as Container,
  Box as LoadingContainer,
  Box as Row,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import Input from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import { Text } from '@node-space/storybook-components/dist/Text'
import Loader from 'components/spinners/LoadingAnimationDark'
import { PathNames } from 'constants/General'
import {
  bvnkNetworkConnectionsAtom,
  isFetchingConnectionsAtom,
  isFetchingConnectionsErrorAtom,
} from 'pages/BvnkNetwork/atoms/bvnkNetworkAtoms'
import CompanyLogo from 'pages/BvnkNetwork/components/CompanyLogo'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { formatString, generateReference, roundNumberWithCommas } from 'utils/utils'
import { selectedBvnkNetworkBeneficiaryAtom, selectedWalletAtom } from './atoms/atoms'
import { PayActionProps } from './Pay'

const PayFormBvnkNetworkBeneficiary = ({
  action,
  wallets,
  form,
  loading: isLoading,
  isLimitsError,
  isFetchingWallets,
  registerFormField,
  dismissAction,
  setStep,
}: PayActionProps) => {
  const { t } = useTranslation()
  const formValues = form.watch()

  const bvnkNetworkConnections = useAtomValue(bvnkNetworkConnectionsAtom)
  const isFetchingConnections = useAtomValue(isFetchingConnectionsAtom)
  const isFetchingConnectionsError = useAtomValue(isFetchingConnectionsErrorAtom)
  const setSelectedWalletAtom = useSetAtom(selectedWalletAtom)
  const setSelectedBvnkNetworkBeneficiaryAtom = useSetAtom(selectedBvnkNetworkBeneficiaryAtom)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.PAY_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code,
      walletId: selectedWallet?.id,
    })
  }, [])

  const selectedNetworkBeneficiary = useMemo(() => {
    if (!formValues?.beneficiarySelection && !bvnkNetworkConnections?.connected?.length) return

    return bvnkNetworkConnections?.connected?.find(
      connection => connection?.id === formValues?.beneficiarySelection
    )
  }, [formValues?.beneficiarySelection, bvnkNetworkConnections?.connected])

  useEffect(() => {
    form?.setValue('reference', generateReference())
  }, [formValues?.walletId])

  useEffect(() => {
    setSelectedBvnkNetworkBeneficiaryAtom(selectedNetworkBeneficiary)
  }, [selectedNetworkBeneficiary])

  const selectedWallet = useMemo(() => {
    if (isFetchingWallets || !wallets?.length) return

    if (formValues?.walletId) {
      return wallets?.find(wallet => wallet?.id?.toString() === formValues?.walletId)
    } else if (selectedNetworkBeneficiary && action?.metadata?.pay?.beneficiaryOptionId) {
      const wallet = wallets?.find(
        wallet => wallet?.lsid === selectedNetworkBeneficiary?.wallets?.[0]?.walletId
      )

      form.setValue('walletId', wallet?.id?.toString())
      return wallet
    }

    return
  }, [
    action?.metadata?.pay?.beneficiaryOptionId,
    formValues?.walletId,
    selectedNetworkBeneficiary,
    isFetchingWallets,
    wallets,
  ])

  useEffect(() => {
    setSelectedWalletAtom(selectedWallet)
  }, [selectedWallet])

  const isInsufficientBalance = selectedWallet?.balance <= 0

  const beneficiaryOptions = useMemo(() => {
    return bvnkNetworkConnections?.connected?.map(connection => {
      const { details, id } = connection || {}
      return {
        icon: details?.name ?? '',
        label: details?.name,
        value: id,
        secondLabel: null,
        secondLabelText: details?.description || ' ',
        customIcon: <CompanyLogo companyName={details?.name} size={36} />,
        hideSecondLabelDelimiter: true,
      }
    })
  }, [bvnkNetworkConnections?.connected])

  const selectBeneficiaryDropdownPlaceholder = useMemo(() => {
    if (!beneficiaryOptions?.length) {
      return t('manageBeneficiaries.noBeneficiaries')
    } else if (!!beneficiaryOptions?.length && !selectedNetworkBeneficiary) {
      return t('manageBeneficiaries.beneficiarySelect')
    }
  }, [beneficiaryOptions?.length, selectedNetworkBeneficiary])

  const errorCalloutMessage = useMemo(() => {
    if (isFetchingConnectionsError) {
      return t('bvnkNetwork.connectionsListError')
    } else if (isLimitsError) {
      return t('manageBeneficiaries.beneficiaryLimitshError')
    }
  }, [isLimitsError])

  const actions: ButtonProps[] = [
    { children: t('cancel'), secondary: true, onClick: dismissAction },
    {
      children: t('payRecipient'),
      testid: t('payRecipient'),
      type: 'submit',
      loading: isLoading || isFetchingConnections,
      disabled:
        !selectedNetworkBeneficiary ||
        !beneficiaryOptions?.length ||
        isInsufficientBalance ||
        !selectedWallet,
    },
  ]

  const isShowingInsufficientError = isInsufficientBalance && !errorCalloutMessage
  const isShowingErrorCalloutMessage = !!errorCalloutMessage
  const isShowingBeneficiarySelect =
    !isInsufficientBalance &&
    !errorCalloutMessage &&
    beneficiaryOptions?.length &&
    !isLoading &&
    !isFetchingConnections &&
    (selectedWallet || action?.metadata?.pay?.beneficiaryOptionId)

  const onSubmit: SubmitHandler<typeof formValues> = async () => {
    setStep('amount')
  }

  const onWalletChange = walletId => {
    form.setValue('walletId', walletId)
  }

  const onBeneficiaryChange = beneficiaryReference => {
    form.setValue('beneficiarySelection', beneficiaryReference)
  }

  return (
    <Wrapper>
      <ModalNavBar title={t('whoToPay')} onClose={dismissAction} />
      <Row padding={24}>
        <Container flex direction="col" gapY={24}>
          <DoubleLabelSelect
            disabled
            key={formValues?.walletId}
            iconsUrl={process?.env?.ICONS_URL}
            label={t('wallet')}
            value={formValues?.walletId}
            placeholder={!selectedWallet ? t('noWallets') : t('searchOrSelectAWallet')}
            name="currencyCode"
            skeletonLoading={isFetchingWallets}
            options={
              selectedWallet && [
                {
                  label: selectedWallet?.description,
                  value: selectedWallet?.id?.toString(),
                  icon: selectedWallet?.currency?.code?.toLowerCase(),
                  secondLabel: formatString(
                    t('balanceWithCurrency'),
                    roundNumberWithCommas(selectedWallet?.balance, 2),
                    selectedWallet?.currency?.code
                  ),
                },
              ]
            }
            onChange={onWalletChange}
          />

          {isLoading && (
            <LoadingContainer flex justifyContent="center" paddingT={32}>
              {Loader}
            </LoadingContainer>
          )}

          {!isLoading && (
            <>
              {isShowingInsufficientError && (
                <Callout
                  state="error"
                  message={t('payInsufficient', { currencyCode: selectedWallet?.currency?.code })}
                />
              )}

              {isShowingErrorCalloutMessage && (
                <Callout state="error" message={errorCalloutMessage} />
              )}

              {isShowingBeneficiarySelect ? (
                <>
                  <DoubleLabelSelect
                    disabled={!beneficiaryOptions?.length}
                    iconsUrl={process.env.ICONS_URL}
                    label={t('manageBeneficiaries.selectBeneficiary')}
                    menuMaxHeight={300}
                    name="beneficiarySelection"
                    options={beneficiaryOptions}
                    placeholder={selectBeneficiaryDropdownPlaceholder}
                    value={formValues?.beneficiarySelection}
                    onChange={onBeneficiaryChange}
                    helperText={t('payouts.beneficiaryFiltersDisclaimer')}
                  />
                </>
              ) : (
                !isShowingErrorCalloutMessage &&
                !isShowingInsufficientError && (
                  <Callout state="warning">
                    <Box flex direction="col" gapY={12}>
                      <Text size="sm" color="inherit">
                        {t('payouts.noSupportedBeneficiaries')}
                      </Text>
                      <Quicklink
                        className="text-secondary-500 hover:text-secondary-700"
                        to={`${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}?callbackUrl=${location.pathname}`}
                        text={t('addNewBeneficiaryWithoutPlus')}
                        target="_top"
                      />
                    </Box>
                  </Callout>
                )
              )}
              {!isShowingErrorCalloutMessage && (
                <Input
                  {...registerFormField('reference')}
                  value={formValues?.reference}
                  placeholder={t('inputPlaceholders.beneficiaryPaymentReferencePlaceHolder')}
                />
              )}
            </>
          )}
        </Container>
      </Row>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalActions actions={actions} />
      </form>
    </Wrapper>
  )
}

export default React.memo(PayFormBvnkNetworkBeneficiary)
