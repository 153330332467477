import { useEffect, useMemo } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Profile } from 'types/types'
import type { AccountsData } from '../contexts/accountsContext'

const useLaunchDarkly = (profile: Profile, accountContext: { data: AccountsData }) => {
  const LDClient = useLDClient()
  const { id } = profile ?? {}

  // Memoize the context to prevent it from changing on every render.
  const context = useMemo(() => {
    if (!profile || !accountContext?.data?.currentAccount) return null

    const { firstName, lastName, emailAddress: email } = profile || {}
    const currentAccount = accountContext?.data?.currentAccount

    return {
      kind: 'multi',
      user: { key: `${id}`, firstName, lastName, email },
      account: {
        key: `${currentAccount?.id}`,
        name: `${currentAccount?.name}`,
        orgId: `${currentAccount?.id}`,
        debugEmail: email,
      },
    }
  }, [profile, accountContext?.data?.currentAccount])

  useEffect(() => {
    if (id && LDClient && context) {
      LDClient.identify(context)
    }
  }, [id, LDClient, context])

  return { LDClient }
}

export default useLaunchDarkly
