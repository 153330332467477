import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Modal, { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import {
  SimpleTableV2,
  TableColumnV2,
  TableRowV2,
} from '@node-space/storybook-components/dist/SimpleTableV2'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { MerchantType } from 'types/types'
import { MerchantDetailsSummary } from './MerchantDetailsSummary'
import { MerchantEdit } from './MerchantEdit'
import MerchantWalletInfo from './MerchantWalletInfo'

export const MerchantTable = () => {
  const { t } = useTranslation()

  const { data: { merchantList = [] } = {} } = useMerchantsQuery()

  const permissions = useMappedRoles()?.manageMerchants
  const canEdit = permissions?.canEdit

  const [isModalEditOpen, setModalEditOpen] = useState(false)
  const [isModalDetailsOpen, setModalDetailsOpen] = useState(false)
  const [selectedDetailsMerchant, setSelectedDetailMerchant] = useState<MerchantType>(null)
  const [selectedEditMerchant, setSelectedEditMerchant] = useState<MerchantType>(null)

  const handleOpenEditModal = (merchant: MerchantType) => {
    setSelectedEditMerchant(merchant)
    setModalEditOpen(true)
  }

  const handleCloseEditModal = () => {
    setSelectedEditMerchant(null)
    setModalEditOpen(false)
  }

  const handleOpenDetailsModal = (merchant: MerchantType) => {
    setSelectedDetailMerchant(merchant)
    setModalDetailsOpen(true)
  }

  const handleRowClick = (merchant: MerchantType) => {
    setSelectedDetailMerchant(merchant)
    setModalDetailsOpen(true)
  }

  const handleCloseDetailModal = () => {
    setModalDetailsOpen(false)
    setSelectedDetailMerchant(null)
  }
  const tableRowClasses = 'py-5 px-1 '

  const tableColumns: TableColumnV2[] = [
    { headerTitle: t('merchant'), className: 'pl-4' },
    { headerTitle: t('wallet') },
    { headerTitle: t('balance') },
    { headerTitle: '' },
  ]

  const getTableData = (): TableRowV2[] => {
    if (!merchantList) return []
    return merchantList?.map((merchant: MerchantType) => ({
      onClick: () => handleRowClick(merchant),
      rowData: [
        { cellData: merchant?.displayName, className: `${tableRowClasses} pl-4` },
        {
          cellData: merchant?.wallet?.currency ? (
            <MerchantWalletInfo
              description={merchant?.wallet?.description}
              currencyCode={merchant?.wallet?.currency.code}
            />
          ) : (
            '-'
          ),
        },
        { cellData: merchant?.wallet?.balance ? merchant?.wallet?.balance : '-' },
        {
          cellData: canEdit ? (
            <TablePopoverMenu
              key={`${merchant?.merchantId}_${merchant?.displayName}_${merchant?.webhookUrl}_${merchant?.wallet?.id}`}
              stopPropagation
              testid={merchant?.merchantId}
              menuItems={[
                {
                  iconName: 'InfoCircleIcon',
                  label: t('Details'),
                  onClick: () => handleOpenDetailsModal(merchant),
                },
                {
                  iconName: 'EditIcon',
                  label: t('Edit'),
                  onClick: () => handleOpenEditModal(merchant),
                },
              ]}
            />
          ) : (
            <TablePopoverMenu
              key={`${merchant?.merchantId}_${merchant?.displayName}_${merchant?.webhookUrl}_${merchant?.wallet?.id}`}
              stopPropagation
              testid={merchant?.merchantId}
              menuItems={[
                {
                  iconName: 'InfoCircleIcon',
                  label: t('Details'),
                  onClick: () => handleOpenDetailsModal(merchant),
                },
              ]}
            />
          ),
          className: `${tableRowClasses} pr-4`,
          align: 'end',
        },
      ],
    }))
  }

  return (
    <Box className="overflow-y-hidden">
      <SimpleTableV2 columns={tableColumns} tableData={getTableData()} />

      {selectedEditMerchant && (
        <MerchantEdit
          merchant={selectedEditMerchant}
          isModalOpen={isModalEditOpen}
          handleCloseModal={handleCloseEditModal}
        />
      )}

      {selectedDetailsMerchant && (
        <Modal allowOverFlow visible={isModalDetailsOpen} closeModal={handleCloseDetailModal}>
          <ModalNavBar
            title={selectedDetailsMerchant?.displayName}
            onClose={handleCloseDetailModal}
          />

          <MerchantDetailsSummary merchant={selectedDetailsMerchant} />
        </Modal>
      )}
    </Box>
  )
}
