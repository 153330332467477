import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { AddCryptoAddressResponse } from 'types/whitelisting'
import CurrencyCell from 'components/CurrencyCell'
import DeleteAddressModal from './DeleteModal/DeleteAddressModal'
import { TableDataSkeleton } from 'components/Skeletons/TableDataSkeleton'
import { TableRowSkeleton } from 'components/Skeletons/TableRowSkeleton'

interface CryptoAddressTableProps {
  cryptoAddresses: AddCryptoAddressResponse[]
  showDeleteModal: boolean
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
  onDeleteCallback: () => void
  isSearching: boolean
  canEdit: boolean
}
const CryptoAddressTable = ({
  cryptoAddresses,
  showDeleteModal,
  setShowDeleteModal,
  handleClose,
  onDeleteCallback,
  isSearching,
  canEdit,
}: CryptoAddressTableProps) => {
  const { t } = useTranslation()

  const [selectedCryptoAddress, setSelectedCryptoAddress] = useState()

  const setCryptoId = useCallback(
    address => {
      setSelectedCryptoAddress(address)
    },
    [selectedCryptoAddress]
  )

  const mappedTableData = cryptoAddresses?.map(cryptoAddress => {
    return {
      rowData: [
        {
          className: 'p-4',
          cellData: isSearching ? (
            <TableDataSkeleton skeletalIcon />
          ) : (
            <CurrencyCell
              cryptoDescription={`${cryptoAddress?.currency?.code} ${
                !!cryptoAddress?.protocol ? `(${cryptoAddress?.protocol})` : ''
              }`}
              currencyName={cryptoAddress?.currency?.name}
              currencyCode={cryptoAddress?.currency?.code}
              key="name"
            />
          ),
        },
        {
          className: 'px-4',
          cellData: (
            <Box>
              <Text size="sm">{cryptoAddress?.address}</Text>
              {cryptoAddress?.destinationTag && (
                <Text className="text-tiny" color="text-500">
                  {`${t('destinationTag')}: ${cryptoAddress?.destinationTag}`}
                </Text>
              )}
              <Text className="text-tiny" color="text-500">
                {cryptoAddress?.name}
              </Text>
            </Box>
          ),
        },
        {
          cellData: cryptoAddress?.reason,
        },
        {
          cellData: (
            <Box>
              <Text size="sm">{cryptoAddress?.creator}</Text>
              {!!cryptoAddress?.dateCreated && (
                <Text className="text-tiny" color="text-500">
                  {dayjs(cryptoAddress?.dateCreated).format('DD MMM YYYY')}
                </Text>
              )}
            </Box>
          ),
        },
        {
          cellData: canEdit ? (
            <div
              data-testid="delete-address"
              className="cursor-pointer"
              onClick={() => {
                setCryptoId(cryptoAddress)
                setShowDeleteModal(true)
              }}
            >
              <Icon name="TrashIcon" />
            </div>
          ) : (
            ''
          ),
        },
      ],
    }
  })

  return (
    <>
      {showDeleteModal && (
        <DeleteAddressModal
          handleOnModalClose={handleClose}
          selectedCryptoAddress={selectedCryptoAddress}
          onDeleteCallback={onDeleteCallback}
        />
      )}
      {isSearching ? (
        <TableRowSkeleton rows={4} />
      ) : (
        <SimpleTableV2
          columns={[
            {
              className: 'px-4',
              headerTitle: t('manageCryptoAddresses.columns.cryptocurrency'),
            },
            {
              className: 'px-4',
              headerTitle: t('manageCryptoAddresses.columns.address'),
            },
            {
              className: 'w-1/4',
              headerTitle: t('manageCryptoAddresses.columns.reason'),
            },
            {
              headerTitle: t('manageCryptoAddresses.columns.added'),
            },
            {
              headerTitle: '',
            },
          ]}
          tableData={mappedTableData}
        />
      )}
    </>
  )
}

export default CryptoAddressTable
