import { useEffect, useState } from 'react'
import { ConnectionItem } from '../components/ConnectionsListItem'

export const useBvnkMemberSearch = (
  debouncedSearchTerm: string,
  connectionList: ConnectionItem[]
) => {
  const [filteredConnectionList, setFilteredConnectionList] =
    useState<ConnectionItem[]>(connectionList)
  const [isFiltering, setIsFiltering] = useState(false)

  useEffect(() => {
    setIsFiltering(true)
    if (!!debouncedSearchTerm) {
      const filteredList = connectionList?.filter(connection => {
        return (
          connection?.name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase()) ||
          connection?.description?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())
        )
      })

      setFilteredConnectionList(filteredList)
    } else {
      setFilteredConnectionList([...connectionList])
    }
    setIsFiltering(false)
  }, [debouncedSearchTerm, connectionList])

  return { isFiltering, filteredConnectionList }
}
