import React from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box } from '@node-space/storybook-components'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { MerchantType } from 'types/types'

interface MerchantDetailsSummaryProps {
  merchant: MerchantType
}

export const MerchantDetailsSummary = ({ merchant }: MerchantDetailsSummaryProps) => {
  const { t } = useTranslation()

  return (
    <Box flex direction="col">
      <SummaryDetail>
        <SummaryRow
          label={t('merchantId')}
          renderDescription={
            <Box flex direction="row" justifyContent="between">
              <Text size="sm" color="grey">
                {merchant?.merchantId}
              </Text>

              <CopyButton
                copyText={String(merchant?.merchantId)}
                iconColour={copyButtonIconColour}
                confirmationMessage={t('copiedToClipboard')}
              />
            </Box>
          }
        />
        <SummaryRow
          label={t('secretKey')}
          renderDescription={
            <Box flex direction="row" justifyContent="between">
              <Text size="sm" color="grey" className="truncate text-ellipsis overflow-hidden">
                {ellipseMiddleText(merchant?.secret, 25, 10)}
              </Text>

              <CopyButton
                copyText={String(merchant?.secret)}
                iconColour={copyButtonIconColour}
                confirmationMessage={t('copiedToClipboard')}
              />
            </Box>
          }
        />

        <SummaryRow
          label={t('webhookUrl')}
          renderDescription={
            <Box flex direction="row" justifyContent="between">
              <Text size="sm" color="grey">
                {merchant?.webhookUrl || '-'}
              </Text>

              {!!merchant?.webhookUrl && (
                <CopyButton
                  copyText={String(merchant?.webhookUrl)}
                  iconColour={copyButtonIconColour}
                  confirmationMessage={t('copiedToClipboard')}
                />
              )}
            </Box>
          }
        />
      </SummaryDetail>
    </Box>
  )
}
