import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useKeyPress } from '@node-space/hooks'
import { Modal, ModalNavBar, Box as Wrapper } from '@node-space/storybook-components'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { Amp } from 'utils/tracker'
import { PaymentCreateModalContext } from '../../context/PaymentCreateModalProvider'
import PaymentsCreateForm from '../Form/PaymentsCreateForm'

interface PaymentCreateModalProps {
  onSuccessCreatePayment?: (value: string) => void
}

const PaymentCreateModal = ({ onSuccessCreatePayment }: PaymentCreateModalProps) => {
  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useContext(PaymentCreateModalContext)

  useEffect(() => {
    Amp.track(AmplitudeEvent.PAYMENT_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const handleKeyPress = useCallback(() => {
    if (!showCreateModal) {
      return
    }
    setShowCreateModal(false)
  }, [showCreateModal])

  useKeyPress('Escape', handleKeyPress)

  return (
    <Modal visible={true} closeModal={() => setShowCreateModal(false)}>
      <ModalNavBar title={t('createPayment')} onClose={() => setShowCreateModal(false)} />
      <Wrapper tag="section" testid="payment-create-modal-wrapper" className=" w-512 mx-auto">
        <PaymentsCreateForm onSuccess={onSuccessCreatePayment} />
      </Wrapper>
    </Modal>
  )
}

export default React.memo(PaymentCreateModal)
