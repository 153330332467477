import React, { ChangeEvent, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as ApprovalRangeRow, Box } from '@node-space/storybook-components/dist/Box'
import Select from '@node-space/storybook-components/dist/Select'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { ApprovalRulesData } from 'types/approvals'
import ApprovalFromToRange from './ApprovalFromToRange'
import ApprovalRangeControls from './ApprovalRangeControls'
import { useApproverCount } from './hooks/useApprovalRangeSettings'

interface ApprovalRangeFormProps {
  approvalRulesData: ApprovalRulesData[]
  setApprovalRulesData: (value: SetStateAction<ApprovalRulesData[]>) => void
  isApprovalRuleInvalid: boolean
  setIsApprovalRuleInvalid: (value: SetStateAction<boolean>) => void
}

const ApprovalRangeForm = ({
  approvalRulesData,
  setApprovalRulesData,
  isApprovalRuleInvalid,
  setIsApprovalRuleInvalid,
}: ApprovalRangeFormProps) => {
  const { t } = useTranslation()

  const {
    profile: { currencyCode },
  } = useProfileContext()
  const { data: accountPreferences, isLoading: isLoadingAccountPreferences } =
    useAccountPreferencesQuery()
  const baseCurrency =
    (!isLoadingAccountPreferences && (accountPreferences?.currency || currencyCode)) || ''

  const approversCount = (index: number) => {
    const isFirstAndNotOnlyRow = index === 0 && approvalRulesData?.length > 1
    return isFirstAndNotOnlyRow ? useApproverCount(0) : useApproverCount(1)
  }

  const handleFormChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newFieldsData = [...approvalRulesData]
    if (newFieldsData?.[index + 1] && event?.target?.value) {
      newFieldsData[index + 1].minAmount = Number(event?.target?.value)
    }
    setApprovalRulesData(newFieldsData)
  }

  return (
    <>
      {approvalRulesData?.map((approvalRuleData, index) => (
        <ApprovalRangeRow key={index} flex gapX={12} paddingB={12}>
          <ApprovalFromToRange
            rowIndex={index}
            handleInputChange={handleFormChange}
            rangeValues={approvalRuleData}
            approvalRulesData={approvalRulesData}
            postLabel={baseCurrency}
            isApprovalRuleInvalid={isApprovalRuleInvalid}
            setIsApprovalRuleInvalid={setIsApprovalRuleInvalid}
          />
          <Box className="w-32">
            <Select
              menuMaxHeight={200}
              name={t('approvals.approvers')}
              label={t('approvals.approvers')}
              placeholder={1}
              value={
                index === 0 &&
                approvalRulesData?.length === 1 &&
                approvalRulesData?.[index]?.approvalsCount === 0
                  ? '1'
                  : `${approvalRulesData?.[index]?.approvalsCount}`
              }
              options={approversCount(index)}
              onChange={value => {
                approvalRulesData[index].approvalsCount = Number(value)
                handleFormChange.call(handleFormChange)
              }}
            />
          </Box>
          <Box className="w-8">
            <ApprovalRangeControls
              rowIndex={index}
              approvalRulesData={approvalRulesData}
              setApprovalRulesData={setApprovalRulesData}
              setIsApprovalRuleInvalid={setIsApprovalRuleInvalid}
            />
          </Box>
        </ApprovalRangeRow>
      ))}
    </>
  )
}

export default ApprovalRangeForm
