import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getChatbotAuthToken } from 'services/ChatBotService'
import { setChatbotTokenCookie } from 'utils/utils'

export const useChatBotQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.chatbotToken(),
    queryFn: () => getChatbotAuthToken(),
    enabled: false,
    select: data => {
      setChatbotTokenCookie(data?.token)
      return data?.token
    },
  })
}
