import React from 'react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Box as Container } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { DateFormat } from 'constants/General'
import { fileNameAtom, uploadMessageAtom } from '../atoms/massPayoutAtoms'

export const MassPayoutCallout = () => {
  const { t } = useTranslation()

  const [fileName, setFileName] = useAtom(fileNameAtom)
  const [message, setMessage] = useAtom(uploadMessageAtom)
  const currentDateTime = dayjs()
  const currentTime = currentDateTime.format(DateFormat.H_MM_SS_A)
  const currentDate = currentDateTime.format(DateFormat.DD_MM_YYYY_)

  const handleBannerClose = () => {
    setFileName('')
    setMessage('')
  }

  return (
    fileName &&
    message && (
      <Container paddingB={16}>
        <Callout
          state="info"
          message={t('massPayouts.wasSentForProcessing', {
            fileName: fileName,
            time: currentTime,
            date: currentDate,
          })}
          showCloseButton
          onClose={handleBannerClose}
        />
      </Container>
    )
  )
}
