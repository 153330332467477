import React from 'react'
import { useRecurringExportContext } from 'hooks/context/useRecurringExportContext'
import { RecurringExportSteps } from 'types/walletTransactionsExport'
import { RecurringExportForm } from './RecurringExportForm'
import { RecurringExportList } from './RecurringExportList'

interface RecurringExportType {
  downloadAll: boolean
}

export const RecurringExport = ({ downloadAll }: RecurringExportType) => {
  const { step } = useRecurringExportContext()

  return (
    <>
      {step === RecurringExportSteps.LIST && <RecurringExportList />}
      {step === RecurringExportSteps.FORM && <RecurringExportForm downloadAll={downloadAll} />}
    </>
  )
}
