import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import Loader from '@node-space/storybook-components/dist/Loader'
import { SidePanel, SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Text } from '@node-space/storybook-components/dist/Text'
import { appBannersId } from 'components/app/AppBanners'
import { DateFormat } from 'constants/General'
import { useGetMassFiatPayoutsDetailsQuery } from 'hooks/queries/MassPayouts/useGetMassFiatPayoutsDetails'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { getSumOfRecords, getValidRecords } from 'pages/MassPayouts/utils'
import { WalletType } from 'types/types'
import { highlightRowBasedOnId } from 'utils/table/table'
import { FiatPayout } from '../../types'
import { MassPayoutListSidePanelBody } from '../MassPayoutListSidePanelBody'

export type MassPayoutListSummaryProps = {
  isOpen: boolean
  massFiatPayoutDetails: FiatPayout
  pageMassPayout: PagingSidePanelType
  onClose: () => void
  wallets: WalletType[]
  isLoadingWallets: boolean
}

export type SidePanelBodyDataProps = {
  massPayoutDetails: FiatPayout
  country: string
}

export const FiatBatchSidePanel = ({
  isOpen,
  massFiatPayoutDetails,
  pageMassPayout,
  onClose,
  wallets,
  isLoadingWallets,
}: MassPayoutListSummaryProps) => {
  const { t } = useTranslation()
  const bannerSpacing = useComponentHeight(appBannersId)

  const { data: payoutDetails, isLoading: isLoadingMassFiatPayoutDetails } =
    useGetMassFiatPayoutsDetailsQuery(massFiatPayoutDetails?.reference)

  const selectedWallet = wallets?.find(wallet => wallet?.lsid === payoutDetails?.walletId)
  const walletName = `${selectedWallet?.description ?? massFiatPayoutDetails?.walletId} (${selectedWallet?.currency?.code})`

  const primaryLabel = t('massPayouts.numberOfPayoutsProcessed', {
    numberOfProcessedPayments: getValidRecords(payoutDetails?.payoutStatuses),
    numberOfPayments: getSumOfRecords(payoutDetails?.payoutStatuses),
  })
  const secondaryLabel = payoutDetails?.alias

  const uploadedBy = payoutDetails?.createdBy?.fullName

  const date = dayjs(payoutDetails?.createdAt).format(DateFormat.DD_MMMM_YYYY)
  return (
    <SidePanel
      isOpen={isOpen}
      paging={pageMassPayout}
      onClose={onClose}
      activeRowId={payoutDetails?.walletId}
      onActiveRowChange={highlightRowBasedOnId}
      externalStyle={{ top: `${bannerSpacing}px` }}
      sidePanelHeader={{
        customSidePanelHeader: isLoadingMassFiatPayoutDetails ? (
          <Box flex justifyContent="center">
            <Loader theme="dark" size="large" />
          </Box>
        ) : (
          <SidePanelHeader
            heading={{
              iconUrls: null,
              primaryLabel: (
                <Box className="md:flex w-full">
                  <Text size="xl" weight="semibold" className="text-900">
                    {primaryLabel}
                  </Text>
                </Box>
              ),
              secondaryLabel: (
                <Text size="sm" className="text-500">
                  {secondaryLabel}
                </Text>
              ),
            }}
            bottomContent={{
              leftLabel: t('massPayouts.dateCreated'),
              leftDescription: date && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap">
                  {date}
                </Text>
              ),
              rightLabel: t('massPayouts.uploadedBy'),
              rightDescription: uploadedBy && (
                <Text tag="span" size="sm" color="text-500" className="mb-1 flex-wrap break-words">
                  {uploadedBy}
                </Text>
              ),
            }}
            template={2}
          />
        ),
      }}
    >
      <Box padding={24} tag="section" width="full" background="white">
        {isLoadingMassFiatPayoutDetails && isLoadingWallets ? (
          <Box flex justifyContent="center">
            <Loader theme="dark" size="large" />
          </Box>
        ) : (
          <MassPayoutListSidePanelBody
            dateCreated={date}
            uploadedBy={uploadedBy}
            paidFromWallet={walletName}
          />
        )}
      </Box>
    </SidePanel>
  )
}
