import React from 'react'
import { useTranslation } from 'react-i18next'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { BatchSummaryInformation } from '../types'

type MassPayoutConfirmationSummaryProps = {
  batchInformation: BatchSummaryInformation
}

export const MassPayoutConfirmationSummary = ({
  batchInformation,
}: MassPayoutConfirmationSummaryProps) => {
  const { t } = useTranslation()

  return (
    <>
      <SummaryDetail>
        {batchInformation?.numberOfPayments && (
          <SummaryRow
            label={t('massPayouts.numberOfPayments')}
            description={batchInformation?.numberOfPayments}
            testid="number-of-payments"
          />
        )}
        {batchInformation?.totalBatchAmount && (
          <SummaryRow
            label={t('massPayouts.batchAmount')}
            description={batchInformation?.totalBatchAmount}
            testid="total-batch-amount"
          />
        )}
        {batchInformation?.totalBatchFees && (
          <SummaryRow
            label={t('massPayouts.fees')}
            description={batchInformation?.totalBatchFees}
            testid="total-batch-fees"
          />
        )}
        {batchInformation?.totalBatchAmountWithFees && (
          <SummaryRow
            label={t('massPayouts.batchAmountInclFees')}
            description={batchInformation?.totalBatchAmountWithFees}
            testid="total-batch-amount-with-fees"
          />
        )}
        {batchInformation?.walletName && (
          <SummaryRow
            label={t('massPayouts.payingFromWallet')}
            description={`${batchInformation?.walletName} (${batchInformation.currency})`}
            testid="total-batch-amount-with-fees"
          />
        )}
      </SummaryDetail>
    </>
  )
}
