import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAllChannelsPayments } from 'services/PaymentsService'
import { Paging } from 'types/types'

interface ChannelsPaymentsApiOptions {
  paging?: Paging
  from?: string
  to?: string
  merchant?: string
  status?: string
  search?: string
}

export const useChannelsPaymentsQuery = (
  paymentOptions: ChannelsPaymentsApiOptions,
  queryOptions?: Omit<UseQueryOptions<unknown>, 'queryKey' | 'queryFn'>
) => {
  const { paging, from, to, merchant, status, search } = paymentOptions

  return useQuery({
    queryKey: reactQueryKeys.channelPayments(paging, from, to, merchant, status, search),
    queryFn: () => getAllChannelsPayments(paging, from, to, merchant, status, search),
    gcTime: 0,
    staleTime: Infinity,
    enabled: false,
    ...queryOptions,
  })
}
