export const TransactionType = {
  PAY_IN: 'payIn',
  PAY_OUT: 'payOut',
  UNDER_PAID: 'underPaid',
  OVER_PAID: 'overPaid',
  USER_DEPOSIT: 'userDeposit',
  USER_WITHDRAWAL: 'userWithdrawal',
  PAYMENT_IN_FEES: 'paymentInFees',
  PAYMENT_OUT_FEES: 'paymentOutFees',
  PAYMENT_UNDER: 'paymentUnder',
  PAYMENT_OVER: 'paymentOver',
  CHANNEL_DEPOSIT_FEE: 'channelDepositFee',
  LATE_PAYMENT: 'latePayment',
  OFF_CHAIN: 'offChain',
  BVNK_NETWORK_TRANSFER: 'bvnkNetworkTransfer',
} as const

export const TransactionSubType = {
  UNDERPAYMENT: 'underPayment',
  OVERPAYMENT: 'overPayment',
} as const

export type TTransactionType = (typeof TransactionType)[keyof typeof TransactionType]
export type TTransactionSubType = (typeof TransactionSubType)[keyof typeof TransactionSubType]
