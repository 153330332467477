import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@mantine/hooks'
import { Box as Section, Button } from '@node-space/storybook-components'
import { DocumentCheck } from '@node-space/storybook-components/dist/Icons'
import PaymentUnderpaidModal from './PaymentUnderpaidModal'
import { PaymentsResponse, PaymentStatus } from 'types/payments'
import { secondaryButtonIconColour } from 'styles/theme/colours'

interface PaymentUnderpaidProps {
  transaction?: PaymentsResponse
}

const PaymentUnderpaid = ({ transaction }: PaymentUnderpaidProps) => {
  const { t } = useTranslation()
  const [isModalOpen, toggleModal] = useToggle(false, [false, true])
  const [isPaymentSuccess, toggleSuccess] = useToggle(false, [false, true])

  const canMarkPaymentComplete = transaction?.status === PaymentStatus.PROCESSING

  const onModalToggle = () => toggleModal()

  return (
    <>
      {canMarkPaymentComplete && !isPaymentSuccess && (
        <Section tag="span" testid="payment-mark-underpaid-section">
          <Button
            testid="payment-mark-underpaid-button"
            noStyling
            className={`mr-8`}
            iconElement={<DocumentCheck color={secondaryButtonIconColour} />}
            onClick={onModalToggle}
          >
            {t('acceptUnderpayment')}
          </Button>
          <PaymentUnderpaidModal
            isModalOpen={isModalOpen}
            setIsModalOpen={onModalToggle}
            setIsPaymentSuccess={toggleSuccess}
            transaction={transaction}
          />
        </Section>
      )}
    </>
  )
}

export default PaymentUnderpaid
