import { requestApi } from 'api/api'
import {
  AddCryptoAddressRequest,
  AddCryptoAddressResponse,
  UpdateProtocolRequest,
  ValidateCryptoAddressRequest,
  ValidateCryptoAddressResponse,
} from 'types/whitelisting'

export const getCryptoAddresses = async (req: {
  queryTerm?: string
  currency?: string
  max?: number
  offset?: number
  pagination?: boolean
}): Promise<{
  results: AddCryptoAddressResponse[]
  paging: { total: number; offset: number; max: number }
}> => {
  const { queryTerm = '', currency = '', offset = 0, max, pagination = true } = req

  const { data, headers } = await requestApi({
    url: `/v2/address/whitelist`,
    method: 'GET',
    params: {
      sort: 'id',
      order: 'desc',
      pagination,
      ...(!!queryTerm && { q: queryTerm }),
      ...(!!currency && { currency }),
      ...(!!offset && { offset }),
      ...(!!max && { max }),
    },
    fullResponse: true,
  })

  return {
    results: data ?? [],
    paging: {
      total: Number(headers['x-api-list-total']),
      offset: Number(headers['x-api-list-start']),
      max,
    },
  }
}

export const postCryptoAddress = (
  data: AddCryptoAddressRequest
): Promise<AddCryptoAddressResponse> => {
  return requestApi({
    url: '/v2/address/whitelist',
    method: 'POST',
    data,
  })
}

export const validateCryptoAddress = (
  data: ValidateCryptoAddressRequest
): Promise<ValidateCryptoAddressResponse> => {
  return requestApi({
    url: '/v1/pay/validate',
    method: 'PUT',
    data,
  })
}

export const deleteCryptoAddress = ({ cryptoId }): Promise<AddCryptoAddressResponse> => {
  return requestApi({
    url: `/v3/address/whitelist/${cryptoId}`,
    method: 'DELETE',
  })
}

export const updateProtocol = ({
  uuid,
  selectedProtocol,
  totp,
}): Promise<UpdateProtocolRequest> => {
  return requestApi({
    url: `/v2/address/whitelist/${uuid}`,
    method: 'PUT',
    data: {
      authorization: {
        totp,
      },
      protocol: selectedProtocol,
    },
  })
}
