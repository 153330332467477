import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCryptoAddresses } from 'services/WhitelistingService'

export const useWhitelistedCryptoAddressesQuery = (req: {
  queryTerm?: string
  currency?: string
  offset?: number
  max?: number
  pagination?: boolean
  enabled: boolean
}) => {
  return useQuery({
    queryKey: reactQueryKeys.getCryptoAddresses(req),
    queryFn: () => getCryptoAddresses(req),
    placeholderData: keepPreviousData,
    enabled: !!req?.enabled,
  })
}
