import { useEffect } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PreProcessedPayouts, UpdateFiatPayoutFormPayload } from 'pages/MassPayouts/types'
import { Currencies } from 'types/types'

const PAYMENT_DISABLED_ERROR = 'Payments to individuals are not enabled for this wallet'

interface UseSetRecordToEditErrors {
  record: PreProcessedPayouts
  walletCurrency: string
  setError: UseFormSetError<UpdateFiatPayoutFormPayload>
  setGeneralError: React.Dispatch<React.SetStateAction<string>>
  setPaymentDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const useSetFiatRecordToEditErrors = ({
  record,
  walletCurrency,
  setError,
  setGeneralError,
  setPaymentDisabled,
}: UseSetRecordToEditErrors) => {
  const { t } = useTranslation()

  useEffect(() => {
    const { validationErrors } = record ?? {}

    if (validationErrors?.beneficiary) {
      switch (walletCurrency) {
        case Currencies.EUR:
          setError?.('accountNumber', { message: t('massPayouts.fiatErrors.accountNumberEUR') })
          setError?.('bankCode', { message: t('massPayouts.fiatErrors.bankCodeEUR') })
          break
        case Currencies.GBP:
          setError?.('accountNumber', { message: t('massPayouts.fiatErrors.accountNumberGBP') })
          setError?.('bankCode', { message: t('massPayouts.fiatErrors.bankCodeGBP') })
          break
        default:
          setError?.('accountNumber', {
            message: t('massPayouts.fiatErrors.accountDetailsDefault'),
          })
          setError?.('bankCode', { message: t('massPayouts.fiatErrors.accountDetailsDefault') })
          break
      }
    }

    if (validationErrors?.amount) {
      const isAmountError = validationErrors?.general?.includes('Payout amount')

      if (isAmountError) {
        setError?.('amount', { message: t('massPayouts.fiatErrors.amount') })
      } else {
        setGeneralError(validationErrors?.general)
      }
    }

    if (validationErrors?.paymentReference) {
      setError?.('paymentReference', { message: t('massPayouts.fiatErrors.paymentReference') })
    }

    if (validationErrors?.dateOfBirth) {
      setError?.('dateOfBirth', { message: t('massPayouts.fiatErrors.dateOfBirth') })
    }

    if (validationErrors?.general) {
      setGeneralError(validationErrors?.general)
      setPaymentDisabled(validationErrors?.general === PAYMENT_DISABLED_ERROR)
    }
  }, [record?.validationErrors])
}
