import React, { ReactNode } from 'react'
import { FilterContextProvider } from 'components/FilterBar/context/FilterProvider'
import { AccountsContextProvider } from 'contexts/accountsContext'
import { DevXProvider } from 'contexts/devXContext'
import { OfflineContextProvider } from 'contexts/offlineContext'
import { PendingAgreementsProvider } from 'contexts/pendingAgreementsContext'
import { ProfileContextProvider } from 'contexts/profileContext'
import { ToastContextProvider } from 'contexts/toastContext'
import { TwoFAProvider } from 'contexts/twoFAContext'

export type DataProviderType = {
  data: any
  isLoading: boolean
}

export interface AppProvidersProps {
  children: ReactNode
  profile: DataProviderType
  accounts: DataProviderType
  shouldShowUKInfoBanner: boolean
}

export const AppProviders = ({
  children,
  profile,
  accounts,
  shouldShowUKInfoBanner,
}: AppProvidersProps) => {
  return (
    <OfflineContextProvider>
      <AccountsContextProvider
        accountsData={accounts?.data}
        isLoading={accounts?.isLoading}
        shouldShowUKInfoBanner={shouldShowUKInfoBanner}
      >
        <ProfileContextProvider profile={profile?.data} isLoading={profile?.isLoading}>
          <FilterContextProvider>
            <TwoFAProvider>
              <ToastContextProvider>
                <PendingAgreementsProvider>
                  <DevXProvider>{children}</DevXProvider>
                </PendingAgreementsProvider>
              </ToastContextProvider>
            </TwoFAProvider>
          </FilterContextProvider>
        </ProfileContextProvider>
      </AccountsContextProvider>
    </OfflineContextProvider>
  )
}
