import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useBeneficiaryPayoutDisclaimer = (
  hasStoredBeneficiaries: boolean,
  hasBeneficiaryOptions: boolean
) => {
  const { t } = useTranslation()
  const [payoutBeneficiaryDisclaimer, setPayoutBeneficiaryDisclaimer] = useState<string>(null)

  useEffect(() => {
    if (hasStoredBeneficiaries && hasBeneficiaryOptions) {
      setPayoutBeneficiaryDisclaimer(t('payouts.beneficiaryFiltersDisclaimer'))
    }
  }, [hasStoredBeneficiaries, hasBeneficiaryOptions])

  return payoutBeneficiaryDisclaimer
}

export default useBeneficiaryPayoutDisclaimer
