import React from 'react'
import { Timeline } from '@node-space/storybook-components'
import { PaymentsResponse } from 'types/payments'
import { useUpdateTimeline } from '../../../hooks/useUpdateTimeline'

interface PaymentTimelineProps {
  transaction: PaymentsResponse
}

export const PaymentTimeline = ({ transaction }: PaymentTimelineProps) => {
  const values = useUpdateTimeline(transaction)

  return <Timeline rows={values} />
}
