import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import { ReceiveForm, ReceiveSteps } from '../components/Receive/Receive'
import receiveSchema from '../schemas/receiveSchema'
import useWalletActionApi from './useWalletActionApi'

const useReceiveAction = (sharedActionProps: WalletActionSharedProps) => {
  const { t } = useTranslation()

  const [receiveStep, setReceiveStep] = useState<ReceiveSteps>('form')
  const receiveActionApi = useWalletActionApi<ReceiveForm, ReceiveSteps>({
    intialStep: 'form',
    step: receiveStep,
    setStep: setReceiveStep,
    formSchema: receiveSchema({ t, step: receiveStep }),
    ...sharedActionProps,
  })

  return receiveActionApi
}

export default useReceiveAction
