import React from 'react'
import { Box, Box as MenuItem } from '@node-space/storybook-components/dist/Box'
import Icons from '@node-space/storybook-components/dist/components/Icons/PhosphorIcons'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { PopoverMenu } from '@node-space/storybook-components/dist/PopoverMenu'

export type TablePopoverMenuItemType = {
  iconName: keyof typeof Icons
  label: string
  onClick: () => void
}

export type TablePopoverMenuProps = {
  menuItems: TablePopoverMenuItemType[]
  stopPropagation?: boolean
  hasPadding?: boolean
  testid?: string
}

export const TablePopoverMenu = ({
  menuItems,
  stopPropagation,
  hasPadding,
  testid,
}: TablePopoverMenuProps) => {
  return (
    <Box
      testid="transaction-table-row-popover-menu"
      {...(stopPropagation && { onClick: e => e?.stopPropagation() })}
      {...(hasPadding && { padding: 8 })}
    >
      <PopoverMenu
        activeClass="p-2 bg-gray-02 rounded-3xl"
        hoverClass="p-2 hover:bg-gray-02 hover:rounded-3xl cursor-pointer"
        menuItems={menuItems?.map(item => ({
          onClick: item?.onClick,
          item: (
            <MenuItem key={item?.label} flex direction="row" alignItems="center" gapX={8}>
              <Icon color="grey-08" name={item?.iconName} />
              <span>{item?.label}</span>
            </MenuItem>
          ),
        }))}
        testId={testid}
      >
        <Box aria-label={`${testid}-popover-menu-button-icon`}>
          <Icon color="grey-08" name="MeatballIcon" />
        </Box>
      </PopoverMenu>
    </Box>
  )
}
