import React, { ChangeEvent, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash-es/isEmpty'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Box as Form } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import Modal, {
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { logSentryError } from '@node-space/utils'
import { RequestError } from 'components/Errors/RequestError'
import { usePutWalletDescriptionMutation } from 'hooks/mutations/usePutWalletDescriptionMutation'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { BaseErrorResponse } from 'types/beneficiaries'
import walletEditSchema from '../../schemas/walletEditSchema'

interface WalletEditProps {
  setShowEditWalletModal: (value: React.SetStateAction<boolean>) => void
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>
  selectedWalletName: string | undefined
  selectedItem: string
}

export interface WalletEditFormFields {
  walletName: string
}

export const WalletEditModal = ({
  setShowEditWalletModal,
  setSelectedItem,
  selectedWalletName,
  selectedItem,
}: WalletEditProps) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { mutate, isPending } = usePutWalletDescriptionMutation()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const formSchema = walletEditSchema(t)

  const form = useForm<WalletEditFormFields>({
    defaultValues: {
      walletName: '',
    },
    resolver: yupResolver(formSchema),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form

  const formValues = form.watch()

  const actions: ButtonProps[] = [
    {
      children: t('save'),
      loading: isPending,
      disabled: !isEmpty(errors),
      type: 'submit',
    },
  ]

  useEffect(() => {
    if (selectedWalletName) {
      setValue('walletName', selectedWalletName)
    }
  }, [selectedWalletName])

  const onSubmit: SubmitHandler<typeof formValues> = async () => {
    resetRequestError()

    const payload = {
      walletId: Number(selectedItem),
      description: formValues?.walletName,
    }

    mutate(payload, {
      onSuccess: async () => {
        addToast({ title: t('walletNameUpdated'), state: 'success' })

        setShowEditWalletModal(false)
        await queryClient.invalidateQueries({ queryKey: reactQueryKeys.wallets() })
        setSelectedItem(null)
      },
      onError: (error: BaseErrorResponse) => {
        setRequestError({
          show: true,
          errorCode: error?.status,
        })
        logSentryError('Error: usePutWalletDescriptionMutation', error)
      },
    })
  }

  return (
    <Modal visible={true} closeModal={() => setShowEditWalletModal(false)}>
      <Form tag="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalNavBar title={t('editWalletName')} onClose={() => setShowEditWalletModal(false)} />
        <ModalBody>
          <Box flex direction="col" alignItems="center">
            <Input
              {...register('walletName')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue('walletName', e?.target?.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              label={t('walletName')}
              inputMode="text"
              name="walletName"
              value={formValues?.walletName}
              error={!!errors?.walletName}
              errorText={errors?.walletName?.message}
            />
          </Box>
          {requestError?.show && (
            <Box flex direction="col" alignItems="center">
              <RequestError errorCode={requestError?.errorCode}>
                {requestError?.handledMessage}
              </RequestError>
            </Box>
          )}
        </ModalBody>
        <ModalActions actions={actions} />
      </Form>
    </Modal>
  )
}
