const General = {
  COOKIE_NAME: process.env.COOKIE_NAME || 'cdCookie201805',
  IMPERSONATION_COOKIE_NAME: 'cdImpersonation',
  COOKIE_PERMANENT: 'cdPrevious',
  IGNORE_2FA_COOKIE_NAME: 'ignoreWithdraw2faWarning',
  ZENDESK_COOKIE_NAME: 'cdZendeskAuth',
}

export const Image = {
  GLOBAL_IMG_PATH: `${process.env.RELEASE_ID || ''}`,
}

export const CookieTime = {
  TEN_SECONDS: 10 / 86400,
  ONE_HOUR: 1 / 24,
  ONE_DAY: 1,
}

export const SalesForce = {
  PARDOT_DEVX_ENDPOINT: 'https://info.bvnk.com/l/1013952/2023-02-23/bmr',
}

export default General

export const DateFormat = {
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  DD_MM_YYYY: 'DD MM YYYY',
  D_MMM_YYYY_AT_HH_MM: 'D MMM YYYY @ HH:mm',
  DD_MMM_YYYY_HH_MM_A: 'DD MMM YYYY, hh:mm A',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MMMM_YYYY_AT_HH_MM: 'DD MMMM YYYY @ HH:mm',
  HH_MM_DD_MMM_YYYY: 'HH:mm - DD MMM YYYY',
  LL: 'LL',
  H_MM_SS_A: 'h:mm:ss A',
  DD_MM_YYYY_: 'DD-MM-YYYY',
}

export const PathNames = {
  APPROVALS: '/approvals',
  AUTHENTICATE: '/authenticate',
  BVNK_NETWORK: '/bvnk-network',
  CHANNELS_LINKS: '/channels',
  CONFIRM_ACCOUNT: '/create-account/1',
  CUSTOMERS: '/customers',
  CUSTOMER: '/customers/:id',
  DASHBOARD: '/',
  DEV_GET_STARTED: '/dev-get-started',
  DEVX_SIGNUP_URL: '/create-dev-account',
  FORGOT: '/forgot',
  FORGOT_PASSWORD: '/forgot-password',
  GET_STARTED: '/get-started',
  IMPERSONATE: '/impersonate',
  KYB_SIGNUP_URL: '/create-account',
  LEGAL_AGREEMENTS: '/manage-legal-agreements',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MASS_PAYOUTS: '/mass-payouts',
  MASS_PAYOUTS_CREATE: '/mass-payouts/create',
  MASS_PAYOUTS_REVIEW: '/payout-details/:reference',
  MASS_PAYOUTS_LIST: '/list',
  MANAGE_ACCOUNT: '/manage-account',
  MANAGE_API_KEYS: '/manage-api-keys',
  MANAGE_BANK_ACCOUNTS: '/manage-bank-accounts',
  MANAGE_BENEFICIARIES: '/manage-beneficiaries',
  MANAGE_CHANNELS: '/manage-channels',
  MANAGE_CRYPTO_WHITELISTING: '/manage-crypto-address-whitelist',
  MANAGE_MERCHANT_DETAILS: '/manage-merchant-details',
  MANAGE_PAYMENT_SUPPORT: '/manage-payment-support',
  MANAGE_TEAM_MEMBERS: '/manage-team-members',
  MANAGE_USER_PROFILE: '/manage-user-profile',
  NOT_FOUND: '/404',
  PAYMENTS: '/payments',
  PAYMENTS_CREATE: '/create',
  PAYMENTS_LINKS: '/links',
  RESET_TOKEN: '/forgot/:token',
  SETTINGS: '/settings',
  TRANSACTION_HISTORY: '/transaction-history',
  UPGRADE: '/upgrade',
  VERIFY: '/verify',
  VERIFICATION: '/verification',
  WALLET_CREATE: '/create',
  WALLET_DASHBOARD: '/dashboard',
  WALLETS: '/wallets',
  WALLETS_CATCH_ALL: '/wallets/:code([w]{2,5})/:id([d]+)',
  WALLETS_CREATE_SUCCESS: '/new/success',
  WALLETS_DETAILS: '/wallet-details/:id',
  VERIFICATION_STATUS: '/verification-result/:status',
  ACCOUNT_ERROR: '/account-error',
}

export type PathNamesType = (typeof PathNames)[keyof typeof PathNames]

export const DefaultLoginRedirect = PathNames.DASHBOARD

export const RedirectPathStateKey = 'redirectPath'

export const Approval = {
  ACCOUNT: 'Account',
  FLOWS: {
    ALL_PAYOUT: 'ALL_PAYOUT',
    FIAT_1ST_PARTY_PAYOUT: 'FIAT_1ST_PARTY_PAYOUT',
    FIAT_3RD_PARTY_PAYOUT: 'FIAT_3RD_PARTY_PAYOUT',
    CRYPTO_SEND: 'CRYPTO_SEND',
    CRYPTO_PAYOUT: 'CRYPTO_PAYOUT',
  },
  ACCOUNT_TRANSACTION_APPROVER: 'ACCOUNT_TRANSACTION_APPROVER',
  DECISION: {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
  },
  MINIMUM_APPROVERS_FOR_MODAL_SEARCH: 5,
  RULES: {
    INFINITY: 'Infinity/Max',
  },
}

export const MERCHANT_PORTAL = 'merchantPortal'

export const DEFAULT_NUMBER_OF_ROWS = '20'

export const DurationInMilliseconds = {
  THIRTY_SECONDS: 30000,
} as const
