import React from 'react'
import { Box as Section, SmallTabs } from '@node-space/storybook-components'
import ChannelDetails from './ChannelDetails'
import { ChannelPayment } from 'types/channels'

interface PaymentRowExpandedProps {
  channelPayment: ChannelPayment
}

const ChannelRowExpanded = ({ channelPayment }: PaymentRowExpandedProps) => {
  return (
    <Section tag="section" alignItems="center" border="gray" background="white" full padding={28}>
      <SmallTabs
        tabs={[
          {
            id: '1',
            tabHeader: 'Details',
            tabContent: <ChannelDetails channelPayment={channelPayment} />,
            testid: 'channel-details-tab',
          },
        ]}
      />
    </Section>
  )
}

export default React.memo(ChannelRowExpanded)
