import React, { Dispatch, SetStateAction } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import useConvertAction from 'pages/Wallets/hooks/useConvertAction'
import useDepositAction from 'pages/Wallets/hooks/useDepositAction'
import usePayAction from 'pages/Wallets/hooks/usePayAction'
import useReceiveAction from 'pages/Wallets/hooks/useReceiveAction'
import useSendAction from 'pages/Wallets/hooks/useSendAction'
import useWithdrawAction from 'pages/Wallets/hooks/useWithdrawAction'
import { WalletActions } from 'types/wallets'
import { Convert } from '../Convert/Convert'
import { Deposit } from '../Deposit/Deposit'
import { Pay } from '../Pay/Pay'
import { Receive } from '../Receive/Receive'
import { Send } from '../Send/Send'
import { Withdraw } from '../Withdraw/Withdraw'
import { WithdrawV2 } from '../Withdraw/WithdrawV2'
import { WalletAction, WalletActionSharedProps } from './WalletModal'

interface WalletModalActionProps {
  action: WalletAction
  walletId: string
  setWalletId: Dispatch<SetStateAction<string>>
  onModalClose: () => void
  refresh: () => void
}

export const WalletModalAction = ({
  action,
  walletId,
  setWalletId,
  onModalClose,
  refresh,
}: WalletModalActionProps) => {
  const { enableUseMigratedPaymentMethods } = useFeatureFlags()

  const actionWalletId = action?.data?.id?.toString()
  const actionKey = action?.key

  const sharedActionProps: WalletActionSharedProps = {
    walletId,
    actionWalletId,
    setWalletId,
    onModalClose,
  }

  const payActionMeta = {
    beneficiaryOptionId: action?.metadata?.pay?.beneficiaryOptionId,
  }

  const withdrawAction = useWithdrawAction(sharedActionProps)
  const depositAction = useDepositAction(sharedActionProps)
  const convertAction = useConvertAction(sharedActionProps)
  const sendAction = useSendAction(sharedActionProps)
  const receiveAction = useReceiveAction(sharedActionProps)
  const payAction = usePayAction(sharedActionProps, payActionMeta)

  return (
    <>
      {action?.key === WalletActions.PAY && (
        <Pay
          action={action}
          refresh={refresh}
          {...{
            ...payAction,
            isLimitsError: false,
          }}
        />
      )}

      {actionKey === WalletActions.WITHDRAW && (
        <>
          {enableUseMigratedPaymentMethods ? (
            <WithdrawV2
              action={action}
              refresh={refresh}
              {...{
                ...withdrawAction,
              }}
            />
          ) : (
            <Withdraw
              action={action}
              refresh={refresh}
              {...{
                ...withdrawAction,
              }}
            />
          )}
        </>
      )}

      {actionKey === WalletActions.DEPOSIT && (
        <Deposit
          action={action}
          refresh={refresh}
          {...{
            ...depositAction,
          }}
        />
      )}

      {actionKey === WalletActions.SEND && (
        <Send
          action={action}
          refresh={refresh}
          {...{
            ...sendAction,
          }}
        />
      )}

      {actionKey === WalletActions.RECEIVE && (
        <Receive
          action={action}
          refresh={refresh}
          {...{
            ...receiveAction,
          }}
        />
      )}

      {actionKey === WalletActions.CONVERT && (
        <Convert
          action={action}
          refresh={refresh}
          {...{
            ...convertAction,
          }}
        />
      )}
    </>
  )
}
