import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Box as Form, Box as FormWrapper } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import {
  ModalActions,
  ModalBody,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { logSentryError } from '@node-space/utils'
import { useMerchantsContext } from 'hooks/context'
import { useCreateChannel } from 'hooks/mutations/useCreateChannel'
import { useCryptoCurrenciesQuery, useFiatCurrenciesQuery } from 'hooks/queries'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useMounted } from 'hooks/useMounted'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ChannelCreateRequest } from 'types/payments'
import { ErrorAPI, MerchantType } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import CurrencySelectionWarning from './CurrencySelectionWarning'
import DisplayCurrencyField from './DisplayCurrencyField'
import MerchantIdField from './MerchantIdField'
import PayCurrencyField from './PayCurrencyField'
import ReferenceInputField from './ReferenceInputField'

export interface ChannelCreateForm {
  onSuccess: (val: string) => void
  dismissAction: () => void
}

const ChannelCreateForm = ({ onSuccess, dismissAction }: ChannelCreateForm) => {
  const { t } = useTranslation()
  const { data: fiatCurrencies, isPending: isLoadingFiat } = useFiatCurrenciesQuery()
  const { data: cryptoCurrencies, isPending: isLoadingCrypto } = useCryptoCurrenciesQuery()
  const { mutate, isPending: isCreatingChannel, error } = useCreateChannel()
  const merchantList = useMerchantsContext()
  const isMounted = useMounted()
  const [formError, setFormError] = useState('')
  const [activeMerchant, setActiveMerchant] = useState<MerchantType>(merchantList?.[0])
  const { control, register, formState, handleSubmit, setValue, setError, clearErrors } =
    useForm<ChannelCreateRequest>()

  const merchantId = useWatch({
    control,
    name: 'merchantId',
    defaultValue: '',
  })

  const displayCurrency = useWatch({
    control,
    name: 'displayCurrency',
    defaultValue: '',
  })

  const payCurrency = useWatch({
    control,
    name: 'payCurrency',
    defaultValue: '',
  })

  const reference = useWatch({
    control,
    name: 'reference',
    defaultValue: '',
  })

  const isBtnDisabled = useMemo(() => {
    return (
      isCreatingChannel ||
      displayCurrency === '' ||
      merchantId === '' ||
      reference === '' ||
      payCurrency === ''
    )
  }, [isCreatingChannel, displayCurrency, merchantId, reference, payCurrency])

  useEffect(() => {
    if (isMounted && !!merchantList?.length && merchantList[0]?.wallet) {
      setActiveMerchant(merchantList[0])
    }
  }, [merchantList])

  /**
   * Set the active merchant on Select
   * @param value
   */
  const onChangeMerchant = (value: string) => {
    let selectedMerchant = null
    setValue('merchantId', value)
    if (merchantList.length > 0) {
      selectedMerchant = merchantList.find(m => {
        return m.merchantId == value
      })
    }
    if (selectedMerchant) {
      setActiveMerchant(selectedMerchant)
      setValue('displayCurrency', selectedMerchant?.wallet?.currency?.code)
    }
  }

  /**
   * Loop over the merchant list and prepare the data
   * for Select options or return an empty array.
   */
  const filteredMerchantList = merchantList.filter(merchant => useIsActiveWallet(merchant?.wallet))
  const merchantListOptions = useMemo(() => {
    return filteredMerchantList && filteredMerchantList.length > 0
      ? filteredMerchantList.map(merchant => {
          const code = merchant?.wallet?.currency?.code
          return {
            icon: code?.toLowerCase(),
            label: merchant?.displayName,
            value: merchant?.merchantId,
            secondLabel: `${code} (ID: ${merchant?.wallet?.id})`,
            secondLabelText: 'Funding Wallet',
          }
        })
      : []
  }, [merchantList])

  const fiatCurrenciesOptions = useMemo(() => {
    return fiatCurrencies && fiatCurrencies.length > 0
      ? fiatCurrencies.map(fiat => {
          return { value: fiat.code, label: fiat.name }
        })
      : []
  }, [fiatCurrencies])

  const cryptoCurrenciesOptions = useMemo(() => {
    return cryptoCurrencies && cryptoCurrencies.length > 0
      ? cryptoCurrencies.map(fiat => {
          return { value: fiat.code, label: fiat.name }
        })
      : []
  }, [cryptoCurrencies])

  useEffect(() => {
    if (error) {
      const err: BaseErrorResponse = error
      const axiosError = err as { data: ErrorAPI }
      if (axiosError && axiosError.data?.errorList && axiosError.data?.errorList.length > 0) {
        axiosError.data.errorList.forEach(err =>
          setError(err.parameter as keyof ChannelCreateRequest, { message: err?.message })
        )
      }
    }
  }, [error])
  const handleResetCurrency = () => {
    setValue('displayCurrency', activeMerchant?.wallet?.currency?.code)
  }

  const onSubmit: SubmitHandler<ChannelCreateRequest> = values => {
    mutate(values, {
      onSuccess: res => {
        track.Amp.track(AmplitudeEvent.EOF_CHANNEL, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
          mid: merchantId,
          displayCurrency: displayCurrency,
          payInCurrency: payCurrency,
          reference: reference,
        })
        onSuccess(res?.redirectUrl)
      },
      onError: (error: BaseErrorResponse) => {
        logSentryError('Error from ChannelCreateRequest', error)
        const apiError = error as { data: ErrorAPI }
        // safeguard - default to display generic error message if BE does not return meaningful error message for channel create endpoint
        if (!apiError?.data?.errorList) {
          setFormError(t('oopsSomethingWentWrongContracted'))
        }
      },
    })
  }

  return (
    <FormWrapper tag="section" testid="channel-create-form-wrapper">
      <Form tag="form" testid="channel-create-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalScrollable>
          <ModalBody hasTopPadding={false} spaceChildren={false} hasBottomPadding={false}>
            {formError && (
              <Box paddingT={16}>
                <Callout message={formError} showCloseButton={false} state="error" />
              </Box>
            )}
            <MerchantIdField
              merchantId={merchantId}
              merchantListOptions={merchantListOptions}
              onChangeMerchant={onChangeMerchant}
              errors={formState.errors}
              isLoading={!merchantListOptions.length}
            />
            <DisplayCurrencyField
              currencyList={fiatCurrenciesOptions}
              selectedCurrency={displayCurrency}
              errors={formState?.errors}
              onChangeHandler={setValue}
              isLoading={isLoadingFiat}
            />
            <PayCurrencyField
              currencyList={cryptoCurrenciesOptions}
              selectedCurrency={displayCurrency}
              errors={formState?.errors}
              onChangeHandler={setValue}
              isLoading={isLoadingCrypto}
            />
            {displayCurrency && displayCurrency !== activeMerchant?.wallet?.currency?.code && (
              <CurrencySelectionWarning handleResetCurrency={handleResetCurrency} />
            )}
            <Box flex flexWrap paddingX={12} className="-mx-3 mb-6 mb-2">
              <ReferenceInputField errors={formState?.errors} register={register} />
            </Box>
            <Box className="mb-6">
              <Callout
                message={t('payments.channels.infoBannerText')}
                showCloseButton={false}
                state="info"
              />
            </Box>
          </ModalBody>
        </ModalScrollable>
        <ModalActions
          actions={[
            {
              children: t('cancel'),
              onClick: dismissAction,
              secondary: true,
            },
            {
              children: t('payments.channels.createChannel'),
              onClick: () => {
                setFormError('')
                clearErrors()
              },
              disabled: isBtnDisabled,
              loading: isCreatingChannel,
              type: 'submit',
              testid: 'create-channel-btn',
            },
          ]}
        />
      </Form>
    </FormWrapper>
  )
}

export default React.memo(ChannelCreateForm)
