import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FieldErrorsImpl } from 'react-hook-form'
import { Box as FormRow, Icon, Tooltip, DoubleLabelSelect } from '@node-space/storybook-components'

interface MerchantIdFieldProps {
  merchantListOptions: {
    icon: string
    value: string
    label: string
    secondLabel: string
    secondLabelText?: string
  }[]
  merchantId: string
  onChangeMerchant: (value: string) => void
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >
  isLoading: boolean
}

const MerchantIdField = ({
  merchantListOptions,
  merchantId,
  onChangeMerchant,
  errors,
  isLoading,
}: MerchantIdFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormRow className="mb-12 h-16 mt-8 z-20">
      <DoubleLabelSelect
        label={
          <>
            <label className="mr-2">{t('merchantId')}</label>
            <Tooltip
              id="merchant-tooltip"
              position="right"
              bodyContent={`${t('payments.channels.tooltips.merchantId')}`}
              className="w-72 whitespace-normal"
            >
              <Icon name="InfoCircleIcon" size="sm" />
            </Tooltip>
          </>
        }
        name="merchantId"
        options={merchantListOptions}
        value={merchantId}
        onChange={value => {
          onChangeMerchant(value)
        }}
        menuMaxHeight={225}
        placeholder={t('merchantId')}
        iconsUrl={process.env.ICONS_URL}
        error={Boolean(errors.merchantId)}
        errorText={errors.merchantId?.message}
        loading={isLoading}
      />
    </FormRow>
  )
}

export default React.memo(MerchantIdField)
