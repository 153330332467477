import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Modal, ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'

const UnsupportedWalletAction = ({ setUnsupportedWalletAction, unsupportedWalletAction }) => {
  const { t } = useTranslation('walletsPage.modals.unsupportedWalletAction')

  return (
    <Modal visible={true} closeModal={() => setUnsupportedWalletAction(null)}>
      <ModalNavBar title={t('currencyNotSupported')} />

      <Box flex direction="col" padding={24} gapY={24}>
        <div>
          {unsupportedWalletAction === 'deposit'
            ? t('deposit')
            : unsupportedWalletAction === 'withdraw'
            ? t('withdraw')
            : t('unsupportedCurrency')}
        </div>
      </Box>

      <ModalActions
        actions={[
          {
            type: 'button',
            children: t('cancel'),
            testid: 'cancel-disclaimer',
            onClick: () => setUnsupportedWalletAction(null),
            secondary: true,
          },
        ]}
      />
    </Modal>
  )
}

export default UnsupportedWalletAction
