import React from 'react'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { StatusPill } from '@node-space/storybook-components'
import { Box as Row } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { PendingTransactionsResponse } from 'types/approvals'
import { getStatusState, titleCase } from 'utils/utils'

interface SidePanelHeaderTemplateProps {
  transactionDetails: PendingTransactionsResponse
}

export const SidePanelHeaderTemplate = ({ transactionDetails }: SidePanelHeaderTemplateProps) => {
  const { t } = useTranslation()

  const iconUrl = `${process.env.ICONS_URL}${transactionDetails?.currency?.code?.toLowerCase()}.svg`

  return (
    <SidePanelHeader
      template={2}
      heading={{
        iconUrls: [iconUrl],
        primaryLabel: `${transactionDetails?.amount} ${transactionDetails?.currency?.code}`,
        secondaryLabel: startCase(transactionDetails?.type),
      }}
      bottomContent={{
        leftLabel: t('status'),
        leftDescription: (
          <StatusPill
            displayAsDot
            state={getStatusState(transactionDetails?.status?.toUpperCase())}
            label={titleCase(transactionDetails?.status)}
            fitContent
          />
        ),
        ...(transactionDetails?.userReference && {
          rightLabel: t('reference'),
          rightDescription: (
            <Row flex alignItems="center">
              <Text
                tag="span"
                size="sm"
                weight="medium"
                color="dark-grey"
                className="mb-1 flex-wrap break-words"
              >
                {ellipseMiddleText(transactionDetails?.userReference, 10, 10)}
              </Text>
              <CopyButton
                copyText={transactionDetails?.userReference}
                confirmationMessage={t('copiedToClipboard')}
                iconColour={copyButtonIconColour}
                confirmationMessageAlignment="left"
              />
            </Row>
          ),
        }),
      }}
    />
  )
}
