import { arrayListToString } from './utils'

export const applyPasswordTests = (value: string, t): { isValid: boolean; message: string } => {
  const passwordTests = {
    hasLowercase: {
      pattern: /[a-z]/,
      message: t('passwordError.lowercase'),
    },
    hasUppercase: {
      pattern: /[A-Z]/,
      message: t('passwordError.uppercase'),
    },
    hasNumber: {
      pattern: /[0-9]/,
      message: t('passwordError.number'),
    },
    hasMinChars: {
      pattern: /^.{8,}/,
      message: t('passwordError.minChars'),
    },
  }

  let isValid = true
  const errors = []
  Object.keys(passwordTests).forEach(key => {
    const test = passwordTests[key]
    const testValid = test.pattern.test(value)
    if (!testValid) {
      isValid = false
      const isFirstTypeTest = !errors?.length && key !== 'hasMinChars'
      errors.push(`${isFirstTypeTest ? `${t('passwordError.includes')} ` : ''}${test.message}`)
    }
  })
  return { isValid, message: arrayListToString(errors, t('passwordError.start')) }
}
