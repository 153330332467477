import React from 'react'
import { AddCustomerPayload } from 'types/customers'
import { MappedErrorMessageState } from 'types/errors'
import { ModalStep } from '../types'
import { AddCustomerConfirm } from './AddCustomerConfirm'
import { AddCustomerForm } from './AddCustomerForm'

interface AddCustomerStepProps {
  modalStep: ModalStep
  customerFormState: AddCustomerPayload
  onBackHandler: () => void
  submit: () => void
  appendCustomerDetails: (customerDetails: AddCustomerPayload) => void
  isLoading: boolean
  requestError: MappedErrorMessageState
  resetRequestError: () => void
}

export const AddCustomerStep = ({
  modalStep,
  customerFormState,
  onBackHandler,
  submit,
  appendCustomerDetails,
  isLoading,
  requestError,
  resetRequestError,
}: AddCustomerStepProps) => {
  if (modalStep === ModalStep.ADD_CUSTOMER) {
    return (
      <AddCustomerForm
        isSubmitting={false}
        onBack={onBackHandler}
        customerFormState={customerFormState}
        onContinue={customerDetails => appendCustomerDetails(customerDetails)}
      />
    )
  }

  if (modalStep === ModalStep.CONFIRMATION) {
    return (
      <AddCustomerConfirm
        customer={customerFormState}
        isLoading={isLoading}
        onContinue={submit}
        onBack={onBackHandler}
        requestError={requestError}
        resetRequestError={resetRequestError}
      />
    )
  }

  return null
}
