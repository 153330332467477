import { LegalAgreementType } from 'pages/LegalAgreements/types'

/**
 * This function changes the order of agreements array to show a specific agreement first.
 * @param name The name of the agreement to be shown first.
 * @param agreementsData The array of agreements to be shown.
 * @returns updated array of agreements.
 */
export const orderAgreements = (
  name: string,
  agreementsData: LegalAgreementType[]
): LegalAgreementType[] => {
  const priorityAgreementIndex = agreementsData?.findIndex((agreementData: LegalAgreementType) =>
    agreementData?.agreement?.name?.includes(name)
  )

  if (priorityAgreementIndex === -1) return agreementsData

  const updatedAgreementsList = [...agreementsData]
  const priorityAgreement = updatedAgreementsList[priorityAgreementIndex]
  updatedAgreementsList?.splice(priorityAgreementIndex, 1)
  updatedAgreementsList?.unshift(priorityAgreement)

  return updatedAgreementsList
}

/**
 * This function filters the agreements array to show a specific agreement.
 * @param agreementId The id of the agreement to be returned.
 * @param agreementsData The array of agreements to be filtered.
 * @returns single agreement.
 */
export const filterSingleAgreement = (
  agreementId: number,
  agreementsData: LegalAgreementType[]
): LegalAgreementType[] => {
  const filteredAgreement = agreementsData?.filter(
    (agreementsData: LegalAgreementType) => agreementsData?.id === agreementId
  )

  return filteredAgreement
}
