export const CSATFeedbackValue = {
  BAD: 'Bad',
  OKAY: 'Okay',
  GOOD: 'Good',
} as const
export type CSATFeedbackValueType = (typeof CSATFeedbackValue)[keyof typeof CSATFeedbackValue]

export const CSATFeedbackRating = {
  BAD: 2,
  OKAY: 3,
  GOOD: 4,
} as const
export type CSATFeedbackRatingType = (typeof CSATFeedbackRating)[keyof typeof CSATFeedbackRating]

export const CSATOptionStatus = {
  DEFAULT: 'default',
  SELECTED: 'selected',
  UNSELECTED: 'unselected',
} as const
export type CSATOptionStatusType = (typeof CSATOptionStatus)[keyof typeof CSATOptionStatus]

export type CSATOptionType = {
  label: string
  value: CSATFeedbackValueType
  rating: CSATFeedbackRatingType
}

export type CSATCustomerType = {
  emailAddress: string
  firstname: string
  lastname: string
  companyName: string
  customerID: string
}

export const CriteriaKeys = {
  IS_CASE_COMPLETED: 'isCaseCompleted',
  HAS_SENT_EMAIL: 'hasSentEmail',
  HAS_FILTERED_CASE_STATUS: 'hasFilteredCaseStatus',
  HAS_UPLOADED_DOCUMENT: 'hasUploadedDocument',
  HAS_ENTERED_CUSTOMER_ID: 'hasEnteredCustomerId',
} as const

export type CriteriaKeyType = (typeof CriteriaKeys)[keyof typeof CriteriaKeys]

export type CriteriaData = Record<CriteriaKeyType, boolean>

export type CSATSurveyData = {
  criteria?: CriteriaData
  isSurveyCompleted?: boolean
}
