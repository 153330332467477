import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import { BeneficiaryEntityType, BeneficiaryTransferDestination } from 'types/beneficiaries'
import { createAddressSchema } from 'utils/yupSchemas/addressSchemas'
import {
  createBeneficiaryNameSchema,
  createGenericNameSchema,
} from 'utils/yupSchemas/createGenericNameSchema'
import { createNameSchema } from 'utils/yupSchemas/createNameSchema'
import { createReferenceSchema } from 'utils/yupSchemas/createReferenceSchema'

export const getCommonSchemaFields = (t: TFunction<'translation', undefined>) => {
  const beneficiaryEntityType = yup.string().trim().required()
  const beneficiaryType = yup.string().trim().required()

  // Individual Beneficiary
  const firstName = createGenericNameSchema(t('firstName'), t, 2)
  const lastName = createGenericNameSchema(t('lastName'), t, 2)
  const dateOfBirth = yup
    .string()
    .trim()
    .label(t('dateOfBirth'))
    .test({
      test(value, ctx) {
        if (ctx?.parent?.beneficiaryEntityType === BeneficiaryEntityType.INDIVIDUAL) {
          const pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

          if (value) {
            if (!pattern.test(value)) {
              return ctx.createError({
                message: t('dateOfBirthInvalidError'),
              })
            }

            const dateFormat = 'DD/MM/YYYY'
            const date = dayjs(value, dateFormat)

            if (!date.isValid()) {
              return ctx.createError({
                message: t('dateOfBirthInvalidError'),
              })
            }

            const maxDate = dayjs(new Date())
            if (date.isAfter(maxDate)) {
              return ctx.createError({
                message: t('dateOfBirthFutureDateError'),
              })
            }
            return true
          }

          return true
        }

        return ctx.schema.notRequired()
      },
    })

  // Business beneficiary
  const businessName = createGenericNameSchema(t('businessName'), t)

  // All beneficiaries
  const countryCode = yup.string().label('Country').required()
  const currencyLabel = t('currency')
  const currencyCode = yup.string().label(currencyLabel).required()
  const alias = createBeneficiaryNameSchema(t('beneficiaryName'), t)

  // International beneficiary address details
  const validateField = (value: string, ctx: yup.TestContext<AnyObject>, label: string) => {
    if (ctx?.parent?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL) {
      if (!value) {
        ctx.schema.required()
        ctx.createError({
          message: t('fieldIsARequiredField', { field: label }),
        })
      }
      return true
    }

    // Should not be required for LOCAL transfer type
    ctx.schema.notRequired()
    return true
  }

  const addessLine1Label = t('addressLine1')
  const addressLine1 = createAddressSchema(addessLine1Label, t).test({
    test(value, ctx) {
      return validateField(value, ctx, addessLine1Label)
    },
  })

  const addessLine2Label = t('addressLine2')
  const addressLine2 = createAddressSchema(addessLine2Label, t)

  const cityLabel = t('city')
  const city = createAddressSchema(cityLabel, t).test({
    test(value, ctx) {
      return validateField(value, ctx, cityLabel)
    },
  })

  const postCodeLabel = t('postCode')
  const postCode = createNameSchema(postCodeLabel, t).test({
    test(value, ctx) {
      return validateField(value, ctx, postCodeLabel)
    },
  })

  const regionLabel = t('region')
  const region = createAddressSchema(regionLabel, t).test({
    test(value, ctx) {
      return validateField(value, ctx, regionLabel)
    },
  })

  const beneficiaryPaymentReference = createReferenceSchema(t('beneficiaryPaymentReference'), t)

  return {
    alias,
    beneficiaryEntityType,
    beneficiaryType,
    firstName,
    lastName,
    dateOfBirth,
    businessName,
    countryCode,
    currencyCode,
    addressLine1,
    addressLine2,
    city,
    postCode,
    region,
    beneficiaryPaymentReference,
  }
}
