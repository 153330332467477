import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useKeyPress } from '@node-space/hooks'
import { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { WalletCreateModalContext } from '../../context/WalletCreateModalProvider'
import WalletsCreateForm from '../Form/WalletsCreateForm'

interface WalletCreateModalProps {
  refresh: () => void
}

const WalletCreateModal = ({ refresh }: WalletCreateModalProps) => {
  const { t } = useTranslation()
  const [createWalletModal, setCreateWalletModal] = useContext(WalletCreateModalContext)

  const handleKeyPress = useCallback(() => {
    if (!createWalletModal) {
      return
    }
    setCreateWalletModal(false)
  }, [createWalletModal])

  useKeyPress('Escape', handleKeyPress)
  const handleCloseModal = () => setCreateWalletModal(false)
  return (
    <Modal visible={createWalletModal} closeModal={handleCloseModal}>
      <Wrapper tag="section" className="w-512 mx-auto">
        <ModalNavBar title={t('createAWallet')} onClose={handleCloseModal} />
        <WalletsCreateForm
          onSuccess={() => {
            refresh()
            handleCloseModal()
          }}
          setCreateWalletModal={setCreateWalletModal}
        />
      </Wrapper>
    </Modal>
  )
}

export default React.memo(WalletCreateModal)
