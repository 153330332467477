import { TFunction } from 'react-i18next'
import * as yup from 'yup'

const NAME_VALIDATION_PATTERN = /^[a-zA-Z0-9 .,\s]*$/
const BENEFICIARY_NAME_VALIDATION_PATTERN = /^[a-zA-Z0-9.&\s]*$/

export const createGenericNameSchema = (
  label: string,
  t: TFunction<'translation', undefined>,
  minLength?: number,
  maxLength?: number
) =>
  yup
    .string()
    .trim()
    .label(label)
    .min(
      minLength || 3,
      t('fieldMinLength', {
        field: label,
        min: minLength || 3,
        interpolation: { escapeValue: false },
      })
    )
    .max(
      maxLength || 50,
      t('fieldMaxLength', {
        field: label,
        max: maxLength || 50,
      }).trim()
    )
    .matches(NAME_VALIDATION_PATTERN, t('manageBeneficiaries.nameFieldInvalidCharacters'))

export const createBeneficiaryNameSchema = (
  label: string,
  t: TFunction<'translation', undefined>
) =>
  yup
    .string()
    .trim()
    .label(label)
    .min(
      3,
      t('fieldMinLength', {
        field: label,
        min: 3,
        interpolation: { escapeValue: false },
      })
    )
    .max(
      50,
      t('fieldMaxLength', {
        field: label,
        max: 50,
      }).trim()
    )
    .matches(
      BENEFICIARY_NAME_VALIDATION_PATTERN,
      t('manageBeneficiaries.beneficiaryNameFieldInvalidCharacters')
    )
