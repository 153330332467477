import * as yup from 'yup'
import { MassPayoutConfirmationPayload } from '../types'

export const MassPayoutConfirmationSchema = () => {
  const password = yup.string().trim().required()

  const formFields = {
    password,
  }

  return yup.object<Partial<Record<keyof MassPayoutConfirmationPayload, yup.AnySchema>>>(formFields)
}
