import { requestApi } from 'api/api'
import { Channel } from 'types/channels'
import { CreateChannelReq, EditChannelReq, GetChannelsReq } from 'types/transaction/GetChannelsReq'
import { Paging } from 'types/types'

export const getChannels = async ({
  offset,
  max,
  merchant,
  search,
}: GetChannelsReq): Promise<{ channels: Channel[]; paging: Paging }> => {
  const { data, headers }: { data; headers: { [key: string]: string } } = await requestApi({
    url: `/v2/channel`,
    method: 'GET',
    fullResponse: true,
    params: {
      ...(search && { q: encodeURI(search) }),
      ...(merchant !== 'ALL' && { merchantId: merchant }),
      ...(max && { max }),
      offset,
    },
  })
  return {
    channels: data || [],
    paging: {
      total: Number(headers['x-api-list-total']),
      offset: Number(headers['x-api-list-start']),
      max,
    },
  }
}

export const createChannel = (data: CreateChannelReq): Promise<Channel> => {
  return requestApi({
    url: '/v2/channel',
    method: 'POST',
    data,
  })
}

export const editChannel = async ({ merchantId, reference, uuid }: EditChannelReq) => {
  return requestApi({
    url: '/v2/channel',
    method: 'PUT',
    data: { merchantId, reference, uuid },
  })
}
