import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { AccountType } from 'types/accounts'
import { AccountTypes } from 'types/types'
import { BUSINESS_VERIFIED } from 'utils/auth/utils'
import { useAccountsQuery, useProfileQuery } from './queries'
import { useVerificationRequestStatusQuery } from './queries/useVerificationRequestStatusQuery'

export const useRequestsOnLogin = () => {
  const navigate = useNavigate()
  const {
    data: profile,
    isFetching: isFetchingProfile,
    isFetched: isFetchedProfile,
    isError: isErrorProfile,
    error: errorProfile,
  } = useProfileQuery()

  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    isFetched: isFetchedAccounts,
    isError: isErrorUserAccount,
    error: errorUserAccounts,
  } = useAccountsQuery(isFetchedProfile)

  const currentAccount: AccountType = accounts?.find(account => account?.isCurrent)

  const {
    data: verificationRequestStatus,
    isFetching: isFetchingVerificationRequestStatus,
    isFetched: isFetchedVerificationRequestStatus,
    isError: isErrorVerificationRequestStatus,
    error: errorVerificationRequestStatus,
  } = useVerificationRequestStatusQuery(isFetchedProfile)

  const isLoading = isFetchingProfile && isFetchingAccounts && isFetchingVerificationRequestStatus
  const isError = isErrorProfile || isErrorUserAccount || isErrorVerificationRequestStatus
  const error = errorProfile || errorUserAccounts || errorVerificationRequestStatus

  useEffect(() => {
    if (!isLoading && isError) {
      const sentryTraceId = logSentryErrorAndGetTraceId('Account Error: ', error)
      navigate(PathNames.ACCOUNT_ERROR, {
        state: sentryTraceId,
      })
    }
  }, [isLoading, isError, error])

  const isVerifiedBusinessAccount = useMemo(
    () => verificationRequestStatus === BUSINESS_VERIFIED,
    [verificationRequestStatus]
  )

  const isDeveloperAccount = useMemo(
    () => profile?.options?.registrationAccountType === AccountTypes.DEVELOPER,
    [profile?.options?.registrationAccountType]
  )

  const isAuthorizedAccount = useMemo(
    () => isVerifiedBusinessAccount || isDeveloperAccount,
    [isVerifiedBusinessAccount, isDeveloperAccount]
  )

  const isLoadingRequestsOnLogin = useMemo(
    () => isFetchingProfile || isFetchingVerificationRequestStatus || isFetchingAccounts,
    [isFetchingProfile, isFetchingVerificationRequestStatus, isFetchingAccounts]
  )

  const fetchedRequest = {
    profile: isFetchedProfile,
    verification: isFetchedVerificationRequestStatus,
    account: isFetchedAccounts,
  }

  return {
    isLoadingRequestsOnLogin,
    fetchedRequest,
    profile,
    isVerifiedBusinessAccount,
    isDeveloperAccount,
    isAuthorizedAccount,
    accounts,
    currentAccount,
  }
}
