import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { capitalize } from '@node-space/utils'
import { Description } from 'components/text/Description'
import { useCustomerQuery } from 'hooks/queries/Customers/useCustomerQuery'
import { isBusinessCustomer } from '../utils'
import { CustomerDetailRow } from './CustomerDetailRow'

type SidePanelCustomerBodyProps = {
  customerId: string
}

export const SidePanelCustomerBody = ({ customerId }: SidePanelCustomerBodyProps) => {
  const { t } = useTranslation()
  const { data: customer, error, isPending } = useCustomerQuery(customerId)

  if (isPending) {
    return (
      <Box className="sm:flex sm:justify-between">
        <Skeleton className="w-24 sm:mb-0 mb-1" variant="text" />
        <Skeleton className="w-24" variant="text" />
      </Box>
    )
  }

  if (error) {
    return (
      <StatusCard status="error" headline={t('requestErrors.pageErrorIntro')}>
        <Description>
          {`${t('requestErrors.comeBackLater')} `}
          <TextAction isWindowReload text={t('requestErrors.refreshPage')} />.
        </Description>
      </StatusCard>
    )
  }

  return (
    <Box flex direction="col" gapY={8}>
      <Text size="lg" weight="medium">
        {t('customers.customerDetails')}
      </Text>
      {isBusinessCustomer(customer) && (
        <>
          {customer?.type && (
            <CustomerDetailRow
              label={t('customers.type')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {capitalize(customer?.type?.toLowerCase())}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.name && (
            <CustomerDetailRow
              label={t('customers.businessName')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.name}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.industry?.name && (
            <CustomerDetailRow
              label={t('customers.industry')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.industry?.name}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.businessRegistrationNumber && (
            <CustomerDetailRow
              label={t('customers.businessRegistrationNumber')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.businessRegistrationNumber}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.taxResidenceCountryCode && (
            <CustomerDetailRow
              label={t('customers.businessTaxResidency')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.taxResidenceCountryCode}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.address?.address1 && (
            <CustomerDetailRow
              label={t('customers.address.address')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.address?.address1}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.address?.countryCode && (
            <CustomerDetailRow
              label={t('customers.country')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.address?.country}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          <Text size="lg" weight="medium">
            {t('customers.representative.title')}
          </Text>
          {customer?.company?.representative?.firstName && (
            <CustomerDetailRow
              label={t('customers.representative.name')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {`${customer?.company?.representative?.firstName} ${customer?.company?.representative?.lastName}`}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.representative?.dateOfBirth && (
            <CustomerDetailRow
              label={t('customers.representative.dateOfBirth')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.representative?.dateOfBirth}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
          {customer?.company?.representative?.address?.country && (
            <CustomerDetailRow
              label={t('customers.representative.country')}
              detail={
                <Box flex flexWrap justifyContent="start" className="lg:justify-end">
                  <Text weight="medium" className="text-left lg:text-right" tag="span" size="sm">
                    {customer?.company?.representative?.address?.country}
                  </Text>
                </Box>
              }
              isColumn={false}
            />
          )}
        </>
      )}
    </Box>
  )
}
