import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getCustomers } from 'services/CustomerService'
import { Customer } from 'types/customers'
import { WithPaginationApiResponse } from 'types/pagination'
import { API_PAGINATION_LIMIT, API_PAGINATION_OFFSET, withPaginationPaging } from 'utils/pagination'

export const useCustomersQuery = (
  search: string,
  size = API_PAGINATION_LIMIT,
  page = API_PAGINATION_OFFSET,
  sort = ''
) => {
  return useQuery({
    queryKey: reactQueryKeys.customers(size, page, sort),
    queryFn: () => getCustomers(search, size, page, sort),
    select: (res: WithPaginationApiResponse<Customer[]>) => {
      const customerListWithPagination = withPaginationPaging<Customer[]>(res)
      return customerListWithPagination
    },
  })
}
