import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { Error, ErrorResponse } from './types'

export type UUID = string

export const BankTransferType = {
  SWIFT: 'SWIFT',
} as const

export type BankTransferType = (typeof BankTransferType)[keyof typeof BankTransferType]

export const BeneficiaryTransferDestination = {
  LOCAL: 'LOCAL',
  INTERNATIONAL: 'INTERNATIONAL',
} as const

export type BeneficiaryTransferDestination =
  (typeof BeneficiaryTransferDestination)[keyof typeof BeneficiaryTransferDestination]

export const BeneficiaryEntityType = {
  BUSINESS: 'BUSINESS',
  INDIVIDUAL: 'INDIVIDUAL',
} as const

export type BeneficiaryEntityType =
  (typeof BeneficiaryEntityType)[keyof typeof BeneficiaryEntityType]

export const BeneficiaryType = {
  FIRST_PARTY: 'FIRST_PARTY',
  THIRD_PARTY: 'THIRD_PARTY',
} as const

export type BeneficiaryType = (typeof BeneficiaryType)[keyof typeof BeneficiaryType]

export const BeneficiaryDetailsCurrency = {
  EUR: 'EUR',
  GBP: 'GBP',
  NGN: 'NGN',
  USD: 'USD',
  ZAR: 'ZAR',
  default: '',
} as const

export type BeneficiaryDetailsCurrency =
  (typeof BeneficiaryDetailsCurrency)[keyof typeof BeneficiaryDetailsCurrency]

export const USDBeneficiaryOptions = {
  CBIT: 'CBIT',
  BANK_ACCOUNT: 'BANKACCOUNT',
} as const

export type USDBeneficiaryOptions =
  (typeof USDBeneficiaryOptions)[keyof typeof USDBeneficiaryOptions]

export type Address = {
  addressLine1: string
  addressLine2?: string
  city: string
  postCode: string
  region: string
  country: string
}

export type IntermediaryBank = {
  code: string
  bankName: string
  accountNumber?: string
  bankAccountType?: string
  bankAddress: {
    addressLine1: string
    addressLine2?: string
    city: string
    postCode: string
    region: string
    country: string
  }
}

export type BeneficiaryDetails = {
  /**
   * @deprecated Use entityType instead
   */
  type?: BeneficiaryEntityType
  alias?: string
  currency: BeneficiaryDetailsCurrency
  firstName?: string
  beneficiaryType?: BeneficiaryType
  lastName?: string
  businessName?: string
  addressCountry: string
  address: Address
  transferDestination?: BeneficiaryTransferDestination
  updatedAt?: string
  bvnkAccountReference?: string
  category?: string
  createdAt?: string
  dateOfBirth?: string
  entityType?: BeneficiaryEntityType
  fiat: {
    accountNumber: string
    code: string
    bankName?: string
    bankAddress?: Address
    intermediaryBank?: IntermediaryBank
    accountType?: string
  }
  reference?: string
  beneficiaryPaymentReference?: string
  transferType?: string
  bankTypeIdentifier?: string
}

export type AddBeneficiaryRequest = {
  data: BeneficiaryDetails
  authorization: {
    totp: string
  }
}

export type AddBeneficiaryRequestPreRequest = BeneficiaryDetails

export type AddBeneficiaryPostChallengeRequest = {
  challenge: UUID
  tokenResponse: string
}

export type ModifyFields<T, R> = Omit<T, keyof R> & R

export type AddBeneficiaryChallengeResponse = {
  challenge?: {
    value?: UUID
    type?: string
    ttl?: string
  }
  callbackUrl?: string
  context?: {
    code?: string
    message?: string
  }
  details: {
    documentLink: string
    errors: {
      requestBody: string[]
    }
  }
}

export type AddBeneficiaryPostResponse = ErrorResponse & {
  data?: AddBeneficiaryChallengeResponse
}

export interface BanksResponse {
  banks: Bank[]
}

export interface Bank {
  countryCode: string
  bankName: string
  bankIdentifier: string
}

export interface NgBankAccountValidationRequest {
  accountNumber: string
  bankCode: string
}

export interface NgBankAccountValidationResponse {
  data: {
    accountName?: string
    accountNumber?: string
    accountNumberFormat?: string
    bankIdentifier?: string
    errorList: Error[]
  }
  status: number
  statusText: string
  headers: { [property: string]: unknown }
  config: { [property: string]: unknown }
  request: { [property: string]: unknown }
}

export interface BaseErrorResponse {
  code?: string
  data?: {
    challenge?: {
      value?: string
      type?: string
      ttl?: string
    }
    code?: string
    message?: string
    errorList?: Error[]
    details?: {
      documentLink?: string
      errors?: {
        requestBody?: string[]
      }
    }
  }
  errorList?: Error[]
  message?: string
  status?: number
}

export type AddBeneficiaryFormErrors = Partial<FieldErrorsImpl<AddBeneficiaryFormPayload>>

export type AddBeneficiaryFormPayloadKeys = keyof AddBeneficiaryFormPayload

export interface BaseFormProps {
  handleChange: (field: string, value: string) => void
  register: UseFormRegister<AddBeneficiaryFormPayload>
}

export interface AddBeneficiaryBasicPayload {
  alias?: string
  currencyCode?: BeneficiaryDetailsCurrency
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  businessName?: string
  countryCode?: string
  beneficiaryEntityType?: BeneficiaryEntityType
  beneficiaryType?: BeneficiaryType
  beneficiaryPaymentReference?: string
  // Intl beneficiaries
  addressLine1?: string
  addressLine2?: string
  city?: string
  postCode?: string
  region?: string
  // EUR
  iban?: string
  bic?: string
  // GBP
  accountNumber?: string
  sortCode?: string
  // NGN
  ngnAccountNumber?: string
  bankCode?: string
  bankName?: string
  // USD
  usdAccountNumber?: string
  abaRoutingNumber?: string
  cbitAddress?: string
  cbitBankCode?: string
  // ZAR
  zarAccountNumber?: string
  zarAccountType?: string
  zarRoutingNumberBranchCode?: string
  zarBank?: string
}

export type AddBeneficiaryBasicPayloadKeys = keyof AddBeneficiaryBasicPayload

export interface BankAddress {
  bankName?: string
  bankAddressLine1?: string
  bankAddressLine2?: string
  bankCity?: string
  bankPostCode?: string
  bankRegion?: string
  bankCountry?: string
  bankCountryName?: string
}

export interface AddBeneficiaryBankDetailsPayload extends BankAddress {
  intlIban?: string
  bicSwiftCode?: string
}

export type AddBeneficiaryBankDetailsPayloadKeys = keyof AddBeneficiaryBankDetailsPayload

export interface AddIntermediaryBankDetailsPayload {
  intermediaryBankName?: string
  intermediaryBic?: string
  intermediaryBankAddressLine1?: string
  intermediaryBankAddressLine2?: string
  intermediaryBankCity?: string
  intermediaryBankPostCode?: string
  intermediaryBankRegion?: string
  intermediaryBankCountry?: string
  intermediaryBankCountryName?: string
}

export type AddIntermediaryBankDetailsPayloadKeys = keyof AddIntermediaryBankDetailsPayload

export interface AddBeneficiaryFormPayload
  extends AddBeneficiaryBasicPayload,
    AddBeneficiaryBankDetailsPayload,
    AddIntermediaryBankDetailsPayload {
  transferDestination?: BeneficiaryTransferDestination
  countryName?: string
  bankCountryName?: string
  password?: string
  challengeCode?: UUID
  skipIntermediaryBankScreen?: boolean
}

export type AddBeneficiaryFormPayloadErrors = Record<
  keyof AddBeneficiaryFormPayload,
  { message: string }
>

export interface UpdateBeneficiaryFormPayload {
  alias: string
  password: string
}

export type UpdateBeneficiaryFormPayloadKeys = keyof UpdateBeneficiaryFormPayload

export type UpdateBeneficiaryFormErrors = Partial<FieldErrorsImpl<UpdateBeneficiaryFormPayload>>
export interface EditBeneficiaryPostRequestPayload {
  challenge: UUID
  password: string
}

export interface AddLocalBeneficiaryFormPayload {
  currencyCode?: BeneficiaryDetailsCurrency
  firstName?: string
  lastName?: string
  businessName?: string
  countryCode?: string
  // EUR
  iban?: string
  bic?: string
  // GBP
  accountNumber?: string
  sortCode?: string
  // NGN
  ngnAccountNumber?: string
  bankCode?: string
  // USD
  usdAccountNumber?: string
  abaRoutingNumber?: string
  cbitAddress?: string
  cbitBankCode?: string
}

export interface AddLocalBeneficiaryFormConfirmPayload {
  password: string
}

export interface ValidateIbanRequest {
  accountNumberFormat: string
  accountNumber: string
}

export interface ValidateIbanResponse {
  bankAccount?: {
    type?: string
    bankName?: string
    accountNumber?: string
    bankCode?: string
    bankAddress?: {
      addressLine1?: string
      addressLine2?: string
      city?: string
      region?: string
      postCode?: string
      countryCode?: string
    }
  }
  validationFailed: string[]
  supportedPaymentMethods: string[]
}

export interface ValidateScanRequest {
  sortCode: string
  accountNumber: string
}

export interface ValidateScanResponse {
  bankAccount?: {
    type?: string
    bankName?: string
    accountNumber?: string
    bankCode?: string
    bankAddress?: {
      addressLine1?: string
      addressLine2?: string
      city?: string
      region?: string
      postCode?: string
      countryCode?: string
    }
  }
  bic: string
  iban: string
  validationFailed: string[]
  supportedPaymentMethods: string[]
}

export interface ValidatBicRequest {
  bic?: string
}
