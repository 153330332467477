import { Amplitude } from 'utils/amplitude/amplitude'
import { GoogleAnalytics } from 'utils/googleAnalytics'
import { Hotjar as HJ } from 'utils/hotJar'

const track = {
  Amp: Amplitude,
  GA: GoogleAnalytics,
  Hotjar: HJ,
}

export const Amp = Amplitude
export const GA = GoogleAnalytics
export const Hotjar = HJ

export default track
