import { useContext } from 'react'
import { FilterProviderContext } from 'components/FilterBar/context/FilterProvider'

const useFilterFromTo = () => {
  const [state, dispatch] = useContext(FilterProviderContext)

  return {
    from: state?.from,
    to: state?.to,
    isTouched: state?.isTouched,
    setIsTouched: (value: boolean) => dispatch({ type: 'UPDATE_TOUCHED', payload: value }),
    setFrom: (value: Date) => dispatch({ type: 'UPDATE_FROM', payload: value }),
    setTo: (value: Date) => dispatch({ type: 'UPDATE_TO', payload: value }),
    resetDateRange: () => dispatch({ type: 'RESET_DATE' }),
    setToNull: () => dispatch({ type: 'SET_DATES_TO_NULL' }),
  }
}

export default useFilterFromTo
