import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import { ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { useAddWhitelistedProtocol } from 'hooks/mutations/useAddWhitelistedProtocol'
import { BaseErrorResponse } from 'types/beneficiaries'

const ConfirmProtocol = ({ form, setStep }) => {
  const { t } = useTranslation()
  const { mutate, isPending } = useAddWhitelistedProtocol()

  const handleContinue = async () => {
    mutate(
      {
        uuid: form.getValues('uuid'),
        selectedProtocol: form.getValues('protocol'),
        totp: form.getValues('twoFaField'),
      },
      {
        onError: (error: BaseErrorResponse) => {
          if (error?.status !== 403) {
            logSentryError('Error from 2FA withdrawal - add missing protocol', error)
          }
          form.setError('twoFaField', {
            message: `${t('invalidCode')}`,
          })
        },
        onSuccess: () => {
          form.setValue('steps', {
            totalSteps: 4,
            currentStep: 3,
          })
          setStep('confirm')
        },
      }
    )
  }

  useEffect(() => {
    const twoFaCode = form.getValues('twoFaField')
    if (twoFaCode?.length === TWO_FA_REQUIRED_LENGTH) {
      handleContinue()
    }
  }, [form.getValues('twoFaField')])

  return (
    <Box>
      <ModalNavBar
        title={t('payments.withdrawals.confirmProtocol')}
        onBack={
          !isPending &&
          (() => {
            setStep('form')
            form.setValue('address', null)
          })
        }
      />
      <ModalBody>
        <Callout state="info" message={t('payments.withdrawals.calloutAddingProtocol')} />
        <Text size="sm">{t('authCodeConfirm')}</Text>
        {isPending ? (
          <Box flex flexJustifyCenter>
            <Loader size="medium" />
          </Box>
        ) : (
          <Input2FA
            onChange={value => {
              form.setValue('twoFaField', value)
            }}
            error={!!form.formState?.errors?.twoFaField}
            errorText={form.formState?.errors?.twoFaField?.message}
            onCompleteFill={() => form.clearErrors('twoFaField')}
          />
        )}
      </ModalBody>
    </Box>
  )
}

export default ConfirmProtocol
