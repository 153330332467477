import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import EmptyContainer from 'components/EmptyContainer'
import { PathNames } from 'constants/General'

export const WalletNotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRedirectToWalletList = () => {
    navigate(`${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`)
  }
  return (
    <EmptyContainer>
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="font-medium text-lg">{t('walletNotFound')}</div>
        <div className="text-sm text-gray-08">{t('returnWalletHome')}</div>
        <Button
          onClick={handleRedirectToWalletList}
          iconElement={<Icon name="JumpBackIcon" color="white" />}
        >
          {t('return')}
        </Button>
      </div>
    </EmptyContainer>
  )
}
