import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Box, {
  Box as Description,
  Box as Form,
  Box as IPContainer,
  Box as Wrapper,
} from '@node-space/storybook-components/dist/Box'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAddApiKeyMutation } from 'hooks/mutations'
import { ApiKeyCreateResponse, ApiKeyRequestForm } from 'types/apiKeys'
import { BaseErrorResponse } from 'types/beneficiaries'

type ApiKey2FAModalProps = {
  setShowSuccessModal: (response: ApiKeyCreateResponse) => void
  onBack: () => void
  payload: ApiKeyRequestForm
}

const ApiKey2FAModal = ({ setShowSuccessModal, onBack, payload }: ApiKey2FAModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { profile } = useProfileContext()

  const [otc, setOtc] = useState('')
  const [errorOtpText, setErrorOtpText] = useState('')
  const [requestError, setRequestError] = useState('')
  const { mutate, isPending } = useAddApiKeyMutation()

  const mappedReq = (payload: ApiKeyRequestForm, otc: string) => {
    const roles = new Set(profile.roles)

    if (payload?.withdrawals) {
      roles.add('ROLE_WITHDRAWAL')
    }

    if (payload?.payroll) {
      roles.add('ROLE_MPS_PAYROLL')
    }

    const allowedIps = payload?.allowedIps
      .filter(({ value }) => value !== '')
      .map(({ value }) => value)

    return {
      description: payload?.description,
      totp: otc,
      roles: Array.from(roles),
      allowedIps: allowedIps?.length ? allowedIps?.join(',') : null,
    }
  }

  const onSuccess = async () => {
    mutate(mappedReq(payload, otc), {
      onSuccess: resp => {
        queryClient.invalidateQueries({ queryKey: ['apiKeys'] })
        setShowSuccessModal(resp)
        setErrorOtpText('')
      },
      onError: (error: BaseErrorResponse) => {
        setErrorOtpText(t('incorrectOtpCode'))
        if (error?.status === 400) {
          setRequestError(t('manageApiKeys.invalidRequest'))
        } else if (error.status !== 403) {
          logSentryError(
            'Error from ApiKey2FAModal V2 - createApiKey',
            new Error(JSON.stringify(error?.data?.errorList))
          )
          setErrorOtpText(t('oopsSomethingWentWrong'))
        } else {
          setRequestError(t('oopsSomethingWentWrong'))
        }
      },
    })
  }

  useEffect(() => {
    if (otc?.length === TWO_FA_REQUIRED_LENGTH) {
      onSuccess()
    }
  }, [otc])

  const mappedIps = payload?.allowedIps?.map(ip => (
    <span key={ip?.label} className="text-tiny ">
      {ip?.label}
    </span>
  ))

  const getAdditionallyEnabledFeatures = () => {
    const features: string[] = []
    payload?.withdrawals && features.push(t('manageApiKeys.enableWithdrawalsLabel'))

    if (!features?.length) {
      return '-'
    } else {
      return features?.length > 1 ? features?.join(', ') : features?.toString()
    }
  }

  return (
    <Modal visible closeModal={() => false}>
      <ModalNavBar
        title={t('manageApiKeys.confirmGeneratingNewAPIKey')}
        onBack={!isPending && (() => onBack())}
      />
      <Wrapper padding={24} gapY={24} flex direction="col">
        <Description flex direction="col" borderRadius={4} border="gray" paddingX={16}>
          <Box key={payload?.description} width={'full'}>
            <SummaryRow label={t('name')} description={payload?.description} />
            <SummaryRow
              label={t('manageApiKeys.allowedIpAddressInputLabel')}
              renderDescription={
                mappedIps?.length ? (
                  <IPContainer flex flexWrap gapY={8} gapX={16}>
                    {mappedIps}
                  </IPContainer>
                ) : (
                  <>-</>
                )
              }
            />
            <SummaryRow
              label={t('manageApiKeys.additionallyEnabledFor')}
              description={getAdditionallyEnabledFeatures()}
            />
          </Box>
        </Description>

        <Form flex direction="col">
          <Text size="sm">{t('authCodeConfirm')}</Text>
          {isPending ? (
            <Form paddingT={24} flex justifyContent="center">
              <Loader size="medium" />
            </Form>
          ) : (
            <Input2FA
              testid="apikey-2fa"
              disabled={isPending}
              onCompleteFill={value => {
                setOtc(value)
              }}
              error={!!errorOtpText}
              errorText={errorOtpText}
            />
          )}
        </Form>
        {requestError && <Text color="error">{requestError}</Text>}
      </Wrapper>
    </Modal>
  )
}

export default ApiKey2FAModal
