import React, { createContext } from 'react'
import { AccountType } from 'types/accounts'

export interface AccountsData {
  accounts: AccountType[]
  currentAccount: AccountType
  isVerifiedBusinessAccount: boolean
  isDeveloperAccount: boolean
}

export interface AccountsContextType extends AccountsData {
  isLoading: boolean
  shouldShowUKInfoBanner: boolean
}

export interface AccountContextProviderProps {
  accountsData: AccountsData
  isLoading: boolean
  children: React.ReactNode
  shouldShowUKInfoBanner: boolean
}

export const AccountsContext = createContext<AccountsContextType>({
  accounts: [],
  currentAccount: {
    id: null,
    name: '',
    isCurrent: false,
    reference: null,
  },
  isVerifiedBusinessAccount: false,
  isDeveloperAccount: false,
  isLoading: false,
  shouldShowUKInfoBanner: false,
})

export const AccountsContextProvider = ({
  accountsData,
  isLoading,
  children,
  shouldShowUKInfoBanner,
}: AccountContextProviderProps) => {
  const { accounts, currentAccount, isVerifiedBusinessAccount, isDeveloperAccount } =
    accountsData || {}

  const value: AccountsContextType = {
    accounts,
    currentAccount,
    isVerifiedBusinessAccount,
    isDeveloperAccount,
    isLoading,
    shouldShowUKInfoBanner,
  }

  return <AccountsContext.Provider value={value}>{children}</AccountsContext.Provider>
}
