import { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { validateIban } from 'services/BeneficiaryService'
import { BaseErrorResponse } from 'types/beneficiaries'
import { MappedErrorMessageState } from 'types/errors'
import { QueryMetaType } from 'types/reactQuery'

export const useIbanValidation = (
  accountNumber: string,
  accountNumberFormat: string,
  setRequestError: Dispatch<SetStateAction<MappedErrorMessageState>>,
  enabled = false
) => {
  const meta: QueryMetaType = {
    excludedErrorCodes: [400],
    onErrorAdditional: (error: BaseErrorResponse) => {
      setRequestError({
        errorCode: error.status,
        show: true,
      })
    },
    errorAdditionalData: { accountNumber, accountNumberFormat },
  }

  return useQuery({
    queryKey: reactQueryKeys.validateIban({ accountNumber, accountNumberFormat }),
    queryFn: () => validateIban({ accountNumber, accountNumberFormat }),
    enabled: enabled,
    gcTime: 0,
    meta,
  })
}
