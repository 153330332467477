import React from 'react'
import { useTranslation } from 'react-i18next'
import { Callout, Loader } from '@node-space/storybook-components'
import { ProtocolContextProvider } from 'contexts/protocolContext'
import { useWalletContext } from 'hooks/context'
import { usePrepareNewlyCreatedCryptoWallet } from 'pages/WalletDetails/hooks/usePrepareNewlyCreatedCryptoWallet'
import { CopyAllDetailsCrypto } from './CopyAllDetailsCrypto'
import { ProtocolsRadioButtons } from './ProtocolsRadioButtons'
import { WalletAddressDetails } from './WalletAddressDetails'
import { WalletQrCode } from './WalletQrCode'
import { WarningMessage } from './WarningMessage'

export const CryptoExtendedDetails = () => {
  const wallet = useWalletContext()
  const { isError, isPending } = usePrepareNewlyCreatedCryptoWallet()
  const { t } = useTranslation()
  if (isPending) {
    return (
      <div className="flex justify-center">
        <Loader theme="dark" size="large" />
      </div>
    )
  }
  if (isError) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }
  return (
    <ProtocolContextProvider wallet={wallet}>
      <div
        data-testid="crypto-extended-details"
        className="bg-gray-01 p-11 border border-gray-03 max-w-3xl rounded"
      >
        <div className="flex flex-col sm:flex-row gap-6 pb-9 mr-12">
          <WalletQrCode />
          <div className="self-center">
            <ProtocolsRadioButtons />
            <WalletAddressDetails />
          </div>
        </div>
        {wallet.lookup && <CopyAllDetailsCrypto />}
        <WarningMessage />
      </div>
    </ProtocolContextProvider>
  )
}
