import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { FiatPayout } from 'pages/MassPayouts/types'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getMassFiatPayoutsList } from 'services/MasspayoutService'
import { WithPaginationApiResponse } from 'types/pagination'
import { API_PAGINATION_LIMIT, API_PAGINATION_OFFSET, withPaginationPaging } from 'utils/pagination'

export interface GetMassFiatPayoutsListProps {
  page: number
  size?: number
  enabled?: boolean
}

export const useGetMassFiatPayoutsListQuery = ({
  size = API_PAGINATION_LIMIT,
  page = API_PAGINATION_OFFSET,
  enabled = false,
}: GetMassFiatPayoutsListProps) => {
  return useQuery({
    queryKey: reactQueryKeys.massFiatPayoutsList(),
    queryFn: () => getMassFiatPayoutsList({ size, page }),
    enabled,
    gcTime: 30000,
    refetchInterval: enabled ? 30000 : false, // Poll every 30 seconds if enabled
    placeholderData: keepPreviousData,
    select: (res: WithPaginationApiResponse<FiatPayout[]>) => {
      //  Transform the result to removing default Pageable pagination and adding Paging object
      const fiatBatchWithPagination = withPaginationPaging<FiatPayout[]>(res)
      return fiatBatchWithPagination
    },
  })
}
