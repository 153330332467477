import { useMemo } from 'react'
import { useCapabiltiesQuery } from 'hooks/queries/Capabilities/useCapabiltiesQuery'
import { Capabilities, CapabilitiesScope } from 'types/capabilities/capabilities'
import { WalletCapabilities, WalletType } from 'types/types'

const useIsB2cPayoutsEnabled = (wallet: WalletType) => {
  const isWalletB2cPayoutsEnabledDefault = wallet?.capabilities?.includes(
    WalletCapabilities.B2C_PAYOUTS
  )

  const {
    data: walletB2cCapability,
    isPending: isFetchingCapability,
    isError: isFetchingCapabilityError,
  } = useCapabiltiesQuery(
    {
      subjectId: wallet?.lsid,
      scope: CapabilitiesScope.WALLET,
      group: Capabilities.FIAT_PAYOUTS_B2C.group,
      name: Capabilities.FIAT_PAYOUTS_B2C.name,
    },
    !!wallet
  )

  const capabilityValue = useMemo(() => {
    if (isFetchingCapabilityError) return isWalletB2cPayoutsEnabledDefault

    if (!isFetchingCapability) {
      return !walletB2cCapability
        ? isWalletB2cPayoutsEnabledDefault
        : walletB2cCapability?.value?.toString() === 'true'
    }

    return false
  }, [walletB2cCapability, isFetchingCapability, isFetchingCapabilityError])

  return {
    isB2cPayoutEnabled: capabilityValue,
    isError: isFetchingCapabilityError,
    isLoading: isFetchingCapability,
  }
}

export default useIsB2cPayoutsEnabled
