import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecaptchaScoreError } from 'recaptcha/RecaptchaScoreError'
import { RecaptchaTokenError } from 'recaptcha/RecaptchaTokenError'
import { RecaptchaProps } from 'recaptcha/types'
import { isRecapthcaLowScoreError } from 'recaptcha/utils'
import { Button } from '@node-space/storybook-components/dist/Button'
import Input2FA from '@node-space/storybook-components/dist/Input2FA'
import { logError } from '@node-space/utils'
import { SubmitError } from 'components/Form/SubmitError'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useLoginMutation } from 'hooks/mutations'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ErrorData, WorkspaceListItemId } from 'types/types'

interface TwoFactorAuthProps {
  loginData: PostLoginReq
  recaptcha: RecaptchaProps
  setLoginData: React.Dispatch<React.SetStateAction<PostLoginReq>>
  setRequiresWorkspaceSelection: (workspaces: [WorkspaceListItemId]) => Promise<void>
  setSuccessfulLogin: () => Promise<void>
}

const TwoFactorAuth = ({
  loginData,
  recaptcha,
  setLoginData,
  setRequiresWorkspaceSelection,
  setSuccessfulLogin,
}: TwoFactorAuthProps) => {
  const { t } = useTranslation()

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ReactNode>('')

  const { isLoading: isProfileLoading } = useProfileContext()
  const { mutate: postLogin, isPending: isLoadingSubmitRequest } = useLoginMutation()

  const form = useForm<PostLoginReq>({
    defaultValues: {
      ...loginData,
      otp: '',
    },
  })
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const [twoFactorCode, setTwoFactorCode] = useState('')
  const [twoFactorCodeError, setTwoFactorCodeError] = useState('')
  const handleTwoFactorCodeChange = useCallback(
    (value: string) => {
      setTwoFactorCode(value)
      setTwoFactorCodeError('')
    },
    [twoFactorCodeError, setTwoFactorCodeError]
  )

  const isLoading = useMemo(
    () => isProfileLoading || recaptcha?.isLoading || isSubmitting || isLoadingSubmitRequest,
    [isProfileLoading, recaptcha?.isLoading, isSubmitting, isLoadingSubmitRequest]
  )

  const onSubmit = async (values: PostLoginReq) => {
    setShowError(false)
    setErrorMessage('')
    const recaptchaToken = await recaptcha?.getToken()

    if (!recaptchaToken) {
      setErrorMessage(<RecaptchaTokenError />)
      setShowError(true)
      return
    }

    const payload: PostLoginReq = {
      ...values,
      recaptcha: recaptchaToken,
      otp: twoFactorCode,
    }

    postLogin(payload, {
      onSuccess: async () => {
        await setSuccessfulLogin()
      },
      onError: async (error: BaseErrorResponse) => {
        const errorMessage = error?.data?.message
        if (error.status === 409) {
          setLoginData(payload)
          await setRequiresWorkspaceSelection(error.data as [WorkspaceListItemId] & ErrorData)
        } else if (error.status === 412) {
          setTwoFactorCodeError(t('2faRejectedError'))
        } else {
          if (isRecapthcaLowScoreError(errorMessage)) {
            setErrorMessage(<RecaptchaScoreError />)
          } else {
            setErrorMessage(t('errorMessages.login.default'))
            if (error.status !== 401) {
              logError(
                `Error from TwoFactorAuth - postLogin${errorMessage ? ` - ${errorMessage}` : ''}`,
                error,
                null,
                'fatal'
              )
            }
          }
          setShowError(true)
        }
      },
    })
  }

  return (
    <LoginPageLayout title={t('2faTitle')} description={t('2faDescription')}>
      {showError && <SubmitError>{errorMessage}</SubmitError>}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <Input2FA
          autoFocus
          testid="otp-field"
          disabled={isLoading}
          error={!!twoFactorCodeError}
          errorText={twoFactorCodeError}
          onChange={handleTwoFactorCodeChange}
        />
        <Button
          loading={isLoading}
          disabled={!!twoFactorCodeError || twoFactorCode?.length < TWO_FA_REQUIRED_LENGTH}
          testid="2fa-submit"
          type="submit"
          full
        >
          {t('submit')}
        </Button>
      </form>
    </LoginPageLayout>
  )
}

export default TwoFactorAuth
