import React from 'react'
import { Box as Column, Box as OuterWrapper } from '@node-space/storybook-components/dist/Box'
import { Search } from '@node-space/storybook-components/dist/Search'

const SearchFields = ({
  searchByTransactionHash,
  t,
  queryPaymentsLinkBy,
  searchByRef,
  onSearchByRefChanged,
  onSearchByTransactionHash,
}) => {
  return (
    <Column className="flex w-full mr-3 gap-4">
      <OuterWrapper testid="filter-search-field-wrapper" background="white" flexSize="auto">
        <Search
          disabled={!!searchByTransactionHash}
          placeholderText={t('payments.searchByReference')}
          searchTerm={queryPaymentsLinkBy || searchByRef || ''}
          size="sm"
          testid="filter-search-field"
          onChange={e => onSearchByRefChanged(e?.target.value)}
        />
      </OuterWrapper>
      <OuterWrapper testid="filter-search-field-wrapper" background="white" flexSize="auto">
        <Search
          disabled={!!queryPaymentsLinkBy || !!searchByRef}
          placeholderText={t('payments.searchByHash')}
          searchTerm={searchByTransactionHash}
          size="sm"
          testid="filter-search-field-by-transaction-hash"
          onChange={e => onSearchByTransactionHash(e?.target.value)}
        />
      </OuterWrapper>
    </Column>
  )
}

export default SearchFields
