import { requestApi } from 'api/api'
import { AccountUser, AccountUserGroup } from 'types/accounts'
import { AggregatedPaymentMethod, BusinessData, PaymentMethodType } from 'types/BankAccountsDetails'
import { SouthAfricanBank } from 'types/southAfricanBanks'
import { AccountPreferenceKey, FormFieldsAccountUser, Preferences } from 'types/types'
import { VerificationRequestStatus } from 'types/verification'

export const getSouthAfricanBanks = (): Promise<SouthAfricanBank[]> => {
  return requestApi({
    url: '/bank/ZA',
    method: 'GET',
  })
}

export const getBankAccounts = (
  max = 0,
  pagination = false
): Promise<AggregatedPaymentMethod[]> => {
  return requestApi({
    url: '/payment-method',
    method: 'GET',
    params: {
      max,
      pagination,
    },
  })
}

export const getPaymentMethodTypes = (): Promise<PaymentMethodType[]> => {
  return requestApi({
    url: '/payment-method-type',
    method: 'GET',
  })
}

export const getAccountUsers = (): Promise<AccountUser[]> => {
  return requestApi({
    url: '/account/user',
    method: 'GET',
    params: {
      max: 100,
      offset: 0,
    },
  })
}

export const getAccountUserGroups = (): Promise<AccountUserGroup[]> => {
  return requestApi({
    url: '/account/user/groups',
    method: 'GET',
    params: {
      order_by: 'priority',
      direction: 'asc',
    },
  })
}

export const getBusinessData = (): Promise<BusinessData> => {
  return requestApi({
    url: '/business/account/business-details',
    method: 'GET',
  })
}

export const createAccountUser = (formData: FormFieldsAccountUser): Promise<unknown> => {
  return requestApi({
    url: '/account/user',
    method: 'POST',
    data: {
      ...formData,
    },
  })
}

export const updateAccountUser = (
  userID: number,
  formData: FormFieldsAccountUser
): Promise<unknown> => {
  return requestApi({
    url: `/account/user/${userID}`,
    method: 'PUT',
    data: {
      ...formData,
    },
  })
}

export const deleteAccountUser = (userID: number): Promise<unknown> => {
  return requestApi({
    url: `/account/user/${userID}`,
    method: 'DELETE',
  })
}

export const getAccountPreferences = (): Promise<Preferences> => {
  return requestApi({
    url: '/account/preference',
    method: 'GET',
  })
}

export const getVerificationRequestStatus = (): Promise<VerificationRequestStatus> => {
  return requestApi({
    url: '/business/account/verification-request-status',
    method: 'GET',
  })
}

export const detectCountry = () => {
  return requestApi({
    url: '/country/detect',
    method: 'GET',
    authenticated: false,
  })
}

export const putShowVerifiedBanner = (value: boolean): Promise<VerificationRequestStatus> => {
  return requestApi({
    url: '/account/preference/showVerifiedBanner',
    method: 'PUT',
    data: {
      value,
    },
  })
}

export const putAccountPreference = async <T>({
  preferenceKey,
  value,
}: {
  preferenceKey: AccountPreferenceKey
  value: T
}) => {
  return requestApi({
    url: `/account/preference/${preferenceKey}`,
    method: 'PUT',
    data: {
      value,
    },
  })
}
