import { identify, Identify, init, track, Types } from '@amplitude/analytics-browser'

const amplitudeToken = process.env.AMPLITUDE_TOKEN
const isEnabled = !!amplitudeToken
const isDebugEnabled = false
const identifyObj = new Identify()

isEnabled &&
  init(amplitudeToken, null, {
    defaultTracking: {
      sessions: true,
      pageViews: false,
      formInteractions: false,
      fileDownloads: false,
    },
    ...(!!isDebugEnabled && { logLevel: Types.LogLevel.Debug }),
  })

const actions = {
  track: (eventInput: string | Types.BaseEvent, eventProperties = {}, eventOptions = {}) =>
    isEnabled && track(eventInput, eventProperties, eventOptions),
  trackAsync: (
    eventInput: string | Types.BaseEvent,
    eventProperties = {},
    eventOptions = {}
  ): Promise<Types.Result> => isEnabled && track(eventInput, eventProperties, eventOptions).promise,
  identify: (userInfo = {}) => {
    if (isEnabled) {
      identify(identifyObj, userInfo)
    }
  },
  set: (key: string, value: string | number | boolean) => {
    if (isEnabled) {
      identifyObj.set(key, value)
    }
  },
}

export const Amplitude = actions
