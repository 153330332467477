import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as OuterWrapper } from '@node-space/storybook-components/dist/Box'
import { Select } from '@node-space/storybook-components/dist/Select'
import { Text } from '@node-space/storybook-components/dist/Text'
import useFilterTag from 'components/FilterBar/hooks/useFilterTag'
import { PaymentTag } from 'types/payments'

interface FilterTagProps {
  onPaymentTagChange: (value: string) => void
}

const FilterTag = ({ onPaymentTagChange }: FilterTagProps) => {
  const { t } = useTranslation()

  const { tag } = useFilterTag()

  const paymentTagOptions = [
    { value: PaymentTag.ALL, label: t(PaymentTag.ALL.toLowerCase()) },
    { value: PaymentTag.ONHOLD, label: t(PaymentTag.ONHOLD.toLowerCase()) },
  ]

  const onTagChange = (tag: PaymentTag) => {
    onPaymentTagChange(tag)
  }

  return (
    <OuterWrapper testid="filter-tag-field-wrapper" className="col-span-6">
      <Text size="sm" weight="medium" className="mb-1">
        {t('tag')}
      </Text>
      <Select
        data-testid="filter-tag-field"
        value={tag}
        options={paymentTagOptions}
        onChange={onTagChange}
      />
    </OuterWrapper>
  )
}

export default FilterTag
