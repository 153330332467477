import { requestApi } from 'api/api'
import { CurrencyPreference } from 'types/types'

export const changePassword = (
  currentPassword: string,
  password: string,
  confirmPassword: string
): Promise<unknown> => {
  return requestApi({
    url: '/user/password',
    method: 'PUT',
    data: {
      currentPassword,
      password,
      confirmPassword,
    },
  })
}

export const changeCurrencyPref = (value: string): Promise<CurrencyPreference> => {
  return requestApi({
    url: '/account/preference/currency',
    method: 'PUT',
    data: {
      value,
    },
  })
}
