import React, { Dispatch, SetStateAction, useEffect } from 'react'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { AlignActions, Modal, ModalActions } from '@node-space/storybook-components/dist/Modal'
import { SuccessContainer } from '@node-space/storybook-components/dist/SuccessContainer'
import { Text, TextSize } from '@node-space/storybook-components/dist/Text'
import CopyUrl from 'components/CopyUrl'
import { useCreatedPaymentContext } from 'pages/Payments/hooks/useCreatedPaymentContext'
import { PaymentType } from 'types/payments'
import { TextAlignType } from 'types/text'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { Amp } from 'utils/tracker'

interface PaymentSuccessModalProps {
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>
  redirectUrl: string
}

const PaymentSuccessModal = ({ setShowSuccessModal, redirectUrl }: PaymentSuccessModalProps) => {
  const { t } = useTranslation()

  const { createdPayment } = useCreatedPaymentContext()
  const successMessage =
    createdPayment?.type === PaymentType?.OUT
      ? t('paymentEmailTheLinkPayOut')
      : t('paymentEmailTheLinkPayIn')

  useEffect(() => {
    if (createdPayment?.type)
      Amp.track(AmplitudeEvent.EOF_PAYMENT, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
        displayAmount: createdPayment?.displayCurrency?.amount,
        walletAmount: createdPayment?.walletCurrency?.amount,
        merchantId: createdPayment?.merchantId,
        flow: createdPayment?.type, // either IN or OUT
        displayCurrency: createdPayment?.displayCurrency?.currency,
        reference: createdPayment?.reference,
        expiryDate: dayjs(createdPayment?.expiryDate).format('YYYY-MM-DD HH:mm:ss'),
        returnUrl: createdPayment?.redirectUrl,
        actualCurrency: createdPayment?.walletCurrency?.currency,
      })
  }, [])

  return (
    <Modal
      testid="payment-success-modal-wrapper"
      visible={true}
      closeModal={() => setShowSuccessModal(false)}
    >
      <SuccessContainer
        test-id="payment-success-modal-container"
        headline={t('paymentCreated', {
          type:
            createdPayment?.type === PaymentType?.OUT
              ? startCase(t('payments.out'))
              : startCase(t('payments.in')),
        })}
        hasDescription={false}
      >
        <CopyUrl testid="payment-success-payment-link" redirectUrl={redirectUrl} />
        <Text
          testid="payment-success-text"
          size={TextSize.BASE}
          color={'disabled'}
          align={TextAlignType.CENTER}
        >
          {successMessage}
        </Text>
      </SuccessContainer>
      <ModalActions
        actions={
          [
            {
              children: t('done'),
              type: 'button',
              onClick: () => setShowSuccessModal(false),
              testid: 'payment-success-text-done-btn',
            },
          ] as ButtonProps[]
        }
        alignActions={AlignActions.CENTER}
      />
    </Modal>
  )
}

export default React.memo(PaymentSuccessModal)
