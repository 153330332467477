import { TFunction } from 'react-i18next'
import { InflowPaymentTypes } from 'types/payments'
import { paymentDirections } from 'types/types'
import { formatTotal } from './utils'
import { TransactionContent } from './SearchTransactionTable'

export const transformSearchResultsByPaymentType = (
  transaction: TransactionContent,
  t?: TFunction<string, unknown>
) => {
  let fromCurrency: string
  let toCurrency: string
  let amount: number | string
  let fromAmount: number | string
  let amountWithoutCurrency: number | string

  const resolvedPaymentDirection =
    InflowPaymentTypes.includes(transaction?.type) ||
    InflowPaymentTypes.includes(transaction?.description)
      ? paymentDirections.IN
      : paymentDirections.OUT

  const copiesPerKeyTransactionTypes = !!t && {
    paymentIn: {
      amountPaid: t('wallets.searchTransaction.paymentInAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    paymentOut: {
      amountPaid: t('wallets.searchTransaction.paymentOutAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentOutAmountReceived'),
    },
    payIn: {
      amountPaid: t('wallets.searchTransaction.paymentInAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    payOut: {
      amountPaid: t('wallets.searchTransaction.paymentOutAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentOutAmountReceived'),
    },
    underPayment: {
      amountPaid: t('wallets.searchTransaction.paymentInAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    latePayment: {
      amountPaid: t('wallets.searchTransaction.paymentInAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    overPayment: {
      amountPaid: t('wallets.searchTransaction.paymentInAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    userDeposit: {
      amountPaid: t('wallets.searchTransaction.paymentOutAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    channelDeposit: {
      amountPaid: t('wallets.searchTransaction.paymentOutAmountPaid'),
      amountReceived: t('wallets.searchTransaction.paymentInAmountReceived'),
    },
    channelDepositFee: {
      amountPaid: t('wallets.searchTransaction.paymentOutAmountPaid'),
      walletAddress: t('wallets.searchTransaction.channelDepositFeeAmount'),
    },
    withdrawalFailed: {
      amountPaid: t('wallets.searchTransaction.userWithdrawalFailedAmount'),
      amountReceived: t('wallets.searchTransaction.userWithdrawalFailedAmount'),
    },
    userWithdrawal: {
      amountPaid: t('wallets.searchTransaction.userWithdrawalAmount'),
      amountReceived: t('wallets.searchTransaction.userWithdrawalAmount'),
    },
  }

  const channelsTransactionTypes = {
    CHANNEL_DEPOSIT: 'channelDeposit',
    CHANNEL_DEPOSIT_FEE: 'channelDepositFee',
  }

  const isChannelsDeposit = !!(
    transaction?.type === channelsTransactionTypes.CHANNEL_DEPOSIT ||
    transaction?.description === channelsTransactionTypes.CHANNEL_DEPOSIT ||
    transaction?.type === channelsTransactionTypes.CHANNEL_DEPOSIT_FEE ||
    transaction?.description === channelsTransactionTypes.CHANNEL_DEPOSIT_FEE
  )

  switch (transaction?.direction) {
    case paymentDirections.IN:
      // Payment In - Processing Fee, Exact (not over, not under etc)
      fromCurrency = transaction?.fromCurrency ?? ''
      toCurrency = transaction?.toCurrency ?? ''
      amount = Object.is(transaction?.amount, null)
        ? ''
        : formatTotal(transaction?.amount, toCurrency ?? '')
      fromAmount = transaction?.fromAmount && `${transaction?.fromAmount} ${fromCurrency}`
      amountWithoutCurrency = transaction?.amount
      // TODO:  add condition for latePayment once product defines expectations / accpetance criteria,
      break
    case paymentDirections.OUT:
      // Payment Out - Processing Fee, Exact
      fromCurrency = transaction?.fromCurrency ?? ''
      toCurrency = transaction?.toCurrency ?? ''
      amount = Object.is(transaction?.amount, null)
        ? ''
        : formatTotal(transaction?.amount, fromCurrency)
      fromAmount = transaction?.toAmount && `${transaction?.toAmount} ${toCurrency}`
      amountWithoutCurrency = transaction?.amount
      break
    default:
      fromAmount =
        transaction?.fromAmount && `${transaction?.fromAmount} ${transaction?.fromCurrency}`
      amountWithoutCurrency = transaction?.amount
      if (isChannelsDeposit) {
        fromCurrency = transaction?.fromCurrency
        toCurrency = transaction?.toCurrency
        amount = Object.is(transaction?.amount, null)
          ? ''
          : formatTotal(transaction?.amount, toCurrency ?? fromCurrency ?? '')
      } else {
        fromCurrency = transaction?.fromCurrency ?? ''
        toCurrency = transaction?.toCurrency ?? ''
        amount = Object.is(transaction?.amount, null)
          ? ''
          : formatTotal(transaction?.amount, fromCurrency)
      }
  }

  return {
    isChannelsDeposit,
    fromCurrency,
    toCurrency,
    amount,
    fromAmount,
    amountWithoutCurrency,
    resolvedPaymentDirection,
    copiesPerKeyTransactionTypes,
  }
}
