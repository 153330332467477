import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Accordion } from '@node-space/storybook-components/dist/Accordion'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { useDevXContext } from 'hooks/useDevXContext'
import { isSandbox } from 'utils/environment'
import { Amp } from 'utils/tracker'
import { AmplitudeEvent, AmplitudeEventType } from 'utils/amplitude/amplitudeEvents'
import { StepKeys } from 'pages/Dashboard/constants'
import { GetStartedAccordionItem, StepKey } from 'pages/Dashboard/types'
import { useDevXGetStartedStepTracking } from 'hooks/useDevXGetStartedStepTracking'
import { PathNames } from 'constants/General'

export interface GetStartedStepsProps {
  isLoading: boolean
  canViewApiKeys: boolean
  hasApiKeys: boolean
  hasMerchants: boolean
  hasPayments: boolean
}

export const GetStartedSteps = ({
  isLoading,
  canViewApiKeys,
  hasApiKeys,
  hasMerchants,
  hasPayments,
}: GetStartedStepsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goLiveUrl = `${process.env.PROD_URL}${PathNames.KYB_SIGNUP_URL}`

  const { isDevXGoLiveReady } = useDevXContext()

  const { applyStepTracking, isStepCompleted, getStepState, onCompleteStep, onCompleteSteps } =
    useDevXGetStartedStepTracking(canViewApiKeys)

  const trackDevXEvent = (eventLabel: AmplitudeEventType) => {
    isSandbox && Amp.track(eventLabel)
  }

  const isLoadingStepStatus = useMemo(
    () => (applyStepTracking ? isLoading : false),
    [applyStepTracking, isLoading]
  )

  const createApiKeyAccordionItem: GetStartedAccordionItem = {
    name: 'createApiKey',
    title: t('getStarted.generateYourAPIKeys'),
    iconName: 'ApiIcon',
    children: t('getStarted.generateYourAPIKeysDescription'),
    actions: [
      {
        label: t('manageApiKeys.generateAPIKeyBtn'),
        testid: 'generate-api-key-btn',
        onClick: () => {
          trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_GENERATE_API_KEY)
          navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_API_KEYS}`)
        },
      },
    ],
  }

  const accordionItems: GetStartedAccordionItem[] = [
    {
      name: 'useApiKey',
      title: t('getStarted.bvnkApis'),
      iconName: 'SupportIcon',
      children: t('getStarted.bvnkApisDescription'),
      actions: [
        {
          label: t('getStarted.readDocs'),
          testid: 'read-api-docs-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_READ_DOCS)
            onCompleteStep(StepKeys.USE_API_KEY)
            window.open(`${process.env.DEVELOPER_DOCS}/creating-your-api-keys`)
          },
        },
        {
          label: t('getStarted.readApiRef'),
          testid: 'read-api-ref-docs-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_READ_API_REF)
            onCompleteStep(StepKeys.USE_API_KEY)
            window.open(`${process.env.API_REFERENCE}`)
          },
        },
      ],
    },
    {
      name: 'viewWallets',
      title: t('getStarted.viewYourWallets'),
      iconName: 'WalletIcon',
      children: t('getStarted.viewYourWalletsDescription'),
      actions: [
        {
          label: t('getStarted.viewWallets'),
          testid: 'view-wallets-btn',
          onClick: async () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_VIEW_WALLETS)
            await onCompleteStep(StepKeys.VIEW_WALLETS)
            navigate(`${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`)
          },
        },
        {
          label: t('learnMore'),
          testid: 'wallets-learn-more-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_LEARN_ABOUT_WALLETS)
            window.open(`${process.env.DEVELOPER_DOCS}/currencies-1`)
          },
        },
      ],
    },
    {
      name: 'createMerchant',
      title: t('createAMerchant'),
      iconName: 'MerchantIcon',
      children: t('getStarted.createMerchantDescription'),
      actions: [
        {
          label: t('createAMerchant'),
          testid: 'create-merchant-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_CREATE_MERCHANT)
            navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_MERCHANT_DETAILS}`)
          },
        },
        {
          label: t('learnMore'),
          testid: 'merchant-learn-more-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_MERCHANT_LEARN_MORE)
            window.open(`${process.env.DEVELOPER_DOCS}/creating-your-first-merchant`)
          },
        },
      ],
    },
    {
      name: 'createPayment',
      title: t('createAPayment'),
      iconName: 'PaymentIcon',
      children: t('getStarted.createPaymentDescription'),
      actions: [
        {
          label: t('createAPayment'),
          testid: 'create-payment-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_CREATE_PAYMENT)
            navigate(`${PathNames.PAYMENTS}${PathNames.PAYMENTS_CREATE}`)
          },
        },
      ],
    },
    {
      name: 'goLive',
      title: t('getStarted.howToGoLive'),
      iconName: 'AgreementIcon',
      children: (
        <>
          <p>
            {t('getStarted.goLiveDescription.verifyWithKYB')}{' '}
            <TextAction
              text={goLiveUrl}
              onClick={() => {
                trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_GO_LIVE)
                onCompleteStep(StepKeys.GO_LIVE)
                window.open(goLiveUrl)
              }}
            />
          </p>
          <p>{t('getStarted.goLiveDescription.contactSales')}</p>
        </>
      ),
      actions: [
        {
          label: t('contactSales'),
          testid: 'contact-sales-btn',
          onClick: () => {
            trackDevXEvent(AmplitudeEvent.DEVX_SANDBOX_CLICKED_CONTACT_SALES)
            onCompleteStep(StepKeys.GO_LIVE)
            window.open(`${process.env.CONTACT_SALES_URL}`)
          },
        },
      ],
    },
  ]

  if (canViewApiKeys) {
    accordionItems?.unshift(createApiKeyAccordionItem)
  }

  useEffect(() => {
    if (isDevXGoLiveReady && applyStepTracking && !isStepCompleted(StepKeys.GO_LIVE)) {
      onCompleteStep(StepKeys.GO_LIVE)
    }
  }, [isDevXGoLiveReady, applyStepTracking])

  useEffect(() => {
    if (!isLoading && applyStepTracking) {
      const dataSteps: Partial<Record<StepKey, boolean>> = {
        createApiKey: hasApiKeys,
        createMerchant: hasMerchants,
        createPayment: hasPayments,
      }
      const completedDataSteps: StepKey[] = Object.entries(dataSteps)
        ?.filter((step: [StepKey, boolean]) => step?.[1])
        ?.map((stepEntry: [StepKey, boolean]) => stepEntry?.[0])

      if (completedDataSteps?.length) {
        onCompleteSteps(completedDataSteps)
      }
    }
  }, [isLoading, applyStepTracking])

  return (
    <Box paddingT={16} paddingB={8}>
      <Accordion
        defaultOpen={accordionItems?.[0]?.name}
        items={accordionItems?.map(item => ({
          ...item,
          iconElement: !isLoadingStepStatus ? (
            <Icon name={item?.iconName} size="lg" color="inherit" />
          ) : (
            <Loader />
          ),
          iconState:
            applyStepTracking && !isLoadingStepStatus ? getStepState(item?.name as StepKey) : null,
        }))}
      />
    </Box>
  )
}
