import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPaymentSupportCases } from 'services/PaymentsService'
import { PaymentCaseType } from 'types/payments'
import { Paging } from 'types/types'

export const usePaymentSupportCasesQuery = (paging: Paging, status?: PaymentCaseType[]) => {
  return useQuery({
    queryKey: reactQueryKeys.paymentSupportCases(),
    queryFn: () => getPaymentSupportCases(paging, status),
    placeholderData: keepPreviousData,
    gcTime: 0,
    staleTime: Infinity,
  })
}
