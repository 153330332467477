import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPaymentCaseEmails } from 'services/PaymentsService'

export const usePaymentCaseEmailsQuery = (paymentCaseId: string) => {
  return useQuery({
    queryKey: reactQueryKeys.paymentCaseEmails(paymentCaseId),
    queryFn: () => getPaymentCaseEmails(paymentCaseId),
    enabled: !!paymentCaseId,
    gcTime: 0,
  })
}
