import React from 'react'
import { useWalletContext, useProtocolContext } from 'hooks/context'
import { QRCode } from '@node-space/storybook-components'

type WalletQRCodeProps = {
  address?: string
}

export const WalletQrCode = ({ address }: WalletQRCodeProps) => {
  const wallet = useWalletContext()
  const { currentProtocol } = useProtocolContext()
  const value = address
    ? address
    : currentProtocol?.address
    ? currentProtocol.address
    : wallet?.address
  return <QRCode value={value || ''} />
}
