import hotjar from '@hotjar/browser'
import { isProduction } from './environment'

isProduction &&
  hotjar.init(Number(process.env.HOTJAR_SITE_ID) || 0, Number(process.env.HOTJAR_V) || 0, {
    debug: false,
  })

const actions = {
  init: (siteId: string | number, version: number) =>
    isProduction && hotjar.init(Number(siteId), Number(version), { debug: true }),
  identify: (userId: string | number, userInfo = {}) =>
    isProduction && hotjar.identify(String(userId), userInfo),
  event: (actionName: string) => isProduction && hotjar.event(actionName),
}

export const Hotjar = actions
