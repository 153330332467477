import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { useOnceOffExportContext } from 'hooks/context/useOnceOffExportContext'
import { OnceOffExportSteps } from 'types/walletTransactionsExport'
import { OnceOffExportComplete } from './OnceOffExportComplete'
import { OnceOffExportForm } from './OnceOffExportForm'

interface OnceOffExportType {
  downloadAll: boolean
}

export const OnceOffExport = ({ downloadAll }: OnceOffExportType) => {
  const { t } = useTranslation()
  const { enableRecurringExports } = useFeatureFlags()
  const { step, exportFormat, setExportFormat, handleClose } = useOnceOffExportContext()

  const showModalNavBar = enableRecurringExports ? !downloadAll : !enableRecurringExports

  return (
    <>
      {step === OnceOffExportSteps.FORM && (
        <>
          {showModalNavBar && (
            <ModalNavBar
              title={t('walletsPage.exportTransactionNavBarTitle')}
              onClose={handleClose}
            />
          )}
          <OnceOffExportForm downloadAll={downloadAll} setExportFormat={setExportFormat} />
        </>
      )}
      {step === OnceOffExportSteps.COMPLETE && (
        <OnceOffExportComplete handleClose={handleClose} exportFormat={exportFormat} />
      )}
    </>
  )
}
