import React from 'react'
import { Box as SkeletonIcon } from '@node-space/storybook-components'

interface CellSkeletonProps {
  skeletalIcon?: boolean
}

export const TypeCellSkeleton = ({ skeletalIcon = true }: CellSkeletonProps) => {
  return (
    <div className="flex gap-2 items-center w-mediumSmall">
      {skeletalIcon && (
        <SkeletonIcon size={32} borderRadius="full" background="gray" className="animate-pulse" />
      )}
      <div className="h-3 rounded bg-gray-02 animate-pulse w-20" />
    </div>
  )
}
