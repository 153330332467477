import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Toggle } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import SimpleTableV2, { TableRowV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { logSentryError } from '@node-space/utils'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { Approval } from 'constants/General'
import { useManageApproversMutation } from 'hooks/mutations/useManageApproversMutation'
import { useToastContext } from 'hooks/useToastContext'
import { AccountUser } from 'types/accounts'
import { ComplexTooltip } from './ComplexTooltip'

interface TeamMemberTableProps {
  accountUsers: AccountUser[]
  currentEmailAddress: string
  onEditUserClick: (accountUser: AccountUser) => void
  onDeleteUserClick: (accountUser: AccountUser) => void
  invalidateAccountUsers?: () => void
  isFetchingAccountUsers?: boolean
  defaultNumberOfRows?: number | string
  canEdit?: boolean
  canManageApprover?: boolean
}

const tableRowClasses = 'py-5 px-1'

export const TeamMemberTable = ({
  accountUsers,
  currentEmailAddress,
  onEditUserClick,
  onDeleteUserClick,
  invalidateAccountUsers,
  isFetchingAccountUsers,
  defaultNumberOfRows,
  canEdit,
  canManageApprover,
}: TeamMemberTableProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const [complexTooltip, setComplexTooltip] = useState({
    isRoleTooltipDisplayed: false,
    isApproverTooltipDisplayed: false,
  })
  const [rowIndexIsLoading, setRowIndexIsLoading] = useState<number>()

  const { mutate: mutateManageApprovers, isPending: toggleApproverIsProcessing } =
    useManageApproversMutation()

  const showComplexTooltip = Object.values(complexTooltip).includes(true)

  const toggleApprover = (rowIndex: number) => {
    setRowIndexIsLoading(rowIndex)
    const updatedTeamMemberValues = [...accountUsers]

    const toggleApproverPayload = [
      {
        emailAddress: updatedTeamMemberValues?.[rowIndex]?.emailAddress,
        approver: !updatedTeamMemberValues?.[rowIndex]?.approver,
      },
    ]

    mutateManageApprovers(
      { updateUserRoleGroups: toggleApproverPayload },
      {
        onSuccess: () => {
          addToast({
            title: t('approvals.manageApproversSuccessToastMessage'),
            state: 'success',
            delay: 10,
          })
          invalidateAccountUsers()
          setRowIndexIsLoading(null)
        },
        onError: (error: unknown) => {
          addToast({
            title: t('oopsSomethingWentWrong'),
            state: 'error',
            delay: 10,
          })
          setRowIndexIsLoading(null)
          logSentryError('Error from TeamMemberTable - toggleApprovers ', error)
        },
      }
    )
  }

  const buildAccountUsersData = (): TableRowV2[] => {
    return accountUsers?.map((item, rowIndex) => {
      let groupList = 'N/A'
      if (item?.groups?.length) {
        groupList = item?.groups
          ?.filter(userGroup => userGroup?.name !== Approval.ACCOUNT_TRANSACTION_APPROVER)
          ?.map(group => {
            return group?.name
          })
          .join(', ')
      }

      return {
        rowData: [
          {
            cellData: item?.name,
            className: `${tableRowClasses} pl-4`,
          },
          {
            cellData: item?.emailAddress,
            className: `${tableRowClasses} pl-4`,
          },
          { cellData: groupList },
          {
            cellData: (
              <Box flex>
                <Toggle
                  disabled={
                    !canManageApprover ||
                    item?.emailAddress == currentEmailAddress ||
                    item?.isWorkspaceOwner
                  }
                  value={item?.approver || item?.isWorkspaceOwner}
                  onChange={() => toggleApprover(rowIndex)}
                />
                {rowIndex === rowIndexIsLoading && toggleApproverIsProcessing && (
                  <Loader size="small" />
                )}
              </Box>
            ),
            className: `${tableRowClasses}`,
          },
          {
            cellData:
              item?.emailAddress !== currentEmailAddress && canEdit ? (
                <TablePopoverMenu
                  stopPropagation
                  testid="team-member-popover-menu"
                  menuItems={[
                    {
                      iconName: 'EditIcon',
                      label: t('teamMember.changeUserRole'),
                      onClick: () => onEditUserClick(item),
                    },
                    {
                      iconName: 'TrashIcon',
                      label: t('delete'),
                      onClick: () => onDeleteUserClick(item),
                    },
                  ]}
                />
              ) : (
                ''
              ),
            className: `${tableRowClasses} pr-4`,
            align: 'end',
          },
        ],
      }
    })
  }

  const tableData = buildAccountUsersData()

  return (
    <>
      {showComplexTooltip && (
        <ComplexTooltip complexTooltip={complexTooltip} setComplexTooltip={setComplexTooltip} />
      )}
      {!tableData && !isFetchingAccountUsers ? (
        <StatusCard status="success" headline={t('noAccountUsersExist')} />
      ) : (
        <SimpleTableV2
          testid="team-member-table"
          columns={[
            { headerTitle: t('teamMember.title'), className: 'pl-4' },
            { headerTitle: t('email'), className: 'pl-4' },
            {
              headerTitle: (
                <Box flex>
                  {t('teamMember.role')}
                  <Button
                    onClick={() =>
                      setComplexTooltip({ ...complexTooltip, isRoleTooltipDisplayed: true })
                    }
                    noStyling
                    className="ml-2"
                  >
                    <Icon color="inherit" name="InfoCircleIcon" />
                  </Button>
                </Box>
              ),
            },
            {
              headerTitle: (
                <Box flex>
                  {t('teamMember.approver')}
                  <Button
                    onClick={() =>
                      setComplexTooltip({ ...complexTooltip, isApproverTooltipDisplayed: true })
                    }
                    noStyling
                    className="ml-2"
                  >
                    <Icon color="inherit" name="InfoCircleIcon" />
                  </Button>
                </Box>
              ),
            },
            { headerTitle: '' },
          ]}
          isLoading={isFetchingAccountUsers}
          numberOfRowsLoading={Number(defaultNumberOfRows)}
          tableData={tableData}
        />
      )}
    </>
  )
}
