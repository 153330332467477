import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box as Wrapper,
  Box as FormContainer,
  Modal,
  ModalNavBar,
  Input,
  ModalActions,
  ModalSize,
} from '@node-space/storybook-components'
import { DocumentUpload } from 'types/verification'
import UploadCaseDocument from './UploadCaseDocument'

interface UploadDocumentModalProps {
  onClose: () => void
  onUpload: (document: DocumentUpload) => void
}

export const UploadDocumentModal = ({ onClose, onUpload }: UploadDocumentModalProps) => {
  const { t } = useTranslation()

  const [caseDocument, setCaseDocument] = useState<DocumentUpload>(null)
  const [documentDescription, setDocumentDescription] = useState('')
  const [hasChanged, setHasChanged] = useState(false)

  const documentTypeError = useMemo(
    () =>
      (documentDescription?.length && !documentDescription?.trim()?.length) ||
      (hasChanged && !documentDescription?.length),
    [documentDescription, hasChanged]
  )

  const uploadDocument = () => {
    caseDocument.description = documentDescription

    onUpload(caseDocument)
    onClose()
  }

  return (
    <Modal size={ModalSize.lg} visible={true} closeModal={onClose}>
      <ModalNavBar title={t('documents.uploadADocument')} onClose={onClose} />
      <Wrapper tag="section" testid="upload-modal-wrapper">
        <FormContainer
          flex
          direction="col"
          paddingX={24}
          paddingT={8}
          paddingB={24}
          gapY={16}
          testid="upload-document-form"
        >
          <UploadCaseDocument file={caseDocument} setFile={setCaseDocument} />
          <Input
            value={documentDescription}
            onChange={e => {
              !hasChanged && setHasChanged(true)
              setDocumentDescription(e.target.value)
            }}
            label={t('formFields.documentDescription')}
            testid="documentDescription"
            placeholder={t('formFields.shortDescription')}
            error={documentTypeError}
            errorText={t('fieldRequired', { field: t('formFields.documentDescription') })}
          />
        </FormContainer>
        <ModalActions
          actions={[
            {
              children: t('documents.uploadDocument'),
              onClick: uploadDocument,
              disabled: !documentDescription?.trim()?.length || !caseDocument,
              type: 'submit',
              testid: 'uploadDocument',
            },
          ]}
        />
      </Wrapper>
    </Modal>
  )
}
