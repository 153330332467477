import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface CustomDateOption {
  name: string
  fromDate: string | number | dayjs.Dayjs | Date
  toDate: string | number | dayjs.Dayjs | Date
}

export interface CustomDateValueOptionsType {
  last30Days: CustomDateOption
  lastMonth: CustomDateOption
  last3Months: CustomDateOption
  customDateRange: CustomDateOption
}

interface CustomDateRadioOptionsType {
  label: string
  value: string
  secondaryLabel?: string
}

const todaysDate = dayjs()

const last30Days = todaysDate.subtract(30, 'day')

const getStartDateOfXMonth = (lastXMonthsNumericValue: 1 | 3): dayjs.Dayjs => {
  return todaysDate.subtract(lastXMonthsNumericValue, 'month').startOf('month')
}

const endOfLastMonth = todaysDate.subtract(1, 'month').endOf('month')

export const customDateValueOptions = (
  from?: string | number | dayjs.Dayjs | Date,
  to?: string | number | dayjs.Dayjs | Date
): CustomDateValueOptionsType => {
  return {
    last30Days: {
      name: 'last30Days',
      fromDate: last30Days.format('YYYY-MM-DD'),
      toDate: todaysDate.format('YYYY-MM-DD'),
    },
    lastMonth: {
      name: 'lastMonth',
      fromDate: getStartDateOfXMonth(1).format('YYYY-MM-DD'),
      toDate: endOfLastMonth.format('YYYY-MM-DD'),
    },
    last3Months: {
      name: 'last3Months',
      fromDate: getStartDateOfXMonth(3).format('YYYY-MM-DD'),
      toDate: endOfLastMonth.format('YYYY-MM-DD'),
    },
    customDateRange: {
      name: 'customDateRange',
      fromDate: dayjs(from).format('YYYY-MM-DD'),
      toDate: dayjs(to).format('YYYY-MM-DD'),
    },
  }
}

export const customDateRadioOptions = (
  csvExportCustomDateOption: CustomDateValueOptionsType
): CustomDateRadioOptionsType[] => {
  const { t } = useTranslation()
  return [
    {
      label: t('walletsPage.exportDateOptions.last30Days'),
      value: csvExportCustomDateOption.last30Days.name,
      secondaryLabel: `${last30Days.format('MMM DD, YY')} - ${todaysDate.format('MMM DD, YY')}`,
    },
    {
      label: t('walletsPage.exportDateOptions.lastMonth'),
      value: csvExportCustomDateOption.lastMonth.name,
      secondaryLabel: `${getStartDateOfXMonth(1).format('MMM DD, YY')} - ${endOfLastMonth.format(
        'MMM DD, YY'
      )}`,
    },
    {
      label: t('walletsPage.exportDateOptions.lastXMonths', { numberOfMonths: 3 }),
      value: csvExportCustomDateOption.last3Months.name,
      secondaryLabel: `${getStartDateOfXMonth(3).format('MMM DD, YY')} - ${endOfLastMonth.format(
        'MMM DD, YY'
      )}`,
    },
    {
      label: t('walletsPage.exportDateOptions.customDateRange'),
      value: csvExportCustomDateOption.customDateRange.name,
    },
  ]
}

export const exportFormatRadioOptions = () => {
  const { t } = useTranslation()
  return [
    {
      label: t('walletsPage.transactionCsv'),
      value: 'csv',
    },
    {
      label: t('walletsPage.pdfStatement'),
      value: 'pdf',
    },
  ]
}
