import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
  Box,
  Box as IconCircle,
  Box as TextContainer,
} from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'

export interface SettingsLinkProps {
  title: string
  description: string
  icon: React.SVGProps<SVGSVGElement>
  path: string
  disable: boolean
}

const SettingsLink = ({ title, description, icon, path, disable }: SettingsLinkProps) => {
  return (
    <Box divider="bottom" className="relative">
      <Link
        className={clsx('group', {
          'pointer-events-none disable-element opacity-50': disable,
        })}
        to={path}
        aria-label={`${title} link`}
      >
        <Box
          flex
          alignItems="center"
          padding={16}
          gapX={16}
          className="group-hover:bg-lightBlueHover sm:p-6"
        >
          <IconCircle
            flex
            centerChildren
            size={48}
            flexSize="keep"
            borderRadius="full"
            background="gray"
            className="group-hover:bg-white"
          >
            {icon}
          </IconCircle>

          <TextContainer flexSize="fill">
            <Text size="sm" weight="medium" className="leading-5">
              {title}
            </Text>
            <Text size="sm" color="text-500" className="leading-5">
              {description}
            </Text>
          </TextContainer>

          <Icon name="CaretRightIcon" />
        </Box>
      </Link>
    </Box>
  )
}

export default SettingsLink
