import React, { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { UploadFile } from '@node-space/storybook-components'
import { logError } from '@node-space/utils'
import { DocumentUpload } from 'types/verification'
import {
  FILE_UPLOAD_LIMIT,
  MAX_DOCUMENT_UPLOAD,
  convertDataUrlToArrayBuffer,
  dropZoneAcceptedDocuments,
  getMimeTypeFromArrayBuffer,
} from 'utils/documents'

interface UploadFileProps {
  file: DocumentUpload
  setFile: (fileData: DocumentUpload) => void
}

export default function UploadCaseDocument({ file, setFile }: UploadFileProps) {
  const { t } = useTranslation()
  const [error, setError] = useState<string>('')

  const onDrop = (acceptedFiles, fileRejections: FileRejection[]) => {
    // -- clear state
    setError(null)
    setFile(null)

    // -- error: multiple files are uploaded
    if (fileRejections?.length > MAX_DOCUMENT_UPLOAD) {
      setError(t('documents.multipleUploadsError'))
      return
    }

    // -- error: file extension is not accepted
    if (!acceptedFiles?.length) {
      setError(t('documents.uploadRequirementsOnly'))
      return
    }

    acceptedFiles.forEach(acceptedFile => {
      try {
        const fileExtension: string = acceptedFile?.type
        const fileExtensionDisplay: string = fileExtension.split('/')[1].toLocaleUpperCase()

        // -- error: file exceeds max size
        if (acceptedFile?.size > FILE_UPLOAD_LIMIT) {
          setError(t('documents.smallerFileRequired'))
          return
        }

        // -- read file data and set state
        const reader = new FileReader()

        // -- to handle other events on the reader, use 'reader.onabort' and 'reader.onerror'
        reader.onload = () => {
          const dataUrl = reader?.result as string

          const arrayBuffer: ArrayBuffer = convertDataUrlToArrayBuffer(dataUrl)
          const mimeType: string = getMimeTypeFromArrayBuffer(arrayBuffer)

          // -- error: file extension and the mime type is incompatible
          if (!mimeType || mimeType !== fileExtension) {
            setError(t('documents.mimeTypeError', { extension: fileExtensionDisplay }))
            return
          }

          const fileData = { fileData: acceptedFile, buffer: dataUrl }
          setFile && setFile(fileData)
        }

        reader.readAsDataURL(acceptedFile)
      } catch (error) {
        logError('uploadFileOnDrop', error)
        setError(t('oopsSomethingWentWrong'))
      }
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: dropZoneAcceptedDocuments,
  })

  return (
    <UploadFile
      defaultMessage={t('documents.defaultMessage')}
      file={file}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      isDragActive={isDragActive}
      name="case-document"
      textList={{
        dropFile: t('documents.dropFile'),
        replaceFile: t('documents.replaceFile'),
        uploadFile: t('documents.uploadFile'),
        uploadingFile: t('documents.uploadingFile'),
        fileRequired: t('fieldRequired', { field: t('documents.file') }),
      }}
      errorMessage={error}
      title={t('documents.uploadADocument')}
    />
  )
}
