import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextColor } from '@node-space/storybook-components'
import { useAccountsContext } from 'hooks/context/useAccountsContext'

export const LoginFooter = () => {
  const { t } = useTranslation()
  const { shouldShowUKInfoBanner } = useAccountsContext()

  return (
    <>
      {shouldShowUKInfoBanner && (
        <Text size="sm" align="center" className="mt-auto pb-3">
          {t('ukApprovalStamp')}
        </Text>
      )}
      <Text size="xs" color={TextColor.GREY06} align="center" className="mt-auto pb-4">
        {`${t('recaptcha.protected')}`}
        <br />© 2017 - {new Date().getFullYear()} BVNK. {`${t('allRightsReserved')}`}
      </Text>
    </>
  )
}
