export const RowHighlightColors = {
  ON: 'bg-secondary-50',
  OFF: 'bg-transparent',
}

export const highlightRowBasedOnId = (id?: string): void => {
  const elements = Array.from(document.querySelectorAll('tr'))

  if (elements?.length) {
    elements.forEach(el => {
      el.classList.remove(RowHighlightColors.ON)
      el.classList.add(RowHighlightColors.OFF)
    })
  }

  if (!id) return

  const element = document.getElementById(id)
  if (element) {
    const parentWithClass = element.closest('tr')
    if (parentWithClass) {
      parentWithClass.classList.remove(RowHighlightColors.OFF)
      parentWithClass.classList.add(RowHighlightColors.ON)
    }
  }
}
