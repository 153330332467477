import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ProofOfPaymentLink } from 'components/ProofOfPaymentLink/ProofOfPaymentLink'
import { usePopDownloadQuery } from 'hooks/queries/usePopDownloadQuery'

interface ProofOfPaymentButtonProps {
  externalReference?: string
  isLink?: boolean
}

export const ProofOfPaymentButton = ({
  externalReference,
  isLink = false,
}: ProofOfPaymentButtonProps) => {
  const { t } = useTranslation()
  const proofOfPaymentLink = `/banking/payouts/${externalReference}/proof-of-payment`

  const {
    isFetching: isFetchingDocument,
    isError: isDocumentFetchError,
    refetch: refetchDocument,
  } = usePopDownloadQuery(proofOfPaymentLink)

  const onClickHandler = async () => {
    if (externalReference) {
      const documentData = await refetchDocument()

      if (documentData?.data) {
        const url = window.URL.createObjectURL(new Blob([documentData?.data]))
        const link = document.createElement('a')
        const fileName = `ProofOfPayment_${externalReference}.pdf`
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    }
  }

  return (
    <Box>
      {isLink ? (
        <ProofOfPaymentLink
          isFetchingDocument={isFetchingDocument}
          onClickHandler={onClickHandler}
        />
      ) : (
        <Button
          testid="pop-download-button"
          full={true}
          loading={isFetchingDocument}
          onClick={onClickHandler}
        >
          {t('downloadProofOfPayment')}
        </Button>
      )}

      {isDocumentFetchError && (
        <Box paddingT={12}>
          <Callout state="error" message={t('downloadProofOfPaymentError')} />
        </Box>
      )}
    </Box>
  )
}
