import React, { useEffect } from 'react'
import clsx from 'clsx'
import { Box, Box as Container } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import { useMappedRoles } from 'hooks/useMappedRoles'

export interface TextAreaProps {
  disabled?: boolean
  isLoading?: boolean
  value: string
  name?: string
  placeHolder?: string
  testid?: string
  sendEmail: () => void
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const SendEmailArea = ({
  disabled,
  isLoading,
  value,
  name,
  testid,
  placeHolder,
  onChange,
  sendEmail,
}: TextAreaProps) => {
  const permissions = useMappedRoles().paymentSupport
  const canEdit = permissions?.canEdit

  const caseEmailsList = document?.getElementById('caseEmailsList')

  document.querySelectorAll<HTMLTextAreaElement>('#caseEmailInputArea').forEach(element => {
    element.style.height = `${element.scrollHeight}px`
    element.addEventListener('input', event => {
      if (event.target instanceof HTMLTextAreaElement) {
        event.target.style.height = 'auto'
        event.target.style.height = `${event.target.scrollHeight}px`

        if (Number(event.target.style.height.replace('px', '')) < 250) {
          caseEmailsList.style.setProperty(
            'height',
            `calc(100% - ${event.target.scrollHeight + 130}px)`
          )
        }
      }
    })
  })

  useEffect(() => {
    if (!value) {
      // reset caseEmailsList manipulated height
      caseEmailsList?.style && caseEmailsList?.style?.setProperty('height', `calc(100% - 151px)`)
    }
  }, [value])

  return (
    <Container width="full" paddingX={20} className="text-sm font-normal absolute bottom-[24px]">
      <Box
        flex
        gapX={8}
        paddingX={12}
        className={clsx(
          `py-[10px] min-h-10 relative rounded transition focus-within:ring-1 ring-1 ring-neutral-thunder focus-within:ring-primary-500 hover:ring-primary-500`,
          {
            'bg-neutral-cotton': !disabled,
            'bg-neutral-overcast': !canEdit || disabled || isLoading,
          }
        )}
      >
        <textarea
          id="caseEmailInputArea"
          className="rounded w-full max-h-[250px] resize-none bg-transparent text-blue transition placeholder:text-gray-06 focus:outline-none focus-visible:outline-none"
          name={name}
          rows={1}
          onChange={onChange}
          value={value}
          placeholder={placeHolder}
          data-testid={testid}
          disabled={!canEdit || disabled || isLoading}
        />
        <Box flex flexItemsEnd>
          {isLoading ? (
            <Loader size="small" />
          ) : (
            <Button
              className="-mr-2"
              iconElement={<Icon color="primary-500" name="SendIcon" />}
              noStyling
              onClick={() => sendEmail()}
              disabled={!canEdit || !value?.trim()}
            />
          )}
        </Box>
      </Box>
    </Container>
  )
}
