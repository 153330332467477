import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postMassFiatPayouts } from 'services/MasspayoutService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useMassFiatPayoutsMutation = () =>
  useMutation({
    mutationFn: postMassFiatPayouts,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useMassFiatPayoutsMutation', error, data)
      }
    },
})
