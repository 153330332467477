import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { UpdateBeneficiaryFormPayload } from 'types/beneficiaries'
import { createGenericNameSchema } from 'utils/yupSchemas/createGenericNameSchema'

export const updateBeneficiarySchema = (t: TFunction<'translation', undefined>) => {
  const alias = createGenericNameSchema(t('alias'), t)
  const password = yup.string().trim().required()

  const formFields = {
    alias,
    password,
  }

  return yup.object<Partial<Record<keyof UpdateBeneficiaryFormPayload, yup.AnySchema>>>(formFields)
}
