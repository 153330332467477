import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ConnectionStatusTypes, NetworkConnectionViewTypes } from 'constants/bvnkNetwork'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useUpdateNetworkConnectionMutation } from 'hooks/mutations/BvnkNetwork/useUpdateNetworkConnectionMutation'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useToastContext } from 'hooks/useToastContext'
import WalletModal, { WalletAction } from 'pages/Wallets/components/Modal/WalletModal'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import {
  BvnkNetworkConnection,
  ConnectionStatusType,
  NetworkConnectionViewType,
} from 'types/bvnkNetwork'
import { WalletTypes } from 'types/types'
import { getConnectionStatusUpdateSuccessMessage } from 'utils/bvnkNetwork'
import { ConnectionConfirmationModal } from './ConnectionConfirmationModal'

export interface ActionButtonsProps {
  connection: BvnkNetworkConnection
  networkConnectionViewType: NetworkConnectionViewType
}

export const ActionButtons = ({ connection, networkConnectionViewType }: ActionButtonsProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { profile } = useProfileContext()
  const addToast = useToastContext()
  const { mutate: updateNetworkConnection } = useUpdateNetworkConnectionMutation()
  const [isConnectionConfirmationModalOpen, setIsConnectionConfirmationModalOpen] = useState(false)
  const [isPayWalletModalOpen, setIsPayWalletModalOpen] = useState(false)
  const [walletModalAction, setWalletModalAction] = useState<WalletAction>(null)
  const permissions = useMappedRoles()

  const buttons: ButtonProps[] = []

  const updateConnection = (
    connection: BvnkNetworkConnection,
    connectionStatus: ConnectionStatusType
  ) => {
    updateNetworkConnection(
      { connectionId: connection?.id, connectionStatus },
      {
        onSuccess: () => {
          const successToastMessage = getConnectionStatusUpdateSuccessMessage(
            connection?.details?.name,
            connectionStatus,
            t
          )
          addToast({
            title: successToastMessage,
            state: 'success',
          })
          queryClient.invalidateQueries({ queryKey: ['bvnkNetworkConnections'] })
        },
        onError: () => {
          addToast({
            title: t('bvnkNetwork.updateNetworkConnectionError', { networkMember: name }),
            state: 'error',
          })
        },
      }
    )
  }

  const onRejectClick = () => {
    setIsConnectionConfirmationModalOpen(true)
  }

  const onConnectionConfirmationModalClose = () => {
    setIsConnectionConfirmationModalOpen(false)
  }

  const refreshWallets = async () => {
    await queryClient.invalidateQueries({ queryKey: reactQueryKeys.walletsV2(WalletTypes.FIAT) })
    await queryClient.invalidateQueries({
      queryKey: reactQueryKeys.totalBalance(profile?.currencyCode),
    })
  }

  const onTransferHandler = (connectionId: string) => {
    const walletAction: WalletAction = {
      key: 'pay',
      metadata: { pay: { beneficiaryOptionId: connectionId, beneficiaryPaymentType: 'network' } },
    }

    setWalletModalAction(walletAction)
    setIsPayWalletModalOpen(true)
  }

  switch (networkConnectionViewType) {
    case NetworkConnectionViewTypes.CONNECTIONS_VIEW:
      const canTranfer = permissions?.bvnkNetwork?.canTrade
      canTranfer &&
        buttons.push(
          ...([
            {
              children: (
                <Text size="sm" color="primary-500">
                  {t('bvnkNetwork.transfer')}
                </Text>
              ),
              iconElement: <Icon name="CoinArrowOut" size="sm" color="primary-500" />,
              noStyling: true,
              onClick: () => onTransferHandler(connection?.id),
            },
          ] as ButtonProps[])
        )

      break
    case NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW:
      buttons.push(
        ...([
          {
            children: (
              <Text size="sm" color="primary-500">
                {t('bvnkNetwork.accept')}
              </Text>
            ),
            iconElement: <Icon name="CheckIcon" size="sm" color="primary-500" />,
            noStyling: true,
            onClick: () => updateConnection(connection, ConnectionStatusTypes.CONNECTED),
          },
          {
            children: (
              <Text size="sm" color="status-error-500">
                {t('bvnkNetwork.deny')}
              </Text>
            ),
            iconElement: <Icon name="Prohibit" size="sm" color="status-error-500" />,
            noStyling: true,
            onClick: onRejectClick,
          },
        ] as ButtonProps[])
      )
      break
    case NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW:
      buttons.push(
        ...([
          {
            children: (
              <Text size="sm" color="status-error-500">
                {t('bvnkNetwork.deleteRequest')}
              </Text>
            ),
            iconElement: <Icon name="Prohibit" size="sm" color="status-error-500" />,
            noStyling: true,
            onClick: () => updateConnection(connection, ConnectionStatusTypes.DISCONNECTED),
          },
        ] as ButtonProps[])
      )
      break
    default:
  }

  return (
    <>
      {buttons?.map(button => <Button key={button?.name} {...button} />)}
      {/* TODO: Probably combine Action buttons and context menu */}
      <ConnectionConfirmationModal
        connection={connection}
        connectionStatus={ConnectionStatusTypes.REJECTED}
        isModalOpen={isConnectionConfirmationModalOpen}
        onModalClose={onConnectionConfirmationModalClose}
      />
      {isPayWalletModalOpen && (
        <WalletModal
          action={walletModalAction}
          setWalletModalAction={setWalletModalAction}
          refresh={refreshWallets}
          setShowWalletModal={setIsPayWalletModalOpen}
        />
      )}
    </>
  )
}
