export const UserGroups = {
  'Workspace Owner': 'Workspace Owner',
  Admin: 'Admin',
  Collaborator: 'Collaborator',
  'Pay-In Only': 'Pay-In Only',
  'Pay-In Only ': 'Pay-In Only ',
  Viewer: 'Viewer',
  'Compliance-Plus': 'Compliance-Plus',
  Compliance: 'Compliance',
  Login: 'Login',
} as const
