import { useMemo } from 'react'
import { useProfileContext } from './context/useProfileContext'

const accessRights = {
  canView: false,
  canEdit: false,
}

const permissionTemplates = {
  // First iteration, everyone has access. controlled by feature flag
  bvnkNetwork: {
    ...accessRights,
    canView: true,
    canTrade: false,
  },
  customers: {
    ...accessRights,
    canView: true,
    canTrade: false,
  },
  channels: {
    ...accessRights,
    canExport: false,
    canViewCPSLink: false,
  },
  manageApprovals: {
    canView: true,
    canEdit: false,
  },
  manageBusinessDetails: {
    ...accessRights,
  },
  manageLegalAgreements: {
    ...accessRights,
  },
  manageMerchants: {
    ...accessRights,
  },
  manageChannels: {
    ...accessRights,
  },
  manageCompanyInfo: {
    canView: true,
    canEdit: false,
  },
  manageBeneficiaries: {
    ...accessRights,
  },
  manageCryptoAddress: {
    ...accessRights,
  },
  manageApiIntegration: {
    ...accessRights,
  },
  manageTeamMemberApprovers: {
    ...accessRights,
  },
  manageTeamMembers: {
    ...accessRights,
  },
  manageProfile: {
    ...accessRights,
  },
  payments: {
    ...accessRights,
    canExport: false,
    canViewCPSLink: false,
    canCreatePayIn: false,
  },
  paymentSupport: {
    ...accessRights,
  },
  profile: {
    canView: true,
    canEdit: true,
  },
  settings: {
    ...accessRights,
  },
  wallets: {
    ...accessRights,
    canExport: false,
    canTrade: false,
  },
}

const roles = [
  'ROLE_ACCOUNT_OWNER',
  'ROLE_APPROVER', // can action approvals
  'ROLE_MANAGE_APPROVER', // can manage approvers - only workspace owners
  'ROLE_USER',
  'ROLE_USER_WALLET', // can edit/view wallets
  'ROLE_USER_WALLET_READ', // can view wallets
  'ROLE_USER_PAY_READ', // can view payments
  'ROLE_USER_MERCHANT_CHANNEL_PAYMENT_READ', // can view payments in channel
  'ROLE_USER_READ',
  'ROLE_ACCOUNT_READ',
  'ROLE_USER_ACCOUNT_USER_READ', // user can read the list users in the organization
  'ROLE_USER_MERCHANT_READ', // can view merchants
  'ROLE_MERCHANT', // can edit/view merchants
  'ROLE_USER_MERCHANT_CHANNEL_READ', // can view channels
  'ROLE_MERCHANT_PAY_IN', // can create pay in
  'ROLE_MERCHANT_PAY_OUT', // can create pay out
  'ROLE_USER_MERCHANT_CHANNEL', // can edit/view channels
  'ROLE_USER_AUTHENTICATION_TOKEN_READ', // can view API keys
  'ROLE_USER_MERCHANT', // can edit/view merchants
  'ROLE_USER_WALLET_TRADE', // can convert currencies do withdrawals
  'ROLE_USER_BENEFICIARY_READ', // can view beneficiaries
  'ROLE_USER_PAYMENT_METHOD_READ', // can read bank
  'ROLE_USER_ADDRESS_WHITELIST', // can manage add/delete crypto address
  'ROLE_USER_ADDRESS_WHITELIST_READ', // can view crypto address
  'ROLE_CASES_READ', // can read rfi cases
  'ROLE_CASES_EDIT', // can action rfi cases
] as const

type Role = (typeof roles)[number]

type MappedPermissions = {
  [key: string]: {
    canView: boolean
    canEdit: boolean
    canExport?: boolean
    canViewCPSLink?: boolean
    canCreatePayIn?: boolean
    canTrade?: boolean
  }
}

export const useMappedRoles = () => {
  const { profile } = useProfileContext()

  return useMemo(() => {
    const mappedPermissions: MappedPermissions = { ...permissionTemplates }

    const processRole = (role: Role) => {
      switch (role) {
        case 'ROLE_USER':
          mappedPermissions['wallets'] = {
            canView: true,
            canEdit: true,
            canExport: true,
            canTrade: true,
          }
          mappedPermissions['channels'] = {
            canView: true,
            canEdit: true,
            canExport: true,
            canViewCPSLink: true,
          }
          mappedPermissions['payments'] = {
            canView: true,
            canEdit: true,
            canExport: true,
            canViewCPSLink: true,
            canCreatePayIn: true,
          }
          mappedPermissions['manageApiIntegration'] = {
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageBankAccounts'] = {
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageBusinessDetails'] = {
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageLegalAgreements'] = {
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageBeneficiaries'] = {
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageCryptoAddress'] = {
            canView: true,
            canEdit: false,
          }
          break
        case 'ROLE_ACCOUNT_OWNER':
          mappedPermissions['manageTeamMembers'] = {
            ...mappedPermissions['manageTeamMembers'],
            canView: true,
            canEdit: true,
          }
          mappedPermissions['manageCompanyInfo'] = {
            ...mappedPermissions['manageCompanyInfo'],
            canEdit: true,
          }
          break
        case 'ROLE_USER_WALLET': {
          mappedPermissions['wallets'] = {
            ...mappedPermissions['wallets'],
            canView: true,
            canEdit: true,
            canExport: true,
            canTrade: true,
          }
          break
        }
        case 'ROLE_USER_WALLET_READ': {
          mappedPermissions['wallets'] = {
            ...mappedPermissions['wallets'],
            canView: true,
            canExport: true,
          }
          break
        }
        case 'ROLE_USER_PAY_READ':
          mappedPermissions['payments'] = {
            ...mappedPermissions['payments'],
            canView: true,
            canExport: true,
          }
          break
        case 'ROLE_USER_MERCHANT_CHANNEL_PAYMENT_READ':
        case 'ROLE_USER_READ':
          mappedPermissions['channels'] = {
            ...mappedPermissions['channels'],
            canView: true,
            canExport: true,
          }
          break
        case 'ROLE_ACCOUNT_READ':
          mappedPermissions['profile'] = {
            ...mappedPermissions['profile'],
            canView: true,
          }
          break
        case 'ROLE_USER_ACCOUNT_USER_READ':
          mappedPermissions['manageTeamMembers'] = {
            ...mappedPermissions['manageTeamMembers'],
            canView: true,
          }
          break
        case 'ROLE_MERCHANT':
          mappedPermissions['manageMerchants'] = {
            ...mappedPermissions['manageMerchants'],
            canView: true,
            canEdit: true,
          }
          mappedPermissions['bvnkNetwork'] = {
            ...mappedPermissions['bvnkNetwork'],
            canView: true,
            canEdit: true,
            canTrade: true,
          }
          mappedPermissions['customers'] = {
            ...mappedPermissions['customers'],
            canView: true,
            canEdit: true,
            canTrade: true,
          }
          break
        case 'ROLE_USER_MERCHANT_READ':
          mappedPermissions['manageMerchants'] = {
            ...mappedPermissions['manageMerchants'],
            canView: true,
          }
          break
        case 'ROLE_MERCHANT_PAY_IN':
          mappedPermissions['payments'] = {
            ...mappedPermissions['payments'],
            canCreatePayIn: true,
          }
          break
        case 'ROLE_USER_MERCHANT_CHANNEL_READ':
          mappedPermissions['manageChannels'] = {
            ...mappedPermissions['manageChannels'],
            canView: true,
          }
          break
        case 'ROLE_MERCHANT_PAY_OUT':
          mappedPermissions['payments'] = {
            ...mappedPermissions['payments'],
            canEdit: true,
          }
          break
        case 'ROLE_USER_MERCHANT_CHANNEL':
          mappedPermissions['channels'] = {
            ...mappedPermissions['channels'],
            canView: true,
            canEdit: true,
          }
          break
        case 'ROLE_USER_AUTHENTICATION_TOKEN_READ':
          mappedPermissions['manageApiIntegration'] = {
            ...mappedPermissions['manageApiIntegration'],
            canView: true,
          }
          break
        case 'ROLE_USER_MERCHANT':
          mappedPermissions['manageMerchants'] = {
            ...mappedPermissions['manageMerchants'],
            canView: true,
            canEdit: true,
          }
          break
        case 'ROLE_USER_WALLET_TRADE':
          mappedPermissions['wallets'] = {
            ...mappedPermissions['wallets'],
            canTrade: true,
          }
          break
        case 'ROLE_USER_BENEFICIARY_READ':
          mappedPermissions['manageBeneficiaries'] = {
            ...mappedPermissions['manageBeneficiaries'],
            canView: true,
          }
          break
        case 'ROLE_USER_PAYMENT_METHOD_READ':
          mappedPermissions['manageBankAccounts'] = {
            ...mappedPermissions['manageBankAccounts'],
            canView: true,
          }
          break
        case 'ROLE_USER_ADDRESS_WHITELIST':
          mappedPermissions['manageCryptoAddress'] = {
            ...mappedPermissions['manageCryptoAddress'],
            canView: true,
            canEdit: true,
          }
          break

        case 'ROLE_USER_ADDRESS_WHITELIST_READ':
          mappedPermissions['manageCryptoAddress'] = {
            ...mappedPermissions['manageCryptoAddress'],
            canView: true,
          }
          break

        case 'ROLE_APPROVER':
          mappedPermissions['manageApprovals'] = {
            ...mappedPermissions['manageApprovals'],
            canEdit: true,
          }
          break

        case 'ROLE_MANAGE_APPROVER':
          mappedPermissions['manageTeamMemberApprovers'] = {
            ...mappedPermissions['manageTeamMemberApprovers'],
            canEdit: true,
          }
          break

        case 'ROLE_CASES_READ':
          mappedPermissions['paymentSupport'] = {
            ...mappedPermissions['paymentSupport'],
            canView: true,
          }
          break

        case 'ROLE_CASES_EDIT':
          mappedPermissions['paymentSupport'] = {
            ...mappedPermissions['paymentSupport'],
            canView: true,
            canEdit: true,
          }
          break

        default:
          // eslint-disable-next-line no-console
          // isLocal && console.log(`Unhandled role. ${role}`)
          break
      }
    }

    profile?.roles?.forEach((role: Role) => {
      processRole(role)
    })

    return mappedPermissions
  }, [profile?.roles])
}
