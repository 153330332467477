import React from 'react'

import { Text } from '@node-space/storybook-components'
import { Description } from 'components/text/Description'

interface Enable2FANoticeModalProps {
  heading: string
  description: string
}

const Enable2FANoticeModal = ({ heading, description }: Enable2FANoticeModalProps) => {
  return (
    <>
      <Text tag="h3" weight="medium">
        {heading}
      </Text>
      <Description>{description}</Description>
    </>
  )
}

export default Enable2FANoticeModal
