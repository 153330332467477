import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RecaptchaScoreError } from 'recaptcha/RecaptchaScoreError'
import { RecaptchaTokenError } from 'recaptcha/RecaptchaTokenError'
import { RecaptchaProps } from 'recaptcha/types'
import { isRecapthcaLowScoreError } from 'recaptcha/utils'
import { Box as Container } from '@node-space/storybook-components/dist/Box'
import { List } from '@node-space/storybook-components/dist/List'
import { logError } from '@node-space/utils'
import { SubmitError } from 'components/Form/SubmitError'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { useLoginMutation } from 'hooks/mutations'
import { PostLoginReq, PostWorkplaceAuthReq } from 'types/authentication/PostLoginReq'
import { BaseErrorResponse } from 'types/beneficiaries'
import { WorkspaceListItem } from 'types/types'

interface WorkplacesAuthProps {
  workspaceList: WorkspaceListItem[]
  loginData: PostLoginReq
  recaptcha: RecaptchaProps
  setRequiresUserSelection: (value: boolean) => void
  setShowTwoFactor: (value: boolean) => void
  setSuccessfulLogin: () => Promise<void>
}

const WorkplacesAuth = ({
  workspaceList,
  loginData,
  recaptcha,
  setShowTwoFactor,
  setRequiresUserSelection,
  setSuccessfulLogin,
}: WorkplacesAuthProps) => {
  const { t } = useTranslation()

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ReactNode>('')

  const { mutate: postLogin } = useLoginMutation()

  const loginWithWorkspace = async (value: string) => {
    setIsLoadingSubmit(true)
    setShowError(false)
    setErrorMessage('')

    const recaptchaToken = await recaptcha?.getToken()

    if (!recaptchaToken) {
      setIsLoadingSubmit(false)
      setErrorMessage(<RecaptchaTokenError />)
      setShowError(true)
      return
    }

    const postData: PostWorkplaceAuthReq = {
      ...loginData,
      recaptcha: recaptchaToken,
      accountId: parseInt(value),
    }

    postLogin(postData, {
      onSuccess: async () => {
        await setSuccessfulLogin()
      },
      onError: async (error: BaseErrorResponse) => {
        const errorMessage = error?.data?.message
        if (error.status === 412) {
          setShowTwoFactor(true)
          setRequiresUserSelection(false)
        } else {
          if (isRecapthcaLowScoreError(errorMessage)) {
            setErrorMessage(<RecaptchaScoreError />)
          } else {
            setErrorMessage(t('errorMessages.login.default'))
            if (error.status !== 401) {
              logError(
                `Error from WorkplacesAuth - postLogin${errorMessage ? ` - ${errorMessage}` : ''}`,
                error,
                JSON.stringify(postData, null, 3),
                'fatal'
              )
            }
          }
          setShowError(true)
        }
      },
      onSettled: async () => {
        setIsLoadingSubmit(false)
      },
    })
  }

  return (
    <LoginPageLayout title={t('welcomeBack')} description={t('workspaceSelection')}>
      {showError && <SubmitError>{errorMessage}</SubmitError>}
      <Container testid='workspace-list'>
        <List
          disabled={recaptcha?.isLoading || isLoadingSubmit}
          listItems={workspaceList}
          testid="workplaces"
          onClick={e => {
            loginWithWorkspace(e.currentTarget.dataset.value)
          }}
        />
      </Container>
    </LoginPageLayout>
  )
}

export default WorkplacesAuth
