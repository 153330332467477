import { useQuery, useQueryClient } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAllCryptoMassPayoutsByPolling } from 'services/MasspayoutService'

export const useGetCryptoMassPayoutsListByPolling = (enabled = true) => {
  const queryClient = useQueryClient()
  const initialData = queryClient.getQueryData(reactQueryKeys.massCryptoPayoutsList())

  return useQuery({
    queryKey: reactQueryKeys.massCryptoPayoutsListByPolling(),
    queryFn: getAllCryptoMassPayoutsByPolling,
    enabled,
    initialData: () => {
      initialData
    },
    refetchInterval: 7500,
    staleTime: 7500,
    select: massPayouts => {
      const mergedPayouts = massPayouts
        ?.map(payout => {
          const existingPayout = initialData?.find(
            initialPayout => initialPayout?.externalId === payout?.externalId
          )
          const { processedItemsCount, totalItemsCount, ...partialUpdatedPayout } = payout || {}
          return (
            existingPayout && {
              ...partialUpdatedPayout,
              ...existingPayout,
              totalProcessedItems: processedItemsCount,
              totalItems: totalItemsCount,
            }
          )
        })
        ?.filter(payout => payout)
      return mergedPayouts
    },
  })
}
