import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { TableRow } from '@node-space/storybook-components/dist/components/SimpleTable'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { DateFormat } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsTransactionsQuery } from 'hooks/queries/useApprovalsTransactionsQuery'
import { ApprovalActionStatuses, ApprovalStatuses } from 'types/approvals'
import { SidePanelSummary } from '../SidePanel/SidePanelSummary'

const rowHeaderWidth = 'px-4 w-1/5'

export const RejectedTransactions = () => {
  const { t } = useTranslation()
  const { currentAccount } = useAccountsContext()

  const currentAccountReference = currentAccount?.reference || ''
  const {
    data: rejectedTransactions,
    isError,
    isFetching,
  } = useApprovalsTransactionsQuery(
    currentAccountReference,
    ApprovalStatuses.REJECTED,
    !!currentAccountReference
  )

  const {
    isSidePanelOpen: isRejectedTransactionDetailsOpen,
    sidePanelData: rejectedTransactionDetails,
    paging,
    setIsSidePanelOpen,
    updateSidePanel,
  } = useSidePanel(rejectedTransactions)

  const openSidePanelSummary = (index: number) => {
    updateSidePanel(index)
    setIsSidePanelOpen(true)
  }

  const transactionsData = (): TableRow[] => {
    return rejectedTransactions?.map((approvalTransaction, index) => ({
      rowData: [
        {
          cellData: (
            <Box flex alignItems="center" gap={12} className="relative">
              <ImageIcon
                width={24}
                height={24}
                hasBackgroundColor={false}
                src={`${
                  process.env.ICONS_URL
                }${approvalTransaction?.currency?.code?.toLowerCase()}.svg`}
              />
              <Box className="relative">
                {!!(approvalTransaction?.amount && approvalTransaction?.currency?.code) && (
                  <Text size="sm" className="text-500">
                    {`${approvalTransaction?.amount} ${approvalTransaction?.currency?.code}`}
                  </Text>
                )}
                <Text size="sm" color="disabled">
                  {approvalTransaction?.walletName}
                </Text>
              </Box>
            </Box>
          ),
          className: `py-4 px-4`,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.beneficiaryDetails?.accountNumber ||
                  approvalTransaction?.beneficiaryDetails?.destinationWallet}
              </Text>
              <Text size="sm" color="disabled">
                {approvalTransaction?.beneficiaryDetails?.beneficiaryName}
              </Text>
            </Box>
          ),
          className: `break-all px-4`,
        },
        {
          cellData: (
            <Box>
              <Text size="sm" className="text-500">
                {approvalTransaction?.initiatedBy || approvalTransaction?.initiatedByEmail}
              </Text>
              <Text size="sm" color="disabled">
                {dayjs(approvalTransaction?.initiatedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
              </Text>
            </Box>
          ),
          className: `px-4`,
        },
        {
          cellData: (
            <Box>
              {!!approvalTransaction?.processedBy ? (
                approvalTransaction?.processedBy?.map((processedByDetail, index) => (
                  <Box key={index}>
                    {processedByDetail?.status ===
                      ApprovalActionStatuses.PAYOUT_MERCHANT_APPROVAL_REJECTED && (
                      <>
                        <Text size="sm" className="text-500" key={index}>
                          {`${processedByDetail?.userFullName?.trim() || processedByDetail?.email}`}
                        </Text>
                        <Text size="sm" color="disabled">
                          {dayjs(processedByDetail?.eventTime).format(
                            DateFormat.D_MMM_YYYY_AT_HH_MM
                          )}
                        </Text>
                      </>
                    )}
                  </Box>
                ))
              ) : (
                <>
                  <Text size="sm" className="text-500">
                    {approvalTransaction?.actionedBy}
                  </Text>
                  <Text size="sm" color="disabled">
                    {dayjs(approvalTransaction?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
                  </Text>
                </>
              )}
            </Box>
          ),
          className: `px-4`,
        },
        {
          cellData: approvalTransaction?.rejectionReason,
        },
      ],
      onClick: () => openSidePanelSummary(index),
    }))
  }

  const rejectedTransactionsData = transactionsData()

  return (
    <Box paddingY={16}>
      {!!rejectedTransactionDetails && (
        <SidePanelSummary
          isOpen={isRejectedTransactionDetailsOpen}
          paging={paging}
          onClose={() => setIsSidePanelOpen(false)}
          transactionDetails={rejectedTransactionDetails}
          tab={ApprovalStatuses.REJECTED}
        />
      )}
      {isError ? (
        <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />
      ) : !isFetching && !rejectedTransactionsData?.length ? (
        <StatusCard
          headline={t('approvals.emptyStateHeadline', {
            approvalStatus: ApprovalStatuses.REJECTED.toLowerCase(),
          })}
          description={t('approvals.emptyStateDescription', {
            approvalStatus: ApprovalStatuses.REJECTED.toLowerCase(),
          })}
        />
      ) : (
        <SimpleTableV2
          columns={[
            {
              headerTitle: t('payment'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('to'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('approvals.initiatedBy'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('approvals.rejectedBy'),
              className: rowHeaderWidth,
            },
            {
              headerTitle: t('approvals.reasonForRejection'),
              className: `w-1/5`,
            },
          ]}
          isLoading={isFetching}
          tableData={rejectedTransactionsData}
        />
      )}
    </Box>
  )
}
