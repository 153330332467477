import React from 'react'
import {
  Box as CurrencyWrapper,
  Box as DescriptionWrapper,
  Box as IconWrapper,
  ImageIcon,
  Text,
} from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import SafeGuardIcon from 'components/wallets/SafeguardIcon'
import { BvnkNetworkTag } from 'pages/BvnkNetwork/components/BvnkNetworkTag'
import { TableSectionItem } from 'pages/Wallets/types'

type CurrencyProps = {
  wallet: TableSectionItem
  isEmoney: boolean
}

const Currency = ({ wallet, isEmoney }: CurrencyProps) => {
  return (
    <CurrencyWrapper
      flex
      alignItems="center"
      paddingY={8}
      paddingL={16}
      gapX={12}
      className="sm:pl-7"
    >
      <IconWrapper className="relative">
        <ImageIcon
          width={24}
          height={24}
          src={`${process.env.ICONS_URL}${wallet.currency?.code?.toLowerCase()}.svg`}
        />
        {isEmoney && <SafeGuardIcon />}
      </IconWrapper>
      <DescriptionWrapper paddingL={8} paddingY={8}>
        <Text size="sm" className="sm:w-full">
          {wallet.description}
        </Text>
        <Box flex gapX={8} flexWrap>
          <Text size="xs" tag="span" color="grey">
            {wallet.currency.code}
          </Text>
          {wallet?.supportsInternalBvnkNetworkTransfers && <BvnkNetworkTag />}
        </Box>
      </DescriptionWrapper>
    </CurrencyWrapper>
  )
}

export default Currency
