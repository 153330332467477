import React from 'react'
import { toLower } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import SafeGuardIcon from 'components/wallets/SafeguardIcon'
import { useWalletContext } from 'hooks/context'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { formatTotal } from 'pages/Wallets/components/Table/utils'

type WalletBalanceProps = {
  isEmoney: boolean
}

export const WalletBalance = ({ isEmoney }: WalletBalanceProps) => {
  const wallet = useWalletContext()
  const { t } = useTranslation()
  const { profile } = useProfileContext() || {}
  const { currencyCode: baseCurrency } = profile || {}
  const isSelectedWalletActive = useIsActiveWallet(wallet)
  const { data: accountPreferences } = useAccountPreferencesQuery()

  const preferredCurrency = accountPreferences?.currency
  const userCurrency = preferredCurrency || baseCurrency
  const walletCurrency = wallet?.currency?.code
  const walletBalance = wallet?.approxBalance
  const walletConvertedAvailable = wallet?.approxConvertedAvailable

  const inactiveWalletLabel = (
    <Box flex alignItems="center" gap={4}>
      <Icon size="default" name="LockIcon" color={'status-error-900'} />
      <Text color="status-error-900">{t('unavailable').toUpperCase()}</Text>
    </Box>
  )

  return (
    <>
      <Box flex alignItems="center" gap={12} className="relative">
        {walletCurrency && (
          <ImageIcon
            width={32}
            height={32}
            src={`${process.env.ICONS_URL}${toLower(walletCurrency)}.svg`}
          />
        )}
        {isEmoney && <SafeGuardIcon isDetails={true} />}
        <Text size="3xl" weight="semibold" color="dark-grey">
          {!Number(walletBalance)
            ? `${walletBalance} ${walletCurrency}`
            : formatTotal(Number(walletBalance), walletCurrency)}
        </Text>
        {!isSelectedWalletActive && <StatusPill state={'error'} label={inactiveWalletLabel} />}
      </Box>
      {walletCurrency !== baseCurrency && Boolean(Number(walletConvertedAvailable)) && (
        <Text size="lg" weight="normal" color="grey">
          {`~ ${formatTotal(Number(walletConvertedAvailable), userCurrency)}`}
        </Text>
      )}
    </>
  )
}
