import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateReference } from 'utils/utils'
import { ConvertFormType, ConvertSteps } from '../components/Convert/Convert'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import convertSchema from '../schemas/convertSchema'
import useWalletActionApi from './useWalletActionApi'

const useConvertAction = (sharedActionProps: WalletActionSharedProps) => {
  const { t } = useTranslation()

  const [convertStep, setConvertStep] = useState<ConvertSteps>('form')
  const convertActionApi = useWalletActionApi<ConvertFormType, ConvertSteps>({
    intialStep: 'form',
    step: convertStep,
    setStep: setConvertStep,
    formSchema: convertSchema({ t, step: convertStep }),
    defaultFormValues: { reference: generateReference() },
    ...sharedActionProps,
  })

  return convertActionApi
}

export default useConvertAction
