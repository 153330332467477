import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { postWalletSearch } from 'services/WalletsService'

export const useWalletSearchQuery = req => {
  return useQuery({
    queryKey: reactQueryKeys.postWalletSearch(req),
    queryFn: () => postWalletSearch(req),
    placeholderData: keepPreviousData,
  })
}
