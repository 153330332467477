import React from 'react'
import { ButtonProps } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Modal, { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'

export interface MassPayoutEditRecordModalProps {
  isEditPayoutModalVisible: boolean
  actions: ButtonProps[]
  onBackHandler: () => void
  children: React.ReactNode
  onCloseModal: () => void
}

export const MassPayoutEditRecordModal = ({
  isEditPayoutModalVisible,
  actions,
  onBackHandler,
  children,
  onCloseModal,
}: MassPayoutEditRecordModalProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Modal visible={isEditPayoutModalVisible} closeModal={() => onCloseModal()}>
        <ModalNavBar
          title={t('massPayouts.editPayment')}
          onClose={() => onCloseModal()}
          onBack={onBackHandler}
        />
        {children}
        {!!actions?.length && <ModalActions actions={actions} />}
      </Modal>
    </Box>
  )
}
