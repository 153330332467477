import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box as Form } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { ButtonCardProps } from 'components/CardButtonGroup/CardButton/CardButton'
import { CardButtonGroup } from 'components/CardButtonGroup/CardButtonGroup'
import { AddBeneficiaryContextProvider } from 'contexts/addBeneficiaryContext'
import {
  useAddBeneficiaryMutationChallengeRequest,
  useAddBeneficiaryMutationPreRequest,
} from 'hooks/mutations/useBeneficiariesMutation'
import { useNgBankAccountValidationMutation } from 'hooks/mutations/useNgBankAccountValidationMutation'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryName } from 'reactQueryKeys/reactQueryKeys'
import {
  AddBeneficiaryBankDetailsPayload,
  AddBeneficiaryBasicPayload,
  AddBeneficiaryFormPayload,
  AddBeneficiaryFormPayloadErrors,
  AddBeneficiaryPostChallengeRequest,
  AddIntermediaryBankDetailsPayload,
  BaseErrorResponse,
  BeneficiaryDetailsCurrency,
  BeneficiaryEntityType,
  BeneficiaryTransferDestination,
  BeneficiaryType,
} from 'types/beneficiaries'
import { Error } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import {
  orchestrateAddBeneficiaryPayload,
  setInternationalBeneficiaryError,
  setLocalBeneficiaryError,
} from 'utils/beneficiaries'
import track from 'utils/tracker'
import { sanitizeValues } from 'utils/utils'
import { ERROR_MESSAGES } from '../constants'
import { usdBeneficiaryBankTypeAtom } from './atoms/beneficiaryAtoms'
import { AddBeneficiaryConfirm } from './components/AddBeneficiaryConfirm'
import { AddBeneficiaryForm } from './components/AddBeneficiaryForm'
import { BeneficiaryBankDetails } from './components/BeneficiaryBankDetails/BeneficiaryBankDetails'
import { IntermediaryBankDetails } from './components/IntermediaryBankDetails/IntermediaryBankDetails'

export const ModalStep = {
  TRANSFER_TYPE: 'TRANSFER_TYPE',
  ADD_BENEFICIARY: 'ADD_BENEFICIARY',
  INTERNATIONAL_BANK_DETAILS: 'INTERNATIONAL_BANK_DETAILS',
  INTERMEDIARY_BANK_DETAILS: 'INTERMEDIARY_BANK_DETAILS',
  CONFIRMATION: 'CONFIRMATION',
  CLOSE_MODAL: 'CLOSE_MODAL',
} as const

export type ModalStep = (typeof ModalStep)[keyof typeof ModalStep]

interface AddBeneficiaryProps {
  handleCloseModal: () => void
  handleOnSuccessCloseModal: () => void
}

export const AddBeneficiary = ({
  handleCloseModal,
  handleOnSuccessCloseModal,
}: AddBeneficiaryProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { enableBeneficiaryIndividual, enableSwiftBeneficiaryCreation } = useFeatureFlags()
  const [modalStep, setModalStep] = useState<ModalStep>(
    enableSwiftBeneficiaryCreation ? ModalStep.TRANSFER_TYPE : ModalStep.ADD_BENEFICIARY
  )
  const [beneficiaryFormState, setBeneficiaryFormState] = useState<AddBeneficiaryFormPayload>(
    {} as AddBeneficiaryFormPayload
  )
  const [beneficiaryIbanValidationState, setBeneficiaryIbanValidationState] =
    useState<AddBeneficiaryBankDetailsPayload>({} as AddBeneficiaryBankDetailsPayload)
  const [beneficiaryBicValidationState, setBeneficiaryBicValidationState] = useState<string>(
    {} as string
  )
  const [beneficiaryFormErrors, setBeneficiaryFormErrors] =
    useState<AddBeneficiaryFormPayloadErrors>(null)
  const [submitPreRequest, setSubmitPreRequest] = useState(false)
  const [submitBeneficiary, setSubmitBeneficiary] = useState(false)
  const [validatingBankAccount, setValidatingBankAccount] = useState(false)
  const [loading, setLoading] = useState(false)

  const showModalHeader = useMemo(() => {
    return modalStep !== ModalStep.ADD_BENEFICIARY || !enableBeneficiaryIndividual
  }, [enableBeneficiaryIndividual, modalStep])

  useEffect(() => {
    setBeneficiaryFormState({
      ...beneficiaryFormState,
      beneficiaryEntityType:
        beneficiaryFormState?.beneficiaryEntityType ?? BeneficiaryEntityType.BUSINESS,
      beneficiaryType: beneficiaryFormState?.beneficiaryType ?? BeneficiaryType.THIRD_PARTY,
      transferDestination:
        beneficiaryFormState?.transferDestination ?? BeneficiaryTransferDestination.LOCAL,
    })
  }, [])

  const transferDestination = useMemo(() => {
    return beneficiaryFormState?.transferDestination
  }, [beneficiaryFormState?.transferDestination])

  const { mutate: mutatePreRequest, isPending: isLoadingPreRequest } =
    useAddBeneficiaryMutationPreRequest()

  const { mutate: mutatePostRequest, isPending: isLoadingPostRequest } =
    useAddBeneficiaryMutationChallengeRequest()

  const { mutate: validateNgBankAccount } = useNgBankAccountValidationMutation()

  const beneficiaryBankType = useAtomValue(usdBeneficiaryBankTypeAtom)

  const isSubmitting = useMemo(() => {
    return validatingBankAccount || isLoadingPreRequest || loading
  }, [validatingBankAccount, isLoadingPreRequest, loading])

  const modalHeader = useMemo(() => {
    if (modalStep === ModalStep.INTERNATIONAL_BANK_DETAILS) {
      return t('manageBeneficiaries.beneficiaryBankDetails')
    }
    if (modalStep === ModalStep.INTERMEDIARY_BANK_DETAILS) {
      return t('manageBeneficiaries.intermediaryBankDetails')
    }

    if (modalStep === ModalStep.CONFIRMATION) {
      return t('confirmBeneficiaryDetails')
    }

    return t('manageBeneficiaries.addBeneficiaryTitle')
  }, [modalStep])

  useEffect(() => {
    submitPreRequest && onSubmitPreRequest()
  }, [submitPreRequest])

  useEffect(() => {
    submitBeneficiary && onSubmitPostRequest()
  }, [submitBeneficiary])

  const onSubmitPreRequest = async () => {
    const payload = orchestrateAddBeneficiaryPayload(
      sanitizeValues(beneficiaryFormState) as AddBeneficiaryFormPayload,
      beneficiaryBankType
    )

    const mutatePreRequestErrorHandler = error => {
      if (error?.status === 428) {
        setBeneficiaryFormState({
          ...beneficiaryFormState,
          challengeCode: error?.data?.challenge?.value,
        })

        setModalStep(ModalStep.CONFIRMATION)
      }

      if (error.status === 400) {
        if (
          beneficiaryFormState?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL
        ) {
          const errorMessage = error?.data?.details?.errors?.requestBody[0] || ''
          const isIntermediaryBankError = errorMessage.includes(
            ERROR_MESSAGES.INVALID_INTERMEDIARY_BANK_MESSAGE
          )
          const isNameError = errorMessage.includes(ERROR_MESSAGES.INVALID_BENEFICIARY_NAME_MESSAGE)
          const errorFields = isIntermediaryBankError
            ? ['intermediaryBic']
            : isNameError
              ? ['alias']
              : ['intlIban', 'bicSwiftCode']

          setModalStep(
            isIntermediaryBankError
              ? ModalStep.INTERMEDIARY_BANK_DETAILS
              : isNameError
                ? ModalStep.ADD_BENEFICIARY
                : ModalStep.INTERNATIONAL_BANK_DETAILS
          )
          setInternationalBeneficiaryError(
            errorMessage,
            errorFields,
            beneficiaryFormErrors,
            setBeneficiaryFormErrors
          )
        } else {
          setModalStep(ModalStep.ADD_BENEFICIARY)
          setLocalBeneficiaryError(
            beneficiaryFormState.currencyCode,
            error,
            beneficiaryFormErrors,
            setBeneficiaryFormErrors,
            beneficiaryBankType
          )
        }
      }
    }

    if (beneficiaryFormState?.currencyCode === BeneficiaryDetailsCurrency.NGN) {
      setValidatingBankAccount(true)
      validateNgBankAccount(
        {
          accountNumber: beneficiaryFormState?.ngnAccountNumber,
          bankCode: beneficiaryFormState?.bankCode,
        },
        {
          onSuccess: () => {
            mutatePreRequest(payload, {
              onError: mutatePreRequestErrorHandler,
            })
            setValidatingBankAccount(false)
          },
          onError: (err: BaseErrorResponse) => {
            const errors = err?.data?.errorList?.reduce((fieldErrors: {}, fieldError: Error) => {
              if (fieldError?.parameter && fieldError?.message) {
                return {
                  ...fieldErrors,
                  [fieldError?.parameter]: { message: fieldError?.message },
                }
              }

              return fieldErrors
            }, [])
            setBeneficiaryFormErrors({ ...beneficiaryFormErrors, ...errors })
            setValidatingBankAccount(false)
          },
        }
      )
    } else {
      mutatePreRequest(payload, {
        onError: mutatePreRequestErrorHandler,
      })
    }
    setLoading(false)
    setSubmitPreRequest(false)
  }

  const onSubmitPostRequest = () => {
    const { password, challengeCode } = beneficiaryFormState
    const payload: AddBeneficiaryPostChallengeRequest = {
      challenge: challengeCode,
      tokenResponse: password,
    }

    mutatePostRequest(payload, {
      onSuccess: async () => {
        handleOnSuccessCloseModal()
        await queryClient.invalidateQueries({ queryKey: [reactQueryName.BENEFICIARIES] })
        addToast({
          title: t('manageBeneficiaries.beneficiarySuccess'),
          state: 'success',
        })
        setSubmitBeneficiary(false)
        track.Amp.track(AmplitudeEvent.EOF_ADD_BENEFICIARY_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.VIEW,
        })
      },
      onError: (error: BaseErrorResponse) => {
        const isAliasError = error?.data?.details?.errors?.requestBody[0]?.includes(
          'already in use for this account'
        )
        const bvknErrorCode = error?.data?.code

        if (error.status === 401) {
          setBeneficiaryFormErrors({
            ...beneficiaryFormErrors,
            password: { message: error?.data?.message },
          })
        } else if (error.status === 403) {
          setBeneficiaryFormErrors({ ...beneficiaryFormErrors, password: t('incorrectOtpCode') })
        } else if (bvknErrorCode === 'BEN-MAN-001' && isAliasError) {
          setBeneficiaryFormErrors({
            ...beneficiaryFormErrors,
            alias: { message: t('aliasError') },
          })
          setModalStep(ModalStep.ADD_BENEFICIARY)
        }

        setSubmitBeneficiary(false)
      },
    })
  }

  const beneficiaryTransferDestinationCardButtons: ButtonCardProps[] = [
    {
      label: t('manageBeneficiaries.local'),
      description: t('manageBeneficiaries.localTransferTypeDescription'),
      icon: <Icon name={'MapPin'} size="default" color="primary-500" />,
      onCardClick: () => {
        appendTransferDestination(BeneficiaryTransferDestination.LOCAL)
      },
    },
    {
      label: t('manageBeneficiaries.international'),
      description: t('manageBeneficiaries.internationalTransferTypeDescription'),
      icon: <Icon name={'Globe'} size="default" color="primary-500" />,
      onCardClick: () => {
        appendTransferDestination(BeneficiaryTransferDestination.INTERNATIONAL)
      },
    },
  ]

  const submit = (password: string) => {
    setBeneficiaryFormState({ ...beneficiaryFormState, password })

    setSubmitBeneficiary(true)
  }

  const appendTransferDestination = async (transferDestination: BeneficiaryTransferDestination) => {
    setBeneficiaryFormState({ ...beneficiaryFormState, transferDestination })
    setModalStep(ModalStep.ADD_BENEFICIARY)
  }

  const appendBeneficiaryDetails = async (beneficiaryDetails: AddBeneficiaryBasicPayload) => {
    setBeneficiaryFormState({ ...beneficiaryFormState, ...beneficiaryDetails })
    if (
      beneficiaryFormState?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL
    ) {
      setModalStep(ModalStep.INTERNATIONAL_BANK_DETAILS)
    } else {
      setSubmitPreRequest(true)
    }
  }

  const appendInternationBankDetails = async (bankDetails: AddBeneficiaryBankDetailsPayload) => {
    setBeneficiaryFormState({ ...beneficiaryFormState, ...bankDetails })
    if (
      beneficiaryFormState?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL
    ) {
      setModalStep(ModalStep.INTERMEDIARY_BANK_DETAILS)
    } else {
      setSubmitPreRequest(true)
    }
  }

  const appendIntermediaryBankDetails = async (
    bankDetails: AddIntermediaryBankDetailsPayload,
    skipIntermediaryBankScreen: boolean
  ) => {
    setBeneficiaryFormState({
      ...beneficiaryFormState,
      ...bankDetails,
      skipIntermediaryBankScreen,
    })

    setSubmitPreRequest(true)
  }

  const onBackHandler = () => {
    setBeneficiaryFormErrors({} as AddBeneficiaryFormPayloadErrors)

    switch (modalStep) {
      case ModalStep.TRANSFER_TYPE:
        handleCloseModal()
        break
      case ModalStep.ADD_BENEFICIARY:
        if (enableSwiftBeneficiaryCreation) {
          setModalStep(ModalStep.TRANSFER_TYPE)
        } else {
          handleCloseModal()
        }
        break
      case ModalStep.INTERNATIONAL_BANK_DETAILS:
        setModalStep(ModalStep.ADD_BENEFICIARY)
        break
      case ModalStep.INTERMEDIARY_BANK_DETAILS:
        setModalStep(ModalStep.INTERNATIONAL_BANK_DETAILS)
        break
      case ModalStep.CONFIRMATION:
        if (beneficiaryFormState?.transferDestination === BeneficiaryTransferDestination.LOCAL) {
          setModalStep(ModalStep.ADD_BENEFICIARY)
        } else {
          setModalStep(ModalStep.INTERMEDIARY_BANK_DETAILS)
        }
        break
      default:
        break
    }
  }

  const skipStep = (nextStep: ModalStep) => {
    setModalStep(nextStep)
    setSubmitPreRequest(true)
    setBeneficiaryFormState({ ...beneficiaryFormState, skipIntermediaryBankScreen: true })
  }

  const renderStep = () => {
    if (modalStep === ModalStep.TRANSFER_TYPE) {
      return <CardButtonGroup cards={beneficiaryTransferDestinationCardButtons} />
    }

    if (modalStep === ModalStep.ADD_BENEFICIARY) {
      return (
        <AddBeneficiaryForm
          transferDestination={transferDestination}
          isSubmitting={isSubmitting}
          onBack={onBackHandler}
          onContinue={beneficiaryDetails => appendBeneficiaryDetails(beneficiaryDetails)}
        />
      )
    }

    if (modalStep === ModalStep.INTERNATIONAL_BANK_DETAILS) {
      return (
        <BeneficiaryBankDetails
          isSubmitting={isLoadingPreRequest || loading}
          onBack={onBackHandler}
          onContinue={bankDetails => appendInternationBankDetails(bankDetails)}
        />
      )
    }

    if (modalStep === ModalStep.INTERMEDIARY_BANK_DETAILS) {
      return (
        <IntermediaryBankDetails
          isSubmitting={isSubmitting}
          onBack={onBackHandler}
          onContinue={(bankDetails, skipDetails) =>
            appendIntermediaryBankDetails(bankDetails, skipDetails)
          }
          skipStep={skipStep}
        />
      )
    }

    if (modalStep === ModalStep.CONFIRMATION) {
      return (
        <AddBeneficiaryConfirm
          error={!!beneficiaryFormErrors?.password}
          isLoading={isLoadingPostRequest}
          onContinue={password => submit(password)}
        />
      )
    }
  }

  return (
    <AddBeneficiaryContextProvider
      value={{
        beneficiaryFormState,
        setBeneficiaryFormState,
        beneficiaryIbanValidationState,
        setBeneficiaryIbanValidationState,
        beneficiaryBicValidationState,
        setBeneficiaryBicValidationState,
        beneficiaryFormErrors,
        setBeneficiaryFormErrors,
      }}
    >
      {showModalHeader && (
        <ModalNavBar title={modalHeader} onClose={handleCloseModal} onBack={onBackHandler} />
      )}
      <Form tag="form">{renderStep()}</Form>
    </AddBeneficiaryContextProvider>
  )
}
