import React, { useEffect, useMemo, useState } from 'react'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useDebounce, useFeatureFlags } from '@node-space/hooks'
import Box, { Box as LoadingContainer } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import loadingAnimation from 'components/spinners/LoadingAnimationDark'
import { PathNames } from 'constants/General'
import useBeneficiaryPayoutDisclaimer from 'hooks/useBeneficiaryPayoutDisclaimer'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { BeneficiaryDetails } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { getAccountNumber, getBankCode } from 'utils/bankAccount'
import track from 'utils/tracker'
import {
  formatString,
  generateReference,
  isTestEnvironment,
  roundNumberWithCommas,
  stringToDecimal,
} from 'utils/utils'
import { selectedFiatBeneficiaryAtom } from '../Pay/atoms/atoms'
import { WithdrawActionProps } from './Withdraw'

const WithdrawForm = ({
  dismissAction,
  setStep,
  wallets,
  form,
  loading,
  registerFormField,
  bankAccounts,
  withdrawalLimits,
  hasWithdrawalFeeError,
  isBeneficiaryListError,
  withdrawalFee,
  beneficiaries,
  isFetchingFee,
}: WithdrawActionProps) => {
  const { t } = useTranslation()
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const debouncedWithdrawAmount = useDebounce(withdrawAmount, 500)
  const setSelectedFiatBeneficiaryAtom = useSetAtom(selectedFiatBeneficiaryAtom)
  const { enableFiatCurrencyFilter } = useFeatureFlags()
  const permissions = useMappedRoles()
  const formValues = form.watch()
  const supportedWithdrawCurrencies = enableFiatCurrencyFilter?.supportedWithdrawCurrencies
  const canAddBeneficiary = permissions?.manageBeneficiaries?.canEdit

  useEffect(() => {
    form.setValue('amount', stringToDecimal(withdrawAmount))
    validateAmount(stringToDecimal(withdrawAmount))
  }, [debouncedWithdrawAmount])

  const amountOnChangeHandler = e => {
    setWithdrawAmount(e.currentTarget.value)
  }

  useEffect(() => {
    const bankAccount = bankAccounts?.find(
      bankAccount =>
        bankAccount?.reference?.toString() === formValues?.bankAccountId ||
        bankAccount?.id?.toString() === formValues?.bankAccountId
    )
    const beneficiary: BeneficiaryDetails = beneficiaries?.find(
      beneficiary => beneficiary?.reference === bankAccount?.reference
    )
    setSelectedFiatBeneficiaryAtom(beneficiary)

    if (!bankAccount) {
      form.setValue('reference', '')
    } else if (bankAccount?.beneficiaryPaymentReference) {
      form.setValue('reference', bankAccount?.beneficiaryPaymentReference)
    } else {
      form.setValue('reference', generateReference())
    }
  }, [formValues?.bankAccountId])

  const onSubmit = () => {
    setStep('confirm')
  }

  const supportedWallets = useMemo(() => {
    return wallets.filter(
      x =>
        x?.currency?.code &&
        x?.currency?.fiat &&
        ((!supportedWithdrawCurrencies && x?.currency?.supportsWithdrawals) ||
          supportedWithdrawCurrencies?.includes(x?.currency?.code?.toUpperCase()))
    )
  }, [wallets])

  const selectedWallet = useMemo(() => {
    return (
      formValues?.walletId &&
      supportedWallets?.find(x => x?.id?.toString() === formValues?.walletId)
    )
  }, [formValues?.walletId, supportedWallets])

  const sourceWallets = useMemo(() => {
    return supportedWallets.map(wallet => ({
      icon: wallet?.currency?.code?.toLowerCase(),
      walletIcon: true,
      value: wallet?.id?.toString(),
      label: wallet?.description,
      secondLabel: formatString(
        t('balanceWithCurrency'),
        roundNumberWithCommas(wallet.balance, 2),
        wallet?.currency?.code
      ),
    }))
  }, [supportedWallets])

  const destinationBeneficiaries = useMemo(() => {
    // TODO: Replace and always use reference once legacy payment methods are consolidated
    return bankAccounts
      ?.filter(bankAccount => bankAccount?.currency === selectedWallet?.currency?.code)
      .map(bankAccount => {
        const alias = bankAccount?.alias ? `(${bankAccount?.alias})` : ''

        return {
          icon: bankAccount?.currency?.toLowerCase() || '',
          label: `${bankAccount?.description} ${alias}`,
          value: bankAccount?.id?.toString() || bankAccount?.reference?.toString(),
          secondLabel: ` ${getBankCode(bankAccount)}`,
          secondLabelText: getAccountNumber(bankAccount),
        }
      })
  }, [bankAccounts, selectedWallet?.currency?.code])

  const amountRightLabel = useMemo(() => {
    return (
      selectedWallet &&
      withdrawalLimits?.minimum &&
      formatString(
        t('minimumAmount'),
        roundNumberWithCommas(withdrawalLimits?.minimum, 2),
        selectedWallet?.currency?.code
      )
    )
  }, [selectedWallet, withdrawalLimits?.minimum])

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WITHDRAW_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
    })
  }, [])

  const isInsufficientBalance =
    selectedWallet?.balance < withdrawalLimits?.minimum ||
    withdrawalLimits?.maximum < withdrawalLimits?.minimum

  useEffect(() => {
    if (!formValues?.reference) {
      form?.setValue('reference', generateReference())
    }
  }, [])

  const selectBeneficiaryDropdownPlaceholder = useMemo(() => {
    if (bankAccounts?.length && !destinationBeneficiaries?.length) {
      return t('manageBeneficiaries.beneficiarySelect')
    } else if (!bankAccounts?.length && !destinationBeneficiaries?.length) {
      return t('manageBeneficiaries.noBeneficiaries')
    } else if (!!destinationBeneficiaries?.length && !formValues?.bankAccountId) {
      return t('manageBeneficiaries.beneficiarySelect')
    }
  }, [destinationBeneficiaries, formValues?.bankAccountId, bankAccounts])

  const canContinue = useMemo(() => {
    return (
      !loading &&
      formValues?.bankAccountId &&
      formValues?.amount &&
      !form.formState.errors.amount &&
      !hasWithdrawalFeeError &&
      !isInsufficientBalance &&
      !isBeneficiaryListError
    )
  }, [
    formValues.bankAccountId,
    formValues.amount,
    form.formState.errors.amount,
    hasWithdrawalFeeError,
    loading,
    isInsufficientBalance,
    isBeneficiaryListError,
  ])

  const beneficiaryListHelperText = useBeneficiaryPayoutDisclaimer(
    !!bankAccounts?.length,
    !!destinationBeneficiaries?.length
  )

  const maxAmount = withdrawalFee?.maximumWithdrawalAmount

  const validateAmount = (amount: number | string, onBlur = isTestEnvironment) => {
    const error = checkMinAmount(amount, onBlur) || checkMaxAmount(amount)
    if (error) {
      form.setError('amount', { message: error })
    } else if (hasWithdrawalFeeError) {
      form.setError('amount', { message: t('fetchingFeeError') })
    } else {
      form.clearErrors('amount')
    }
  }

  const amountErrorMinimum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMinimum'),
      roundNumberWithCommas(withdrawalLimits.minimum),
      selectedWallet?.currency?.code
    )

  const amountErrorMaximumFees =
    maxAmount &&
    selectedWallet &&
    formatString(
      t('amountErrorMaximumFees'),
      roundNumberWithCommas(maxAmount),
      selectedWallet?.currency?.code
    )

  const amountErrorMaximum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMaximum'),
      roundNumberWithCommas(withdrawalLimits?.maximum),
      selectedWallet?.currency?.code
    )

  const sourceWalletPlaceholder = useMemo(() => {
    if (!sourceWallets) {
      return t('loadingEllipses')
    } else if (sourceWallets?.length === 0) {
      return t('noWallets')
    } else {
      return t('searchOrSelectAWallet')
    }
  }, [sourceWallets])

  const checkMinAmount = (amount: number | string, onBlur = false) => {
    if (withdrawalLimits?.minimum && Number(amount) < withdrawalLimits?.minimum && onBlur) {
      return amountErrorMinimum
    }
  }

  const checkMaxAmount = (amount: number | string) => {
    // check amount against the maximum amount
    if (Number(amount) > maxAmount) {
      return amountErrorMaximumFees
    } else if (Number(amount) > withdrawalLimits?.maximum) {
      return amountErrorMaximum
    }
  }

  const onAmountErrorClickHandler = () => {
    const { message: amountErrorMessage } = form.formState?.errors?.amount || {}

    if (amountErrorMessage === amountErrorMinimum) {
      setAmount(withdrawalLimits.minimum)
    } else if (amountErrorMessage === amountErrorMaximumFees) {
      setAmount(maxAmount)
    } else if (amountErrorMessage === amountErrorMaximum) {
      setAmount(withdrawalLimits?.maximum)
    }
  }

  const setAmount = (amount: number | string) => {
    form.setValue('amount', amount.toString())
    form.clearErrors('amount')
  }

  const amountOnBlurHandler = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    validateAmount(stringToDecimal(event?.target?.value), true)
  }

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      secondary: true,
      onClick: () => dismissAction(),
    },
    {
      children: t('withdrawPreview'),
      disabled: !canContinue,
      loading: isFetchingFee || loading,
      type: 'submit',
      testid: t('withdrawPreview'),
    },
  ]

  return (
    <Box>
      <ModalNavBar title={t('withdraw')} onClose={dismissAction} />
      <ModalScrollable>
        <Box flex direction="col" padding={24} paddingT={20} gapY={24}>
          {/* wallet selector */}
          <DoubleLabelSelect
            loading={!selectedWallet}
            iconsUrl={process.env.ICONS_URL}
            name={t('selectWallet')}
            label={t('selectWallet')}
            showOverflow
            menuMaxHeight={250}
            placeholder={sourceWalletPlaceholder}
            options={sourceWallets}
            value={selectedWallet ? formValues.walletId : null}
            isSearchable={true}
            iconLeft={!formValues?.walletId && <Icon name="SearchIcon" />}
            onChange={(value: string) =>
              form.reset({ walletId: value }, { keepDefaultValues: true })
            }
            displayTooltipForLongLabel
          />

          {(loading || !selectedWallet) && (
            <LoadingContainer flex justifyContent="center" paddingT={32}>
              {loadingAnimation}
            </LoadingContainer>
          )}

          {!loading && selectedWallet && (
            <>
              {isInsufficientBalance && (
                <Callout
                  state="error"
                  message={t('withdrawInsufficient', {
                    currencyCode: selectedWallet?.currency?.code,
                  })}
                />
              )}

              {isBeneficiaryListError && (
                <Callout state="error" message={t('manageBeneficiaries.beneficiaryFetchError')} />
              )}

              {!isInsufficientBalance && (
                <>
                  <DoubleLabelSelect
                    showOverflow
                    loading={loading}
                    helperText={beneficiaryListHelperText}
                    iconsUrl={process.env.ICONS_URL}
                    label={t('withdrawTo')}
                    menuMaxHeight={250}
                    name={t('withdrawTo')}
                    options={destinationBeneficiaries || []}
                    placeholder={selectBeneficiaryDropdownPlaceholder}
                    rightLabel={
                      canAddBeneficiary && (
                        <Quicklink
                          className="text-primary-500"
                          to={`${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}?callbackUrl=${location.pathname}`}
                          text={t('addNewBeneficiary')}
                          target="_top"
                        />
                      )
                    }
                    value={formValues.bankAccountId}
                    onChange={(value: string) => form.setValue('bankAccountId', value)}
                    displayTooltipForLongLabel
                  />
                  <Input
                    {...registerFormField('amount')}
                    label={t('amount')}
                    testid="amount"
                    postLabel={selectedWallet?.currency?.code}
                    inputMode="decimal"
                    required
                    rightLabel={amountRightLabel}
                    onRightLabelClick={() => {
                      validateAmount(withdrawalLimits?.minimum, true)
                      form.setValue('amount', withdrawalLimits.minimum)
                    }}
                    disabled={!formValues.bankAccountId}
                    onBlur={amountOnBlurHandler}
                    onChange={amountOnChangeHandler}
                    onErrorClick={onAmountErrorClickHandler}
                  />
                  <Input
                    {...registerFormField('reference')}
                    placeholder={t('inputPlaceholders.beneficiaryPaymentReferencePlaceHolder')}
                  />
                </>
              )}

              {!!bankAccounts?.length && !destinationBeneficiaries?.length && (
                <Callout state="warning" message={t('payouts.noSupportedBeneficiaries')} />
              )}
            </>
          )}
        </Box>
      </ModalScrollable>

      {/* actions */}
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalActions actions={actions} currentStep={1} totalSteps={3} />
      </form>
    </Box>
  )
}

export default WithdrawForm
