import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getChannels } from 'services/ChannelService'
import { GetChannelsReq } from 'types/transaction/GetChannelsReq'

export const useChannelsQuery = (req: GetChannelsReq) => {
  return useQuery({
    queryKey: reactQueryKeys.channels(req),
    queryFn: () => getChannels(req),
    placeholderData: keepPreviousData,
    gcTime: 0,
    enabled: false,
  })
}
