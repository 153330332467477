import React from 'react'
import { AlignActions } from '@node-space/storybook-components'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { useReportScheduleQuery } from 'hooks/queries/useReportScheduleQuery'
import { ExportAccordionItems, ExportAccordionItemsType } from 'types/walletTransactionsExport'

interface TransactionExportModalActionsType {
  openAccordion: ExportAccordionItemsType
  onceOffExportModalActions: ButtonProps[]
  recurringExportModalActions: ButtonProps[]
}

export const TransactionExportModalActions = ({
  openAccordion,
  onceOffExportModalActions,
  recurringExportModalActions,
}: TransactionExportModalActionsType) => {
  const { isFetching, isError } = useReportScheduleQuery(
    openAccordion === ExportAccordionItems.RECURRING
  )

  return (
    <>
      {openAccordion === ExportAccordionItems.ONCE_OFF && (
        <ModalActions actions={onceOffExportModalActions} alignActions={AlignActions.RIGHT} />
      )}
      {openAccordion === ExportAccordionItems.RECURRING && !isFetching && !isError && (
        <ModalActions actions={recurringExportModalActions} alignActions={AlignActions.RIGHT} />
      )}
    </>
  )
}
