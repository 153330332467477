import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import Box from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import Text from '@node-space/storybook-components/dist/Text'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'

interface CopyRowProps {
  title: string
  description: string
  copiedValue?: string
  setCopiedValue?: (copiedValue: string) => void
  setCopiedTitle?: (copiedTitle: string) => void
}

const CopyRow = ({
  title,
  description,
  copiedValue,
  setCopiedValue,
  setCopiedTitle,
}: CopyRowProps) => {
  const { t } = useTranslation()

  const handleCopy = () => {
    setCopiedValue && setCopiedValue(description)
    setCopiedTitle && setCopiedTitle(title)
  }

  return (
    <Box flex direction="row" alignItems="center" paddingY={8}>
      <Box className="flex flex-col flex-1 mr-2">
        <Text size="sm" weight="medium">
          {title}
        </Text>
        <Text size="sm">{description}</Text>
      </Box>
      <CopyToClipboard text={description} onCopy={handleCopy}>
        <Box className="cursor-pointer">
          <Tooltip
            position="top"
            bodyContent={copiedValue === description ? t('copied') : t('copy')}
          >
            <Icon name="CopyIcon" color={secondaryButtonIconColour} />
          </Tooltip>
        </Box>
      </CopyToClipboard>
    </Box>
  )
}

export default CopyRow
