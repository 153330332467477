import React from 'react'
import {
  Box as ActionsContainer,
  Box,
  Box as ContextMenuContainer,
  Box as IconCircle,
  Box as TextContainer,
} from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'

export interface ConnectionItem {
  connectionId?: string
  name: string
  description: string
  icon: React.ReactNode
  actions?: React.ReactNode
  contextMenu?: React.ReactNode
}

const ConnectionsListItem = ({
  actions,
  connectionId,
  contextMenu,
  description,
  name,
  icon,
}: ConnectionItem) => {
  return (
    <Box testid="connect-list-item" key={connectionId} flex alignItems="center" gapX={8}>
      <IconCircle
        flex
        centerChildren
        size={32}
        flexSize="keep"
        borderRadius="full"
        background="gray"
        className="group-hover:bg-white"
      >
        {icon}
      </IconCircle>

      <TextContainer flexSize="fill">
        <Text size="sm" weight="medium" className="leading-5">
          {name}
        </Text>
        <Text size="xs" color="text-500" className="leading-5">
          {description}
        </Text>
      </TextContainer>

      {actions && (
        <ActionsContainer flex gapX={12}>
          {actions}
        </ActionsContainer>
      )}

      {contextMenu && (
        <ContextMenuContainer flex gapX={12}>
          {contextMenu}
        </ContextMenuContainer>
      )}
    </Box>
  )
}

export default ConnectionsListItem
