import { useState } from 'react'
import { MappedErrorMessageState } from 'types/errors'

export const useMappedErrorMessage = () => {
  const initialRequestError: MappedErrorMessageState = {
    show: false,
  }

  const [requestError, setRequestError] = useState<MappedErrorMessageState>(initialRequestError)

  const resetRequestError = () => setRequestError(initialRequestError)

  return { initialRequestError, requestError, setRequestError, resetRequestError }
}
