import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { postPay } from 'services/WalletsService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const usePayoutMutation = () => {
  return useMutation({
    mutationFn: postPay,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: usePayoutMutation', error, data)
      }
    },
  })
}
