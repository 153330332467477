import { TFunction } from 'react-i18next'
import {
  AddBeneficiaryBankDetailsPayload,
  AddBeneficiaryBasicPayloadKeys,
  AddBeneficiaryFormPayload,
  AddBeneficiaryFormPayloadErrors,
  AddBeneficiaryPostResponse,
  AddBeneficiaryRequestPreRequest,
  BeneficiaryDetails,
  BeneficiaryDetailsCurrency,
  BeneficiaryEntityType,
  BeneficiaryTransferDestination,
  BeneficiaryType,
  USDBeneficiaryOptions,
  ValidateIbanResponse,
  ValidateScanResponse,
} from 'types/beneficiaries'
import { Currencies } from 'types/types'
import { titleCase } from './utils'

export const resetCurrencySpecificFields = (
  currencyCode: BeneficiaryDetailsCurrency,
  resetField: (field: string) => void,
  beneficiaryBankType: USDBeneficiaryOptions
) => {
  const eurFields = ['iban', 'bic']
  const gbpFields = ['accountNumber', 'sortCode']
  const ngnFields = ['ngnAccountNumber', 'bankCode', 'bankName']
  const usdFields = ['usdAccountNumber', 'abaRoutingNumber']
  const usdCbitFields = ['cbitAddress']
  const zarFields = [
    'zarBank',
    'zarAccountNumber',
    'zarRoutingNumberBranchCode',
    'zarAccountType',
    'bankName',
  ]

  let fieldsToReset: string[] = []
  switch (currencyCode) {
    case BeneficiaryDetailsCurrency.EUR:
      fieldsToReset = [...gbpFields, ...ngnFields, ...usdFields, ...zarFields, ...usdCbitFields]
      break
    case BeneficiaryDetailsCurrency.GBP:
      fieldsToReset = [...eurFields, ...ngnFields, ...usdFields, ...zarFields, ...usdCbitFields]
      break
    case BeneficiaryDetailsCurrency.NGN:
      fieldsToReset = [...eurFields, ...gbpFields, ...usdFields, ...zarFields, ...usdCbitFields]
      break
    case BeneficiaryDetailsCurrency.USD:
      if (beneficiaryBankType === USDBeneficiaryOptions.CBIT) {
        fieldsToReset = [...eurFields, ...gbpFields, ...ngnFields, ...zarFields, ...usdFields]
      } else {
        fieldsToReset = [...eurFields, ...gbpFields, ...ngnFields, ...zarFields, ...usdCbitFields]
      }
      break
    case BeneficiaryDetailsCurrency.ZAR:
      fieldsToReset = [...eurFields, ...gbpFields, ...ngnFields, ...usdFields, ...usdCbitFields]
      break
    default:
      break
  }

  fieldsToReset.forEach(field => resetField(field))
}

export const resetTransferDestinationFields = (
  transferDestination: BeneficiaryTransferDestination,
  resetField: (field: string) => void
) => {
  if (transferDestination === BeneficiaryTransferDestination.LOCAL) {
    const fields = ['addressLine1', 'addressLine2', 'city', 'postCode', 'region']
    fields.forEach(field => resetField(field))
  }
}

export const resetBeneficaryTypeFields = (
  beneficiaryEntityType: BeneficiaryEntityType,
  resetField: (field: string) => void
) => {
  switch (beneficiaryEntityType) {
    case BeneficiaryEntityType.INDIVIDUAL:
      resetField('businessName')
      break
    case BeneficiaryEntityType.BUSINESS:
      resetField('firstName')
      resetField('lastName')
      break
    default:
      break
  }
}

const setCurrencyErrors = (
  currencyCode: BeneficiaryDetailsCurrency,
  error: AddBeneficiaryPostResponse,
  formErrors: AddBeneficiaryFormPayloadErrors,
  setFormStateError: React.Dispatch<React.SetStateAction<AddBeneficiaryFormPayloadErrors>>,
  beneficiaryBankType: USDBeneficiaryOptions
) => {
  const message = error?.data?.details?.errors?.requestBody[0] || ''
  const errorFields = []

  switch (currencyCode) {
    case BeneficiaryDetailsCurrency.EUR:
      errorFields.push('iban', 'bic')
      break
    case BeneficiaryDetailsCurrency.GBP:
      errorFields.push('accountNumber', 'sortCode')
      break
    case BeneficiaryDetailsCurrency.NGN:
      errorFields.push('ngnAccountNumber', 'bankCode')
      break
    case BeneficiaryDetailsCurrency.USD:
      if (beneficiaryBankType === USDBeneficiaryOptions.CBIT) {
        errorFields.push('cbitAddress')
      } else {
        errorFields.push('usdAccountNumber', 'abaRoutingNumber')
      }
      break
    case BeneficiaryDetailsCurrency.ZAR:
      errorFields.push('zarAccountNumber', 'zarRoutingNumberBranchCode')
      break
  }

  errorFields.forEach(field => {
    setFormStateError({
      ...formErrors,
      [field]: { message },
    })
  })
}

export const setInternationalBeneficiaryError = (
  errorMessage: string,
  errorFields: string[],
  formErrors: AddBeneficiaryFormPayloadErrors,
  setFormStateError: React.Dispatch<React.SetStateAction<AddBeneficiaryFormPayloadErrors>>
) => {
  errorFields.forEach(field => {
    setFormStateError({
      ...formErrors,
      [field]: { message: errorMessage },
    })
  })
}

export const setLocalBeneficiaryError = (
  currencyCode: BeneficiaryDetailsCurrency,
  error: AddBeneficiaryPostResponse,
  formErrors: AddBeneficiaryFormPayloadErrors,
  setFormStateError: React.Dispatch<React.SetStateAction<AddBeneficiaryFormPayloadErrors>>,
  beneficiaryBankType: USDBeneficiaryOptions
) => {
  if (error?.data?.code === 'BEN-MAN-001') {
    setCurrencyErrors(currencyCode, error, formErrors, setFormStateError, beneficiaryBankType)
  }
}

export const getFieldsToValidateForCurrency = (
  currencyCode: BeneficiaryDetailsCurrency,
  beneficiaryEntityType: BeneficiaryEntityType,
  transferDestination: BeneficiaryTransferDestination,
  beneficiaryPaymentReference?: string,
  beneficiaryBankTypeType?: USDBeneficiaryOptions
) => {
  const validateFields: AddBeneficiaryBasicPayloadKeys[] = ['countryCode', 'currencyCode', 'alias']

  if (transferDestination === BeneficiaryTransferDestination.INTERNATIONAL) {
    validateFields.push('addressLine1', 'city', 'postCode', 'region')
  } else {
    switch (currencyCode) {
      case BeneficiaryDetailsCurrency.EUR:
        validateFields.push('bic')
        break
      case BeneficiaryDetailsCurrency.GBP:
        validateFields.push('accountNumber', 'sortCode')
        break
      case BeneficiaryDetailsCurrency.NGN:
        validateFields.push('ngnAccountNumber', 'bankCode')
        break
      case BeneficiaryDetailsCurrency.USD:
        if (beneficiaryBankTypeType === USDBeneficiaryOptions.CBIT) {
          validateFields.push('cbitAddress')
        } else {
          validateFields.push('usdAccountNumber', 'abaRoutingNumber')
        }
        break
      case BeneficiaryDetailsCurrency.ZAR:
        validateFields.push(
          'zarBank',
          'zarAccountNumber',
          'zarAccountType',
          'zarRoutingNumberBranchCode'
        )
        break
      default:
        break
    }
  }

  switch (beneficiaryEntityType) {
    case BeneficiaryEntityType.INDIVIDUAL:
      validateFields.push('firstName', 'lastName', 'dateOfBirth')
      break
    case BeneficiaryEntityType.BUSINESS:
      validateFields.push('businessName')
      break
    default:
      break
  }

  if (beneficiaryPaymentReference) {
    validateFields.push('beneficiaryPaymentReference')
  }

  return validateFields
}

export const getIsGbpAccountDetialsValid = (scanValidationResponse: ValidateScanResponse) => {
  const validationErrors = scanValidationResponse?.validationFailed
  return {
    sortCode: !validationErrors?.some(errorMessage => errorMessage?.includes('Sort Code')),
    accountNumber: !validationErrors?.some(
      errorMessage => errorMessage?.includes('Account number') || errorMessage?.includes('Modulus')
    ),
  }
}

const getAccountNumberAndCode = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankTypeType: USDBeneficiaryOptions
) => {
  if (formValues?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL) {
    return {
      accountNumber: formValues.intlIban,
      code: formValues.bicSwiftCode,
    }
  } else {
    switch (formValues.currencyCode) {
      case BeneficiaryDetailsCurrency.GBP:
        return {
          accountNumber: formValues.accountNumber,
          code: formValues.sortCode,
        }
      case BeneficiaryDetailsCurrency.EUR:
        return {
          accountNumber: formValues.iban,
          code: formValues.bic,
        }
      case BeneficiaryDetailsCurrency.NGN:
        return {
          accountNumber: formValues.ngnAccountNumber,
          code: formValues.bankCode,
        }
      case BeneficiaryDetailsCurrency.USD:
        if (beneficiaryBankTypeType === USDBeneficiaryOptions.CBIT) {
          return {
            accountNumber: formValues?.cbitAddress,
            code: formValues?.cbitBankCode,
          }
        } else {
          return {
            accountNumber: formValues?.usdAccountNumber,
            code: formValues?.abaRoutingNumber,
          }
        }
      case BeneficiaryDetailsCurrency.ZAR:
        return {
          accountNumber: formValues?.zarAccountNumber,
          code: formValues?.zarRoutingNumberBranchCode,
          accountType: formValues?.zarAccountType,
        }
      default:
        return {
          accountNumber: '',
          code: '',
        }
    }
  }
}

const getBankAccountDetails = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankTypeType: USDBeneficiaryOptions
) => {
  const accountNumberAndCode = getAccountNumberAndCode(formValues, beneficiaryBankTypeType)

  return {
    ...accountNumberAndCode,
    ...(formValues?.bankName && { bankName: formValues?.bankName }),
    ...(formValues?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL && {
      bankAddress: {
        addressLine1: formValues?.bankAddressLine1,
        addressLine2: formValues?.bankAddressLine2,
        city: formValues?.bankCity,
        postCode: formValues?.bankPostCode,
        region: formValues?.bankRegion,
        country: formValues?.bankCountry,
      },
    }),
  }
}

const getIntermediaryBankDetials = (formValues: AddBeneficiaryFormPayload) => {
  if (
    formValues?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL &&
    !formValues?.skipIntermediaryBankScreen
  ) {
    return {
      intermediaryBank: {
        code: formValues?.intermediaryBic,
        bankName: formValues?.intermediaryBankName,
        bankAddress: {
          addressLine1: formValues?.intermediaryBankAddressLine1,
          addressLine2: formValues?.intermediaryBankAddressLine2,
          city: formValues?.intermediaryBankCity,
          postCode: formValues?.intermediaryBankPostCode,
          region: formValues?.intermediaryBankRegion,
          country: formValues?.intermediaryBankCountry,
        },
      },
    }
  }
  return null // Return null if skipIntermediaryBankScreen is true
}

export const orchestrateAddBeneficiaryPayload = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankTypeType: USDBeneficiaryOptions
): AddBeneficiaryRequestPreRequest => {
  return {
    alias: formValues?.alias,
    entityType: formValues?.beneficiaryEntityType,
    currency: formValues?.currencyCode,
    addressCountry: formValues?.countryCode,
    transferDestination: formValues?.transferDestination || BeneficiaryTransferDestination.LOCAL,
    beneficiaryType: formValues?.beneficiaryType,
    ...(formValues?.beneficiaryPaymentReference && {
      beneficiaryPaymentReference: formValues?.beneficiaryPaymentReference,
    }),
    fiat: {
      ...getBankAccountDetails(formValues, beneficiaryBankTypeType),
      ...getIntermediaryBankDetials(formValues),
    },
    ...(formValues?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL && {
      address: {
        addressLine1: formValues?.addressLine1,
        addressLine2: formValues?.addressLine2,
        city: formValues?.city,
        region: formValues?.region,
        postCode: formValues?.postCode,
        country: formValues?.countryCode,
      },
    }),
    ...(formValues?.beneficiaryEntityType === BeneficiaryEntityType.INDIVIDUAL
      ? {
          firstName: formValues?.firstName,
          lastName: formValues?.lastName,
          ...(formValues?.dateOfBirth && { dateOfBirth: formValues?.dateOfBirth }),
        }
      : {
          businessName: formValues?.businessName,
        }),
  }
}

export const getLocalBankDetailsSummary = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankType: USDBeneficiaryOptions,
  t: TFunction<'translation', undefined>
) => {
  const beneficiaryDetails = getBeneficiaryDetails(formValues, t)
  const currencyAccountDetails = getCurrencyAccountDetails(formValues, beneficiaryBankType, t)
  const accountCurrency = formValues?.currencyCode && {
    [t('accountCurrency')]: formValues?.currencyCode,
  }
  const countryCodeLabel =
    formValues?.beneficiaryEntityType === BeneficiaryEntityType.INDIVIDUAL
      ? t('manageBeneficiaries.countryOfResidence')
      : t('manageBeneficiaries.countryOfRegistration')

  const countryCode = formValues?.countryCode && {
    [countryCodeLabel]: formValues?.countryName ?? formValues?.countryCode,
  }

  return {
    ...beneficiaryDetails,
    ...currencyAccountDetails,
    ...accountCurrency,
    ...countryCode,
  }
}

const getBeneficiaryDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
) => {
  switch (formValues?.beneficiaryEntityType) {
    case BeneficiaryEntityType.INDIVIDUAL:
      return {
        ...((formValues?.firstName || formValues?.lastName) && {
          [t('manageBeneficiaries.firstAndLastName')]:
            `${formValues?.firstName ?? ''} ${formValues?.lastName ?? ''}`,
        }),
        ...(formValues?.alias && {
          [t('beneficiaryName')]: formValues?.alias,
        }),
        ...(formValues?.dateOfBirth && { [t('beneficiaryDateOfBirth')]: formValues?.dateOfBirth }),
        ...(formValues?.beneficiaryPaymentReference && {
          [t('beneficiaryPaymentReference')]: formValues?.beneficiaryPaymentReference,
        }),
      }
    case BeneficiaryEntityType.BUSINESS:
      return {
        ...(formValues?.businessName && {
          [t('businessName')]: formValues?.businessName,
        }),
        ...(formValues?.alias && {
          [t('beneficiaryName')]: formValues?.alias,
        }),
        ...(formValues?.beneficiaryPaymentReference && {
          [t('beneficiaryPaymentReference')]: formValues?.beneficiaryPaymentReference,
        }),
      }
  }
}

const getCurrencyAccountDetails = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankType: USDBeneficiaryOptions,
  t: TFunction<'translation', undefined>
) => {
  switch (formValues?.currencyCode) {
    case Currencies.EUR:
      return getEurDetails(formValues, t)
    case Currencies.GBP:
      return getGbpDetails(formValues, t)
    case Currencies.USD:
      return getUsdDetails(formValues, beneficiaryBankType, t)
    case Currencies.NGN:
      return getNgnDetails(formValues, t)
    case Currencies.ZAR:
      return getZarDetails(formValues, t)
  }
}

const getEurDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(formValues?.iban && { [t('iban')]: formValues.iban }),
    ...(formValues?.bic && { [t('bic')]: formValues.bic }),
  }
}

const getGbpDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(formValues?.accountNumber && { [t('accountNumber')]: formValues.accountNumber }),
    ...(formValues?.sortCode && { [t('sortCode')]: formValues.sortCode }),
  }
}

const getUsdDetails = (
  formValues: AddBeneficiaryFormPayload,
  beneficiaryBankType: USDBeneficiaryOptions,
  t: TFunction<'translation', undefined>
) => {
  if (beneficiaryBankType === USDBeneficiaryOptions.CBIT) {
    return {
      ...(formValues?.cbitAddress && { [t('cbitAddress')]: formValues.cbitAddress }),
    }
  } else {
    return {
      ...(formValues?.usdAccountNumber && { [t('usdAccountNumber')]: formValues.usdAccountNumber }),
      ...(formValues?.abaRoutingNumber && { [t('abaRoutingNumber')]: formValues.abaRoutingNumber }),
    }
  }
}

const getNgnDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(formValues?.ngnAccountNumber && { [t('accountNumber')]: formValues.ngnAccountNumber }),
    ...(formValues?.bankName && { [t('bank')]: formValues?.bankName ?? formValues?.bankCode }),
  }
}

const getZarDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
) => {
  return {
    ...(formValues?.zarAccountNumber && { [t('accountNumber')]: formValues.zarAccountNumber }),
    ...(formValues?.zarRoutingNumberBranchCode && {
      [t('routingNumberBranchCode')]: formValues.zarRoutingNumberBranchCode,
    }),
    ...(formValues?.zarAccountType && { [t('accountType')]: titleCase(formValues.zarAccountType) }),
    ...(formValues?.bankName && { [t('bank')]: formValues?.bankName ?? formValues?.zarBank }),
  }
}

export const getSwiftBankDetails = (
  formValues: AddBeneficiaryFormPayload,
  t: TFunction<'translation', undefined>
): {
  beneficiaryDetails: { [property: string]: string }
  beneficiaryBankDetails: { [property: string]: string }
  intermediaryBankDetails: { [property: string]: string }
} => {
  const beneficiaryDetails = getBeneficiaryDetails(formValues, t)
  return {
    beneficiaryDetails: {
      ...beneficiaryDetails,
      ...(formValues?.currencyCode && {
        [t('accountCurrency')]: formValues?.currencyCode,
      }),
      ...(formValues?.addressLine1 && {
        [t('addressLine1')]: formValues?.addressLine1,
      }),
      ...(formValues?.addressLine2 && {
        [t('addressLine2')]: formValues?.addressLine2,
      }),
      ...(formValues?.city && {
        [t('city')]: formValues?.city,
      }),
      ...(formValues?.region && {
        [t('region')]: formValues?.region,
      }),
      ...(formValues?.postCode && {
        [t('postCode')]: formValues?.postCode,
      }),
      ...(formValues?.countryName && {
        [t('country')]: formValues?.countryName,
      }),
    },
    beneficiaryBankDetails: {
      ...(formValues?.intlIban && { [t('iban')]: formValues?.intlIban }),
      ...(formValues?.bicSwiftCode && { [t('bicSwiftCode')]: formValues?.bicSwiftCode }),
      ...(formValues?.bankName && { [t('bankName')]: formValues?.bankName }),
      ...(formValues?.bankAddressLine1 && { [t('addressLine1')]: formValues?.bankAddressLine1 }),
      ...(formValues?.bankAddressLine2 && { [t('addressLine2')]: formValues?.bankAddressLine2 }),
      ...((formValues?.bankCountry || formValues?.bankCountryName) && {
        [t('country')]: formValues?.bankCountryName ?? formValues?.bankCountry,
      }),
    },
    intermediaryBankDetails: {
      ...(formValues?.intermediaryBankName && {
        [t('manageBeneficiaries.intermediarysBankName')]: formValues?.intermediaryBankName,
      }),
      ...(formValues?.intermediaryBic && {
        [t('bic')]: formValues?.intermediaryBic,
      }),
      ...(formValues?.intermediaryBankAddressLine1 && {
        [t('addressLine1')]: formValues?.intermediaryBankAddressLine1,
      }),
      ...(formValues?.intermediaryBankAddressLine2 && {
        [t('addressLine2')]: formValues?.intermediaryBankAddressLine2,
      }),
      ...(formValues?.intermediaryBankCity && {
        [t('city')]: formValues?.intermediaryBankCity,
      }),
      ...(formValues?.intermediaryBankPostCode && {
        [t('postCode')]: formValues?.intermediaryBankPostCode,
      }),
      ...((formValues?.intermediaryBankCountryName || formValues?.intermediaryBankCountry) && {
        [t('country')]:
          formValues?.intermediaryBankCountryName ?? formValues?.intermediaryBankCountry,
      }),
    },
  }
}

const cleanIbanValidationResponse = (bankDetails: ValidateIbanResponse): ValidateIbanResponse => {
  return {
    bankAccount: {
      type: bankDetails?.bankAccount?.type,
      bankName: bankDetails?.bankAccount?.bankName?.trim(),
      accountNumber: bankDetails?.bankAccount?.accountNumber?.trim(),
      bankCode: bankDetails?.bankAccount?.bankCode?.trim(),
      bankAddress: {
        addressLine1: bankDetails?.bankAccount?.bankAddress?.addressLine1?.trim(),
        addressLine2: bankDetails?.bankAccount?.bankAddress?.addressLine2?.trim(),
        city: bankDetails?.bankAccount?.bankAddress?.city?.trim(),
        region: bankDetails?.bankAccount?.bankAddress?.region?.trim(),
        postCode: bankDetails?.bankAccount?.bankAddress?.postCode?.trim(),
        countryCode: bankDetails?.bankAccount?.bankAddress?.countryCode?.trim(),
      },
    },
    validationFailed: bankDetails?.validationFailed,
    supportedPaymentMethods: bankDetails?.supportedPaymentMethods,
  }
}

export const setFieldsFetchedFromIbanValidation = (
  ibanValidationResponse: ValidateIbanResponse
): AddBeneficiaryBankDetailsPayload => {
  const cleanDetail = cleanIbanValidationResponse(ibanValidationResponse)

  if (!cleanDetail?.validationFailed?.length) {
    const { bankCode, bankName, bankAddress, accountNumber } = cleanDetail?.bankAccount || {}

    return {
      ...(accountNumber && {
        intlIban: accountNumber,
      }),
      ...(bankCode && {
        bicSwiftCode: bankCode,
      }),
      ...(bankName && {
        bankName: bankName,
      }),
      ...(bankAddress?.addressLine1 && {
        bankAddressLine1: bankAddress?.addressLine1,
      }),
      ...(bankAddress?.addressLine2 && {
        bankAddressLine2: bankAddress?.addressLine2,
      }),
      ...(bankAddress?.city && {
        bankCity: bankAddress?.city,
      }),
      ...(bankAddress?.postCode && {
        bankPostCode: bankAddress?.postCode,
      }),
      ...(bankAddress?.region && {
        bankRegion: bankAddress?.region,
      }),
      ...(bankAddress?.countryCode && {
        bankCountry: bankAddress?.countryCode,
      }),
    }
  }
}

export const getEntityType = (
  beneficiaryEntityType: BeneficiaryEntityType,
  t: TFunction<'translation', undefined>
): string => {
  switch (beneficiaryEntityType) {
    case BeneficiaryEntityType.BUSINESS:
      return t('manageBeneficiaries.business')
    case BeneficiaryEntityType.INDIVIDUAL:
      return t('manageBeneficiaries.individual')
    default:
      return ''
  }
}

export const getBeneficiaryType = (
  beneficiaryType: BeneficiaryType,
  t: TFunction<'translation', undefined>
): string => {
  switch (beneficiaryType) {
    case BeneficiaryType.FIRST_PARTY:
      return t('internal')
    case BeneficiaryType.THIRD_PARTY:
      return t('external')
    default:
      return ''
  }
}

export const getBeneficiaryName = (beneficiary: BeneficiaryDetails): string => {
  return beneficiary?.entityType === BeneficiaryEntityType.INDIVIDUAL
    ? `${beneficiary?.firstName} ${beneficiary?.lastName}`
    : beneficiary?.businessName
}

export const getInternationalAccountNumberLabel = (
  currency: string,
  t: TFunction<'translation', undefined>
) => {
  if (currency === Currencies.USD) {
    return t('accountNumber')
  } else {
    return t('iban')
  }
}

export const getInternationalAccountNumberPlaceholder = (
  currency: string,
  t: TFunction<'translation', undefined>
) => {
  if (currency === Currencies.USD) {
    return t('inputPlaceholders.enterAccountNumberPlaceholder')
  } else {
    return t('inputPlaceholders.enterIbanPlaceholder')
  }
}

export const getInternationalBankCodeLabel = (
  currency: string,
  t: TFunction<'translation', undefined>
) => {
  if (currency === Currencies.USD) {
    return t('swiftCode')
  } else {
    return t('bic')
  }
}

export const getInternationalBankCodePlaceholder = (
  currency: string,
  t: TFunction<'translation', undefined>
) => {
  if (currency === Currencies.USD) {
    return t('inputPlaceholders.enterSwiftPlaceholder')
  } else {
    return t('inputPlaceholders.enterBicPlaceholder')
  }
}

export const getTransferDestinations = (
  supportedTransferDestinations: BeneficiaryTransferDestination[]
) => {
  return supportedTransferDestinations?.length
    ? supportedTransferDestinations
    : [BeneficiaryTransferDestination.LOCAL]
}
