import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPendingAgreements } from 'services/AgreementsService'

export const usePendingAgreementsQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.pendingAgreements(),
    queryFn: getPendingAgreements,
    enabled: isEnabled,
  })
}
