import React, { useMemo } from 'react'
import { useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { routeConfig } from './configuration'

export type BreadcrumbProps = {
  customConfig?: CustomBreadCrumbConfig[]
}

export type CustomBreadCrumbConfig = {
  title: string
  link?: string
}

type BreadCrumb = {
  title?: string
  link?: string
  showSeparator?: boolean
}

const Breadcrumbs = ({ customConfig }: BreadcrumbProps) => {
  const location = useLocation()
  const { t } = useTranslation()
  const isMobileScreen = useMediaQuery('(max-width: 640px)')

  const breadcrumbs = useMemo(() => {
    const pathArray = location.pathname.split('/').filter(crumb => !!crumb)
    const finalBreadcrumbs: BreadCrumb[] = []

    if (customConfig) {
      customConfig.forEach((breadcrumbItem, index) => {
        finalBreadcrumbs.push({ title: t(breadcrumbItem.title), link: breadcrumbItem?.link })

        if (customConfig.length > index + 1) {
          finalBreadcrumbs.push({ showSeparator: true }) // Separator
        }
      })
    } else {
      pathArray.forEach(crumb => {
        const breadcrumbConfig = routeConfig[`/${crumb}`]

        if (breadcrumbConfig) {
          // Incorporate predefined breadcrumb items if they exist
          if (breadcrumbConfig.breadcrumbs) {
            breadcrumbConfig.breadcrumbs.forEach(breadcrumbItem => {
              finalBreadcrumbs.push(breadcrumbItem)
              finalBreadcrumbs.push({ showSeparator: true }) // Separator
            })
          }

          // Add current breadcrumb
          finalBreadcrumbs.push({ title: breadcrumbConfig.title, link: null })
        }
      })
    }

    return finalBreadcrumbs
  }, [location.pathname, customConfig])

  if (!breadcrumbs.length) {
    return null
  }

  if (isMobileScreen) {
    return (
      <Box flex>
        <Text size="base" className="font-semibold text-gray-900 pl-2">
          {t(breadcrumbs[breadcrumbs.length - 1]?.title)}
        </Text>
      </Box>
    )
  }

  return (
    <Box flex>
      {breadcrumbs.map((cr, index) => (
        <Box key={index} flex>
          {cr?.showSeparator ? (
            <Box flex alignItems="center" width={36} paddingX={12}>
              <Icon name="CaretRightIcon" color="inherit" />
            </Box>
          ) : (
            cr?.title && (
              <Text size="lg" className="sm:text-xl font-semibold text-gray-900">
                {cr?.link ? <Link to={cr.link}>{t(cr.title)}</Link> : t(cr.title)}
              </Text>
            )
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Breadcrumbs
