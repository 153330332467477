import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import { PayForm, PaySteps } from '../components/Pay/Pay'
import paySchema from '../schemas/paySchema'
import useWalletActionApi from './useWalletActionApi'

interface PayActionMeta {
  beneficiaryOptionId?: string
}
const usePayAction = (sharedActionProps: WalletActionSharedProps, metaData?: PayActionMeta) => {
  const { t } = useTranslation()
  const { beneficiaryOptionId } = metaData || {}

  const [payStep, setPayStep] = useState<PaySteps>('form')
  const payActionApi = useWalletActionApi<PayForm, PaySteps>({
    intialStep: 'form',
    step: payStep,
    setStep: setPayStep,
    formSchema: paySchema({ t, step: payStep }),
    ...(beneficiaryOptionId && {
      defaultFormValues: { beneficiarySelection: beneficiaryOptionId },
    }),
    ...sharedActionProps,
  })

  return payActionApi
}

export default usePayAction
