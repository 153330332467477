import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Notabene,
  NotabeneError,
  NotabeneTransaction,
  TransactionType,
} from 'types/notabene/notabene'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { notabeneWidgetStyles, notabeneWidgetTheme } from './utils/NotabeneUtils'

export interface NotabeneWidgetProps {
  customerToken?: string
  transaction?: NotabeneTransaction
  transactionType: TransactionType
  vaspDID?: string
  widgetBaseURL: string
  handleValidStateChange: (valid: boolean, tx: NotabeneTransaction) => void
  onError: (err: NotabeneError) => void
}

export default function NotabeneWidget({
  customerToken,
  transaction,
  transactionType,
  vaspDID,
  widgetBaseURL,
  handleValidStateChange,
  onError,
}: NotabeneWidgetProps) {
  // This to prevent re-rendering and Javascipt window object issues with Notabene widget's iframe window
  const rendered = useRef(false)
  const notabene = useRef<null | Notabene>(null)
  const { t } = useTranslation()
  const [containerExists, setContainerExists] = useState(false)
  const { transactionAmount, transactionAsset, beneficiaryAccountNumber, originatorAccountNumber } =
    (transaction || {}) as NotabeneTransaction

  const onValidStateChange = (valid: boolean) => {
    if (notabene.current?.tx) handleValidStateChange(valid, notabene.current.tx)
  }

  const onWidgetError = (err: NotabeneError) => {
    onError(err)
  }

  useEffect(() => {
    const containerElement = document.getElementById('notabeneWidgetContainer')
    setContainerExists(containerElement !== null)
  }, [transaction])

  const containerElement = document.getElementById('notabeneWidgetContainer')
  useEffect(() => {
    if (!transaction || !containerExists) {
      return
    }

    const render = async () => {
      // Avoid rendering twice and without window available.
      if (!window || rendered.current) {
        return
      }

      rendered.current = true

      const { Notabene } = await import('@notabene/javascript-sdk/dist/lib/index')

      const nb = new Notabene({
        vaspDID: vaspDID,
        widget: widgetBaseURL,
        container: '#notabeneWidgetContainer',
        authToken: customerToken,
        theme: notabeneWidgetTheme,
        onValidStateChange,
        onWidgetError,
      })

      nb.setTransaction({
        transactionAsset,
        transactionAmount,
        ...(beneficiaryAccountNumber && { beneficiaryAccountNumber }),
        ...(originatorAccountNumber && { originatorAccountNumber }),
      })

      notabene.current = nb

      nb.renderWidget(transactionType)
    }

    render()
  }, [transactionType, JSON.stringify(transaction), containerElement])

  // Destroy widget only when component is removed.
  useEffect(() => {
    return () => {
      if (!notabene?.current) return
      notabene.current.destroyWidget()
      rendered.current = false
    }
  }, [])

  // Calling setTransaction trigger a reload in the widget.
  useEffect(() => {
    if (!notabene?.current) return

    notabene.current.setTransaction({
      transactionAsset,
      transactionAmount,
      ...(beneficiaryAccountNumber && { beneficiaryAccountNumber }),
      ...(originatorAccountNumber && { originatorAccountNumber }),
    })
  }, [transactionAmount, transactionAsset, beneficiaryAccountNumber, originatorAccountNumber])

  if (!transaction) {
    return (
      <>
        <Box flex flexCol alignItems="center" paddingB={16}>
          <Box flex alignItems="center" justifyContent="center" flexJustifyCenter paddingB={16}>
            <Icon name="ErrorCircleIcon" color={'status-error-500'} size="lg" />
          </Box>
          <Box paddingB={16}>
            <Text align="center" size="lg" weight="medium" color="text-900" tag="h3">
              {t('oopsSomethingWentWrongContracted')}
            </Text>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <style>{notabeneWidgetStyles}</style>
      <div id="notabeneWidgetContainer" />
    </>
  )
}
