import React from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'

interface HighlightProps {
  children: React.ReactNode
}

/**
 * @deprecated To be replaced with Shady highlight component
 */
const Highlight = ({ children }: HighlightProps) => {
  return (
    <Box borderRadius="full" className="px-2 py-0.5 ml-2 bg-purple-500">
      <Text size="xs" weight="medium" color="grey-01">
        {children}
      </Text>
    </Box>
  )
}

export default Highlight
