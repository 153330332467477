import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import {
  patchMassFiatPayoutsPreChallengeRequest,
  postMassFiatPayoutsPostChallengeRequest,
} from 'services/MasspayoutService'
import { BaseErrorResponse } from 'types/beneficiaries'

export const useCreateMassFiatPayoutsPreRequest = () => {
  return useMutation({
    mutationFn: patchMassFiatPayoutsPreChallengeRequest,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useCreateMassFiatPayoutsPreRequest', error, {
          data: typeof data === 'string' ? { message: data } : data,
        })
      }
    },
  })
}

export const useCreateMassFiatPayoutsPostRequest = () =>
  useMutation({ mutationFn: postMassFiatPayoutsPostChallengeRequest })
