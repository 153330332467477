import { useEffect, useState } from 'react'
import { PaginationPaging } from 'types/pagination'

export const usePaginationPagingPersist = (pagination: PaginationPaging) => {
  const [paging, setPaging] = useState<PaginationPaging>()
  const showPagination: boolean = paging?.total <= paging?.max

  useEffect(() => {
    if (pagination) {
      setPaging(pagination)
    }
  }, [pagination])

  return {
    paging,
    showPagination,
  }
}
