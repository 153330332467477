import React from 'react'
import { FieldErrorsImpl } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Icon, Select, Tooltip } from '@node-space/storybook-components'
import { Box as FormRow } from '@node-space/storybook-components'

interface DisplayCurrencyFieldProps {
  currencyList: { value: string; label: string }[]
  onChangeHandler: (name: string, value: string) => void
  selectedCurrency: string
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >
  isLoading: boolean
}

const DisplayCurrencyField = ({
  currencyList,
  onChangeHandler,
  selectedCurrency,
  errors,
  isLoading,
}: DisplayCurrencyFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormRow className="flex flex-wrap -mx-3 mb-6 px-3">
      <div className="w-full">
        <Select
          label={
            <>
              <label className="mr-2">{t('displayCurrency')}</label>
              <Tooltip
                id="display-currency-tooltip"
                position="right"
                bodyContent={`${t('payments.channels.tooltips.displayCurrency')}`}
                className="w-72 whitespace-normal"
              >
                <Icon name="InfoCircleIcon" size="sm" />
              </Tooltip>
            </>
          }
          name="displayCurrency"
          options={currencyList}
          onChange={value => {
            onChangeHandler('displayCurrency', value)
          }}
          value={selectedCurrency}
          error={Boolean(errors?.displayCurrency)}
          errorText={errors?.displayCurrency?.message}
          loading={isLoading}
          isSearchable
        />
      </div>
    </FormRow>
  )
}

export default React.memo(DisplayCurrencyField)
