import React, { useEffect } from 'react'
import { useListState } from '@mantine/hooks'
import { toLower } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { TimelineColor, TimelineRow } from '@node-space/storybook-components'
import { PaymentsResponse, PaymentStatus, PaymentType } from 'types/payments'
import { calculatePercentageDiff } from 'utils/utils'
import { PaymentTimelineRowInfo } from '../components/Table/Timeline/PaymentTimelineRowInfo'

export const useUpdateTimeline = (transaction: PaymentsResponse) => {
  const { t } = useTranslation()
  const isPaymentIn = transaction?.type === PaymentType.IN
  const amountDue = transaction?.walletCurrency
    ? transaction.paidCurrency?.amount - transaction.paidCurrency?.actual
    : 0

  const [values, handlers] = useListState<TimelineRow>([
    {
      color: TimelineColor.BLUE,
      date: transaction?.dateCreated,
      component: (
        <PaymentTimelineRowInfo
          generalInfo={t('newPaymentInfoTimeline', {
            paymentType: isPaymentIn ? t('payments.in') : t('payments.out'),
          })}
          boldInfo={`${transaction?.displayCurrency?.amount} ${transaction?.displayCurrency?.currency}`}
          testid="timeline-action-of-value"
        />
      ),
    },
  ])

  useEffect(() => {
    const canMarkPaymentComplete = transaction?.status === PaymentStatus.PROCESSING
    const isUnderpaidStatus = transaction?.status === PaymentStatus.UNDERPAID
    const isOverpaidStatus = transaction?.status === PaymentStatus.OVERPAID

    let updatedTimelineRows: TimelineRow[] = [...values]

    if (
      transaction.quoteStatus !== 'TEMPLATE' &&
      transaction.quoteStatus !== 'PENDING' &&
      values.length === 1
    ) {
      updatedTimelineRows.push({
        color: TimelineColor.BLUE,
        component: (
          <PaymentTimelineRowInfo
            generalInfo={t('customerAcceptedQuoteTimeline', {
              action: isPaymentIn
                ? t('transactionAction.pay').toLowerCase()
                : t('transactionAction.receive').toLowerCase(),
            })}
            boldInfo={`${transaction?.paidCurrency?.amount} ${transaction?.paidCurrency?.currency}`}
          />
        ),
      })
    }

    if (transaction?.transactions?.length > 1 && !isUnderpaidStatus && !isOverpaidStatus) {
      transaction?.transactions?.forEach(current => {
        updatedTimelineRows.push({
          color: TimelineColor.BLUE,
          date: current?.dateConfirmed,
          component: (
            <PaymentTimelineRowInfo
              generalInfo={t('paymentOfTimeline', {
                action: isPaymentIn ? t('payments.received') : t('payments.sent'),
              })}
              testid="timeline-action-of-value"
              boldInfo={`${current?.amount} ${transaction?.paidCurrency?.currency}`}
            />
          ),
        })
      })
    }

    switch (transaction?.status) {
      case PaymentStatus.EXPIRED:
        if (transaction?.expiryDate <= Date.now()) {
          updatedTimelineRows.push({
            date: transaction?.expiryDate,
            color: TimelineColor.RED,
            component: <PaymentTimelineRowInfo generalInfo={t('paymentExpired')} />,
          })
        }
        if (transaction?.latePayment) {
          updatedTimelineRows.push({
            color: TimelineColor.RED,
            component: (
              <PaymentTimelineRowInfo
                generalInfo={t('latePaymentDescription', {
                  paidAmount: transaction?.paidCurrency?.actual,
                  paidCurrency: transaction?.paidCurrency?.currency,
                })}
              />
            ),
          })
        }
        break

      case PaymentStatus.UNDERPAID:
        transaction?.transactions?.forEach(current => {
          updatedTimelineRows.push({
            color: TimelineColor.BLUE,
            date: current?.dateConfirmed,
            component: (
              <PaymentTimelineRowInfo
                generalInfo={t('paymentOfTimeline', {
                  action: isPaymentIn ? t('payments.received') : t('payments.sent'),
                })}
                testid="timeline-action-of-value"
                boldInfo={`${current?.amount} ${transaction?.paidCurrency?.currency}`}
              />
            ),
          })
        })

        updatedTimelineRows.push({
          color: TimelineColor.ORANGE,
          component: isPaymentIn ? (
            <PaymentTimelineRowInfo
              generalInfo={t('amountDue')}
              testid="timeline-amount-due-value"
              boldInfo={`${amountDue} ${transaction?.paidCurrency?.currency}${
                canMarkPaymentComplete
                  ? ` (${calculatePercentageDiff(
                      transaction?.paidCurrency?.amount,
                      transaction?.paidCurrency?.actual,
                      'remaining'
                    )} ${toLower(t('owing'))})`
                  : ''
              }`}
            />
          ) : (
            <PaymentTimelineRowInfo generalInfo={t('paymentIsProcessing')} />
          ),
        })
        break

      case PaymentStatus.OVERPAID:
        transaction?.transactions?.forEach(current => {
          updatedTimelineRows.push({
            color: TimelineColor.BLUE,
            date: current?.dateConfirmed,
            component: (
              <PaymentTimelineRowInfo
                generalInfo={t('paymentOfTimeline', {
                  action: isPaymentIn ? t('payments.received') : t('payments.sent'),
                })}
                testid="timeline-action-of-value"
                boldInfo={`${current?.amount} ${transaction?.paidCurrency?.currency}`}
              />
            ),
          })
        })
        break
      case PaymentStatus.COMPLETE:
        const latestTransaction = transaction?.transactions?.reduce((latest, current) => {
          return new Date(current?.dateConfirmed) > new Date(latest?.dateConfirmed)
            ? current
            : latest
        }, transaction?.transactions?.[0])

        updatedTimelineRows.push({
          color: TimelineColor.GREEN,
          date: latestTransaction?.dateConfirmed,
          component: (
            <PaymentTimelineRowInfo
              generalInfo={t('paymentOfTimeline', {
                action: isPaymentIn ? t('payments.received') : t('payments.sent'),
              })}
              testid="timeline-action-of-value"
              boldInfo={`${transaction?.paidCurrency?.actual} ${transaction?.paidCurrency?.currency}${
                canMarkPaymentComplete
                  ? ` (${calculatePercentageDiff(
                      transaction?.paidCurrency?.amount,
                      transaction?.paidCurrency?.actual,
                      'paid'
                    )} ${toLower(t('paid'))})`
                  : ''
              }`}
            />
          ),
        })
        break

      default:
        break
    }

    if (!!transaction?.hasEnhancedChecks) {
      updatedTimelineRows.push({
        color: TimelineColor.ORANGE,
        component: <PaymentTimelineRowInfo generalInfo={t('payments.statusOnHoldTimeline')} />,
      })
    }

    if (updatedTimelineRows?.length > values?.length) {
      updatedTimelineRows = updatedTimelineRows.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      handlers.setState(updatedTimelineRows)
    }
  }, [transaction])

  return values
}
