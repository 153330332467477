import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Box } from '@node-space/storybook-components/dist/Box'
import {
  SimpleTableV2,
  TableColumnV2,
  TableRowV2,
} from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import { DateFormat } from 'constants/General'
import { LegalAgreementDetailsType, LegalAgreementStatus, LegalAgreementTableType } from './types'
import { usePendingAgreementsContext } from 'hooks/context/usePendingAgreementsContext'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import OnClickProvider from 'components/OnClickProvider/OnClickProvider'

export type LegalAgreementsTableProps = {
  agreementsList: LegalAgreementTableType[]
  isLoading: boolean
  onOpenAgreementDetails: (index: number) => void
}

export const LegalAgreementsTable = ({
  agreementsList,
  isLoading,
  onOpenAgreementDetails,
}: LegalAgreementsTableProps) => {
  const { t } = useTranslation()

  const { setShouldRefetch, setShowSingleAgreementId } = usePendingAgreementsContext()

  const tableHeadClasses = 'py-3 px-1'
  const tableRowClasses = 'py-5 px-1'

  const tableColumns: TableColumnV2[] = [
    { headerTitle: t('legalAgreements.name'), className: `${tableHeadClasses} pl-4` },
    { headerTitle: t('dateAccepted'), className: tableHeadClasses },
    { headerTitle: t('acceptedBy'), className: tableHeadClasses },
    { headerTitle: t('status'), width: '73px', className: `${tableHeadClasses} pr-4` },
    { headerTitle: '', width: '68px' },
  ]

  const tableData: TableRowV2[] = isLoading
    ? []
    : agreementsList?.map((agreement: LegalAgreementTableType, agreementIndex: number) => {
        const agreementDetails: LegalAgreementDetailsType = agreement?.agreement

        const dateSigned =
          agreement?.status === LegalAgreementStatus?.SIGNED && !!agreement?.lastUpdated
            ? dayjs(agreement?.lastUpdated).format(DateFormat.DD_MMM_YYYY)
            : ''

        const userInitial = agreement?.user?.firstName?.substring(0, 1)
        const signedBy = agreement?.user ? `${userInitial}. ${agreement?.user?.lastName}` : ''

        return {
          rowData: [
            {
              cellData: (
                <OnClickProvider
                  id={agreementDetails?.reference}
                  onClick={() => onOpenAgreementDetails(agreementIndex)}
                >
                  <Text size="sm">{agreementDetails?.name}</Text>{' '}
                </OnClickProvider>
              ),
              className: `${tableRowClasses} pl-4`,
            },
            {
              cellData: dateSigned,
              className: tableRowClasses,
            },
            {
              cellData: signedBy,
              className: tableRowClasses,
            },
            {
              cellData: (
                <Box width="full">
                  <StatusPill
                    state={agreement?.statusPill?.state}
                    label={agreement?.statusPill?.label}
                  />
                </Box>
              ),
              className: tableRowClasses,
            },
            {
              cellData: (
                <TablePopoverMenu
                  testid={agreementDetails?.name}
                  menuItems={[
                    {
                      iconName: 'InfoCircleIcon',
                      label: t('legalAgreements.details'),
                      onClick: () => onOpenAgreementDetails(agreementIndex),
                    },
                    {
                      ...(agreement?.status === LegalAgreementStatus.SIGNED
                        ? {
                            iconName: 'DocumentDownloadIcon',
                            label: t('download'),
                            onClick: () => window.open(agreementDetails?.url),
                          }
                        : {
                            iconName: 'DocumentsIcon',
                            label: t('reviewAndAccept'),
                            onClick: () => {
                              setShowSingleAgreementId(agreement?.id)
                              setShouldRefetch(true)
                            },
                          }),
                    },
                  ]}
                />
              ),
              className: `${tableRowClasses} pr-4`,
              align: 'end',
            },
          ],
        }
      })

  return <SimpleTableV2 columns={tableColumns} tableData={tableData} isLoading={isLoading} />
}
