import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as OuterWrapper } from '@node-space/storybook-components/dist/Box'
import { Select } from '@node-space/storybook-components/dist/Select'
import { Text } from '@node-space/storybook-components/dist/Text'
import useFilterStatus from 'components/FilterBar/hooks/useFilterStatus'
import { PaymentStatus, PaymentType } from 'types/payments'
import { titleCase } from 'utils/utils'

type FilterStatusProps = {
  onPaymentStatusChanged: (value: string) => void
  isChannel: boolean
}

const FilterStatus = ({ onPaymentStatusChanged, isChannel }: FilterStatusProps) => {
  const { t } = useTranslation()

  const { status } = useFilterStatus()

  const paymentStatusOptions = [
    { value: PaymentStatus.ALL, label: titleCase(PaymentStatus.ALL) },
    { value: PaymentStatus.PENDING, label: titleCase(PaymentStatus.PENDING) },
    { value: PaymentStatus.PROCESSING, label: titleCase(PaymentStatus.PROCESSING) },
    { value: PaymentStatus.EXPIRED, label: titleCase(PaymentStatus.EXPIRED) },
    { value: PaymentStatus.UNDERPAID, label: titleCase(PaymentStatus.UNDERPAID) },
    { value: PaymentStatus.CANCELLED, label: titleCase(PaymentStatus.CANCELLED) },
    { value: PaymentStatus.COMPLETE, label: titleCase(PaymentStatus.COMPLETE) },
  ]

  const channelPaymentStatusOptions = [
    { value: PaymentStatus.ALL, label: titleCase(PaymentStatus.ALL) },
    { value: PaymentStatus.DETECTED, label: titleCase(PaymentStatus.DETECTED) },
    { value: PaymentStatus.COMPLETE, label: titleCase(PaymentStatus.COMPLETE) },
  ]

  const onStatusChange = (status: PaymentType) => {
    onPaymentStatusChanged(status)
  }

  return (
    <OuterWrapper testid="filter-status-field-wrapper" className="col-span-6">
      <Text size="sm" weight="medium" className="mb-1">
        {t('status')}
      </Text>
      <Select
        data-testid="filter-status-field"
        value={status}
        options={isChannel ? channelPaymentStatusOptions : paymentStatusOptions}
        onChange={onStatusChange}
        isSearchable={false}
      />
    </OuterWrapper>
  )
}

export default FilterStatus
