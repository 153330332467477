import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'
import { MassPayoutFileUpload } from '../components/FileUpload/MassPayoutFileUpload'
import MassPayoutHeader from '../components/MassPayoutHeader'
import MassPayoutModal from '../components/MassPayoutModal'
import { MassPayoutSubmitButton } from '../components/MassPayoutSubmitButton'
import { MassPayoutTypeSelection } from '../components/MassPayoutTypeSelection'
import { MassPayoutWalletSelection } from '../components/MassPayoutWalletSelection'
import { MassPayoutButtonGroup } from '../types'

export const MassPayoutsUpload = () => {
  const { t } = useTranslation()

  return (
    <Box flex direction="col" width="full">
      <MassPayoutHeader buttonGroup={MassPayoutButtonGroup?.BATCH_UPLOAD} />
      <Box
        flex
        direction="col"
        className="justify-center items-center gap-y-6 w-full border border-gray-04 bg-white py-14 bg-primary"
      >
        <Box flex direction="col" className="gap-y-6 w-100">
          <Text size="xl" weight="medium" align="center">
            {t('massPayouts.createNewMassPayout')}
          </Text>
          <MassPayoutTypeSelection />
          <MassPayoutWalletSelection />
          <MassPayoutFileUpload />
          <MassPayoutSubmitButton />
          <MassPayoutModal />
        </Box>
      </Box>
    </Box>
  )
}
