import React, { useEffect, useState } from 'react'
import { getCookie } from '@node-space/rest-api'
import { logSentryError } from '@node-space/utils'
import General from 'constants/General'
import { useChatBotQuery } from 'hooks/queries/useChatBot'
import { decodeJwtPayload, isWithinMinutesFromNow, TEN_MINUTES } from 'utils/utils'

declare global {
  interface Window {
    zE: unknown
  }
}
interface ChatbotProps {
  isAuthed: boolean
}

const ChatBot = ({ isAuthed }: ChatbotProps) => {
  const [cookieAuthToken, setCookieAuth] = useState(() => getCookie(General.ZENDESK_COOKIE_NAME))
  const { data: token, refetch, isError, error } = useChatBotQuery()
  const availableToken = token || cookieAuthToken
  const expiryTime = availableToken ? (decodeJwtPayload(availableToken)?.exp as number) : null

  const loadChatbotScript = () => {
    const scriptId = 'ze-snippet'
    if (document.getElementById(scriptId)) {
      if (window?.zE) {
        window.zE('messenger', 'loginUser', callback => {
          callback(availableToken)
        })
      }
    }

    const zendeskScript = document.createElement('script')
    zendeskScript.src = process.env.ZENDESK_CHATBOT_SCRIPT_URL
    zendeskScript.async = true
    zendeskScript.id = scriptId
    document.body.appendChild(zendeskScript)

    zendeskScript.onload = () => {
      if (window?.zE) {
        window.zE('messenger', 'loginUser', callback => {
          callback(availableToken)
        })
      }
      window.zE('messenger:set', 'zIndex', 29)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentCookie = getCookie(General.ZENDESK_COOKIE_NAME)

      if (currentCookie !== cookieAuthToken) {
        setCookieAuth(currentCookie)
      }

      if (expiryTime && isWithinMinutesFromNow(expiryTime, 15)) {
        refetch()
      }
    }, TEN_MINUTES)

    return () => clearInterval(intervalId)
  }, [cookieAuthToken])

  useEffect(() => {
    if (isAuthed && !availableToken) {
      refetch()
    }

    if (availableToken && isAuthed) {
      loadChatbotScript()
    }
    if (isError && error) {
      logSentryError('Error: useChatBotQuery', error)
    }
  }, [availableToken, refetch, isAuthed])

  return <></>
}

export default ChatBot
