import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  DoubleLabelSelect,
  Input,
  ModalActions,
  ModalNavBar,
} from '@node-space/storybook-components'
import { logSentryError } from '@node-space/utils'
import { RequestError } from 'components/Errors/RequestError'
import { usePostAddMerchantMutation } from 'hooks/mutations/usePostAddMerchantMutation'
import { usePutEditMerchantMutation } from 'hooks/mutations/usePutEditMerchantMutation'
import { useWalletsQuery } from 'hooks/queries/useWalletsQuery'
import { useActiveWallets } from 'hooks/useActiveWallets'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import {
  AddMerchantPayload,
  EditMerchantPayload,
  Error,
  FormFieldsMerchant,
  MerchantType,
} from 'types/types'
import { WalletStatus } from 'types/wallets'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface MerchantEditorProps {
  setMerchant: Dispatch<SetStateAction<MerchantType>>
  handleCloseModal: () => void
  actionName: string
  submitBtnText: string
  merchant?: MerchantType
  action: 'EDIT' | 'CREATE'
}

export const MerchantEditor = ({
  setMerchant,
  handleCloseModal,
  actionName,
  submitBtnText,
  merchant,
  action,
}: MerchantEditorProps) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { mutate: mutateAddMerchant, isPending: isLoadingAddMerchant } =
    usePostAddMerchantMutation()
  const { mutate: mutateEditMerchant, isPending: isLoadingEditMerchant } =
    usePutEditMerchantMutation()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const { data: wallets = [], isPending: isLoadingWallets } = useWalletsQuery()

  const [errors, setErrors] = useState<Error[]>([])
  const [initialValues] = useState<FormFieldsMerchant>({
    merchantName: merchant?.displayName || '',
    walletId: merchant?.wallet?.id ? `${merchant?.wallet?.id}` : `${wallets?.[0]?.id}`,
    webhookUrl: merchant?.webhookUrl || '',
  })

  useEffect(() => {
    if (action === 'CREATE') {
      track.Amp.track(AmplitudeEvent.MERCHANT_INITIATE, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
    if (action === 'EDIT') {
      track.Amp.track(AmplitudeEvent.MERCHANT_EDIT, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
  }, [])

  const shouldDisable = (values: FormFieldsMerchant) => {
    return action === 'CREATE'
      ? Boolean(errors?.length || JSON.stringify(initialValues) === JSON.stringify(values))
      : Boolean(
          errors?.length ||
            (JSON.stringify(initialValues) === JSON.stringify(values) && merchant?.wallet)
        )
  }

  const formik = useFormik<FormFieldsMerchant>({
    initialValues,
    onSubmit: async values => {
      if (shouldDisable(values)) {
        return
      }
      resetRequestError()

      if (action === 'CREATE') {
        const merchantDetails: AddMerchantPayload = {
          displayName: values?.merchantName,
          ...(!!values?.webhookUrl && { webhookUrl: values?.webhookUrl }),
          wallet: {
            id: Number(values?.walletId),
          },
        }

        mutateAddMerchant(merchantDetails, {
          onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: reactQueryKeys.merchants() })
            setMerchant(data)
          },
          onError: (error: unknown) => {
            setRequestError({
              handledMessage: t('oopsSomethingWentWrongContracted'),
              show: true,
            })
            logSentryError('Error: usePostAddMerchantMutation', error)
          },
        })
      } else {
        const merchantDetails: EditMerchantPayload = {
          displayName: values?.merchantName,
          ...(!!values?.webhookUrl && { webhookUrl: values?.webhookUrl }),
          wallet: Number(values?.walletId),
          merchant,
        }

        mutateEditMerchant(merchantDetails, {
          onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: reactQueryKeys.merchants() })
            setMerchant(data)
          },
          onError: (error: unknown) => {
            setRequestError({
              handledMessage: t('oopsSomethingWentWrongContracted'),
              show: true,
            })
            logSentryError('Error: usePutEditMerchantMutation', error)
          },
        })
      }
    },
    validate: values => {
      setErrors([])
      if (values?.merchantName?.length > 40) {
        setErrors([
          ...errors,
          {
            code: '',
            parameter: 'merchantName',
            message: t('merchantNameLengthError'),
          },
        ])
      }
      const validateWebhookUrl = (value: string) =>
        (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) &&
        value.indexOf(' ') === -1
          ? false
          : true

      if (values?.webhookUrl && validateWebhookUrl(values?.webhookUrl)) {
        setErrors([
          ...errors,
          {
            code: '',
            parameter: 'webhookUrl',
            message: t('webhookUrlInvalidMsg'),
          },
        ])
      }
    },
  })

  const activeWallets = useActiveWallets(wallets)

  const getWalletOptions = () => {
    if (!activeWallets?.length) {
      return []
    }
    return activeWallets?.map(wallet => ({
      label: wallet?.description,
      secondLabelText: t('balance'),
      secondLabel: `${wallet?.balance} ${wallet?.currency?.code}`,
      icon: wallet?.currency?.code?.toLowerCase(),
      value: `${wallet?.id}`,
      walletIcon: true,
    }))
  }

  const handleError = (field: keyof FormFieldsMerchant) => {
    const error = errors?.find(err => err.parameter === field)
    return error?.message
  }

  return (
    <>
      <ModalNavBar title={actionName} onClose={handleCloseModal} />
      <form onSubmit={formik?.handleSubmit} className="pt-5">
        <div className="px-5 mb-6">
          <Input
            label={t('merchantName')}
            placeholder={t('merchantName')}
            name="merchantName"
            onChange={formik?.handleChange}
            value={formik?.values?.merchantName}
            error={Boolean(handleError('merchantName'))}
            errorText={handleError('merchantName')}
            required
          />
        </div>
        <div className="px-5 mb-6">
          <DoubleLabelSelect
            label={t('wallet')}
            onChange={(value: string) => {
              formik.setFieldValue('walletId', value)
            }}
            loading={isLoadingWallets}
            name="wallet"
            value={String(formik?.values?.walletId)}
            options={getWalletOptions()}
            menuMaxHeight={360}
            iconsUrl={process.env.ICONS_URL}
          />
        </div>
        <div className="px-5 pb-6 border-gray-200">
          <Input
            testid="merchant-webhook"
            label={t('webhookUrl')}
            rightLabel={t('optionalFld')}
            placeholder="https://"
            name="webhookUrl"
            onChange={formik?.handleChange}
            value={formik?.values?.webhookUrl}
            error={Boolean(handleError('webhookUrl'))}
            errorText={handleError('webhookUrl')}
          />
        </div>
        {requestError?.show && (
          <div className="px-5 pb-6">
            <RequestError>{requestError?.handledMessage}</RequestError>
          </div>
        )}

        <ModalActions
          actions={[
            {
              children: t('cancel'),
              secondary: true,
              onClick: handleCloseModal,
            },
            {
              children: submitBtnText,
              testid: 'merchant-save-btn',
              loading: action === 'CREATE' ? isLoadingAddMerchant : isLoadingEditMerchant,
              disabled: shouldDisable(formik?.values),
              type: 'submit',
            },
          ]}
        />
      </form>
    </>
  )
}
