import React from 'react'
import { Box, Button, Text } from '@node-space/storybook-components'
import EmptyContainer from 'components/EmptyContainer'

interface ChannelsEmptyStateProps {
  title: string
  description?: string
  descriptionChildren?: React.ReactNode
  createChannelCallback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ctaBtnText?: string
  viewOnly?: boolean
}
const ChannelsEmptyState = ({
  title,
  description,
  descriptionChildren,
  ctaBtnText,
  createChannelCallback,
  viewOnly = false,
}: ChannelsEmptyStateProps) => {
  return (
    <EmptyContainer className="grid p-16 mt-2 justify-items-center">
      <Box className="m-auto mb-4">
        <Text size="lg" weight="medium" color="black" tag="h3">
          {title}
        </Text>
      </Box>
      <Box className="flex m-auto">
        <Text size="sm" color="grey">
          {description}
        </Text>
        {descriptionChildren && descriptionChildren}
      </Box>
      {!viewOnly && (
        <Button className="mt-6" full={false} onClick={createChannelCallback}>
          {ctaBtnText}
        </Button>
      )}
    </EmptyContainer>
  )
}

export default React.memo(ChannelsEmptyState)
