import React from 'react'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { Button, Icon } from '@node-space/storybook-components'
import { numberWithCommas } from '@node-space/utils'
import { WalletType } from '../../../../types/types'
import { TableItemColumn, TotalBalance } from '../../types'

/*
 * Based on total amount and base currency build string
 */
export const formatTotal = (total: number, baseCurrency: string): string =>
  total ? `${numberWithCommas(total)} ${baseCurrency}` : ''

/*
 * Helper function that maps out data for Wallet v2 table
 */
export const mapTableColumns = ({
  item,
  handleEditWalletName,
  viewOnly,
  totalBalance,
}: {
  item: WalletType
  handleEditWalletName: (id: string, title: string) => void
  viewOnly: boolean
  totalBalance: TotalBalance
}) => {
  return [
    {
      id: 'image',
      title: `${process.env.ICONS_URL}${item?.currency?.code?.toLowerCase()}.svg`,
      isImage: true,
    },
    {
      id: 'wallet',
      title: item?.description,
      description: item?.currency?.code,
      render: item => {
        return (
          <div className="my-4 ml-7">
            <div
              className={`flex text-gray-800 sm:w-full group${
                item?.data?.selected ? 'bg-selectedGray' : ''
              } hover:text-primary`}
              onClick={e => {
                e?.stopPropagation()
                handleEditWalletName(item.id, item.data.title)
              }}
            >
              <span>{item?.data?.title}</span>
              {!viewOnly && (
                <div
                  className={`pt-px pl-2 ${
                    !item?.data?.selected ? 'invisible' : ''
                  } group-hover:visible`}
                >
                  <Button
                    noStyling
                    iconElement={<Icon name="EditIcon" color={secondaryButtonIconColour} />}
                  ></Button>
                </div>
              )}
            </div>
            <span className="text-gray-500">{item?.data?.currencyCode}</span>
          </div>
        )
      },
    },
    {
      id: 'balance',
      title: `${item?.balance} ${item?.currency?.code}`,
      baseCurrencyTotal: totalBalance?.balances.find(balance => balance?.walletId === item?.id),
      align: 'right',
    },
  ] as TableItemColumn[]
}
