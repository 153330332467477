import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { capitalize } from '@node-space/utils'
import { RequestError } from 'components/Errors/RequestError'
import { AddCustomerPayload } from 'types/customers'
import { MappedErrorMessageState } from 'types/errors'
import { CustomerDetailsSection } from './CustomerDetailsSection'

type AddCustomerConfirmProps = {
  onContinue: () => void
  isLoading: boolean
  customer: AddCustomerPayload
  onBack: () => void
  requestError: MappedErrorMessageState
  resetRequestError: () => void
}

export const AddCustomerConfirm = ({
  customer,
  onContinue,
  isLoading,
  onBack,
  requestError,
  resetRequestError,
}: AddCustomerConfirmProps) => {
  const { t } = useTranslation()

  const customerDetails = [
    { label: 'customers.type', value: capitalize(customer?.customerEntityType?.toLowerCase()) },
    { label: 'customers.businessName', value: customer?.businessName },
    { label: 'customers.industry', value: customer?.industryName },
    { label: 'customers.businessDescription', value: customer?.businessDescription },
    { label: 'customers.businessRegistrationNumber', value: customer?.businessRegistrationNumber },
    { label: 'customers.businessTaxResidency', value: customer?.businessTaxResidencyName },
    { label: 'customers.businessMonthlyVolume', value: customer?.businessMonthlyVolumeName },
  ]

  const addressDetails = [
    {
      label: 'customers.address.address',
      value: [customer?.addressLine1, customer?.addressLine2, customer?.city, customer?.postCode]
        ?.filter(Boolean)
        ?.join(', '),
    },
    { label: 'customers.country', value: customer?.countryName },
  ]

  const representativeDetails = [
    { label: 'customers.representative.firstName', value: customer?.firstName },
    { label: 'customers.representative.lastName', value: customer?.lastName },
    { label: 'customers.representative.dateOfBirth', value: customer?.dateOfBirth },
    { label: 'customers.representative.nationality', value: customer?.nationalityName },
  ]

  const actions: ButtonProps[] = [
    {
      children: t('back'),
      testid: 'back',
      secondary: true,
      onClick: () => {
        resetRequestError()
        onBack()
      },
    },
    {
      children: t('customers.createCustomer'),
      onClick: onContinue,
      loading: isLoading,
    },
  ]

  return (
    <>
      <Box flex flexCol padding={24} paddingT={12} gapY={12}>
        <CustomerDetailsSection customerDetails={customerDetails} />
        <CustomerDetailsSection
          title={t('customers.registeredAddress')}
          customerDetails={addressDetails}
        />
        <CustomerDetailsSection
          title={t('customers.representative.title')}
          customerDetails={representativeDetails}
        />
        {requestError?.show && (
          <Box padding={20}>
            <RequestError
              errorCode={requestError?.errorCode}
              sentryTraceId={requestError?.sentryTraceId}
            />
          </Box>
        )}
        <ModalActions actions={actions} />
      </Box>
    </>
  )
}
