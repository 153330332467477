import React from 'react'
import { Search, Box as OuterWrapper } from '@node-space/storybook-components'
import useFilterSearch from 'components/FilterBar/hooks/useFilterSearch'
import { sanitizeUserInput } from 'utils/utils'

const FilterSearch = ({
  preLoadedSearchTerm,
  placeholder,
}: {
  preLoadedSearchTerm?: string
  placeholder: string
}) => {
  const { search, setSearch } = useFilterSearch()

  const onSearchQueryChanged = e => {
    const { value } = e?.target ?? ''
    setSearch(sanitizeUserInput(value))
  }

  return (
    <OuterWrapper testid="filter-search-field-wrapper" className="bg-white">
      <Search
        searchTerm={preLoadedSearchTerm || search}
        size="sm"
        testid="filter-search-field"
        placeholderText={placeholder}
        onChange={onSearchQueryChanged}
      />
    </OuterWrapper>
  )
}

export default FilterSearch
