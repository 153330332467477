import React from 'react'
import { TFunction } from 'react-i18next'
import { Box as Container, Box as Item } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { ImageIcon } from '@node-space/storybook-components/dist/ImageIcon'
import { TableRow } from '@node-space/storybook-components/dist/SimpleTable'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { CryptoPayout, MassPayoutCryptoBatch, RecordStatus } from 'pages/MassPayouts/types'
import { getCurrencySrc } from 'pages/MassPayouts/utils'
import { Currency } from 'types/types'
import { formatErrorMsg } from 'utils/utils'

interface CryptoTableRowsProps {
  cryptoBatchPayout: MassPayoutCryptoBatch
  handleDeleteCryptoPayout: (payout: CryptoPayout) => void
  handleShowEditCryptoPayoutModal: (
    e: React.MouseEvent<HTMLButtonElement>,
    payout: CryptoPayout
  ) => void
  onOpenPayoutDetails: (payoutIndex?: number) => void
  t: TFunction<'translation', undefined>
  cryptoCurrencies: Currency[]
}

export const getCryptoTableRows = ({
  cryptoBatchPayout,
  handleDeleteCryptoPayout,
  handleShowEditCryptoPayoutModal,
  onOpenPayoutDetails,
  t,
  cryptoCurrencies,
}: CryptoTableRowsProps): TableRow[] => {
  return cryptoBatchPayout?.items?.map((payout: CryptoPayout, payoutIndex) => ({
    key: payout?.externalId,
    rowData: [
      {
        cellData: (
          <Item flex paddingL={16} gapX={4}>
            <Text size="sm">{payout?.fundingWalletAmount ?? payout?.paidCurrencyAmount}</Text>
            <Text size="sm">
              {payout?.fundingWalletAmount ? cryptoBatchPayout?.walletCurrency : payout?.currency}
            </Text>
          </Item>
        ),
        testid: `individual-record-amount`,
      },
      {
        cellData: (
          <Item flex alignItems="center" justifyContent="center">
            <Container>
              <ImageIcon
                width={32}
                height={32}
                hasBackgroundColor={false}
                src={getCurrencySrc(payout?.currency, payout?.protocol, cryptoCurrencies)}
              />
            </Container>
            <Item paddingY={20} paddingL={16} paddingR={12}>
              <Text size="sm">{ellipseMiddleText(payout?.address, 10, 10)}</Text>
              <Text size="sm" color="grey">
                {payout?.tag}
              </Text>
            </Item>
          </Item>
        ),
        testid: `individual-record-recipient`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            <Text size="sm">{payout?.reference}</Text>
          </Item>
        ),
        testid: `individual-record-reference`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            {payout?.errorMessage && (
              <StatusPill
                state="error"
                label={t('massPayouts.errors', {
                  numberOfErrors: formatErrorMsg(payout?.errorMessage?.value).errorList?.length,
                })}
                fitContent
              />
            )}
          </Item>
        ),
        testid: `individual-record-errors`,
      },
      {
        cellData: (
          <Item flex paddingR={12}>
            {!payout?.errorMessage && (
              <Container paddingR={8}>
                <Icon name="SuccessCircleIcon" color="validation-success" />
              </Container>
            )}
            {(payout?.status === RecordStatus.DRAFT || payout?.status === RecordStatus.ERROR) && (
              <>
                <Container
                  paddingR={8}
                  onClick={e =>
                    handleShowEditCryptoPayoutModal(
                      e as React.MouseEvent<HTMLButtonElement>,
                      payout as CryptoPayout
                    )
                  }
                >
                  <Icon name="EditIcon" color="grey-10" />
                </Container>
                <Container paddingR={12} onClick={() => handleDeleteCryptoPayout(payout)}>
                  <Icon name="TrashIcon" color="grey-10" />
                </Container>
              </>
            )}
          </Item>
        ),
        align: 'end',
        testid: `individual-record-operations`,
      },
    ],
    onClick: () => onOpenPayoutDetails(payoutIndex),
  }))
}
