import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TableRowSkeleton } from 'components/Skeletons/TableRowSkeleton'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useWhitelistedCryptoAddressesQuery } from 'hooks/queries/useWhitelistedCryptoAddressesQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { TransactionsPagination } from '../WalletDetails/components/RecentActivity/TransactionsPagination'
import AddCryptoAddressModal from './AddModal/AddCryptoAddressModal'
import CryptoAddressesHeader from './CryptoAddressesHeader'
import CryptoAddressTable from './CryptoAddressTable'
import CryptoFilterBar from './CryptoFilterBar'

const WHITELIST_LEARN_MORE_LINK = process.env.WHITELIST_LEARN_MORE_LINK
const DEFAULT_NUMBER_OF_ROWS = '10'

const CryptoAddressesWhitelisting = () => {
  const { t } = useTranslation()
  const { profile } = useProfileContext() || {}
  const { twoFactorEnabled: isTwoFactorEnabled } = profile || {}
  const [searchParams] = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl') ?? null

  const permissions = useMappedRoles().manageCryptoAddress
  const canEdit = permissions?.canEdit

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchCurrency, setSearchCurrency] = useState<string>('')

  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [numberOfRows, setNumberOfRows] = useState(DEFAULT_NUMBER_OF_ROWS)

  const [showAddCryptoAddressModal, setShowAddCryptoAddressModal] = useState<boolean>(!!callbackUrl)
  const handleCloseAddModal = () => {
    setShowAddCryptoAddressModal(false)
  }

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  const {
    data: { results: whitelistedCryptoAddresses = [], paging = null } = {},
    refetch,
    isFetching: isFetchingCryptoAddresses,
    isError,
    isSuccess,
  } = useWhitelistedCryptoAddressesQuery({
    queryTerm: searchTerm,
    currency: searchCurrency,
    offset,
    max: Number(numberOfRows),
    enabled: true,
  })

  const hasWhitelistedCryptoAddresses = !!whitelistedCryptoAddresses?.length
  const isBeingSearched = !!(searchTerm.length || searchCurrency.length)

  const onAddCryptoSuccess = () => {
    resetCryptoTable()
    setShowAddCryptoAddressModal(false)
  }

  const onDeleteCallback = () => {
    resetCryptoTable()
    setShowDeleteModal(false)
  }

  const resetCryptoTable = () => {
    setPage(0)
    setOffset(0)
    refetch()
  }

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.MANAGE_ACCOUNT_MANAGE_CRYPTO_ADDRESSES, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const whitelistingPageHeader = (
    <>
      <CryptoAddressesHeader
        showAddCryptoAddressModal={showAddCryptoAddressModal}
        onAddCryptoSuccess={onAddCryptoSuccess}
        handleClose={handleCloseAddModal}
        setShowAddCryptoAddressModal={setShowAddCryptoAddressModal}
        resetCryptoTable={resetCryptoTable}
        canEdit={canEdit && isTwoFactorEnabled}
      />

      <CryptoFilterBar
        searchTerm={searchTerm}
        onSearchTermChange={term => {
          setSearchTerm(term)
          setOffset(0)
          setPage(0)
        }}
        searchCurrency={searchCurrency}
        onSearchCurrencyChange={currency => {
          setSearchCurrency(currency)
          setOffset(0)
          setPage(0)
        }}
        isBeingSearched={isBeingSearched}
        isFetchingCryptoAddresses={isFetchingCryptoAddresses}
      />
    </>
  )

  return (
    <Box>
      {!isError &&
      isBeingSearched &&
      !isFetchingCryptoAddresses &&
      !hasWhitelistedCryptoAddresses ? (
        <>
          {whitelistingPageHeader}
          <StatusCard headline={t('manageCryptoAddresses.noSearchReslts')}>
            <Button
              noStyling
              onClick={() => {
                setSearchTerm('')
                setSearchCurrency('')
              }}
            >
              <Text size="sm" color="primary-500">
                {t('clearFilters')}
              </Text>
            </Button>
          </StatusCard>
        </>
      ) : !isError && !isFetchingCryptoAddresses && !hasWhitelistedCryptoAddresses ? (
        <>
          {showAddCryptoAddressModal && canEdit && (
            <AddCryptoAddressModal
              closeModal={handleCloseAddModal}
              onAddCryptoSuccess={onAddCryptoSuccess}
              resetCryptoTable={resetCryptoTable}
            />
          )}

          <StatusCard
            button={{
              onClick: () => setShowAddCryptoAddressModal(true),
              text: t('manageCryptoAddresses.addAddressBtn'),
              disabled: !canEdit || !isTwoFactorEnabled,
            }}
            headline={t('manageCryptoAddresses.emptyState.title')}
          >
            <Box {...(isTwoFactorEnabled ? { flex: true } : { flexRow: true })}>
              <Text size="sm">
                {t('manageCryptoAddresses.emptyState.subtitle')}
                {!isTwoFactorEnabled && (
                  <span>{` ${t('manageCryptoAddresses.emptyState.twoFactorDisabled')}.`} </span>
                )}
              </Text>
              <Button
                {...(isTwoFactorEnabled && { className: 'ml-1' })}
                noStyling
                onClick={() => window.open(WHITELIST_LEARN_MORE_LINK)}
              >
                <Text size="sm" className="text-primary-500">
                  {t('learnMore')}
                </Text>
              </Button>
            </Box>
          </StatusCard>
        </>
      ) : (
        <>
          {whitelistingPageHeader}

          {!isError && isFetchingCryptoAddresses && !hasWhitelistedCryptoAddresses && (
            <Box width="full">
              <TableRowSkeleton rows={4} />
            </Box>
          )}

          {isSuccess && hasWhitelistedCryptoAddresses && (
            <>
              <CryptoAddressTable
                cryptoAddresses={whitelistedCryptoAddresses}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                handleClose={handleCloseDeleteModal}
                onDeleteCallback={onDeleteCallback}
                isSearching={isFetchingCryptoAddresses}
                canEdit={canEdit && isTwoFactorEnabled}
              />

              {!!paging?.total && (
                <Box paddingT={32}>
                  <TransactionsPagination
                    page={page}
                    setPage={setPage}
                    setOffset={setOffset}
                    pagination={paging}
                    nrOfRows={numberOfRows}
                    setNrOfRows={setNumberOfRows}
                  />
                </Box>
              )}
            </>
          )}
          {isError && <StatusCard status="error" headline={t('oopsSomethingWentWrong')} />}
        </>
      )}
    </Box>
  )
}

export default CryptoAddressesWhitelisting
