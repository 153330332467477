import React from 'react'
import { Box, Box as Wrapper } from '@node-space/storybook-components'
import { ellipseMiddleText } from '@node-space/utils'
import { Transaction } from 'types/transaction'
import { getTransactionReference } from 'utils/utils'

interface TransactionReferenceProps {
  transaction: Transaction
}

export const TransactionReference = ({ transaction }: TransactionReferenceProps) => {
  const reference = getTransactionReference(transaction)

  return (
    <Box tag="span" flex alignItems="center" gap={12} width="full" maxWidth={320}>
      {reference && (
        <Wrapper className="flex items-center gap-2">
          <Box>{ellipseMiddleText(reference, 20, 10)}</Box>
        </Wrapper>
      )}
    </Box>
  )
}
