import React from 'react'
import clsx from 'clsx'
import { sortBy } from 'lodash-es'
import Box from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Timeline, TimelineColor } from '@node-space/storybook-components/dist/Timeline'
import { BvnkNetworkTransactionActivities } from 'types/transaction'

interface BvnkNetworkActivityTransactionActivityProps {
  transactionActivities: BvnkNetworkTransactionActivities[]
}

const LAST_STEP = 4

const BvnkNetworkActivityTransactionActivity = ({
  transactionActivities,
}: BvnkNetworkActivityTransactionActivityProps) => {
  const activities = sortBy(transactionActivities, ['step'])?.map(activity => {
    return {
      color: activity?.step === LAST_STEP ? TimelineColor.GREEN : TimelineColor.BLUE,
      date: activity?.dateCreated,
      component: (
        <Text className={clsx({ 'pb-5': activity?.step !== LAST_STEP })} size="sm" weight="normal">
          {activity?.description}
        </Text>
      ),
    }
  })

  return (
    <Box paddingX={8} paddingY={12}>
      <Timeline rows={activities} showDateCol />
    </Box>
  )
}
export default BvnkNetworkActivityTransactionActivity
