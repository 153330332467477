import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useFeatureFlags } from '@node-space/hooks'
import { useDepositAccountsQuery } from 'hooks/queries'
import { useWalletLimits } from 'hooks/queries/useWalletLimits'
import { IWalletActionApiType } from 'pages/Wallets/hooks/useWalletActionApi'
import {
  AggregatedPaymentMethod,
  BankAccountsDetails,
  DepositBankAccount,
} from 'types/BankAccountsDetails'
import { Currencies, CurrencyLimits, ErrorAPI, WalletType } from 'types/types'
import { checkIfVibanIsInactive } from 'utils/utils'
import { WalletTabProps } from '../Modal/WalletModal'
import DepositForm from './DepositForm'
import DepositInstructions from './DepositInstructions'

export type DepositSteps = 'form' | 'instructions'

export type DepositForm = {
  walletId?: string
  accountId?: string
}

export type DepositActionProps = WalletTabProps &
  IWalletActionApiType<DepositForm, DepositSteps> & {
    accounts?: BankAccountsDetails | DepositBankAccount[]
    depositLimits: CurrencyLimits
    inactiveSegregatedAccount?: boolean
    isError?: boolean
  }

type DepositProps = WalletTabProps & IWalletActionApiType<DepositForm, DepositSteps>

/**
 * Deposit Tab containing state, form and API data for each step
 */
export const Deposit = ({
  dismissAction,
  form,
  setStep,
  setLoading,
  step,
  loading,
  wallets,
  setWalletId,
  ...otherProps
}: DepositProps) => {
  const queryClient = useQueryClient()
  const [account, setAccount] = useState<AggregatedPaymentMethod>(null)
  const [inactiveSegregatedAccount, setInactiveSegregatedAccount] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState<WalletType>(null)
  const formValues = form.watch()

  const { enableMultiplePaymentMethods } = useFeatureFlags()

  const {
    data: depositDetails,
    error: depositDetailsError,
    isError: isDepositDetailsError,
    isFetching: isFetchingDepositDetails,
    refetch: fetchDepositDetails,
  } = useDepositAccountsQuery(formValues?.walletId, false, enableMultiplePaymentMethods)

  const {
    data: walletLimits,
    isFetching: isFetchingWalletLimits,
    isError: isFetchingWalletLimitsError,
    refetch: fetchWalletLimits,
  } = useWalletLimits(selectedWallet?.currency?.code as Currencies, selectedWallet?.id, false)

  useEffect(() => {
    const wallet = wallets?.find(x => x?.id?.toString() === formValues?.walletId)
    setSelectedWallet(wallet)
  }, [formValues?.walletId, wallets])

  useEffect(() => {
    if (selectedWallet) {
      form.setValue('accountId', null)
      fetchDepositDetails()
    }
  }, [selectedWallet])

  useEffect(() => {
    if (!enableMultiplePaymentMethods && depositDetailsError) {
      const errorFromApi = depositDetailsError as {
        data: ErrorAPI
      }
      if (checkIfVibanIsInactive(errorFromApi?.data)) {
        setInactiveSegregatedAccount(true)
      }
    }
  }, [depositDetailsError, depositDetails, enableMultiplePaymentMethods])

  useEffect(() => {
    const isValidV1DepositDetails =
      !isFetchingDepositDetails &&
      !isDepositDetailsError &&
      selectedWallet &&
      !enableMultiplePaymentMethods &&
      (depositDetails as BankAccountsDetails)?.isAggregatedWallet

    if (isValidV1DepositDetails) {
      const bankAccountId = (
        depositDetails as BankAccountsDetails
      )?.aggregatedPaymentMethods?.[0]?.id?.toString()

      if (bankAccountId) {
        form.setValue('accountId', bankAccountId)
        setAccount((depositDetails as BankAccountsDetails)?.aggregatedPaymentMethods?.[0])
      }
    }
    fetchWalletLimits()
  }, [
    depositDetails,
    isFetchingDepositDetails,
    isDepositDetailsError,
    selectedWallet,
    enableMultiplePaymentMethods,
  ])

  useEffect(() => {
    if (
      !enableMultiplePaymentMethods &&
      (depositDetails as BankAccountsDetails)?.isAggregatedWallet
    ) {
      const bankAccount = (depositDetails as BankAccountsDetails)?.aggregatedPaymentMethods?.find(
        paymentMethod => paymentMethod?.id?.toString() === formValues?.accountId
      )

      if (bankAccount) {
        setAccount(bankAccount)
      }
    }
  }, [formValues?.accountId, enableMultiplePaymentMethods])

  const isLoading = loading || isFetchingDepositDetails || isFetchingWalletLimits

  const onDismissDeposit = () => {
    queryClient.cancelQueries({ queryKey: ['depositAccountsV2, getWalletLimits'] })
    dismissAction?.()
  }

  const depositFormProps = {
    dismissAction: onDismissDeposit,
    form,
    setStep,
    setLoading,
    step,
    loading: isLoading,
    wallets,
    setWalletId,
    depositLimits: walletLimits,
    accounts: depositDetails,
    ...otherProps,
  }

  /**
   * Switch active component based on 'step' value
   */
  const renderStep = () => {
    switch (step) {
      case 'form':
        return (
          <DepositForm
            {...depositFormProps}
            accounts={depositDetails}
            inactiveSegregatedAccount={inactiveSegregatedAccount}
            loading={isLoading}
            isError={isDepositDetailsError || isFetchingWalletLimitsError}
          />
        )
      case 'instructions':
        return <DepositInstructions {...depositFormProps} account={account} />
    }
  }

  return renderStep()
}
