import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash-es'
import { useWalletContext } from 'hooks/context'
import { useDepositDetailsMutation } from 'hooks/mutations'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'

export const usePrepareNewlyCreatedCryptoWallet = () => {
  const wallet = useWalletContext()
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError } = useDepositDetailsMutation()
  useEffect(() => {
    ;(async () => {
      try {
        if (wallet && wallet?.id && !wallet?.address) {
          await mutateAsync({
            walletId: String(wallet.id),
          })
          if (
            wallet?.currency?.protocols?.length > 1 &&
            wallet.currency.protocols.length > wallet.alternatives?.length
          ) {
            const protocols = wallet.currency?.protocols || []
            const alternatives = wallet.alternatives || []
            const additionalsProtocols = protocols.filter(
              protocol =>
                protocol.code !== wallet.protocol &&
                !alternatives.find(alt => alt.protocol === protocol.code)
            )
            if (!isEmpty(additionalsProtocols)) {
              for (const protocol of additionalsProtocols) {
                await mutateAsync({
                  walletId: String(wallet.id),
                  protocol: protocol.code,
                })
              }
            }
          }
          queryClient.invalidateQueries({ queryKey: reactQueryKeys.wallet(wallet.id) })
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [wallet])

  return { isPending, isError }
}
