import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { debounce } from 'lodash-es'
import { logSentryError } from '@node-space/utils'
import { rootInitialState } from 'components/FilterBar/context/reducer'
import useFilterFromTo from 'components/FilterBar/hooks/useFilterFromTo'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import useFilterStatus from 'components/FilterBar/hooks/useFilterStatus'
import useFilterTag from 'components/FilterBar/hooks/useFilterTag'
import { DateFormat } from 'constants/General'
import { usePaymentsQuery } from 'hooks/queries'
import { getAllPaymentsCSV } from 'services/PaymentsService'
import { PaymentList } from 'types/payments'
import { QueryMetaType } from 'types/reactQuery'
import { IDateValue } from 'types/types'

const usePaymentsApi = () => {
  const [isExportingCSV, setIsExportingCSV] = useState(false)

  const { resetDateRange } = useFilterFromTo()
  const { status, setStatus, resetStatus } = useFilterStatus()
  const { tag, setTag, resetTag } = useFilterTag()
  const { merchant, setMerchant, resetMerchant } = useFilterMerchant()

  const [searchByRef, setSearchByRef] = useState('')
  const [searchByTransactionHash, setSearchByTransactionHash] = useState('')

  const formattedStartDate = dayjs(rootInitialState?.from).format(DateFormat.YYYY_MM_DD)
  const formattedEndDate = dayjs(rootInitialState?.to).format(DateFormat.YYYY_MM_DD)

  const [dateValue, setDateValue] = useState<IDateValue>({
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  })

  const onDateSelected = (dateVal: IDateValue) => {
    setDateValue(dateVal)
  }

  const [paymentsList, setPaymentsList] = useState<PaymentList>({
    enteredTo: new Date(Date.now()),
    visible: false,
    isTransactionsLoaded: false,
    transactions: [],
    paging: { offset: 0, total: 0, max: 20 },
    pagingMax: 20,
    selectedPayloadOpen: false,
  })

  const { paging, pagingMax } = paymentsList

  const meta: QueryMetaType = {
    onSuccess: res => processPayments(res),
    errorAdditionalData: {
      merchant,
      status,
      searchByRef,
      searchByTransactionHash,
    },
  }

  const { refetch, isFetching, isError, error } = usePaymentsQuery(
    {
      paging,
      from: dateValue?.startDate?.toString(),
      to: dateValue?.endDate?.toString(),
      merchant,
      status,
      searchByRef,
      searchByTransactionHash,
      tag,
    },
    {
      meta,
    }
  )

  const processPayments = data => {
    const transactions = data?.results || []
    setPaymentsList(prev => ({
      ...prev,
      isTransactionsLoaded: true,
      transactions,
      paging: {
        ...prev?.paging,
        total: data?.paging?.total || 0,
        offset: data?.paging?.offset || 0,
      },
    }))
  }

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    [refetch]
  )

  useEffect(() => {
    if (searchByRef || searchByTransactionHash) {
      debouncedRefetch()
    } else {
      refetch()
    }

    return () => {
      debouncedRefetch.cancel()
    }
  }, [searchByRef, searchByTransactionHash, debouncedRefetch])

  useEffect(() => {
    if (
      dateValue?.startDate === formattedStartDate &&
      dateValue?.endDate === formattedEndDate &&
      status === rootInitialState.status &&
      merchant === rootInitialState.merchant &&
      tag === rootInitialState.tag
    ) {
      refetch()
    }
  }, [dateValue?.startDate, dateValue?.endDate, status, merchant, tag])

  useEffect(() => {
    refetch()
  }, [paging.offset])

  useEffect(() => {
    return () => {
      resetDateRange()
      resetStatus()
      resetTag()
      resetMerchant()
    }
  }, [])

  const handleExportClicked = useCallback(async () => {
    setIsExportingCSV(true)

    try {
      const res = await getAllPaymentsCSV(
        dateValue?.startDate?.toString(),
        dateValue?.endDate?.toString(),
        status,
        searchByRef,
        merchant
      )
      const data = res?.data

      const searchTerm = searchByRef || searchByTransactionHash

      try {
        fileDownload(
          data,
          `payment-${dateValue?.startDate}-${dateValue?.endDate}${
            searchTerm ? `-${searchTerm}` : ''
          }.csv`,
          'application/octet-stream'
        )
      } catch (err) {
        logSentryError('Error from usePaymentsApi - handleExportClicked - try fileDownload', err, {
          merchant,
          dateValue,
        })
      }
      setIsExportingCSV(false)
    } catch (err) {
      logSentryError(
        'Error from usePaymentsApi - handleExportClicked - try getAllPaymentsCSV',
        err,
        {
          merchant,
          dateValue,
        }
      )
      setIsExportingCSV(false)
    }
  }, [dateValue?.startDate, dateValue?.endDate, status, searchByRef, merchant])

  const onSearchByRefChanged = (search: string) => setSearchByRef(search)
  const onSearchByTransactionHash = (search: string) => {
    setSearchByTransactionHash(search)
  }
  const onPaymentStatusChanged = status => setStatus(status)
  const onPaymentTagChange = tag => setTag(tag)

  const onMerchantSelected = useCallback(
    merchant => {
      setMerchant(merchant)
    },
    [
      dateValue?.startDate,
      dateValue?.endDate,
      status,
      merchant,
      searchByRef,
      paging,
      pagingMax,
      tag,
    ]
  )

  const onSuccessPayment = useCallback(() => {
    refetch()
  }, [
    dateValue?.startDate,
    dateValue?.endDate,
    status,
    merchant,
    searchByRef,
    paging,
    pagingMax,
    tag,
  ])

  const onPageChange = useCallback(
    ({ paging: { offset } }) => {
      setPaymentsList(prev => ({
        ...prev,
        paging: {
          ...paging,
          offset: offset,
          max: pagingMax,
        },
      }))
    },
    [
      dateValue?.startDate,
      dateValue?.endDate,
      status,
      merchant,
      searchByRef,
      paging,
      pagingMax,
      tag,
    ]
  )

  const onResetAll = () => {
    setDateValue({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
    setStatus(rootInitialState?.status)
    setMerchant(rootInitialState?.merchant)
    setTag(rootInitialState?.tag)
    setSearchByRef('')
    resetSearch()
  }

  const resetSearch = () => {
    setSearchByRef('')
    setSearchByTransactionHash('')
  }

  return {
    executor: refetch,
    isLoading: isFetching,
    isError,
    error,
    data: {
      paymentsList,
      dateValue,
      merchant,
      searchByRef,
      searchByTransactionHash,
      status,
      isExportingCSV,
      tag,
    },
    actions: {
      onDateSelected,
      handleExportClicked,
      onSearchByRefChanged,
      onSearchByTransactionHash,
      onPaymentStatusChanged,
      onPaymentTagChange,
      onMerchantSelected,
      onSuccessPayment,
      onPageChange,
      onResetAll,
      setPaymentsList,
      showSearchResult: refetch,
      resetSearch,
    },
  }
}

export default usePaymentsApi
