import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Box as FormRow } from '@node-space/storybook-components/dist/Box'
import IconSelect from '@node-space/storybook-components/dist/IconSelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import Radio, { RadioProps } from '@node-space/storybook-components/dist/Radio'
import { DestinationTagValues, ONPASTE_EVENT_FIRE_DELAY_TIME } from 'types/whitelisting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { AddCryptoAddressFormData } from './AddCryptoAddressModal'

type FormObserver = {
  backClickState: boolean
  resetState: boolean
  requiresRevalidation: boolean
  addressIsValidated: boolean
  mayRequireDestinationTag: boolean
  destinationTagIsRequired: (typeof DestinationTagValues)[keyof typeof DestinationTagValues]
}

interface StepAddCryptoAddressFormProps {
  formObserver: FormObserver
  setFormObserver: Dispatch<SetStateAction<FormObserver>>
  form: UseFormReturn<AddCryptoAddressFormData, unknown, AddCryptoAddressFormData>
  cryptocurrency: string
  address: string
  getCryptoCurrencies: { value: string; label: string }[]
  isLoadingCryptocurrecies: boolean
  radioProtocolProps: RadioProps
  handleValidateAddress: (
    addressInput: string,
    updatedCurrency?: string,
    addressWasPasted?: boolean
  ) => void
  handlePasteAddress: (addressElement: React.ClipboardEvent<HTMLInputElement>) => void
}

const StepAddCryptoAddressForm = ({
  formObserver,
  setFormObserver,
  form,
  cryptocurrency,
  address,
  getCryptoCurrencies,
  isLoadingCryptocurrecies,
  radioProtocolProps,
  handleValidateAddress,
  handlePasteAddress,
}: StepAddCryptoAddressFormProps) => {
  const { t } = useTranslation()
  const { register, setValue, clearErrors } = form

  const validateAddress = (address: string, updatedCurrency = '') => {
    if (address && cryptocurrency) {
      handleValidateAddress(address, updatedCurrency)
    }
  }

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.ADD_CRYPTO_ADDRESS_WHITELIST_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <Box padding={24} gap={20} flex flexCol>
      <FormRow>
        <IconSelect
          {...register('cryptocurrency')}
          menuMaxHeight={300}
          iconsUrl={`${process.env.ICONS_URL}`}
          label={t('manageCryptoAddresses.modal.cryptocurrencyLabel')}
          name="cryptocurrency"
          options={getCryptoCurrencies}
          isSearchable
          error={
            formObserver.resetState
              ? !formObserver.resetState
              : !!form.formState.errors?.cryptocurrency?.message
          }
          errorText={form.formState.errors?.cryptocurrency?.message}
          placeholder={t('Search')}
          disabled={isLoadingCryptocurrecies}
          value={cryptocurrency}
          onChange={value => {
            clearErrors('cryptocurrency')
            setValue('cryptocurrency', value)
            validateAddress(address, value)
            if (formObserver.addressIsValidated) {
              setFormObserver({ ...formObserver, requiresRevalidation: true })
            }
          }}
        />
      </FormRow>
      {radioProtocolProps && (
        <FormRow>
          <Radio
            {...radioProtocolProps}
            onChange={value => {
              setValue('protocol', value)
              validateAddress(address)
              if (formObserver.addressIsValidated) {
                setFormObserver({ ...formObserver, requiresRevalidation: true })
              }
            }}
          />
        </FormRow>
      )}
      <FormRow>
        <Input
          label={t('manageCryptoAddresses.modal.addressNameLabel')}
          inputMode="text"
          name="addressName"
          error={
            formObserver.resetState
              ? !formObserver.resetState
              : !!form.formState.errors?.addressName?.message
          }
          errorText={form.formState.errors?.addressName?.message}
          {...register('addressName')}
          onBlur={() => validateAddress(address)}
        />
      </FormRow>
      {formObserver?.mayRequireDestinationTag && (
        <FormRow className="relative right-2">
          <Radio
            height={24}
            onChange={value =>
              setFormObserver({ ...formObserver, destinationTagIsRequired: value })
            }
            name="destinationTagIsRequired"
            options={[
              {
                label: t('manageCryptoAddresses.modal.doesntRequireDestinationTag', {
                  cryptocurrency,
                }),
                value: DestinationTagValues.NOT_REQUIRED,
              },
              {
                label: t('manageCryptoAddresses.modal.requiresDestinationTag', {
                  cryptocurrency,
                }),
                value: DestinationTagValues.IS_REQUIRED,
              },
            ]}
            value={formObserver?.destinationTagIsRequired}
          />
        </FormRow>
      )}
      <FormRow>
        <Input
          label={t('manageCryptoAddresses.modal.addressLabel')}
          inputMode="text"
          name="address"
          onPaste={e => setTimeout(() => handlePasteAddress(e), ONPASTE_EVENT_FIRE_DELAY_TIME)}
          error={
            formObserver.resetState
              ? !formObserver.resetState
              : !!form.formState.errors?.address?.message
          }
          errorText={form.formState.errors?.address?.message}
          onChange={e => {
            handleValidateAddress(e.target.value)
            if (formObserver.addressIsValidated) {
              setFormObserver({ ...formObserver, requiresRevalidation: true })
            }
          }}
          {...register('address')}
          onBlur={e => handleValidateAddress(e.target.value)}
        />
      </FormRow>
      {!!Number(formObserver?.destinationTagIsRequired) &&
        formObserver?.mayRequireDestinationTag && (
          <FormRow>
            <Input
              label={t('destinationTag')}
              inputMode="text"
              name="destinationTag"
              error={
                formObserver.resetState
                  ? !formObserver.resetState
                  : !!form.formState.errors?.destinationTag?.message
              }
              errorText={form.formState.errors?.destinationTag?.message}
              {...register('destinationTag')}
              onBlur={() => validateAddress(address)}
            />
          </FormRow>
        )}
      <FormRow>
        <Input
          label={t('manageCryptoAddresses.modal.reasonLabel')}
          inputMode="text"
          name="reason"
          error={
            formObserver.resetState
              ? !formObserver.resetState
              : !!form.formState.errors?.reason?.message
          }
          errorText={form.formState.errors?.reason?.message}
          {...register('reason')}
          onBlur={() => validateAddress(address)}
        />
      </FormRow>
    </Box>
  )
}

export default StepAddCryptoAddressForm
