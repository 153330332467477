import { TFunction } from 'react-i18next'
import * as yup from 'yup'

export const getGbpSpecificSchemaFields = (t: TFunction<'translation', undefined>) => {
  const accountNumberLabel = t('accountNumber')
  const accountNumberLength = 8
  const sortCodeLength = 6

  const accountNumber = yup
    .string()
    .label(accountNumberLabel)
    .min(
      accountNumberLength,
      t('fieldExactLength', {
        field: accountNumberLabel,
        amount: accountNumberLength,
      })
    )
    .max(
      accountNumberLength,
      t('fieldExactLength', {
        field: accountNumberLabel,
        amount: accountNumberLength,
      })
    )
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const sortCodeLabel = t('sortCode')
  const sortCode = yup
    .string()
    .label(sortCodeLabel)
    .min(
      sortCodeLength,
      t('fieldExactLength', {
        field: sortCodeLabel,
        amount: sortCodeLength,
      })
    )
    .max(
      sortCodeLength,
      t('fieldExactLength', {
        field: sortCodeLabel,
        amount: sortCodeLength,
      })
    )
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  return {
    accountNumber,
    sortCode,
  }
}
