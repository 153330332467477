export type VerificationRequestStatus =
  | 'businessVerificationNotSubmitted'
  | 'businessVerificationPending'
  | 'businessVerified'

export interface CountryOptions {
  value: string
  label: string
}

export interface DocumentUpload {
  id?: number
  buffer: string | ArrayBuffer
  documentType?: string
  description?: string
  fileData: File
  path?: string
}

export const VerificationResult = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  DECLINED: 'declined',
} as const

export type VerificationResult = (typeof VerificationResult)[keyof typeof VerificationResult]
