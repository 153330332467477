import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getWalletById } from 'services/WalletsService'
import { WalletType } from 'types/types'

export const useWalletQuery = (id: number) => {
  return useQuery<WalletType>({
    queryKey: reactQueryKeys.wallet(id),
    queryFn: () => getWalletById(id),
  })
}
