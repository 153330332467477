import React, { createContext, Dispatch, SetStateAction } from 'react'
import { UseFormSetValue } from 'react-hook-form/dist/types'
import { Option } from '@node-space/storybook-components/dist/Select'
import { CustomDateValueOptionsType } from 'pages/WalletDetails/constants/walletTransactionsExportOptions'
import { BaseErrorResponse } from 'types/beneficiaries'
import { IDateValue } from 'types/types'
import {
  ExportFileFormatType,
  OnceOffExportForm,
  OnceOffExportStepsType,
} from 'types/walletTransactionsExport'

export interface OnceOffExportContextValue {
  step: OnceOffExportStepsType
  setStep: Dispatch<SetStateAction<OnceOffExportStepsType>>
  formValues: OnceOffExportForm
  setValue: UseFormSetValue<OnceOffExportForm>
  exportCustomDateOptions: CustomDateValueOptionsType
  dateValue: IDateValue
  setDateValue: Dispatch<SetStateAction<IDateValue>>
  exportFormatOptions: Option[]
  exportFormat: ExportFileFormatType
  setExportFormat: Dispatch<SetStateAction<ExportFileFormatType>>
  handleClose: () => void
  allTransactionsExportError: BaseErrorResponse
  isErrorAllTransactionsExport: boolean
  walletTransactionsExportError: BaseErrorResponse
  isErrorWalletTransactionsExport: boolean
}

export const OnceOffExportContext = createContext<OnceOffExportContextValue>({
  step: null,
  setStep: () => null,
  formValues: null,
  setValue: () => null,
  exportCustomDateOptions: null,
  dateValue: null,
  setDateValue: () => null,
  exportFormatOptions: null,
  exportFormat: null,
  setExportFormat: () => null,
  handleClose: () => null,
  allTransactionsExportError: null,
  isErrorAllTransactionsExport: null,
  walletTransactionsExportError: null,
  isErrorWalletTransactionsExport: null,
})

interface OnceOffExportContextProviderProps {
  value: OnceOffExportContextValue
  children: React.ReactNode
}

export const OnceOffExportContextProvider = ({
  children,
  value,
}: OnceOffExportContextProviderProps) => {
  return <OnceOffExportContext.Provider value={value}>{children}</OnceOffExportContext.Provider>
}
