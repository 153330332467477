import { PathNames } from 'constants/General'

export const routeConfig = {
  [PathNames.WALLET_DASHBOARD]: {
    title: 'wallets.title',
    link: PathNames.DASHBOARD,
  },
  [PathNames.TRANSACTION_HISTORY]: {
    title: 'wallets.transactionHistory',
    link: PathNames.TRANSACTION_HISTORY,
  },
  [PathNames.WALLET_CREATE]: {
    title: 'wallets.title',
    link: PathNames.DASHBOARD,
  },
  [PathNames.WALLETS_DETAILS]: {
    title: 'wallets.walletDetails',
    breadcrumbs: [
      {
        title: 'wallets.title',
        link: `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`,
      },
    ],
    backActionUrl: `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`,
  },
  [PathNames.CHANNELS_LINKS]: {
    title: 'channels',
    link: PathNames.DASHBOARD,
  },
  [PathNames.PAYMENTS_LINKS]: {
    title: 'payments.title',
    link: PathNames.DASHBOARD,
  },
  [PathNames.MASS_PAYOUTS]: {
    title: 'massPayouts.massPayouts',
    link: PathNames.MASS_PAYOUTS,
  },
  [PathNames.MASS_PAYOUTS_CREATE]: {
    title: 'massPayouts.massPayouts',
    breadcrumbs: [
      {
        title: 'massPayouts.massPayouts',
        link: `${PathNames.MASS_PAYOUTS}`,
      },
      {
        title: 'massPayouts.create',
        link: `${PathNames.MASS_PAYOUTS_CREATE}`,
      },
    ],
    backActionUrl: `${PathNames.MASS_PAYOUTS}`,
  },
  [PathNames.MASS_PAYOUTS_REVIEW]: {
    title: 'massPayouts.payoutDetails',
    breadcrumbs: [
      {
        title: 'massPayouts.massPayouts',
        link: `${PathNames.MASS_PAYOUTS}`,
      },
    ],
    backActionUrl: `${PathNames.MASS_PAYOUTS}`,
  },
  [PathNames.BVNK_NETWORK]: {
    title: 'bvnkNetwork.title',
    link: PathNames.BVNK_NETWORK,
  },
  [PathNames.CUSTOMERS]: {
    title: 'customers.title',
    link: PathNames.CUSTOMERS,
  },
  [PathNames.CUSTOMER]: {
    title: 'customers.title',
    breadcrumbs: [
      {
        title: 'customers.title',
        link: `${PathNames.CUSTOMERS}`,
      },
    ],
    backActionUrl: `${PathNames.CUSTOMERS}`,
  },
  create: {
    title: 'createAPayment',
    link: PathNames.DASHBOARD,
  },
  [PathNames.APPROVALS]: {
    title: 'approvals.title',
    link: PathNames.DASHBOARD,
  },
  [PathNames.MANAGE_ACCOUNT]: {
    title: 'manageAccount',
    link: PathNames.DASHBOARD,
  },
  [PathNames.MANAGE_MERCHANT_DETAILS]: {
    title: 'settingsManageMerchants',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.LEGAL_AGREEMENTS]: {
    title: 'legalAgreements.title',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_CHANNELS]: {
    title: 'payments.channels.manageChannels',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_PAYMENT_SUPPORT]: {
    title: 'settingsPaymentSupport',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_BANK_ACCOUNTS]: {
    title: 'settingsBankAccount',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_CRYPTO_WHITELISTING]: {
    title: 'settingsManageCryptoAddress',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_BENEFICIARIES]: {
    title: 'settingsManageBeneficiaries',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_API_KEYS]: {
    title: 'manageApiKeys.apiKeys',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_TEAM_MEMBERS]: {
    title: 'settingsTeamMembers',
    link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
    breadcrumbs: [
      {
        title: 'manageAccount',
        link: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
      },
    ],
    backActionUrl: `${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`,
  },
  [PathNames.MANAGE_USER_PROFILE]: {
    title: 'userProfile',
    link: null,
  },
}
