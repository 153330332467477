import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DetailsCard, DetailsCardProps } from '@node-space/storybook-components'

interface DetailsSectionProps {
  title?: string
  cards: DetailsCardProps[]
}

const DetailsSection = ({ title, cards }: DetailsSectionProps) => {
  return (
    <div className="text-gray-08 mb-5">
      {title && (
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-5">{title}</h2>
      )}

      {!!cards?.length &&
        cards?.map(({ title, details, children, button }, i) => {
          return (
            <DetailsCard
              key={uuidv4()}
              title={title}
              details={details}
              button={button}
              hasGutterTop={i > 0}
            >
              {children}
            </DetailsCard>
          )
        })}
    </div>
  )
}

export default DetailsSection
