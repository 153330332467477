import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import { secondaryButtonIconColour } from 'styles/theme/colours'

interface CopyTextWrapperProps {
  textToCopy: string
  textContainer?: React.ReactNode
}

export default function CopyTextWrapper({ textToCopy, textContainer }: CopyTextWrapperProps) {
  const { t } = useTranslation()

  return (
    <Box flex>
      {textContainer ?? <Text size="sm">{textToCopy}</Text>}
      <CopyButton
        copyText={String(textToCopy)}
        iconColour={secondaryButtonIconColour}
        confirmationMessage={t('copiedToClipboard')}
      />
    </Box>
  )
}
