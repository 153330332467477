import React from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'

export const TableRowSkeleton = ({ rows }: { rows: number }) => {
  return (
    <>
      {[...Array(rows)].map((e, i) => (
        <Box key={`row-pebble-${i}`} paddingT={8} testid="skeleton-row">
          <Box height={32} width="full" className="rounded bg-gray-02 animate-pulse"></Box>
        </Box>
      ))}
    </>
  )
}
