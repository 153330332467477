import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import Modal, {
  AlignActions,
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import Search from '@node-space/storybook-components/dist/Search'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import Text from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { Approval } from 'constants/General'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useManageApproversMutation } from 'hooks/mutations/useManageApproversMutation'
import { useAccountUsersQuery } from 'hooks/queries/useAccountUsersQuery'
import { useToastContext } from 'hooks/useToastContext'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

const ManageApproversModal = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const {
    permissions,
    approvalsConfig: { handleManageApproversModal },
  } = useApprovalsContext()
  const {
    profile: { emailAddress },
  } = useProfileContext()

  const [checkBoxValues, setCheckboxValues] = useState([])
  const [changedCheckBoxValues, setChangedCheckBoxValues] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const {
    data: accountUsers,
    isFetching: isFetchingAccountUsers,
    isSuccess,
  } = useAccountUsersQuery()
  const { mutate: mutateManageApprovers, isPending: isLoadingManageApproversAction } =
    useManageApproversMutation()

  useEffect(() => {
    setCheckboxValues(accountUsers)
  }, [accountUsers])

  const onCheckboxChange = (index: number) => {
    const updatedCheckboxValues = cloneDeep(checkBoxValues)
    updatedCheckboxValues[index].approver = !updatedCheckboxValues?.[index]?.approver

    // new approver value must be different from Old/API value to effect change
    const oldApproverValue = searchInput
      ? checkBoxValues?.[index]?.approver
      : accountUsers?.[index]?.approver
    if (oldApproverValue !== updatedCheckboxValues[index].approver) {
      changedCheckBoxValues[index] = {
        emailAddress: updatedCheckboxValues[index].emailAddress,
        approver: updatedCheckboxValues[index].approver,
      }
      setChangedCheckBoxValues(changedCheckBoxValues)
    } else {
      delete changedCheckBoxValues[index]
      setChangedCheckBoxValues(changedCheckBoxValues)
    }
    setCheckboxValues(updatedCheckboxValues)
  }

  const selectedApproversCount =
    !isFetchingAccountUsers &&
    !isLoadingManageApproversAction &&
    checkBoxValues?.filter(c => c?.approver)?.length

  const cleanChangedCheckBoxValues = changedCheckBoxValues?.filter(Boolean)

  const handleClose = () => handleManageApproversModal({ isVisible: false })

  const onManageApproversSuccess = () => {
    setSearchInput('')
    setChangedCheckBoxValues([])
    addToast({
      title: t('approvals.manageApproversSuccessToastMessage'),
      state: 'success',
      delay: 10,
    })
    queryClient.invalidateQueries({ queryKey: ['accountUsers'] })
    handleClose()
    track.Amp.track(AmplitudeEvent.EOF_MANAGE_APPROVERS_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
    })
  }

  const onManageApproversError = (error: unknown) => {
    setChangedCheckBoxValues([])
    addToast({
      title: t('oopsSomethingWentWrong'),
      state: 'error',
      delay: 10,
    })
    track.Amp.track(AmplitudeEvent.EOF_MANAGE_APPROVERS_ERROR, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
    })
    logSentryError('Error from ManageApprovals - handleManageApproversAction', error)
  }

  const handleManageApproversAction = async () => {
    mutateManageApprovers(
      { updateUserRoleGroups: cleanChangedCheckBoxValues },
      {
        onSuccess: () => onManageApproversSuccess(),
        onError: (error: unknown) => onManageApproversError(error),
      }
    )
  }

  const displaySearchField = accountUsers?.length >= Approval.MINIMUM_APPROVERS_FOR_MODAL_SEARCH

  const filterApprovers = (query: string) => {
    const lowerCaseSearchInput = query?.toLowerCase()
    setSearchInput(query)
    const filterResult = accountUsers?.filter(
      value =>
        value?.name?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        value?.emailAddress?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        value?.groups?.[0]?.name?.toLowerCase()?.includes(lowerCaseSearchInput)
    )
    setCheckboxValues(query ? filterResult : accountUsers)
  }

  const modalActions = permissions?.canEdit
    ? [
        {
          children: t('cancel'),
          secondary: true,
          onClick: handleClose,
          disabled: isFetchingAccountUsers || isLoadingManageApproversAction,
        },
        {
          children: t('save'),
          onClick: handleManageApproversAction,
          loading: isLoadingManageApproversAction,
          disabled: isFetchingAccountUsers || !cleanChangedCheckBoxValues?.length,
        },
      ]
    : [
        {
          children: t('done'),
          disabled: isFetchingAccountUsers,
          onClick: handleClose,
        },
      ]

  return (
    <Modal closeOnBackgroundClick={false} closeModal={handleClose} visible>
      <ModalNavBar
        title={`${t('approvals.manageApproversModaltitle')} ${
          !!selectedApproversCount ? `(${selectedApproversCount})` : ''
        } `}
        onClose={handleClose}
      />
      <ModalScrollable>
        <Box padding={24}>
          {isFetchingAccountUsers && (
            <Box width="full" role="skeletonTab">
              <SkeletonForm name="approversSkeletonForm" rowCount={1} />
            </Box>
          )}
          {!isFetchingAccountUsers && isSuccess && (
            <>
              <Box width="full" paddingB={16}>
                {displaySearchField && (
                  <Search
                    searchTerm={searchInput}
                    placeholderText={t('approvals.searchTeamMembers')}
                    size="sm"
                    textSize="sm"
                    testid="filter-search-field"
                    onChange={e => filterApprovers(e.target.value)}
                  />
                )}
              </Box>
              {checkBoxValues?.map((item, rowIndex) =>
                permissions?.canEdit ? (
                  <Box paddingB={16} key={rowIndex}>
                    <CheckBox
                      {...((item?.emailAddress == emailAddress || item?.isWorkspaceOwner) && {
                        disabled: true,
                      })}
                      label={`${`${item?.name || item?.emailAddress} ${
                        !!item?.groups?.[0]?.name ? `(${item?.groups?.[0]?.name})` : ''
                      }`}`}
                      value={item?.approver || item?.isWorkspaceOwner}
                      onChange={() => onCheckboxChange(rowIndex)}
                    />
                  </Box>
                ) : (
                  item?.approver && (
                    <Box paddingB={16} key={rowIndex}>
                      <Text size="sm">{`${`${item?.name || item?.emailAddress} ${
                        !!item?.groups?.[0]?.name ? `(${item?.groups?.[0]?.name})` : ''
                      }`}`}</Text>
                    </Box>
                  )
                )
              )}
            </>
          )}
        </Box>
      </ModalScrollable>
      <ModalActions
        actions={modalActions}
        {...(!permissions?.canEdit && { alignActions: AlignActions.CENTER })}
      />
    </Modal>
  )
}

export default ManageApproversModal
