import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { secondaryButtonIconColour } from 'styles/theme/colours'
import { useFeatureFlags } from '@node-space/hooks'
import { Box, Box as Form } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { ellipseMiddleText, logSentryError, wait } from '@node-space/utils'
import NotabeneWidget from 'components/Notabene/NotabeneWidget'
import {
  getNotabeneTransaction,
  handleError,
  handleValidStateChange,
  NotabeneSendScreen,
} from 'components/Notabene/utils/NotabeneUtils'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { MERCHANT_PORTAL } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useValidateCryptoAddressMutation } from 'hooks/mutations/useValidateCryptoAddressMutation'
import { useWithdrawMutation } from 'hooks/mutations/useWithdrawMutation'
import useNotabeneCredentials from 'hooks/useNotabeneCredentials'
import { useTwoFAContext } from 'hooks/useTwoFAContext'
import { getNotebeneAssetInfo } from 'services/notabene/NotabeneService'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ErrorCodes } from 'types/errorCodes'
import {
  NotabeneAssetInfoResponse,
  NotabeneErrorData,
  NotabeneTransaction,
  TransactionType,
} from 'types/notabene/notabene'
import { CODE_CRYPTO, ValidateCryptoAddressRequest } from 'types/whitelisting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { floorWithPrecision } from 'utils/utils'
import { SendActionProps, SendForm } from './Send'

const SendConfirm = ({
  setStep,
  form,
  wallets,
  dismissAction,
  registerFormField,
}: SendActionProps) => {
  const { t } = useTranslation()
  const { twoFactorEnabled } = useTwoFAContext()
  const { enableTravelRule } = useFeatureFlags()
  const notabeneCredentials = useNotabeneCredentials()
  const { currentAccount } = useAccountsContext()
  const { mutate: postSend } = useWithdrawMutation()
  const { mutate: mutateValidateCrypto } = useValidateCryptoAddressMutation()
  const formValues = form.watch()
  const [isLoading, setIsLoading] = useState(false)

  // Notabene
  const [notabeneTransactionValidated, setNotabeneTransactionValidated] = useState(false)
  const [notabeneTransaction, setNotabeneTransaction] = useState<NotabeneTransaction | undefined>(
    undefined
  )
  const [notabeneError, setNotabeneError] = useState<NotabeneErrorData | undefined>(undefined)
  const [selectedAsset, setSelectedAsset] = useState<NotabeneAssetInfoResponse | undefined>(
    undefined
  )
  const [notebeneFlowScreen, setNotabeneFlowStep] = useState<NotabeneSendScreen>(
    NotabeneSendScreen.DETAILS
  )

  // Text copy
  const [_, setCopied] = useState<string | null>()
  const [copyText, setCopyText] = useState<string>(t('copy'))

  // Error on UI
  const [confirmSendError, setConfirmSendError] = useState<string>('')

  const supportedWallets = wallets?.filter(
    x => x?.currency?.code && x?.currency?.supportsWithdrawals && !x?.currency?.fiat
  )
  const selectedWallet =
    formValues?.walletId &&
    supportedWallets &&
    supportedWallets?.find(x => x.id.toString() === formValues?.walletId)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CONVERT_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      cryptoWhitelisting: true,
      fromWallet: selectedWallet?.id,
      saveBeneficiary: formValues?.saveBeneficiary,
      beneficiaryName: formValues?.beneficiaryName,
      currency: selectedWallet?.currency?.code,
      protocol: formValues?.protocol,
      reference: formValues?.reference,
    })

    const addressArgs: ValidateCryptoAddressRequest = {
      code: CODE_CRYPTO,
      address: formValues?.address,
      accountReference: currentAccount?.reference,
      source: MERCHANT_PORTAL,
      currency: selectedWallet?.currency?.code,
      amount: Number(formValues?.amount),
      protocol: formValues?.protocol,
      ...(formValues?.destinationTag && { tag: formValues?.destinationTag }),
    }

    mutateValidateCrypto(addressArgs, {
      onSuccess: () => {
        setConfirmSendError('')
      },

      onError: (error: BaseErrorResponse) => {
        if (error?.data?.code === ErrorCodes.INVALID_ADDRESS) {
          setConfirmSendError(t('addressValidationFailed'))
        } else if (error?.data?.code === ErrorCodes.FAILED_FINCRIME_INVALID_ADDRESS) {
          setConfirmSendError(t('cantProcessPayout'))
        } else {
          setConfirmSendError(t('oopsSomethingWentWrong'))
          logSentryError('Error from Send ConfirmationV2 - validateCryptoAddress', error)
        }
      },
    })
  }, [])

  const probeNotabeneTravelRuleWidget = async () => {
    setIsLoading(true)
    if (formValues?.amount && formValues?.address && formValues?.walletId) {
      try {
        const assetInfo = await getNotebeneAssetInfo(selectedWallet?.currency?.code)
        setSelectedAsset(assetInfo)

        if (!assetInfo?.supported) {
          // TODO(dillan.isaacs): Phase 2: Add feedback for unsupported asset
          // setNotabeneFlowStep(NotabeneSendScreen.ASSET_NOT_SUPPORTED)
          setIsLoading(false)
          return
        }

        setNotabeneFlowStep(NotabeneSendScreen.WIDGET)
        track.Amp.track(AmplitudeEvent.SEND_NOTABENE_INITIATE, {
          category: AmplitudeEventCategory.WALLET,
          action: AmplitudeEventAction.LOAD,
        })
      } catch (err: unknown) {
        track.Amp.track(AmplitudeEvent.SEND_NOTABENE_ERROR, {
          category: AmplitudeEventCategory.WALLET,
          action: AmplitudeEventAction.LOAD,
          error: JSON.stringify(err),
        })
        logSentryError('Error from SendConfirm - probeNotabeneTravelRuleWidget', err)
        setNotabeneFlowStep(NotabeneSendScreen.ERROR)
      }
    }
    setIsLoading(false)
  }

  const actions: ButtonProps[] = [
    {
      children: t('confirmDetails'),
      testid: 'send-confirm-btn',
      loading: isLoading,
      disabled: isLoading || (selectedAsset?.supported && !formValues.password),
      onClick: probeNotabeneTravelRuleWidget,
    },
  ]

  const passwordActions: ButtonProps[] = [
    {
      children: t('confirmDetails'),
      testid: 'send-confirm-btn',
      loading: isLoading,
      disabled: isLoading || !formValues.password,
      onClick: () => send(),
    },
  ]

  useEffect(() => {
    if (formValues?.password?.length === TWO_FA_REQUIRED_LENGTH && twoFactorEnabled) {
      send()
    }
  }, [formValues?.password])

  /**
   * Send funds via API
   */
  const send = () => {
    if (!!confirmSendError) return

    setIsLoading(true)
    const amountPlusFee: number =
      Number(
        floorWithPrecision(Number(formValues?.amount), selectedWallet?.currency?.pricePrecision)
      ) +
      Number(
        floorWithPrecision(selectedWallet?.withdrawalFee, selectedWallet?.currency.pricePrecision)
      )

    const addressData: {
      address: string
      protocol: string
      destinationTag?: string
      travelRule?: NotabeneTransaction
      reference?: string
    } = {
      address: formValues?.address,
      protocol: formValues?.protocol || selectedWallet?.protocol || null,
      ...(enableTravelRule && {
        travelRule: {
          ...notabeneTransaction,
          ...(notabeneError ? { error: notabeneError } : {}),
        } as NotabeneTransaction,
      }),
    }

    if (formValues?.destinationTag) {
      addressData.destinationTag = formValues?.destinationTag
    }

    const postData = {
      address: JSON.stringify(addressData),
      amount: amountPlusFee,
      authorization: {},
      paymentReference: formValues?.reference,
      ...(formValues?.saveBeneficiary
        ? {
            addressWhitelistRequest: {
              name: formValues?.beneficiaryName,
              currencyCode: selectedWallet?.currency?.code,
              address: formValues?.address,
              ...(formValues?.protocol && { protocol: formValues?.protocol }),
              ...(formValues?.destinationTag && { destinationTag: formValues?.destinationTag }),
            },
          }
        : {}),
    }

    if (twoFactorEnabled) {
      postData.authorization = {
        totp: formValues?.password,
      }
    } else {
      postData.authorization = {
        password: formValues?.password,
      }
    }

    postSend(
      { walletId: selectedWallet?.id?.toString(), requestData: postData },
      {
        onSuccess: () => {
          form.setValue('steps', {
            totalSteps: 4,
            currentStep: 4,
          })
          setConfirmSendError('')
          setStep('complete')
        },
        onError: (err: BaseErrorResponse) => {
          if (err?.data?.errorList) {
            const apiError = err?.data?.errorList?.[0]?.message || t('oopsSomethingWentWrong')
            setConfirmSendError(apiError)
            err.data.errorList.forEach(error => {
              form.setError(error?.parameter as keyof SendForm, { message: error?.message })
            })
          } else if (err?.status === 403) {
            form.setError('password', {
              message: twoFactorEnabled ? t('authCodeInvalid') : t('authPasswordInvalid'),
            })
          } else {
            setConfirmSendError(t('sendPostWithdrawError'))
          }
          logSentryError('Error from SendConfirm - postWithdraw', err, postData)
        },
        onSettled: () => {
          setIsLoading(false)
        },
      }
    )
  }

  const onCopy = async (value: string) => {
    setCopied(value)
    setCopyText(t('copied'))
    await wait(5000)
    setCopied(null)
    setCopyText(t('copy'))
  }

  const notabeneTravelRuleTransaction: NotabeneTransaction | undefined =
    enableTravelRule &&
    getNotabeneTransaction(
      { address: formValues?.address, amount: formValues?.amount },
      selectedAsset
    )

  return (
    <Box data-testid="send-confirm">
      {/* nav bar */}
      <ModalNavBar
        title={t('confirmSend')}
        onBack={
          !isLoading &&
          (() => {
            setConfirmSendError('')
            setStep('form')
          })
        }
        onClose={dismissAction}
      />

      <ModalScrollable>
        {/* Notabene send flow - Travel rule FF is true */}
        {enableTravelRule ? (
          <>
            {/* Transaction details */}
            {notebeneFlowScreen === NotabeneSendScreen.DETAILS && (
              <>
                <SummaryDetail>
                  <SummaryRow
                    label={t('sendAmount')}
                    testid="send-amount"
                    description={`${floorWithPrecision(
                      formValues?.amount,
                      selectedWallet?.currency?.pricePrecision
                    )} ${selectedWallet?.currency?.code}`}
                  />
                  <SummaryRow
                    label={t('toAddress')}
                    renderDescription={
                      <Box flex flexRow>
                        <Text size="sm" color="grey">
                          {ellipseMiddleText(formValues?.address, 10, 10)}
                        </Text>
                        <Box paddingL={4}>
                          {/* TODO(dillan.isaacs): Replace with CopyButton */}
                          <CopyToClipboard
                            text={formValues?.address}
                            onCopy={() => onCopy(formValues?.address)}
                          >
                            <span data-tip={copyText}>
                              <Tooltip id="tooltip-copy" position="top" bodyContent={copyText}>
                                <Icon name="CopyIcon" color={secondaryButtonIconColour} />
                              </Tooltip>
                            </span>
                          </CopyToClipboard>
                        </Box>
                      </Box>
                    }
                    testid="to-address"
                  />
                  {formValues?.destinationTag && (
                    <SummaryRow
                      label={t('destinationTag')}
                      description={formValues.destinationTag}
                      testid="destination-tag"
                    />
                  )}
                  {formValues?.protocol && (
                    <SummaryRow
                      label={t('Network')}
                      description={formValues.protocol}
                      testid="network"
                    />
                  )}
                  <SummaryRow
                    label={t('fee')}
                    testid="fee"
                    description={`${floorWithPrecision(
                      selectedWallet?.withdrawalFee,
                      selectedWallet?.currency?.pricePrecision
                    )} ${selectedWallet?.currency?.code}`}
                  />
                </SummaryDetail>
              </>
            )}
            {/* Widget */}
            {notebeneFlowScreen === NotabeneSendScreen.WIDGET && (
              <div>
                <div className="m-6 mb-5 mt-4">
                  <NotabeneWidget
                    customerToken={notabeneCredentials?.customerToken}
                    transactionType={TransactionType.WITHDRAWAL}
                    transaction={notabeneTravelRuleTransaction}
                    vaspDID={notabeneCredentials?.vaspDID}
                    widgetBaseURL={process?.env?.NOTABENE_WIDGET_URL}
                    handleValidStateChange={(valid, notabeneTransaction) => {
                      handleValidStateChange(
                        valid,
                        notabeneTransaction,
                        setNotabeneTransaction,
                        setNotabeneTransactionValidated
                      )
                    }}
                    onError={err => handleError(err, setNotabeneError)}
                  />
                  {notabeneTransactionValidated && (
                    <div className="mb-6 space-y-4">
                      {confirmSendError && (
                        <Callout state="error" message={confirmSendError} showCloseButton={false} />
                      )}
                      <div className="flex justify-center text-gray-120 text-sm font-medium">
                        {twoFactorEnabled ? t('authCodeConfirm') : t('authPasswordConfirm')}
                      </div>
                      {/* // security input */}
                      {twoFactorEnabled ? (
                        isLoading ? (
                          <Form paddingT={24} flex justifyContent="center">
                            <Loader size="medium" />
                          </Form>
                        ) : (
                          <Input2FA
                            onChange={value => {
                              form.setValue('password', value)
                            }}
                            error={!!form.formState?.errors?.password}
                            errorText={form.formState?.errors?.password?.message}
                          />
                        )
                      ) : (
                        <Input
                          placeholder={t('password')}
                          type="password"
                          testid="password"
                          value={formValues?.password || ''}
                          disabled={isLoading}
                          onChange={e => {
                            form.clearErrors('password')
                            form.setValue('password', e?.target?.value)
                          }}
                          {...registerFormField('password')}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* TODO(dillan.isaacs): Asset not supported */}
            {/* Error */}
            {notebeneFlowScreen === NotabeneSendScreen.ERROR && (
              <Box flex flexCol alignItems="center" paddingB={16}>
                <Box
                  flex
                  alignItems="center"
                  justifyContent="center"
                  flexJustifyCenter
                  paddingB={16}
                >
                  <Icon name="ErrorCircleIcon" color={'status-error-500'} size="lg" />
                </Box>
                <Box paddingB={16}>
                  <Text align="center" size="lg" weight="medium" color="text-900" tag="h3">
                    {t('oopsSomethingWentWrongContracted')}
                  </Text>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            {/* Normal send flow - Travel rule FF is false */}
            <SummaryDetail>
              <SummaryRow
                label={t('transactionAction.sendFrom')}
                testid="send-amount"
                description={selectedWallet?.description}
              />
              <SummaryRow
                label={t('sendAmount')}
                testid="send-amount"
                description={`${floorWithPrecision(
                  formValues?.amount,
                  selectedWallet?.currency?.pricePrecision
                )} ${selectedWallet?.currency?.code}`}
              />
              <SummaryRow
                label={t('fee')}
                testid="fee"
                description={`${floorWithPrecision(
                  selectedWallet?.withdrawalFee,
                  selectedWallet?.currency?.pricePrecision
                )} ${selectedWallet?.currency?.code}`}
              />
              <SummaryRow
                label={t('toAddress')}
                renderDescription={
                  <Box flex flexRow>
                    <Text size="sm" color="grey">
                      {ellipseMiddleText(formValues?.address, 10, 20)}
                    </Text>
                    <Box paddingL={4}>
                      {/* TODO(dillan.isaacs): Replace with CopyButton */}
                      <CopyToClipboard
                        text={formValues?.address}
                        onCopy={() => onCopy(formValues?.address)}
                      >
                        <span className="cursor-pointer">
                          <Tooltip id="tooltip-copy" position="top" bodyContent={copyText}>
                            <Icon name="CopyIcon" color={secondaryButtonIconColour} />
                          </Tooltip>
                        </span>
                      </CopyToClipboard>
                    </Box>
                  </Box>
                }
                testid="to-address"
              />
              {formValues?.destinationTag && (
                <SummaryRow
                  label={t('destinationTag')}
                  description={formValues.destinationTag}
                  testid="destination-tag"
                />
              )}
              {formValues?.protocol && (
                <SummaryRow
                  label={t('selectedProtocol')}
                  description={formValues.protocol}
                  testid="network"
                />
              )}
              {formValues?.reference && (
                <SummaryRow
                  label={t('paymentReference')}
                  testid="payment-reference"
                  description={formValues?.reference}
                />
              )}
              {formValues?.beneficiaryName && (
                <SummaryRow
                  label={t('beneficiaryName')}
                  description={formValues?.beneficiaryName}
                  testid="beneficiary-name"
                />
              )}
            </SummaryDetail>
            {confirmSendError && (
              <Box paddingX={24} paddingB={16}>
                <Callout state="error" message={confirmSendError} showCloseButton={false} />
              </Box>
            )}
            <Box className="mx-6 mb-6" gapY={12}>
              <Text size="sm" color="black" weight="medium">
                {twoFactorEnabled ? t('authCodeConfirm') : t('authPasswordConfirm')}
              </Text>

              {/* security input */}
              {twoFactorEnabled ? (
                isLoading ? (
                  <Form paddingT={24} flex justifyContent="center">
                    <Loader size="medium" />
                  </Form>
                ) : (
                  <Input2FA
                    onChange={value => {
                      form.setValue('password', value)
                    }}
                    error={!!form.formState?.errors?.password}
                    errorText={form.formState?.errors?.password?.message}
                  />
                )
              ) : (
                <Input
                  placeholder={t('password')}
                  type="password"
                  testid="password"
                  value={formValues?.password || ''}
                  disabled={isLoading}
                  onChange={e => {
                    form.clearErrors('password')
                    form.setValue('password', e?.target?.value)
                  }}
                  {...registerFormField('password')}
                />
              )}
            </Box>
          </>
        )}

        {enableTravelRule && !notabeneTransactionValidated && <ModalActions actions={actions} />}

        {!twoFactorEnabled && !enableTravelRule && <ModalActions actions={passwordActions} />}
      </ModalScrollable>
    </Box>
  )
}

export default SendConfirm
