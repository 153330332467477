import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Text, { TextProps } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { PathNames } from 'constants/General'
import { ExternalLinks } from 'constants/links'

export interface MappedErrorMessageProps {
  errorCode?: number
  fallbackMessage?: React.ReactNode
}

export const MappedErrorMessage = ({ errorCode, fallbackMessage }: MappedErrorMessageProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const errorTextProps: TextProps = {
    color: 'inherit',
    size: 'sm',
  }

  const textAction = {
    contactSupport: (
      <TextAction
        text={t('errorMessages.contactSupport')}
        linkTo={ExternalLinks.CONTACT_SUPPORT}
        inheritColor
      />
    ),
    expiredSession: (
      <TextAction text={t('errorMessages.expiredLoginSession')} isWindowReload inheritColor />
    ),
    manageTeamMembers: (
      <TextAction
        text={t('teamMember.workspaceOwner')}
        onClick={() => navigate(PathNames.MANAGE_TEAM_MEMBERS)}
        inheritColor
      />
    ),
    reload: <TextAction text={t('errorMessages.reload')} isWindowReload inheritColor />,
  }

  const tryLaterOrContactSupport = (
    <Text tag="span" {...errorTextProps}>
      {`${t('errorMessages.tryAgainLater')} `}
      {textAction.contactSupport}.
    </Text>
  )

  if (errorCode >= 500) {
    return (
      <Text {...errorTextProps}>
        {`${t('errorMessages.error5xx')} `}
        {tryLaterOrContactSupport}
      </Text>
    )
  }

  switch (errorCode) {
    case 401:
      return (
        <Text {...errorTextProps}>
          {`${t('errorMessages.error401')} `}
          {textAction.expiredSession}.
        </Text>
      )
    case 403:
      return (
        <Text {...errorTextProps}>
          {t('errorMessages.error403Start')}
          {textAction.manageTeamMembers}
          {t('errorMessages.error403End')}
        </Text>
      )
    case 429:
      return (
        <Text {...errorTextProps}>
          {`${t('errorMessages.error429')} `}
          {tryLaterOrContactSupport}
        </Text>
      )

    default:
      return (
        <Text {...errorTextProps}>
          {fallbackMessage ?? t('errorMessages.errorOccured')}
          {` ${t('errorMessages.please')} `}
          {textAction.reload}
          {` ${t('errorMessages.or')} `}
          {textAction.contactSupport}.
        </Text>
      )
  }
}
