import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Box as Form,
  Box as FormRow,
  Box as FormWrapper,
} from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { ExpirySelect } from '@node-space/storybook-components/dist/ExpirySelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { Select } from '@node-space/storybook-components/dist/Select'
import { useMerchantsContext } from 'hooks/context'
import { useCreatePaymentMutation } from 'hooks/mutations'
import { useCryptoCurrenciesQuery, useFiatCurrenciesQuery } from 'hooks/queries'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { useCreatedPaymentContext } from 'pages/Payments/hooks/useCreatedPaymentContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ErrorCodes } from 'types/errorCodes'
import { PaymentsCreateRequest } from 'types/payments'
import { ErrorAPI, ErrorResponseV2, MerchantType } from 'types/types'
import track from 'utils/tracker'
import { generateReference, mapToOptions, roundNumberWithCommas } from 'utils/utils'

export interface PaymentsCreateForm {
  onSuccess: (val: string) => void
}

const PaymentsCreateForm = ({ onSuccess }: PaymentsCreateForm) => {
  const { t } = useTranslation()

  const merchantList = useMerchantsContext()
  const { data: fiatCurrencies } = useFiatCurrenciesQuery()
  const { data: cryptoCurrencies } = useCryptoCurrenciesQuery()
  const { mutate, isPending, error } = useCreatePaymentMutation()

  const [activeMerchant, setActiveMerchant] = useState<MerchantType>(merchantList?.[0])
  const { setCreatedPayment } = useCreatedPaymentContext()
  const permissions = useMappedRoles()?.payments
  const [paymentCreateError, setPaymentCreateError] = useState<string>('')

  const { register, formState, handleSubmit, setValue, watch, setError, clearErrors } =
    useForm<PaymentsCreateRequest>({
      defaultValues: {
        merchantId: activeMerchant?.merchantId || '',
        currency: activeMerchant?.wallet?.currency?.code || '',
        amount: '',
        returnUrl: '',
        reference: `${generateReference()}`,
        flow: 'DEFAULT',
        type: 'IN',
        expiryMinutes: '2880',
      },
    })

  const canCreateOnlyPayIn = permissions?.canCreatePayIn && !permissions?.canEdit

  const paymentOptions = [{ value: 'IN', label: 'In' }]

  if (!canCreateOnlyPayIn) {
    paymentOptions.push({ value: 'OUT', label: 'Out' })
  }

  useEffect(() => {
    setPaymentCreateError('')
    setActiveMerchant(merchantList[0])
  }, [merchantList])

  /**
   * Set the active merchant on Select
   * @param value
   */
  const onChangeMerchant = (value: string) => {
    let selectedMerchant = null
    setValue('merchantId', value)
    if (merchantList.length > 0) {
      selectedMerchant = merchantList.find(m => {
        return m.merchantId == value
      })
    }
    if (selectedMerchant) {
      setActiveMerchant(selectedMerchant)
      setValue('currency', selectedMerchant?.wallet?.currency?.code)
    }
  }

  /**
   * Loop over the merchant list and prepare the data
   * for Select options or return an empty array.
   */
  const filteredMerchantList = merchantList.filter(merchant => useIsActiveWallet(merchant?.wallet))
  const merchantListOptions = filteredMerchantList?.map(merchant => {
    const code = merchant?.wallet?.currency?.code
    const approximateBalance = merchant.wallet?.currency?.fiat
      ? roundNumberWithCommas(merchant?.wallet?.approxBalance, 2)
      : merchant?.wallet?.approxBalance

    return {
      icon: code?.toLowerCase(),
      label: merchant?.displayName,
      value: merchant?.merchantId,
      secondLabel: ` ${approximateBalance} ${code}`,
      secondLabelText: t('fundingWallet'),
    }
  })

  const fiatCurrenciesOptions = mapToOptions(fiatCurrencies)
  const cryptoCurrenciesOptions = mapToOptions(cryptoCurrencies)

  const currenciesOptions = [...fiatCurrenciesOptions, ...cryptoCurrenciesOptions].sort((x, y) =>
    x.label.localeCompare(y.label)
  )

  useEffect(() => {
    if (error) {
      const err: BaseErrorResponse = error
      const axiosError = err as { data: ErrorAPI }
      const responseError = err as { data: ErrorResponseV2 }

      if (responseError?.data?.code === ErrorCodes.FORBIDDEN_REQUEST) {
        setPaymentCreateError(responseError?.data?.message || t('payments.createPaymentForbidden'))
      }

      if (responseError?.data?.code === ErrorCodes.REFERENCE_SPECIAL_CHARACTERS) {
        setError('reference', { message: responseError?.data?.message })
      }

      if (axiosError && axiosError.data?.errorList && axiosError.data?.errorList.length > 0) {
        axiosError.data.errorList.forEach(axiosErr =>
          setError(axiosErr.parameter as keyof PaymentsCreateRequest, {
            message: axiosErr?.message,
          })
        )
      }
    }
  }, [error])

  const handleResetCurrency = () => {
    setValue('currency', activeMerchant?.wallet?.currency?.code)
  }

  const onSubmit: SubmitHandler<PaymentsCreateRequest> = values => {
    mutate(values, {
      onSuccess: res => {
        setPaymentCreateError('')
        // GA - Create Payment
        track.GA.event({
          category: 'Wallet',
          action: `Merchant: '${watch('merchantId')}' successfully created an ${watch(
            'type'
          )} payment. Reference: '${watch('reference')}'`,
        })
        setCreatedPayment(res)
        onSuccess(res?.redirectUrl)
      },
    })
  }

  return (
    <FormWrapper tag="section" testid="payment-create-form-wrapper">
      <Form tag="form" testid="payments-create-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalScrollable>
          <Box className="px-6">
            {/* API Error */}
            {paymentCreateError && (
              <FormRow paddingT={24}>
                <Callout state="error" message={paymentCreateError} showCloseButton={false} />
              </FormRow>
            )}
            {/* Select: Merchant ID */}
            <FormRow
              testid="payments-create-form-merchant-select-field"
              className="mb-12 h-16 mt-6 z-20"
            >
              <DoubleLabelSelect
                label={t('merchantId')}
                name="merchantId"
                options={merchantListOptions}
                value={watch('merchantId')}
                onChange={value => {
                  onChangeMerchant(value)
                }}
                menuMaxHeight={225}
                placeholder={t('merchantId')}
                iconsUrl={process.env.ICONS_URL}
                error={!!formState?.errors?.merchantId}
                errorText={formState?.errors?.merchantId?.message}
              />
            </FormRow>
            <FormRow
              testid="payments-create-form-amount-field"
              className="flex flex-wrap -mx-3 mb-6 lg:mb-0"
            >
              {/* Input: Payment Amount */}
              <Box className="lg:flex md:64 flex-grow px-3 mb-3 lg:mb-0">
                <Box className="lg:w-60 lg:mr-6 mb-6">
                  <Input
                    label={t('paymentAmount')}
                    required={true}
                    inputMode="numeric"
                    placeholder={`${t('amount')}...`}
                    name="amount"
                    testid="payment-amount"
                    errorText={formState?.errors?.amount?.message}
                    error={!!formState?.errors?.amount}
                    postLabel={watch('currency')}
                    {...register('amount')}
                  />
                </Box>
                <Box
                  testid="payments-create-form-payment-type-field"
                  className="w-full lg:flex-1 h-16"
                >
                  {/* Select: Type */}
                  <Radio
                    horizontal
                    borderless={false}
                    label={t('type')}
                    options={paymentOptions}
                    value={watch('type')}
                    onChange={value => {
                      setValue('type', value)
                    }}
                  ></Radio>
                </Box>
              </Box>
            </FormRow>
            <FormRow
              testid="payments-create-form-display-currency-field"
              className="flex flex-wrap -mx-3 mb-6 px-3"
            >
              <Box className="w-full">
                <Select
                  label={t('displayCurrency')}
                  name="currency"
                  options={currenciesOptions}
                  onChange={value => {
                    setValue('currency', value)
                  }}
                  isSearchable={true}
                  value={watch('currency')}
                  rightLabel={t('optionalLabel')}
                  error={!!formState?.errors?.currency}
                  errorText={formState?.errors?.currency?.message}
                />
              </Box>
            </FormRow>

            {watch('currency') && watch('currency') !== activeMerchant?.wallet?.currency?.code && (
              <Box className="mb-6" role="alert" data-testid="display-currency-warning">
                <Callout state="warning" message={t('displayCurrencyNotice')}>
                  <p>
                    {t('displayCurrencyNotice')}{' '}
                    <button className="underline" onClick={handleResetCurrency}>
                      {t('ResetTheDisplayCurrency')}
                    </button>
                  </p>
                </Callout>
              </Box>
            )}
            <Box className="flex flex-wrap -mx-3 mb-6 px-3 lg:mb-0">
              {/* Input: Unique Reference */}
              <Box className="lg:w-60 lg:mr-6 w-full mb-6">
                <Input
                  testid="payments-create-form-reference-field"
                  label={t('uniqueReference')}
                  inputMode="text"
                  placeholder={t('uniqueReference')}
                  name="reference"
                  errorText={formState?.errors?.reference?.message}
                  error={!!formState?.errors?.reference}
                  {...register('reference')}
                />
                {/* Input: Expiry Minutes */}
              </Box>
              <Box
                testid="payments-create-form-expiry-date-field"
                className="flex-grow lg:w-16 w-full mb-3"
              >
                <ExpirySelect
                  name="expiryAmount"
                  options={[
                    { value: 'days', label: t('days') },
                    { value: 'minutes', label: t('minutes') },
                  ]}
                  value="days"
                  placeholder={t('selectOption')}
                  menuMaxHeight={215}
                  onValueChange={values => {
                    setValue(
                      'expiryMinutes',
                      values.expiryType == 'days'
                        ? values.expiryAmount * 24 * 60
                        : values.expiryAmount
                    )
                  }}
                  errorText={formState?.errors?.expiryMinutes?.message}
                  error={!!formState?.errors?.expiryMinutes}
                />
              </Box>
            </Box>
            {/* Input: Return URL */}
            <Box className="mb-6 pb-6">
              <Input
                testid="payments-create-form-return-url-field"
                label={t('returnUrl')}
                inputMode="text"
                placeholder="http://..."
                name="returnUrl"
                rightLabel={t('optionalLabel')}
                errorText={formState?.errors?.returnUrl?.message}
                error={!!formState?.errors?.returnUrl}
                {...register('returnUrl')}
              />
            </Box>
          </Box>
        </ModalScrollable>
        {/* Button: Create Payment */}
        <ModalActions
          actions={[
            {
              children: t('createPayment'),
              onClick: () => {
                clearErrors()
              },
              disabled: isPending || watch('amount') === '' || watch('merchantId') === '',
              loading: isPending,
              type: 'submit',
              testid: 'payments-create-form-create-btn',
            },
          ]}
        />
      </Form>
    </FormWrapper>
  )
}

export default React.memo(PaymentsCreateForm)
