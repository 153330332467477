import { useFeatureFlags } from '@node-space/hooks'
import { EnableFiatCurrencyFilter } from 'types/types'

interface WalletProperties {
  currency: string
  supportsWithdrawals: boolean
  supportsThirdParty: boolean
  supportsDeposits: boolean
  partner: string
}
const walletActionsSupport = (
  walletProperties: WalletProperties,
  enableFiatCurrencyFilter: EnableFiatCurrencyFilter
) => {
  const { currency, supportsWithdrawals, supportsThirdParty, supportsDeposits } = walletProperties
  const supportedWithdrawCurrencies = enableFiatCurrencyFilter?.supportedWithdrawCurrencies || []
  const supportedDepositCurrencies = enableFiatCurrencyFilter?.supportedDepositCurrencies || []
  const supportedPayCurrencies = enableFiatCurrencyFilter?.supportedPayCurrencies || []
  const walletSupportsWithdrawals =
    supportsWithdrawals && supportedWithdrawCurrencies.includes(currency?.toUpperCase())
  const walletSupportsPay =
    supportsThirdParty && supportedPayCurrencies?.includes(currency?.toUpperCase())
  const walletSupportsDeposits =
    (supportedDepositCurrencies?.includes(currency?.toUpperCase()) && supportsDeposits) ||
    (!supportedDepositCurrencies && supportsDeposits)

  return {
    supportsPay: walletSupportsPay,
    supportsDeposits: walletSupportsDeposits,
    supportsWithdrawals: walletSupportsWithdrawals,
  }
}

export const useFiatWalletActionSupport = (walletProperties: WalletProperties) => {
  const { enableFiatCurrencyFilter, walletPartnerConfig } = useFeatureFlags()
  const { supportsPay, supportsDeposits, supportsWithdrawals } = walletActionsSupport(
    walletProperties,
    enableFiatCurrencyFilter
  )
  const walletPartner = walletProperties?.partner || ''
  const isWalletPartnerAndPartnerConfigAvailable =
    !!walletProperties?.partner && !!walletPartnerConfig?.[walletPartner]

  // If there's a wallet parter config, this should take priority
  if (isWalletPartnerAndPartnerConfigAvailable) {
    const { pay, deposit, withdraw } = walletPartnerConfig?.[walletPartner]?.walletActions || {}

    return {
      supportsPay: supportsPay && !!pay,
      supportsDeposits: supportsDeposits && !!deposit,
      supportsWithdrawals: supportsWithdrawals && !!withdraw,
    }
  } else {
    return {
      supportsPay,
      supportsDeposits,
      supportsWithdrawals,
    }
  }
}
