import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { StatusPillProps } from '@node-space/storybook-components/dist/StatusPill'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { useLegalAgreementsQuery } from 'hooks/queries/useLegalAgreementsQuery'
import {
  LegalAgreementStatus,
  LegalAgreementStatusType,
  LegalAgreementTableType,
  LegalAgreementType,
} from './types'
import { LegalAgreementsTable } from './LegalAgreementsTable'
import { LegalAgreementDetails } from './LegalAgreementDetails'

export const LegalAgreements = () => {
  const { t } = useTranslation()
  const { enableLegalAgreementsCallout } = useFeatureFlags()

  const getStatus = (status: LegalAgreementStatusType): StatusPillProps => {
    switch (status) {
      case LegalAgreementStatus.PENDING:
        return {
          state: 'warning',
          label: `${t('statusLabel.pending')}`,
        }
      case LegalAgreementStatus.SIGNED:
        return {
          state: 'success',
          label: `${t('statusLabel.accepted')}`,
        }
    }
  }

  const mapAgreementsData = (data: LegalAgreementType[]) => {
    if (!data?.length) return []

    return data?.map(agreement => ({
      ...agreement,
      status: agreement?.status as LegalAgreementStatusType,
      statusPill: getStatus(agreement?.status as LegalAgreementStatusType),
    }))
  }

  const {
    data: agreementsData,
    isFetching: isFetchingAgreementsData,
    isError: isAgreementsDataError,
    refetch: refetchAgreementsData,
  } = useLegalAgreementsQuery([LegalAgreementStatus.PENDING, LegalAgreementStatus.SIGNED])

  const agreementsList = useMemo(() => mapAgreementsData(agreementsData), [agreementsData])

  const {
    isSidePanelOpen: isAgreementDetailsOpen,
    sidePanelData: agreementDetails,
    paging: pageAgreements,
    setIsSidePanelOpen: setIsAgreementDetailsOpen,
    updateSidePanel: updateAgreementDetails,
  } = useSidePanel<LegalAgreementTableType>(agreementsList)

  const showAgreements = useMemo(
    () => !isFetchingAgreementsData && !isAgreementsDataError && !!agreementsData?.length,
    [isFetchingAgreementsData, isAgreementsDataError, agreementsData]
  )
  const showEmptyState = useMemo(
    () => !isFetchingAgreementsData && !isAgreementsDataError && !agreementsData?.length,
    [isFetchingAgreementsData, isAgreementsDataError, agreementsData]
  )
  const showErrorState = useMemo(
    () => !isFetchingAgreementsData && isAgreementsDataError,
    [isFetchingAgreementsData, isAgreementsDataError]
  )

  return (
    <>
      <PageHeader description={t('legalAgreements.mainDescription')} />

      {!!enableLegalAgreementsCallout?.text && (
        <Box paddingB={12}>
          <Callout state="info" message={enableLegalAgreementsCallout?.text} />
        </Box>
      )}

      {(isFetchingAgreementsData || showAgreements) && (
        <LegalAgreementsTable
          agreementsList={agreementsList}
          isLoading={isFetchingAgreementsData}
          onOpenAgreementDetails={(index: number) => {
            updateAgreementDetails(index)
            setIsAgreementDetailsOpen(true)
          }}
        />
      )}

      {!isFetchingAgreementsData && showAgreements && (
        <LegalAgreementDetails
          isOpen={isAgreementDetailsOpen}
          agreement={agreementDetails}
          pageAgreements={pageAgreements}
          onClose={() => setIsAgreementDetailsOpen(false)}
        />
      )}

      {showEmptyState && (
        <StatusCard
          headline={t('legalAgreements.emptyStateHeadline')}
          description={t('legalAgreements.emptyStateDescription')}
        />
      )}

      {showErrorState && (
        <StatusCard status="error" headline={t('errorMessages.getErrorTitle')}>
          <Text size="sm">
            {t('errorMessages.errorAction')}{' '}
            <TextAction text={t('errorMessages.reload')} onClick={refetchAgreementsData} />.
          </Text>
        </StatusCard>
      )}
    </>
  )
}
