import React from 'react'
import { useTranslation } from 'react-i18next'
import { Callout } from '@node-space/storybook-components'
import { useWalletContext } from 'hooks/context'
import { useProtocolContext } from 'hooks/context/useProtocolContext'
import { getWalletProtocolNetwork } from './getWalletProtocolNetwork'

export const WarningMessage = () => {
  const { t } = useTranslation()
  const wallet = useWalletContext()
  const { currentProtocol } = useProtocolContext()

  const { selectedProtocolNetwork: protocolNetwork } = getWalletProtocolNetwork(
    wallet,
    currentProtocol
  )

  return (
    <Callout
      testid="crypto-send-warning"
      state="warning"
      message={t('cryptoDepositWarning', {
        currencyCode: wallet.currency?.code?.toUpperCase(),
        protocol: !!protocolNetwork ? `(${protocolNetwork} network)` : '',
      })}
    />
  )
}
