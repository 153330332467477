import { atom } from 'jotai'
import { BeneficiaryTransferDestination, BeneficiaryType } from 'types/beneficiaries'
import { WalletTypes } from 'types/types'
import { DocumentUpload } from 'types/verification'
import {
  FiatPayout,
  MassPayoutButtonGroup,
  MassPayoutButtonGroupType,
  MassPayoutCryptoBatch,
  MassPayoutPostAuthRequest,
  MassPayoutSteps,
  MassPayoutStepsType,
  ModalVariant,
  ModalVariantType,
  PreProcessedPayouts,
} from '../types'

interface ModalAtomProps {
  variant?: ModalVariantType
  isOpen: boolean
}

export const merchantAtom = atom('')
export const walletAtom = atom<string>('')
export const fileAtom = atom<DocumentUpload | null>(null as DocumentUpload)
export const uploadErrorAtom = atom('')
export const uploadMessageAtom = atom('')
export const fileNameAtom = atom('')
export const massPayoutStepAtom = atom<MassPayoutStepsType>(MassPayoutSteps.INITIAL)
export const walletTypeAtom = atom(WalletTypes.CRYPTO)
export const modalAtom = atom<ModalAtomProps>({
  variant: ModalVariant.PROCESSED,
  isOpen: false,
})
export const fiatBatchReferenceAtom = atom<string>('')
export const fiatBatchInformationAtom = atom<FiatPayout>(null as FiatPayout)
export const fiatBatchListAtom = atom<FiatPayout[]>(null as FiatPayout[])
export const isEditPayoutModalVisibleAtom = atom<boolean>(false)
export const authRequestAtom = atom<MassPayoutPostAuthRequest>(null as MassPayoutPostAuthRequest)
export const isConfirmBatchModalVisibleAtom = atom<boolean>(false)
export const preProcessedRecordToUpdateAtom = atom<PreProcessedPayouts>(null as PreProcessedPayouts)
export const preProcessedRecordToDeleteAtom = atom<PreProcessedPayouts>(null as PreProcessedPayouts)

export const totalNumberOfPayoutsAtom = atom<number>(0)
export const totalNumberOfPayoutErrorsAtom = atom<number>(0)
export const beneficiaryTypeAtom = atom<BeneficiaryType>(null as BeneficiaryType)
export const transferDestinationAtom = atom<BeneficiaryTransferDestination>(
  null as BeneficiaryTransferDestination
)
export const MassPayoutButtonGroupAtom = atom<MassPayoutButtonGroupType>(
  MassPayoutButtonGroup.BATCH_INITIAL
)
export const cryptoBatchListAtom = atom<MassPayoutCryptoBatch[]>([])
