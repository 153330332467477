import { useTranslation } from 'react-i18next'
import { InputProps } from '@node-space/storybook-components/dist/Input'
import { RadioProps } from '@node-space/storybook-components/dist/Radio'
import { Option as SelectOption, SelectProps } from '@node-space/storybook-components/dist/Select'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'

type BaseFieldProps<T> = {
  key: T
  placeholder?: string
  showLabel?: boolean
}

type InputFieldProps<T> = BaseFieldProps<T> & {
  useLabelAsPlaceholder?: boolean
  type?: 'text' | 'email' | 'password'
}

type TextAreaFieldProps<T> = BaseFieldProps<T>

type SelectFieldProps<T> = BaseFieldProps<T> & {
  key: T
  options: SelectOption[]
  onChangeAdditional?: (value: string) => void
}

type RadioFieldProps<T> = {
  key: T
  options: RadioOption[]
  onChangeAdditional?: (value: string) => void
}

/**
 * This hook contains functions for setting form field props
 * using react-hook-form foundation.
 * @param formSchema The form schema set up with yup.
 * @param form The options exposed by react-hook-form useForm hook.
 * @param formValues The form values as controlled by react-hook-form useWatch hook.
 * @template T The form keys Type used for key values.
 * @returns functions for setting various types of form field props.
 */
export const useFormFields = <T>(formSchema, form, formValues) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    formState: { errors },
  } = form

  /**
   * This function gets the field label set in form schema.
   * @param {T} key The field key.
   * @returns string
   */
  const getLabel = (key: T): string => formSchema?.fields?.[key]?.spec?.label

  /**
   * This function is used for setting shared field props.
   * @param {T} key The field key.
   * @param {String} placeholder Optional value for placeholder.
   * No placeholder if omitted.
   * @param {Boolean} showLabel Optionally shows label. False if omitted.
   * @returns form field props
   */
  const setBaseField = ({ key, placeholder, showLabel = true }: BaseFieldProps<T>) => ({
    ...register(key),
    ...(placeholder && { placeholder }),
    ...(showLabel && { label: getLabel(key) }),
    value: formValues[key],
    error: !!errors[key],
    errorText: errors[key]?.message,
  })

  /**
   * This function is used for setting an input field props.
   * @param {T} key The field key.
   * @param {String} placeholder Optional value for placeholder.
   * @param {Boolean} useLabelAsPlaceholder Optionally uses label text as input placeholder.
   * False if omitted.
   * @param {Boolean} showLabel Optionally shows label. True if omitted.
   * @param {String} type Optional value to change input type.
   * - Options are "text" | "email" | "password".
   * - "text" if omitted.
   * @returns form field props
   */
  const setInputField = ({
    key,
    placeholder,
    useLabelAsPlaceholder,
    showLabel,
    type,
  }: InputFieldProps<T>): InputProps => ({
    ...setBaseField({
      key,
      placeholder: useLabelAsPlaceholder ? getLabel(key) : placeholder,
      showLabel,
    }),
    type: type ?? 'text',
  })

  /**
   * This function is used for setting an input field props.
   * @param {T} key The field key.
   * @param {String} placeholder Optional value for placeholder.
   * @param {Boolean} showLabel Optionally shows label. True if omitted.
   * - Options are "text" | "email" | "password".
   * - "text" if omitted.
   * @returns form field props
   */
  const setTextAreaField = ({
    key,
    placeholder,
    showLabel,
  }: TextAreaFieldProps<T>): InputProps => ({
    ...setBaseField({ key, placeholder, showLabel }),
  })

  /**
   * This function is used for setting a radio field props.
   * @param {T} key The field key.
   * @param {String} placeholder Optional value for placeholder.
   * @param {SelectOption[]} options The select options array.
   * @param {Function} onChangeAdditional An optional function for any additional logic when
   * the value is changed, like resetting errors. Recieves the updated value and returns void.
   * @returns form field props
   */
  const setSelectField = ({
    key,
    placeholder,
    showLabel,
    options,
    onChangeAdditional,
  }: SelectFieldProps<T>): SelectProps => ({
    ...setBaseField({ key, placeholder, showLabel }),
    options,
    onChange: value => {
      !!onChangeAdditional && onChangeAdditional(value)
      setValue(key, value, { shouldValidate: true, shouldDirty: true })
    },
  })

  /**
   * This function is used for setting a radio field props.
   * @param {T} key The field key.
   * @param {RadioOption[]} options The radio options array.
   * @param {Function} onChangeAdditional An optional function for any additional logic when
   * the value is changed, like resetting errors. Recieves the updated value and returns void.
   * @returns form field props
   */
  const setRadioField = ({ key, options, onChangeAdditional }: RadioFieldProps<T>): RadioProps => ({
    ...setBaseField({ key }),
    options,
    onChange: value => {
      !!onChangeAdditional && onChangeAdditional(value)
      setValue(key, value, { shouldValidate: true, shouldDirty: true })
    },
  })

  /**
   * This function is used for setting an email field props.
   * @param {T} key The field key.
   * @returns form field props
   */
  const setEmailField = ({ key }: InputFieldProps<T>): InputProps => ({
    ...setBaseField({
      key,
      placeholder: t('yourEmailAddress'),
    }),
    type: 'email',
    inputMode: 'email',
  })

  return {
    getLabel,
    setBaseField,
    setInputField,
    setTextAreaField,
    setSelectField,
    setRadioField,
    setEmailField,
  }
}
