import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { BeneficiaryEntityType } from 'types/beneficiaries'
import { createGenericNameSchema } from 'utils/yupSchemas/createGenericNameSchema'
import { ALPHANUMERIC_VALIDATION, createNameSchema } from 'utils/yupSchemas/createNameSchema'
import { createReferenceSchema } from 'utils/yupSchemas/createReferenceSchema'
import { UpdateFiatPayoutFormPayload } from '../types'

export const updateFiatPayoutSchema = (t: TFunction<'translation', undefined>) => {
  const businessName = createGenericNameSchema(t('massPayouts.beneficiaryName'), t)
  const bankCode = createNameSchema(t('accountNumber'), t, null, null, ALPHANUMERIC_VALIDATION)
  const accountNumber = createNameSchema(t('accountNumber'), t, null, null, ALPHANUMERIC_VALIDATION)
  const amountLabel = t('massPayouts.amount')
  const amount = yup
    .string()
    .label(amountLabel)
    .matches(/^[0-9]*$/, t('onlyNumericValues'))
  const paymentReference = createReferenceSchema(t('reference'), t)
  const bankCountry = yup.string().label('Country').required()
  const firstName = createGenericNameSchema(t('firstName'), t)
  const lastName = createGenericNameSchema(t('lastName'), t)
  const type = yup.string().label(t('manageBeneficiaries.beneficiaryType')).required()
  const transferDestination = yup.string().label(t('massPayouts.beneficiaryLocation')).required()
  const dateOfBirth = yup
    .string()
    .trim()
    .label(t('dateOfBirth'))
    .test({
      test(value, ctx) {
        if (ctx?.parent?.beneficiaryEntityType === BeneficiaryEntityType.INDIVIDUAL) {
          const pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

          if (value) {
            if (!pattern.test(value)) {
              return ctx.createError({
                message: t('dateOfBirthInvalidError'),
              })
            }

            const dateFormat = 'DD/MM/YYYY'
            const date = dayjs(value, dateFormat)

            if (!date.isValid()) {
              return ctx.createError({
                message: t('dateOfBirthInvalidError'),
              })
            }

            const maxDate = dayjs(new Date())
            if (date.isAfter(maxDate)) {
              return ctx.createError({
                message: t('dateOfBirthFutureDateError'),
              })
            }
            return true
          }

          return true
        }

        return ctx.schema.notRequired()
      },
    })

  const formFields = {
    accountNumber,
    amount,
    bankCode,
    bankCountry,
    type,
    businessName,
    dateOfBirth,
    firstName,
    lastName,
    paymentReference,
    transferDestination,
  }

  return yup.object<Partial<Record<keyof UpdateFiatPayoutFormPayload, yup.AnySchema>>>(formFields)
}
