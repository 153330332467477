import React, { useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useDeleteBeneficiaryMutation } from 'hooks/mutations/useBeneficiariesMutation'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryName } from 'reactQueryKeys/reactQueryKeys'
import {
  BeneficiaryDetails,
  BeneficiaryDetailsCurrency,
  BeneficiaryEntityType,
} from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

type DeleteBeneficiaryProps = {
  beneficiaryDetails: BeneficiaryDetails
  onClose: () => void
}

export const DeleteBeneficiary = ({ beneficiaryDetails, onClose }: DeleteBeneficiaryProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()

  const { mutate, isPending } = useDeleteBeneficiaryMutation()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.DELETE_BENEFICIARY_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const details = useMemo(() => {
    const {
      reference,
      entityType,
      currency,
      firstName,
      lastName,
      businessName,
      fiat: { accountNumber },
    } = beneficiaryDetails

    return {
      reference,
      name:
        entityType === BeneficiaryEntityType.INDIVIDUAL ? `${firstName} ${lastName}` : businessName,
      accountLabel: currency === BeneficiaryDetailsCurrency.EUR ? t('bic') : t('accountNumber'),
      accountNumber: accountNumber,
    }
  }, [beneficiaryDetails])

  const handleDelete = () => {
    mutate(details?.reference?.toString(), {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [reactQueryName.BENEFICIARIES] })
        addToast({
          title: t('manageBeneficiaries.beneficiaryDeleted'),
          state: 'info',
        })
        onClose()
        track.Amp.track(AmplitudeEvent.EOF_DELETE_BENEFICIARY_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.CLICK,
        })
      },
      onError: () => {
        addToast({
          title: t('serverError'),
          state: 'error',
          delay: 20,
        })
        onClose()
      },
    })
  }

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      testid: 'cancel',
      secondary: true,
      onClick: onClose,
    },
    {
      children: t('confirm'),
      testid: 'delete-beneficiary',
      loading: isPending,
      onClick: handleDelete,
    },
  ]
  return (
    <>
      <ModalNavBar title={t('manageBeneficiaries.deleteBeneficiaryTitle')} onClose={onClose} />
      <Box flex flexCol paddingX={24} paddingT={20} paddingB={8} gap={16}>
        <Text size="sm" color="grey">
          {t('manageBeneficiaries.deleteBeneficiaryText')}
        </Text>
      </Box>
      <SummaryDetail testid="delete-beneficiary-summary">
        <SummaryRow
          label={t('manageBeneficiaries.beneficiaryName')}
          renderDescription={<Text size="sm">{details?.name}</Text>}
        />
        <SummaryRow
          label={t('manageBeneficiaries.beneficiaryAccountNumber')}
          renderDescription={<Text size="sm">{details?.accountNumber}</Text>}
        />
      </SummaryDetail>
      <ModalActions actions={actions} />
    </>
  )
}
