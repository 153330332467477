import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@node-space/hooks'
import { ellipseMiddleText, subtractNumbers } from '@node-space/utils'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { useExchangeRateQuery } from 'hooks/queries/useExchangeRateQuery'
import { useWalletLimits } from 'hooks/queries/useWalletLimits'
import { useWhitelistedCryptoAddressesQuery } from 'hooks/queries/useWhitelistedCryptoAddressesQuery'
import { WalletType } from 'types/types'
import {
  CryptoWithDestinationTag,
  DestinationTagObserver,
  DestinationTagValues,
} from 'types/whitelisting'
import { floorWithPrecision, formatString } from 'utils/utils'
import { SendForm } from '../Send'

export const useSendWallets = (formValues: SendForm, wallets: WalletType[]) => {
  const { t } = useTranslation()
  const { profile } = useProfileContext()
  const { data: accountPreferences } = useAccountPreferencesQuery()

  const defatultDestinationTagObserver: DestinationTagObserver = {
    mayRequireDestinationTag: false,
    destinationTagIsRequired: DestinationTagValues.NOT_REQUIRED,
  }

  const [destinationTagObserver, setDestinationTagObserver] = useState<DestinationTagObserver>(
    defatultDestinationTagObserver
  )

  const supportedWallets = wallets?.filter(
    wallet =>
      wallet?.currency?.code && wallet?.currency?.supportsWithdrawals && !wallet?.currency?.fiat
  )
  const selectedWallet =
    formValues?.walletId &&
    supportedWallets?.find(
      supportedWallet => supportedWallet?.id?.toString() === formValues?.walletId
    )

  const preferredCurrency = accountPreferences?.currency ?? profile?.currencyCode
  const debouncedAmount = useDebounce(formValues?.amount, 1000)

  const {
    data: exchangeRate,
    isFetching: isFetchingExchangeRate,
    isError: isExchangeError,
    error: exchangeRateError,
  } = useExchangeRateQuery({
    fromCurrency: selectedWallet?.currency?.code,
    toCurrency: preferredCurrency,
    amount: debouncedAmount,
    enabled: !!debouncedAmount,
  })

  const {
    data: limits,
    isFetching: isFetchingLimits,
    isError: isErrorLimits,
  } = useWalletLimits(
    selectedWallet?.currency?.code,
    selectedWallet?.id,
    !!selectedWallet?.currency?.code && !!selectedWallet?.id
  )

  const withdrawalLimits = limits?.withdrawalLimits?.current

  const {
    data: whitelistedCryptoAddresses,
    isFetching: isFetchingCryptoAddresses,
    isError: isErrorCryptoAddresses,
  } = useWhitelistedCryptoAddressesQuery({
    queryTerm: '',
    currency: selectedWallet?.currency?.code,
    pagination: false,
    enabled: !!selectedWallet?.currency?.code,
  })

  const insufficientBalance =
    selectedWallet?.balance < withdrawalLimits?.minimum ||
    withdrawalLimits?.maximum < withdrawalLimits?.minimum

  const selectedWalletCurrencyCode = selectedWallet?.currency?.code

  const amountErrorMinimum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMinimum'),
      floorWithPrecision(withdrawalLimits?.minimum, selectedWallet?.currency?.pricePrecision),
      selectedWallet?.currency?.code
    )

  // calculate max withdraw amount from available balance minus withdrawal fee
  const maxAmount =
    selectedWallet && subtractNumbers(selectedWallet?.available, selectedWallet?.withdrawalFee)

  const amountErrorMaximumFees =
    maxAmount &&
    selectedWallet &&
    formatString(
      t('amountErrorMaximumFees'),
      floorWithPrecision(maxAmount, selectedWallet?.currency?.pricePrecision),
      selectedWallet?.currency?.code
    )

  const amountErrorMaximum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMaximum'),
      floorWithPrecision(withdrawalLimits?.maximum, selectedWallet?.currency?.pricePrecision),
      selectedWallet?.currency?.code
    )

  const checkMinAmount = (amount: number | string, onBlur = false) => {
    if (withdrawalLimits?.minimum && Number(amount) < withdrawalLimits?.minimum && onBlur) {
      return amountErrorMinimum
    }
  }

  const checkMaxAmount = (amount: number | string) => {
    // check amount against available balance minus withdrawal fee
    if (Number(amount) > maxAmount) {
      return amountErrorMaximumFees
    } else if (Number(amount) > withdrawalLimits?.maximum) {
      return amountErrorMaximum
    }
  }

  const supportedWalletOptions = useMemo(
    () =>
      supportedWallets?.map(wallet => ({
        icon: wallet?.currency?.code?.toLowerCase(),
        value: wallet?.id?.toString(),
        label: wallet?.description,
        secondLabel: formatString(
          t('balanceWithCurrency'),
          floorWithPrecision(wallet?.balance, wallet?.currency?.pricePrecision),
          wallet?.currency?.code
        ),
      })),
    [supportedWallets]
  )

  const whitelistedAddressesOptions = useMemo(() => {
    return whitelistedCryptoAddresses?.results?.map(address => ({
      icon: address?.currency?.code?.toLowerCase(),
      secondLabelText: t('manageCryptoAddresses.modal.addressLabel'),
      value: destinationTagObserver?.mayRequireDestinationTag
        ? { address: address?.address, destinationTag: address?.destinationTag }
        : address?.address,
      label: address?.name,
      secondLabel: ` ${ellipseMiddleText(address?.address, 6, 6)}`,
    }))
  }, [whitelistedCryptoAddresses, destinationTagObserver])

  useEffect(() => {
    setDestinationTagObserver({
      ...destinationTagObserver,
      mayRequireDestinationTag:
        selectedWalletCurrencyCode === CryptoWithDestinationTag.XRP ||
        selectedWalletCurrencyCode === CryptoWithDestinationTag.ALGO,
      destinationTagIsRequired: DestinationTagValues.NOT_REQUIRED,
    })
  }, [selectedWalletCurrencyCode])

  return {
    selectedWallet,
    selectedWalletCurrencyCode,
    preferredCurrency,
    insufficientBalance,
    supportedWalletOptions,
    whitelistedAddressesOptions,
    destinationTagObserver,
    isFetchingCryptoAddresses,
    isFetchingExchangeRate,
    withdrawalLimits,
    amountErrorMinimum,
    whitelistedCryptoAddresses,
    exchangeRate,
    isExchangeError,
    isErrorCryptoAddresses,
    maxAmount,
    amountErrorMaximumFees,
    amountErrorMaximum,
    exchangeRateError,
    isFetchingLimits,
    isErrorLimits,
    checkMinAmount,
    checkMaxAmount,
  }
}
