import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  TablePopoverMenu,
  TablePopoverMenuItemType,
} from 'components/TablePopoverMenu/TablePopoverMenu'
import { PathNames } from 'constants/General'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { useFiatWalletActionSupport } from 'hooks/useFiatWalletActionSupport'
import { WalletTypes } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useMappedRoles } from '../../../../hooks/useMappedRoles'
import { TableSectionItem } from '../../types'
import { WalletAction } from '../Modal/WalletModal'

interface Props {
  handleEditWalletName: (id: string, description: string) => void
  setShowWalletModal?: React.Dispatch<React.SetStateAction<boolean>>
  setWalletModalAction: React.Dispatch<SetStateAction<WalletAction>>
  type: WalletTypes
  wallet: TableSectionItem
}

const Submenu = ({
  handleEditWalletName,
  setShowWalletModal,
  setWalletModalAction,
  type,
  wallet,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const permissions = useMappedRoles()
  const isNetworkWallet = wallet?.supportsInternalBvnkNetworkTransfers
  const canEdit = permissions?.wallets?.canEdit
  const canTrade = permissions?.wallets?.canTrade
  const canEditOrTrade = canEdit || canTrade
  const canPay = isNetworkWallet ? permissions?.bvnkNetwork?.canTrade : canEdit || canTrade

  const isSelectedWalletActive = useIsActiveWallet(wallet)

  const sharedSubMenuConfig: TablePopoverMenuItemType[] = [
    {
      iconName: 'ClockCounterClockwiseIcon',
      label: t('wallets.transactionHistory'),
      onClick: () =>
        navigate(`${PathNames.WALLETS}/wallet-details/${wallet?.id}?tab=recent-activity`),
    },
  ]

  const walletDetails: TablePopoverMenuItemType = {
    iconName: 'InfoCircleIcon',
    label: t('wallets.walletDetails'),
    onClick: () =>
      navigate(`${PathNames.WALLETS}/wallet-details/${wallet?.id}?tab=extended-details`),
  }

  if (canEdit && isSelectedWalletActive) {
    sharedSubMenuConfig.push({
      iconName: 'EditIcon',
      label: t('wallets.editWalletName'),
      onClick: () => {
        handleEditWalletName(`${wallet?.id}`, wallet?.description)
      },
    })
  }

  const handleSetWalletModalAction = ({ key, data }: WalletAction) => {
    setShowWalletModal?.(true)
    setWalletModalAction({ key: key, data: data })
    track.Amp.track(AmplitudeEvent.WALLET_POPOVER_MENU, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      flow: key,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
  }

  const FiatWalletMenuItems = (): TablePopoverMenuItemType[] => {
    const walletProperties = {
      currency: wallet?.currency?.code,
      supportsWithdrawals: wallet?.supportsWithdrawals,
      supportsThirdParty: wallet?.supportsThirdParty,
      supportsDeposits: wallet?.supportsDeposits,
      partner: wallet?.partner,
    }
    const { supportsPay, supportsDeposits, supportsWithdrawals } =
      useFiatWalletActionSupport(walletProperties)

    const menuItems: TablePopoverMenuItemType[] = [...sharedSubMenuConfig]

    if (supportsDeposits && canEditOrTrade && isSelectedWalletActive) {
      menuItems.push(walletDetails)
    }

    if (supportsPay && canPay && isSelectedWalletActive) {
      menuItems.push({
        iconName: 'PaymentIcon',
        label: t('transactionAction.pay'),
        onClick: () => {
          handleSetWalletModalAction({ key: 'pay', data: wallet })
        },
      })
    }

    if (supportsWithdrawals && canEditOrTrade && isSelectedWalletActive) {
      menuItems.push({
        iconName: 'WithdrawIcon',
        label: t('transactionAction.withdraw'),
        onClick: () => {
          handleSetWalletModalAction({ key: 'withdraw', data: wallet })
        },
      })
    }

    if (supportsDeposits && canEditOrTrade && isSelectedWalletActive) {
      menuItems.push({
        iconName: 'DepositIcon',
        label: t('transactionAction.deposit'),
        onClick: () => {
          handleSetWalletModalAction({ key: 'deposit', data: wallet })
        },
      })
    }

    if (canEditOrTrade && isSelectedWalletActive) {
      menuItems.push({
        iconName: 'ConvertIcon',
        label: t('transactionAction.convert'),
        onClick: () => {
          handleSetWalletModalAction({ key: 'convert', data: wallet })
        },
      })
    }

    return menuItems
  }

  const CryptoWalletMenuItems = (): TablePopoverMenuItemType[] => {
    const menuItems: TablePopoverMenuItemType[] = [...sharedSubMenuConfig]

    if (canEditOrTrade && isSelectedWalletActive) {
      menuItems.push(walletDetails)
    }

    if (canEditOrTrade && isSelectedWalletActive) {
      menuItems.push(
        {
          iconName: 'ArrowUpRightIcon',
          label: t('transactionAction.send'),
          onClick: () => {
            handleSetWalletModalAction({ key: 'send', data: wallet })
          },
        },
        {
          iconName: 'ArrowDownLeftIcon',
          label: t('transactionAction.receive'),
          onClick: () => {
            handleSetWalletModalAction({ key: 'receive', data: wallet })
          },
        },
        {
          iconName: 'ConvertIcon',
          label: t('transactionAction.convert'),
          onClick: () => {
            handleSetWalletModalAction({ key: 'convert', data: wallet })
          },
        }
      )
    }

    return menuItems
  }

  return (
    <TablePopoverMenu
      stopPropagation
      hasPadding
      testid={
        type?.toUpperCase() === WalletTypes.FIAT
          ? 'fiatContextPopoverMenu'
          : 'cryptoContextPopoverMenu'
      }
      menuItems={
        type?.toUpperCase() === WalletTypes.FIAT ? FiatWalletMenuItems() : CryptoWalletMenuItems()
      }
    />
  )
}

export default Submenu
