import React from 'react'
import { Button, Input } from '@node-space/storybook-components'
import { WalletType } from 'types/types'

interface PropsInterface {
  wallet: WalletType
  onClickCreateWalletDone: Function
  onClickCreateWalletAltAction: Function
  [x: string]: any
}

export const WalletCreateSuccessfulForm = ({
  wallet,
  onClickCreateWalletDone,
  onClickCreateWalletAltAction,
}: PropsInterface) => {
  const supportedFiatCurrencies = ['eur', 'gbp', 'zar']

  const handleOnClickCreateWalletDone = e => {
    e.preventDefault()
    onClickCreateWalletDone()
  }

  const handleOnClickCreateWalletAltAction = (e, isFiatCurrency: boolean) => {
    e.preventDefault()
    onClickCreateWalletAltAction(isFiatCurrency)
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <h1 className="heading row-span-full col-span-full mb-20">New Wallet Added</h1>
        <div className="col-span-full w-full md:w-2/3 mb-20">
          <Input
            disabled={true}
            name="wallet"
            value={wallet.description}
            testid="wallet"
            postLabel={`Balance 0.00`}
          />
        </div>
      </div>
      <div className="flex flex-row justify-center items-center gap-3 mb-20">
        <Button
          type="button"
          testid="create-wallet-done"
          onClick={e => handleOnClickCreateWalletDone(e)}
        >
          Done
        </Button>
        {supportedFiatCurrencies.includes(wallet.currency.code.toLowerCase()) &&
          wallet.currency.fiat && (
            <Button
              type="button"
              testid="create-wallet-alt-action"
              onClick={e => handleOnClickCreateWalletAltAction(e, wallet.currency.fiat)}
            >
              Fund
            </Button>
          )}
        {!wallet.currency.fiat && wallet.currency.supportsDeposits && (
          <Button
            type="button"
            testid="create-wallet-alt-action"
            onClick={e => handleOnClickCreateWalletAltAction(e, wallet.currency.fiat)}
          >
            Receive
          </Button>
        )}
      </div>
    </>
  )
}
