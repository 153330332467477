import React from 'react'
import { isNull } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import { ProofOfPaymentButton } from 'components/ProofOfPaymentButton/ProofOfPaymentButton'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { TransactionType, TTransactionSubType } from 'types/transaction/TransactionType'
import { getSupportedPaymentTypes, getTransactionStatus } from 'utils/utils'

interface ProofOfPaymentContainerProps {
  transactionType?: string
  transactionSubType?: TTransactionSubType | string
  status?: string
  externalReference?: string
  hasProofOfPayment?: boolean
  isLink?: boolean
  paymentReference?: string
}

export const ProofOfPaymentContainer = ({
  transactionType,
  transactionSubType,
  status,
  externalReference,
  hasProofOfPayment,
  isLink = false,
  paymentReference,
}: ProofOfPaymentContainerProps) => {
  const { t } = useTranslation()
  const { profile } = useProfileContext()
  const { emailAddress } = profile || {}

  const cryptoWithdrawalSubType = getSupportedPaymentTypes().CRYPTO_WITHDRAWAL

  const failedStatuses = getTransactionStatus()?.failedStatuses
  const pendingStatuses = getTransactionStatus()?.pendingStatuses

  const isFailedTransaction = failedStatuses?.includes(status)

  const isFiatWithdrawal =
    transactionType === TransactionType.USER_WITHDRAWAL &&
    transactionSubType !== cryptoWithdrawalSubType

  const popReference = paymentReference || ''

  let calloutText

  const popRequest = `${process.env.POP_REQUEST_URL}?ticket_form_id=9628604216594&tf_anonymous_requester_email=${emailAddress}&tf_subject=POP Request - Reference ${popReference}&tf_360020194739=${popReference}&tf_17483170612370=proof_of_payment_needed`

  if (isNull(hasProofOfPayment)) {
    calloutText = (
      <Box flex flexWrap gapX={4} testid="request-pop">
        {t('notBankingAPIWalletPart1')}
        <Quicklink className="inline " to={popRequest} text={t('clickHere')} />
        {t('notBankingAPIWalletPart2')}
      </Box>
    )
  } else if (pendingStatuses?.includes(status)) {
    calloutText = t('transactionInProgress')
  }

  return (
    <Box>
      {!isFailedTransaction && isFiatWithdrawal && hasProofOfPayment && (
        <Box {...(!isLink && { paddingX: 16, paddingB: 16 })}>
          <ProofOfPaymentButton isLink={isLink} externalReference={externalReference} />
        </Box>
      )}

      {!isFailedTransaction && isFiatWithdrawal && calloutText && (
        <Box {...(!isLink && { paddingX: 16, paddingB: 16 })}>
          <Callout state="info" message={calloutText} />
        </Box>
      )}
    </Box>
  )
}

export default ProofOfPaymentContainer
