import React, { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { debounce } from 'lodash-es'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import {
  formatString,
  isTestEnvironment,
  roundNumberWithCommas,
  stringToDecimal,
} from 'utils/utils'
import { selectedBvnkNetworkBeneficiaryAtom, selectedFiatBeneficiaryAtom } from './atoms/atoms'
import usePaymentFeeRequest from './hooks/usePaymentFeeRequest'
import { PayActionProps } from './Pay'
import { getRecipientName } from './utils/payFormUtils'

const PayAmount = ({
  form,
  loading,
  payFees,
  wallets,
  withdrawalLimits,
  feeError,
  confirmAmount,
  dismissAction,
  setStep,
  registerFormField,
}: PayActionProps) => {
  const selectedBeneficiary = useAtomValue(selectedFiatBeneficiaryAtom)
  const selectedBvnkNetworkBeneficiary = useAtomValue(selectedBvnkNetworkBeneficiaryAtom)
  const { t } = useTranslation()
  const formValues = form.watch()
  const [transactionFee, setTransactionFee] = useState('')
  const [maxAmount, setMaxAmount] = useState(null)
  const [feeAmount, setFeeAmount] = useState(null)

  const selectedWallet =
    formValues.walletId && wallets.find(x => x.id.toString() === formValues.walletId)

  const { maxPayoutAmount, isFetchingMaxPayoutAmount, fetchingMaxPayoutAmountError } =
    usePaymentFeeRequest(wallets, formValues, selectedBeneficiary)

  useEffect(() => {
    form.setFocus('amount')
  }, [form.setFocus])

  useEffect(() => {
    setMaxAmount(maxPayoutAmount)
  }, [maxPayoutAmount])

  useEffect(() => {
    if (!!payFees?.fee) {
      setFeeAmount(payFees?.fee)
    } else if (formValues?.amount && !!payFees?.feePercentage) {
      setFeeAmount(Number(formValues?.amount) * (payFees.feePercentage / 100))
    } else if (!!payFees?.feePercentage) {
      setFeeAmount(Number(maxAmount) * (payFees.feePercentage / 100))
    } else {
      setFeeAmount(0)
    }
  }, [selectedWallet, feeAmount, payFees])

  useEffect(() => {
    const maximumAmount = payFees?.maximumWithdrawalAmount
    setMaxAmount(maximumAmount)
  }, [selectedWallet, feeAmount])

  const validateAmount = (amount: number | string, onBlur = isTestEnvironment) => {
    const error = checkMinAmount(amount, onBlur) || checkMaxAmount(amount)

    if (error) {
      form.setError('amount', { message: error })
    } else {
      form.clearErrors('amount')
      form.setValue('amount', stringToDecimal(amount.toString()))
    }
  }

  const amountErrorMinimum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMinimum'),
      roundNumberWithCommas(withdrawalLimits.minimum),
      selectedWallet.currency.code
    )

  const amountErrorMaximumFees =
    selectedWallet.available &&
    selectedWallet &&
    t('amountErrorMaximumPay', {
      amount: roundNumberWithCommas(maxAmount),
      currencyCode: selectedWallet.currency.code,
    })

  const amountErrorMaximum =
    withdrawalLimits &&
    selectedWallet &&
    formatString(
      t('amountErrorMaximum'),
      roundNumberWithCommas(withdrawalLimits?.maximum),
      selectedWallet.currency.code
    )

  const checkMinAmount = (amount: number | string, onBlur = false) => {
    if (withdrawalLimits?.minimum && Number(amount) < withdrawalLimits.minimum && onBlur) {
      return amountErrorMinimum
    }
  }

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.PAY_INPUT_AMOUNT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: selectedWallet?.currency?.code?.toLowerCase(),
      walletId: selectedWallet?.id,
    })
  }, [])

  useEffect(() => {
    if (payFees?.feePercentage && !isNaN(Number(payFees?.feePercentage))) {
      setTransactionFee(
        `${roundNumberWithCommas(Number(formValues?.amount) * (payFees.feePercentage / 100))} ${selectedWallet?.currency?.code}`
      )
    } else if (payFees?.fee && !isNaN(Number(payFees?.fee))) {
      setTransactionFee(`${roundNumberWithCommas(payFees?.fee)} ${selectedWallet?.currency?.code}`)
    } else {
      setTransactionFee(`${roundNumberWithCommas(0)} ${selectedWallet?.currency?.code}`)
    }
  }, [payFees])

  useEffect(() => {
    if (feeError) {
      form.setError('amount', { message: feeError?.data?.message })
    } else {
      form.clearErrors('amount')
    }
  }, [feeError])

  const checkMaxAmount = (amount: number | string) => {
    // check amount against available balance minus withdrawal fee
    if (Number(amount) > maxAmount) {
      return amountErrorMaximumFees
    } else if (Number(amount) > withdrawalLimits?.maximum) {
      return amountErrorMaximum
    }
  }

  // action to set amount to a valid value based on validation errors
  const errorAction =
    !!form.formState?.errors?.amount?.message &&
    (form.formState.errors.amount.message === amountErrorMinimum
      ? () => setAmount(withdrawalLimits?.minimum)
      : form.formState.errors.amount.message === amountErrorMaximumFees
        ? () => setAmount(maxAmount)
        : form.formState.errors.amount.message === amountErrorMaximum &&
          (() => setAmount(withdrawalLimits?.maximum)))

  const setAmount = (amount: number | string) => {
    form.setValue('amount', amount?.toString())
    form.clearErrors('amount')
  }

  const onSubmit: SubmitHandler<typeof formValues> = async () => {
    confirmAmount()
  }

  const handleAmountOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      validateAmount(stringToDecimal(value))
    }
  }

  const actions: ButtonProps[] = [
    { children: t('cancel'), secondary: true, onClick: dismissAction },
    {
      children: t('continue'),
      disabled:
        !formValues.amount ||
        !!form.formState.errors.amount ||
        !!feeError ||
        !!fetchingMaxPayoutAmountError,
      testid: t('continue'),
      type: 'submit',
      loading: loading || isFetchingMaxPayoutAmount,
    },
  ]

  return (
    <Box>
      <ModalNavBar
        title={`${t('pay')} ${selectedBvnkNetworkBeneficiary ? selectedBvnkNetworkBeneficiary?.details?.name : getRecipientName(selectedBeneficiary)}`}
        onBack={() => setStep('form')}
        onClose={dismissAction}
      />

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalScrollable>
          <Box className="p-6 pt-5">
            <Box className="space-y-6">
              <Input
                {...registerFormField('amount')}
                postLabel={selectedWallet && selectedWallet.currency.code}
                inputMode="decimal"
                placeholder={'0.00'}
                required
                bottomLeftLabel={
                  maxAmount &&
                  t('amountMaximumPay', {
                    amount: roundNumberWithCommas(maxAmount),
                    currencyCode: selectedWallet.currency.code,
                  })
                }
                onBottomLeftLabelClick={() =>
                  maxAmount && setAmount(roundNumberWithCommas(maxAmount))
                }
                onBlur={e => validateAmount(stringToDecimal(e.target.value), true)}
                onChange={e =>
                  debounce(() => validateAmount(stringToDecimal(e.target.value)), 2000)
                }
                onKeyDown={e => handleAmountOnEnter(e)}
                onErrorClick={errorAction ? () => errorAction() : null}
              />
            </Box>
            <SummaryRow
              label={t('transactionFee')}
              description={transactionFee}
              testid="transaction-fee"
            />
          </Box>
        </ModalScrollable>

        {/* actions */}
        <ModalActions actions={actions} />
      </form>
    </Box>
  )
}

export default PayAmount
