import React, { createContext, useState } from 'react'

export type PendingAgreementsContextType = {
  shouldRefetch: boolean
  showSingleAgreementId: number
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>
  setShowSingleAgreementId: React.Dispatch<React.SetStateAction<number>>
}

interface PendingAgreementsProviderProps {
  children: React.ReactNode
}

export const PendingAgreementsContext = createContext<PendingAgreementsContextType>(null)

export const PendingAgreementsProvider = ({ children }: PendingAgreementsProviderProps) => {
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [showSingleAgreementId, setShowSingleAgreementId] = useState<number>()

  const value = {
    shouldRefetch,
    showSingleAgreementId,
    setShouldRefetch,
    setShowSingleAgreementId,
  }

  return (
    <PendingAgreementsContext.Provider value={value}>{children}</PendingAgreementsContext.Provider>
  )
}
