import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getWalletsV2 } from 'services/WalletsService'
import { TWalletType } from 'types/wallets'
import { TEN_MINUTES } from 'utils/utils'

export const useWalletsQueryV2 = ({
  walletType,
  searchTerm,
  offset,
  max,
}: {
  walletType: TWalletType
  searchTerm?: string
  offset?: number
  max?: number
}) => {
  return useQuery({
    queryKey: reactQueryKeys.walletsV2(walletType, searchTerm, offset, max),
    queryFn: () => getWalletsV2(walletType, searchTerm, offset, max),
    staleTime: TEN_MINUTES,
  })
}
