import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { TransactionExport } from 'pages/WalletDetails/components/RecentActivity/WalletTransactionsExport/TransactionExport'
import {
  SearchWalletTable,
  searchWalletTableTags,
} from 'pages/Wallets/components/Table/SearchWalletTable'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

export const TransactionHistory = () => {
  const { t } = useTranslation()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.TRANSACTION_HISTORY_HOME, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <>
      <PageHeader
        description={t('wallets.transactionHistoryHeader')}
        renderRightSection={<TransactionExport downloadAll />}
      />
      <SearchWalletTable pageId={searchWalletTableTags.WALLETS_TRANSACTION_HISTORY.id} />
    </>
  )
}
