import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import {
  AlignActions,
  Modal,
  ModalActions,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { SummaryDetail, SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { useDeleteCryptoAddressMutation } from 'hooks/mutations/useDeleteCryptoAddressMutation'
import { useToastContext } from 'hooks/useToastContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import { WhitelistingCryptoAddress } from 'types/whitelisting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface DeleteAddressModalProps {
  handleOnModalClose: () => void
  selectedCryptoAddress: WhitelistingCryptoAddress
  onDeleteCallback: () => void
}

const DeleteAddressModal = ({
  handleOnModalClose,
  selectedCryptoAddress,
  onDeleteCallback,
}: DeleteAddressModalProps) => {
  const { t } = useTranslation()
  const addToast = useToastContext()

  const { mutate: mutateDeleteAddress, isPending: isLoadingDeleteAddress } =
    useDeleteCryptoAddressMutation()

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.DELETE_CRYPTO_ADDRESS_WHITELIST_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const handleOnDelete = async () => {
    const commonConfig = {
      onSuccess: () => {
        onDeleteCallback()
        addToast({
          title: t('manageCryptoAddresses.modal.deleteSuccess'),
          state: 'info',
          delay: 10,
        })
        track.Amp.track(AmplitudeEvent.EOF_DELETE_CRYPTO_ADDRESS_WHITELIST_SUCCESS, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.CLICK,
        })
      },
      onError: (error: BaseErrorResponse) => {
        logSentryError('Error from DeleteAddressModal - deleteCallback ', error)
        addToast({
          title: t('oopsSomethingWentWrong'),
          state: 'error',
          delay: 10,
        })
        handleOnModalClose()
      },
    }
    mutateDeleteAddress({ cryptoId: selectedCryptoAddress?.uuid }, commonConfig)
  }

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      testid: 'close-button',
      onClick: handleOnModalClose,
      secondary: true,
    },
    {
      children: t('confirm'),
      testid: 'submit-button',
      onClick: handleOnDelete,
      loading: isLoadingDeleteAddress,
    },
  ]
  return (
    <Modal visible closeModal={handleOnModalClose} closeOnBackgroundClick>
      <ModalNavBar
        title={t('manageCryptoAddresses.modal.deleteTitle')}
        onClose={handleOnModalClose}
      />
      <>
        <Box flex flexCol paddingX={24} paddingT={20} paddingB={8} gap={16}>
          <Text size="sm" color="grey">
            {t('manageCryptoAddresses.modal.deleteDescription')}
          </Text>
        </Box>
        <SummaryDetail testid="delete-crypto-address">
          <SummaryRow
            label={t('address')}
            renderDescription={<Text size="sm">{selectedCryptoAddress?.address}</Text>}
          />
          <SummaryRow
            label={t('name')}
            renderDescription={<Text size="sm">{selectedCryptoAddress?.name}</Text>}
          />
          {!!selectedCryptoAddress?.protocol && (
            <SummaryRow
              label={t('manageCryptoAddresses.modal.protocolLabel')}
              renderDescription={<Text size="sm">{selectedCryptoAddress?.protocol}</Text>}
            />
          )}
        </SummaryDetail>
      </>
      <ModalActions actions={actions} alignActions={AlignActions.RIGHT} />
    </Modal>
  )
}

export default DeleteAddressModal
