import { ApprovalStatuses } from 'types/approvals'
import { useApprovalsTransactionsQuery } from './queries/useApprovalsTransactionsQuery'

export const usePendingApprovalDetails = (currentAccountReference: string, isEnabled?: boolean) => {
  const {
    data: pendingApprovals,
    isError: isPendingApprovalsError,
    isSuccess: isPendingApprovalsSuccess,
    isFetching: isFetchingPendingApprovals,
  } = useApprovalsTransactionsQuery(currentAccountReference, ApprovalStatuses.PENDING, isEnabled)

  const numberOfPendingApprovals = pendingApprovals?.length

  return {
    pendingApprovals,
    numberOfPendingApprovals,
    hasPendingApprovals: !!numberOfPendingApprovals,
    isPendingApprovalsError,
    isPendingApprovalsSuccess,
    isFetchingPendingApprovals,
  }
}
