import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import {
  AddCustomerFormErrors,
  AddCustomerPayload,
  CustomerEntityType,
  TransformedOption,
} from 'types/customers'
import { BusinessCustomerFields } from '../CustomerEntityType/Business'
import { IndividualCustomerFields } from '../CustomerEntityType/Individual'

interface CustomerEntityFormProps {
  activeTab: CustomerEntityType
  businessName?: string
  businessDescription?: string
  businessRegistrationNumber?: string
  businessTaxResidency?: string
  countries?: { label: string; value: string }[]
  isCountriesLoading?: boolean
  businessMonthlyVolume?: string
  monthlyVolumesOptions?: { label: string; value: string }[]
  isFetchingMonthlyVolumes?: boolean
  industryOptions?: TransformedOption[]
  isFetchingIndustries?: boolean
  addressLine1?: string
  addressLine2?: string
  city?: string
  postCode?: string
  countryCode?: string
  industry?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  nationality?: string
  errors?: AddCustomerFormErrors
  register?: UseFormRegister<AddCustomerPayload>
  setValue?: (name: string, value: string) => void
}

export const CustomerEntityForm = ({
  activeTab,
  businessName,
  businessDescription,
  businessRegistrationNumber,
  businessTaxResidency,
  countries,
  isCountriesLoading,
  businessMonthlyVolume,
  monthlyVolumesOptions,
  isFetchingMonthlyVolumes,
  industryOptions,
  isFetchingIndustries,
  addressLine1,
  addressLine2,
  city,
  postCode,
  countryCode,
  industry,
  firstName,
  lastName,
  dateOfBirth,
  nationality,
  errors,
  register,
  setValue,
}: CustomerEntityFormProps) => {
  switch (activeTab) {
    case CustomerEntityType.COMPANY:
      return (
        <BusinessCustomerFields
          businessName={businessName}
          businessDescription={businessDescription}
          businessRegistrationNumber={businessRegistrationNumber}
          isCountriesLoading={isCountriesLoading}
          countries={countries}
          businessTaxResidency={businessTaxResidency}
          businessMonthlyVolume={businessMonthlyVolume}
          monthlyVolumesOptions={monthlyVolumesOptions}
          isFetchingMonthlyVolumes={isFetchingMonthlyVolumes}
          industryOptions={industryOptions}
          isFetchingIndustries={isFetchingIndustries}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          postCode={postCode}
          countryCode={countryCode}
          industry={industry}
          firstName={firstName}
          lastName={lastName}
          dateOfBirth={dateOfBirth}
          nationality={nationality}
          errors={errors}
          register={register}
          setValue={setValue}
        />
      )
    case CustomerEntityType.INDIVIDUAL:
      return (
        <IndividualCustomerFields
          firstName={firstName}
          lastName={lastName}
          dateOfBirth={dateOfBirth}
          errors={errors}
          register={register}
          setValue={setValue}
        />
      )
    default:
      return null
  }
}
