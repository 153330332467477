import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import Box, { Box as Wrapper } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { TableRow } from '@node-space/storybook-components/dist/SimpleTable'
import { SimpleTableV2 } from '@node-space/storybook-components/dist/SimpleTableV2'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { MerchantsProvider } from 'contexts/merchantsContext'
import { useMerchantsQuery } from 'hooks/queries/useMerchantsQuery'
import { Channel } from 'types/channels'
import { CurrencyInterface } from 'types/wallets'
import EditChannelForm from './EditChannelForm'
import Currency from './TableCell/Currency'

interface ChannelsTableProps {
  channels: Channel[]
  currencies: CurrencyInterface[]
}

const ChannelsTable = ({ channels, currencies }: ChannelsTableProps) => {
  const { t } = useTranslation()
  const { data: { merchantList = [] } = {} } = useMerchantsQuery()
  const [selectedChannelDetails, setSelectedChannelDetails] = useState<Channel | null>(null)
  const [isModalDetailsOpen, setModalDetailsOpen] = useState(false)
  const [isModalEditOpen, setModalEditOpen] = useState(false)
  const { enableEditChannel } = useFeatureFlags()

  const handleRowClick = (channel: Channel) => {
    setSelectedChannelDetails(channel)
  }

  const handleOpenDetailsModal = (channel: Channel) => {
    setSelectedChannelDetails(channel)
    setModalDetailsOpen(true)
  }

  const handleCloseDetailModal = () => {
    setModalDetailsOpen(false)
  }

  const handleCloseEditModal = () => {
    setModalEditOpen(false)
  }

  const handleOpenEditModal = (channel: Channel) => {
    setSelectedChannelDetails(channel)
    setModalEditOpen(true)
  }

  const convertChannelInfo = (): TableRow[] => {
    if (!channels) return []

    return channels?.map(channel => {
      return {
        onClick: () => handleRowClick(channel),
        rowData: [
          {
            cellData: <Text size="sm">{channel.reference}</Text>,
            className: 'px-8 py-4',
          },
          {
            cellData: <Text size="sm">{channel.merchantId}</Text>,
          },
          {
            cellData: <Currency walletCurrency={channel.walletCurrency} currencies={currencies} />,
          },
          {
            cellData: (
              <TablePopoverMenu
                stopPropagation
                testid={channel.uuid}
                menuItems={[
                  {
                    iconName: 'InfoCircleIcon',
                    label: t('Details'),
                    onClick: () => handleOpenDetailsModal(channel),
                  },
                  ...(enableEditChannel
                    ? [
                        {
                          iconName: 'EditIcon' as const,
                          label: t('Edit'),
                          onClick: () => handleOpenEditModal(channel),
                        },
                      ]
                    : []),
                ]}
              />
            ),
          },
        ],
      }
    })
  }

  return (
    <Wrapper className="my-6 overflow-x-auto">
      <SimpleTableV2
        columns={[
          { headerTitle: t('channelReference'), className: 'px-8' },
          { headerTitle: t('merchantId'), className: 'px-8' },
          { headerTitle: t('walletCurrency'), className: 'px-8' },
          {
            className: 'px-4 mr--2',
            headerTitle: '',
          },
        ]}
        tableData={convertChannelInfo()}
      />
      {isModalEditOpen && (
        <Modal allowOverFlow visible={isModalEditOpen} closeModal={handleCloseEditModal}>
          <ModalNavBar title={t('payments.channels.editChannel')} onClose={handleCloseEditModal} />
          <MerchantsProvider merchants={merchantList}>
            <EditChannelForm
              selectedChannelDetails={selectedChannelDetails}
              key={selectedChannelDetails?.id}
              onSuccess={() => {
                setModalEditOpen(false)
              }}
            />
          </MerchantsProvider>
        </Modal>
      )}
      <Modal
        testid="channelDetails"
        allowOverFlow
        visible={isModalDetailsOpen}
        closeModal={handleCloseDetailModal}
      >
        <ModalNavBar title={selectedChannelDetails?.reference} onClose={handleCloseDetailModal} />
        <SummaryDetail>
          <SummaryRow
            label="Channel URL"
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {ellipseMiddleText(selectedChannelDetails?.redirectUrl, 30, 10)}
                </Text>
                <CopyButton
                  copyText={selectedChannelDetails?.redirectUrl}
                  confirmationMessage={t('copiedToClipboard')}
                />
                <Button
                  noStyling
                  className={`text-primary-500 hover:text-primary-700 active:text-primary-900`}
                  iconElement={<Icon name="ExternalLinkIcon" color="inherit" />}
                  onClick={() => {
                    window.open(selectedChannelDetails?.redirectUrl)
                  }}
                />
              </Box>
            }
          ></SummaryRow>
          <SummaryRow
            label="Merchant ID"
            renderDescription={
              <Box className="flex flex-row justify-between">
                <Text size="sm" color="grey">
                  {ellipseMiddleText(selectedChannelDetails?.merchantId, 30, 10)}
                </Text>
                <CopyButton
                  copyText={selectedChannelDetails?.redirectUrl}
                  confirmationMessage={t('copiedToClipboard')}
                />
              </Box>
            }
          ></SummaryRow>
          <SummaryRow
            label="Wallet Currency"
            renderDescription={
              <Text size="sm" color="grey">
                {selectedChannelDetails?.walletCurrency}
              </Text>
            }
          ></SummaryRow>
        </SummaryDetail>
      </Modal>
    </Wrapper>
  )
}

export default React.memo(ChannelsTable)
