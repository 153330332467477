import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { AddCustomerPayload } from 'types/customers'

export const addCustomerDetailsSchema = (t: TFunction<'translation', undefined>) => {
  const validateInputField = (label: string, minLength?: number, maxLength?: number) => {
    let schema = yup
      .string()
      .trim()
      .required(t('fieldRequired', { field: label, interpolation: { escapeValue: false } }))
      .label(label)

    if (minLength !== undefined) {
      schema = schema.min(
        minLength,
        t('fieldMinLength', { field: label, min: minLength, interpolation: { escapeValue: false } })
      )
    }

    if (maxLength !== undefined) {
      schema = schema.max(
        maxLength,
        t('fieldMaxLength', { field: label, max: maxLength, interpolation: { escapeValue: false } })
      )
    }

    return schema
  }

  const customerEntityType = yup.string().trim().required()
  const customerType = yup.string().trim().required()
  const businessName = validateInputField(t('customers.businessName'), 3, 50)
  const industry = yup.string().label(t('customers.industry')).required()
  const industryName = yup.string().label(t('customers.industry')).notRequired()
  const businessDescription = validateInputField(t('customers.businessDescription'), 3, 50)
  const businessRegistrationNumber = validateInputField(t('customers.businessRegistrationNumber'))
  const businessMonthlyVolume = yup.string().label(t('customers.businessMonthlyVolume')).required()
  const businessMonthlyVolumeName = yup
    .string()
    .label(t('customers.businessMonthlyVolume'))
    .notRequired()
  const businessTaxResidency = yup.string().label(t('customers.businessTaxResidency')).required()
  const businessTaxResidencyName = yup
    .string()
    .label(t('customers.businessTaxResidency'))
    .notRequired()

  const firstName = validateInputField(t('customers.representative.firstName'), 3, 30)
  const lastName = validateInputField(t('customers.representative.lastName'), 3, 30)
  const nationality = yup.string().label(t('customers.representative.nationality')).required()
  const nationalityName = yup
    .string()
    .label(t('customers.representative.nationality'))
    .notRequired()
  const dateOfBirth = yup
    .string()
    .trim()
    .label(t('dateOfBirth'))
    .test({
      test(value, ctx) {
        const pattern = /^(\d{4})-(\d{2})-(\d{2})$/

        if (value) {
          if (!pattern.test(value)) {
            return ctx.createError({
              message: t('customers.dateOfBirthInvalidError'),
            })
          }

          const dateFormat = 'YYYY-MM-DD'
          const date = dayjs(value, dateFormat)

          if (!date.isValid()) {
            return ctx.createError({
              message: t('customers.dateOfBirthInvalidError'),
            })
          }

          const maxDate = dayjs(new Date())
          if (date.isAfter(maxDate)) {
            return ctx.createError({
              message: t('dateOfBirthFutureDateError'),
            })
          }
          return true
        } else {
          return ctx.createError({
            message: t('customers.dateOfBirthInvalidError'),
          })
        }
      },
    })
  const addressLine1 = validateInputField(t('addressLine1'), 2, 200).required()
  const addressLine2 = validateInputField(t('addressLine2'), 2, 200).notRequired()
  const countryCode = yup.string().label('country').required()
  const countryName = yup.string().label(t('country')).notRequired()
  const city = validateInputField(t('city'), 2, 200)
  const postCode = validateInputField(t('postCode'), 2, 20)

  const formFields = {
    customerEntityType,
    customerType,
    businessName,
    industry,
    industryName,
    businessDescription,
    businessRegistrationNumber,
    businessMonthlyVolume,
    businessMonthlyVolumeName,
    businessTaxResidency,
    businessTaxResidencyName,
    firstName,
    lastName,
    dateOfBirth,
    nationality,
    nationalityName,
    countryCode,
    countryName,
    addressLine1,
    addressLine2,
    city,
    postCode,
  }

  return yup.object<Partial<Record<keyof AddCustomerPayload, yup.AnySchema>>>(formFields)
}
