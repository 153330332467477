import React, { createContext, useMemo } from 'react'
import { PaymentCaseType } from 'types/payments'

interface PaymentCaseContextShared {
  paymentCaseId: string
  paymentCaseStatus: PaymentCaseType
}
interface PaymentCaseProviderProps extends PaymentCaseContextShared {
  children: React.ReactNode
}

export const PaymentCaseContext = createContext<PaymentCaseContextShared>(null)

export const PaymentCaseProvider = ({
  paymentCaseId,
  paymentCaseStatus,
  children,
}: PaymentCaseProviderProps) => {
  const paymentCaseValue = useMemo(
    () => ({ paymentCaseId, paymentCaseStatus }),
    [paymentCaseId, paymentCaseStatus]
  )

  return (
    <PaymentCaseContext.Provider value={paymentCaseValue}>{children}</PaymentCaseContext.Provider>
  )
}
