import React, { useEffect, useState } from 'react'
import {
  Box,
  Box as Container,
  Box as TextContainer,
} from '@node-space/storybook-components/dist/Box'
import SidePanel, { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import Text from '@node-space/storybook-components/dist/Text'
import { Customer } from 'types/customers'
import { assignedColorArray } from '../constants'
import { AssignedColors, AssignedColorType } from '../types'
import CustomerListItem from './CustomerListItem'
import IconCircle from './IconCircle'
import { SidePanelCustomerBody } from './SidePanelCustomerBody'

interface CustomersListProps {
  customers: Customer[]
}

const CustomersList = ({ customers }: CustomersListProps) => {
  const [activeRowId, setActiveRowId] = useState(null)
  const [assignedColor, setAssignedColor] = useState<AssignedColorType>(AssignedColors.BLUE)

  useEffect(() => {
    if (activeRowId !== null && !!customers?.length) {
      const index = customers?.findIndex(customer => customer?.reference === activeRowId)

      if (index >= 0) {
        setAssignedColor(assignedColorArray[index % assignedColorArray?.length])
      }
    }
  }, [activeRowId, customers])

  const {
    isSidePanelOpen: isCustomerOpen,
    sidePanelData: customerData,
    paging: pagingCustomerList,
    setIsSidePanelOpen: setIsCustomerOpen,
    updateSidePanel: updateCustomer,
  } = useSidePanel(customers)

  return (
    <>
      <Box flex direction="col" border="gray" divideChildren="y">
        {customers?.map((customer, index) => {
          return (
            <Box key={customer?.reference}>
              <CustomerListItem
                index={index}
                key={customer?.reference}
                customerId={customer?.reference}
                name={customer?.name}
                description={customer?.description}
                status={customer?.status}
                setIsCustomerOpen={setIsCustomerOpen}
                updateCustomer={updateCustomer}
                activeRowId={activeRowId}
              />
            </Box>
          )
        })}
        <SidePanel
          isOpen={isCustomerOpen}
          onClose={() => setIsCustomerOpen(false)}
          paging={pagingCustomerList}
          scrollBody={false}
          activeRowId={customerData?.reference}
          onActiveRowChange={id => setActiveRowId(id)}
          sidePanelHeader={{
            customSidePanelHeader: (
              <Container width="full" flex direction="row" paddingY={32} paddingX={20}>
                <IconCircle name={customerData?.name} assignedColor={assignedColor} />
                <TextContainer flexSize="fill" paddingL={16} gapY={4}>
                  <Text size="xl" className="leading-5">
                    {customerData?.name}
                  </Text>
                  <Text size="xs" color="text-500" className="leading-5">
                    {customerData?.description}
                  </Text>
                </TextContainer>
              </Container>
            ),
          }}
        >
          <Container flex direction="col" paddingX={20} paddingY={24} gapY={12}>
            <SidePanelCustomerBody customerId={customerData?.reference} />
          </Container>
        </SidePanel>
      </Box>
    </>
  )
}

export default CustomersList
