import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Box as Form, Box as FormWrapper } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { logError } from '@node-space/utils'
import { useMerchantsContext } from 'hooks/context'
import { useUpdateChannelReference } from 'hooks/mutations/useUpdateChannelReference'
import { useCryptoCurrenciesQuery, useFiatCurrenciesQuery } from 'hooks/queries'
import { useToastContext } from 'hooks/useToastContext'
import { BaseErrorResponse } from 'types/beneficiaries'
import { Channel } from 'types/channels'
import { ChannelCreateRequest } from 'types/payments'
import { EditChannelReq } from 'types/transaction/GetChannelsReq'
import { ErrorAPI } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track, { Amp } from 'utils/tracker'
import DisplayCurrencyField from './DisplayCurrencyField'
import MerchantIdField from './MerchantIdField'
import PayCurrencyField from './PayCurrencyField'
import ReferenceInputField from './ReferenceInputField'

export interface EditChannelForm {
  onSuccess: () => void
  selectedChannelDetails: Channel
}

const EditChannelForm = ({ onSuccess, selectedChannelDetails }: EditChannelForm) => {
  const { t } = useTranslation()
  const { data: fiatCurrencies, isPending: isLoadingFiat } = useFiatCurrenciesQuery()
  const { data: cryptoCurrencies, isPending: isLoadingCrypto } = useCryptoCurrenciesQuery()
  const { mutate: editChannelReference, error, isPending } = useUpdateChannelReference()
  const merchantList = useMerchantsContext()
  const [formError, setFormError] = useState('')
  const { control, register, formState, handleSubmit, setValue, setError, clearErrors } =
    useForm<EditChannelReq>()
  const addToast = useToastContext()

  useEffect(() => {
    Amp.track(AmplitudeEvent.CHANNEL_EDIT_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  const merchantId = useWatch({
    control,
    name: 'merchantId',
    defaultValue: selectedChannelDetails?.merchantId,
  })

  const displayCurrency = useWatch({
    control,
    name: 'displayCurrency',
    defaultValue: selectedChannelDetails?.displayCurrency,
  })

  const payCurrency = useWatch({
    control,
    name: 'payCurrency',
    defaultValue: selectedChannelDetails?.payCurrency,
  })

  const reference = useWatch({
    control,
    name: 'reference',
    defaultValue: selectedChannelDetails?.reference,
  })

  const isBtnDisabled = useMemo(() => {
    return reference === selectedChannelDetails.reference || isLoadingFiat || isLoadingCrypto
  }, [reference, isLoadingCrypto, isLoadingFiat])

  /**
   * Loop over the merchant list and prepare the data
   * for Select options or return an empty array.
   */
  const merchantListOptions = useMemo(() => {
    return merchantList && merchantList.length > 0
      ? merchantList.map(merchant => {
          const code = merchant?.wallet?.currency?.code
          return {
            icon: code?.toLowerCase(),
            label: merchant.displayName,
            value: merchant.merchantId,
            secondLabel: `${code} (ID: ${merchant?.wallet?.id})`,
            secondLabelText: 'Funding Wallet',
          }
        })
      : []
  }, [merchantList])

  const fiatCurrenciesOptions = useMemo(() => {
    return fiatCurrencies && fiatCurrencies.length > 0
      ? fiatCurrencies.map(fiat => {
          return { value: fiat.code, label: fiat.name }
        })
      : []
  }, [fiatCurrencies])

  const cryptoCurrenciesOptions = useMemo(() => {
    return cryptoCurrencies && cryptoCurrencies.length > 0
      ? cryptoCurrencies.map(fiat => {
          return { value: fiat.code, label: fiat.name }
        })
      : []
  }, [cryptoCurrencies])

  useEffect(() => {
    if (error) {
      const err: BaseErrorResponse = error
      const axiosError = err as { data: ErrorAPI }
      if (axiosError && axiosError.data?.errorList && axiosError.data?.errorList.length > 0) {
        axiosError.data.errorList.forEach(err =>
          setError(err.parameter as keyof ChannelCreateRequest, { message: err?.message })
        )
      }
    }
  }, [error])

  const onSubmit: SubmitHandler<EditChannelReq> = value => {
    const { merchantId, uuid } = selectedChannelDetails
    const editChannelPayload = { ...value, merchantId, uuid }
    editChannelReference(editChannelPayload, {
      onSuccess: () => {
        track.Amp.track(AmplitudeEvent.EOF_EDIT_CHANNEL, {
          category: AmplitudeEventCategory.MERCHANT_PORTAL,
          action: AmplitudeEventAction.LOAD,
        })
        addToast({ title: t('payments.channels.updateSuccess'), state: 'success' })
        onSuccess()
      },
      onError: error => {
        logError('Error from EditChannelRequest', error)
        const err: BaseErrorResponse = error
        const apiError = err as { data: ErrorAPI }
        // safeguard - default to display generic error message if BE does not return meaningful error message for channel create endpoint
        if (!apiError?.data?.errorList) {
          setFormError(t('oopsSomethingWentWrongContracted'))
        }
      },
    })
  }

  return (
    <FormWrapper tag="section" testid="channel-edit-form-wrapper">
      <Form tag="form" testid="channel-edit-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalScrollable>
          <Box className="px-6">
            {formError && (
              <Box paddingT={16}>
                <Callout message={formError} showCloseButton={false} state="error" />
              </Box>
            )}
            <MerchantIdField
              merchantId={merchantId}
              merchantListOptions={merchantListOptions}
              errors={formState.errors}
              isLoading={!merchantListOptions.length}
            />
            <DisplayCurrencyField
              currencyList={fiatCurrenciesOptions}
              selectedCurrency={displayCurrency}
              errors={formState?.errors}
              onChangeHandler={setValue}
              isLoading={isLoadingFiat}
            />
            <PayCurrencyField
              currencyList={cryptoCurrenciesOptions}
              selectedCurrency={payCurrency}
              errors={formState?.errors}
              onChangeHandler={setValue}
              isLoading={isLoadingCrypto}
            />
            <Box className="flex flex-wrap -mx-3 mb-6 px-3">
              <ReferenceInputField
                reference={selectedChannelDetails?.reference}
                errors={formState?.errors}
                register={register}
              />
            </Box>
            <Box className="mb-6">
              <Callout
                message={t('payments.channels.infoBannerText')}
                showCloseButton={false}
                state="info"
              />
            </Box>
          </Box>
        </ModalScrollable>
        <ModalActions
          actions={[
            {
              children: t('payments.channels.saveChannelReference'),
              onClick: () => {
                setFormError('')
                clearErrors()
              },
              disabled: isBtnDisabled,
              loading: isPending,
              type: 'submit',
              testid: 'create-channel-btn',
            },
          ]}
        />
      </Form>
    </FormWrapper>
  )
}

export default React.memo(EditChannelForm)
