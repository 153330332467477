import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Accordion, Box, Callout, Loader } from '@node-space/storybook-components'
import { DepositAccountsContextV2Provider } from 'contexts/depositAccountsV2Context'
import { useWalletContext } from 'hooks/context'
import { useDepositAccountsQuery } from 'hooks/queries'
import { useWalletLimits } from 'hooks/queries/useWalletLimits'
import { useIsActiveWallet } from 'hooks/useActiveWallets'
import { usePrepareNewlyCreatedFiatWallet } from 'pages/WalletDetails/hooks/usePrepareNewlyCreatedFiatWallet'
import SingleDepositDetails from 'pages/Wallets/components/Deposit/components/SingleDepositDetails'
import { BankAccountsDetails, DepositBankAccount } from 'types/BankAccountsDetails'
import { BankTransferType } from 'types/beneficiaries'
import { Currencies } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { checkIfVibanIsInactive } from 'utils/utils'
import { getPaymentTitle } from 'utils/wallet'
import { BankAccountDetails } from './BankAccountDetails'
import { BankRadioButtons } from './BankRadioButtons'
import { CopyAllDetailsFiat } from './CopyAllDetailsFiat'
import { WarningMessage } from './WarningMessage'

export const FiatExtendedDetails = () => {
  const wallet = useWalletContext()
  const { enableMultiplePaymentMethods } = useFeatureFlags()
  const {
    isError,
    isPending,
    data: accountDetails,
    error,
  } = useDepositAccountsQuery(wallet?.id?.toString(), true, enableMultiplePaymentMethods)
  const { data: walletLimits } = useWalletLimits(
    wallet?.currency?.code as Currencies,
    wallet?.id,
    false
  )
  const { isDepositError, isDepositLoading } = usePrepareNewlyCreatedFiatWallet()
  const { t } = useTranslation()
  const isSelectedWalletActive = useIsActiveWallet(wallet)

  const [copiedValue, setCopiedValue] = useState('')
  const [copiedTitle, setCopiedTitle] = useState('')
  const [openAccordion, setOpenAccordion] = useState('')

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.WALLET_DETAILS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: wallet?.id,
    })
  }, [])

  const handleSetCopiedTitle = (value: string) => {
    track.Amp.track(AmplitudeEvent.DEPOSIT_COPY, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      copied: value.toLowerCase() || '',
    })
    setCopiedTitle(value)
  }

  const getAccordianItems = (bankAccounts: DepositBankAccount[]) => {
    const localPaymentMethods = []
    const internationalPaymentMethods = []
    bankAccounts?.forEach((account, index) => {
      const accordionItem = {
        name: `${index}-${account?.accountNumber}`,
        title: getPaymentTitle(account, t),
        children: (
          <SingleDepositDetails
            account={account as DepositBankAccount}
            wallet={wallet}
            walletLimits={walletLimits}
            copiedValue={copiedValue}
            setCopiedValue={setCopiedValue}
            handleSetCopiedTitle={handleSetCopiedTitle}
          />
        ),
      }
      account?.isSwift || account?.accountNumberFormat === BankTransferType.SWIFT
        ? internationalPaymentMethods.push(accordionItem)
        : localPaymentMethods.push(accordionItem)
    })
    return [...localPaymentMethods, ...internationalPaymentMethods]
  }

  if (isPending || isDepositLoading) {
    return (
      <div className="flex justify-center">
        <Loader theme="dark" size="large" />
      </div>
    )
  }

  if (checkIfVibanIsInactive(error?.data)) {
    return (
      <Callout
        state="warning"
        message={t('inactiveSegragatedWalletMessage', {
          currencyName: wallet.currency?.code,
        })}
      />
    )
  }
  if (isError || isDepositError) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }

  return (
    isSelectedWalletActive &&
    (enableMultiplePaymentMethods ? (
      !!(accountDetails as DepositBankAccount[])?.length ? (
        <Box padding={24} className="max-w-mediumLarge">
          <Accordion
            isSlim={true}
            items={getAccordianItems(accountDetails as DepositBankAccount[])}
            bodyClassOverrides="pt-4 pr-[15px]"
            borderless={false}
            getOpenName={name => {
              setOpenAccordion(name)
            }}
            defaultOpen={openAccordion}
          />
        </Box>
      ) : (
        <Box padding={24} className="max-w-mediumLarge">
          <SingleDepositDetails
            account={accountDetails?.[0] as DepositBankAccount}
            wallet={wallet}
            walletLimits={walletLimits}
            copiedValue={copiedValue}
            setCopiedValue={setCopiedValue}
            handleSetCopiedTitle={handleSetCopiedTitle}
          />
        </Box>
      )
    ) : (
      <DepositAccountsContextV2Provider depositAccounts={accountDetails as BankAccountsDetails}>
        <Box
          data-testid="fiat-extended-details"
          className="bg-gray-01 p-11 border border-gray-03 max-w-mediumLarge rounded"
        >
          {!!(accountDetails as BankAccountsDetails)?.aggregatedPaymentMethods?.length && (
            <BankRadioButtons />
          )}
          <BankAccountDetails />
          <CopyAllDetailsFiat />
          <WarningMessage />
        </Box>
      </DepositAccountsContextV2Provider>
    ))
  )
}
