import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box as BannersContainer, Box } from '@node-space/storybook-components/dist/Box'
import { OfflineBanner } from 'components/banners/OfflineBanner'
import { useOfflineContext } from 'hooks/context/useOfflineContext'

export const appBannersId = 'appBanners'

export type AppBannerType = React.ReactNode

export interface AppBannersProps {
  banners: AppBannerType[]
}

export const AppBanners = ({ banners }: AppBannersProps) => {
  const { isOffline } = useOfflineContext()

  return (
    <BannersContainer className="sticky top-0 left-0 w-full z-40" id={appBannersId}>
      {isOffline && <OfflineBanner />}
      {!!banners?.length && banners?.map(banner => <Box key={uuidv4()}>{banner}</Box>)}
    </BannersContainer>
  )
}
