import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Skeleton } from '@node-space/storybook-components/dist/SkeletonLoader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { useWalletControllerContext } from 'hooks/useWalletControllerContext'
import { numberWithCommas } from 'utils/utils'

interface WalletTotalBalanceProps {
  isFetchingTotalBalance?: boolean
}

const WalletTotalBalance = ({ isFetchingTotalBalance }: WalletTotalBalanceProps) => {
  const { t } = useTranslation()

  const { totalBalance, baseCurrency, hasErrors } = useWalletControllerContext()
  const isLoadingTotalBalance = isFetchingTotalBalance

  return (
    <Box data-testid="wallets-total-balance">
      {!isLoadingTotalBalance ? (
        <>
          {!!totalBalance && !!baseCurrency && (
            <Box className="mb-2">
              <Text size="sm" color="grey">
                {t('totalBalance')}
              </Text>

              <Text tag="span" weight="semibold" size="3xl" color="dark-grey">
                {`${numberWithCommas(Number(totalBalance))} ${baseCurrency}`}
              </Text>
            </Box>
          )}
        </>
      ) : (
        <>
          {!hasErrors && (
            <Box flex direction="col" gapY={8} paddingT={12} paddingB={20}>
              <Skeleton className="w-1/5" variant="text-xs" />
              <Skeleton className="w-2/5" variant="title" />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default WalletTotalBalance
