import { atom } from 'jotai'
import { BeneficiaryDetails } from 'types/beneficiaries'
import { BvnkNetworkConnection } from 'types/bvnkNetwork'
import { WalletType } from 'types/types'

export const selectedFiatBeneficiaryAtom = atom<BeneficiaryDetails | null>(
  null as BeneficiaryDetails
)
export const selectedBvnkNetworkBeneficiaryAtom = atom<BvnkNetworkConnection | null>(
  null as BvnkNetworkConnection
)
export const selectedWalletAtom = atom<WalletType | null>(null as WalletType)
