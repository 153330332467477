import React, { createContext } from 'react'

export const WalletControllerContext = createContext<WalletControllerProviderProps>(null)

interface WalletControllerProviderProps {
  children?: React.ReactNode
  isLoading: boolean
  isFetching: boolean
  isSuccess?: boolean
  hasErrors: boolean
  baseCurrency: string
  totalBalance: number
  totalFiat?: number
  totalCrypto?: number
}

export const WalletControllerProvider = ({
  children,
  isLoading,
  isFetching,
  isSuccess,
  hasErrors,
  baseCurrency,
  totalBalance,
  totalFiat,
  totalCrypto,
}: WalletControllerProviderProps) => {
  return (
    <WalletControllerContext.Provider
      value={{
        isLoading,
        isFetching,
        isSuccess,
        hasErrors,
        totalBalance,
        baseCurrency,
        totalFiat,
        totalCrypto,
      }}
    >
      {children}
    </WalletControllerContext.Provider>
  )
}
