import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { AddIntermediaryBankDetailsPayload } from 'types/beneficiaries'
import { createNameSchema } from 'utils/yupSchemas/createNameSchema'
import { createAddressSchema } from 'utils/yupSchemas/addressSchemas'

export const addIntermediaryBankDetailsSchema = (t: TFunction<'translation', undefined>) => {
  const bicMinLength = 8
  const bicMaxLength = 11
  const intermediaryBankName = createNameSchema(t('lastName'), t)
  const intermediaryBicLabel = t('bic')
  const intermediaryBic = yup
    .string()
    .label(intermediaryBicLabel)
    .min(
      bicMinLength,
      t('fieldMinLength', {
        field: intermediaryBicLabel,
        min: bicMinLength,
      })
    )
    .matches(
      /^[0-9a-zA-Z]+$/,
      t('fieldAlphanumeric', {
        field: intermediaryBicLabel,
      })
    )
    .max(
      bicMaxLength,
      t('fieldMaxLength', {
        field: intermediaryBicLabel,
        max: bicMaxLength,
      })
    )
  const intermediaryBankAddressLine1 = createAddressSchema(t('bankAddressLine1'), t)
  const intermediaryBankAddressLine2 = createAddressSchema(t('bankAddressLine2'), t)
  const intermediaryBankCity = createAddressSchema(t('city'), t)
  const intermediaryBankPostCode = createAddressSchema(t('postCode'), t)
  const intermediaryBankRegion = createAddressSchema(t('region'), t)
  const intermediaryBankCountry = yup.string().trim().label(t('country')).required()

  const formFields = {
    intermediaryBankName,
    intermediaryBic,
    intermediaryBankAddressLine1,
    intermediaryBankAddressLine2,
    intermediaryBankCity,
    intermediaryBankPostCode,
    intermediaryBankRegion,
    intermediaryBankCountry,
  }

  return yup.object<Partial<Record<keyof AddIntermediaryBankDetailsPayload, yup.AnySchema>>>(
    formFields
  )
}
