import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Wrapper, Button, Text } from '@node-space/storybook-components'

const Description = () => {
  const { t } = useTranslation()

  return (
    <Wrapper paddingB={16}>
      <Text size="sm" color="grey" className="mb-5" align="center">
        {t('twoFactorVerification.descriptionPartOne')}
        <a
          href={process.env.AUTHY_DOWNLOAD_LINK}
          className="ml-1 underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          {t('twoFactorVerification.authy')}
        </a>
        ,{' '}
        <a
          href={process.env.GOOGLE_AUTH_DOWNLOAD_LINK}
          className="ml-1 underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          {t('twoFactorVerification.googleAuthenticator')}
        </a>
        ,{' '}
        <a
          href={process.env.MICROSFT_AUTH_DOWNLOAD_LINK}
          className="ml-1 underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          {t('twoFactorVerification.microsoftAuthenticator')}
        </a>
        , {t('twoFactorVerification.etc')}.
      </Text>
      <Text size="sm" color="grey" align="center">
        {t('twoFactorVerification.descriptionPartTwo')}{' '}
        <Button noStyling onClick={() => window.open(process.env.TWO_FACTOR_LEARN_MORE_LINK)}>
          {t('learnMore')}
        </Button>
      </Text>
    </Wrapper>
  )
}

export default React.memo(Description)
