import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@node-space/storybook-components/dist/Icon'
import { AlignActions, ModalActions } from '@node-space/storybook-components/dist/Modal'
import ResponseContainer from '@node-space/storybook-components/dist/ModalResponseContainer'

interface OnceOffExportCompleteType {
  handleClose: () => void
  exportFormat: string
}

export const OnceOffExportComplete = ({ handleClose, exportFormat }: OnceOffExportCompleteType) => {
  const { t } = useTranslation()

  return (
    <>
      <ResponseContainer
        headline={t('walletsPage.checkYourMail')}
        responseIcon={<Icon name="ClapIcon" color="status-success-500" size="lg" />}
        description={t('walletsPage.sendTransactionToEmailDescription', {
          exportFormat: exportFormat.toUpperCase(),
        })}
      />
      <ModalActions
        actions={[
          {
            children: t('done'),
            onClick: handleClose,
            type: 'button',
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </>
  )
}
