import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import { deletePreprocessedRecords } from 'services/MasspayoutService'

export const useDeletePreProcessedRecordMutation = () => {
  return useMutation({
    mutationFn: deletePreprocessedRecords,
    onError(error: { status: number; message?: string; errorList: unknown }, data) {
      logSentryError('Error from useDeletePreProcessedRecordMutation', error, data)
    },
  })
}
