import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box as Column,
  Box as FilterDownload,
  Box as OuterWrapper,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { secondaryButtonIconColour } from '../../styles/theme/colours'
import FilterResetList from './components/Filters/FilterResetList'
import FilterSearch from './components/Filters/FilterSearch'
import FilterModal from './components/Modal/FilterModal'
import type { FilterBarType } from './types'

const FilterBar = ({
  eventHandlers,
  isChannel = false,
  isChannelManagement = false,
  conditions: { isExportingCSV = false },
  filterDateRange,
  preLoadedSearchTerm,
  searchInputPlaceholder,
  children,
}: FilterBarType) => {
  const { t } = useTranslation()
  const { enablePaymentsExport, enablePaymentsCsvExport } = useFeatureFlags()

  return (
    <>
      <OuterWrapper
        testid="filter-bar-component"
        flex
        justifyContent="between"
        className={'flex-col lg:flex-row '}
      >
        <Column className="block w-full mr-3">
          {children || (
            <FilterSearch
              preLoadedSearchTerm={preLoadedSearchTerm}
              placeholder={searchInputPlaceholder}
            />
          )}
        </Column>
        <Column className="item flex mt-4 lg:mt-0">
          <FilterModal
            eventHandlers={eventHandlers}
            isChannel={isChannel}
            filterDateRange={filterDateRange}
            isChannelManagement={isChannelManagement}
          />
          {(enablePaymentsExport || enablePaymentsCsvExport) && !isChannelManagement && (
            <FilterDownload>
              <Button
                secondary
                iconElement={
                  !isExportingCSV ? (
                    <Icon name="DownloadIcon" color={secondaryButtonIconColour} />
                  ) : (
                    <></>
                  )
                }
                onClick={eventHandlers?.handleExportClicked}
                disabled={isExportingCSV}
                testid="filter-bar-download-csv-btn"
              >
                {!isExportingCSV ? t('export') : `${t('loading')}...`}
              </Button>
            </FilterDownload>
          )}
        </Column>
      </OuterWrapper>
      <FilterResetList executor={eventHandlers?.executor} />
    </>
  )
}

export default FilterBar
