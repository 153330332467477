import React, { ChangeEvent, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Input from '@node-space/storybook-components/dist/Input'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { ApprovalRulesData } from 'types/approvals'
import { stringToDecimal } from 'utils/utils'
import { useApprovalRangeConfig } from './hooks/useApprovalRangeSettings'

interface ApprovalFromToRangeProps {
  rangeValues: { minAmount: number; maxAmount: string | number }
  rowIndex: number
  handleInputChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void
  approvalRulesData: ApprovalRulesData[]
  postLabel: string
  isApprovalRuleInvalid: boolean
  setIsApprovalRuleInvalid?: (value: SetStateAction<boolean>) => void
}

const ApprovalFromToRange = ({
  rangeValues,
  rowIndex,
  handleInputChange,
  approvalRulesData,
  postLabel,
  isApprovalRuleInvalid,
  setIsApprovalRuleInvalid,
}: ApprovalFromToRangeProps) => {
  const { t } = useTranslation()

  const { prefillFromValue, toInputIsDisabled, currentRowToValue, isCurrentRowRangeInvalid } =
    useApprovalRangeConfig(approvalRulesData, rowIndex)

  const currentToValueHasError = !!currentRowToValue && isCurrentRowRangeInvalid

  const onChangeToField = (rowIndex: number, e: ChangeEvent<HTMLInputElement>) => {
    approvalRulesData[rowIndex].maxAmount = stringToDecimal(e?.target?.value)
  }

  useEffect(() => {
    if (currentToValueHasError) {
      setIsApprovalRuleInvalid(currentToValueHasError)
    }
  }, [rowIndex, isApprovalRuleInvalid, currentToValueHasError])

  return (
    <Box flex gapX={12} width="full">
      <Input
        type="text"
        inputMode="decimal"
        label={t('from')}
        disabled
        postLabel={postLabel}
        value={prefillFromValue}
      />
      <Input
        type="text"
        inputMode="decimal"
        label={t('to')}
        value={rangeValues?.maxAmount?.toString() || ''}
        disabled={toInputIsDisabled}
        onChange={e => {
          onChangeToField(rowIndex, e)
          handleInputChange(rowIndex, e)
        }}
        postLabel={postLabel}
        {...(currentToValueHasError && {
          error: true,
          rightLabel: (
            <Tooltip
              clickable
              hasMaxWidth
              bodyContent={t('approvals.addNewRowIsDisabledError')}
              position="top"
            >
              <Button
                noStyling
                disabled
                iconElement={<Icon size="sm" name="InfoCircleIcon" color="validation-error" />}
              />
            </Tooltip>
          ),
        })}
      />
    </Box>
  )
}

export default ApprovalFromToRange
