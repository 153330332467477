import React, { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { Select } from '@node-space/storybook-components/dist/Select'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useAddBeneficiaryContext } from 'hooks/useAddBeneficiaryContext'
import {
  AddIntermediaryBankDetailsPayload,
  AddIntermediaryBankDetailsPayloadKeys,
} from 'types/beneficiaries'
import { ModalStep } from '../../AddBeneficiary'
import { addIntermediaryBankDetailsSchema } from '../schemas/addIntermediaryBankDetailsSchema'

interface BeneficiaryBankDetailsProps {
  onBack: () => void
  onContinue: (bankDetails: AddIntermediaryBankDetailsPayload, skipDetails: boolean) => void
  isSubmitting: boolean
  skipStep: (nextStep: ModalStep) => void
}

export const IntermediaryBankDetails = ({
  onContinue,
  isSubmitting,
  skipStep,
}: BeneficiaryBankDetailsProps) => {
  const { t } = useTranslation()
  const { data: countries, isPending: countriesLoading } = useCountriesQuery(true)
  const { beneficiaryFormState, beneficiaryFormErrors } = useAddBeneficiaryContext()
  const formSchema = addIntermediaryBankDetailsSchema(t)
  const { register, formState, setValue, control, trigger, clearErrors, watch, setError } =
    useForm<AddIntermediaryBankDetailsPayload>({
      resolver: yupResolver(formSchema),
    })

  const [
    intermediaryBankName,
    intermediaryBic,
    intermediaryBankAddressLine1,
    intermediaryBankAddressLine2,
    intermediaryBankCity,
    intermediaryBankPostCode,
    intermediaryBankRegion,
    intermediaryBankCountry,
  ] = useWatch({
    control,
    name: [
      'intermediaryBankName',
      'intermediaryBic',
      'intermediaryBankAddressLine1',
      'intermediaryBankAddressLine2',
      'intermediaryBankCity',
      'intermediaryBankPostCode',
      'intermediaryBankRegion',
      'intermediaryBankCountry',
    ],
    defaultValue: {
      intermediaryBankName: '',
      intermediaryBic: '',
      intermediaryBankAddressLine1: '',
      intermediaryBankAddressLine2: '',
      intermediaryBankCity: '',
      intermediaryBankPostCode: '',
      intermediaryBankRegion: '',
      intermediaryBankCountry: '',
    },
  })

  const formValues = watch()

  useEffect(() => {
    setCachedValues()
  }, [])

  useEffect(() => {
    if (beneficiaryFormErrors) {
      Object.keys(beneficiaryFormErrors).forEach((field: AddIntermediaryBankDetailsPayloadKeys) => {
        setError(field, beneficiaryFormErrors[field])
      })
    } else {
      clearErrors()
    }
  }, [setError, clearErrors, beneficiaryFormErrors])

  const setCachedValues = () => {
    Object.keys(beneficiaryFormState).forEach((field: keyof AddIntermediaryBankDetailsPayload) => {
      if (beneficiaryFormState[field]) {
        setValue(field, beneficiaryFormState[field])
      }
    })
  }

  const countryOptions = useMemo(() => {
    if (countries?.length) {
      return countries?.map(country => ({
        label: country?.name,
        value: country?.code,
      }))
    }

    return []
  }, [countries])

  const handleIntermediaryCountrySelect = (countryCode: string) => {
    const countryName = countryOptions.find(country => country?.value === countryCode)?.label
    setValue('intermediaryBankCountryName', countryName)
    setValue('intermediaryBankCountry', countryCode)
  }

  const handleChange = (field: keyof AddIntermediaryBankDetailsPayload, value: string) => {
    setValue(field, value)
  }

  const submitBankAddressAccountDetails = async () => {
    const validateFields: AddIntermediaryBankDetailsPayloadKeys[] = [
      'intermediaryBankName',
      'intermediaryBic',
      'intermediaryBankAddressLine1',
      'intermediaryBankCity',
      'intermediaryBankPostCode',
      'intermediaryBankRegion',
      'intermediaryBankCountry',
    ]
    clearErrors()

    const verifyFields = await trigger(validateFields)

    if (verifyFields) {
      return onContinue(formValues, false)
    }
  }

  const actions: ButtonProps[] = [
    {
      children: t('skipThisStep'),
      testid: 'skip',
      secondary: true,
      onClick: () => {
        clearErrors()
        skipStep(ModalStep.CONFIRMATION)
      },
    },
    {
      children: t('manageBeneficiaries.addBeneficiaryCta'),
      testid: 'add-benefiary',
      onClick: () => {
        submitBankAddressAccountDetails()
      },
      disabled: isSubmitting,
      loading: isSubmitting,
    },
  ]

  return (
    <ModalScrollable>
      <Box padding={24}>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('intermediaryBankName')}
            label={t('manageBeneficiaries.intermediarysBankName')}
            inputMode="text"
            name="intermediaryBankName"
            testid="input-intermediary-bank-name"
            errorText={formState?.errors?.intermediaryBankName?.message}
            error={!!formState?.errors?.intermediaryBankName}
            value={intermediaryBankName}
            onChange={event => handleChange('intermediaryBankName', event?.target?.value)}
            placeholder={t('manageBeneficiaries.enterIntermediaryBankName')}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('intermediaryBic')}
            label={t('bic')}
            inputMode="text"
            name="intermediaryBic"
            testid="input-intermediary-bic"
            errorText={formState?.errors?.intermediaryBic?.message}
            error={!!formState?.errors?.intermediaryBic}
            value={intermediaryBic}
            onChange={event => handleChange('intermediaryBic', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterBicPlaceholder')}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('intermediaryBankAddressLine1')}
            label={t('bankAddressLine1')}
            inputMode="text"
            name="intermediaryBankAddressLine1"
            testid="input-intermediary-bank-address-line-1"
            errorText={formState?.errors?.intermediaryBankAddressLine1?.message}
            error={!!formState?.errors?.intermediaryBankAddressLine1}
            value={intermediaryBankAddressLine1}
            onChange={event => handleChange('intermediaryBankAddressLine1', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterAddressLine1Placeholder')}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('intermediaryBankAddressLine2')}
            label={t('bankAddressLine2')}
            inputMode="text"
            name="intermediaryBankAddressLine2"
            testid="input-intermediary-bank-address-line-2"
            errorText={formState?.errors?.intermediaryBankAddressLine2?.message}
            error={!!formState?.errors?.intermediaryBankAddressLine2}
            value={intermediaryBankAddressLine2}
            onChange={event => handleChange('intermediaryBankAddressLine2', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterAddressLine2Placeholder')}
          />
        </Box>
        <Box flex flexRow gapX={12} paddingB={16}>
          <Input
            {...register('intermediaryBankCity')}
            label={t('city')}
            inputMode="text"
            name="city"
            testid="input-bank-city"
            errorText={formState?.errors?.intermediaryBankCity?.message}
            error={!!formState?.errors?.intermediaryBankCity}
            value={intermediaryBankCity}
            onChange={event => handleChange('intermediaryBankCity', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterCityPlaceholder')}
          />
          <Input
            {...register('intermediaryBankPostCode')}
            label={t('postCode')}
            inputMode="text"
            name="bic"
            testid="input-bank-post-code"
            errorText={formState?.errors?.intermediaryBankPostCode?.message}
            error={!!formState?.errors?.intermediaryBankPostCode}
            value={intermediaryBankPostCode}
            onChange={event => handleChange('intermediaryBankPostCode', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterPostalCodePlaceholder')}
          />
        </Box>
        <Box flex flexRow gapX={12} paddingB={16}>
          <Input
            {...register('intermediaryBankRegion')}
            label={t('region')}
            inputMode="text"
            name="intermediaryBankRegion"
            testid="input-bank-region"
            errorText={formState?.errors?.intermediaryBankRegion?.message}
            error={!!formState?.errors?.intermediaryBankRegion}
            value={intermediaryBankRegion}
            onChange={event => handleChange('intermediaryBankRegion', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterRegionPlaceholder')}
          />
        </Box>
        <Box flexSize="fill">
          <Select
            {...register('intermediaryBankCountry')}
            label={t('country')}
            value={intermediaryBankCountry}
            isSearchable
            options={countryOptions}
            error={!!formState?.errors?.intermediaryBankCountry}
            errorText={formState?.errors?.intermediaryBankCountry?.message}
            placeholder={t('searchOrSelectACountry')}
            loading={countriesLoading}
            onChange={handleIntermediaryCountrySelect}
          />
        </Box>
      </Box>
      <ModalActions actions={actions} />
    </ModalScrollable>
  )
}
