import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { ReceiveForm, ReceiveSteps } from '../components/Receive/Receive'

const receiveSchema = ({
  t,
  step,
}: {
  t: TFunction<'translation', undefined>
  step: ReceiveSteps
}) => {
  return yup.object<Partial<Record<keyof ReceiveForm, yup.AnySchema>>>({})
}

export default receiveSchema
