import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  Box,
  Box as AgreementTimeline,
  Box as SignedAgreement,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Timeline, TimelineColor } from '@node-space/storybook-components/dist/Timeline'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import { SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import SidePanel from '@node-space/storybook-components/dist/SidePanel'
import { DateFormat } from 'constants/General'
import { usePendingAgreementsContext } from 'hooks/context/usePendingAgreementsContext'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { appBannersId } from 'components/app/AppBanners'
import { highlightRowBasedOnId } from 'utils/table/table'
import { LegalAgreementStatus, LegalAgreementTableType } from './types'

export type LegalAgreementDetailsProps = {
  isOpen: boolean
  agreement: LegalAgreementTableType
  pageAgreements: PagingSidePanelType
  onClose: () => void
}

export const LegalAgreementDetails = ({
  isOpen,
  agreement,
  pageAgreements,
  onClose,
}: LegalAgreementDetailsProps) => {
  const { t } = useTranslation()
  const { setShouldRefetch, setShowSingleAgreementId } = usePendingAgreementsContext()
  const bannerSpacing = useComponentHeight(appBannersId)

  const agreementDetails = agreement?.agreement
  const appendixes = agreement?.appendixes

  const isSigned = agreement?.status === LegalAgreementStatus.SIGNED

  const displayDate = isSigned ? agreement?.lastUpdated : agreement?.dateCreated
  const formattedDate = !!displayDate
    ? dayjs(displayDate).format(DateFormat.DD_MMM_YYYY_HH_MM_A)
    : ''

  return (
    <>
      <SidePanel
        isOpen={isOpen}
        onClose={onClose}
        paging={pageAgreements}
        activeRowId={agreementDetails?.reference}
        onActiveRowChange={highlightRowBasedOnId}
        externalStyle={{ top: `${bannerSpacing}px` }}
        sidePanelHeader={{
          sidePanelHeaderTemplate: (
            <SidePanelHeader
              heading={agreementDetails?.name}
              description={agreementDetails?.description}
              bottomContent={{
                leftLabel: (
                  <StatusPill
                    state={agreement?.statusPill?.state}
                    label={agreement?.statusPill?.label}
                    displayAsDot
                    fitContent
                  />
                ),
              }}
              template={3}
            />
          ),
        }}
      >
        <Box flex direction="col" padding={20} gap={24} className="lg:p-6">
          <Timeline
            rows={[
              {
                color: isSigned ? TimelineColor.BLUE : TimelineColor.ORANGE,
                component: (
                  <AgreementTimeline>
                    <Text tag="h4" size="sm">
                      <span>{t('legalAgreements.received')}</span>
                      {isSigned && (
                        <Text tag="span" size="sm" weight="medium">
                          {` ${t('legalAgreements.accepted')}`}
                        </Text>
                      )}
                    </Text>
                    <Text size="xs" color="text-500">
                      {formattedDate}
                    </Text>
                  </AgreementTimeline>
                ),
              },
            ]}
            showDateCol={false}
          />

          {isSigned ? (
            <>
              <SignedAgreement>
                <Text tag="h4" size="sm">
                  {agreementDetails?.name}
                </Text>
                <Quicklink
                  to={agreementDetails?.url}
                  text={agreementDetails?.fileName}
                  iconElement={<Icon name="DocumentDownloadIcon" color="inherit" />}
                  className="mt-2"
                />
              </SignedAgreement>

              {!!agreement?.appendixes?.length && (
                <SignedAgreement>
                  <Box flex gap={4}>
                    <Text tag="h4" size="sm">
                      {t('legalAgreements.appendixLinkTitle')}
                    </Text>
                    <Tooltip
                      bodyContent={t('legalAgreements.appendixLinkTooltip')}
                      className="whitespace-normal"
                    >
                      <Icon name="InfoCircleIcon" />
                    </Tooltip>
                  </Box>
                  <Quicklink
                    key={appendixes?.[0]?.reference}
                    to={appendixes?.[0]?.url}
                    text={appendixes?.[0]?.name}
                    iconElement={<Icon name="DocumentDownloadIcon" color="inherit" />}
                    className="mt-2"
                  />
                </SignedAgreement>
              )}
            </>
          ) : (
            <Button
              iconElement={<Icon name="DocumentsIcon" color="inherit" />}
              onClick={() => {
                setShowSingleAgreementId(agreement?.id)
                setShouldRefetch(true)
                onClose()
              }}
            >
              {t('reviewAndAccept')}
            </Button>
          )}
        </Box>
      </SidePanel>
    </>
  )
}
