import React, { Dispatch, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@node-space/storybook-components/dist/Box'
import SmallTabs from '@node-space/storybook-components/dist/SmallTabs'
import { Text } from '@node-space/storybook-components/dist/Text'
import { PathNames } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useApprovalsContext } from 'hooks/context/useApprovalsContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { ApprovalStatuses } from 'types/approvals'
import { ApprovedTransactions } from './Tables/ApprovedTransactions'
import { ExpiredTransactions } from './Tables/ExpiredTransactions'
import { PendingTransactions } from './Tables/PendingTransactions'
import { RejectedTransactions } from './Tables/RejectedTransactions'

interface ApprovalTabsProps {
  queryTab: keyof typeof ApprovalStatuses
  activeTab: string
  setActiveTab: Dispatch<React.SetStateAction<string>>
}

const ApprovalTabs = ({ queryTab, activeTab, setActiveTab }: ApprovalTabsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { currentAccount } = useAccountsContext()
  const currentAccountReference = currentAccount?.reference || ''
  const {
    pendingApprovalDetails: { hasPendingApprovals, numberOfPendingApprovals },
  } = useApprovalsContext()

  const tabs = [
    {
      id: ApprovalStatuses.PENDING,
      tabHeader: (
        <Box flex>
          {t('approvals.pending')}
          {hasPendingApprovals && (
            <Text
              size="xs"
              color="grey-01"
              className="ml-2 bg-purple-500 font-medium px-2 py-0.5 rounded-full"
            >
              {numberOfPendingApprovals}
            </Text>
          )}
        </Box>
      ),
      tabContent: <PendingTransactions />,
      onClick: () => {
        navigate(`${PathNames.APPROVALS}`, { replace: true })
        setActiveTab(ApprovalStatuses.PENDING)
        queryClient.invalidateQueries({
          queryKey: reactQueryKeys.approvalsTransactions(
            currentAccountReference,
            ApprovalStatuses.PENDING
          ),
        })
      },
    },
    {
      id: ApprovalStatuses.APPROVED,
      tabHeader: t('approvals.approved'),
      tabContent: <ApprovedTransactions />,
      onClick: () => {
        navigate(`${PathNames.APPROVALS}`, { replace: true })
        setActiveTab(ApprovalStatuses.APPROVED)
      },
    },
    {
      id: ApprovalStatuses.REJECTED,
      tabHeader: t('approvals.rejected'),
      tabContent: <RejectedTransactions />,
      onClick: () => {
        navigate(`${PathNames.APPROVALS}`, { replace: true })
        setActiveTab(ApprovalStatuses.REJECTED)
      },
    },
    {
      id: ApprovalStatuses.EXPIRED,
      tabHeader: t('approvals.expired'),
      tabContent: <ExpiredTransactions />,
      onClick: () => {
        navigate(`${PathNames.APPROVALS}`, { replace: true })
        setActiveTab(ApprovalStatuses.EXPIRED)
      },
    },
  ]

  useEffect(() => {
    if (queryTab) {
      setActiveTab(queryTab)
    }
  }, [queryTab])

  return <SmallTabs tabs={tabs} currentValueId={activeTab} />
}

export default ApprovalTabs
