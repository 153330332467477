import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RestApi } from '@node-space/rest-api'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { PathNames } from 'constants/General'
import { SendActionProps } from './Send'

const Send2fa = ({ setStep }: SendActionProps) => {
  const { t } = useTranslation()
  const [ignore2fa, setIgnore2fa] = useState<boolean>(false)
  const navigate = useNavigate()

  const setIgnore2faWarning = () => {
    // 86400 minutes = 60 days
    RestApi.setCookie('ignoreSend2faWarning', '1', 86400)
  }

  const actions: ButtonProps[] = [
    {
      secondary: true,
      children: t('2FAEnable'),
      onClick: () => {
        ignore2fa && setIgnore2faWarning()
        navigate(`${PathNames.SETTINGS}${PathNames.MANAGE_USER_PROFILE}`)
      },
    },
    {
      children: t('2FAContinue'),
      testid: t('2FAContinue'),
      onClick: () => {
        ignore2fa && setIgnore2faWarning()
        setStep('form')
      },
    },
  ]
  return (
    <div data-testid="send2fa">
      <div className="flex flex-col p-6 space-y-4">
        <span className="text-gray-120">{t('2FASendingNotice')}</span>
        <span className="text-gray-100 text-sm">{t('2FAMessage')}</span>
        <span className="text-gray-100 text-sm">{t('2FAMessageSettings')}</span>
        <CheckBox
          label={t('2FAAskAgain')}
          value={ignore2fa}
          onChange={value => setIgnore2fa(value)}
        />
      </div>

      {/* actions */}
      <ModalActions actions={actions} />
    </div>
  )
}

export default Send2fa
