import React from 'react'
import { Banner, BannerState } from '@node-space/storybook-components/dist/Banner'

export interface FlagEnabledBannerProps {
  state: BannerState
  message: string
  buttonText: string
  buttonLink: string
}

export const FlagEnabledBanner = ({
  state,
  message,
  buttonText,
  buttonLink,
}: FlagEnabledBannerProps) => {
  return <Banner state={state} message={message} linkLabel={buttonText} link={buttonLink} />
}
