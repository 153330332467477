import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getReportSchedules } from 'services/TransactionExportService'

export const useReportScheduleQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: reactQueryKeys.reportSchedules(),
    queryFn: getReportSchedules,
    enabled,
    select: response => {
      return response?.content
    },
  })
}
