import { TFunction } from 'react-i18next'
import * as yup from 'yup'

export const NAME_VALIDATION_PATTERN = /^[ a-zA-Z0-9.,\/&-]*$/
export const BENEFICIARY_NAME_VALIDATION_PATTERN = /^[ a-zA-Z0-9.,\/&()-]*$/
export const ALPHANUMERIC_VALIDATION = /^[0-9a-zA-Z]+$/
export const NUMERIC_VALIDATION_PATTERN = new RegExp(/^[0-9]*$/)

//TODO: Replace params with a params object
export const createNameSchema = (
  label: string,
  t: TFunction<'translation', undefined>,
  minLength?: number,
  maxLength?: number,
  regexPattern?: RegExp,
  regexTranslationKey?: string
) => {
  return yup
    .string()
    .trim()
    .label(label)
    .min(
      minLength || 3,
      t('fieldMinLength', {
        field: label,
        min: minLength || 3,
        interpolation: { escapeValue: false },
      })
    )
    .max(
      maxLength || 50,
      t('fieldMaxLength', {
        field: label,
        max: maxLength || 50,
      }).trim()
    )
    .matches(
      regexPattern || NAME_VALIDATION_PATTERN,
      t(regexTranslationKey ?? 'fieldAlphanumericInvalidCharacters', {
        field: label,
      })
    )
}
