export const Capabilities = {
  FIAT_PAYOUTS_B2C: { group: 'FIAT', name: 'PAYOUTS_B2C' },
} as const

export const CapabilitiesScope = {
  GLOBAL: 'GLOBAL',
  ACCOUNT: 'ACCOUNT',
  MERCHANT: 'MERCHANT',
  PARTNER_ACCOUNT: 'PARTNER_ACCOUNT',
  PROCESSING_PROFILE: 'PROCESSING_PROFILE',
  WALLET: 'WALLET',
} as const

export type CapabilitiesScope = (typeof CapabilitiesScope)[keyof typeof CapabilitiesScope]

export const CapabilitiesFormat = {
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  STRING: 'STRING',
} as const

export type CapabilitiesFormat = (typeof CapabilitiesFormat)[keyof typeof CapabilitiesFormat]

export interface CapabilitiesRequest {
  group?: string
  name?: string
  scope?: CapabilitiesScope
  subjectId?: string
}

export interface CapabilitiesResponse {
  group: string
  name: string
  scope: CapabilitiesScope
  format: CapabilitiesFormat
  subjectId: string
  value: boolean | number | string
}
