import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { isNumber, isString, snakeCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import {
  Button,
  Box as Code,
  Box as Column,
  CopyButton,
  Box as Heading,
  Box as Key,
  Box as KeyValueRow,
  Box as MainArea,
  Box as Section,
  Box as ShowHide,
  Text,
  Box as Value,
} from '@node-space/storybook-components'
import { ellipseMiddleText } from '@node-space/utils'
import { ChannelPayment } from 'types/channels'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { sentenceCase, shortenString, sortObjectPropertiesByName } from 'utils/utils'

interface ChannelDetailsProps {
  channelPayment: ChannelPayment
}

const ChannelDetails = ({ channelPayment }: ChannelDetailsProps) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.CHANNEL_VIEW_DETAILS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.LOAD,
    })
  }, [])

  const dataColumns = [
    {
      heading: `${t('general')}:`,
      items: [
        {
          label: `${t('createdDate')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-date-created`} flex>
              {dayjs(channelPayment?.dateCreated).format('YYYY-MM-DD HH:mm:ss')}
            </Value>
          ),
        },
        {
          label: `${t('reference')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-reference`} flex>
              <Text size="sm" color="black">
                {channelPayment?.reference}
              </Text>
              <CopyButton
                copyText={String(channelPayment?.reference)}
                iconColour={copyButtonIconColour}
              />
            </Value>
          ),
        },
        {
          label: `${t('Uuid')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-uuid`} flex>
              <Text size="sm" color="black">
                {shortenString(channelPayment?.uuid, 20)}
              </Text>
              <CopyButton copyText={channelPayment?.uuid} iconColour={copyButtonIconColour} />
            </Value>
          ),
        },
        {
          label: `${t('status')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-status`}>
              {channelPayment?.status}
            </Value>
          ),
        },
        {
          label: `${t('hash')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-hash`} flex>
              <Text size="sm" color="black">
                {ellipseMiddleText(channelPayment?.hash, 6, 6) || '-'}
              </Text>
              {channelPayment?.hash && (
                <CopyButton copyText={channelPayment?.hash} iconColour={copyButtonIconColour} />
              )}
            </Value>
          ),
        },
      ],
    },
    {
      heading: `${t('payment')}:`,
      items: [
        {
          label: `${t('payments.channels.amountIn')} `,
          value: (
            <Value
              testid={`details-${snakeCase(channelPayment?.reference)}-amount`}
              flex
            >{`${channelPayment?.paidAmount} ${channelPayment?.paidCurrency}`}</Value>
          ),
        },
        {
          label: `${t('payments.channels.feeAmount')}:`,
          value: (
            <Value
              testid={`details-${snakeCase(channelPayment?.reference)}-fee`}
              flex
            >{`${channelPayment?.feeAmount} ${channelPayment?.feeCurrency}`}</Value>
          ),
        },
        {
          label: `${t('payments.channels.walletCurrency')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-wallet-currency`} flex>
              {channelPayment?.walletCurrency}
            </Value>
          ),
        },
        {
          label: `${t('payments.channels.displayCurrency')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-display-currency`} flex>
              {channelPayment?.displayCurrency}
            </Value>
          ),
        },
        {
          label: `${t('payments.channels.exchangeRate')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-exchange-rate`} flex>
              {channelPayment?.exchangeRate?.rate}
            </Value>
          ),
        },
        {
          label: `${t('payments.channels.walletAmount')}:`,
          value: (
            <Value testid={`details-${snakeCase(channelPayment?.reference)}-wallet-amount`} flex>
              {channelPayment?.walletAmount}
            </Value>
          ),
        },
        {
          label: `${t('From wallet address')}:`,
          value: (
            <Value flex>
              <Text
                testid={`details-${snakeCase(channelPayment?.reference)}-source`}
                size="sm"
                color="black"
              >
                {(channelPayment?.sources?.[0] &&
                  ellipseMiddleText(channelPayment?.sources?.[0], 6, 6)) ||
                  '-'}
              </Text>
              {channelPayment?.sources?.[0] && (
                <CopyButton
                  copyText={channelPayment?.sources?.[0]}
                  iconColour={copyButtonIconColour}
                />
              )}
            </Value>
          ),
        },
      ],
    },
  ]
  return (
    <>
      <MainArea className="xs:w-23 grid xs:grid-cols-1 xs:space-y-4 sm:gap-6 sm:grid-cols-3 sm:justify-between sm:space-y-0 lg:grid-cols-3 lg:justify-between lg:space-y-0 pt-4">
        {!!dataColumns?.length &&
          dataColumns?.map(column => {
            return (
              <Column
                key={column?.heading}
                flex
                flexAuto
                flexCol
                className="xs:w-4/6 space-y-1 sm:w-full"
              >
                <Heading
                  flex
                  justifyContent="between"
                  paddingB={8}
                  divider="bottom"
                  className="mb-1"
                >
                  <Text size="sm" weight="medium" color="black">
                    {column?.heading}
                  </Text>
                </Heading>

                {!!column?.items?.length &&
                  column?.items?.map(({ label, value }) => {
                    return (
                      <KeyValueRow
                        key={String(label)}
                        flex
                        justifyContent="between"
                        paddingX={4}
                        borderRadius={4}
                        className="mb-1 hover:bg-gray-02"
                      >
                        <Key>
                          {!isString(label) && !isNumber(label) ? (
                            <>{label || '-'}</>
                          ) : (
                            <Text size="sm" color="grey">
                              {sentenceCase(String(label)) || '-'}
                            </Text>
                          )}
                        </Key>
                        <Value>
                          {!isString(value) && !isNumber(value) ? (
                            <>{value || '-'}</>
                          ) : (
                            <Text size="sm" color="black">
                              {String(value) || '-'}
                            </Text>
                          )}
                        </Value>
                      </KeyValueRow>
                    )
                  })}
              </Column>
            )
          })}
      </MainArea>
      <Section className="mt-6">
        <Button
          testid={`details-${snakeCase(channelPayment?.reference)}-view-payload-btn`}
          noStyling
          className="flex"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Text weight="medium" size="sm">
            {t('fullPayload', {
              toggle: isExpanded ? t('hide') : t('view'),
            })}
          </Text>
        </Button>
        <ShowHide flex className={`my-4 animate-fade-in-down ${isExpanded ? `block` : `hidden`}`}>
          <Code
            tag="pre"
            flex
            full
            className="monospace font-bold p-4 text-left text-white text-xs bg-black rounded-xl"
          >
            {JSON.stringify(sortObjectPropertiesByName(channelPayment), null, 3)}
          </Code>
        </ShowHide>
      </Section>
    </>
  )
}

export default React.memo(ChannelDetails)
