import React from 'react'
import { Box as Section } from '@node-space/storybook-components/dist/Box'
import { PagingSidePanelType } from '@node-space/storybook-components/dist/components/SidePanel/types'
import { SidePanel } from '@node-space/storybook-components/dist/SidePanel'
import { appBannersId } from 'components/app/AppBanners'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { PendingTransactionsResponse } from 'types/approvals'
import { highlightRowBasedOnId } from 'utils/table/table'
import { SidePanelBody } from './SidePanelBody'
import { SidePanelHeaderTemplate } from './SidePanelHeaderTemplate'

interface SidePanelSummaryProps {
  isOpen: boolean
  paging: PagingSidePanelType
  onClose: () => void
  transactionDetails: PendingTransactionsResponse
  tab?: string
}

export const SidePanelSummary = ({
  isOpen,
  paging,
  onClose,
  transactionDetails,
  tab,
}: SidePanelSummaryProps) => {
  const bannerSpacing = useComponentHeight(appBannersId)

  return (
    <SidePanel
      isOpen={isOpen}
      paging={paging}
      onClose={onClose}
      activeRowId={transactionDetails?.userReference}
      onActiveRowChange={highlightRowBasedOnId}
      sidePanelHeader={{
        sidePanelHeaderTemplate: (
          <SidePanelHeaderTemplate transactionDetails={transactionDetails} />
        ),
      }}
      externalStyle={{ top: `${bannerSpacing}px` }}
    >
      <Section tag="section" width="full" background="white">
        <SidePanelBody transactionDetails={transactionDetails} tab={tab} />
      </Section>
    </SidePanel>
  )
}
