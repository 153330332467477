import { useMutation } from '@tanstack/react-query'
import { logSentryError } from '@node-space/utils'
import {
  addBeneficiary,
  addBeneficiaryPostChallengeRequest,
  addBeneficiaryPreRequest,
  deleteBeneficiary,
  updateBeneficiaryPostChallengeRequest,
  updateBeneficiaryPreRequest,
} from 'services/BeneficiaryService'
import { BaseErrorResponse, BeneficiaryDetails } from 'types/beneficiaries'

export const useAddBeneficiaryMutation = () => useMutation({ mutationFn: addBeneficiary })

export const useAddBeneficiaryMutationPreRequest = () => {
  return useMutation({
    mutationFn: addBeneficiaryPreRequest,
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useAddBeneficiaryMutationPreRequest', error, data)
      }
    },
  })
}

export const useAddBeneficiaryMutationChallengeRequest = () =>
  useMutation({ mutationFn: addBeneficiaryPostChallengeRequest })

export const useDeleteBeneficiaryMutation = () => useMutation({ mutationFn: deleteBeneficiary })

export const useUpdateBeneficiaryMutationPreRequest = () => {
  return useMutation({
    mutationFn: (beneficiary: BeneficiaryDetails) => updateBeneficiaryPreRequest(beneficiary),
    onError: (error: BaseErrorResponse, data) => {
      if (error?.status !== 428) {
        logSentryError('Error: useUpdateBeneficiaryMutation', error, data)
      }
    },
  })
}

export const useUpdateBeneficiaryMutationChallengeRequest = () =>
  useMutation({ mutationFn: updateBeneficiaryPostChallengeRequest })
