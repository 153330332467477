import { useMutation } from '@tanstack/react-query'
import { postForgotPassword, postResetPassword } from 'services/PasswordService'

export const useForgotPasswordMutation = () => {
  return useMutation({ mutationFn: postForgotPassword })
}

export const useResetPasswordMutation = () => {
  return useMutation({ mutationFn: postResetPassword })
}
