import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Callout,
  DoubleLabelSelect,
  ModalActions,
  ModalDivider,
  ModalScrollable,
  Radio,
} from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import CopyRow from 'components/CopyRow'
import { WalletQrCode } from 'pages/WalletDetails/components/ExtendedDetails/CryptoExtendedDetails/WalletQrCode'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { floorWithPrecision, formatString } from 'utils/utils'
import { ReceiveActionProps } from './Receive'

const ReceiveForm = ({
  dismissAction,
  form,
  wallet,
  loading,
  wallets,
  isFetchingWallets,
}: ReceiveActionProps) => {
  const [copiedValue, setCopiedValue] = useState<string>()
  const { t } = useTranslation()
  const formValues = form.watch()

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      secondary: true,
      onClick: () => dismissAction(),
    },
    {
      children: t('done'),
      onClick: () => dismissAction(),
      disabled: isFetchingWallets || loading,
      ...((isFetchingWallets || loading) && {
        iconElement: <Loader />,
      }),
    },
  ]
  const messageError = form.formState?.errors?.message?.message
  const supportedWallets = wallets.filter(x => !x.currency.fiat && x.currency.supportsDeposits)

  // use wallet over form wallet for the case of fetching new wallet deposit details when address is missing
  const selectedWallet =
    wallet ||
    (formValues.walletId && supportedWallets.find(x => x.id.toString() === formValues.walletId))

  // set radio options if more than 1 protocol detected for selected wallet e.g. TRC20 and ERC20 for USDT
  let radioProtocolProps
  if (selectedWallet?.currency?.protocols?.length) {
    const otherProtocols = selectedWallet.currency.protocols.filter(
      x => x.code !== selectedWallet.protocol
    )

    if (otherProtocols?.length) {
      radioProtocolProps = {
        label: t('selectNetwork'),
        name: 'protocol',
        options: [{ code: selectedWallet.protocol }, ...otherProtocols].map(x => ({
          label: x.code,
          value: x.code,
        })),
        borderless: false,
        value: formValues.protocol || selectedWallet.protocol,
        horizontal: true,
      }
    }
  }

  const protocolAddress =
    selectedWallet && (!formValues.protocol || selectedWallet.protocol === formValues.protocol)
      ? selectedWallet.address
      : (selectedWallet?.alternatives as { protocol: string; address: string }[])?.find(
          x => x.protocol === formValues.protocol
        )?.address

  return (
    <div data-testid="receiveForm">
      <ModalNavBar title={t('receive')} onClose={dismissAction} />
      <ModalScrollable>
        <div className="p-6 pt-5 pb-7">
          {messageError && (
            <div className="pb-4">
              <Callout message={messageError} state="error" />
            </div>
          )}

          {/* wallet selector */}
          <div className="pb-6">
            <DoubleLabelSelect
              iconsUrl={process.env.ICONS_URL}
              label={t('currency')}
              menuMaxHeight={400}
              showOverflow
              skeletonLoading={isFetchingWallets}
              name={t('currency')}
              placeholder={
                !isFetchingWallets && !wallets?.length ? t('noWallets') : t('searchOrSelectAWallet')
              }
              options={supportedWallets?.map(x => ({
                icon: x.currency?.code?.toLowerCase(),
                value: x.id?.toString(),
                label: x.description,
                secondLabel: formatString(
                  t('balanceWithCurrency'),
                  floorWithPrecision(x.balance, x.currency?.pricePrecision),
                  x.currency?.code
                ),
              }))}
              value={formValues?.walletId}
              isSearchable={true}
              iconLeft={!formValues?.walletId && <Icon name="SearchIcon" />}
              onChange={(value: string) =>
                form.reset({ walletId: value }, { keepDefaultValues: true })
              }
              displayTooltipForLongLabel
            />
          </div>

          {loading && (
            <Box flex padding={8} justifyContent="center">
              {<Loader />}
            </Box>
          )}

          {/* qr code and wallet details */}
          {!loading && selectedWallet && (
            <>
              {radioProtocolProps && (
                <div className="pb-6">
                  <Radio
                    {...radioProtocolProps}
                    onChange={value => form.setValue('protocol', value)}
                  />
                </div>
              )}

              {!messageError && (
                <>
                  <div className="flex flex-col p-4 pt-6 pb-4 mb-6 rounded border-gray border bg-gray-1">
                    <div className="mb-4 flex justify-center">
                      <WalletQrCode address={protocolAddress} />
                    </div>
                    <ModalDivider />
                    <CopyRow
                      title={formatString(
                        t('yourWalletAddress'),
                        selectedWallet.currency.code,
                        formValues.protocol ? ` ${formValues.protocol}` : ''
                      )}
                      description={protocolAddress}
                      copiedValue={copiedValue}
                      setCopiedValue={copiedValue => {
                        track.Amp.track(AmplitudeEvent.RECEIVE_COPY, {
                          category: AmplitudeEventCategory.MERCHANT_PORTAL,
                          action: AmplitudeEventAction.CLICK,
                          copied: 'wallet address',
                        })
                        setCopiedValue(copiedValue)
                      }}
                    />
                    {selectedWallet.lookup && (
                      <CopyRow
                        title={t('destinationTag')}
                        description={selectedWallet.lookup}
                        copiedValue={copiedValue}
                        setCopiedValue={copiedValue => {
                          track.Amp.track(AmplitudeEvent.RECEIVE_COPY, {
                            category: AmplitudeEventCategory.MERCHANT_PORTAL,
                            action: AmplitudeEventAction.CLICK,
                            copied: 'destination tag',
                          })
                          setCopiedValue(copiedValue)
                        }}
                      />
                    )}
                  </div>

                  {/* confirmation message */}
                  {!selectedWallet.currency.options?.confirmations && (
                    <Callout
                      state="info"
                      message={t('cryptoDepositWarning', {
                        currencyCode: selectedWallet?.currency?.code.toUpperCase(),
                      })}
                    />
                  )}
                  {selectedWallet.currency.options?.confirmations && (
                    <Callout state="info">
                      <div className="text-gray-100 text-sm">
                        {formatString(
                          t('sendDisclaimer'),
                          selectedWallet.currency.code,
                          formValues.protocol
                            ? formatString(t('sendEnsureNetwork'), formValues.protocol)
                            : '',
                          selectedWallet.currency.options?.confirmations,
                          selectedWallet.currency.options?.address
                            ? ' Track your deposit status'
                            : ''
                        )}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            selectedWallet.currency.options?.address &&
                            selectedWallet.currency.options.address.replace(
                              '{{address}}',
                              protocolAddress
                            )
                          }
                          className="text-primary font-medium cursor-pointer"
                        >
                          {` ${t('here')}`}
                        </a>
                        .
                      </div>
                    </Callout>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </ModalScrollable>
      {/* actions */}
      <ModalActions actions={actions} />
    </div>
  )
}

export default ReceiveForm
