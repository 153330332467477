import { Approval } from 'constants/General'
import { ApprovalRulesData } from 'types/approvals'

export const useApproverCount = (startIteratorFrom: number) => {
  const arrayLength = startIteratorFrom === 0 ? 10 : 9
  return [...Array(arrayLength)].map((_, i) => {
    const iteratorValue = startIteratorFrom + i
    return { label: `${iteratorValue}`, value: `${iteratorValue}` }
  })
}

export const useApprovalRangeConfig = (
  approvalRulesData: ApprovalRulesData[],
  rowIndex: number
) => {
  const rowCount = approvalRulesData?.length

  const currentRowFromValue = approvalRulesData?.[rowIndex]?.minAmount
  const currentRowToValue = approvalRulesData?.[rowIndex]?.maxAmount
  const previousRowToValue = approvalRulesData?.[rowIndex - 1]?.maxAmount
  const incrementedToValue = !!previousRowToValue
    ? (Number(previousRowToValue) + 0.01)?.toString()
    : ''

  const addButtonIsVisible = rowCount === 1 || (rowCount > 1 && rowIndex === rowCount - 1)
  const deletebuttonIsVisible = rowIndex !== 0 && rowCount > 1 && rowIndex === rowCount - 1

  const currentRowToValueToNumber = Number(currentRowToValue)
  const isCurrentRowRangeInvalid =
    currentRowToValue !== Approval.RULES.INFINITY &&
    (isNaN(currentRowToValueToNumber) ||
      currentRowToValueToNumber <= 0 ||
      currentRowToValueToNumber <= Number(currentRowFromValue))
  const isAnyToValueInvalid = !!approvalRulesData?.filter(ruleData => !!!ruleData?.maxAmount)
    ?.length

  const prefillFromValue = rowIndex > 0 ? incrementedToValue : currentRowFromValue?.toString()
  const toInputIsDisabled = rowCount === 1 || currentRowToValue === Approval.RULES.INFINITY

  return {
    rowCount,
    prefillFromValue,
    toInputIsDisabled,
    addButtonIsVisible,
    deletebuttonIsVisible,
    currentRowToValue,
    isCurrentRowRangeInvalid,
    approvalRangeIsInvalid: isCurrentRowRangeInvalid || isAnyToValueInvalid,
  }
}
