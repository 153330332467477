import { PathNames } from 'constants/General'
import { isProduction, isSandbox } from '../environment'

export const PardotRoutes = {
  [PathNames.DEV_GET_STARTED]: {
    piAId: '1014952',
    piCId: '4223',
    onlyRun: () => isProduction,
  },
  [PathNames.DEVX_SIGNUP_URL]: {
    piAId: '1014952',
    piCId: '4712',
    onlyRun: () => isSandbox,
  },
} as const

export type PardotRoutes = (typeof PardotRoutes)[keyof typeof PardotRoutes]

interface LPSProps {
  piAId: string
  piCId: string
}

export const LoadPardotScript = ({ piAId, piCId }: LPSProps) => {
  const element = document.createElement('script')

  ;(window as any).piAId = piAId
  ;(window as any).piCId = piCId

  element.id = 'external'
  element.src = 'https://info.bvnk.com/pd.js'
  element.type = 'text/javascript'
  element.async = true
  document.head.appendChild(element)

  return element
}
