import React, { useMemo } from 'react'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box as Row } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { BvnkNetworkTransferTransactionSubType } from 'pages/WalletDetails/components/RecentActivity/SidePanel/PaymentTemplates/BvnkNetworkTransfer'
import { useTransactionIcons } from 'pages/Wallets/hooks/useTransactionIcons'
import { TransactionType } from 'types/transaction/TransactionType'
import { useTransactionHistoryLabel } from './hooks/useTransactionHistoryLabel'

interface SidePanelHeaderTransactionHistoryProps {
  data: {
    iconUrls: string[]
    amountWithCurrency: string
    transactionType: string
    transactionSubType: string
    customerReference: string
    transformedData: { fromAmount: string }
    name?: string
  }
}

export const SidePanelHeaderTransactionHistory = ({
  data,
}: SidePanelHeaderTransactionHistoryProps) => {
  const { t } = useTranslation()
  const {
    iconUrls,
    amountWithCurrency,
    transactionType,
    transactionSubType,
    customerReference,
    transformedData,
    name,
  } = data

  const transactionsTypeSubType = `${transactionType}_${transactionSubType}`
  const { singleIcon, isMultipleIcons } = useTransactionIcons(transactionsTypeSubType, iconUrls)

  const secondaryLabelContent = useTransactionHistoryLabel({
    transactionType,
    transactionSubType,
    name,
    fallback: startCase(transactionType),
  })

  return (
    <SidePanelHeader
      template={2}
      heading={{
        iconUrls: isMultipleIcons ? iconUrls : singleIcon,
        primaryLabel:
          transactionType === TransactionType.LATE_PAYMENT
            ? transformedData?.fromAmount
            : amountWithCurrency,
        secondaryLabel: secondaryLabelContent,
      }}
      {...(!!customerReference && {
        reference: {
          legend: customerReference && t('customerReference'),
          label: !!customerReference && (
            <Row flex flexItemsCenter>
              <Text tag="span" size="sm">
                {ellipseMiddleText(customerReference, 10, 10)}
              </Text>
              <CopyButton
                copyText={customerReference}
                confirmationMessage={t('copiedToClipboard')}
                iconColour={copyButtonIconColour}
              />
            </Row>
          ),
        },
      })}
    />
  )
}
