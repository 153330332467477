import { isArray } from 'lodash-es'
import { useFeatureFlags } from '@node-space/hooks'
import { Approval } from 'constants/General'
import { ApprovalPreferences } from 'types/approvals'
import { useAccountsContext } from './context/useAccountsContext'
import { useApprovalsPreference } from './useApprovalsPreference'

/**
 * Checks if Approvals is required for a payment flow or not
 * if no paymentFlow is passed, it will return all the flows with their corresponding boolean values
 *
 *
 * @param {string} paymentFlow - any of the supported flow types
 * @param {number | string} amount - option to check if approval is required for amount
 */
export const useApprovalsConfigForFlow = (
  paymentFlow?: string,
  amount?: number | string
): {
  approvalPreferences?: ApprovalPreferences[]
  approvalsIsRequiredForFlow?: boolean
  isFetchingApprovalPreference?: boolean
} => {
  const { enableApprovalFlowAccountLevel, enableApprovalGlobalAccountRule } = useFeatureFlags()
  const { currentAccount } = useAccountsContext()
  const currentAccountReference = currentAccount?.reference
  const isQueryEnabled = enableApprovalFlowAccountLevel && !!currentAccountReference
  const { approvalFlowPreferences, isFetchingApprovalPreference } = useApprovalsPreference(
    currentAccountReference,
    isQueryEnabled
  )
  const approvalPreferences = approvalFlowPreferences?.preferences || approvalFlowPreferences
  const isValidApprovalPreferenceFormat = isArray(approvalPreferences)

  if (!paymentFlow && isValidApprovalPreferenceFormat) {
    return { approvalPreferences }
  }

  const indexOfPaymentFlow =
    isValidApprovalPreferenceFormat &&
    approvalPreferences?.findIndex(
      approvalPreference =>
        approvalPreference?.flow === paymentFlow && approvalPreference?.approvalsRequired
    )

  let approvalsIsRequiredForFlow = isValidApprovalPreferenceFormat && indexOfPaymentFlow !== -1
  const approvalLimits = approvalFlowPreferences?.limits
  if (amount && approvalsIsRequiredForFlow && approvalLimits && enableApprovalGlobalAccountRule) {
    const amountValue = Number(amount)
    const approvalLimitsCheck = approvalLimits?.find(limit =>
      limit?.maxAmount === Approval.RULES.INFINITY
        ? amountValue >= Number(limit?.minAmount)
        : amountValue >= Number(limit?.minAmount) && amountValue <= Number(limit?.maxAmount)
    )
    approvalsIsRequiredForFlow = !!Number(approvalLimitsCheck?.approvalsCount)
  }

  return { approvalsIsRequiredForFlow, isFetchingApprovalPreference }
}
