import React, { memo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import Modal, {
  ModalActions,
  ModalBody,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import Text from '@node-space/storybook-components/dist/Text'
import { PathNames } from 'constants/General'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { WalletTypes } from 'types/types'
import {
  modalAtom,
  totalNumberOfPayoutsAtom,
  uploadMessageAtom,
  walletTypeAtom,
} from '../atoms/massPayoutAtoms'
import { ModalVariant } from '../types'

interface MassPayoutModalProps {
  totalPayments?: number
}

const MassPayoutModal = ({ totalPayments }: MassPayoutModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { reference: batchId } = useParams()
  const queryClient = useQueryClient()
  const [modal, setModal] = useAtom(modalAtom)
  const message = useAtomValue(uploadMessageAtom)
  const totalNumberOfPayouts = useAtomValue(totalNumberOfPayoutsAtom)
  const walletType = useAtomValue(walletTypeAtom)

  const cryptoPayout = walletType === WalletTypes.CRYPTO

  const handleDoneClick = () => {
    setModal({ isOpen: false })
  }

  const handleSubmit = () => {
    navigate(PathNames.MASS_PAYOUTS)
    setModal({ isOpen: false })
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.massCryptoPayoutById(batchId) })
  }

  const handleUploadNewFile = () => {
    navigate(PathNames.MASS_PAYOUTS_CREATE)
    setModal({ isOpen: false })
  }

  const numberOfPayments = cryptoPayout ? (totalNumberOfPayouts ?? totalPayments) : totalPayments

  const MassPayoutModalVariant = ({ modalVariant }) => {
    if (modalVariant === ModalVariant.PROCESSED)
      return (
        <Modal visible closeModal={null}>
          <StatusCard
            customIcon={<Icon name="HourglassIcon" size="hero" />}
            description={<Text size="sm">{message}</Text>}
            headline={t('massPayouts.fileIsBeingProcessed')}
            status="success"
          />
          <ModalActions
            actions={[
              {
                children: t('done'),
                onClick: handleDoneClick,
              },
            ]}
            alignActions="center"
          />
        </Modal>
      )
    if (modalVariant === ModalVariant.SUCCESSFUL)
      return (
        <Modal visible closeModal={null}>
          <Box data-testid="upload-successful">
            <ModalNavBar onClose={handleDoneClick} hasDivider={false} />
            <ModalBody hasTopPadding={false}>
              <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
                <Icon name="ClapIcon" color="status-success-500" size="lg" />
                <Text tag="h1" color="text-900" align="center" size="xl" weight="semibold">
                  {t('paymentSubmitted')}
                </Text>
                <Text size="sm" align="center" weight="normal" color="text-900">
                  {`${numberOfPayments} payments have been successfully submitted and are on their way to your recipients.`}
                </Text>
                <Text size="sm" color="grey" weight="normal" align="center">
                  {cryptoPayout
                    ? 'You can track the payments in Transaction history.'
                    : t('massPayouts.trackPayments')}
                </Text>
              </Box>
            </ModalBody>
          </Box>
          <ModalActions
            actions={[
              {
                children: t('done'),
                onClick: handleSubmit,
              },
            ]}
            alignActions="center"
          />
        </Modal>
      )
    if (modalVariant === ModalVariant.APPROVALS)
      return (
        <Modal visible closeModal={null}>
          <Box data-testid="upload-approvals">
            <ModalNavBar onClose={handleDoneClick} hasDivider={false} />
            <ModalBody hasTopPadding={false}>
              <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
                <Icon name="HourglassIcon" size="lg" />
                <Text tag="h1" color="text-900" size="xl" align="center" weight="semibold">
                  {t('approvals.sentForApproval')}
                </Text>
                <Text size="sm" align="center" weight="medium" color="grey">
                  {t('approvals.sentForApprovalDisclaimer')}
                </Text>
              </Box>
            </ModalBody>
          </Box>
          <ModalActions
            actions={[
              {
                children: t('done'),
                onClick: handleSubmit,
              },
            ]}
            alignActions="center"
          />
        </Modal>
      )
    if (modalVariant === ModalVariant.DRAFT)
      return (
        <Modal visible closeModal={null}>
          <Box data-testid="modal-draft">
            <ModalNavBar onClose={handleDoneClick} hasDivider={false} />
            <ModalBody hasTopPadding={false}>
              <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
                <Icon name="HourglassIcon" size="lg" />
                <Text tag="h1" color="text-900" align="center" size="xl" weight="semibold">
                  {t('massPayouts.draftOutstanding')}
                </Text>
                <Text size="sm" color="grey" weight="normal" align="center">
                  {t('massPayouts.outstandingPayments', {
                    numberOfPayments: numberOfPayments,
                  })}
                </Text>
              </Box>
            </ModalBody>
          </Box>
          <ModalActions
            actions={[
              {
                children: t('massPayouts.uploadNewFile'),
                onClick: handleUploadNewFile,
                secondary: true,
              },
              {
                children: t('massPayouts.continueEditing'),
                onClick: handleDoneClick,
              },
            ]}
            alignActions="end"
          />
        </Modal>
      )
  }

  return modal?.isOpen && <MassPayoutModalVariant modalVariant={modal?.variant} />
}

export default memo(MassPayoutModal)
