import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Input2FA from '@node-space/storybook-components/dist/Input2FA'
import QRCode from '@node-space/storybook-components/dist/QRCode'
import { Description } from 'components/text/Description'
import { useProfileContext } from 'hooks/context/useProfileContext'

interface Enable2FAVerifyModalProps {
  secret: string
  isLoading: boolean
  hasError: boolean
  onChange: (val: string) => void
}

const Enable2FAVerifyModal = ({
  secret,
  isLoading,
  hasError,
  onChange,
}: Enable2FAVerifyModalProps) => {
  const { t } = useTranslation()

  const [qrCodeUrl, setQrCodeUrl] = useState('')

  const { profile } = useProfileContext()

  useEffect(() => {
    const otpUrl = `otpauth://totp/BVNK:${profile?.firstName}?secret=${secret}&issuer=BVNK&algorithm=SHA1&digits=6&period=30`
    if (secret.length > 0) {
      setQrCodeUrl(otpUrl)
    }
  }, [secret, profile])

  return (
    <>
      <Description>{t('qrCodeScanNotice')}</Description>

      <QRCode value={qrCodeUrl} />

      <Box flex direction="col" alignItems="center">
        <InputLabel>{t('authCodeConfirm')}</InputLabel>
        <Input2FA
          testid="otc-2FA-input"
          disabled={isLoading}
          onCompleteFill={value => {
            onChange(value)
          }}
          error={hasError}
          errorText={t('incorrectOtpCode')}
        />
      </Box>
    </>
  )
}

export default Enable2FAVerifyModal
