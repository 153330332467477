import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getWalletTransactions } from 'services/TransactionService'
import { GetWalletTransactionsReq } from 'types/transaction/GetTransactionsReq'

export const useTransactionsQuery = (req: GetWalletTransactionsReq) => {
  return useQuery({
    queryKey: reactQueryKeys.walletTransactions(req),
    queryFn: () => getWalletTransactions(req),
    placeholderData: keepPreviousData,
  })
}
