import React from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AnimatedIcon } from '@node-space/storybook-components/dist/AnimatedIcon'
import loader from '@node-space/storybook-components/dist/lottie/loader.json'
import { Logo } from './logos/Logo'
import { LogoColor } from 'types/types'

const AppLoader = () => {
  return (
    <Box flex direction="col" centerChildren width="full" minHeight="screen" gap={20}>
      <AnimatedIcon animationData={loader} width={80} height={80} />
      <Logo logoColor={LogoColor.DARK} />
    </Box>
  )
}

export default AppLoader
