import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import EmptyContainer from 'components/EmptyContainer'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useMappedRoles } from 'hooks/useMappedRoles'

interface BeneficiaryEmptyStateProps {
  onAddBeneficiary: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const BeneficiaryEmptyState = ({ onAddBeneficiary }: BeneficiaryEmptyStateProps) => {
  const { t } = useTranslation()
  const { profile } = useProfileContext() || {}
  const { twoFactorEnabled: isTwoFactorEnabled } = profile || {}
  const permissions = useMappedRoles()
  const canEdit = permissions?.manageBeneficiaries?.canEdit

  const EmptyStateDescription = () => {
    if (canEdit) {
      return (
        <Text size="sm" color="grey">
          {isTwoFactorEnabled
            ? t('manageBeneficiaries.noBeneficiariesDescription')
            : t('manageBeneficiaries.noBeneficiariesNo2FADescription')}
        </Text>
      )
    } else {
      return (
        <Text size="sm" color="grey">
          {t('manageBeneficiaries.noBeneficiariesAndCannotAdd')}
        </Text>
      )
    }
  }

  return (
    <EmptyContainer className="grid p-16 mt-2 justify-items-center">
      <Box paddingB={16}>
        <Text size="lg" weight="medium" color="black" tag="h3">
          {t('manageBeneficiaries.noBeneficiaries')}
        </Text>
      </Box>
      <Box flex paddingB={24}>
        <EmptyStateDescription />
      </Box>
      <Tooltip
        position="top"
        bodyContent={`${t('manageBeneficiaries.noBeneficiariesNo2FALink')}`}
        className="w-[260px] whitespace-normal"
        disabled={isTwoFactorEnabled}
      >
        {canEdit && (
          <Button
            iconElement={<Icon name="PlusIcon" color="white" />}
            onClick={onAddBeneficiary}
            disabled={!isTwoFactorEnabled}
          >
            {t('manageBeneficiaries.addBeneficiary')}
          </Button>
        )}
      </Tooltip>
    </EmptyContainer>
  )
}
