import React, { useMemo } from 'react'
import i18next from 'i18next'
import { isEmpty, startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import Box, { Box as Row } from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import Icon from '@node-space/storybook-components/dist/Icon'
import { SidePanelHeader } from '@node-space/storybook-components/dist/SidePanel'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { useTransactionIcons } from 'pages/Wallets/hooks/useTransactionIcons'
import { getSupportedPaymentTypes, titleCase } from 'utils/utils'
import ProofOfPaymentContainer from '../Modal/components/ProofOfPaymentContainer'
import { isTransactionANetworkTransfer } from './utils'

interface SidePanelHeaderWalletProps {
  data: {
    iconUrls: string[]
    amountWithCurrency: string
    transactionReference: string
    transactionType: string
    transactionSubType: string
    currencyFrom?: string
    currencyTo?: string
    status?: string
    externalReference?: string
    hasProofOfPayment?: boolean
    bvnkNetworkBeneficiary?: string
    bvnkNetworkSender?: string
  }
  supportedPaymentTypes: ReturnType<typeof getSupportedPaymentTypes>
}

const statusType = {
  SUCCESS: 'success',
  SUBMITTED: 'success',
  DECLINED: 'error',
  ERROR: 'error',
  FAILED: 'error',
  REJECTED: 'error',
  PROCESSING: 'info',
  PENDING_USER_APPROVAL: 'info',
  COMPLETE: 'success',
}

export const SidePanelHeaderWallet = ({
  data,
  supportedPaymentTypes,
}: SidePanelHeaderWalletProps) => {
  const { t } = useTranslation()

  const {
    iconUrls,
    amountWithCurrency,
    transactionReference,
    transactionType,
    transactionSubType,
    currencyFrom = '',
    currencyTo = '',
    status,
    externalReference,
    hasProofOfPayment,
    bvnkNetworkBeneficiary,
    bvnkNetworkSender,
  } = data

  const transactionsTypeSubType = `${transactionType}_${transactionSubType}`
  const isUserWithdrawal =
    transactionsTypeSubType === supportedPaymentTypes.FIRST_AND_THIRD_PARTY_WITHDRAWAL
  const isNetworkTransfer = isTransactionANetworkTransfer(transactionType, transactionSubType)

  const transactionAttributes = useMemo(() => {
    const transactionTypeSubType = `${transactionType}_${transactionSubType}`
    const isConvertIn = transactionTypeSubType == supportedPaymentTypes.CONVERT_IN
    const isConvertOut = transactionTypeSubType == supportedPaymentTypes.CONVERT_OUT
    return {
      isOfTypeTrade: isConvertIn || isConvertOut,
      copyOfTypeTrade:
        (isConvertIn && i18next.t('transactions.sideBar.convertedIn')) ||
        (isConvertOut && i18next.t('transactions.sideBar.convertedOut')),
    }
  }, [transactionType, transactionSubType])

  const getNetworkTransferSidepanelHeader = (
    transactionsTypeSubType: string,
    bvnkNetworkBeneficiary: string,
    bvnkNetworkSender: string
  ) => {
    const supportedPaymentTypes = getSupportedPaymentTypes()

    const isPayout =
      transactionsTypeSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_OUT
    const isPayin =
      transactionsTypeSubType === supportedPaymentTypes.BVNK_NETWORK_TRANSFER_PAYMENT_IN

    if (isPayout) {
      return t('transactions.sideBar.networkTransferTo', {
        networkMember: bvnkNetworkBeneficiary,
      })
    } else if (isPayin) {
      return t('transactions.sideBar.networkTransferFrom', {
        networkMember: bvnkNetworkSender,
      })
    }

    return startCase(transactionType || transactionSubType)
  }

  const secondaryLabelContent = useMemo(() => {
    if (transactionAttributes?.isOfTypeTrade) {
      return (
        <Box className="md:flex">
          <Text size="sm" className="text-900">
            {transactionAttributes?.copyOfTypeTrade} {currencyFrom}
          </Text>
          <Icon name="ArrowRightIcon" />
          <Text size="sm" className="text-900">
            {currencyTo}
          </Text>
        </Box>
      )
    } else if (isNetworkTransfer) {
      return getNetworkTransferSidepanelHeader(
        transactionsTypeSubType,
        bvnkNetworkBeneficiary,
        bvnkNetworkSender
      )
    } else {
      return startCase(transactionType || transactionSubType)
    }
  }, [
    transactionAttributes?.isOfTypeTrade,
    transactionAttributes?.copyOfTypeTrade,
    currencyFrom,
    currencyTo,
    isNetworkTransfer,
  ])

  const mappedStatus = statusType[status] ?? 'info'

  const rightContent = {
    rightLabel: i18next.t('transactions.sideBar.reference'),
    rightDescription: (
      <Row flex flexItemsCenter>
        <Text tag="span" size="sm">
          {ellipseMiddleText(transactionReference, 20, 10)}
        </Text>
        <CopyButton
          copyText={transactionReference}
          confirmationMessage={i18next.t('copiedToClipboard')}
          iconColour={copyButtonIconColour}
        />
      </Row>
    ),
  }
  const leftContent = {
    leftLabel: i18next.t('status'),
    leftDescription: (
      <StatusPill displayAsDot state={mappedStatus} label={titleCase(status)} fitContent />
    ),
  }

  const { singleIcon, isMultipleIcons } = useTransactionIcons(transactionsTypeSubType, iconUrls)

  const bottomContent = {
    ...((isUserWithdrawal || !isEmpty(transactionReference)) && {
      ...(!isEmpty(transactionReference) && {
        ...rightContent,
      }),
      ...((isUserWithdrawal || isNetworkTransfer) && {
        ...leftContent,
      }),
    }),
  }

  return (
    <SidePanelHeader
      template={2}
      heading={{
        iconUrls: isMultipleIcons ? iconUrls : singleIcon,
        primaryLabel: transactionAttributes?.isOfTypeTrade ? (
          <Text size="xl" weight="semibold" className="text-900">
            {amountWithCurrency}
          </Text>
        ) : (
          amountWithCurrency
        ),
        secondaryLabel: secondaryLabelContent,
      }}
      bottomContent={bottomContent}
      isDividerDisplayed={!isEmpty(bottomContent)}
    >
      {isUserWithdrawal && (
        <ProofOfPaymentContainer
          transactionType={transactionType}
          transactionSubType={transactionSubType}
          status={status}
          externalReference={externalReference}
          hasProofOfPayment={hasProofOfPayment}
          isLink={true}
          paymentReference={transactionReference}
        />
      )}
    </SidePanelHeader>
  )
}
