import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box as Form } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import {
  AlignActions,
  Modal,
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { RadioProps } from '@node-space/storybook-components/dist/Radio'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { logSentryError } from '@node-space/utils'
import { MERCHANT_PORTAL } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useAddCryptoAddressMutation } from 'hooks/mutations/useAddCryptoAddressMutation'
import { useValidateCryptoAddressMutation } from 'hooks/mutations/useValidateCryptoAddressMutation'
import { useCryptoCurrenciesQuery } from 'hooks/queries'
import { BaseErrorResponse } from 'types/beneficiaries'
import { ErrorCodes } from 'types/errorCodes'
import {
  AddCryptoAddressRequest,
  AddCryptoAddressScreens,
  CODE_CRYPTO,
  CryptoWithDestinationTag,
  DestinationTagValues,
  INVALID_CODE_RESPONSE,
  MINIMUM_ADDRESS_LENGTH_PRE_VALIDATION,
  ValidateCryptoAddressRequest,
} from 'types/whitelisting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import addCryptoAddressSchema from '../schemas/addCryptoAddressSchema'
import StepAddCryptoAddressForm from './StepAddCryptoAddressForm'
import StepConfirmCryptoAddressForm from './StepConfirmCryptoAddressForm'

export interface AddCryptoAddressFormData {
  cryptocurrency: string
  addressName: string
  address: string
  destinationTag?: string
  reason?: string
  protocol?: string
}

interface AddCryptoAddressModalProps {
  closeModal: () => void
  onAddCryptoSuccess: () => void
  resetCryptoTable: () => void
}

export type AddCryptoAddressScreensStates =
  (typeof AddCryptoAddressScreens)[keyof typeof AddCryptoAddressScreens]

const AddCryptoAddressModal = ({
  closeModal,
  onAddCryptoSuccess,
  resetCryptoTable,
}: AddCryptoAddressModalProps) => {
  const { currentAccount } = useAccountsContext()
  const { t } = useTranslation()
  const [formObserver, setFormObserver] = useState({
    backClickState: false,
    resetState: false,
    requiresRevalidation: false,
    addressIsValidated: false,
    mayRequireDestinationTag: false,
    destinationTagIsRequired: DestinationTagValues.NOT_REQUIRED,
  })
  const [whitelistAddressScreen, setWhitelistAddressScreen] =
    useState<AddCryptoAddressScreensStates>(AddCryptoAddressScreens.FORM)

  const [twoFactorCode, setTwoFactorCode] = useState('')
  const [twoFactorCodeError, setTwoFactorCodeError] = useState('')
  const handleTwoFactorCodeChange = useCallback(
    (value: string) => {
      setTwoFactorCode(value)
    },
    [twoFactorCodeError, setTwoFactorCodeError]
  )
  const [whitelistAddressError, setWhitelistAddressError] = useState('')

  const form = useForm<AddCryptoAddressFormData>({
    resolver: yupResolver(addCryptoAddressSchema(t)),
  })
  const { control, handleSubmit, clearErrors } = form
  const formValues = useWatch({ control })
  const { cryptocurrency, protocol, addressName, address, destinationTag, reason } = formValues

  const { data: cryptoCurrencies, isPending: isLoadingCryptocurrecies } = useCryptoCurrenciesQuery()
  const { mutate: mutateAddCrypto, isPending: addCryptoAddressIsProcessing } =
    useAddCryptoAddressMutation()
  const { mutate: mutateValidateCrypto, isPending: addressValidating } =
    useValidateCryptoAddressMutation()

  useEffect(() => {
    setFormObserver({
      ...formObserver,
      mayRequireDestinationTag:
        cryptocurrency === CryptoWithDestinationTag.XRP ||
        cryptocurrency === CryptoWithDestinationTag.ALGO,
      destinationTagIsRequired: DestinationTagValues.NOT_REQUIRED,
    })
  }, [cryptocurrency])

  const getCryptoCurrencies = useMemo(() => {
    if (!!cryptoCurrencies?.length) {
      return cryptoCurrencies.map(currency => ({
        value: currency.code,
        label: `${currency.name} (${currency.code})`,
        protocol: currency.protocols,
      }))
    }
    return []
  }, [cryptoCurrencies])

  const selectedCryptocurrencyProtocols = getCryptoCurrencies?.filter(
    x => x.value === cryptocurrency
  )?.[0]?.protocol

  let radioProtocolProps: RadioProps
  if (selectedCryptocurrencyProtocols?.length > 1) {
    radioProtocolProps = {
      label: t('selectNetwork'),
      name: 'protocol',
      options: selectedCryptocurrencyProtocols?.map(x => ({
        label: x.code,
        value: x.code,
      })),
      value: protocol === undefined ? selectedCryptocurrencyProtocols?.[0]?.code : protocol,
      borderless: false,
      horizontal: true,
    }
  }

  const requiredDestinationTagIsMissing = !!(
    formObserver.destinationTagIsRequired === DestinationTagValues.IS_REQUIRED && !destinationTag
  )

  const handleValidateAddress = async (
    addressInput: string,
    updatedCurrency?: string,
    addressWasPasted = false
  ) => {
    const validationPreconditionCheckFails =
      formObserver.addressIsValidated ||
      !cryptocurrency ||
      !addressName ||
      (!addressWasPasted && address?.length < MINIMUM_ADDRESS_LENGTH_PRE_VALIDATION)

    if (requiredDestinationTagIsMissing || validationPreconditionCheckFails) return

    const addressArgs: ValidateCryptoAddressRequest = {
      code: CODE_CRYPTO,
      address: addressInput || address,
      accountReference: currentAccount?.reference,
      source: MERCHANT_PORTAL,
      currency: updatedCurrency || cryptocurrency,
      ...(selectedCryptocurrencyProtocols?.length > 1 && {
        protocol: protocol === undefined ? selectedCryptocurrencyProtocols?.[0]?.code : protocol,
      }),
      ...(destinationTag && { tag: destinationTag }),
    }

    mutateValidateCrypto(addressArgs, {
      onSuccess: () => {
        clearErrors()
        setFormObserver({ ...formObserver, addressIsValidated: true, requiresRevalidation: false })
      },

      onError: (error: BaseErrorResponse) => {
        setFormObserver({ ...formObserver, addressIsValidated: false })
        if (error?.data?.code === ErrorCodes.INVALID_ADDRESS) {
          form.setError('address', {
            message: `${t('addressValidationFailed')}`,
          })
        } else if (error?.data?.code === ErrorCodes.FAILED_FINCRIME_INVALID_ADDRESS) {
          form.setError('address', {
            message: `${t('cantProcessPayout')}`,
          })
        } else {
          form.setError('address', {
            message: `${t('oopsSomethingWentWrong')}`,
          })
          logSentryError('Error from AddCryptoAddressModal - validateCryptoAddress', error)
        }
      },
    })
  }

  const handlePasteAddress = (addressElement: React.ClipboardEvent<HTMLInputElement>) => {
    const {
      target: { value: addressValue },
    } = addressElement as unknown as { target: { value: string } }
    handleValidateAddress(addressValue, '', true)
  }

  const addCryptoAddressRequestPayload = () => {
    const addressArgs: {
      address: string
      protocol?: string
    } = {
      address: address,
      ...(selectedCryptocurrencyProtocols?.length > 1 && {
        protocol: protocol || selectedCryptocurrencyProtocols?.[0]?.code,
      }),
    }

    const data: AddCryptoAddressRequest = {
      name: addressName,
      currencyCode: cryptocurrency,
      address,
      protocol: addressArgs?.protocol,
      ...(destinationTag && { destinationTag }),
      reason: reason,
      authorization: {
        totp: twoFactorCode,
      },
    }

    return data
  }

  const preConfirmAddCryptoAddress = () => {
    if (formObserver.requiresRevalidation) {
      setFormObserver({ ...formObserver, addressIsValidated: false })
      handleValidateAddress(address)
    } else {
      const formHasErrors = !!Object.keys(form.formState?.errors)?.length

      if (
        cryptocurrency &&
        addressName &&
        address &&
        !formHasErrors &&
        formObserver.addressIsValidated
      ) {
        setFormObserver({ ...formObserver, resetState: false, requiresRevalidation: false })
        setWhitelistAddressScreen(AddCryptoAddressScreens.CONFIRM)
      }
    }
  }

  const addCryptoAddressAction: SubmitHandler<AddCryptoAddressFormData> = async () => {
    whitelistAddressScreen === AddCryptoAddressScreens.CONFIRM &&
      mutateAddCrypto(addCryptoAddressRequestPayload(), {
        onSuccess: () => {
          clearErrors()
          setFormObserver({ ...formObserver, backClickState: false })
          setWhitelistAddressScreen(AddCryptoAddressScreens.SUCCESS)
          track.Amp.track(AmplitudeEvent.EOF_ADD_CRYPTO_ADDRESS_WHITELIST_SUCCESS, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.VIEW,
          })
        },
        onError: (error: BaseErrorResponse) => {
          if (error?.status === INVALID_CODE_RESPONSE) {
            setTwoFactorCodeError(t('invalidCode'))
            setWhitelistAddressError(error?.errorList?.[0]?.message || t('oopsSomethingWentWrong'))
          } else if (error?.status === 400) {
            setWhitelistAddressError(
              `${error?.errorList?.[0]?.message || t('oopsSomethingWentWrong')}`
            )
          } else {
            logSentryError('Error from AddCryptoAddressModal - addCryptoAddressAction ', error)
            // TODO: update this error message to something more descriptive
            setWhitelistAddressError(t('oopsSomethingWentWrong'))
          }
        },
      })
  }

  const modalActions: ButtonProps[] = [
    { children: t('cancel'), onClick: closeModal, secondary: true },
    {
      children:
        (whitelistAddressScreen === AddCryptoAddressScreens.FORM && t('Add')) ||
        (whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS && t('Done')),

      type: 'submit',

      onClick:
        (whitelistAddressScreen === AddCryptoAddressScreens.FORM && preConfirmAddCryptoAddress) ||
        (whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS && onAddCryptoSuccess),

      // disable initial form Add button if required fields aren't present
      ...(whitelistAddressScreen === AddCryptoAddressScreens.FORM &&
        (!cryptocurrency ||
          !addressName ||
          !formObserver.addressIsValidated ||
          addressValidating) && {
          disabled: true,
        }),
      // enable initial form Add button if required fields and addressIsValidated
      ...(whitelistAddressScreen === AddCryptoAddressScreens.FORM &&
        cryptocurrency &&
        addressName &&
        formObserver.addressIsValidated && {
          disabled: false,
        }),

      loading: whitelistAddressScreen === AddCryptoAddressScreens.FORM && addressValidating,
    },
  ]

  // make SUCCESS screen's secondary button "add another" btn and reset form
  whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS &&
    modalActions.unshift({
      children: t('Add another'),
      type: 'button',
      secondary: true,
      onClick: () => {
        clearErrors()
        setTwoFactorCodeError('')
        setWhitelistAddressError('')
        resetCryptoTable()
        form.reset()
        setWhitelistAddressScreen(AddCryptoAddressScreens.FORM)
        setFormObserver({ ...formObserver, resetState: true })
        setFormObserver({ ...formObserver, addressIsValidated: false })
      },
    })

  return (
    <Modal visible closeModal={closeModal}>
      <Form
        testid="add-crypto-address-form"
        tag="form"
        onSubmit={handleSubmit(addCryptoAddressAction)}
      >
        {whitelistAddressScreen !== AddCryptoAddressScreens.SUCCESS && (
          <ModalNavBar
            title={
              (whitelistAddressScreen === AddCryptoAddressScreens.FORM &&
                t('manageCryptoAddresses.modal.addTitle')) ||
              (whitelistAddressScreen === AddCryptoAddressScreens.CONFIRM &&
                t('manageCryptoAddresses.modal.confirmTitle'))
            }
            onClose={closeModal}
            onBack={
              whitelistAddressScreen === AddCryptoAddressScreens.CONFIRM &&
              (() => {
                clearErrors()
                setWhitelistAddressError('')
                setTwoFactorCodeError('')
                setFormObserver({
                  ...formObserver,
                  backClickState: true,
                  requiresRevalidation: true,
                })
                setWhitelistAddressScreen(AddCryptoAddressScreens.FORM)
              })
            }
          />
        )}
        <ModalScrollable
          {...(whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS && {
            hasBottomPadding: false,
          })}
        >
          {/* ADD CRYPTO ADDRESS STEP */}
          {whitelistAddressScreen === AddCryptoAddressScreens.FORM && (
            <StepAddCryptoAddressForm
              formObserver={formObserver}
              setFormObserver={setFormObserver}
              form={form}
              cryptocurrency={cryptocurrency}
              address={address}
              getCryptoCurrencies={getCryptoCurrencies}
              isLoadingCryptocurrecies={isLoadingCryptocurrecies}
              radioProtocolProps={radioProtocolProps}
              handleValidateAddress={handleValidateAddress}
              handlePasteAddress={handlePasteAddress}
            />
          )}
          {/* CONFIRM CRYPTO ADDRESS STEP */}
          {whitelistAddressScreen === AddCryptoAddressScreens.CONFIRM && (
            <StepConfirmCryptoAddressForm
              confirmStepData={{
                cryptocurrency,
                protocol,
                selectedCryptocurrencyProtocols,
                addressName,
                address,
                destinationTag,
                reason,
              }}
              confirmStepUtils={{
                twoFactorCode,
                twoFactorCodeError,
                handleTwoFactorCodeChange,
                addCryptoAddressAction,
                addCryptoAddressIsProcessing,
                whitelistAddressError,
              }}
            />
          )}
          {/* SUCCESS STEP */}
          {whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS && (
            <StatusCard
              status="success"
              headline={t('manageCryptoAddresses.modal.cryptoAddressAdded')}
              description={t('manageCryptoAddresses.modal.cryptoAddressAddedDescription')}
              borderless
            />
          )}
        </ModalScrollable>
        {/* MODAL ACTIONS IS NOT NEEDED ON CONFIRM SCREEN */}
        {(whitelistAddressScreen === AddCryptoAddressScreens.FORM ||
          whitelistAddressScreen === AddCryptoAddressScreens.SUCCESS) && (
          <ModalActions actions={modalActions} alignActions={AlignActions.RIGHT} />
        )}
      </Form>
    </Modal>
  )
}

export default AddCryptoAddressModal
