import React from 'react'
import Icon from '@node-space/storybook-components/dist/Icon'
import { CSATFeedbackValue, CSATFeedbackValueType } from './types'

export type CSATIconType = {
  size?: number
}

export type CSATIconProps = CSATIconType & {
  name: CSATFeedbackValueType
  selected: boolean
}

export const CSATIcon = ({ name, selected }: CSATIconProps) => {
  if (name === CSATFeedbackValue.BAD)
    return selected ? <Icon name="BadIconSelected" /> : <Icon name="BadIcon" />
  if (name === CSATFeedbackValue.OKAY)
    return selected ? <Icon name="OkayIconSelected" /> : <Icon name="OkayIcon" />
  return selected ? <Icon name="GoodIconSelected" /> : <Icon name="GoodIcon" />
}
