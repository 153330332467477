import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TwoFAModal from 'components/modals/TwoFAModal/TwoFAModal'
import { ApiKeyCreateResponse, ApiKeyRequestForm } from 'types/apiKeys'
import { ApiKeyModalContext } from '../../context/ApiKeyModalProvider'
import ApiKey2FAModal from './ApiKey2FAModal'
import ApiKeyCreateModal from './ApiKeyCreateModal'
import ApiKeySuccessModal from './ApiKeySuccessModal'

const ApiKeyModal = () => {
  const { t } = useTranslation()

  const [payload, setPayload] = useState<ApiKeyRequestForm>()
  const [response, setResponse] = useState<ApiKeyCreateResponse>()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { setShowCreateModal, show2FAModal, setShow2FAModal, setFrom2FA } =
    useContext(ApiKeyModalContext)

  const onSuccessCreateApiKey = useCallback(
    (payload: ApiKeyRequestForm) => {
      setShowCreateModal(false)
      setFrom2FA(false)
      setShow2FAModal(true)
      setPayload(payload)
    },
    [setShowCreateModal, setFrom2FA, setShow2FAModal, setPayload]
  )

  const onBack2FAApiKey = useCallback(() => {
    setShow2FAModal(false)
    setShowCreateModal(true)
  }, [])

  const onSuccess2FAApiKey = useCallback((response: ApiKeyCreateResponse) => {
    setResponse(response)
    setShow2FAModal(false)
    setShowSuccessModal(true)
  }, [])

  return (
    <>
      {show2FAModal && (
        <TwoFAModal
          notice={{
            navTitle: t('manageApiKeys.generateNewAPIKey'),
            heading: t('2FAEnable'),
            description: t('2FAEnableNotice'),
          }}
          onComplete={() => {
            setFrom2FA(true)
            setShowCreateModal(true)
          }}
        />
      )}

      {show2FAModal && payload && (
        <ApiKey2FAModal
          setShowSuccessModal={onSuccess2FAApiKey}
          onBack={onBack2FAApiKey}
          payload={payload}
        />
      )}

      {showSuccessModal && (
        <ApiKeySuccessModal response={response} setShowSuccessModal={setShowSuccessModal} />
      )}

      {setShowCreateModal && <ApiKeyCreateModal onSuccessCreateApiKey={onSuccessCreateApiKey} />}
    </>
  )
}

export default ApiKeyModal
