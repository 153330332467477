import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { createReferenceSchema } from 'utils/yupSchemas/createReferenceSchema'
import { PayForm, PaySteps } from '../components/Pay/Pay'

const paySchema = ({ t, step }: { t: TFunction<'translation', undefined>; step: PaySteps }) => {
  const firstName = yup.string().trim()

  const lastName = yup.string().trim()
  const businessName = yup.string().trim()
  const countryCode = yup.string().trim()
  const amount = yup.number()
  const reference = createReferenceSchema(t('beneficiaryPaymentReference'), t, true)
  const challenge = yup.string()
  // EUR
  const iban = yup.string().trim()
  const bic = yup.string().trim()

  // GBP
  const accountNumber = yup.string()
  const sortCode = yup.string()

  // NGN
  const ngnAccountNumber = yup.string()
  const bankCode = yup.string()

  // USD
  const usdAccountNumber = yup.string()
  const abaRoutingNumber = yup.string()

  const password = yup.string().trim()
  const transferDestination = yup.string().trim()
  const formFields = {
    firstName,
    lastName,
    businessName,
    countryCode,
    reference,
    transferDestination,
    challenge,
    // EUR
    iban,
    bic,
    // GBP
    accountNumber,
    sortCode,
    // NGN
    ngnAccountNumber,
    bankCode,
    // USD
    usdAccountNumber,
    abaRoutingNumber,
  }
  const amountFields = { amount }
  const confirmFields = { password }

  return yup.object<Partial<Record<keyof PayForm, yup.AnySchema>>>(
    step === 'form' ? formFields : step === 'amount' ? amountFields : confirmFields
  )
}

export default paySchema
