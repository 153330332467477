import { BasisOfApprovalParam, UnapprovableTransactions } from 'types/approvals'

export const useCheckUnapprovableTransactions = (
  checkedRowData: unknown[],
  currentEmailAddress: string
) => {
  const collection = checkedRowData?.reduce(
    (acc: UnapprovableTransactions, rowData: BasisOfApprovalParam) => {
      if (rowData?.initiatedByEmail === currentEmailAddress)
        acc.selfInitiatedTransactions.push(rowData)
      if (rowData?.canApprove === false) acc.cannotApproveTransactions.push(rowData)
      return acc
    },
    { selfInitiatedTransactions: [], cannotApproveTransactions: [] }
  ) as UnapprovableTransactions

  return collection
}
