import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour, secondaryButtonIconColour } from 'styles/theme/colours'
import { CopyButton, Icon, Box as Row, Box as TextRow } from '@node-space/storybook-components'
import { ellipseMiddleText } from '@node-space/utils'
import { SpecialPayments } from 'types/payments'
import { DepositDetails, Transaction } from 'types/transaction'
import { setPaymentDescription } from 'utils/transactionText'
import { getTransactionReference } from 'utils/utils'
import { TransactionDetailRow } from './TransactionDetailRow'

interface TransactionDetailsProps {
  transaction: Transaction
  title: string
}

export const TransactionDetails = ({ transaction, title }: TransactionDetailsProps) => {
  const { t } = useTranslation()
  const transactionReference = getTransactionReference(transaction)
  const depositDetails: DepositDetails = transaction?.context?.depositDetails
  const withdrawalDetails = transaction?.context?.withdrawalDetails

  const getExternalLink = (): string => {
    return `${transaction?.wallet.currency?.options?.transaction?.replace('{{hash}}', '')}${
      transaction?.context.hash
    }`
  }
  return (
    <div className="border border-gray-03 bg-gray-01 px-4 py-1">
      {depositDetails?.senderAccountNumber && (
        <TransactionDetailRow label={t('walletsPage.fromBankAccountNo')}>
          <p>{depositDetails?.senderAccountNumber}</p>
        </TransactionDetailRow>
      )}
      {depositDetails?.senderBankCode && (
        <TransactionDetailRow label={t('walletsPage.fromBankCode')}>
          <p>{depositDetails?.senderBankCode}</p>
        </TransactionDetailRow>
      )}
      {depositDetails?.senderName && (
        <TransactionDetailRow label={t('walletsPage.receivedFrom')}>
          <p>{depositDetails?.senderName}</p>
        </TransactionDetailRow>
      )}
      {!!withdrawalDetails?.beneficiary?.accountNumber && (
        <TransactionDetailRow label={t('accountNumber')}>
          <p>{withdrawalDetails?.beneficiary?.accountNumber}</p>
        </TransactionDetailRow>
      )}
      {!!withdrawalDetails?.beneficiary?.bankName && (
        <TransactionDetailRow label={t('bankName')}>
          <p>{withdrawalDetails?.beneficiary?.bankName}</p>
        </TransactionDetailRow>
      )}
      {!!withdrawalDetails?.beneficiary?.bankCode && (
        <TransactionDetailRow label={t('bankCode')}>
          <p>{withdrawalDetails?.beneficiary?.bankCode}</p>
        </TransactionDetailRow>
      )}
      {!!withdrawalDetails?.beneficiary?.name && (
        <TransactionDetailRow label={t('beneficiaryName')}>
          <p>{withdrawalDetails?.beneficiary?.name}</p>
        </TransactionDetailRow>
      )}
      {transaction?.id && (
        <TransactionDetailRow label={t('id')}>
          <p>{transaction.id}</p>
        </TransactionDetailRow>
      )}
      {transaction?.dateCreated && (
        <TransactionDetailRow label={t('date')}>
          <p>{dayjs(transaction.dateCreated).format('DD MMMM YYYY @ HH:mm')}</p>
        </TransactionDetailRow>
      )}
      {transaction?.context?.hash && (
        <>
          <TransactionDetailRow label={t('hash')}>
            {!transaction.context.hash.includes('offChain') ? (
              <Row flex flexItemsCenter gap={8}>
                <TextRow>{ellipseMiddleText(transaction.context.hash, 20, 10)}</TextRow>
                <CopyButton
                  copyText={transaction.context.hash}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                />
              </Row>
            ) : (
              <p>{t('offChain')}</p>
            )}
          </TransactionDetailRow>
          {!transaction.context.hash.includes('offChain') &&
            transaction.wallet?.currency?.options?.transaction && (
              <TransactionDetailRow label={t('explore')}>
                <a
                  className="cursor-pointer text-primary flex  items-center gap-2"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="exploreLink"
                  href={getExternalLink()}
                >
                  <span>{t('externalLink')}</span>
                  <span>
                    <Icon name="ExternalLinkIcon" color={secondaryButtonIconColour} />
                  </span>
                </a>
              </TransactionDetailRow>
            )}
        </>
      )}
      {transaction?.type && (
        <TransactionDetailRow label={t('description')}>
          <p>{title || setPaymentDescription(transaction)}</p>
        </TransactionDetailRow>
      )}
      {transaction?.type === SpecialPayments.JOURNAL_ENTRY && transaction?.description && (
        <TransactionDetailRow label={t('reference')}>
          <Row flex flexItemsCenter gap={8}>
            <TextRow tag="p">{ellipseMiddleText(transaction?.description, 20, 10)}</TextRow>
            <CopyButton
              copyText={transaction?.description}
              confirmationMessage={t('copiedToClipboard')}
              iconColour={copyButtonIconColour}
            />
          </Row>
        </TransactionDetailRow>
      )}
      {transactionReference && (
        <TransactionDetailRow label={t('reference')}>
          <Row flex flexItemsCenter gap={8}>
            <TextRow tag="p">{ellipseMiddleText(transactionReference, 20, 10)}</TextRow>
            <CopyButton
              copyText={transactionReference}
              confirmationMessage={t('copiedToClipboard')}
              iconColour={copyButtonIconColour}
            />
          </Row>
        </TransactionDetailRow>
      )}
    </div>
  )
}
