import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Modal, ModalSize } from '@node-space/storybook-components/dist/Modal'
import { PageHeader } from '@node-space/storybook-components/dist/PageHeader'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountUserGroupsQuery } from 'hooks/queries/useAccountUserGroupsQuery'
import { useAccountUsersQuery } from 'hooks/queries/useAccountUsersQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { TeamMemberDelete } from '../components/TeamMemberDelete'
import { TeamMemberEditor } from './components/TeamMemberEditor'
import { TeamMemberTable } from './components/TeamMemberTable'

const DEFAULT_NUMBER_OF_ROWS = '10'

const TeamMembersV2 = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const permissions = useMappedRoles()?.manageTeamMembers
  const manageApproverPermissions = useMappedRoles()?.manageTeamMemberApprovers
  const canEdit = permissions?.canEdit
  const canManageApprover = manageApproverPermissions?.canEdit

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null)

  const {
    profile: { emailAddress },
  } = useProfileContext()

  const { data: accountUsers, isFetching: isFetchingAccountUsers } = useAccountUsersQuery()
  const { data: accountUserGroups, isFetching: isFetchingAccountUserGroups } =
    useAccountUserGroupsQuery()

  const isLoading = useMemo(
    () => isFetchingAccountUserGroups || isFetchingAccountUsers,
    [isFetchingAccountUserGroups, isFetchingAccountUsers]
  )

  const invalidateAccountUsers = () => {
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.accountUsers() })
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.accountUserGroups() })
  }

  const onEditUserClick = user => {
    setSelectedUser(user)
    setEditModalOpen(true)
  }

  const onDeleteUserClick = user => {
    setSelectedUserToDelete(user)
    setDeleteModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    if (!!selectedUser) {
      setSelectedUser(null)
    }
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    if (!!selectedUserToDelete) {
      setSelectedUserToDelete(null)
    }
  }

  return (
    <>
      <PageHeader
        description={canEdit ? t('teamMemberSubParagraphEdit') : t('teamMemberSubParagraphView')}
        renderRightSection={
          canEdit && (
            <Button
              onClick={() => setEditModalOpen(true)}
              iconElement={<Icon name="PlusIcon" color="white" />}
            >
              {t('addTeamMember')}
            </Button>
          )
        }
      />
      <>
        {/* TODO: implement search - https://coindirectworld.atlassian.net/browse/MXE-1210 */}
        <TeamMemberTable
          accountUsers={accountUsers}
          currentEmailAddress={emailAddress}
          onEditUserClick={onEditUserClick}
          onDeleteUserClick={onDeleteUserClick}
          invalidateAccountUsers={invalidateAccountUsers}
          isFetchingAccountUsers={isLoading}
          defaultNumberOfRows={DEFAULT_NUMBER_OF_ROWS}
          canEdit={canEdit}
          canManageApprover={canManageApprover}
        />
        {/* TODO: add pagination support - https://coindirectworld.atlassian.net/browse/MXE-1210 */}
      </>
      <Modal allowOverFlow visible={deleteModalOpen} closeModal={handleCloseDeleteModal}>
        <TeamMemberDelete
          onDeleteCallback={invalidateAccountUsers}
          userToDelete={selectedUserToDelete}
          handleCloseModal={handleCloseDeleteModal}
        />
      </Modal>
      <Modal
        size={ModalSize.xl}
        allowOverFlow
        visible={editModalOpen}
        closeModal={handleCloseEditModal}
      >
        <TeamMemberEditor
          handleCloseModal={handleCloseEditModal}
          actionName={selectedUser ? t('editTeamMember') : t('createTeamMember')}
          submitBtnText={selectedUser ? t('save') : t('sendTeamMemberInvite')}
          userGroups={accountUserGroups}
          accountUser={selectedUser}
          getValues={invalidateAccountUsers}
          action={selectedUser ? 'EDIT' : 'CREATE'}
          canEdit={canEdit}
          canManageApprover={canManageApprover}
        />
      </Modal>
    </>
  )
}

export default TeamMembersV2
