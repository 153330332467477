import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAccountUserGroups } from 'services/AccountService'

export const useAccountUserGroupsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.accountUserGroups(),
    queryFn: getAccountUserGroups,
  })
}
