import { TFunction } from 'react-i18next'
import { ConnectionStatusTypes, NetworkConnectionViewTypes } from 'constants/bvnkNetwork'
import {
  BvnkNetworkConnectionsResponse,
  ConnectionStatusType,
  NetworkConnectionViewType,
} from 'types/bvnkNetwork'

const statusFilters = {
  [NetworkConnectionViewTypes.CONNECTIONS_VIEW]: ConnectionStatusTypes.CONNECTED,
  [NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW]: ConnectionStatusTypes.REQUESTED,
  [NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW]: ConnectionStatusTypes.RECEIVED,
}

export const getConnections = (
  connections: BvnkNetworkConnectionsResponse,
  networkConnectionViewType: NetworkConnectionViewType
) => {
  switch (networkConnectionViewType) {
    case NetworkConnectionViewTypes.CONNECTIONS_VIEW:
      return connections?.connected
    case NetworkConnectionViewTypes.SENT_INVITATIONS_VIEW:
      return connections?.requested
    case NetworkConnectionViewTypes.RECEIVED_INVITATIONS_VIEW:
      return connections?.received
    default:
      return
  }
}

export const getStatusFilter = (networkConnectionViewType: NetworkConnectionViewType) => {
  return statusFilters[networkConnectionViewType]
}

export const getConnectionStatusUpdateSuccessMessage = (
  networkPartnerName: string,
  status: ConnectionStatusType,
  t: TFunction<'translation', undefined>
) => {
  const networkMember = { networkMember: networkPartnerName }
  const statusUpdateMessage = {
    [ConnectionStatusTypes.CONNECTED]: t('bvnkNetwork.connectionToMemberAccepted', networkMember),
    [ConnectionStatusTypes.DISCONNECTED]: t('bvnkNetwork.connectionToMemberRemoved', networkMember),
    [ConnectionStatusTypes.REJECTED]: t('bvnkNetwork.connectionToMemberRejected', networkMember),
  }

  const message = statusUpdateMessage[status]

  return message ?? t('bvnkNetwork.successfull')
}
