import { capitalize } from '@node-space/utils'
import { Transaction } from '../types/transaction'
import { startCase } from 'lodash-es'

/**
 * Use the type if there isn't a subType
 * @param data
 * @returns
 */
const setPaymentType = (data: Transaction) => {
  const subType = data?.context?.subType
  if (subType) {
    return data?.context?.subType
  }
  return data.type
}

/**
 * Format the payment description
 * @param data
 * @returns
 */
const setPaymentDescription = (data: Transaction) => {
  const type = data?.context?.subType === 'trade' ? 'trade' : data.type
  switch (type) {
    case 'trade':
      return formatTrade(data)
    case 'payIn':
    case 'paymentUnder':
      if (data.context?.type === 'IN') {
        return formatPayType(data, 'In')
      }
      return formatFallbackType(data.type)
    case 'payOut':
      if (data.context?.type === 'OUT') {
        return formatPayType(data, 'Out')
      }
      return formatFallbackType(data.type)
    default:
      return startCase(data?.type)
  }
}

/**
 * Format the payment text using pairs
 * @param data
 * @param text
 * @returns
 */
const formatPayType = (data: Transaction, text: string) => {
  const toCurrency = data?.context?.toCurrencyCode
  const toCurrencyText = toCurrency ? `/${toCurrency}` : ''
  return `Payment ${text} ${data?.context?.fromCurrencyCode}${toCurrencyText}`
}

/**
 * Format the trade text using pairs
 * @param data
 * @returns
 */
const formatTrade = (data: Transaction) => {
  return `Trade ${data?.context?.fromCurrencyCode}/${data?.context?.toCurrencyCode}`
}

/**
 * Format the fallback text
 * @param type
 * @returns
 */
export const formatFallbackType = (type: string) => {
  switch (type) {
    case 'payOutCancelled':
    case 'payInCancelled':
      return 'Cancelled'
    default:
      return capitalize(type)
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(' ')
  }
}

export { setPaymentType, setPaymentDescription, formatPayType }
