import React, { useCallback, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useKeyPress } from '@node-space/hooks'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import { ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import {
  useUpdateBeneficiaryMutationChallengeRequest,
  useUpdateBeneficiaryMutationPreRequest,
} from 'hooks/mutations/useBeneficiariesMutation'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryName } from 'reactQueryKeys/reactQueryKeys'
import {
  BaseErrorResponse,
  BeneficiaryDetails,
  EditBeneficiaryPostRequestPayload,
  UpdateBeneficiaryFormPayload,
  UpdateBeneficiaryFormPayloadKeys,
} from 'types/beneficiaries'
import { ErrorCodes } from 'types/errorCodes'
import { updateBeneficiarySchema } from '../AddBeneficiary/components/schemas/updateBeneficiarySchema'
import { EditBeneficiaryConfirm } from './components/EditBeneficiaryConfirm'
import { EditBeneficiaryForm } from './components/EditBeneficiaryForm'

export const EditBeneficiaryModalStep = {
  EDIT_BENEFICIARY: 'EDIT_BENEFICIARY',
  CONFIRMATION: 'CONFIRMATION',
} as const

export type EditBeneficiaryModalStep =
  (typeof EditBeneficiaryModalStep)[keyof typeof EditBeneficiaryModalStep]

interface BeneficiaryAliasEditProps {
  setShowEditBeneficiaryModal: (value: React.SetStateAction<boolean>) => void
  setBeneficiaryToUpdate: (value: React.SetStateAction<BeneficiaryDetails>) => void
  showEditBeneficiaryModal: boolean
  errorMessage: string | undefined
  beneficiaryToUpdate: BeneficiaryDetails
}

export const EditBeneficiary = ({
  setShowEditBeneficiaryModal,
  setBeneficiaryToUpdate,
  showEditBeneficiaryModal,
  errorMessage,
  beneficiaryToUpdate,
}: BeneficiaryAliasEditProps) => {
  const { t } = useTranslation()

  const updateFormSchema = updateBeneficiarySchema(t)
  const { control, formState, register, setValue, trigger, clearErrors, watch, setError } =
    useForm<UpdateBeneficiaryFormPayload>({
      resolver: yupResolver(updateFormSchema),
    })

  const formValues = watch()

  const [alias, password] = useWatch({
    control,
    name: ['alias', 'password'],
    defaultValue: {
      alias: beneficiaryToUpdate?.alias,
      password: '',
    },
  })

  const addToast = useToastContext()
  const queryClient = useQueryClient()
  const { mutate: mutatePreRequest, isPending: isLoadingPreRequest } =
    useUpdateBeneficiaryMutationPreRequest()
  const {
    mutate: mutatePostRequest,
    isPending: isLoadingPostRequest,
    isError: isOtpRequestError,
  } = useUpdateBeneficiaryMutationChallengeRequest()
  const [isGenericError, setIsGenericError] = useState(false)
  const [updateBeneficiaryState, setUpdateBeneficiaryState] =
    useState<EditBeneficiaryPostRequestPayload>()
  const [modalStep, setModalStep] = useState<EditBeneficiaryModalStep>(
    EditBeneficiaryModalStep.EDIT_BENEFICIARY
  )

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      testid: 'cancel-beneficiary-update',
      secondary: true,
      onClick: () => {
        clearErrors()
        setBeneficiaryToUpdate(null)
        setShowEditBeneficiaryModal(false)
      },
    },
    {
      children: t('save'),
      testid: 'update-beneficiary',
      onClick: () => onBeneficiaryUpdatePreRequest(),
      loading: isLoadingPreRequest,
      disabled: !alias || alias === beneficiaryToUpdate?.alias,
    },
  ]
  const modalHeader =
    modalStep === EditBeneficiaryModalStep.EDIT_BENEFICIARY
      ? t('manageBeneficiaries.editBeneficiary')
      : t('confirmBeneficiaryDetails')

  const handleOnchange = (field: UpdateBeneficiaryFormPayloadKeys, value: string) => {
    setValue(field, value)
  }

  const orchestratedPreRequest = () => {
    const payload = { ...beneficiaryToUpdate, alias }
    delete payload?.transferType
    delete payload?.bvnkAccountReference
    return payload
  }

  const onBeneficiaryUpdatePreRequest = async () => {
    const isValidDetails = await trigger(['alias'])

    if (isValidDetails) {
      clearErrors()
      setIsGenericError(false)
      const updateBeneficiaryPayload = orchestratedPreRequest()
      mutatePreRequest(updateBeneficiaryPayload, {
        onError: (error: BaseErrorResponse) => {
          const aliasAlreadyinUseError = 'already in use for this account'
          const isAliasError =
            error?.data?.details?.errors?.requestBody[0]?.includes(aliasAlreadyinUseError)
          const bvknErrorCode = error?.data?.code
          if (error?.status === 428) {
            setUpdateBeneficiaryState({
              ...updateBeneficiaryState,
              challenge: error?.data?.challenge?.value,
            })
            setModalStep(EditBeneficiaryModalStep.CONFIRMATION)
          } else if (bvknErrorCode === ErrorCodes?.BENEFICIARY_MANAGEMENT_ERROR && isAliasError) {
            setError('alias', { message: t('aliasError') })
          } else {
            setIsGenericError(true)
          }
        },
      })
    }
  }

  const onBeneficiaryUpdatePostRequest = async () => {
    const isValidDetails = await trigger(['password'])

    if (isValidDetails) {
      clearErrors()
      setIsGenericError(false)
      const otpChallengeRequest = {
        challenge: updateBeneficiaryState?.challenge,
        tokenResponse: formValues?.password,
      }

      mutatePostRequest(otpChallengeRequest, {
        onSuccess: async () => {
          setShowEditBeneficiaryModal(false)
          await queryClient.invalidateQueries({ queryKey: [reactQueryName.BENEFICIARIES] })
          setBeneficiaryToUpdate(null)
          addToast({
            title: t('manageBeneficiaries.beneficiaryNameUpdated'),
            state: 'success',
            delay: 0,
          })
        },
        onError: (error: BaseErrorResponse) => {
          const bvknErrorStatus = error?.status
          if (bvknErrorStatus === 403) {
            setError('password', { message: t('incorrectOtpCode') })
          } else {
            setIsGenericError(true)
          }
        },
      })
    }
  }

  const onKeyPressEnter = useCallback(() => {
    if (!showEditBeneficiaryModal) return

    if (alias && alias !== beneficiaryToUpdate?.alias) {
      onBeneficiaryUpdatePreRequest()
    }
  }, [showEditBeneficiaryModal, beneficiaryToUpdate, alias])

  const onKeyPressEscape = useCallback(() => {
    if (!showEditBeneficiaryModal) return

    setShowEditBeneficiaryModal(false)
  }, [showEditBeneficiaryModal])

  useKeyPress('Enter', onKeyPressEnter)
  useKeyPress('Escape', onKeyPressEscape)

  const onBackHandler = () => {
    switch (modalStep) {
      case EditBeneficiaryModalStep.EDIT_BENEFICIARY:
        setShowEditBeneficiaryModal(false)
        break
      case EditBeneficiaryModalStep.CONFIRMATION:
        setModalStep(EditBeneficiaryModalStep.EDIT_BENEFICIARY)
        break
      default:
        setShowEditBeneficiaryModal(false)
    }
  }

  const onEditSubmit = () => {
    onBeneficiaryUpdatePostRequest()
  }

  const renderStep = () => {
    if (modalStep === EditBeneficiaryModalStep.EDIT_BENEFICIARY) {
      return (
        <EditBeneficiaryForm
          alias={alias}
          errorMessage={errorMessage}
          errors={formState?.errors}
          isGenericError={isGenericError}
          handleOnchange={handleOnchange}
          register={register}
        />
      )
    }

    if (modalStep === EditBeneficiaryModalStep.CONFIRMATION) {
      return (
        <EditBeneficiaryConfirm
          beneficiaryUpdatedDetails={formValues}
          isOtpRequestError={isOtpRequestError}
          isLoading={isLoadingPostRequest}
          password={password}
          onEditSubmit={onEditSubmit}
          register={register}
          setValue={setValue}
        />
      )
    }
  }

  return (
    <>
      <ModalNavBar
        title={modalHeader}
        onClose={() => setShowEditBeneficiaryModal(false)}
        onBack={onBackHandler}
      />
      {renderStep()}
      {isGenericError && (
        <Box flex justifyContent="center" paddingX={20} paddingB={20}>
          <Callout state="error" message={t('manageBeneficiaries.oopsSomethingWentWrong')} />
        </Box>
      )}
      {modalStep === EditBeneficiaryModalStep.EDIT_BENEFICIARY && (
        <ModalActions actions={actions} />
      )}
    </>
  )
}
