import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import Input from '@node-space/storybook-components/dist/Input'
import { Modal, ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { useAccountPreferenceMutation } from 'hooks/mutations/useAccountPreferenceMutation'

interface EditCompanyProfileProps {
  description: string
  isOpen: boolean
  logo: string
  onEditError: () => void
  onEditSuccess: () => void
  onModalClose: () => void
}

const EditCompanyProfile = ({
  description,
  isOpen,
  logo,
  onEditError,
  onEditSuccess,
  onModalClose,
}: EditCompanyProfileProps) => {
  const { t } = useTranslation()
  const { mutate: updateCompanyInfoPreference, isPending: isUpdatingCompanyInfo } =
    useAccountPreferenceMutation<string>()
  const [companyInfoDescription, setCompanyInfoDescription] = useState(description)

  const onCompanyInfoUpdate = async () => {
    updateCompanyInfoPreference(
      { preferenceKey: 'companyDescription', value: companyInfoDescription },
      {
        onSuccess: () => {
          onEditSuccess?.()
        },
        onError: () => {
          onEditError?.()
        },
      }
    )
  }

  const actions: ButtonProps[] = [
    {
      children: t('bvnkNetwork.saveChanges'),
      disabled: companyInfoDescription === description || !companyInfoDescription,
      loading: isUpdatingCompanyInfo,
      testid: 'save-company-info',
      onClick: onCompanyInfoUpdate,
    },
  ]

  const onCompanyDescriptionChange = (value: string) => {
    setCompanyInfoDescription(value)
  }

  return (
    <Modal testid="edit-company-info-modal" visible={isOpen} closeModal={onModalClose}>
      <ModalNavBar title={t('bvnkNetwork.editCompanyInfo')} onClose={onModalClose} />
      <Box flex direction="col" gapY={20} padding={24}>
        <Input
          onChange={e => onCompanyDescriptionChange(e?.target?.value)}
          label={t('bvnkNetwork.companyDescription')}
          inputMode="text"
          name="companyDescription"
          value={companyInfoDescription}
        />
        {/* TODO: Implement logo upload */}
        <Callout state="info" message={t('bvnkNetwork.editCompanyNameCallout')} />
      </Box>

      <ModalActions actions={actions} />
    </Modal>
  )
}

export default EditCompanyProfile
