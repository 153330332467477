import React, { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtomValue } from 'jotai'
import { trimStart } from 'lodash-es'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import Input from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { Tabs } from '@node-space/storybook-components/dist/Tabs'
import { RequestError } from 'components/Errors/RequestError'
import { useBanksQuery } from 'hooks/queries/useBanks'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useIbanValidation } from 'hooks/queries/useIbanValidation'
import { useScanValidation } from 'hooks/queries/useScanValidation'
import { useSouthAfricanBanks } from 'hooks/queries/useSouthAfricanBanks'
import { useAddBeneficiaryContext } from 'hooks/useAddBeneficiaryContext'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import {
  AddBeneficiaryBasicPayload,
  AddBeneficiaryBasicPayloadKeys,
  AddBeneficiaryFormPayload,
  BeneficiaryDetailsCurrency,
  BeneficiaryEntityType,
  BeneficiaryTransferDestination,
  BeneficiaryType,
} from 'types/beneficiaries'
import { CountryCodes, Currencies, SelectOption } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import {
  getFieldsToValidateForCurrency,
  getIsGbpAccountDetialsValid,
  resetCurrencySpecificFields,
} from 'utils/beneficiaries'
import track from 'utils/tracker'
import { titleCase } from 'utils/utils'
import { usdBeneficiaryBankTypeAtom } from '../atoms/beneficiaryAtoms'
import { BeneficiaryAddressFields } from './BeneficiaryAddress/BeneficiaryAddress'
import { BusinessBeneficiaryFields } from './BeneficiaryEntityType/Business'
import { IndividualBeneficiaryFields } from './BeneficiaryEntityType/Individual'
import { BeneficaryTypeCheckbox } from './BeneficiaryTypeCheckbox'
import { EurFormFields } from './Currencies/EurFormFields'
import { GbpFormFields } from './Currencies/GbpFormFields'
import { NgnFormFields } from './Currencies/NgnFormFields'
import { UsdFormFields } from './Currencies/UsdFormFields'
import { ZarFormFields } from './Currencies/ZarFormFields'
import { addBeneficiaryDetailsSchema } from './schemas/addBeneficiaryDetailsSchema'

type AddBeneficiaryFormProps = {
  transferDestination: BeneficiaryTransferDestination
  isSubmitting: boolean
  onBack: () => void
  onContinue: (bankDetails: AddBeneficiaryBasicPayload) => void
}

export const AddBeneficiaryForm = ({
  transferDestination,
  isSubmitting,
  onBack,
  onContinue,
}: AddBeneficiaryFormProps) => {
  const { t } = useTranslation()
  const {
    enableBeneficiaryIndividual,
    enableBeneficiaryConsolidation,
    enableUseMigratedPaymentMethods,
    enableCbitBeneficiaryCreation,
  } = useFeatureFlags()
  const { beneficiaryFormState, beneficiaryFormErrors, setBeneficiaryFormErrors } =
    useAddBeneficiaryContext()
  const {
    data: countries,
    isPending: isCountriesLoading,
    isError: isCountriesLoadingError,
  } = useCountriesQuery(true)
  const [activeTab, setActiveTab] = useState<BeneficiaryEntityType>(
    beneficiaryFormState?.beneficiaryEntityType ?? BeneficiaryEntityType.BUSINESS
  )
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const beneficiaryBankType = useAtomValue(usdBeneficiaryBankTypeAtom)

  const formSchema = addBeneficiaryDetailsSchema(t)
  const {
    control,
    formState,
    register,
    setValue,
    resetField,
    trigger,
    clearErrors,
    watch,
    setError,
  } = useForm<AddBeneficiaryFormPayload>({
    resolver: yupResolver(formSchema),
  })

  const formValues = watch()

  const [
    alias,
    firstName,
    lastName,
    dateOfBirth,
    businessName,
    countryCode,
    currencyCode,
    beneficiaryEntityType,
    beneficiaryType,
    beneficiaryPaymentReference,
    // International beneficiary address
    addressLine1,
    addressLine2,
    city,
    postCode,
    region,
    // EUR
    iban,
    bic,
    // GBP
    accountNumber,
    sortCode,
    // NGN
    ngnAccountNumber,
    bankCode,
    // USD
    usdAccountNumber,
    abaRoutingNumber,
    cbitAddress,
    cbitBankCode,
    // ZAR
    zarAccountNumber,
    zarAccountType,
    zarRoutingNumberBranchCode,
  ] = useWatch({
    control,
    name: [
      'alias',
      'firstName',
      'lastName',
      'dateOfBirth',
      'businessName',
      'countryCode',
      'currencyCode',
      'beneficiaryEntityType',
      'beneficiaryType',
      'beneficiaryPaymentReference',
      // International beneficiary address
      'addressLine1',
      'addressLine2',
      'city',
      'postCode',
      'region',
      // EUR
      'iban',
      'bic',
      // GBP
      'accountNumber',
      'sortCode',
      // NGN
      'ngnAccountNumber',
      'bankCode',
      // USD
      'usdAccountNumber',
      'abaRoutingNumber',
      'cbitAddress',
      'cbitBankCode',
      // ZAR
      'zarAccountNumber',
      'zarAccountType',
      'zarRoutingNumberBranchCode',
    ],
    defaultValue: {
      alias: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      businessName: '',
      countryCode: '',
      currencyCode: '',
      beneficiaryEntityType: BeneficiaryEntityType.BUSINESS,
      beneficiaryType: BeneficiaryType.THIRD_PARTY,
      beneficiaryPaymentReference: '',
      // International beneficiary address
      addressLine1: '',
      addressLine2: '',
      city: '',
      postCode: '',
      region: '',
      // EUR
      iban: '',
      bic: '',
      // GBP
      accountNumber: '',
      sortCode: '',
      // NGN
      ngnAccountNumber: '',
      bankCode: '',
      // USD
      usdAccountNumber: '',
      abaRoutingNumber: '',
      cbitAddress: '',
      cbitBankCode: '',
      // ZAR
      zarAccountNumber: '',
      zarAccountType: '',
      zarRoutingNumberBranchCode: '',
    },
  })

  const isFirstPartyBeneficiaryEnabled =
    (enableUseMigratedPaymentMethods || enableBeneficiaryConsolidation) &&
    beneficiaryEntityType === BeneficiaryEntityType.BUSINESS
  const isLocalBeneficiary = transferDestination === BeneficiaryTransferDestination?.LOCAL
  const isInternationalBeneficiary =
    transferDestination === BeneficiaryTransferDestination?.INTERNATIONAL
  const isFirstPartyBeneficiary = beneficiaryType === BeneficiaryType.FIRST_PARTY
  const isFirstPartyBeneficiaryDisabled = currencyCode === BeneficiaryDetailsCurrency.ZAR

  const availableCurrencies = useMemo(() => {
    const beneficiaryAccountCurrencies: BeneficiaryDetailsCurrency[] = [
      BeneficiaryDetailsCurrency.EUR,
      BeneficiaryDetailsCurrency.GBP,
    ]

    if (activeTab === BeneficiaryEntityType.BUSINESS && enableCbitBeneficiaryCreation) {
      beneficiaryAccountCurrencies.push(BeneficiaryDetailsCurrency.USD)
    }

    if (
      isInternationalBeneficiary &&
      !beneficiaryAccountCurrencies.includes(BeneficiaryDetailsCurrency.USD)
    ) {
      beneficiaryAccountCurrencies.push(BeneficiaryDetailsCurrency.USD)
    }

    // Temporarily remove ZAR from local beneficiary creation

    // if (
    //   isLocalBeneficiary &&
    //   !beneficiaryAccountCurrencies.includes(BeneficiaryDetailsCurrency.ZAR)
    // ) {
    //   beneficiaryAccountCurrencies.push(BeneficiaryDetailsCurrency.ZAR)
    // }

    return beneficiaryAccountCurrencies.map(currency => ({
      icon: currency.toLowerCase(),
      label: currency,
      value: currency,
      secondLabel: '',
    }))
  }, [transferDestination, activeTab, enableCbitBeneficiaryCreation])

  const { bankList, isBankListError, isFetchingBankList } = useBanksQuery(
    CountryCodes.NG,
    Currencies.NGN,
    setRequestError,
    currencyCode === Currencies.NGN
  )

  const {
    data: ibanBankDetails,
    isFetching: isValidatingIban,
    refetch: validateIban,
  } = useIbanValidation(iban, 'IBAN', setRequestError, false)

  const {
    data: scanBankDetails,
    isFetching: isValidatingScan,
    refetch: validateScan,
  } = useScanValidation(sortCode, accountNumber, setRequestError, false)

  const {
    data: southAfricanBanks,
    isFetching: isFetchingSouthAfricanBanks,
    isError: isSouthAfricanBanksError,
  } = useSouthAfricanBanks(setRequestError, currencyCode === Currencies.ZAR)

  const ibanSupportedPaymentMethods = ibanBankDetails?.supportedPaymentMethods
  const scanSupportedPaymentMethods = scanBankDetails?.supportedPaymentMethods

  const isSepaSupported =
    ibanSupportedPaymentMethods?.includes('SEPA_CT') ||
    ibanSupportedPaymentMethods?.includes('SEPA_INST')

  const isFPSandChapsSupported =
    scanSupportedPaymentMethods?.includes('CHAPS') ||
    scanSupportedPaymentMethods?.includes('BACS') ||
    scanSupportedPaymentMethods?.includes('FASTER_PAYMENT') ||
    scanSupportedPaymentMethods?.includes('CCC_PAYMENTS')

  useEffect(() => {
    if (ibanBankDetails?.bankAccount?.bankCode && isSepaSupported) {
      handleChange('bic', ibanBankDetails?.bankAccount?.bankCode)
    } else {
      handleChange('bic', '')
    }
  }, [ibanBankDetails?.bankAccount?.bankCode])

  useEffect(() => {
    setValue('beneficiaryEntityType', BeneficiaryEntityType.BUSINESS)
    setCachedValues()
    track.Amp.track(AmplitudeEvent.ADD_BENEFICIARY_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      transferDestination: transferDestination?.toLowerCase(),
      entityType: BeneficiaryEntityType.BUSINESS.toLowerCase(),
    })
  }, [])

  useEffect(() => {
    if (ibanBankDetails?.validationFailed?.length >= 1) {
      setBeneficiaryFormErrors({
        ...beneficiaryFormErrors,
        iban: { message: t('invalidIbanErrorMessage') },
      })
    } else if (!isSepaSupported && iban) {
      setBeneficiaryFormErrors({
        ...beneficiaryFormErrors,
        iban: { message: t('reachabilityCheckErrorMessage') },
      })
      clearErrors()
    }
  }, [ibanBankDetails])

  useEffect(() => {
    if (scanBankDetails && !isFPSandChapsSupported) {
      setBeneficiaryFormErrors({
        ...beneficiaryFormErrors,
        accountNumber: { message: t('reachabilityCheckErrorMessage') },
      })
    }
  }, [scanBankDetails])

  useEffect(() => {
    if (beneficiaryFormErrors) {
      Object.keys(beneficiaryFormErrors).forEach((field: AddBeneficiaryBasicPayloadKeys) => {
        setError(field, beneficiaryFormErrors[field])
      })
    } else {
      clearErrors()
    }
  }, [setError, clearErrors, beneficiaryFormErrors])

  useEffect(() => {
    if (currencyCode === Currencies.ZAR) {
      setValue('beneficiaryType', BeneficiaryType.FIRST_PARTY)
    } else {
      setValue('beneficiaryType', BeneficiaryType.THIRD_PARTY)
    }
  }, [currencyCode])

  const isInValidEurDetails =
    ibanSupportedPaymentMethods &&
    !isSepaSupported &&
    isLocalBeneficiary &&
    currencyCode === Currencies.EUR

  const isInvalidForm =
    isBankListError || isSouthAfricanBanksError || isCountriesLoadingError || isInValidEurDetails

  useEffect(() => {
    if (
      !isBankListError &&
      !isSouthAfricanBanksError &&
      !isCountriesLoadingError &&
      !isInValidEurDetails
    ) {
      resetRequestError()
    }
  }, [isBankListError, isSouthAfricanBanksError, isCountriesLoadingError, isInValidEurDetails])

  const setCachedValues = () => {
    Object.keys(beneficiaryFormState).forEach((field: keyof AddBeneficiaryBasicPayload) => {
      if (beneficiaryFormState[field]) {
        setValue(field, beneficiaryFormState[field])
      }
    })
  }

  const handleContinue = async () => {
    resetRequestError()
    const validateFields = getFieldsToValidateForCurrency(
      currencyCode,
      beneficiaryEntityType,
      transferDestination,
      formValues?.beneficiaryPaymentReference,
      beneficiaryBankType
    )
    if (formState?.errors?.iban?.message === t('invalidIbanErrorMessage')) {
      clearErrors(validateFields)
      await trigger(validateFields)
    } else {
      validateFields.push('iban')
      clearErrors()
    }
    resetCurrencySpecificFields(currencyCode, resetField, beneficiaryBankType)

    const verifyFields = await trigger(validateFields)

    if (verifyFields) {
      if (
        currencyCode === Currencies.GBP &&
        transferDestination === BeneficiaryTransferDestination.LOCAL
      ) {
        const scanValidationResponse = await validateScan()
        const isValid = getIsGbpAccountDetialsValid(scanValidationResponse?.data)
        const isSortCodeValid = isValid?.sortCode
        const isAccountNumberValid = isValid?.accountNumber

        if (!isSortCodeValid || !isAccountNumberValid) {
          !isSortCodeValid && setError('sortCode', { message: t('invalidSortCodeErrorMessage') })
          !isAccountNumberValid &&
            setError('accountNumber', { message: t('invalidAccountNumberErrorMessage') })
        } else {
          onContinue(formValues)
        }
      } else {
        onContinue(formValues)
      }
    }
  }

  const handleInternationalIbanOnBlur = () => {
    clearErrors()
    iban && validateIban()
  }

  const setTab = (beneficiaryEntityTypeTab: BeneficiaryEntityType) => {
    setActiveTab(beneficiaryEntityTypeTab)
    setValue('beneficiaryEntityType', beneficiaryEntityTypeTab)

    if (beneficiaryEntityTypeTab === BeneficiaryEntityType.INDIVIDUAL) {
      setValue('beneficiaryType', BeneficiaryType.THIRD_PARTY)
    }

    track.Amp.track(AmplitudeEvent.ADD_BENEFICIARY_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
      transferDestination: transferDestination?.toLowerCase(),
      entityType: beneficiaryEntityTypeTab?.toLowerCase(),
    })
  }

  const beneficiaryFormLoading = useMemo(() => {
    return isSubmitting || isValidatingIban || isValidatingScan
  }, [isSubmitting, isValidatingIban, isValidatingScan])

  const actions: ButtonProps[] = [
    {
      children: t('back'),
      testid: 'back',
      secondary: true,
      onClick: () => {
        resetRequestError()
        clearErrors()
        onBack()
      },
    },
    {
      children: isLocalBeneficiary ? t('manageBeneficiaries.addBeneficiaryCta') : t('continue'),
      testid: isLocalBeneficiary ? 'add-beneficiary' : 'continue',
      onClick: handleContinue,
      loading: beneficiaryFormLoading,
      disabled: isInvalidForm,
    },
  ]

  const getBankOptions = (): SelectOption[] => {
    return bankList?.banks?.map(bank => {
      return {
        label: titleCase(bank?.bankName),
        value: bank?.bankIdentifier,
      }
    })
  }
  const southAfricanBankOptions: SelectOption[] = useMemo(() => {
    return southAfricanBanks?.map(bank => ({
      label: titleCase(bank?.name),
      value: bank?.id?.toString(),
    }))
  }, [southAfricanBanks])

  const handleChange = (field: AddBeneficiaryBasicPayloadKeys, value: string) => {
    setValue(field, trimStart(value))
  }

  const renderAccountTypeFields = () => {
    switch (activeTab) {
      case BeneficiaryEntityType.BUSINESS:
        return (
          <BusinessBeneficiaryFields
            availableCurrencies={availableCurrencies}
            alias={alias}
            businessName={businessName}
            countries={countries?.map(country => ({
              label: country?.name,
              value: country?.code,
            }))}
            isCountriesLoading={isCountriesLoading}
            countryCode={countryCode}
            currencyCode={currencyCode}
            errors={formState?.errors}
            register={register}
            setValue={setValue}
            renderCurrencySpecificFields={isLocalBeneficiary && renderCurrencySpecificFields}
          />
        )
      case BeneficiaryEntityType.INDIVIDUAL:
        return (
          <IndividualBeneficiaryFields
            availableCurrencies={availableCurrencies}
            alias={alias}
            countries={countries?.map(country => ({
              label: country?.name,
              value: country?.code,
            }))}
            isCountriesLoading={isCountriesLoading}
            countryCode={countryCode}
            currencyCode={currencyCode}
            errors={formState?.errors}
            firstName={firstName}
            lastName={lastName}
            dateOfBirth={dateOfBirth}
            register={register}
            setValue={setValue}
            renderCurrencySpecificFields={isLocalBeneficiary && renderCurrencySpecificFields}
            isLocalBeneficiary={isLocalBeneficiary}
          />
        )
      default:
        return null
    }
  }

  const renderCurrencySpecificFields = () => {
    return (
      <Box>
        {currencyCode === BeneficiaryDetailsCurrency.EUR && (
          <EurFormFields
            bic={bic}
            errors={formState?.errors}
            iban={iban}
            handleChange={handleChange}
            register={register}
            onIbanInputBlur={handleInternationalIbanOnBlur}
            isValidatingIban={isValidatingIban}
            ibanValidationData={ibanBankDetails}
            isSepaSupported={isSepaSupported}
          />
        )}
        {currencyCode === BeneficiaryDetailsCurrency.GBP && (
          <GbpFormFields
            accountNumber={accountNumber}
            errors={formState?.errors}
            sortCode={sortCode}
            handleChange={handleChange}
            register={register}
            isValidatingScan={isValidatingScan}
            scanValidationData={scanBankDetails}
          />
        )}
        {currencyCode === BeneficiaryDetailsCurrency.NGN && (
          <NgnFormFields
            bankCode={bankCode}
            banks={getBankOptions()}
            errors={formState?.errors}
            fetchingBankList={isFetchingBankList}
            ngnAccountNumber={ngnAccountNumber}
            handleChange={handleChange}
            register={register}
          />
        )}
        {currencyCode === BeneficiaryDetailsCurrency.USD && (
          <UsdFormFields
            abaRoutingNumber={abaRoutingNumber}
            errors={formState?.errors}
            cbitAddress={cbitAddress}
            cbitBankCode={cbitBankCode}
            usdAccountNumber={usdAccountNumber}
            handleChange={handleChange}
            register={register}
          />
        )}
        {currencyCode === BeneficiaryDetailsCurrency.ZAR && (
          <ZarFormFields
            routingNumberBranchCode={zarRoutingNumberBranchCode}
            accountType={zarAccountType}
            selectedBank={bankCode}
            bankOptions={southAfricanBankOptions}
            errors={formState?.errors}
            accountNumber={zarAccountNumber}
            isFetchingBanks={isFetchingSouthAfricanBanks}
            handleChange={handleChange}
            register={register}
            setValue={setValue}
          />
        )}
      </Box>
    )
  }

  const renderSwiftBeneficiaryDetails = () => {
    return (
      <BeneficiaryAddressFields
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        errors={formState?.errors}
        postCode={postCode}
        region={region}
        handleChange={handleChange}
        register={register}
      />
    )
  }

  const renderTab = () => {
    return (
      <>
        {requestError?.show && (
          <Box paddingB={20}>
            <RequestError errorCode={requestError?.errorCode} />
          </Box>
        )}

        {renderAccountTypeFields()}
        <Box paddingB={16}>
          <Input
            {...register('beneficiaryPaymentReference')}
            label={`${t('beneficiaryPaymentReference')} (${t('optional')})`}
            inputMode="text"
            name="beneficiaryPaymentReference"
            testid="input-beneficiaryPaymentReference"
            errorText={formState?.errors?.beneficiaryPaymentReference?.message}
            error={!!formState?.errors?.beneficiaryPaymentReference}
            value={beneficiaryPaymentReference}
            onChange={event => handleChange('beneficiaryPaymentReference', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterReferencePlaceholder')}
          />
        </Box>
        {isInternationalBeneficiary && renderSwiftBeneficiaryDetails()}
        {isFirstPartyBeneficiaryEnabled && (
          <BeneficaryTypeCheckbox
            disabled={isFirstPartyBeneficiaryDisabled}
            isFirstPartyBeneficiary={isFirstPartyBeneficiary}
            setValue={setValue}
          />
        )}
      </>
    )
  }

  return (
    <ModalScrollable>
      <Box testid="add-beneficiary-form" flex flexCol>
        {enableBeneficiaryIndividual && (
          <Tabs
            activeTab={activeTab}
            tabs={[
              {
                id: BeneficiaryEntityType.BUSINESS,
                title: t('manageBeneficiaries.business'),
              },
              {
                id: BeneficiaryEntityType.INDIVIDUAL,
                title: t('manageBeneficiaries.individual'),
              },
            ]}
            setActiveTab={setTab}
          />
        )}
        <Box padding={24}>{renderTab()}</Box>
      </Box>
      <ModalActions actions={actions} />
    </ModalScrollable>
  )
}
