import React from 'react'

interface PaymentTimelineRowInfoProps {
  generalInfo: string
  boldInfo?: string
  testid?: string
}

export const PaymentTimelineRowInfo = ({
  generalInfo,
  boldInfo,
  testid,
}: PaymentTimelineRowInfoProps) => {
  return (
    <div className="pb-5">
      <span>{generalInfo}</span>
      {boldInfo && (
        <span className="pl-1">
          <strong className="font-medium" data-testid={testid}>
            {boldInfo}
          </strong>
        </span>
      )}
    </div>
  )
}
