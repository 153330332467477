import React from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { DetailsCard } from '@node-space/storybook-components/dist/DetailsCard'
import Loader from '@node-space/storybook-components/dist/Loader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { iconBgColour } from 'pages/Customers/atoms/customersAtoms'
import { CustomerDetails } from '../types'
import IconCircle from './IconCircle'

interface CustomerInfoProps {
  customerDetails: CustomerDetails
  isLoading?: boolean
}

const CustomerInfo = ({ customerDetails, isLoading }: CustomerInfoProps) => {
  const { t } = useTranslation()
  const currentIconBgColour = useAtomValue(iconBgColour)

  return (
    <Box border="gray" background="white" width="full">
      <Box flex direction="row">
        <Box alignItems="start">
          <Text size="xl" weight="medium" className="p-4">
            {t('customers.companyInfo')}
          </Text>
        </Box>
      </Box>
      <Box flex direction="col" padding={16} alignItems="center" gapY={12}>
        <IconCircle name={customerDetails?.name} assignedColor={currentIconBgColour} size={48} />
        <Text size="lg" weight="medium" align="center">
          {customerDetails?.name}
        </Text>
      </Box>
      {isLoading ? (
        <Box flex centerChildren width="full" paddingT={28}>
          <Loader size="medium" />
        </Box>
      ) : (
        customerDetails && (
          <DetailsCard
            borderless
            background="white"
            details={[
              {
                label: t('customers.customerType'),
                value: customerDetails?.type,
              },
              {
                label: t('customers.registrationNumber'),
                value: customerDetails?.registrationNumber,
              },
              {
                label: t('customers.taxResidency'),
                value: customerDetails?.taxResidence,
              },
              {
                label: t('customers.businessAddress'),
                value: customerDetails?.address,
              },
              {
                label: t('customers.representativeName'),
                value: customerDetails?.representativeName,
              },
            ]}
          />
        )
      )}
    </Box>
  )
}

export default CustomerInfo
