import { Query } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { logSentryError } from '@node-space/utils'
import { BaseErrorResponse } from 'types/beneficiaries'
import { QueryMetaType } from 'types/reactQuery'
import { forceLogout } from 'utils/auth/utils'

const DEFAULT_EXLCUDED_ERROR_CODES = [428, 429]

export const onQueryError = (error: BaseErrorResponse, query: Query) => {
  const { errorAdditionalData, isErrorFatal, excludedErrorCodes, onErrorAdditional } =
    (query?.meta as QueryMetaType) || {}

  const excludedErrorStatusCodes = excludedErrorCodes
    ?.filter(code => !DEFAULT_EXLCUDED_ERROR_CODES?.includes(code))
    ?.concat(DEFAULT_EXLCUDED_ERROR_CODES)

  const isExcludedError = excludedErrorStatusCodes?.includes(error?.status)

  if (!isExcludedError && query?.queryKey?.[0]) {
    logSentryError(
      `Error with query key (${query?.queryKey[0]}):`,
      error,
      ...(!isEmpty(errorAdditionalData) ? [errorAdditionalData] : [])
    )
  }
  onErrorAdditional && onErrorAdditional(error)

  if (isErrorFatal) {
    forceLogout()
  }
}
