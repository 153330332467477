import React from 'react'
import { useTranslation } from 'react-i18next'
import { RecaptchaScoreError } from 'recaptcha/RecaptchaScoreError'
import Text, { TextProps } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { ExternalLinks } from 'constants/links'
import { LoginError, type LoginErrorType } from '../utils'

export interface LoginErrorProps {
  errorType: LoginErrorType
}

export const LoginErrorMessage = ({ errorType }: LoginErrorProps) => {
  const { t } = useTranslation()

  const errorTextProps: TextProps = {
    color: 'inherit',
    size: 'sm',
  }

  const contactSupportTextAction = (
    <TextAction
      text={t('errorMessages.contactSupport')}
      linkTo={ExternalLinks.CONTACT_SUPPORT}
      inheritColor
    />
  )

  if (errorType === LoginError.CREDENTIALS) {
    return <Text {...errorTextProps}>{t('errorMessages.login.credentials')}</Text>
  }
  if (errorType === LoginError.FORBIDDEN) {
    return (
      <Text {...errorTextProps}>
        {`${t('errorMessages.login.default')} ${t('errorMessages.tryAgainLater')} `}
        {contactSupportTextAction}.
      </Text>
    )
  }
  if (errorType === LoginError.RECAPTCHA_LOW_SCORE) {
    return <RecaptchaScoreError />
  }

  return null
}
