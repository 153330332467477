import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@node-space/storybook-components'
import { PaymentCreateModalContext } from '../../context/PaymentCreateModalProvider'
import { useProfileContext } from 'hooks/context/useProfileContext'

const PaymentHeaderCreateButton = () => {
  const { t } = useTranslation()
  const { viewOnly } = useProfileContext()
  const [_, setShowCreateModal] = useContext(PaymentCreateModalContext)

  return (
    <Button
      testid="create-payment-btn"
      disabled={viewOnly}
      onClick={() => setShowCreateModal(true)}
      iconElement={<Icon name="PlusIcon" color="white" />}
    >
      {`${t('createPayment')}`}
    </Button>
  )
}

export default React.memo(PaymentHeaderCreateButton)
