import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import '@node-space/storybook-components/dist/index.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useFeatureFlags, usePageViewTracker } from '@node-space/hooks'
import { AppProviders } from 'components/app/AppProviders'
import { ProfileSettingProtectedPage } from 'components/app/ProfileSettingProtectedPage'
import ChatBot from 'components/ChatBot/ChatBot'
import ErrorContent from 'components/ErrorContent'
import { PendingAgreements } from 'components/PendingAgreements/PendingAgreements'
import ProtectedComponent from 'components/ProtectedComponent'
import { PathNames } from 'constants/General'
import { AccountsData } from 'contexts/accountsContext'
import { useBvnkNetworkConnectionsQuery } from 'hooks/queries/BvnkNetwork/useBvnkNetworkConnectionsQuery'
import { useCapabiltiesQuery } from 'hooks/queries/Capabilities/useCapabiltiesQuery'
import { useComplianceViewHelper } from 'hooks/useComplianceViewHelper'
import useLaunchDarkly from 'hooks/useLaunchDarkly'
import { useRefreshSession } from 'hooks/useRefreshSession'
import { useRequestsOnLogin } from 'hooks/useRequestsOnLogin'
import useSentryIdentification from 'hooks/useSentryIdentification'
import AccountError from 'pages/AccountError'
//   Keep Page components A-Z
import ApiKeysV2 from 'pages/ApiKeys/V2/ApiKeys'
import Approvals from 'pages/Approvals/Approvals'
import { Authenticate } from 'pages/Authenticate/Authenticate'
import {
  bvnkNetworkConnectionsAtom,
  isBvnkNetworkEnabledAtom,
  isFetchingConnectionsAtom,
  isFetchingConnectionsErrorAtom,
} from 'pages/BvnkNetwork/atoms/bvnkNetworkAtoms'
import { BvnkNetwork } from 'pages/BvnkNetwork/BvnkNetwork'
import Channels from 'pages/Channels/Channels'
import { currentCustomerAtom } from 'pages/Customers/atoms/customersAtoms'
import { Customer } from 'pages/Customers/Customer'
import { Customers } from 'pages/Customers/Customers'
import { isBusinessCustomer, isIndividualCustomer } from 'pages/Customers/utils'
import DevXGetStarted from 'pages/Dashboard/components/DevXGetStarted'
import { GetStarted } from 'pages/GetStarted/GetStarted'
import Impersonate from 'pages/Impersonate'
import { LegalAgreements } from 'pages/LegalAgreements/LegalAgreements'
import ForgotPassword from 'pages/Login/ForgotPassword'
import LoginNew from 'pages/Login/Login'
import PasswordReset from 'pages/Login/PasswordReset'
import { ManageAccount } from 'pages/ManageAccount/ManageAccount'
import { ManageBeneficiaries } from 'pages/ManageBeneficiaries/ManageBeneficiaries'
import ManageChannels from 'pages/ManageChannels/ManageChannels'
import CryptoAddressWhitelisting from 'pages/ManageCryptoAddresses/CryptoAddressesWhitelisting'
import { MassPayouts } from 'pages/MassPayouts/MassPayouts'
import { MassPayoutReview } from 'pages/MassPayouts/pages/MassPayoutReview'
import { MassPayoutsUpload } from 'pages/MassPayouts/pages/MassPayoutsUpload'
import { MerchantsDetails } from 'pages/MerchantDetails/MerchantsDetails'
import NotFound from 'pages/NotFound'
import Payments from 'pages/Payments'
import { PaymentSupport } from 'pages/PaymentSupport/PaymentSupport'
import { CreateAccount } from 'pages/Signup/CreateAccount'
import { CTAGetStarted } from 'pages/Signup/CTAGetStarted'
import TeamMembers from 'pages/TeamMembers/TeamMembers'
import TeamMembersV2 from 'pages/TeamMembers/V2/TeamMembersV2'
import { TransactionHistory } from 'pages/TransactionHistory/TransactionHistory'
import UserDetails from 'pages/UserDetails/UserDetails'
import VerificationStatus from 'pages/Verification/VerificationStatus'
import Verify from 'pages/Verify/Verify'
import { WalletDetails } from 'pages/WalletDetails/WalletDetails'
import WalletCreateSuccessful from 'pages/Wallets/WalletCreateSuccessful'
import Wallets from 'pages/Wallets/Wallets'
import { CapabilitiesScope } from 'types/capabilities/capabilities'
import { AccountTypes, CountryCodes, Profile } from 'types/types'
import { AmplitudeEvent } from 'utils/amplitude/amplitudeEvents'
import { isProduction, isSandbox } from 'utils/environment'
import { usePardotTracking } from 'utils/pardot/usePardotTracking'
import track from 'utils/tracker'
import PageLayout, { PageLayoutProps } from './components/layout/PageLayout'
import Logout from './components/Logout'

const App = () => {
  const setIsBvnkNetworkEnabled = useSetAtom(isBvnkNetworkEnabledAtom)
  const setBvnkNetworkConnections = useSetAtom(bvnkNetworkConnectionsAtom)
  const setIsFetchingBvnkNetwork = useSetAtom(isFetchingConnectionsAtom)
  const setIsFetchingConnectionsError = useSetAtom(isFetchingConnectionsErrorAtom)

  const isBvnkNetworkEnabled = useAtomValue(isBvnkNetworkEnabledAtom)
  const currentCustomer = useAtomValue(currentCustomerAtom)

  const { t } = useTranslation()
  const [sentryTraceId, setSentryTraceId] = useState('')
  const {
    enableLegalAgreements,
    enableApprovalFlowAccountLevel,
    enableChatbot,
    enableMassPayouts,
    enableBvnkNetwork,
    enableUnderlyingCustomer,
  } = useFeatureFlags()
  const location = useLocation()

  const {
    isLoadingRequestsOnLogin,
    fetchedRequest,
    profile,
    isVerifiedBusinessAccount,
    isDeveloperAccount,
    isAuthorizedAccount,
    accounts,
    currentAccount,
  } = useRequestsOnLogin()

  const {
    data: networkCapability,
    isFetching: isFetchingNetworkCapability,
    isError: isFetchingNetworkCapabilityError,
  } = useCapabiltiesQuery(
    {
      group: 'NETWORK',
      name: 'PARTICIPANT',
      scope: CapabilitiesScope.ACCOUNT,
      subjectId: currentAccount?.reference,
    },
    !!currentAccount?.reference && enableBvnkNetwork
  )

  const {
    data: bvnkNetworkConnections,
    isFetching: isFetchingConnections,
    isError: isFetchingConnectionsError,
  } = useBvnkNetworkConnectionsQuery(
    {
      accountReference: currentAccount?.reference,
    },
    !!currentAccount?.reference &&
      isBvnkNetworkEnabled &&
      enableBvnkNetwork &&
      !isFetchingNetworkCapability &&
      !isFetchingNetworkCapabilityError
  )

  useEffect(() => {
    !isFetchingNetworkCapability && setIsBvnkNetworkEnabled(networkCapability?.value === 'true')
  }, [networkCapability, isFetchingNetworkCapability])

  useEffect(() => {
    setBvnkNetworkConnections(bvnkNetworkConnections)
    setIsFetchingBvnkNetwork(isFetchingConnections)
    setIsFetchingConnectionsError(isFetchingConnectionsError)
  }, [bvnkNetworkConnections, isFetchingConnections, isFetchingConnectionsError])

  const profileContext: { data: Profile; isLoading: boolean } = useMemo(
    () => ({ data: profile, isLoading: isLoadingRequestsOnLogin }),
    [profile, isLoadingRequestsOnLogin]
  )

  const accountsContext: { data: AccountsData; isLoading: boolean } = useMemo(
    () => ({
      data: { accounts, currentAccount, isVerifiedBusinessAccount, isDeveloperAccount },
      isLoading: isLoadingRequestsOnLogin,
    }),
    [
      accounts,
      currentAccount,
      isVerifiedBusinessAccount,
      isDeveloperAccount,
      isLoadingRequestsOnLogin,
    ]
  )

  const isComplianceView = useComplianceViewHelper(profile)

  useLaunchDarkly(profile, accountsContext)
  useSentryIdentification(profile)
  usePardotTracking()
  usePageViewTracker()
  useRefreshSession(!!fetchedRequest?.verification)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (profile?.loggedIn) {
      // Google Analytics: Tracking start
      track.GA.initialize()
      track.GA.event({
        category: 'Login',
        action: `A user with ID: ${profile?.id} logged in`,
      })

      // HotJar: Tracking start
      track.Hotjar.identify(profile?.id, {
        first_name: profile?.firstName,
        last_name: profile?.lastName,
        id: profile?.id,
        country: profile?.country?.name,
        default_currency: profile?.currencyCode,
        twoFactorEnabled: profile?.twoFactorEnabled,
        registrationAccountType: profile?.options?.registrationAccountType,
      })
      track.Hotjar.event('app_start')
      track.Hotjar.event('user_logged_in')

      // On Sandbox only - and DevX account then log
      if (isSandbox && isDeveloperAccount) {
        track.Amp.track(AmplitudeEvent.DEVX_SANDBOX_DEVELOPER_LOGGED_IN)
      }

      // Amplitude Tracking start
      track.Amp.set('email', profile?.emailAddress)
      track.Amp.set('first_name', profile?.firstName)
      track.Amp.set('last_name', profile?.lastName)
      track.Amp.set('country', profile?.country?.code)
      track.Amp.set('currency', profile?.currencyCode)
      track.Amp.identify({
        user_id: `${profile?.id}`?.padStart(5, '0'),
      })
    }
  }, [profile, isDeveloperAccount])

  const shouldShowUKInfoBanner = useMemo(() => {
    return profile?.country?.code === CountryCodes.UK
  }, [profile?.country?.code])

  const canRouteDefault = isAuthorizedAccount

  const defaultPageLayout: PageLayoutProps = {
    loading: isLoadingRequestsOnLogin,
    canRoute: canRouteDefault,
  }

  const onBeforeErrorCaptureHandler = (scope: Sentry.Scope) => {
    const { traceId } = scope.getPropagationContext()
    setSentryTraceId(traceId)
  }

  return (
    <Sentry.ErrorBoundary
      beforeCapture={onBeforeErrorCaptureHandler}
      fallback={({ componentStack }) => (
        <ErrorContent errorInfo={componentStack} sentryTraceId={sentryTraceId} />
      )}
      onReset={() => {
        setSentryTraceId('')
      }}
    >
      <AppProviders
        profile={profileContext}
        accounts={accountsContext}
        shouldShowUKInfoBanner={shouldShowUKInfoBanner}
      >
        {enableChatbot && <ChatBot isAuthed={profile?.loggedIn} />}
        <Routes>
          {/* WALLETS START */}
          <Route element={<PageLayout {...defaultPageLayout} />}>
            <Route
              path={PathNames.WALLETS}
              element={
                <ProtectedComponent featureName="wallets">
                  <Wallets />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`}
              element={
                <ProtectedComponent featureName="wallets">
                  <Wallets />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.WALLETS}${PathNames.WALLET_CREATE}`}
              element={
                <ProtectedComponent featureName="wallets">
                  <Wallets />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.WALLETS}${PathNames.WALLETS_CATCH_ALL}`}
              element={<Wallets />}
            />
            <Route path={`${PathNames.WALLETS}`} element={<Wallets />} />
            <Route
              path={`${PathNames.WALLETS}${PathNames.WALLETS_CREATE_SUCCESS}`}
              element={<WalletCreateSuccessful />}
            />
          </Route>
          <Route
            element={
              <PageLayout
                {...defaultPageLayout}
                breadcrumbConfig={[
                  {
                    title: 'wallets.title',
                    link: `${PathNames.WALLETS}${PathNames.WALLET_DASHBOARD}`,
                  },
                  {
                    title: 'wallets.walletDetails',
                  },
                ]}
              />
            }
          >
            <Route
              path={`${PathNames.WALLETS}${PathNames.WALLETS_DETAILS}`}
              element={<WalletDetails />}
            />
          </Route>
          {/* WALLETS END */}
          {/* PAYMENTS START */}
          <Route element={<PageLayout {...defaultPageLayout} />}>
            <Route
              path={`${PathNames.PAYMENTS}${PathNames.PAYMENTS_LINKS}`}
              element={
                <ProtectedComponent featureName="payments">
                  <Payments />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.PAYMENTS}${PathNames.PAYMENTS_CREATE}`}
              element={<Payments />}
            />
            <Route
              path={`${PathNames.PAYMENTS}${PathNames.CHANNELS_LINKS}`}
              element={<Channels />}
            />
          </Route>
          {/* PAYMENTS END */}

          {/* MASS PAYOUTS */}
          <Route element={<PageLayout {...defaultPageLayout} canRoute />}>
            <Route
              key="mass-payouts"
              path={PathNames.MASS_PAYOUTS}
              element={
                enableMassPayouts ? (
                  <ProtectedComponent featureName="payments">
                    <MassPayouts />
                  </ProtectedComponent>
                ) : (
                  <></>
                )
              }
            />
          </Route>

          <Route
            element={
              <PageLayout
                {...defaultPageLayout}
                breadcrumbConfig={[
                  {
                    title: 'massPayouts.massPayouts',
                    link: `${PathNames.MASS_PAYOUTS}`,
                  },
                  {
                    title: 'massPayouts.create',
                  },
                ]}
              />
            }
          >
            <Route path={PathNames.MASS_PAYOUTS_CREATE} element={<MassPayoutsUpload />} />
          </Route>

          <Route
            element={
              <PageLayout
                {...defaultPageLayout}
                breadcrumbConfig={[
                  {
                    title: 'massPayouts.massPayouts',
                    link: `${PathNames.MASS_PAYOUTS}`,
                  },
                  {
                    title: 'massPayouts.payoutDetails',
                  },
                ]}
              />
            }
          >
            <Route
              path={`${PathNames.MASS_PAYOUTS}${PathNames.MASS_PAYOUTS_REVIEW}`}
              element={<MassPayoutReview />}
            />
          </Route>

          {/* BVNK NETWORK */}
          <Route element={<PageLayout {...defaultPageLayout} canRoute />}>
            <Route
              key="bvnk-network"
              path={PathNames.BVNK_NETWORK}
              element={
                enableBvnkNetwork && isBvnkNetworkEnabled ? (
                  <ProtectedComponent featureName="bvnkNetwork">
                    <BvnkNetwork />
                  </ProtectedComponent>
                ) : (
                  <NotFound />
                )
              }
            />
          </Route>

          {/* Customers */}
          <Route
            element={
              <PageLayout
                {...defaultPageLayout}
                canRoute
                breadcrumbConfig={[
                  {
                    title: 'customers.title',
                    link: `${PathNames.CUSTOMERS}`,
                  },
                  ...(isBusinessCustomer(currentCustomer) && !!currentCustomer?.company?.name
                    ? [
                        {
                          title: currentCustomer?.company?.name,
                        },
                      ]
                    : []),
                  ...(isIndividualCustomer(currentCustomer) && !!currentCustomer?.person?.firstName
                    ? [
                        {
                          title: `${currentCustomer?.person?.firstName} ${currentCustomer?.person?.lastName}`,
                        },
                      ]
                    : []),
                ]}
              />
            }
          >
            <Route
              key="my-customers"
              path={PathNames.CUSTOMERS}
              element={
                enableUnderlyingCustomer ? (
                  <ProtectedComponent featureName="customers">
                    <Customers />
                  </ProtectedComponent>
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path={`${PathNames.CUSTOMER}`}
              element={
                enableUnderlyingCustomer ? (
                  <ProtectedComponent featureName="customers">
                    <Customer />
                  </ProtectedComponent>
                ) : (
                  <NotFound />
                )
              }
              index
            />
          </Route>

          <Route
            key="create-account"
            path={`${PathNames.KYB_SIGNUP_URL}/*`}
            element={<CreateAccount accountType={AccountTypes.BUSINESS} />}
          />
          <Route element={<PageLayout {...defaultPageLayout} />}>
            <Route
              key="transaction-history"
              path={PathNames.TRANSACTION_HISTORY}
              element={
                <ProtectedComponent featureName="wallets">
                  <TransactionHistory />
                </ProtectedComponent>
              }
            />
          </Route>
          {!isProduction && [
            <Route
              key="create-account"
              path={PathNames.DEVX_SIGNUP_URL}
              element={<CreateAccount accountType={AccountTypes.DEVELOPER} />}
            />,
            <Route
              key="dev-get-started"
              element={
                <PageLayout
                  {...defaultPageLayout}
                  title={t('home')}
                  isTitleEnabled={false}
                  canRoute
                />
              }
            >
              <Route path={PathNames.DEV_GET_STARTED} element={<DevXGetStarted />} />,
            </Route>,
          ]}
          {/* APPROVALS */}
          <Route element={<PageLayout {...defaultPageLayout} canRoute />}>
            <Route
              path={PathNames.APPROVALS}
              element={
                enableApprovalFlowAccountLevel ? (
                  <ProtectedComponent featureName="manageApprovals">
                    <Approvals />
                  </ProtectedComponent>
                ) : (
                  <></>
                )
              }
            />
          </Route>
          {/* MANAGE ACCOUNT ROUTES START */}
          <Route element={<PageLayout {...defaultPageLayout} canRoute />}>
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_ACCOUNT}`}
              element={<ManageAccount />}
              index
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_USER_PROFILE}`}
              element={<UserDetails />}
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_PAYMENT_SUPPORT}`}
              element={<PaymentSupport />}
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_CHANNELS}`}
              element={<ManageChannels />}
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_API_KEYS}`}
              element={
                <ProtectedComponent featureName="manageApiIntegration">
                  <ApiKeysV2 />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_TEAM_MEMBERS}`}
              element={
                <ProtectedComponent featureName="manageTeamMembers">
                  {enableApprovalFlowAccountLevel ? <TeamMembersV2 /> : <TeamMembers />}
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_MERCHANT_DETAILS}`}
              element={<MerchantsDetails />}
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_CRYPTO_WHITELISTING}`}
              element={<CryptoAddressWhitelisting />}
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.MANAGE_BENEFICIARIES}`}
              element={
                <ProtectedComponent featureName="manageBeneficiaries">
                  <ManageBeneficiaries />
                </ProtectedComponent>
              }
            />
            <Route
              path={`${PathNames.SETTINGS}${PathNames.LEGAL_AGREEMENTS}`}
              element={
                <ProfileSettingProtectedPage
                  profileSettingName="isLegalAgreementSectionEnabled"
                  rolePermissionName="manageLegalAgreements"
                  hasOverride={enableLegalAgreements}
                >
                  <LegalAgreements />
                </ProfileSettingProtectedPage>
              }
            />
          </Route>
          {/* MANAGE ACCOUNT ROUTES END */}
          <Route path={PathNames.LOGIN} element={<LoginNew />} />
          <Route path={PathNames.GET_STARTED} element={<CTAGetStarted />} />
          <Route key="forgot" path={PathNames.FORGOT} element={<ForgotPassword />} />
          <Route
            key="forgot-password"
            path={PathNames.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route key="reset-password" path={PathNames.RESET_TOKEN} element={<PasswordReset />} />
          <Route key="impersonate" path={PathNames.IMPERSONATE} element={<Impersonate />} />
          <Route path={PathNames.LOGOUT} element={<Logout />} />
          <Route
            path={PathNames.AUTHENTICATE}
            element={
              <Authenticate
                isLoadingData={isLoadingRequestsOnLogin}
                isOnboardingAccount={!isVerifiedBusinessAccount}
              />
            }
          />
          <Route
            element={
              <PageLayout
                {...defaultPageLayout}
                title={!isComplianceView ? t('home') : t('settingsPaymentSupport')}
                isTitleEnabled={isComplianceView}
              />
            }
          >
            <Route
              path="/"
              element={
                <GetStarted
                  isComplianceView={isComplianceView}
                  complianceViewComponent={<PaymentSupport />}
                />
              }
            />
          </Route>
          <Route path={PathNames.VERIFY} element={<Verify />} />
          <Route path={PathNames.VERIFICATION_STATUS} element={<VerificationStatus />} />
          <Route path={PathNames.ACCOUNT_ERROR} element={<AccountError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {isVerifiedBusinessAccount && <PendingAgreements />}
      </AppProviders>
    </Sentry.ErrorBoundary>
  )
}

export default App
