import { requestApi } from 'api/api'
import { AddMerchantPayload, EditMerchantPayload, MerchantType } from 'types/types'

export const getMerchants = (): Promise<MerchantType[]> => {
  return requestApi({
    url: '/v1/merchant',
    method: 'GET',
  })
}

export const postAddMerchant = ({
  displayName,
  wallet,
  webhookUrl,
}: AddMerchantPayload): Promise<MerchantType> => {
  return requestApi({
    url: '/v1/merchant',
    method: 'POST',
    data: {
      displayName,
      wallet,
      ...(!!webhookUrl && { webhookUrl }),
    },
  })
}

export const putEditMerchant = ({
  displayName,
  wallet,
  webhookUrl,
  merchant,
}: EditMerchantPayload): Promise<MerchantType> => {
  return requestApi({
    url: `/v1/merchant/${merchant?.merchantId}`,
    method: 'PUT',
    data: {
      displayName,
      wallet,
      ...(!!webhookUrl && { webhookUrl }),
    },
  })
}
