import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '@node-space/storybook-components/dist/Modal'
import { MerchantType } from 'types/types'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { MerchantDetails } from './MerchantDetails'
import { MerchantEditor } from './MerchantEditor'

interface MerchantEditProps {
  merchant: MerchantType
  isModalOpen: boolean
  handleCloseModal: () => void
}

export const MerchantEdit = ({ merchant, isModalOpen, handleCloseModal }: MerchantEditProps) => {
  const { t } = useTranslation()

  const [editedMerchant, setEditedMerchant] = useState<MerchantType>()

  useEffect(() => {
    if (!!editedMerchant) {
      track.Amp.track(AmplitudeEvent.EOF_EDIT_MERCHANT_SUCCESS, {
        category: AmplitudeEventCategory.APPLICATION,
        action: AmplitudeEventAction.CLICK,
      })
    }
  }, [editedMerchant])

  return (
    <Modal allowOverFlow visible={isModalOpen} closeModal={handleCloseModal}>
      {editedMerchant ? (
        <MerchantDetails
          handleCloseModal={handleCloseModal}
          merchant={editedMerchant}
          successMessage={t('changesSaved')}
        />
      ) : (
        <MerchantEditor
          handleCloseModal={handleCloseModal}
          merchant={merchant}
          setMerchant={setEditedMerchant}
          actionName={t('editMerchant')}
          submitBtnText={t('save')}
          action="EDIT"
        />
      )}
    </Modal>
  )
}
