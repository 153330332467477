import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { useFeatureFlags } from '@node-space/hooks'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import Icon from '@node-space/storybook-components/dist/Icon'
import Text from '@node-space/storybook-components/dist/Text'
import CopyRow from 'components/CopyRow'
import { Options } from 'types/BankAccountsDetails'
import { Currencies, CurrencyLimits, WalletType } from 'types/types'
import { roundNumberWithCommas } from 'utils/utils'
import { getLocalBankDetails } from 'utils/wallet'
import { minAmount } from '../constants/constants'
import useDepositPaymentReference from '../hooks/useDepositPaymentReference'

interface LocalDetailsProps {
  options: Options
  wallet: WalletType
  currency: Currencies
  isSegregated: boolean
  copiedValue?: string
  setCopiedValue?: React.Dispatch<React.SetStateAction<string>>
  setCopiedTitle?: React.Dispatch<React.SetStateAction<string>>
  depositLimits?: CurrencyLimits
}

const LocalDetails = ({
  options,
  wallet,
  currency,
  isSegregated,
  copiedValue,
  setCopiedValue,
  setCopiedTitle,
  depositLimits,
}: LocalDetailsProps) => {
  const { t } = useTranslation()
  const { enableMultiplePaymentMethods } = useFeatureFlags()
  const accountDetails = getLocalBankDetails(
    options,
    wallet,
    currency,
    isSegregated,
    t,
    enableMultiplePaymentMethods
  )
  const paymentReference = useDepositPaymentReference(options)

  if (!Object.keys(accountDetails)?.length) {
    return <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
  }

  return (
    <Box>
      <Box>
        <Text size="sm">
          {`${t('depositUseDetails')} `}
          <span className="font-semibold">{`${
            depositLimits?.minimum
              ? roundNumberWithCommas(depositLimits?.minimum)
              : minAmount[currency]
          } ${currency}`}</span>
          {t('depositMatchDetails', {
            0: 'BVNK',
          })}
        </Text>
        <Box testid="beneficiaryDepositDetails" divideChildren="y">
          {Object.keys(accountDetails)
            .filter(label => !!label)
            .map(label => {
              return (
                <Box key={label}>
                  <CopyRow
                    key={label}
                    title={label}
                    description={accountDetails[label]}
                    copiedValue={copiedValue}
                    setCopiedValue={setCopiedValue}
                    setCopiedTitle={setCopiedTitle}
                  />
                </Box>
              )
            })}
        </Box>
      </Box>
      <Box flex justifyContent="center">
        <CopyButton confirmationMessageTheme="dark" copyObject={accountDetails}>
          <Box flex direction="row" gapX={4}>
            <Text weight="medium" color={copyButtonIconColour}>
              {t('copyAllDetails')}
            </Text>
            <Icon name="CopyIcon" color={copyButtonIconColour} />
          </Box>
        </CopyButton>
      </Box>
      {!!paymentReference && (
        <Box paddingT={16}>
          <Callout state="warning" message={t('depositInformation')} />
        </Box>
      )}
    </Box>
  )
}

export default memo(LocalDetails)
