import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getPaymentCaseDocuments } from 'services/PaymentsService'

export const usePaymentCaseDocumentsQuery = (paymentCaseId: string) => {
  return useQuery({
    queryKey: reactQueryKeys.paymentCaseDocuments(paymentCaseId),
    queryFn: () => getPaymentCaseDocuments(paymentCaseId),
    enabled: !!paymentCaseId,
    gcTime: 0,
  })
}
