import React, { useMemo } from 'react'
import i18next from 'i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

// TODO: Move this to a global SubTypes constant for all types of transactions
// Will require updates to all side panel views
export const BvnkNetworkTransferTransactionSubType = {
  PAYMENT_IN: 'PAYMENT_IN',
  PAYMENT_OUT: 'PAYMENT_OUT',
} as const

interface BvnkNetworkTransferProps {
  amountWithCurrency: string
  transactionReference: string
  currencyCode: string
  transactionType: string
  transactionSubType: string
}

const BvnkNetworkTransfer = ({
  amountWithCurrency,
  transactionReference,
  transactionType,
  transactionSubType,
}: BvnkNetworkTransferProps) => {
  const amountWithCurrencyLabel = useMemo(() => {
    const isPayin = transactionSubType === BvnkNetworkTransferTransactionSubType.PAYMENT_IN
    const isPayout = transactionSubType === BvnkNetworkTransferTransactionSubType.PAYMENT_OUT

    if (isPayin) {
      return i18next.t(`transactions.sideBar.paymentInAmountReceived`)
    } else if (isPayout) {
      return i18next.t(`transactions.sideBar.amountWithdrawn`)
    } else {
      return i18next.t(`transactions.sideBar.amount`)
    }
  }, [transactionSubType])

  return (
    <Box gapY={8}>
      {amountWithCurrency && (
        <TransactionDetailRow label={amountWithCurrencyLabel} detail={amountWithCurrency} />
      )}
      {transactionType && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.transactionType')}
          detail={transactionType?.replace('Bvnk', 'BVNK')}
        />
      )}
      {!!transactionReference && (
        <CopyTransactionRow
          content={transactionReference}
          label={i18next.t('transactions.sideBar.reference')}
        />
      )}
    </Box>
  )
}
export default BvnkNetworkTransfer
