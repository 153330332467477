import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box, Box as Row } from '@node-space/storybook-components/dist/Box'
import CopyButton from '@node-space/storybook-components/dist/CopyButton'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { TransactionDetailRow } from 'components/TransactionDetails/TransactionDetailRow'
import { DateFormat } from 'constants/General'
import {
  ApprovalActionStatuses,
  ApprovalStatuses,
  PendingTransactionsResponse,
} from 'types/approvals'
import { SpecialPayments } from 'types/payments'
import { number2Ordinal } from 'utils/utils'

interface SidePanelBodyProps {
  transactionDetails: PendingTransactionsResponse
  tab?: string
}

export const SidePanelBody = ({ transactionDetails, tab }: SidePanelBodyProps) => {
  const { t } = useTranslation()

  const hasBeneficiaryDetails = !!transactionDetails?.beneficiaryDetails
  const hasDistinctFromAndToCurrency =
    transactionDetails?.currency?.code &&
    transactionDetails?.fromCurrency &&
    transactionDetails?.currency?.code !== transactionDetails?.fromCurrency
  const isCryptoPayout = transactionDetails?.type === SpecialPayments.CRYPTO_PAYOUT

  return (
    <Box padding={16}>
      <Text size="base" weight="medium" color="dark-grey">
        {t('transactionDetails')}
      </Text>
      <Box paddingT={8} paddingB={8}>
        {hasDistinctFromAndToCurrency && (
          <>
            <TransactionDetailRow
              label={t('approvals.amountPaid')}
              detail={`${transactionDetails?.fromAmount} ${transactionDetails?.fromCurrency}`}
            />
            {tab === ApprovalStatuses.PENDING && (
              <TransactionDetailRow
                label={t('approvals.beneficiaryToReceive')}
                detail={`${transactionDetails?.amount} ${transactionDetails?.currency?.code}`}
              />
            )}
            {tab === ApprovalStatuses.APPROVED && (
              <TransactionDetailRow
                label={t('approvals.beneficiaryReceived')}
                detail={`${transactionDetails?.amount} ${transactionDetails?.currency?.code}`}
              />
            )}
          </>
        )}
        {!hasDistinctFromAndToCurrency && (
          <TransactionDetailRow
            label={isCryptoPayout ? t('approvals.amountPaid') : t('approvals.amountWithdrawn')}
            detail={`${transactionDetails?.amount} ${transactionDetails?.currency?.code}`}
          />
        )}
        {!!transactionDetails?.userReference && (
          <TransactionDetailRow
            label={t('reference')}
            detail={
              <Row flex alignItems="center">
                <Text
                  tag="span"
                  size="sm"
                  weight="medium"
                  color="dark-grey"
                  className="mb-1 flex-wrap break-words"
                >
                  {ellipseMiddleText(transactionDetails?.userReference, 10, 10)}
                </Text>
                <CopyButton
                  copyText={transactionDetails?.userReference}
                  confirmationMessage={t('copiedToClipboard')}
                  iconColour={copyButtonIconColour}
                  confirmationMessageAlignment="left"
                />
              </Row>
            }
          />
        )}
        <TransactionDetailRow
          label={t('approvals.walletName')}
          detail={transactionDetails?.walletName}
        />
        <TransactionDetailRow
          label={t('approvals.initiatedBy')}
          detail={transactionDetails?.initiatedBy}
        />
        {transactionDetails?.initiatedAt && (
          <TransactionDetailRow
            label={t('approvals.dateInitiated')}
            detail={dayjs(transactionDetails?.initiatedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
          />
        )}
        {!!transactionDetails?.processedBy &&
          transactionDetails?.processedBy?.map((processedByDetail, index) => (
            <Box key={index}>
              {processedByDetail?.status ===
                ApprovalActionStatuses.PAYOUT_MERCHANT_APPROVAL_APPROVED && (
                <TransactionDetailRow
                  label={`${number2Ordinal(index + 1)} ${t('approver')}`}
                  detail={`${processedByDetail?.userFullName?.trim() || processedByDetail?.email}, ${dayjs(processedByDetail?.eventTime).format(DateFormat.D_MMM_YYYY_AT_HH_MM)} `}
                />
              )}
              {processedByDetail?.status ===
                ApprovalActionStatuses.PAYOUT_MERCHANT_APPROVAL_REJECTED && (
                <TransactionDetailRow
                  label={t('approvals.rejectedBy')}
                  detail={`${processedByDetail?.userFullName?.trim() || processedByDetail?.email}, ${dayjs(processedByDetail?.eventTime).format(DateFormat.D_MMM_YYYY_AT_HH_MM)} `}
                />
              )}
            </Box>
          ))}
        {!!transactionDetails?.actionedBy && tab === ApprovalStatuses.APPROVED && (
          <TransactionDetailRow
            label={t('approvals.approvedBy')}
            detail={transactionDetails?.actionedBy}
          />
        )}
        {!!transactionDetails?.actionedAt && tab === ApprovalStatuses.APPROVED && (
          <TransactionDetailRow
            label={t('approvals.dateApproved')}
            detail={dayjs(transactionDetails?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
          />
        )}
        {!!transactionDetails?.actionedBy && tab === ApprovalStatuses.REJECTED && (
          <TransactionDetailRow
            label={t('approvals.rejectedBy')}
            detail={transactionDetails?.actionedBy}
          />
        )}
        {!!transactionDetails?.actionedAt &&
          !transactionDetails?.processedBy &&
          tab === ApprovalStatuses.REJECTED && (
            <TransactionDetailRow
              label={t('approvals.dateRejected')}
              detail={dayjs(transactionDetails?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
            />
          )}
        {!!transactionDetails?.actionedAt && tab === ApprovalStatuses.EXPIRED && (
          <TransactionDetailRow
            label={t('approvals.dateOfExpiry')}
            detail={dayjs(transactionDetails?.actionedAt).format(DateFormat.D_MMM_YYYY_AT_HH_MM)}
          />
        )}
      </Box>
      {hasBeneficiaryDetails && (
        <>
          <Text size="base" weight="medium" color="dark-grey" className="mt-3">
            {t('beneficiaryDetails')}
          </Text>
          <Box gapY={8} paddingB={8}>
            {!!transactionDetails?.beneficiaryDetails?.destinationWallet && (
              <TransactionDetailRow
                label={t('destinationWallet')}
                detail={
                  <Row flex alignItems="center">
                    <Text
                      tag="span"
                      size="sm"
                      weight="medium"
                      color="dark-grey"
                      className="mb-1 flex-wrap break-words"
                    >
                      {ellipseMiddleText(
                        transactionDetails?.beneficiaryDetails?.destinationWallet,
                        10,
                        10
                      )}
                    </Text>
                    <CopyButton
                      copyText={transactionDetails?.beneficiaryDetails?.destinationWallet}
                      confirmationMessage={t('copiedToClipboard')}
                      iconColour={copyButtonIconColour}
                      confirmationMessageAlignment="left"
                    />
                  </Row>
                }
              />
            )}
            {!!transactionDetails?.beneficiaryDetails?.bankCode && (
              <TransactionDetailRow
                label={t('bankCode')}
                detail={transactionDetails?.beneficiaryDetails?.bankCode}
              />
            )}
            {!!transactionDetails?.beneficiaryDetails?.accountNumber && (
              <TransactionDetailRow
                label={t('accountNumber')}
                detail={transactionDetails?.beneficiaryDetails?.accountNumber}
              />
            )}
            {!!transactionDetails?.beneficiaryDetails?.beneficiaryName && (
              <TransactionDetailRow
                label={t('beneficiaryName')}
                detail={transactionDetails?.beneficiaryDetails?.beneficiaryName}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
