import React from 'react'
import { Box, Box as MessageContainer } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import dayjs from 'dayjs'
import { DateFormat } from 'constants/General'
import { PaymentCaseEmail as CaseEmail } from 'types/payments'
import { getExtractedEmailText } from 'utils/utils'

interface PaymentCaseEmailProps {
  paymentCaseEmail: CaseEmail
}

const dateFormat = DateFormat.HH_MM_DD_MMM_YYYY

export const PaymentCaseEmail = ({ paymentCaseEmail }: PaymentCaseEmailProps) => {
  return (
    <Box flex width="full" justifyContent={paymentCaseEmail?.incoming ? 'end' : 'start'}>
      <Box flex flexCol gapY={4} className="w-full md:w-[calc(100%-20px)] break-words">
        <Text size="xs" color="grey" align={paymentCaseEmail?.incoming ? 'right' : 'left'}>
          {dayjs(paymentCaseEmail?.createdDate).format(dateFormat)}
        </Text>
        <Text size="sm" color="black" align={paymentCaseEmail?.incoming ? 'right' : 'left'}>
          {paymentCaseEmail?.fromAddress}
        </Text>
        <MessageContainer
          background={paymentCaseEmail?.incoming ? 'info' : 'gray'}
          borderRadius={4}
        >
          <Text className="text-sm p-3 whitespace-pre-line" color="black">
            {getExtractedEmailText(paymentCaseEmail?.textBody)}
          </Text>
        </MessageContainer>
      </Box>
    </Box>
  )
}
