import * as yup from 'yup'

export const apiKeySchema = () => {
  const description = yup.string().trim().label('Name').required()

  const formFields = {
    description,
  }

  return yup.object<Partial<Record<string, yup.AnySchema>>>(formFields)
}
