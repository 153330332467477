import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import {
  PopoverMenuItem,
  PopoverPlacement,
} from '@node-space/storybook-components/dist/components/PopoverMenu/types'
import Icon from '@node-space/storybook-components/dist/Icon'
import PopoverMenu from '@node-space/storybook-components/dist/PopoverMenu'
import { ConnectionStatusTypes } from 'constants/bvnkNetwork'
import { BvnkNetworkConnection } from 'types/bvnkNetwork'
import { ConnectionConfirmationModal } from './ConnectionConfirmationModal'

interface ConnectionConnectionMenuProps {
  connection: BvnkNetworkConnection
}

export const ConnectionConnectionMenu = ({ connection }: ConnectionConnectionMenuProps) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onDisconnectClick = () => {
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const menuItems: PopoverMenuItem[] = [
    {
      item: (
        <Button
          testid="connection-disconnect-btn"
          noStyling
          name={t('bvnkNetwork.disconnect')}
          className="text-status-error-500 hover:text-status-error-500 hover:no-underline"
          iconElement={<Icon name="ErrorCircleIcon" color="status-error-500" />}
          onClick={onDisconnectClick}
        >
          {t('bvnkNetwork.disconnect')}
        </Button>
      ),
    },
  ]

  return (
    <Box testid="connection-item-context-menu">
      <PopoverMenu
        menuItems={menuItems}
        placement={'bottomRight' as PopoverPlacement}
        hoverClass="cursor-pointer"
      >
        <Icon color="grey-08" name="MeatballIcon" />
      </PopoverMenu>

      <ConnectionConfirmationModal
        connection={connection}
        connectionStatus={ConnectionStatusTypes.DISCONNECTED}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
      />
    </Box>
  )
}
