import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { logError } from '@node-space/utils'
import { rootInitialState } from 'components/FilterBar/context/reducer'
import useFilterFromTo from 'components/FilterBar/hooks/useFilterFromTo'
import useFilterMerchant from 'components/FilterBar/hooks/useFilterMerchant'
import useFilterSearch from 'components/FilterBar/hooks/useFilterSearch'
import useFilterStatus from 'components/FilterBar/hooks/useFilterStatus'
import { DateFormat } from 'constants/General'
import { useChannelsPaymentsQuery } from 'hooks/queries/useChannelsPaymentsQuery'
import { getAllChannelsPaymentsCSV } from 'services/PaymentsService'
import { BaseErrorResponse } from 'types/beneficiaries'
import { PaymentList } from 'types/payments'
import { QueryMetaType } from 'types/reactQuery'
import { IDateValue } from 'types/types'

const useChannelsApi = () => {
  const [isError, setIsError] = useState(false)
  const [isExportingCSV, setIsExportingCSV] = useState(false)
  const [error, setError] = useState(undefined)

  const { resetDateRange } = useFilterFromTo()
  const { status, setStatus, resetStatus } = useFilterStatus()
  const { merchant, setMerchant, resetMerchant } = useFilterMerchant()
  const { search, setSearch, resetSearch, resetIsSearchUsed } = useFilterSearch()

  const formattedStartDate = dayjs(rootInitialState?.from).format(DateFormat.YYYY_MM_DD)
  const formattedEndDate = dayjs(rootInitialState?.to).format(DateFormat.YYYY_MM_DD)

  const [dateValue, setDateValue] = useState<IDateValue>({
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  })

  const onDateSelected = (dateVal: IDateValue) => {
    setDateValue(dateVal)
  }

  const [paymentsList, setPaymentsList] = useState<PaymentList>({
    enteredTo: new Date(Date.now()),
    visible: false,
    isTransactionsLoaded: false,
    transactions: [],
    paging: { offset: 0, total: 0, max: 20 },
    pagingMax: 20,
    selectedPayloadOpen: false,
  })

  const { paging, pagingMax } = paymentsList

  const meta: QueryMetaType = {
    onSuccess: res => processPayments(res),
    onErrorAdditional: (error: BaseErrorResponse) => {
      console.error('Error on useChannelsPaymentsQuery', error)
      setError(error)
      setIsError(true)
    },
  }

  const { refetch, isFetching } = useChannelsPaymentsQuery(
    {
      paging,
      from: dateValue?.startDate?.toString(),
      to: dateValue?.endDate?.toString(),
      merchant,
      status,
      search,
    },
    {
      meta,
    }
  )

  const processPayments = res => {
    const transactions = res?.data || []
    setPaymentsList(prev => ({
      ...prev,
      isTransactionsLoaded: true,
      transactions: [...transactions],
      paging: {
        ...prev?.paging,
        total: res?.paging?.total || 0,
        offset: res?.paging?.offset || 0,
      },
    }))
  }

  useEffect(() => {
    refetch()
  }, [search])

  useEffect(() => {
    if (
      dateValue?.startDate === formattedStartDate &&
      dateValue?.endDate === formattedEndDate &&
      status === rootInitialState.status &&
      merchant === rootInitialState.merchant
    ) {
      refetch()
    }
  }, [dateValue?.startDate, dateValue?.endDate, status, merchant])

  useEffect(() => {
    refetch()
  }, [paging.offset])

  useEffect(() => {
    return () => {
      resetSearch()
      resetDateRange()
      resetStatus()
      resetMerchant()
    }
  }, [])

  const handleExportClicked = useCallback(async () => {
    setIsExportingCSV(true)

    try {
      const res = await getAllChannelsPaymentsCSV(
        dateValue?.startDate?.toString(),
        dateValue?.endDate?.toString(),
        status,
        search,
        merchant
      )
      const data = res?.data
      try {
        fileDownload(
          data,
          `channels-${dateValue?.startDate}-${dateValue?.endDate}${search ? `-${search}` : ''}.csv`,
          'application/octet-stream'
        )
      } catch (err) {
        setIsError(true)
        logError('Error from useChannelApi - handleExportClicked - try FileDownload', err)
      }
      setIsExportingCSV(false)
    } catch (err) {
      logError(
        'Error from useChannelApi - handleExportClicked - try getAllChannelsPaymentsCSV',
        err
      )
      setIsExportingCSV(false)
      setIsError(true)
    }
  }, [dateValue?.startDate, dateValue?.endDate, status, search, merchant])

  const onSearchChanged = search => setSearch(search)

  const onPaymentStatusChanged = (status: string) => setStatus(status)

  const onMerchantSelected = useCallback(
    merchant => {
      setMerchant(merchant)
    },
    [dateValue?.startDate, dateValue?.endDate, status, merchant, search, paging, pagingMax]
  )

  const onSuccessPayment = useCallback(() => {
    refetch()
  }, [dateValue?.startDate, dateValue?.endDate, status, merchant, search, paging, pagingMax])

  const onPageChange = useCallback(
    ({ paging: { offset } }) => {
      setPaymentsList(prev => ({
        ...prev,
        paging: {
          ...paging,
          offset: offset,
          max: pagingMax,
        },
      }))
    },
    [dateValue?.startDate, dateValue?.endDate, status, merchant, search, paging, pagingMax]
  )

  const onResetAll = () => {
    setDateValue({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
    setStatus(rootInitialState?.status)
    setMerchant(rootInitialState?.merchant)
    setSearch(rootInitialState?.search)
    resetIsSearchUsed()
  }

  return {
    executor: refetch,
    isLoading: isFetching,
    isError,
    error,
    data: { paymentsList, dateValue, merchant, search, status, isExportingCSV },
    actions: {
      onDateSelected,
      handleExportClicked,
      onSearchChanged,
      onPaymentStatusChanged,
      onMerchantSelected,
      onSuccessPayment,
      onPageChange,
      onResetAll,
      setPaymentsList,
      showSearchResult: refetch,
    },
  }
}

export default useChannelsApi
