import React from 'react'
import { Text, TextColor, TextSize } from '@node-space/storybook-components'
import { TextAlignType } from 'types/text'

export interface DescriptionProps {
  children: React.ReactNode
  align?: TextAlignType
  size?: TextSize
  color?: TextColor
}

export const Description = ({
  children,
  size = TextSize.SM,
  color = TextColor.GREY,
  align,
}: DescriptionProps) => {
  return (
    <Text size={size} color={color} align={align}>
      {children}
    </Text>
  )
}
