import React from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import { NetworkConnectionViewType } from 'types/bvnkNetwork'
import ConnectionsEmptyState from './ConnectionsEmptyState'
import ConnectionsList from './ConnectionsList'
import { ConnectionItem } from './ConnectionsListItem'

export interface ContentProps {
  networkConnectionViewType: NetworkConnectionViewType
  connectionList: ConnectionItem[]
}

export const ConnectionsTabContent = ({
  connectionList,
  networkConnectionViewType,
}: ContentProps) => {
  if (!connectionList?.length) {
    return (
      <Box paddingT={16}>
        <ConnectionsEmptyState networkConnectionViewType={networkConnectionViewType} />
      </Box>
    )
  } else {
    return (
      <Box border="gray">
        <ConnectionsList connections={connectionList} />
      </Box>
    )
  }
}
