import React, { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { Input } from '@node-space/storybook-components/dist/Input'
import { ModalActions, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { Select } from '@node-space/storybook-components/dist/Select'
import { useBicValidation } from 'hooks/queries/useBicValidation'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useIbanValidation } from 'hooks/queries/useIbanValidation'
import { useAddBeneficiaryContext } from 'hooks/useAddBeneficiaryContext'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import {
  AddBeneficiaryBankDetailsPayload,
  AddBeneficiaryBankDetailsPayloadKeys,
  BeneficiaryDetailsCurrency,
  ValidateIbanResponse,
} from 'types/beneficiaries'
import {
  getInternationalAccountNumberLabel,
  getInternationalAccountNumberPlaceholder,
  getInternationalBankCodeLabel,
  getInternationalBankCodePlaceholder,
  setFieldsFetchedFromIbanValidation,
} from 'utils/beneficiaries'
import { addBeneficiaryBankDetailsSchema } from '../schemas/addBeneficiaryBankDetailsSchema'

interface BeneficiaryBankDetailsProps {
  onBack: () => void
  onContinue: (bankDetails: AddBeneficiaryBankDetailsPayload) => void
  isSubmitting: boolean
}

export const BeneficiaryBankDetails = ({
  onBack,
  onContinue,
  isSubmitting,
}: BeneficiaryBankDetailsProps) => {
  const { t } = useTranslation()
  const { data: countries, isPending: countriesLoading } = useCountriesQuery(true)
  const {
    beneficiaryFormState,
    beneficiaryFormErrors,
    setBeneficiaryFormErrors,
    beneficiaryIbanValidationState,
    setBeneficiaryIbanValidationState,
    beneficiaryBicValidationState,
    setBeneficiaryBicValidationState,
  } = useAddBeneficiaryContext()
  const { setRequestError } = useMappedErrorMessage()
  const formSchema = addBeneficiaryBankDetailsSchema(t)
  const { register, formState, setValue, control, trigger, clearErrors, watch, setError } =
    useForm<AddBeneficiaryBankDetailsPayload>({
      resolver: yupResolver(formSchema),
    })
  const [ibanValidationData, setIbanValidationData] = useState<AddBeneficiaryBankDetailsPayload>(
    beneficiaryIbanValidationState
  )

  const [
    intlIban,
    bicSwiftCode,
    bankName,
    bankAddressLine1,
    bankAddressLine2,
    bankCity,
    bankPostCode,
    bankRegion,
    bankCountry,
  ] = useWatch({
    control,
    name: [
      'intlIban',
      'bicSwiftCode',
      'bankName',
      'bankAddressLine1',
      'bankAddressLine2',
      'bankCity',
      'bankPostCode',
      'bankRegion',
      'bankCountry',
    ],
    defaultValue: {
      intlIban: '',
      bicSwiftCode: '',
      bankName: '',
      bankAddressLine1: '',
      bankAddressLine2: '',
      bankCity: '',
      bankPostCode: '',
      bankRegion: '',
      bankCountry: '',
    },
  })

  const {
    isFetching: isValidatingIban,
    refetch: validateIban,
    isError: isNotAnIban,
  } = useIbanValidation(intlIban, 'IBAN', setRequestError, false)

  const {
    data: bicValidation,
    refetch: validateBic,
    isFetching: isValidatingBic,
  } = useBicValidation(bicSwiftCode, setRequestError, false)

  const formValues = watch()

  useEffect(() => {
    setCachedValues()
    setCachedIbanValidationValues()
  }, [])

  useEffect(() => {
    if (beneficiaryFormErrors) {
      Object.keys(beneficiaryFormErrors).forEach((field: AddBeneficiaryBankDetailsPayloadKeys) => {
        setError(field, beneficiaryFormErrors[field])
      })
    } else {
      clearErrors()
    }
  }, [setError, clearErrors, beneficiaryFormErrors])

  const setCachedValues = () => {
    Object.keys(beneficiaryFormState || {}).forEach(
      (field: keyof AddBeneficiaryBankDetailsPayload) => {
        if (beneficiaryFormState?.[field]) {
          setValue(field, beneficiaryFormState?.[field])
        }
      }
    )
  }

  const setCachedIbanValidationValues = () => {
    Object.keys(beneficiaryIbanValidationState || {}).forEach(
      (field: keyof AddBeneficiaryBankDetailsPayload) => {
        if (beneficiaryIbanValidationState?.[field]) {
          setValue(field, beneficiaryIbanValidationState?.[field])
        }
      }
    )
  }

  const countryOptions = useMemo(() => {
    if (countries?.length) {
      return countries?.map(country => ({
        label: country?.name,
        value: country?.code,
      }))
    }

    return []
  }, [countries])

  const handleChange = (field: keyof AddBeneficiaryBankDetailsPayload, value: string) => {
    setValue(field, value)
  }

  const setBankDetailsFromApi = (bankDetails: ValidateIbanResponse) => {
    const details = setFieldsFetchedFromIbanValidation(bankDetails)
    setIbanValidationData(details)
    setBeneficiaryIbanValidationState(details)
    if (Object.keys(details || {})?.length) {
      Object.keys(details)?.forEach(detail => {
        if (detail === 'bankCountry') {
          handleCountrySelect(details[detail])
        } else {
          handleChange(detail as keyof AddBeneficiaryBankDetailsPayload, details[detail])
        }
      })
    } else {
      setIbanValidationData(null)
    }
  }

  const fieldNames: AddBeneficiaryBankDetailsPayloadKeys[] = [
    'bicSwiftCode',
    'bankName',
    'bankAddressLine1',
    'bankAddressLine2',
    'bankCity',
    'bankPostCode',
    'bankRegion',
    'bankCountry',
  ]

  const currencyCode = beneficiaryFormState?.currencyCode
  const isUSDCurrency = currencyCode === BeneficiaryDetailsCurrency.USD

  const handleInternationalIbanOnBlur = async () => {
    if (!isUSDCurrency) {
      if (beneficiaryIbanValidationState) {
        setIbanValidationData(null)
        setBeneficiaryIbanValidationState(null)
        fieldNames.forEach(fieldName => {
          setValue(fieldName as keyof AddBeneficiaryBankDetailsPayload, '')
        })
      }
      clearErrors()
      const ibanAccountDetails = intlIban && (await validateIban())
      const accountDetails = ibanAccountDetails?.data
      const isValidIban =
        (!ibanAccountDetails && ibanValidationData && !beneficiaryIbanValidationState) ||
        isNotAnIban

      if (isValidIban) {
        Object.keys(ibanValidationData || {})?.forEach(detail => {
          if (detail === 'bankCountry') {
            handleCountrySelect('')
          } else {
            handleChange(detail as keyof AddBeneficiaryBankDetailsPayload, '')
          }
        })
        setIbanValidationData(null)
      } else if (!accountDetails?.validationFailed?.length) {
        setBankDetailsFromApi(accountDetails)
      } else if (accountDetails?.validationFailed?.length >= 1) {
        setBeneficiaryFormErrors({
          ...beneficiaryFormErrors,
          intlIban: { message: t('invalidAccountNumberErrorMessage') },
        })
      }
    }
  }

  const handleInternationalBicOnBlur = async () => {
    if (beneficiaryBicValidationState) {
      setBeneficiaryBicValidationState(null)
    }
    clearErrors()
    if (isUSDCurrency) {
      const bicValidationResponse = bicSwiftCode && (await validateBic())
      if (bicValidationResponse && bicValidationResponse?.data !== 'Valid') {
        setBeneficiaryFormErrors({
          ...beneficiaryFormErrors,
          bicSwiftCode: { message: t('invalidBicCodeErrorMessage') },
        })
      } else {
        setBeneficiaryBicValidationState(bicValidationResponse?.data)
      }
    }
  }

  const isBicValid = bicValidation === 'Valid'

  const submitBankAddressAccountDetails = async () => {
    const validateFields: AddBeneficiaryBankDetailsPayloadKeys[] = [
      'bicSwiftCode',
      'bankName',
      'bankAddressLine1',
      'bankCity',
      'bankPostCode',
      'bankRegion',
      'bankCountry',
    ]

    if (formState?.errors?.intlIban?.message === t('invalidAccountNumberErrorMessage')) {
      clearErrors(fieldNames as AddBeneficiaryBankDetailsPayloadKeys[])
      await trigger(validateFields)
    } else {
      validateFields.push('intlIban')
      clearErrors()

      const verifyFields = await trigger(validateFields)

      if (verifyFields) {
        return onContinue(formValues)
      }
    }
  }

  const actions: ButtonProps[] = [
    {
      children: t('back'),
      testid: 'back',
      secondary: true,
      onClick: () => {
        clearErrors()
        onBack()
      },
    },
    {
      children: t('continue'),
      testid: 'continue',
      onClick: () => submitBankAddressAccountDetails(),
      loading: isSubmitting || isValidatingIban,
      disabled: currencyCode === BeneficiaryDetailsCurrency.USD && !isBicValid,
    },
  ]

  const handleCountrySelect = (countryCode: string) => {
    const countryName = countryOptions.find(country => country?.value === countryCode)?.label
    setValue('bankCountryName', countryName)
    setValue('bankCountry', countryCode)
  }

  return (
    <ModalScrollable>
      <Box padding={24}>
        <Box paddingB={16}>
          <Input
            {...register('intlIban')}
            label={getInternationalAccountNumberLabel(currencyCode, t)}
            inputMode="text"
            name="intlIban"
            testid="input-intl-iban"
            errorText={formState?.errors?.intlIban?.message}
            error={!!formState?.errors?.intlIban}
            value={intlIban}
            disabled={isValidatingIban}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('intlIban', event?.target?.value)
            }
            onBlur={handleInternationalIbanOnBlur}
            isValid={!!ibanValidationData?.intlIban}
            placeholder={getInternationalAccountNumberPlaceholder(currencyCode, t)}
          />
        </Box>
        <Box paddingB={16}>
          <Input
            {...register('bicSwiftCode')}
            label={getInternationalBankCodeLabel(currencyCode, t)}
            inputMode="text"
            name="bicSwiftCode"
            testid="input-bic-swift"
            errorText={formState?.errors?.bicSwiftCode?.message}
            error={!!formState?.errors?.bicSwiftCode}
            value={bicSwiftCode}
            disabled={
              isValidatingIban || !!beneficiaryIbanValidationState?.bicSwiftCode || isValidatingBic
            }
            onChange={event => handleChange('bicSwiftCode', event?.target?.value)}
            placeholder={getInternationalBankCodePlaceholder(currencyCode, t)}
            isValid={(!!ibanValidationData?.bicSwiftCode || isBicValid) && isUSDCurrency}
            onBlur={handleInternationalBicOnBlur}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('bankName')}
            label={t('beneficiarysBankName')}
            inputMode="text"
            name="bankName"
            testid="input-bank-name"
            errorText={formState?.errors?.bankName?.message}
            error={!!formState?.errors?.bankName}
            value={bankName}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankName}
            onChange={event => handleChange('bankName', event?.target?.value)}
            placeholder={t('enterBankName')}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('bankAddressLine1')}
            label={t('bankAddressLine1')}
            inputMode="text"
            name="bankAddressLine1"
            testid="input-bank-address-line-1"
            errorText={formState?.errors?.bankAddressLine1?.message}
            error={!!formState?.errors?.bankAddressLine1}
            value={bankAddressLine1}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankAddressLine1}
            onChange={event => handleChange('bankAddressLine1', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterAddressLine1Placeholder')}
          />
        </Box>
        <Box flex flexRow paddingB={16}>
          <Input
            {...register('bankAddressLine2')}
            label={t('bankAddressLine2')}
            inputMode="text"
            name="bankAddressLine2"
            testid="input-bank-address-line-2"
            errorText={formState?.errors?.bankAddressLine2?.message}
            error={!!formState?.errors?.bankAddressLine2}
            value={bankAddressLine2}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankAddressLine2}
            onChange={event => handleChange('bankAddressLine2', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterAddressLine2Placeholder')}
          />
        </Box>
        <Box flex flexRow gapX={12} paddingB={16}>
          <Input
            {...register('bankCity')}
            label={t('city')}
            inputMode="text"
            name="city"
            testid="input-bank-city"
            errorText={formState?.errors?.bankCity?.message}
            error={!!formState?.errors?.bankCity}
            value={bankCity}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankCity}
            onChange={event => handleChange('bankCity', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterCityPlaceholder')}
          />
          <Input
            {...register('bankPostCode')}
            label={t('postCode')}
            inputMode="text"
            name="bic"
            testid="input-bank-post-code"
            errorText={formState?.errors?.bankPostCode?.message}
            error={!!formState?.errors?.bankPostCode}
            value={bankPostCode}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankPostCode}
            onChange={event => handleChange('bankPostCode', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterPostalCodePlaceholder')}
          />
        </Box>
        <Box flex flexRow gapX={12} paddingB={16}>
          <Input
            {...register('bankRegion')}
            label={t('region')}
            inputMode="text"
            name="bankRegion"
            testid="input-bank-region"
            errorText={formState?.errors?.bankRegion?.message}
            error={!!formState?.errors?.bankRegion}
            value={bankRegion}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankRegion}
            onChange={event => handleChange('bankRegion', event?.target?.value)}
            placeholder={t('inputPlaceholders.enterRegionPlaceholder')}
          />
        </Box>
        <Box flexSize="fill">
          <Select
            {...register('bankCountry')}
            label={t('country')}
            value={bankCountry}
            isSearchable
            options={countryOptions}
            error={!!formState?.errors?.bankCountry}
            errorText={formState?.errors?.bankCountry?.message}
            placeholder={t('searchOrSelectACountry')}
            loading={countriesLoading}
            disabled={isValidatingIban || !!beneficiaryIbanValidationState?.bankCountry}
            onChange={handleCountrySelect}
          />
        </Box>
      </Box>
      <ModalActions actions={actions} />
    </ModalScrollable>
  )
}
