import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useAuthQuery } from 'hooks/useAuthQuery'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getAccounts } from 'services/AuthService'

export const useAccountsQuery = (isEnabled = true) => {
  const { isQueryAllowed } = useAuthQuery()

  return useQuery({
    queryKey: reactQueryKeys.accounts(),
    queryFn: getAccounts,
    placeholderData: keepPreviousData,
    enabled: isEnabled && isQueryAllowed,
    staleTime: Infinity,
  })
}
