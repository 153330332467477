import { requestApi } from 'api/api'
import {
  FiatPayout,
  MassPayoutCryptoBatch,
  MassPayoutPostAuthRequest,
} from 'pages/MassPayouts/types'
import { UUID } from 'types/beneficiaries'
import { WithPaginationApiResponse } from 'types/pagination'
import { DocumentUpload } from 'types/verification'

//------------------- CRYPTO --------------------------------------------------------------

interface PostMassCryptoPayoutsRequest {
  merchantId: string
  file: DocumentUpload
  enableMassPayoutsV2: boolean
}

export const postMassCryptoPayouts = ({
  merchantId,
  file,
  enableMassPayoutsV2,
}: PostMassCryptoPayoutsRequest) => {
  const formData = new FormData()

  formData.append('merchantId', merchantId) //append the values with key, value pair
  formData.append('file', file.fileData)

  const createMassPayoutUrl = enableMassPayoutsV2 ? '/v1/pay/masspayout/new' : '/v1/pay/masspayout'

  return requestApi({
    url: createMassPayoutUrl,
    method: 'POST',
    data: formData,
  })
}

export const getAllCryptoMassPayouts = (): Promise<MassPayoutCryptoBatch[]> => {
  return requestApi({
    url: '/v1/pay/masspayout',
    method: 'GET',
    params: {
      max: 20,
      sort: 'createdAt',
      order: 'desc',
    },
  })
}

export const getAllCryptoMassPayoutsByPolling = (): Promise<MassPayoutCryptoBatch[]> => {
  return requestApi({
    url: `/v1/pay/masspayout/status`,
    params: {
      max: 20,
      sort: 'createdAt',
      order: 'desc',
    },
    method: 'GET',
  })
}

interface GetCryptoMassPayoutByIdRequest {
  batchId: string
}

export const getCryptoMassPayoutById = ({
  batchId,
}: GetCryptoMassPayoutByIdRequest): Promise<MassPayoutCryptoBatch> => {
  return requestApi({
    url: `/v1/pay/masspayout/${batchId}?status=DRAFT,ERROR`,
    method: 'GET',
  })
}

interface DeleteCryptoPayoutByIdRequest {
  batchId: string
  externalId: string
}

export const deleteCryptoPayoutById = ({ batchId, externalId }: DeleteCryptoPayoutByIdRequest) => {
  return requestApi({
    url: `/v1/pay/masspayout/${batchId}/items/${externalId}`,
    method: 'DELETE',
  })
}

interface PutUpdateCryptoPayoutByIdRequest {
  batchId: string
  externalId: string
  fundingWalletAmount?: string
  paidCurrencyAmount?: string
  currency: string
  protocol: string
  address: string
  reference: string
  tag?: string
  onlyValidate?: boolean
}

export const putUpdateCryptoPayoutById = ({
  batchId,
  externalId,
  fundingWalletAmount,
  paidCurrencyAmount,
  currency,
  protocol,
  address,
  reference,
  tag = '',
  onlyValidate = false,
}: PutUpdateCryptoPayoutByIdRequest) => {
  return requestApi({
    url: `/v1/pay/masspayout/${batchId}/items/${externalId}`,
    method: 'PUT',
    params: {
      ...(onlyValidate && { onlyValidate }),
    },
    data: {
      fundingWalletAmount,
      paidCurrencyAmount,
      currency,
      protocol,
      address,
      reference,
      tag,
    },
  })
}

interface PutSubmitCryptoMassPayoutByIdRequest {
  batchId: string
}

export const putSubmitCryptoMassPayoutById = ({
  batchId,
}: PutSubmitCryptoMassPayoutByIdRequest) => {
  return requestApi({
    url: `/v1/pay/masspayout/${batchId}`,
    method: 'PUT',
  })
}

//-------------------------------------- FIAT --------------------------------------

interface MassFiatPayoutRequest {
  walletId: string
  file: DocumentUpload
}

export const postMassFiatPayouts = ({ walletId, file }: MassFiatPayoutRequest) => {
  const formData = new FormData()

  formData.append('walletId', walletId)
  formData.append('file', file.fileData)

  return requestApi({
    url: '/v2/fiat/pay/batch',
    method: 'POST',
    data: formData,
  })
}

export const patchMassFiatPayoutsPreChallengeRequest = (batchReference: string) => {
  return requestApi({
    url: `/v2/fiat/pay/batch/${batchReference}/trigger`,
    method: 'PATCH',
  })
}

export const postMassFiatPayoutsPostChallengeRequest = ({
  challenge,
  tokenResponse,
}: {
  challenge: UUID
  tokenResponse: string
}): Promise<MassPayoutPostAuthRequest> => {
  return requestApi({
    url: '/v2/fiat/pay/operation-authorizations',
    method: 'POST',
    data: {
      challenge,
      tokenResponse,
    },
  })
}
export interface MassFiatPayoutsDetailsRequest {
  batchReference: string
}

export const getMassFiatPayoutsDetails = ({
  batchReference,
}: MassFiatPayoutsDetailsRequest): Promise<FiatPayout> => {
  return requestApi({
    url: `/v2/fiat/pay/batch/${batchReference}`,
    method: 'GET',
  })
}

export const getMassFiatPayoutsList = ({
  size,
  page,
}: {
  size: number
  page: number
}): Promise<WithPaginationApiResponse<FiatPayout[]>> => {
  return requestApi({
    url: `/v2/fiat/pay/batch`,
    method: 'GET',
    params: {
      page,
      sort: 'createdAt,desc',
      ...(size && { size }),
    },
  })
}

export const patchPreprocessedRecords = async ({ reference, data }) => {
  return requestApi({
    url: `/v2/fiat/pay/batch/record/${reference}`,
    method: 'PATCH',
    data,
  })
}

export const deletePreprocessedRecords = async ({ reference }) => {
  return requestApi({
    url: `/v2/fiat/pay/batch/record/${reference}`,
    method: 'DELETE',
  })
}
