import { WithdrawForm } from 'pages/Wallets/components/Withdraw/Withdraw'
import { WithdrawV2Form } from 'pages/Wallets/components/Withdraw/WithdrawV2'
import { PayoutsV2PreAuthRequest } from 'types/payoutsV2'
import { WalletType } from 'types/types'

// TODO: Remove when V2 migration over
export const getWithdrawRequest = (
  formValues: WithdrawForm,
  selectedWallet: WalletType,
  isPayoutsV2Enabled: boolean
) => {
  return {
    walletId: isPayoutsV2Enabled ? selectedWallet?.lsid : selectedWallet?.id?.toString(),
    amount: {
      value: Number(formValues?.amount),
      currency: selectedWallet?.currency?.code,
    },
    paymentReference: formValues?.reference,
    beneficiary: {
      reference: formValues?.bankAccountId,
    },
  } as PayoutsV2PreAuthRequest
}

export const getWithdrawV2Request = (
  formValues: WithdrawV2Form,
  selectedWallet: WalletType,
  isPayoutsV2Enabled: boolean
) => {
  return {
    walletId: isPayoutsV2Enabled ? selectedWallet?.lsid : selectedWallet?.id?.toString(),
    amount: {
      value: Number(formValues?.amount),
      currency: selectedWallet?.currency?.code,
    },
    paymentReference: formValues?.reference,
    beneficiary: {
      reference: formValues?.beneficiaryReference,
    },
  } as PayoutsV2PreAuthRequest
}
