import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { AlignActions, ModalActions } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { WalletAction } from 'pages/Wallets/components/Modal/WalletModal'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { ApprovalStatuses } from 'types/approvals'

interface RequiresApprovalSuccessModalProps {
  onClose: () => void
  actionType?: WalletAction
}

const RequiresApprovalSuccessModal = ({ onClose }: RequiresApprovalSuccessModalProps) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const { currentAccount } = useAccountsContext()
  const currentAccountReference = currentAccount?.reference

  return (
    <Box paddingT={32}>
      <Box
        flex
        direction="col"
        alignItems="center"
        justifyContent="center"
        paddingY={20}
        paddingX={20}
      >
        <Icon color="inherit" name="HourglassIcon" />
        <Text align="center" className="pt-3" weight="semibold">
          {t('approvals.sentForApproval')}
        </Text>
        <Text align="center" color="disabled" size="sm" className="pt-4 pb-6">
          {t('approvals.sentForApprovalDisclaimer')}
        </Text>
      </Box>
      <ModalActions
        actions={[
          {
            children: t('done'),
            onClick: () => {
              onClose()
              queryClient.invalidateQueries({
                queryKey: reactQueryKeys.approvalsTransactions(
                  currentAccountReference,
                  ApprovalStatuses.PENDING
                ),
              })
            },
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </Box>
  )
}

export default RequiresApprovalSuccessModal
