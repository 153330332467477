import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import Accordion from '@node-space/storybook-components/dist/Accordion'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  AlignActions,
  Modal,
  ModalActions,
  ModalNavBar,
} from '@node-space/storybook-components/dist/Modal'
import { OnceOffExportContextProvider } from 'contexts/onceOffExportContext'
import { RecurringExportContextProvider } from 'contexts/recurringExportContext'
import { useReportScheduleQuery } from 'hooks/queries/useReportScheduleQuery'
import { useReportScheduleTimezonesQuery } from 'hooks/queries/useReportScheduleTimezonesQuery'
import {
  ExportAccordionItems,
  ExportAccordionItemsType,
  OnceOffExportSteps,
  RecurringExportForm,
  RecurringExportSteps,
} from 'types/walletTransactionsExport'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { useOnceOffExportHelper } from './hooks/useOnceOffExportHelper'
import { useRecurringExportHelper } from './hooks/useRecurringExportHelper'
import { OnceOffExport } from './OnceOffExport/OnceOffExport'
import { OnceOffExportComplete } from './OnceOffExport/OnceOffExportComplete'
import { RecurringExport } from './RecurringExport/RecurringExport'
import { RecurringExportComplete } from './RecurringExport/RecurringExportComplete'
import { TransactionExportModalActions } from './TransactionExportModalActions'

interface TransactionExportModalProps {
  handleClose: () => void
  showCustomDateExportModal: boolean
  downloadAll: boolean
}

export const TransactionExportModal = ({
  handleClose,
  showCustomDateExportModal,
  downloadAll,
}: TransactionExportModalProps) => {
  const { t } = useTranslation()
  const [openAccordion, setOpenAccordion] = useState<ExportAccordionItemsType>(null)
  const [closeAllItems, setCloseAllItems] = useState(false)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.EXPORT_TRANSACTION_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.LOAD,
    })
  }, [])

  const onceOffValues = useOnceOffExportHelper({ downloadAll, handleClose })
  const recurringValues = useRecurringExportHelper({
    handleClose,
    setOpenAccordion,
    setCloseAllItems,
  })

  const { control } = recurringValues.form

  const recurringformValues = useWatch({ control }) as RecurringExportForm

  const { enableRecurringExports } = useFeatureFlags()

  const { isFetching: isFetchingTimezones } = useReportScheduleTimezonesQuery(
    openAccordion === ExportAccordionItems.RECURRING &&
      recurringValues?.step === RecurringExportSteps.FORM
  )

  const { data: reportSchedules = [] } = useReportScheduleQuery(
    openAccordion === ExportAccordionItems.RECURRING
  )

  const isLoadingCreateSchedule =
    isFetchingTimezones || recurringValues?.isLoadingPostReportSchedule
  const isLoadingUpdateSchedule = isFetchingTimezones || recurringValues?.isLoadingPutReportSchedule

  const onceOffExportModalActions: ButtonProps[] = [
    {
      children: t('cancel'),
      onClick: handleClose,
      secondary: true,
    },
    {
      children: t('walletsPage.exportToEmail'),
      onClick: onceOffValues?.handleTransactionExportToEmail,
      loading: onceOffValues?.isExporting,
      secondary: false,
    },
  ]

  const recurringExportModalActions: ButtonProps[] = [
    {
      children: t('cancel'),
      onClick: handleClose,
      secondary: true,
    },
    ...(recurringValues?.isUpdatingReportSchedule
      ? [
          {
            children: t('exports.updateSchedule'),
            onClick: () => recurringValues?.updateReportSchedule(recurringformValues),
            disabled: isLoadingUpdateSchedule,
            ...(isLoadingUpdateSchedule && {
              iconElement: <Loader />,
            }),
          },
        ]
      : [
          {
            children: t('exports.createSchedule'),
            onClick: () => recurringValues?.createReportSchedule(recurringformValues),
            disabled: isLoadingCreateSchedule || !!reportSchedules?.length,
            ...(isLoadingCreateSchedule && {
              iconElement: <Loader />,
            }),
          },
        ]),
  ]

  const handleOnItemOpen = (name: string) => {
    setOpenAccordion(name as ExportAccordionItemsType)
    recurringValues?.setStep(RecurringExportSteps.LIST)
    onceOffValues?.setStep(OnceOffExportSteps.FORM)
    recurringValues?.setCurrentReportSchedule(null)
    recurringValues?.setIsUpdatingReportSchedule(false)
  }

  return (
    <Modal
      closeOnBackgroundClick={false}
      closeModal={handleClose}
      visible={showCustomDateExportModal}
    >
      {enableRecurringExports && downloadAll ? (
        <>
          {onceOffValues?.step !== OnceOffExportSteps.COMPLETE &&
          recurringValues?.step !== RecurringExportSteps.COMPLETE ? (
            <>
              <ModalNavBar
                title={t('walletsPage.exportTransactionNavBarTitle')}
                onClose={handleClose}
              />
              <Box paddingT={8} paddingB={16} paddingX={16}>
                <Accordion
                  borderless
                  isSlim
                  bodyClassOverrides="!p-0"
                  defaultOpen={ExportAccordionItems?.RECURRING}
                  closeAll={closeAllItems}
                  getOpenName={name => {
                    handleOnItemOpen(name)
                  }}
                  items={[
                    {
                      name: 'once-off',
                      title: t('exports.onceOff'),
                      children: (
                        <OnceOffExportContextProvider value={onceOffValues}>
                          <OnceOffExport downloadAll={downloadAll} />
                        </OnceOffExportContextProvider>
                      ),
                    },
                    {
                      name: 'recurring',
                      title: t('exports.recurring'),
                      children: (
                        <RecurringExportContextProvider value={recurringValues}>
                          <RecurringExport downloadAll={downloadAll} />
                        </RecurringExportContextProvider>
                      ),
                    },
                  ]}
                />
              </Box>
              <TransactionExportModalActions
                openAccordion={openAccordion}
                onceOffExportModalActions={onceOffExportModalActions}
                recurringExportModalActions={recurringExportModalActions}
              />
            </>
          ) : (
            <>
              {onceOffValues?.step === OnceOffExportSteps.COMPLETE && (
                <OnceOffExportComplete
                  handleClose={handleClose}
                  exportFormat={onceOffValues.exportFormat}
                />
              )}
              {recurringValues?.step === RecurringExportSteps.COMPLETE && (
                <RecurringExportComplete
                  isUpdatingReportSchedule={recurringValues?.isUpdatingReportSchedule}
                  reportSchedule={recurringValues?.currentReportSchedule}
                  handleClose={handleClose}
                />
              )}
            </>
          )}
        </>
      ) : (
        <OnceOffExportContextProvider value={onceOffValues}>
          <OnceOffExport downloadAll={downloadAll} />
          {onceOffValues?.step !== OnceOffExportSteps.COMPLETE && (
            <ModalActions actions={onceOffExportModalActions} alignActions={AlignActions.RIGHT} />
          )}
        </OnceOffExportContextProvider>
      )}
    </Modal>
  )
}
