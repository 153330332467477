import React from 'react'
import { Box } from '@node-space/storybook-components'
import { useTitleTransaction } from 'pages/WalletDetails/hooks/useTitleTransaction'
import { Transaction } from 'types/transaction'

interface TransactionTitleProps {
  transaction: Transaction
}

export const TransactionTitle = ({ transaction }: TransactionTitleProps) => {
  const { title } = useTitleTransaction(transaction)

  return (
    <Box tag="span" flex alignItems="center" gap={12} width="full" maxWidth={320}>
      {title}
    </Box>
  )
}
