import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { AddBeneficiaryBankDetailsPayload } from 'types/beneficiaries'
import { createAddressSchema } from 'utils/yupSchemas/addressSchemas'
import {
  ALPHANUMERIC_VALIDATION,
  BENEFICIARY_NAME_VALIDATION_PATTERN,
  createNameSchema,
} from 'utils/yupSchemas/createNameSchema'

export const addBeneficiaryBankDetailsSchema = (t: TFunction<'translation', undefined>) => {
  const intlIban = createNameSchema(t('accountNumber'), t, 7, null, ALPHANUMERIC_VALIDATION)
  const bicSwiftCode = createNameSchema(t('bicSwiftCode'), t, 8, null, ALPHANUMERIC_VALIDATION)
  const bankName = createNameSchema(
    t('beneficiarysBankName'),
    t,
    null,
    null,
    BENEFICIARY_NAME_VALIDATION_PATTERN,
    'bankNameInvalidCharacters'
  )
  const bankAddressLine1 = createAddressSchema(t('bankAddressLine1'), t)
  const bankAddressLine2 = createAddressSchema(t('bankAddressLine2'), t)
  const bankCity = createAddressSchema(t('city'), t)
  const bankPostCode = createAddressSchema(t('postCode'), t)
  const bankRegion = createAddressSchema(t('region'), t)
  const bankCountry = yup.string().trim().label(t('country')).required()

  const formFields = {
    intlIban,
    bicSwiftCode,
    bankName,
    bankAddressLine1,
    bankAddressLine2,
    bankCity,
    bankPostCode,
    bankRegion,
    bankCountry,
  }

  return yup.object<Partial<Record<keyof AddBeneficiaryBankDetailsPayload, yup.AnySchema>>>(
    formFields
  )
}
