import React from 'react'
import i18next from 'i18next'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

interface LatePaymentProps {
  amountWithCurrency: string
  transactionHash: string
}

const LatePayment = ({ amountWithCurrency, transactionHash }: LatePaymentProps) => {
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.paymentInAmountReceived')}
          detail={amountWithCurrency}
        />
      )}
      {transactionHash && (
        <CopyTransactionRow content={transactionHash} label={i18next.t('hash')} />
      )}
    </>
  )
}

export default LatePayment
