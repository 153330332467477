import React from 'react'
import { Box as Row } from '@node-space/storybook-components'
import { Text } from '@node-space/storybook-components/dist/Text'

interface TransactionDetailRowProps {
  label: string
  detail: React.ReactNode
}

export const TransactionDetailRow = ({ label, detail }: TransactionDetailRowProps) => {
  return (
    <Row className="md:flex-row" flex direction="col" paddingT={4} paddingB={4}>
      <Text tag="span" size="sm" color="disabled" className="md:w-2/5">
        {label}
      </Text>
      <Text
        tag="span"
        size="sm"
        weight="medium"
        color="dark-grey"
        className="md:w-3/5 flex md:justify-end md:text-right break-all"
      >
        {detail}
      </Text>
    </Row>
  )
}
