import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Loader } from '@node-space/storybook-components/dist/Loader'
import { Modal, ModalNavBar, ModalScrollable } from '@node-space/storybook-components/dist/Modal'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useEditApiKeyMutation } from 'hooks/mutations'
import { useToastContext } from 'hooks/useToastContext'
import { ApiKey, ApiKeyCreateAPIRequest, ApiKeyRequestForm } from 'types/apiKeys'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { ApiKeyModalContext } from '../../context/ApiKeyModalProvider'
import ApiKeyCreateForm from './../Form/ApiKeyCreateForm'

type PaymentCreateModalProps = {
  from2FA?: boolean
  onSuccessCreateApiKey: (value: ApiKeyRequestForm) => void
  selectedApiKey: ApiKey
  onClose: () => void
}

const ApiKeyManageModal = ({
  onSuccessCreateApiKey,
  onClose,
  selectedApiKey,
}: PaymentCreateModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { profile } = useProfileContext()
  const { showManageModal, setShowManageModal } = useContext(ApiKeyModalContext)
  const { mutate, isPending } = useEditApiKeyMutation()
  const [requestError, setRequestError] = useState('')

  useEffect(() => {
    if (showManageModal) {
      track.Amp.track(AmplitudeEvent.API_KEY_EDIT, {
        category: AmplitudeEventCategory.MERCHANT_PORTAL,
        action: AmplitudeEventAction.VIEW,
      })
    }
  }, [showManageModal])

  const [payload, setPayload] = useState<ApiKeyRequestForm>({
    description: selectedApiKey?.description ?? '',
    withdrawals: selectedApiKey?.canWithdraw ?? true,
    allowedIps:
      selectedApiKey?.allowedIps
        ?.filter(value => value !== '')
        .map(ip => ({ value: ip, label: ip })) || [],
  })

  const closeModalCallback = useCallback(() => {
    setShowManageModal(false)
    setPayload(null)
    onClose()
  }, [setShowManageModal, setPayload])

  const mappedReq = (payload: ApiKeyRequestForm): ApiKeyCreateAPIRequest => {
    const roles = payload.withdrawals
      ? [...profile.roles, 'ROLE_WITHDRAWAL']
      : profile.roles.filter(role => role !== 'ROLE_WITHDRAWAL')

    const allowedIps = payload.allowedIps
      .filter(({ value }) => value !== '')
      .map(({ value }) => value)

    return {
      allowedIps: allowedIps.length ? allowedIps.join(',') : null,
      description: payload.description,
      roles: roles,
    }
  }

  const updateKeyCallback = async (payload: ApiKeyRequestForm) => {
    mutate(
      { id: selectedApiKey.id, payload: mappedReq(payload) },
      {
        onSuccess: () => {
          addToast({ title: t('manageApiKeys.apiKeyUpdated'), state: 'success' })
          track.Amp.track(AmplitudeEvent.EOF_EDIT_API_KEY_SUCCESS, {
            category: AmplitudeEventCategory.MERCHANT_PORTAL,
            action: AmplitudeEventAction.CLICK,
          })
          queryClient.invalidateQueries({ queryKey: ['apiKeys'] })
          onSuccessCreateApiKey(payload)
        },
        onError: (error: BaseErrorResponse) => {
          if (error?.status === 400) {
            setRequestError(t('manageApiKeys.invalidRequest'))
          } else {
            setRequestError(t('oopsSomethingWentWrong'))
          }
          console.error('Error: update api key', error)
        },
      }
    )
  }

  return (
    <Modal visible={showManageModal} closeModal={closeModalCallback}>
      <ModalNavBar
        title={t('manageApiKeys.manageApiKeyModalHeader')}
        onClose={closeModalCallback}
      />
      <ModalScrollable>
        {payload && !isPending ? (
          <ApiKeyCreateForm
            payload={payload}
            onSubmit={(formData: ApiKeyRequestForm) => updateKeyCallback(formData)}
            isLoading={isPending}
            requestError={requestError}
            isEditMode
            dismissAction={closeModalCallback}
          />
        ) : (
          <Box flex justifyContent="center">
            <Loader theme="dark" size="large" />
          </Box>
        )}
      </ModalScrollable>
    </Modal>
  )
}

export default ApiKeyManageModal
