import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import {
  ModalActions,
  ModalBody,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { numberWithCommas } from '@node-space/utils'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { Amp } from 'utils/tracker'
import { floorWithPrecision, formatString } from 'utils/utils'
import { ConvertActionProps } from './Convert'

const ConvertComplete = ({ dismissAction, form, wallets, quote, refresh }: ConvertActionProps) => {
  const { t } = useTranslation()
  const formValues = form.watch()

  // refresh wallets
  useEffect(() => {
    Amp.track(AmplitudeEvent.CONVERT_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currencyFrom: fromWallet?.currency?.code,
      currencyTo: toWallet?.currency?.code,
      amountFrom: quote?.amountIn,
      amountTo: quote?.amountOut,
      walletFrom: fromWallet?.id,
      walletTo: toWallet?.id,
      quoteId: quote?.uuid,
    })

    refresh()
  }, [])

  const actions: ButtonProps[] = [{ children: t('done'), onClick: () => dismissAction() }]

  const fromWallet =
    formValues.walletId && wallets.find(x => x.id.toString() === formValues.walletId)
  const toWallet =
    formValues.toWalletId && wallets.find(x => x.id.toString() === formValues.toWalletId)
  const currencyCode = toWallet ? toWallet.currency.code : formValues.toWalletId
  const summaryMessage = formatString(
    t('convertSummary'),
    floorWithPrecision(quote.amountIn, fromWallet.currency.pricePrecision),
    fromWallet.currency.code,
    numberWithCommas(floorWithPrecision(quote.amountOut, toWallet.currency.pricePrecision)),
    currencyCode
  )

  return (
    <>
      <ModalScrollable>
        <ModalNavBar onClose={dismissAction} hasDivider={false} />
        <ModalBody hasTopPadding={false}>
          <Box flex direction="col" alignItems="center" paddingB={24} paddingX={32} gap={12}>
            <Icon name="ClapIcon" color="status-success-500" size="lg" />
            <Text tag="h1" color="dark-grey" size="xl" weight="semibold">
              {t('transactionSubmitted')}
            </Text>
            <Text align="center" weight="medium" size="sm">
              {summaryMessage}
            </Text>
            <Text align="center" size="sm" color="grey">
              {t('convertCompleteMessage')}
            </Text>
          </Box>
        </ModalBody>
      </ModalScrollable>
      <ModalActions actions={actions} alignActions="center" />
    </>
  )
}

export default ConvertComplete
