import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldErrorsImpl } from 'react-hook-form'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Select } from '@node-space/storybook-components/dist/Select'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import {
  Box as Wrapper,
  Box as FormRow,
  Box as TooltipLabel,
} from '@node-space/storybook-components/dist/Box'

interface PayCurrencyFieldProps {
  currencyList: { value: string; label: string }[]
  onChangeHandler: (name: string, value: string) => void
  selectedCurrency: string
  errors?: Partial<
    FieldErrorsImpl<{
      merchantId: string
      displayCurrency: string
      payCurrency: string
      reference: string
    }>
  >

  isLoading: boolean
}

const DisplayCurrencyField = ({
  currencyList,
  onChangeHandler,
  selectedCurrency,
  errors,
  isLoading,
}: PayCurrencyFieldProps) => {
  const { t } = useTranslation()

  return (
    <FormRow className="flex flex-wrap -mx-3 mb-6 px-3">
      <Wrapper className="w-full">
        <Select
          disabled
          label={
            <>
              <TooltipLabel className="mr-2">
                {t('payments.channels.cryptoToBePaidIn')}
              </TooltipLabel>
              <Tooltip
                id="pay-currency-tooltip"
                position="right"
                bodyContent={`${t('payments.channels.tooltips.payCurrency')}`}
                className="w-72 whitespace-normal"
              >
                <Icon name="InfoCircleIcon" size="sm" />
              </Tooltip>
            </>
          }
          name="payCurrency"
          options={currencyList}
          onChange={value => {
            onChangeHandler('payCurrency', value)
          }}
          value={selectedCurrency}
          error={Boolean(errors?.payCurrency)}
          errorText={errors?.payCurrency?.message}
          loading={isLoading}
          isSearchable
        />
      </Wrapper>
    </FormRow>
  )
}

export default React.memo(DisplayCurrencyField)
