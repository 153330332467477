import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { WalletCreateModalContext } from '../../context/WalletCreateModalProvider'
import { WalletAction } from '../Modal/WalletModal'

interface WalletHeaderButtonsProps {
  setWalletModalAction: React.Dispatch<React.SetStateAction<WalletAction | undefined>>
  canEdit: boolean
  canTrade: boolean
  viewOnly: boolean
  setShowWalletModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const WalletHeaderButtons = ({
  setWalletModalAction,
  canEdit,
  canTrade,
  viewOnly,
  setShowWalletModal,
}: WalletHeaderButtonsProps) => {
  const { t } = useTranslation()

  const [_, setCreateWalletModal] = useContext(WalletCreateModalContext)

  const handleModalAction = (key: string) => {
    setShowWalletModal?.(true)
    setWalletModalAction({ key: key } as unknown as WalletAction)
    track.Amp.track(AmplitudeEvent.WALLET_CONVERT, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.CLICK,
    })
  }

  return (
    <Box flex className="sm:p-0">
      <Box width="full" className="sm:w-auto">
        {!viewOnly && canEdit && (
          <Button
            onClick={() => {
              setCreateWalletModal(true)
            }}
            disabled={viewOnly}
            iconElement={<Icon name="PlusIcon" color="white" />}
          >
            {`${t('walletsPage.addAWallet')}`}
          </Button>
        )}
      </Box>
      <Box width="full" className="ml-3 sm:w-auto">
        {canEdit || canTrade ? (
          <Button
            testid="buttonConvert"
            iconElement={<Icon color="inherit" name="ConvertIcon" />}
            onClick={() => handleModalAction('convert')}
          >
            {t('walletsPage.convert')}
          </Button>
        ) : (
          !viewOnly &&
          (canEdit || canTrade) && (
            <Button
              onClick={() => {
                setShowWalletModal?.(true)
                setWalletModalAction('convert' as unknown as WalletAction)
              }}
              disabled={viewOnly}
            >
              {t('walletsPage.convert')}
            </Button>
          )
        )}
      </Box>
    </Box>
  )
}

export default React.memo(WalletHeaderButtons)
