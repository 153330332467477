import { TFunction } from 'react-i18next'
import * as yup from 'yup'

export interface IAddCryptoAddressForm {
  cryptocurrency: string
  addressName: string
  address: string
  destinationTag?: string
  reason?: string
  protocol?: string
}

const addCryptoAddressSchema = (t: TFunction<'translation', undefined>) => {
  const validateInputField = (label: string) => {
    return yup
      .string()
      .trim()
      .required(t('fieldRequired', { field: label }))
      .label(label)
  }

  return yup.object<Partial<Record<keyof IAddCryptoAddressForm, yup.AnySchema>>>({
    cryptocurrency: validateInputField(t('manageCryptoAddresses.modal.cryptocurrencyLabel')),
    addressName: validateInputField(t('manageCryptoAddresses.modal.addressNameLabel')),
    address: validateInputField(t('manageCryptoAddresses.modal.addressLabel')),
    reason: yup.string().trim(),
    destinationTag: yup.string().trim(),
    protocol: yup.string().trim(),
  })
}

export default addCryptoAddressSchema
