import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getIndustries } from 'services/CustomerService'

export const useIndustriesQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.industries(),
    queryFn: () => getIndustries(),
  })
}
