import React, { SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { Approval } from 'constants/General'
import { useApprovalsContext } from 'hooks/useApprovalsContext'
import { ApprovalRulesData } from 'types/approvals'
import { useApprovalRangeConfig } from './hooks/useApprovalRangeSettings'

interface ApprovalRangeControlsProps {
  rowIndex: number
  approvalRulesData: ApprovalRulesData[]
  setApprovalRulesData: (value: SetStateAction<ApprovalRulesData[]>) => void
  setIsApprovalRuleInvalid?: (value: SetStateAction<boolean>) => void
}

const ApprovalRangeControls = ({
  approvalRulesData,
  rowIndex,
  setApprovalRulesData,
  setIsApprovalRuleInvalid,
}: ApprovalRangeControlsProps) => {
  const { t } = useTranslation()

  const { permissions } = useApprovalsContext()
  const {
    addButtonIsVisible,
    deletebuttonIsVisible,
    approvalRangeIsInvalid,
    currentRowToValue,
    rowCount,
    isCurrentRowRangeInvalid,
  } = useApprovalRangeConfig(approvalRulesData, rowIndex)

  const handleAddNewRow = (index: number) => {
    const values = [...approvalRulesData]
    const currentToValueIsInfinity = values?.[index]?.maxAmount === Approval.RULES.INFINITY
    const nextRowsFromValue = currentToValueIsInfinity ? '' : approvalRulesData?.[index]?.maxAmount
    approvalRulesData[index].maxAmount = nextRowsFromValue
    const nextRowsToValue = !currentToValueIsInfinity ? '' : Approval.RULES.INFINITY

    nextRowsToValue
      ? values.push({
          minAmount: Number(nextRowsFromValue),
          maxAmount: nextRowsToValue,
          approvalsCount: 2,
        })
      : values?.splice(index + 1, 0, {
          minAmount: Number(nextRowsFromValue),
          maxAmount: nextRowsToValue,
          approvalsCount: 1,
        })

    setApprovalRulesData(values)
  }

  const handleRemoveRow = (index: number) => {
    const values = [...approvalRulesData]
    values?.splice(index, 1)
    if (values?.length === 1) {
      values[0].maxAmount = Approval.RULES.INFINITY
      values[0].approvalsCount = values?.[0]?.approvalsCount || 1
    } else {
      values[index - 1].maxAmount = Approval.RULES.INFINITY
    }

    setApprovalRulesData(values)
  }

  useEffect(() => {
    setIsApprovalRuleInvalid(approvalRangeIsInvalid)
  }, [currentRowToValue, rowCount, approvalRangeIsInvalid])

  return (
    <Box flex className="py-9 w-8" height={32}>
      {addButtonIsVisible && permissions?.canEdit && (
        <>
          {isCurrentRowRangeInvalid ? (
            <Tooltip
              clickable
              hasMaxWidth
              bodyContent={t('approvals.addNewRowIsDisabledError')}
              position="bottom"
            >
              <Button
                onClick={() => handleAddNewRow(rowIndex)}
                noStyling
                disabled
                iconElement={<Icon name="PlusIcon" color="primary-500" />}
              />
            </Tooltip>
          ) : (
            <Button
              onClick={() => handleAddNewRow(rowIndex)}
              noStyling
              iconElement={<Icon name="PlusIcon" color="primary-500" />}
            />
          )}
        </>
      )}

      {deletebuttonIsVisible && permissions?.canEdit && (
        <Button
          onClick={() => handleRemoveRow(rowIndex)}
          noStyling
          iconElement={<Icon name="TrashIcon" color="primary-500" />}
        />
      )}
    </Box>
  )
}

export default ApprovalRangeControls
