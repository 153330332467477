import { Dispatch, SetStateAction } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getListOfBanksForCountryAndCurrency } from 'services/BeneficiaryService'
import { BaseErrorResponse } from 'types/beneficiaries'
import { MappedErrorMessageState } from 'types/errors'
import { QueryMetaType } from 'types/reactQuery'

export const useBanksQuery = (
  countryCode: string,
  currencyCode: string,
  setRequestError: Dispatch<SetStateAction<MappedErrorMessageState>>,
  enabled = false
) => {
  const meta: QueryMetaType = {
    onErrorAdditional: (err: BaseErrorResponse) => {
      setRequestError({
        errorCode: err.status,
        show: true,
      })
    },
  }

  const {
    data: bankList,
    isError: isBankListError,
    isFetching: isFetchingBankList,
    isPending: isLoadingBankList,
  } = useQuery({
    queryKey: reactQueryKeys.banks(countryCode, currencyCode, enabled),
    queryFn: () => {
      if (!countryCode || !currencyCode) {
        return null
      }
      return getListOfBanksForCountryAndCurrency(countryCode, currencyCode)
    },
    enabled,
    placeholderData: keepPreviousData,
    meta,
  })

  return {
    bankList,
    isBankListError,
    isFetchingBankList,
    isLoadingBankList,
  }
}
