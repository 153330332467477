import React from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box as Body,
  Box as Container,
  Box as Page,
} from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import { AppBanners, AppBannerType } from 'components/app/AppBanners'
import { FlagEnabledBanner } from 'components/banners/FlagEnabledBanner'
import { LoginFooter } from 'components/footer/LoginFooter'
import { LogoHeader } from 'components/header/LogoHeader'
import { Description } from 'components/text/Description'
import { TextAlignType } from 'types/text'

interface LoginPageLayoutProps {
  children: React.ReactNode
  title?: string
  description?: React.ReactNode
  headerRightContent?: React.ReactNode
  hasFooter?: boolean
  narrowView?: boolean
  titleBig?: boolean
}

const LoginPageLayout = ({
  children,
  title,
  description,
  headerRightContent,
  hasFooter,
  narrowView = true,
  titleBig,
}: LoginPageLayoutProps) => {
  const { enableBanner } = useFeatureFlags()

  const appBanners: AppBannerType[] = []

  if (enableBanner?.enabled) {
    appBanners.push(<FlagEnabledBanner {...enableBanner} />)
  }

  return (
    <>
      <AppBanners banners={appBanners} />

      <Page flex direction="col" width="full" minHeight="screen" background="off-white">
        <LogoHeader>{headerRightContent}</LogoHeader>
        <Container
          flex
          flexSize="fill"
          width="full"
          centerChildren
          paddingX={16}
          className="sm:px-8"
        >
          <Body
            flex
            direction="col"
            {...(narrowView && {
              maxWidth: 384,
            })}
            width="full"
            paddingY={32}
            gapY={24}
          >
            {title && (
              <Text
                tag="h1"
                size={titleBig ? '3xl' : 'xl'}
                weight="semibold"
                align={TextAlignType.CENTER}
                testid="login-header-text"
              >
                {title}
              </Text>
            )}
            {description && <Description align={TextAlignType.CENTER}>{description}</Description>}
            {children}
          </Body>
        </Container>
        {hasFooter && <LoginFooter />}
      </Page>
    </>
  )
}

export default LoginPageLayout
