import React, { createContext } from 'react'
import { Profile } from 'types/authentication/Profile'

export interface ProfileContext {
  profile: Profile
  isLoading: boolean
  viewOnly: boolean
}

export const ProfileContext = createContext<ProfileContext>({
  profile: { twoFactorEnabled: false } as Profile,
  isLoading: false,
  viewOnly: false,
})

interface ProfileContextProviderProps {
  profile: Profile
  isLoading: boolean
  children: React.ReactNode
}

export const ProfileContextProvider = ({
  children,
  profile,
  isLoading,
}: ProfileContextProviderProps) => {
  return (
    <ProfileContext.Provider value={{ profile, isLoading, viewOnly: profile?.viewOnly }}>
      {children}
    </ProfileContext.Provider>
  )
}
