import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Description } from 'components/text/Description'
import { EMIAgreement } from './AgreementBody/EMIAgreement'
import { ModulrAgreement } from './AgreementBody/ModulrAgreement'
import {
  AgreementLinkType,
  AgreementOptions,
  EMICountries,
  EMICountriesType,
  ModulrCountries,
  ModulrCountriesType,
  SharedAgreementCountries,
} from './types'
import { LegalAgreementAppendixType } from 'pages/LegalAgreements/types'

const PRIVACY_POLICY_URL = process.env.PRIVACY_POLICY_URL

export const isEMI = name => name?.includes(`_${AgreementOptions.EMI}`)
export const isMODULR = name => name?.includes(AgreementOptions.MODULR)

export const getCountry = name => {
  if (name?.includes('UK')) return SharedAgreementCountries.UK
  if (name?.includes('MALTA')) return EMICountries.EEA
  if (name?.includes('EUROPE')) return ModulrCountries.EU
  return ''
}

export const useAgreementsModalData = () => {
  const { t } = useTranslation()

  const getTitle = (name: string): string => {
    const country = getCountry(name)
    if (isEMI(name)) return t(`emiAgreement.title.${country}`)
    if (isMODULR(name)) return t('modulrAgreement.title', { country })
    return t('pendingAgreements.linkText', { name })
  }

  const getBodyContent = (name: string, description: string): ReactNode => {
    const country = getCountry(name)
    if (isEMI(name)) return <EMIAgreement country={country as EMICountriesType} />
    if (isMODULR(name)) return <ModulrAgreement country={country as ModulrCountriesType} />
    return <Description>{description}</Description>
  }

  const getLinkLabel = (name: string): string => {
    const country = getCountry(name)
    if (isEMI(name))
      return t('emiTerms', { country: country === 'EEA' ? SharedAgreementCountries.EU : country })
    if (isMODULR(name)) return t('modulrAgreement.modulrTerms', { country })
    return t('pendingAgreements.linkText', { name })
  }

  const getLinks = (
    name: string,
    url: string,
    appendixes?: LegalAgreementAppendixType[]
  ): AgreementLinkType[] => {
    const agreementLink: AgreementLinkType = {
      label: getLinkLabel(name),
      url: url,
    }
    const privacyPolicyLink: AgreementLinkType = {
      label: t('privacyPolicy'),
      url: PRIVACY_POLICY_URL,
    }

    const links: AgreementLinkType[] = [agreementLink]

    if (!!appendixes?.length) {
      const latestAppendixLink: AgreementLinkType = {
        label: appendixes?.[0]?.name ?? '',
        url: appendixes?.[0]?.url ?? '',
      }
      links?.push(latestAppendixLink)
    }
    if (isEMI(name)) links?.push(privacyPolicyLink)

    return links
  }

  const getCheckboxLabel = (name: string): string => {
    if (isEMI(name)) return t('emiAgreement.confirmAndAgree')
    if (isMODULR(name)) return t('modulrAgreement.confirmAndAgree')
    return t('pendingAgreements.confirmAndAgree', { name })
  }

  return {
    getTitle,
    getBodyContent,
    getLinks,
    getLinkLabel,
    getCheckboxLabel,
  }
}
