import React, { memo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import { Modal, ModalNavBar } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import PageHeader from '@node-space/storybook-components/dist/PageHeader'
import { logSentryError } from '@node-space/utils'
import { Approval, PathNames } from 'constants/General'
import {
  useCreateMassFiatPayoutsPostRequest,
  useCreateMassFiatPayoutsPreRequest,
} from 'hooks/mutations/useCreateMassFiatPayoutsMutation'
import { usePutSubmitCryptoMassPayoutMutation } from 'hooks/mutations/usePutSubmitCryptoMassPayoutMutation'
import { useApprovalsConfigForFlow } from 'hooks/useApprovalsConfigForFlow'
import { useToastContext } from 'hooks/useToastContext'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  authRequestAtom,
  fiatBatchReferenceAtom,
  isConfirmBatchModalVisibleAtom,
  modalAtom,
  totalNumberOfPayoutsAtom,
  walletTypeAtom,
} from '../atoms/massPayoutAtoms'
import { useIsCryptoBatch } from '../hooks/useIsCryptoBatch'
import {
  BatchStatus,
  BatchSummaryInformation,
  MassPayoutButtonGroup,
  MassPayoutButtonGroupType,
  MassPayoutPostAuthRequest,
  ModalVariant,
} from '../types'
import { MassPayoutConfirmationModal } from './MassPayoutConfirmationModal'

const MassPayoutsTemplates = {
  CRYPTO_V1_CSV: `${process.env.ASSETS_URL}/templates/MassCryptoPayouts.csv`,
  CRYPTO_V2_CSV: `${process.env.ASSETS_URL}/templates/v2/Mass Crypto Payouts - Example file.csv`,
  FIAT_B2B_AND_CRYPTO_TEMPLATES_ZIP: `${process.env.ASSETS_URL}/templates/v2/b2b/Mass Payouts V2 Templates.zip`,
  FIAT_B2B_B2C_AND_CRYPTO_TEMPLATES_ZIP: `${process.env.ASSETS_URL}/templates/v2/b2c/Mass Payouts V2 Templates.zip`,
} as const

const SubmitDisableStatuses: string[] = [
  BatchStatus.PROCESSING,
  BatchStatus.CREATED,
  BatchStatus.COMPLETED,
]

interface MassPayoutHeaderProps {
  isPayoutSubmitted?: boolean
  batchInformation?: BatchSummaryInformation
  buttonGroup: MassPayoutButtonGroupType
}

const MassPayoutHeader = ({ batchInformation, buttonGroup }: MassPayoutHeaderProps) => {
  const { t } = useTranslation()
  const { reference: batchId } = useParams()
  const { enableMassPayoutsV2, enableMassPayoutsFiat } = useFeatureFlags()
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const navigate = useNavigate()
  const fiatBatchReference = useAtomValue(fiatBatchReferenceAtom)
  const [isConfirmBatchModalVisible, setIsConfirmBatchModalVisible] = useAtom(
    isConfirmBatchModalVisibleAtom
  )
  const setModal = useSetAtom(modalAtom)
  const [authRequest, setAuthRequest] = useAtom<MassPayoutPostAuthRequest>(authRequestAtom)
  const handleDownloadCSVTemplate = () => {
    if (enableMassPayoutsV2) {
      const fileToDownload = enableMassPayoutsFiat
        ? MassPayoutsTemplates.FIAT_B2B_AND_CRYPTO_TEMPLATES_ZIP
        : MassPayoutsTemplates.CRYPTO_V2_CSV
      window.open(fileToDownload, '_blank')
    } else {
      window.open(MassPayoutsTemplates.CRYPTO_V1_CSV, '_blank')
    }
  }
  const walletType = useAtomValue(walletTypeAtom)
  const setNumberOfPayouts = useSetAtom(totalNumberOfPayoutsAtom)

  const isCrypto = useIsCryptoBatch(walletType)

  const { approvalsIsRequiredForFlow, isFetchingApprovalPreference } = useApprovalsConfigForFlow(
    Approval.FLOWS.FIAT_3RD_PARTY_PAYOUT
  )

  const {
    mutate: createMassFiatPayoutPreRequest,
    isPending: isCreateMassFiatPayoutsPreRequestLoading,
  } = useCreateMassFiatPayoutsPreRequest()

  const {
    mutate: createMassFiatPayoutPostRequest,
    isPending: isCreateMassFiatPayoutPostRequestLoading,
  } = useCreateMassFiatPayoutsPostRequest()

  const handleCreateMassFiatPayout = () => {
    createMassFiatPayoutPreRequest(fiatBatchReference || batchInformation?.reference, {
      onError: (error: BaseErrorResponse) => {
        if (error?.status === 428) {
          setAuthRequest({
            ...authRequest,
            challenge: error?.data?.challenge?.value,
          })
          setIsConfirmBatchModalVisible(true)
        }
        if (error.status === 400) {
          // Display appropriate callout with error message sent from the BE.
        } else {
          // Display generic/fallback error messsge
        }
      },
    })
  }

  const onSubmitPostRequest = (password: string) => {
    setIsConfirmBatchModalVisible(false)

    const payoutsPostRequest = {
      challenge: authRequest?.challenge,
      tokenResponse: password || authRequest?.tokenResponse,
    }

    createMassFiatPayoutPostRequest(payoutsPostRequest, {
      onSuccess: async () => {
        if (approvalsIsRequiredForFlow) {
          setModal({ isOpen: true, variant: ModalVariant.APPROVALS })
        } else {
          setModal({ isOpen: true, variant: ModalVariant.SUCCESSFUL })
        }
        await queryClient.invalidateQueries({ queryKey: ['massFiatPayoutsDetails'] })
      },
      onError: (error: BaseErrorResponse) => {
        addToast({
          title: t('oopsSomethingWentWrong'),
          state: 'error',
          delay: 10,
        })
        logSentryError('Error: submitFiatMassPayoutPut', error, {
          fiatBatchReference,
        })
      },
    })
  }

  const handleUploadNewFile = () => {
    const isPayoutSubmitted =
      batchInformation?.status !== BatchStatus.VALIDATED &&
      batchInformation?.status !== BatchStatus.CREATED &&
      batchInformation?.status !== BatchStatus.DRAFT
    if (!isPayoutSubmitted) {
      setModal({ isOpen: true, variant: ModalVariant.DRAFT })
    } else {
      navigate(PathNames.MASS_PAYOUTS_CREATE)
    }
  }

  const { mutate: submitCryptoMassPayoutPut, isPending: isSubmitCryptoMassPayoutPutLoading } =
    usePutSubmitCryptoMassPayoutMutation()

  const handleConfirmCryptoBatch = () => {
    setIsConfirmBatchModalVisible(true)
  }

  const handleSubmitCryptoBatch = () => {
    submitCryptoMassPayoutPut(
      { batchId },
      {
        onSuccess: async response => {
          setNumberOfPayouts(response?.totalItems - response?.totalProcessedItems)
          setIsConfirmBatchModalVisible(false)
          setModal({ isOpen: true, variant: ModalVariant.SUCCESSFUL })
          await queryClient.invalidateQueries({ queryKey: reactQueryKeys.massCryptoPayoutsList() })
        },
        onError: (error: BaseErrorResponse) => {
          addToast({
            title: t('oopsSomethingWentWrong'),
            state: 'error',
            delay: 10,
          })
          logSentryError('Error: submitCryptoMassPayoutPut', error, {
            batchId,
          })
        },
      }
    )
  }

  const isFiatBatchValid =
    (!isCrypto && Number(batchInformation?.numberOfErrors) === 0) ||
    !batchInformation?.numberOfErrors
  const isCryptoBatchValid =
    isCrypto &&
    batchInformation?.status === BatchStatus.DRAFT &&
    Number(batchInformation?.numberOfErrors) === 0
  const isBatchValidated = isCrypto ? isCryptoBatchValid : isFiatBatchValid
  const isSubmitDisabled =
    SubmitDisableStatuses.includes(batchInformation?.status) || !isBatchValidated

  const isLoading = false

  const HeaderButtons = () => {
    if (buttonGroup === MassPayoutButtonGroup?.BATCH_LIST) {
      return (
        <Box flex justifyContent="end" gapX={12}>
          <Button
            iconElement={<Icon name="DownloadIcon" color="primary-500" />}
            onClick={handleDownloadCSVTemplate}
            testid="download-masspayout-templates-btn"
            secondary
          >
            {t('massPayouts.downloadTemplates')}
          </Button>
          <Button
            iconElement={<Icon name="PlusIcon" color="white" />}
            onClick={handleUploadNewFile}
            testid="submit-masspayout-templates-btn"
            disabled={false}
          >
            {t('massPayouts.createNewMassPayout')}
          </Button>
        </Box>
      )
    }

    if (buttonGroup === MassPayoutButtonGroup?.BATCH_DETAILS) {
      return (
        <Box flex justifyContent="end" gapX={12}>
          <Button
            iconElement={<Icon name="UploadIcon" color="primary-500" />}
            onClick={handleUploadNewFile}
            testid="upload-masspayout-btn"
            secondary
          >
            {t('massPayouts.uploadNew')}
          </Button>
          <Button
            iconElement={<Icon name="PaymentIcon" color="white" />}
            onClick={isCrypto ? handleConfirmCryptoBatch : handleCreateMassFiatPayout}
            testid="submit-masspayout-templates-btn"
            disabled={isSubmitDisabled}
          >
            {t('massPayouts.submitPayments')}
          </Button>
        </Box>
      )
    }

    if (buttonGroup === MassPayoutButtonGroup?.BATCH_UPLOAD) {
      return (
        <Box flex justifyContent="end" gapX={12}>
          <Button
            iconElement={<Icon name="DownloadIcon" color="white" />}
            onClick={handleDownloadCSVTemplate}
            testid="download-masspayout-templates-btn"
          >
            {t('massPayouts.downloadTemplates')}
          </Button>
        </Box>
      )
    }
  }
  return (
    <>
      <PageHeader
        description={t('massPayouts.pageHeader')}
        renderRightSection={<HeaderButtons />}
      />
      <Box>
        <Modal
          visible={isConfirmBatchModalVisible}
          closeModal={() => setIsConfirmBatchModalVisible(false)}
          closeOnBackgroundClick
        >
          <ModalNavBar
            title={t('massPayouts.submitPayments')}
            {...(!isLoading && { onBack: () => setIsConfirmBatchModalVisible(false) })}
          />
          <MassPayoutConfirmationModal
            onContinue={isCrypto ? handleSubmitCryptoBatch : onSubmitPostRequest}
            batchInformation={batchInformation}
            isLoading={false}
            error={false}
            twoFaRequired={!isCrypto}
          />
        </Modal>
      </Box>
    </>
  )
}

export default memo(MassPayoutHeader)
