import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DepositForm, DepositSteps } from '../components/Deposit/Deposit'
import { WalletActionSharedProps } from '../components/Modal/WalletModal'
import depositSchema from '../schemas/depositSchema'
import useWalletActionApi from './useWalletActionApi'

const useDepositAction = (sharedActionProps: WalletActionSharedProps) => {
  const { t } = useTranslation()

  const [depositStep, setDepositStep] = useState<DepositSteps>('form')
  const depositActionApi = useWalletActionApi<DepositForm, DepositSteps>({
    intialStep: 'form',
    step: depositStep,
    setStep: setDepositStep,
    formSchema: depositSchema({ t, step: depositStep }),
    ...sharedActionProps,
  })

  return depositActionApi
}

export default useDepositAction
