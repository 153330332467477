import React from 'react'
import i18next from 'i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TransactionDetailRow } from '../TransactionDetailRow'
import CopyTransactionRow from './CopyTransactionRow'

interface UserCryptoWithdrawalProps {
  amountWithCurrency: string
  transactionDate: string
  transactionHash: string
  recipientDetails: string
}

const UserCryptoWithdrawal = ({
  amountWithCurrency,
  transactionDate,
  transactionHash,
  recipientDetails,
}: UserCryptoWithdrawalProps) => {
  const { address: beneficiaryAddress, protocol } = JSON.parse(recipientDetails)
  return (
    <>
      {amountWithCurrency && (
        <TransactionDetailRow
          label={i18next.t('transactions.sideBar.paymentOutAmountPaid')}
          detail={amountWithCurrency}
        />
      )}
      {transactionHash && (
        <CopyTransactionRow content={transactionHash} label={i18next.t('hash')} />
      )}
      {transactionDate && (
        <TransactionDetailRow label={i18next.t('date')} detail={transactionDate} />
      )}

      {beneficiaryAddress && protocol && (
        <>
          <>
            <Text size="base" weight="medium" color="dark-grey" className="mt-3">
              {i18next.t('transactions.sideBar.beneficiaryDetails')}
            </Text>
            <CopyTransactionRow
              content={beneficiaryAddress}
              label={i18next.t('transactions.sideBar.beneficiaryAddress')}
            />
          </>
          <TransactionDetailRow
            label={i18next.t('transactions.sideBar.protocol')}
            detail={protocol}
          />
        </>
      )}
    </>
  )
}
export default UserCryptoWithdrawal
