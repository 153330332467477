import React from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import Box from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Approval, PathNames } from 'constants/General'
import { useMassCryptoPayoutsMutation } from 'hooks/mutations/useMassCryptoPayoutsMutation'
import { useMassFiatPayoutsMutation } from 'hooks/mutations/useMassFiatPayoutsMutation'
import { useApprovalsConfigForFlow } from 'hooks/useApprovalsConfigForFlow'
import { BaseErrorResponse } from 'types/beneficiaries'
import { WalletTypes } from 'types/types'
import {
  fiatBatchReferenceAtom,
  fileAtom,
  merchantAtom,
  modalAtom,
  totalNumberOfPayoutErrorsAtom,
  totalNumberOfPayoutsAtom,
  uploadErrorAtom,
  uploadMessageAtom,
  walletAtom,
  walletTypeAtom,
} from '../atoms/massPayoutAtoms'
import { ModalVariant } from '../types'

export const MassPayoutSubmitButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enableMassPayoutsV2 } = useFeatureFlags()
  const { approvalsIsRequiredForFlow, isFetchingApprovalPreference } = useApprovalsConfigForFlow(
    Approval.FLOWS.CRYPTO_PAYOUT
  )

  const [merchant, setMerchant] = useAtom(merchantAtom)
  const [wallet, setWallet] = useAtom(walletAtom)
  const [file, setFile] = useAtom(fileAtom)
  const [error, setError] = useAtom(uploadErrorAtom)

  const setFiatBatchReference = useSetAtom(fiatBatchReferenceAtom)
  const setModal = useSetAtom(modalAtom)
  const setMessage = useSetAtom(uploadMessageAtom)
  const setTotalNumberOfPayouts = useSetAtom(totalNumberOfPayoutsAtom)
  const setTotalNumberOfPayoutErrors = useSetAtom(totalNumberOfPayoutErrorsAtom)

  const walletType = useAtomValue(walletTypeAtom)

  const { mutate: massCryptoPayoutsMutation, isPending: isMassCryptoPayoutsLoading } =
    useMassCryptoPayoutsMutation()

  const { mutate: massFiatPayoutsMutation, isPending: isMassFiatPayoutsLoading } =
    useMassFiatPayoutsMutation()

  const handleOnSuccess = response => {
    if (walletType === WalletTypes.FIAT) {
      setMessage(response?.message)
      setError('')
      setFile(null)
      setFiatBatchReference(response)
      setWallet('')
      navigate(
        `${PathNames.MASS_PAYOUTS}/payout-details/${response}?batchType=${walletType.toLowerCase()}`
      )
    }
    if (walletType === WalletTypes.CRYPTO) {
      if (enableMassPayoutsV2) {
        setTotalNumberOfPayouts(response?.totalItems || 0)
        setTotalNumberOfPayoutErrors(response?.totalErrors || 0)
        if (response?.totalErrors === 0) {
          if (approvalsIsRequiredForFlow) {
            setModal({ isOpen: true, variant: ModalVariant.APPROVALS })
          } else {
            setModal({ isOpen: true, variant: ModalVariant.SUCCESSFUL })
          }
        } else {
          navigate(
            `${PathNames.MASS_PAYOUTS}/payout-details/${response?.externalId}?batchType=${walletType.toLowerCase()}`
          )
        }
      } else {
        setMessage(response?.message)
        setModal({ isOpen: true, variant: ModalVariant.PROCESSED })
      }
      setError('')
      setFile(null)
      setMerchant('')
    }
  }

  const handleOnError = (error: BaseErrorResponse) => {
    console.error('error', error)
    setModal({ isOpen: false })
    setError(error?.errorList?.[0]?.message || t('oopsSomethingWentWrong'))
  }

  const handleSubmit = () => {
    const mutation = {
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    }

    if (walletType === WalletTypes.FIAT) {
      massFiatPayoutsMutation(
        {
          walletId: wallet as string,
          file: file,
        },
        mutation
      )
    }

    if (walletType === WalletTypes.CRYPTO) {
      massCryptoPayoutsMutation(
        {
          merchantId: merchant,
          file: file,
          enableMassPayoutsV2: enableMassPayoutsV2,
        },
        mutation
      )
    }
  }

  const isMassPayoutDisabled =
    walletType === WalletTypes.FIAT ? !file || !wallet || !!error : !file || !merchant || !!error

  return (
    <>
      {!!error && <Callout state="error" message={error} />}
      <Box flex justifyContent="end">
        <Button
          loading={
            isMassCryptoPayoutsLoading || isMassFiatPayoutsLoading || isFetchingApprovalPreference
          }
          disabled={isMassPayoutDisabled}
          className="-mr-2"
          iconElement={<Icon color="white" name="SendIcon" />}
          onClick={handleSubmit}
        >
          {t('massPayouts.submitFile')}
        </Button>
      </Box>
    </>
  )
}
