import React, { useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import Accordion from '@node-space/storybook-components/dist/Accordion'
import { Box } from '@node-space/storybook-components/dist/Box'
import { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text, TextColor } from '@node-space/storybook-components/dist/Text'
import { useAddBeneficiaryContext } from 'hooks/useAddBeneficiaryContext'
import { AddBeneficiaryFormPayload, BeneficiaryTransferDestination } from 'types/beneficiaries'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import { getLocalBankDetailsSummary, getSwiftBankDetails } from 'utils/beneficiaries'
import track from 'utils/tracker'
import { usdBeneficiaryBankTypeAtom } from '../atoms/beneficiaryAtoms'

type BeneficiarySummaryProps = {
  formState: AddBeneficiaryFormPayload
}

const LocalBeneficiarySummary = ({ formState }: BeneficiarySummaryProps) => {
  const { t } = useTranslation()
  const beneficiaryBankType = useAtomValue(usdBeneficiaryBankTypeAtom)
  const details = getLocalBankDetailsSummary(formState, beneficiaryBankType, t)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.ADD_BENEFICIARY_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      ...(formState?.currencyCode && { currency: formState?.currencyCode }),
      ...(formState?.iban && { iban: formState?.iban }),
      ...(formState?.beneficiaryPaymentReference && {
        beneficiaryRef: formState?.beneficiaryPaymentReference,
      }),
      ...(formState?.accountNumber && { accountNumber: formState?.accountNumber }),
      ...(formState?.ngnAccountNumber && { ngnAccountNumber: formState?.ngnAccountNumber }),
      ...(formState?.zarAccountNumber && { zarAccountNumber: formState?.zarAccountNumber }),
      ...(formState?.alias && { beneficiaryName: formState?.alias }),
    })
  }, [])

  return (
    <Box
      testid="beneficiaryDetailsSummary"
      full
      divideChildren="y"
      background="off-white"
      paddingX={20}
      borderRadius={4}
      border="gray"
    >
      {Object.keys(details ?? {}).map(label => {
        return (
          <Box key={label} full>
            <SummaryRow
              label={label}
              renderDescription={
                <Text size="sm" className="break-all">
                  {details?.[label]}
                </Text>
              }
            />
          </Box>
        )
      })}
    </Box>
  )
}

const InternationalBeneficiarySummary = ({ formState }: BeneficiarySummaryProps) => {
  const { t } = useTranslation()
  const [openAccordion, setOpenAccordion] = useState('beneficiaryDetailsSummary')
  const { beneficiaryDetails, beneficiaryBankDetails, intermediaryBankDetails } =
    getSwiftBankDetails(formState, t)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.ADD_BENEFICIARY_CONFIRM, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      internationalIban: formState?.intlIban,
      beneficiaryRef: formState?.beneficiaryPaymentReference,
    })
  }, [])

  const accordionItems = [
    {
      name: 'beneficiaryDetailsSummary',
      title: t('beneficiaryDetails'),
      children: (
        <Box divider="top" paddingX={24}>
          <Box testid="beneficiaryDepositDetails">
            {Object.keys(beneficiaryDetails || {}).map(label => {
              return (
                <SummaryRow
                  key={label}
                  label={label}
                  renderDescription={<Text size="sm">{beneficiaryDetails?.[label]}</Text>}
                />
              )
            })}
          </Box>
        </Box>
      ),
    },
    {
      name: 'beneficiaryBankDetailsSummary',
      title: t('beneficiaryBankDetails'),
      children: (
        <Box divider="top" paddingX={24}>
          <Box testid="beneficiaryBankDepositDetails">
            {Object.keys(beneficiaryBankDetails || {}).map(label => {
              return (
                <SummaryRow
                  key={label}
                  label={label}
                  renderDescription={<Text size="sm">{beneficiaryBankDetails?.[label]}</Text>}
                />
              )
            })}
          </Box>
        </Box>
      ),
    },
  ]

  if (!formState?.skipIntermediaryBankScreen && Object.keys(intermediaryBankDetails || {}).length) {
    accordionItems.push({
      name: 'intermediaryBankDetailsSummary',
      title: t('intermediaryBankDetails'),
      children: (
        <Box divider="top" paddingX={24}>
          <Box testid="intermediaryBankDepositDetails">
            {Object.keys(intermediaryBankDetails || {}).map(label => {
              return (
                <SummaryRow
                  key={label}
                  label={label}
                  renderDescription={<Text size="sm">{intermediaryBankDetails?.[label]}</Text>}
                />
              )
            })}
          </Box>
        </Box>
      ),
    })
  }

  return (
    <Box full>
      <Accordion
        isSlim
        items={accordionItems}
        getOpenName={name => setOpenAccordion(name)}
        defaultOpen={openAccordion}
        headerTextColor={TextColor.PRIMARY_500}
        bodyClassOverrides="!p-0"
      ></Accordion>
    </Box>
  )
}

export const BeneficiarySummary = () => {
  const { beneficiaryFormState } = useAddBeneficiaryContext()
  const isInternationalBeneficiary = useMemo(() => {
    return (
      beneficiaryFormState?.transferDestination === BeneficiaryTransferDestination.INTERNATIONAL
    )
  }, [beneficiaryFormState?.transferDestination])

  return isInternationalBeneficiary ? (
    <InternationalBeneficiarySummary formState={beneficiaryFormState} />
  ) : (
    <LocalBeneficiarySummary formState={beneficiaryFormState} />
  )
}
