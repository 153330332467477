import React from 'react'
import clsx from 'clsx'
import {
  Box,
  Box as Container,
  Box as LogoContainer,
} from '@node-space/storybook-components/dist/Box'
import { LogoColor } from 'types/types'
import { Logo } from 'components/logos/Logo'

export interface LogoHeaderProps {
  children?: React.ReactNode
  isMobileSticky?: boolean
}

export const LogoHeader = ({ children, isMobileSticky }: LogoHeaderProps) => {
  return (
    <>
      <Container
        flex
        justifyContent="between"
        width="full"
        paddingX={16}
        paddingT={12}
        {...(isMobileSticky && {
          background: 'off-white',
          divider: 'bottom',
          paddingB: 12,
          alignItems: 'center',
        })}
        className={clsx('sm:pt-4 sm:px-8', {
          'fixed md:relative z-20 sm:pb-4': isMobileSticky,
        })}
      >
        <LogoContainer paddingT={8} {...(isMobileSticky && { paddingB: 8 })}>
          <Logo logoColor={LogoColor.DARK} />
        </LogoContainer>
        {children}
      </Container>
      {isMobileSticky && <Box className="h-headerMobile sm:h-header md:hidden mb-[1px]" />}
    </>
  )
}
