import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isString, isEmpty } from 'lodash-es'
import AppLoader from 'components/AppLoader'
import { getBase64DecodeToString, getDecodedToken, TOKEN_PARAM } from 'utils/auth/utils'
import { useRefreshToken } from 'hooks/useRefreshToken'
import { PathNames } from 'constants/General'

export default function Verify() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get(TOKEN_PARAM)

  const [accessToken, setAccessToken] = useState<string>()
  const [secret, setSecret] = useState<string>()

  const { data: refreshToken, isFetched } = useRefreshToken(accessToken, secret)

  useEffect(() => {
    if (!isString(token) && isEmpty(token)) return

    const tokenString = getBase64DecodeToString(token)
    if (!tokenString) {
      setSearchParams('')
      return navigate(PathNames.LOGOUT)
    }

    const [accessToken, secret] = getDecodedToken(tokenString)
    if (accessToken && secret) {
      setAccessToken(accessToken)
      setSecret(secret)
    }
  }, [token])

  useEffect(() => {
    if (!isFetched) return

    setSearchParams('')
    if (!refreshToken) {
      return navigate(PathNames.LOGOUT)
    }
    return navigate(PathNames.DASHBOARD)
  }, [refreshToken, isFetched])

  return <AppLoader />
}
