import { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecurringExportContextValue } from 'contexts/recurringExportContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { usePostReportScheduleMutation } from 'hooks/mutations/usePostReportScheduleMutation'
import { usePutReportScheduleMutation } from 'hooks/mutations/usePutReportScheduleMutation'
import { BaseErrorResponse } from 'types/beneficiaries'
import {
  ExportAccordionItems,
  ExportAccordionItemsType,
  ExportFileFormat,
  RecurringExportForm,
  RecurringExportSteps,
  RecurringExportStepsType,
  ReportSchedule,
  ReportScheduleFrequency,
} from 'types/walletTransactionsExport'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'

interface UseRecurringExportHelperProps {
  handleClose: () => void
  setOpenAccordion: Dispatch<SetStateAction<ExportAccordionItemsType>>
  setCloseAllItems: Dispatch<SetStateAction<boolean>>
}

export const useRecurringExportHelper = ({
  handleClose,
  setOpenAccordion,
  setCloseAllItems,
}: UseRecurringExportHelperProps): RecurringExportContextValue => {
  const { t } = useTranslation()
  const { profile } = useProfileContext()
  const {
    mutate: postReportSchedule,
    isPending: isLoadingPostReportSchedule,
    error: postReportScheduleError,
    isError: isErrorPostReportSchedule,
  } = usePostReportScheduleMutation()
  const {
    mutate: putReportSchedule,
    isPending: isLoadingPutReportSchedule,
    error: putReportScheduleError,
    isError: isErrorPutReportSchedule,
  } = usePutReportScheduleMutation()
  const [currentReportSchedule, setCurrentReportSchedule] = useState<ReportSchedule>(null)
  const [step, setStep] = useState<RecurringExportStepsType>(RecurringExportSteps.LIST)
  const [isUpdatingReportSchedule, setIsUpdatingReportSchedule] = useState(false)

  const defaultValues: RecurringExportForm = {
    frequencyType: ReportScheduleFrequency.DAILY,
    format: ExportFileFormat?.CSV,
    frequencyDay: '1',
    time: '09:00',
    timezone: null,
  }

  const form = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const { control, setError } = form

  const formValues = useWatch({ control }) as RecurringExportForm

  const amplitudeProperties = {
    category: AmplitudeEventCategory.MERCHANT_PORTAL,
    action: AmplitudeEventAction.LOAD,
    frequencyType: formValues?.frequencyType,
    ...(Number(formValues?.frequencyDay) && {
      frequencyDay: Number(formValues?.frequencyDay),
    }),
    time: formValues?.time,
    timezone: formValues?.timezone,
    type: ExportAccordionItems.RECURRING,
  }

  const createReportSchedule: SubmitHandler<RecurringExportForm> = () => {
    if (!formValues?.time) {
      setError('time', { type: 'custom', message: t('exports.timeRequiredField') })
      return
    }
    if (!formValues?.timezone) {
      setError('timezone', { type: 'custom', message: t('exports.timezoneRequiredField') })
      return
    }

    const reportScheduleData = {
      ...formValues,
      channel: 'email',
      frequencyDay: Number(formValues?.frequencyDay),
      email: profile?.emailAddress,
    }

    setCurrentReportSchedule(reportScheduleData)

    postReportSchedule(reportScheduleData, {
      onSuccess: () => {
        track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_SUCCESS, amplitudeProperties)
        setStep(RecurringExportSteps.COMPLETE)
      },
      onError: (error: BaseErrorResponse) => {
        track.Amp.track(AmplitudeEvent.EOF_CREATE_EXPORT_INITIATE_ERROR, {
          ...amplitudeProperties,
          error,
        })
      },
    })
  }

  const updateReportSchedule: SubmitHandler<RecurringExportForm> = () => {
    const reportScheduleData = {
      ...currentReportSchedule,
      ...formValues,
      frequencyDay: Number(formValues?.frequencyDay),
    }

    setCurrentReportSchedule(reportScheduleData)

    putReportSchedule(reportScheduleData, {
      onSuccess: () => {
        track.Amp.track(AmplitudeEvent.EOF_EDIT_EXPORT_INITIATE_SUCCESS, amplitudeProperties)
        setStep(RecurringExportSteps.COMPLETE)
      },
      onError: (error: BaseErrorResponse) => {
        track.Amp.track(AmplitudeEvent.EOF_EDIT_EXPORT_INITIATE_ERROR, {
          ...amplitudeProperties,
          error,
        })
      },
    })
  }

  return {
    step,
    setStep,
    handleClose,
    currentReportSchedule,
    setCurrentReportSchedule,
    isUpdatingReportSchedule,
    setIsUpdatingReportSchedule,
    form,
    createReportSchedule,
    updateReportSchedule,
    setOpenAccordion,
    setCloseAllItems,
    isLoadingPostReportSchedule,
    isErrorPostReportSchedule,
    postReportScheduleError,
    isLoadingPutReportSchedule,
    isErrorPutReportSchedule,
    putReportScheduleError,
  }
}
