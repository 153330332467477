import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtom, useSetAtom } from 'jotai'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input2FA } from '@node-space/storybook-components/dist/Input2FA'
import Loader from '@node-space/storybook-components/dist/Loader'
import { ModalActions } from '@node-space/storybook-components/dist/Modal'
import { TWO_FA_REQUIRED_LENGTH } from 'components/TwoFA/TwoFAField'
import { authRequestAtom, isConfirmBatchModalVisibleAtom } from '../atoms/massPayoutAtoms'
import { MassPayoutConfirmationSchema } from '../schemas/massPayoutConfirmationSchema'
import {
  BatchSummaryInformation,
  MassPayoutConfirmationPayload,
  MassPayoutPostAuthRequest,
} from '../types'
import { MassPayoutConfirmationSummary } from './MassPayoutConfirmationSummary'

type MassPayoutConfirmationModalProps = {
  onContinue: (password?: string) => void
  batchInformation: BatchSummaryInformation
  isLoading: boolean
  error: boolean
  twoFaRequired: boolean
}

export const MassPayoutConfirmationModal = ({
  onContinue,
  batchInformation,
  isLoading,
  error,
  twoFaRequired,
}: MassPayoutConfirmationModalProps) => {
  const { t } = useTranslation()
  const [authRequest, setAuthRequest] = useAtom<MassPayoutPostAuthRequest>(authRequestAtom)
  const setIsConfirmBatchModalVisible = useSetAtom(isConfirmBatchModalVisibleAtom)
  const formSchema = MassPayoutConfirmationSchema()
  const { control, register, setValue, setError, clearErrors } =
    useForm<MassPayoutConfirmationPayload>({
      resolver: yupResolver(formSchema),
    })
  const password = useWatch({
    control,
    name: 'password',
    defaultValue: '',
  })

  useEffect(() => {
    if (password?.length === TWO_FA_REQUIRED_LENGTH) {
      setAuthRequest({ ...authRequest, tokenResponse: password })
      onContinue(password)
    }
  }, [password])

  return (
    <Box flex flexCol paddingT={16}>
      <MassPayoutConfirmationSummary batchInformation={batchInformation} />

      {isLoading ? (
        <Box flex flexJustifyCenter>
          <Loader size="medium" />
        </Box>
      ) : twoFaRequired ? (
        <Box padding={24}>
          <Input2FA
            {...register('password')}
            label={t('authCodeConfirm')}
            onChange={value => setValue('password', value)}
            testid="input-2fa"
            error={error}
            errorText={t('incorrectOtpCode')}
          />
        </Box>
      ) : (
        <ModalActions
          actions={[
            {
              children: t('cancel'),
              onClick: () => setIsConfirmBatchModalVisible(false),
              secondary: true,
            },
            {
              children: t('submit'),
              onClick: () => onContinue(),
            },
          ]}
          alignActions="end"
        />
      )}
    </Box>
  )
}
