import React from 'react'
import { Radio } from '@node-space/storybook-components'
import {} from 'hooks/context/useDepositAccountsContext'
import { useMediaQuery } from '@mantine/hooks'
import { useDepositAccountsV2Context } from 'hooks/context'

export const BankRadioButtons = () => {
  const { depositAccounts, selectedAccount, setSelectedAccount } = useDepositAccountsV2Context()
  const isMobileScreen = useMediaQuery('(min-width: 640px)')

  const getOptions = () => {
    return depositAccounts.aggregatedPaymentMethods.map(account => ({
      label: account.description,
      value: String(account.id),
    }))
  }
  const handleChange = (value: string) => {
    const account = depositAccounts.aggregatedPaymentMethods.find(
      account => String(account.id) === value
    )
    setSelectedAccount(account)
  }

  return (
    <div className="pb-8">
      <Radio
        horizontal={isMobileScreen}
        borderless={false}
        onChange={handleChange}
        options={getOptions()}
        value={String(selectedAccount.id)}
      />
    </div>
  )
}
