import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isObject from 'lodash-es/isObject'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { DoubleLabelSelect } from '@node-space/storybook-components/dist/DoubleLabelSelect'
import { Input } from '@node-space/storybook-components/dist/Input'
import Loader from '@node-space/storybook-components/dist/Loader'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import { Radio, RadioProps } from '@node-space/storybook-components/dist/Radio'
import { logSentryError } from '@node-space/utils'
import LoadingAnimationDark from 'components/spinners/LoadingAnimationDark'
import { MERCHANT_PORTAL } from 'constants/General'
import { useAccountsContext } from 'hooks/context/useAccountsContext'
import { useValidateCryptoAddressMutation } from 'hooks/mutations/useValidateCryptoAddressMutation'
import { useValidateReferenceWithdrawalMutation } from 'hooks/mutations/useValidateReferenceWithdrawalMutation'
import { BaseErrorResponse } from 'types/beneficiaries'
import { DestinationTagValues } from 'types/whitelisting'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { floorWithPrecision, formatString, isTestEnvironment, stringToDecimal } from 'utils/utils'
import { useSendWallets } from './hooks/useSendWallets'
import { SendActionProps } from './Send'

const SendForm = ({
  dismissAction,
  setStep,
  wallets,
  form,
  loading,
  registerFormField,
  isFetchingWallets,
}: SendActionProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [isNewAddress, setIsNewAddress] = useState(false)

  const { currentAccount } = useAccountsContext()

  const { enableDestinationTagInWalletSendFlow } = useFeatureFlags()

  const formValues = form.watch()

  const {
    selectedWallet,
    preferredCurrency,
    selectedWalletCurrencyCode,
    insufficientBalance,
    supportedWalletOptions,
    whitelistedAddressesOptions,
    destinationTagObserver,
    isFetchingCryptoAddresses,
    isFetchingExchangeRate,
    withdrawalLimits,
    amountErrorMinimum,
    whitelistedCryptoAddresses,
    exchangeRate,
    isExchangeError,
    isErrorCryptoAddresses,
    maxAmount,
    amountErrorMaximumFees,
    amountErrorMaximum,
    exchangeRateError,
    isFetchingLimits,
    isErrorLimits,
    checkMinAmount,
    checkMaxAmount,
  } = useSendWallets(formValues, wallets)

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.SEND_INITIATE, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      cryptoWhitelisting: true,
    })
  }, [])

  useEffect(() => {
    if (withdrawalLimits?.currency !== selectedWalletCurrencyCode && !loading) {
      // set default protocol if wallet contains more than 1
      if (selectedWallet?.alternatives?.length) {
        form.setValue('protocol', selectedWallet?.protocol)
      }
    }
  }, [formValues?.walletId])

  const handleReference = ({ target }) => {
    form.setValue('reference', target?.value)
    form.clearErrors('reference')

    mutateReference(
      {
        reference: target?.value,
      },
      {
        onSuccess: () => {
          form.clearErrors('reference')
        },
        onError: (error: BaseErrorResponse) => {
          if (error?.status !== 412) {
            logSentryError('Send form reference validation error', error?.errorList)
            form.setError('reference', {
              message: t('referenceFailed'),
            })
          } else {
            form.setError('reference', {
              message: error?.errorList[0]?.message,
            })
          }
        },
      }
    )
  }

  const { mutate: mutateValidateCrypto, isPending: addressValidating } =
    useValidateCryptoAddressMutation()

  const { mutate: mutateReference, isPending: referenceIsValidating } =
    useValidateReferenceWithdrawalMutation()

  const handleNextStep = () => {
    if (isNewAddress && !!formValues?.protocol) {
      return setStep('confirm')
    }
    hasDefinedProtocol() ? setStep('confirm') : setStep('addProtocol')
  }

  const requiredDestinationTagIsMissing =
    destinationTagObserver?.destinationTagIsRequired === DestinationTagValues.IS_REQUIRED &&
    !formValues.destinationTag

  const isFormLoading =
    isFetchingCryptoAddresses ||
    addressValidating ||
    loading ||
    isFetchingWallets ||
    isFetchingLimits

  const showCryptoAddressesError =
    isErrorCryptoAddresses && !insufficientBalance && !isErrorLimits && !isFetchingCryptoAddresses

  const preSendFlowConditionCheck = useMemo(() => {
    const isAddressInvalid = !formValues?.address
    const isCurrencyCodeMissing = !selectedWalletCurrencyCode
    const isAmountMissing = !formValues?.amount
    const hasAmountErrors = !!Object.keys(form.formState?.errors?.amount || {})?.length
    const isAddressValidating = addressValidating
    const isProtocolRequiredButMissing =
      selectedWallet?.alternatives?.length && !formValues?.protocol
    const hasGeneralFormErrors = !!Object.keys(form?.formState?.errors || {})?.length

    if (enableDestinationTagInWalletSendFlow) {
      return (
        isAddressInvalid ||
        isCurrencyCodeMissing ||
        isAmountMissing ||
        requiredDestinationTagIsMissing ||
        hasAmountErrors ||
        hasGeneralFormErrors ||
        isAddressValidating
      )
    } else {
      return (
        isAddressInvalid ||
        isCurrencyCodeMissing ||
        isAmountMissing ||
        requiredDestinationTagIsMissing ||
        hasAmountErrors ||
        isProtocolRequiredButMissing ||
        hasGeneralFormErrors ||
        isAddressValidating
      )
    }
  }, [enableDestinationTagInWalletSendFlow, selectedWalletCurrencyCode, formValues, form])

  const actions: ButtonProps[] = [
    {
      children: t('cancel'),
      secondary: true,
      testid: 'cancel',
      onClick: dismissAction,
    },
    {
      children: t('continue'),
      testid: t('continue'),
      disabled: preSendFlowConditionCheck || isFormLoading,
      ...(isFormLoading && { iconElement: <Loader /> }),
      onClick: handleNextStep,
    },
  ]

  // set radio options if more than 1 protocol detected for selected wallet e.g. TRC20 and ERC20 for USDT
  let radioProtocolProps: RadioProps
  if (selectedWallet?.currency?.protocols?.length) {
    const otherProtocols = selectedWallet.currency.protocols.filter(
      x => x.code !== selectedWallet.protocol
    )

    if (otherProtocols?.length) {
      radioProtocolProps = {
        label: t('selectedProtocol'),
        name: 'protocol',
        options: [{ code: selectedWallet.protocol }, ...otherProtocols].map(x => ({
          label: x.code,
          value: x.code,
        })),
        borderless: false,
        value: null,
        horizontal: true,
      }
    }
  }

  const validateAmount = (amount: number | string, onBlur = isTestEnvironment) => {
    let error = checkMinAmount(amount, onBlur)

    if (!error) {
      error = checkMaxAmount(amount)
    }
    if (error) {
      form.setError('amount', { message: error })
    } else {
      form.clearErrors('amount')
    }
  }

  // action to set amount to a valid value based on validation errors
  const errorAction =
    !!form.formState?.errors?.amount?.message &&
    (form.formState.errors.amount.message === amountErrorMinimum
      ? () => setAmount(withdrawalLimits?.minimum)
      : form.formState.errors.amount.message === amountErrorMaximumFees
        ? () => setAmount(maxAmount)
        : form.formState.errors.amount.message === amountErrorMaximum &&
          (() => setAmount(withdrawalLimits?.maximum)))

  const setAmount = (amount: number | string) => {
    form.setValue('amount', amount)
    form.clearErrors('amount')
  }

  const getProtocolBasedOnAddress = useCallback(
    selectedAddress =>
      whitelistedCryptoAddresses?.results?.find(({ address }) => address === selectedAddress)
        ?.protocol,
    [whitelistedCryptoAddresses]
  )

  const getUUIDBasedOnAddress = useCallback(
    selectedAddress =>
      whitelistedCryptoAddresses?.results?.find(({ address }) => address === selectedAddress)?.uuid,
    [whitelistedCryptoAddresses]
  )

  const hasDefinedProtocol = () => {
    if (selectedWallet?.currency?.protocols?.length === 1) {
      return true
    }

    // Allow protocol change on add of new address
    if (isNewAddress && !!formValues?.protocol) {
      return false
    }

    return !!getProtocolBasedOnAddress(form.getValues('address'))
  }

  const handleProtocolChange = (protocol: string) => {
    form.setValue('protocol', protocol)
    mutateValidateCrypto(
      {
        currency: selectedWalletCurrencyCode,
        code: selectedWalletCurrencyCode,
        address: form.getValues('address'),
        protocol: form.getValues('protocol'),
        accountReference: currentAccount?.reference,
        source: MERCHANT_PORTAL,
      },
      {
        onSuccess: () => {
          form.clearErrors('protocol')
        },

        onError: (error: BaseErrorResponse) => {
          if (error?.status === 400) {
            form.setError('protocol', {
              message: `${t('invalidProtocolSelected')}`,
            })
          } else {
            logSentryError('Send form protocol selection error', error)
            form.setError('protocol', {
              message: `${t('oopsSomethingWentWrong')}`,
            })
          }
        },
      }
    )
  }

  const convertedAmount = () => {
    const amount = form.getValues('amount')

    if (isFetchingExchangeRate) {
      return LoadingAnimationDark
    }

    if (amount && !isFetchingExchangeRate && !!exchangeRate?.value) {
      return `~ ${exchangeRate?.value} ${preferredCurrency}`
    }

    // in case of an error let's hide exchange amount
    if (isExchangeError) {
      logSentryError('Error: convert amount withdrawal flow', exchangeRateError, {
        location,
        component: 'SendForm',
      })
      return ''
    }
    return `0 ${preferredCurrency}`
  }

  const showInputFields =
    selectedWallet &&
    !insufficientBalance &&
    !isErrorLimits &&
    !isFetchingLimits &&
    !isFetchingCryptoAddresses &&
    !isErrorCryptoAddresses

  return (
    <Box>
      <ModalNavBar title={t('send')} onClose={dismissAction} />
      <Box paddingT={20} paddingB={28} data-testid="send-form">
        <ModalScrollable>
          <Box flex direction="col" paddingX={24} gapY={24} className="my-px">
            {/* wallet selector */}
            <Box>
              <DoubleLabelSelect
                iconsUrl={process.env.ICONS_URL}
                name={t('selectWallet')}
                label={t('selectWallet')}
                menuMaxHeight={400}
                skeletonLoading={isFetchingWallets}
                showOverflow
                placeholder={
                  !wallets
                    ? t('loadingEllipses')
                    : wallets && wallets.length === 0
                      ? t('noWallets')
                      : t('from')
                }
                options={supportedWalletOptions}
                value={formValues?.walletId}
                onChange={(value: string) =>
                  form.reset(
                    {
                      walletId: value,
                      protocol: formValues?.protocol,
                      saveBeneficiary: false,
                    },
                    { keepDefaultValues: true }
                  )
                }
                isSearchable
              />
            </Box>

            <Box className="relative">
              <DoubleLabelSelect
                iconsUrl={process.env.ICONS_URL}
                name={t('selectWallet')}
                label={t('payments.withdrawals.destinationAddress')}
                menuMaxHeight={400}
                showOverflow
                placeholder={t('searchOrEnterAddress')}
                skeletonLoading={isFetchingWallets || isFetchingCryptoAddresses}
                options={whitelistedAddressesOptions}
                saveInputValue
                value={null}
                formatCreateLabel={userInput => `Add "${userInput}"`}
                {...(!destinationTagObserver?.mayRequireDestinationTag && {
                  onChange: address => {
                    setIsNewAddress(false)
                    form.clearErrors('address')
                    form.clearErrors('protocol')
                    form.setValue('address', address)
                    form.setValue('saveBeneficiary', false)
                    form.setValue('beneficiaryName', '')
                    form.setValue('protocol', getProtocolBasedOnAddress(address))
                    form.setValue('uuid', getUUIDBasedOnAddress(address))

                    const addressMatch = whitelistedAddressesOptions?.find(whitelistedAddress => {
                      if (whitelistedAddress?.value === address) {
                        return true
                      }
                    })

                    if (!addressMatch) {
                      setIsNewAddress(true)
                    }
                  },
                })}
                {...(destinationTagObserver?.mayRequireDestinationTag && {
                  onChange: (selectedAddress: { address: string; destinationTag?: string }) => {
                    setIsNewAddress(false)
                    form.clearErrors('address')
                    form.clearErrors('protocol')
                    form.setValue('destinationTag', '')
                    form.setValue('saveBeneficiary', false)
                    form.setValue('beneficiaryName', '')

                    const addressMatch = whitelistedAddressesOptions?.find(whitelistedAddress => {
                      if (
                        isObject(whitelistedAddress?.value) &&
                        whitelistedAddress?.value?.address === selectedAddress?.address
                      ) {
                        return true
                      }
                    })

                    if (!addressMatch) {
                      form.setValue('address', String(selectedAddress))
                      form.setValue('protocol', getProtocolBasedOnAddress(selectedAddress))
                      form.setValue('uuid', getUUIDBasedOnAddress(selectedAddress))
                      setIsNewAddress(true)
                    } else {
                      form.setValue('address', selectedAddress?.address)
                      form.setValue('protocol', getProtocolBasedOnAddress(selectedAddress?.address))
                      form.setValue('uuid', getUUIDBasedOnAddress(selectedAddress?.address))

                      if (selectedAddress?.destinationTag) {
                        form.setValue('destinationTag', selectedAddress?.destinationTag)
                      }
                    }
                  },
                })}
                isSearchable
                displayTooltipForLongLabel
              />
            </Box>

            {insufficientBalance && (
              <Callout
                state="error"
                message={t('sendNotEnoughFunds', {
                  currencyCode: selectedWalletCurrencyCode,
                })}
              />
            )}

            {isErrorLimits && !insufficientBalance && !isFetchingLimits && (
              <Callout state="error" message={t('limitsError')} />
            )}

            {showCryptoAddressesError && (
              <Callout state="error" message={t('sendCryptoAddressesError')} />
            )}

            {showInputFields && (
              <>
                {/* protocol selector */}
                {radioProtocolProps && form.getValues('address') && (
                  <Radio
                    {...radioProtocolProps}
                    onChange={e => handleProtocolChange(e)}
                    disabled={hasDefinedProtocol()}
                    value={form.getValues('protocol')}
                    error={!!form.formState?.errors?.protocol}
                    errorText={form.formState?.errors?.protocol?.message}
                  />
                )}

                {enableDestinationTagInWalletSendFlow && formValues?.destinationTag && (
                  <Box>
                    <Input
                      {...registerFormField('destinationTag')}
                      label={t('destinationTag')}
                      inputMode="text"
                      name="destinationTag"
                      placeholder={t('destinationTag')}
                      value={formValues?.destinationTag}
                      disabled
                    />
                  </Box>
                )}

                {/* destination tag */}
                {!enableDestinationTagInWalletSendFlow &&
                  (selectedWallet?.lookup || destinationTagObserver?.mayRequireDestinationTag) && (
                    <Input
                      label={`${t('destinationTag')} (${t('optionalLabel')})`}
                      value={formValues?.destinationTag}
                      onChange={e => form.setValue('destinationTag', e?.target?.value)}
                    />
                  )}

                {/* amount */}
                <Input
                  {...registerFormField('amount')}
                  label={`${selectedWalletCurrencyCode} ${t('amount')}`}
                  testid={t('amount')}
                  required
                  postLabel={convertedAmount()}
                  bottomLeftLabel={
                    selectedWallet &&
                    withdrawalLimits?.minimum &&
                    formatString(
                      t('minimumAmount'),
                      floorWithPrecision(
                        withdrawalLimits?.minimum,
                        selectedWallet?.currency?.pricePrecision
                      ),
                      selectedWalletCurrencyCode
                    )
                  }
                  inputMode="decimal"
                  onBottomLeftLabelClick={() => {
                    validateAmount(withdrawalLimits?.minimum, true)
                    form.setValue('amount', withdrawalLimits?.minimum)
                  }}
                  onBlur={e => validateAmount(stringToDecimal(e.target.value), true)}
                  onChange={e => {
                    form.setValue('amount', stringToDecimal(e.target.value))
                    validateAmount(stringToDecimal(e.target.value))
                  }}
                  onErrorClick={errorAction ? () => errorAction() : null}
                />
                <Input
                  {...registerFormField('reference')}
                  testid={t('reference')}
                  label={`${t('paymentReference')} (${t('optional')})`}
                  error={form.getFieldState('reference', form.formState)?.invalid}
                  errorText={form.getFieldState('reference', form.formState)?.error?.message}
                  value={formValues?.reference}
                  onBlur={handleReference}
                  disabled={referenceIsValidating}
                />

                {isNewAddress && formValues?.address && (
                  <CheckBox
                    {...registerFormField('saveBeneficiary')}
                    value={formValues?.saveBeneficiary}
                    onChange={() => form.setValue('saveBeneficiary', !formValues?.saveBeneficiary)}
                    label={t('saveBeneficiary')}
                  />
                )}

                {isNewAddress && !!formValues?.saveBeneficiary && formValues?.address && (
                  <Input
                    {...registerFormField('beneficiaryName')}
                    testid={t('beneficiaryName')}
                    label={t('beneficiaryName')}
                    value={formValues.beneficiaryName}
                  />
                )}
              </>
            )}
          </Box>
        </ModalScrollable>
      </Box>

      {/* actions */}
      <ModalActions actions={actions} />
    </Box>
  )
}

export default SendForm
