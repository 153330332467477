import { requestApi } from 'api/api'
import { ApiKey, ApiKeyCreateAPIRequest, ApiKeyCreateResponse } from 'types/apiKeys'

export const createApiKey = (payload: ApiKeyCreateAPIRequest): Promise<ApiKeyCreateResponse> => {
  return requestApi({
    url: '/token',
    method: 'POST',
    data: payload,
  })
}

export const deleteApiKey = (apiKey: string): Promise<void> => {
  return requestApi({
    url: `/token?token=${apiKey}`,
    method: 'DELETE',
  })
}

export const getPasswordSecret = (password: string): Promise<{ body: string | null }> => {
  return requestApi({
    url: '/user/twofactor/request',
    method: 'POST',
    data: { body: password },
  })
}

export const verify2FACode = (otc: string): Promise<void> => {
  return requestApi({
    url: '/user/twofactor/confirm',
    method: 'POST',
    data: { body: otc },
  })
}

export const getAllApiKeys = async (query: string): Promise<ApiKey[]> => {
  const searchQuery = query ? `?searchTerm=${encodeURI(query)}` : ''

  return requestApi({
    url: `/token/withdraw${searchQuery}`,
    method: 'GET',
  })
}

export const editApiKey = (
  payload: ApiKeyCreateAPIRequest,
  id: number
): Promise<ApiKeyCreateResponse> => {
  return requestApi({
    url: `/token/${id}`,
    method: 'PUT',
    data: payload,
  })
}
