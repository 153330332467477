import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlignActions } from '@node-space/storybook-components'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import {
  ModalActions,
  ModalNavBar,
  ModalScrollable,
} from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { postDeposit } from 'services/WalletsService'
import { AggregatedPaymentMethod, SegregatedPaymentMethod } from 'types/BankAccountsDetails'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { formatString, roundNumberWithCommas } from 'utils/utils'
import { DepositActionProps } from './Deposit'
import DepositAccountDetailsBoard from './DepositAccountDetailsBoard'
import useDepositPaymentReference from './hooks/useDepositPaymentReference'

interface DepositInstructionsProps extends Omit<DepositActionProps, 'accounts'> {
  account: AggregatedPaymentMethod | SegregatedPaymentMethod
}

const DepositInstructions = ({
  wallets,
  dismissAction,
  setStep,
  form,
  account,
  depositLimits,
}: DepositInstructionsProps) => {
  const { t } = useTranslation()

  const formValues = form.watch()

  const actions: ButtonProps[] = [{ children: t('done'), onClick: () => dismissAction() }]

  const { options: accountOptions, currency } = account || {}
  const [wallet, setWallet] = useState(wallets.find(x => x.id.toString() === formValues.walletId))

  // If the wallet address is null initialise the wallet to get an address
  useEffect(() => {
    if (!wallet?.address) {
      const postDepositWallet = () => {
        postDeposit(formValues?.walletId)
          .then(wallet => {
            setWallet(wallet)
          })
          .catch(err => {
            logSentryError('Error from DepositInstructions - postDeposit', err)
          })
      }
      postDepositWallet()
    }
    track.Amp.track(AmplitudeEvent.EOF_DEPOSIT_SUCCESS, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
      currency: wallet?.currency?.code?.toLowerCase(),
      walletId: formValues?.walletId,
    })
  }, [])

  // fallback limits
  const minAmount = {
    EUR: '500.00',
    ZAR: '5000.00',
    USD: '500.00',
    NGN: '1000.00',
  }

  const paymentReference = useDepositPaymentReference(accountOptions)

  return (
    <Box testid="deposit-instructions">
      {/* nav bar */}
      <ModalNavBar
        title={`${t('deposit')} ${wallet?.currency?.code}`}
        onBack={() => setStep('form')}
        onClose={dismissAction}
      />

      <ModalScrollable>
        {Object.keys(accountOptions || {}).length ? (
          <Box flex direction="col" className="flex-1">
            <Box flex paddingX={24} paddingY={16}>
              <Text size="sm">
                {`${t('depositUseDetails')} `}
                <span className="font-semibold">{`${
                  depositLimits?.minimum
                    ? roundNumberWithCommas(depositLimits?.minimum)
                    : minAmount[currency]
                } ${account.currency}`}</span>
                {` ${formatString(t('depositMatchDetails'), 'BVNK', t('bvnkBusiness').valueOf())}`}
              </Text>
            </Box>
            <Box width="full" paddingX={24} paddingY={16}>
              <DepositAccountDetailsBoard
                wallet={wallet}
                currency={currency}
                options={accountOptions}
              />
            </Box>
            {/* reference number warning */}
            {!!paymentReference && (
              <Box paddingX={24} paddingY={12}>
                <Callout state="warning" message={t('depositReferenceMessage')} />
              </Box>
            )}
          </Box>
        ) : (
          <Box paddingY={16} paddingX={20}>
            <Callout state="error" message={t('oopsSomethingWentWrongFetchDespoitDetails')} />
          </Box>
        )}
      </ModalScrollable>

      {/* actions */}
      <ModalActions totalSteps={2} actions={actions} alignActions={AlignActions.CENTER} />
    </Box>
  )
}

export default DepositInstructions
