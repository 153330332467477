export const RecaptchaVersion = {
  V3: 'V3',
} as const
export type RecaptchaVersionType = (typeof RecaptchaVersion)[keyof typeof RecaptchaVersion]

export type RecaptchaProps = {
  version: RecaptchaVersionType
  isLoading: boolean
  getToken: () => Promise<string>
}

export type RecaptchaPayload = {
  recaptcha: string
  recaptchaVersion?: RecaptchaVersionType
}
