import React from 'react'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { copyButtonIconColour } from 'styles/theme/colours'
import { Box, Box as Row } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Modal, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ellipseMiddleText } from '@node-space/utils'
import { PathNames } from 'constants/General'
import { TransactionDetailRow } from 'pages/WalletDetails/components/RecentActivity/Modal/TransactionDetailRow'
import { TransactionType } from 'types/transaction/TransactionType'
import { TransactionContent, TransformedInfo } from './SearchTransactionTable'

interface TransactionModalProps {
  transaction: TransactionContent
  transformedInfo: TransformedInfo
  onClose: () => void
}

export const SearchTransactionModal = ({
  transaction,
  transformedInfo,
  onClose,
}: TransactionModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const modalTextCopies = {
    IN: {
      walletAddress: t('wallets.searchTransaction.payInWalletAddress'),
      amount: t('wallets.searchTransaction.payInAmount'),
      paidWallet: t('wallets.searchTransaction.paidInWallet'),
    },
    OUT: {
      walletAddress: t('wallets.searchTransaction.payOutWalletAddress'),
      amount: t('wallets.searchTransaction.payOutAmount'),
      paidWallet: t('wallets.searchTransaction.paidOutWallet'),
    },
  }

  const transactionReference =
    transaction?.customerReference ||
    (transformedInfo.isChannelsDeposit && transaction?.transactionReference)

  const deepLinkToOriginalPayments = () => {
    if (transaction?.description === 'channelDeposit') {
      navigate(
        `${PathNames.PAYMENTS}${PathNames.CHANNELS_LINKS}?dlt-reference=${transactionReference}`
      )
    } else {
      navigate(
        `${PathNames.PAYMENTS}${PathNames.PAYMENTS_LINKS}?dlt-reference=${transactionReference}`
      )
    }
  }

  const hasDirectionOrIsChannelsDeposit =
    transaction?.direction || transformedInfo.isChannelsDeposit

  const showSentFromWalletAddress =
    transaction?.walletAddress && transaction?.description === TransactionType.USER_DEPOSIT
  const showSentToWalletAddress =
    transaction?.walletAddress && transaction?.description === TransactionType.USER_WITHDRAWAL

  return (
    <Modal visible closeOnBackgroundClick closeModal={onClose}>
      <ModalNavBar title={startCase(transaction?.description)} onClose={onClose} />
      <Box padding={16} flex justifyContent="center">
        <Text size="xl" color="grey" weight="semibold">
          {transformedInfo?.amountWithoutCurrency ? `${transformedInfo?.amount}` : ''}
        </Text>
      </Box>
      <Box paddingX={16} paddingB={16}>
        <Box border="gray" paddingX={16}>
          {transaction?.walletAddress && hasDirectionOrIsChannelsDeposit && (
            <TransactionDetailRow
              label={
                modalTextCopies?.[
                  transaction?.direction ?? transformedInfo?.resolvedPaymentDirection
                ]?.walletAddress
              }
            >
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.walletAddress, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.walletAddress}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {transaction?.customerReference && (
            <TransactionDetailRow label={t('customerReference')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.customerReference, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.customerReference}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {transformedInfo.isChannelsDeposit && transaction?.transactionReference && (
            <TransactionDetailRow label={t('customerReference')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.transactionReference, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.transactionReference}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {transformedInfo?.amountWithoutCurrency && hasDirectionOrIsChannelsDeposit && (
            <TransactionDetailRow
              label={
                modalTextCopies?.[
                  transaction?.direction ?? transformedInfo?.resolvedPaymentDirection
                ]?.amount
              }
            >
              <Text color="grey" size="sm">
                {transformedInfo?.fromAmount}
              </Text>
            </TransactionDetailRow>
          )}
          {transaction?.walletName && hasDirectionOrIsChannelsDeposit && (
            <TransactionDetailRow
              label={
                modalTextCopies?.[
                  transaction?.direction ?? transformedInfo?.resolvedPaymentDirection
                ]?.paidWallet
              }
            >
              <Text color="grey" size="sm">
                {startCase(transaction.walletName)}
              </Text>
            </TransactionDetailRow>
          )}
          {transaction?.transactionUUID && (
            <TransactionDetailRow label={t('transactionUuid')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.transactionUUID, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.transactionUUID}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {transaction?.hash && (
            <TransactionDetailRow label={t('hash')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.hash, 20, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.hash}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {showSentFromWalletAddress && (
            <TransactionDetailRow label={t('wallets.searchTransaction.payInWalletAddress')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.walletAddress, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.walletAddress}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {showSentToWalletAddress && (
            <TransactionDetailRow label={t('wallets.searchTransaction.payOutWalletAddress')}>
              <Row flex flexItemsCenter gap={8}>
                <Text color="grey" size="sm">
                  {ellipseMiddleText(transaction?.walletAddress, 15, 10)}
                </Text>
                <Box>
                  <CopyButton
                    copyText={transaction?.walletAddress}
                    confirmationMessage={t('copiedToClipboard')}
                    iconColour={copyButtonIconColour}
                  />
                </Box>
              </Row>
            </TransactionDetailRow>
          )}
          {transaction?.walletName &&
            transaction?.description === TransactionType.USER_WITHDRAWAL && (
              <TransactionDetailRow label={t('wallets.searchTransaction.sentFromWallet')}>
                <Text color="grey" size="sm">
                  {startCase(transaction.walletName)}
                </Text>
              </TransactionDetailRow>
            )}
          {transaction?.dateCreated && (
            <TransactionDetailRow label={t('date')}>
              <Text color="grey" size="sm">
                {dayjs(transaction.dateCreated).format('DD MMMM YYYY @ HH:mm')}
              </Text>
            </TransactionDetailRow>
          )}
          {transaction?.description && (
            <TransactionDetailRow label={t('transactionType')}>
              <Text color="grey" size="sm">
                {startCase(transaction.description)}
              </Text>
            </TransactionDetailRow>
          )}
          {!!transaction?.accountNumber && (
            <TransactionDetailRow label={t('accountNumber')}>
              <p>{transaction?.accountNumber}</p>
            </TransactionDetailRow>
          )}
          {!!transaction?.bankName && (
            <TransactionDetailRow label={t('bankName')}>
              <p>{transaction?.bankName}</p>
            </TransactionDetailRow>
          )}
          {!!transaction?.bankCode && (
            <TransactionDetailRow label={t('bankCode')}>
              <p>{transaction?.bankCode}</p>
            </TransactionDetailRow>
          )}
          {!!transaction?.name && (
            <TransactionDetailRow label={t('beneficiaryName')}>
              <p>{transaction?.name}</p>
            </TransactionDetailRow>
          )}
          {!!transactionReference && (
            <Box
              flex
              alignItems="center"
              justifyContent="center"
              paddingY={12}
              divider="bottom"
              className="last:border-b-0"
            >
              <Text size="sm" color="grey">
                <Button onClick={deepLinkToOriginalPayments} noStyling>
                  {t('wallets.searchTransaction.dltDeepLinkText')}
                </Button>
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
