import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@node-space/storybook-components/dist/Icon'
import { AlignActions, ModalActions } from '@node-space/storybook-components/dist/Modal'
import ResponseContainer from '@node-space/storybook-components/dist/ModalResponseContainer'
import SummaryDetail, { SummaryRow } from '@node-space/storybook-components/dist/SummaryDetail'
import { Text } from '@node-space/storybook-components/dist/Text'
import { ReportSchedule } from 'types/walletTransactionsExport'
import { ReportScheduleDescription } from './ReportScheduleDescription'

interface RecurringExportCompleteType {
  handleClose: () => void
  reportSchedule: ReportSchedule
  isUpdatingReportSchedule: boolean
}

export const RecurringExportComplete = ({
  handleClose,
  reportSchedule,
  isUpdatingReportSchedule,
}: RecurringExportCompleteType) => {
  const { t } = useTranslation()

  return (
    <>
      <ResponseContainer
        headline={
          isUpdatingReportSchedule ? t('exports.scheduleUpdated') : t('exports.reportScheduled')
        }
        responseIcon={<Icon name="ClapIcon" color="status-success-500" size="lg" />}
      >
        <SummaryDetail hasMargin={false}>
          <SummaryRow
            label={t('exports.schedule')}
            renderDescription={<ReportScheduleDescription reportSchedule={reportSchedule} />}
          />
          <SummaryRow
            label={t('exports.format')}
            renderDescription={
              <Text size="sm" weight="normal" color="grey">
                {reportSchedule?.format?.toUpperCase()}
              </Text>
            }
          />
        </SummaryDetail>
      </ResponseContainer>
      <ModalActions
        actions={[
          {
            children: t('done'),
            onClick: handleClose,
            type: 'button',
          },
        ]}
        alignActions={AlignActions.CENTER}
      />
    </>
  )
}
