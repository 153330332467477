import React from 'react'
import { useWalletContext } from 'hooks/context'
import { CryptoExtendedDetails } from './CryptoExtendedDetails/CryptoExtendedDetails'
import { FiatExtendedDetails } from './FiatExtendedDetails/FiatExtendedDetails'

export const ExtendedDetails = () => {
  const wallet = useWalletContext()
  return (
    <div data-testid="extended-details-screen" className="pt-8">
      {wallet?.currency?.fiat ? <FiatExtendedDetails /> : <CryptoExtendedDetails />}
    </div>
  )
}
