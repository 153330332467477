import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Box, { Box as Container } from '@node-space/storybook-components/dist/Box'
import ImageIcon from '@node-space/storybook-components/dist/ImageIcon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Logo } from 'components/logos/Logo'
import { PathNames } from 'constants/General'
import { LogoColor } from 'types/types'
import { VerificationResult } from 'types/verification'

const VerificationStatus = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { status: statusParam } = useParams() || {}

  const status = statusParam?.toLowerCase() as VerificationResult
  const isVerificationSuccessful = status === VerificationResult.SUCCESS

  const statusMatch = Object.values(VerificationResult)?.includes(status)

  useEffect(() => {
    if (!statusMatch) {
      navigate(PathNames.NOT_FOUND, { replace: true })
    }
  }, [statusMatch])

  return (
    <Container height="screen" width="full" flex direction="col" className="bg-neutral-stone ">
      <Box background="white" paddingY={20} paddingX={32}>
        <Logo logoColor={LogoColor.DARK} />
      </Box>
      <Box
        height="full"
        flex
        direction="col"
        justifyContent="center"
        paddingX={16}
        gap={32}
        className="sm:px-16"
      >
        <ImageIcon
          hasBackgroundColor={false}
          width={184}
          height={184}
          src={`${process.env.ICONS_URL}verification/${
            isVerificationSuccessful ? 'user-accepted' : 'user-rejected'
          }.svg`}
        />
        <Text size="3xl" color="text-900" weight={'semibold'}>
          {isVerificationSuccessful
            ? t('verificationStatus.successful')
            : t('verificationStatus.unsuccessful')}
        </Text>
      </Box>
    </Container>
  )
}

export default VerificationStatus
