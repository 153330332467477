import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { BeneficiaryDetailsCurrency } from 'types/beneficiaries'

export const getZarSpecificSchemaFields = (t: TFunction<'translation', undefined>) => {
  const accountNumberLabel = t('accountNumber')
  const accountNumberMinLength = 10
  const accountNumberMaxLength = 12
  const routingNumberLength = 6

  const zarAccountNumber = yup
    .string()
    .label(accountNumberLabel)
    .min(
      accountNumberMinLength,
      t('fieldMinLength', {
        field: accountNumberLabel,
        min: accountNumberMinLength,
      })
    )
    .max(
      accountNumberMaxLength,
      t('fieldMaxLength', {
        field: accountNumberLabel,
        max: accountNumberMaxLength,
      })
    )
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const zarRoutingNumberBranchCodeLabel = t('abaRoutingNumber')

  const zarRoutingNumberBranchCode = yup
    .string()
    .label(zarRoutingNumberBranchCodeLabel)
    .min(
      routingNumberLength,
      t('fieldMinLength', {
        field: zarRoutingNumberBranchCodeLabel,
        min: routingNumberLength,
      })
    )
    .max(
      routingNumberLength,
      t('fieldMaxLength', {
        field: zarRoutingNumberBranchCodeLabel,
        max: routingNumberLength,
      })
    )
    .matches(/^[0-9]*$/, t('onlyNumericValues'))

  const zarAccountTypeLabel = t('accountType')
  const zarAccountType = yup
    .string()
    .label(zarAccountTypeLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is ZAR
        if (ctx?.parent?.currencyCode === BeneficiaryDetailsCurrency.ZAR) {
          if (!value) {
            ctx.schema.required()
            return ctx.createError({ message: t('accountTypeRequired') })
          }
          return true
        }

        // Should not be required for other currencies
        ctx.schema.notRequired()
        return true
      },
    })

  const zarBankLabel = t('bank')
  const zarBank = yup
    .string()
    .label(zarBankLabel)
    .test({
      test(value, ctx) {
        // Make this required when the currency is ZAR
        if (ctx?.parent?.currencyCode === BeneficiaryDetailsCurrency.ZAR) {
          if (!value) {
            ctx.schema.required()
            return ctx.createError({ message: t('bankNameRequired') })
          }
          return true
        }

        // Should not be required for other currencies
        ctx.schema.notRequired()
        return true
      },
    })

  return {
    zarAccountNumber,
    zarAccountType,
    zarBank,
    zarRoutingNumberBranchCode,
  }
}
