import { requestApi } from 'api/api'
import {
  ReportSchedule,
  ReportSchedulesResponse,
  ReportScheduleTimeZone,
} from 'types/walletTransactionsExport'

export const getReportSchedules = (): Promise<ReportSchedulesResponse> => {
  return requestApi({
    url: '/v1/report-schedules',
    method: 'GET',
  })
}

export const getReportScheduleTimezones = (): Promise<ReportScheduleTimeZone[]> => {
  return requestApi({
    url: '/v1/report-schedules/timezones',
    method: 'GET',
  })
}

export const deleteReportSchedule = (id: number): Promise<unknown> => {
  return requestApi({
    url: `/v1/report-schedules/${id}`,
    method: 'DELETE',
  })
}

export const postReportSchedule = (data: ReportSchedule): Promise<ReportSchedule> => {
  return requestApi({
    url: '/v1/report-schedules',
    method: 'POST',
    data,
  })
}

export const putReportSchedule = (data: ReportSchedule): Promise<ReportSchedule> => {
  return requestApi({
    url: `/v1/report-schedules/${data?.id}`,
    method: 'PUT',
    data,
  })
}
