import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import Box, { Box as Container, Box as Row } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import SidePanel, { useSidePanel } from '@node-space/storybook-components/dist/SidePanel'
import SimpleTableV2 from '@node-space/storybook-components/dist/SimpleTableV2'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import Text from '@node-space/storybook-components/dist/Text'
import Tooltip from '@node-space/storybook-components/dist/Tooltip'
import { ellipseMiddleText } from '@node-space/utils'
import { appBannersId } from 'components/app/AppBanners'
import CopyTextWrapper from 'components/CopyTextWrapper'
import OnClickProvider from 'components/OnClickProvider/OnClickProvider'
import TablePagination from 'components/TablePagination'
import { TablePopoverMenu } from 'components/TablePopoverMenu/TablePopoverMenu'
import { DateFormat } from 'constants/General'
import { PaymentCaseProvider } from 'contexts/paymentCaseContext'
import { useComponentHeight } from 'hooks/useComponentHeight'
import { PaymentCase, PaymentCaseType } from 'types/payments'
import { highlightRowBasedOnId } from 'utils/table/table'
import { getPaymentCaseStatus, getPaymentCaseStatusLabel } from 'utils/utils'
import useCaseListApi, { CASELIST_PAGINATION_MAX } from '../hooks/useCaseListApi'
import { CaseCompleteCheckbox } from './CaseCompleteCheckbox'
import { CustomerIdInput } from './CustomerIdInput'
import { PaymentCaseDetailsBody } from './PaymentCaseDetailsBody'
import { PaymentCaseError } from './PaymentCaseError'

interface PaymentSupportTableProps {
  status?: PaymentCaseType[]
}

export const PaymentSupportTable = ({ status }: PaymentSupportTableProps) => {
  const { t } = useTranslation()
  const {
    enableCasePortalSupportTableUpdate,
    enableCasePortalCustomerId,
    enableCasePortalCompletedCheck,
  } = useFeatureFlags()
  const bannerSpacing = useComponentHeight(appBannersId)

  const {
    isFetching,
    isError,
    data: {
      caseListPagination: { paging, pagingMax },
      casesList,
    },
    actions: { setCaseListPagination, onPageChange },
  } = useCaseListApi(status)

  const {
    isSidePanelOpen: isPaymentCaseOpen,
    sidePanelData: paymentCase,
    paging: pagingPaymentCases,
    setIsSidePanelOpen: setIsPaymentCaseOpen,
    updateSidePanel: updatePaymentCase,
  } = useSidePanel<PaymentCase>(casesList)

  const columns = [
    enableCasePortalSupportTableUpdate && {
      className: 'px-4 min-w-[160px]',
      headerTitle: t('paymentSupport.paymentReference'),
    },
    enableCasePortalCustomerId &&
      enableCasePortalSupportTableUpdate && {
        className: 'px-4 min-w-[120px]',
        headerTitle: t('paymentSupport.customerId'),
      },
    !enableCasePortalSupportTableUpdate && {
      className: 'px-4 min-w-[120px]',
      headerTitle: t('paymentSupport.caseNumber'),
    },
    !enableCasePortalSupportTableUpdate && {
      className: 'px-4 min-w-[160px]',
      headerTitle: t('paymentSupport.paymentReference'),
    },
    {
      className: 'pr-4 max-w-[200px] md:max-w-[350px] lg:max-w-[500px]',
      headerTitle: t('paymentSupport.subject'),
    },
    {
      className: 'pr-4 min-w-[120px]',
      headerTitle: t('paymentSupport.caseOpened'),
    },
    !enableCasePortalSupportTableUpdate && {
      className: 'pr-4 min-w-[120px]',
      headerTitle: t('paymentSupport.caseClosed'),
    },
    {
      className: 'pr-4 w-[160px]',
      headerTitle: t('paymentSupport.status'),
    },
    enableCasePortalCompletedCheck &&
      enableCasePortalSupportTableUpdate && {
        className: 'pr-4',
        headerTitle: t('paymentSupport.completed'),
      },
    !enableCasePortalSupportTableUpdate && {
      className: 'pr-4',
      headerTitle: '',
    },
  ].filter(Boolean)

  const setSidePanel = (index: number) => {
    updatePaymentCase(index)
    setIsPaymentCaseOpen(true)
  }

  const mappedPaymentCases = casesList?.map((paymentCase, paymentCaseIndex) => {
    return {
      rowData: [
        enableCasePortalSupportTableUpdate && {
          className: 'py-5 px-4',
          cellData: (
            <OnClickProvider
              id={paymentCase?.caseNumber}
              onClick={() => setSidePanel(paymentCaseIndex)}
            >
              {!!paymentCase?.paymentReference ? (
                <Text size="sm">{paymentCase?.paymentReference}</Text>
              ) : (
                <Tooltip position="top" bodyContent={t('paymentSupport.nullTooltip')}>
                  <Icon name="InfoCircleIcon" />
                </Tooltip>
              )}
            </OnClickProvider>
          ),
        },
        enableCasePortalCustomerId &&
          enableCasePortalSupportTableUpdate && {
            className: 'py-5 px-4',
            cellData: (
              <Text size="sm" className="truncate">
                {paymentCase?.customerId}
              </Text>
            ),
          },
        !enableCasePortalSupportTableUpdate && {
          className: 'py-5 px-4',
          cellData: (
            <OnClickProvider
              id={paymentCase?.caseNumber}
              onClick={() => setSidePanel(paymentCaseIndex)}
            >
              <Text size="sm">{paymentCase?.caseNumber}</Text>
            </OnClickProvider>
          ),
        },
        !enableCasePortalSupportTableUpdate && {
          className: 'py-5 px-4',
          cellData: (
            <>
              {!paymentCase?.paymentReference ? (
                <Tooltip position="top" bodyContent={t('paymentSupport.nullTooltip')}>
                  <Icon name="InfoCircleIcon" />
                </Tooltip>
              ) : (
                <CopyTextWrapper
                  textToCopy={paymentCase?.paymentReference}
                  textContainer={
                    <Text size="sm">
                      {ellipseMiddleText(paymentCase?.paymentReference, 10, 10)}
                    </Text>
                  }
                />
              )}
            </>
          ),
        },
        {
          className: 'pr-4 max-w-[200px] md:max-w-[350px] lg:max-w-[500px]',
          cellData: (
            <Text size="sm" className="truncate">
              {paymentCase?.subject}
            </Text>
          ),
        },
        {
          className: 'pr-4',
          cellData: (
            <Text size="sm">{dayjs(paymentCase?.createdDate).format(DateFormat.DD_MMM_YYYY)}</Text>
          ),
        },
        !enableCasePortalSupportTableUpdate && {
          className: 'pr-4',
          cellData: (
            <>
              {paymentCase?.closedDate && (
                <Text size="sm">
                  {dayjs(paymentCase?.closedDate).format(DateFormat.DD_MMM_YYYY)}
                </Text>
              )}
            </>
          ),
        },
        {
          className: 'pr-4 w-[160px]',
          cellData: (
            <StatusPill
              fitContent
              label={getPaymentCaseStatusLabel(paymentCase?.status)}
              state={getPaymentCaseStatus(paymentCase?.status)}
            />
          ),
        },
        !enableCasePortalSupportTableUpdate && {
          className: 'pr-4',
          cellData: (
            <TablePopoverMenu
              testid="transactionTablePopverMenu"
              menuItems={[
                {
                  iconName: 'SupportIcon',
                  label: t('paymentSupport.caseDetails'),
                  onClick: () => {
                    updatePaymentCase(paymentCaseIndex)
                    setIsPaymentCaseOpen(true)
                  },
                },
              ]}
            />
          ),
          align: 'end',
        },
        enableCasePortalCompletedCheck &&
          enableCasePortalSupportTableUpdate && {
            className: 'px-4',
            cellData: paymentCase?.customerCaseCompleted && <Icon name={'CheckIcon'} />,
          },
      ].filter(Boolean),
      onClick: () => enableCasePortalSupportTableUpdate && setSidePanel(paymentCaseIndex),
    }
  })

  return (
    <>
      {/* Show content and loading state */}
      {(isFetching || (!isError && !!casesList?.length)) && (
        <>
          <SimpleTableV2
            columns={columns}
            tableData={isFetching ? [] : (mappedPaymentCases as [])}
            isLoading={isFetching}
          />

          {paging?.total > CASELIST_PAGINATION_MAX && (
            <TablePagination
              disableLeftDropdown
              total={paging?.total}
              pagingMax={pagingMax}
              paging={paging}
              setPaymentsList={setCaseListPagination}
              executor={onPageChange}
            />
          )}

          <SidePanel
            isOpen={isPaymentCaseOpen}
            onClose={() => setIsPaymentCaseOpen(false)}
            externalStyle={{ top: `${bannerSpacing}px` }}
            paging={pagingPaymentCases}
            scrollBody={false}
            activeRowId={paymentCase?.caseNumber}
            onActiveRowChange={highlightRowBasedOnId}
            sidePanelHeader={{
              customSidePanelHeader: (
                <Container>
                  <Row flex alignItems="center" justifyContent="between">
                    <Text tag="h2" size="lg" weight="medium" className="sm:text-xl">
                      {`${t('paymentSupport.caseNumber')} ${paymentCase?.caseNumber}`}
                    </Text>
                    {enableCasePortalCompletedCheck &&
                      paymentCase?.status === PaymentCaseType.RESOLVED && (
                        <CaseCompleteCheckbox
                          isCaseCompleted={!!paymentCase?.customerCaseCompleted}
                          caseId={paymentCase?.id}
                        />
                      )}
                  </Row>
                  {enableCasePortalCustomerId ? (
                    <CustomerIdInput
                      customerId={paymentCase?.customerId}
                      caseId={paymentCase?.id}
                    />
                  ) : (
                    <Row>
                      <Text size="sm" color="text-300">
                        {paymentCase?.subject}
                      </Text>
                      <Box divider="top" className="my-4" />
                    </Row>
                  )}
                  <Row flex gapX={16} alignItems="center" justifyContent="between">
                    <Box>
                      <Text size="sm" color="text-500" className="mb-1">
                        {t('paymentSupport.status')}
                      </Text>
                      <StatusPill
                        displayAsDot
                        state={getPaymentCaseStatus(paymentCase?.status)}
                        label={getPaymentCaseStatusLabel(paymentCase?.status)}
                        fitContent
                      />
                    </Box>
                    <Box>
                      <Text size="sm" color="text-500" className="mb-1">
                        {t('paymentSupport.paymentReference')}
                      </Text>
                      <Row flex justifyContent="end">
                        {!paymentCase?.paymentReference ? (
                          <Tooltip position="top" bodyContent={t('paymentSupport.nullTooltip')}>
                            <Icon name="InfoCircleIcon" />
                          </Tooltip>
                        ) : (
                          <CopyTextWrapper
                            textToCopy={paymentCase?.paymentReference}
                            textContainer={
                              <Text size="sm">
                                {ellipseMiddleText(paymentCase?.paymentReference, 7, 7)}
                              </Text>
                            }
                          />
                        )}
                      </Row>
                    </Box>
                  </Row>
                </Container>
              ),
            }}
          >
            <PaymentCaseProvider
              paymentCaseId={paymentCase?.id}
              paymentCaseStatus={paymentCase?.status}
            >
              <PaymentCaseDetailsBody />
            </PaymentCaseProvider>
          </SidePanel>
        </>
      )}

      {/* Show empty state */}
      {!isFetching && !isError && !casesList?.length && (
        <StatusCard
          headline={
            !status?.length
              ? t('paymentSupport.noCasesOpened')
              : t('paymentSupport.noCasesOpenedOnFilter')
          }
          description={t('paymentSupport.complianceTeamNotice')}
          className="rounded"
        />
      )}

      {/* Show error state */}
      {isError && !isFetching && (
        <StatusCard headline={t('paymentSupport.errorHeadline')} status="error">
          <PaymentCaseError />
        </StatusCard>
      )}
    </>
  )
}
